// register error overlay
const showErrorOverlay = (errorOverlay, { error }) => {
  const overlay = new errorOverlay(error)
  document.body.appendChild(overlay)
}

export const initialize = () => {
  // must be within function call because that's when the element is defined for sure.
  const errorOverlay = customElements.get('vite-error-overlay')
  // don't open outside vite environment
  if (!errorOverlay) return
  window.addEventListener('error', showErrorOverlay)
  window.addEventListener('error', (error) => showErrorOverlay(errorOverlay, error))
}
