import de from '@/locales/translations/de/de.json'
import common from '@/locales/translations/de/common.json'
import glossary from '@/locales/translations/de/glossary.json'
import validation from '@/locales/translations/de/validation.json'

export default {
  translation: de,
  common: common,
  glossary: glossary,
  validation: validation,
}
