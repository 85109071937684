import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { Student } from '@/sections/student/summary/types/common'
import type { Params } from '@/sections/principal/dashboard/types/common'
import type * as StudentsDrawerTypes from './studentsDrawer'

export interface NewStudentModalState {
  open: boolean
  students: Student[]
  selectedStudents: Student[]
  loading: boolean
  error: string
  params: Params
  total: number
  search: string
}

export const SET_NEW_STUDENT_MODAL_OPEN = 'CLASSROOM_MIGRATION/SET_NEW_STUDENT_MODAL_OPEN'
export const SET_NEW_STUDENT_MODAL_CLOSED = 'CLASSROOM_MIGRATION/SET_NEW_STUDENT_MODAL_CLOSED'

export const SET_SELECTED_STUDENT = 'CLASSROOM_MIGRATION/SET_SELECTED_STUDENT'
export const SET_STUDENT_SEARCH = 'CLASSROOM_MIGRATION/SET_STUDENT_SEARCH'

export const GET_ALL_STUDENTS_REQUEST = 'CLASSROOM_MIGRATION/GET_ALL_STUDENTS_REQUEST'
export const GET_ALL_STUDENTS_SUCCESS = 'CLASSROOM_MIGRATION/GET_ALL_STUDENTS_SUCCESS'
export const GET_ALL_STUDENTS_FAILURE = 'CLASSROOM_MIGRATION/GET_ALL_STUDENTS_FAILURE'

export const NEW_STUDENTS_MODAL_LIMIT = 10

export interface SetNewStudentModalOpen {
  type: typeof SET_NEW_STUDENT_MODAL_OPEN
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface SetNewStudentModalClosed {
  type: typeof SET_NEW_STUDENT_MODAL_CLOSED
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetAllStudentsRequest {
  type: typeof GET_ALL_STUDENTS_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetAllStudentsSuccess {
  type: typeof GET_ALL_STUDENTS_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    students: Student[]
    total: number
    params: Params
  }
}

export interface GetAllStudentsFailure {
  type: typeof GET_ALL_STUDENTS_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface SetSelectedStudent {
  type: typeof SET_SELECTED_STUDENT
  payload: {
    requestOrigin: ReduxRequestOrigin
    student: Student
  }
}

export interface SetStudentSearch {
  type: typeof SET_STUDENT_SEARCH
  payload: {
    requestOrigin: ReduxRequestOrigin
    search: string
  }
}

export type NewStudentModalActions =
  | GetAllStudentsRequest
  | GetAllStudentsSuccess
  | GetAllStudentsFailure
  | SetNewStudentModalOpen
  | SetNewStudentModalClosed
  | SetSelectedStudent
  | SetStudentSearch
  | StudentsDrawerTypes.AddNewStudents
