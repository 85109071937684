import React from 'react'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'

type StyleProps = Partial<LoadingContainerProps>

const useStyles = makeStyles(() => ({
  loadingContainer: (props: StyleProps) => ({
    background: props.backgroundColor || '#F8F8F6',
    width: '100%',
    height: props.height || '100%',
    top: 0,
    left: 0,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: props.positionAbsolute ? 'absolute' : 'relative',
    opacity: props.hide ? 0 : 1,
    visibility: props.hide ? 'hidden' : 'visible',
    transition: '.2s all ease-in-out',
  }),
}))

export interface LoadingContainerProps {
  backgroundColor?: string
  positionAbsolute?: boolean
  height?: number
  hide?: boolean
  className?: string
  children?: React.ReactNode
}

const LoadingContainer = React.forwardRef(
  (
    { backgroundColor, children, positionAbsolute, height, hide, className }: LoadingContainerProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const classes = useStyles({ backgroundColor, positionAbsolute, height, hide })
    return (
      <div ref={ref} className={clsx(classes.loadingContainer, className)}>
        {children}
      </div>
    )
  }
)

export default LoadingContainer
