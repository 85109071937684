import React from 'react'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    fontSize: 20,
    color: '#1A1A1A',
    letterSpacing: '0.01em',
    marginBottom: 10,
  },
}))

const Title = ({ children, className, ...props }) => {
  const classes = useStyles()
  return (
    <Typography id="step-title" variant="h6" className={`${classes.title} ${className}`} {...props}>
      {children}
    </Typography>
  )
}

export default Title
