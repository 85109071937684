export interface ConstraintOption {
  label: string
  max: number
}

export enum EvaluationType {
  CONCEPTUAL = 'conceptual',
  NUMERIC = 'numeric',
}

export const evaluationTypes = [
  {
    id: 1,
    type: EvaluationType.CONCEPTUAL,
    name: 'utilsGrades.conceptual',
    constraints: {
      options: [
        { label: 'utilsGrades.insufficient', max: 40 },
        { label: 'utilsGrades.regular', max: 60 },
        { label: 'utilsGrades.good', max: 80 },
        { label: 'utilsGrades.veryGood', max: 90 },
        { label: 'utilsGrades.outstanding', max: 100 },
      ],
    },
  },
  {
    id: 2,
    type: EvaluationType.NUMERIC,
    name: 'utilsGrades.numericGrade',
    constraints: {
      min: 1,
      max: 10,
    },
  },
  {
    id: 3,
    type: EvaluationType.NUMERIC,
    name: 'utilsGrades.numericGradeHundred',
    constraints: {
      min: 1,
      max: 100,
    },
  },
]

type Grade = {
  value: string | number
  unprocessedValue: number
  type: EvaluationType
  max?: number
}

export const getGrade = (grade: number, evaluationTypeID: number): Grade => {
  const evaluationType = evaluationTypes.find((e) => e.id === evaluationTypeID)
  if (!evaluationType)
    return { value: grade, unprocessedValue: grade, type: EvaluationType.NUMERIC, max: 100 }

  const max = evaluationType.constraints.max || 100
  const options = evaluationType.constraints.options || []

  switch (evaluationType.type) {
    default:
    case 'numeric':
      return {
        max,
        value: convertNumeric(grade, max),
        unprocessedValue: grade,
        type: evaluationType.type,
      }
    case 'conceptual':
      return {
        value: convertConcept(grade, options),
        unprocessedValue: grade,
        type: evaluationType.type,
      }
  }
}

export const convertNumeric = (grade: number, max: number) => {
  const v = (grade / 100) * max
  return round(v)
}

const round = (grade: number) => {
  return Math.round((grade + Number.EPSILON) * 100) / 100
}

export const convertConcept = (grade: number, options: ConstraintOption[]) => {
  const option = options.find((c) => c.max >= grade) || { label: '?', max: 0 }
  return option.label
}
