import type { CancelTokenSource } from 'axios'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { MessageThreadReply } from '@/api/bulletin'

export interface MessageThreadRepliesState {
  error: Error | null
  loading: boolean
  status: REQUEST_STATUS
  total: number
  replies: MessageThreadReply[]
  cancelToken: CancelTokenSource | null
  messageId: number
  threadOwnerId: number
}

export enum MESSAGE_THREAD_REPLIES_TYPES {
  GET_MESSAGE_THREADS = 'STUDENT/SUMMARY/TABS/MESSAGE_THREADS_REPLIES/GET_MESSAGE_THREADS',
  GET_MESSAGE_THREADS_SUCCESS = 'STUDENT/SUMMARY/TABS/MESSAGE_THREADS_REPLIES/GET_MESSAGE_THREADS_SUCCESS',
  GET_MESSAGE_THREADS_FAILURE = 'STUDENT/SUMMARY/TABS/MESSAGE_THREADS_REPLIES/GET_MESSAGE_THREADS_FAILURE',
  CLEAN_MESSAGE_THREADS = 'STUDENT/SUMMARY/TABS/MESSAGE_THREADS_REPLIES/CLEAN_MESSAGE_THREADS',
  SET_MESSAGE_THREAD_CANCEL_TOKEN = 'STUDENT/SUMMARY/TABS/MESSAGE_THREADS_REPLIES/SET_MESSAGE_THREAD_CANCEL_TOKEN',
}

export type CleanMessageThread = {
  type: MESSAGE_THREAD_REPLIES_TYPES.CLEAN_MESSAGE_THREADS
}

export type GetMessageThread = {
  type: MESSAGE_THREAD_REPLIES_TYPES.GET_MESSAGE_THREADS
  payload: {
    messageId: number
    threadOwnerId: number
  }
}

export type GetMessageThreadSuccess = {
  type: MESSAGE_THREAD_REPLIES_TYPES.GET_MESSAGE_THREADS_SUCCESS
  payload: {
    total: number
    replies: MessageThreadReply[]
  }
}

export type GetMessageThreadFailure = {
  type: MESSAGE_THREAD_REPLIES_TYPES.GET_MESSAGE_THREADS_FAILURE
  payload: {
    error: Error
  }
}

export type SetMessageThreadCancelToken = {
  type: MESSAGE_THREAD_REPLIES_TYPES.SET_MESSAGE_THREAD_CANCEL_TOKEN
  payload: {
    token: CancelTokenSource
  }
}

export type MessageThreadRepliesActions =
  | CleanMessageThread
  | GetMessageThread
  | GetMessageThreadSuccess
  | GetMessageThreadFailure
  | SetMessageThreadCancelToken
