import type {
  AddFilesToUpload,
  DownloadFileSuccess,
  RemoveFileToUpload,
  RemoveUploadedFile,
  SetStudentFilesModalOpen,
  SetStudentFilesModalClosed,
  Question,
  UploadedFile,
} from '@/sections/editor/types/studentFiles'
import {
  ADD_FILES_TO_UPLOAD,
  DOWNLOAD_FILE_FAILURE,
  DOWNLOAD_FILE_REQUEST,
  DOWNLOAD_FILE_SUCCESS,
  REMOVE_FILE_TO_UPLOAD,
  REMOVE_UPLOADED_FILE,
  UPLOAD_FILES_FAILURE,
  UPLOAD_FILES_REQUEST,
  UPLOAD_FILES_SUCCESS,
  SET_STUDENT_FILES_MODAL_OPEN,
  SET_STUDENT_FILES_MODAL_CLOSED,
} from '@/sections/editor/types/studentFiles'
import type { AppThunk } from '@/state/thunk'
import { apiPrivate } from '@/api'
import { filesUrl } from '@aula/config'
import axios from 'axios'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import fileDownload from 'js-file-download'
import { isStudentEquivalent } from '@/legacy/rolesManagement'
import activityActions from '@/sections/student/dashboard/actions/activity'

const actions = {
  addFilesToUpload: (files: File[], questionID: number): AddFilesToUpload => ({
    type: ADD_FILES_TO_UPLOAD,
    payload: { files, questionID },
  }),
  removeFileToUpload: (file: File, questionID: number): RemoveFileToUpload => ({
    type: REMOVE_FILE_TO_UPLOAD,
    payload: { file, questionID },
  }),

  uploadFiles:
    (files: File[], ownerID: number, questionID: number): AppThunk<void> =>
    async (dispatch) => {
      dispatch({ type: UPLOAD_FILES_REQUEST, payload: { questionID } })
      const requests = files.map((file) => {
        const formData = new FormData()
        formData.append('name', file.name)
        formData.append('file', file)
        formData.append('owner_id', ownerID.toString())
        // @ts-ignore
        formData.append('private', true)
        return apiPrivate.post(filesUrl + '/v1/files', formData)
      })
      return axios
        .all(requests)
        .then(
          axios.spread((...responses) => {
            const files = responses.map((response) => response.data.description.file)
            const payload = { files, questionId: questionID, type: 'attachment' }
            dispatch({ type: UPLOAD_FILES_SUCCESS, payload })
            return false
          })
        )
        .catch((error) => {
          handleErrorsWithAction(error, UPLOAD_FILES_FAILURE, dispatch, { questionID })
          return true
        }) // TODO handle when some request success but others fail
    },

  addFileFromBackpackAndSave:
    (file: UploadedFile, questionID: number): AppThunk<void> =>
    async (dispatch, getState) => {
      dispatch(actions.addFileFromBackpack(file, questionID))

      // @ts-ignore
      const { id, classroomId: classroomID } = getState().student.dashboard.activity.activity

      activityActions.markAsCompleted(classroomID, id, true, true)
    },

  addFileFromBackpack: (file: UploadedFile, questionID: number) => ({
    type: UPLOAD_FILES_SUCCESS,
    payload: {
      files: [file],
      questionId: questionID,
      type: 'attachment',
    },
  }),

  downloadFile:
    (fileID: number, fileName: string, questionID: number): AppThunk =>
    (dispatch, getState) => {
      dispatch({
        type: DOWNLOAD_FILE_REQUEST,
        payload: {
          fileID,
          questionID,
        },
      })

      let activityID
      const role = getState().user.role

      // @ts-ignore
      if (isStudentEquivalent(role)) activityID = getState().student.dashboard.activity.activity.id
      // @ts-ignore
      else activityID = getState().teacher.dashboard.activity.activity.id

      const config = {
        responseType: 'blob',
        params: { method: 'submission', activityId: activityID },
      }

      return (
        apiPrivate
          // @ts-ignore
          .get(filesUrl + `/v1/files/${fileID}/download`, config)
          .then((response) => {
            fileDownload(response.data, fileName)
            dispatch(actions.downloadFileSuccess(fileID, questionID))
          })
          .catch((error) => {
            handleErrorsWithAction(error, DOWNLOAD_FILE_FAILURE, dispatch, { fileID, questionID })
          })
      )
    },

  downloadFileSuccess: (fileID: number, questionID: number): DownloadFileSuccess => ({
    type: DOWNLOAD_FILE_SUCCESS,
    payload: { fileID, questionID },
  }),

  removeUploadedFile: (fileID: number, questionID: number): RemoveUploadedFile => ({
    type: REMOVE_UPLOADED_FILE,
    payload: { fileID, questionID },
  }),

  setStudentFilesModalOpen: (
    selectedQuestion: Question,
    version: string
  ): SetStudentFilesModalOpen => ({
    type: SET_STUDENT_FILES_MODAL_OPEN,
    payload: { selectedQuestion, version },
  }),
  setStudentFilesModalClosed: (): SetStudentFilesModalClosed => ({
    type: SET_STUDENT_FILES_MODAL_CLOSED,
  }),
}

export default actions
