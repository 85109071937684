import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCalendarComplete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={23}
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0v3h10V0h2v3h2a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h2V0h2Zm15 6v2H2V6a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1ZM2 20V10h20v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1Zm10.207-2.293 4.5-4.5-1.414-1.414-3.793 3.793-1.793-1.793-1.414 1.414 2.5 2.5a1 1 0 0 0 1.414 0Z"
      fill="#1A1A1A"
    />
  </svg>
)
export default SvgCalendarComplete
