import { handleBoolEnv } from '../utils'
import type { IWindow } from './types'

const env = (window as IWindow)._env_ || {}

export const ANDROID_APP_PACKAGE = env.WEBAPP_ANDROID_APP_PACKAGE ?? ''
export const APP_LOCALE = env.WEBAPP_APP_LOCALE ?? 'es'
export const ATTENDANCES_URL = env.WEBAPP_ATTENDANCES_URL ?? ''
export const AVV_URL = env.WEBAPP_AVV_URL ?? ''
export const BASE_URL = env.WEBAPP_URL
export const FORMS_VIEWER_URL = env.WEBAPP_FORMS_VIEWER_URL ?? ''
export const BASE_WEBSOCKET_URL = env.WEBAPP_WEBSOCKET_URL ?? BASE_URL
export const BULLETIN_URL = env.WEBAPP_BULLETIN_URL ?? ''
export const CABA_GAMES = env.WEBAPP_CABA_GAMES ?? ''
export const CHAT_URL = env.WEBAPP_CHAT_URL ?? ''
export const CHAT_VIDEOCALL_URL = env.WEBAPP_CHAT_VIDEOCALL_URL ?? ''
export const DISPATCHER_URL = env.WEBAPP_DISPATCHER_URL ?? ''
export const FILES_URL = env.WEBAPP_FILES_URL ?? ''
export const FIREBASE_ENABLED = handleBoolEnv(env.WEBAPP_FIREBASE_ENABLED, false)
export const IMPORT_VERSION = env.WEBAPP_IMPORT_VERSION ?? 'v3'
export const IOS_APP_SCHEME = env.WEBAPP_IOS_APP_SCHEME ?? ''
export const LIBRARY_URL = env.WEBAPP_LIBRARY_URL ?? ''
export const FORMS_URL = env.WEBAPP_FORMS_URL ?? ''
export const NO_ONLINE_SCRIPTS = handleBoolEnv(env.WEBAPP_NO_ONLINE_SCRIPTS, false)
export const NOTIFIER_URL = env.WEBAPP_NOTIFIER ?? ''
export const ORGANIZATIONS_URL = env.WEBAPP_ORGANIZATIONS_URL ?? ''
export const REPORT_CARD_URL = env.WEBAPP_REPORT_CARD_URL ?? ''
export const ROBOTS_API_URL = env.WEBAPP_ROBOTS_API_URL ?? ''
export const ROBOTS_URL = env.WEBAPP_ROBOTS_URL ?? ''
export const SEARCH_URL = env.WEBAPP_SEARCH_URL ?? ''
export const TED_STORAGE_URL = env.WEBAPP_TED_STORAGE_URL ?? ''
export const TED_UPLOAD_VIDEO_URL = env.WEBAPP_TED_UPLOAD_VIDEO_URL ?? ''
export const TED_REPORTS_URL = env.WEBAPP_TED_REPORTS_URL ?? ''
export const REPORT_CARD_EXPORT_URL = env.WEBAPP_REPORT_CARD_EXPORT_URL ?? ''
export const TED_URL = env.WEBAPP_TED_URL ?? ''
export const URL = BASE_URL + '/lms'
export const VIMEO_CREATE_VIDEO_URL = env.WEBAPP_VIMEO_CREATE_VIDEO_URL ?? ''
export const WEB_BFF = env.WEBAPP_WEB_BFF ?? ''
export const MICROSOFT_APP_ID = env.WEBAPP_MICROSOFT_APP_ID ?? ''
export const MICROSOFT_REDIRECT_URI = env.WEBAPP_MICROSOFT_REDIRECT_URI ?? ''
export const PDF_GENERATOR_PREVIEW_URL = env.WEBAPP_PDF_GENERATOR_PREVIEW_URL ?? ''
export const CLOUDFLARE_WORKER_GOOGLE_AUTH_URL = env.WEBAPP_CLOUDFLARE_WORKER_GOOGLE_AUTH_URL ?? ''
