import { useEffect, useState } from 'react'
import { Hidden, Typography, Tooltip, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Messages, NewMessages, Teacher } from '@/legacy/svgs/activityRibbon'
import ProgressCircle from '@/legacy/components/progressCircle'
import SubjectChip from '@/legacy/components/v2/subjectChip'
import clsx from 'clsx'
import { tableStyles } from './tableStyles'
import {
  isActivityFinished,
  isAwaitingApproval,
  isCompleted,
  isNew,
  shouldShowStateLabel,
} from '@/legacy/components/v2/ribbons/states'
import { DateUnit, formatGrade } from '@/legacy/components/v2/ribbons/student/units'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import MailIcon from '@mui/icons-material/Mail'
import OpenMailIcon from '@mui/icons-material/Drafts'
import { OutlineButton } from '@/legacy/components/v1'
import { useTranslation } from 'react-i18next'
import { theme } from '@aula/config'

const useStyles = makeStyles(tableStyles)

const StudentActivityRow = (props) => {
  const {
    id,
    title,
    Icon,
    date = [],
    completed,
    subject,
    subjectClick,
    buttonLabel,
    buttonClick,
    buttonEnabled,
    progress,
    hideProgress,
    showMessages,
    messages,
    messageClick,
    state,
    grade,
    feedback,
    unparsedDate,
    activity,
  } = props

  const classes = useStyles()
  const { t } = useTranslation()
  const [feedbackOpen, setFeedbackOpen] = useState(false)

  const getColor = (current, total) => {
    if (typeof current === 'undefined' || typeof total === 'undefined') return

    const percentage = (current / total) * 100

    if (percentage < 33) return '#F45C3A'
    else if (percentage < 66) return '#FFB800'
    else return '#50D174'
  }

  const getProgress = () => {
    if (isCompleted(state)) return t('teacher.activities.completed')
    if (isAwaitingApproval(state)) return t('teacher.dashboardExam.inCorrection')
    if (progress === 0) return '-'
    return `${progress}%`
  }

  return (
    <div className={classes.studentRow}>
      <div className={clsx(classes.cellContent, classes.titleCell)}>
        {shouldShowStateLabel(state) && (
          <Tooltip
            placement="bottom"
            classes={{ popper: classes.popper, tooltip: classes.tooltip, arrow: classes.arrow }}
            title={
              isNew(state)
                ? t('components.ribbon.student.new')
                : t('components.ribbon.student.delayed')
            }
            arrow
          >
            <div
              className={classes.state}
              style={{
                background: isNew(state) ? theme.palette.secondary.main : '#f37067',
              }}
            />
          </Tooltip>
        )}
        {Icon && <Icon className={classes.activityIcon} />}
        <Typography className={clsx(classes.regularLabel, classes.title)}>
          {title || t('activityTab.activity')}
        </Typography>
      </div>
      <div className={classes.cellContent}>
        <DateUnit state={state} activity={activity} />
      </div>
      <Hidden xsDown>
        <div className={classes.cellContent}>
          {!hideProgress && (
            <>
              {isActivityFinished(state) ? (
                <>
                  {isCompleted(state) && <CheckCircleIcon className={classes.checkIcon} />}
                  {isAwaitingApproval(state) && <Teacher className={classes.teacherIcon} />}
                </>
              ) : (
                <ProgressCircle
                  size={20}
                  thickness={7}
                  value={progress}
                  color={getColor(completed?.current, completed?.total)}
                />
              )}
              <Typography className={clsx(classes.regularLabel, classes.completionLabel)}>
                {grade ? (
                  <>
                    <div className={classes.grade}>
                      <Typography className={classes.regularLabel}>
                        {formatGrade(grade, t)}
                      </Typography>
                      {feedback && (
                        <Tooltip
                          placement="bottom"
                          classes={{
                            popper: classes.popper,
                            tooltip: classes.tooltip,
                            arrow: classes.arrow,
                          }}
                          title={feedback}
                          arrow
                          open={feedbackOpen}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          onClose={() => setFeedbackOpen(false)}
                        >
                          <IconButton
                            onClick={() => setFeedbackOpen((f) => !f)}
                            className={classes.iconButton}
                            aria-label="feedback"
                          >
                            {!feedbackOpen ? (
                              <MailIcon className={classes.envelope} />
                            ) : (
                              <OpenMailIcon className={classes.envelope} />
                            )}
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </>
                ) : (
                  <Typography className={classes.regularLabel}>{getProgress()}</Typography>
                )}
              </Typography>
            </>
          )}
        </div>
      </Hidden>
      <Hidden smDown>
        {showMessages ? (
          <div
            className={classes.cellContent}
            style={{ cursor: 'pointer' }}
            onClick={() => messageClick(id)}
          >
            {messages > 0 ? <NewMessages /> : <Messages />}
            {messages > 0 && (
              <Typography className={classes.messages}>{t('chats.messagesToast')}</Typography>
            )}
          </div>
        ) : (
          <div className={classes.cellContent}>
            <Messages className={classes.disabledMessages} />
          </div>
        )}
      </Hidden>
      <Hidden smDown>
        <div className={classes.cellContent}>
          <div className={classes.subjectChipContainer} onClick={() => subjectClick(subject)}>
            <SubjectChip subject={subject} maxCharacters={16} />
          </div>
        </div>
      </Hidden>
      <div className={classes.cellContent} style={{ justifyContent: 'center' }}>
        {!isCompleted(state) ? (
          <OutlineButton
            onClick={buttonClick}
            dense
            disabled={!buttonEnabled}
            className={classes.mainButton}
          >
            {buttonLabel}
          </OutlineButton>
        ) : (
          <span className={classes.regularLabel} style={{ textAlign: 'center' }}>
            -
          </span>
        )}
      </div>
    </div>
  )
}

export default StudentActivityRow
