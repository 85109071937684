import React from 'react'
import { makeStyles } from '@mui/styles'
import { ButtonV2 } from '@/legacy/components/v1'

const useStyles = makeStyles((theme) => ({
  activityTabs: {
    display: 'inline-block',
    float: 'right',
  },
  leftTab: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
  rightTab: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
  primaryTab: {
    fontWeight: '100',
    border: `solid 1px ${theme.palette.primary.main}`,
    '&:hover': {
      borderColor: theme.palette.secondary.main,
    },
  },
  secondaryTab: {
    fontWeight: '100',
    backgroundColor: 'white',
    color: 'black',
    border: 'solid 1px #e6e6e6',
    '&:hover': {
      borderColor: theme.palette.secondary.main,
    },
  },
  numberBubble: {
    marginLeft: '6px',
    marginRight: '-4px',
    color: 'black',
  },
}))

const ButtonTabs = ({
  value,
  leftLabel,
  rightLabel,
  leftQuantity,
  rightQuantity,
  onClickLeft,
  onClickRight,
}) => {
  const classes = useStyles()

  const classesLeftButton = value === 0 ? classes.primaryTab : classes.secondaryTab
  const classesRightButton = value === 1 ? classes.primaryTab : classes.secondaryTab

  return (
    <div className={classes.activityTabs}>
      <ButtonV2 dense className={[classesLeftButton, classes.leftTab]} onClick={onClickLeft}>
        {leftLabel}
        {/* <NumberBubble quantity={leftQuantity} classNameContainer={classes.numberBubble} /> */}
      </ButtonV2>

      <ButtonV2 dense className={[classesRightButton, classes.rightTab]} onClick={onClickRight}>
        {rightLabel}
        {/* <NumberBubble classNameContainer={classes.numberBubble} quantity={rightQuantity} /> */}
      </ButtonV2>
    </div>
  )
}

export default ButtonTabs
