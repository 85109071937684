import type { COSMOS_KEY } from '@aula/config'

export interface LogAsModalState {
  isOpen: boolean
  cosmosSelected: COSMOS_KEY | null
  user: {
    firstName: string
    lastName: string
    id: number
  }
}

export enum LOG_AS_MODAL_TYPES {
  OPEN = 'LOG_AS_MODAL/OPEN',
  CLOSE = 'LOG_AS_MODAL/CLOSE',
  COSMO_SELECTED = 'LOG_AS_MODAL/COSMO_SELECTED',
  USER_SELECTED = 'LOG_AS_MODAL/USER_SELECTED',
}

export interface LogAsModalOpenAction {
  type: LOG_AS_MODAL_TYPES.OPEN
}

export interface LogAsModalCloseAction {
  type: LOG_AS_MODAL_TYPES.CLOSE
}

export interface LogAsModalCosmoSelectedAction {
  type: LOG_AS_MODAL_TYPES.COSMO_SELECTED
  payload: {
    cosmos: COSMOS_KEY | null
  }
}
export interface LogAsUserSelectedAction {
  type: LOG_AS_MODAL_TYPES.USER_SELECTED
  payload: {
    user: { firstName: string; lastName: string; id: number }
  }
}

export type LogAsModalAction =
  | LogAsModalOpenAction
  | LogAsModalCloseAction
  | LogAsModalCosmoSelectedAction
  | LogAsUserSelectedAction
