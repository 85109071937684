export const OPEN_MODAL = 'REPORT_CARD/GRADING/MODAL/OPEN_MODAL'
export const CLOSE_MODAL = 'REPORT_CARD/GRADING/MODAL/CLOSE_MODAL'

export type HookOrigin = 'NAVIGATION' | 'DRAWER'

export type openModalAction = {
  type: typeof OPEN_MODAL
  payload: {
    origin: HookOrigin
  }
}

export type closeModalAction = {
  type: typeof CLOSE_MODAL
}
