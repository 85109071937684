import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

// State.
export interface DeleteBookModalState {
  isOpenModal: boolean
  status: REQUEST_STATUS
  bookIdToDelete: number
}

// Types.
export enum DELETE_BOOK_MODAL_TYPES {
  OPEN_MODAL = 'CONTENT_CREATOR/DELETE_BOOK_MODAL/OPEN_MODAL',
  SET_BOOK_ID_TO_DELETE = 'CONTENT_CREATOR/DELETE_BOOK_MODAL/SET_BOOK_ID_TO_DELETE',
  DELETE_BOOK_REQUEST = 'CONTENT_CREATOR/DELETE_BOOK_MODAL/DELETE_BOOK_REQUEST',
  DELETE_BOOK_SUCCESS = 'CONTENT_CREATOR/DELETE_BOOK_MODAL/DELETE_BOOK_SUCCESS',
  DELETE_BOOK_FAILURE = 'CONTENT_CREATOR/DELETE_BOOK_MODAL/DELETE_BOOK_FAILURE',
  CLEAN_MODAL = 'CONTENT_CREATOR/DELETE_BOOK_MODAL/CLEAN_MODAL',
}

// Actions.
// Open modal action.
export type OpenModalAction = {
  type: DELETE_BOOK_MODAL_TYPES.OPEN_MODAL
}

// Set bookId to delete action.
export type SetBookIdToDeleteAction = {
  type: DELETE_BOOK_MODAL_TYPES.SET_BOOK_ID_TO_DELETE
  payload: number
}

// Delete book request action.
export type DeleteBookRequestAction = {
  type: DELETE_BOOK_MODAL_TYPES.DELETE_BOOK_REQUEST
}

// Delete book success action.
export type DeleteBookSuccessAction = {
  type: DELETE_BOOK_MODAL_TYPES.DELETE_BOOK_SUCCESS
}

// Delete book failure action.
export type DeleteBookFailureAction = {
  type: DELETE_BOOK_MODAL_TYPES.DELETE_BOOK_FAILURE
}

// Delete book action.
export type DeleteBookAction =
  | DeleteBookRequestAction
  | DeleteBookSuccessAction
  | DeleteBookFailureAction

// Clean modal action.
export type CleanModalAction = {
  type: DELETE_BOOK_MODAL_TYPES.CLEAN_MODAL
}

// Action.
export type DeleteBookModalAction =
  | OpenModalAction
  | SetBookIdToDeleteAction
  | DeleteBookAction
  | CleanModalAction
