import type { Stage as OrganizationStage } from '@/api/lms/organizations'
import type { ManagementSubject } from '@/api/lms/subjects'
import type { Stage as ModalStage } from '@/sections/management/types/addEditClassroomModal'

interface WithName {
  id: number
  name: string
}
interface WithLabel {
  id: number
  label: string
}

export function getOptions(prop: WithName[]): WithLabel[] {
  return prop.map((s) => {
    return { id: s.id, label: s.name }
  })
}

export function getSelected(options: WithLabel[], selectedId: number): WithLabel {
  return options.find((fs) => fs.id === selectedId) || options[0]
}

export function getStageOptions(stages: OrganizationStage[]): ModalStage[] {
  return stages.map((s) => {
    return {
      id: s.id,
      label: s.name,
      masterSeries: {
        id: s.masterSeries.id,
        label: s.masterSeries.name,
        steps:
          s.masterSeries.steps?.length > 0
            ? s.masterSeries.steps.map((step) => {
                return {
                  id: step.stepValue,
                  label: step.name,
                }
              })
            : [{ id: 0, label: '-' }],
      },
    }
  })
}

/**
 * Devuelve un booleano que indica si las materias actuales estan incluidas en las materias iniciales
 */
export function areSubjectsIncluded(
  initial: ManagementSubject[],
  current: ManagementSubject[]
): boolean {
  let areIncluded = true
  const currentIds = current.map(({ id }) => id)
  for (const { id } of initial) {
    if (!currentIds.includes(id)) {
      areIncluded = false
      break
    }
  }
  return areIncluded
}
