import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { Classroom } from '@/sections/student/summary/types/common'

export interface ClassroomsState {
  status: REQUEST_STATUS
  data: Classroom[]
  selectedClassroom: Classroom
}

export enum CLASSROOMS_TYPES {
  GET_STUDENT_CLASSROOMS_REQUEST = 'STUDENT/SUMMARY/DRAWER/CLASSROOMS/GET_STUDENT_CLASSROOMS_REQUEST',
  GET_STUDENT_CLASSROOMS_SUCCESS = 'STUDENT/SUMMARY/DRAWER/CLASSROOMS/GET_STUDENT_CLASSROOMS_SUCCESS',
  GET_STUDENT_CLASSROOMS_FAILURE = 'STUDENT/SUMMARY/DRAWER/CLASSROOMS/GET_STUDENT_CLASSROOMS_FAILURE',
  SET_SELECTED_CLASSROOM = 'STUDENT/SUMMARY/DRAWER/CLASSROOMS/SET_SELECTED_CLASSROOM',
  CLEAN_CLASSROOMS = 'STUDENT/SUMMARY/DRAWER/CLASSROOMS/CLEAN_CLASSROOMS',
}

// Get student classrooms actions.
export type GetStudentClassroomsRequestAction = {
  type: CLASSROOMS_TYPES.GET_STUDENT_CLASSROOMS_REQUEST
}

export type GetStudentClassroomsSuccessAction = {
  type: CLASSROOMS_TYPES.GET_STUDENT_CLASSROOMS_SUCCESS
  payload: {
    data: Classroom[]
  }
}

export type GetStudentClassroomsFailureAction = {
  type: CLASSROOMS_TYPES.GET_STUDENT_CLASSROOMS_FAILURE
}

export type GetStudentClassroomsAction =
  | GetStudentClassroomsRequestAction
  | GetStudentClassroomsSuccessAction
  | GetStudentClassroomsFailureAction

// Set selected classroom action.
export type SetSelectedClassroomAction = {
  type: CLASSROOMS_TYPES.SET_SELECTED_CLASSROOM
  payload: {
    newClassroom: Classroom
  }
}

// Clean classrooms action.
export type CleanClassroomsAction = {
  type: CLASSROOMS_TYPES.CLEAN_CLASSROOMS
}

export type ClassroomsAction =
  | GetStudentClassroomsAction
  | SetSelectedClassroomAction
  | CleanClassroomsAction
