import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import type {
  DeleteAssignmentModalState,
  DeleteUserModalAction,
} from '@/sections/management/types/users/deleteAssignmentModal'
import { INIT_MODAL } from '@/sections/management/types/users/deleteAssignmentModal'

const initialState: DeleteAssignmentModalState = {
  assignment: {
    assignmentId: '',
    role: '',
    classroomId: 0,
    subjectId: 0,
    assignmentName: '',
  },
  open: false,
  loading: false,
  error: '',
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_DELETE_ASSIGNMENT_MODAL

const DeleteAssignmentModalReducer: Reducer<DeleteAssignmentModalState, DeleteUserModalAction> = (
  state = initialState,
  action
): DeleteAssignmentModalState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case INIT_MODAL:
      return {
        ...state,
        assignment: action.payload.assignment,
        open: true,
        error: initialState.error,
      }
    case Redux.Types.ManagementUsers.UNASSIGN_USER_REQUEST:
      return { ...state, loading: true }
    case Redux.Types.ManagementUsers.UNASSIGN_USER_SUCCESS:
      return initialState
    case Redux.Types.ManagementUsers.UNASSIGN_USER_FAILURE:
      return { ...state, error: action.payload.error, loading: false }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, DeleteAssignmentModalReducer, requestDestination)
