export const tableStyles = (theme) => ({
  row: {
    fontFamily: theme.typography.fontFamily,
    background: '#FFF',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '25% 25% 13% 12% 20% 5%',
    border: '1px solid #DFDFDA',
    boxSizing: 'border-box',
    borderTop: 0,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '35% 35% 20% 10%',
    },
    [theme.breakpoints.down(432)]: {
      gridTemplateColumns: '50% 20% 20% 10%',
    },
  },
  studentRow: {
    minHeight: 48,
    fontFamily: theme.typography.fontFamily,
    background: '#FFF',
    width: '100%',
    display: 'grid',
    border: '1px solid #DFDFDA',
    boxSizing: 'border-box',
    borderTop: 0,
    gridTemplateColumns: '25% 25% 15% 10% 12% 13%',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '32% 22% 25% 21%',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '45% 22% 33%',
    },
  },
  contentCreatorRow: {
    fontFamily: theme.typography.fontFamily,
    background: '#FFF',
    width: '100%',
    display: 'grid',
    border: '1px solid #DFDFDA',
    boxSizing: 'border-box',
    borderTop: 0,
    gridTemplateColumns: '8% 20% 18% 18% 14% 17% 5%',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '25% 18% 20% 15% 17% 5%',
    },
  },
  sequenceRow: {
    gridTemplateColumns: '25% 21% 22% 15% 12% 5%',
  },
  headerRow: {
    borderTop: '1px solid #DFDFDA',
    borderRadius: '8px 8px 0 0',
    background: '#F8F8F6',
  },
  headerCell: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
    padding: 12,
  },
  cellContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px 12px',
  },
  activityIcon: {
    width: 24,
    height: 24,
    marginRight: 12,
  },
  regularLabel: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 14,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  regularOrganization: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 14,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    cursor: 'pointer',
    borderRadius: 6,
    padding: '0 4px',
    '&:hover': {
      background: '#0000000A',
    },
  },
  completionCell: {
    cursor: 'pointer',
    '&:hover': {
      background: '#f9f9f9',
    },
  },
  completionLabel: {
    marginLeft: 8,
  },
  title: {
    maxWidth: 'calc(100% - 38px)',
  },
  calendarIcon: {
    width: 24,
    minWidth: 24,
    height: 24,
    marginRight: 8,
    marginTop: -5,
  },
  messagesContainer: {
    cursor: 'pointer',
  },
  messages: {
    color: '#1A1A1A',
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 'bold',
  },
  disabledMessagesContainer: {
    cursor: 'default',
  },
  disabledMessages: {
    '& path': {
      fill: '#d8d8d8',
    },
  },
  state: {
    width: 6,
    height: 6,
    marginRight: 12,
    borderRadius: '50%',
  },
  tooltip: {
    background: 'rgba(26, 26, 26, 0.9)',
  },
  arrow: {
    '&::before': {
      background: 'rgba(26, 26, 26, 0.9)',
    },
  },
  checkIcon: {
    color: '#50D174',
    height: 18,
    width: 18,
  },
  grade: {
    display: 'flex',
  },
  iconButton: {
    padding: 0,
    marginLeft: 12,
  },
  envelope: {
    fontSize: 16,
  },
  mainButton: {
    fontSize: 12,
    borderWidth: '1px !important',
    minWidth: 76,
    padding: '2px 8px !important',
    marginLeft: 'auto',
  },
  extraCircles: {
    marginBottom: 8,
  },
  extraCircle: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0 0',
  },
  extraCircleLabel: {
    marginLeft: 8,
  },
})
