import * as React from 'react'
import type { SVGProps } from 'react'
const SvgWednesday = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.926 1v3h10V1h2v3h2a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3h-18a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h2V1h2Zm12 5h-16a1 1 0 0 0-1 1v2h20V7a1 1 0 0 0-1-1h-2Zm-17 5v10a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V11h-20Z"
      fill="#1A1A1A"
    />
    <path
      d="M5.777 19.47v-6.3h1.368l2.007 3.996 1.98-3.996H12.5v6.3h-1.152v-4.347L9.593 18.57h-.909L6.93 15.123v4.347H5.777Zm7.945 0v-6.3h1.152v6.3h-1.152Zm2.374 0v-6.3h4.113v.927h-2.962v1.728h2.692v.9h-2.692v1.818h2.962v.927h-4.113Z"
      fill="#000"
    />
  </svg>
)
export default SvgWednesday
