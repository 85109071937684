import type { LocaleSpecification } from 'moment'

export const en: LocaleSpecification = {
  months: [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ],
  monthsShort: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
  weekdays: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
  weekdaysShort: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
  weekdaysMin: ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'],
  longDateFormat: {
    LT: 'h:mm A',
    LTS: 'h:mm:ss A',
    L: 'MM/DD/YYYY',
    LL: 'MMMM D, YYYY',
    LLL: 'MMMM D, YYYY LT',
    LLLL: 'dddd, MMMM D, YYYY LT',
  },
  calendar: {
    sameDay: function () {
      return '[Today at] LT'
    },
    nextDay: function () {
      return '[Tomorrow at] LT'
    },
    nextWeek: function () {
      return 'dddd [at] LT'
    },
    lastDay: function () {
      return '[Yesterday at] LT'
    },
    lastWeek: function () {
      return 'dddd [at] LT'
    },
    sameElse: 'L',
  },
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
  ordinalParse: /\d{1,2}(st|nd|rd|th)/,
  ordinal: function (val: number) {
    const b = val % 10
    return b === 1 && val !== 11
      ? val + 'st'
      : b === 2 && val !== 12
      ? val + 'nd'
      : b === 3 && val !== 13
      ? val + 'rd'
      : val + 'th'
  },
  week: {
    dow: 0,
    doy: 4,
  },
}
