import { ROLE } from '@aula/config'

export enum EbRole {
  EB_ADMIN = 'eb-admin',
  EB_EVALUATOR = 'eb-evaluator',
}

export type IndividualRole = `${ROLE}`

// TODO: Integrate with regular roles when main role enum issues are solved
export enum ManagementRole {
  ADMIN = 'admin',
  PRINCIPAL = 'principal',
}

// TODO should be replaced by parseApiRoleToIntl
export const mapRolesToLocale = (role: IndividualRole): string => {
  switch (role) {
    case ROLE.TEACHER:
      return 'Docente'
    case ROLE.CLASS_TEACHER:
      return 'Docente Principal'
    case ROLE.STUDENT:
      return 'Estudiante'
    case ROLE.PRINCIPAL:
      return 'Director'
    case ROLE.RECTOR:
      return 'Rector'
    case ROLE.VICE_PRINCIPAL:
      return 'Vice Director'
    case ROLE.CONTENT_CREATOR:
      return 'Contenidista'
    case ROLE.EB_ADMIN:
      return 'Administrador'
    case ROLE.GUEST:
      return 'Invitado'
    case ROLE.EB_EVALUATOR:
      return 'Evaluador'

    default:
      return ''
  }
}

/**
 * Converts a string into a Role.
 *
 * @param s String to convert
 * @param d Default value in case the string is invalid
 */
export const strToRole = (s: string, d: ROLE): ROLE => {
  return strToRoleNd(s) ?? d
}

/**
 * No default version of strToRole.
 *
 * @param s String to convert
 */
export const strToRoleNd = (s: string): ROLE | null => {
  for (const [key, value] of Object.entries(ROLE)) {
    if (value === s) return ROLE[key]
  }
  return null
}

/**
 * Converts a string into one of the roles specified. If none of the options are suitable, the first
 * option is selected, unless the default has been set. If, for some reason, opts is an empty array,
 * the function will arbitrarily return Role.STUDENT.
 *
 * T can be used to indicate the roles in opts and keep Typescript happy. As in the following
 * example:
 *
 * let r: Role.STUDENT | Role.TEACHER
 * r = strToRoleSubset<Role.STUDENT | Role.TEACHER>(role, [Role.STUDENT, Role.TEACHER])
 *
 * WARNING: this function trusts that you will not set a value in the array that is not in the
 * union type. Doing so will result in an error Typescript will not save you from.
 *
 * Alternatively, if you don't need to be specific and can accept any roles, simply go with:
 *
 * let r: Role
 * r = strToRoleSubset<Role>(role, [Role.STUDENT])
 *
 * @param s String to convert
 * @param opts Options to chose from
 * @param d Default value in case the role is not found in the options
 */
export const strToRoleSubset = <T extends ROLE>(s: string, opts: ROLE[], d?: ROLE): T => {
  const r = strToRoleNd(s)
  if (r !== null && opts.includes(r)) return <T>r
  if (d) return <T>d
  if (opts.length > 0) return <T>opts[0]
  return <T>ROLE.STUDENT
}

export function parseApiRoleToIntl(role: ROLE) {
  switch (role) {
    case ROLE.STUDENT:
      return 'student'
    case ROLE.TEACHER:
      return 'teacher'
    case ROLE.CLASS_TEACHER:
      return 'classTeacher'
    case ROLE.PARENT:
      return 'parent'
    case ROLE.CONTENT_CREATOR:
      return 'contentCreator'
    case ROLE.PRINCIPAL:
      return 'principal'
    case ROLE.VICE_PRINCIPAL:
      return 'vicePrincipal'
    case ROLE.RECTOR:
      return 'rector'
    case ROLE.PRECEPTOR:
      return 'preceptor'

    default:
      return '-'
  }
}
