import AbscencesDrawerContainer from '@/sections/report-card/grading/containers/abscencesDrawer'
import { SideDrawerContent } from '@/sections/report-card/grading/sideDrawer/containers/sideDrawerWrapper'

export interface DrawerContentProps {
  drawerContent: SideDrawerContent
}

export default function DrawerContent({ drawerContent }: DrawerContentProps): JSX.Element {
  switch (drawerContent) {
    case SideDrawerContent.ABSCENCES:
      return <AbscencesDrawerContainer />

    case SideDrawerContent.INITIAL:
    default:
      return <></>
  }
}
