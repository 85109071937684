import { API } from '@/api/lms'
import { getAsPrincipal } from '@/legacy/services/api/lms/classrooms'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { AppThunk } from '@/state/thunk'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import type {
  Classroom,
  ClassroomAge,
  SetClassroomToMigrate,
  GetClassroomsSuccess,
  CompleteMigrationSuccess,
  SetClassroomToMigrateV2,
  GetClassroomStudentsSuccess,
  StudentWithClassroom,
  SetAlertModalOpenAction,
  SetAlertModalCloseAction,
  Migration,
  ClearMigrationStateAction,
} from '@/sections/principal/classroom-migration/types/migrationClassrooms'
import {
  SET_CLASSROOM_TO_MIGRATE,
  GET_CLASSROOMS_REQUEST,
  GET_CLASSROOMS_SUCCESS,
  GET_CLASSROOMS_FAILURE,
  COMPLETE_MIGRATION_REQUEST,
  COMPLETE_MIGRATION_SUCCESS,
  COMPLETE_MIGRATION_FAILURE,
  SET_CLASSROOM_TO_MIGRATE_V2,
  GET_CLASSROOM_STUDENTS_FAILURE,
  GET_CLASSROOM_STUDENTS_SUCCESS,
  GET_CLASSROOM_STUDENTS_REQUEST,
  SET_ALERT_MODAL_OPEN,
  SET_ALERT_MODAL_CLOSE,
  CLEAR_MIGRATION_STATE,
} from '@/sections/principal/classroom-migration/types/migrationClassrooms'
import type { GetState } from '@/state/reducer'

let searchTimer: ReturnType<typeof setTimeout>

const migrationClassroomActionsCreator = {
  getClassroomsOptions:
    (
      requestOrigin: ReduxRequestOrigin,
      limit: number,
      offset: number,
      organizationID: number,
      academicPeriodId: number,
      type: ClassroomAge,
      search: string,
      toMigrate?: boolean
    ): AppThunk =>
    (dispatch) => {
      if (searchTimer) clearTimeout(searchTimer)
      searchTimer = setTimeout(
        () =>
          dispatch(
            migrationClassroomActionsCreator.getClassrooms(
              requestOrigin,
              offset,
              organizationID,
              academicPeriodId,
              type,
              search,
              toMigrate
            )
          ),
        250
      )
    },
  setClassroomToMigrate: (
    requestOrigin: ReduxRequestOrigin,
    oldClassroom: Classroom,
    newClassroom?: Classroom
  ): SetClassroomToMigrate => ({
    type: SET_CLASSROOM_TO_MIGRATE,
    payload: {
      requestOrigin,
      oldClassroom,
      newClassroom,
    },
  }),
  setClassroomToMigrateV2: (
    requestOrigin: ReduxRequestOrigin,
    oldClassroom: Classroom,
    newClassroom?: Classroom
  ): SetClassroomToMigrateV2 => ({
    type: SET_CLASSROOM_TO_MIGRATE_V2,
    payload: {
      requestOrigin,
      oldClassroom,
      newClassroom,
    },
  }),
  getClassrooms:
    (
      requestOrigin: ReduxRequestOrigin,
      offset: number,
      organizationID: number,
      academicPeriodId: number,
      type: ClassroomAge,
      search?: string,
      toMigrate?: boolean
    ): AppThunk =>
    (dispatch, getState: GetState) => {
      dispatch({ type: GET_CLASSROOMS_REQUEST, payload: { requestOrigin, type, offset } })

      const limit =
        getState().principal.classroomMigration.migrationClassrooms.pagination.rowsPerPage

      return getAsPrincipal({
        limit: type === 'new' ? 30 : limit,
        offset: offset * limit,
        organizationId: organizationID,
        academicPeriodId,
        search,
        toMigrate,
        ...(toMigrate && { getStudentsCount: true }),
      })
        .then((resp) => {
          const { rows: classrooms, count, more } = resp
          dispatch(
            migrationClassroomActionsCreator.getClassroomsSuccess(
              requestOrigin,
              type,
              classrooms,
              count,
              more
            )
          )
        })
        .catch((error) => {
          handleErrorsWithAction(error, GET_CLASSROOMS_FAILURE, dispatch, { requestOrigin })
        })
    },
  getClassroomsSuccess: (
    requestOrigin: ReduxRequestOrigin,
    type: ClassroomAge,
    classrooms: Classroom[],
    count: number,
    more: boolean
  ): GetClassroomsSuccess => ({
    type: GET_CLASSROOMS_SUCCESS,
    payload: {
      requestOrigin,
      classrooms,
      type,
      count,
      more,
    },
  }),
  completeMigration:
    (requestOrigin: ReduxRequestOrigin, migrations: Migration[]): AppThunk =>
    (dispatch) => {
      dispatch({ type: COMPLETE_MIGRATION_REQUEST, payload: { requestOrigin } })

      const formattedClassrooms = migrations.flatMap((migration) => {
        return migration.fromClassroomsList.map((classroom) => ({
          srcId: classroom.id,
          dstId: migration.toClassroom.id,
          ...(migration.studentsToRemove.length ? { remove: migration.studentsToRemove } : {}),
          ...(migration.studentsToAdd.length ? { add: migration.studentsToAdd } : {}),
        }))
      })

      return API.Classrooms.migrateClassrooms(formattedClassrooms)
        .then(() => {
          dispatch(migrationClassroomActionsCreator.completeMigrationSuccess(requestOrigin))
          dispatch(migrationClassroomActionsCreator.setCloseAlertModal(requestOrigin))
        })
        .catch((error) => {
          handleErrorsWithAction(error, COMPLETE_MIGRATION_FAILURE, dispatch, { requestOrigin })
        })
    },
  completeMigrationSuccess: (requestOrigin: ReduxRequestOrigin): CompleteMigrationSuccess => ({
    type: COMPLETE_MIGRATION_SUCCESS,
    payload: {
      requestOrigin,
    },
  }),
  getClassroomStudents:
    (requestOrigin: ReduxRequestOrigin): AppThunk =>
    async (dispatch, getState: GetState) => {
      dispatch({ type: GET_CLASSROOM_STUDENTS_REQUEST, payload: { requestOrigin } })

      try {
        const migrations = getState().principal.classroomMigration.migrationClassrooms.migrations

        const promises = migrations.flatMap((classroom) => {
          return classroom.fromClassroomsList.map((c) => {
            return API.Students.getClassroomStudents(c.id, {
              limit: 100,
              offset: 0,
            }).then((response) => {
              const list = response.data.description.students
              if (list === null) {
                return [
                  {
                    student: { id: 0 },
                    classroom: { ...classroom },
                    toClassroomId: classroom.toClassroom.id,
                  },
                ]
              }
              const studentsList = list.map((st) => ({
                student: { ...st },
                classroom: { ...c },
                toClassroomId: classroom.toClassroom.id,
                isNew: false,
              }))
              return studentsList
            })
          })
        })

        const studentArrays = await Promise.all(promises)
        const flattenedStudents = studentArrays.flat()

        dispatch(
          migrationClassroomActionsCreator.getClassroomStudentsSuccess(
            requestOrigin,
            flattenedStudents
          )
        )
      } catch (error) {
        handleErrorsWithAction(error, GET_CLASSROOM_STUDENTS_FAILURE, dispatch, { requestOrigin })
      }
    },
  getClassroomStudentsSuccess: (
    requestOrigin: ReduxRequestOrigin,
    studentsWithClassroom: StudentWithClassroom[]
  ): GetClassroomStudentsSuccess => ({
    type: GET_CLASSROOM_STUDENTS_SUCCESS,
    payload: { requestOrigin, studentsWithClassroom },
  }),
  setOpenAlertModal: (requestOrigin: ReduxRequestOrigin): SetAlertModalOpenAction => ({
    type: SET_ALERT_MODAL_OPEN,
    payload: { requestOrigin },
  }),
  setCloseAlertModal: (requestOrigin: ReduxRequestOrigin): SetAlertModalCloseAction => ({
    type: SET_ALERT_MODAL_CLOSE,
    payload: { requestOrigin },
  }),
  clearMigrationState: (requestOrigin: ReduxRequestOrigin): ClearMigrationStateAction => ({
    type: CLEAR_MIGRATION_STATE,
    payload: { requestOrigin },
  }),
}

export default migrationClassroomActionsCreator
