import type { Audience } from '@/api/lms/library'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export interface DeleteAudienceModalState {
  isOpenModal: boolean
  audienceToDelete: Audience
  status: REQUEST_STATUS
}

export enum MODAL_TYPES {
  OPEN_MODAL = 'EBADMIN/MANAGEMENT/DELETE_AUDIENCE_MODAL/OPEN_MODAL',
  CLOSE_MODAL = 'EBADMIN/MANAGEMENT/DELETE_AUDIENCE_MODAL/CLOSE_MODAL',
  SET_AUDIENCE_TO_DELETE = 'EBADMIN/MANAGEMENT/DELETE_AUDIENCE_MODAL/SET_AUDIENCE_TO_DELETE',
  DELETE_AUDIENCE_REQUEST = 'EBADMIN/MANAGEMENT/DELETE_AUDIENCE_MODAL/DELETE_AUDIENCE_REQUEST',
  DELETE_AUDIENCE_SUCCESS = 'EBADMIN/MANAGEMENT/DELETE_AUDIENCE_MODAL/DELETE_AUDIENCE_SUCCESS',
  DELETE_AUDIENCE_FAILURE = 'EBADMIN/MANAGEMENT/DELETE_AUDIENCE_MODAL/DELETE_AUDIENCE_FAILURE',
  CLEAN_MODAL = 'EBADMIN/MANAGEMENT/DELETE_AUDIENCE_MODAL/CLEAN_MODAL',
}

// Open modal action.
export type OpenModalAction = {
  type: MODAL_TYPES.OPEN_MODAL
}

// Close modal action.
export type CloseModalAction = {
  type: MODAL_TYPES.CLOSE_MODAL
}

// Set audience to delete action.
export type SetAudienceToDeleteAction = {
  type: MODAL_TYPES.SET_AUDIENCE_TO_DELETE
  payload: {
    audienceToDelete: Audience
  }
}

// Delete audience actions.
export type DeleteAudienceRequestAction = {
  type: MODAL_TYPES.DELETE_AUDIENCE_REQUEST
}

export type DeleteAudienceSuccessAction = {
  type: MODAL_TYPES.DELETE_AUDIENCE_SUCCESS
}

export type DeleteAudienceFailureAction = {
  type: MODAL_TYPES.DELETE_AUDIENCE_FAILURE
}

export type DeleteAudienceAction =
  | DeleteAudienceRequestAction
  | DeleteAudienceSuccessAction
  | DeleteAudienceFailureAction

// Clean modal action.
export type CleanModalAction = {
  type: MODAL_TYPES.CLEAN_MODAL
}

export type DeleteAudienceModalAction =
  | OpenModalAction
  | CloseModalAction
  | SetAudienceToDeleteAction
  | DeleteAudienceAction
  | CleanModalAction
