import type {
  ACTIVITY_KEY_READING,
  ACTIVITY_KEY_ROBOTS_REMOTE,
  ACTIVITY_KEY_QUESTIONS,
  ACTIVITY_KEY_WRITTEN_EXAM,
  ACTIVITY_KEY_AVV,
  ACTIVITY_KEY_ORAL_EXAM,
} from '@/legacy/components/v2/ribbons/types'
import { ACTIVITY_TYPE_QUESTIONS } from '@/legacy/components/v2/ribbons/types'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { Availability } from '@/sections/content-creator/books/types/books'

export const CREATE_PREMIUM_REQUEST = 'CONTENT_CREATOR/PREMIUM_ACTIVITIES/CREATE_PREMIUM_REQUEST'
export const CREATE_PREMIUM_SUCCESS = 'CONTENT_CREATOR/PREMIUM_ACTIVITIES/CREATE_PREMIUM_SUCCESS'
export const CREATE_PREMIUM_FAILURE = 'CONTENT_CREATOR/PREMIUM_ACTIVITIES/CREATE_PREMIUM_FAILURE'

export const EDIT_PREMIUM_REQUEST = 'CONTENT_CREATOR/PREMIUM_ACTIVITIES/EDIT_PREMIUM_REQUEST'
export const EDIT_PREMIUM_SUCCESS = 'CONTENT_CREATOR/PREMIUM_ACTIVITIES/EDIT_PREMIUM_SUCCESS'
export const EDIT_PREMIUM_FAILURE = 'CONTENT_CREATOR/PREMIUM_ACTIVITIES/EDIT_PREMIUM_FAILURE'

export const GET_PREMIUM_ACTIVITY_REQUEST =
  'CONTENT_CREATOR/PREMIUM_ACTIVITIES/GET_PREMIUM_ACTIVITY_REQUEST'
export const GET_PREMIUM_ACTIVITY_SUCCESS =
  'CONTENT_CREATOR/PREMIUM_ACTIVITIES/GET_PREMIUM_ACTIVITY_SUCCESS'
export const GET_PREMIUM_ACTIVITY_FAILURE =
  'CONTENT_CREATOR/PREMIUM_ACTIVITIES/GET_PREMIUM_ACTIVITY_FAILURE'

export const DELETE_PREMIUM_ACTIVITY_REQUEST =
  'CONTENT_CREATOR/PREMIUM_ACTIVITIES/DELETE_PREMIUM_ACTIVITY_REQUEST'
export const DELETE_PREMIUM_ACTIVITY_SUCCESS =
  'CONTENT_CREATOR/PREMIUM_ACTIVITIES/DELETE_PREMIUM_ACTIVITY_SUCCESS'
export const DELETE_PREMIUM_ACTIVITY_FAILURE =
  'CONTENT_CREATOR/PREMIUM_ACTIVITIES/DELETE_PREMIUM_ACTIVITY_FAILURE'

export const GET_PREMIUM_ACTIVITIES_REQUEST =
  'CONTENT_CREATOR/PREMIUM_ACTIVITIES/GET_PREMIUM_ACTIVITIES_REQUEST'
export const GET_PREMIUM_ACTIVITIES_SUCCESS =
  'CONTENT_CREATOR/PREMIUM_ACTIVITIES/GET_PREMIUM_ACTIVITIES_SUCCESS'
export const GET_PREMIUM_ACTIVITIES_FAILURE =
  'CONTENT_CREATOR/PREMIUM_ACTIVITIES/GET_PREMIUM_ACTIVITIES_FAILURE'

export const SET_OPEN = 'CONTENT_CREATOR/PREMIUM_ACTIVITIES/SET_OPEN'

export const SET_CREATE_PREMIUM_MODAL_OPEN =
  'CONTENT_CREATOR_ACTIVITY/SET_CREATE_PREMIUM_MODAL_OPEN'
export const SET_EDIT_PREMIUM_MODAL_OPEN = 'CONTENT_CREATOR_ACTIVITY/SET_EDIT_PREMIUM_MODAL_OPEN'
export const SET_CLONE_PREMIUM_MODAL_OPEN = 'CONTENT_CREATOR_ACTIVITY/SET_CLONE_PREMIUM_MODAL_OPEN'

export const PREMIUM_ACTIVITY_DELETION_MODAL = 'PREMIUM_ACTIVITY_DELETION_MODAL'

export const GET_ALL_TAGS_REQUEST = 'CONTENT_CREATOR/COMMON/GET_ALL_TAGS_REQUEST'
export const GET_ALL_TAGS_SUCCESS = 'CONTENT_CREATOR/COMMON/GET_ALL_TAGS_SUCCESS'
export const GET_ALL_TAGS_FAILURE = 'CONTENT_CREATOR/COMMON/GET_ALL_TAGS_FAILURE'

export const SET_SEARCH = 'CONTENT_CREATOR/PREMIUM_ACTIVITIES/SET_SEARCH'

export type PremiumActivitiesModalType = typeof PREMIUM_ACTIVITY_DELETION_MODAL

export type LibraryResourceTag = {
  id: number
  name: string
}

export type LibraryResourceTagCategory = {
  name: string
  tags: LibraryResourceTag[]
}

export type SelectedLibraryResourceTags = {
  [tagCategoryName: string]: LibraryResourceTag[]
}

export type ActivityType =
  | typeof ACTIVITY_KEY_READING
  | typeof ACTIVITY_KEY_AVV
  | typeof ACTIVITY_KEY_QUESTIONS
  | typeof ACTIVITY_KEY_READING
  | typeof ACTIVITY_KEY_ROBOTS_REMOTE
  | typeof ACTIVITY_KEY_WRITTEN_EXAM
  | typeof ACTIVITY_KEY_ORAL_EXAM

export type PremiumActivity = {
  id: number
  createdAt: string
  activityID: number
  title: string
  description: string
  availability: Availability
  origin: number
  originName: string
  hasDeliverables: boolean
  hasFiles: boolean
  hasQuestions: boolean
  organizationName: string
  tags: LibraryResourceTagCategory[]
  type: ActivityType
}

export interface CreatePremiumActivitySuccess {
  type: typeof CREATE_PREMIUM_SUCCESS
  payload: {
    premiumActivity: PremiumActivity
  }
}
export interface EditPremiumActivitySuccess {
  type: typeof EDIT_PREMIUM_SUCCESS
  payload: {
    premiumActivity: PremiumActivity
  }
}

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    open: boolean
    modalType?: PremiumActivitiesModalType
    selectedActivity?: PremiumActivity
  }
}

export interface DeletePremiumActivityRequest {
  type: typeof DELETE_PREMIUM_ACTIVITY_REQUEST
}

export interface DeletePremiumActivitySuccess {
  type: typeof DELETE_PREMIUM_ACTIVITY_SUCCESS
  payload: {
    activityID: number
  }
}

export interface DeletePremiumActivityFailure {
  type: typeof DELETE_PREMIUM_ACTIVITY_FAILURE
  payload: {
    error: string
  }
}

export interface GetPremiumActivitiesRequest {
  type: typeof GET_PREMIUM_ACTIVITIES_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
    page: number
    rowsPerPage: number
  }
}

export interface GetPremiumActivitiesSuccess {
  type: typeof GET_PREMIUM_ACTIVITIES_SUCCESS
  payload: {
    premiumActivities: PremiumActivity[]
    count: number
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetPremiumActivitiesFailure {
  type: typeof GET_PREMIUM_ACTIVITIES_FAILURE
  payload: {
    error: string
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetAllTagsRequest {
  type: typeof GET_ALL_TAGS_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetAllTagsSuccess {
  type: typeof GET_ALL_TAGS_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    tagCategories: LibraryResourceTagCategory[]
  }
}

export interface GetAllTagsFailure {
  type: typeof GET_ALL_TAGS_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface SetSearch {
  type: typeof SET_SEARCH
  payload: {
    search: string
  }
}

export type PremiumActivitiesState = {
  open: boolean
  modalType: PremiumActivitiesModalType
  selectedActivity?: PremiumActivity | null
  loading: boolean
  error: string
  search: string
}

export type PremiumActivitiesActions =
  | CreatePremiumActivitySuccess
  | DeletePremiumActivityRequest
  | DeletePremiumActivitySuccess
  | DeletePremiumActivityFailure
  | GetPremiumActivitiesRequest
  | GetPremiumActivitiesSuccess
  | GetPremiumActivitiesFailure
  | SetOpen
  | GetAllTagsRequest
  | GetAllTagsSuccess
  | GetAllTagsFailure
  | SetSearch
