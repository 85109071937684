import moment from 'moment'
import {
  ACTIVITY_KEY_AVV,
  ACTIVITY_KEY_ORAL_EXAM,
  ACTIVITY_KEY_QUESTIONS,
  ACTIVITY_KEY_READING,
  ACTIVITY_KEY_WRITTEN_EXAM,
} from '@/legacy/components/v2/ribbons/types'
import { ROBOTS_BOARD_TYPES } from '@aula/config'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import * as QuestionsTypes from '@/sections/editor/types/questions'
import * as ContentCreatorBooksTypes from '@/sections/content-creator/books/types/books'
import * as ContentCreatorPremiumActivitiesTypes from '@/sections/content-creator/premium-activities/types/premium-activities'
import types from '@/sections/teacher/activities/types'

const initialState = {
  id: '',
  editMode: false,
  cloneMode: false,
  premiumCopyMode: false,
  isPremium: false,
  open: false,
  step: 'all-start',
  loading: false,
  loadingClassrooms: false,
  loadingClassroomOptions: false,
  loadingSubjects: false,
  classrooms: [],
  classroomID: '',
  classroom: {},
  subjects: [],
  subjectID: '',
  subject: {},
  academicPeriods: [],
  selectedAcademicPeriod: null,
  subgroups: [],
  selectedSubgroups: [],
  type: ACTIVITY_KEY_READING,
  date: moment(),
  time: moment(),
  deadline: moment(),
  publicationDate: moment(),
  title: '',
  board: 0,
  boardList: [],
  level: ROBOTS_BOARD_TYPES.SENIOR,
  isRemoteSession: false,
  isSimulatorEnabled: false,
  robotsGamesCodeName: '',
  robotsGamesImages: [],
  simpleDescription: '',
  enableChat: false,
  hasDeliverable: false,
  submissionsAfterDeadline: false,
  distributeSchedule: true,
  error: '',
  showEvaluation: true,
  examDuration: 60,
  lastClassroomID: 0,
  availability: ContentCreatorBooksTypes.AVAILABILITY_FREE,
  availableInLibrary: false,
  premiumActivityId: 0,
  books: [],
  selectedBook: ContentCreatorBooksTypes.noBook,
  loadingBooks: false,
  organizationID: 0,
  tagCategories: [],
  selectedTags: {},
  loadingTags: false,
  bookSearch: ContentCreatorBooksTypes.noBook.title,
  classroomSearch: 'No seleccionada',
  recordingEnabled: false,
  recordingDuration: 1,
  disableTypeChange: false,
  educationalInfoOn: false,
  rubricOn: false,
  description: '',
  isZoom: false,
  zoomUrl: '',
  recurrentToggle: false,
  recurrence: 1,
  recurrenceEnd: moment(),
}

const requestDestination = reduxRequestOriginMap.CONTENT_CREATOR_PREMIUM_ACTIVITY_CREATION_MODAL

function root(state = initialState, action) {
  switch (action.type) {
    case types.SET_MODAL_OPEN: {
      const { open, classroomID, date } = action.payload
      if (open) {
        return {
          ...state,
          open,
          date,
          classroomID,
          deadline: date,
          time: moment().add(1, 'hour'),
          editMode: false,
        }
      }
      return initialState
    }
    case types.SET_ACADEMIC_PERIODS: {
      const { academicPeriods } = action.payload
      return {
        ...state,
        academicPeriods,
      }
    }
    case types.SET_SELECTED_PERIOD: {
      const { selectedAcademicPeriod } = action.payload
      return {
        ...state,
        selectedAcademicPeriod,
        classrooms: [],
        classroom: {},
        classroomID: '',
        subjects: [],
        subjectID: '',
        subject: {},
        subgroups: [],
        selectedSubgroups: [],
      }
    }
    case types.SET_EDIT_MODAL_OPEN: {
      const { step, classroom } = action.payload
      return { ...state, step, classroom, classroomID: classroom.id, open: true, editMode: true }
    }
    case types.SET_CLONE_MODAL_OPEN: {
      const { step, classroom } = action.payload
      return {
        ...state,
        step,
        classroom: classroom || state.classroom,
        classroomID: classroom?.id || state.classroomID,
        open: true,
        editMode: false,
        cloneMode: true,
        premiumCopyMode: action.payload.isPremiumCopy,
      }
    }
    case types.SET_STEP:
      return {
        ...state,
        step: action.payload.step,
        disableTypeChange: action.payload.step === 'other-editor' || state.disableTypeChange,
      }
    case types.GET_ACTIVITY_REQUEST:
      return { ...state, loading: true, error: '' }
    case types.GET_ACTIVITY_SUCCESS: {
      const { activity, subgroups } = action.payload
      const {
        id,
        date,
        startDate: startDate,
        endDate: endDate,
        deadline,
        publicationDate: publicationDate,
        chatEnabled: enableChat,
        showEvaluation,
        organization,
      } = activity
      const {
        title,
        description,
        hasDeliverable: hasDeliverable,
        submissionsAfterDeadline: submissionsAfterDeadline,
        premiumCopy: premiumCopy,
      } = activity
      const {
        robotsId: robotsId,
        robotsIsRemote: isRemoteSession,
        robotsSimulatorEnabled: isSimulatorEnabled,
        robotsGamesCodeName: robotsGamesCodeName,
        gameImages: robotsGamesImages,
      } = activity

      const type = activity.type
      const isExor = type === ACTIVITY_KEY_ORAL_EXAM
      const isExes = type === ACTIVITY_KEY_WRITTEN_EXAM
      const isAVV = type === ACTIVITY_KEY_AVV
      const isTypeWithStartEnd = isAVV || isExes || isExor

      const isStartDateValid = moment(startDate).isValid()
      const isEndDateValid = moment(endDate).isValid()
      const isDeadlineDateValid = moment(deadline).isValid()
      const isPublicationDateValid = moment(publicationDate).isValid()

      const isDateAfterToday = moment(date).isAfter()
      const isStartDateAfterToday = moment(startDate).isAfter()
      const isPublicationAfterToday = moment(publicationDate).isAfter()

      const mDate =
        isTypeWithStartEnd && isStartDateValid && isStartDateAfterToday
          ? moment(startDate)
          : isDateAfterToday
          ? moment(date)
          : moment()
      const time =
        isTypeWithStartEnd && isStartDateValid && isStartDateAfterToday
          ? moment(startDate)
          : moment().add(1, 'hour')

      const simpleDescription = isAVV ? description : state.isPremium ? state.simpleDescription : ''
      const recordingEnabled = isAVV ? activity.avv?.record : state.recordingEnabled
      const recordingDuration = isAVV ? activity.avv?.duration : state.recordingDuration
      const level = activity.robotsBoardType ? activity.robotsBoardType : ROBOTS_BOARD_TYPES.SENIOR
      const board = activity.robotsBoardId ? activity.robotsBoardId : 0
      const mDeadline = isDeadlineDateValid ? moment(deadline) : mDate
      const mPublicationDate =
        isPublicationDateValid && isPublicationAfterToday ? moment(publicationDate) : moment()
      const mEndTime = isEndDateValid ? moment(endDate) : moment()

      return {
        ...state,
        enableChat,
        id,
        type,
        date: premiumCopy ? moment() : mDate,
        time: premiumCopy ? moment().add(1, 'hour') : time,
        deadline: premiumCopy ? moment() : mDeadline,
        publicationDate: mPublicationDate,
        loading: false,
        distributeSchedule: true,
        title,
        description,
        simpleDescription,
        hasDeliverable,
        submissionsAfterDeadline,
        recordingEnabled,
        recordingDuration,
        robotsId,
        isRemoteSession,
        isSimulatorEnabled,
        robotsGamesCodeName,
        robotsGamesImages,
        level,
        board,
        educationalInfoOn: Boolean(activity.educationalInfo),
        isZoom: !!activity.isZoom,
        zoomUrl: activity.isZoom ? activity.videocallUrl : initialState.zoomUrl,
        ...((isExor || isExes) && {
          examDuration: mEndTime.diff(mDate, 'minutes') > 0 ? mEndTime.diff(mDate, 'minutes') : 60,
        }),
        showEvaluation: showEvaluation,
        classroomID: activity.classroomId,
        classroom: {
          ...activity.classroom,
          organization,
        },
        selectedSubgroups: subgroups ? subgroups : state.selectedSubgroups,
      }
    }
    case types.GET_ACTIVITY_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case types.GET_CLASSROOMS_REQUEST:
      return {
        ...state,
        loadingClassrooms: !action.payload.optionsLoading,
        loadingClassroomOptions: action.payload.optionsLoading,
        error: '',
        lastClassroomID: action.payload.lastSearchID,
      }
    case types.GET_CLASSROOMS_SUCCESS: {
      const { classrooms, selectedClassroom, optionsLoading } = action.payload
      return {
        ...state,
        loadingClassrooms: false,
        loadingClassroomOptions: false,
        classrooms,
        ...(!optionsLoading
          ? {
              classroomID: selectedClassroom?.id || state.classroomID,
              classroom: selectedClassroom || state.classroom,
            }
          : {}),
      }
    }
    case types.GET_CLASSROOMS_FAILURE:
      return {
        ...state,
        loadingClassrooms: false,
        loadingClassroomOptions: false,
        error: action.payload.error,
      }
    case types.GET_SUBJECTS_REQUEST:
      return { ...state, loadingSubjects: true, error: '' }
    case types.GET_SUBJECTS_SUCCESS: {
      const { subjects, selectedSubject } = action.payload
      const subjectID = selectedSubject.id
      return {
        ...state,
        loadingSubjects: false,
        subjects,
        subjectID,
        subject: selectedSubject,
      }
    }
    case types.GET_SUBJECTS_FAILURE:
      return { ...state, loadingSubjects: false, error: action.payload.error }

    case types.GET_CLASSROOM_SUBGROUPS_REQUEST:
      return { ...state }
    case types.GET_CLASSROOM_SUBGROUPS_SUCCESS: {
      const { subgroups } = action.payload
      const newSelectedSubgroups =
        state.selectedSubgroups.filter(
          (ss) => ss.id === subgroups.find((s) => s.id === ss.id).id
        ) || []
      return {
        ...state,
        subgroups,
        selectedSubgroups: newSelectedSubgroups,
      }
    }
    case types.GET_CLASSROOM_SUBGROUPS_FAILURE:
      return { ...state, error: action.payload.error }

    case types.SELECT_CLASSROOM:
      return {
        ...state,
        classroomID: action.payload.classroomID,
        classroom: state.classrooms.find((s) => s.id === action.payload.classroomID) || {},
        subject: {},
        subjectID: '',
        subjects: [],
        subgroups: [],
        selectedSubgroups:
          action.payload.classroomID == state.classroomID && state.cloneMode
            ? state.selectedSubgroups
            : [],
      }
    case types.SELECT_SUBJECT:
      return {
        ...state,
        subjectID: action.payload.subjectID,
        subject: state.subjects.find((s) => s.id === action.payload.subjectID) || {},
      }
    case types.SELECT_SUBGROUPS:
      return {
        ...state,
        selectedSubgroups: action.payload.selectedSubgroups,
      }
    case types.SELECT_TYPE:
      return { ...state, type: action.payload.type }
    case types.SET_DATE:
      return { ...state, date: action.payload.date }
    case types.SET_TIME:
      return { ...state, time: action.payload.time }
    case types.SET_DEADLINE:
      return { ...state, deadline: action.payload.deadline }
    case types.SET_TITLE:
      return { ...state, title: action.payload.title }
    case types.SET_IS_ZOOM:
      return { ...state, isZoom: action.payload.isZoom }
    case types.SET_ZOOM_URL:
      return { ...state, zoomUrl: action.payload.zoomUrl }
    case types.GET_BOARDS:
      return {
        ...state,
        board: typeof action.payload.board !== 'undefined' ? action.payload.board : state.board,
        boardList: action.payload.boards,
      }
    case types.SET_BOARD:
      return { ...state, board: action.payload.board }
    case types.SET_LEVEL:
      return { ...state, level: action.payload.level }
    case types.SET_IS_SIMULATOR_ENABLED:
      return { ...state, isSimulatorEnabled: action.payload.isSimulatorEnabled }
    case types.SET_IS_REMOTE_SESSION:
      return { ...state, isRemoteSession: action.payload.isRemoteSession }
    case types.SET_REMOTE_SESSION_DATE:
      return { ...state, remoteSessionDate: action.payload.remoteSessionDate }
    case types.SET_REMOTE_SESSION_TIME:
      return { ...state, remoteSessionTime: action.payload.remoteSessionTime }
    case types.SET_DESCRIPTION:
      return { ...state, description: action.payload.description }
    case types.SET_SIMPLE_DESCRIPTION:
      return { ...state, simpleDescription: action.payload.description }
    case types.SET_ENABLE_CHAT:
      return { ...state, enableChat: action.payload.enableChat }
    case types.SET_PUBLICATION_DATE:
      return { ...state, publicationDate: action.payload.publicationDate }
    case types.SET_HAS_DELIVERABLE:
      return { ...state, hasDeliverable: action.payload.hasDeliverable }
    case types.SET_DISTRIBUTE_SCHEDULE:
      return { ...state, distributeSchedule: action.payload.distributeSchedule }
    case types.SET_SUBMISSIONS_AFTER_DEADLINE:
      return { ...state, submissionsAfterDeadline: action.payload.submissionsAfterDeadline }
    case types.SET_EXAM_DURATION:
      return { ...state, examDuration: action.payload.examDuration }
    case types.SET_AVAILABILITY:
      return { ...state, availability: action.payload.availability }
    case types.SET_AVAILABLE_IN_LIBRARY:
      return { ...state, availableInLibrary: action.payload.availability }
    case types.SET_RECORDING_ENABLED:
      return { ...state, recordingEnabled: action.payload.recordingEnabled }
    case types.SET_RECORDING_DURATION:
      return { ...state, recordingDuration: action.payload.recordingDuration }
    case types.SET_EDUCATIONAL_INFO_ON:
      return { ...state, educationalInfoOn: action.payload.educationalInfoOn }
    case types.SET_RUBRIC_ON:
      return { ...state, rubricOn: action.payload.rubricOn }
    case types.SET_BOOK_SEARCH: {
      return { ...state, bookSearch: action.payload.search }
    }
    case types.SET_CLASSROOM_SEARCH: {
      return { ...state, classroomSearch: action.payload }
    }
    case types.SET_SHOW_EVALUATION: {
      return { ...state, showEvaluation: action.payload.showEvaluation }
    }
    case types.CREATE_AVV_REQUEST:
    case types.EDIT_AVV_REQUEST:
    case types.CREATE_OTHER_REQUEST:
    case types.CREATE_ROBOTS_REMOTE_REQUEST:
    case types.EDIT_ROBOTS_REMOTE_REQUEST:
    case types.EDIT_OTHER_REQUEST:
      return { ...state, loading: true, error: '' }
    case types.CREATE_AVV_SUCCESS:
    case types.EDIT_AVV_SUCCESS:
    case types.CREATE_ROBOTS_REMOTE_SUCCESS:
    case types.EDIT_ROBOTS_REMOTE_SUCCESS:
      return state.isPremium ? state : initialState
    case types.EDIT_OTHER_SUCCESS:
    case types.CREATE_OTHER_SUCCESS:
      return action.payload.isPremium ? state : initialState
    case types.CREATE_AVV_FAILURE:
    case types.EDIT_AVV_FAILURE:
    case types.CREATE_OTHER_FAILURE:
    case types.EDIT_OTHER_FAILURE:
    case types.CREATE_ROBOTS_REMOTE_FAILURE:
    case types.EDIT_ROBOTS_REMOTE_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    /** CONTENT CREATOR ACTIONS **/
    case types.SET_SELECTED_BOOK: {
      return {
        ...state,
        selectedBook: action.payload.selectedBook,
        bookSearch: action.payload.selectedBook.title,
      }
    }
    case types.SET_ORGANIZATION_ID: {
      return {
        ...state,
        organizationID: action.payload.organizationID,
        selectedBook: ContentCreatorBooksTypes.noBook,
      }
    }
    case types.SET_TAGS: {
      const { tagCategory, selectedTags } = action.payload
      return {
        ...state,
        selectedTags: {
          ...state.selectedTags,
          [tagCategory]: selectedTags,
        },
      }
    }
    case ContentCreatorPremiumActivitiesTypes.SET_CREATE_PREMIUM_MODAL_OPEN: {
      const { open, organizationID } = action.payload
      if (open) {
        return {
          ...state,
          open,
          editMode: false,
          isPremium: true,
          type: ACTIVITY_KEY_READING,
          organizationID,
        }
      }
      return initialState
    }
    case ContentCreatorPremiumActivitiesTypes.CREATE_PREMIUM_REQUEST: {
      return { ...state, loading: true, error: '' }
    }
    case ContentCreatorPremiumActivitiesTypes.CREATE_PREMIUM_SUCCESS:
      return initialState
    case ContentCreatorPremiumActivitiesTypes.CREATE_PREMIUM_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case ContentCreatorPremiumActivitiesTypes.GET_PREMIUM_ACTIVITY_REQUEST: {
      return { ...state, loading: true, error: '' }
    }
    case ContentCreatorPremiumActivitiesTypes.GET_PREMIUM_ACTIVITY_SUCCESS: {
      const { premiumActivity, selectedBook } = action.payload
      return {
        ...state,
        loading: false,
        simpleDescription: premiumActivity.description,
        availability: premiumActivity.availability,
        availableInLibrary: premiumActivity.availableInLibrary,
        premiumActivityID: premiumActivity.id,
        selectedBook,
        bookSearch: selectedBook.title,
      }
    }
    case ContentCreatorBooksTypes.GET_ORGANIZATION_BOOKS_REQUEST: {
      if (action.payload.requestOrigin === requestDestination) {
        return { ...state, loadingBooks: true }
      } else {
        return state
      }
    }
    case ContentCreatorBooksTypes.GET_ORGANIZATION_BOOKS_SUCCESS: {
      const { requestOrigin, books } = action.payload
      if (requestOrigin === requestDestination) {
        return { ...state, loadingBooks: false, error: '', books }
      } else {
        return state
      }
    }
    case ContentCreatorBooksTypes.GET_ORGANIZATION_BOOKS_FAILURE: {
      if (action.payload.requestOrigin === requestDestination) {
        return { ...state, loadingBooks: false, error: '' }
      } else {
        return state
      }
    }
    case ContentCreatorPremiumActivitiesTypes.SET_EDIT_PREMIUM_MODAL_OPEN: {
      const { step, organizationID } = action.payload

      return { ...state, isPremium: true, step, open: true, editMode: true, organizationID }
    }
    case ContentCreatorPremiumActivitiesTypes.SET_CLONE_PREMIUM_MODAL_OPEN: {
      const { step, organizationID } = action.payload

      return {
        ...state,
        isPremium: true,
        step,
        open: true,
        editMode: false,
        cloneMode: true,
        organizationID,
      }
    }
    case ContentCreatorPremiumActivitiesTypes.EDIT_PREMIUM_REQUEST: {
      return { ...state, loading: true, error: '' }
    }
    case ContentCreatorPremiumActivitiesTypes.EDIT_PREMIUM_SUCCESS:
      return initialState
    case ContentCreatorPremiumActivitiesTypes.EDIT_PREMIUM_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case ContentCreatorPremiumActivitiesTypes.GET_ALL_TAGS_REQUEST:
      if (action.payload.requestOrigin === requestDestination) {
        return { ...state, loadingTags: true }
      } else {
        return state
      }
    case ContentCreatorPremiumActivitiesTypes.GET_ALL_TAGS_SUCCESS:
      if (action.payload.requestOrigin === requestDestination) {
        return { ...state, loadingTags: false, tagCategories: action.payload.tagCategories }
      } else {
        return state
      }
    case ContentCreatorPremiumActivitiesTypes.GET_ALL_TAGS_FAILURE:
      if (action.payload.requestOrigin === requestDestination) {
        return { ...state, loadingTags: false, error: action.payload.error }
      } else {
        return state
      }
    case QuestionsTypes.ADD_QUESTION:
      if (state.type === 'reading') {
        return { ...state, type: ACTIVITY_KEY_QUESTIONS }
      } else {
        return { ...state }
      }
    case types.SET_RECURRENT_TOGGLE: {
      return {
        ...state,
        recurrentToggle: !state.recurrentToggle,
      }
    }
    case types.SET_RECURRENCE: {
      return {
        ...state,
        recurrence: action.payload.recurrence,
      }
    }
    case types.SET_RECURRENCE_END: {
      return {
        ...state,
        recurrenceEnd: action.payload.dateRecurrenceEnd,
      }
    }
    default:
      return state
  }
}

export default root
