import type { ReduxRequestOrigin } from '@/legacy/redux/constants'

export const SET_INCLUDE_DURATION = 'CONTENT_CREATOR/CERTIFICATE/SET_INCLUDE_DURATION'
export const SET_INCLUDE_PLACE = 'CONTENT_CREATOR/CERTIFICATE/SET_INCLUDE_PLACE'
export const SET_INCLUDE_DATE = 'CONTENT_CREATOR/CERTIFICATE/SET_INCLUDE_DATE'
export const SET_OPTIONAL_TEXT = 'CONTENT_CREATOR/CERTIFICATE/SET_OPTIONAL_TEXT'

export const SET_ORGANIZATION = 'CONTENT_CREATOR/CERTIFICATE/SET_ORGANIZATION'
export const ADD_ORGANIZATION = 'CONTENT_CREATOR/CERTIFICATE/ADD_ORGANIZATION'
export const DELETE_ORGANIZATION = 'CONTENT_CREATOR/CERTIFICATE/DELETE_ORGANIZATION'
export const SET_CLOSE_MODAL_SIGNATURE = 'CONTENT_CREATOR/CERTIFICATE/SET_CLOSE_MODAL_SIGNATURE'
export const SELECT_ORGANIZATION = 'CONTENT_CREATOR/CERTIFICATE/SELECT_ORGANIZATION'

export const SET_OPEN_MODAL_CERTIFICATE = 'CONTENT_CREATOR/COURSES/SET_OPEN_MODAL_CERTIFICATE'
export const SET_CLOSE_CERTIFICATE = 'CONTENT_CREATOR/COURSES/SET_CLOSE_CERTIFICATE'

export const CLEAR_CERTIFICATE_SUCCESS = 'CONTENT_CREATOR/CERTIFICATE/CLEAR_CERTIFICATE_SUCCESS'
export const EDIT_CERTIFICATE_SUCCESS = 'CONTENT_CREATOR/CERTIFICATE/EDIT_CERTIFICATE_SUCCESS'

export const SET_CERTIFICATE = 'CONTENT_CREATOR/CERTIFICATE/SET_CERTIFICATE'
export const ADD_ORG_LOGO_REQUEST = 'CONTENT_CREATOR/CERTIFICATE/ADD_ORG_LOGO_REQUEST'
export const ADD_ORG_LOGO_SUCCESS = 'CONTENT_CREATOR/CERTIFICATE/ADD_ORG_LOGO_SUCCESS'
export const ADD_ORG_LOGO_FAILURE = 'CONTENT_CREATOR/CERTIFICATE/ADD_ORG_LOGO_FAILURE'
export const REMOVE_LOGO = 'CONTENT_CREATOR/CERTIFICATE/REMOVE_LOGO'

export const SET_POSITION = 'CONTENT_CREATOR/CERTIFICATE/SET_POSITION'
export const SET_CLARIFICATION = 'CONTENT_CREATOR/CERTIFICATE/SET_CLARIFICATION'

export const UPLOAD_SIGNATURE_REQUEST = 'CONTENT_CREATOR/CERTIFICATE/UPLOAD_SIGNATURE_REQUEST'
export const UPLOAD_SIGNATURE_SUCCESS = 'CONTENT_CREATOR/CERTIFICATE/UPLOAD_SIGNATURE_SUCCESS'
export const UPLOAD_SIGNATURE_FAILURE = 'CONTENT_CREATOR/CERTIFICATE/UPLOAD_SIGNATURE_FAILURE'
export const CLEAR_SIGNATURE_IMAGE = 'CONTENT_CREATOR/CERTIFICATE/CLEAR_SIGNATURE_IMAGE'

export const SELECT_SIGNATURE = 'CONTENT_CREATOR/CERTIFICATE/SELECT_SIGNATURE'
export const ADD_SIGNATURE = 'CONTENT_CREATOR/CERTIFICATE/ADD_SIGNATURE'
export const REMOVE_SIGNATURE = 'CONTENT_CREATOR/CERTIFICATE/REMOVE_SIGNATURE'

export const LIST_SIGNATURES_REQUEST = 'CONTENT_CREATOR/CERTIFICATE/LIST_SIGNATURES_REQUEST'
export const LIST_SIGNATURES_SUCCESS = 'CONTENT_CREATOR/CERTIFICATE/LIST_SIGNATURES_SUCCESS'
export const LIST_SIGNATURES_FAILURE = 'CONTENT_CREATOR/CERTIFICATE/LIST_SIGNATURES_FAILURE'

export const GET_SIGNATURE_REQUEST = 'CONTENT_CREATOR/CERTIFICATE/GET_SIGNATURE_REQUEST'
export const GET_SIGNATURE_SUCCESS = 'CONTENT_CREATOR/CERTIFICATE/GET_SIGNATURE_SUCCESS'
export const GET_SIGNATURE_FAILURE = 'CONTENT_CREATOR/CERTIFICATE/GET_SIGNATURE_FAILURE'

export const CREATE_SIGNATURE_REQUEST = 'CONTENT_CREATOR/CERTIFICATE/CREATE_SIGNATURE_REQUEST'
export const CREATE_SIGNATURE_SUCCESS = 'CONTENT_CREATOR/CERTIFICATE/CREATE_SIGNATURE_SUCCESS'
export const CREATE_SIGNATURE_FAILURE = 'CONTENT_CREATOR/CERTIFICATE/CREATE_SIGNATURE_FAILURE'

export const SET_SIGNATURE_MODAL_OPEN = 'CONTENT_CREATOR/CERTIFICATE/SET_SIGNATURE_MODAL_OPEN'

export const ADD_CUSTOM_BACKGROUND_REQUEST =
  'CONTENT_CREATOR/CERTIFICATE/ADD_CUSTOM_BACKGROUND_REQUEST'
export const ADD_CUSTOM_BACKGROUND_SUCCESS =
  'CONTENT_CREATOR/CERTIFICATE/ADD_CUSTOM_BACKGROUND_SUCCESS'
export const ADD_CUSTOM_BACKGROUND_FAILURE =
  'CONTENT_CREATOR/CERTIFICATE/ADD_CUSTOM_BACKGROUND_FAILURE'

export const REMOVE_CUSTOM_BACKGROUND = 'CONTENT_CREATOR/CERTIFICATE/REMOVE_CUSTOM_BACKGROUND'

export interface CertificateOrganization {
  name: string
  logo: string
}

export interface BaseSignature {
  organizationId: number
  position: string
  clarification: string
  signatureUrl: string
}

export interface ExistingSignature extends BaseSignature {
  id: number
  createdAt: string
}
export interface CourseCertificate {
  includeDuration: boolean
  includePlace: boolean
  includeDate: boolean
  text: string
  organizations: CertificateOrganization[]
  // position: string // DEPRECATED
  // clarification: string // DEPRECATED
  // signatureUrl: string // DEPRECATED
  signatures: ExistingSignature[]
  background: string
}

export type CourseCertificateState = {
  certificate: CourseCertificate
  error: string
  logosBeingUploaded: number[]
  loadingSignature: boolean
  openSignature: BaseSignature
  signatureList: ExistingSignature[]
  signatureModalOpen: boolean
  signatureModalOpenFromIndex: number | null
  loadingBackground
}

export interface SetCertificate {
  type: typeof SET_CERTIFICATE
  payload: {
    requestOrigin: ReduxRequestOrigin
    certificate: CourseCertificate
    overwriteEverything: boolean
  }
}

export interface AddOrgLogoRequest {
  type: typeof ADD_ORG_LOGO_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
    idx: number
  }
}
export interface AddOrgLogoSuccess {
  type: typeof ADD_ORG_LOGO_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    url: string
    idx: number
  }
}
export interface AddOrgLogoFailure {
  type: typeof ADD_ORG_LOGO_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
    idx: number
  }
}
export interface AddCustomBackgroundRequest {
  type: typeof ADD_CUSTOM_BACKGROUND_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}
export interface AddCustomBackgroundSuccess {
  type: typeof ADD_CUSTOM_BACKGROUND_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    url: string
  }
}
export interface AddCustomBackgroundFailure {
  type: typeof ADD_CUSTOM_BACKGROUND_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}
export interface RemoveCustomBackground {
  type: typeof REMOVE_CUSTOM_BACKGROUND
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}
export interface RemoveLogo {
  type: typeof REMOVE_LOGO
  payload: {
    requestOrigin: ReduxRequestOrigin
    idx: number
  }
}
export interface UploadSignatureRequest {
  type: typeof UPLOAD_SIGNATURE_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}
export interface UploadSignatureSuccess {
  type: typeof UPLOAD_SIGNATURE_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    url: string
  }
}
export interface UploadSignatureFailure {
  type: typeof UPLOAD_SIGNATURE_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface ClearSignatureImage {
  type: typeof CLEAR_SIGNATURE_IMAGE
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface SelectSignature {
  type: typeof SELECT_SIGNATURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    signature: ExistingSignature
    index: number
  }
}

export interface AddSignature {
  type: typeof ADD_SIGNATURE
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface RemoveSignature {
  type: typeof REMOVE_SIGNATURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    index: number
  }
}
export interface SetIncludeDuration {
  type: typeof SET_INCLUDE_DURATION
  payload: {
    requestOrigin: ReduxRequestOrigin
    includeDuration: boolean
  }
}
export interface SetIncludePlace {
  type: typeof SET_INCLUDE_PLACE
  payload: {
    requestOrigin: ReduxRequestOrigin
    includePlace: boolean
  }
}
export interface SetIncludeDate {
  type: typeof SET_INCLUDE_DATE
  payload: {
    requestOrigin: ReduxRequestOrigin
    includeDate: boolean
  }
}
export interface SetOptionalText {
  type: typeof SET_OPTIONAL_TEXT
  payload: {
    requestOrigin: ReduxRequestOrigin
    text: string
  }
}

export interface SetClarification {
  type: typeof SET_CLARIFICATION
  payload: {
    requestOrigin: ReduxRequestOrigin
    clarification: string
  }
}

export interface SetPosition {
  type: typeof SET_POSITION
  payload: {
    requestOrigin: ReduxRequestOrigin
    position: string
  }
}
export interface SetOrganization {
  type: typeof SET_ORGANIZATION
  payload: {
    requestOrigin: ReduxRequestOrigin
    idx: number
    name?: string
    logo?: string
  }
}

export interface AddOrganization {
  type: typeof ADD_ORGANIZATION
  payload: {
    requestOrigin: ReduxRequestOrigin
    name?: string
    logo?: string
  }
}

export interface DeleteOrganization {
  type: typeof DELETE_ORGANIZATION
  payload: {
    requestOrigin: ReduxRequestOrigin
    idx: number
  }
}

export interface ClearCertificateSuccess {
  type: typeof CLEAR_CERTIFICATE_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface EditCertificateSuccess {
  type: typeof EDIT_CERTIFICATE_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface SetCloseCertificate {
  type: typeof SET_CLOSE_CERTIFICATE
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface SetSignatureModalOpen {
  type: typeof SET_SIGNATURE_MODAL_OPEN
  payload: {
    requestOrigin: ReduxRequestOrigin
    open: boolean
    index: number | null
  }
}

export interface ListSignaturesRequest {
  type: typeof LIST_SIGNATURES_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface ListSignaturesSuccess {
  type: typeof LIST_SIGNATURES_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    signatures: ExistingSignature[]
  }
}

export interface ListSignaturesFailure {
  type: typeof LIST_SIGNATURES_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface GetSignatureRequest {
  type: typeof GET_SIGNATURE_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetSignatureSuccess {
  type: typeof GET_SIGNATURE_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    signature: ExistingSignature
  }
}

export interface GetSignatureFailure {
  type: typeof GET_SIGNATURE_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface CreateSignatureRequest {
  type: typeof CREATE_SIGNATURE_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface CreateSignatureSuccess {
  type: typeof CREATE_SIGNATURE_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    signature: ExistingSignature
  }
}

export interface CreateSignatureFailure {
  type: typeof CREATE_SIGNATURE_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export type ContentCreatorModalAction =
  | SetCertificate
  | SetIncludeDuration
  | SetIncludePlace
  | SetIncludeDate
  | SetOptionalText
  | SetOrganization
  | AddOrganization
  | DeleteOrganization
  | AddOrgLogoRequest
  | AddOrgLogoSuccess
  | AddOrgLogoFailure
  | AddCustomBackgroundRequest
  | AddCustomBackgroundSuccess
  | AddCustomBackgroundFailure
  | RemoveLogo
  | ClearCertificateSuccess
  | EditCertificateSuccess
  | SetCloseCertificate
  | SetClarification
  | SetPosition
  | UploadSignatureRequest
  | UploadSignatureSuccess
  | UploadSignatureFailure
  | SelectSignature
  | AddSignature
  | RemoveSignature
  | SetSignatureModalOpen
  | ListSignaturesRequest
  | ListSignaturesSuccess
  | ListSignaturesFailure
  | GetSignatureRequest
  | GetSignatureSuccess
  | GetSignatureFailure
  | CreateSignatureRequest
  | CreateSignatureSuccess
  | CreateSignatureFailure
  | ClearSignatureImage
  | RemoveCustomBackground
