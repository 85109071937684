export const LA_RIOJA_PALETTE = {
  primary: {
    light: '#823CA9',
    main: '#642F81',
    dark: '#823CA9',
    contrastText: '#FFF',
  },
  secondary: {
    light: '#2E9BDC',
    main: '#642F81',
    dark: '#2987BF',
  },
}
