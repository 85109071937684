import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import {
  ASSIGN_USER_FAILURE,
  ASSIGN_USER_REQUEST,
  ASSIGN_USER_SUCCESS,
  EDIT_USER_ASSIGNMENT_LEVELS_FAILURE,
  EDIT_USER_ASSIGNMENT_LEVELS_REQUEST,
  EDIT_USER_ASSIGNMENT_LEVELS_SUCCESS,
  UNASSIGN_USER_FROM_ORGANIZATION_FAILURE,
  UNASSIGN_USER_FROM_ORGANIZATION_REQUEST,
  UNASSIGN_USER_FROM_ORGANIZATION_SUCCESS,
} from '@/legacy/redux/types/managementUsers'
import { checkRequestOrigin, setGenericField, toggleEntity } from '@/legacy/redux/utils'
import type {
  AssignmentsInputsAction,
  AssignmentsInputState,
} from '@/legacy/components/v3/inputs/assignments/types/assignmentsInput'
import {
  AssignmentMethods,
  CLEAR_ORGANIZATION_SUGGESTIONS,
  GET_ORGANIZATION_SUGGESTIONS_FAILURE,
  GET_ORGANIZATION_SUGGESTIONS_REQUEST,
  GET_ORGANIZATION_SUGGESTIONS_SUCCESS,
  GET_PROFILES_FAILURE,
  GET_PROFILES_REQUEST,
  GET_PROFILES_SUCCESS,
  OPEN_AND_POPULATE_MODAL_FAILURE,
  OPEN_AND_POPULATE_MODAL_REQUEST,
  OPEN_AND_POPULATE_MODAL_SUCCESS,
  PRELOAD_ORG_SUGGESTION_FAILURE,
  PRELOAD_ORG_SUGGESTION_REQUEST,
  PRELOAD_ORG_SUGGESTION_SUCCESS,
  REMOVE_ROLE,
  SELECT_CLASSROOM,
  SELECT_ORGANIZATION_SUGGESTION,
  SELECT_PROFILE,
  SELECT_ROLE,
  SET_IS_EDIT_MODE,
  SET_SELECTED_STAGES,
  SET_STAGES,
  TOGGLE_MODAL_OPEN,
  TOGGLE_STAGE,
  SET_SELECTED_ACADEMIC_PERIOD,
} from '@/legacy/components/v3/inputs/assignments/types/assignmentsInput'

export const roleOptions = [
  { id: 1, label: 'student' },
  { id: 2, label: 'teacher' },
  { id: 3, label: 'principal' },
  { id: 4, label: 'content-creator' },
  { id: 5, label: 'parent' },
] // HARDCODED:

const selectInitialState = { id: 0, label: '' }
const selectedClassroomInitialState = { id: 0, label: '', stage: '', academicPeriod: 0 }
export const initialSelectedProfile = { id: 0, name: '', role: '', organizationId: 0 }

const initialState: AssignmentsInputState = {
  open: false,
  loading: false,
  isEditMode: false,
  error: '',
  roleOptions,
  selectedRole: selectInitialState,
  organizationInput: '',
  organization: {
    label: '',
    suggestions: [],
    loading: false,
    error: '',
    id: 0,
  },
  selectedAssignmentMethod: AssignmentMethods.CLASSROOM,
  stages: [],
  selectedStages: [],
  selectedClassroom: selectedClassroomInitialState,
  subjects: [],
  profiles: [],
  selectedProfile: initialSelectedProfile,
  selectedAcademicPeriod: {
    id: 0,
    name: '',
  },
}

const requestDestination = reduxRequestOriginMap.ASSIGNMENTS_INPUT

const AssignmentsInputReducer: Reducer<AssignmentsInputState, AssignmentsInputsAction> = (
  state = initialState,
  action
): AssignmentsInputState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case TOGGLE_MODAL_OPEN: {
      if (state.open) {
        return initialState
      } else {
        return { ...state, open: !state.open }
      }
    }
    case TOGGLE_STAGE: {
      const { stage } = action.payload
      const stagesAfterToggle = toggleEntity(state.selectedStages, stage, 'id')

      return {
        ...state,
        selectedStages: stagesAfterToggle,
      }
    }
    case SELECT_ROLE:
      return {
        ...state,
        // @ts-ignore
        selectedRole: roleOptions.find((opt) => {
          return opt.id === action.payload.roleId
        }),
      }
    case GET_ORGANIZATION_SUGGESTIONS_REQUEST:
      return {
        ...state,
        organization: {
          ...state.organization,
          loading: true,
        },
      }
    case GET_ORGANIZATION_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        organization: {
          ...state.organization,
          loading: false,
          suggestions: action.payload.suggestions,
        },
      }
    case GET_ORGANIZATION_SUGGESTIONS_FAILURE:
      return {
        ...state,
        organization: {
          ...state.organization,
          loading: false,
          error: action.payload.error,
        },
      }
    case SELECT_ORGANIZATION_SUGGESTION: {
      const isTheSameOrg = state.organization.id === action.payload.suggestion.id

      return {
        ...state,
        organization: {
          ...state.organization,
          label: action.payload.suggestion.label,
          id: action.payload.suggestion.id || 0, // FIX: this happens because id on type Suggestion is optional
          suggestions: [],
          academicPeriods: action.payload.suggestion.academicPeriods,
        },
        organizationInput: action.payload.suggestion.label,
        stages: action.payload.suggestion.stages,
        selectedStages: isTheSameOrg ? state.selectedStages : [],
        subjects: isTheSameOrg ? state.subjects : [],
      }
    }
    case SET_SELECTED_STAGES: {
      return {
        ...state,
        selectedStages: action.payload.selectedStages,
      }
    }
    case REMOVE_ROLE: {
      return {
        ...state,
        roleOptions: state.roleOptions.filter((opt) => opt.id !== action.payload.role.id),
      }
    }
    case CLEAR_ORGANIZATION_SUGGESTIONS:
      return {
        ...state,
        organization: {
          ...state.organization,
          suggestions: [],
        },
      }
    case SELECT_CLASSROOM: {
      const isTheSameClassroom = state.selectedClassroom.id === action.payload.classroom.id

      return isTheSameClassroom
        ? state
        : {
            ...state,
            selectedClassroom: {
              ...action.payload.classroom,
              stage: '',
              academicPeriod: 0,
            },
            subjects: [],
          }
    }
    case ASSIGN_USER_REQUEST:
    case UNASSIGN_USER_FROM_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ASSIGN_USER_SUCCESS:
    case UNASSIGN_USER_FROM_ORGANIZATION_SUCCESS:
      return initialState
    case ASSIGN_USER_FAILURE:
    case UNASSIGN_USER_FROM_ORGANIZATION_FAILURE:
    case OPEN_AND_POPULATE_MODAL_FAILURE:
    case GET_PROFILES_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }

    case OPEN_AND_POPULATE_MODAL_REQUEST:
    case GET_PROFILES_REQUEST:
      return { ...state, open: true, loading: true, error: '' }
    case OPEN_AND_POPULATE_MODAL_SUCCESS:
      return {
        ...state,
        loading: false,
        subjects: action.payload.subjects,
        selectedAssignmentMethod:
          action.payload.subjects.length > 0
            ? AssignmentMethods.SUBJECTS
            : AssignmentMethods.CLASSROOM,
        selectedRole:
          roleOptions.find((ro) => {
            return ro.label === action.payload.selectedRole
          }) || roleOptions[0],
      }
    case GET_PROFILES_SUCCESS: {
      const { profiles } = action.payload
      return {
        ...state,
        loading: false,
        profiles,
      }
    }
    case SELECT_PROFILE: {
      const { profile } = action.payload

      return {
        ...state,
        selectedProfile: profile,
      }
    }
    case SET_IS_EDIT_MODE: {
      const { isEditMode } = action.payload
      return {
        ...state,
        isEditMode,
      }
    }
    case SET_STAGES: {
      const { stages } = action.payload
      return {
        ...state,
        stages,
      }
    }
    case PRELOAD_ORG_SUGGESTION_REQUEST:
    case EDIT_USER_ASSIGNMENT_LEVELS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      }
    }
    case EDIT_USER_ASSIGNMENT_LEVELS_SUCCESS: {
      return {
        ...initialState,
      }
    }
    case PRELOAD_ORG_SUGGESTION_SUCCESS: {
      const { profiles } = action.payload

      return {
        ...state,
        loading: false,
        error: '',
        profiles,
      }
    }
    case PRELOAD_ORG_SUGGESTION_FAILURE:
    case EDIT_USER_ASSIGNMENT_LEVELS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    }
    case SET_SELECTED_ACADEMIC_PERIOD: {
      return {
        ...state,
        selectedAcademicPeriod: action.payload.newAcademicPeriod,
      }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, AssignmentsInputReducer, requestDestination)
