// List of all competences keys
// Just add here new ones and the corresponding translations in the 2 objects below
export type Competences = {
  autonomy: string
  communication: string
  creativity: string
  criticalThinking: string
  initiative: string
  problemSolving: string
  teamWork: string
}

// Keep sorted
const enCompetences = {
  autonomy: 'Autonomy',
  communication: 'Communication',
  creativity: 'Creativity',
  criticalThinking: 'Critical thinking',
  initiative: 'Initiative',
  problemSolving: 'Problem solving',
  teamWork: 'Teamwork',
} as const

// Keep sorted
export const esCompetences = {
  autonomy: 'Autonomía',
  communication: 'Comunicación',
  creativity: 'Creatividad',
  criticalThinking: 'Pensamiento crítico',
  initiative: 'Iniciativa y espíritu emprendedor',
  problemSolving: 'Resolución de problemas',
  teamWork: 'Trabajo en equipo y colaboración',
} as const

type ENCompetencesNames = (typeof enCompetences)[keyof Competences]
type ESCompetencesNames = (typeof esCompetences)[keyof Competences]

const competenceNames = {
  en: enCompetences,
  es: esCompetences,
}

// Utilities
export type CompetencesIds = keyof typeof enCompetences
export type CompetencesNames = ENCompetencesNames | ESCompetencesNames

// Array with all competences IDs (we can use ENCompetencesNames or ESCompetencesNames, same thing)
export const competencesIdsArray: Array<keyof Competences> = Object.keys(enCompetences) as Array<
  keyof Competences
>

/**
 * Receives the ID of a competence and returns a user-friendly string in the language of the app
 * @example const name = getCompetenceName("initiative") // Iniciativa y espíritu emprendedor
 * @example const name = getCompetenceName("criticalThinking") // Critical thinking
 */
export function getCompetenceName(locale: string, id: CompetencesIds): CompetencesNames {
  if (locale === 'en') {
    return enCompetences[id]
  } else {
    return esCompetences[id]
  }
}

/**
 * Receives the name of a competence and returns the ID that belongs to it
 * @example const id = getCompetenceId("Iniciativa y espíritu emprendedor") // initiative
 * @example const id = getCompetenceId("Initiative") // initiative
 * @example const id = getCompetenceId("Critical thinking") // criticalThinking
 */
export function getCompetenceId(name: CompetencesNames): CompetencesIds {
  let id = ''
  let indexOfS: number

  const esValues = Object.values(competenceNames['es'])
  const enValues = Object.values(competenceNames['en'])

  for (const value of esValues) {
    if (value === name) {
      indexOfS = esValues.indexOf(name)
      id = Object.keys(competenceNames['es'])[indexOfS]
      break
    }
  }

  if (!id.length) {
    for (const value of enValues) {
      if (value === name) {
        indexOfS = enValues.indexOf(name)
        id = Object.keys(competenceNames['es'])[indexOfS]
        break
      }
    }
  }

  return id as CompetencesIds
}
