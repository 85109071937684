import { MESSAGE_STATUS } from '@/api/bulletin'
import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/student/summary/types/tabs/schoolRecords/schoolRecords'

const initialState: Types.SchoolRecordsTabState = {
  status: REQUEST_STATUS.IDLE,
  error: null,
  messages: [],
  pagination: {
    total: 0,
    offset: 0,
    limit: 10,
    page: 0,
  },
  selectedMessage: {
    id: 0,
    edited: false,
    type: 'other',
    title: '',
    body: '',
    date: '',
    status: MESSAGE_STATUS.READ,
    to: [],
    toList: [],
    attachments: [],
    hasUnreadResponses: false,
    isRichText: false,
    allowsReply: false,
    sendTime: '',
    from: {
      id: 0,
      name: '',
      avatar: '',
      asParent: false,
      children: [],
    },
    elements: [],
    lastActivityAt: '',
    communicationType: 'message',
    isFirst: false,
  },
  currentStep: Types.SCHOOL_RECORDS_STEPS.INITIAL,
}

const schoolRecordsTabReducer = (
  state: Types.SchoolRecordsTabState = initialState,
  action: Types.SchoolRecordsTabAction
): Types.SchoolRecordsTabState => {
  switch (action.type) {
    case Types.SCHOOL_RECORDS_TAB_TYPES.GET_MESSAGES_REQUEST: {
      const { page, offset, limit } = action.payload
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        pagination: {
          ...state.pagination,
          page,
          limit,
          offset,
        },
      }
    }
    case Types.SCHOOL_RECORDS_TAB_TYPES.GET_MESSAGES_SUCCESS: {
      const { messages, total } = action.payload
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        messages,
        pagination: { ...state.pagination, total },
      }
    }
    case Types.SCHOOL_RECORDS_TAB_TYPES.GET_MESSAGES_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        error,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.SCHOOL_RECORDS_TAB_TYPES.SET_SELECTED_MESSAGE: {
      const { message } = action.payload
      return { ...state, selectedMessage: message }
    }
    case Types.SCHOOL_RECORDS_TAB_TYPES.RESET_TAB: {
      return initialState
    }
    case Types.SCHOOL_RECORDS_TAB_TYPES.SET_CURRENT_STEP: {
      const { currentStep } = action.payload
      return {
        ...state,
        currentStep,
      }
    }
    default:
      return state
  }
}

export default schoolRecordsTabReducer
