import * as Classrooms from './classrooms'
import * as Organizations from './organizations'
import * as Submissions from './submissions'
import * as Users from './users'
import * as Relationships from './relationships'
import * as PendingSubjects from './pendingSubjects'

export default {
  Classrooms,
  Organizations,
  Submissions,
  Users,
  Relationships,
  PendingSubjects,
}
