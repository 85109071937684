import type {
  GetActivitySuccess,
  GetQuestionSuccess,
  GoToStats,
  LastQuestion,
  NextQuestion,
  Question,
  SeeAnswerSuccess,
  SetOpen,
  UpdateTimer,
} from '@/sections/quiz/common/types/quiz'
import {
  ACTIVITY_FAILURE,
  ACTIVITY_REQUEST,
  ACTIVITY_SUCCESS,
  ANSWER_FAILURE,
  ANSWER_REQUEST,
  ANSWER_SUCCESS,
  COMPLETE_QUIZ_FAILURE,
  COMPLETE_QUIZ_REQUEST,
  COMPLETE_QUIZ_SUCCESS,
  GO_TO_STATS,
  LAST_QUESTION,
  NEXT_QUESTION,
  QUESTION_FAILURE,
  QUESTION_REQUEST,
  QUESTION_SUCCESS,
  SEE_ANSWER_FAILURE,
  SEE_ANSWER_REQUEST,
  SEE_ANSWER_SUCCESS,
  SET_OPEN,
  SHOW_ONBOARDING,
  SHOW_ONBOARDING_FAILURE,
  UPDATE_TIMER,
} from '@/sections/quiz/common/types/quiz'
import type { AppThunk } from '@/state/thunk'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import messagesActions from '@/sections/header/actions/messages'
import { API } from '@/api/lms'
import activityActions from '@/sections/student/dashboard/actions/activity'
import moment from 'moment'
import dashboardActions from '@/sections/student/dashboard/actions/dashboard'

const actions = {
  getQuizStatus: (activityID: number): AppThunk => {
    return (dispatch) => {
      dispatch({ type: ACTIVITY_REQUEST, payload: { activityID: activityID } })

      return API.Quiz.getOrCreateSubmission(activityID)
        .then((response) => {
          const { lastQuestionAnswered, questionIds, submissionId, submitted, time, title, score } =
            response.data.description
          dispatch(
            actions.getActivitySuccess(
              lastQuestionAnswered,
              questionIds,
              submissionId,
              submitted,
              time,
              title,
              score
            )
          )
        })
        .catch((error) => {
          handleErrorsWithAction(error, ACTIVITY_FAILURE, dispatch)
          dispatch(messagesActions.showToast('quiz.actions.errorGetQuiz', error, { error: true }))
        })
    }
  },
  getActivitySuccess: (
    lastQuestionAnswered: number,
    questionIDs: any,
    submissionID: number,
    submitted: boolean,
    time: number,
    title: string,
    maxScore: number
  ): GetActivitySuccess => ({
    type: ACTIVITY_SUCCESS,
    payload: {
      lastQuestionAnswered,
      questionIDs,
      submissionID,
      submitted,
      time,
      title,
      maxScore,
    },
  }),
  getQuestion: (activityID: number, submissionID: number, questionID: number): AppThunk => {
    return (dispatch) => {
      dispatch({ type: QUESTION_REQUEST })

      return API.Quiz.getQuestion(activityID, submissionID, questionID)
        .then((response) => {
          const { id, options, score, text, time, type, url, body } = response.data.description
          const question: Question = { id, options, score, text, time, type, url, body }
          dispatch(actions.getQuestionSuccess(question))
          return question
        })
        .catch((error) => {
          handleErrorsWithAction(error, QUESTION_FAILURE, dispatch)
          dispatch(
            messagesActions.showToast('quiz.actions.errorGetQuestion', error, { error: true })
          )
          return null
        })
    }
  },
  getQuestionSuccess: (question: Question): GetQuestionSuccess => ({
    type: QUESTION_SUCCESS,
    payload: {
      question,
    },
  }),
  getAnswer: (activityID: number, submissionID: number, questionID: number): AppThunk => {
    return (dispatch) => {
      dispatch({ type: SEE_ANSWER_REQUEST })

      return API.Quiz.getAnswer(activityID, submissionID, questionID)
        .then((response) => {
          const { answer, correctOption, type } = response.data.description
          dispatch(actions.getAnswerSuccess(answer, correctOption, type))
        })
        .catch((error) => {
          handleErrorsWithAction(error, SEE_ANSWER_FAILURE, dispatch)
          dispatch(messagesActions.showToast('quiz.actions.errorGetAnswer', error, { error: true }))
        })
    }
  },
  getAnswerSuccess: (answer: number, correctOption: number, type: string): SeeAnswerSuccess => ({
    type: SEE_ANSWER_SUCCESS,
    payload: { answer, correctOption, type },
  }),
  nextQuestion: (): NextQuestion => ({ type: NEXT_QUESTION }),
  lastQuestion: (): LastQuestion => ({ type: LAST_QUESTION }),
  goToStats: (): GoToStats => ({ type: GO_TO_STATS }),
  setOpen: (open: boolean): SetOpen => ({ type: SET_OPEN, payload: { open: open } }),
  getOnboarding: (): AppThunk => {
    return (dispatch) => {
      return API.Quiz.onboarding()
        .then((response) => {
          const { hasDoneAnyQuiz } = response.data.description

          if (hasDoneAnyQuiz) {
            dispatch({ type: SHOW_ONBOARDING })
          }
        })
        .catch((error) => {
          handleErrorsWithAction(error, SHOW_ONBOARDING_FAILURE, dispatch)
          dispatch(
            messagesActions.showToast('quiz.actions.errorOnboarding', error, { error: true })
          )
        })
    }
  },
  completeQuiz: (activityID: number, submissionID: number): AppThunk => {
    return (dispatch, getState) => {
      dispatch({ type: COMPLETE_QUIZ_REQUEST })
      return API.Quiz.complete(activityID, submissionID)
        .then((response) => {
          const { correct, elapsed, totalScore, wrong } = response.data.description
          dispatch({
            type: COMPLETE_QUIZ_SUCCESS,
            payload: { correct, incorrect: wrong, elapsed, totalScore: totalScore },
          })

          const date = moment().format()
          const { selectedOrganization, selectedAcademicPeriod } = getState().user
          dispatch(
            dashboardActions.getStudentDashboard(
              selectedOrganization.id,
              selectedAcademicPeriod.id,
              date
            )
          )
          dispatch(
            dashboardActions.getDashboardV4(
              selectedOrganization.id,
              selectedAcademicPeriod.id,
              date
            )
          )
          return true
        })
        .catch((error) => {
          handleErrorsWithAction(error, COMPLETE_QUIZ_FAILURE, dispatch)
          dispatch(messagesActions.showToast('quiz.actions.errorFinal', error, { error: true }))
          return false
        })
    }
  },
  chooseAnswer: (
    activityID: number,
    submissionID: number,
    questionID: number,
    optionID: number
  ): AppThunk => {
    return (dispatch) => {
      dispatch({ type: ANSWER_REQUEST })

      return API.Quiz.answer(activityID, submissionID, questionID, optionID)
        .then((response) => {
          const { correct, correctAnswer } = response.data.description

          dispatch({
            type: ANSWER_SUCCESS,
            payload: { correct, correctAnswer: correctAnswer, questionID, optionID },
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, ANSWER_FAILURE, dispatch)
          dispatch(messagesActions.showToast('quiz.actions.errorAnswering', error, { error: true }))
        })
    }
  },
  updateTimer: (): UpdateTimer => ({ type: UPDATE_TIMER }),
}

export default actions
