export const GET_UPCOMING_ACTIVITIES_REQUEST = 'AVV/GET_UPCOMING_ACTIVITIES_REQUEST'
export const GET_UPCOMING_ACTIVITIES_SUCCESS = 'AVV/GET_UPCOMING_ACTIVITIES_SUCCESS'
export const GET_UPCOMING_ACTIVITIES_FAILURE = 'AVV/GET_UPCOMING_ACTIVITIES_FAILURE'

export const REMOVE_EXPIRED_ACTIVITIES = 'AVV/REMOVE_EXPIRED_ACTIVITIES'
export const REMOVE_DELETED_ACTIVITY = 'AVV/REMOVE_DELETED_ACTIVITIES'
export interface Subject {
  color: string
  id: number
  name: string
}

export interface UpcomingActivity {
  chatEnabled: boolean
  classroomId: number
  date: string
  deadline: string
  endDate: string
  evaluationType: number
  id: number
  publicationDate: string
  startDate: string
  subject: Subject
  title: string
  type: string
}

export interface GetUpcomingActivitiesRequest {
  type: typeof GET_UPCOMING_ACTIVITIES_REQUEST
  payload: {
    silentLoading: boolean
  }
}

export interface GetUpcomingActivitiesSuccess {
  type: typeof GET_UPCOMING_ACTIVITIES_SUCCESS
  payload: {
    upcomingActivities: UpcomingActivity[]
  }
}

export interface GetUpcomingActivitiesFailure {
  type: typeof GET_UPCOMING_ACTIVITIES_FAILURE
  payload: {
    error: string
  }
}

export interface RemoveExpiredActivities {
  type: typeof REMOVE_EXPIRED_ACTIVITIES
}

export interface RemoveDeletedActivity {
  type: typeof REMOVE_DELETED_ACTIVITY
  payload: {
    activityId: number
  }
}

export interface AVVState {
  upcomingActivities: UpcomingActivity[]
  loading: boolean
  error: string
}

export type AVVActions =
  | GetUpcomingActivitiesRequest
  | GetUpcomingActivitiesSuccess
  | GetUpcomingActivitiesFailure
  | RemoveExpiredActivities
  | RemoveDeletedActivity
