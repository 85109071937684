import type {
  ChatActions,
  ChatChannel,
  ChatState,
  Destinatary,
} from '@/sections/chat/types/chat'
import {
  ADD_NEW_MESSAGE,
  CHANNEL_BY_ACTIVITY_ID_FAILURE,
  CHANNEL_BY_ACTIVITY_ID_REQUEST,
  CHANNEL_BY_ACTIVITY_ID_SUCCESS,
  CHANNEL_LIST_FAILURE,
  CHANNEL_LIST_REQUEST,
  CHANNEL_LIST_SUCCESS,
  CHANNEL_NOT_FOUND,
  CLEAN_MESSAGE_COUNT,
  MESSAGE_LIST_FAILURE,
  MESSAGE_LIST_REQUEST,
  MESSAGE_LIST_SUCCESS,
  NEW_MESSAGE,
  SEND_MESSAGE,
  SEND_VIDEOCALL_INVITE,
  SET_ACTIVE_CHAT,
  SET_CHAT_TYPE,
  SET_CLOSE,
  SET_NEW_MESSAGE_IN_ACTUAL_CHAT,
  SET_OPEN,
  SHOW_NOT_EXISTING_CHANNEL,
} from '@/sections/chat/types/chat'
import { persistReducer } from 'redux-persist'
import localforage from 'localforage'
import { ChatSwitchKeys } from '@/sections/chat/constants'
import { isClassroomChannel } from '@/sections/chat/utils'
import { mergeDeepLeft } from 'ramda'
import { REQUEST_STATUS } from '@/legacy/redux/types/status'

const initialState: ChatState = {
  open: false,
  type: ChatSwitchKeys.Classroom,
  title: '',
  subtitle: '',
  channels: [],
  statusChannels: REQUEST_STATUS.IDLE,
  statusMessages: REQUEST_STATUS.IDLE,
  selectedChat: {
    id: 0,
    channels: [],
    type: '',
    activityType: '',
    name: '',
    isActive: false,
    unreadMessages: 0,
  },
  selectedChannel: '',
  messages: [],
  message: '',
  newMessageInActualChat: false,
  error: '',
  channelsByKey: {},
}

const findActualChannel = (destinatary: Destinatary, channels: ChatChannel[] = []) =>
  channels.find((channel: ChatChannel) => channel.destinatary === destinatary) || { key: '' }

function root(state = initialState, action: ChatActions): ChatState {
  switch (action.type) {
    case SET_OPEN:
      return { ...state, open: true }
    case SET_CLOSE:
      return { ...state, open: false }
    case SET_CHAT_TYPE: {
      const { type } = action.payload
      const channel = findActualChannel(type, state.selectedChat.channels)
      return { ...state, type, selectedChannel: channel.key }
    }
    case ADD_NEW_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload.message],
        newMessageInActualChat: true,
      }
    case SET_NEW_MESSAGE_IN_ACTUAL_CHAT:
      return { ...state, newMessageInActualChat: action.payload.value }
    case CHANNEL_LIST_REQUEST:
      return {
        ...state,
        statusChannels: REQUEST_STATUS.LOADING,
      }
    case CHANNEL_LIST_SUCCESS:
      return { ...state, channels: action.payload.channels, statusChannels: REQUEST_STATUS.SUCCESS }
    case CHANNEL_LIST_FAILURE:
      return { ...state, error: action.payload.error, statusChannels: REQUEST_STATUS.FAILURE }
    case MESSAGE_LIST_REQUEST:
      return { ...state, statusMessages: REQUEST_STATUS.LOADING }
    case MESSAGE_LIST_SUCCESS:
      return { ...state, messages: action.payload.messages, statusMessages: REQUEST_STATUS.SUCCESS }
    case MESSAGE_LIST_FAILURE:
      return { ...state, error: action.payload.error, statusMessages: REQUEST_STATUS.FAILURE }
    case CHANNEL_BY_ACTIVITY_ID_REQUEST:
      return { ...state, statusChannels: REQUEST_STATUS.LOADING }
    case CHANNEL_BY_ACTIVITY_ID_SUCCESS:
      return { ...state, channels: action.payload.channels, statusChannels: REQUEST_STATUS.SUCCESS }
    case CHANNEL_BY_ACTIVITY_ID_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        statusChannels: REQUEST_STATUS.FAILURE,
        statusMessages: REQUEST_STATUS.FAILURE,
      }
    case SET_ACTIVE_CHAT: {
      const type = state.type
      const { item, channels } = action.payload
      const channel = findActualChannel(type, item.channels)
      return {
        ...state,
        channels,
        ...(!isClassroomChannel(item) && {
          title: item.name,
          subtitle: item.description || '',
          selectedChat: item,
          selectedChannel: channel.key,
        }),
      }
    }
    case SEND_MESSAGE:
      return { ...state, messages: [...state.messages, action.payload.message] }
    case SEND_VIDEOCALL_INVITE:
      return { ...state, messages: [...state.messages, action.payload.message] }
    case SHOW_NOT_EXISTING_CHANNEL: {
      const channelsByKey = { [action.payload.key]: { show: true } }
      return { ...state, channelsByKey: mergeDeepLeft(channelsByKey, state.channelsByKey) }
    }
    case NEW_MESSAGE: {
      const { activityID, key } = action.payload
      const unread = state.channelsByKey[key]?.unread || 0
      const channelsByKey = { [key]: { unread: unread + 1, activityID } }
      return { ...state, channelsByKey: mergeDeepLeft(channelsByKey, state.channelsByKey) }
    }
    case CLEAN_MESSAGE_COUNT: {
      const { key } = action.payload
      const channelsByKey = { [key]: { unread: 0 } }
      return { ...state, channelsByKey: mergeDeepLeft(channelsByKey, state.channelsByKey) }
    }
    case CHANNEL_NOT_FOUND: {
      const { showChat } = action.payload
      return { ...state, open: showChat, loadingMessages: true }
    }
    default:
      return state
  }
}

const persistConfig = {
  key: 'chat',
  storage: localforage,
  whitelist: ['room', 'classroomID', 'activityID', 'activityTitle'],
}

export default persistReducer(persistConfig, root)
