import { combineReducers } from 'redux'
import dashboard from './dashboard/reducer'
import activities from './activities/reducer'
import studentList from './studentList/reducers/studentList'
import students from './students/reducer'
import evaluation from './evaluation/reducer'
import classroom from './classroom/reducer'
import overview from './overview/reducer'
import library from './library/reducer'
import writtenExams from './writtenExams/reducer'
import oralExams from './oralExams/reducer'
import bulletin from './bulletin/reducer'

const rootReducer = combineReducers({
  dashboard,
  activities,
  studentList,
  evaluation,
  classroom,
  overview,
  library,
  students,
  writtenExams,
  oralExams,
  bulletin,
})

export default rootReducer
