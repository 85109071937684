import type {
  QUESTIONS_MODAL_TYPE_QUESTIONS,
  QUESTIONS_MODAL_TYPE_SECTIONS,
  QUESTIONS_MODAL_TYPE_TEXT,
  QUESTIONS_TYPES,
  UNION_ANSWER_LEFT,
  UNION_ANSWER_RIGHT,
} from '@/sections/editor/constants'
import type { ActivitySuccess as WrittenExamActivitySuccess } from '@/sections/student/writtenExam/types/writtenExam'
import { activityRibbonMap } from '@/legacy/components/v2/ribbons/types'
import type { ActivityTypes } from '@/api/lms/activities/activities'

export const SET_OPEN = 'QUESTIONS/OPEN_QUESTIONS_MODAL'
export const SET_EDIT = 'QUESTIONS/OPEN_EDIT_QUESTIONS_MODAL'
export const SET_CLOSE = 'QUESTIONS/CLOSE_QUESTIONS_MODAL'
export const SET_QUESTION_TYPE = 'QUESTIONS/SET_QUESTION_TYPE'
export const SET_QUESTION = 'QUESTIONS/SET_QUESTION'
export const SET_DIVIDER = 'QUESTIONS/SET_DIVIDER'
export const SET_BODY = 'QUESTIONS/SET_BODY'
export const SET_SCORE = 'QUESTIONS/SET_SCORE'
export const SET_TIME = 'QUESTIONS/SET_TIME'
export const SET_TIME_LIMIT_ENABLED = 'QUESTIONS/SET_TIME_LIMIT_ENABLED'
export const EDIT_SCORE = 'QUESTIONS/EDIT_SCORE'
export const SET_MAX_SCORE = 'QUESTIONS/SET_MAX_SCORE'
export const ADD_OPTION = 'QUESTIONS/ADD_OPTION'
export const SET_OPTION = 'QUESTIONS/SET_OPTION'
export const SET_CORRECT_OPTION = 'QUESTIONS/SET_CORRECT_OPTION'
export const SET_QUESTION_ANSWER = 'QUESTIONS/SET_QUESTION_ANSWER'
export const SET_BLANK_ANSWER = 'QUESTIONS/SET_BLANK_ANSWER'
export const SET_UNION_ANSWER = 'QUESTIONS/SET_UNION_ANSWER'
export const REMOVE_OPTION = 'QUESTIONS/REMOVE_OPTION'
export const ADD_QUESTION = 'QUESTIONS/ADD_QUESTION'
export const EDIT_QUESTION = 'QUESTIONS/EDIT_QUESTION'
export const REMOVE_QUESTION = 'QUESTIONS/REMOVE_QUESTION'
export const MOVE_QUESTION_UP = 'QUESTIONS/MOVE_QUESTION_UP'
export const MOVE_QUESTION_DOWN = 'QUESTIONS/MOVE_QUESTION_DOWN'
export const ADD_IMAGE_TO_OPTION_REQUEST = 'QUESTIONS/ADD_IMAGE_TO_OPTION_REQUEST'
export const ADD_IMAGE_TO_OPTION_SUCCESS = 'QUESTIONS/ADD_IMAGE_TO_OPTION_SUCCESS'
export const ADD_IMAGE_TO_OPTION_FAILURE = 'QUESTIONS/ADD_IMAGE_TO_OPTION_FAILURE'
export const REMOVE_IMAGE_FROM_OPTION = 'QUESTIONS/REMOVE_IMAGE_FROM_OPTION'
export const ADD_UNION_OPTION = 'QUESTIONS/ADD_UNION_OPTION'
export const SET_UNION_OPTION = 'QUESTIONS/SET_UNION_OPTION'
export const SET_UNION_MATCH = 'QUESTIONS/SET_UNION_MATCH'
export const REMOVE_UNION_OPTION = 'QUESTIONS/REMOVE_UNION_OPTION'
export const ADD_IMAGE_TO_QUESTION_REQUEST = 'QUESTIONS/ADD_IMAGE_TO_QUESTION_REQUEST'
export const ADD_IMAGE_TO_QUESTION_SUCCESS = 'QUESTIONS/ADD_IMAGE_TO_QUESTION_SUCCESS'
export const ADD_IMAGE_TO_QUESTION_FAILURE = 'QUESTIONS/ADD_IMAGE_TO_QUESTION_FAILURE'
export const REMOVE_IMAGE_FROM_QUESTION = 'QUESTIONS/REMOVE_IMAGE_FROM_QUESTION'
export const SET_QUESTION_IFRAME = 'QUESTIONS/SET_QUESTION_IFRAME'
export const REMOVE_IFRAME_FROM_QUESTION = 'QUESTIONS/REMOVE_IFRAME_FROM_QUESTION'
export const SET_QUESTION_MIN_LENGTH = 'QUESTIONS/SET_QUESTION_MIN_LENGTH'
export const SET_QUESTION_WORD_COUNT = 'QUESTIONS/SET_QUESTION_WORD_COUNT'
export const SET_WORD_COUNT_TOGGLE = 'QUESTIONS/SET_WORD_COUNT_TOGGLE'
export const SET_MIN_LENGTH_TOGGLE = 'QUESTIONS/SET_MIN_LENGTH_TOGGLE'
export const SET_LINE_HEIGHT = 'QUESTIONS/SET_LINE_HEIGHT'

export type UnionColumn = typeof UNION_ANSWER_LEFT | typeof UNION_ANSWER_RIGHT

export type QuestionsModalType =
  | typeof QUESTIONS_MODAL_TYPE_QUESTIONS
  | typeof QUESTIONS_MODAL_TYPE_TEXT
  | typeof QUESTIONS_MODAL_TYPE_SECTIONS

export interface Option {
  text: string
  order: number
  correct?: boolean
  type: string
  url: string | null
  loading: boolean
  column?: UnionColumn
  matchesWith?: number
}

export interface UnionOption {
  column: UnionColumn
  id: number
  matchesWith: number
  text: string
  url: string | null
  order: number
}

export interface ChoiceQuestion {
  type: typeof QUESTIONS_TYPES.CHOICE
  imageLoading: boolean
  url: string | null
  iframeUrl: string
  text: string
  score: number
  order?: number
  time: number
  options: Option[]
  minLength?: number
  maxLength?: number
  wordCount?: number
  divider: boolean
  sectionId: string | null
  id?: string
  unionOptions: {
    [UNION_ANSWER_LEFT]: Option[]
    [UNION_ANSWER_RIGHT]: Option[]
  }
}

export interface SectionQuestion {
  type: typeof QUESTIONS_TYPES.SECTION
  imageLoading: boolean
  url: string | null
  iframeUrl: string
  text: string
  order?: number
  score: number
  options: Option[]
  minLength?: number
  maxLength?: number
  lineHeight?: number
  wordCount?: number
  divider: boolean
  sectionId?: string | null
  id: string
  unionOptions: {
    [UNION_ANSWER_LEFT]: Option[]
    [UNION_ANSWER_RIGHT]: Option[]
  }
}
export interface WritingQuestion {
  type: typeof QUESTIONS_TYPES.WRITING
  imageLoading: boolean
  url: string | null
  iframeUrl: string
  text: string
  order?: number
  score: number
  options: Option[]
  minLength?: number
  maxLength?: number
  lineHeight?: number
  wordCount?: number
  divider: boolean
  sectionId: string | null
  id?: string
  unionOptions: {
    [UNION_ANSWER_LEFT]: Option[]
    [UNION_ANSWER_RIGHT]: Option[]
  }
}

export interface FillInQuestion {
  type: typeof QUESTIONS_TYPES.FILLABLE
  imageLoading: boolean
  minLength?: number
  iframeUrl: string
  maxLength?: number
  url: string | null
  text: string
  body: string
  score: number
  order?: number
  wordCount?: number
  options: Option[]
  divider: boolean
  sectionId: string | null
  id?: string
  unionOptions: {
    [UNION_ANSWER_LEFT]: Option[]
    [UNION_ANSWER_RIGHT]: Option[]
  }
}

export interface UnionQuestion {
  type: typeof QUESTIONS_TYPES.UNION
  imageLoading: boolean
  minLength?: number
  iframeUrl: string
  maxLength?: number
  url: string | null
  text: string
  body: string
  score: number
  order?: number
  wordCount?: number
  options: Option[]
  divider: boolean
  sectionId: string | null
  id?: string
  unionOptions: {
    [UNION_ANSWER_LEFT]: Option[]
    [UNION_ANSWER_RIGHT]: Option[]
  }
}

export type QuestionType =
  | typeof QUESTIONS_TYPES.CHOICE
  | typeof QUESTIONS_TYPES.WRITING
  | typeof QUESTIONS_TYPES.FILLABLE

export type GenericQuestion =
  | WritingQuestion
  | ChoiceQuestion
  | FillInQuestion
  | UnionQuestion
  | SectionQuestion

export interface Matches {
  [UNION_ANSWER_LEFT]: {
    [id: string]: UnionOption
  }
  [UNION_ANSWER_RIGHT]: {
    [id: string]: UnionOption
  }
}
export interface Answer {
  questionId: number
  type: string
  optionId?: number
  answer?: string
  filled?: {
    [number: number]: string
  }
  matches?: Matches
}

export interface QuestionsState {
  answers: {
    [key: string]: Answer
  }
  open: boolean
  modalType: QuestionsModalType
  questionTemplate: GenericQuestion
  questions: GenericQuestion[]
  editingQuestion: number | null
  maxScore: number
  loading: boolean
  error: string
  timeLimitEnabled: boolean
  minLengthToggle: boolean
  wordCountToggle: boolean
}

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    modalType: QuestionsModalType
  }
}

export interface SetEditOpen {
  type: typeof SET_EDIT
  payload: {
    index: number
  }
}

export interface SetClose {
  type: typeof SET_CLOSE
}

export interface SetQuestionType {
  type: typeof SET_QUESTION_TYPE
  payload: {
    type: typeof QUESTIONS_TYPES.WRITING | typeof QUESTIONS_TYPES.CHOICE
  }
}

export interface SetQuestion {
  type: typeof SET_QUESTION
  payload: {
    text: string
  }
}

export interface SetDivider {
  type: typeof SET_DIVIDER
  payload: {
    divider: boolean
  }
}

export interface SetBody {
  type: typeof SET_BODY
  payload: {
    body: string
  }
}

export interface SetQuestionMinLength {
  type: typeof SET_QUESTION_MIN_LENGTH
  payload: {
    minLength: number
  }
}

export interface SetQuestionWordCount {
  type: typeof SET_QUESTION_WORD_COUNT
  payload: {
    wordCount: number
  }
}

export interface SetWordCountToggle {
  type: typeof SET_WORD_COUNT_TOGGLE
}

export interface AddOption {
  type: typeof ADD_OPTION
}

export interface SetOption {
  type: typeof SET_OPTION
  payload: {
    text: string
    index: number
  }
}

export interface SetCorrectQuestion {
  type: typeof SET_CORRECT_OPTION
  payload: {
    index: number
  }
}

export interface SetQuestionAnswer {
  type: typeof SET_QUESTION_ANSWER
  payload: {
    id: number
    type: QuestionType
    answer: string | number
  }
}

export interface RemoveOption {
  type: typeof REMOVE_OPTION
  payload: {
    index: number
  }
}

export interface AddUnionOption {
  type: typeof ADD_UNION_OPTION
  payload: {
    column: UnionColumn
  }
}

export interface SetUnionOption {
  type: typeof SET_UNION_OPTION
  payload: {
    column: UnionColumn
    text: string
    index: number
  }
}

export interface RemoveUnionOption {
  type: typeof REMOVE_UNION_OPTION
  payload: {
    column: UnionColumn
    index: number
  }
}

export interface SetUnionMatch {
  type: typeof SET_UNION_MATCH
  payload: {
    index: number
    match: number
  }
}

export interface AddQuestion {
  type: typeof ADD_QUESTION
  payload: {
    activityType: ActivityTypes
  }
}

export interface EditQuestion {
  type: typeof EDIT_QUESTION
}

export interface RemoveQuestion {
  type: typeof REMOVE_QUESTION
  payload: {
    index: number
  }
}

export interface MoveQuestionUp {
  type: typeof MOVE_QUESTION_UP
  payload: {
    index: number
  }
}

export interface MoveQuestionDown {
  type: typeof MOVE_QUESTION_DOWN
  payload: {
    index: number
  }
}

export interface AddImageToOptionRequest {
  type: typeof ADD_IMAGE_TO_OPTION_REQUEST
  payload: {
    index: number
    column: UnionColumn
  }
}

export interface AddImageToOptionSuccess {
  type: typeof ADD_IMAGE_TO_OPTION_SUCCESS
  payload: {
    index: number
    url: string
    column: UnionColumn
  }
}

export interface AddImageToOptionFailure {
  type: typeof ADD_IMAGE_TO_OPTION_FAILURE
  payload: {
    index: number
    column: UnionColumn
    error: string
  }
}

export interface RemoveImageFromOption {
  type: typeof REMOVE_IMAGE_FROM_OPTION
  payload: {
    index: number
    column: UnionColumn
  }
}

export interface SetBlankAnswer {
  type: typeof SET_BLANK_ANSWER
  payload: {
    id: number
    number: number
    answer: string
  }
}

export interface SetUnionAnswer {
  type: typeof SET_UNION_ANSWER
  payload: {
    id: number
    leftOrder: number
    rightOrder: number
  }
}

export interface AddImageToQuestionRequest {
  type: typeof ADD_IMAGE_TO_QUESTION_REQUEST
}

export interface AddImageToQuestionSuccess {
  type: typeof ADD_IMAGE_TO_QUESTION_SUCCESS
  payload: {
    url: string
  }
}

export interface AddImageToQuestionFailure {
  type: typeof ADD_IMAGE_TO_QUESTION_FAILURE
  payload: {
    error: string
  }
}

export interface RemoveImageFromQuestion {
  type: typeof REMOVE_IMAGE_FROM_QUESTION
}

export interface SetQuestionIframe {
  type: typeof SET_QUESTION_IFRAME
  payload: {
    iframeUrl: string
  }
}
export interface SetScore {
  type: typeof SET_SCORE
  payload: {
    score: number
  }
}

export interface SetTime {
  type: typeof SET_TIME
  payload: {
    time: number
  }
}

export interface SetTimeLimitEnabled {
  type: typeof SET_TIME_LIMIT_ENABLED
  payload: {
    timeLimitEnabled: boolean
  }
}

export interface EditScore {
  type: typeof EDIT_SCORE
  payload: {
    score: number
    index: number
  }
}

export interface SetMaxScore {
  type: typeof SET_MAX_SCORE
  payload: {
    maxScore: number
  }
}

export interface SetMinLengthToggle {
  type: typeof SET_MIN_LENGTH_TOGGLE
}

export interface SetLineHeight {
  type: typeof SET_LINE_HEIGHT
  payload: {
    lineHeight: number
  }
}

export type QuestionsActionTypes =
  | SetOpen
  | SetEditOpen
  | SetClose
  | SetQuestionType
  | SetQuestion
  | SetDivider
  | AddOption
  | SetOption
  | SetCorrectQuestion
  | SetQuestionAnswer
  | RemoveOption
  | AddQuestion
  | EditQuestion
  | RemoveQuestion
  | MoveQuestionUp
  | MoveQuestionDown
  | AddImageToOptionRequest
  | AddImageToOptionSuccess
  | AddImageToOptionFailure
  | RemoveImageFromOption
  | WrittenExamActivitySuccess
  | SetBlankAnswer
  | SetBody
  | AddUnionOption
  | SetUnionOption
  | RemoveUnionOption
  | SetUnionMatch
  | SetUnionAnswer
  | AddImageToQuestionRequest
  | AddImageToQuestionSuccess
  | AddImageToQuestionFailure
  | RemoveImageFromQuestion
  | SetQuestionIframe
  | SetScore
  | SetTime
  | EditScore
  | SetMaxScore
  | SetQuestionMinLength
  | SetLineHeight
  | SetMinLengthToggle
  | SetTimeLimitEnabled
  | SetQuestionWordCount
  | SetWordCountToggle
