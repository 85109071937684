import { apiPrivate } from './..'
import { url } from '@aula/config'

// DEPRECATED: From now on API files shouldn't be organized by role, but by concern (eg: Classrooms - getAsPrincipal, getAsTeacher, etc.)
export const EbAdminPrincipalAPI = {
  changePassword,
  getCustomFields,
}

type Role = 'admin' | 'principal'

interface ChangePasswordForm {
  passwordTypeId: number
  password: string
}

/**
 * Change student password.
 *
 * @role EbAdmin
 * @param studentID StudentID
 * @param form { passwordTypeId: id, password: string }
 */

function changePassword(
  studentID: number,
  form: ChangePasswordForm,
  role: Role = 'admin',
  organizationID?: number
) {
  const formData = new FormData()
  formData.append('password_type_id', String(form.passwordTypeId))
  formData.append('password', form.password)

  if (role === 'principal' && organizationID)
    formData.append('organization_id', organizationID.toString())

  return apiPrivate.post(url + `/v1/${role}/users/${studentID}/password`, formData)
}

/**
 * Get custom fields
 *
 * @role EbAdmin / Principal
 * @param organizationID StudentID
 */

function getCustomFields(organizationID: number, role: Role) {
  return apiPrivate.get(`${url}/v1/${role}/organizations/${organizationID}/fields`)
}