import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import types from '@/sections/teacher/activities/types'

const initialState = {
  activity: {},
  classroomID: '',
  status: REQUEST_STATUS.IDLE,
  open: false,
  error: '',
  many: false,
}

function root(state = initialState, action) {
  switch (action.type) {
    case types.SET_DELETE_MODAL_OPEN: {
      const { open, classroomID, activity, many } = action.payload
      return { ...state, activity: open ? activity : state.activity, classroomID, open, many: many }
    }
    case types.DELETE_ACTIVITY_REQUEST:
      return { ...state, status: REQUEST_STATUS.LOADING, error: '' }
    case types.DELETE_ACTIVITY_SUCCESS: {
      const { activity } = state
      return { ...initialState, activity, status: REQUEST_STATUS.SUCCESS }
    }
    case types.DELETE_ACTIVITY_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        status: REQUEST_STATUS.FAILURE,
      }
    default:
      return state
  }
}

export default root
