import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Content, Header, Modal } from '@/legacy/components/modals/mainModal'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '@/state/hooks'
import { bindActionCreators } from 'redux'
import MigrationClassroomsActionCreators from '@/sections/principal/classroom-migration/actions/migrationClassrooms'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import SvgWarning from '@/legacy/svgs/Warning'
import Button, { Variant } from '@/legacy/components/atomic/button/button'
import clsx from 'clsx'
import { formatClassroomName } from '@aula/tools'
import { REQUEST_STATUS } from '@/legacy/redux/types/status'

const useStyles = makeStyles((theme) => ({
  modal: {
    maxHeight: 500,
    width: '100vw',
    [theme.breakpoints.down('xl')]: {
      maxWidth: 550,
    },
    [theme.breakpoints.down(550)]: {
      maxWidth: '90%',
    },
  },
  contentWrapper: {
    width: '100%',
    height: '100%',
    minHeight: 450,
    padding: 24,
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
  },
  text: {
    textAlign: 'left',
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,
  },
  buttons: {
    marginTop: 20,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    gap: 6,
  },
  button: {
    height: 40,
    width: '100%',
    alignSelf: 'center',
    borderRadius: '3px',
  },
  cancelButton: {
    fontWeight: theme.typography.fontWeightMedium,
    marginLeft: 18,
  },
  nextButton: {
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: 18,
  },
  classroomContainer: {
    marginTop: 8,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  classroomInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.border,
    padding: 4,
  },
  pendingText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
  },
  studentsText: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.secondary,
  },
  classroomText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

const AlertMigrationModal: React.FC = () => {
  const dispatch = useDispatch()
  const {
    alertModal: { isOpen },
    migrations,
    migrationCompletionStatus,
  } = useAppSelector((state) => state.principal.classroomMigration.migrationClassrooms)
  const styles = useStyles()
  const { t } = useTranslation()
  const classroomMigrationsActions = bindActionCreators(MigrationClassroomsActionCreators, dispatch)
  const requestOrigin = reduxRequestOriginMap.PRINCIPAL_MIGRATION_TAB

  const migrationsWithoutVerify = migrations.filter((migration) => !migration.verified)

  const handleCloseModal = () => {
    classroomMigrationsActions.setCloseAlertModal(requestOrigin)
  }

  const handleStartMigration = () => {
    classroomMigrationsActions.completeMigration(requestOrigin, migrations)
    handleCloseModal()
  }

  return (
    <Modal
      onClose={handleCloseModal}
      open={isOpen}
      containerStyle={styles.modal}
      closingDisabled={migrationCompletionStatus === REQUEST_STATUS.LOADING}
    >
      <Header
        title={t('principal.classroomMigration.alertHeader')}
        onClose={handleCloseModal}
        type="warning"
        closingDisabled={false}
      />
      <Content contentClass={styles.contentWrapper}>
        <div className={styles.contentContainer}>
          <SvgWarning />
          <div>
            <Typography className={styles.text}>
              {t('principal.classroomMigration.alertMissingVerification')}
            </Typography>
            <Typography className={styles.text}>
              <strong>{t('principal.classroomMigration.alertWantToMigrate')}</strong>
            </Typography>
          </div>
          <div className={styles.classroomContainer}>
            <div className={styles.info}>
              <Typography className={styles.pendingText}>
                {t('principal.classroomMigration.classroomPendingOfVerification')}
              </Typography>
              <Typography className={styles.pendingText}>
                {migrationsWithoutVerify.length}
              </Typography>
            </div>
            {migrationsWithoutVerify.map((migration, i) => (
              <div key={i} className={styles.classroomInfo}>
                <Typography className={styles.classroomText}>
                  {formatClassroomName({
                    grade: migration.toClassroom.grade,
                    division: migration.toClassroom.division,
                    shift: migration.toClassroom.shift,
                    stage: migration.toClassroom.stage,
                  })}
                </Typography>
                <Typography className={styles.studentsText}>
                  {migration.students.length} {t('generic.students')}
                </Typography>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            variant={Variant.OUTLINE}
            onClick={handleStartMigration}
            className={clsx(styles.button, styles.cancelButton)}
            disabled={migrationCompletionStatus === REQUEST_STATUS.LOADING}
          >
            {t('principal.classroomMigration.migrateAnyway')}
          </Button>

          <Button
            variant={Variant.DEFAULT}
            className={clsx(styles.button, styles.nextButton)}
            onClick={handleCloseModal}
            disabled={migrationCompletionStatus === REQUEST_STATUS.LOADING}
          >
            {t('principal.classroomMigration.finishVerification')}
          </Button>
        </div>
      </Content>
    </Modal>
  )
}

export default AlertMigrationModal
