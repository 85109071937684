import type {
  AddProfileModalAction,
  AddProfileModalState,
} from '@/sections/management/types/addProfileModal'
import { ADD_PROFILE_STATE_TYPES } from '@/sections/management/types/addProfileModal'

const initialState: AddProfileModalState = {
  isOpen: false,
}

const AddProfileModalReducer = (
  state: AddProfileModalState = initialState,
  action: AddProfileModalAction
): AddProfileModalState => {
  switch (action.type) {
    case ADD_PROFILE_STATE_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isOpen: true,
      }
    }
    case ADD_PROFILE_STATE_TYPES.CLOSE_MODAL: {
      return {
        ...state,
        isOpen: false,
      }
    }
    default:
      return state
  }
}

export default AddProfileModalReducer
