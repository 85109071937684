import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type {
  StageSelectorModalState,
  StageSelectorModalAction,
} from '@/sections/management/types/stageSelectorModal'
import {
  SET_OPEN,
  REMOVE_STAGE,
  ADD_STAGE,
} from '@/sections/management/types/stageSelectorModal'

const initialState: StageSelectorModalState = {
  open: false,
  stageOptions: [],
  allSelected: true,
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_STAGE_SELECTOR_MODAL

const StageSelectorModalReducer: Reducer<StageSelectorModalState, StageSelectorModalAction> = (
  state = initialState,
  action
): StageSelectorModalState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case SET_OPEN:
      return { ...state, open: true }
    case ADD_STAGE: {
      const { stage } = action.payload
      const stagesAfterToggle = state.stageOptions.find((c) => c.id === stage.id)
        ? state.stageOptions
        : [...state.stageOptions, stage]

      return {
        ...state,
        stageOptions: stagesAfterToggle,
      }
    }
    case REMOVE_STAGE: {
      const { stage } = action.payload

      const stagesAfterToggle = state.stageOptions.filter((c) => c.id !== stage.id)
      return {
        ...state,
        stageOptions: stagesAfterToggle,
      }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, StageSelectorModalReducer, requestDestination)
