import React from 'react'
import { makeStyles } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Tabs from './tabsV2'
import UserAvatar from '@/legacy/components/v1/atomic/v1/userAvatar'
import Notifications from '@/legacy/components/v1/svgs/header/notifications'
import NewMessages from '@/legacy/components/v1/svgs/header/newMessages'
import Messages from '@/legacy/components/v1/svgs/header/messages'
import Logo from '@/legacy/components/v1/svgs/logo'
import { Collapse, Popover } from '@mui/material'
import SubjectList from './subjectList'
import { isEmpty } from 'ramda'

const drawerBreakPoint = 690

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.25)',
    height: 72,
    background: '#fff',
    justifyContent: 'center',
  },
  tabsContainer: {
    width: 'fit-content',
    margin: 'auto',
    display: 'none',
    [`@media (min-width: ${drawerBreakPoint}px)`]: {
      display: 'block',
    },
  },
  logoContainer: ({ button }) => ({
    marginRight: button ? 207 : 0,
  }),
  menuButton: {
    background: 'none',
  },
  title: {
    flexGrow: 1,
    justifyItems: 'center',
  },
  buttonContainer: {
    marginRight: 20,
    display: 'inline',
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  openDrawerButton: {
    marginRight: 10,
    [`@media (min-width: ${drawerBreakPoint}px)`]: {
      display: 'none',
    },
  },
  drawerCloseIcon: {
    display: 'inline-block',
    textAlign: 'left',
    width: '12px',
    position: 'relative',
    left: '31px',
    top: '23px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& span': {
      display: 'inline-block',
      width: 'auto',
      '& svg': {
        width: '12px',
        height: '12px',
      },
    },
  },
  drawerListContainer: {
    marginTop: '50px',
  },
  drawerListItem: {
    minWidth: 262,
    padding: '14px 12px',
    boxSizing: 'border-box',
    position: 'relative',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      bottom: 0,
      left: 12,
      right: 12,
      borderBottom: '1px solid #DFDFDA',
    },
  },
  drawerListItemIcon: {
    minWidth: '40px',
  },
  drawerListItemText: {
    flex: 'unset',
    '& span': {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '23px',
    },
  },
  messageButton: {
    padding: 12,
    '&:hover': {
      background: 'transparent',
    },
  },
  popover: {
    left: '0 !important',
    minWidth: '100vw',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.25)',
  },
}))

export default function StudentMenuAppBarV2(props) {
  const {
    newMessages = false,
    openMessages,
    tabs,
    tabsChange,
    tabValue,
    name,
    lastName,
    Button,
    summary,
    onClickSubject,
    menuItems,
    loading,
  } = props
  const { showMessageButton, showNotificationsButton } = props
  const classes = useStyles({ button: !!Button })

  const [dropDownAnchorEl, setDropDownAnchorEl] = React.useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [listOpen, setListOpen] = React.useState(false)
  const open = Boolean(anchorEl)

  const avatarName = name + ' ' + lastName

  const handleClickOnLogo = () => props.onLogoClick()

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItem = (clickFn) => {
    handleClose()
    clickFn()
  }

  const handleDropdownOpen = (e) => {
    setDropDownAnchorEl(e.currentTarget)
  }

  const handleDropdownClose = (e) => {
    setDropDownAnchorEl(null)
  }

  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  const handleToggleList = () => {
    setListOpen(!listOpen)
  }

  const areTabs = !isEmpty(tabs)
  const areMenuItems = !isEmpty(menuItems)

  return (
    <>
      <AppBar position="fixed" color="transparent" className={classes.appBar}>
        <Toolbar>
          {areTabs && (
            <IconButton
              className={classes.openDrawerButton}
              onClick={handleDrawerOpen}
              color="inherit"
              aria-label="Menu"
            >
              <MenuIcon />
            </IconButton>
          )}
          <IconButton
            disableRipple
            edge="start"
            aria-label="menu"
            color="inherit"
            onClick={handleClickOnLogo}
            className={classes.logoContainer}
          >
            <Avatar className={classes.menuButton}>
              <Logo alt="logo" />
            </Avatar>
          </IconButton>
          <div className={classes.title}>
            <div className={classes.tabsContainer}>
              <Tabs
                onChange={tabsChange}
                tabs={tabs}
                value={tabValue}
                handleDropdownOpen={handleDropdownOpen}
                handleDropdownClose={handleDropdownClose}
                dropdownOpen={Boolean(dropDownAnchorEl)}
              />
            </div>
          </div>
          <div className={classes.userContainer}>
            {Button && (
              <div className={classes.buttonContainer}>
                <Button />
              </div>
            )}
            {showMessageButton && (
              <IconButton
                className={classes.messageButton}
                disableRipple
                size="small"
                color="inherit"
                onClick={openMessages}
              >
                {newMessages ? <NewMessages /> : <Messages />}
              </IconButton>
            )}
            {showNotificationsButton && <Notifications />}
            <IconButton
              disableRipple
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              id="profile-button"
            >
              <UserAvatar name={avatarName} />
            </IconButton>
            {areMenuItems && (
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                {menuItems.map(({ onClick, label, id }, index) => (
                  <MenuItem key={index} id={id} onClick={() => handleMenuItem(onClick)}>
                    {label}
                  </MenuItem>
                ))}
              </Menu>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Popover
        elevation={0}
        classes={{ paper: classes.popover }}
        open={Boolean(dropDownAnchorEl)}
        anchorEl={dropDownAnchorEl}
        onClose={handleDropdownClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {/* <SubjectList
          onMouseLeave={handleDropdownClose}
          subjects={summary}
          onClick={onClickSubject}
          loading={loading}
        /> */}
      </Popover>
      <Drawer variant="temporary" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <IconButton
          disableRipple
          size="small"
          color="inherit"
          onClick={handleDrawerClose}
          className={classes.drawerCloseIcon}
        >
          <CloseIcon />
        </IconButton>
        <List component="nav" className={classes.drawerListContainer}>
          {tabs.map(({ label, Icon, value, useDropdown, onClick }) =>
            useDropdown ? (
              <React.Fragment key={value}>
                <ListItem button onClick={handleToggleList} className={classes.drawerListItem}>
                  <ListItemIcon className={classes.drawerListItemIcon}>
                    {Icon({ active: value === tabValue })}
                  </ListItemIcon>
                  <ListItemText className={classes.drawerListItemText}>{label}</ListItemText>
                  <ArrowDropDownIcon />
                </ListItem>
                <Collapse in={listOpen} timeout="auto" unmountOnExit>
                  <List component="div">
                    {summary.map((item) => (
                      <ListItem
                        key={item.subject.id}
                        button
                        onClick={() => {
                          setDrawerOpen(false)
                          onClickSubject(item.subject)
                        }}
                      >
                        <ListItemText className={classes.drawerListItemText}>
                          {item.subject.name}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ) : (
              <ListItem
                key={value}
                button
                onClick={() => {
                  setDrawerOpen(false)
                  onClick()
                }}
                className={classes.drawerListItem}
              >
                <ListItemIcon className={classes.drawerListItemIcon}>
                  {Icon({ active: value === tabValue })}
                </ListItemIcon>
                <ListItemText className={classes.drawerListItemText}>{label}</ListItemText>
              </ListItem>
            )
          )}
        </List>
      </Drawer>
    </>
  )
}

StudentMenuAppBarV2.defaultProps = {
  openMessages: () => {},
  newMessages: false,
  tabs: [],
  tabsChange: () => {},
  tabValue: '',
  name: '',
  lastName: '',
  onLogoClick: () => {},
  menuItems: [],
}
