import type { SetField } from '@/legacy/redux/types/generic'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type { SubjectSubitem } from '@/sections/report-card/config/types/entities'
import type { ScoreType } from '@/sections/report-card/types/common'
import type { CreatedReportCardConfigRangeScore } from '@/sections/report-card/grading/types/entities'
import type { StudentScoreSubitem } from '@/sections/report-card/grading/types/tabs/scoresTab'
import type { FindClassroomReportCardConfigSuccess } from '@/sections/report-card/grading/types/mainView'

export interface SubitemMap {
  [orderValue: number]: StudentScoreSubitem & { alreadyExists: boolean } // Items already graded can´t be overwritten with 0
}

export interface ScoresGradingFormState {
  scoreType: ScoreType
  conceptScoreValues: CreatedReportCardConfigRangeScore[]
  numericalScoreValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  loading: boolean
  error: string
  currentValue: number
  observation: string
  mainValueAlreadyExists: boolean // subjects already graded can´t be overwritten with 0
  subitems: SubitemMap
  resit: boolean // Indicates wether the user needs (another) extra period or not
  finalScore: string
}

export const CLEAR_STATE = 'REPORT_CARD/GRADING/FORMS/SCORES/CLEAR_STATE'
export type ClearState = GenericActionWithType<typeof CLEAR_STATE>

export const INIT_STUDENT_SCORE = 'REPORT_CARD/GRADING/FORMS/SCORES/INIT_STUDENT_SCORE'
export type InitStudentScore = GenericActionWithType<
  typeof INIT_STUDENT_SCORE,
  {
    scoreType: ScoreType
    currentValue?: number
    subjectSubitems?: SubjectSubitem[]
    studentSubitems?: StudentScoreSubitem[]
    observation?: string
    finalScore?: string
    resit?: boolean
  }
>

export const SET_SUBITEM_SCORE = 'REPORT_CARD/GRADING/FORMS/SCORES/SET_SUBITEM_SCORE'
export type SetSubitemScore = GenericActionWithType<
  typeof SET_SUBITEM_SCORE,
  { orderValue: number; value: number }
>

export const SAVE_OR_EDIT_SCORE_REQUEST =
  'REPORT_CARD/GRADING/FORMS/SCORES/SAVE_OR_EDIT_SCORE_REQUEST'
export const SAVE_OR_EDIT_SCORE_SUCCESS =
  'REPORT_CARD/GRADING/FORMS/SCORES/SAVE_OR_EDIT_SCORE_SUCCESS'
export const SAVE_OR_EDIT_SCORE_FAILURE =
  'REPORT_CARD/GRADING/FORMS/SCORES/SAVE_OR_EDIT_SCORE_FAILURE'

export type SaveOrEditScoreRequest = GenericActionWithType<typeof SAVE_OR_EDIT_SCORE_REQUEST>
export type SaveOrEditScoreSuccess = GenericActionWithType<typeof SAVE_OR_EDIT_SCORE_SUCCESS>
export type SaveOrEditScoreFailure = GenericActionWithType<
  typeof SAVE_OR_EDIT_SCORE_FAILURE,
  ErrorPayload
>

export type SaveOrEditScoreAction =
  | SaveOrEditScoreRequest
  | SaveOrEditScoreSuccess
  | SaveOrEditScoreFailure

export const SET_RESIT = 'REPORT_CARD/GRADING/FORMS/SCORES/SET_RESIT'
export type SetResit = GenericActionWithType<typeof SET_RESIT, { resit: boolean }>

export type ScoresGradingFormAction =
  | InitStudentScore
  | SetSubitemScore
  | FindClassroomReportCardConfigSuccess
  | ClearState
  | SetField<'currentValue', number>
  | SaveOrEditScoreAction
  | SetResit
