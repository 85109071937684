import types from '@/sections/editor/types/editor'
import { editorStateFromRaw, editorStateToJSON } from 'megadraft'
import { pathOr } from 'ramda'
import linkDecorator from '@/sections/editor/plugins/linkTargetBlank/linkDecorator'

function getFromRaw(state) {
  try {
    return editorStateFromRaw(JSON.parse(state), linkDecorator)
  } catch (e) {
    return editorStateFromRaw(null, linkDecorator)
  }
}

let timeout = null

const actions = {
  setEditorState: (state) => ({ type: types.SET_EDITOR_STATE, payload: { state } }),

  setRawEditorState: (activity) => ({
    type: types.SET_RAW_EDITOR_STATE,
    payload: {
      state: getFromRaw(pathOr(null, ['description'], activity)),
    },
  }),

  clear: () => ({ type: types.CLEAR, payload: { state: editorStateFromRaw(null, linkDecorator) } }),

  /**
   * Do not use outside of the editor.
   *
   * @param state
   * @returns {{payload: {state: *}, type: string}}
   */
  setCurrentState: (state) => (dispatch) => {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      window.localStorage.setItem('editor-state', editorStateToJSON(state))
      // dispatch({type: types.SET_CURRENT_STATE, payload: {state}})
    }, 500)
  },

  reset: () => (dispatch) => {
    window.localStorage.removeItem('editor-state')
    dispatch(actions.setEditorState(editorStateFromRaw(null, linkDecorator)))
  },

  recover: () => (dispatch) => {
    const stored = window.localStorage.getItem('editor-state')
    dispatch(actions.setEditorState(getFromRaw(stored)))
  },
}

export default actions
