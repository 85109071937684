import { pickCosmos } from '@aula/config'
import localforage from 'localforage'
import moment from 'moment'
import { persistReducer } from 'redux-persist'
import type { AcademicPeriod, Organization } from '@/api/lms/user'
import { views } from '@/legacy/rolesManagement'
import { LOGIN_SUCCESS as ANSWER_LOGIN_SUCCESS } from '@/sections/auth/answersLogin/types/answersLogin'
import { LOGIN_SUCCESS as CODE_LOGIN_SUCCESS } from '@/sections/auth/codeLogin/types/codeLogin'
import {
  LOGIN_SUCCESS,
  SELECT_ACADEMIC_PERIOD,
  SELECT_ORGANIZATION,
} from '@/sections/auth/login/types/login'
import { EDIT_ORGANIZATION_SUCCESS } from '@/sections/management/types/organizations/addEditOrganizationModal'
import type { OrganizationRole, UserActions, UserState } from './types'
import { GET_INFO_SUCCESS, SET_FIREBASE_TOKEN, SET_ROLE, UPDATE_USER_AVATAR } from './types'

const initialState: UserState = {
  token: null,
  role: '',
  profile: {
    id: 0,
    name: '',
    role: '',
    organizationId: 0,
  },
  user: {
    id: 0,
    name: '',
    color: '',
    lastName: '',
    email: '',
    avatar: '',
    dateOfBirth: moment(),
  },
  altRoles: [],
  sandbox: 0,
  isSandbox: false,
  organizations: [],
  organizationList: [],
  selectedOrganization: {
    id: 0,
    name: '',
    logo: '',
    emblem: '',
    roles: [],
    academicPeriods: [],
    profiles: [],
  },
  selectedAcademicPeriod: { id: 0, name: '', default: false },
  firebaseToken: null,
  organizationRoles: [],
}

function root(state = initialState, action: UserActions): UserState {
  switch (action.type) {
    case UPDATE_USER_AVATAR:
      return { ...state, user: { ...state.user, avatar: action.payload.avatar } }
    case SET_ROLE:
      return { ...state, role: action.payload.role }
    case SET_FIREBASE_TOKEN:
      return { ...state, firebaseToken: action.payload.token }
    case CODE_LOGIN_SUCCESS:
    case ANSWER_LOGIN_SUCCESS:
    case LOGIN_SUCCESS: {
      return { ...initialState, token: action.payload.token }
    }
    case GET_INFO_SUCCESS: {
      const p = action.payload
      const organizations = filterOrganizationsByCosmos(p.organizations)

      const altRoles: string[] = []
      organizations.forEach((o) => {
        altRoles.push(...o.roles)
      })
      p.ebRoles.forEach((r) => {
        altRoles.push(r)
      })

      // Sort academic periods by id desc
      organizations.forEach((o) => {
        o.academicPeriods.sort((a, b) => (a.id < b.id ? 1 : -1))
      })

      const selectedOrganization = pickOrganization(state.selectedOrganization, organizations)

      const organizationWithRoles = organizations.find((or) => or.id === selectedOrganization.id)
      const validRoles = organizationWithRoles?.roles ?? altRoles
      // Admin role is not related to organizations, so we need to do things like this one:
      if (altRoles.find((r) => r === views.EB_ADMIN)) {
        validRoles.push(views.EB_ADMIN)
      }
      // Same with evaluator role
      if (altRoles.find((r) => r === views.EB_EVALUATOR)) {
        validRoles.push(views.EB_EVALUATOR)
      }
      const role = pickRole(validRoles, state.role, p.role)

      const selectedAcademicPeriod = pickAcademicPeriod(selectedOrganization.academicPeriods)

      return {
        ...state,
        user: {
          id: p.id,
          name: p.name,
          color: p.color,
          lastName: p.lastName,
          email: '',
          avatar: p.avatar,
          dateOfBirth: p.dateOfBirth,
        },
        profile: p.profile,
        role: role,
        altRoles: altRoles,
        sandbox: p.sandbox,
        isSandbox: p.sandbox !== 0,
        organizations: organizations.map((o) => o.id),
        organizationList: organizations,
        selectedOrganization: { ...selectedOrganization },
        selectedAcademicPeriod: { ...selectedAcademicPeriod },
        organizationRoles: organizations.map((o) => ({
          organizationID: o.id,
          roles: o.roles,
        })),
      }
    }
    case SELECT_ORGANIZATION: {
      const selected = action.payload.selectedOrganization

      let role = state.role
      // This should never be undefined, but in the impossible chance that it is, we keep the role the same
      const organizationRole = state.organizationRoles.find(
        (or) => or.organizationID === selected.id
      )
      if (organizationRole && !hasRoleInOrganization(organizationRole, state.role)) {
        role = organizationRole.roles[0]
      }

      const selectedAcademicPeriod = pickAcademicPeriod(selected.academicPeriods)

      return {
        ...state,
        selectedOrganization: { ...selected },
        selectedAcademicPeriod: { ...selectedAcademicPeriod },
        role,
      }
    }
    case SELECT_ACADEMIC_PERIOD:
      const selected = action.payload.selectedAcademicPeriod
      return {
        ...state,
        selectedAcademicPeriod: { ...selected },
      }
    case EDIT_ORGANIZATION_SUCCESS: {
      const { organizationId, emblem } = action.payload
      const { selectedOrganization } = state

      if (!!emblem && organizationId === selectedOrganization.id) {
        return { ...state, selectedOrganization: { ...selectedOrganization, emblem } }
      } else {
        return state
      }
    }
    default:
      return state
  }
}

const persistConfig = {
  key: 'user',
  storage: localforage,
  blacklist: [],
}

export default persistReducer(persistConfig, root)

function pickOrganization(
  prevOrganization: Organization,
  organizations: Organization[]
): Organization {
  if (organizations.length === 0) return initialState.selectedOrganization

  const foundCurrentOrganization = organizations.find((o) => o.id === prevOrganization.id)
  // if we had a previously selected organization that is still valid, we keep it, but we update the academic periods.
  // This is important when the user changes roles (views) because the user info gets reinitialized.
  return foundCurrentOrganization ?? organizations[0]
}

// validRoles refers to the roles available within the selected organization
function pickRole(validRoles: string[], prevRole: string, newRole: string): string {
  // Having no valid roles can happen if you are in the wrong cosmos
  if (validRoles.length === 0) return views.GUEST

  // We keep our current role if valid (otherwise we might force the user to change to a different view)
  if (prevRole !== '' && validRoles.find((r) => r === prevRole)) {
    return prevRole
  }

  // If the new role is available within the currently selected organization, we keep it
  if (validRoles.find((r) => r === newRole)) return newRole
  return validRoles[0]
}

function hasRoleInOrganization(organizationRole: OrganizationRole, role: string): boolean {
  return organizationRole.roles.find((r) => r === role) !== undefined
}

// pickAcademicPeriod selects the period tagged as default (assuming there's only one). If there
// is none, it picks the last element of the list.
function pickAcademicPeriod(list: AcademicPeriod[]): AcademicPeriod {
  if (list.length === 0) return initialState.selectedAcademicPeriod
  const defaultPeriod = list.find((v) => v.default)
  return defaultPeriod || list[list.length - 1]
}

function filterOrganizationsByCosmos(organizations: Organization[]): Organization[] {
  const { key } = pickCosmos()
  return organizations.filter((o) => o.cosmos?.some((k) => k === key))
}
