import type { UploadFileData } from '@/api/files/types'
import type { ClearState } from '@/legacy/redux/types/generic'
import { SetField } from '@/legacy/redux/types/generic'
import type {
  ErrorPayload,
  GenericAction,
  GenericActionWithType,
  GenericPayload,
} from '@/legacy/redux/utils'
import type {
  HeaderConfig,
  SignatureAPI,
} from '@/legacy/services/api/reportCard/config/types/common'
import type { SaveOrEditReportCardConfigSuccess } from '@/sections/report-card/config/types/apiInteractions'

export interface ReportCardConfigSignaturesState {
  frontPage: string
  header: HeaderConfig
  secondCover: string
  uploading: {
    frontPage: boolean
    header: boolean
    signature: boolean
    secondCover: boolean
  }
  error: string
  studentSignature: boolean
  newSignature: boolean
  signatures: Signature[]
}

export interface Signature extends SignatureAPI {
  isLoading: boolean
}

export type SignaturesStepUploadType = 'frontPage' | 'header' | 'signature' | 'secondCover'

export const FILE_UPLOAD_REQUEST =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SIGNATURES/FILE_UPLOAD_REQUEST'
export const FILE_UPLOAD_SUCCESS =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SIGNATURES/FILE_UPLOAD_SUCCESS'
export const FILE_UPLOAD_FAILURE =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SIGNATURES/FILE_UPLOAD_FAILURE'

interface FileUploadRequestPayload extends GenericPayload {
  uploadType: SignaturesStepUploadType
  signatureId?: Signature['id']
}

export interface FileUploadRequest extends GenericAction {
  type: typeof FILE_UPLOAD_REQUEST
  payload: FileUploadRequestPayload
}

interface FileUploadSuccessPayload extends GenericPayload {
  uploadType: SignaturesStepUploadType
  uploadedFile: UploadFileData
  signatureId?: Signature['id']
}

export interface FileUploadSuccess extends GenericAction {
  type: typeof FILE_UPLOAD_SUCCESS
  payload: FileUploadSuccessPayload
}

interface FileUploadFailurePayload extends ErrorPayload {
  uploadType: SignaturesStepUploadType
  signatureId?: Signature['id']
}

export interface FileUploadFailure extends GenericAction {
  type: typeof FILE_UPLOAD_FAILURE
  payload: FileUploadFailurePayload
}

export type FileUpload = FileUploadRequest | FileUploadSuccess | FileUploadFailure

export const CLEAR_FILE = 'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SIGNATURES/CLEAR_FILE'

interface ClearFilePayload extends GenericPayload {
  uploadType: SignaturesStepUploadType
  signatureId?: Signature['id']
}

export interface ClearFile extends GenericAction {
  type: typeof CLEAR_FILE
  payload: ClearFilePayload
}

export const SET_VALUES_FOR_EDITION =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SIGNATURES/SET_VALUES_FOR_EDITION'

interface SetValuesForEditionPayload extends GenericPayload {
  signaturesState: ReportCardConfigSignaturesState
}

export type SetValuesForEdition = GenericActionWithType<
  typeof SET_VALUES_FOR_EDITION,
  SetValuesForEditionPayload
>

export const ADD_NEW_SIGNATURE =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SIGNATURES/ADD_NEW_SIGNATURE'
export type AddNewSignatureAction = GenericActionWithType<
  typeof ADD_NEW_SIGNATURE,
  {
    isEditMode: boolean
  }
>

export const REMOVE_SIGNATURE = 'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SIGNATURES/REMOVE_SIGNATURE'
export type RemoveSignatureAction = GenericActionWithType<
  typeof REMOVE_SIGNATURE,
  { signature: Signature }
>

export const SET_SIGNATURE_CLARIFICATION =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SIGNATURES/SET_SIGNATURE_CLARIFICATION'
export type SetSignatureClarificationAction = GenericActionWithType<
  typeof SET_SIGNATURE_CLARIFICATION,
  {
    signature: Signature
    newValue: string
  }
>

export type HeaderConfigType = keyof Omit<HeaderConfig, 'header'>

export const SET_BULLETIN_HEADER =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SIGNATURES/SET_BULLETIN_HEADER'
export type SetBulletinHeaderAction = GenericActionWithType<
  typeof SET_BULLETIN_HEADER,
  {
    newValue: string
    headerType: HeaderConfigType
  }
>

export type ReportCardConfigSignatureAction =
  | FileUpload
  | ClearFile
  | ClearState
  | SaveOrEditReportCardConfigSuccess
  | SetValuesForEdition
  | AddNewSignatureAction
  | RemoveSignatureAction
  | SetSignatureClarificationAction
  | SetBulletinHeaderAction
