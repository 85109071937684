import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useState } from 'react'

const ThreeDotMenu = ({ menuOptions, styles = {} }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const isAnchorEl = Boolean(anchorEl)
  const handleClose = (e) => {
    if (e) e.stopPropagation()
    setAnchorEl(null)
  }

  const onClick = (e, onClickFn) => {
    e.stopPropagation()
    onClickFn()
    handleClose()
  }

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
        size="small"
        style={styles}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={isAnchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
          },
        }}
      >
        {menuOptions.map((option) => (
          <MenuItem disableRipple key={option.label} onClick={(e) => onClick(e, option.onClick)}>
            <Typography>{option.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default ThreeDotMenu
