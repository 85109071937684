import type { AssignmentsList } from '@/legacy/redux/types/assignments'
import type { ClearState } from '@/legacy/redux/types/generic'
import type { UnassignUserFromOrganizationAction } from '@/legacy/redux/types/managementUsers'
import type { GenericActionWithType } from '@/legacy/redux/utils'

export interface DeleteOrganizationModalState {
  open: boolean
  loading: boolean
  error: string
  assignmentsList: AssignmentsList | null
}

export const INIT_MODAL = 'MANAGEMENT/USERS/DELETE_USER_ORGANIZATION_MODAL/INIT_MODAL'
export type InitModal = GenericActionWithType<
  typeof INIT_MODAL,
  { assignmentsList: AssignmentsList }
>

export type DeleteOrganizationModalAction =
  | InitModal
  | UnassignUserFromOrganizationAction
  | ClearState
