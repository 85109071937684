import { combineReducers } from 'redux'
import deletionModal from './reducers/deletionModal'
import editModal from './reducers/editModal'
import mainTab from './reducers/mainTab'
import stepperModal from './reducers/stepperModal'
import stepperModalSteps from './reducers/stepperModalSteps'
import pdfPreviewModal from './reducers/pdfPreviewModal'

const reducers = combineReducers({
  mainTab,
  stepperModal,
  stepperModalSteps,
  deletionModal,
  editModal,
  pdfPreviewModal,
})

export type ConfigurationDrawerType = ReturnType<typeof reducers>
export default reducers
