import { combineReducers } from 'redux'
import mainView from './reducers/mainView'
import tabs from './reducers/tabs/reducer'
import studentDrawer from './reducers/studentDrawer'
import gradingForms from './reducers/gradingForms/reducer'
import sendReportCard from './reducers/sendReportCard'
import sideDrawer from './sideDrawer/reducers/sideDrawer'
import gradingModal from './reducers/gradingModal'

export default combineReducers({
  mainView,
  tabs,
  studentDrawer,
  gradingForms,
  sendReportCard,
  sideDrawer,
  gradingModal,
})
