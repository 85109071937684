import * as React from 'react'
import type { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.5 10.834h-9a.5.5 0 0 0-.4.8l4.5 6a.499.499 0 0 0 .8 0l4.5-6a.5.5 0 0 0-.4-.8ZM5.5 9.5h9a.5.5 0 0 0 .4-.8l-4.5-5.999a.499.499 0 0 0-.8 0l-4.5 6a.5.5 0 0 0 .4.8Z"
      fill="#4F36D6"
    />
  </svg>
)

export default SvgComponent
