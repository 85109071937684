export enum ROLE {
  TEACHER = 'teacher',
  CLASS_TEACHER = 'class-teacher',
  CONTENT_CREATOR = 'content-creator',
  ADMIN = 'admin',
  EB_ADMIN = 'eb-admin',
  EB_EVALUATOR = 'eb-evaluator',
  GUEST = 'guest',
  PARENT = 'parent',
  PRINCIPAL = 'principal',
  RECTOR = 'rector',
  STUDENT = 'student',
  VICE_PRINCIPAL = 'vice-principal',
  PRECEPTOR = 'preceptor',
}
