import type { Contact } from '@/api/bulletin/types'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export interface ContactsState {
  status: REQUEST_STATUS
  loading: boolean
  error: Error | null
  contacts: Contact[]
}

export enum CONTACTS_TYPES {
  GET_CONTACTS = 'TEACHER/BULLETIN/CONTACTS/GET_CONTACTS',
  GET_CONTACTS_FAILURE = 'TEACHER/BULLETIN/CONTACTS/GET_CONTACTS_FAILURE',
  GET_CONTACTS_SUCCESS = 'TEACHER/BULLETIN/CONTACTS/GET_CONTACTS_SUCCESS',
}

export type GetContacts = {
  type: CONTACTS_TYPES.GET_CONTACTS
}

export type GetContactsSuccess = {
  type: CONTACTS_TYPES.GET_CONTACTS_SUCCESS
  payload: {
    contacts: Contact[]
  }
}

export type GetContactsFailure = {
  type: CONTACTS_TYPES.GET_CONTACTS_FAILURE
  payload: {
    error: Error
  }
}

export type ContactsActions = GetContacts | GetContactsSuccess | GetContactsFailure
