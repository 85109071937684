import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import type { AuthActions, AuthState } from '@/legacy/redux/types/auth'
import {
  ASSIGN_OWN_PASSWORD_FAILURE,
  ASSIGN_OWN_PASSWORD_REQUEST,
  ASSIGN_OWN_PASSWORD_SUCCESS,
  GET_PASSWORD_TYPES_FAILURE,
  GET_PASSWORD_TYPES_REQUEST,
  GET_PASSWORD_TYPES_SUCCESS,
  TOGGLE_MODAL_OPEN,
  SET_PASSWORD,
  SET_REPEAT_PASSWORD,
  ASSIGN_USER_PASSWORD_REQUEST,
  ASSIGN_USER_PASSWORD_SUCCESS,
  ASSIGN_USER_PASSWORD_FAILURE,
} from '@/legacy/redux/types/auth'
import { checkRequestOrigin } from '@/legacy/redux/utils'

const INITIAL_PASSWORD_TYPE = {
  id: 1,
  inputType: 'number',
  max: 4,
  min: 4,
  name: 'number',
  default: false,
}

const initialState: AuthState = {
  open: false,
  loading: false,
  password: '',
  repeatPassword: '',
  defaultPasswordType: INITIAL_PASSWORD_TYPE,
  passwordTypes: [INITIAL_PASSWORD_TYPE],
  error: '',
  userPasswordChangeDetails: null,
}

const requestDestination = reduxRequestOriginMap.AUTH

const AuthReducer: Reducer<AuthState, AuthActions> = (state = initialState, action): AuthState => {
  switch (action.type) {
    case TOGGLE_MODAL_OPEN:
      return {
        ...state,
        open: !state.open,
        userPasswordChangeDetails:
          action.payload.userPasswordChangeDetails || initialState.userPasswordChangeDetails,
      }
    case GET_PASSWORD_TYPES_REQUEST:
      return { ...state, loading: true }
    case GET_PASSWORD_TYPES_SUCCESS: {
      const { passwordTypes } = action.payload
      const defaultPasswordType = passwordTypes.find((pt) => {
        return pt.default
      })

      return {
        ...state,
        loading: false,
        passwordTypes,
        defaultPasswordType: defaultPasswordType || state.defaultPasswordType,
      }
    }
    case GET_PASSWORD_TYPES_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case SET_PASSWORD:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        password: action.payload.password,
      }
    case SET_REPEAT_PASSWORD:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        repeatPassword: action.payload.repeatPassword,
      }
    case ASSIGN_OWN_PASSWORD_REQUEST:
    case ASSIGN_USER_PASSWORD_REQUEST:
      return { ...state, loading: true }
    case ASSIGN_OWN_PASSWORD_SUCCESS:
    case ASSIGN_USER_PASSWORD_SUCCESS:
      return { ...state, password: '', repeatPassword: '', open: false, loading: false }
    case ASSIGN_USER_PASSWORD_FAILURE:
    case ASSIGN_OWN_PASSWORD_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, AuthReducer, requestDestination)
