import * as React from 'react'
import type { SVGProps } from 'react'
const SvgQuestions = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="questions-svg"
    {...props}
  >
    <rect opacity={0.15} width={56} height={56} rx={6} fill="#FFB800" />
    <path d="M21.595 43.277c4.41-4.403-4.47-13.285-8.872-8.874L10 46l11.595-2.723Z" fill="#fff" />
    <path
      d="m40.375 24.493-18.78 18.783a2.62 2.62 0 0 0-.765-1.748 2.62 2.62 0 0 0-2.475-.705 2.634 2.634 0 0 0-.705-2.475 2.62 2.62 0 0 0-2.475-.705 2.638 2.638 0 0 0-.713-2.483 2.605 2.605 0 0 0-1.747-.765l18.787-18.776 8.873 8.874Z"
      fill="#FFB800"
    />
    <path
      d="m42.382 22.494 2.905-2.906a2.45 2.45 0 0 0 0-3.458l-5.415-5.415a2.449 2.449 0 0 0-3.457 0L33.51 13.62l8.872 8.874Z"
      fill="#4F36D6"
    />
    <path
      d="m42.382 22.494 2.905-2.906a2.45 2.45 0 0 0 0-3.458l-5.415-5.415a2.449 2.449 0 0 0-3.457 0L33.51 13.62l8.872 8.874Z"
      fill="#1A1A1A"
      fillOpacity={0.6}
    />
    <path d="M13.502 45.174a3.584 3.584 0 0 0-2.677-2.677L10 45.999l3.502-.825Z" fill="#4F36D6" />
    <path
      d="M13.502 45.174a3.584 3.584 0 0 0-2.677-2.677L10 45.999l3.502-.825Z"
      fill="#1A1A1A"
      fillOpacity={0.6}
    />
  </svg>
)
export default SvgQuestions
