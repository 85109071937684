import React, { useMemo } from 'react'
import { ButtonGroup, CircularProgress, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import EditIcon from '@/legacy/components/v1/svgs/multipleChoice/edit'
import DeleteIcon from '@/legacy/components/v1/svgs/multipleChoice/delete'
import MoveIcon from '@/legacy/components/v1/svgs/multipleChoice/move'
import clsx from 'clsx'
import Error from '@/legacy/components/v1/atomic/v1/error'
import { useDropzone } from 'react-dropzone'
import AttachedFileChip from './attachedFileChip'
import { getFileExtension } from '@/legacy/components/v1/utils/editorUtils'

const useStyles = makeStyles((theme) => ({
  questionContainer: {
    border: '1px solid #D8DBE8',
    borderRadius: '16px',
    padding: '24px',
  },
  isActive: {
    borderColor: '#4F36D6',
  },
  titleContainer: {
    display: 'flex',
  },
  title: {
    flex: 1,
    fontSize: '18px',
    fontWeight: '500',
  },
  filesContainer: {
    display: 'flex',
    flexFlow: 'wrap',
    margin: '10px 0',
  },
  file: {
    padding: 4,
  },
  dropzone: {
    width: '100%',
    marginTop: '16px',
    borderRadius: 6,
  },
  button: {
    padding: '0 0 0 20px',
    minWidth: '20px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
}))

const useStylesV2 = makeStyles((theme) => ({
  questionContainer: {
    borderBottom: '1px solid #D8DBE8',
    padding: '24px 0 15px',
    margin: '0 24px',
  },
  titleContainer: {
    display: 'flex',
  },
  title: {
    flex: 1,
    fontSize: '18px',
    fontWeight: '500',
  },
  filesContainer: {
    display: 'flex',
    flexFlow: 'wrap',
    margin: '10px 0',
  },
  file: {
    padding: 4,
  },
  dropzone: {
    width: '100%',
    marginTop: '16px',
    marginBottom: 10,
    borderRadius: 6,
  },
  button: {
    padding: '0 0 0 20px',
    minWidth: '20px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
}))

const baseStyle = {
  flex: 1,
  minHeight: 100,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

const useStylesVersion = (version) => {
  const classesV2 = useStylesV2()
  const classes = useStyles()
  return version === 'V2' ? classesV2 : classes
}

const AttachmentQuestion = (props) => {
  const { title, onDelete, onEdit, onMove, isActive, status, files, footer } = props
  const { label, blockedLabel, error, loading, dropzoneProps: customDropzoneProps, version } = props
  const classes = useStylesVersion(version)

  const dropzoneProps = customDropzoneProps
  if (status === 'blocked') dropzoneProps.disabled = true

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } =
    useDropzone(dropzoneProps)

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...props.baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept, props.baseStyle]
  )

  const text = status !== 'blocked' ? label : blockedLabel

  return (
    <div className={clsx(classes.questionContainer, { [classes.isActive]: isActive })}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>{title}</Typography>
        {status === 'edit' ? (
          <ButtonGroup disableRipple className={classes.buttonContainer}>
            <IconButton className={classes.button} onClick={onEdit}>
              <EditIcon />
            </IconButton>
            <IconButton className={classes.button} onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
            <IconButton className={classes.button} onClick={onMove}>
              <MoveIcon />
            </IconButton>
          </ButtonGroup>
        ) : null}
      </div>
      <div className={classes.filesContainer}>
        {files.map((file, index) => (
          <div className={classes.file} key={index}>
            <AttachedFileChip
              onClick={file.onClick ? file.onClick : () => {}}
              onRemove={file.onRemove ? file.onRemove : () => {}}
              name={file.name}
              extension={getFileExtension(file.name)}
              downloadable={file.downloadable}
              downloading={file.downloading}
              isEdit={file.isEdit}
            />
          </div>
        ))}
      </div>
      <div className={classes.dropzone}>
        <div {...getRootProps({ style })}>
          <input id="student-upload-file" {...getInputProps()} />
          {loading ? <CircularProgress /> : <p>{text}</p>}
        </div>
      </div>
      {footer}
      <Error error={error} />
    </div>
  )
}

export default AttachmentQuestion

AttachmentQuestion.defaultProps = {
  error: '',
  baseStyle: {},
  dropzoneProps: {},
  files: [],
  label: 'Arrastre sus archivos aquí, o haga click para seleccionarlos',
  blockedLabel: 'Los estudiantes dejarán aquí sus archivos',
  version: 'V1',
  footer: null,
}
