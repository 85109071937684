import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import * as sequencesTypes from '@/sections/content-creator/sequences/types/sequences'
import type {
  TeacherLibrarySequencesAction,
  TeacherLibrarySequencesState,
} from '@/sections/teacher/library/types/librarySequences'
import {
  GET_TEACHER_SEQUENCES_FAILURE,
  GET_TEACHER_SEQUENCES_REQUEST,
  GET_TEACHER_SEQUENCES_SUCCESS,
  SET_SEARCH,
  SET_SEQUENCE_CARD_CLOSED,
  TOGGLE_ONLY_WITH_LICENSES,
  GET_TEACHER_SEQUENCES_ALL_TAGS_SUCCESS,
  GET_TEACHER_SEQUENCES_ALL_TAGS_FAILURE,
} from '@/sections/teacher/library/types/librarySequences'

const initialState: TeacherLibrarySequencesState = {
  sequences: [],
  loadingSequences: false,
  onlyWithLicenses: false,
  sequencesPagination: {
    page: 0,
    rowsPerPage: 12,
    count: 0,
  },
  error: '',
  search: '',
  tags: [],
}

const requestDestination = reduxRequestOriginMap.TEACHER_LIBRARY_SEQUENCES

function root(
  state: TeacherLibrarySequencesState = initialState,
  action: TeacherLibrarySequencesAction
): TeacherLibrarySequencesState {
  if (action.payload?.requestOrigin !== requestDestination) return state

  switch (action.type) {
    case GET_TEACHER_SEQUENCES_REQUEST: {
      return {
        ...state,
        loadingSequences: true,
        sequencesPagination: {
          ...state.sequencesPagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    }
    case GET_TEACHER_SEQUENCES_SUCCESS: {
      return {
        ...state,
        loadingSequences: false,
        error: '',
        sequences: action.payload.sequences,
        sequencesPagination: {
          ...state.sequencesPagination,
          count: action.payload.count,
        },
      }
    }
    case GET_TEACHER_SEQUENCES_FAILURE:
      return { ...state, loadingSequences: false, error: action.payload.error }
    case TOGGLE_ONLY_WITH_LICENSES:
      return { ...state, onlyWithLicenses: action.payload.onlyWithLicenses }
    case SET_SEARCH:
      return { ...state, search: action.payload.text }
    case GET_TEACHER_SEQUENCES_ALL_TAGS_SUCCESS: {
      return { ...state, tags: action.payload.tagCategories }
    }
    case GET_TEACHER_SEQUENCES_ALL_TAGS_FAILURE: {
      return { ...state, tags: [], error: action.payload.error }
    }
    default:
      return state
  }
}

export default root
