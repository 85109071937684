import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { PremiumActivity } from '@/sections/content-creator/premium-activities/types/premium-activities'

export const SET_OPEN = 'CONTENT_CREATOR/BOOKS/SET_OPEN'
export const GET_ORGANIZATION_BOOKS_REQUEST = 'CONTENT_CREATOR/BOOKS/GET_ORGANIZATION_BOOKS_REQUEST'
export const GET_ORGANIZATION_BOOKS_SUCCESS = 'CONTENT_CREATOR/BOOKS/GET_ORGANIZATION_BOOKS_SUCCESS'
export const GET_ORGANIZATION_BOOKS_FAILURE = 'CONTENT_CREATOR/BOOKS/GET_ORGANIZATION_BOOKS_FAILURE'
export const GET_BOOK_ACTIVITIES_REQUEST = 'CONTENT_CREATOR/GET_BOOK_ACTIVITIES_REQUEST'
export const GET_BOOK_ACTIVITIES_SUCCESS = 'CONTENT_CREATOR/GET_BOOK_ACTIVITIES_SUCCESS'
export const GET_BOOK_ACTIVITIES_FAILURE = 'CONTENT_CREATOR/GET_BOOK_ACTIVITIES_FAILURE'

export const AVAILABILITY_FREE = 'free'
export const AVAILABILITY_PREMIUM = 'premium'

export type Availability = typeof AVAILABILITY_FREE | typeof AVAILABILITY_PREMIUM

export const BOOK_INFO_MODAL = 'BOOK_INFO_MODAL'
export type ModalType = typeof BOOK_INFO_MODAL

export const ACTIVITIES_VIEW = 0
export const ASSIGNMENTS_VIEW = 1
export const NEW_ASSIGNMENT_VIEW = 2

export type BookModalView =
  | typeof ACTIVITIES_VIEW
  | typeof ASSIGNMENTS_VIEW
  | typeof NEW_ASSIGNMENT_VIEW

export type Book = {
  id: number
  createdAt: string
  userID: number
  organizationID: number
  title: string
  description: string
  fileID: number
  availability: Availability
  isbn: string
  url: string
  coverURL: string
  availableInLibrary: boolean
}

export const noBook: Book = {
  id: 0,
  createdAt: '',
  userID: 0,
  organizationID: 0,
  title: 'contentCreator.books.noAssociateActivity',
  description: '',
  fileID: 0,
  availability: AVAILABILITY_FREE,
  isbn: '',
  url: '',
  coverURL: '',
  availableInLibrary: false,
}

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    open: boolean
    modalType?: ModalType
    selectedBook?: Book
    organizationID: number
  }
}

export interface GetOrganizationBooksRequest {
  type: typeof GET_ORGANIZATION_BOOKS_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
    page: number
    rowsPerPage: number
    title: string
  }
}

export interface GetOrganizationBooksSuccess {
  type: typeof GET_ORGANIZATION_BOOKS_SUCCESS
  payload: {
    books: Book[]
    selectedBookId: number
    count: number
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetOrganizationBooksFailure {
  type: typeof GET_ORGANIZATION_BOOKS_FAILURE
  payload: {
    error: string
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetBookActivitiesRequest {
  type: typeof GET_BOOK_ACTIVITIES_REQUEST
  payload: {
    page: number
    rowsPerPage: number
  }
}

export interface GetBookActivitiesSuccess {
  type: typeof GET_BOOK_ACTIVITIES_SUCCESS
  payload: {
    bookActivities: PremiumActivity[]
    count: number
  }
}

export interface GetBookActivitiesFailure {
  type: typeof GET_BOOK_ACTIVITIES_FAILURE
  payload: {
    error: string
  }
}

export type BooksState = {
  open: boolean
  modalType: ModalType
  selectedBook: Book
  organizationID: number
  loadingBookActivities: boolean
  selectedBookActivities: PremiumActivity[]
}

export type BooksActions =
  | SetOpen
  | GetOrganizationBooksRequest
  | GetOrganizationBooksSuccess
  | GetOrganizationBooksFailure
  | GetBookActivitiesRequest
  | GetBookActivitiesSuccess
  | GetBookActivitiesFailure
