import type { Moment } from 'moment'
import type { AcademicPeriod, Info, Organization } from '@/api/lms/user'
import type { Logout } from '@/sections/header/types/logout'
import type { LoginSuccess as AnswerLoginSuccess } from '@/sections/auth/answersLogin/types/answersLogin'
import type { LoginSuccess as CodeLoginSuccess } from '@/sections/auth/codeLogin/types/codeLogin'
import type {
  Classroom,
  LoginSuccess,
  SelectAcademicPeriod,
  SelectOrganization,
} from '@/sections/auth/login/types/login'
import type { EditOrganizationAction } from '@/sections/management/types/organizations/addEditOrganizationModal'
import type { Profile } from '@/api/lms/organizations'

export const SET_ROLE = 'USER/SET_ROLE'
export const SET_FIREBASE_TOKEN = 'USER/SET_FIREBASE_TOKEN'
export const UPDATE_USER_AVATAR = 'USER/UPDATE_USER_AVATAR'
export const GET_INFO_REQUEST = 'USER/GET_INFO_REQUEST'
export const GET_INFO_SUCCESS = 'USER/GET_INFO_SUCCESS'
export const GET_INFO_FAILURE = 'USER/GET_INFO_FAILURE'

export interface SetRole {
  type: typeof SET_ROLE
  payload: {
    role: string
  }
}

export interface SetFirebaseToken {
  type: typeof SET_FIREBASE_TOKEN
  payload: {
    token: string
  }
}
export interface UpdateUserAvatar {
  type: typeof UPDATE_USER_AVATAR
  payload: {
    avatar: string
  }
}

export type User = {
  email: string
  id: number
  color: string
  lastName: string
  name: string
  avatar: string
  dateOfBirth: Moment | null
}

export interface GetInfoRequest {
  type: typeof GET_INFO_REQUEST
}

export interface GetInfoSuccess {
  type: typeof GET_INFO_SUCCESS
  payload: Info
}

export interface GetInfoFailure {
  type: typeof GET_INFO_FAILURE
  payload: {
    error: string
  }
}

export type UserState = {
  token: string | null
  role: string
  profile: Profile
  user: User
  altRoles: string[]
  classroom?: Classroom
  classroomID?: number
  sandbox: number
  isSandbox: boolean
  organizations: number[]
  organizationList: Organization[]
  selectedOrganization: Organization
  selectedAcademicPeriod: AcademicPeriod
  firebaseToken: string | null
  organizationRoles: OrganizationRole[]
}

export type OrganizationRole = {
  organizationID: number
  roles: string[]
}

export type UserActions =
  | LoginSuccess
  | AnswerLoginSuccess
  | CodeLoginSuccess
  | SetRole
  | Logout
  | SelectOrganization
  | SelectAcademicPeriod
  | SetFirebaseToken
  | UpdateUserAvatar
  | GetInfoRequest
  | GetInfoSuccess
  | GetInfoFailure
  | EditOrganizationAction
