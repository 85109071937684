import type { Moment } from 'moment'
import { AttendancesAPI } from '@/api/attendances'
import type { AttendanceStatus } from '@/api/attendances/config'
import type { Student } from '@/legacy/redux/types/students'
import Services from '@/legacy/services/api'
import type { GetAttendancesByStudentParams } from '@/legacy/services/api/attendances'
import type { AppThunk } from '@/state/thunk'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import messageActionCreators from '@/sections/header/actions/messages'
import * as Types from '@/sections/report-card/grading/types/abscencesDrawer'
import type { AxiosError } from 'axios'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'

const AbscencesDrawerActionCreators = {
  clearState: (requestOrigins: ReduxRequestOrigin[]): Types.ClearState => ({
    type: Types.CLEAR_STATE,
    payload: { requestOrigins },
  }),
  initDrawer: (
    requestOrigins: ReduxRequestOrigin[],
    selectedStudent: Student,
    students: Student[]
  ): Types.InitDrawer => ({
    type: Types.INIT_DRAWER,
    payload: { requestOrigins, selectedStudent, students },
  }),
  getAbscences:
    (requestOrigins: ReduxRequestOrigin[], params: GetAttendancesByStudentParams): AppThunk =>
    async (dispatch) => {
      const request: Types.GetAbscencesRequest = {
        type: Types.GET_ABSCENCES_REQUEST,
        payload: { requestOrigins },
      }

      dispatch(request)
      try {
        const abscences = await Services.Attendances.getByStudent(params)

        const success: Types.GetAbscencesSuccess = {
          type: Types.GET_ABSCENCES_SUCCESS,
          payload: { requestOrigins, abscences },
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, Types.GET_ABSCENCES_FAILURE, dispatch)
      }
    },
  updateAbscenceRadio:
    (requestOrigins: ReduxRequestOrigin[], date: Moment, status: AttendanceStatus): AppThunk =>
    async (dispatch) => {
      const request: Types.UpdateAbscenceRequest = {
        type: Types.UPDATE_ABSCENCE_REQUEST,
        payload: {
          requestOrigins,
          date,
          status,
        },
      }

      dispatch(request)
      const success: Types.UpdateAbscenceSuccess = {
        type: Types.UPDATE_ABSCENCE_SUCCESS,
        payload: {
          requestOrigins,
          date,
          status,
        },
      }

      dispatch(success)
    },
  validateAbsence:
    (
      requestOrigins: ReduxRequestOrigin[],
      configId: number,
      periodId: number,
      studentId: number,
      records,
      subjectId: number
    ): AppThunk =>
    async (dispatch) => {
      try {
        const request: Types.SaveAbscencesRequest = {
          type: Types.SAVE_ABSCENCES_REQUEST,
          payload: { requestOrigins },
        }
        dispatch(request)

        await AttendancesAPI.Records.updateMultiple(configId, studentId, records, subjectId)

        dispatch(
          AbscencesDrawerActionCreators.saveAbscences(
            requestOrigins,
            periodId,
            studentId,
            subjectId
          )
        )
      } catch (error) {
        handleErrorsWithAction(error, Types.SAVE_ABSCENCES_FAILURE, dispatch, {
          requestOrigins: [reduxRequestOriginMap.REPORT_CARD_GRADING_ABSENCES_DRAWER],
        })
        dispatch(
          messageActionCreators.showToast(
            'reportCard.grading.attendances.sideDrawer.errorUpdatingAttendances',
            error as AxiosError<{ description: string }>,
            {
              error: true,
            }
          )
        )
      }
    },
  saveAbscences:
    (
      requestOrigins: ReduxRequestOrigin[],
      periodId: number,
      studentId: number,
      subjectId: number
    ): AppThunk =>
    async (dispatch) => {
      const request: Types.SaveAbscencesRequest = {
        type: Types.SAVE_ABSCENCES_REQUEST,
        payload: { requestOrigins },
      }

      dispatch(request)
      try {
        await Services.ReportCard.Attendances.saveAttendances({
          periodId,
          studentId,
          subjectId,
        })

        const success: Types.SaveAbscencesSuccess = {
          type: Types.SAVE_ABSCENCES_SUCCESS,
          payload: { requestOrigins },
        }

        dispatch(success)
        dispatch(
          messageActionCreators.showToast(
            'reportCard.grading.attendances.sideDrawer.successSavingAttendances'
          )
        )
      } catch (error) {
        handleErrorsWithAction(error, Types.SAVE_ABSCENCES_FAILURE, dispatch)
        dispatch(
          messageActionCreators.showToast(
            'reportCard.grading.attendances.sideDrawer.errorSavingAttendances',
            // @ts-ignore
            error.response?.data.description || error,
            {
              error: true,
            }
          )
        )
      }
    },
}

export default AbscencesDrawerActionCreators
