import type { Cosmos, CosmosLogin, CosmosMap } from './types'
import { COSMOS_KEY, COSMOS_NAME } from './names'

const locales: string[] = ['es', 'en', 'pt', 'fr', 'ko', 'de', 'tw', 'ru', 'zh', 'ja']

const defaultLoginOptions: CosmosLogin = {
  regular: true,
  googleOneTap: false,
  googleButton: false,
  microsoft: false,
}

const AULA: Cosmos = {
  key: COSMOS_KEY.AULA,
  name: COSMOS_NAME.AULA,
  title: 'Aula - EDUCABOT',
  locale: 'es',
  localeList: locales,
  url: '',
  login: {
    regular: true,
    googleOneTap: true,
    googleButton: true,
    microsoft: true,
  },
}

const CABA: Cosmos = {
  key: COSMOS_KEY.CABA,
  name: COSMOS_NAME.CABA,
  locale: 'es',
  localeList: ['es'],
  title: 'CABA',
  url: 'createc.bue.edu.ar',
  login: {
    regular: false,
    googleOneTap: true,
    googleButton: true,
    microsoft: false,
  },
  featureOverrides: {
    hideLockedContent: true,
    showWelcomeTour: true,
    hidePremiumLibraryElements: true,
    hideRecordings: true,
    hideCourses: true,
    hideMigrations: true,
    hideAddLicense: true,
  },
  config: {
    ROBOTS_API_URL: 'https://app.createc.bue.edu.ar/api',
  },
}

const LA_RIOJA_MIN_SALUD: Cosmos = {
  key: COSMOS_KEY.LA_RIOJA_MIN_SALUD,
  name: COSMOS_NAME.LA_RIOJA_MIN_SALUD,
  locale: 'es',
  localeList: ['es'],
  title: 'Ministerio de Salud de La Rioja',
  url: 'historiadesalud.educabot.com',
  login: defaultLoginOptions,
}

const LA_RIOJA: Cosmos = {
  key: COSMOS_KEY.LA_RIOJA,
  name: COSMOS_NAME.LA_RIOJA,
  locale: 'es',
  localeList: ['es'],
  title: 'Rosario Vera Peñaloza',
  url: 'tuescuela.larioja.edu.ar',
  login: defaultLoginOptions,
  featureOverrides: {
    hidePremiumLibraryElements: true,
  },
}

const LETS_GO_FOR_MORE: Cosmos = {
  key: COSMOS_KEY.LETS_GO_FOR_MORE,
  name: COSMOS_NAME.LETS_GO_FOR_MORE,
  locale: 'es',
  localeList: ['es', 'en', 'pt'],
  title: 'Lets Go For More',
  url: 'aula.letsgoformore.com',
  login: defaultLoginOptions,
  defaultDocTypeId: 12,
}

const TED: Cosmos = {
  key: COSMOS_KEY.TED,
  name: COSMOS_NAME.TED,
  locale: 'es',
  localeList: ['es'],
  title: 'Clubes TED-Ed',
  url: 'plataforma.clubesteded.org',
  login: defaultLoginOptions,
  featureOverrides: {
    showContentsTab: true,
    hidePremiumLibraryElements: true,
    hideAppBarSubjectsTab: true,
    showTEDEmailNotificacion: true,
  },
}

const ATLAS: Cosmos = {
  key: COSMOS_KEY.ATLAS,
  name: COSMOS_NAME.ATLAS,
  locale: 'es',
  localeList: ['es'],
  title: 'Atlas grupo editorial',
  url: 'www.stude-gea.com',
  login: defaultLoginOptions,
  defaultDocTypeId: 5,
}

const AGENPIA: Cosmos = {
  key: COSMOS_KEY.AGENPIA,
  name: COSMOS_NAME.AGENPIA,
  locale: 'es',
  localeList: ['es'],
  title: 'AgenPIA',
  url: 'plataforma.agenpia.org',
  login: defaultLoginOptions,
}

export const COSMOS: CosmosMap<Cosmos> = {
  AULA,
  CABA,
  LA_RIOJA_MIN_SALUD,
  LA_RIOJA,
  LETS_GO_FOR_MORE,
  TED,
  ATLAS,
  AGENPIA,
}
