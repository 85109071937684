import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import moment from 'moment'
import type {
  SequenceAssignmentModalAction,
  SequenceAssignmentModalState,
  ActivityConfiguration,
} from '@/sections/teacher/library/types/sequenceAssignmentModal'
import {
  SET_CLASSROOM,
  GET_CLASSROOMS_FAILURE,
  GET_CLASSROOMS_SUCCESS,
  TOGGLE_SEQUENCE_MODAL_OPEN,
  GET_CLASSROOMS_REQUEST,
  GET_CLASSROOM_SUBGROUPS_REQUEST,
  GET_CLASSROOM_SUBGROUPS_SUCCESS,
  GET_CLASSROOM_SUBGROUPS_FAILURE,
  SELECT_SUBGROUPS,
  GET_SUBJECTS_REQUEST,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_FAILURE,
  SET_SUBJECT,
  SET_CLASSROOM_SEARCH,
  SET_DATE,
  SET_START_DATE,
  SET_DEADLINE,
  ASSIGN_SEQUENCE_ACTIVITIES_REQUEST,
  ASSIGN_SEQUENCE_ACTIVITIES_SUCCESS,
  ASSIGN_SEQUENCE_ACTIVITIES_FAILURE,
  SET_PUBLICATION_DATE,
  SET_CHAT_ENABLED,
  SET_ACTIVITY_IDS,
  TOGGLE_ASSIGN_MODAL_OPEN,
  SET_DURATION,
  SET_DISTRIBUTE,
  GET_SEQUENCE_REQUEST,
  GET_SEQUENCE_SUCCESS,
  GET_SEQUENCE_FAILURE,
  SET_SELECTED_UNITS,
} from '@/sections/teacher/library/types/sequenceAssignmentModal'
import { formatClassroomLabel } from '@/sections/principal/classroom-migration/components/migrationTab'
import { AVAILABILITY } from '@/sections/content-creator/sequenceDrawer/types/books'

const initialState: SequenceAssignmentModalState = {
  open: false,
  modalOpen: false,
  modalStep: 1,
  activityIds: [],
  selectedUnits: [],
  date: moment(),
  deadline: moment(),
  startDate: moment(),
  publicationDate: moment(),
  chatEnabled: false,
  subject: {
    id: 0,
    name: '',
    color: '',
  },
  selectedClassroom: {
    id: 0,
    division: '',
    grade: '',
    organization: {
      id: 0,
      name: '',
    },
    organizationType: '',
    shift: '',
    year: 0,
  },
  classrooms: [],
  subgroups: [],
  selectedSubgroups: [],
  subjects: [],
  loadingClassrooms: false,
  loadingClassroomOptions: false,
  loadingSubgroups: false,
  loadingSubjects: false,
  loadingAssignment: false,
  loadingSequence: false,
  error: '',
  lastClassroomID: 0,
  classroomSearch: '',
  sequence: {
    id: 0,
    createdAt: '',
    organization: {
      id: 0,
      name: '',
      logo: '',
    },
    title: '',
    description: '',
    book: {
      id: 0,
      title: '',
    },
    activityCount: 0,
    availability: AVAILABILITY.FREE,
    activities: [],
    units: [],
    audiences: [],
  },
}

const requestDestination = reduxRequestOriginMap.TEACHER_LIBRARY_SEQUENCE_ASSIGNMENT_MODAL

function root(
  state: SequenceAssignmentModalState = initialState,
  action: SequenceAssignmentModalAction
): SequenceAssignmentModalState {
  if (action.payload?.requestOrigin !== requestDestination) return state

  switch (action.type) {
    case GET_CLASSROOMS_REQUEST:
      return {
        ...state,
        loadingClassrooms: !action.payload.optionsLoading,
        loadingClassroomOptions: action.payload.optionsLoading,
        lastClassroomID: action.payload.lastSearchID,
      }
    case GET_CLASSROOMS_SUCCESS: {
      const { classrooms, optionsLoading } = action.payload

      return {
        ...state,
        loadingClassroomOptions: false,
        loadingClassrooms: false,
        classrooms: classrooms,
        selectedClassroom:
          optionsLoading && classrooms.length ? state.selectedClassroom : classrooms[0],
        classroomSearch:
          optionsLoading && classrooms.length
            ? state.classroomSearch
            : // @ts-ignore
              formatClassroomLabel(classrooms[0]),
      }
    }
    case GET_CLASSROOMS_FAILURE:
      return {
        ...state,
        loadingClassroomOptions: false,
        loadingClassrooms: false,
        error: action.payload.error,
      }
    case TOGGLE_SEQUENCE_MODAL_OPEN:
      if (!action.payload.open) return initialState
      return { ...state, open: action.payload.open }
    case TOGGLE_ASSIGN_MODAL_OPEN:
      return { ...state, modalOpen: action.payload.open, modalStep: action.payload.step || 1 }
    case SET_ACTIVITY_IDS:
      return { ...state, activityIds: action.payload.activityIds || [] }
    case SET_SELECTED_UNITS:
      return {
        ...state,
        selectedUnits: action.payload.newUnits,
      }
    case SET_CLASSROOM:
      return { ...state, selectedClassroom: action.payload.classroom }
    case GET_CLASSROOM_SUBGROUPS_REQUEST:
      return { ...state, loadingSubgroups: true }
    case GET_CLASSROOM_SUBGROUPS_SUCCESS: {
      const { subgroups } = action.payload
      return {
        ...state,
        loadingSubgroups: false,
        subgroups,
        selectedSubgroups: [],
      }
    }
    case GET_CLASSROOM_SUBGROUPS_FAILURE:
      return { ...state, loadingSubgroups: false, error: action.payload.error }
    case SELECT_SUBGROUPS:
      return {
        ...state,
        selectedSubgroups: action.payload.selectedSubgroups,
      }
    case GET_SUBJECTS_REQUEST: {
      return { ...state, loadingSubjects: true }
    }
    case GET_SUBJECTS_SUCCESS: {
      return {
        ...state,
        loadingSubjects: false,
        subjects: action.payload.subjects,
        subject: action.payload.subjects[0] || null,
      }
    }
    case GET_SUBJECTS_FAILURE: {
      return { ...state, loadingSubjects: false, error: action.payload.error }
    }
    case SET_SUBJECT: {
      const subjectId = action.payload.subjectId
      const subject = state.subjects.find((s) => s.id === subjectId) || state.subject
      return { ...state, subject }
    }
    case SET_CLASSROOM_SEARCH: {
      return { ...state, classroomSearch: action.payload.classroomSearch }
    }
    case SET_DATE: {
      const activityIds = state.activityIds.map((a) => {
        const newActivity = a
        if (a.id === action.payload.activityId) {
          newActivity.date = action.payload.date
          newActivity.start = action.payload.date
        }
        return newActivity
      })
      return { ...state, activityIds }
    }
    case SET_START_DATE: {
      const activityIds = state.activityIds.map((a) => {
        const newActivity = a
        if (a.id === action.payload.activityId) {
          newActivity.start = action.payload.startDate
        }
        return newActivity
      })
      return { ...state, activityIds }
    }
    case SET_DEADLINE: {
      const activityIds = state.activityIds.map((a) => {
        const newActivity = a
        if (a.id === action.payload.activityId) {
          newActivity.deadline = action.payload.deadline
        }
        return newActivity
      })
      return { ...state, activityIds }
    }
    case SET_CHAT_ENABLED: {
      const activityIds = state.activityIds.map((a) => {
        const newActivity = a
        if (a.id === action.payload.activityId) {
          newActivity.enableChat = action.payload.chatEnabled
        }
        return newActivity
      })
      return { ...state, activityIds }
    }
    case SET_DURATION: {
      const activityIds = state.activityIds.map((a) => {
        const newActivity = a
        if (a.id === action.payload.activityId) {
          newActivity.duration = action.payload.duration
          newActivity.end = moment(a.start || undefined).add(action.payload.duration, 'minutes')
        }
        return newActivity
      })
      return { ...state, activityIds }
    }
    case SET_DISTRIBUTE: {
      const activityIds = state.activityIds.map((a) => {
        const newActivity = a
        if (a.id === action.payload.activityId) {
          newActivity.distribute = action.payload.distribute
        }
        return newActivity
      })
      return { ...state, activityIds }
    }
    case SET_PUBLICATION_DATE: {
      const activityIds = state.activityIds.map((a) => {
        const newActivity = a
        if (a.id === action.payload.activityId) {
          newActivity.publicationDate = action.payload.publicationDate
        }
        return newActivity
      })
      return { ...state, activityIds }
    }
    case ASSIGN_SEQUENCE_ACTIVITIES_REQUEST: {
      return { ...state, loadingAssignment: true, error: '' }
    }
    case ASSIGN_SEQUENCE_ACTIVITIES_SUCCESS: {
      return initialState
    }
    case ASSIGN_SEQUENCE_ACTIVITIES_FAILURE: {
      return { ...state, loadingAssignment: false, error: action.payload.error }
    }
    case GET_SEQUENCE_REQUEST: {
      return { ...state, loadingSequence: true, open: true }
    }
    case GET_SEQUENCE_SUCCESS: {
      const activityIds: ActivityConfiguration[] =
        action.payload.sequence.activities.map((a) => ({
          id: a.activityId,
          date: null,
          deadline: null,
          start: null,
          end: null,
          publicationDate: null,
          duration: 0,
          distribute: false,
          enableChat: false,
        })) || []

      const selectedUnits = action.payload.sequence.units.map(({ id }) => id)

      return {
        ...state,
        loadingSequence: false,
        sequence: action.payload.sequence,
        activityIds,
        selectedUnits,
      }
    }
    case GET_SEQUENCE_FAILURE: {
      return { ...state, loadingSequence: false, error: action.payload.error }
    }
    default:
      return state
  }
}

export default root
