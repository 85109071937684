import type { Reducer } from 'redux'
import * as Types from '@/sections/report-card/config/types/editModal'

const initialState: Types.ReportCardConfigeditModalState = {
  open: false,
  loading: false,
  error: '',
  classroom: null,
}

const ReportCardConfigDeletionModal: Reducer<
  Types.ReportCardConfigeditModalState,
  Types.ReportCardConfigDeletionModalAction
> = (state = initialState, action): Types.ReportCardConfigeditModalState => {
  switch (action.type) {
    case Types.CLEAR_STATE:
      return initialState
    case Types.SET_OPEN:
      return { ...state, open: true, classroom: action.payload.classroom }
    case Types.SET_CLOSE:
      return { ...state, open: false }
    default:
      return state
  }
}

export default ReportCardConfigDeletionModal
