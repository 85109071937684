import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import type {
  DeleteUserModalAction,
  DeleteUserModalState,
} from '@/sections/management/types/users/deleteUserModal'
import { INIT_MODAL } from '@/sections/management/types/users/deleteUserModal'

const initialState: DeleteUserModalState = {
  user: null,
  open: false,
  loading: false,
  error: '',
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_DELETE_USER_MODAL

const AdminDeleteUserReducer: Reducer<DeleteUserModalState, DeleteUserModalAction> = (
  state = initialState,
  action
): DeleteUserModalState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case INIT_MODAL:
      return { ...state, user: action.payload.user, open: true, error: initialState.error }
    case Redux.Types.ManagementUsers.DELETE_USER_REQUEST:
      return { ...state, loading: true }
    case Redux.Types.ManagementUsers.DELETE_USER_SUCCESS:
      return initialState
    case Redux.Types.ManagementUsers.DELETE_USER_FAILURE:
      return { ...state, error: action.payload.error, loading: false }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, AdminDeleteUserReducer, requestDestination)
