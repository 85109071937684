import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import type {
  DashboardCourseAction,
  DashboardCourseState,
} from '@/sections/courses/dashboard/types/courseOverview'
import {
  GET_COURSE_FAILURE,
  GET_COURSE_REQUEST,
  GET_COURSE_SUCCESS,
  SET_CLOSED,
  SET_OPEN,
} from '@/sections/courses/dashboard/types/courseOverview'
import { ROLE } from '@aula/config'
import moment from 'moment'

const initialState: DashboardCourseState = {
  open: false,
  loadingCourse: false,
  course: {
    role: ROLE.STUDENT,
    activityCount: 0,
    activityTypeCount: {},
    addressedTo: '',
    coverPhoto: '',
    currency: 1,
    duration: 100,
    hasCertificate: false,
    id: 0,
    longDescription: '',
    name: '',
    organization: {
      bio: '',
      logo: '',
      logoSquare: '',
      name: '',
    },
    requirements: '',
    price: 0,
    shortDescription: '',
    tags: [],
    type: '',
    unitCount: 0,
    content: [],
    studentProgress: {
      certificateId: '',
      completedCount: 0,
      lastActivity: 0,
    },
    certificateConfiguration: {
      includeDuration: false,
      includePlace: false,
      includeDate: false,
      optionalText: '',
      organization: null,
      includeSignature: false,
      error: '',
      coverLogo: null,
      loadingLogoImage: false,
      nameText: '',
      positionText: '',
      coverImage: null,
      loadingPhoto: false,
    },
    startDate: moment(),
    endDate: moment(),
  },
  courseID: 0,
  error: '',
}

const requestDestinations: ReduxRequestOrigin[] = [
  reduxRequestOriginMap.COURSE_MODAL,
  reduxRequestOriginMap.TEACHER_LIBRARY_COURSES,
]

const CoursesReducer = (
  state: DashboardCourseState = initialState,
  action: DashboardCourseAction
): DashboardCourseState => {
  if (!requestDestinations.includes(action.payload?.requestOrigin)) return state

  switch (action.type) {
    case SET_OPEN:
      return { ...state, open: true, courseID: action.payload.courseID }
    case SET_CLOSED:
      return initialState
    case GET_COURSE_REQUEST:
      return { ...state, loadingCourse: true }
    case GET_COURSE_SUCCESS:
      return { ...state, loadingCourse: false, course: action.payload.course }
    case GET_COURSE_FAILURE:
      return { ...state, error: action.payload.error, open: false, loadingCourse: false }
    default:
      return state
  }
}

export default CoursesReducer
