import { combineReducers } from 'redux'
import examsDrawer from './reducers/examsDrawer'
import themesDrawer from './reducers/themesDrawer'
import deleteThemeModal from './reducers/deleteThemeModal'
import addEditThemeModal from './reducers/addEditThemeModal'

const managementReducer = combineReducers({
  examsDrawer,
  themesDrawer,
  deleteThemeModal,
  addEditThemeModal,
})

export default managementReducer
