import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles((theme) => ({
  container: ({ column }) => ({
    padding: '8px 16px',
    display: 'flex',
    flexDirection: column ? 'column' : 'row',
    borderRadius: 8,
    border: '1px solid #D8DBE8',
    background: '#EDEFF8',
    overflow: 'hidden',
  }),
  organizationContainer: ({ column }) => ({
    paddingRight: column ? 0 : 15,
    boxSizing: 'border-box',
    overflow: 'hidden',
  }),
  text: {
    lineHeight: '18px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  subjectContainer: ({ column }) => ({
    paddingLeft: column ? 0 : 15,
    display: 'flex',
    boxSizing: 'border-box',
    overflow: 'hidden',
  }),
  subjectDot: ({ column, color }) => ({
    minWidth: 8,
    width: 8,
    minHeight: 8,
    height: 8,
    margin: column ? '5px 8px 5px 0' : '5px 8px',
    borderRadius: 4,
    background: color,
  }),
}))

const ClassroomChip = ({ organization, subject, color, column, className, ...props }) => {
  const classes = useStyles({ column, color })
  return (
    <div className={clsx(classes.container, className)} {...props}>
      <div className={classes.organizationContainer}>
        <Typography className={classes.text}>{organization}</Typography>
      </div>
      <div className={classes.subjectContainer}>
        <div className={classes.subjectDot} />
        <Typography className={classes.text} title={subject}>
          {subject}
        </Typography>
      </div>
    </div>
  )
}

export default ClassroomChip
