export type Meta = {
  name: string
  buttonName?: string
}

export type Repeatability = 'repeatable_multiple' | 'repeatable_within' | 'non_repeatable'

export interface Template {
  id: number
  name: string
  emblem: string
}

export interface TemplateContent {
  name: string
  sections: Section[]
}

export interface Section {
  meta: Meta
  label: string
  buttonLabel: string
  repeatability: Repeatability
  fields: Field[]
}
export interface Field {
  meta: Meta
  type: FIELD_TYPE
  inclusionRequired: boolean
  answerRequired: boolean
  options: Option | Record<string, unknown>
  hide?: boolean
  isCustom?: boolean
  subfields: Subfield
}

interface Option {
  hardcoded: Hardcoded[]
  action: string
  other: boolean
}

export interface Hardcoded {
  name: string
  meta: Meta
}

type SubfieldFullName = {
  name: string
  lastName: string
}

type SubfieldCustom = Record<string, unknown>

type SubfieldAddress = {
  city: string
  street: string
  number: string
  floorApt: string
}

type SubfieldAcademicPeriod = {
  academicPeriod: string
  grade: string
}

export type Subfield = SubfieldFullName | SubfieldAddress | SubfieldAcademicPeriod | SubfieldCustom

export type NewFormField = {
  text: string
  sectionName: string
}

export enum FIELD_TYPE {
  FULL_NAME = 'full_name',
  DATE = 'date',
  IDENTIFICATION = 'identification',
  ADDRESS = 'address',
  RADIO_BUTTON = 'radio_buttons',
  PLAIN_TEXT = 'plain_text',
  SHORT_TEXT = 'short_text',
  LONG_TEXT = 'long_text',
  ACADEMIC_PERIOD_AND_GRADE = 'academic_period_and_grade',
}
