import type { Classroom } from '@/sections/teacher/classroom/types/classroom'

export interface MessageCloningState {
  classroomIdForCloning: number
  classroomsForCloning: Classroom[]
  loading: boolean
  error: Error | null
}

export enum MESSAGE_CLONING_TYPES {
  GET_CLASSROOMS_FOR_CLONING_REQUEST = 'TEACHER/BULLETIN/MESSAGE_CLONING/GET_CLASSROOMS_FOR_CLONING_REQUEST',
  GET_CLASSROOMS_FOR_CLONING_SUCCESS = 'TEACHER/BULLETIN/MESSAGE_CLONING/GET_CLASSROOMS_FOR_CLONING_SUCCESS',
  GET_CLASSROOMS_FOR_CLONING_FAILURE = 'TEACHER/BULLETIN/MESSAGE_CLONING/GET_CLASSROOMS_FOR_CLONING_FAILURE',
  SET_CLASSROOM_ID_FOR_CLONING = 'TEACHER/BULLETIN/MESSAGE_CLONING/SET_CLASSROOM_ID_FOR_CLONING',
}

export type GetClassroomsForCloningRequest = {
  type: MESSAGE_CLONING_TYPES.GET_CLASSROOMS_FOR_CLONING_REQUEST
}

export type GetClassroomsForCloningSuccess = {
  type: MESSAGE_CLONING_TYPES.GET_CLASSROOMS_FOR_CLONING_SUCCESS
  payload: {
    classrooms: Classroom[]
    defaultClassroomId: number
  }
}

export type GetClassroomsForCloningFailure = {
  type: MESSAGE_CLONING_TYPES.GET_CLASSROOMS_FOR_CLONING_FAILURE
  payload: {
    error: Error
  }
}

export type SetClassroomIdForCloning = {
  type: MESSAGE_CLONING_TYPES.SET_CLASSROOM_ID_FOR_CLONING
  payload: {
    classroomId: number
  }
}

export type MessageCloningActions =
  | GetClassroomsForCloningRequest
  | GetClassroomsForCloningSuccess
  | GetClassroomsForCloningFailure
  | SetClassroomIdForCloning
