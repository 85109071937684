import React from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '@/state/hooks'
import Component from '@/sections/student/dashboard/components/ribbon'
import activityActions from '@/sections/student/dashboard/actions/activity'
import examActions from '@/sections/student/exams/actions/onboardingModal'
import conferenceActions from '@/sections/conference/actions/conference'
import quizActions from '@/sections/quiz/common/actions/quiz'
import chatActions from '@/sections/chat/actions/chat'
import { useNavigate } from 'react-router-dom'
import robotsActions from '@/sections/robots/actions'
import backpackActions from '@/sections/backpack/actions/backpack'
import CollabMiniatureWaitingRoomActionCreators from '@/sections/student/collabMiniature/actions/collabMiniatureWaitingRoom'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'

const Ribbon = (props) => {
  const classroomID = useAppSelector((state) => state.user.classroomID)
  const channelsByKey = useAppSelector((state) => state.chat.channelsByKey)

  const activityDisplayType = useAppSelector((state) => state.display.activityDisplayType)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const getRobotsToken = (...args) => dispatch(robotsActions.getRobotsToken(...args))
  const getActivity = (...args) => dispatch(activityActions.getActivity(...args))
  const openAVV = (...args) => dispatch(conferenceActions.openAVV(...args))
  const openExamModal = (...args) => dispatch(examActions.setOpen(...args))
  const goToChat = (...args) => dispatch(chatActions.goToChat(...args))
  const getQuiz = (...args) => dispatch(quizActions.getQuizStatus(...args))
  const toggleRecordingOpen = (...args) => dispatch(backpackActions.toggleRecordingOpen(...args))
  const openCollabWaitingRoom = (activityId) =>
    dispatch(
      CollabMiniatureWaitingRoomActionCreators.setOpen(
        [reduxRequestOriginMap.COLLAB_MINIATURE_WAITING_ROOM],
        true,
        activityId
      )
    )

  const { activity } = props
  const buttonActions = {
    getActivity,
    openAVV,
    openExamModal,
    getRobotsToken,
    getQuiz,
    toggleRecordingOpen,
    openCollabWaitingRoom,
  }

  return (
    <Component
      channelsByKey={channelsByKey}
      classroomID={classroomID}
      activity={activity}
      buttonActions={buttonActions}
      goToChat={goToChat}
      navigate={navigate}
      activityDisplayType={activityDisplayType}
    />
  )
}

export default Ribbon
