import type { ReportCardAuditState } from '@/sections/report-card/config/types/audit'
import type { Reducer } from 'redux'
import * as Types from '@/sections/report-card/config/types/audit'
import { setGenericField } from '@/legacy/redux/utils'
import { Redux } from '@/legacy/redux'

const initialState: ReportCardAuditState = {
  error: '',
  loadingClassrooms: false,
  loadingVersions: false,
  classrooms: [],
  selectedClassroom: undefined,
  versions: [],
  selectedAcademicPeriod: { id: 0, name: '', default: false },
  pagination: { more: false, page: 0, rowsPerPage: 5, count: 0 },
  versionIdToDownload: 0,
  isOpenAddressesModal: false,
  sendBulletinsModal: {
    isOpen: false,
    versionToSend: null,
    isLoading: false,
    error: null,
  },
}

const ReportCardAudit: Reducer<ReportCardAuditState, Types.ReportCardAuditAction> = (
  state = initialState,
  action
): ReportCardAuditState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case Types.GET_CLASSROOMS_REQUEST:
      return { ...state, loadingClassrooms: true }
    case Types.GET_CLASSROOMS_SUCCESS:
      return { ...state, classrooms: action.payload.classrooms, loadingClassrooms: false }
    case Types.GET_CLASSROOMS_FAILURE:
      return { ...state, error: action.payload.error, loadingClassrooms: false }
    case Types.GET_REPORT_CARD_VERSIONS_REQUEST:
      return { ...state, loadingVersions: true }
    case Types.GET_REPORT_CARD_VERSIONS_SUCCESS:
      return {
        ...state,
        versions: action.payload.versions,
        pagination: {
          ...state.pagination,
          count: action.payload.count,
          page: action.payload.page,
          more: action.payload.more,
        },
        loadingVersions: false,
      }
    case Types.GET_REPORT_CARD_VERSIONS_FAILURE:
      return { ...state, error: action.payload.error, loadingVersions: false }
    case Types.SET_VERSION_ID_TO_DOWNLOAD: {
      const { versionId } = action.payload

      return {
        ...state,
        versionIdToDownload: versionId,
      }
    }
    case Types.TOGGLE_ADDRESSES_MODAL_OPEN: {
      const { canOpenModal } = action.payload

      return {
        ...state,
        isOpenAddressesModal: canOpenModal,
      }
    }
    case Types.TOGGLE_SEND_BULLETINS_MODAL_OPEN: {
      const { canOpenModal } = action.payload

      return {
        ...state,
        sendBulletinsModal: {
          ...state.sendBulletinsModal,
          isOpen: canOpenModal,
        },
      }
    }
    case Types.SET_VERSION_TO_SEND: {
      const { version } = action.payload

      return {
        ...state,
        sendBulletinsModal: {
          ...state.sendBulletinsModal,
          versionToSend: version,
        },
      }
    }
    case Types.SEND_BULLETINS_REQUEST: {
      return {
        ...state,
        sendBulletinsModal: {
          ...state.sendBulletinsModal,
          isLoading: true,
          error: null,
        },
      }
    }
    case Types.SEND_BULLETINS_SUCCESS: {
      return {
        ...state,
        sendBulletinsModal: {
          ...state.sendBulletinsModal,
          isLoading: false,
          error: null,
        },
      }
    }
    case Types.SEND_BULLETINS_FAILURE: {
      const { error } = action.payload

      return {
        ...state,
        sendBulletinsModal: {
          ...state.sendBulletinsModal,
          isLoading: false,
          error,
        },
      }
    }
    default:
      return state
  }
}

export default ReportCardAudit
