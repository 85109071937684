import type { Moment } from 'moment'

export interface MessageEventsState {
  isOpenModal: boolean
  form: BasicMsgEvent
  events: MsgEvent[]
}

export interface BasicMsgEvent {
  startDate: Moment
  startTime: Moment
  endDate: Moment
  endTime: Moment
  location: string
}

export interface MsgEvent extends BasicMsgEvent {
  id: number
  isActiveToDelete: boolean
}

export enum MESSAGE_EVENTS_TYPES {
  CLEAN_MESSAGE_EVENTS = 'TEACHER/BULLETIN/MESSAGE_EVENTS/CLEAN_MESSAGE_EVENTS',
  OPEN_MESSAGE_EVENTS_MODAL = 'TEACHER/BULLETIN/MESSAGE_EVENTS/OPEN_MESSAGE_EVENTS_MODAL',
  CLOSE_MESSAGE_EVENTS_MODAL = 'TEACHER/BULLETIN/MESSAGE_EVENTS/CLOSE_MESSAGE_EVENTS_MODAL',
  SET_EVENT_MOMENT = 'TEACHER/BULLETIN/MESSAGE_EVENTS/SET_EVENT_MOMENT',
  SET_LOCATION = 'TEACHER/BULLETIN/MESSAGE_EVENTS/SET_LOCATION',
  SET_EVENT_TO_DELETE = 'TEACHER/BULLETIN/MESSAGE_EVENTS/SET_EVENT_TO_DELETE',
  ADD_NEW_MESSAGE_EVENT = 'TEACHER/BULLETIN/MESSAGE_EVENTS/ADD_NEW_MESSAGE_EVENT',
  REMOVE_MESSAGE_EVENT = 'TEACHER/BULLETIN/MESSAGE_EVENTS/REMOVE_MESSAGE_EVENT',
  SET_MESSAGE_EVENTS = 'TEACHER/BULLETIN/MESSAGE_EVENTS/SET_MESSAGE_EVENTS',
}

export interface cleanMessageEventsAction {
  type: MESSAGE_EVENTS_TYPES.CLEAN_MESSAGE_EVENTS
}

export interface openMessageEventsModalAction {
  type: MESSAGE_EVENTS_TYPES.OPEN_MESSAGE_EVENTS_MODAL
  payload: {
    startDate: Moment
    startTime: Moment
    endDate: Moment
    endTime: Moment
  }
}

export interface closeMessageEventModalAction {
  type: MESSAGE_EVENTS_TYPES.CLOSE_MESSAGE_EVENTS_MODAL
}

export type MomentType = 'startDate' | 'startTime' | 'endDate' | 'endTime'

export interface setEventMomentAction {
  type: MESSAGE_EVENTS_TYPES.SET_EVENT_MOMENT
  payload: {
    momentType: MomentType
    newMoment: Moment
  }
}

export interface setLocationAction {
  type: MESSAGE_EVENTS_TYPES.SET_LOCATION
  payload: {
    value: string
  }
}

export interface setEventToDeleteAction {
  type: MESSAGE_EVENTS_TYPES.SET_EVENT_TO_DELETE
  payload: {
    eventId: number
  }
}

export interface addNewMessageEventAction {
  type: MESSAGE_EVENTS_TYPES.ADD_NEW_MESSAGE_EVENT
  payload: {
    newId: number
  }
}

export interface removeMessageEventAction {
  type: MESSAGE_EVENTS_TYPES.REMOVE_MESSAGE_EVENT
}

export interface setMessageEventsAction {
  type: MESSAGE_EVENTS_TYPES.SET_MESSAGE_EVENTS
  payload: {
    events: MsgEvent[]
  }
}

export type MessageEventsActions =
  | cleanMessageEventsAction
  | openMessageEventsModalAction
  | closeMessageEventModalAction
  | setEventMomentAction
  | setLocationAction
  | setEventToDeleteAction
  | addNewMessageEventAction
  | removeMessageEventAction
  | setMessageEventsAction
