import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { Pages } from '@/sections/content-creator/dashboard/types/dashboard'
import type * as premiumActivitiesTypes from '@/sections/content-creator/premium-activities/types/premium-activities'
import type { CourseCertificateConfiguration } from '@/sections/courses/dashboard/types/courses'

export const SET_OPEN = 'CONTENT_CREATOR/COURSES/SET_OPEN'
export const SET_CLOSED = 'CONTENT_CREATOR/COURSES/SET_CLOSED'
export const SET_NAME = 'CONTENT_CREATOR/COURSES/SET_NAME'
export const SET_SHORT_DESCRIPTION = 'CONTENT_CREATOR/COURSES/SET_SHORT_DESCRIPTION'
export const SET_LONG_DESCRIPTION = 'CONTENT_CREATOR/COURSES/SET_LONG_DESCRIPTION'
export const SET_REQUIREMENTS = 'CONTENT_CREATOR/COURSES/SET_REQUIREMENTS'
export const SET_ADDRESSED_TO = 'CONTENT_CREATOR/COURSES/SET_ADDRESSED_TO'
export const SET_PRICE = 'CONTENT_CREATOR/COURSES/SET_PRICE'
export const SET_CURRENCY = 'CONTENT_CREATOR/COURSES/SET_CURRENCY'
export const SET_TYPE = 'CONTENT_CREATOR/COURSES/SET_TYPE'
export const SET_TAGS = 'CONTENT_CREATOR/COURSES/SET_TAGS'
export const SET_COVER_PHOTO = 'CONTENT_CREATOR/COURSES/SET_COVER_PHOTO'
export const SET_DURATION = 'CONTENT_CREATOR/COURSES/SET_DURATION'
export const ADD_ENTITY = 'CONTENT_CREATOR/COURSES/ADD_ENTITY'
export const REMOVE_ACTIVITY = 'CONTENT_CREATOR/COURSES/REMOVE_ACTIVITY'
export const REMOVE_ENTITY = 'CONTENT_CREATOR/COURSES/REMOVE_ENTITY'
export const SET_SELECTED_ENTITIES = 'CONTENT_CREATOR/COURSES/SET_SELECTED_ENTITIES'
export const SET_CERTIFICATE_ACTIVATED = 'CONTENT_CREATOR/COURSES/SET_CERTIFICATE_ACTIVATED'
export const SET_OPEN_MODAL_CERTIFICATE = 'CONTENT_CREATOR/COURSES/SET_OPEN_MODAL_CERTIFICATE'
export const SET_CLOSE_MODAL_CERTIFICATE = 'CONTENT_CREATOR/COURSES/SET_CLOSE_MODAL_CERTIFICATE'

export const CREATE_COURSE_REQUEST = 'CONTENT_CREATOR/COURSES/CREATE_COURSE_REQUEST'
export const CREATE_COURSE_SUCCESS = 'CONTENT_CREATOR/COURSES/CREATE_COURSE_SUCCESS'
export const CREATE_COURSE_FAILURE = 'CONTENT_CREATOR/COURSES/CREATE_COURSE_FAILURE'

export const EDIT_COURSE_REQUEST = 'CONTENT_CREATOR/COURSES/EDIT_COURSE_REQUEST'
export const EDIT_COURSE_SUCCESS = 'CONTENT_CREATOR/COURSES/EDIT_COURSE_SUCCESS'
export const EDIT_COURSE_FAILURE = 'CONTENT_CREATOR/COURSES/EDIT_COURSE_FAILURE'

export const DELETE_COURSE_REQUEST = 'CONTENT_CREATOR/COURSES/DELETE_COURSE_REQUEST'
export const DELETE_COURSE_SUCCESS = 'CONTENT_CREATOR/COURSES/DELETE_COURSE_SUCCESS'
export const DELETE_COURSE_FAILURE = 'CONTENT_CREATOR/COURSES/DELETE_COURSE_FAILURE'

export const GET_COURSE_REQUEST = 'CONTENT_CREATOR/COURSES/GET_COURSE_REQUEST'
export const GET_COURSE_SUCCESS = 'CONTENT_CREATOR/COURSES/GET_COURSE_SUCCESS'
export const GET_COURSE_FAILURE = 'CONTENT_CREATOR/COURSES/GET_COURSE_FAILURE'

export const LIST_COURSES_REQUEST = 'CONTENT_CREATOR/COURSES/LIST_COURSES_REQUEST'
export const LIST_COURSES_SUCCESS = 'CONTENT_CREATOR/COURSES/LIST_COURSES_SUCCESS'
export const LIST_COURSES_FAILURE = 'CONTENT_CREATOR/COURSES/LIST_COURSES_FAILURE'

export const ADD_IMAGE_REQUEST = 'CONTENT_CREATOR/COURSES/ADD_IMAGE_REQUEST'
export const ADD_IMAGE_SUCCESS = 'CONTENT_CREATOR/COURSES/ADD_IMAGE_SUCCESS'
export const ADD_IMAGE_FAILURE = 'CONTENT_CREATOR/COURSES/ADD_IMAGE_FAILURE'
export const REMOVE_IMAGE = 'CONTENT_CREATOR/COURSES/REMOVE_IMAGE'

export const COURSE_INFORMATION_STEP = 'COURSE_CREATION_MODAL/COURSE_INFORMATION_STEP'
export const COURSE_MAIN_STEP = 'COURSE_CREATION_MODAL/COURSE_MAIN_STEP'

export const EDIT_UNIT = 'COURSE_CREATION_MODAL/EDIT_UNIT'

export const SET_COURSE_DELETION_MODAL_OPEN =
  'CONTENT_CREATOR/COURSES/SET_COURSE_DELETION_MODAL_OPEN'

export const TOGGLE_AVAILABLE_IN_LIBRARY = 'CONTENT_CREATOR/COURSES/TOGGLE_AVAILABLE_IN_LIBRARY'

export type CourseCreationStep = typeof COURSE_INFORMATION_STEP | typeof COURSE_MAIN_STEP

export const COURSE_TYPES = {
  AUTONOMOUS: 'autonomous',
  GUIDED: 'guided',
}

export type CourseType = typeof COURSE_TYPES.AUTONOMOUS | typeof COURSE_TYPES.GUIDED

export const CURRENCY_TYPES = {
  ARS: 1,
  USD: 2,
}
export type Currency = typeof CURRENCY_TYPES.ARS | typeof CURRENCY_TYPES.USD

export const COURSE_ENTITY_TYPES = {
  ACTIVITY: 'activity',
  UNIT: 'unit',
}
export type CourseEntityType = typeof COURSE_ENTITY_TYPES.ACTIVITY | typeof COURSE_ENTITY_TYPES.UNIT
export interface CourseActivity {
  title: string
  order: number
  id: number
  type: premiumActivitiesTypes.ActivityType
  entityType: typeof COURSE_ENTITY_TYPES.ACTIVITY
}

export interface CourseUnit {
  title: string
  order: number
  id: number
  entityType: typeof COURSE_ENTITY_TYPES.UNIT
  modified?: boolean
}

export type CourseEntity = CourseActivity | CourseUnit

export type CourseContentItem = {
  activity?: {
    activityId: number
    id: number
    organizationId: number
    title: string
    type: string
  }
  order: number
  resourceId: number
  text: string
  type: CourseEntityType
}

export type Organizations = {
  organizationId: number
  logo: string
}

export type Course = {
  id: number
  organizationId: number
  name: string
  shortDescription: string
  longDescription: string
  requirements: string
  addressedTo: string
  currency: Currency
  price: number
  duration: number
  type: CourseType
  coverPhoto: string
  tags: string[]
  organizations: Organizations[]
  unitCount: number
  activityCount: number
  activityTypeCount: any
  content: CourseContentItem[]
  certificateConfiguration: CourseCertificateConfiguration
  availableInLibrary: boolean
}

export interface ToggleAvailableInLibrary {
  type: typeof TOGGLE_AVAILABLE_IN_LIBRARY
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}
export interface CreateCourseRequest {
  type: typeof CREATE_COURSE_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface CreateCourseSuccess {
  type: typeof CREATE_COURSE_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface CreateCourseFailure {
  type: typeof CREATE_COURSE_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface EditCourseRequest {
  type: typeof EDIT_COURSE_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface EditCourseSuccess {
  type: typeof EDIT_COURSE_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface EditCourseFailure {
  type: typeof EDIT_COURSE_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface DeleteCourseRequest {
  type: typeof DELETE_COURSE_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface DeleteCourseSuccess {
  type: typeof DELETE_COURSE_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface DeleteCourseFailure {
  type: typeof DELETE_COURSE_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface GetCourseRequest {
  type: typeof GET_COURSE_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
    courseId: number
  }
}

export interface GetCourseSuccess {
  type: typeof GET_COURSE_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    course: Course
    cloneMode: boolean
    editMode: boolean
  }
}

export interface GetCourseFailure {
  type: typeof GET_COURSE_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface ListCoursesRequest {
  type: typeof LIST_COURSES_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
    page: number
    rowsPerPage: number
  }
}

export interface ListCoursesSuccess {
  type: typeof LIST_COURSES_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    courses: Course[]
    count: number
  }
}

export interface ListCoursesFailure {
  type: typeof LIST_COURSES_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    requestOrigin: ReduxRequestOrigin
    step: CourseCreationStep
  }
}

export interface SetClosed {
  type: typeof SET_CLOSED
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface SetName {
  type: typeof SET_NAME
  payload: {
    requestOrigin: ReduxRequestOrigin
    name: string
  }
}

export interface SetLongDescription {
  type: typeof SET_LONG_DESCRIPTION
  payload: {
    requestOrigin: ReduxRequestOrigin
    longDescription: string
  }
}

export interface SetShortDescription {
  type: typeof SET_SHORT_DESCRIPTION
  payload: {
    requestOrigin: ReduxRequestOrigin
    shortDescription: string
  }
}

export interface SetAddressedTo {
  type: typeof SET_ADDRESSED_TO
  payload: {
    requestOrigin: ReduxRequestOrigin
    addressedTo: string
  }
}

export interface SetRequirements {
  type: typeof SET_REQUIREMENTS
  payload: {
    requestOrigin: ReduxRequestOrigin
    requirements: string
  }
}

export interface SetCurrency {
  type: typeof SET_CURRENCY
  payload: {
    requestOrigin: ReduxRequestOrigin
    currency: number
  }
}

export interface SetPrice {
  type: typeof SET_PRICE
  payload: {
    requestOrigin: ReduxRequestOrigin
    price: number
  }
}

export interface SetDuration {
  type: typeof SET_DURATION
  payload: {
    requestOrigin: ReduxRequestOrigin
    duration: number
  }
}

export interface SetType {
  type: typeof SET_TYPE
  payload: {
    requestOrigin: ReduxRequestOrigin
    type: CourseType
  }
}

export interface SetTags {
  type: typeof SET_TAGS
  payload: {
    requestOrigin: ReduxRequestOrigin
    tags: string[]
  }
}

export interface SetCoverPhoto {
  type: typeof SET_COVER_PHOTO
  payload: {
    requestOrigin: ReduxRequestOrigin
    coverPhoto: string
  }
}

export interface SetSelectedEntities {
  type: typeof SET_SELECTED_ENTITIES
  payload: {
    requestOrigin: ReduxRequestOrigin
    selectedEntities: CourseEntity[]
  }
}

export interface AddEntity {
  type: typeof ADD_ENTITY
  payload: {
    requestOrigin: ReduxRequestOrigin
    entity: CourseEntity
  }
}

export interface RemoveActivity {
  type: typeof REMOVE_ACTIVITY
  payload: {
    requestOrigin: ReduxRequestOrigin
    activityId: number
  }
}

export interface RemoveEntity {
  type: typeof REMOVE_ENTITY
  payload: {
    requestOrigin: ReduxRequestOrigin
    entityIndex: number
  }
}

export interface AddImageRequest {
  type: typeof ADD_IMAGE_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface AddImageSuccess {
  type: typeof ADD_IMAGE_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    url: string
  }
}

export interface AddImageFailure {
  type: typeof ADD_IMAGE_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface RemoveImage {
  type: typeof REMOVE_IMAGE
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface EditUnit {
  type: typeof EDIT_UNIT
  payload: {
    requestOrigin: ReduxRequestOrigin
    title: string
    index: number
  }
}

export interface SetCourseDeletionModalOpen {
  type: typeof SET_COURSE_DELETION_MODAL_OPEN
  payload: {
    requestOrigin: ReduxRequestOrigin
    open: boolean
    courseId?: number
  }
}

export interface SetCertificateActivated {
  type: typeof SET_CERTIFICATE_ACTIVATED
  payload: {
    requestOrigin: ReduxRequestOrigin
    certificateActivated: boolean
  }
}

export interface SetOpenModalCertificate {
  type: typeof SET_OPEN_MODAL_CERTIFICATE
  payload: {
    requestOrigin: ReduxRequestOrigin
    modalCertificateOpen: boolean
  }
}

export interface SetCloseModalCertificate {
  type: typeof SET_CLOSE_MODAL_CERTIFICATE
  payload: {
    requestOrigin: ReduxRequestOrigin
    modalCertificateOpen: boolean
  }
}

export type CourseCreationDrawerState = {
  open: boolean
  step: CourseCreationStep
  name: string
  shortDescription: string
  longDescription: string
  requirements: string
  addressedTo: string
  currency: Currency
  price: number
  duration: number
  type: CourseType
  coverPhoto: string | null
  selectedEntities: CourseEntity[]
  tags: string[]
  activities: premiumActivitiesTypes.PremiumActivity[]
  loadingActivities: boolean
  loading: boolean
  activitiesPagination: Pages
  editMode: boolean
  cloneMode: boolean
  selectedCourseId: number
  error: string
  loadingCoverPhoto: boolean
  courseDeletionModalOpen: boolean
  certificateActivated: boolean
  modalCertificateOpen: boolean
  availableInLibrary: boolean
}

export type ContentCreatorCourseAction =
  | CreateCourseRequest
  | CreateCourseSuccess
  | CreateCourseFailure
  | EditCourseRequest
  | EditCourseSuccess
  | EditCourseFailure
  | DeleteCourseRequest
  | DeleteCourseSuccess
  | DeleteCourseFailure
  | GetCourseRequest
  | GetCourseSuccess
  | GetCourseFailure
  | ListCoursesRequest
  | ListCoursesSuccess
  | ListCoursesFailure
  | SetOpen
  | SetClosed
  | SetName
  | SetLongDescription
  | SetShortDescription
  | SetTags
  | SetType
  | SetAddressedTo
  | SetRequirements
  | SetCurrency
  | SetPrice
  | SetCoverPhoto
  | SetDuration
  | SetSelectedEntities
  | SetCertificateActivated
  | SetOpenModalCertificate
  | SetCloseModalCertificate
  | AddEntity
  | RemoveActivity
  | RemoveEntity
  | AddImageRequest
  | AddImageSuccess
  | AddImageFailure
  | RemoveImage
  | EditUnit
  | SetCourseDeletionModalOpen
  | premiumActivitiesTypes.GetPremiumActivitiesRequest
  | premiumActivitiesTypes.GetPremiumActivitiesSuccess
  | premiumActivitiesTypes.GetPremiumActivitiesFailure
  | ToggleAvailableInLibrary
