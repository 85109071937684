import * as Types from '@/sections/teacher/bulletin/types/uploadImages'

const initialState: Types.UploadImagesState = {
  images: [],
}

const uploadImagesReducer = (
  state: Types.UploadImagesState = initialState,
  action: Types.UploadImagesActions
): Types.UploadImagesState => {
  switch (action.type) {
    case Types.UPLOAD_IMAGES_TYPES.SET_UPLOAD_IMAGES: {
      return {
        images: [...state.images, ...action.payload.images],
      }
    }
    case Types.UPLOAD_IMAGES_TYPES.CLEAN_IMAGES: {
      return initialState
    }
    default:
      return state
  }
}

export default uploadImagesReducer
