import * as React from 'react'
import type { SVGProps } from 'react'
const SvgThursday = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.852 1v3h10V1h2v3h2a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3h-18a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h2V1h2Zm12 5h-16a1 1 0 0 0-1 1v2h20V7a1 1 0 0 0-1-1h-2Zm-17 5v10a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V11h-20Z"
      fill="#1A1A1A"
    />
    <path
      d="M7.051 19.578c-.612 0-1.104-.171-1.476-.513-.366-.348-.549-.84-.549-1.476h1.152c.006.294.075.528.207.702.132.174.345.26.64.26.275 0 .476-.08.602-.242.126-.168.19-.387.19-.657V13.17h1.151v4.482c0 .618-.174 1.095-.522 1.43-.342.33-.807.496-1.395.496Zm5.573 0c-.462 0-.879-.09-1.25-.27a2.135 2.135 0 0 1-.892-.837c-.216-.372-.324-.843-.324-1.413V13.17h1.152v3.897c0 .492.117.858.351 1.098.24.24.57.36.99.36.414 0 .741-.12.981-.36s.36-.606.36-1.098V13.17h1.152v3.888c0 .57-.114 1.04-.342 1.413a2.166 2.166 0 0 1-.918.837c-.378.18-.798.27-1.26.27Zm3.696-.108v-6.3h4.113v.927h-2.96v1.728h2.69v.9h-2.69v1.818h2.96v.927H16.32Z"
      fill="#000"
    />
  </svg>
)
export default SvgThursday
