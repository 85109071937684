import React from 'react'
import { makeStyles } from '@mui/styles'
import { Grid, LinearProgress, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import renderEmoji from '@/legacy/components/v1/utils/renderEmoji'
import chroma from 'chroma-js'

const useStyles = makeStyles((theme) => ({
  container: {
    border: '1px solid #DFDFDA',
    boxSizing: 'border-box',
    position: 'relative',
    borderRadius: 8,
    background: 'white',
  },
  loadingContainer: {
    background: 'rgba(255,255,255,0.8)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
  },
  hiContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: '24px 0 24px 25px',
      borderRight: '1px solid #DFDFDA',
    },
    [theme.breakpoints.down('xs')]: {
      borderBottom: '1px solid #DFDFDA',
      padding: 12,
    },
  },
  classroom: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    marginBottom: 4,
  },
  name: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '31px',
    letterSpacing: '0.01em',
  },
  wavingGridContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  wavingHandContainer: {
    width: 64,
    height: 64,
    background: chroma('#27A8FF').alpha(0.2).hex(),
    borderRadius: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 36,
      height: 36,
      borderRadius: 36,
    },
  },
  wavingHand: {
    fontSize: 36,
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  progressContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: '29px 0 17px 24px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '12px 12px 8px 12px',
    },
  },
  weekProgress: {
    color: '#302666',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '21px',
    paddingBottom: 8,
    borderBottom: '1px solid #DFDFDA',
    marginBottom: 16,
  },
  itemProgress: {
    color: '#1A1A1A',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '18px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 16,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 8,
    },
  },
  completedContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: '24px 24px 24px 24px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 12px 12px 12px',
    },
  },
  completedWrapper: {
    padding: '17px 19px 15px 19px',
    background: chroma('#27A8FF').alpha(0.15).hex(),
    borderRadius: 8,
    [theme.breakpoints.up('sm')]: {
      padding: '17px 19px 15px 19px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 12,
    },
  },
  remainBarContainer: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  remain: {
    fontWeight: '500',
    letterSpacing: '0.01em',
    color: '#302666',
    marginBottom: 5,
    marginRight: 11,
  },
  completedNumber: {
    fontSize: '24px',
    lineHeight: '31px',
  },
  bar: {
    height: 10,
    background: 'white',
    borderRadius: 3,
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      flex: 1,
    },
  },
  completedLabel: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 16,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 4,
    },
    fontSize: '14px',
    lineHeight: '18px',
  },
}))

const renderStudentCardMiddle = (classes, today, late, week) => {
  return (
    <Grid xs={12} sm={5} item className={classes.progressContainer}>
      <Typography className={classes.weekProgress}>{'Mi Progreso:'}</Typography>
      <Typography className={classes.itemProgress}>
        {renderEmoji('🤓')}Tenés <b>{today}</b> actividades asignadas para hoy.
      </Typography>
      <Typography className={classes.itemProgress}>
        {renderEmoji(':warning:')}Tenés <b>{late}</b> actividades demoradas.
      </Typography>
      <Typography className={classes.itemProgress}>
        {renderEmoji(':calendar:')}Tenés <b>{week}</b> actividades asignadas para esta semana.
      </Typography>
    </Grid>
  )
}

const renderTeacherCardMiddle = (classes, today, live, week) => {
  return (
    <Grid xs={12} sm={5} item className={classes.progressContainer}>
      <Typography className={classes.weekProgress}>{'Esta semana:'}</Typography>
      <Typography className={classes.itemProgress}>
        {renderEmoji('🤓')}Tenés <b>{today}</b> clases en vivo hoy.
      </Typography>
      <Typography className={classes.itemProgress}>
        {renderEmoji(':camera:')}Programaste <b>{live}</b> clases en vivo para esta semana.
      </Typography>
      <Typography className={classes.itemProgress}>
        {renderEmoji(':calendar:')}Creaste <b>{week}</b> tareas para que tus estudiantes completen
        esta semana.
      </Typography>
    </Grid>
  )
}

export default function ProgressCard(props) {
  const { classroom, name, completedLabel, loading = false, teacher } = props
  const { today, late, week, live, progressCurrent, progressMax } = props
  const classes = useStyles()
  const percentageCompleted = (progressCurrent / progressMax) * 100

  return (
    <div className={classes.container}>
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress color="secondary" size="50px" variant="indeterminate" />
        </div>
      )}
      <Grid container>
        <Grid xs={12} sm={4} container item className={classes.hiContainer}>
          <Grid item sm={12} xs={8}>
            <Typography className={classes.classroom}>{classroom}</Typography>
            <Typography className={classes.name}>Hola {name}!</Typography>
          </Grid>
          <Grid sm={12} xs={4} item className={classes.wavingGridContainer}>
            <div className={classes.wavingHandContainer}>
              <Typography className={classes.wavingHand}>{renderEmoji(':waving_hand:')}</Typography>
            </div>
          </Grid>
        </Grid>
        {teacher
          ? renderTeacherCardMiddle(classes, today, live, week)
          : renderStudentCardMiddle(classes, today, late, week)}
        <Grid xs={12} sm={3} item className={classes.completedContainer}>
          <div className={classes.completedWrapper}>
            <Grid container className={classes.remainBarContainer}>
              <Typography className={classes.remain}>
                <span className={classes.completedNumber}>{progressCurrent}</span>/{progressMax}{' '}
                {teacher && 'tareas corregidas'}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={percentageCompleted}
                classes={{ root: classes.bar }}
              />
            </Grid>
            <Typography className={classes.completedLabel}>{completedLabel}</Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

ProgressCard.defaultValue = {
  loading: true,
  classroom: '',
  name: '',
  today: 0,
  late: 0,
  week: 0,
  progressCurrent: 0,
  progressMax: 1,
  progressLabel: '',
}
