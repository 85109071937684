import type { OrganizationOption } from './common'

export const OPEN_DRAWER = 'EB_ADMIN/OPEN_DRAWER'
export const CLOSE_DRAWER = 'EB_ADMIN/CLOSE_DRAWER'
export const SET_ORGANIZATION = 'EB_ADMIN/SET_ORGANIZATION'
export const SET_GLOBAL_SEARCH = 'EB_ADMIN/SET_GLOBAL_SEARCH'
export const DASHBOARD_REQUEST = 'EB_ADMIN/DASHBOARD_REQUEST'
export const DASHBOARD_SUCCESS = 'EB_ADMIN/DASHBOARD_SUCCESS'
export const DASHBOARD_FAILURE = 'EB_ADMIN/DASHBOARD_FAILURE'
export const ORGANIZATION_REQUEST = 'EB_ADMIN/ORGANIZATION_REQUEST'
export const ORGANIZATION_SUCCESS = 'EB_ADMIN/ORGANIZATION_SUCCESS'
export const ORGANIZATION_FAILURE = 'EB_ADMIN/ORGANIZATION_FAILURE'

export interface EbEvaluatorState {
  open: boolean
  selected: string
  selectedOrganization: OrganizationOption
  lastSearchID: number
  loadingOrganizations: boolean
  loading: boolean
  organizations: number
  organizationOptions: OrganizationOption[]
  globalSearch: string
  exams: number
  themes: number
}

export interface DashboardRequest {
  type: typeof DASHBOARD_REQUEST
}

export interface DashboardSuccess {
  type: typeof DASHBOARD_SUCCESS
  payload: {
    exams: number,
    themes: number,
  }
}

export interface DashboardFailure {
  type: typeof DASHBOARD_FAILURE
  payload: {
    error: string
  }
}

export interface OrganizationRequest {
  type: typeof ORGANIZATION_REQUEST
  payload: {
    lastSearchID: number
  }
}

export interface OrganizationSuccess {
  type: typeof ORGANIZATION_SUCCESS
  payload: {
    organizations: OrganizationOption[]
  }
}

export interface OrganizationFailure {
  type: typeof ORGANIZATION_FAILURE
  payload: {
    error: string
  }
}

export interface SetOrganization {
  type: typeof SET_ORGANIZATION
  payload: {
    selectedOrganization: OrganizationOption
  }
}

export interface SetGlobalSearch {
  type: typeof SET_GLOBAL_SEARCH
  payload: {
    search: string
  }
}

export interface OpenDrawer {
  type: typeof OPEN_DRAWER
  payload: {
    selected: string
  }
}

export interface CloseDrawer {
  type: typeof CLOSE_DRAWER
}

export type EbEvaluatorActions =
  | OpenDrawer
  | CloseDrawer
  | DashboardRequest
  | DashboardSuccess
  | DashboardFailure
  | OrganizationRequest
  | OrganizationSuccess
  | OrganizationFailure
  | SetOrganization
  | SetGlobalSearch
