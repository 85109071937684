import React from 'react'
import { Switch as MUISwitch } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 53,
    height: 30,
    padding: 0,
    margin: '0 8px',
  },
  switchBase: {
    padding: 3,
    '&$checked': {
      transform: 'translateX(19px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
    '&:hover': {
      backgroundColor: 'unset !important',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 30,
    boxSizing: 'border-box',
    border: '1px solid #D8DBE8',
    background: `${theme.palette.background.default} !important`,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
  icon: {
    width: 28,
    height: 24,
    fontSize: 12,
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
  checkCircleIcon: {
    fill: '#50D174',
  },
  controlLabel: {
    margin: 0,
  },
}))

const Switch = ({ id, selected, setSelected }) => {
  const classes = useStyles()
  return (
    <MUISwitch
      id={id}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={selected}
      onChange={(e) => setSelected(e.target.checked)}
      icon={<Icon label="No" />}
      checkedIcon={<Icon label="Sí" primary />}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
    />
  )
}

export default Switch

const useIconStyles = makeStyles((theme) => ({
  icon: ({ primary }) => ({
    width: 26,
    height: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 30,
    border: 'solid 1px',
    borderColor: primary ? theme.palette.primary.main : '#d8dbd8',
    background: primary ? theme.palette.primary.main : '#fff',
  }),
  text: ({ primary }) => ({
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: primary ? '#fff' : theme.palette.primary.main,
  }),
}))

const Icon = ({ label, primary }) => {
  const classes = useIconStyles({ primary })
  return (
    <div className={classes.icon}>
      <Typography className={classes.text}>{label}</Typography>
    </div>
  )
}
