/**
 * Ignore properties of an object, useful when is needed omit multiple properties from an object.
 * @example omitProps({ foo: 'foo', bar: 'bar' }, ['foo']) // { bar: 'bar' }
 * @template T
 * @template K
 * @param {T} obj
 * @param {K[]} omitablesKeys string array of ignorable keys
 */
export const omitProps = <T, K extends keyof T>(obj: T, omitablesKeys: K[]) =>
  Object.keys(obj).reduce((newObj, key) => {
    if (omitablesKeys.includes(key as K)) return newObj
    return {
      ...newObj,
      [key]: obj[key],
    }
  }, {} as Omit<T, K>)

/**
 * Remove properties of an object that are either `null` , `''` (empty string) or `undefined` .
 * @param {T} obj the object whose properties are to be checked
 * @param {K[]} whiteList props not to be ckecked
 * @example const obj = { prop1: 'foo', prop2: false, prop3: null, prop4: '', prop5: undefined }
 * removeFalsyProps(obj) // { prop1: 'foo', prop2: 'bar', prop3: false }
 * @example const obj = { prop1: 'foo', prop2: false, prop3: null, prop4: '', prop5: undefined }
 * removeFalsyProps(obj, ['prop4']) // { prop1: 'foo', prop2: 'bar', prop3: false, prop4: '' }
 */
export const removeNullOrEmptyParams = <T extends Record<string, any>, K extends keyof T>(
  obj: T,
  whiteList?: K[]
): Partial<T> => {
  const o = {}
  for (const [key, value] of Object.entries(obj)) {
    if (whiteList?.includes(key as K)) {
      o[key] = value
    } else {
      if ((value === null || value) === '' || value === undefined) continue
      o[key] = value
    }
  }
  return o
}
