import type { Reducer } from 'redux'
import * as Types from '@/sections/report-card/config/types/config'
import type {
  ReportCardConfigAction,
  ReportCardConfigState,
} from '@/sections/report-card/config/types/config'

const initialState: ReportCardConfigState = {
  loading: false,
  error: '',
  config: {
    absences: {
      available: false,
      name: '',
    },
    attitudinalScoreValues: [],
    attitudinalScores: {
      available: false,
      evaluableFlag: false,
      name: '',
    },
    classroomId: 0,
    competenceScoreTypes: [],
    competenceScoreValues: [],
    competenceScores: {
      editable: false,
      available: false,
      name: '',
    },
    conceptScores: {
      scoresSubject: [],
      values: [],
      scales: [],
    },
    enableScores: true,
    header: '',
    id: 0,
    numericalScores: {
      id: 0,
      scale: 0,
      scoresSubject: [],
    },
    organizationId: 0,
    periods: [],
    reviews: {
      available: false,
      bySubject: false,
      byAttitudinal: false,
      name: '',
    },
    scores: {
      available: false,
      name: '',
    },
    status: '',
    finalMark: false,
    cover: '',
    studentSignature: false,
    hasExtraPeriod: false,
    signatures: [],
    secondCover: '',
  },
}

const ReportCardConfig: Reducer<ReportCardConfigState, ReportCardConfigAction> = (
  state = initialState,
  action
): ReportCardConfigState => {
  switch (action.type) {
    case Types.GET_REPORT_CARD_CONFIG_REQUEST:
      return { ...state, loading: true }
    case Types.GET_REPORT_CARD_CONFIG_SUCCESS:
      return { ...state, loading: false, config: action.payload.config }
    case Types.GET_REPORT_CARD_CONFIG_FAILURE:
      return { ...state, error: action.payload.error }
    case Types.REPORT_CARD_ENABLE_SCORES_REQUEST:
      return { ...state, loading: true }
    case Types.REPORT_CARD_ENABLE_SCORES_SUCCESS:
      return {
        ...state,
        loading: false,
        config: {
          ...state.config,
          enableScores: action.payload.enableScores,
        },
      }
    case Types.REPORT_CARD_ENABLE_SCORES_FAILURE:
      return { ...state, error: action.payload.error }
    default:
      return state
  }
}

export default ReportCardConfig
