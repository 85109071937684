import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import type {
  DetailsExamModalAction,
  DetailsExamModalState,
} from '@/sections/management/types/detailsExamModal'
import { SET_INITIAL_VALUES, CLEAR_FIELDS } from '@/sections/management/types/detailsExamModal'

const initialState: DetailsExamModalState = {
  open: false,
  loading: false,
  error: '',
  id: '',
  name: '',
  classroomId: 0,
  organizationId: 0,
  theme: 0,
  games: [],
  datetime: '',
  deadline: '',
  duration: 0,
  type: 0,
  stage: 0,
  code: '',
  teacherCode: '',
  maxStudents: 0,
  maxStudentsCount: 0,
  cue: '',
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_DETAILS_EXAM_MODAL

const DetailsExamModalReducer: Reducer<DetailsExamModalState, DetailsExamModalAction> = (
  state = initialState,
  action
): DetailsExamModalState => {
  switch (action.type) {
    case CLEAR_FIELDS: {
      return {
        ...initialState,
      }
    }
    case SET_INITIAL_VALUES: {
      const { exam } = action.payload
      return {
        ...state,
        open: true,
        id: exam.id,
        name: exam.name,
        classroomId: exam.classroomId,
        organizationId: exam.organizationId,
        theme: exam.theme,
        games: exam.games,
        datetime: exam.datetime,
        deadline: exam.deadline,
        duration: exam.duration,
        type: exam.type,
        stage: exam.stage,
        code: exam.code,
        teacherCode: exam.teacherCode,
        maxStudents: exam.maxStudents,
        maxStudentsCount: exam.maxStudentsCount,
        cue: exam.cue,
      }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, DetailsExamModalReducer, requestDestination)
