import { combineReducers } from 'redux'
import TedMainViewReducer from './reducers/contents'
import TedGatheringsReducer from './reducers/gatherings'
import TedVideosReducer from './reducers/videos'
import TedVideoModalsReducer from './reducers/videoModal'
import VideoAttachmentsReducer from './reducers/videoAttachments'
import AddFacilitatorModalReducer from './reducers/addFacilitatorModal'
import TedGroupsReducer from './reducers/progress'
import EditModalReducer from './reducers/editGroupModal'

export default combineReducers({
  main: TedMainViewReducer,
  gatherings: TedGatheringsReducer,
  videos: TedVideosReducer,
  videosModals: TedVideoModalsReducer,
  videoAttachments: VideoAttachmentsReducer,
  addFacilitatorModal: AddFacilitatorModalReducer,
  progress: TedGroupsReducer,
  editModal: EditModalReducer,
})
