import { combineReducers } from 'redux'
import forms from './formView/reducers/forms'
import generateFormsModal from './generateFormsModal/reducers/generateFormsModal'
import answersDrawer from './answers/reducers/answersDrawer'
import academicModal from './academicModal/reducers/academicModal'

export default combineReducers({
  forms,
  generateFormsModal,
  academicModal,
  answersDrawer,
})
