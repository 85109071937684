import React, { useMemo } from 'react'
import type { SVGComponent } from '@aula/config'
import type { OverridableComponent } from '@mui/material/OverridableComponent'
import type { Theme } from '@mui/material'
import { Fade } from '@mui/material'
import { makeStyles } from '@mui/styles'
import DotsAnimation from './dotsAnimation'
import clsx from 'clsx'

export enum Variant {
  DEFAULT = 'default',
  OUTLINE = 'outline',
  TEXT = 'text',
}

type StyleProps = {
  fullWidth?: boolean
  loading?: boolean
  disabled?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  base: ({ fullWidth, loading, disabled }) => ({
    width: fullWidth ? '100%' : 'fit-content',
    height: '40px',
    position: 'relative',
    border: '1px solid',
    borderRadius: '3px',
    fontFamily: theme.typography.fontFamily,
    outline: 'none',
    transition: 'all 300ms ease',
    fontSize: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    pointerEvents: loading || disabled ? 'none' : 'auto',
    paddingRight: '12px',
    paddingLeft: '12px',
  }),
  default: {
    color: '#FFFFFF',
    background: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.secondary.main,
      color: '#FFF',
    },
    '&:active': {
      background: '#8A84AB',
      color: '#FFF',
    },
    '&:disabled': {
      background: '#DFDFDA',
      color: '#FFF',
      cursor: 'initial',
    },
  },
  outline: {
    color: theme.palette.primary.main,
    background: '#FFFFFF',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      color: '#FFFFFF',
      background: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '&:active': {
      background: '#FFF',
      color: '#8A84AB',
    },
    '&:disabled': {
      background: '#FFFFFF',
      color: '#DFDFDA',
      cursor: 'initial',
    },
  },
  text: () => ({
    color: theme.palette.primary.main,
    background: '#FFFFFF',
    borderColor: 'transparent',
    '&:hover': {
      background: ' #42B3FF33',
      color: '#42B3FF',
    },
    '&:active': {
      background: '#2A205E33',
      color: '#8A84AB',
    },
    '&:disabled': {
      background: '#FFFFFF',
      color: '#DFDFDA',
      cursor: 'initial',
    },
  }),
  loadingContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    borderRadius: '3px',
  },
  loadingDefault: {
    color: '#FFFFFF',
    background: theme.palette.primary.main,
  },
  loadingOutline: {
    color: theme.palette.primary.main,
    background: '#FFFFFF',
  },
  loadingText: {
    color: theme.palette.primary.main,
    background: '#FFFFFF',
  },
  icon: {
    display: 'flex',
    width: 17,
    height: 17,
  },
}))

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: Variant
  fullWidth?: boolean
  onClick: (e: React.MouseEvent) => void
  iconLeft?: SVGComponent | OverridableComponent<any>
  iconRight?: SVGComponent | OverridableComponent<any>
  className?: string
  iconClassName?: string
  disabled?: boolean
  loading?: boolean
}

type StyleMap = {
  mainClass: string
  loadingClass: string
}

const Button: React.FC<ButtonProps> = ({
  children,
  variant = Variant.DEFAULT,
  fullWidth = false,
  onClick,
  disabled,
  loading,
  iconLeft: IconLeft,
  iconRight: IconRight,
  className,
  iconClassName,
  ...props
}) => {
  const C = useStyles({ fullWidth, loading })

  const variantStyleMap: StyleMap = useMemo(() => {
    switch (variant) {
      case Variant.DEFAULT:
        return { mainClass: C.default, loadingClass: C.loadingDefault }
      case Variant.OUTLINE:
        return { mainClass: C.outline, loadingClass: C.loadingOutline }
      case Variant.TEXT:
        return { mainClass: C.text, loadingClass: C.loadingText }
    }
  }, [C.default, C.loadingDefault, C.loadingOutline, C.loadingText, C.outline, C.text, variant])

  const buttonClasses = clsx(C.base, variantStyleMap.mainClass, className)
  const loaderClasses = clsx(C.loadingContainer, variantStyleMap.loadingClass)
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClick(e)
  return (
    <button className={buttonClasses} onClick={handleClick} disabled={disabled} {...props}>
      <Fade mountOnEnter unmountOnExit in={loading}>
        <div className={loaderClasses}>
          <DotsAnimation color={variant} />
        </div>
      </Fade>
      {IconLeft && <IconLeft className={clsx(C.icon, iconClassName)} />}
      {children && (
        <div style={{ marginLeft: IconLeft ? 10 : 0, marginRight: IconRight ? 10 : 0 }}>
          {children}
        </div>
      )}
      {IconRight && <IconRight className={clsx(C.icon, iconClassName)} />}
    </button>
  )
}

export default Button
