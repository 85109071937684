import type { ClearState } from '@/legacy/redux/types/generic'
import type { UnassignUserAction } from '@/legacy/redux/types/managementUsers'
import type { GenericActionWithType } from '@/legacy/redux/utils'

export interface DeleteAssignmentModalState {
  open: boolean
  loading: boolean
  error: string
  assignment: {
    assignmentId: string
    classroomId: number
    subjectId: number
    role: string
    assignmentName: string
  }
}

export const INIT_MODAL = 'MANAGEMENT/USERS/DELETE_ASSIGNMENT_MODAL/INIT_MODAL'
export type InitModal = GenericActionWithType<
  typeof INIT_MODAL,
  {
    assignment: {
      assignmentId: string
      classroomId: number
      subjectId: number
      role: string
      assignmentName: string
    }
  }
>

export type DeleteUserModalAction = InitModal | UnassignUserAction | ClearState
