import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import type { DefaultResponse } from '@/legacy/services/helpers/types'

export function onRequestSuccess(config: AxiosRequestConfig) {
  config.data = config.data ? snakecaseKeys(config.data, { deep: true }) : config.data
  config.params = config.params ? snakecaseKeys(config.params, { deep: true }) : config.params
  return config
}

export function onRequestError(error: AxiosError) {
  return Promise.reject(error)
}

export function onResponseSuccess(response: AxiosResponse<DefaultResponse>) {
  // cualquier código de estado que se encuentre dentro del rango de 2xx hace que esta función se ejecute
  response.data = camelcaseKeys(response.data, { deep: true })
  if (response.data.description) {
    response.data = response.data.description
    return response
  }
  return response
}

export function onResponseError(error: AxiosError) {
  // cualquier código de estado que se encuentre fuera del rango de 2xx hace que esta función se ejecute
  return Promise.reject(error)
}
