import * as Types from '@/sections/principal/documentation/types/rejectionDetailsModal'

const initialState: Types.RejectionDetailsModalState = {
  isOpenModal: false,
  documentId: 0,
}

const rejectionDetailsModalReducer = (
  state: Types.RejectionDetailsModalState = initialState,
  action: Types.RejectionDetailsAction
): Types.RejectionDetailsModalState => {
  switch (action.type) {
    case Types.REJECTION_DETAILS_MODAL_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isOpenModal: true,
        documentId: action.payload,
      }
    }
    case Types.REJECTION_DETAILS_MODAL_TYPES.CLEAN_MODAL: {
      return initialState
    }
    default:
      return state
  }
}

export default rejectionDetailsModalReducer
