import type { Moment } from 'moment'
import { apiPrivate } from '@/api'
import { url } from '@aula/config'
import type { Reservation } from '@/sections/teacher/activities/types/collabMiniatureAssign'

export const CollabMiniatureAPI = {
  getTimeslots,
  getScenarios,
  assignScenarios,
  getToken,
}

/**
 * Get timeslots.
 *
 * @role Teacher
 */
function getTimeslots() {
  return apiPrivate.get(url + `/v1/timeslots`)
}

/**
 * Get scenarios.
 *
 * @role Teacher
 */

function getScenarios(startTime: Moment, endTime: Moment, activityID?: number) {
  const params = {
    startTime: startTime.format(),
    endTime: endTime.format(),
    ...(activityID ? { activityId: activityID } : {}),
  }

  return apiPrivate.get(url + `/v1/collab-miniatures-scenarios`, { params })
}

/**
 * Assign scenarios.
 *
 * @role Teacher
 */

function assignScenarios(activityID: number, reservations: Reservation[]) {
  const data = {
    config: reservations,
  }

  return apiPrivate.post(url + `/v1/collab-miniatures/${activityID}/assignments`, data)
}

/**
 * Get Token from Robots.
 *
 * @role Any
 */

function getToken(activityID: number) {
  return apiPrivate.get(url + `/v1/collab-miniatures/${activityID}/token`)
}
