import { ROLE } from '@aula/config'
import type { TFunction } from 'i18next'
import type { IndividualAssignment } from '@/legacy/redux/types/assignments'
import type { PrincipalClassroom } from '@/legacy/redux/types/classrooms'
import type { Classroom } from '@/sections/teacher/classroom/types/classroom'

export function pagesToLimit({ page, rowsPerPage }: { page: number; rowsPerPage: number }) {
  return {
    limit: rowsPerPage,
    offset: page * rowsPerPage,
  }
}

export const shortenIntl = (
  t: TFunction<'translation'>,
  id: string,
  prefix?: string,
  extraProps?: { [key: string]: any }
) => t(prefix ? `${prefix}.${id}` : id, { ...extraProps })

export const wordsToInitials = (words: string): string => {
  const matches = words.match(/(\b\S)?/g)

  return matches ? matches.join('').toUpperCase() : ''
}

export const convertPrincipalClassroomToTeacherClassroom = (
  classroom: PrincipalClassroom
): Classroom => ({
  ...classroom,
  organization: {
    name: classroom.organizationName,
    id: classroom.organizationId,
  },
  pendingActivities: 0,
})

// TODO: Replace all other implementations of classroom name building with this
export const buildClassroomName = (
  classroom: Classroom,
  excludeKeys?: Array<keyof Classroom>
): string => {
  if (!classroom) return ''

  const keyIsExcluded = (key: keyof Classroom) =>
    Boolean(excludeKeys?.find((excludedKey) => excludedKey === key))

  const shouldKeyBeReturned = (key: keyof Classroom) =>
    classroom[key] && !keyIsExcluded(key) ? classroom[key].toString() : ''

  const shouldAnyOfTheseKeysBeReturned = (keys: Array<keyof Classroom>) =>
    Boolean(keys.find((key) => shouldKeyBeReturned(key)))

  const shouldAddSeparator = (
    leftItems: Array<keyof Classroom>,
    rightItems: Array<keyof Classroom>,
    separator: string
  ) =>
    shouldAnyOfTheseKeysBeReturned(leftItems) && shouldAnyOfTheseKeysBeReturned(rightItems)
      ? separator
      : ''

  const organizationHasName = classroom.organization?.name?.length > 0

  const texts = [
    shouldKeyBeReturned('stage'),
    shouldAddSeparator(['stage'], ['grade', 'division'], ' - '),
    shouldKeyBeReturned('grade'),
    shouldAddSeparator(['grade'], ['division'], ' '),
    shouldKeyBeReturned('division'),
    shouldAddSeparator(['stage', 'grade', 'division'], ['shift', 'organization'], ', '),
    shouldKeyBeReturned('shift'),
    organizationHasName ? shouldAddSeparator(['shift'], ['organization'], ' - ') : '',
    organizationHasName && shouldKeyBeReturned('organization') ? classroom.organization.name : '',
  ]

  return texts.join('')
}

export const buildAssignmentName = (
  assignment: IndividualAssignment,
  translatedRoleName: string
): string => {
  const { role, stages } = assignment

  let assignmentName = translatedRoleName

  if (assignment.classroomName.trim().length > 0) {
    assignmentName = assignmentName + `. ${assignment.classroomName}`
  }
  if (assignment.subjectName.trim().length > 0) {
    assignmentName = assignmentName + `. ${assignment.subjectName}`
  }

  if (role === ROLE.PRINCIPAL || role === ROLE.PRECEPTOR) {
    const stagesText = stages?.map(({ name }) => name).join(', ')
    return `${translatedRoleName} - ${stagesText}.`
  }

  return assignmentName + '.'
}

export const findDuplicateValuesInObjectArray = <T>(
  arr: Array<T>,
  properties: Array<keyof T>
): boolean =>
  properties.some((property) => {
    const uniqueValues = new Set(arr.map((v) => v[property]))
    return uniqueValues.size < arr.length
  })
