import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { ErrorPayload } from '@/legacy/redux/utils'
import { GenericPayload } from '@/legacy/redux/utils'

export interface Student {
  avatar: string
  createdAt: string
  dni: string
  dateOfBirth: string
  deletedAt: string | null
  email: string
  gender: string
  id: number
  lastName: string
  name: string
  phone: string
  tags: string[]
  updatedAt: string
}

// LIST CLASSROOM STUDENTS
export const LIST_CLASSROOM_STUDENTS_REQUEST =
  'STUDENTS_TEACHER/STUDENTS/LIST_CLASSROOM_STUDENTS_REQUEST'
export const LIST_CLASSROOM_STUDENTS_SUCCESS =
  'STUDENTS_TEACHER/STUDENTS/LIST_CLASSROOM_STUDENTS_SUCCESS'
export const LIST_CLASSROOM_STUDENTS_FAILURE =
  'STUDENTS_TEACHER/STUDENTS/LIST_CLASSROOM_STUDENTS_FAILURE'

export interface ListClassroomStudentsRequest {
  type: typeof LIST_CLASSROOM_STUDENTS_REQUEST
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    page: number
    rowsPerPage: number
  }
}

export interface ListClassroomStudentsSuccess {
  type: typeof LIST_CLASSROOM_STUDENTS_SUCCESS
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    students: Student[]
    count: number
    nextStudentIndex: number
  }
}
export interface ListClassroomStudentsFailure {
  type: typeof LIST_CLASSROOM_STUDENTS_FAILURE
  payload: ErrorPayload
}

export type ListClassroomStudentsAction =
  | ListClassroomStudentsRequest
  | ListClassroomStudentsSuccess
  | ListClassroomStudentsFailure

// LIST ACTIVITY STUDENTS
export const LIST_ACTIVITY_STUDENTS_REQUEST =
  'STUDENTS_TEACHER/STUDENTS/LIST_ACTIVITY_STUDENTS_REQUEST'
export const LIST_ACTIVITY_STUDENTS_SUCCESS =
  'STUDENTS_TEACHER/STUDENTS/LIST_ACTIVITY_STUDENTS_SUCCESS'
export const LIST_ACTIVITY_STUDENTS_FAILURE =
  'STUDENTS_TEACHER/STUDENTS/LIST_ACTIVITY_STUDENTS_FAILURE'

export interface ActivityEvaluation {
  date: string
  feedback: string
  id: number
  value: number
}
export interface ActivityStudent {
  email: string
  evaluation: ActivityEvaluation | null
  id: number
  avatar: string
  robotsId: number
  lastName: string
  name: string
  submitted: boolean
}

export interface ListActivityStudentsRequest {
  type: typeof LIST_ACTIVITY_STUDENTS_REQUEST
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    page: number
    rowsPerPage: number
  }
}

export interface ListActivityStudentsSuccess {
  type: typeof LIST_ACTIVITY_STUDENTS_SUCCESS
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    students: ActivityStudent[]
    count: number
  }
}
export interface ListActivityStudentsFailure {
  type: typeof LIST_ACTIVITY_STUDENTS_FAILURE
  payload: ErrorPayload
}

export type ListActivityStudentsAction =
  | ListActivityStudentsRequest
  | ListActivityStudentsSuccess
  | ListActivityStudentsFailure

// ALL STUDENT ACTIONS UNION TYPE
export type StudentAction = ListClassroomStudentsAction | ListActivityStudentsAction
