import { combineReducers } from 'redux'
import dashboard from './dashboard/reducer'
import books from './books/reducer'
import premiumActivities from './premium-activities/reducer'
import deleteSequenceModal from './deleteSequenceModal/reducer'
import courses from './courses/reducer'
import sequenceDrawer from './sequenceDrawer/reducer'
import newBooks from './newBooks/reducer'
import deleteBookModal from './deleteBookModal/reducer'
import booksDrawer from './booksDrawer/reducer'

const rootReducer = combineReducers({
  dashboard,
  booksDrawer,
  books,
  deleteBookModal,
  premiumActivities,
  deleteSequenceModal,
  courses,
  sequenceDrawer,
  newBooks,
})

export default rootReducer
