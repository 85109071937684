import moment from 'moment'
import type { Reducer } from 'redux'
import { Redux } from '@/legacy/redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { LIST_CLASSROOM_STUDENTS_SUCCESS } from '@/legacy/redux/types/students'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import * as Types from '@/sections/report-card/grading/types/abscencesDrawer'

const initialState: Types.AbscencesDrawerState = {
  loading: false,
  error: '',
  abscences: {
    records: [],
  },
  students: [],
  selectedStudentIndex: 0,
  previousRecord: null,
  saveRecordsRequestStatus: Types.SaveRecordsRequestStatus.SUCCESS,
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_GRADING_ABSENCES_DRAWER

const ReportCardGradingAbsencesDrawer: Reducer<
  Types.AbscencesDrawerState,
  Types.AbscencesDrawerActions
> = (state = initialState, action): Types.AbscencesDrawerState => {
  switch (action.type) {
    case Types.CLEAR_STATE:
      return initialState
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case Types.INIT_DRAWER: {
      const { selectedStudent, students } = action.payload
      const selectedStudentIndex = students.findIndex((s) => {
        return s.id === selectedStudent.id
      })
      return {
        ...state,
        students,
        selectedStudentIndex,
      }
    }
    case Types.GET_ABSCENCES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.GET_ABSCENCES_SUCCESS:
      return { ...state, loading: false, abscences: action.payload.abscences }
    case Types.GET_ABSCENCES_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case Types.SAVE_ABSCENCES_REQUEST:
      return {
        ...state,
        loading: true,
        saveRecordsRequestStatus: Types.SaveRecordsRequestStatus.PENDING,
      }
    case Types.SAVE_ABSCENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        saveRecordsRequestStatus: Types.SaveRecordsRequestStatus.SUCCESS,
      }
    case Types.SAVE_ABSCENCES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        saveRecordsRequestStatus: Types.SaveRecordsRequestStatus.FAILED,
      }
    case Types.UPDATE_ABSCENCE_REQUEST: {
      const { date, status } = action.payload
      const newAbscences = state.abscences

      let previousRecord: Types.PreviousRecord = null

      const dateRecordIndex = state.abscences.records.findIndex((r) => {
        return moment(r.date).isSame(date, 'day')
      })

      const isThereRecord = dateRecordIndex > -1

      if (isThereRecord) {
        previousRecord = newAbscences.records[dateRecordIndex]
        // @ts-ignore
        newAbscences.records[dateRecordIndex].statusId = status.id
      } else {
        newAbscences.records.push({
          abbreviation: status.abbreviation,
          color: status.color,
          date: date.format('YYYY-MM-DD'),
          description: status.description,
          // @ts-ignore
          statusId: status.id,
          value: status.value,
        })
      }

      return { ...state, abscences: newAbscences, previousRecord }
    }
    case Types.UPDATE_ABSCENCE_SUCCESS: {
      return { ...state, previousRecord: null }
    }
    case Types.UPDATE_ABSCENCE_FAILURE: {
      const { date } = action.payload
      const { abscences: newAbscences, previousRecord } = state

      const dateRecordIndex = state.abscences.records.findIndex((r) => {
        return moment(r.date).isSame(date)
      })

      if (previousRecord) {
        newAbscences.records.splice(dateRecordIndex, 1, previousRecord)
      } else {
        newAbscences.records.splice(dateRecordIndex, 1)
      }

      return {
        ...state,
        abscences: newAbscences,
        previousRecord: null,
        error: action.payload.error,
      }
    }
    case LIST_CLASSROOM_STUDENTS_SUCCESS:
      return {
        ...state,
        students: action.payload.students,
        selectedStudentIndex: action.payload.nextStudentIndex,
      }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, ReportCardGradingAbsencesDrawer, requestDestination)
