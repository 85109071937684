export enum FEATURE_FLAG {
  APPLICATIONS_FEATURE = 'applications',
  ATTACH_FEATURE = 'attach',
  ATTENDANCES_FEATURE = 'attendances',
  AVV_WIDGET_ONLY_FEATURE = 'avv_widget_only',
  AVVS_FEATURE = 'avvs',
  BACKPACK_FEATURE = 'backpack',
  BREAKOUT_ROOMS_FEATURE = 'breakout_rooms',
  BULLETIN_FEATURE = 'bulletin',
  BULLETIN_VIMEO_FEATURE = 'bulletin_vimeo',
  BULLETIN_ENDORSEMENT_FEATURE = 'bulletin_endorsement',
  CHANGE_PASSWORD_FEATURE = 'change_password',
  CHAT_FEATURE = 'chat',
  CHAT_VIDEOCALL_FEATURE = 'chat_videocall',
  COLLAB_MINIATURE_FEATURE = 'collab_miniature',
  CONTENTS_TAB_FEATURE = 'contents_tab',
  COURSE_CERTIFICATE_FEATURE = 'course_certificate',
  COURSES_FEATURE = 'courses',
  DELIVERABLES_FEATURE = 'deliverables',
  EDUCATIONAL_INFO_FEATURE = 'educational_info',
  EVALUATION_PDF_FEATURE = 'evaluation_pdf',
  EXES_RECORDING_FEATURE = 'exes_recording',
  GAMES_FEATURE = 'games',
  LIBRARY_FEATURE = 'library',
  LIMIT_SUBMISSIONS_FEATURE = 'limit_submissions',
  NOTIFICATIONS_FEATURE = 'notifications',
  ORAL_EXAMS_FEATURE = 'oral_exams',
  OVERVIEW_FEATURE = 'overview',
  PRINCIPAL_PASSWORD_PRIVILEGES_FEATURE = 'principal_password_privileges',
  QUESTIONS_FEATURE = 'questions',
  QUIZ_FEATURE = 'quiz',
  RECORDINGS_FEATURE = 'recordings',
  RECORDINGS_MULTISERVER_FEATURE = 'recordings_multiserver',
  REPORT_CARD_EXPORT_FEATURE = 'report_card_export',
  REPORT_CARD_ABSENCES_FEATURE = 'report_card_absences',
  REPORT_CARD_FEATURE = 'report_card',
  ROBOTS_REMOTE_FEATURE = 'robots_remote',
  SEARCH_BAR_FEATURE = 'search_bar',
  STUDENT_OVERVIEW_FEATURE = 'student_overview',
  STUDENT_OVERVIEW_QUALIFICATIONS_FEATURE = 'student_overview_qualifications',
  SUBGROUPS_FEATURE = 'subgroups',
  TEACHER_STUDENT_TAB_FEATURE = 'student_tab',
  TED_AUTORIZATIONS_FEATURE = 'ted_video_autorizations',
  TED_VIDEOS_FEATURE = 'ted_video',
  TED_ADD_FACILITATOR_FEATURE = 'ted_add_facilitator',
  TED_REJECT_VIDEO_FEATURE = 'ted_reject_video_feature',
  TED_REPORTS_FEATURE = 'ted_reports',
  TED_PROGRESS_FEATURE = 'ted_progress',
  WRITTEN_EXAMS_FEATURE = 'written_exams',
  ZOOM_VIDEOCALLS_FEATURE = 'zoom_videocalls',
  AVV_RECURRENT_FEATURE = 'avv_recurrent',
  PRECEPTOR_ROLE_FEATURE = 'preceptor_role',
  MOBILE_AUTHORIZATIONS_FEATURE = 'mobile_authorizations',
  REPORT_CARD_AUDIT_FEATURE = 'report_card_audit',
  FORMS_FEATURE = 'forms',
  TEACHER_SCHOOL_RECORDS_TAB = 'teacher_school_records_tab',
  AUDIENCES_FEATURE = 'audiences',
  TEACHER_BULLETIN_PENDING_SUBJECTS = 'teacher_bulletin_pending_subjects',
  TEACHER_EXPORT_STUDENTS_TABLE = 'teacher_export_students_table',
  DOCUMENTATION_TAB_FEATURE = 'documentation_tab',
  ROBOTS_SIMULATOR_FEATURE = 'robots_simulator',
  STUDENT_RESTRICTIONS = 'student_restrictions',
  DELETE_ORGANIZATION = 'delete_organization',
  CLONE_ORGANIZATION = 'clone_organization',
  PDF_REPORT_CARD_CONFIG_PREVIEW = 'pdf_report_card_config_preview',
}
