export const GET_CLASSROOM_REQUEST = 'CLASSROOM_TEACHER/GET_CLASSROOM_REQUEST'
export const GET_CLASSROOM_SUCCESS = 'CLASSROOM_TEACHER/GET_CLASSROOM_SUCCESS'
export const GET_CLASSROOM_FAILURE = 'CLASSROOM_TEACHER/GET_CLASSROOM_FAILURE'

export const SET_ACTIVE_TAB = 'CLASSROOM_TEACHER/SET_ACTIVE_TAB'

export interface Classroom {
  division: string
  grade: string
  id: number
  organization: {
    id: number
    name: string
  }
  shift: string
  stage: string
  year: number
  pendingActivities: number
}

export interface ClassroomState {
  classroom: Classroom
  loading: boolean
  error: string
  activeTab: number
}

export interface GetClassroomRequest {
  type: typeof GET_CLASSROOM_REQUEST
}

export interface GetClassroomSuccess {
  type: typeof GET_CLASSROOM_SUCCESS
  payload: {
    classroom: Classroom
  }
}

export interface GetClassroomFailure {
  type: typeof GET_CLASSROOM_FAILURE
  payload: {
    error: string
  }
}

export interface SetActiveTab {
  type: typeof SET_ACTIVE_TAB
  payload: {
    activeTab: number
  }
}

export type ClassroomActions =
  | GetClassroomRequest
  | GetClassroomSuccess
  | GetClassroomFailure
  | SetActiveTab
