import type { SideDrawerContent } from '@/sections/report-card/grading/sideDrawer/containers/sideDrawerWrapper'
import * as Types from '@/sections/report-card/grading/sideDrawer/types/sideDrawer'

const ReportCardSideDrawerActionCreators = {
  openSideDrawer: (drawerContent: SideDrawerContent): Types.OpenDrawer => ({
    type: Types.OPEN_DRAWER,
    payload: { drawerContent },
  }),
  closeSideDrawer: (): Types.CloseDrawer => ({
    type: Types.CLOSE_DRAWER,
  }),
}

export default ReportCardSideDrawerActionCreators
