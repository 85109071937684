import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import MUISelect from '@mui/material/Select'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  select: {
    height: 48,
    padding: '0px 16px',
    boxSizing: 'border-box',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #D8DBE8',
    fontSize: 16,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    borderRadius: 4,
    '&:focus': {
      borderRadius: 4,
      borderColor: '#A051E6',
    },
    '&> div': {
      borderBottom: 'none',
    },
  },
  menuItem: {
    fontWeight: theme.typography.fontWeightRegular,
    padding: '0px 16px',
    '&:last-child > div': {
      borderBottom: 'none',
    },
  },
  menuItemContainer: {
    width: '100%',
    display: 'flex',
    padding: '9px 0',
    borderBottom: '1px solid #D8DBE8',
    alignItems: 'center',
  },
  selectedMenuItem: {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#7D00DC',
    backgroundColor: 'inherit !important',
  },
  checkIcon: {
    fontSize: '15px',
    position: 'absolute',
    display: 'inline',
    right: '16px',
  },
  paper: {
    border: '1px solid #D8DBE8',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    width: 260,
  },
}))

function getBulletStyles(subjectColor) {
  return {
    height: '10px',
    width: '10px',
    backgroundColor: subjectColor,
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '10px',
  }
}

const Select = (props) => {
  const { options, select, disabled = false, selectedValue, showBullet, className } = props
  const classes = useStyles()

  const handleChange = (event) => {
    select(event.target.value)
  }

  const MenuProps = {
    PaperProps: {
      elevation: 0,
    },
    classes: { list: classes.list, paper: classes.paper },
  }

  return (
    <FormControl className={clsx(classes.formControl, classes.noLabel, className)}>
      <MUISelect
        displayEmpty
        disabled={disabled}
        variant="outlined"
        value={selectedValue}
        onChange={handleChange}
        classes={{ select: classes.select }}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {options.map((option) => (
          <MenuItem
            disableRipple
            key={option.value}
            value={option.value}
            classes={{ root: classes.menuItem, selected: classes.selectedMenuItem }}
            selected={option.value === selectedValue}
          >
            <div className={classes.menuItemContainer}>
              {showBullet && <span style={getBulletStyles(option.color)} />}
              {option.label}
            </div>
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  )
}

export default Select
