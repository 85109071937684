import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { Subgroup } from './libraryBooks'

export interface AssignBookModalState {
  isOpen: boolean
  associateStatus: REQUEST_STATUS
  subgroups: {
    status: REQUEST_STATUS
    list: Subgroup[]
    selectedSubgroups: Subgroup[]
    error: string
  }
  classrooms: {
    status: REQUEST_STATUS
    list: Classroom[]
    error: string
    selectedClassroom: Classroom
    classroomId: number
  }
}

type AdmLevel = {
  id: number
  name: string
}

export interface Classroom {
  admLevel1: AdmLevel
  admLevel2: AdmLevel
  country: {
    id: number
    name: string
  }
  division: string
  enrollmentCapacity: number
  grade: string
  id: number
  organization: {
    id: number
    name: string
  }
  organizationType: string
  shift: string
  stage: string
  year: number
}

export enum ASSIGN_BOOK_MODAL_TYPE {
  SET_OPEN = 'LIBRARY_TEACHER/SET_ASSIGN_BOOK_MODAL_OPEN',
  SET_CLOSE = 'LIBRARY_TEACHER/SET_ASSIGN_BOOK_MODAL_CLOSE',
  SELECT_SUBGROUPS = 'LIBRARY_TEACHER/ASSING_BOOK_MODAL/SELECT_SUBGROUPS',
  SELECT_CLASSROOM = 'LIBRARY_TEACHER/ASSING_BOOK_MODAL/SELECT_CLASSROOM',
  GET_CLASSROOMS_REQUEST = 'LIBRARY_TEACHER/ASSIGN_BOOK_MODAL/GET_CLASSROOMS_REQUEST',
  GET_CLASSROOMS_SUCCESS = 'LIBRARY_TEACHER/ASSIGN_BOOK_MODAL/GET_CLASSROOMS_SUCCESS',
  GET_CLASSROOMS_FAILURE = 'LIBRARY_TEACHER/ASSIGN_BOOK_MODAL/GET_CLASSROOMS_FAILURE',
  GET_CLASSROOM_SUBGROUPS_REQUEST = 'LIBRARY_TEACHER/ASSIGN_BOOK_MODAL/GET_CLASSROOM_SUBGROUPS_REQUEST',
  GET_CLASSROOM_SUBGROUPS_SUCCESS = 'LIBRARY_TEACHER/ASSIGN_BOOK_MODAL/GET_CLASSROOM_SUBGROUPS_SUCCESS',
  GET_CLASSROOM_SUBGROUPS_FAILURE = 'LIBRARY_TEACHER/ASSIGN_BOOK_MODAL/GET_CLASSROOM_SUBGROUPS_FAILURE',
  ASSOCIATE_BOOK_WITH_CLASSROOM_REQUEST = 'LIBRARY_TEACHER/ASSIGN_BOOK_MODAL/ASSOCIATE_BOOK_WITH_CLASSROOM_REQUEST',
  ASSOCIATE_BOOK_WITH_CLASSROOM_SUCCESS = 'LIBRARY_TEACHER/ASSIGN_BOOK_MODAL/ASSOCIATE_BOOK_WITH_CLASSROOM_SUCCESS',
  ASSOCIATE_BOOK_WITH_CLASSROOM_FAILURE = 'LIBRARY_TEACHER/ASSIGN_BOOK_MODAL/ASSOCIATE_BOOK_WITH_CLASSROOM_FAILURE',
}

export type SetOpenAssignBookModalAction = {
  type: ASSIGN_BOOK_MODAL_TYPE.SET_OPEN
}

export type SetCloseAssignBookModalAction = {
  type: ASSIGN_BOOK_MODAL_TYPE.SET_CLOSE
}

export interface GetClassroomsRequestAction {
  type: ASSIGN_BOOK_MODAL_TYPE.GET_CLASSROOMS_REQUEST
}

export interface GetClassroomsSuccessAction {
  type: ASSIGN_BOOK_MODAL_TYPE.GET_CLASSROOMS_SUCCESS
  payload: {
    classrooms: Classroom[]
  }
}

export interface GetClassroomsFailureAction {
  type: ASSIGN_BOOK_MODAL_TYPE.GET_CLASSROOMS_FAILURE
  payload: {
    error: string
  }
}

export interface GetClassroomSubgroupsRequestAction {
  type: ASSIGN_BOOK_MODAL_TYPE.GET_CLASSROOM_SUBGROUPS_REQUEST
}

export interface GetClassroomSubgroupsSuccessAction {
  type: ASSIGN_BOOK_MODAL_TYPE.GET_CLASSROOM_SUBGROUPS_SUCCESS
  payload: {
    subgroups: Subgroup[]
  }
}

export interface GetClassroomSubgroupsFailureAction {
  type: ASSIGN_BOOK_MODAL_TYPE.GET_CLASSROOM_SUBGROUPS_FAILURE
  payload: {
    error: string
  }
}

export interface SelectSubgroupsAction {
  type: ASSIGN_BOOK_MODAL_TYPE.SELECT_SUBGROUPS
  payload: {
    selectedSubgroups: Subgroup[]
  }
}

export interface SelectClassroomAction {
  type: ASSIGN_BOOK_MODAL_TYPE.SELECT_CLASSROOM
  payload: {
    classroomId: number
  }
}

export interface AssoaciteBookWithClassroomRequestAction {
  type: ASSIGN_BOOK_MODAL_TYPE.ASSOCIATE_BOOK_WITH_CLASSROOM_REQUEST
}
export interface AssoaciteBookWithClassroomSuccessAction {
  type: ASSIGN_BOOK_MODAL_TYPE.ASSOCIATE_BOOK_WITH_CLASSROOM_SUCCESS
}
export interface AssoaciteBookWithClassroomFailureAction {
  type: ASSIGN_BOOK_MODAL_TYPE.ASSOCIATE_BOOK_WITH_CLASSROOM_FAILURE
}

export type AssignBookModalActions =
  | SetOpenAssignBookModalAction
  | SetCloseAssignBookModalAction
  | GetClassroomsRequestAction
  | GetClassroomsSuccessAction
  | GetClassroomsFailureAction
  | GetClassroomSubgroupsRequestAction
  | GetClassroomSubgroupsSuccessAction
  | GetClassroomSubgroupsFailureAction
  | SelectSubgroupsAction
  | SelectClassroomAction
  | AssoaciteBookWithClassroomRequestAction
  | AssoaciteBookWithClassroomSuccessAction
  | AssoaciteBookWithClassroomFailureAction
