import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import CheckIcon from '@mui/icons-material/Check'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  select: {
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #D8DBE8',
    fontSize: 16,
    padding: '8px 26px 7px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#A051E6',
    },
  },
  menuItem: {
    fontWeight: theme.typography.fontWeightRegular,
    padding: '0px 16px',
    '&:last-child > div': {
      borderBottom: 'none',
    },
  },
  menuItemContainer: {
    width: '100%',
    display: 'flex',
    padding: '9px 0',
    borderBottom: '1px solid #D8DBE8',
    alignItems: 'center',
  },
  selectedMenuItem: {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#7D00DC',
    backgroundColor: 'inherit !important',
  },
  checkIcon: {
    fontSize: '15px',
    position: 'absolute',
    display: 'inline',
    right: '16px',
  },
  paper: {
    border: '1px solid #D8DBE8',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    width: 260,
  },
}))

const ActivityPicker = (props) => {
  const { selectedActivity, setSelectedActivity, activities } = props
  const classes = useStyles()

  const handleChange = (event) => {
    const value = event.target.value
    setSelectedActivity(value)
  }

  const MenuProps = {
    PaperProps: {
      elevation: 0,
    },
    classes: { list: classes.list, paper: classes.paper },
  }

  const renderActivity = (activity) => {
    return <span>{activity.name}</span>
  }

  return (
    <FormControl className={clsx(classes.formControl, classes.noLabel)}>
      <Select
        displayEmpty
        variant="outlined"
        value={selectedActivity || ''}
        onChange={handleChange}
        classes={{ select: classes.select }}
        renderValue={(selected) => {
          const activity = selectedActivity
            ? activities.find((activity) => activity.id === selectedActivity.id)
            : null
          return !selected || !activity ? 'Seleccionar Actividad' : renderActivity(selectedActivity)
        }}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {activities.map((activity) => {
          const selected = activity.id === selectedActivity?.id
          return (
            <MenuItem
              disableRipple
              key={activity.id}
              value={activity}
              classes={{ root: classes.menuItem, selected: classes.selectedMenuItem }}
              selected={selected}
            >
              <div className={classes.menuItemContainer}>
                {renderActivity(activity)}
                {selected && <CheckIcon className={clsx(classes.checkIcon)} />}
              </div>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default ActivityPicker
