import { scores } from '@aula/constants'

/**
 * Calculate a numerical score based on the given value, base, and maximum value.
 *
 * @param value - The numerical value for which to calculate the score.
 * @param base - The base value used for scaling the score (either 10 or 100).
 * @param max - The maximum possible value (default is 1000).
 * @returns The calculated numerical score.
 * @throws If the 'value' is NaN, if it's outside the range [0, max], or if the 'base' is not 10 or 100.
 */
export const getNumericalScore = (value: number, base: number, max = 1000): number => {
  if (Number.isNaN(value)) throw new Error('Value must not be NaN.')
  if (value < 0 || value > max) throw new Error('Value must be between 0 and the maximum value.')
  if (base !== 10 && base !== 100) throw new Error('Incorrect base, it must be either 10 or 100.')

  const val = (value * base) / max
  return val > max ? val / 100 : val
}

export type ConceptualOption = {
  max: number
  value: string
}

/**
 * Calculate a conceptual score based on the given numerical value and a list of conceptual options.
 *
 * @param value - The numerical value for which to calculate the conceptual score.
 * @param options - An array of conceptual options with maximum values.
 * @returns The calculated conceptual score as a string.
 * @throws If no valid conceptual option is found for the given numerical value.
 */
export const getConceptualScore = (value: number, options: ConceptualOption[]): string => {
  if (Number.isNaN(value)) throw new Error('Value must not be NaN.')

  const score = options.find((opt) => opt.max >= value)
  if (score) return score.value
  throw new Error('Value cannot be undefined in the provided options.')
}

/**
 * Indicates whether the grade is conceptual according to the given evaluationType.
 *
 * @param evaluationType - A number. Indicates the type of evaluation
 * @returns A boolean.
 */

export const isConceptualScore = (evaluationType: number): boolean => evaluationType === 1

/**
 * Returns the base of a note, according to the given evaluationType.
 *
 * @param evaluationType - A number. Indicates the type of evaluation.
 * @returns A number, which corresponds to the base of a score.
 * @throws If the evaluationType is not valid.
 */
export const getBaseByEvaluationType = (evaluationType: number): number => {
  switch (evaluationType) {
    case 1:
      return 1000
    case 2:
      return 10
    case 3:
      return 100
    default:
      throw new Error('The evaluationType does not exist')
  }
}

/**
 * Obtains the numeric or conceptual score, in the correct format, according to the given value and evaluationType.
 *
 * @param value - A number. Indicates the value of the note to be displayed.
 * @param evaluationType - A number. Indicates the type of evaluation
 * @returns The note in the correct format. It can be a string or a number.
 * @throws If the evaluationType is not valid.
 */
export const getScoreByEvaluationType = (
  value: number,
  evaluationType: number
): string | number => {
  switch (evaluationType) {
    case 1:
      return getConceptualScore(value, scores.scoreOptions)
    case 2:
      return getNumericalScore(value, 10)
    case 3:
      return getNumericalScore(value, 100)
    default:
      throw new Error('The evaluationType does not exist')
  }
}

/**
 * Gets the appropriate translation for the given key for a conceptual score.
 *
 * @param key - A number, indicating the key for the translation.
 * @returns A string, that is a translation of the conceptual score.
 */
export const getConceptualScoreTranslation = (key: string, t): string => {
  return t('scores.' + key)
}

/**
 * Sets a score in the correct format, according to the evaluation type given. The score returned must ALWAYS be an integer from 0 to 1000 inclusive.
 *
 * @param value - A number, indicating the value to be formatted.
 * @param evaluationType - A number, which indicates the evaluationType to be taken to format the value.
 * @returns A number (integer), describing the given value, in the agreement format.
 */
export const setScoreByEvaluationType = (value: number, evaluationType: number): number => {
  if (Number.isNaN(value)) {
    throw new Error('value must be a number')
  }

  const isValidEvaluationType = [1, 2, 3].includes(evaluationType)

  if (!isValidEvaluationType) {
    throw new Error('The evaluationType does not exist')
  }

  const score = (value * 1000) / getBaseByEvaluationType(evaluationType)
  const integer = Math.trunc(score)

  if (integer > 1000) {
    throw new Error('The integer cannot be more than 1000')
  }

  return integer
}
