import { isEmpty, mergeDeepLeft, mergeDeepRight } from 'ramda'
import { QUESTIONS_TYPES, UNION_ANSWER_LEFT, UNION_ANSWER_RIGHT } from './constants'

export const isFilled = (option) =>
  option.text !== '' || (option.type === 'image' && option.url !== '')

export const getFilledOptions = (options) => options.filter((option) => isFilled(option))

export const isSomeCorrectOption = (options) =>
  options.some((option) => option.correct && isFilled(option))

export const testFillInText = (string) => {
  if (!string) return true

  const matched = string.match(/(#{[0-9]+})/g)

  if (!matched) return true

  for (let i = 0; i < matched.length; i++) {
    const newI = parseInt(matched[i].replace(/(#{|})/, ''))
    if (newI !== i + 1) return true
  }

  return false
}

export const testUnionOptions = (unionOptions) => {
  const leftArray = Object.values(unionOptions[UNION_ANSWER_LEFT])
  const rightArray = Object.values(unionOptions[UNION_ANSWER_RIGHT])

  if (leftArray.length <= 1 && rightArray.length <= 1) return true
  if (!leftArray.length || !rightArray.length) return true

  return !!(
    leftArray.find((o) => o.text === '' && o.url === '') ||
    rightArray.find((o) => o.text === '' && o.url === '')
  )
}

export const getFormattedOptions = (options) =>
  getFilledOptions(options).map((option, index) => ({ ...option, order: index + 1 }))

const getIFrameUrl = (url) => {
  if (url.startsWith('http')) return url
  if (url.startsWith('<')) return /"(http.*?)"/.exec(url)[1]
  return ''
}

export const formatQuestionTemplate = (question) => {
  return {
    ...question,
    options: getFormattedOptions(question.options),
    iframeUrl: getIFrameUrl(question.iframeUrl),
  }
}

export const reorderQuestions = (questions) =>
  questions.map((question, index) => ({
    ...question,
    order: index + 1,
    score: parseFloat(question.score),
  }))

export const groupQuestionsBySections = (questions) => {
  const sections = questions.filter((q) => q.type === QUESTIONS_TYPES.SECTION)
  if (!sections.length) return questions

  return sections.map((section) => {
    const sectionQuestions = questions.filter((q) => q.sectionId === section.id)
    const sectionScore = sectionQuestions.reduce((sum, q) => sum + (q.score || 0), 0)

    return {
      ...section,
      questions: sectionQuestions,
      score: sectionScore,
    }
  })
}
export const retrieveQuestionsFromAPI = (questions) => {
  const processedQuestions = []

  questions?.forEach((item) => {
    if (item.type === QUESTIONS_TYPES.SECTION) {
      const sectionId = crypto.randomUUID()
      const sectionItem = { ...item }
      delete sectionItem.questions

      processedQuestions.push({
        ...sectionItem,
        id: sectionId,
        minLength: item.min || null,
        options: item.options || [],
        unionOptions: {},
        score: 0,
      })

      item.questions?.forEach((question) => {
        processedQuestions.push({
          ...question,
          minLength: question.min || null,
          options: question.options || [],
          sectionId: sectionId,
          unionOptions:
            question.type === QUESTIONS_TYPES.UNION && question.options
              ? {
                  [UNION_ANSWER_LEFT]: question.options.filter(
                    (opt) => opt.column === UNION_ANSWER_LEFT
                  ),
                  [UNION_ANSWER_RIGHT]: question.options.filter(
                    (opt) => opt.column === UNION_ANSWER_RIGHT
                  ),
                }
              : {},
        })
      })
    } else {
      processedQuestions.push({
        ...item,
        minLength: item.min || null,
        options: item.options || [],
        unionOptions:
          item.type === QUESTIONS_TYPES.UNION && item.options
            ? {
                [UNION_ANSWER_LEFT]: item.options.filter((opt) => opt.column === UNION_ANSWER_LEFT),
                [UNION_ANSWER_RIGHT]: item.options.filter(
                  (opt) => opt.column === UNION_ANSWER_RIGHT
                ),
              }
            : {},
      })
    }
  })

  return processedQuestions
}

export const getFileExtension = (fileName) => {
  const re = /(?:\.([^.]+))?$/
  return re.exec(fileName)[1] || '?'
}

export const removeFileExtension = (fileName) => {
  const re = /(?:\.([^.]+))?$/
  return fileName.replace(re, '')
}

const filterQuestionTypes = (allowedTypes) => (question) => allowedTypes.includes(question.type)

export const getAnswersTemplates = (questions = [], answers = []) => {
  const filterFn = filterQuestionTypes([
    QUESTIONS_TYPES.WRITING,
    QUESTIONS_TYPES.CHOICE,
    QUESTIONS_TYPES.FILLABLE,
    QUESTIONS_TYPES.UNION,
  ])
  const answersTemplate = questions
    .filter(filterFn)
    .reduce((acc, { type, id, min, wordCount, body = '', options = [] }) => {
      switch (type) {
        case QUESTIONS_TYPES.UNION: {
          const matches = {
            [UNION_ANSWER_LEFT]: {},
            [UNION_ANSWER_RIGHT]: {},
          }

          options.forEach((option) => {
            matches[option.column]['col' + option.column + 'ord' + option.order] = {
              column: option.column,
              id: option.id,
              matchesWith: -1,
              text: option.text,
              url: option.url,
              order: option.order,
            }
          })

          return { ...acc, [id]: { questionId: id, type, matches } }
        }
        case QUESTIONS_TYPES.FILLABLE: {
          const filled = {}
          const matched = body.match(/(#{[0-9]+})/g)
          if (matched)
            matched.forEach((m) => {
              filled[parseInt(m.replace(/(#{|})/, ''))] = ''
            })

          return { ...acc, [id]: { questionId: id, type, filled } }
        }
        default:
          return {
            ...acc,
            [id]: {
              questionId: id,
              type,
              optionId: 0,
              answer: '',
              min,
              minLength: min,
              wordCount,
            },
          }
      }
    }, {})

  const answersFiltered = answers.filter(filterFn)

  if (!isEmpty(answers)) {
    const formattedAnswers = answersFiltered.reduce((acc, answer) => {
      const newAns = {
        type: answer.type,
        questionId: answer.questionId,
        optionId: answer.optionId || 0,
        answer: answer.answer || '',
        min: answer.min,
        minLength: answer.min,
        wordCount: answer.wordCount,
      }

      switch (answer.type) {
        case QUESTIONS_TYPES.FILLABLE: {
          const filled = {}
          answer.filled.forEach((f) => {
            filled[f.number] = f.value
          })
          newAns.filled = filled
          break
        }
        case QUESTIONS_TYPES.UNION: {
          const matches = {
            [UNION_ANSWER_LEFT]: {},
            [UNION_ANSWER_RIGHT]: {},
          }

          answer.matches.forEach((m) => {
            matches[m.column]['col' + m.column + 'ord' + m.order] = {
              matchesWith: m.matchesWith,
              order: m.order,
              submissionAnswerId: m.submissionAnswerId,
            }
          })

          newAns.matches = matches
          break
        }
        default:
          break
      }

      return { ...acc, [answer.questionId]: newAns }
    }, {})

    const merged = mergeDeepRight(answersTemplate, formattedAnswers)

    return merged
  }

  return answersTemplate
}

export const getAttachmentAnswersTemplates = (questions = [], answers = []) => {
  const filterFn = filterQuestionTypes([QUESTIONS_TYPES.ATTACHMENT])
  const answersTemplate = questions.filter(filterFn).reduce(
    (acc, { type, id }) => ({
      ...acc,
      [id]: { questionId: id, files: [], filesToUpload: [], error: '' },
    }),
    {}
  )
  const answersFiltered = answers.filter(filterFn)

  if (!isEmpty(answersFiltered)) {
    const formattedAnswers = answersFiltered.reduce(
      (acc, answer) => ({ ...acc, [answer.questionId]: { files: answer.files } }),
      {}
    )
    return mergeDeepLeft(formattedAnswers, answersTemplate)
  }
  return answersTemplate
}

export const acceptTypesBuilder = (types) => {
  const { documents, pdf, images, powerpoint, excel, audio, video } = types
  let formats = []
  const keys = []
  if (documents) {
    keys.push(
      'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    )
    formats = formats.concat(['.doc', '.docx'])
  }
  if (pdf) {
    keys.push('application/pdf')
    formats = formats.concat(['.pdf'])
  }
  if (images) {
    keys.push('image/*')
    formats = formats.concat(['.jpg', '.png', '.gif'])
  }
  if (powerpoint) {
    keys.push(
      'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation'
    )
    formats = formats.concat(['.ppt', '.pptx'])
  }
  if (excel) {
    keys.push(
      'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    )
    formats = formats.concat(['.xls', '.xlsx'])
  }
  if (audio) {
    keys.push('audio/mpeg,audio/mp4,audio/ogg,audio/wav,audio/opus')
    formats = formats.concat(['.mp3', '.wav', '.opus']) // '.oga'
  }
  if (video) {
    keys.push('video/x-msvideo,video/mpeg,video/ogg,video/webm,video/mp4')
    formats = formats.concat(['.mp4', '.mpeg', '.avi']) // '.ogv', '.webm'
  }
  return {
    keys,
    formats,
  }
}

export const convertMBtoBytes = (mb) => mb * 1024 * 1024

export const getQuestionLegend = (index) => String.fromCharCode(65 + index)

export const getQuestionNumber = (questions, index) => {
  const previousQuestions = questions.filter((_, i) => i < index)
  const nonQuestions = previousQuestions.filter((q) => q.type === QUESTIONS_TYPES.TEXT)

  return previousQuestions.length - nonQuestions.length + 1
}
export const sortQuestionsWithSections = (questions) => {
  const sortedQuestions = []

  questions
    .filter((q) => q.type === QUESTIONS_TYPES.SECTION)
    .forEach((section) => {
      sortedQuestions.push(section)
      sortedQuestions.push(...questions.filter((q) => q.sectionId === section.id))
    })

  sortedQuestions.push(
    ...questions.filter((q) => !q.sectionId && q.type !== QUESTIONS_TYPES.SECTION)
  )

  return sortedQuestions
}
