import { makeStyles } from '@mui/styles'
import React from 'react'
import DrawerCloseButton from '@/legacy/components/v3/appBar/components/closeButton'
import type { Theme } from '@mui/material'
import clsx from 'clsx'

interface StylesProps {
  type?: string
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  headerContainer: {
    width: '100%',
    backgroundColor: (props) =>
      props.type === 'warning' ? '#F45C3A' : theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    minHeight: 60,
    zIndex: 1000,
    borderRadius: '8px 8px 0px 0px',
  },
  iconContainer: {
    display: 'flex',
    height: '100%',
    width: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: 18,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    color: 'white',
    textAlign: 'center',
    maxWidth: '95%',
  },
}))

type HeaderColor = 'regular' | 'warning'

interface HeaderProps {
  title: string
  onClose?: () => void
  type?: HeaderColor
  iconLeftComponent?: React.ReactNode
  onClickIconLeft?: () => void
  className?: string
  titleStyle?: string
  closingDisabled?: boolean
}

const Header = (props: HeaderProps) => {
  const {
    title,
    iconLeftComponent,
    onClose,
    onClickIconLeft = () => {},
    type = 'regular',
    className = '',
    titleStyle = {},
    closingDisabled,
  } = props

  const stylesProps = {
    type,
  }

  const renderSideDivs = onClose || iconLeftComponent

  const C = useStyles(stylesProps)
  return (
    <div className={clsx(C.headerContainer, className)}>
      {renderSideDivs && (
        <div className={C.iconContainer} onClick={onClickIconLeft}>
          {iconLeftComponent && iconLeftComponent}
        </div>
      )}
      <div className={C.titleContainer}>
        <span className={clsx(C.headerTitle, titleStyle)}>{title}</span>
      </div>
      {renderSideDivs && (
        <div className={C.iconContainer}>
          {onClose && (
            <DrawerCloseButton fill="white" onClick={onClose} closingDisabled={closingDisabled} />
          )}
        </div>
      )}
    </div>
  )
}

export default Header
