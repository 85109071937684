import { apiPrivate } from './..'
import type { COSMOS_KEY } from '@aula/config'
import { ROLE, libraryUrl, url } from '@aula/config'
import type { Availability } from '@/sections/content-creator/books/types/books'
import type { Moment } from 'moment'
import type { Params } from '@/sections/eb-admin/dashboard/types/common'
import type {
  CourseActivity,
  CourseType,
  CourseUnit,
  Currency,
} from '@/sections/content-creator/courses/types/courses'
import type { CourseCertificate } from '@/sections/content-creator/courses/types/certificate'
import type {
  AVAILABILITY,
  Book,
  Tag,
} from '@/sections/content-creator/sequenceDrawer/types/books'
import type { ActivityTypes } from './activities/activities'
import type { BasicAudience, VisibilityTag } from './organizations'
export const LibraryAPI = {
  createBook,
  deleteBook,
  getManyBooks,
  getManyBooksContentCreator,
  getBook,
  getBookLicenses,
  updateBook,
  createPremiumActivity,
  createPremiumActivityV2,
  getPremiumActivities,
  getPremiumActivitiesByAudience,
  getPremiumActivitiesContentCreator,
  getPremiumActivity,
  deletePremiumActivity,
  updatePremiumActivity,
  updatePremiumActivityV2,
  getTeacherBooks,
  getSingleLicenseStock,
  getOrganizationLicenseStock,
  createLicenseStock,
  updateLicenseStock,
  getAllTags,
  createLicense,
  revokeSingleLicense,
  revokeLicensesInBulk,
  claimCode,
  createSequence,
  editSequence,
  deleteSequence,
  getSequence,
  listSequences,
  getTeacherSequences,
  getTeacherSequencesByAudience,
  createCourse,
  getLibraryCourses,
  getDashboardCourses,
  getDashboardCourse,
  assignCourse,
  assignCourseV2,
  listContentCreatorCourses,
  getLibraryCoursesByAudiences,
  getCourse,
  editCourse,
  deleteCourse,
  deleteCourseAssignment,
  getCourseContent,
  getCourseProgress,
  setCourseCertificate,
  getCertificate,
  listOrganizationSignatures,
  getSignature,
  createSignature,
  getAudienceCount,
  createSequenceV2,
  editSequenceV2,
  getSequenceV2,
  getAudiences,
  createAudience,
  getAudienceContents,
  deleteAudience,
  editAudience,
  getBooksByAudience,
  getDocumentList,
  changeDocumentStatus,
  associateBookWithAClassroom,
}

export const ResourceTypeBook = 'book'
export const ResourceTypeActivity = 'activity'

export type LibraryResourceType = typeof ResourceTypeBook | typeof ResourceTypeActivity

export type CertificateOrg = {
  organizationId: number
  logo?: string
}

const appendRepeatedParams = (
  params: URLSearchParams,
  paramKey: string,
  values: string[] | number[] | Record<string, string | number>
) => {
  if (Array.isArray(values)) {
    values.forEach((value) => params.append(paramKey, value.toString()))
  } else if (typeof values === 'object' && values !== null) {
    for (const key in values) {
      const value = values[key]
      if (value !== undefined) {
        params.append(paramKey, value.toString())
      }
    }
  }
}

const buildCertificate = (cert: CourseCertificate) => {
  return {
    ...cert,
    signatures: cert?.signatures?.map((s) => s.id),
  }
}

/**
 * Creates a book.
 *
 * @role Content Creator
 * @param title
 * @param description
 * @param availability
 * @param fileId
 * @param fileUrl
 * @param organizationID
 * @param coverUrl
 * @param tags
 */
function createBook(
  title: string,
  description: string,
  availability: Availability,
  fileId: number,
  fileUrl: string,
  organizationId: number,
  coverUrl: string,
  tags: number[],
  availableInLibrary: boolean,
  audienceIds?: number[],
  activityIds?: number[]
) {
  const payload = {
    title,
    description,
    availability,
    fileId,
    fileUrl,
    organizationId,
    coverUrl,
    tags,
    availableInLibrary,
    ...(audienceIds && { audienceIds }),
    ...(activityIds && { activityIds }),
  }

  return apiPrivate.post(`${libraryUrl}/v2/book`, payload)
}

/**
 * Deletes a book.
 *
 * @role Content Creator
 * @param bookID
 */
function deleteBook(bookID: number) {
  return apiPrivate.delete(`${libraryUrl}/v1/book/${bookID}`)
}

/**
 * Gets books.
 * Can be filtered by organization/title/tags.
 *
 * @role Teacher
 * @param organizationIDs
 * @param pagination
 * @param title
 * @param tags
 */
function getManyBooks(
  organizationIDs?: number[],
  pagination?: Pagination,
  title?: string,
  tagIDs?: number[],
  getLicenses?: boolean,
  onlyWithLicenses?: boolean
) {
  const requestParams = new URLSearchParams()

  if (organizationIDs) appendRepeatedParams(requestParams, 'organization_ids', organizationIDs)
  if (tagIDs) appendRepeatedParams(requestParams, 'tags', tagIDs)
  if (pagination?.limit) requestParams.append('limit', pagination.limit.toString())
  if (pagination?.offset) requestParams.append('offset', pagination.offset.toString())
  if (title) requestParams.append('title', title)
  if (getLicenses) requestParams.append('get_licenses', 'true')
  if (onlyWithLicenses) requestParams.append('only_with_licenses', 'true')

  const request = {
    params: requestParams,
  }

  return apiPrivate.get(`${libraryUrl}/v2/library/books`, request)
}

/**
 * Gets books.
 * Can be filtered by organization/title.
 *
 * @role Content Creator
 * @param organizationIDs
 * @param pagination
 * @param title
 */
function getManyBooksContentCreator(
  organizationIDs?: number[],
  pagination?: Pagination,
  title?: string
) {
  const requestParams = new URLSearchParams()

  if (organizationIDs) appendRepeatedParams(requestParams, 'organization_ids', organizationIDs)
  if (title) requestParams.append('title', title)
  if (pagination?.limit) requestParams.append('limit', pagination.limit.toString())
  if (pagination?.offset) requestParams.append('offset', pagination.offset.toString())

  const request = {
    params: requestParams,
  }

  return apiPrivate.get(`${libraryUrl}/v1/books`, request)
}

interface GetBookResponse {
  description: {
    book: Book
  }
}

function getBook(bookId: number) {
  return apiPrivate.get<GetBookResponse>(`${libraryUrl}/v1/book/${bookId}`)
}

/**
 * Gets all classrooms/groups/students who have been granted licenses for a given book.
 *
 * @role Any
 * @param bookID
 *
 */
function getBookLicenses(bookID: number) {
  return apiPrivate.get(`${libraryUrl}/v1/book/${bookID}/licenses`)
}

/**
 * Updates a book
 *
 * @role Any
 * @param bookId
 * @param title
 * @param description
 * @param availability
 * @param organizationId
 * @param isbn
 * @param fileUrl
 * @param coverUrl
 * @param tags
 */
function updateBook(
  bookId: number,
  title: string,
  description: string,
  availability: Availability,
  organizationId: number,
  isbn: string,
  fileUrl: string,
  coverUrl: string,
  availableInLibrary: boolean,
  tags?: number[],
  audienceIds?: number[],
  activityIds?: number[]
) {
  const payload = {
    title,
    description,
    availability,
    fileUrl,
    organizationId,
    coverUrl,
    tags,
    isbn,
    availableInLibrary,
    ...(audienceIds && { audienceIds }),
    ...(activityIds && { activityIds }),
  }

  return apiPrivate.put(`${libraryUrl}/v2/book/${bookId}`, payload)
}

/**
 * Creates a premium activity.
 *
 * @role Content Creator
 * @param title
 * @param activityID
 * @param organizationID
 * @param origin
 * @param description
 * @param availability
 */
function createPremiumActivity(
  title: string,
  activityID: number,
  organizationID: number,
  origin: number,
  description: string,
  availability: Availability,
  tags: number[],
  availableInLibrary = false
) {
  const data = {
    title,
    origin,
    activityId: activityID,
    organizationId: organizationID,
    description,
    availability,
    availableInLibrary: availableInLibrary,
    tags,
  }

  return apiPrivate.post(`${libraryUrl}/v1/premium_activity`, data)
}

type CreatePremiumActivityParams = {
  title: string
  activityId: number
  organizationId: number
  origin: number
  description: string
  availability: Availability
  tags: number[]
  audienceIds?: number[]
  availableInLibrary?: boolean
}

function createPremiumActivityV2(params: CreatePremiumActivityParams) {
  const { availableInLibrary = false, ...other } = params
  const data = {
    availableInLibrary,
    ...other,
  }
  return apiPrivate.post(`${libraryUrl}/v2/premium_activity`, data)
}

export type Pagination = {
  limit: number
  offset: number
}

interface GameLevel {
  name: string
  description: string
  level: number
}

interface GameImage {
  name: string
  url: string
}

export interface PremiumActivity {
  id: number
  createdAt: string
  activityId: number
  organizationId: number
  organizationName: string
  organizationLogo: string
  title: string
  description: string
  origin: number
  originName: string
  availability: string
  type: string
  hasQuestions: boolean
  hasFiles: boolean
  hasDeliverables: boolean
  tags: Tag[]
  gameLevels: GameLevel[]
  gameImages: GameImage[]
  userId: number
  draft: boolean
  availableInLibrary: boolean
  amount: number
}

export interface GetPremiumActivitiesResponse {
  description: PremiumActivity[]
  total: number
}

/**
 * Gets premium activities.
 *
 * @role Any
 * @param activityIDs
 * @param organizationIDs
 * @param origins
 * @param pagination
 * @param tagIDs
 * @param getLicenses
 * @param onlyWithLicenses
 * @param view Type "libraryTabType". Query param to ask backend for a group of entities to show on activity tabs
 * @param search
 * @param version
 */
function getPremiumActivities(
  role: ROLE | string,
  activityIDs?: number[],
  organizationIDs?: number[],
  origins?: number[],
  pagination?: Pagination,
  tagIDs?: number[],
  getLicenses?: boolean,
  onlyWithLicenses?: boolean,
  view?: string,
  search?: string,
  version?: string // TODO deprecate this and only use v2 once the backend makes the necessary changes
) {
  const requestParams = new URLSearchParams()

  if (activityIDs) appendRepeatedParams(requestParams, 'activities', activityIDs)
  if (organizationIDs) appendRepeatedParams(requestParams, 'organizations', organizationIDs)
  if (origins) {
    appendRepeatedParams(requestParams, 'origins', origins)
    // this is v2's version of 'origins'
    if (origins.length > 0) requestParams.append('book_id', origins[0].toString())
  }
  if (tagIDs) appendRepeatedParams(requestParams, 'tags', tagIDs)

  if (pagination?.limit) requestParams.append('limit', pagination.limit.toString())
  if (pagination?.offset) requestParams.append('offset', pagination.offset.toString())

  if (getLicenses) requestParams.append('get_licenses', 'true')
  if (onlyWithLicenses) requestParams.append('only_with_licenses', 'true')

  if (search && search.trim() !== '') requestParams.append('search', search)
  if (view) requestParams.append('view', view)

  if (role) requestParams.append('role', role !== ROLE.CONTENT_CREATOR ? ROLE.TEACHER : role)

  const request = {
    params: requestParams,
  }

  if (!version) version = 'v2'
  return apiPrivate.get<GetPremiumActivitiesResponse>(
    `${libraryUrl}/${version}/library/premium_activities`,
    request
  )
}

export enum View {
  ACTIVITY = 'activity',
  GAME = 'game',
}

interface GetPremiumActivitiesByAudienceParams {
  organizationId: number
  role: ROLE
  limit: number
  offset?: number
  search?: string
  bookId?: number
  onlyWithLicences?: boolean
  view?: View
  tags?: number[]
}

export interface PremiumActivityByAudience {
  id: number
  createdAt: string
  activityId: number
  organizationId: number
  organizationName: string
  organizationLogo: string
  title: string
  description: string
  origin: number
  originName: string
  availability: AVAILABILITY
  type: ActivityTypes
  hasQuestions: boolean
  hasFiles: boolean
  hasDeliverables: boolean
  tags: Tag[]
  gameLevels: GameLevel[]
  gameImages: GameImage[]
  userId: number
  draft: boolean
  availableInLibrary: boolean
  amount: number
  audiences: { id: number; title: string }[]
}
interface GetPremiumActivitiesByAudienceResponse {
  description: {
    activities: PremiumActivityByAudience[]
    total: number
    count: number
  }
}

/**
 * Get premium activies filtered by audience.
 */
function getPremiumActivitiesByAudience({
  search,
  view,
  ...rest
}: GetPremiumActivitiesByAudienceParams): Promise<
  GetPremiumActivitiesByAudienceResponse['description']
> {
  const validParams = {
    ...(search?.trim() !== '' && { search }),
    ...(view && { view }),
    ...rest,
  }

  return apiPrivate
    .get<GetPremiumActivitiesByAudienceResponse>(`${libraryUrl}/v2/premium_activities`, {
      params: validParams,
    })
    .then((resp) => resp.data.description)
}

/**
 * Gets premium activities.
 *
 * @role Content-Creator
 * @param activityIDs
 * @param organizationIDs
 * @param pagination
 */
function getPremiumActivitiesContentCreator(
  activityIDs?: number[],
  organizationIDs?: number[],
  pagination?: Pagination,
  search?: string,
  types: string[] = [],
  view?: string
) {
  const requestParams = new URLSearchParams()

  if (activityIDs) appendRepeatedParams(requestParams, 'activities', activityIDs)
  if (organizationIDs) appendRepeatedParams(requestParams, 'organizations', organizationIDs)
  if (types) appendRepeatedParams(requestParams, 'types', types)

  if (pagination?.limit) requestParams.append('limit', pagination.limit.toString())
  if (pagination?.offset) requestParams.append('offset', pagination.offset.toString())

  if (search) requestParams.append('search', search)
  if (view) requestParams.append('view', view)

  const request = {
    params: requestParams,
  }

  return apiPrivate.get(`${libraryUrl}/v1/premium_activities`, request)
}

/**
 * Gets a premium activity.
 *
 * @role Any
 * @param premiumActivityID
 */
function getPremiumActivity(premiumActivityID: number) {
  return apiPrivate.get(`${libraryUrl}/v1/premium_activity/${premiumActivityID}`)
}

/**
 * Deletes a premium activity.
 *
 * @role Any
 * @param activityIDs
 * @param organizationIDs
 * @param origins
 */
function deletePremiumActivity(premiumActivityID: number) {
  return apiPrivate.delete(`${libraryUrl}/v1/premium_activity/${premiumActivityID}`)
}

/**
 * Updates a premium activity.
 *
 * @role Content Creator
 * @param premiumActivityID
 * @param title
 * @param organizationID
 * @param origin
 * @param description
 * @param availability
 * @param tags
 * @param availableInLibrary
 */
function updatePremiumActivity(
  premiumActivityID: number,
  title: string,
  organizationID: number,
  origin: number,
  description: string,
  availability: Availability,
  tags: number[],
  availableInLibrary = false
) {
  const data = {
    title,
    origin,
    organizationId: organizationID,
    description,
    availability,
    availableInLibrary: availableInLibrary,
    tags,
  }

  return apiPrivate.put(`${libraryUrl}/v1/premium_activity/${premiumActivityID}`, data)
}

type UpdatePremiumActivityParams = {
  premiumActivityId: number
  title: string
  organizationId: number
  origin: number
  description: string
  availability: Availability
  tags: number[]
  audienceIds?: number[]
  availableInLibrary?: boolean
}

function updatePremiumActivityV2(params: UpdatePremiumActivityParams) {
  const { availableInLibrary = false, premiumActivityId, ...other } = params
  const data = {
    availableInLibrary,
    ...other,
  }
  return apiPrivate.put(`${libraryUrl}/v2/premium_activity/${premiumActivityId}`, data)
}

type AssociateBookWithAClassroomParams = {
  classroomId: number
  groupId?: number
  bookId: number
}
/**
 * Associate book with classroom/classroom-group, adding the book in the classroom's student's backpacks
 * @role Teacher
 * @param params
 *
 */
async function associateBookWithAClassroom(ps: AssociateBookWithAClassroomParams) {
  const { bookId, ...data } = ps
  return apiPrivate
    .post(`${libraryUrl}/v1/book/${bookId}/classroom`, data)
    .then((response) => response.data)
}

/**
 * Gets teacher books.
 *
 * @role Teacher
 *
 */
function getTeacherBooks() {
  return apiPrivate.get(`${libraryUrl}/v1/books`)
}

/**
 * Gets license stock for a given resource.
 *
 * @role Any
 * @param organizationID
 * @param resourceType
 * @param resourceID
 *
 */
function getSingleLicenseStock(
  organizationID: number,
  resourceType: LibraryResourceType,
  resourceID: number
) {
  return apiPrivate.get(
    `${libraryUrl}/v1/license_stock/organization/${organizationID}/${resourceType}/${resourceID}`
  )
}

/**
 * Gets license stock for every resource tied to an organization (either activities or books).
 *
 * @role Admin
 * @param organizationID
 * @param resourceType
 * @param params
 *
 */
function getOrganizationLicenseStock(
  organizationID: number,
  resourceType: LibraryResourceType,
  params: Params
) {
  return apiPrivate.get(
    `${libraryUrl}/v1/license_stock/organization/${organizationID}/${resourceType}`,
    { params }
  )
}

/**
 * Creates resource stock (either activities or books) for an organization.
 *
 * @role Admin
 * @param organizationID
 * @param resourceType
 * @param resourceID
 * @param expiry
 * @param amount
 *
 */

function createLicenseStock(
  organizationID: number,
  resourceType: LibraryResourceType,
  resourceID: number,
  expiry: Moment,
  amount: number
) {
  const data = {
    resourceId: resourceID,
    resourceType: resourceType,
    organizationId: organizationID,
    expiry: expiry.format(),
    amount,
  }

  return apiPrivate.post(`${libraryUrl}/v1/license_stock`, data)
}

/**
 * Updates resource stock (either activities or books) for an organization.
 *
 * @role Admin
 * @param organizationID
 * @param resourceType
 * @param resourceID
 * @param expiry
 * @param amount
 *
 */

function updateLicenseStock(
  organizationID: number,
  resourceType: LibraryResourceType,
  id: number,
  resourceID: number,
  expiry: Moment,
  amount: number
) {
  const data = {
    id,
    expiry: expiry.format(),
    amount,
  }

  return apiPrivate.put(
    `${libraryUrl}/v1/license_stock/organization/${organizationID}/${resourceType}/${resourceID}`,
    data
  )
}

/**
 * Assigns book or activity licenses to users, subgroups or classrooms.
 *
 * @role Teacher
 * @param organizationID
 * @param resourceType
 * @param resourceID
 * @param expiry
 * @param amount
 *
 */

function createLicense(
  organizationID: number,
  resourceType: LibraryResourceType,
  resourceID: number,
  expiry: Moment,
  destinationType: 'subgroup' | 'classroom' | 'user',
  destinationId: number
) {
  let dynamicKey

  switch (destinationType) {
    case 'subgroup':
      dynamicKey = 'group_id'
      break
    case 'classroom':
      dynamicKey = 'classroom_id'
      break
    default:
    case 'user':
      dynamicKey = 'user_id'
  }

  const data = {
    resourceId: resourceID,
    resourceType: resourceType,
    organizationId: organizationID,
    [dynamicKey]: destinationId,
    // expiry,
    originId: 0,
    originType: 'license_stock',
  }

  return apiPrivate.post(`${libraryUrl}/v1/license`, data)
}

/**
 * Deletes a license.
 *
 * @role Teacher
 * @param licenseID
 *
 */

function revokeSingleLicense(licenseID: number) {
  return apiPrivate.delete(`${libraryUrl}/v1/license/${licenseID}`)
}

/**
 * Deletes many licenses.
 *
 * @role Teacher
 * @param resourceType
 * @param resourceID
 * @param groupID
 * @param classroomID
 *
 */

function revokeLicensesInBulk(
  resourceType: LibraryResourceType,
  resourceID: number,
  groupID?: number,
  classroomID?: number
) {
  const data = {
    resourceType: resourceType,
    resourceId: resourceID,
    ...(groupID ? { groupId: groupID } : {}),
    ...(classroomID ? { classroomId: classroomID } : {}),
  }

  return apiPrivate.delete(`${libraryUrl}/v1/license-bulk`, { data })
}

/**
 * Gets a list of tags.
 *
 * @role Any
 *
 */
function getAllTags() {
  return apiPrivate.get(`${libraryUrl}/v1/tags`)
}

/**
 * Gets a list of tags.
 *
 * @role Any
 *
 */
function claimCode(code: string) {
  const data = {
    uuid: code,
  }
  return apiPrivate
    .post(`${libraryUrl}/v1/codes/claim`, data)
    .then(() => {}) // make it Promise<void>
    .catch((err) => {
      throw err
    })
}

/**
 * Creates a sequence.
 *
 * @role Content creator
 * @param organizationId
 * @param bookId
 * @param title
 * @param availability
 * @param description
 * @param activities
 *
 */
function createSequence(
  bookId: number,
  title: string,
  availability: Availability,
  description: string,
  activities: number[]
) {
  const data = {
    bookId: bookId,
    title,
    availability,
    description,
    activities,
  }

  return apiPrivate.post(`${libraryUrl}/v1/sequences`, data)
}

/**
 * Gets a sequence.
 *
 * @role Content creator
 * @param sequenceId
 *
 */
function getSequence(sequenceId: number) {
  return apiPrivate.get(`${libraryUrl}/v1/sequences/${sequenceId}`)
}

/**
 * Gets a list of sequences.
 *
 * @role Content creator
 * @param organizationIds
 * @param availability
 * @param title
 * @param pagination
 *
 */
function listSequences(
  organizationIds?: number[],
  availability?: Availability,
  title?: string,
  pagination?: Pagination
) {
  const requestParams = new URLSearchParams()

  if (organizationIds) appendRepeatedParams(requestParams, 'organization_ids', organizationIds)
  if (availability) requestParams.append('availability', availability)
  if (title) requestParams.append('title', title)
  if (pagination?.limit) requestParams.append('limit', pagination.limit.toString())
  if (pagination?.offset) requestParams.append('offset', pagination.offset.toString())

  const request = {
    params: requestParams,
  }

  return apiPrivate.get(`${libraryUrl}/v1/sequences`, request)
}

/**
 * Gets a list of sequences.
 *
 * @role Teacher
 * @param organizationIds
 * @param availability
 * @param title
 * @param pagination
 *
 */
function getTeacherSequences(
  organizationIds?: number[],
  availability?: Availability,
  title?: string,
  search?: string,
  pagination?: Pagination,
  onlyWithLicenses?: boolean,
  tagIDs?: number[]
) {
  const requestParams = new URLSearchParams()

  if (organizationIds) appendRepeatedParams(requestParams, 'organization_ids', organizationIds)
  if (availability) requestParams.append('availability', availability)
  if (title) requestParams.append('title', title)
  if (search) requestParams.append('search', search)
  if (pagination?.limit) requestParams.append('limit', pagination.limit.toString())
  if (pagination?.offset) requestParams.append('offset', pagination.offset.toString())
  if (onlyWithLicenses) requestParams.append('only_with_licenses', onlyWithLicenses.toString())
  if (tagIDs) appendRepeatedParams(requestParams, 'tags', tagIDs)

  const request = {
    params: requestParams,
  }

  return apiPrivate.get(`${libraryUrl}/v2/library/sequences`, request)
}

interface GetTeacherSequencesByAudienceParams {
  organizationId: number
  role: ROLE.CONTENT_CREATOR | ROLE.TEACHER
  tags?: number[]
  onlyWithLicenses?: boolean
  limit: number
  offset: number
  search?: string
}

export interface TeacherSequence {
  id: number
  createdAt: string
  organizationId: number
  organizationName: string
  organizationLogo: string
  title: string
  description: string
  bookId: number
  bookTitle: string
  activityCount: number
  unitCount: number
  availability: AVAILABILITY
  amount: number
}

interface GetTeacherSequencesByAudienceResponse {
  description: {
    sequences: TeacherSequence[]
    count: number
    total: number
  }
}

/**
 * Get a list of sequences by audience.
 */
function getTeacherSequencesByAudience(
  params: GetTeacherSequencesByAudienceParams
): Promise<GetTeacherSequencesByAudienceResponse['description']> {
  const { organizationId, role, tags, onlyWithLicenses, limit, offset, search } = params
  const requestParams = new URLSearchParams()

  requestParams.append('organization_id', organizationId.toString())
  requestParams.append('role', role)
  if (tags && tags.length > 0) appendRepeatedParams(requestParams, 'tags', tags)
  if (onlyWithLicenses) requestParams.append('only_with_licenses', onlyWithLicenses.toString())
  requestParams.append('limit', limit.toString())
  requestParams.append('offset', offset.toString())
  if (search && search.trim() !== '') requestParams.append('search', search)

  return apiPrivate
    .get<GetTeacherSequencesByAudienceResponse>(`${libraryUrl}/v2/sequences`, {
      params: requestParams,
    })
    .then((resp) => resp.data.description)
}

/**
 * Edits a sequence.
 *
 * @role Content creator
 * @param organizationId
 * @param bookId
 * @param title
 * @param availability
 * @param description
 * @param activities
 *
 */
function editSequence(
  sequenceId: number,
  bookId: number,
  title: string,
  availability: Availability,
  description: string,
  activities: number[]
) {
  const data = {
    bookId: bookId,
    title,
    availability,
    description,
    activities,
  }

  return apiPrivate.put(`${libraryUrl}/v1/sequences/${sequenceId}`, data)
}

/**
 * Deletes a sequence.
 *
 * @role Content creator
 * @param sequenceId
 *
 */
function deleteSequence(sequenceId: number) {
  return apiPrivate.delete(`${libraryUrl}/v1/sequences/${sequenceId}`)
}

/**
 * Creates a course.
 *
 * @role Content creator
 * @param organizationId
 * @param name
 * @param shortDescription
 * @param longDescription
 * @param requirements
 * @param addressedTo
 * @param currency
 * @param price
 * @param duration
 * @param type
 * @param coverPhoto
 * @param activities
 * @param units
 * @param tags
 * @param includeDuration
 * @param includePlace
 * @param includeDate
 * @param text
 * @param logo
 * @param signatures
 */

function createCourse(
  organizationId: number,
  name: string,
  shortDescription: string,
  longDescription: string,
  requirements: string,
  addressedTo: string,
  currency: Currency,
  price: number,
  duration: number,
  type: CourseType,
  coverPhoto: string,
  activities: CourseActivity[],
  units: CourseUnit[],
  tags: string[],
  certificate: CourseCertificate | null,
  availableInLibrary: boolean,
  audienceIds?: number[]
) {
  const data = {
    organizationId: organizationId,
    name,
    shortDescription: shortDescription,
    longDescription: longDescription,
    requirements,
    addressedTo: addressedTo,
    currency,
    price,
    duration,
    type,
    coverPhoto: coverPhoto,
    activities: activities.map((act) => ({ ...act })),
    units: units.map((unit) => ({ ...unit })),
    tags,
    availableInLibrary,
    certificate: certificate ? buildCertificate(certificate) : null,
    ...(audienceIds && { audienceIds }),
  }

  return apiPrivate.post(`${libraryUrl}/v1/courses`, data)
}
/**
 * Edits a course.
 *
 * @role Content creator
 * @param courseId
 * @param organizationId
 * @param name
 * @param shortDescription
 * @param longDescription
 * @param requirements
 * @param addressedTo
 * @param currency
 * @param price
 * @param duration
 * @param type
 * @param coverPhoto
 * @param activities
 * @param units
 * @param tags
 *
 */
function editCourse(
  courseId: number,
  organizationId: number,
  name: string,
  shortDescription: string,
  longDescription: string,
  requirements: string,
  addressedTo: string,
  currency: Currency,
  price: number,
  duration: number,
  type: CourseType,
  coverPhoto: string,
  activities: CourseActivity[],
  units: CourseUnit[],
  tags: string[],
  certificate: CourseCertificate | null,
  availableInLibrary: boolean,
  audienceIds?: number[]
) {
  const data = {
    courseId: courseId,
    organizationId: organizationId,
    name,
    shortDescription: shortDescription,
    longDescription: longDescription,
    requirements,
    addressedTo: addressedTo,
    currency,
    price,
    duration,
    type,
    availableInLibrary,
    ...(audienceIds && { audienceIds }),
    coverPhoto: coverPhoto,
    activities: activities.map((act) => ({ ...act })),
    units: units.map((unit) => ({ ...unit })),
    tags,
    certificate: certificate ? buildCertificate(certificate) : null,
  }

  return apiPrivate.put(`${libraryUrl}/v1/courses/${courseId}`, data)
}

/**
 * Get course.
 *
 * @role Any
 * @param courseID
 * @param organizationId
 * @param role
 *
 */

type GetCourseParams = { courseId: number; role: ROLE; organizationId: number }

function getCourse(params: GetCourseParams) {
  const { courseId, organizationId, role } = params
  return apiPrivate.get(`${libraryUrl}/v1/courses/${courseId}`, {
    params: {
      organizationId,
      role,
    },
  })
}

/**
 * Delete a Course.
 *
 * @role Content Creator
 * @param organizationId
 * @param pagination
 *
 */

function deleteCourse(courseID: number) {
  return apiPrivate.delete(`${libraryUrl}/v1/courses/${courseID}`)
}

/**
 * Delete a Course Assignment (and all its assigned activities).
 *
 * @role Teacher
 *
 */

function deleteCourseAssignment(courseID: number) {
  return apiPrivate.delete(`${url}/v1/courses/${courseID}`)
}

/**
 * Get courses as contentCreator.
 *
 * @role ContentCreator
 * @param organizationId
 * @param pagination
 *
 */

function listContentCreatorCourses(
  organizationId: number,
  role: ROLE,
  pagination?: Pagination,
  search?: string
) {
  const requestParams = new URLSearchParams()

  requestParams.append('organization_id', organizationId.toString())
  requestParams.append('role', role.toString())
  if (pagination?.limit) requestParams.append('limit', pagination.limit.toString())
  if (pagination?.offset) requestParams.append('offset', pagination.offset.toString())
  if (search) requestParams.append('search', search)

  const request = {
    params: requestParams,
  }

  return apiPrivate.get(`${libraryUrl}/v1/courses`, request)
}

/**
 * Get courses as teacher.
 *
 * @role Teacher
 * @param organizationId
 * @param pagination
 *
 */

function getLibraryCourses(organizationId: number, pagination: Pagination, getActivities = false) {
  const requestParams = new URLSearchParams()

  requestParams.append('organization_id', organizationId.toString())
  requestParams.append('limit', pagination.limit.toString())
  requestParams.append('offset', pagination.offset.toString())
  requestParams.append('get_activities', String(getActivities))

  const request = {
    params: requestParams,
  }

  return apiPrivate.get(`${libraryUrl}/v2/library/courses`, request)
}

type GetLibraryCourseByAudience = {
  role: ROLE
  limit: number
  offset: number
  organizationId: number
  title?: string
  getActivities?: boolean
  tags?: number[]
  onlyWithLicenses?: boolean
}

function getLibraryCoursesByAudiences(params: GetLibraryCourseByAudience) {
  return apiPrivate.get(`${libraryUrl}/v2/courses`, { params })
}

function getDashboardCourses(
  role: string,
  organizationID: number,
  academicPeriodID: number,
  pagination: Pagination
) {
  const requestParams = new URLSearchParams()

  requestParams.append('role', role.toString())
  requestParams.append('limit', pagination.limit.toString())
  requestParams.append('offset', pagination.offset.toString())
  requestParams.append('organization_id', organizationID.toString())
  requestParams.append('academic_period_id', academicPeriodID.toString())

  const request = {
    params: requestParams,
  }

  return apiPrivate.get(`${url}/v1/courses`, request)
}

function getDashboardCourse(courseID: number, role: string) {
  const requestParams = new URLSearchParams()

  requestParams.append('role', role.toString())

  const request = {
    params: requestParams,
  }

  return apiPrivate.get(`${url}/v1/courses/${courseID}`, request)
}

/**
 * Assign course to classroom.
 *
 * @role Teacher
 * @param classroomId
 * @param subjectId
 * @param subgroupIds
 * @param date
 * @param start
 * @param end
 * @param deadline
 * @param publicationDate
 * @param enableChat
 * @param courseId
 *
 */
function assignCourse(
  classroomId: number,
  subjectId: number,
  subgroupIds: number[],
  date: Moment,
  start: Moment,
  end: Moment,
  deadline: Moment,
  publicationDate: Moment,
  enableChat: boolean,
  courseId: number
) {
  const data = {
    classroomId: classroomId,
    subjectId: subjectId,
    date,
    start,
    end,
    deadline,
    publicationDate: publicationDate,
    enableChat: enableChat,
    courseId: courseId,
    subgroupIds: subgroupIds,
  }

  return apiPrivate.post(url + `/v1/courses`, data)
}

/**
 * Assign course to classroom (V2).
 *
 * @role Teacher
 * @param classroomId
 * @param subjectId
 * @param subgroupIds
 * @param activitiesDates
 * @param courseId
 * @param startDate
 * @param endDate
 *
 */
function assignCourseV2(
  classroomId: number,
  subjectId: number,
  subgroupIds: number[],
  activitiesDates: any[],
  courseId: number,
  startDate: Moment,
  endDate?: Moment
) {
  const data = {
    classroomId: classroomId,
    subjectId: subjectId,
    courseId: courseId,
    subgroupIds: subgroupIds,
    activityDates: activitiesDates,
    startDate: startDate.format(),
    ...(endDate ? { endDate: endDate.format() } : {}),
  }

  return apiPrivate.post(url + `/v2/courses`, data)
}

/**
 * Retrieves course content.
 *
 * @param courseId
 * @param role
 *
 */

function getCourseContent(courseID: number, role: string) {
  const requestParams = new URLSearchParams()

  requestParams.append('role', role.toString())

  const request = {
    params: requestParams,
  }

  return apiPrivate.get(`${url}/v1/courses/${courseID}/content`, request)
}

/**
 * Retrieves course progress.
 *
 * @role Teacher
 * @param courseId
 *
 */

function getCourseProgress(courseID: number) {
  return apiPrivate.get(`${url}/v1/views/teacher/course/${courseID}/progress`)
}

/**
 * Issues a course certificate to an student
 * @returns
 */

function setCourseCertificate(courseID: number, studentID: number) {
  const data = {
    courseId: courseID,
    studentId: studentID,
  }
  return apiPrivate.post(`${url}/v1/certificates`, data)
}

/**
 * Gets certificate data by its UUID
 * @param UUID
 * @returns
 */

function getCertificate(UUID: string) {
  return apiPrivate.get(`${url}/v1/p/certificates/${UUID}`)
}

/**
 * Fetches the list of signatures for a given organization.
 * @param organizationID
 */

function listOrganizationSignatures(organizationId: number) {
  return apiPrivate.get(`${libraryUrl}/v1/organizations/${organizationId}/signatures`)
}

/**
 * Fetches a specific signature.
 * @param signatureID
 */

function getSignature(signatureId: number) {
  return apiPrivate.get(`${libraryUrl}/v1/signatures/${signatureId}`)
}

/**
 * Creates a new signature for a given organization.
 * @param organizationID
 */

function createSignature(
  organizationId: number,
  position: string,
  clarification: string,
  signatureUrl: string
) {
  const data = {
    organizationId,
    position,
    clarification,
    signatureUrl,
  }

  return apiPrivate.post(`${libraryUrl}/v1/signatures`, data)
}

function getAudienceCount(organizationId: number) {
  return apiPrivate
    .get(`${libraryUrl}/v1/audiences-count`, { params: { organizationId } })
    .then((resp) => resp.data.description.count)
}

interface CreateSequenceV2Body {
  bookId: number
  title: string
  organizationId: number
  description: string
  activities: { id: number; order: number }[]
  units: { title: string; order: number }[]
  availability: AVAILABILITY
  audienceIds?: number[]
}

function createSequenceV2(body: CreateSequenceV2Body) {
  return apiPrivate.post(`${libraryUrl}/v2/sequences`, body)
}

export interface SequenceInfoToEdit {
  bookId: number
  title: string
  description: string
  activities: { id: number; order: number }[]
  units: { id: number; title: string; order: number }[]
  availability: AVAILABILITY
  audienceIds?: number[]
}

function editSequenceV2(sequenceId: number, body: SequenceInfoToEdit) {
  return apiPrivate.put(`${libraryUrl}/v2/sequences/${sequenceId}`, body)
}

export interface Sequence {
  id: number
  createdAt: string
  organization: {
    id: number
    name: string
    logo: string
  }
  title: string
  description: string
  book: {
    id: number
    title: string
  }
  activityCount: number
  availability: AVAILABILITY
  activities: Activity[]
  units: Unit[]
  audiences: BasicAudience[]
  availableInLibrary: boolean
}

export interface Activity {
  id: number
  activityId: number
  title: string
  activityType: ActivityTypes
  order: number
}

export interface Unit {
  id: number
  title: string
  order: number
}

interface GetSequenceV2Reponse {
  description: {
    sequence: Sequence
  }
}

interface GetSequenceV2ParamsBase {
  activitiesByAudiences: boolean
}

export interface GetSequenceV2ParamsAsATeacher extends GetSequenceV2ParamsBase {
  role: ROLE.TEACHER
  organizationId: number
}

export interface GetSequenceV2ParamsAsAContentCreator extends GetSequenceV2ParamsBase {
  role: ROLE.CONTENT_CREATOR
}

type GetSequenceV2Params = GetSequenceV2ParamsAsATeacher | GetSequenceV2ParamsAsAContentCreator

function getSequenceV2(sequenceId: number, params: GetSequenceV2Params): Promise<Sequence> {
  return apiPrivate
    .get<GetSequenceV2Reponse>(`${libraryUrl}/v2/sequences/${sequenceId}`, { params })
    .then((resp) => resp.data.description.sequence)
}

interface GetAudiencesParams {
  organizationId?: number
  limit: number
  offset: number
  role?: ROLE
  search?: string
  cosmos?: COSMOS_KEY[]
}

export interface Audience {
  cosmos: string[]
  createdAt: string
  id: number
  name: string
}
interface GetAudiencesResponse {
  description: {
    audiences: Audience[]
    count: number
    more: boolean
  }
}

function getAudiences(params: GetAudiencesParams): Promise<GetAudiencesResponse['description']> {
  const { organizationId, limit, offset, role, search, cosmos } = params
  const requestParams = new URLSearchParams()

  if (organizationId && !isNaN(organizationId))
    requestParams.append('organization_id', organizationId.toString())
  if (role) requestParams.append('role', role)
  if (search) requestParams.append('search', search)
  if (cosmos && cosmos.length > 0)
    cosmos.forEach((cosmoKey) => requestParams.append('cosmos', cosmoKey))

  requestParams.append('limit', limit.toString())
  requestParams.append('offset', offset.toString())
  requestParams.append('sortDir', 'desc')

  return apiPrivate
    .get<GetAudiencesResponse>(`${libraryUrl}/v1/audiences`, { params: requestParams })
    .then((resp) => resp.data.description)
}

interface CreateAudienceBody {
  name: string
  cosmos: string[]
}

function createAudience(body: CreateAudienceBody) {
  return apiPrivate.post(`${libraryUrl}/v1/audiences`, body)
}

interface GetAudienceContentsResponse {
  description: {
    contents: Content[]
    more: boolean
    count: number
  }
}

export interface Content {
  id: number
  title: string
  type: AUDIENCE_CONTENT_TYPE
  organizationName: string
}

export enum AUDIENCE_CONTENT_TYPE {
  ACTIVITY = 'activity',
  BOOK = 'book',
  SEQUENCE = 'sequence',
  COURSE = 'course',
}

export interface GetAudienceContentsParams {
  limit: number
  offset?: number
  search?: string
  contentType?: AUDIENCE_CONTENT_TYPE
}

function getAudienceContents(
  audienceId: number,
  params: GetAudienceContentsParams
): Promise<GetAudienceContentsResponse['description']> {
  const { limit, offset = 0, search, contentType } = params

  const validParams = {
    limit,
    ...(!isNaN(offset) && { offset }),
    ...(search?.trim() && { search }),
    ...(contentType && { contentType }),
  }

  return apiPrivate
    .get<GetAudienceContentsResponse>(`${libraryUrl}/v1/audiences/${audienceId}/contents`, {
      params: validParams,
    })
    .then((resp) => resp.data.description)
}

function deleteAudience(audienceId: number) {
  return apiPrivate.delete(`${libraryUrl}/v1/audiences/${audienceId}`)
}

function editAudience(body: CreateAudienceBody, audienceId: number) {
  return apiPrivate.put(`${libraryUrl}/v1/audiences/${audienceId}`, body)
}

interface GetBooksByAudienceParams {
  organizationId: number
  role: ROLE
  limit: number
  offset?: number
  title?: string
  tags?: number[]
  onlyWithLicenses?: boolean
}

export interface BookByAudience
  extends Omit<Book, 'coverURL' | 'organizationID' | 'userID' | 'fileID'> {
  tags: Tag[]
  visibilityTags: VisibilityTag[]
  amount: number
  organizationName: string
  organizationLogo: string
  audiences: { id: number; name: string }[]
  coverUrl: string
  availableInLibrary: boolean
}

interface GetBooksByAudienceResponse {
  description: {
    books: BookByAudience[]
    count: number
    total: number
  }
}

function getBooksByAudience({
  title,
  tags,
  ...rest
}: GetBooksByAudienceParams): Promise<GetBooksByAudienceResponse['description']> {
  const validParams = {
    ...(title?.trim() !== '' && { title }),
    ...(tags && tags.length > 0 && { tags }),
    ...rest,
  }

  return apiPrivate
    .get<GetBooksByAudienceResponse>(`${libraryUrl}/v2/books`, { params: validParams })
    .then((resp) => resp.data.description)
}

export interface GetDocumentListParams {
  role: ROLE.PRINCIPAL
  organizationId: number
  academicPeriodId: number
  limit: number
  offset: number
}

export interface Document {
  createdAt: string
  feedback: string
  id: number
  information: DocumentInformation
  status: DOCUMENT_STATUS
  student: {
    id: number
    name: string
    lastName: string
  }
  type: DOCUMENT_TYPE
  url: string
}

export interface DocumentInformation {
  receiver: string
}

export enum DOCUMENT_STATUS {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
}

export enum DOCUMENT_TYPE {
  CAR = 'CAR',
}

interface GetDocumentListResponse {
  description: {
    count: number
    documents: Document[]
    more: boolean
  }
}

function getDocumentList(
  params: GetDocumentListParams
): Promise<GetDocumentListResponse['description']> {
  return apiPrivate
    .get<GetDocumentListResponse>(url + '/v1/documents', { params })
    .then((resp) => resp.data.description)
}

interface ChangeDocumentStatusBaseParams {
  documentId: number
  status: DOCUMENT_STATUS
}

export interface ChangeDocumentStatusToApprovedParams extends ChangeDocumentStatusBaseParams {
  classroomId: number
  status: DOCUMENT_STATUS.APPROVED
}

export interface ChangeDocumentStatusToRejectedParams extends ChangeDocumentStatusBaseParams {
  feedback: string
  status: DOCUMENT_STATUS.REJECTED
}

type ChangeDocumentStatusParams =
  | ChangeDocumentStatusToApprovedParams
  | ChangeDocumentStatusToRejectedParams

function changeDocumentStatus(params: ChangeDocumentStatusParams) {
  const { documentId, ...payload } = params
  return apiPrivate.put(url + `/v1/documents/${documentId}/status`, payload)
}
