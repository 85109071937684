import type { PrincipalClassroom } from '@/legacy/redux/types/classrooms'
import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type { GenericAction, GenericPayload } from '@/legacy/redux/utils'
import type {
  GetAndValidateClassroomsSubjectsAction,
  SaveOrEditReportCardConfigFailure,
  SaveOrEditReportCardConfigRequest,
  SaveOrEditReportCardConfigSuccess,
} from './apiInteractions'

export enum ReportCardConfigStepperModalStep {
  PERIODS = 1,
  SCORES = 2,
  SUBJECTS = 3,
  VISUALS = 4,
  SUMMARY = 5,
}

export interface ReportCardConfigStepperModalState {
  currentStep: ReportCardConfigStepperModalStep
  open: boolean
  loading: boolean
  editLoading: boolean
  editMode: boolean
  error: string
  classroomsBeingConfigured: PrincipalClassroom[]
  confirmationChecked: boolean
  subjectsAreValid: boolean
}

export const SET_OPEN = 'REPORT_CARD/CONFIG/STEPPER_MODAL/SET_OPEN'

export interface SetOpenPayload extends GenericPayload {
  classroomsBeingConfigured: PrincipalClassroom[]
}

export interface SetOpen extends GenericAction {
  type: typeof SET_OPEN
  payload: SetOpenPayload
}

export const SET_EDIT_OPEN_START = 'REPORT_CARD/CONFIG/STEPPER_MODAL/SET_EDIT_OPEN_START'
export const SET_EDIT_OPEN_FINAL = 'REPORT_CARD/CONFIG/STEPPER_MODAL/SET_EDIT_OPEN_FINAL'

export interface SetEditOpenStart extends GenericAction {
  type: typeof SET_EDIT_OPEN_START
  payload: SetOpenPayload
}
export interface SetEditOpenFinal extends GenericAction {
  type: typeof SET_EDIT_OPEN_FINAL
}

export const SET_CLOSED = 'REPORT_CARD/CONFIG/STEPPER_MODAL/SET_CLOSED'

export interface SetClosed extends GenericAction {
  type: typeof SET_CLOSED
}

export type ReportCardConfigStepperModalAction =
  | SaveOrEditReportCardConfigRequest
  | SaveOrEditReportCardConfigSuccess
  | SaveOrEditReportCardConfigFailure
  | GetAndValidateClassroomsSubjectsAction
  | SetOpen
  | SetClosed
  | SetField<'currentStep' | 'confirmationChecked', ReportCardConfigStepperModalStep | boolean>
  | ClearState
  | SetEditOpenStart
  | SetEditOpenFinal
