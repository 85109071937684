import type { ReduxRequestOrigin } from '@/legacy/redux/constants'

export const SET_OPEN = 'NEW_CLASSROOM_MODAL/SET_OPEN'
export const SET_DIVISION = 'NEW_CLASSROOM_MODAL/SET_DIVISION'
export const SET_GRADE = 'NEW_CLASSROOM_MODAL/SET_GRADE'
export const SET_STAGE = 'NEW_CLASSROOM_MODAL/SET_STAGE'
export const SET_SHIFT = 'NEW_CLASSROOM_MODAL/SET_SHIFT'
export const SET_YEAR = 'NEW_CLASSROOM_MODAL/SET_YEAR'

export const CREATE_CLASSROOM_REQUEST = 'NEW_CLASSROOM_MODAL/CREATE_CLASSROOM_REQUEST'
export const CREATE_CLASSROOM_SUCCESS = 'NEW_CLASSROOM_MODAL/CREATE_CLASSROOM_SUCCESS'
export const CREATE_CLASSROOM_FAILURE = 'NEW_CLASSROOM_MODAL/CREATE_CLASSROOM_FAILURE'

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    requestOrigin: ReduxRequestOrigin
    open: boolean
  }
}

export interface SetDivision {
  type: typeof SET_DIVISION
  payload: {
    requestOrigin: ReduxRequestOrigin
    division: string
  }
}

export interface SetGrade {
  type: typeof SET_GRADE
  payload: {
    requestOrigin: ReduxRequestOrigin
    grade: string
  }
}

export interface SetStage {
  type: typeof SET_STAGE
  payload: {
    requestOrigin: ReduxRequestOrigin
    stage: string
  }
}

export interface SetShift {
  type: typeof SET_SHIFT
  payload: {
    requestOrigin: ReduxRequestOrigin
    shift: string
  }
}

export interface CreateClassroomRequest {
  type: typeof CREATE_CLASSROOM_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface CreateClassroomSuccess {
  type: typeof CREATE_CLASSROOM_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface CreateClassroomFailure {
  type: typeof CREATE_CLASSROOM_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface NewClassroomModalState {
  open: boolean
  stage: string
  shift: string
  year: number
  grade: string
  division: string
  loading: boolean
  error: string
}

export type NewClassroomModalActions =
  | SetShift
  | SetStage
  | SetDivision
  | SetGrade
  | SetOpen
  | CreateClassroomRequest
  | CreateClassroomSuccess
  | CreateClassroomFailure
