import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/student/summary/types/tabs/activities/count'

const initialState: Types.CountState = {
  status: REQUEST_STATUS.IDLE,
  data: {
    total: 0,
    completed: 0,
    average: 0,
    validAverage: false,
  },
}

const countReducer = (
  state: Types.CountState = initialState,
  action: Types.CountAction
): Types.CountState => {
  switch (action.type) {
    case Types.COUNT_TYPES.GET_COUNT_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.COUNT_TYPES.GET_COUNT_SUCCESS: {
      return {
        status: REQUEST_STATUS.SUCCESS,
        data: action.payload.data,
      }
    }
    case Types.COUNT_TYPES.GET_COUNT_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.COUNT_TYPES.CLEAN_COUNT: {
      return initialState
    }
    default:
      return state
  }
}

export default countReducer
