import type { AxiosResponse } from 'axios'
import type { Moment } from 'moment'
import moment from 'moment'
import { apiPrivate } from '..'
import { attendancesUrl } from '@aula/config'

export const AttendancesRecordsAPI = {
  update,
  updateMultiple,
  getClassroomAttendances,
}

export type StudentAttendance = {
  description: string
  abbreviation: string
  color: string
  value: number
  date: Moment
  studentId: number
  subjectId: number
}

export type ApiStudentAttendance = {
  description: string
  abbreviation: string
  color: string
  value: string
  date: Moment
  studentId: number
  subjectId: number
}

/**
 * Update attendance for a specific student at a specific date.
 * @param configurationId
 * @param date
 * @param studentId
 * @param statusId
 * @param subjectId
 * @returns null
 */

function update(
  configurationId: number,
  date: Moment,
  studentId: number,
  statusId: number,
  subjectId: number
): Promise<null> {
  const config = {
    configurationId,
    date: date.format('YYYY-MM-DD'),
    studentId,
    statusId,
    subjectId,
  }

  return apiPrivate.put(attendancesUrl + '/v3/attendances', config)
}

export type StudentAttendancesArray = {
  date: string
  statusId: number
}

/**
 * Update attendances for a specific student.
 * @param configurationId
 * @param studentId
 * @returns null
 */

function updateMultiple(
  configurationId: number,
  studentId: number,
  attendances: StudentAttendancesArray[],
  subjectId: number
): Promise<null> {
  const config = {
    configurationId,
    studentId,
    attendances,
    subjectId,
  }

  return apiPrivate.put(attendancesUrl + '/v3/attendancesMultiple', config)
}

type GetClassroomAttendancesResponse = {
  startDate: Moment
  endDate: Moment
  classroomId: number
  attendances: StudentAttendance[]
}

/**
 * Get attendances for a given classroom between given dates.
 * @param classroomId
 * @param startDate
 * @param endDate
 */

function getClassroomAttendances(
  classroomId: number,
  startDate: Moment,
  endDate: Moment,
  subjectId: number
): Promise<GetClassroomAttendancesResponse> {
  const params = {
    classroomId,
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
    limit: 1000,
    subjectId,
  }

  return apiPrivate
    .get(attendancesUrl + `/v2/attendances`, { params })
    .then((response: AxiosResponse<GetClassroomAttendancesResponse>) => {
      return {
        ...response.data,
        attendances: response.data.attendances.map((attendance) => ({
          ...attendance,
          value: parseFloat(attendance.value), //?
          date: moment(attendance.date),
        })),
      }
    })
    .catch((err) => {
      throw err
    })
}
