export default {
  SET_MODAL_OPEN: 'TEACHER_ACTIVITY/SET_MODAL_OPEN',
  SET_EDIT_MODAL_OPEN: 'TEACHER_ACTIVITY/SET_EDIT_MODAL_OPEN',
  SET_CLONE_MODAL_OPEN: 'TEACHER_ACTIVITY/SET_CLONE_MODAL_OPEN',
  SET_STEP: 'TEACHER_ACTIVITY/SET_STEP',
  GET_ACTIVITY_REQUEST: 'TEACHER_ACTIVITY/GET_ACTIVITY_REQUEST',
  GET_ACTIVITY_SUCCESS: 'TEACHER_ACTIVITY/GET_ACTIVITY_SUCCESS',
  GET_ACTIVITY_FAILURE: 'TEACHER_ACTIVITY/GET_ACTIVITY_FAILURE',
  GET_CLASSROOMS_REQUEST: 'TEACHER_ACTIVITY/GET_CLASSROOMS_REQUEST',
  GET_CLASSROOMS_SUCCESS: 'TEACHER_ACTIVITY/GET_CLASSROOMS_SUCCESS',
  GET_CLASSROOMS_FAILURE: 'TEACHER_ACTIVITY/GET_CLASSROOMS_FAILURE',
  GET_SUBJECTS_REQUEST: 'TEACHER_ACTIVITY/GET_SUBJECTS_REQUEST',
  GET_SUBJECTS_SUCCESS: 'TEACHER_ACTIVITY/GET_SUBJECTS_SUCCESS',
  GET_SUBJECTS_FAILURE: 'TEACHER_ACTIVITY/GET_SUBJECTS_FAILURE',
  GET_CLASSROOM_SUBGROUPS_REQUEST: 'TEACHER_ACTIVITY/GET_CLASSROOM_SUBGROUPS_REQUEST',
  GET_CLASSROOM_SUBGROUPS_SUCCESS: 'TEACHER_ACTIVITY/GET_CLASSROOM_SUBGROUPS_SUCCESS',
  GET_CLASSROOM_SUBGROUPS_FAILURE: 'TEACHER_ACTIVITY/GET_CLASSROOM_SUBGROUPS_FAILURE',
  SELECT_CLASSROOM: 'TEACHER_ACTIVITY/SELECT_CLASSROOM',
  SELECT_SUBJECT: 'TEACHER_ACTIVITY/SELECT_SUBJECT',
  SET_ACADEMIC_PERIODS: 'TEACHER_ACTIVITY/SET_ACADEMIC_PERIODS',
  SET_SELECTED_PERIOD: 'TEACHER_ACTIVITY/SET_SELECTED_PERIOD',
  SELECT_SUBGROUPS: 'TEACHER_ACTIVITY/SELECT_SUBGROUPS',
  SELECT_TYPE: 'TEACHER_ACTIVITY/SELECT_TYPE',
  SET_DATE: 'TEACHER_ACTIVITY/SET_DATE',
  SET_TIME: 'TEACHER_ACTIVITY/SET_TIME',
  SET_DEADLINE: 'TEACHER_ACTIVITY/SET_DEADLINE',
  SET_TITLE: 'TEACHER_ACTIVITY/SET_TITLE',
  SET_LEVEL: 'TEACHER_ACTIVITY/SET_LEVEL',
  SET_IS_REMOTE_SESSION: 'TEACHER_ACTIVITY/SET_IS_REMOTE_SESSION',
  SET_REMOTE_SESSION_DATE: 'TEACHER_ACTIVITY/SET_REMOTE_SESSION_DATE',
  SET_REMOTE_SESSION_TIME: 'TEACHER_ACTIVITY/SET_REMOTE_SESSION_TIME',
  SET_IS_SIMULATOR_ENABLED: 'TEACHER_ACTIVITY/SET_IS_SIMULATOR_ENABLED',
  SET_SHOW_EVALUATION: 'TEACHER_ACTIVITY/SET_SHOW_EVALUATION',
  GET_BOARDS: 'TEACHER_ACTIVITY/GET_BOARDS',
  SET_BOARD: 'TEACHER_ACTIVITY/SET_BOARD',
  SET_SIMPLE_DESCRIPTION: 'TEACHER_ACTIVITY/SET_SIMPLE_DESCRIPTION',
  SET_DESCRIPTION: 'TEACHER_ACTIVITY/SET_DESCRIPTION',
  SET_IS_ZOOM: 'TEACHER_ACTIVITY/SET_IS_ZOOM',
  SET_ZOOM_URL: 'TEACHER_ACTIVITY/SET_ZOOM_URL',
  SET_ENABLE_CHAT: 'TEACHER_ACTIVITY/SET_ENABLE_CHAT',
  SET_PUBLICATION_DATE: 'TEACHER_ACTIVITY/SET_PUBLICATION_DATE',
  SET_HAS_DELIVERABLE: 'TEACHER_ACTIVITY/SET_HAS_DELIVERABLE',
  SET_DISTRIBUTE_SCHEDULE: 'TEACHER_ACTIVITY/SET_DISTRIBUTE_SCHEDULE',
  SET_SUBMISSIONS_AFTER_DEADLINE: 'TEACHER_ACTIVITY/SET_SUBMISSIONS_AFTER_DEADLINE',
  SET_EXAM_DURATION: 'TEACHER_ACTIVITY/SET_EXAM_DURATION',
  SET_AVAILABILITY: 'TEACHER_ACTIVITY/SET_AVAILABILITY',
  SET_AVAILABLE_IN_LIBRARY: 'TEACHER_ACTIVITY/SET_AVAILABLE_IN_LIBRARY',
  SET_EDUCATIONAL_INFO_ON: 'TEACHER_ACTIVITY/SET_EDUCATIONAL_INFO_ON',
  SET_RUBRIC_ON: 'TEACHER_ACTIVITY/SET_RUBRIC_ON',
  CREATE_AVV_REQUEST: 'TEACHER_ACTIVITY/CREATE_AVV_REQUEST',
  CREATE_AVV_SUCCESS: 'TEACHER_ACTIVITY/CREATE_AVV_SUCCESS',
  CREATE_AVV_FAILURE: 'TEACHER_ACTIVITY/CREATE_AVV_FAILURE',
  EDIT_AVV_REQUEST: 'TEACHER_ACTIVITY/EDIT_AVV_REQUEST',
  EDIT_AVV_SUCCESS: 'TEACHER_ACTIVITY/EDIT_AVV_SUCCESS',
  EDIT_AVV_FAILURE: 'TEACHER_ACTIVITY/EDIT_AVV_FAILURE',
  CREATE_OTHER_REQUEST: 'TEACHER_ACTIVITY/CREATE_OTHER_REQUEST',
  CREATE_OTHER_SUCCESS: 'TEACHER_ACTIVITY/CREATE_OTHER_SUCCESS',
  CREATE_OTHER_FAILURE: 'TEACHER_ACTIVITY/CREATE_OTHER_FAILURE',
  EDIT_OTHER_REQUEST: 'TEACHER_ACTIVITY/EDIT_OTHER_REQUEST',
  EDIT_OTHER_SUCCESS: 'TEACHER_ACTIVITY/EDIT_OTHER_SUCCESS',
  EDIT_OTHER_FAILURE: 'TEACHER_ACTIVITY/EDIT_OTHER_FAILURE',
  CREATE_ROBOTS_REMOTE_REQUEST: 'TEACHER_ACTIVITY/CREATE_ROBOTS_REMOTE_REQUEST',
  CREATE_ROBOTS_REMOTE_SUCCESS: 'TEACHER_ACTIVITY/CREATE_ROBOTS_REMOTE_SUCCESS',
  CREATE_ROBOTS_REMOTE_FAILURE: 'TEACHER_ACTIVITY/CREATE_ROBOTS_REMOTE_FAILURE',
  EDIT_ROBOTS_REMOTE_REQUEST: 'TEACHER_ACTIVITY/EDIT_ROBOTS_REMOTE_REQUEST',
  EDIT_ROBOTS_REMOTE_SUCCESS: 'TEACHER_ACTIVITY/EDIT_ROBOTS_REMOTE_SUCCESS',
  EDIT_ROBOTS_REMOTE_FAILURE: 'TEACHER_ACTIVITY/EDIT_ROBOTS_REMOTE_FAILURE',
  SET_CLASSROOM_SEARCH: 'TEACHER_ACTIVITY/SET_CLASSROOM_SEARCH',
  SET_RECORDING_DURATION: 'TEACHER_ACTIVITY/SET_RECORDING_DURATION',
  SET_RECORDING_ENABLED: 'TEACHER_ACTIVITY/SET_RECORDING_ENABLED',
  // CONTENT CREATOR ACTIVITIES
  SET_CREATE_PREMIUM_MODAL_OPEN: 'CONTENT_CREATOR_ACTIVITY/SET_CREATE_PREMIUM_MODAL_OPEN',
  SET_EDIT_PREMIUM_MODAL_OPEN: 'CONTENT_CREATOR_ACTIVITY/SET_EDIT_PREMIUM_MODAL_OPEN',
  SET_SELECTED_BOOK: 'CONTENT_CREATOR_ACTIVITY/SET_SELECTED_BOOK',
  SET_BOOK_SEARCH: 'CONTENT_CREATOR_ACTIVITY/SET_BOOK_SEARCH',
  SET_ORGANIZATION_ID: 'CONTENT_CREATOR_ACTIVITY/SET_ORGANIZATION_ID',
  SET_TAGS: 'CONTENT_CREATOR_ACTIVITY/SET_TAGS',
  SET_RECURRENT_TOGGLE: 'TEACHER_ACTIVITY/SET_RECURRENT_TOGGLE',
  SET_RECURRENCE: 'TEACHER_ACTIVITY/SET_RECURRENCE',
  SET_RECURRENCE_END: 'TEACHER_ACTIVITY/SET_RECURRENT_END',
}
