export default {
  CLOSE_MODAL: 'DASHBOARD_STUDENT/ACTIVITY_CLOSE_MODAL',
  ACTIVITY_REQUEST: 'DASHBOARD_STUDENT/ACTIVITY_REQUEST',
  ACTIVITY_SUCCESS: 'DASHBOARD_STUDENT/ACTIVITY_SUCCESS',
  ACTIVITY_FAILURE: 'DASHBOARD_STUDENT/ACTIVITY_FAILURE',
  MARK_AS_COMPLETED_REQUEST: 'DASHBOARD_STUDENT/MARK_AS_COMPLETED_STUDENT_REQUEST',
  MARK_AS_COMPLETED_SUCCESS: 'DASHBOARD_STUDENT/MARK_AS_COMPLETED_STUDENT_SUCCESS',
  MARK_AS_COMPLETED_FAILURE: 'DASHBOARD_STUDENT/MARK_AS_COMPLETED_STUDENT_FAILURE',
  DOWNLOAD_FILE_REQUEST: 'DASHBOARD_STUDENT/DOWNLOAD_FILE_REQUEST',
  DOWNLOAD_FILE_SUCCESS: 'DASHBOARD_STUDENT/DOWNLOAD_FILE_SUCCESS',
  DOWNLOAD_FILE_FAILURE: 'DASHBOARD_STUDENT/DOWNLOAD_FILE_FAILURE',
  SET_SEND_ANSWERS_MODAL_OPEN: 'DASHBOARD_STUDENT/SET_SEND_ANSWERS_MODAL_OPEN',
  SET_SEND_ANSWERS_STEPPER_OPEN: 'DASHBOARD_STUDENT/SET_SEND_ANSWERS_STEPPER_OPEN',
  SET_SEND_ANSWERS_STEPPER_CLOSED: 'DASHBOARD_STUDENT/SET_SEND_ANSWERS_STEPPER_CLOSED',
  SET_ANSWERS_STEPPER_STEP: 'DASHBOARD_STUDENT/SET_ANSWERS_STEPPER_STEP',
  GET_COURSE_CONTENT_REQUEST: 'DASHBOARD_STUDENT/GET_COURSE_CONTENT_REQUEST',
  GET_COURSE_CONTENT_SUCCESS: 'DASHBOARD_STUDENT/GET_COURSE_CONTENT_SUCCESS',
  GET_COURSE_CONTENT_FAILURE: 'DASHBOARD_STUDENT/GET_COURSE_CONTENT_FAILURE',
  SET_OPEN_UNIT: 'DASHBOARD_STUDENT/SET_OPEN_UNIT',
  SET_SECTION_MODAL: 'DASHBOARD_STUDENT/SET_SECTION_MODAL',
  SET_CURRENT_SECTION: 'DASHBOARD_STUDENT/SET_CURRENT_SECTION',
  SET_COMPLETED_SECTIONS: 'DASHBOARD_STUDENT/SET_COMPLETED_SECTIONS',
  SET_SELECTED_SECTION: 'DASHBOARD_STUDENT/SET_SELECTED_SECTION',
}
