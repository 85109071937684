import type { RobotsActions, RobotsState } from './types'
import {
  ROBOTS_TOKEN_REQUEST,
  ROBOTS_TOKEN_SUCCESS,
  ROBOTS_TOKEN_FAILURE,
  ADD_ROBOTS_EVENT_REQUEST,
  ADD_ROBOTS_EVENT_SUCCESS,
  ADD_ROBOTS_EVENT_FAILURE,
} from './types'

const initialState: RobotsState = {
  robotsLogin: false,
  loadingToken: false,
  error: '',
}

function root(state = initialState, action: RobotsActions) {
  switch (action.type) {
    case ROBOTS_TOKEN_REQUEST:
      return { ...state, token: '', loadingToken: true, error: '' }
    case ROBOTS_TOKEN_SUCCESS:
      return { ...state, loadingToken: false, error: '' }
    case ROBOTS_TOKEN_FAILURE:
      return { ...state, loadingToken: false, error: action.payload.error }
    case ADD_ROBOTS_EVENT_REQUEST:
    case ADD_ROBOTS_EVENT_SUCCESS:
    case ADD_ROBOTS_EVENT_FAILURE:
      return state
    default:
      return state
  }
}

export default root
