import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type {
  EducationalTagsModalAction,
  EducationalTagsModalState,
} from '@/sections/teacher/activities/types/createExtraInfo/educationalInfo/tagsModal'
import {
  GET_EDUCATIONAL_TAGS_FAILURE,
  GET_EDUCATIONAL_TAGS_REQUEST,
  GET_EDUCATIONAL_TAGS_SUCCESS,
  SET_OPEN,
  TOGGLE_TAG,
} from '@/sections/teacher/activities/types/createExtraInfo/educationalInfo/tagsModal'
import * as EducationalInfoStepTypes from '@/sections/teacher/activities/types/createExtraInfo/educationalInfo/educationalInfoStep'

const initialState: EducationalTagsModalState = {
  open: false,
  allTags: [],
  selectedTags: [],
  pagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0,
  },
  search: '',
  category: '',
  loading: false,
  error: '',
}

const requestDestination = reduxRequestOriginMap.EDUCATIONAL_TAGS_MODAL

const EducationalTagsModalReducer: Reducer<
  EducationalTagsModalState,
  EducationalTagsModalAction
> = (state = initialState, action): EducationalTagsModalState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD: {
      return setGenericField(state, action.payload)
    }
    case SET_OPEN: {
      const { open, category, currentTags } = action.payload
      if (!open) return initialState
      return {
        ...state,
        open,
        category: category || state.category,
        selectedTags: currentTags || state.selectedTags,
      }
    }
    case GET_EDUCATIONAL_TAGS_REQUEST: {
      const { page, rowsPerPage } = action.payload

      return {
        ...state,
        loading: true,
        pagination: {
          ...state.pagination,
          page,
          rowsPerPage,
        },
      }
    }
    case GET_EDUCATIONAL_TAGS_SUCCESS: {
      const { tags, count } = action.payload

      return {
        ...state,
        allTags: tags,
        loading: false,
        pagination: {
          ...state.pagination,
          count,
        },
      }
    }
    case GET_EDUCATIONAL_TAGS_FAILURE: {
      const { error } = action.payload

      return {
        ...state,
        error,
        loading: false,
      }
    }
    case TOGGLE_TAG: {
      const { tag } = action.payload

      // If clicked tag is inside of selectedTags it gets filtered, if not it gets added
      const filtered = state.selectedTags.filter((t) => t.id !== tag.id)
      if (filtered.length === state.selectedTags.length) filtered.push(tag)

      return {
        ...state,
        selectedTags: filtered,
      }
    }
    case EducationalInfoStepTypes.SET_TAGS:
      return initialState
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, EducationalTagsModalReducer, requestDestination)
