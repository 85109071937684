import tw from '@/locales/translations/tw/tw.json'
import common from '@/locales/translations/tw/common.json'
import glossary from '@/locales/translations/tw/glossary.json'
import validation from '@/locales/translations/tw/validation.json'

export default {
  translation: tw,
  common: common,
  glossary: glossary,
  validation: validation,
}
