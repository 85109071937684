export const iconAnimations = {
  '@keyframes questionIcon': {
    '0%': {
      transform: 'translate(0)',
    },
    '5%': {
      transform: 'rotate(5deg)',
    },
    '10%': {
      transform: 'rotate(-5deg)',
    },
    '15%': {
      transform: 'rotate(5deg)',
    },
    '20%': {
      transform: ' rotate(-5deg)',
    },
    '25%': {
      transform: 'rotate(4deg)',
    },
    '30%': {
      transform: 'rotate(-4deg)',
    },
    '35%': {
      transform: 'rotate(4deg)',
    },
    '40%': {
      transform: 'rotate(-4deg)',
    },
    '45%': {
      transform: 'rotate(4deg)',
    },
    '50%': {
      transform: ' rotate(-4deg)',
    },
    '55%': {
      transform: 'rotate(4deg)',
    },
    '60%': {
      transform: 'rotate(-3deg)',
    },
    '65%': {
      transform: 'rotate(3deg)',
    },
    '70%': {
      transform: 'rotate(-2deg)',
    },
    '75%': {
      transform: 'rotate(2deg)',
    },
    '80%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
  '@keyframes avvIcon': {
    '0%': {
      transform: 'translate(0)',
    },
    '20%': {
      transform: 'translateX(100%) rotate(90deg)',
    },
    '21%': {
      transform: 'translateX(100%) translateY(100%) rotate(0)',
    },
    '22%': {
      transform: 'translateX(-100%) translateY(100%) rotate(0)',
    },
    '23%': {
      transform: 'translateX(-100%) translateY(0%) rotate(-90deg)',
    },
    '43%': {
      transform: 'translate(0) rotate(0)',
    },
  },
  '@keyframes readingIconSecondPage': {
    '0%': {
      transform: 'perspective(200px) rotateY(0deg)',
    },
    '100%': {
      transform: 'perspective(200px) rotateY(180deg)',
    },
  },
  '@keyframes readingIconThirdPage': {
    '0%': {
      transform: 'perspective(200px) rotateY(0deg)',
      fillOpacity: 1,
    },
    '50%': {
      fillOpacity: 1,
    },
    '51%': {
      fillOpacity: 0,
    },
    '100%': {
      transform: 'perspective(200px) rotateY(180deg)',
      fillOpacity: 0,
    },
  },
  '@keyframes robotsRemote': {
    '0%': {
      transform: 'translateY(0.5px)',
    },
    '5%': {
      transform: 'translateY(-0.5px)',
    },
    '10%': {
      transform: 'translateY(0.5px)',
    },
    '15%': {
      transform: 'translateY(-0.5px)',
    },
    '20%': {
      transform: 'translateY(0.5px)',
    },
    '25%': {
      transform: 'translateY(-0.5px)',
    },
    '30%': {
      transform: 'translateY(0.5px)',
    },
    '35%': {
      transform: 'translateY(-0.5px)',
    },
    '40%': {
      transform: 'translateY(0.5px)',
    },
    '45%': {
      transform: 'translateY(0)',
    },
    '100%': {
      transform: 'translateY(0)',
    },
  },
  '@keyframes writtenExamMain': {
    '0%': {
      transform: 'rotate(0) translate(0)',
    },
    '12%': {
      transform: 'rotate(-5deg) translateX(4px)',
      transformOrigin: 'top left',
    },
    '17%': {
      transform: 'translateX(100%)',
      animationTimingFunction: 'ease-in-out',
    },
    '19%': {
      transform: 'translateX(100%) translateY(100%)',
    },
    '20%': {
      transform: 'translateX(-100%) translateY(100%)',
    },
    '21%': {
      transform: 'rotate(-4deg) translateY(0) translateX(-100%)',
    },
    '30%': {
      transform: 'rotate(-8deg) translateX(-5px)',
      animationTimingFunction: 'ease-in-out',
    },
    '32%': {
      transform: 'rotate(0) translateX(0)',
    },
  },
  '@keyframes writtenExamA': {
    '0%': {
      opacity: 1,
    },
    '18%': {
      opacity: 1,
    },
    '19%': {
      opacity: 0,
      transform: 'rotate(0)',
    },
    '34%': {
      opacity: 0,
      transform: 'rotate(-40deg) scale(5)',
    },
    '38%': {
      opacity: 1,
      transform: 'rotate(0deg) scale(1)',
      animationTimingFunction: 'ease-in',
    },
  },
  '@keyframes writtenExamPlus': {
    '0%': {
      opacity: 1,
    },
    '18%': {
      opacity: 1,
    },
    '19%': {
      opacity: 0,
      transform: 'rotate(0)',
    },
    '42%': {
      opacity: 0,
      transform: 'rotate(-40deg) scale(5)',
    },
    '45%': {
      opacity: 1,
      transform: 'rotate(0deg) scale(1)',
      animationTimingFunction: 'ease-in',
    },
  },
  '@keyframes oralExamBubble': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '25%': {
      transform: 'rotate(2deg)',
    },
    '75%': {
      transform: 'rotate(-2deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
  '@keyframes oralExamLine': {
    '0%': {
      transform: 'scaleX(1)',
    },
    '35%': {
      transform: 'scaleX(1.08)',
    },
    '70%': {
      transform: 'scaleX(0.9)',
    },
    '100%': {
      transform: 'scaleX(1)',
    },
  },
}

export const iconCss = {
  '& svg.questions-svg path': {
    animation: '$questionIcon 2800ms ease-in-out infinite',
    transformOrigin: '0 100%',
  },
  '& svg.avv-svg path': {
    animation: '$avvIcon 2800ms ease-in-out infinite',
    transformOrigin: '50% 50%',
  },
  '& svg.reading-svg': {
    '& .secondpage': {
      animation: '$readingIconSecondPage 1000ms ease-in-out infinite',
      transformOrigin: '50% 50%',
    },
    '& .thirdpage': {
      animation: '$readingIconThirdPage 1000ms ease-in-out infinite',
      transformOrigin: '50% 50%',
    },
  },
  '& svg.robots-remote-svg': {
    '& .rr-person1': {
      animation: '$robotsRemote 2000ms ease-in-out infinite',
      transformOrigin: '50% 50%',
    },
    '& .rr-person2': {
      animation: '$robotsRemote 2000ms ease-in-out infinite 1000ms',
      transformOrigin: '50% 50%',
    },
  },
  '& svg.robots-activity-svg': {
    '& .rr-person1': {
      animation: '$robotsRemote 2000ms ease-in-out infinite',
      transformOrigin: '50% 50%',
    },
    '& .rr-person2': {
      animation: '$robotsRemote 2000ms ease-in-out infinite 1000ms',
      transformOrigin: '50% 50%',
    },
  },
  '& svg.animations-svg': {
    '& .rr-person1': {
      animation: '$robotsRemote 2000ms ease-in-out infinite',
      transformOrigin: '50% 50%',
    },
    '& .rr-person2': {
      animation: '$robotsRemote 2000ms ease-in-out infinite 1000ms',
      transformOrigin: '50% 50%',
    },
  },
  '& svg.written-exam-svg .page': {
    animation: '$writtenExamMain 5000ms ease-in-out infinite',
    transformOrigin: '50% 50%',
    '& .letter-a': {
      animation: '$writtenExamA 5000ms ease-in-out infinite',
      transformOrigin: '50% 50%',
    },
    '& .plus-sign': {
      animation: '$writtenExamPlus 5000ms ease-in-out infinite',
      transformOrigin: '50% 50%',
    },
  },
  '& svg.oral-exam-svg': {
    '& .content': {
      animation: '$oralExamBubble 320ms ease-in-out infinite',
      transformOrigin: '0 100%',
    },
    '& .line': {
      animation: '$oralExamLine 800ms ease-in-out infinite',
      transformOrigin: '50% 50%',
      '&:nth-child(1)': {
        animationDelay: '0ms',
      },
      '&:nth-child(2)': {
        animationDelay: '100ms',
      },
      '&:nth-child(3)': {
        animationDelay: '200ms',
      },
    },
  },
}

export const stateAnimations = {
  '@keyframes swell': {
    '0%': {
      maxWidth: 12,
      color: 'transparent',
      opacity: 0,
    },
    '20%': {
      maxWidth: 12,
      color: 'transparent',
      opacity: 0,
    },
    '100%': {
      maxWidth: '100%',
      opacity: 1,
    },
  },
}

export const stateCss = {
  animation: '$swell 1800ms ease-in-out',
}
