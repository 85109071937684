import type { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { scrollBarStyles } from '@/sections/teacher/activities/styles'
import { Anibot } from '@/legacy/components/v2/anibot'
import EmptyState from '@/legacy/components/v2/emptyState'
import LoadingContainer from './loadingContainer'
import Checkbox from '@mui/material/Checkbox'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'

type TableStylesProps = {
  gridTemplateColumns?: number | string
  overflow?: string
  maxHeight?: string
  loading?: boolean
  columns?: DynamicTableColumn[]
  isEmptyStateDisabled?: boolean
}

export const useTableStyles = makeStyles<Theme, TableStylesProps>((theme) => ({
  container: ({ isEmptyStateDisabled }) => ({
    position: 'relative',
    minHeight: 200,
    minWidth: 'min-content',
    borderRadius: 8,
    overflow: 'hidden',
    borderLeft: '1px solid #DFDFDA',
    borderRight: '1px solid #DFDFDA',
    borderBottom: isEmptyStateDisabled ? 'none' : '1px solid #DFDFDA',
    borderTop: isEmptyStateDisabled ? 'none' : '1px solid #DFDFDA',
  }),

  table: ({ gridTemplateColumns, overflow, maxHeight, loading, isEmptyStateDisabled }) => ({
    borderTop: isEmptyStateDisabled ? '1px solid #DFDFDA' : 'none',
    display: 'grid',
    opacity: loading ? 0 : 1,
    borderCollapse: 'collapse',
    minWidth: '100%',
    gridTemplateColumns,
    position: 'relative',
    overflow: overflow,
    maxHeight: maxHeight,
    '& thead, tbody, tr': {
      display: 'contents',
    },
    '& th, td': {
      padding: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      paddingRight: 10,
      paddingLeft: 10,
      '&:first-child': {
        paddingLeft: 20,
      },
      '&:last-child': {
        paddingRight: 20,
      },
    },
    '& td': {
      transition: '.1s all ease-in-out',
      backgroundColor: '#FFF',
      height: 50,
      borderBottom: '1px solid #DFDFDA',
      display: 'flex',
      alignItems: 'center',
      '&:first-child': {
        borderLeft: '1px solid #DFDFDA',
      },
      '&:last-child': {
        borderRight: '1px solid #DFDFDA',
      },
    },
    ...scrollBarStyles,
  }),
  colHeader: {
    background: theme.palette.background.default,
    display: 'flex',
    height: 40,
    alignItems: 'center',
    transition: '.1s all ease-in-out',
    color: theme.palette.secondary.main,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: 16,
    padding: 12,
    borderBottom: '1px solid #DFDFDA',
  },
  regularText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 400,
  },
  boldText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  ellipsis: {
    display: 'block',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSPace: 'nowrap',
  },
}))

export type DynamicTableColumn = {
  id: string | number
  label: string
  minWidth?: string
  maxWidth?: string
  className?: string
  customHeader?: React.ReactNode
  hidable?: boolean
  noOrdering?: boolean
  centerContent?: boolean
  onSelectAll?: (id: string | number, checked: boolean) => void
  selectAllChecked?: boolean
}

interface DynamicTableProps {
  columns: DynamicTableColumn[]
  children?: React.ReactNode
  onColClick?: (id: string | number) => void
  orderBy?: string
  orderDirection?: string
  loading?: boolean
  hidableCondition?: boolean
  hideArrows?: boolean
  overflow?: string
  maxHeight?: string
  containerClass?: string
  tableClass?: string
  emptyStateTitle?: string
  emptyStateSubtitle?: string
  isEmptyStateDisabled?: boolean
}

const DynamicTable = (props: DynamicTableProps) => {
  const { columns, children, onColClick = () => {}, orderBy = 0 } = props
  const { orderDirection = 'desc', loading, hidableCondition, hideArrows = true } = props
  const { overflow = 'hidden', maxHeight = 'initial', containerClass = '', tableClass = '' } = props
  const {
    emptyStateTitle = 'components.dashboard.emptyStateTitle',
    emptyStateSubtitle = 'components.dashboard.emptyStateSubtitle',
    isEmptyStateDisabled = false,
  } = props

  const gridTemplateColumns = columns
    .map((col, idx) =>
      col.hidable && hidableCondition
        ? idx === 0
          ? '20px'
          : '0px'
        : `minmax(${col.minWidth || 'min-content'}, ${col.maxWidth || '1fr'})`
    )
    .join(' ')

  const classes = useTableStyles({
    gridTemplateColumns,
    columns,
    overflow,
    maxHeight,
    loading,
    isEmptyStateDisabled,
  })
  const { t } = useTranslation()
  const handleColClick = (id: string | number) => {
    onColClick(id)
  }

  return (
    <div className={clsx(classes.container, containerClass)}>
      {children ? (
        <table className={clsx(classes.table, tableClass)}>
          <thead>
            <tr>
              {columns.map((col) => {
                const order = orderBy === col.id && orderDirection === 'desc'
                return (
                  col.customHeader || (
                    <th
                      key={col.id}
                      className={clsx(
                        classes.colHeader,
                        col.id === orderBy ? classes.selectedColHeader : '',
                        col.className
                      )}
                      onClick={() => (!col.noOrdering ? handleColClick(col.id) : {})}
                      style={{
                        cursor: !col.noOrdering ? 'pointer' : 'default',
                        opacity: col.hidable && hidableCondition ? 0 : 1,
                        justifyContent: col.centerContent ? 'center' : 'flex-start',
                      }}
                    >
                      {col.label && col.label}
                      {col.onSelectAll && (
                        <Checkbox
                          value={col.id}
                          checked={col.selectAllChecked || false}
                          onChange={(_, checked) =>
                            col.onSelectAll ? col.onSelectAll(col.id, checked) : () => {}
                          }
                          name={'classroomName'}
                        />
                      )}
                      {!col.noOrdering && !hideArrows && (
                        <span
                          style={{
                            transform: order ? 'rotate("180deg")' : 'rotate(0)',
                          }}
                        >
                          {order ? <ArrowDropUp /> : <ArrowDropDown />}
                        </span>
                      )}
                    </th>
                  )
                )
              })}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      ) : (
        !loading &&
        !isEmptyStateDisabled && (
          <EmptyState variant={14} title={t(emptyStateTitle)} subtitle={t(emptyStateSubtitle)} />
        )
      )}
      {loading && (
        <LoadingContainer positionAbsolute backgroundColor="transparent">
          <Anibot />
        </LoadingContainer>
      )}
    </div>
  )
}

export default DynamicTable
