import type { ROLE } from '@aula/config'
import { ManagementRole } from '@/legacy/roles'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'

export interface AuthState {
  open: boolean
  loading: boolean
  password: string
  repeatPassword: string
  defaultPasswordType: PasswordType
  passwordTypes: PasswordType[]
  error: string
  userPasswordChangeDetails: UserPasswordChangeDetails | null
}

export interface PasswordType {
  id: number
  inputType: string
  max: number
  min: number
  name: string
  default: boolean
}

export type UserPasswordChangeDetails = {
  userId: number
  organizationId: number
  role: ROLE
  classroomId?: number
}

// Toggle Modal Open
export const TOGGLE_MODAL_OPEN = 'AUTH/TOGGLE_MODAL_OPEN'

export type ToggleModalOpen = GenericActionWithType<
  typeof TOGGLE_MODAL_OPEN,
  {
    userPasswordChangeDetails?: UserPasswordChangeDetails
  }
>

// Get password types
export const GET_PASSWORD_TYPES_REQUEST = 'AUTH/GET_PASSWORD_TYPES_REQUEST'
export const GET_PASSWORD_TYPES_SUCCESS = 'AUTH/GET_PASSWORD_TYPES_SUCCESS'
export const GET_PASSWORD_TYPES_FAILURE = 'AUTH/GET_PASSWORD_TYPES_FAILURE'

export type GetPasswordTypesRequest = GenericActionWithType<typeof GET_PASSWORD_TYPES_REQUEST>
export type GetPasswordTypesSuccess = GenericActionWithType<
  typeof GET_PASSWORD_TYPES_SUCCESS,
  { passwordTypes: PasswordType[] }
>
export type GetPasswordTypesFailure = GenericActionWithType<
  typeof GET_PASSWORD_TYPES_FAILURE,
  ErrorPayload
>

export type GetPasswordTypesAction =
  | GetPasswordTypesRequest
  | GetPasswordTypesSuccess
  | GetPasswordTypesFailure

// Assign own password
export const ASSIGN_OWN_PASSWORD_REQUEST = 'AUTH/ASSIGN_OWN_PASSWORD_REQUEST'
export const ASSIGN_OWN_PASSWORD_SUCCESS = 'AUTH/ASSIGN_OWN_PASSWORD_SUCCESS'
export const ASSIGN_OWN_PASSWORD_FAILURE = 'AUTH/ASSIGN_OWN_PASSWORD_FAILURE'

export type AssignOwnPasswordRequest = GenericActionWithType<typeof ASSIGN_OWN_PASSWORD_REQUEST>
export type AssignOwnPasswordSuccess = GenericActionWithType<typeof ASSIGN_OWN_PASSWORD_SUCCESS>
export type AssignOwnPasswordFailure = GenericActionWithType<
  typeof ASSIGN_OWN_PASSWORD_FAILURE,
  ErrorPayload
>

export type AssignOwnPasswordAction =
  | AssignOwnPasswordRequest
  | AssignOwnPasswordSuccess
  | AssignOwnPasswordFailure

// Assign password
export const ASSIGN_USER_PASSWORD_REQUEST = 'AUTH/ASSIGN_USER_PASSWORD_REQUEST'
export const ASSIGN_USER_PASSWORD_SUCCESS = 'AUTH/ASSIGN_USER_PASSWORD_SUCCESS'
export const ASSIGN_USER_PASSWORD_FAILURE = 'AUTH/ASSIGN_USER_PASSWORD_FAILURE'

export type AssignUserPasswordRequest = GenericActionWithType<typeof ASSIGN_USER_PASSWORD_REQUEST>
export type AssignUserPasswordSuccess = GenericActionWithType<typeof ASSIGN_USER_PASSWORD_SUCCESS>
export type AssignUserPasswordFailure = GenericActionWithType<
  typeof ASSIGN_USER_PASSWORD_FAILURE,
  ErrorPayload
>

export type AssignUserPasswordAction =
  | AssignUserPasswordRequest
  | AssignUserPasswordSuccess
  | AssignUserPasswordFailure

// Set password
export const SET_PASSWORD = 'AUTH/SET_PASSWORD'
export type SetPassword = GenericActionWithType<
  typeof SET_PASSWORD,
  { password: string; error: string }
>

// Set repeatPassword
export const SET_REPEAT_PASSWORD = 'AUTH/SET_REPEAT_PASSWORD'
export type SetRepeatPassword = GenericActionWithType<
  typeof SET_REPEAT_PASSWORD,
  { repeatPassword: string; error: string }
>

export type AuthActions =
  | GetPasswordTypesAction
  | AssignOwnPasswordAction
  | AssignUserPasswordAction
  | ToggleModalOpen
  | SetPassword
  | SetRepeatPassword
