import { clone } from 'ramda'
import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import { SAVE_OR_EDIT_REPORT_CARD_CONFIG_SUCCESS } from '@/sections/report-card/config/types/apiInteractions'
import { CardId } from '@/sections/report-card/config/types/entities'
import * as Types from '@/sections/report-card/config/types/stepperModalSteps/scores'

const initialState: Types.ReportCardConfigScoresState = {
  scores: {
    name: '',
    available: true,
    id: CardId.SCORES,
    numericalScores: {
      subjects: [],
      subjectIds: [],
      scale: 100,
    },
    conceptScores: {
      subjects: [],
      subjectIds: [],
      values: [],
      scales: [],
    },
    numericalOn: true,
    conceptOn: true,
  },
  attitudinalScores: {
    name: '',
    available: true,
    values: [],
    id: CardId.ATTITUDINAL_SCORES,
  },
  competenceScores: {
    available: true,
    name: '',
    types: [],
    values: [],
    id: CardId.COMPETENCE_SCORES,
  },
  reviews: {
    name: '',
    available: true,
    bySubject: false,
    id: CardId.REVIEWS,
  },
  absences: {
    name: '',
    available: true,
    id: CardId.ABSENCES,
  },
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_CONFIG_SCORES_STEP

const ReportCardConfigScoresStep: Reducer<
  Types.ReportCardConfigScoresState,
  Types.ReportCardConfigScoresAction
> = (state = initialState, action): Types.ReportCardConfigScoresState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case Types.SET_SCORE_VALUES: {
      const { scoreCategory, values, scaleId } = action.payload

      switch (scoreCategory) {
        case 'scores': {
          return {
            ...state,
            scores: { ...state.scores, conceptScores: { ...state.scores.conceptScores, values } },
          }
        }
        case 'attitudinalScores':
        case 'competenceScores': {
          return {
            ...state,
            [scoreCategory]: {
              ...clone(state[scoreCategory]),
              values,
            },
          }
        }
        case 'scales': {
          return {
            ...state,
            scores: {
              ...state.scores,
              conceptScores: {
                ...state.scores.conceptScores,
                scales: state.scores.conceptScores.scales.map((scale) => {
                  return scale.id !== scaleId ? scale : { ...scale, values: values }
                }),
              },
            },
          }
        }
        default:
          return state
      }
    }
    case Types.SET_CATEGORY_AVAILABLE: {
      const { scoreCategory, available } = action.payload

      const updatedCategory = {
        ...clone(state[scoreCategory]),
        available,
      }

      return {
        ...state,
        [scoreCategory]: updatedCategory,
      }
    }
    case Types.SET_REVIEWS_BY_SUBJECT: {
      const { bySubject } = action.payload

      return { ...state, reviews: { ...state.reviews, bySubject } }
    }
    case Types.SET_COMPETENCE_TYPES: {
      const { competenceTypes } = action.payload

      return {
        ...state,
        competenceScores: {
          ...state.competenceScores,
          types: competenceTypes,
        },
      }
    }
    case Types.SET_NUMERICAL_OR_CONCEPT_ON: {
      const scoresState = {
        ...state.scores,
        numericalOn:
          action.payload.toggleType === 'numerical'
            ? !state.scores.numericalOn
            : state.scores.numericalOn,
        conceptOn:
          action.payload.toggleType === 'concept'
            ? !state.scores.conceptOn
            : state.scores.conceptOn,
      }

      return {
        ...state,
        scores: {
          ...scoresState,
          available: scoresState.numericalOn || scoresState.conceptOn,
        },
      }
    }
    case SAVE_OR_EDIT_REPORT_CARD_CONFIG_SUCCESS:
      return initialState
    case Types.SET_CARD_NAME: {
      const { cardId, newName } = action.payload
      return { ...state, [cardId]: { ...state[cardId], name: newName } }
    }
    case Types.SET_VALUES_FOR_EDITION: {
      const { scoresState } = action.payload
      return scoresState
    }
    case Types.ADD_NEW_SCALE: {
      const { newScale } = action.payload

      return {
        ...state,
        scores: {
          ...state.scores,
          conceptScores: {
            ...state.scores.conceptScores,
            scales: [...state.scores.conceptScores.scales, newScale],
          },
        },
      }
    }
    case Types.REMOVE_SCALE: {
      const { scaleId } = action.payload

      return {
        ...state,
        scores: {
          ...state.scores,
          conceptScores: {
            ...state.scores.conceptScores,
            scales: state.scores.conceptScores.scales.filter((scale) => scale.id !== scaleId),
          },
        },
      }
    }
    case Types.SET_SCALE_NAME: {
      const { scaleId, newName } = action.payload

      return {
        ...state,
        scores: {
          ...state.scores,
          conceptScores: {
            ...state.scores.conceptScores,
            scales: state.scores.conceptScores.scales.map((scale) => {
              return scale.id !== scaleId ? scale : { ...scale, name: newName }
            }),
          },
        },
      }
    }
    case Types.REMOVE_SCALE_VALUE: {
      const { scaleId, indexOfValueToDelete } = action.payload

      return {
        ...state,
        scores: {
          ...state.scores,
          conceptScores: {
            ...state.scores.conceptScores,
            scales: state.scores.conceptScores.scales.map((scale) => {
              return scale.id !== scaleId
                ? scale
                : {
                    ...scale,
                    values: scale.values.filter((value, index) => index !== indexOfValueToDelete),
                  }
            }),
          },
        },
      }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, ReportCardConfigScoresStep, requestDestination)
