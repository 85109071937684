import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import type {
  DeleteClassroomConfirmModalAction,
  DeleteClassroomConfirmModalState,
} from '@/sections/management/types/deleteClassroomConfirmModal'
import { SET_INITIAL_VALUES } from '@/sections/management/types/deleteClassroomConfirmModal'

const initialState: DeleteClassroomConfirmModalState = {
  open: false,
  loading: false,
  error: '',
  classroom: null,
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_DELETE_CLASSROOM_CONFIRM_MODAL

const DeleteClassroomConfirmModalReducer: Reducer<
  DeleteClassroomConfirmModalState,
  DeleteClassroomConfirmModalAction
> = (state = initialState, action): DeleteClassroomConfirmModalState => {
  switch (action.type) {
    case SET_INITIAL_VALUES: {
      return {
        ...state,
        open: true,
        classroom: action.payload.classroom,
      }
    }
    case Redux.Types.Classrooms.DELETE_CLASSROOM_AS_MGMT_REQUEST:
      return { ...state, loading: true }
    case Redux.Types.Generic.CLEAR_STATE:
    case Redux.Types.Classrooms.DELETE_CLASSROOM_AS_MGMT_SUCCESS:
      return initialState
    case Redux.Types.Classrooms.DELETE_CLASSROOM_AS_MGMT_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(
  initialState,
  DeleteClassroomConfirmModalReducer,
  requestDestination
)
