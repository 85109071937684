import * as tus from 'tus-js-client'

const CHUNK_SIZE = 5000000 // 5MB

type CreateTusUploadArgs = {
  file: File
  fileSize: number
  uploadLink: string
  onError: (error: Error) => void
  onSuccess: () => void
  onProgress: (bytesSent: number, bytesTotal: number) => void
}

export const createTusUpload = ({
  file, // readable
  fileSize,
  uploadLink,
  onError,
  onSuccess,
  onProgress,
}: CreateTusUploadArgs) => {
  const upload = new tus.Upload(file, {
    endpoint: uploadLink,
    chunkSize: CHUNK_SIZE,
    uploadSize: fileSize,
    retryDelays: [0, 1000, 3000, 5000],
    onError,
    onSuccess,
    onProgress,
  })

  upload.url = uploadLink
  return upload
}
