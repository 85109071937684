import type { Reducer } from 'redux'
import * as Types from '@/sections/report-card/grading/types/tabs/abscences'
import type {
  AbscencesTabActions,
  AbscencesTabState,
} from '@/sections/report-card/grading/types/tabs/abscences'

const initialState: AbscencesTabState = {
  loading: false,
  error: '',
  studentsAbscences: [],
}

const ReportCardGradingScoresTab: Reducer<AbscencesTabState, AbscencesTabActions> = (
  state = initialState,
  action
): AbscencesTabState => {
  switch (action.type) {
    case Types.GET_ABSCENCES_REQUEST:
      return { ...state, loading: true }
    case Types.GET_ABSCENCES_SUCCESS:
      return { ...state, loading: false, studentsAbscences: action.payload.abscences.attendances }
    case Types.GET_ABSCENCES_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default ReportCardGradingScoresTab
