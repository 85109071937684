import type { Activity } from '@/api/lms/activities/types'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export interface ActivitiesState {
  status: REQUEST_STATUS
  data: Activity[]
  pagination: {
    count: number
    total: number
    limit: number
    offset: number
  }
}

export enum ACTIVITIES_TYPES {
  GET_ACTIVITIES_REQUEST = 'STUDENT/SUMMARY/TABS/ACTIVITIES/ACTIVITIES/GET_ACTIVITIES_REQUEST',
  GET_ACTIVITIES_SUCCCESS = 'STUDENT/SUMMARY/TABS/ACTIVITIES/ACTIVITIES/GET_ACTIVITIES_SUCCCESS',
  GET_ACTIVITIES_FAILURE = 'STUDENT/SUMMARY/TABS/ACTIVITIES/ACTIVITIES/GET_ACTIVITIES_FAILURE',
  SET_PAGINATION_OFFSET = 'STUDENT/SUMMARY/TABS/ACTIVITIES/ACTIVITIES/SET_PAGINATION_OFFSET',
  CLEAN_ACTIVITIES = 'STUDENT/SUMMARY/TABS/ACTIVITIES/ACTIVITIES/CLEAN_ACTIVITIES',
}

// Get activities actions.
export type GetActivitiesRequestAction = {
  type: ACTIVITIES_TYPES.GET_ACTIVITIES_REQUEST
}

export type GetActivitiesSuccessAction = {
  type: ACTIVITIES_TYPES.GET_ACTIVITIES_SUCCCESS
  payload: {
    activities: Activity[]
    count: number
    total: number
  }
}

export type GetActivitiesFailureAction = {
  type: ACTIVITIES_TYPES.GET_ACTIVITIES_FAILURE
}

export type GetActivitiesAction =
  | GetActivitiesRequestAction
  | GetActivitiesSuccessAction
  | GetActivitiesFailureAction

// Set pagination offset.
export type SetPaginationOffsetAction = {
  type: ACTIVITIES_TYPES.SET_PAGINATION_OFFSET
  payload: {
    newOffset: number
  }
}

// Clean activities.
export type CleanActivitiesAction = {
  type: ACTIVITIES_TYPES.CLEAN_ACTIVITIES
}

export type ActivitiesAction =
  | GetActivitiesAction
  | SetPaginationOffsetAction
  | CleanActivitiesAction
