import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin, setGenericField, toggleEntity } from '@/legacy/redux/utils'
import type {
  ClassroomSelectorModalState,
  ClassroomSelectorModalAction,
} from '@/sections/management/types/classroomSelectorModal'
import {
  SET_OPEN,
  REMOVE_CLASSROOM,
  ADD_CLASSROOM,
  LIST_CLASSROOMS_AS_ADMIN_FAILURE,
  LIST_CLASSROOMS_AS_ADMIN_REQUEST,
  LIST_CLASSROOMS_AS_ADMIN_SUCCESS,
  LIST_NEIGHBORHOODS_AS_ADMIN_REQUEST,
  LIST_NEIGHBORHOODS_AS_ADMIN_SUCCESS,
  LIST_NEIGHBORHOODS_AS_ADMIN_FAILURE,
} from '@/sections/management/types/classroomSelectorModal'

const initialState: ClassroomSelectorModalState = {
  open: false,
  loading: false,
  error: '',
  pagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0,
  },
  classrooms: [],
  classroomOptions: [],
  search: '',
  grade: '',
  management: 0,
  neighborhood: 0,
  neighborhoods: [],
  organizationId: 0,
  allSelected: true,
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_CLASSROOM_SELECTOR_MODAL

const ClassroomSelectorModalReducer: Reducer<
  ClassroomSelectorModalState,
  ClassroomSelectorModalAction
> = (state = initialState, action): ClassroomSelectorModalState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case SET_OPEN:
      return { ...state, open: true, classroomOptions: action.payload.classroomOptions }
    case LIST_CLASSROOMS_AS_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    case LIST_CLASSROOMS_AS_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        classrooms: action.payload.classrooms,
        pagination: {
          ...state.pagination,
          count: action.payload.count,
        },
      }
    case LIST_NEIGHBORHOODS_AS_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        neighborhoods: action.payload.neighborhoods,
      }
    case LIST_NEIGHBORHOODS_AS_ADMIN_FAILURE:
    case LIST_CLASSROOMS_AS_ADMIN_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case LIST_NEIGHBORHOODS_AS_ADMIN_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case ADD_CLASSROOM: {
      const { classroom } = action.payload
      const classroomOption = {
        classroomId: classroom.id,
        organizationId: classroom.organizationId,
        organizationType: classroom.organizationType || '',
        organizationName: classroom.organizationName || '',
        label: `${classroom.grade}° ${classroom.division}, ${classroom.shift} - ${classroom.organizationName}`,
        studentsCount: classroom.studentsCount,
        division: classroom.division,
        grade: classroom.grade,
        shift: classroom.shift,
        cue: classroom.organizationCue,
        neighborhood: classroom.organizationNeighborhood,
      }
      const classroomsAfterToggle = state.classroomOptions.find(
        (c) => c.classroomId === classroomOption.classroomId
      )
        ? state.classroomOptions
        : [...state.classroomOptions, classroomOption]

      return {
        ...state,
        classroomOptions: classroomsAfterToggle,
      }
    }
    case REMOVE_CLASSROOM: {
      const { classroom } = action.payload

      const classroomsAfterToggle = state.classroomOptions.filter(
        (c) => c.classroomId !== classroom.id
      )
      return {
        ...state,
        classroomOptions: classroomsAfterToggle,
      }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, ClassroomSelectorModalReducer, requestDestination)
