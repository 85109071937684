import type { ClearState } from '@/legacy/redux/types/generic'
import type { GenericAction, GenericPayload } from '@/legacy/redux/utils'
import type { RangePickerRange } from '@/sections/report-card/config/components/atomic/rangePicker'
import type { SaveOrEditReportCardConfigSuccess } from '@/sections/report-card/config/types/apiInteractions'
import type { ScoreCategory } from './scores'

export interface ReportCardConfigRangePickerModalState {
  ranges: RangePickerRange[]
  activeRangeName: string
  open: boolean
  category: ScoreCategory
  scaleId?: number
}

export const POPULATE_AND_OPEN_RANGE_PICKER =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SCORES/RANGE_PICKER/POPULATE_AND_OPEN_RANGE_PICKER'

export interface PopulateAndOpenRangePickerPayload extends GenericPayload {
  ranges: RangePickerRange[]
  activeRangeName: string
  category: ScoreCategory
  scaleId?: number
}

export interface PopulateAndOpenRangePicker extends GenericAction {
  type: typeof POPULATE_AND_OPEN_RANGE_PICKER
  payload: PopulateAndOpenRangePickerPayload
}

export type ReportCardConfigRangePickerModalAction =
  | PopulateAndOpenRangePicker
  | ClearState
  | SaveOrEditReportCardConfigSuccess
