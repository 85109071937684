import type { TabsActions, TabsState } from '@/sections/principal/dashboard/types/tabs'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'

const initialState: TabsState = { currentTab: 0 }

const requestOrigin = reduxRequestOriginMap.PRINCIPAL_TABS

function PrincipalTabsReducer(state = initialState, action: TabsActions): TabsState {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, PrincipalTabsReducer, requestOrigin)
