import type { BulletinActions, BulletinState } from '@/sections/teacher/bulletin/types/bulletin'
import { BULLETIN_TYPES } from '@/sections/teacher/bulletin/types/bulletin'

const initialState: BulletinState = {
  openDraftMessageModal: false,
}

export default function bulletinReducer(
  state: BulletinState = initialState,
  action: BulletinActions
): BulletinState {
  switch (action.type) {
    case BULLETIN_TYPES.OPEN_DRAFT_MESSAGE_MODAL: {
      return {
        ...state,
        openDraftMessageModal: true,
      }
    }
    case BULLETIN_TYPES.CLOSE_DRAFT_MESSAGE_MODAL: {
      return {
        ...state,
        openDraftMessageModal: false,
      }
    }
    default:
      return state
  }
}
