import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFillable = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path stroke="#42B3FF" strokeWidth={1.564} strokeLinecap="round" d="M4.782 11.218h6.778" />
    <path
      d="M6.117 9.292a.773.773 0 0 1-.446-.124.559.559 0 0 1-.226-.336.795.795 0 0 1 .08-.489l1.76-3.708c.102-.219.226-.38.372-.482A.902.902 0 0 1 8.175 4c.195 0 .365.051.51.153.147.102.271.263.373.482l1.76 3.708c.087.185.116.35.087.496a.501.501 0 0 1-.212.336.724.724 0 0 1-.423.117c-.224 0-.397-.051-.518-.153-.122-.102-.231-.268-.329-.497l-.35-.817.584.438H6.686l.584-.438-.35.817c-.098.23-.2.395-.307.497-.107.102-.273.153-.496.153ZM8.16 5.693l-.744 1.789-.22-.416h1.95l-.22.416-.751-1.789H8.16Z"
      fill="#42B3FF"
    />
  </svg>
)
export default SvgFillable
