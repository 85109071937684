import { Redux } from '@/legacy/redux'
import { setGenericField } from '@/legacy/redux/utils'
import type {
  AnswerLoginActions,
  AnswerLoginState,
} from '@/sections/auth/answersLogin/types/answersLogin'
import {
  SET_QUESTION_ANSWER,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_TIMEOUT,
  BACK_TO_BEFORE_QUESTION,
  GO_BACK_TO_LOGIN,
  GET_QUESTIONS_FAILURE,
  GET_QUESTIONS_REQUEST,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_TIMEOUT,
  CLEAR_TIMEOUT_MINUTES,
} from '@/sections/auth/answersLogin/types/answersLogin'

const initialState: AnswerLoginState = {
  loading: true,
  error: '',
  questionAnswer: 0,
  questions: [{ id: 0, question: '', i18NCode: '', options: [] }],
  answers: [],
  timeoutMinutes: 0,
  modalIsOpen: false,
  goBack: false,
  stillInTimeoutMinutes: 0,
}

function root(state = initialState, action: AnswerLoginActions) {
  switch (action.type) {
    case GO_BACK_TO_LOGIN: {
      return { ...state, goBack: false }
    }
    case SET_QUESTION_ANSWER: {
      const { answer, index } = action.payload
      const answers = state.answers
      answers[index] = answer
      return { ...state, answers, questionAnswer: state.questionAnswer + 1 }
    }
    case BACK_TO_BEFORE_QUESTION: {
      return { ...state, questionAnswer: state.questionAnswer - 1 }
    }
    case GET_QUESTIONS_REQUEST:
      return { ...initialState, error: '' }
    case GET_QUESTIONS_SUCCESS: {
      const { id, questions } = action.payload
      return { ...state, loading: false, questions, id }
    }
    case GET_QUESTIONS_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case GET_QUESTIONS_TIMEOUT:
      return { ...state, stillInTimeoutMinutes: action.payload.minutes }
    case LOGIN_REQUEST:
      return { ...state, loading: true, error: '' }
    case LOGIN_SUCCESS:
      return { ...state, loading: false }
    case LOGIN_TIMEOUT:
      return { ...state, timeoutMinutes: action.payload.minutes, modalIsOpen: true, loading: false }
    case LOGIN_FAILURE:
      return { ...state, error: action.payload.error, goBack: true }
    case CLEAR_TIMEOUT_MINUTES:
      return { ...state, stillInTimeoutMinutes: 0 }
    default:
      return state
  }
}

export default root
