import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSubject = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
    {...props}
  >
    <rect width="56" height="56" rx="6" fill="#675CFF" fill-opacity="0.15" />
    <circle cx="28" cy="28" r="3" fill="#2A205E" />
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.5 20.206C40.631 24.9004 45.2077 32.1956 42.7224 36.5002C40.2371 40.8049 31.631 40.4889 23.5 35.7944C18.7172 33.0331 15.1642 29.3719 13.5548 25.9488C14.0355 25.8589 14.4758 25.6544 14.8463 25.3647C16.3041 28.4443 19.6953 31.8657 24.25 34.4954C31.6635 38.7756 39.3523 39.3374 41.4234 35.7502C43.4944 32.163 39.1635 25.7852 31.75 21.505C24.3364 17.2248 16.6477 16.663 14.5766 20.2502C14.5483 20.2992 14.5212 20.3487 14.4953 20.3987C14.0622 20.1492 13.5608 20.0047 13.0261 20.0001C13.1 19.8294 13.1837 19.6627 13.2775 19.5002C15.7628 15.1956 24.369 15.5116 32.5 20.206Z"
        fill="#675CFF"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.0935 42.2539C20.6292 39.2212 19 33.97 19 28C19 18.6112 23.0294 11 28 11C32.9706 11 37 18.6112 37 28C37 37.2363 33.1004 44.7523 28.2415 44.994C28.6362 44.5506 28.9013 43.9893 28.9775 43.3696C32.6578 42.3796 35.5 35.8758 35.5 28C35.5 19.4396 32.1421 12.5 28 12.5C23.8579 12.5 20.5 19.4396 20.5 28C20.5 33.387 21.8297 38.1321 23.8479 40.9099C23.4894 41.279 23.2251 41.7399 23.0935 42.2539Z"
      fill="#675CFF"
    />
    <circle cx="43" cy="19" r="2" fill="#2A205E" />
    <circle cx="26" cy="43" r="2" fill="#2A205E" />
    <circle cx="13" cy="23" r="2" fill="#2A205E" />
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40.4503 17.4182C36.694 15.5137 29.9476 16.483 23.5 20.2056C15.369 24.9 10.7922 32.1952 13.2775 36.4998C15.7628 40.8044 24.369 40.4884 32.5 35.794C39.224 31.9119 43.5174 26.2511 43.3964 21.9739C43.2667 21.991 43.1344 21.9999 43 21.9999C42.6177 21.9999 42.252 21.9284 41.9158 21.798C42.3026 25.4775 38.189 30.7774 31.75 34.495C24.3364 38.7752 16.6476 39.337 14.5766 35.7498C12.5055 32.1626 16.8364 25.7848 24.25 21.5046C30.4658 17.9159 36.8751 16.9412 40.0045 18.8342C40.0327 18.3164 40.1922 17.8335 40.4503 17.4182Z"
        fill="#675CFF"
      />
    </g>
  </svg>
)
export default SvgSubject
