import * as genericTypes from './generic'
import * as studentsTypes from './students'
import * as collabMiniatureTypes from './collabMiniature'
import * as classroomsTypes from './classrooms'
import * as subjectsTypes from './subjects'
import * as organizationsTypes from './organizations'
import * as subgroupsTypes from './subgroups'
import * as activitiesTypes from './activities'
import * as managementUsersTypes from './managementUsers'
import * as authTypes from './auth'

const reduxTypes = {
  Auth: authTypes,
  Activities: activitiesTypes,
  Generic: genericTypes,
  Students: studentsTypes,
  CollabMiniature: collabMiniatureTypes,
  Classrooms: classroomsTypes,
  Subgroups: subgroupsTypes,
  Subjects: subjectsTypes,
  Organizations: organizationsTypes,
  ManagementUsers: managementUsersTypes,
}

export default reduxTypes
