import * as Types from '../../types/featureFlag/addEditModal';
import { REQUEST_STATUS } from '@/legacy/redux/types/status';

const initialState: Types.AddEditFeatureFlagsModalState = {
    isOpen: false,
    isEditMode: false,
    status: REQUEST_STATUS.IDLE,
    selectedFeature: {
        id: 0,
        name: '',
        description: '',
        createdAt: '',
        defaultValue: '',
        defaultVisible: false,
    },
};

const addEditFeatureFlagsModalReducer = (
    state: Types.AddEditFeatureFlagsModalState = initialState,
    action: Types.AddEditFeatureFlagsModalAction,
): Types.AddEditFeatureFlagsModalState => {
    switch (action.type) {
        case Types.ADD_EDIT_FEATURE_FLAGS_MODAL.OPEN_MODAL: {
            return {
                ...state,
                isOpen: true,
            }
        }
        case Types.ADD_EDIT_FEATURE_FLAGS_MODAL.TOGGLE_EDIT_MODE: {
            return {
                ...state,
                isEditMode: !state.isEditMode,
            }
        }
        case Types.ADD_EDIT_FEATURE_FLAGS_MODAL.SET_FEATURE_TO_EDIT: {
            return {
                ...state,
                selectedFeature: action.payload,
            }
        }
        case Types.ADD_EDIT_FEATURE_FLAGS_MODAL.CLEAR_MODAL: {
            return initialState;
        }
        case Types.ADD_EDIT_FEATURE_FLAGS_MODAL.CREATE_FEATURE_FLAG_REQUEST:
        case Types.ADD_EDIT_FEATURE_FLAGS_MODAL.EDIT_FEATURE_FLAG_REQUEST: {
            return {
                ...state,
                status: REQUEST_STATUS.LOADING,
            }
        }
        case Types.ADD_EDIT_FEATURE_FLAGS_MODAL.CREATE_FEATURE_FLAG_SUCCESS:
        case Types.ADD_EDIT_FEATURE_FLAGS_MODAL.EDIT_FEATURE_FLAG_SUCCESS: {
            return initialState;
        }
        case Types.ADD_EDIT_FEATURE_FLAGS_MODAL.CREATE_FEATURE_FLAG_FAILURE:
        case Types.ADD_EDIT_FEATURE_FLAGS_MODAL.EDIT_FEATURE_FLAG_FAILURE: {
            return {
                ...state,
                status: REQUEST_STATUS.FAILURE,
            }
        }
        default: return state;
    }
};

export default addEditFeatureFlagsModalReducer;