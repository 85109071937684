import { combineReducers } from 'redux'
import dashboard from './reducers/dashboard'
import bookLicenses from './reducers/bookLicenses'
import organizations from './reducers/organizations'
import importOrganization from './reducers/importOrganization'
import importPasswords from './reducers/importPasswords'

import featureFlags from './reducers/featureFlag'

const rootReducer = combineReducers({
  dashboard,
  bookLicenses,
  organizations,
  importOrganization,
  featureFlags,
  importPasswords,
})

export default rootReducer
