import { VIDEOCALL_ORIGIN } from '@/sections/videocall/constants'
import type { VideocallActions, VideocallState } from '@/sections/videocall/types/videocall'
import { SET_POPUP_CLOSED, SET_POPUP_OPEN } from '@/sections/videocall/types/videocall'

const initialState: VideocallState = {
  popupOpen: false,
  popupUrl: '',
  videoTitle: '',
  videocallOrigin: VIDEOCALL_ORIGIN.CHAT,
}

function root(state = initialState, action: VideocallActions): VideocallState {
  switch (action.type) {
    case SET_POPUP_OPEN: {
      return {
        ...state,
        popupOpen: true,
        popupUrl: action.payload.popupUrl,
        videoTitle: action.payload.videoTitle || '',
        videocallOrigin: action.payload.videocallOrigin,
      }
    }
    case SET_POPUP_CLOSED: {
      return {
        ...state,
        popupOpen: false,
        popupUrl: '',
        videoTitle: '',
        videocallOrigin: VIDEOCALL_ORIGIN.CHAT,
      }
    }
    default:
      return state
  }
}

export default root
