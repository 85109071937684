import { COSMOS_KEY } from '@aula/config'
import type { Reducer } from 'redux'
import type { Option } from '@/legacy/components/v3/selects/selectSimple'
import { Redux } from '@/legacy/redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import type { Plan } from '@/legacy/redux/types/plans'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import * as Types from '@/sections/management/types/organizations/addEditOrganizationModal'
import { getRandomInt } from '@/legacy/utils/randomInt'

// HARDCODED: por ahora uri me dijo que esto va hardcodeado
const managementOptions = [
  {
    id: 1,
    label: 'Privada',
  },
  {
    id: 2,
    label: 'Pública',
  },
]

const initialState: Types.AddEditOrganizationsModalState = {
  open: false,
  loading: false,
  error: '',
  editMode: false,
  detailsMode: false,
  managementOptions, // HARDCODED: por ahora uri me dijo que esto va hardcodeado
  planOptions: [],
  countriesOptions: [],
  provincesOptions: [],
  orgId: 0,
  name: '',
  management: managementOptions[0],
  country: { id: 0, label: '' },
  province: { id: 0, label: '' },
  plan: {
    featuresIds: [],
    id: 0,
    name: '',
  },
  address: '',
  phone: '',
  legalRepresentativeName: '',
  legalRepresentativeEmail: '',
  url: '',
  stages: [],
  shifts: [],
  profiles: [],
  organizationprofiles: [],
  academicPeriods: [],
  emblem: '',
  seal: '',
  sealFile: null,
  signature: '',
  signatureFile: null,
  emblemFile: null,
  logo: '',
  logoFile: null,
  cosmos: [COSMOS_KEY.AULA],
  cue: '',
  visibilityTags: [],
  timeZone: '',
  audiences: [],
  isDemo: false,
  removeAt: null,
  idjs: [],
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_ADD_EDIT_ORGANIZATIONS_MODAL

const AdminOrganizationsModalReducer: Reducer<
  Types.AddEditOrganizationsModalState,
  Types.AddEditOrganizationModalAction
> = (state = initialState, action): Types.AddEditOrganizationsModalState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case Types.CLEAR_FIELDS:
      return {
        ...initialState,
        open: state.open,
        countriesOptions: state.countriesOptions,
        planOptions: state.planOptions,
      }
    case Types.SET_INITIAL_VALUES_REQUEST:
      return {
        ...state,
        open: true,
        loading: true,
        editMode: true,
      }
    case Types.SET_INITIAL_VALUES_SUCCESS: {
      const { address, phone, url, stages, shifts, profiles, audiences } =
        action.payload.organization
      const {
        id,
        name,
        type,
        country,
        province,
        timeZone,
        seal,
        signature,
        isDemo,
        removeAt,
        idjs,
      } = action.payload.organization
      const { logo, emblem, plan, cue, visibilityTags } = action.payload.organization
      const { legalRepName, legalRepEmail, academicPeriods } = action.payload.organization
      const { provincesOptions } = action.payload
      const { cosmos, activeFeaturesIds } = action.payload

      const provincesOpts = provincesOptions.map((prov): Option => {
        return { id: prov.id, label: prov.name }
      })

      const selectedPlan = state.planOptions.find((p) => p.id === plan.id)!

      return {
        ...state,
        loading: false,
        orgId: id,
        name: name,
        management: type === 'private' ? managementOptions[0] : managementOptions[1], // HARDCODED:
        country: {
          id: country.id,
          label: country.name,
        },
        provincesOptions: provincesOpts,
        province: {
          id: province.id,
          label: province.name,
        },
        address: address,
        phone: phone,
        legalRepresentativeName: legalRepName,
        legalRepresentativeEmail: legalRepEmail,
        url: url,
        stages,
        shifts,
        profiles,
        organizationprofiles: profiles,
        academicPeriods,
        logo: logo,
        emblem: emblem,
        seal,
        signature,
        plan: {
          ...selectedPlan,
          featuresIds: activeFeaturesIds,
        },
        cosmos: cosmos || initialState.cosmos,
        cue,
        visibilityTags,
        timeZone,
        audiences,
        isDemo,
        removeAt,
        idjs,
      }
    }
    case Types.SET_INITIAL_VALUES_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case Types.GET_COUNTRIES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.GET_COUNTRIES_SUCCESS: {
      const countriesOpts = action.payload.countries.map((ctry): Option => {
        return { id: ctry.id, label: ctry.name }
      })
      const provincesOpts = action.payload.provinces.map((prov): Option => {
        return { id: prov.id, label: prov.name }
      })
      return {
        ...state,
        countriesOptions: countriesOpts,
        country: countriesOpts[0],
        provincesOptions: provincesOpts,
        province: provincesOpts[0],
        loading: false,
      }
    }
    case Types.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case Types.GET_PROVINCES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.GET_PROVINCES_SUCCESS: {
      const provincesOpts = action.payload.provinces.map((prov): Option => {
        return { id: prov.id, label: prov.name }
      })
      return {
        ...state,
        provincesOptions: provincesOpts.length > 0 ? provincesOpts : initialState.provincesOptions,
        province: provincesOpts[0] ? provincesOpts[0] : initialState.province,
        loading: false,
      }
    }
    case Types.GET_PROVINCES_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        provincesOptions: initialState.provincesOptions,
        province: initialState.province,
        loading: false,
      }
    case Types.GET_PLANS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.GET_PLANS_SUCCESS: {
      return {
        ...state,
        planOptions: action.payload.plans,
        plan: action.payload.plans[0],
        loading: false,
      }
    }
    case Types.GET_PLANS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case Types.CREATE_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.CREATE_ORGANIZATION_SUCCESS:
      return {
        ...initialState,
        countriesOptions: state.countriesOptions,
        planOptions: state.planOptions,
      }
    case Types.CREATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case Types.EDIT_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.EDIT_ORGANIZATION_SUCCESS:
      return {
        ...initialState,
        countriesOptions: state.countriesOptions,
        planOptions: state.planOptions,
      }
    case Types.EDIT_ORGANIZATION_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case Types.CUSTOMIZE_PLAN: {
      const customizedPlanIds = action.payload.activeFeaturesIds

      return {
        ...state,
        plan: {
          ...state.plan,
          featuresIds: customizedPlanIds,
        },
      }
    }
    case Types.ADD_PROFILE_TO_ORG: {
      const { profile } = action.payload

      return {
        ...state,
        profiles: [...state.profiles, profile],
      }
    }
    case Types.REMOVE_PROFILE: {
      const { profileId } = action.payload

      return {
        ...state,
        profiles: state.profiles.filter((profile) => profile.id !== profileId),
      }
    }
    case Types.EDIT_PROFILE: {
      const { newProfileName, profileId } = action.payload

      return {
        ...state,
        profiles: state.profiles.map((profile) =>
          profile.id === profileId
            ? {
                ...profile,
                name: newProfileName,
              }
            : profile
        ),
      }
    }
    case Types.SET_AUDIENCES: {
      const { newAudiences } = action.payload

      return {
        ...state,
        audiences: newAudiences,
      }
    }
    case Types.TOGGLE_DEMO_CHECKBOX: {
      return { ...state, isDemo: !state.isDemo }
    }
    case Types.SET_ACADEMIC_PERIOD_START: {
      return {
        ...state,
        academicPeriods: state.academicPeriods.map((ap, i) =>
          i == action.payload.index ? { ...ap, startsAt: action.payload.date } : ap
        ),
      }
    }
    case Types.SET_ACADEMIC_PERIOD_END: {
      return {
        ...state,
        academicPeriods: state.academicPeriods.map((ap, i) =>
          i == action.payload.index ? { ...ap, endsAt: action.payload.date } : ap
        ),
      }
    }
    case Types.ADD_IDJ: {
      const { newIdj } = action.payload
      return {
        ...state,
        idjs: [...state.idjs, newIdj],
      }
    }
    case Types.DELETE_IDJ: {
      const { id } = action.payload
      return {
        ...state,
        idjs: state.idjs.filter((idj) => idj.id !== id),
      }
    }
    case Types.EDIT_IDJ: {
      const { id, idjTypeId, value, inputType, min, max, error } = action.payload
      return {
        ...state,
        idjs: state.idjs.map((idj) => {
          if (idj.id !== id) return idj

          return {
            ...idj,
            ...(idjTypeId && { idjTypeId }),
            ...(inputType && { inputType }),
            ...(min && { min }),
            ...(max && { max }),
            ...(typeof value === 'string' && { value }),
            ...(typeof error === 'string' && { error }),
          }
        }),
      }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, AdminOrganizationsModalReducer, requestDestination)
