import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import type {
  NewStudentModalActions,
  NewStudentModalState,
} from '@/sections/principal/classroom-migration/types/newStudentModal'
import {
  NEW_STUDENTS_MODAL_LIMIT,
  GET_ALL_STUDENTS_REQUEST,
  GET_ALL_STUDENTS_SUCCESS,
  SET_NEW_STUDENT_MODAL_CLOSED,
  SET_NEW_STUDENT_MODAL_OPEN,
  GET_ALL_STUDENTS_FAILURE,
  SET_SELECTED_STUDENT,
  SET_STUDENT_SEARCH,
} from '@/sections/principal/classroom-migration/types/newStudentModal'
import * as StudentsDrawerTypes from '@/sections/principal/classroom-migration/types/studentsDrawer'

const initialState: NewStudentModalState = {
  open: false,
  search: '',
  students: [],
  selectedStudents: [],
  loading: false,
  total: 0,
  error: '',
  params: {
    offset: 0,
    limit: NEW_STUDENTS_MODAL_LIMIT,
    organizationId: 0,
    search: '',
  },
}

const requestDestination = reduxRequestOriginMap.PRINCIPAL_MIGRATION_TAB

const StudentsDrawerReducer = (
  state: NewStudentModalState = initialState,
  action: NewStudentModalActions
): NewStudentModalState => {
  if (action.payload?.requestOrigin !== requestDestination) return state

  switch (action.type) {
    case SET_NEW_STUDENT_MODAL_OPEN:
      return { ...state, open: true }
    case SET_NEW_STUDENT_MODAL_CLOSED:
      return initialState
    case GET_ALL_STUDENTS_REQUEST:
      return { ...state, loading: true }
    case GET_ALL_STUDENTS_SUCCESS: {
      const { students, params, total } = action.payload

      return {
        ...state,
        loading: false,
        students,
        total,
        params,
      }
    }
    case SET_SELECTED_STUDENT: {
      const { student } = action.payload

      const filtered = state.selectedStudents.filter((st) => st.id !== student.id)

      if (filtered.length === state.selectedStudents.length) filtered.push(student)

      return {
        ...state,
        search: '',
        selectedStudents: filtered,
      }
    }
    case SET_STUDENT_SEARCH:
      return { ...state, search: action.payload.search }
    case GET_ALL_STUDENTS_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case StudentsDrawerTypes.ADD_NEW_STUDENTS:
      return initialState
    default: {
      return state
    }
  }
}

export default StudentsDrawerReducer
