import { url } from '@aula/config'
import { ApiPrivate } from '@/legacy/services/api/api'
import type * as Types from './types'

/**
 * Obtiene una lista de aulas como administrador y una cuenta de las mismas
 */
export const getAsAdmin = (
  params: Types.GetClassroomsAsAdminParams
): Promise<Types.GetClassroomsResponse> =>
  ApiPrivate.get<Types.GetClassroomsResponse>(url + '/v1/admin/classrooms', {
    params,
  }).then((response) => response.data)

/**
 * Obtiene una lista de aulas como director y una cuenta de las mismas
 */
export const getAsPrincipal = (
  params: Types.GetClassroomsAsPrincipalParams
): Promise<Types.GetClassroomsResponse> =>
  ApiPrivate.get<Types.GetClassroomsResponse>(url + '/v1/principal/classrooms', {
    params,
  }).then((response) => response.data)
