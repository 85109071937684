import type { Moment } from 'moment'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type { ErrorPayload, GenericPayload } from '@/legacy/redux/utils'
import type { GetActivitySuccess } from '@/sections/teacher/activities/types/createExtraInfo/educationalInfo/educationalInfoStep'

export const GET_TIMESLOTS_REQUEST = 'COLLAB_MINIATURE/GET_TIMESLOTS_REQUEST'
export const GET_TIMESLOTS_SUCCESS = 'COLLAB_MINIATURE/GET_TIMESLOTS_SUCCESS'
export const GET_TIMESLOTS_FAILURE = 'COLLAB_MINIATURE/GET_TIMESLOTS_FAILURE'

export const GET_SCENARIOS_REQUEST = 'COLLAB_MINIATURE/GET_SCENARIOS_REQUEST'
export const GET_SCENARIOS_SUCCESS = 'COLLAB_MINIATURE/GET_SCENARIOS_SUCCESS'
export const GET_SCENARIOS_FAILURE = 'COLLAB_MINIATURE/GET_SCENARIOS_FAILURE'

export const TOGGLE_SCENARIO = 'COLLAB_MINIATURE/TOGGLE_SCENARIO'
export const TOGGLE_ALL_SCENARIOS = 'COLLAB_MINIATURE/TOGGLE_ALL_SCENARIOS'

// TIMESLOTS
export interface Timeslot {
  id: number
  startTime: string
  endTime: string
}

export interface GetTimeslotsRequest {
  type: typeof GET_TIMESLOTS_REQUEST
  payload: GenericPayload
}

export interface GetTimeslotsSuccess {
  type: typeof GET_TIMESLOTS_SUCCESS
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    timeslots: Timeslot[]
  }
}

export interface GetTimeslotsFailure {
  type: typeof GET_TIMESLOTS_FAILURE
  payload: ErrorPayload
}

// SCENARIOS
export interface Scenario {
  id: number
  name: string
  available: boolean
}

export interface GetScenariosRequest {
  type: typeof GET_SCENARIOS_REQUEST
  payload: GenericPayload
}

export interface GetScenariosSuccess {
  type: typeof GET_SCENARIOS_SUCCESS
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    scenarios: Scenario[]
  }
}

export interface GetScenariosFailure {
  type: typeof GET_SCENARIOS_FAILURE
  payload: ErrorPayload
}

export interface ToggleScenario {
  type: typeof TOGGLE_SCENARIO
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    scenario: Scenario
  }
}

export interface ToggleAllScenarios {
  type: typeof TOGGLE_ALL_SCENARIOS
  payload: GenericPayload
}

export interface CollabMiniatureState {
  loadingScenarios: boolean
  loadingTimeslots: boolean
  error: string
  timeslots: Timeslot[]
  scenarios: Scenario[]
  selectedScenarios: Scenario[]
  date: Moment
  selectedTimeslot: Timeslot | null
  guided: boolean
}

export type CollabMiniatureAction =
  | GetTimeslotsRequest
  | GetTimeslotsSuccess
  | GetTimeslotsFailure
  | GetScenariosRequest
  | GetScenariosSuccess
  | GetScenariosFailure
  | ToggleScenario
  | ToggleAllScenarios
  | SetField<'date', Moment>
  | SetField<'selectedTimeslot', Timeslot>
  | SetField<'guided', boolean>
  | ClearState
  | GetActivitySuccess // THIS IS IMPORTED FROM EDUCATIONAL INFO BUT IS ACTUALLY FROM THE CREATE FLOW (which is non-TS)
