import React from 'react'
import { Paper, Typography } from '@mui/material'
import TextButton from './textButton'
import classNames from 'clsx'
import Badge from './badgeV2'
import chroma from 'chroma-js'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: ({ color }) => ({
    width: 'fit-content',
    border: '1px solid #D8DBE8',
    borderRadius: '8px',
    alignItems: 'center',
    transition: 'all 100ms',
    '&:hover': {
      border: `1px solid ${color} !important`,
      background: chroma(color).alpha(0.15).hex(),
    },
  }),
  rootPending: {
    background: '#FEEFEC',
    border: '1px solid rgba(244, 92, 58, 0.3) !important',
  },
  button: {
    width: '100%',
    justifyContent: 'flex-start',
    padding: 7,
    fontWeight: 'normal',
    transition: 'all 10ms',
    color: 'inherit !important',
  },
  subjectContainer: {
    textAlign: 'left',
  },
  subjectTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  circle: ({ color }) => ({
    width: 8,
    height: 8,
    borderRadius: 8,
    marginRight: 8,
    background: color,
  }),
  subject: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '18px',
  },
  badgeContainer: {
    marginTop: 8,
    display: 'flex',
    '& :not(:last-child)': {
      marginRight: 4,
    },
  },
  warningBadge: {
    background: 'linear-gradient(0deg, rgba(244, 92, 58, 0.1), rgba(244, 92, 58, 0.1)), #FFFFFF',
    color: '#F45C3A',
    borderColor: 'rgba(244, 92, 58, 0.3)',
  },
  happyBadge: {
    background: 'linear-gradient(0deg, rgba(80, 209, 116, 0.2), rgba(80, 209, 116, 0.2)), #FFFFFF;',
    border: '1px solid rgba(80, 209, 116, 0.3)',
  },
}))

export default function SubjectTile(props) {
  const { subject, pending, today, onClick } = props
  const { name, color } = subject

  const classes = useStyles({ color: color || '#D8DBE8' })

  return (
    <Paper
      elevation={0}
      classes={{
        root: classNames(classes.root, { [classes.rootPending]: pending > 0 }),
      }}
    >
      <TextButton className={classes.button} variant="text" onClick={onClick}>
        <div className={classes.subjectContainer}>
          <div className={classes.subjectTitle}>
            <div className={classes.circle} />
            <Typography className={classes.subject}>{name}</Typography>
          </div>
          <div className={classes.badgeContainer}>
            {today > 0 && <Badge emoji=":calendar:" description={today} />}
            {pending > 0 && (
              <Badge emoji=":warning:" description={pending} className={classes.warningBadge} />
            )}
            {pending === 0 && today === 0 && <Badge emoji="😊" className={classes.happyBadge} />}
          </div>
        </div>
      </TextButton>
    </Paper>
  )
}

SubjectTile.defaultProps = {
  pending: 0,
  today: 0,
}
