import { ROLE } from '@aula/config'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { useAppSelector } from '@/state/hooks'

export const views = {
  TEACHER: 'teacher',
  STUDENT: 'student',
  EB_ADMIN: 'eb-admin',
  EB_EVALUATOR: 'eb-evaluator',
  CONTENT_CREATOR: 'content-creator',
  PRINCIPAL: 'principal',
  GUEST: 'guest',
}

const teacherEquivalents = new Set([
  ROLE.PRINCIPAL,
  ROLE.VICE_PRINCIPAL,
  ROLE.CLASS_TEACHER,
  ROLE.TEACHER,
  ROLE.PARENT,
])

const classTeacherEquivalents = new Set([
  ROLE.PRINCIPAL,
  ROLE.VICE_PRINCIPAL,
  ROLE.CLASS_TEACHER,
  ROLE.PARENT,
])

const studentEquivalents = new Set([ROLE.STUDENT])
const ebAdminEquivalent = new Set([ROLE.EB_ADMIN])
const ebEvaluatorEquivalent = new Set([ROLE.EB_EVALUATOR])
const principalEquivalents = new Set([ROLE.PRINCIPAL, ROLE.VICE_PRINCIPAL])
const contentCreatorEquivalent = new Set([ROLE.CONTENT_CREATOR])
const guestEquivalent = new Set([ROLE.GUEST])

const TEACHER_DASHBOARD_URL = '/docente/dashboard'
const STUDENT_DASHBOARD_URL = '/alumno/dashboard'
const EB_ADMIN_DASHBOARD_URL = '/admin/dashboard'
const EB_EVALUATOR_DASHBOARD_URL = '/evaluator/dashboard'
const PRINCIPAL_DASHBOARD_URL = '/principal/dashboard'
const CONTENT_CREATOR_DASHBOARD_URL = '/contenidista/dashboard'
const GUEST_DASHBOARD_URL = '/invitado/dashboard'

export const isTeacherEquivalent = (role) => teacherEquivalents.has(role)
export const isClassTeacherEquivalent = (role) => classTeacherEquivalents.has(role)
export const isStudentEquivalent = (role) => studentEquivalents.has(role)
export const isEbAdminEquivalent = (role) => ebAdminEquivalent.has(role)
export const isEbEvaluatorEquivalent = (role) => ebEvaluatorEquivalent.has(role)
export const isPrincipalEquivalent = (role) => principalEquivalents.has(role)
export const isContentCreatorEquivalent = (role) => contentCreatorEquivalent.has(role)
export const isGuestEquivalent = (role) => guestEquivalent.has(role)

export const defaultRoute = (role) => {
  if (isPrincipalEquivalent(role)) return PRINCIPAL_DASHBOARD_URL
  if (isTeacherEquivalent(role)) return TEACHER_DASHBOARD_URL
  if (isStudentEquivalent(role)) return STUDENT_DASHBOARD_URL
  if (isEbAdminEquivalent(role)) return EB_ADMIN_DASHBOARD_URL
  if (isEbEvaluatorEquivalent(role)) return EB_EVALUATOR_DASHBOARD_URL
  if (isContentCreatorEquivalent(role)) return CONTENT_CREATOR_DASHBOARD_URL
  if (isGuestEquivalent(role)) return GUEST_DASHBOARD_URL
}

export const useStudentEquivalent = () =>
  isStudentEquivalent(useAppSelector((state) => state.user.role))

export const mapAltRoles = (roles, actualView, setRole, t) => {
  const mappedViews = new Set([actualView])

  return roles.reduce((acc, role) => {
    if (isPrincipalEquivalent(role) && !mappedViews.has(views.PRINCIPAL)) {
      mappedViews.add(views.PRINCIPAL)
      return [
        ...acc,
        {
          onClick: () => setRole(role, PRINCIPAL_DASHBOARD_URL),
          label: t('rolesManagement.principalDashboardUrl'),
          id: 'principal-option',
          Icon: DashboardIcon,
          role: ROLE.PRINCIPAL,
        },
      ]
    }
    if (isContentCreatorEquivalent(role) && !mappedViews.has(views.CONTENT_CREATOR)) {
      mappedViews.add(views.CONTENT_CREATOR)
      return [
        ...acc,
        {
          onClick: () => setRole(role, CONTENT_CREATOR_DASHBOARD_URL),
          label: t('rolesManagement.creatorDashboardUrl'),
          id: 'content-creator-option',
          Icon: DashboardIcon,
          role: ROLE.CONTENT_CREATOR,
        },
      ]
    }
    if (isTeacherEquivalent(role) && !mappedViews.has(views.TEACHER)) {
      mappedViews.add(views.TEACHER)
      return [
        ...acc,
        {
          onClick: () => setRole(role, TEACHER_DASHBOARD_URL),
          label: t('rolesManagement.teacherDashboardUrl'),
          id: 'teacher-option',
          Icon: DashboardIcon,
          role: ROLE.TEACHER,
        },
      ]
    }
    if (isStudentEquivalent(role) && !mappedViews.has(views.STUDENT)) {
      mappedViews.add(views.STUDENT)
      return [
        ...acc,
        {
          onClick: () => setRole(role, STUDENT_DASHBOARD_URL),
          label: t('rolesManagement.studentDashboardUrl'),
          id: 'student-option',
          Icon: DashboardIcon,
          role: ROLE.STUDENT,
        },
      ]
    }
    if (isEbAdminEquivalent(role) && !mappedViews.has(views.EB_ADMIN)) {
      mappedViews.add(views.EB_ADMIN)
      return [
        ...acc,
        {
          onClick: () => setRole(role, EB_ADMIN_DASHBOARD_URL),
          label: t('rolesManagement.ebAdminUrl'),
          id: 'eb-admin-option',
          Icon: DashboardIcon,
          role: ROLE.EB_ADMIN,
        },
      ]
    }
    if (isEbEvaluatorEquivalent(role) && !mappedViews.has(views.EB_EVALUATOR)) {
      mappedViews.add(views.EB_EVALUATOR)
      return [
        ...acc,
        {
          onClick: () => setRole(role, EB_EVALUATOR_DASHBOARD_URL),
          label: t('rolesManagement.ebEvaluatorUrl'),
          id: 'eb-evaluator-option',
          Icon: DashboardIcon,
          role: ROLE.EB_EVALUATOR,
        },
      ]
    }
    return acc
  }, [])
}

export const getRole = (role) => {
  if (role === ROLE.TEACHER) return 'rolesManagement.mapRolesToRoutes.teacher'
  if (role === ROLE.STUDENT) return 'rolesManagement.mapRolesToRoutes.student'
  if (role === ROLE.EB_ADMIN) return 'rolesManagement.mapRolesToRoutes.eb_admin'
  if (role === ROLE.CONTENT_CREATOR) return 'rolesManagement.mapRolesToRoutes.content_creator'
  if (role === ROLE.PRINCIPAL) return 'rolesManagement.mapRolesToRoutes.principal'
  if (role === ROLE.VICE_PRINCIPAL) return 'rolesManagement.mapRolesToRoutes.vicePrincipal'
  if (role === ROLE.RECTOR) return 'rolesManagement.mapRolesToRoutes.rector'
  return 'rolesManagement.mapRolesToRoutes.user'
}
