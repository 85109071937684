import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import type { GroupsMainState, GroupMainAction } from '@/sections/ted/types/progress'
import type { Reducer } from 'redux'
import { SET_FIELD } from '@/legacy/redux/types/generic'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import {
  LIST_GROUPS_REQUEST,
  LIST_GROUPS_SUCCESS,
  LIST_GROUPS_FAILURE,
  LIST_INSTITUTIONS_REQUEST,
  LIST_INSTITUTIONS_SUCCESS,
  LIST_INSTITUTIONS_FAILURE,
} from '@/sections/ted/types/progress'

const initialState: GroupsMainState = {
  groups: [],
  pagination: {
    page: 0,
    size: 12,
    more: false,
  },
  institutions: [],
  selectedInstitution: {
    id: 0,
    name: '',
    address: '',
    formal: false,
    cue: '',
    email: '',
    years: [0],
    province: '',
    city: '',
    totalFacilitators: 0,
    mainFacilitatorId: 0,
    totalGroups: 0,
    hub: '',
  },
  error: '',
  loadingGroups: true,
  loadingInstitutions: true,
  institutionId: 0,
}

const requestDestination = reduxRequestOriginMap.TED_GROUPS

const TedGroupsReducer: Reducer<GroupsMainState, GroupMainAction> = (
  state = initialState,
  action
): GroupsMainState => {
  switch (action.type) {
    case SET_FIELD:
      return setGenericField(state, action.payload)
    case LIST_INSTITUTIONS_REQUEST:
      return { ...state, loadingInstitutions: true }
    case LIST_INSTITUTIONS_SUCCESS:
      return { ...state, loadingInstitutions: false, institutions: action.payload.institutions }
    case LIST_INSTITUTIONS_FAILURE:
      return { ...state, loadingInstitutions: false, error: action.payload.error }
    case LIST_GROUPS_REQUEST:
      const { page, rowsPerPage } = action.payload
      return {
        ...state,
        loadingGroups: true,
        pagination: {
          ...state.pagination,
          page: page,
          size: rowsPerPage,
        },
      }
    case LIST_GROUPS_SUCCESS:
      const { more } = action.payload
      return {
        ...state,
        loadingGroups: false,
        groups: action.payload.groups,
        pagination: {
          ...state.pagination,
          more,
        },
      }
    case LIST_GROUPS_FAILURE:
      return { ...state, loadingGroups: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, TedGroupsReducer, requestDestination)
