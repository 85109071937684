import { makeStyles } from '@mui/styles'
import type { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import EmptyState from '@/legacy/components/v2/emptyState'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import MigrationTabActionCreators from '@/sections/principal/classroom-migration/actions/migrationTab'
import Anibot from '@/legacy/components/spinners/anibot'

const requestOrigin = reduxRequestOriginMap.PRINCIPAL_MIGRATION_TAB

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '0 auto',
    marginTop: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '50%',
    gap: 24,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
    },
  },
}))

interface MigrationFinalStepProps {
  onClick: VoidFunction
}

const MigrationFinalStep = (props: MigrationFinalStepProps): ReactElement => {
  const styles = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const migrationTabActions = bindActionCreators(MigrationTabActionCreators, dispatch)

  function handleClick() {
    migrationTabActions.clearState(requestOrigin)
    props.onClick()
  }

  return (
    <div className={styles.container}>
      <Anibot size={110} />
      <EmptyState
        hideImage
        title={t('principal.classroomMigration.startMigration')}
        subtitle={t('principal.classroomMigration.startMigrationSubtitle')}
      />
    </div>
  )
}

export default MigrationFinalStep
