/* eslint-disable prettier/prettier */
import type { AxiosResponse } from 'axios'
import { apiPrivate } from '..'
import type { COSMOS_KEY, ROLE } from '@aula/config'
import { organizationsUrl, url, webBffUrl } from '@aula/config'
import { capitalizeEveryWord } from '@/legacy/utils/text'
import type { DescriptionResponseShortener, GenericEntity, GenericParams } from '@/api/types'
import type { Audience } from './library'
import type { ManagementRole } from '@/legacy/roles'

export const OrganizationsAPI = {
  listAsAdmin,
  getOneAsAdmin,
  getCountries,
  getProvinces,
  getPlans,
  createAsAdmin,
  editAsAdmin,
  listAsPrincipal,
  getOneAsPrincipal,
  editAsPrincipal,
  getStudentsCountAsPrincipal,
  getOrganizationCosmosList,
  listAsManagement,
  getOrganizationPlan,
  getNeighborhoods,
  getSubjects,
  scheduleDeletion,
  cloneOrganization,
}

type CountriesList = {
  countries: GenericEntity[]
}
type ProvincesList = {
  provinces: GenericEntity[]
}

interface Publisher extends GenericEntity {
  logo: string
}

export interface AcademicPeriod extends GenericEntity {
  default: boolean
  startsAt: string
  endsAt: string
}

export interface StudentsCount {
  count: number
}

export interface VisibilityTag {
  id: number
  name: string // Maybe more specific
  value: string
}

export interface Profile {
  name: string
  role: ROLE | string
  id: number
  organizationId: number
}

export type BasicAudience = Pick<Audience, 'id' | 'name'>

export interface Idj {
  id: number
  idjTypeId: number
  name: string
  value: string
}

export interface Stage {
  id: number
  name: string
  masterSeries: MasterSeries
}

export interface MasterSeries {
  id: number
  name: string
  steps: Step[]
}

export interface Step {
  stepValue: number
  name: string
}


export interface ManagementOrganization {
  address: string
  country: GenericEntity
  province: GenericEntity // Provincia o estado
  demo: boolean
  id: number
  legalRepEmail: string
  legalRepName: string
  legalRepPhone: string
  logo: string
  emblem: string
  seal: string
  signature: string
  name: string
  phone: string
  premium: boolean
  type: 'public' | 'private'
  url: string
  stages: Stage[]
  shifts: GenericEntity[]
  profiles: Profile[]
  academicPeriods: AcademicPeriod[]
  publisher: Publisher
  plan: GenericEntity
  institutionType: string
  cue: string
  visibilityTags: VisibilityTag[]
  timeZone: string
  audiences: BasicAudience[]
  isDemo: boolean
  removeAt: string | null
  idjs: Idj[]
}

export type PrincipalOrganization = Omit<ManagementOrganization, 'plan' | 'institutionType'>

/**
 * Lists all organizations.
 *
 * @role Admin
 * @param studentID StudentID
 * @param form { passwordTypeId: id, password: string }
 */

export type ListAsAdminParams = GenericParams & { organizationId?: number }

type ListAsAdminResponse = {
  organizations: ManagementOrganization[]
  total: number
}

function listAsAdmin(params: GenericParams): Promise<ListAsAdminResponse> {
  return apiPrivate
    .get(url + '/v1/admin/organizations', {
      params,
    })
    .then((response: AxiosResponse<DescriptionResponseShortener<any>>) => {
      const { organizations, total } = response.data.description
      const orgs = organizations.map(({ administrationLevel1, ...o }) => {
        return { ...o, province: administrationLevel1 }
      })
      return { organizations: orgs, total }
    })
    .catch((err) => {
      throw err
    })
}

function getOneAsAdmin(orgId: number): Promise<ManagementOrganization> {
  return apiPrivate
    .get(webBffUrl + '/v1/admin/organizations/' + orgId)
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      const { administrationLevel1, properties, ...o } = resp.data.description
      return { ...o, province: administrationLevel1, visibilityTags: properties }
    })
    .catch((err) => {
      throw err
    })
}

function getOrganizationCosmosList(orgId: number): Promise<COSMOS_KEY[]> {
  return apiPrivate
    .get(organizationsUrl + `/v1/organizations/${orgId}/cosmos`)
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      const { cosmosList } = resp.data.description
      return cosmosList
    })
    .catch((err) => {
      throw err
    })
}

function getCountries(): Promise<CountriesList> {
  return apiPrivate
    .get(url + '/v1/data/countries')
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

function getProvinces(countryId: number): Promise<ProvincesList> {
  return apiPrivate
    .get(url + '/v1/data/countries/' + countryId + '/provinces')
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

export interface ManagementNeighborhood {
  id: number
  label: string
}

type NeighborhoodList = {
  neighborhoods: { neighborhood: string }[]
}

function getNeighborhoods(): Promise<NeighborhoodList> {
  return apiPrivate
    .get(url + '/v1/admin/neighborhoods')
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

export type ApiPlan = {
  createdAt: string
  features: { planId: number; featureId: number }[]
  id: number
  name: string
  updatedAt: string
}
type ApiPlansList = {
  plans: ApiPlan[]
}

function getPlans() {
  return apiPrivate
    .get<DescriptionResponseShortener<ApiPlansList>>(organizationsUrl + '/v1/plans')
    .then(({ data: { description } }) => {
      const { plans } = description
      const parsedPlans = plans.map(({ id, name, features }) => {
        return { id, name, featuresIds: features.map((f) => f.featureId) }
      })
      return { plans: parsedPlans }
    })
}

/**
 * Creates an organization.
 *
 * @role Admin
 */

export interface CreateOrganizationAsAdminParams {
  name: string
  type: string
  countryId: number
  administrationLevelId: number // Provincia o estado
  planId: number
  featuresList: number[] // This param is ignored if planId === 0
  address: string
  phone: string
  legalRepName: string
  legalRepMail: string
  organizationUrl: string
  emblem: string
  seal: string
  signature: string
  logo: string
  stages: string[]
  shifts: string[]
  academicPeriods: Omit<AcademicPeriod, 'id'>[]
  cosmosList: COSMOS_KEY[]
  timeZone: string
  audienceIds: number[]
  isDemo: boolean
  idjs: Omit<Idj, 'id'>[]
}

function createAsAdmin({ administrationLevelId: administrationLevel1Id, ...data }: CreateOrganizationAsAdminParams): Promise<void> {
  return apiPrivate
    .post(url + '/v4/admin/organizations', {
      ...data,
      'administration_level_1_id': administrationLevel1Id,
    })
}

export interface EditOrganizationAsAdminParams {
  name: string
  type: string
  countryId: number
  administrationLevelId: number // Provincia o estado
  planId: number | null
  featuresList: number[] // This param is ignored if planId === 0
  address: string
  phone: string
  legalRepName: string
  legalRepMail: string
  organizationUrl: string
  emblem: string
  logo: string
  seal: string
  signature: string
  orgId: number
  stages: GenericEntity[]
  shifts: GenericEntity[]
  academicPeriods: AcademicPeriod[]
  cosmosList: COSMOS_KEY[]
  audienceIds: number[]
  idjs: Idj[]
}

function editAsAdmin({
  administrationLevelId: administrationLevel1Id,
  ...data
}: EditOrganizationAsAdminParams): Promise<void> {
  return apiPrivate
    .put(url + `/v5/admin/organizations/${data.orgId}`, {
      ...data,
      'administration_level_1_id': administrationLevel1Id,
    })
}

// PRINCIPAL
function listAsPrincipal(orgId: number): Promise<PrincipalOrganization> {
  return apiPrivate
    .get(url + '/v1/principal/organizations/' + orgId)
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      //TODO rename this
      const { administrationLevel1, ...o } = resp.data.description
      return { ...o, province: administrationLevel1 }
    })
    .catch((err) => {
      throw err
    })
}

function getOneAsPrincipal(orgId: number): Promise<ManagementOrganization> {
  return apiPrivate
    .get(url + '/v1/principal/organizations/' + orgId)
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      //TODO rename this
      const { administrationLevel1, ...o } = resp.data.description
      return { ...o, province: administrationLevel1 }
    })
    .catch((err) => {
      throw err
    })
}

function getStudentsCountAsPrincipal(orgId: number): Promise<StudentsCount> {
  return apiPrivate
    .get(url + '/v2/organizations/' + orgId + '/student-count')
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

export interface EditOrganizationAsPrincipalParams {
  name: string
  type: string
  countryId: number
  administrationLevelId: number // Provincia o estado
  planId: number | null
  address: string
  phone: string
  legalRepName: string
  legalRepMail: string
  organizationUrl: string
  emblem: string
  logo: string
  orgId: number
  stages: GenericEntity[]
  shifts: GenericEntity[]
  academicPeriods: AcademicPeriod[]
  idjs: Idj[]
}

function editAsPrincipal({
  administrationLevelId: administrationLevel1Id,
  ...data
}: EditOrganizationAsPrincipalParams): Promise<void> {
  return apiPrivate
    .put(url + `/v5/principal/organizations/${data.orgId}`, {
      ...data,
      administration_level_1_id: administrationLevel1Id,
    })
}

// INFO: This is a temporary solution to get the organization's data for both admin and principal on assignments modal autosuggestInput.
export interface ListAsManagementParams extends GenericParams {
  role: 'principal' | 'eb-admin'
}

type ListAsManagementResponse = {
  organizations: ManagementOrganization[]
  total: number
}

function listAsManagement(params: ListAsManagementParams): Promise<ListAsManagementResponse> {
  return apiPrivate
    .get(url + '/v1/organizations', {
      params,
    })
    .then((response: AxiosResponse<DescriptionResponseShortener<any>>) => {
      const { organizations, total } = response.data.description
      const orgs = organizations.map(({ administrationLevel1, name, ...o }) => {
        return {
          ...o,
          name: capitalizeEveryWord(name),
          province: administrationLevel1,
        }
      })
      return { organizations: orgs, total }
    })
    .catch((err) => {
      throw err
    })
}

interface OrganizationPlan {
  name: string
  id: number
}

function getOrganizationPlan(organizationId: number) {
  return apiPrivate
    .get<DescriptionResponseShortener<OrganizationPlan>>(
      organizationsUrl + `/v1/organizations/${organizationId}/plan`
    )
    .then((resp) => resp.data.description)
}

export interface Subject {
  color: string
  id: number
  name: string
}

export type GetSubjectResponse = {
  subjects: Subject[]
}

function getSubjects(organizationId: number): Promise<GetSubjectResponse> {
  return apiPrivate
    .get<DescriptionResponseShortener<GetSubjectResponse>>(
      url + `/v1/organization/${organizationId}/subjects`
    )
    .then((resp) => resp.data.description)
}

export interface ScheduleDeletionParams {
  managementRole: ManagementRole
  organizationId: number
  removeAt: string | null
}

function scheduleDeletion(params: ScheduleDeletionParams): Promise<void> {
  const { managementRole, organizationId, removeAt } = params
  return apiPrivate.put(`${url}/v1/${managementRole}/organizations/${organizationId}/removal`, {
    removeAt,
  })
}

interface CloneOrganizationParams {
  managementRole: ManagementRole
  referenceOrgIdForCloning: number
  name: string
  isDemo: boolean
  removeAt?: string
}

function cloneOrganization(params: CloneOrganizationParams) {
  const { managementRole, referenceOrgIdForCloning, ...data } = params
  return apiPrivate
    .post(`${url}/v1/${managementRole}/organizations/${referenceOrgIdForCloning}/clone`, data)
    .catch((err) => err)
}
