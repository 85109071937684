import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFeatureFlags = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
    {...props}
  >
    <rect width="56" height="56" rx="6" fill="white" />
    <rect width="56" height="56" rx="6" fill="#E84F92" fill-opacity="0.12" />
    <rect x="25" y="15" width="20" height="18" rx="1" fill="#EA5D9A" />
    <path
      d="M14 11H33C33.5523 11 34 11.4477 34 12V28C34 28.5523 33.5523 29 33 29H14V11Z"
      fill="#F3A5C7"
    />
    <rect x="11" y="10" width="1.5" height="36" rx="0.75" fill="#5C546A" />
    <path d="M33 29C28.5 30 25 30.5 25 32V29.0127L33 29Z" fill="#BD1961" />
    <rect
      x="24.3979"
      y="16.2954"
      width="1.27908"
      height="7.67451"
      transform="rotate(15 24.3979 16.2954)"
      fill="#2A205E"
    />
    <path
      d="M16 19.9194L19.9193 16.0002L20.8237 16.9046L17.8089 19.9194L20.8237 22.9343L19.9193 23.8387L16 19.9194Z"
      fill="#2A205E"
    />
    <path
      d="M31.8384 19.9194L27.9191 23.8387L27.0146 22.9343L30.0295 19.9194L27.0146 16.9046L27.9191 16.0002L31.8384 19.9194Z"
      fill="#2A205E"
    />
  </svg>
)
export default SvgFeatureFlags
