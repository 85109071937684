import localforage from 'localforage'
import { persistReducer } from 'redux-persist'
import { GET_INFO_SUCCESS } from '@/sections/user/types'
import type {
  PrincipalActions,
  PrincipalState,
} from '@/sections/principal/dashboard/types/dashboard'
import {
  DASHBOARD_FAILURE,
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  SET_GLOBAL_SEARCH,
  SET_NO_PERMISSIONS_MODAL_OPEN,
  SET_ORGANIZATION,
  OPEN_DRAWER,
  CLOSE_DRAWER,
} from '@/sections/principal/dashboard/types/dashboard'

const initialState: PrincipalState = {
  open: false,
  selected: '',
  selectedOrganization: null,
  lastSearchID: 0,
  loadingOrganizations: false,
  loading: false,
  organizationOptions: [],
  classrooms: 0,
  organizations: 0,
  users: 0,
  subjects: 0,
  bookLicenses: 0,
  globalSearch: '',
  error: '',
  noPermissionsModalOpen: false,
}

function root(state = initialState, action: PrincipalActions): PrincipalState {
  switch (action.type) {
    case OPEN_DRAWER: {
      return {
        ...state,
        open: true,
        selected: action.payload.selected,
      }
    }
    case CLOSE_DRAWER: {
      return {
        ...state,
        open: false,
      }
    }
    case GET_INFO_SUCCESS: {
      const p = action.payload
      const selectedOrganization =
        p.organizations.length > 0
          ? { value: p.organizations[0].id, label: p.organizations[0].name }
          : initialState.selectedOrganization
      return { ...state, selectedOrganization }
    }
    case SET_ORGANIZATION:
      return { ...state, selectedOrganization: action.payload.selectedOrganization }
    case SET_GLOBAL_SEARCH:
      return { ...state, globalSearch: action.payload.search }
    case DASHBOARD_REQUEST:
      return { ...state, loading: true, error: '' }
    case DASHBOARD_SUCCESS: {
      const { classrooms, organizations, users, subjects, bookLicenses } = action.payload
      return {
        ...state,
        loading: false,
        classrooms,
        organizations,
        users,
        subjects,
        bookLicenses,
      }
    }
    case DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        classrooms: 0,
        organizations: 0,
        users: 0,
        subjects: 0,
        bookLicenses: 0,
      }
    case SET_NO_PERMISSIONS_MODAL_OPEN:
      return {
        ...state,
        noPermissionsModalOpen: action.payload.open,
      }
    default:
      return state
  }
}

const persistConfig = {
  key: 'eb-admin-dashboard',
  storage: localforage,
  whitelist: ['selectedOrganization'],
}

export default persistReducer(persistConfig, root)
