import { CustomTooltip } from '@/legacy/components/v1'
import { IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import type { AttendanceStatus } from '@/api/attendances/config'
import Button from '@/legacy/components/atomic/button/button'
import UserAvatar from '@/legacy/components/v3/avatars/userAvatar'
import InputSimpleDebounced from '@/legacy/components/v3/inputs/inputSimpleDebounced'
import type { Student } from '@/legacy/redux/types/students'
import type { ClassroomAttendancesByStudent } from '@/legacy/services/api/attendances/types'
import type { CreatedReportCardPeriod } from '@/sections/report-card/grading/types/entities'
import { formatUsername } from '@aula/tools'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    width: '100%',
    maxWidth: 420,
    background: theme.palette.background.paper,
    fontFamily: theme.typography.fontFamily,
    border: '1px solid #DFDFDA',
    boxSizing: 'border-box',
    borderRadius: '9px',
    letterSpacing: '-0.5px',
    transition: 'all 0.3s ease-in-out',
    gap: 8,
  },
  studentDataContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  userAvatar: {
    width: 24,
    height: 24,
    fontSize: 14,
  },
  studentData: {
    display: 'flex',
    flexDirection: 'column',
  },
  studentName: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
  },
  period: {
    fontSize: 14,
  },
  saveButton: {
    width: '100%',
  },
}))

function getSuggestedScore(abscences: ClassroomAttendancesByStudent) {
  return abscences.records.reduce((prev, curr) => prev + curr.value, 0)
}

type ComponentProps = CardHeaderProps & OverviewProps & ArrowButtonsProps & ScoreProps
export interface AbscencesOverviewProps extends ComponentProps {
  onSave: VoidFunction
}

export default function AbscencesOverview({
  abscences,
  attendanceStatuses: overviewStatuses,
  inputOnChange,
  nextButtonDisabled,
  onClickNext,
  onClickPrevious,
  onSave,
  previousButtonDisabled,
  selectedPeriod,
  selectedStudent: student,
}: AbscencesOverviewProps) {
  const C = useStyles()
  const { t } = useTranslation()

  return (
    <div className={C.mainContainer}>
      <div className={C.card}>
        <CardHeader selectedStudent={student} selectedPeriod={selectedPeriod} />
        <Overview attendanceStatuses={overviewStatuses} abscences={abscences} />
        <Separator />
        {/* <Score abscences={abscences} inputOnChange={inputOnChange} /> */}
        <Typography variant="caption">
          {t('reportCard.grading.attendances.sideDrawer.dataWillReflectOnReportCard')}
        </Typography>
        <Button onClick={onSave} className={C.saveButton}>
          {t('generic.validate')}
        </Button>
      </div>
      <ArrowButtons
        nextButtonDisabled={nextButtonDisabled}
        onClickNext={onClickNext}
        onClickPrevious={onClickPrevious}
        previousButtonDisabled={previousButtonDisabled}
      />
    </div>
  )
}

interface CardHeaderProps {
  selectedStudent: Student
  selectedPeriod?: CreatedReportCardPeriod
}

function CardHeader({ selectedStudent: student, selectedPeriod }: CardHeaderProps) {
  const C = useStyles()
  const studentName = formatUsername({ name: student.name, surname: student.lastName })
  return (
    <div className={C.studentDataContainer}>
      {student ? (
        <>
          <UserAvatar name={studentName} className={C.userAvatar} src={student.avatar} />
          <div className={C.studentData}>
            <Typography className={C.studentName}>{studentName}</Typography>
            {selectedPeriod && <Typography className={C.period}>{selectedPeriod.name}</Typography>}
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  )
}

interface OverviewProps {
  attendanceStatuses: AttendanceStatus[]
  abscences: ClassroomAttendancesByStudent
}

function getAbscencesCount(
  status: AttendanceStatus,
  abscences: ClassroomAttendancesByStudent
): number {
  return abscences.records.filter((abs) => {
    return abs.statusId === status.id
  }).length
}

function Overview({ attendanceStatuses: overviewStatuses, abscences }: OverviewProps) {
  const C = useStyles()
  return (
    <>
      {overviewStatuses.map((status) => {
        return (
          <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
            <div style={{ height: 8, width: 8, borderRadius: 4, background: status.color }}></div>
            <Typography>
              {status.description}: {getAbscencesCount(status, abscences)}
            </Typography>
          </div>
        )
      })}
    </>
  )
}

const useArrowStyles = makeStyles((theme) => ({
  arrowsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 8,
  },
  arrowButton: {
    display: 'flex',
    color: 'black',
    width: '36px',
    height: '36px',
    background: theme.palette.background.paper,
    border: '1px solid #DFDFDA',
    boxSizing: 'border-box',
    borderRadius: '3px',
    '& > span': {
      transform: 'scaleY(0.9)',
    },
  },
}))

interface ArrowButtonsProps {
  onClickPrevious: VoidFunction
  onClickNext: VoidFunction
  previousButtonDisabled: boolean
  nextButtonDisabled: boolean
}

function ArrowButtons({
  onClickPrevious,
  onClickNext,
  nextButtonDisabled,
  previousButtonDisabled,
}: ArrowButtonsProps) {
  const C = useArrowStyles()
  return (
    <div className={C.arrowsContainer}>
      <IconButton
        aria-label="close"
        className={C.arrowButton}
        onClick={onClickPrevious}
        color="primary"
        disabled={previousButtonDisabled}
      >
        <CustomTooltip topOffset={4} title="Anterior">
          <ArrowBack fontSize="small" />
        </CustomTooltip>
      </IconButton>
      <IconButton
        aria-label="close"
        className={C.arrowButton}
        onClick={onClickNext}
        color="primary"
        disabled={nextButtonDisabled}
      >
        <CustomTooltip topOffset={4} title="Siguiente">
          <ArrowForward fontSize="small" />
        </CustomTooltip>
      </IconButton>
    </div>
  )
}

function Separator() {
  return (
    <div
      style={{
        borderBottom: '1px solid #DFDFDA',
        marginTop: 8,
        marginBottom: 8,
      }}
    />
  )
}

interface ScoreProps {
  inputOnChange: (value: string) => void
  abscences: ClassroomAttendancesByStudent
}

function Score({ abscences, inputOnChange }: ScoreProps) {
  const { t } = useTranslation()
  return (
    <>
      <InputSimpleDebounced
        value={`${getSuggestedScore(abscences)} (${t(
          'reportCard.grading.abscences.sideDrawer.suggestedMark'
        )})`}
        onChange={inputOnChange}
        styles={{ width: '100%' }}
        disabled
      />
      <InputSimpleDebounced onChange={inputOnChange} styles={{ width: '100%' }} />
    </>
  )
}
