export const LIMIT = 10

export const RECORDER_OPTIONS = {
  type: 'video',
  video: {
    width: 728,
    height: 480,
  },
  frameInterval: 20,
  mimeType: 'video/webm',
  fileExtension: 'webm',
  timeSlice: 1000,
  audioBitsPerSecond: 100000,
}

export const UNITS = {
  SECONDS: 'seconds',
  MINUTES: 'minutes',
}
export const MODAL_TYPES = {
  START: 'start',
  UNANSWERED_QUESTIONS: 'unansweredQuestions',
  FINISH: 'finish',
  UNSTARTED_SECTIONS: 'unstartedSections',
}
export const EXES_RECORDING_ENABLED = false
