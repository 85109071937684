export const ACTIVITY_REQUEST = 'STUDENT_QUIZ/ACTIVITY_REQUEST'
export const ACTIVITY_SUCCESS = 'STUDENT_QUIZ/ACTIVITY_SUCCESS'
export const ACTIVITY_FAILURE = 'STUDENT_QUIZ/ACTIVITY_FAILURE'

export const QUESTION_REQUEST = 'STUDENT_QUIZ/QUESTION_REQUEST'
export const QUESTION_SUCCESS = 'STUDENT_QUIZ/QUESTION_SUCCESS'
export const QUESTION_FAILURE = 'STUDENT_QUIZ/QUESTION_FAILURE'

export const ANSWER_REQUEST = 'STUDENT_QUIZ/ANSWER_REQUEST'
export const ANSWER_SUCCESS = 'STUDENT_QUIZ/ANSWER_SUCCESS'
export const ANSWER_FAILURE = 'STUDENT_QUIZ/ANSWER_FAILURE'

export const SEE_ANSWER_REQUEST = 'STUDENT_QUIZ/SEE_ANSWER_REQUEST'
export const SEE_ANSWER_SUCCESS = 'STUDENT_QUIZ/SEE_ANSWER_SUCCESS'
export const SEE_ANSWER_FAILURE = 'STUDENT_QUIZ/SEE_ANSWER_FAILURE'

export const NEXT_QUESTION = 'STUDENT_QUIZ/NEXT_QUESTION'
export const LAST_QUESTION = 'STUDENT_QUIZ/LAST_QUESTION'
export const GO_TO_STATS = 'STUDENT_QUIZ/GO_TO_STATS'

export const CHOOSE_ANSWER = 'STUDENT_QUIZ/CHOOSE_ANSWER'

export const COMPLETE_QUIZ_REQUEST = 'STUDENT_QUIZ/COMPLETE_QUIZ_REQUEST'
export const COMPLETE_QUIZ_SUCCESS = 'STUDENT_QUIZ/COMPLETE_QUIZ_SUCCESS'
export const COMPLETE_QUIZ_FAILURE = 'STUDENT_QUIZ/COMPLETE_QUIZ_FAILURE'

export const UPDATE_TIMER = 'STUDENT_QUIZ/UPDATE_TIMER'

export const SET_OPEN = 'STUDENT_QUIZ/SET_OPEN'

export const SHOW_ONBOARDING = 'STUDENT_QUIZ/SHOW_ONBOARDING'
export const SHOW_ONBOARDING_FAILURE = 'STUDENT_QUIZ/SHOW_ONBOARDING_FAILURE'

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    open: boolean
  }
}

export interface GetActivityRequest {
  type: typeof ACTIVITY_REQUEST
  payload: {
    activityID: number
  }
}

export interface GetActivitySuccess {
  type: typeof ACTIVITY_SUCCESS
  payload: {
    lastQuestionAnswered: number
    questionIDs: Array<number>
    submissionID: number
    submitted: boolean
    time: number
    title: string
    maxScore: number
  }
}

export interface GetActivityFailure {
  type: typeof ACTIVITY_FAILURE
  payload: {
    error: string
  }
}

export interface GetQuestionRequest {
  type: typeof QUESTION_REQUEST
}

export interface GetQuestionSuccess {
  type: typeof QUESTION_SUCCESS
  payload: {
    question: Question
  }
}

export interface GetQuestionFailure {
  type: typeof QUESTION_FAILURE
  payload: {
    error: string
  }
}

export interface SeeAnswerRequest {
  type: typeof SEE_ANSWER_REQUEST
}

export interface SeeAnswerSuccess {
  type: typeof SEE_ANSWER_SUCCESS
  payload: {
    answer: number
    correctOption: number
    type: string
  }
}

export interface SeeAnswerFailure {
  type: typeof SEE_ANSWER_FAILURE
  payload: {
    error: string
  }
}

export interface AnswerRequest {
  type: typeof ANSWER_REQUEST
}

export interface AnswerSuccess {
  type: typeof ANSWER_SUCCESS
  payload: {
    correct: boolean
    correctAnswer: number
    questionID: number
    optionID: number
  }
}

export interface AnswerFailure {
  type: typeof ANSWER_FAILURE
  payload: {
    error: string
  }
}

export interface NextQuestion {
  type: typeof NEXT_QUESTION
}

export interface LastQuestion {
  type: typeof LAST_QUESTION
}

export interface GoToStats {
  type: typeof GO_TO_STATS
}

export interface ShowOnboarding {
  type: typeof SHOW_ONBOARDING
}

export interface ShowOnboardingFailure {
  type: typeof SHOW_ONBOARDING_FAILURE
  payload: {
    error: string
  }
}

export interface CompleteQuizRequest {
  type: typeof COMPLETE_QUIZ_REQUEST
}
export interface CompleteQuizFailure {
  type: typeof COMPLETE_QUIZ_FAILURE
  payload: {
    error: string
  }
}

export interface CompleteQuizSuccess {
  type: typeof COMPLETE_QUIZ_SUCCESS
  payload: {
    correct: number
    incorrect: number
    elapsed: number
    totalScore: number
  }
}

export interface ChooseAnswer {
  type: typeof CHOOSE_ANSWER
  payload: {
    order: number
  }
}

export interface UpdateTimer {
  type: typeof UPDATE_TIMER
}

export interface Activity {
  chatEnabled: boolean
  completedCurrent: number
  createdAt: string
  date: string
  deadline: string
  endDate: string
  evaluationType: number
  gradedCurrent: number
  id: number
  publicationDate: string
  startDate: string
  title: string
  type: string
}

export interface Option {
  id: number
  state: 'right_choice' | 'wrong_choice' | 'not_chosen'
  url: string
  text: string
}

export interface Question {
  id: number
  type: string
  text: string
  body: string
  url: string
  options: Array<Option>
  score: number
  time: number
}

export interface QuizStats {
  correct: number
  incorrect: number
  totalScore: number
  elapsed: number
}

export interface QuizState {
  activityID: number
  submissionID: number
  nQuestion: number
  questionIDs: Array<number>
  totalTime: number
  question: Question
  timer: number
  score: number
  maxScore: number
  title: string
  correct: boolean | null
  outOfTime: boolean
  lastQuestionAnswered: number

  statsScreen: boolean
  stats: QuizStats

  loading: boolean
  loadingQuestion: boolean
  loadingAnswer: boolean
  loadingStats: boolean
  loadingAnswerSelection: boolean

  error: string
  open: boolean
  onboarding: boolean
}

export type QuizActions =
  | GetActivityRequest
  | GetActivitySuccess
  | GetActivityFailure
  | NextQuestion
  | LastQuestion
  | GoToStats
  | ChooseAnswer
  | UpdateTimer
  | SetOpen
  | GetQuestionRequest
  | GetQuestionSuccess
  | GetQuestionFailure
  | AnswerRequest
  | AnswerSuccess
  | AnswerFailure
  | ShowOnboarding
  | ShowOnboardingFailure
  | CompleteQuizRequest
  | CompleteQuizSuccess
  | CompleteQuizFailure
  | SeeAnswerRequest
  | SeeAnswerSuccess
  | SeeAnswerFailure
