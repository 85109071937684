import Lms from './lms'
import Ted from './ted'
import ReportCard from './reportCard'
import Web from './web'
import * as Attendances from './attendances'

const Services = {
  Lms,
  Ted,
  ReportCard,
  Web,
  Attendances,
}

export default Services
