import type { NewFacilitator } from '@/api/ted/types'
import type { GenericEntity } from '@/api/types'

export interface AddFacilitatorModalState {
  isOpen: boolean
  loading: boolean
  error: Error | null
  data: {
    provinces: GenericEntity[]
    participatedEditions: number[]
  }
}

export enum ADD_FACILITATOR_MODAL_TYPES {
  OPEN_MODAL = 'TED/ADD_FACILITATOR_MODAL/OPEN_MODAL',
  CLOSE_MODAL = 'TED/ADD_FACILITATOR_MODAL/CLOSE_MODAL',
  GET_PROVINCES_REQUEST = 'TED/ADD_FACILITATOR_MODAL/GET_PROVINCES_REQUEST',
  GET_PROVINCES_SUCCESS = 'TED/ADD_FACILITATOR_MODAL/GET_PROVINCES_SUCCESS',
  GET_PROVINCES_FAILURE = 'TED/ADD_FACILITATOR_MODAL/GET_PROVINCES_FAILURE',
  ADD_FACILITATOR = 'TED/ADD_FACILITATOR_MODAL/ADD_FACILITATOR',
  ADD_FACILITATOR_REQUEST = 'TED/ADD_FACILITATOR_MODAL/ADD_FACILITATOR_REQUEST',
  ADD_FACILITATOR_SUCCESS = 'TED/ADD_FACILITATOR_MODAL/ADD_FACILITATOR_SUCCESS',
  ADD_FACILITATOR_FAILURE = 'TED/ADD_FACILITATOR_MODAL/ADD_FACILITATOR_FAILURE',
}

export type OpenAddFacilitatorModalAction = {
  type: ADD_FACILITATOR_MODAL_TYPES.OPEN_MODAL
}

export type CloseAddFacilitatorModalAction = {
  type: ADD_FACILITATOR_MODAL_TYPES.CLOSE_MODAL
}

export type AddFacilitatorAction = {
  type: ADD_FACILITATOR_MODAL_TYPES.ADD_FACILITATOR
  payload: {
    newFacilitator: NewFacilitator
  }
}

export type AddFacilitatorRequestAction = {
  type: ADD_FACILITATOR_MODAL_TYPES.ADD_FACILITATOR_REQUEST
}

export type AddFacilitatorSuccessAction = {
  type: ADD_FACILITATOR_MODAL_TYPES.ADD_FACILITATOR_SUCCESS
}

export type AddFacilitatorFailureAction = {
  type: ADD_FACILITATOR_MODAL_TYPES.ADD_FACILITATOR_FAILURE
  payload: {
    error: Error
  }
}

export type GetProvincesRequestAction = {
  type: ADD_FACILITATOR_MODAL_TYPES.GET_PROVINCES_REQUEST
}

export type GetProvincesSuccessAction = {
  type: ADD_FACILITATOR_MODAL_TYPES.GET_PROVINCES_SUCCESS
  payload: {
    provinces: GenericEntity[]
    participatedEditions: GenericEntity[]
  }
}

export type GetProvincesFailureAction = {
  type: ADD_FACILITATOR_MODAL_TYPES.GET_PROVINCES_FAILURE
  payload: {
    error: Error
  }
}

export type AddFacilitatorModalActions =
  | OpenAddFacilitatorModalAction
  | CloseAddFacilitatorModalAction
  | GetProvincesRequestAction
  | GetProvincesSuccessAction
  | GetProvincesFailureAction
  | AddFacilitatorAction
  | AddFacilitatorRequestAction
  | AddFacilitatorSuccessAction
  | AddFacilitatorFailureAction
