import types from '@/sections/student/dashboard/types'
import { ACTIVITY_SUCCESS as WRITTEN_EXAM_ACTIVITY_SUCCESS } from '@/sections/student/writtenExam/types/writtenExam'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import {
  GET_COURSE_FAILURE,
  GET_COURSE_REQUEST,
  GET_COURSE_SUCCESS,
} from '@/sections/courses/dashboard/types/courseOverview'
import moment from 'moment'
import { clone } from 'ramda'

const initialState = {
  loading: false,
  loadingCourse: false,
  loadingCourseContent: false,
  loadingMarkAsCompleted: false,
  error: '',
  errorModal: '',
  isActivityDeleted: false,
  open: false,
  sendAnswersModalOpen: false,
  sendAnswersStepperOpen: false,
  sendAnswersStep: 0,
  activity: {},
  courseContent: [],
  downloading: 0,
  course: null,
  courseMode: false,
  openUnit: 0,
  isActivityNotAvailableYet: false,
  sectionModal: {
    open: false,
    type: '',
  },
  currentSection: null,
  completedSections: new Set(),
  selectedSection: null,
}

const requestDestination = reduxRequestOriginMap.STUDENT_ACTIVITY

function root(state = initialState, action) {
  switch (action.type) {
    case GET_COURSE_REQUEST:
      if (action.payload.requestOrigin === requestDestination) {
        return {
          ...state,
          loadingCourse: true,
          open: true,
          error: '',
          errorModal: '',
          courseMode: true,
        }
      } else return state
    case GET_COURSE_SUCCESS:
      if (action.payload.requestOrigin === requestDestination) {
        return {
          ...state,
          loadingCourse: false,
          course: action.payload.course,
        }
      } else return state
    case GET_COURSE_FAILURE:
      if (action.payload.requestOrigin === requestDestination) {
        return {
          ...state,
          loadingCourse: false,
          error: action.payload.error,
          open: !action.payload.closeModal,
        }
      } else return state
    case types.CLOSE_MODAL:
      return { ...state, open: false, courseMode: false }
    case types.ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
        open: true,
        error: '',
        errorModal: '',
        isActivityDeleted: false,
      }
    case WRITTEN_EXAM_ACTIVITY_SUCCESS:
    case types.ACTIVITY_SUCCESS: {
      const { activity } = action.payload
      return { ...state, loading: false, activity }
    }
    case types.ACTIVITY_FAILURE: {
      const { error, isDeleted, isActivityNotAvailableYet } = action.payload
      return {
        ...state,
        loading: false,
        isActivityDeleted: isDeleted,
        isActivityNotAvailableYet,
        error,
      }
    }
    case types.MARK_AS_COMPLETED_REQUEST:
      return { ...state, loadingMarkAsCompleted: true, open: true, error: '', errorModal: '' }
    case types.MARK_AS_COMPLETED_SUCCESS: {
      const { activity } = state

      const activityToEdit = clone(activity)
      activityToEdit.completed = moment().format()
      const courseContent = state.courseContent.map((act) => {
        if (act.resourceId === activity.id) act.done = true
        return act
      })

      return {
        ...state,
        loadingMarkAsCompleted: false,
        open: action.payload.nonClosing,
        sendAnswersStepperOpen: false,
        sendAnswersStep: 0,
        activity: activityToEdit,
        courseContent: courseContent,
      }
    }
    case types.MARK_AS_COMPLETED_FAILURE: {
      const sendAnswersModalOpen = state.sendAnswersModalOpen
      const error = !sendAnswersModalOpen ? action.payload.error : ''
      const errorModal = sendAnswersModalOpen ? action.payload.error : ''
      return { ...state, loadingMarkAsCompleted: false, error, errorModal }
    }
    case types.DOWNLOAD_FILE_REQUEST:
      return { ...state, downloading: action.payload.id, error: '' }
    case types.DOWNLOAD_FILE_SUCCESS:
      return { ...state, downloading: 0 }
    case types.DOWNLOAD_FILE_FAILURE:
      return { ...state, downloading: 0, error: action.payload.error }
    case types.SET_SEND_ANSWERS_MODAL_OPEN:
      return { ...state, sendAnswersModalOpen: true, error: '', errorModal: '' }
    case types.SET_SEND_ANSWERS_STEPPER_OPEN:
      return {
        ...state,
        sendAnswersStepperOpen: true,
        sendAnswersStep: 0,
        error: '',
        errorModal: '',
      }
    case types.SET_SEND_ANSWERS_STEPPER_CLOSED:
      return {
        ...state,
        sendAnswersStepperOpen: false,
        sendAnswersStep: 0,
        error: '',
        errorModal: '',
      }
    case types.SET_ANSWERS_STEPPER_STEP:
      return { ...state, sendAnswersStep: action.payload.step }
    case types.GET_COURSE_CONTENT_REQUEST:
      return { ...state, loadingCourseContent: true }
    case types.GET_COURSE_CONTENT_SUCCESS: {
      const { content } = action.payload

      let currUnit = 0
      for (let i = 0; i < content.length; i++) {
        // traverses all units
        if (content[i].type === 'unit') {
          currUnit = content[i].id
        }

        // when it finds the correct activity, it stops, having the last unit saved on currUnit
        if (content[i].resourceId === state.activity.id) {
          break
        }
      }

      return { ...state, loading: false, courseContent: content, openUnit: currUnit }
    }
    case types.GET_COURSE_CONTENT_FAILURE:
      return { ...state, loadingCourseContent: false, error: action.payload.error }
    case types.SET_OPEN_UNIT:
      return { ...state, openUnit: action.payload.openUnitId }
    case types.SET_SECTION_MODAL:
      return {
        ...state,
        sectionModal: {
          open: action.payload.open,
          type: action.payload.type,
        },
      }
    case types.SET_CURRENT_SECTION:
      return { ...state, currentSection: action.payload.section }
    case types.SET_COMPLETED_SECTIONS:
      return { ...state, completedSections: action.payload.sections }
    case types.SET_SELECTED_SECTION:
      return { ...state, selectedSection: action.payload.section }
    default:
      return state
  }
}

export default root
