export const TED_PALETTE = {
  primary: {
    light: '#ed5157',
    main: '#ED1C24',
    dark: '#ED1C24',
  },
  secondary: {
    light: '#ed5157',
    main: '#ED1C24',
    dark: '#ED1C24',
  },
}
