import React from 'react'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
}))

const Subtitle = ({ children, className, ...props }) => {
  const classes = useStyles()
  return (
    <Typography variant="h5" gutterBottom className={`${classes.subtitle} ${className}`} {...props}>
      {children}
    </Typography>
  )
}

export default Subtitle
