import { isEmpty } from 'ramda'
import {
  EVALUATE_OFFLINE_ACTIVITY_FAILURE,
  EVALUATE_OFFLINE_ACTIVITY_REQUEST,
  EVALUATE_OFFLINE_ACTIVITY_SUCCESS,
} from '@/legacy/redux/types/activities'
import activityTypes from '@/sections/teacher/dashboard/types/activity'
import * as Types from '@/sections/teacher/studentList/types/studentList'
import { retrieveQuestionsFromAPI } from '@/sections/editor/utils'
import { QUESTIONS_TYPES } from '@/sections/editor/constants'

const initialState: Types.StudentListState = {
  open: false,
  activity: {
    id: 0,
    subject: { id: 0, name: '', color: '' },
    classroom: {
      organization: { id: 0, name: '' },
      grade: '',
      id: 0,
      division: '',
      organizationType: '',
      year: 0,
      shift: '',
    },
    evaluationType: 0,
    title: '',
    type: '',
  },
  activityToGrade: {
    id: 0,
    subject: { id: 0, name: '', color: '' },
    classroom: {
      grade: '',
      id: 0,
      division: '',
      year: 0,
      shift: '',
      stage: '',
    },
    lastSubmission: {
      id: 0,
      date: '',
    },
    organization: { id: 0, name: '' },
    evaluationType: 0,
    title: '',
    type: '',
  },
  selectedStudent: {
    email: '',
    evaluation: null,
    id: 0,
    robotsId: 0,
    lastName: '',
    name: '',
    submitted: true,
    avatar: '',
  },
  loadingGrading: false,
  grade: {
    value: 0,
    feedback: '',
    items: [],
  },
  gradeEditable: true,
  students: [],
  loading: false,
  error: '',
  message: '',
  loadingResubmission: false,
  errorResubmission: '',
  hideGradingCard: false,
  suspiciousActivityDates: [],
  studentListTotal: 0,
  studentListPagination: {
    page: 0,
    rowsPerPage: 500,
    count: 0,
  },
}

const getQuestionScoresTemplate = (
  questions: Array<{ id: number; score: number; notes?: Types.Note[] }>
): Types.QuestionGrade[] =>
  questions.map((question) => ({
    questionId: question.id,
    comment: '',
    score: 0,
    maxScore: question.score,
    notes: question.notes || [],
  }))

function root(state = initialState, action: Types.StudentListActions): Types.StudentListState {
  switch (action.type) {
    case Types.SET_OPEN:
      return { ...state, open: true, activity: action.payload.activity }
    case Types.SET_CLOSE:
      return initialState
    case Types.SET_SELECTED_STUDENT: {
      const { student } = action.payload
      const wasEvaluated = Boolean(student.evaluation)
      return {
        ...state,
        suspiciousActivityDates: initialState.suspiciousActivityDates,
        selectedStudent: student,
        grade: student.evaluation || initialState.grade,
        gradeEditable: !wasEvaluated,
        message: '',
      }
    }
    case Types.SET_GRADE:
      return { ...state, grade: { ...state.grade, value: action.payload.grade } }
    case Types.SET_FEEDBACK:
      return { ...state, grade: { ...state.grade, feedback: action.payload.feedback } }
    case Types.SET_GRADE_EDITABLE:
      return { ...state, gradeEditable: action.payload.editable }
    case Types.GET_STUDENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        studentListPagination: {
          count: action.payload.count,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    case Types.GET_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        error: '',
        students: action.payload.students,
        loading: false,
        studentListTotal: action.payload.total,
      }
    case Types.GET_STUDENT_LIST_FAILURE:
      return { ...state, error: action.payload.error, loading: false }
    case Types.CHANGE_EVALUATION_TYPE_REQUEST:
      return { ...state, loading: true, error: '' }
    case Types.CHANGE_EVALUATION_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        activity: { ...state.activity, evaluationType: action.payload.activity.evaluationType },
        activityToGrade: {
          ...state.activityToGrade,
          evaluationType: action.payload.activity.evaluationType,
        },
      }
    case Types.CHANGE_EVALUATION_TYPE_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case Types.GET_STUDENT_SUSPICIOUS_ACTIVITY_REQUEST: {
      return { ...state, loading: true }
    }
    case Types.GET_STUDENT_SUSPICIOUS_ACTIVITY_SUCCESS: {
      return { ...state, loading: false, suspiciousActivityDates: action.payload.dates }
    }
    case Types.GET_STUDENT_SUSPICIOUS_ACTIVITY_FAILURE: {
      return { ...state, loading: false, error: action.payload.error }
    }
    case EVALUATE_OFFLINE_ACTIVITY_REQUEST:
    case Types.EVALUATE_SUBMISSION_REQUEST:
      return { ...state, loadingGrading: true, error: '' }
    case EVALUATE_OFFLINE_ACTIVITY_SUCCESS:
    case Types.EVALUATE_SUBMISSION_SUCCESS:
      return {
        ...state,
        error: '',
        message: action.payload.message || '',
        loadingGrading: false,
        gradeEditable: false,
        selectedStudent: {
          ...state.selectedStudent,
          evaluation: {
            ...(state.selectedStudent.evaluation || { date: '', id: 0 }),
            ...action.payload.evaluation,
          },
        },
      }
    case EVALUATE_OFFLINE_ACTIVITY_FAILURE:
    case Types.EVALUATE_SUBMISSION_FAILURE:
      return { ...state, error: action.payload.error, loadingGrading: false }
    case Types.ALLOW_RESUBMISSION_REQUEST:
      return { ...state, loadingResubmission: true, error: '' }
    case Types.ALLOW_RESUBMISSION_SUCCESS:
      return {
        ...state,
        error: '',
        // message: 'Solicitud de reentrega enviada',
        loadingResubmission: false,
        errorResubmission: '',
      }
    case Types.ALLOW_RESUBMISSION_FAILURE:
      return { ...state, errorResubmission: action.payload.error, loadingResubmission: false }
    case Types.SET_QUESTION_GRADE: {
      const { questionId, score, comment } = action.payload
      const isScoreValid = !isNaN(score)
      const items = state.grade.items?.map((i) =>
        i.questionId === questionId && isScoreValid ? { ...i, score, comment } : i
      )
      return { ...state, grade: { ...state.grade, items } }
    }
    case Types.ADD_QUESTION_NOTE: {
      const { note, questionId } = action.payload

      const items = state.grade.items?.map((i) =>
        i.questionId === questionId ? { ...i, notes: [...i.notes, note] } : i
      )
      return { ...state, grade: { ...state.grade, items } }
    }
    case Types.REPLACE_QUESTION_NOTE: {
      const { note, questionId } = action.payload

      const filteredItems = state.grade.items?.map((i) =>
        i.questionId === questionId ? { ...i, notes: i.notes.filter((n) => n.id !== note.id) } : i
      )
      const items = filteredItems?.map((i) =>
        i.questionId === questionId ? { ...i, notes: [...i.notes, note] } : i
      )
      return { ...state, grade: { ...state.grade, items } }
    }
    case Types.REMOVE_QUESTION_NOTE: {
      const { noteId, questionId } = action.payload

      const items = state.grade.items?.map((i) =>
        i.questionId === questionId ? { ...i, notes: i.notes.filter((n) => n.id !== noteId) } : i
      )
      return { ...state, grade: { ...state.grade, items } }
    }
    case activityTypes.STUDENT_ACTIVITY_SUCCESS: {
      // @ts-ignore
      const activity = action.payload.activity
      activity.questions = activity.questions
        ? retrieveQuestionsFromAPI(activity.questions).filter(
            (q) => q.type !== QUESTIONS_TYPES.SECTION
          )
        : []

      return {
        ...state,
        activityToGrade: activity,
        // @ts-ignore
        hideGradingCard: action.payload.hideGradingCard,
        grade: isEmpty(state.grade.items)
          ? { ...state.grade, items: getQuestionScoresTemplate(activity.questions) }
          : state.grade,
      }
    }
    default:
      return state
  }
}

export default root
