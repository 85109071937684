import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

// State.
export interface PdfPreviewModalState {
  isOpenModal: boolean
  status: REQUEST_STATUS
  url: string
}

// Types.
export enum PDF_PREVIEW_MODAL_TYPES {
  OPEN_MODAL = 'REPORT_CARD/CONFIGURATION_DRAWER/PDF_PREVIEW_MODAL/OPEN_MODAL',
  CLEAN_MODAL = 'REPORT_CARD/CONFIGURATION_DRAWER/PDF_PREVIEW_MODAL/CLEAN_MODAL',
  GENERATE_REPORT_CARD_PDF_REQUEST = 'REPORT_CARD/CONFIGURATION_DRAWER/PDF_PREVIEW_MODAL/GENERATE_REPORT_CARD_PDF_REQUEST',
  GENERATE_REPORT_CARD_PDF_SUCCESS = 'REPORT_CARD/CONFIGURATION_DRAWER/PDF_PREVIEW_MODAL/GENERATE_REPORT_CARD_PDF_SUCCESS',
  GENERATE_REPORT_CARD_PDF_FAILURE = 'REPORT_CARD/CONFIGURATION_DRAWER/PDF_PREVIEW_MODAL/GENERATE_REPORT_CARD_PDF_FAILURE',
}

// Open modal action.
export type OpenModalAction = {
  type: PDF_PREVIEW_MODAL_TYPES.OPEN_MODAL
}

// Clean modal action.
export type CleanModalAction = {
  type: PDF_PREVIEW_MODAL_TYPES.CLEAN_MODAL
}

// Generate report card PDF action.
export type GenerateReportCardPdfRequestAction = {
  type: PDF_PREVIEW_MODAL_TYPES.GENERATE_REPORT_CARD_PDF_REQUEST
}

export type GenerateReportCardPdfSuccessAction = {
  type: PDF_PREVIEW_MODAL_TYPES.GENERATE_REPORT_CARD_PDF_SUCCESS
  payload: {
    url: string
  }
}

export type GenerateReportCardPdfFailureAction = {
  type: PDF_PREVIEW_MODAL_TYPES.GENERATE_REPORT_CARD_PDF_FAILURE
}

export type GenerateReportCardPdfAction =
  | GenerateReportCardPdfRequestAction
  | GenerateReportCardPdfSuccessAction
  | GenerateReportCardPdfFailureAction

// Actions.
export type PdfPreviewModalAction = OpenModalAction | CleanModalAction | GenerateReportCardPdfAction
