import type { Theme } from '@mui/material'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import type { Moment } from 'moment'
import moment from 'moment'
import type { AttendanceStatus } from '@/api/attendances/config'
import type { ClassroomAttendancesByStudent } from '@/legacy/services/api/attendances/types'
import { StatusRadioButton } from '@/sections/attendances/records/components/atomic/statusRadioButton'
import { scrollBarStyles } from '@/sections/teacher/activities/styles'
import {
  ABSCENCES_CELL_HEIGHT,
  ABSCENCES_HEADER_HEIGHT,
} from '@/sections/report-card/grading/components/tabs/abscencesDrawer'
import { useAppSelector } from '@/state/hooks'

export interface AbscencesTableColumn {
  id: string
  date: Moment
  isHoliday: boolean
}

export interface AbscencesTableRow extends AttendanceStatus {
  rowId: string
  date: Moment
}

interface StyleProps {
  isDisabled?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    flex: 1,
    overflow: 'auto',
    height: 'fit-content',
    border: '1px solid #DFDFDA',
    borderRadius: 10,
    ...scrollBarStyles,
  },
  table: {
    overflow: 'auto',
    ...scrollBarStyles,
  },
  tableCell: {
    pointerEvents: ({ isDisabled }) => (isDisabled ? 'none' : 'auto'),
    opacity: ({ isDisabled }) => (isDisabled ? '0.4' : '1'),
    backgroundColor: ({ isDisabled }) =>
      isDisabled ? theme.palette.disabled.main : theme.palette.common.white,
    borderBottom: ({ isDisabled }) => (isDisabled ? 'none' : theme.globals.common.borders.main),
  },
}))

export interface AbscencesTableProps {
  columns: AbscencesTableColumn[]
  rows: AbscencesTableRow[]
  abscences: ClassroomAttendancesByStudent
  onStatusRadioChange: (date: Moment, status: AttendanceStatus) => void
}

export default function AbscencesTable({
  columns,
  rows,
  onStatusRadioChange,
  abscences,
}: AbscencesTableProps) {
  const classes = useStyles({ isDisabled: false })

  const { selectedAbsenceSubjectId } = useAppSelector((state) => state.reportCard.grading.mainView)
  const { days } = useAppSelector((state) => state.attendances.records.mainView.config)

  const validAttendanceDays = days
    .filter((day) => day.subjectId === selectedAbsenceSubjectId)
    .map(({ day }) => day)

  const validColumns = columns.filter((column) => {
    const isValidColumn = validAttendanceDays.includes(column.date.day() + 1)
    return isValidColumn
  })

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table" className={classes.table}>
        <TableHead>
          <TableRow>
            {validColumns.map((column) => {
              return <DateHeaderCell column={column} />
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.rowId}>
                {validColumns.map((column, i) => {
                  return (
                    <StatusRadioCell
                      row={row}
                      column={column}
                      onStatusRadioChange={onStatusRadioChange}
                      checked={
                        !!abscences.records.find((a) => {
                          return moment(a.date).isSame(column.date, 'day') && a.statusId === row.id
                        })
                      }
                    />
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

interface DateHeaderCellProps {
  column: AbscencesTableColumn
}

function DateHeaderCell({ column }: DateHeaderCellProps) {
  const styles = useStyles({ isDisabled: column.isHoliday })
  return (
    <TableCell
      key={column.id}
      align={'center'}
      height={ABSCENCES_HEADER_HEIGHT}
      width={ABSCENCES_CELL_HEIGHT}
      className={styles.tableCell}
    >
      <Typography>{column.date.format('DD')}</Typography>
      <Typography>{column.date.format('MMM').slice(0, -1).toUpperCase()}</Typography>
    </TableCell>
  )
}

interface StatusRadioCellProps {
  column: AbscencesTableColumn
  row: AbscencesTableRow
  onStatusRadioChange: (date: Moment, status: AttendanceStatus) => void
  checked: boolean
}

function StatusRadioCell({ column, row, onStatusRadioChange, checked }: StatusRadioCellProps) {
  const styles = useStyles({ isDisabled: column.isHoliday })
  return (
    <TableCell
      key={column.id + row.rowId}
      align={'center'}
      height={ABSCENCES_CELL_HEIGHT}
      className={styles.tableCell}
    >
      <StatusRadioButton
        checked={column.isHoliday ? false : checked}
        color={row.color}
        tooltipText={row.description}
        handleChange={() => {
          if (column.isHoliday) return
          onStatusRadioChange(column.date, row)
        }}
      />
    </TableCell>
  )
}
