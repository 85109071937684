import types from '@/sections/header/types/classroomsPagination'
import { classroomsPagination } from '@/sections/header/constants'

const initialState = {
  error: null,
  loading: false,
  totalPages: 0,
  totalClassrooms: 0,
  disableNext: false,
  cancelToken: null,
  requestedClassrooms: 0,
  paginator: {
    first: false,
    offset: 0,
    limit: classroomsPagination.limit,
  },
  pagination: [],
  paginationEnd: false,
}

function root(state = initialState, action) {
  switch (action.type) {
    case types.TEACHER_CLASSROOMS_PAGINATION_SET_REQUEST_CANCEL_TOKEN: {
      const { cancelToken } = action.payload
      return {
        ...state,
        cancelToken,
      }
    }
    case types.TEACHER_CLASSROOMS_PAGINATION_SET_CLASSROOMS_BY_PAGE: {
      const { limit } = action.payload
      return {
        ...state,
        paginator: {
          ...state.paginator,
          limit,
        },
      }
    }
    case types.TEACHER_CLASSROOMS_PAGINATION_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case types.TEACHER_CLASSROOMS_PAGINATION_CLEAN: {
      return initialState
    }
    case types.TEACHER_CLASSROOMS_PAGINATION_GO_TO_NEXT_PAGE: {
      const { offset, limit, first } = state.paginator
      if (first) {
        const aumentOffset = offset + limit
        return {
          ...state,
          paginator: {
            first: true,
            offset: aumentOffset,
            limit,
          },
        }
      }

      return {
        ...state,
        paginator: {
          first: true,
          offset: 0,
          limit,
        },
      }
    }
    case types.TEACHER_CLASSROOMS_PAGINATION_GO_TO_BACK_PAGE: {
      return state
    }
    case types.TEACHER_CLASSROOMS_PAGINATION_REQUEST_SUCCESS: {
      const { totalClassrooms, totalPages, requestedClassrooms, pagination, paginationEnd } =
        action.payload

      return {
        ...state,
        error: null,
        loading: false,
        totalPages,
        pagination,
        paginationEnd,
        totalClassrooms,
        requestedClassrooms,
      }
    }
    case types.TEACHER_CLASSROOMS_PAGINATION_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    }
    default:
      return state
  }
}

export default root
