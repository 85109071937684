import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type { ConfiguredReportCard, ReportCardSubject } from './entities'

// Get Organization Report Cards
export const GET_ORGANIZATION_REPORT_CARDS_REQUEST =
  'REPORT_CARD/CONFIG/GET_ORGANIZATION_REPORT_CARDS_REQUEST'
export const GET_ORGANIZATION_REPORT_CARDS_SUCCESS =
  'REPORT_CARD/CONFIG/GET_ORGANIZATION_REPORT_CARDS_SUCCESS'
export const GET_ORGANIZATION_REPORT_CARDS_FAILURE =
  'REPORT_CARD/CONFIG/GET_ORGANIZATION_REPORT_CARDS_FAILURE'

export type GetOrganizationReportCardsRequest = GenericActionWithType<
  typeof GET_ORGANIZATION_REPORT_CARDS_REQUEST
>

export type GetOrganizationReportCardsSuccess = GenericActionWithType<
  typeof GET_ORGANIZATION_REPORT_CARDS_SUCCESS,
  { reportCards: ConfiguredReportCard[]; resetSelection?: boolean }
>

export type GetOrganizationReportCardsFailure = GenericActionWithType<
  typeof GET_ORGANIZATION_REPORT_CARDS_FAILURE,
  ErrorPayload
>

export type GetOrganizationReportCardsAction =
  | GetOrganizationReportCardsRequest
  | GetOrganizationReportCardsSuccess
  | GetOrganizationReportCardsFailure

// Save report card config
export const SAVE_OR_EDIT_REPORT_CARD_CONFIG_REQUEST =
  'REPORT_CARD/CONFIG/SAVE_OR_EDIT_REPORT_CARD_CONFIG_REQUEST'
export const SAVE_OR_EDIT_REPORT_CARD_CONFIG_SUCCESS =
  'REPORT_CARD/CONFIG/SAVE_OR_EDIT_REPORT_CARD_CONFIG_SUCCESS'
export const SAVE_OR_EDIT_REPORT_CARD_CONFIG_FAILURE =
  'REPORT_CARD/CONFIG/SAVE_OR_EDIT_REPORT_CARD_CONFIG_FAILURE'

export type SaveOrEditReportCardConfigRequest = GenericActionWithType<
  typeof SAVE_OR_EDIT_REPORT_CARD_CONFIG_REQUEST
>

export type SaveOrEditReportCardConfigSuccess = GenericActionWithType<
  typeof SAVE_OR_EDIT_REPORT_CARD_CONFIG_SUCCESS
>

export type SaveOrEditReportCardConfigFailure = GenericActionWithType<
  typeof SAVE_OR_EDIT_REPORT_CARD_CONFIG_FAILURE,
  ErrorPayload
>

export type SaveReportCardConfigAction =
  | SaveOrEditReportCardConfigRequest
  | SaveOrEditReportCardConfigSuccess
  | SaveOrEditReportCardConfigFailure

// Delete report card Config
export const DELETE_REPORT_CARD_CONFIG_REQUEST =
  'REPORT_CARD/CONFIG/DELETE_REPORT_CARD_CONFIG_REQUEST'
export const DELETE_REPORT_CARD_CONFIG_SUCCESS =
  'REPORT_CARD/CONFIG/DELETE_REPORT_CARD_CONFIG_SUCCESS'
export const DELETE_REPORT_CARD_CONFIG_FAILURE =
  'REPORT_CARD/CONFIG/DELETE_REPORT_CARD_CONFIG_FAILURE'

export type DeleteReportCardConfigRequest = GenericActionWithType<
  typeof DELETE_REPORT_CARD_CONFIG_REQUEST
>

export type DeleteReportCardConfigSuccess = GenericActionWithType<
  typeof DELETE_REPORT_CARD_CONFIG_SUCCESS
>

export type DeleteReportCardConfigFailure = GenericActionWithType<
  typeof DELETE_REPORT_CARD_CONFIG_FAILURE,
  ErrorPayload
>

export type DeleteReportCardConfigAction =
  | DeleteReportCardConfigRequest
  | DeleteReportCardConfigSuccess
  | DeleteReportCardConfigFailure

// Get subjects from different classrooms and validate they overlap perfectly
export const GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_REQUEST =
  'REPORT_CARD/CONFIG/GET_AND_VALIDATE_SUBJECTS_REQUEST'
export const GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_SUCCESS =
  'REPORT_CARD/CONFIG/GET_AND_VALIDATE_SUBJECTS_SUCCESS'
export const GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_FAILURE =
  'REPORT_CARD/CONFIG/GET_AND_VALIDATE_SUBJECTS_FAILURE'

export type GetAndValidateClassroomsSubjectsRequest = GenericActionWithType<
  typeof GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_REQUEST
>

export type GetAndValidateClassroomsSubjectsSuccess = GenericActionWithType<
  typeof GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_SUCCESS,
  { valid: boolean; subjects: ReportCardSubject[] }
>

export type GetAndValidateClassroomsSubjectsFailure = GenericActionWithType<
  typeof GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_FAILURE,
  ErrorPayload
>

export type GetAndValidateClassroomsSubjectsAction =
  | GetAndValidateClassroomsSubjectsRequest
  | GetAndValidateClassroomsSubjectsSuccess
  | GetAndValidateClassroomsSubjectsFailure
