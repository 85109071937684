import React from 'react'
import { Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Badge from './badge'
import Button from './button'
import classNames from 'clsx'
import chroma from 'chroma-js'

const useStyles = makeStyles((theme) => ({
  root: ({ selected, color, all }) => ({
    width: 'fit-content',
    minWidth: 120,
    border: `1px solid ${selected ? color : '#D8DBE8'}`,
    borderRadius: '8px',
    alignItems: 'center',
    transition: 'all 100ms',
    ...(selected && { background: color }),
    '&:hover': selected
      ? {
          border: `1px solid ${color} !important`,
          background: chroma(color).alpha(0.7).hex(),
        }
      : {
          border: `1px solid ${color} !important`,
          background: chroma(color).alpha(0.15).hex(),
        },
  }),
  rootDelayed: {
    border: '1px solid #F45C3A !important',
  },
  rootAll: ({ selected }) => ({
    height: 70,
    display: 'flex',
    border: '1px solid #D8DBE8',
    ...(selected && { background: '#D8DBE8' }),
  }),
  line: ({ selected, color }) => ({
    width: 4,
    height: 56,
    borderRadius: 10,
    background: selected ? 'white' : color,
  }),
  button: ({ selected, color }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 7,
    fontWeight: 'normal',
    transition: 'all 10ms',
    color: 'inherit !important',
  }),
  subjectContainer: ({ all }) => ({
    margin: all ? 0 : '0px 8px',
    textAlign: 'left',
  }),
  subject: ({ selected, all }) => ({
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '21px',
    ...(selected && !all && { color: 'white' }),
  }),
  badgeContainer: {
    marginTop: 4.5,
    display: 'flex',
    '& :not(:last-child)': {
      marginRight: 4,
    },
  },
  badge: {
    background: '#EDEFF8',
    color: theme.palette.text.primary,
    borderRadius: 20,
    fontSize: 14,
    lineHeight: '18px',
    height: 24,
    width: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      paddingRight: 5,
    },
  },
  warningBadge: {
    background: 'linear-gradient(0deg, rgba(244, 92, 58, 0.1), rgba(244, 92, 58, 0.1)), #FFFFFF',
    color: '#F45C3A',
    fontWeight: 'bold',
  },
  happyBadge: {
    width: 26,
    background: 'linear-gradient(0deg, rgba(80, 209, 116, 0.2), rgba(80, 209, 116, 0.2)), #FFFFFF;',
  },
}))

export default function SubjectCard(props) {
  const { subject, all, delayed, pending, selected, onClick } = props
  const { name, color } = subject

  const classes = useStyles({ selected, color: color || '#D8DBE8', all })

  return (
    <Paper
      elevation={0}
      classes={{
        root: classNames(
          classes.root,
          { [classes.rootDelayed]: delayed > 0 && !selected },
          { [classes.rootAll]: all }
        ),
      }}
    >
      <Button className={classes.button} variant="text" onClick={onClick}>
        {!all && <div className={classes.line} />}
        <div className={classes.subjectContainer}>
          <Typography className={classes.subject}>{name}</Typography>
          {!all && (
            <div className={classes.badgeContainer}>
              {pending > 0 && <Badge emoji="✍" description={pending} />}
              {delayed > 0 && (
                <Badge emoji=":warning:" description={delayed} className={classes.warningBadge} />
              )}
              {delayed === 0 && pending === 0 && (
                <Badge emoji="😊" className={classes.happyBadge} />
              )}
            </div>
          )}
        </div>
      </Button>
    </Paper>
  )
}

SubjectCard.defaultProps = {
  all: false,
  delayed: 0,
  pending: 0,
  selected: false,
}
