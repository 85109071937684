import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type {
  MessageMarkAsReadState,
  MessageMarkAsReadActions,
} from '@/sections/teacher/bulletin/types/messageMarkAsRead'
import { MESSAGE_MARK_AS_READ_TYPE } from '@/sections/teacher/bulletin/types/messageMarkAsRead'

const initialState: MessageMarkAsReadState = {
  loading: false,
  status: REQUEST_STATUS.IDLE,
  error: null,
}

export default function messagesMarkAsReadReducer(
  state = initialState,
  action: MessageMarkAsReadActions
): MessageMarkAsReadState {
  switch (action.type) {
    case MESSAGE_MARK_AS_READ_TYPE.MARK_AS_READ: {
      return {
        ...state,
        loading: true,
        status: REQUEST_STATUS.LOADING,
        error: null,
      }
    }
    case MESSAGE_MARK_AS_READ_TYPE.MARK_AS_READ_SUCCESS: {
      return {
        ...state,
        loading: false,
        status: REQUEST_STATUS.SUCCESS,
      }
    }
    case MESSAGE_MARK_AS_READ_TYPE.MARK_AS_READ_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        loading: false,
        status: REQUEST_STATUS.FAILURE,
        error,
      }
    }
    default:
      return state
  }
}
