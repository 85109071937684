import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/management/types/organizations/deleteOrganizationModal'

const initialState: Types.DeleteOrganizationModalState = {
  isOpenModal: false,
  selectedOrganization: {
    id: 0,
    name: '',
  },
  status: REQUEST_STATUS.IDLE,
}

const deleteOrganizationModalReducer = (
  state: Types.DeleteOrganizationModalState = initialState,
  action: Types.DeleteOrganizationModalAction
): Types.DeleteOrganizationModalState => {
  switch (action.type) {
    case Types.DELETE_ORGANIZATION_MODAL_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isOpenModal: true,
        selectedOrganization: action.payload,
      }
    }
    case Types.DELETE_ORGANIZATION_MODAL_TYPES.CLEAN_MODAL: {
      return initialState
    }
    case Types.DELETE_ORGANIZATION_MODAL_TYPES.SCHEDULE_ORG_DELETION_BY_DATE_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.DELETE_ORGANIZATION_MODAL_TYPES.SCHEDULE_ORG_DELETION_BY_DATE_SUCCESS: {
      return initialState
    }
    case Types.DELETE_ORGANIZATION_MODAL_TYPES.SCHEDULE_ORG_DELETION_BY_DATE_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    default:
      return state
  }
}

export default deleteOrganizationModalReducer
