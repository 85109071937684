import * as React from 'react'
import type { SVGProps } from 'react'
const SvgUnion = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 7.74h4.87M9.565 5 12 7.74l-2.435 2.738"
      stroke="#42B3FF"
      strokeWidth={1.826}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgUnion
