import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/content-creator/sequenceDrawer/types/entities'

const initialState: Types.EntitiesState = {
  data: [],
  status: REQUEST_STATUS.IDLE,
}

const entitiesReducer = (
  state: Types.EntitiesState = initialState,
  action: Types.EntitiesAction
): Types.EntitiesState => {
  switch (action.type) {
    case Types.ENTITIES_TYPES.ADD_ENTITY: {
      const { newEntity } = action.payload

      return {
        ...state,
        data: [...state.data, newEntity],
      }
    }
    case Types.ENTITIES_TYPES.REMOVE_ENTITY: {
      const { indexToRemove } = action.payload
      const newEntities = state.data.filter((entity, i) => i !== indexToRemove)

      return {
        ...state,
        data: newEntities,
      }
    }
    case Types.ENTITIES_TYPES.EDIT_UNIT: {
      const { newTitle, indexToEdit } = action.payload

      const newEntities = state.data.map((entity, index) => {
        if (index !== indexToEdit) return entity

        return {
          ...entity,
          title: newTitle,
        }
      })

      return {
        ...state,
        data: newEntities,
      }
    }
    case Types.ENTITIES_TYPES.SORT_ENTITIES: {
      return {
        ...state,
        data: action.payload.newSortedEntities,
      }
    }
    case Types.ENTITIES_TYPES.CLEAN_ENTITIES: {
      return initialState
    }
    case Types.ENTITIES_TYPES.ADD_ENTITIES: {
      const { newEntities } = action.payload

      return {
        ...state,
        data: [...state.data, ...newEntities],
      }
    }
    case Types.ENTITIES_TYPES.REMOVE_ENTITIES: {
      const { entitiesToRemove } = action.payload

      return {
        ...state,
        data: state.data.filter((entity) => {
          const isIncludedInEntitiesToRemove = entitiesToRemove.some((ent) => ent.id === entity.id)
          return !isIncludedInEntitiesToRemove
        }),
      }
    }
    case Types.ENTITIES_TYPES.SAVE_ENTITIES_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.ENTITIES_TYPES.SAVE_ENTITIES_SUCCESS: {
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
      }
    }
    case Types.ENTITIES_TYPES.SAVE_ENTITIES_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    default:
      return state
  }
}

export default entitiesReducer
