import type {
  Channel,
  ChannelType,
  CleanMessageCount,
  CloseChat,
  GenericChannel,
  GetChannelByActivityIDSuccess,
  GetChannelByActivityIDOptions,
  GetChannelListSuccess,
  GetMessageListSuccess,
  GoToChatOptions,
  Message,
  OpenChat,
  SetNewMessageInActualChat,
  ShowNotExistingChannel,
  SocketMessage,
  User,
} from '@/sections/chat/types/chat'
import {
  ADD_NEW_MESSAGE,
  CHANNEL_BY_ACTIVITY_ID_REQUEST,
  CHANNEL_BY_ACTIVITY_ID_SUCCESS,
  CHANNEL_LIST_FAILURE,
  CHANNEL_LIST_REQUEST,
  CHANNEL_LIST_SUCCESS,
  CHANNEL_NOT_FOUND,
  CLEAN_MESSAGE_COUNT,
  MESSAGE_LIST_FAILURE,
  MESSAGE_LIST_REQUEST,
  MESSAGE_LIST_SUCCESS,
  messageTypes,
  NEW_MESSAGE,
  SEND_MESSAGE,
  SEND_VIDEOCALL_INVITE,
  SET_ACTIVE_CHAT,
  SET_CHAT_TYPE,
  SET_CLOSE,
  SET_NEW_MESSAGE_IN_ACTUAL_CHAT,
  SET_OPEN,
  SHOW_NOT_EXISTING_CHANNEL,
} from '@/sections/chat/types/chat'
import { apiPrivate } from '@/api'
import { chatUrl } from '@aula/config'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import type { AppThunk } from '@/state/thunk'
import {
  addNewChannel,
  findChannel,
  formatChannel,
  formatChannels,
  getMessageParams,
  getSocketMessageParams,
  isActivityChannel,
  reconcileChannels,
  updateActiveChat,
} from '@/sections/chat/utils'
import { isStudentEquivalent, isTeacherEquivalent } from '@/legacy/rolesManagement'
import { ChatSocket, getConnectedSocket } from '@/socketConnections'
import moment from 'moment'
import { isEmpty } from 'ramda'
import { Types } from '@/sections/chat/constants'
import { API } from '@/api/lms'
import type { TFunction } from 'i18next'
import snakecaseKeys from 'snakecase-keys'

let messageCount = 0

const pickChannelsForStudent = (channels) => {
  for (let i = 0; i < channels.length; i++) {
    if (channels[i].subjects && channels[i].subjects.length > 0) return channels[i].subjects
  }
  return []
}

const actions = {
  setOpen: (): OpenChat => ({ type: SET_OPEN }),
  setClose: (): CloseChat => ({ type: SET_CLOSE }),

  setChatType:
    (type: string): AppThunk =>
    async (dispatch) => {
      await dispatch({ type: SET_CHAT_TYPE, payload: { type } })
      await dispatch(actions.getMessageList())
    },

  getChannelList:
    (role: string): AppThunk<void> =>
    (dispatch, getState) => {
      dispatch({ type: CHANNEL_LIST_REQUEST })

      return API.Chat.getChannels()
        .then((response) => {
          const channels = formatChannels(response.data.description.channels)
          const channelsByRole = isTeacherEquivalent(role)
            ? channels
            : pickChannelsForStudent(channels)
          const oldChannels = getState().chat.channels
          const conciledChannels = isEmpty(oldChannels)
            ? channelsByRole
            : reconcileChannels(channelsByRole, oldChannels)
          dispatch(actions.getChannelListSuccess(conciledChannels))
          if (isStudentEquivalent(role) && channelsByRole.length > 0) {
            dispatch(actions.setActiveChat(channelsByRole[0], channelsByRole))
          }
        })
        .catch((error) => {
          handleErrorsWithAction(error, CHANNEL_LIST_FAILURE, dispatch)
        })
    },

  getChannelListSuccess: (channels: GenericChannel): GetChannelListSuccess => ({
    type: CHANNEL_LIST_SUCCESS,
    payload: {
      channels,
    },
  }),

  getChannelByActivityID:
    (id: number, options: GetChannelByActivityIDOptions): AppThunk<void> =>
    async (dispatch, getState) => {
      const { fromNewModifyActivity = false } = options
      const setActiveChat = !fromNewModifyActivity

      dispatch({ type: CHANNEL_BY_ACTIVITY_ID_REQUEST })

      return API.Chat.getChannelByActivityID(id)
        .then((response) => {
          const newChannel = formatChannel(response.data.description.channel)
          const role = getState().user.role
          const newChannelByRole = isTeacherEquivalent(role) ? newChannel : newChannel.subjects[0]

          const channels = getState().chat.channels
          const conciledChannels = isEmpty(channels)
            ? [newChannelByRole]
            : addNewChannel(channels, newChannelByRole)

          dispatch(actions.getChannelByActivityIDSuccess(conciledChannels))

          if (setActiveChat) {
            const activityChannel = isTeacherEquivalent(role)
              ? newChannelByRole.subjects[0].activities[0]
              : newChannelByRole.activities[0]
            dispatch(actions.setActiveChat(activityChannel, conciledChannels))

            const key = activityChannel.channels[0]?.key
            if (key) dispatch(actions.showNotExistingChannel(key))
          }
        })
        .catch((error) => {
          handleErrorsWithAction(error, CHANNEL_LIST_FAILURE, dispatch)
        })
    },

  getChannelByActivityIDSuccess: (channels: GenericChannel): GetChannelByActivityIDSuccess => ({
    type: CHANNEL_BY_ACTIVITY_ID_SUCCESS,
    payload: {
      channels,
    },
  }),

  getMessageList: (): AppThunk<void> => (dispatch, getState) => {
    dispatch({ type: MESSAGE_LIST_REQUEST })

    const state = getState()

    const { id } = state.user.user

    const { selectedChat, type } = state.chat

    const params = getMessageParams(selectedChat, type, id)

    return apiPrivate
      .get(chatUrl + `/v1/messages`, { params })
      .then((response) => {
        const messages = response.data.messages
        dispatch(actions.getMessageListSuccess(messages))
      })
      .catch((error) => {
        handleErrorsWithAction(error, MESSAGE_LIST_FAILURE, dispatch)
      })
  },

  getMessageListSuccess: (messages: Message[]): GetMessageListSuccess => ({
    type: MESSAGE_LIST_SUCCESS,
    payload: {
      messages,
    },
  }),

  setActiveChat:
    (item: Channel, channels: GenericChannel): AppThunk<void> =>
    async (dispatch) => {
      const updatedChannels = updateActiveChat(item, channels)
      dispatch({ type: SET_ACTIVE_CHAT, payload: { item, channels: updatedChannels } })
      if (isActivityChannel(item)) {
        const key = item.channels[0].key
        dispatch(actions.cleanMessageCount(key))
        dispatch(actions.getMessageList())
      }
    },

  sendMessage:
    (message: string, channel: Channel, type: ChannelType, user: User): AppThunk<void> =>
    async (dispatch) => {
      const localMessage = { message, date: moment().format(), user }

      dispatch({ type: SEND_MESSAGE, payload: { message: localMessage } })

      const socket = getConnectedSocket(ChatSocket)
      const params = snakecaseKeys(getSocketMessageParams(channel, type, user.id), { deep: true })
      await socket.emit('SEND_MESSAGE', { ...params, message, id: messageCount })

      messageCount++
    },

  sendVideoCallInvite:
    (roomName: string, channel: Channel, type: ChannelType, user: User, t): AppThunk<void> =>
    async (dispatch) => {
      const localVideoCall = {
        message: t('chats.actions.videoCall'),
        date: moment().format(),
        user,
        payload: { room: roomName },
        type: messageTypes.VIDEO_CALL,
      }

      dispatch({ type: SEND_VIDEOCALL_INVITE, payload: { message: localVideoCall } })

      const socket = getConnectedSocket(ChatSocket)
      const params = getSocketMessageParams(channel, type, user.id)
      await socket.emit('SEND_MESSAGE', {
        ...params,
        message: t('chats.actions.videoCall'),
        id: messageCount,
        type: messageTypes.VIDEO_CALL,
        payload: { room: roomName },
      })

      messageCount++
    },

  addNewMessage:
    (message: SocketMessage): AppThunk<void> =>
    (dispatch, getState) => {
      const state = getState()

      const { activityId: activityID, key } = message.channel
      const { selectedChannel, open } = state.chat

      dispatch({ type: NEW_MESSAGE, payload: { key, activityID: parseInt(activityID, 10) } })

      if (selectedChannel === key) {
        if (open) dispatch(actions.cleanMessageCount(key))
        dispatch({ type: ADD_NEW_MESSAGE, payload: { message } })
      }
    },

  setNewMessageInActualChat: (value: boolean): SetNewMessageInActualChat => ({
    type: SET_NEW_MESSAGE_IN_ACTUAL_CHAT,
    payload: { value },
  }),

  goToChat:
    (
      id: number,
      type: ChannelType = Types.Activity,
      options: GoToChatOptions = { fromOralExam: false }
    ): AppThunk<void> =>
    (dispatch, getState) => {
      const { fromOralExam } = options
      const channels = getState().chat.channels
      const item = findChannel(id, type, channels)

      const showChat = !fromOralExam

      if (item?.channels?.[0]?.key) {
        const key = item.channels[0].key
        if (showChat) dispatch(actions.setOpen())

        dispatch(actions.setActiveChat(item, channels))
        dispatch(actions.showNotExistingChannel(key))
      } else {
        dispatch({ type: CHANNEL_NOT_FOUND, payload: { showChat } })
        dispatch(actions.getChannelByActivityID(id, { fromOralExam }))
      }
    },

  showNotExistingChannel: (key: string): ShowNotExistingChannel => ({
    type: SHOW_NOT_EXISTING_CHANNEL,
    payload: { key },
  }),

  cleanMessageCount: (key: string): CleanMessageCount => ({
    type: CLEAN_MESSAGE_COUNT,
    payload: { key },
  }),
}

export default actions
