import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import { types } from '@/sections/teacher/students/types/students'

const initialState = {
  rows: [],
  total: 0,
  loading: false,
  error: '',
  params: {
    limit: 0,
    offset: 0,
    search: '',
    orderBy: '',
    orderDir: '',
  },
  pagination: {
    current: 0,
    total: 0,
    limit: 10,
  },
  groups: [],
  selectedGroup: {
    id: 0,
    name: '',
    students: [],
  },
  groupsOpen: false,
  loadingGroups: false,
  editingGroup: false,
  creatingGroup: false,
  deleteModalOpen: false,
  deleteModalLoading: false,
  flag: false,
  exportModal: {
    status: REQUEST_STATUS.IDLE,
    isOpen: false,
  },
}

// TODO this can be improved a lot, mainly by putting the repeated code inside functions

function root(state = initialState, action) {
  switch (action.type) {
    case types.SET_OPEN_EXPORT_STUDENTS_MODAL: {
      return { ...state, exportModal: { ...state.exportModal, isOpen: true } }
    }
    case types.SET_CLOSE_EXPORT_STUDENTS_MODAL: {
      return { ...state, exportModal: { ...state.exportModal, isOpen: false } }
    }
    case types.DOWNLOAD_STUDENTS_DATA_REQUEST: {
      return { ...state, exportModal: { ...state.exportModal, status: REQUEST_STATUS.LOADING } }
    }
    case types.DOWNLOAD_STUDENTS_DATA_SUCCESS: {
      return {
        ...state,
        exportModal: { ...state.exportModal, status: REQUEST_STATUS.SUCCESS, isOpen: false },
      }
    }
    case types.DOWNLOAD_STUDENTS_DATA_FAILURE: {
      return {
        ...state,
        exportModal: { ...state.exportModal, status: REQUEST_STATUS.FAILURE, isOpen: false },
      }
    }
    case types.SET_DELETE_MODAL_OPEN:
      return { ...state, deleteModalOpen: action.payload.open, error: '' }
    case types.GET_STUDENTS_DATA_REQUEST:
      return { ...state, loading: true, error: '', params: action.payload.params }
    case types.GET_STUDENTS_DATA_SUCCESS:
      return {
        ...state,
        rows: action.payload.students,
        loading: false,
        error: '',
        pagination: { ...state.pagination, total: action.payload.total },
      }
    case types.GET_STUDENTS_DATA_FAILURE: {
      return { ...state, loading: false, error: action.payload.error }
    }
    case types.SET_PAGINATION: {
      return { ...state, pagination: action.payload.pagination }
    }
    case types.CLEAR_PARAMS: {
      return { ...state, params: initialState.params, pagination: initialState.pagination }
    }
    default:
      return state
  }
}

export default root
