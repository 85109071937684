import type { GenericActionWithType } from '@/legacy/redux/utils'
import type { AlreadyConfiguredReportCard } from './entities'
import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type { ListClassroomStudentsAction, Student } from '@/legacy/redux/types/students'
import type { GetSubjectsAsTeacherAction, Subject } from '@/legacy/redux/types/subjects'
import type { Pages } from '@/sections/content-creator/dashboard/types/dashboard'
import type { ReportCardConfigScoresState } from '@/sections/report-card/config/types/stepperModalSteps/scores'
import type { CreatedReportCardPeriod } from './entities'
import type { GetClassroomRole } from '@/legacy/redux/types/classrooms'
import type { IndividualRole } from '@/legacy/roles'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { GetOrganizationReportCardsAction } from '@/sections/report-card/config/types/apiInteractions'

export interface ReportCardGradingMainViewState {
  config: {
    status: REQUEST_STATUS
    classroomConfigStatus: REQUEST_STATUS
    configFetched: boolean
  }
  header: string
  periods: CreatedReportCardPeriod[]
  selectedPeriodId: number | null
  reportCardId: number
  error: string
  tabs: TabMap
  selectedAbsenceSubjectId: number
  classroomStudents: Student[]
  classroomStudentsPagination: Pages
  loadingClassroomStudents: boolean
  allSubjects: Subject[]
  ownSubjects: Subject[]
  loadingAllSubjects: boolean
  loadingOwnSubjects: boolean
  loadingClassroomRole: boolean
  classroomRole: IndividualRole | null
}

export type TabMap = {
  [Property in keyof ReportCardConfigScoresState]: Tab
}

export type Tab = {
  available: boolean
  name: string
}

export const FIND_CLASSROOM_REPORT_CARD_CONFIG_REQUEST =
  'REPORT_CARD/GRADING/FIND_CLASSROOM_REPORT_CARD_CONFIG_REQUEST'
export const FIND_CLASSROOM_REPORT_CARD_CONFIG_SUCCESS =
  'REPORT_CARD/GRADING/FIND_CLASSROOM_REPORT_CARD_CONFIG_SUCCESS'
export const FIND_CLASSROOM_REPORT_CARD_CONFIG_FAILURE =
  'REPORT_CARD/GRADING/FIND_CLASSROOM_REPORT_CARD_CONFIG_FAILURE'

export const CLEAR_CONFIG_STATE = 'REPORT_CARD/GRADING/CLEAR_CONFIG_STATE'

export type FindClassroomReportCardConfigRequest = GenericActionWithType<
  typeof FIND_CLASSROOM_REPORT_CARD_CONFIG_REQUEST
>
export type FindClassroomReportCardConfigSuccess = GenericActionWithType<
  typeof FIND_CLASSROOM_REPORT_CARD_CONFIG_SUCCESS,
  { config?: AlreadyConfiguredReportCard; found: boolean }
>
export type FindClassroomReportCardConfigFailure = GenericActionWithType<
  typeof FIND_CLASSROOM_REPORT_CARD_CONFIG_FAILURE,
  { error: string }
>

export type FindClassroomReportCardConfigAction =
  | FindClassroomReportCardConfigRequest
  | FindClassroomReportCardConfigSuccess
  | FindClassroomReportCardConfigFailure

export type ClearReportCardGradingStateAction = GenericActionWithType<typeof CLEAR_CONFIG_STATE>

export type ReportCardGradingMainViewAction =
  | ClearState
  | SetField<'selectedPeriodId', number>
  | FindClassroomReportCardConfigAction
  | ListClassroomStudentsAction
  | GetSubjectsAsTeacherAction
  | GetClassroomRole
  | GetOrganizationReportCardsAction
  | ClearReportCardGradingStateAction
