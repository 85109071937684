import type { LoginSuccess as LoginWithAnswerSuccess } from '@/sections/auth/answersLogin/types/answersLogin'
import type { LoginSuccess } from '@/sections/auth/login/types/login'
import type { GetInfoSuccess } from '@/sections/user/types'
import type { OrganizationOption } from './common'

export const OPEN_DRAWER = 'PRINCIPAL/OPEN_DRAWER'
export const CLOSE_DRAWER = 'PRINCIPAL/CLOSE_DRAWER'
export const SET_ORGANIZATION = 'PRINCIPAL/SET_ORGANIZATION'
export const SET_GLOBAL_SEARCH = 'PRINCIPAL/SET_GLOBAL_SEARCH'
export const SET_NO_PERMISSIONS_MODAL_OPEN = 'PRINCIPAL/SET_NO_PERMISSIONS_MODAL_OPEN'
export const DASHBOARD_REQUEST = 'PRINCIPAL/DASHBOARD_REQUEST'
export const DASHBOARD_SUCCESS = 'PRINCIPAL/DASHBOARD_SUCCESS'
export const DASHBOARD_FAILURE = 'PRINCIPAL/DASHBOARD_FAILURE'

export interface PrincipalState {
  open: boolean
  selected: string
  selectedOrganization: OrganizationOption | null
  lastSearchID: number
  loadingOrganizations: boolean
  loading: boolean
  classrooms: number
  organizations: number
  organizationOptions: OrganizationOption[]
  subjects: number
  users: number
  bookLicenses: number
  globalSearch: string
  error: string
  noPermissionsModalOpen: boolean
}

export interface DashboardRequest {
  type: typeof DASHBOARD_REQUEST
}

export interface DashboardSuccess {
  type: typeof DASHBOARD_SUCCESS
  payload: {
    classrooms: number
    organizations: number
    users: number
    subjects: number
    bookLicenses: number
  }
}

export interface DashboardFailure {
  type: typeof DASHBOARD_FAILURE
  payload: {
    error: string
  }
}

export interface SetGlobalSearch {
  type: typeof SET_GLOBAL_SEARCH
  payload: {
    search: string
  }
}

export interface SetOrganization {
  type: typeof SET_ORGANIZATION
  payload: {
    selectedOrganization: OrganizationOption
  }
}

export interface SetNoPermissionsModalOpen {
  type: typeof SET_NO_PERMISSIONS_MODAL_OPEN
  payload: { open: boolean }
}

export interface OpenDrawer {
  type: typeof OPEN_DRAWER
  payload: {
    selected: string
  }
}

export interface CloseDrawer {
  type: typeof CLOSE_DRAWER
}

export type PrincipalActions =
  | OpenDrawer
  | CloseDrawer
  | LoginSuccess
  | LoginWithAnswerSuccess
  | GetInfoSuccess
  | DashboardRequest
  | DashboardSuccess
  | DashboardFailure
  | SetGlobalSearch
  | SetOrganization
  | SetNoPermissionsModalOpen
