export enum REQUEST_STATUS {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  CANCELLED = 'CANCELLED',
}

export const isIdle = (currentStatus: REQUEST_STATUS) => currentStatus === REQUEST_STATUS.IDLE
export const isSuccess = (currentStatus: REQUEST_STATUS) => currentStatus === REQUEST_STATUS.SUCCESS
export const isLoading = (currentStatus: REQUEST_STATUS) => currentStatus === REQUEST_STATUS.LOADING
export const isFailure = (currentStatus: REQUEST_STATUS) => currentStatus === REQUEST_STATUS.FAILURE
export const isCanceled = (currentStatus: REQUEST_STATUS) =>
  currentStatus === REQUEST_STATUS.CANCELLED
