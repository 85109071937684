import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type {
  MessageReceiversActions,
  MessageReceiversState,
} from '@/sections/teacher/bulletin/types/messageReceivers'
import { MESSAGE_RECEIVERS_TYPE } from '@/sections/teacher/bulletin/types/messageReceivers'

const initialState: MessageReceiversState = {
  loading: false,
  status: REQUEST_STATUS.IDLE,
  error: null,
  receivers: [],
  messageId: 0,
}

export default function messageReceiversReducer(
  state = initialState,
  action: MessageReceiversActions
): MessageReceiversState {
  switch (action.type) {
    case MESSAGE_RECEIVERS_TYPE.GET_RECEIVERS: {
      return {
        ...state,
        loading: true,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case MESSAGE_RECEIVERS_TYPE.GET_RECEIVERS_SUCCESS: {
      const { receivers } = action.payload
      return {
        ...state,
        loading: false,
        status: REQUEST_STATUS.SUCCESS,
        receivers,
      }
    }
    case MESSAGE_RECEIVERS_TYPE.GET_RECEIVERS_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        loading: false,
        status: REQUEST_STATUS.FAILURE,
        error,
      }
    }
    case MESSAGE_RECEIVERS_TYPE.SET_MESSAGE_ID: {
      const { messageId } = action.payload
      return {
        ...state,
        messageId,
      }
    }
    case MESSAGE_RECEIVERS_TYPE.CLEAN_RECEIVERS: {
      return initialState
    }
    default:
      return state
  }
}
