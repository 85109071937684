import type { Moment } from 'moment'
import type { CompetencesIds } from '@/sections/report-card/common/competences'
import type { ScoreType } from '@/sections/report-card/types/common'
import type { Scale } from '@/legacy/services/api/reportCard/config/types/get'

export interface ReportCardPeriod {
  startDate: Moment
  endDate: Moment
  name: string
  id: number
  editable: boolean
  extra: boolean // Tells wether this is a support period or not
}

export interface ReportCardConfigRangeScore {
  name: string
  startRange: number
  endRange: number
  editable?: boolean
}

// Identifies every editable title on report card config
// ("notas", "competencias", "informes narrativos", "inasistencias")
export enum CardId {
  SCORES = 'scores',
  ATTITUDINAL_SCORES = 'attitudinalScores',
  COMPETENCE_SCORES = 'competenceScores',
  REVIEWS = 'reviews',
  ABSENCES = 'absences',
}

export interface ReportCardConfigScores {
  name: string
  available: boolean
  id: CardId.SCORES
  conceptOn: boolean
  numericalOn: boolean
  numericalScores: {
    subjects: OrderedSubject[]
    scale: number
    subjectIds: number[]
  }
  conceptScores: {
    subjects: OrderedSubject[]
    values: ReportCardConfigRangeScore[]
    subjectIds: number[]
    scales: Scale[]
  }
}

export interface ReportCardConfigAttitudinalScores {
  name: string
  available: boolean
  values: ReportCardConfigRangeScore[]
  id: CardId.ATTITUDINAL_SCORES
}

export interface ReportCardConfigCompetenceScores {
  name: string
  available: boolean
  values: ReportCardConfigRangeScore[]
  types: CompetencesIds[]
  id: CardId.COMPETENCE_SCORES
}

export interface ReportCardConfigReviews {
  name: string
  available: boolean
  bySubject: boolean
  id: CardId.REVIEWS
}

export interface ReportCardConfigAbsences {
  name: string
  available: boolean
  id: CardId.ABSENCES
}

export interface ConfiguredReportCard {
  id: number
  classroomId: number
  status: 'created'
  enableScores: boolean
}

export interface SubjectSubitem {
  name: string
  orderValue: number // zero indexed
}

export interface ReportCardSubject {
  name: string
  orderValue: number
  reportId: number
  scoreType: ScoreType
  subitems: SubjectSubitem[]
  subjectId: number
  editable: boolean
}

export interface OrderedSubject {
  orderValue: number
  subjectId: number
  subitems: SubjectSubitem[]
  editable: boolean
}

export interface AttitudinalItem {
  name: string
  type: ScoreType
  subitems: SubjectSubitem[]
  enabled: boolean
  orderValue: number
  editable: boolean
}
