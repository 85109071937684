import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/student/summary/types/tabs/attendances/subjects'

const initialState: Types.SubjectsState = {
  status: REQUEST_STATUS.IDLE,
  data: [],
  selectedSubject: {
    id: 0,
    name: '',
    color: '',
  },
}

const subjectsReducer = (
  state: Types.SubjectsState = initialState,
  action: Types.SubjectsAction
): Types.SubjectsState => {
  switch (action.type) {
    case Types.SUBJECTS_TYPES.GET_SUBJECTS_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.SUBJECTS_TYPES.GET_SUBJECTS_SUCCESS: {
      const { data } = action.payload

      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        data,
      }
    }
    case Types.SUBJECTS_TYPES.GET_SUBJECTS_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.SUBJECTS_TYPES.SET_SELECTED_SUBJECT: {
      const { newSelectedSubject } = action.payload

      return {
        ...state,
        selectedSubject: newSelectedSubject,
      }
    }
    case Types.SUBJECTS_TYPES.CLEAN_SUBJECTS: {
      return initialState
    }
    default:
      return state
  }
}

export default subjectsReducer
