import React, { useState } from 'react'
import { TwitterPicker } from 'react-color'
import { TextField, Fade, Backdrop } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    zIndex: theme.zIndex.modal + 2,
  },
  inputSquare: {
    display: 'flex',
    lineHeight: '19px',
  },
  box: {
    width: 19,
    height: 19,
    marginRight: 5,
    borderRadius: 3,
    background: 'repeating-linear-gradient(45deg, #606dbc, #606dbc 6px, #465298 6px, #465298 12px)',
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  wrapper: {
    position: 'absolute',
    top: '60px',
    right: 0,
  },
  picker: {
    '& input': {
      display: 'none',
    },
    '& > div:last-child > div:nth-last-child(3)': {
      display: 'none !important',
    },
  },
}))

const InputWithColorPicker = ({ textFieldProps, pickerProps, isColorValid }) => {
  const classes = useStyles()

  const [colorPickerOpen, setColorPickerOpen] = useState(false)
  const [touched, setTouched] = useState(false)

  const colorsArray = [
    '#212c4c',
    '#4f36d6',
    '#9B00AF',
    '#E84F92',
    '#27a8ff',
    '#36b864',
    '#1DC837',
    '#1b998b',
    '#f8c630',
    '#f3a712',
    '#f46036',
    '#c91835',
  ]

  const colors = pickerProps.colors ? pickerProps.colors : colorsArray

  const handlePickingColor = (color) => {
    pickerProps.onChangeComplete(color)
    setTouched(true)
    setColorPickerOpen(false)
  }

  const handleInputChange = (e) => {
    textFieldProps.onChange(e)
    if (e.target.value.length > 4) setTouched(true)
  }

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={colorPickerOpen}
        onClick={() => setColorPickerOpen(false)}
      />
      <div className={classes.container}>
        <TextField
          {...textFieldProps}
          onChange={(e) => handleInputChange(e)}
          onFocus={() => setColorPickerOpen(true)}
          error={!isColorValid(pickerProps.color) && touched}
          helperText={!isColorValid(pickerProps.color) && touched ? 'Color inválido' : ''}
          InputProps={{
            startAdornment: (
              <div className={classes.inputSquare} position="start">
                <div
                  className={classes.box}
                  style={{ background: isColorValid ? pickerProps.color : 'initial' }}
                />
              </div>
            ),
          }}
        />
        <Fade in={colorPickerOpen}>
          <div className={classes.wrapper}>
            <TwitterPicker
              {...pickerProps}
              onChangeComplete={(color) => handlePickingColor(color)}
              className={classes.picker}
              triangle="top-right"
              colors={colors}
              width={pickerProps.width ? pickerProps.width : '240px'}
            />
          </div>
        </Fade>
      </div>
    </>
  )
}

export default InputWithColorPicker
