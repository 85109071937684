import type { Message } from '@/api/bulletin'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { Pagination } from '@/sections/teacher/bulletin/types/messages'

export interface SchoolRecordsTabState {
  status: REQUEST_STATUS
  error: Error | null
  messages: Message[]
  pagination: Pagination
  selectedMessage: Message
  currentStep: SCHOOL_RECORDS_STEPS
}

export enum SCHOOL_RECORDS_STEPS {
  INITIAL = 0,
  FIRST = 1,
}

export enum SCHOOL_RECORDS_TAB_TYPES {
  RESET_TAB = 'STUDENT/SUMMARY/TABS/SCHOOL_RECORDS/RESET_TAB',
  SET_SELECTED_MESSAGE = 'STUDENT/SUMMARY/TABS/SCHOOL_RECORDS/SET_SELECTED_MESSAGE',
  SET_CURRENT_STEP = 'STUDENT/SUMMARY/TABS/SCHOOL_RECORDS/SET_CURRENT_STEP',
  GET_MESSAGES_REQUEST = 'STUDENT/SUMMARY/TABS/SCHOOL_RECORDS/GET_MESSAGES_REQUEST',
  GET_MESSAGES_SUCCESS = 'STUDENT/SUMMARY/TABS/SCHOOL_RECORDS/GET_MESSAGES_SUCCESS',
  GET_MESSAGES_FAILURE = 'STUDENT/SUMMARY/TABS/SCHOOL_RECORDS/GET_MESSAGES_FAILURE',
}

export type ResetTabAction = {
  type: SCHOOL_RECORDS_TAB_TYPES.RESET_TAB
}

export type SetSelectedMessageAction = {
  type: SCHOOL_RECORDS_TAB_TYPES.SET_SELECTED_MESSAGE
  payload: {
    message: Message
  }
}

export type SetCurrentStepAction = {
  type: SCHOOL_RECORDS_TAB_TYPES.SET_CURRENT_STEP
  payload: {
    currentStep: SCHOOL_RECORDS_STEPS
  }
}

export type GetMessagesRequestAction = {
  type: SCHOOL_RECORDS_TAB_TYPES.GET_MESSAGES_REQUEST
  payload: {
    page: number
    limit: number
    offset: number
  }
}

export type GetMessagesSuccessAction = {
  type: SCHOOL_RECORDS_TAB_TYPES.GET_MESSAGES_SUCCESS
  payload: {
    total: number
    messages: Message[]
  }
}

export type GetMessagesFailureAction = {
  type: SCHOOL_RECORDS_TAB_TYPES.GET_MESSAGES_FAILURE
  payload: {
    error: Error
  }
}

export type SchoolRecordsTabAction =
  | ResetTabAction
  | GetMessagesRequestAction
  | GetMessagesSuccessAction
  | GetMessagesFailureAction
  | SetSelectedMessageAction
  | SetCurrentStepAction
