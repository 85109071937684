import type { ManagementClassroom } from '@/api/lms/classrooms'
import type { IndividualRole } from '@/legacy/roles'
import type {
  ErrorPayload,
  GenericAction,
  GenericActionWithType,
  GenericPagination,
  GenericPayload,
} from '@/legacy/redux/utils'
import type { Subject } from './subjects'

export interface Organization {
  id: number
  name: string
}

// Get classroom role
export const GET_CLASSROOM_ROLE_REQUEST = 'CLASSROOMS/GET_CLASSROOM_ROLE_REQUEST'
export const GET_CLASSROOM_ROLE_SUCCESS = 'CLASSROOMS/GET_CLASSROOM_ROLE_SUCCESS'
export const GET_CLASSROOM_ROLE_FAILURE = 'CLASSROOMS/GET_CLASSROOM_ROLE_FAILURE'

export type GetClassroomRoleRequest = GenericActionWithType<typeof GET_CLASSROOM_ROLE_REQUEST>
export type GetClassroomRoleSuccess = GenericActionWithType<
  typeof GET_CLASSROOM_ROLE_SUCCESS,
  { role: IndividualRole }
>
export type GetClassroomRoleFailure = GenericActionWithType<
  typeof GET_CLASSROOM_ROLE_FAILURE,
  ErrorPayload
>

export type GetClassroomRole =
  | GetClassroomRoleRequest
  | GetClassroomRoleSuccess
  | GetClassroomRoleFailure

// Role: PRINCIPAL
export type PrincipalClassroom = {
  id: number
  stage: string
  grade: string
  division: string
  shift: string
  year: number
  organizationId: number
  organizationName: string
  fullName: string
  academicPeriod: string
}

// GET
export const GET_CLASSROOMS_AS_PRINCIPAL_REQUEST = 'CLASSROOMS/GET_CLASSROOMS_AS_PRINCIPAL_REQUEST'
export const GET_CLASSROOMS_AS_PRINCIPAL_SUCCESS = 'CLASSROOMS/GET_CLASSROOMS_AS_PRINCIPAL_SUCCESS'
export const GET_CLASSROOMS_AS_PRINCIPAL_FAILURE = 'CLASSROOMS/GET_CLASSROOMS_AS_PRINCIPAL_FAILURE'

export const LIST_CLASSROOMS_AS_PRINCIPAL_REQUEST =
  'CLASSROOMS/LIST_CLASSROOMS_AS_PRINCIPAL_REQUEST'
export const LIST_CLASSROOMS_AS_PRINCIPAL_SUCCESS =
  'CLASSROOMS/LIST_CLASSROOMS_AS_PRINCIPAL_SUCCESS'
export const LIST_CLASSROOMS_AS_PRINCIPAL_FAILURE =
  'CLASSROOMS/LIST_CLASSROOMS_AS_PRINCIPAL_FAILURE'

// Create
export const CREATE_CLASSROOM_AS_PRINCIPAL_REQUEST =
  'CLASSROOMS/CREATE_CLASSROOMS_AS_PRINCIPAL_REQUEST'
export const CREATE_CLASSROOM_AS_PRINCIPAL_SUCCESS =
  'CLASSROOMS/CREATE_CLASSROOMS_AS_PRINCIPAL_SUCCESS'
export const CREATE_CLASSROOM_AS_PRINCIPAL_FAILURE =
  'CLASSROOMS/CREATE_CLASSROOMS_AS_PRINCIPAL_FAILURE'

// Edit
export const EDIT_CLASSROOM_AS_PRINCIPAL_REQUEST = 'CLASSROOMS/EDIT_CLASSROOMS_AS_PRINCIPAL_REQUEST'
export const EDIT_CLASSROOM_AS_PRINCIPAL_SUCCESS = 'CLASSROOMS/EDIT_CLASSROOMS_AS_PRINCIPAL_SUCCESS'
export const EDIT_CLASSROOM_AS_PRINCIPAL_FAILURE = 'CLASSROOMS/EDIT_CLASSROOMS_AS_PRINCIPAL_FAILURE'

interface GetClassroomsAsPrincipalRequestPayload extends GenericPayload {
  page: number
  rowsPerPage: number
  migrated?: boolean
}

// Get
export interface GetClassroomsAsPrincipalRequest extends GenericAction {
  type: typeof GET_CLASSROOMS_AS_PRINCIPAL_REQUEST
  payload: GetClassroomsAsPrincipalRequestPayload
}

interface GetClassroomsAsPrincipalSuccessPayload extends GenericPayload {
  classrooms: PrincipalClassroom[]
  count: number
  migrated?: boolean
}

export interface GetClassroomsAsPrincipalSuccess {
  type: typeof GET_CLASSROOMS_AS_PRINCIPAL_SUCCESS
  payload: GetClassroomsAsPrincipalSuccessPayload
}

export interface GetClassroomsAsPrincipalFailure extends GenericAction {
  type: typeof GET_CLASSROOMS_AS_PRINCIPAL_FAILURE
  payload: ErrorPayload
}

export type GetClassroomsAsPrincipal =
  | GetClassroomsAsPrincipalRequest
  | GetClassroomsAsPrincipalSuccess
  | GetClassroomsAsPrincipalFailure

// List
export type ListClassroomsAsPrincipalRequest = GenericActionWithType<
  typeof LIST_CLASSROOMS_AS_PRINCIPAL_REQUEST,
  GenericPagination
>
export type ListClassroomsAsPrincipalSuccess = GenericActionWithType<
  typeof LIST_CLASSROOMS_AS_PRINCIPAL_SUCCESS,
  { classrooms: ManagementClassroom[]; count: number }
>
export type ListClassroomsAsPrincipalFailure = GenericActionWithType<
  typeof LIST_CLASSROOMS_AS_PRINCIPAL_FAILURE,
  ErrorPayload
>

export type ListClassroomsAsPrincipalAction =
  | ListClassroomsAsPrincipalRequest
  | ListClassroomsAsPrincipalSuccess
  | ListClassroomsAsPrincipalFailure

// Create
export type CreateClassroomAsPrincipalRequest = GenericActionWithType<
  typeof CREATE_CLASSROOM_AS_PRINCIPAL_REQUEST
>
export type CreateClassroomAsPrincipalSuccess = GenericActionWithType<
  typeof CREATE_CLASSROOM_AS_PRINCIPAL_SUCCESS
>
export type CreateClassroomAsPrincipalFailure = GenericActionWithType<
  typeof CREATE_CLASSROOM_AS_PRINCIPAL_FAILURE,
  ErrorPayload
>
export type CreateClassroomAsPrincipalAction =
  | CreateClassroomAsPrincipalRequest
  | CreateClassroomAsPrincipalSuccess
  | CreateClassroomAsPrincipalFailure

// Edit
export type EditClassroomAsPrincipalRequest = GenericActionWithType<
  typeof EDIT_CLASSROOM_AS_PRINCIPAL_REQUEST
>
export type EditClassroomAsPrincipalSuccess = GenericActionWithType<
  typeof EDIT_CLASSROOM_AS_PRINCIPAL_SUCCESS
>
export type EditClassroomAsPrincipalFailure = GenericActionWithType<
  typeof EDIT_CLASSROOM_AS_PRINCIPAL_FAILURE,
  ErrorPayload
>
export type EditClassroomAsPrincipalAction =
  | EditClassroomAsPrincipalRequest
  | EditClassroomAsPrincipalSuccess
  | EditClassroomAsPrincipalFailure

export type PrincipalClassroomsAction =
  | ListClassroomsAsPrincipalAction
  | CreateClassroomAsPrincipalAction
  | EditClassroomAsPrincipalAction

// Role: ADMIN

// GET
export const LIST_CLASSROOMS_AS_ADMIN_REQUEST = 'CLASSROOMS/LIST_CLASSROOMS_AS_ADMIN_REQUEST'
export const LIST_CLASSROOMS_AS_ADMIN_SUCCESS = 'CLASSROOMS/LIST_CLASSROOMS_AS_ADMIN_SUCCESS'
export const LIST_CLASSROOMS_AS_ADMIN_FAILURE = 'CLASSROOMS/LIST_CLASSROOMS_AS_ADMIN_FAILURE'

// Create
export const CREATE_CLASSROOM_AS_ADMIN_REQUEST = 'CLASSROOMS/CREATE_CLASSROOMS_AS_ADMIN_REQUEST'
export const CREATE_CLASSROOM_AS_ADMIN_SUCCESS = 'CLASSROOMS/CREATE_CLASSROOMS_AS_ADMIN_SUCCESS'
export const CREATE_CLASSROOM_AS_ADMIN_FAILURE = 'CLASSROOMS/CREATE_CLASSROOMS_AS_ADMIN_FAILURE'

// Edit
export const EDIT_CLASSROOM_AS_ADMIN_REQUEST = 'CLASSROOMS/EDIT_CLASSROOMS_AS_ADMIN_REQUEST'
export const EDIT_CLASSROOM_AS_ADMIN_SUCCESS = 'CLASSROOMS/EDIT_CLASSROOMS_AS_ADMIN_SUCCESS'
export const EDIT_CLASSROOM_AS_ADMIN_FAILURE = 'CLASSROOMS/EDIT_CLASSROOMS_AS_ADMIN_FAILURE'

// GET
export type ListClassroomsAsAdminRequest = GenericActionWithType<
  typeof LIST_CLASSROOMS_AS_ADMIN_REQUEST,
  GenericPagination
>
export type ListClassroomsAsAdminSuccess = GenericActionWithType<
  typeof LIST_CLASSROOMS_AS_ADMIN_SUCCESS,
  { classrooms: ManagementClassroom[]; count: number }
>
export type ListClassroomsAsAdminFailure = GenericActionWithType<
  typeof LIST_CLASSROOMS_AS_ADMIN_FAILURE,
  ErrorPayload
>
export type ListClassroomsAsAdminAction =
  | ListClassroomsAsAdminRequest
  | ListClassroomsAsAdminSuccess
  | ListClassroomsAsAdminFailure

// Create
export type CreateClassroomAsAdminRequest = GenericActionWithType<
  typeof CREATE_CLASSROOM_AS_ADMIN_REQUEST
>
export type CreateClassroomAsAdminSuccess = GenericActionWithType<
  typeof CREATE_CLASSROOM_AS_ADMIN_SUCCESS
>
export type CreateClassroomAsAdminFailure = GenericActionWithType<
  typeof CREATE_CLASSROOM_AS_ADMIN_FAILURE,
  ErrorPayload
>
export type CreateClassroomAsAdminAction =
  | CreateClassroomAsAdminRequest
  | CreateClassroomAsAdminSuccess
  | CreateClassroomAsAdminFailure

// Edit
export type EditClassroomAsAdminRequest = GenericActionWithType<
  typeof EDIT_CLASSROOM_AS_ADMIN_REQUEST
>
export type EditClassroomAsAdminSuccess = GenericActionWithType<
  typeof EDIT_CLASSROOM_AS_ADMIN_SUCCESS
>
export type EditClassroomAsAdminFailure = GenericActionWithType<
  typeof EDIT_CLASSROOM_AS_ADMIN_FAILURE,
  ErrorPayload
>

export const GET_CLASSROOM_SUBJECTS_AS_MGMT_REQUEST =
  'CLASSROOMS/GET_CLASSROOM_SUBJECTS_AS_MGMT_REQUEST'
export const GET_CLASSROOM_SUBJECTS_AS_MGMT_SUCCESS =
  'CLASSROOMS/GET_CLASSROOM_SUBJECTS_AS_MGMT_SUCCESS'
export const GET_CLASSROOM_SUBJECTS_AS_MGMT_FAILURE =
  'CLASSROOMS/GET_CLASSROOM_SUBJECTS_AS_MGMT_FAILURE'

export type GetClassroomSubjectsAsMgmtRequest = GenericActionWithType<
  typeof GET_CLASSROOM_SUBJECTS_AS_MGMT_REQUEST
>
export type GetClassroomSubjectsAsMgmtSuccess = GenericActionWithType<
  typeof GET_CLASSROOM_SUBJECTS_AS_MGMT_SUCCESS,
  { subjects: Subject[] }
>
export type GetClassroomSubjectsAsMgmtFailure = GenericActionWithType<
  typeof GET_CLASSROOM_SUBJECTS_AS_MGMT_FAILURE,
  ErrorPayload
>

// Delete
export const DELETE_CLASSROOM_AS_MGMT_REQUEST = 'CLASSROOMS/DELETE_CLASSROOM_AS_MGMT_REQUEST'
export const DELETE_CLASSROOM_AS_MGMT_SUCCESS = 'CLASSROOMS/DELETE_CLASSROOM_AS_MGMT_SUCCESS'
export const DELETE_CLASSROOM_AS_MGMT_FAILURE = 'CLASSROOMS/DELETE_CLASSROOM_AS_MGMT_FAILURE'

export type DeleteClassroomAsMgmtRequest = GenericActionWithType<
  typeof DELETE_CLASSROOM_AS_MGMT_REQUEST
>
export type DeleteClassroomAsMgmtSuccess = GenericActionWithType<
  typeof DELETE_CLASSROOM_AS_MGMT_SUCCESS
>
export type DeleteClassroomAsMgmtFailure = GenericActionWithType<
  typeof DELETE_CLASSROOM_AS_MGMT_FAILURE,
  ErrorPayload
>

export type DeleteClassroomAsMgmtAction =
  | DeleteClassroomAsMgmtRequest
  | DeleteClassroomAsMgmtSuccess
  | DeleteClassroomAsMgmtFailure

export type GetClassroomSubjectsAsMgmtAction =
  | GetClassroomSubjectsAsMgmtRequest
  | GetClassroomSubjectsAsMgmtSuccess
  | GetClassroomSubjectsAsMgmtFailure

export type EditClassroomAsAdminAction =
  | EditClassroomAsAdminRequest
  | EditClassroomAsAdminSuccess
  | EditClassroomAsAdminFailure

export type AdminClassroomsAction =
  | ListClassroomsAsAdminAction
  | CreateClassroomAsAdminAction
  | EditClassroomAsAdminAction
