import { LOG_AS_MODAL_TYPES } from '@/sections/management/types/users/logAsModal'
import type {
  LogAsModalAction,
  LogAsModalState,
} from '@/sections/management/types/users/logAsModal'

const initialState: LogAsModalState = {
  isOpen: false,
  cosmosSelected: null,
  user: {
    firstName: '',
    lastName: '',
    id: 0,
  },
}

const logAsModalReducer = (state = initialState, action: LogAsModalAction): LogAsModalState => {
  switch (action.type) {
    case LOG_AS_MODAL_TYPES.OPEN:
      return { ...state, isOpen: true }
    case LOG_AS_MODAL_TYPES.CLOSE:
      return { ...state, isOpen: false }
    case LOG_AS_MODAL_TYPES.COSMO_SELECTED:
      return { ...state, cosmosSelected: action.payload.cosmos }
    case LOG_AS_MODAL_TYPES.USER_SELECTED: {
      return {
        ...state,
        user: {
          firstName: action.payload.user.firstName,
          lastName: action.payload.user.lastName,
          id: action.payload.user.id,
        },
      }
    }
    default:
      return state
  }
}

export default logAsModalReducer
