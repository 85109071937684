import type {
  OrganizationActions,
  OrganizationState,
} from '@/sections/eb-admin/dashboard/types/organizations'
import {
  CLEAR_PARAMS,
  GET_COUNTRIES_FAILURE,
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  ORGANIZATION_FAILURE,
  ORGANIZATION_REQUEST,
  ORGANIZATION_SUCCESS,
  SET_ADDRESS,
  SET_CITY,
  SET_COUNTRY,
  SET_FILTER,
  SET_LEGAL_REP_EMAIL,
  SET_LEGAL_REP_NAME,
  SET_LEGAL_REP_PHONE,
  SET_NAME,
  SET_OPEN,
  SET_PAGINATION,
  SET_PHONE,
  SET_SORTER,
  SET_STATE,
  SET_TYPE,
} from '@/sections/eb-admin/dashboard/types/organizations'
import { LIMIT, ORGANIZATION_DEFAULT_STATE } from '@/sections/eb-admin/dashboard/constants'

const initialState: OrganizationState = {
  countryOptions: [],
  organizations: [],
  organization: ORGANIZATION_DEFAULT_STATE,
  error: '',
  loading: false,
  loadingModify: false,
  params: {
    limit: LIMIT,
    offset: 0,
    // Column: 'date',
    // Order: 'desc',
  },
  total: 0,
  sorter: {
    // order: 'descend',
    // field: 'date',
    // columnKey: 'date',
  },
  filter: {
    paymentMethod: [],
    statusId: [],
  },
  searchFilters: [
    // {key: 'id', text: ''},
  ],
  pagination: {
    current: 1,
    total: 0,
  },
}

function root(state = initialState, action: OrganizationActions) {
  switch (action.type) {
    case CLEAR_PARAMS: {
      return { ...state, params: initialState.params, pagination: initialState.pagination }
    }
    case ORGANIZATION_REQUEST:
      return { ...state, loading: true, error: '' }
    case ORGANIZATION_SUCCESS: {
      const { organizations, total, params, resetPagination } = action.payload
      return {
        ...state,
        loading: false,
        organizations,
        total,
        params,
        open: false,
        pagination: !resetPagination ? state.pagination : { ...state.pagination, current: 1 },
      }
    }
    case ORGANIZATION_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case SET_PAGINATION: {
      return { ...state, pagination: action.payload.pagination }
    }
    case SET_SORTER: {
      return { ...state, sorter: action.payload.sorter }
    }
    case SET_FILTER: {
      return { ...state, filter: action.payload.filter }
    }
    case SET_OPEN: {
      const { open, organization } = action.payload
      return { ...state, open, organization }
    }

    case GET_COUNTRIES_REQUEST:
      return {
        ...state,
      }

    case GET_COUNTRIES_SUCCESS: {
      const { countryOptions } = action.payload
      return { ...state, countryOptions }
    }
    case GET_COUNTRIES_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      }

    case SET_NAME:
      return {
        ...state,
        organization: { ...state.organization, name: action.payload.name },
      }
    case SET_COUNTRY:
      return {
        ...state,
        organization: {
          ...state.organization,
          countryId: action.payload.countryId,
        },
      }
    case SET_STATE:
      return {
        ...state,
        organization: { ...state.organization, state: action.payload.state },
      }
    case SET_CITY:
      return {
        ...state,
        organization: { ...state.organization, city: action.payload.city },
      }
    case SET_TYPE:
      return {
        ...state,
        organization: { ...state.organization, type: action.payload.type },
      }
    case SET_ADDRESS:
      return {
        ...state,
        organization: {
          ...state.organization,
          address: action.payload.address,
        },
      }
    case SET_PHONE:
      return {
        ...state,
        organization: { ...state.organization, phone: action.payload.phone },
      }
    case SET_LEGAL_REP_NAME:
      return {
        ...state,
        organization: {
          ...state.organization,
          legalRepName: action.payload.legalRepName,
        },
      }
    case SET_LEGAL_REP_PHONE:
      return {
        ...state,
        organization: {
          ...state.organization,
          legalRepPhone: action.payload.legalRepPhone,
        },
      }
    case SET_LEGAL_REP_EMAIL:
      return {
        ...state,
        organization: {
          ...state.organization,
          legalRepEmail: action.payload.legalRepEmail,
        },
      }

    default:
      return state
  }
}

export default root
