import * as React from 'react'
import type { SVGProps } from 'react'
const SvgAnimations = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={69}
    height={69}
    viewBox="0 0 69 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect opacity={0.15} width={69} height={69} rx={6} fill="#FFB800" />
    <path
      d="M15 15.711c5.623-4.948 15.97-4.948 21.594 0h11.022a.9.9 0 0 1 .9.9v8.998a.9.9 0 0 1-.9.9H25.797c-.45 0-.675.224-.9.45l-.9.9c-.224.224-.45.449-.9.449h-2.699c-.45 0-.674-.225-.9-.45l-.899-.9c-.225-.225-.45-.45-.9-.45h-1.8a.9.9 0 0 1-.899-.9v-9.897Z"
      fill="#FFBF00"
      stroke="#C90"
    />
    <path
      d="M15 27.408a.9.9 0 0 1 .9-.9h1.8c.45 0 .674.225.899.45l.9.9c.225.225.45.45.9.45h2.699c.45 0 .675-.225.9-.45l.9-.9c.224-.225.45-.45.899-.45H50.09a.9.9 0 0 1 .9.9v8.998a.9.9 0 0 1-.9.9H29.396c-.45 0-.675.224-.9.45l-.9.9c-.224.224-.45.449-.9.449h-2.699c-.45 0-.674-.225-.9-.45l-.899-.9c-.225-.225-.45-.45-.9-.45h-1.8a.9.9 0 0 0-.899.9v10.057a.9.9 0 0 0 .9.9h1.8c.45 0 .674.225.899.45l.9.9c.225.224.45.45.9.45h2.699c.45 0 .675-.226.9-.45l.9-.9c.224-.225.45-.45.899-.45H50.09a.9.9 0 0 1 .9.9v5.398a.9.9 0 0 1-.9.9H15.9a.9.9 0 0 1-.9-.9V27.408Z"
      fill="#FFAB19"
      stroke="#CF8B17"
    />
    <path
      d="M18.599 38.205a.9.9 0 0 1 .9-.9h1.8c.45 0 .674.226.899.45l.9.9c.225.225.45.45.9.45h2.699c.45 0 .675-.225.9-.45l.9-.9c.224-.224.45-.45.899-.45h24.518a.9.9 0 0 1 .9.9v8.998a.9.9 0 0 1-.9.9H29.396c-.45 0-.675.224-.9.45l-.9.9c-.224.224-.45.45-.9.45h-2.699c-.45 0-.674-.226-.9-.45l-.899-.9c-.225-.225-.45-.45-.9-.45h-1.8a.9.9 0 0 1-.899-.9v-8.998Z"
      fill="#4C97FF"
      stroke="#3373CC"
    />
    <path
      d="M38.169 39.105h-2.25a3.599 3.599 0 0 0 0 7.198h2.25a3.6 3.6 0 0 0 0-7.198Z"
      fill="#fff"
      stroke="#3373CC"
    />
  </svg>
)
export default SvgAnimations
