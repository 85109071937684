import { Step, StepLabel, Stepper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import type { ReactElement } from 'react'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  stepperRoot: {
    background: 'transparent',
    padding: 0,
  },
  label: {
    transition: '.1s all ease-in-out !important',
    color: '#9E9E9E !important',
    fontSize: 14,
    fontWeight: 500,
  },
  labelBlack: {
    color: '#1A1A1A !important',
  },
  futureIcon: {
    transition: '.1s all ease-in-out !important',
    '& > svg > circle': {
      color: '#D4D4D4',
    },
    '& > svg > text': {
      fill: '#FFF',
    },
  },
  completedIcon: {
    transition: '.1s all ease-in-out !important',
    '& > svg': {
      color: '#',
      '& > path': {
        fill: '#50D174',
      },
    },
  },
  activeIcon: {
    transition: '.1s all ease-in-out !important',
    '& > svg > circle': {
      color: theme.palette.primary.main,
    },
    '& > svg > text': {
      fill: '#FFF',
    },
  },
}))

export type StepType = {
  label: string
  id: number | string
}

type GenericStepperProps = {
  steps: StepType[]
  activeStep: number | string
  verticalItems?: boolean
}

const GenericStepper = ({
  steps,
  activeStep,
  verticalItems,
}: GenericStepperProps): ReactElement => {
  const classes = useStyles()

  return (
    <Stepper
      classes={{
        root: classes.stepperRoot,
      }}
      alternativeLabel={verticalItems}
    >
      {steps.map(({ label, id }) => (
        <Step key={id} active={id === activeStep} completed={activeStep > id}>
          <StepLabel
            classes={{
              label: clsx(classes.label, { fontWeightBold: '700 !important' }),
              active: clsx(classes.label, classes.labelBlack),
              completed: clsx(classes.label, classes.labelBlack),
              iconContainer:
                id === activeStep
                  ? classes.activeIcon
                  : id < activeStep
                  ? classes.completedIcon
                  : classes.futureIcon,
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default GenericStepper
