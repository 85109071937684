import { getUrl } from './endpoints'

export const url = getUrl('URL')
export const filesUrl = getUrl('FILES_URL')
export const avvUrl = getUrl('AVV_URL')
export const chatUrl = getUrl('CHAT_URL')
export const libraryUrl = getUrl('LIBRARY_URL')
export const chatVideocallUrl = getUrl('CHAT_VIDEOCALL_URL')
export const robotsApiUrl = getUrl('ROBOTS_API_URL')
export const organizationsUrl = getUrl('ORGANIZATIONS_URL')
export const notifierUrl = getUrl('NOTIFIER_URL')
export const formsUrl = getUrl('FORMS_URL')
export const bulletinUrl = getUrl('BULLETIN_URL')
export const dispatcherUrl = getUrl('DISPATCHER_URL')
export const searchUrl = getUrl('SEARCH_URL')
export const reportCardUrl = getUrl('REPORT_CARD_URL')
export const attendancesUrl = getUrl('ATTENDANCES_URL')
export const tedUrl = getUrl('TED_URL')
export const tedStorageUrl = getUrl('TED_STORAGE_URL')
export const tedUploadVideoUrl = getUrl('TED_UPLOAD_VIDEO_URL')
export const tedReportsUrl = getUrl('TED_REPORTS_URL')
export const reportCardExportUrl = getUrl('REPORT_CARD_EXPORT_URL')
export const webBffUrl = getUrl('WEB_BFF')
export const vimeoCreateVideoUrl = getUrl('VIMEO_CREATE_VIDEO_URL')
export const cabaGamesUrl = getUrl('CABA_GAMES')
export const pdfGeneratorPreviewUrl = getUrl('PDF_GENERATOR_PREVIEW_URL')
export const googleAuthWorkerUrl = getUrl('CLOUDFLARE_WORKER_GOOGLE_AUTH_URL')
