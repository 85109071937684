import type { ActivityTypes } from '@/api/lms/activities/activities'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { RemoveNullProps } from '@/legacy/utils/types'
import type { Pages } from '@/sections/content-creator/dashboard/types/dashboard'

export const GET_NOTIFICATIONS_REQUEST = 'NOTIFIER/GET_NOTIFICATIONS_REQUEST'
export const GET_NOTIFICATIONS_SUCCESS = 'NOTIFIER/GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_FAILURE = 'NOTIFIER/GET_NOTIFICATIONS_FAILURE'

export const GET_SUBSCRIPTIONS_REQUEST = 'NOTIFIER/GET_SUBSCRIPTIONS_REQUEST'
export const GET_SUBSCRIPTIONS_SUCCESS = 'NOTIFIER/GET_SUBSCRIPTIONS_SUCCESS'
export const GET_SUBSCRIPTIONS_FAILURE = 'NOTIFIER/GET_SUBSCRIPTIONS_FAILURE'

export const UPDATE_SUBSCRIPTION_REQUEST = 'NOTIFIER/UPDATE_SUBSCRIPTION_REQUEST'
export const UPDATE_SUBSCRIPTION_SUCCESS = 'NOTIFIER/UPDATE_SUBSCRIPTION_SUCCESS'
export const UPDATE_SUBSCRIPTION_FAILURE = 'NOTIFIER/UPDATE_SUBSCRIPTION_FAILURE'

export const CHECK_USER_EMAIL_REQUEST = 'NOTIFIER/CHECK_USER_EMAIL_REQUEST'
export const CHECK_USER_EMAIL_SUCCESS = 'NOTIFIER/CHECK_USER_EMAIL_SUCCESS'
export const CHECK_USER_EMAIL_FAILURE = 'NOTIFIER/CHECK_USER_EMAIL_FAILURE'

export const MARK_AS_READ_SUCCESS = 'NOTIFIER/MARK_AS_READ_SUCCESS'
export const MARK_AS_READ_FAILURE = 'NOTIFIER/MARK_AS_READ_FAILURE'
export const MARK_ALL_AS_READ_SUCCESS = 'NOTIFIER/MARK_ALL_AS_READ_SUCCESS'
export const MARK_ALL_AS_READ_FAILURE = 'NOTIFIER/MARK_ALL_AS_READ_FAILURE'

export const SEE_UNREAD_ONLY_SUCCESS = 'NOTIFIER/SEE_UNREAD_ONLY_SUCCESS'
export const SEE_UNREAD_ONLY_FAILURE = 'NOTIFIER/SEE_UNREAD_ONLY_FAILURE'

export const FORWARD_MESSAGE_NOTIFICATION_REQUEST = 'NOTIFIER/FORWARD_MESSAGE_NOTIFICATION_REQUEST'
export const FORWARD_MESSAGE_NOTIFICATION_SUCCESS = 'NOTIFIER/FORWARD_MESSAGE_NOTIFICATION_SUCCESS'
export const FORWARD_MESSAGE_NOTIFICATION_FAILURE = 'NOTIFIER/FORWARD_MESSAGE_NOTIFICATION_FAILURE'

export type NotificationType =
  | 'new_activity'
  | 'activity_correction'
  | 'one_day_left_to_submit_activity'
  | 'activity_submission'
  | 'new_channel_message'
  | 'ten_minutes_left_for_live_class'
  | 'jibri_balance'
  | 'new_student_course'
  | 'new_certificate'

export type NotificationChannel = 'email' | 'webPush' | 'mobilePush'

interface BaseExtraInfo {
  activity: { id: number; title: string; type: ActivityTypes }
  classroom: { id: number; name: string }
  organization: { id: number; name: string }
  subject: { id: number; name: string }
  [key: string]: any
}

interface NewActivityExtraInfo extends BaseExtraInfo {
  score?: number
}

interface OneDayLeftToSubmitExtraInfo extends BaseExtraInfo {
  deliveryDate?: number // date in unix time
}

interface NewChannelMessageExtraInfo extends BaseExtraInfo {
  message?: { id: string; text: string; type: string }
}

interface TenMinutesLeftExtraInfo extends BaseExtraInfo {
  startTime?: number // date in unix time
}
interface CourseExtraInfo extends BaseExtraInfo {
  name?: string
}

interface CertificateExtraInfo extends BaseExtraInfo {
  id?: number
  courseId?: number
  studentId?: number
  organizationId?: number
  title?: string
  action?: number
  timestamp?: string
}

interface BulletinExtraInfo extends BaseExtraInfo {
  bulletinIds: number[]
  id: number
  messageId: number
  organizationId: number
  posterId: number
  text: string
  threadOwnerId: number
  type: string
}

interface BaseNotification {
  id: number
  typeId: number
  userId: number
  readAt: string | null
  title: string
  subtitle: string
  description: string
  createdAt: string
  extra: BaseExtraInfo | null
}

interface NewActivityNotification extends BaseNotification {
  typeId: 1
  extra: NewActivityExtraInfo | null
}

interface OneDayLeftToSubmitNotification extends BaseNotification {
  typeId: 3
  extra: OneDayLeftToSubmitExtraInfo | null
}

interface NewChannelMessageNotification extends BaseNotification {
  typeId: 5
  extra: NewChannelMessageExtraInfo | null
}

interface TenMinutesLeftNotification extends BaseNotification {
  typeId: 6
  extra: TenMinutesLeftExtraInfo | null
}

interface CourseNotification extends BaseNotification {
  typeId: 8
  extra: CourseExtraInfo | null
}

interface CertificateNotification extends BaseNotification {
  typeId: 9
  extra: CertificateExtraInfo | null
}

interface BulletinNotification extends BaseNotification {
  typeId: 10
  extra: BulletinExtraInfo | null
}

export type RawNotification =
  | BaseNotification
  | NewActivityNotification
  | OneDayLeftToSubmitNotification
  | NewChannelMessageNotification
  | TenMinutesLeftNotification
  | CourseNotification
  | CertificateNotification
  | BulletinNotification

export type Notification = RemoveNullProps<RawNotification>

export type NotifierSubscription = {
  userId: number
  notificationTypeId: number
  notificationType: {
    id: number
    name: NotificationType
  }
  mobilePush: boolean
  webPush: boolean
  email: boolean
}

interface NotificationsPagination extends Pages {
  unread: number
}

export interface NotifierState {
  notifications: Notification[]
  loadingNotifications: boolean
  notificationsPagination: NotificationsPagination
  error: string
  subscriptions: NotifierSubscription[]
  loadingSubscriptions: boolean
  emailEnabled: boolean
  loadingEmail: boolean
  unreadOnly: boolean
}

export interface GetNotificationsRequest {
  type: typeof GET_NOTIFICATIONS_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
    page: number
    rowsPerPage: number
    overwrite: boolean
    unreadOnly: boolean
  }
}

export interface GetNotificationsSuccess {
  type: typeof GET_NOTIFICATIONS_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    notifications: Notification[]
    count: number
    unread: number
  }
}

export interface GetNotificationsFailure {
  type: typeof GET_NOTIFICATIONS_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface MarkAsReadSuccess {
  type: typeof MARK_AS_READ_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    notifID: number
  }
}

export interface MarkAsReadFailure {
  type: typeof MARK_AS_READ_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface MarkAllAsReadSuccess {
  type: typeof MARK_ALL_AS_READ_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface MarkAllAsReadFailure {
  type: typeof MARK_ALL_AS_READ_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface SeeUnreadOnlySuccess {
  type: typeof SEE_UNREAD_ONLY_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    checked: boolean
  }
}

export interface SeeUnreadOnlyFailure {
  type: typeof SEE_UNREAD_ONLY_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface GetSubscriptionsRequest {
  type: typeof GET_SUBSCRIPTIONS_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetSubscriptionsSuccess {
  type: typeof GET_SUBSCRIPTIONS_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    subscriptions: NotifierSubscription[]
  }
}

export interface GetSubscriptionsFailure {
  type: typeof GET_SUBSCRIPTIONS_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface UpdateSubscriptionRequest {
  type: typeof UPDATE_SUBSCRIPTION_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface UpdateSubscriptionSuccess {
  type: typeof UPDATE_SUBSCRIPTION_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    subscription: NotifierSubscription
  }
}

export interface UpdateSubscriptionFailure {
  type: typeof UPDATE_SUBSCRIPTION_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface CheckUserEmailRequest {
  type: typeof CHECK_USER_EMAIL_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface CheckUserEmailSuccess {
  type: typeof CHECK_USER_EMAIL_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    emailEnabled: boolean
  }
}

export interface CheckUserEmailFailure {
  type: typeof CHECK_USER_EMAIL_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface ForwardMessageRequest {
  type: typeof FORWARD_MESSAGE_NOTIFICATION_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface ForwardMessageSuccess {
  type: typeof FORWARD_MESSAGE_NOTIFICATION_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface ForwardMessageFailure {
  type: typeof FORWARD_MESSAGE_NOTIFICATION_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export type NotifierAction =
  | GetNotificationsRequest
  | GetNotificationsSuccess
  | GetNotificationsFailure
  | GetSubscriptionsRequest
  | GetSubscriptionsSuccess
  | GetSubscriptionsFailure
  | UpdateSubscriptionRequest
  | UpdateSubscriptionSuccess
  | UpdateSubscriptionFailure
  | CheckUserEmailRequest
  | CheckUserEmailSuccess
  | CheckUserEmailFailure
  | MarkAsReadSuccess
  | MarkAsReadFailure
  | MarkAllAsReadSuccess
  | MarkAllAsReadFailure
  | SeeUnreadOnlySuccess
  | SeeUnreadOnlyFailure
  | ForwardMessageRequest
  | ForwardMessageSuccess
  | ForwardMessageFailure
