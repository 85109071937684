import { createRoot } from 'react-dom/client'
import { StyledEngineProvider } from '@mui/material/styles'
import * as serviceWorker from './serviceWorker'
import App from './app'
import Locales from './locales'
import { initialize as initializeVite } from './vite'
import { initialize as initializeFirebase } from './firebase'

// app
import './index.css'

// megadraft
import './legacy/styles/megadraft.css'
import './legacy/styles/draft.css'
import './legacy/styles/draft.youtube.css'
import './legacy/styles/draft.image.css'
import './legacy/styles/draft.list.css'
import './legacy/styles/draft.preview.css'
import './legacy/styles/draft.video.css'
import './legacy/styles/draft.vimeo.css'

// overwritten global styles
import './legacy/styles/kanban.css'
import './legacy/styles/emoji.picker.css'

// global styles
import 'emoji-mart/css/emoji-mart.css'
import 'megadraft/dist/css/megadraft.css'
import '@lourenci/react-kanban/dist/styles.css'

initializeVite()
initializeFirebase()
serviceWorker.register()

console.info('Release version 🚀:', process.env.APP_RELEASE)

Locales.init().then(() => {
  const root = createRoot(document.getElementById('root') as HTMLElement)
  root.render(
    <StyledEngineProvider injectFirst>
      <App />
    </StyledEngineProvider>
  )
})
