import ru from '@/locales/translations/ru/ru.json'
import common from '@/locales/translations/ru/common.json'
import glossary from '@/locales/translations/ru/glossary.json'
import validation from '@/locales/translations/ru/validation.json'

export default {
  translation: ru,
  common: common,
  glossary: glossary,
  validation: validation,
}
