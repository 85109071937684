import { useDispatch } from 'react-redux'
import { useAppSelector } from '@/state/hooks'
import { bindActionCreators } from 'redux'
import type { RootState } from '@/state/reducer'
import ReportCardSideDrawerActionCreators from '@/sections/report-card/grading/sideDrawer/actions/sideDrawer'
import type { DrawerContentProps } from '@/sections/report-card/grading/sideDrawer/components/sideDrawerContent'
import DrawerContent from '@/sections/report-card/grading/sideDrawer/components/sideDrawerContent'
import ReportCardSideDrawer from '@/sections/report-card/grading/sideDrawer/components/sideDrawerWrapper'
import { useTranslation } from 'react-i18next'

export enum SideDrawerContent {
  ABSCENCES = 'abscences',
  INITIAL = 'initial',
}

function getDrawerTitle(drawerContent: SideDrawerContent): string {
  switch (drawerContent) {
    case SideDrawerContent.ABSCENCES:
      return 'Asistencias'

    case SideDrawerContent.INITIAL:
    default:
      return ''
  }
}

type ReportCardSideDrawerContainerProps = DrawerContentProps

export default function ReportCardSideDrawerContainer({
  drawerContent = SideDrawerContent.ABSCENCES,
}: ReportCardSideDrawerContainerProps) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // Selectors
  const { open } = useAppSelector((state) => state.reportCard.grading.sideDrawer)
  const { selectedAbsenceSubjectId, allSubjects } = useAppSelector(
    (state) => state.reportCard.grading.mainView
  )

  // Actions
  const { closeSideDrawer: closeDrawer } = bindActionCreators(
    ReportCardSideDrawerActionCreators,
    dispatch
  )

  const subjectName: string =
    allSubjects.find((subject) => subject.id === selectedAbsenceSubjectId)?.name ??
    t('reportCard.grading.attendances.attendancesTypes.common')

  return (
    <ReportCardSideDrawer
      open={open}
      onClose={closeDrawer}
      title={getDrawerTitle(drawerContent)}
      subtitle={drawerContent === 'abscences' ? subjectName : ''}
    >
      <DrawerContent drawerContent={drawerContent} />
    </ReportCardSideDrawer>
  )
}
