// TODO: Think of a better structure for this, this won't scale
export enum reduxRequestOriginMap {
  CONTENT_CREATOR_DASHBOARD = 'CONTENT_CREATOR_DASHBOARD',
  CONTENT_CREATOR_PREMIUM_ACTIVITY_CREATION_MODAL = 'CONTENT_CREATOR_PREMIUM_ACTIVITY_CREATION_MODAL',
  CONTENT_CREATOR_BOOKS = 'CONTENT_CREATOR_BOOKS',
  CONTENT_CREATOR_SEQUENCES_DRAWER = 'CONTENT_CREATOR_SEQUENCES_DRAWER',
  CONTENT_CREATOR_COURSE_CREATION_DRAWER = 'CONTENT_CREATOR_COURSE_CREATION_DRAWER',
  CONTENT_CREATOR_COURSE_CREATION_CERTIFICATE = 'CONTENT_CREATOR_COURSE_CREATION_CERTIFICATE',
  EB_ADMIN_LICENSE_STOCK = 'EB_ADMIN_LICENSE_STOCK',
  EB_ADMIN_TEACHERS = 'EB_ADMIN_TEACHERS',
  EB_ADMIN_STUDENTS = 'EB_ADMIN_STUDENTS',
  TEACHER_LIBRARY_BOOKS = 'TEACHER_LIBRARY_BOOKS',
  TEACHER_LIBRARY_SEQUENCES = 'TEACHER_LIBRARY_SEQUENCES',
  TEACHER_LIBRARY_SEQUENCE_ASSIGNMENT_MODAL = 'TEACHER_LIBRARY_SEQUENCE_ASSIGNMENT_MODAL',
  TEACHER_LIBRARY_COURSES = 'TEACHER_LIBRARY_COURSES',
  TEACHER_LIBRARY_COURSE_ASSIGNMENT_MODAL = 'TEACHER_LIBRARY_COURSE_ASSIGNMENT_MODAL',
  TEACHER_SUBGROUPS_DRAWER = 'TEACHER_SUBGROUPS_DRAWER',
  PRINCIPAL_MIGRATION_TAB = 'PRINCIPAL_MIGRATION_TAB',
  PRINCIPAL_NEW_CLASSROOM_MODAL = 'PRINCIPAL_NEW_CLASSROOM_MODAL',
  PRINCIPAL_TEACHERS = 'PRINCIPAL_TEACHERS',
  PRINCIPAL_STUDENTS = 'PRINCIPAL_STUDENTS',
  STUDENT_QUIZ = 'STUDENT_QUIZ',
  LIBRARY_COURSES = 'LIBRARY_COURSES',
  DASHBOARD_COURSES = 'DASHBOARD_COURSES',
  COURSE_MODAL = 'COURSE_MODAL',
  GENERIC_CODE_CLAIM_MODAL = 'GENERIC_CODE_CLAIM_MODAL',
  LICENCE_LOGIN = 'LICENCE_LOGIN',
  NORMAL_LOGIN = 'NORMAL_LOGIN',
  STUDENT_ACTIVITY = 'STUDENT_ACTIVITY',
  NOTIFIER = 'NOTIFIER',
  EDUCATIONAL_TAGS_MODAL = 'EDUCATIONAL_TAGS_MODAL',
  EDUCATIONAL_INFO_STEP = 'EDUCATIONAL_INFO_STEP',
  COLLAB_MINIATURE_CREATION = 'COLLAB_MINIATURE_CREATION',
  COLLAB_MINIATURE_ASSIGNMENT = 'COLLAB_MINIATURE_ASSIGNMENT',
  COLLAB_MINIATURE_WAITING_ROOM = 'COLLAB_MINIATURE_WAITING_ROOM',
  REPORT_CARD_AUDIT = 'REPORT_CARD_AUDIT',
  REPORT_CARD_CONFIG_MAIN_TAB = 'REPORT_CARD_CONFIG_MAIN_TAB',
  REPORT_CARD_CONFIG_STEPPER_MODAL = 'REPORT_CARD_CONFIG_STEPPER_MODAL',
  REPORT_CARD_CONFIG_DELETION_MODAL = 'REPORT_CARD_CONFIG_DELETION_MODAL',
  REPORT_CARD_CONFIG_PERIODS_STEP = 'REPORT_CARD_CONFIG_PERIODS_STEP',
  REPORT_CARD_CONFIG_SUBJECTS_STEP = 'REPORT_CARD_CONFIG_SUBJECTS_STEP',
  REPORT_CARD_CONFIG_SIGNATURES_STEP = 'REPORT_CARD_CONFIG_SIGNATURES_STEP',
  REPORT_CARD_CONFIG_SCORES_STEP = 'REPORT_CARD_CONFIG_SCORES_STEP',
  REPORT_CARD_CONFIG_RANGE_PICKER = 'REPORT_CARD_CONFIG_RANGE_PICKER',
  REPORT_CARD_CONFIG_COMPETENCES = 'REPORT_CARD_CONFIG_COMPETENCES',
  USER_PROFILE = 'USER_PROFILE',
  REPORT_CARD_GRADING_MAIN_VIEW = 'REPORT_CARD_GRADING_MAIN_VIEW',
  REPORT_CARD_GRADING_SCORES_TAB = 'REPORT_CARD_GRADING_SCORES_TAB',
  REPORT_CARD_GRADING_SCORES_FORM = 'REPORT_CARD_GRADING_SCORES_FORM',
  REPORT_CARD_GRADING_ATTITUDINAL_SCORES_TAB = 'REPORT_CARD_GRADING_ATTITUDINAL_SCORES_TAB',
  REPORT_CARD_GRADING_ATTITUDINAL_SCORES_FORM = 'REPORT_CARD_GRADING_ATTITUDINAL_SCORES_FORM',
  REPORT_CARD_GRADING_REVIEWS_TAB = 'REPORT_CARD_GRADING_REVIEWS_TAB',
  REPORT_CARD_GRADING_REVIEWS_FORM = 'REPORT_CARD_GRADING_REVIEWS_FORM',
  REPORT_CARD_GRADING_COMPETENCE_SCORES_TAB = 'REPORT_CARD_GRADING_COMPETENCE_SCORES_TAB',
  REPORT_CARD_GRADING_COMPETENCE_SCORES_FORM = 'REPORT_CARD_GRADING_COMPETENCE_SCORES_FORM',
  REPORT_CARD_GRADING_STUDENT_DRAWER = 'REPORT_CARD_GRADING_STUDENT_DRAWER',
  REPORT_CARD_GRADING_SEND_REPORT_CARD_MODAL = 'REPORT_CARD_GRADING_SEND_REPORT_CARD_MODAL',
  REPORT_CARD_GRADING_ABSENCES_DRAWER = 'REPORT_CARD_GRADING_ABSENCES_DRAWER',
  REPORT_CARD_GRADING_FINAL_APPRECIATIONS = 'REPORT_CARD_GRADING_FINAL_APPRECIATIONS',
  REPORT_CARD_GRADING_FINAL_APPRECIATIONS_DRAWER = 'REPORT_CARD_GRADING_FINAL_APPRECIATIONS_DRAWER',
  ADMIN_ORGANIZATIONS_DRAWER = 'ADMIN_ORGANIZATIONS_DRAWER',
  PRINCIPAL_ORGANIZATIONS_DRAWER = 'PRINCIPAL_ORGANIZATIONS_DRAWER',
  MANAGEMENT_ADD_EDIT_ORGANIZATIONS_MODAL = 'ADMIN_ADD_EDIT_ORGANIZATIONS_MODAL',
  ADMIN_EXAMS_DRAWER = 'ADMIN_EXAMS_DRAWER',
  ADMIN_THEMES_DRAWER = 'ADMIN_THEMES_DRAWER',
  ADMIN_DELETE_THEME_MODAL = 'ADMIN_DELETE_THEME_MODAL',
  ADMIN_ADD_EDIT_THEME_MODAL = 'ADMIN_ADD_EDIT_THEME_MODAL',
  ADMIN_SUBJECTS_DRAWER = 'ADMIN_SUBJECTS_DRAWER',
  MANAGEMENT_ADD_EDIT_SUBJECT_MODAL = 'MANAGEMENT_ADD_EDIT_SUBJECT_MODAL',
  MANAGEMENT_DELETE_SUBJECT_CONFIRM_MODAL = 'MANAGEMENT_DELETE_SUBJECT_CONFIRM_MODAL',
  MANAGEMENT_DELETE_CLASSROOM_CONFIRM_MODAL = 'MANAGEMENT_DELETE_CLASSROOM_CONFIRM_MODAL',
  MANAGEMENT_ADD_EDIT_CLASSROOM_MODAL = 'MANAGEMENT_ADD_EDIT_CLASSROOM_MODAL',
  MANAGEMENT_CLASSROOM_SUBJECTS_MODAL = 'MANAGEMENT_CLASSROOM_SUBJECTS_MODAL',
  MANAGEMENT_CLASSROOM_SELECTOR_MODAL = 'MANAGEMENT_CLASSROOM_SELECTOR_MODAL',
  MANAGEMENT_STAGE_SELECTOR_MODAL = 'MANAGEMENT_STAGE_SELECTOR_MODAL',
  MANAGEMENT_DELETE_EXAM_CONFIRM_MODAL = 'MANAGEMENT_DELETE_EXAM_CONFIRM_MODAL',
  MANAGEMENT_FINISH_EXAM_CONFIRM_MODAL = 'MANAGEMENT_FINISH_EXAM_CONFIRM_MODAL',
  MANAGEMENT_DETAILS_EXAM_MODAL = 'MANAGEMENT_DETAILS_EXAM_MODAL',
  MANAGEMENT_ADD_EDIT_EXAM_MODAL = 'MANAGEMENT_ADD_EDIT_EXAM_MODAL',
  MANAGEMENT_CUSTOMIZE_THEME_MODAL = 'MANAGEMENT_CUSTOMIZE_THEME_MODAL',
  MANAGEMENT_EXAM_SUBJECTS_MODAL = 'MANAGEMENT_EXAM_SUBJECTS_MODAL',
  MANAGEMENT_COSMOS_MODAL = 'MANAGEMENT_COSMOS_MODAL',
  MANAGEMENT_DELETE_USER_MODAL = 'MANAGEMENT_DELETE_USER_MODAL',
  PRINCIPAL_SUBJECTS_DRAWER = 'PRINCIPAL_SUBJECTS_DRAWER',
  ATTENDANCES_CONFIG_MAIN_TAB = 'ATTENDANCES_CONFIG_MAIN_TAB',
  ATTENDANCES_CONFIG_DATES_MODAL = 'ATTENDANCES_CONFIG_DATES_MODAL',
  ATTENDANCES_RECORDS_MAIN_VIEW = 'ATTENDANCES_RECORDS_MAIN_VIEW',
  STUDENT_GET_REPORT_CARD = 'STUDENT_GET_REPORT_CARD',
  OFFLINE_ACTIVITIES_MODAL = 'OFFLINE_ACTIVITIES_MODAL',
  AUTH = 'AUTH',
  TED_MAIN = 'TED_MAIN',
  TED_GROUPS = 'TED_GROUPS',
  TED_EDIT_MODAL = 'TED_EDIT_MODAL',
  TED_GATHERINGS = 'TED_GATHERINGS',
  TED_VIDEOS = 'TED_VIDEOS',
  MANAGEMENT_USERS_DRAWER = 'MANAGEMENT_USERS_DRAWER',
  MANAGEMENT_ADD_EDIT_USER_MODAL = 'MANAGEMENT_ADD_EDIT_USER_MODAL',
  MANAGEMENT_USER_DETAILS_MODAL = 'MANAGEMENT_USER_DETAILS_MODAL',
  ASSIGNMENTS_INPUT = 'ASSIGNMENTS_INPUT',
  MANAGEMENT_DELETE_ASSIGNMENT_MODAL = 'MANAGEMENT_DELETE_ASSIGNMENT_MODAL',
  MANAGEMENT_DELETE_USER_ORGANIZATION_MODAL = 'MANAGEMENT_DELETE_USER_ORGANIZATION_MODAL',
  MANAGEMENT_FEATURES_MODAL = 'MANAGEMENT_FEATURES_MODAL',
  CREATE_FORMS_MODAL = 'CREATE_FORMS_MODAL',
  PRINCIPAL_TABS = 'PRINCIPAL_TABS',
}

export type ReduxRequestOrigin = `${reduxRequestOriginMap}`
