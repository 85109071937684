import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import {
  GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_FAILURE,
  GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_REQUEST,
  GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_SUCCESS,
  SAVE_OR_EDIT_REPORT_CARD_CONFIG_FAILURE,
  SAVE_OR_EDIT_REPORT_CARD_CONFIG_REQUEST,
  SAVE_OR_EDIT_REPORT_CARD_CONFIG_SUCCESS,
} from '@/sections/report-card/config/types/apiInteractions'
import * as Types from '@/sections/report-card/config/types/stepperModal'

const initialState: Types.ReportCardConfigStepperModalState = {
  currentStep: Types.ReportCardConfigStepperModalStep.PERIODS,
  open: false,
  loading: false,
  editLoading: false,
  editMode: false,
  error: '',
  classroomsBeingConfigured: [],
  confirmationChecked: false,
  subjectsAreValid: true,
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_CONFIG_STEPPER_MODAL

const ReportCardConfigStepperModal: Reducer<
  Types.ReportCardConfigStepperModalState,
  Types.ReportCardConfigStepperModalAction
> = (state = initialState, action): Types.ReportCardConfigStepperModalState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case Types.SET_OPEN:
      return {
        ...state,
        open: true,
        classroomsBeingConfigured: action.payload.classroomsBeingConfigured,
      }
    case Types.SET_EDIT_OPEN_START:
      return {
        ...state,
        open: true,
        editLoading: true,
        editMode: true,
        classroomsBeingConfigured: action.payload.classroomsBeingConfigured,
      }
    case Types.SET_EDIT_OPEN_FINAL:
      return {
        ...state,
        editLoading: false,
      }

    case Types.SET_CLOSED:
      return initialState
    case GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_REQUEST:
      return {
        ...state,
        loading: true,
        subjectsAreValid: true,
      }
    case GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        subjectsAreValid: action.payload.valid,
      }
    case GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_FAILURE: {
      return {
        ...state,
        subjectsAreValid: false,
        error: action.payload.error,
      }
    }
    case SAVE_OR_EDIT_REPORT_CARD_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case SAVE_OR_EDIT_REPORT_CARD_CONFIG_SUCCESS:
      return initialState
    case SAVE_OR_EDIT_REPORT_CARD_CONFIG_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, ReportCardConfigStepperModal, requestDestination)
