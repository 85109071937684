import { url } from '@aula/config'
import { ManagementRole } from '@/legacy/roles'
import { ApiPrivate } from '@/legacy/services/api/api'
import type { GetUsersParams, GetUsersResponse } from './types'

/**
 * Obtiene una lista de usuarios
 * @doc https://develop.env.aula.educabot.com/v1/:managementRole/users
 */
export const get = (params: GetUsersParams, role: ManagementRole = ManagementRole.ADMIN) =>
  ApiPrivate.get<GetUsersResponse>(url + `/v1/${role}/users`, { params }).then(
    (response) => response.data
  )

/**
 * Obtiene información de un usuario determinado por tipo y numero de identificacion en una organizacion determinada
 * @doc https://develop.env.aula.educabot.com/v1/:managementRole/users
 */
export const getOneByDocument = (
  docNumber: string,
  docType: number,
  organizationId: number,
  role: ManagementRole
) => {
  const params: GetUsersParams = {
    search: docNumber,
    searchByIdentificationTypeId: docType,
    limit: 1,
    organizationId,
  }
  return ApiPrivate.get<GetUsersResponse>(url + `/v1/${role}/users`, { params }).then(
    (response) => {
      if (response.data.list[0]) {
        return response.data.list[0]
      } else {
        throw new Error('user not found')
      }
    }
  )
}
