import * as React from 'react'
import type { SVGProps } from 'react'
const SvgWriting = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m10.842 10.013.009-.013 2.652-4.273c.274-.443.111-1.009-.364-1.264-.476-.256-1.084-.104-1.359.338L9.626 8.273 8.63 9.876l.198 1.53 1.525-.605.488-.788Z"
      fill="#42B3FF"
    />
    <path
      stroke="#42B3FF"
      strokeWidth={1.88}
      strokeLinecap="round"
      d="M6.365 11.108H2.941M6.365 8.024H2.941M6.365 4.94H2.941"
    />
  </svg>
)
export default SvgWriting
