import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type { DocTypeOption } from '@/legacy/redux/types/docTypesOptions'
import type { Group } from '@/api/ted/types'
import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type { Option } from '@/legacy/components/v3/selects/selectSimple'
import type { GroupStatus, Meeting } from '@/api/ted/main'

export interface EditGroupModalState {
  open: boolean
  loading: boolean
  error: string
  editMode: boolean
  groupId: number
  selectedStatus: GroupStatus
  id: number

  name: string
  currentStage: Meeting

  finalEventCompleted: boolean
  totalParticipants: number
  completedTalksCount: number
  teachersCount: number
}

//clear fields
export const CLEAR_FIELDS = 'TED/ADD_EDIT_MODAL/CLEAR_FIELDS'
export type ClearAllFields = GenericActionWithType<typeof CLEAR_FIELDS>

export const CLOSE_MODAL = 'TED/ADD_EDIT_MODAL/CLOSE_MODAL'

//toggle edit modal open

export const OPEN_EDIT_MODAL_REQUEST = 'TED/ADD_EDIT_MODAL/OPEN_EDIT_MODAL_REQUEST'
export const OPEN_EDIT_MODAL_SUCCESS = 'TED/ADD_EDIT_MODAL/OPEN_EDIT_MODAL_SUCCESS'
export const OPEN_EDIT_MODAL_FAILURE = 'TED/ADD_EDIT_MODAL/OPEN_EDIT_MODAL_FAILURE'

export const CLOSE_EDIT_MODAL = 'TED/ADD_EDIT_MODAL/CLOSE_EDIT_MODAL'
export const EDIT_GROUP_DATA_REQUEST = 'TED/EDIT_GROUP_DATA_REQUEST'
export const EDIT_GROUP_DATA_SUCCESS = 'TED/EDIT_GROUP_DATA_REQUEST'
export const EDIT_GROUP_DATA_FAILURE = 'TED/EDIT_GROUP_DATA_FAILURE'

export type EditGroupDataRequest = GenericActionWithType<typeof EDIT_GROUP_DATA_REQUEST>
export type EditGroupDataSuccess = GenericActionWithType<
  typeof EDIT_GROUP_DATA_SUCCESS,
  {
    updatedGroupData: Group
  }
>
export type EditGroupDataFailure = GenericActionWithType<
  typeof EDIT_GROUP_DATA_FAILURE,
  ErrorPayload
>

export type OpenEditModalRequest = GenericActionWithType<typeof OPEN_EDIT_MODAL_REQUEST>
export type OpenEditModalSuccess = GenericActionWithType<
  typeof OPEN_EDIT_MODAL_SUCCESS,
  { group: Group }
>
export type OpenEditModalFailure = GenericActionWithType<
  typeof OPEN_EDIT_MODAL_FAILURE,
  ErrorPayload
>
export type CloseEditModal = GenericActionWithType<typeof CLOSE_EDIT_MODAL>

export type EditGroupAction =
  | OpenEditModalRequest
  | OpenEditModalSuccess
  | OpenEditModalFailure
  | CloseEditModal
  | ClearAllFields
  | EditGroupDataRequest
  | EditGroupDataSuccess
  | EditGroupDataFailure

export type EditGroupModalAction =
  | SetField<'name' | 'open' | 'selectedStatus', string | boolean | number | Option[]>
  | ClearAllFields
  | EditGroupAction
  | ClearState
