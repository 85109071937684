import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type { Subject } from '@/legacy/redux/types/subjects'
import type { GenericActionWithType, GenericPayload } from '@/legacy/redux/utils'
import type { ScoreType } from '@/sections/report-card/types/common'
import type {
  GetAndValidateClassroomsSubjectsSuccess,
  SaveOrEditReportCardConfigSuccess,
} from '@/sections/report-card/config/types/apiInteractions'
import type {
  AttitudinalItem,
  ReportCardSubject,
} from '@/sections/report-card/config/types/entities'

export interface ReportCardConfigSubjectsState {
  subjects: ReportCardSubject[]
  loading: boolean
  error: string
  subitemsModalOpen: boolean
  reviewsByAttitudinal: boolean
  attitudinalModalOpen: boolean
  attitudinalItems: AttitudinalItem[]
}

export const TOGGLE_SUBJECT_SCORE_TYPE =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SUBJECTS/TOGGLE_SUBJECT_SCORE_TYPE'

export type ToggleSubjectScoreType = GenericActionWithType<
  typeof TOGGLE_SUBJECT_SCORE_TYPE,
  { subject: ReportCardSubject }
>

export const CHANGE_GRADABLES_SCORE_TYPE =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SUBJECTS/CHANGE_GRADABLES_SCORE_TYPE'

export type ChangeGradablesScoreType = GenericActionWithType<
  typeof CHANGE_GRADABLES_SCORE_TYPE,
  { newScoreType: ScoreType }
>

export const CONFIRM_SUBITEMS = 'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SUBJECTS/CONFIRM_SUBITEMS'

export type ConfirmSubitems = GenericActionWithType<
  typeof CONFIRM_SUBITEMS,
  { subjects: ReportCardSubject[]; attitudinalItems: AttitudinalItem[] }
>

export const TOGGLE_ATTITUDINAL_ITEM_SCORE_TYPE =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SUBJECTS/TOGGLE_ATTITUDINAL_ITEM_SCORE_TYPE'

export type ToggleAttitudinalItemScoreType = GenericActionWithType<
  typeof TOGGLE_ATTITUDINAL_ITEM_SCORE_TYPE,
  { attitudinalItem: AttitudinalItem }
>

export const REORDER_SUBJECTS = 'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SUBJECTS/REORDER_SUBJECTS'
export type ReorderSubjects = GenericActionWithType<
  typeof REORDER_SUBJECTS,
  { subjects: ReportCardSubject[] }
>
export const REORDER_ATTITUDINALS =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SUBJECTS/REORDER_ATTITUDINALS'
export type ReorderAttitudinals = GenericActionWithType<
  typeof REORDER_ATTITUDINALS,
  { attitudinalItems: AttitudinalItem[] }
>

export const SET_VALUES_FOR_EDITION =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SUBJECTS/SET_VALUES_FOR_EDITION'

interface SetValuesForEditionPayload extends GenericPayload {
  subjectsState: ReportCardConfigSubjectsState
}

export type SetValuesForEdition = GenericActionWithType<
  typeof SET_VALUES_FOR_EDITION,
  SetValuesForEditionPayload
>

export type ReportCardConfigSubjectsAction =
  | SetField<
      'subjects' | 'attitudinalItems' | 'subitemsModalOpen' | 'attitudinalModalOpen',
      Subject[] | AttitudinalItem[] | boolean
    >
  | ClearState
  | GetAndValidateClassroomsSubjectsSuccess
  | ToggleSubjectScoreType
  | ChangeGradablesScoreType
  | SaveOrEditReportCardConfigSuccess
  | ConfirmSubitems
  | ToggleAttitudinalItemScoreType
  | SetValuesForEdition
  | ReorderSubjects
  | ReorderAttitudinals
