import types from '@/sections/teacher/dashboard/types'
import { apiPrivate } from '@/api'
import { filesUrl, url } from '@aula/config'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import editorActions from '@/sections/editor/actions/editor'
import fileDownload from 'js-file-download'
import { API } from '@/api/lms'
import axios from 'axios'
import studentListActions from '@/sections/teacher/studentList/actions/studentList'

/**
 * Returns whether or not an organization has stock for a specific activity. Which it does if the
 * remaining stock is greater than 0, or the total amount of stock given was 0. The latter case
 * indicates that the stock is infinite.
 *
 * @param organizationId
 * @param activityID
 * @return {Promise<boolean>}
 */
const getWithLicenses = async (organizationId, activityID) => {
  try {
    const activityLicensesResponse = await API.Library.getSingleLicenseStock(
      organizationId,
      'activity',
      activityID
    )
    const stock = activityLicensesResponse.data.description.licenseStock
    return stock.remaining > 0 || stock.amount === 0
  } catch (e) {
    return false
  }
}

const actions = {
  getActivityWithStudentAnswers:
    (classroomID, activityID, studentID, notOpen = false, hideGradingCard = false) =>
    (dispatch) => {
      dispatch({ type: types.STUDENT_ACTIVITY_REQUEST, payload: { notOpen } })

      return apiPrivate
        .get(
          url +
            `/v1/classroom/${classroomID}/activities/${activityID}/students/${studentID}/submissions`
        )
        .then((response) => {
          const { activity } = response.data.description
          dispatch(actions.getActivityWithAnswersSuccess(activity, hideGradingCard))
          dispatch(editorActions.setRawEditorState(activity))
          return activity
        })
        .catch((error) => {
          dispatch(actions.getActivityWithAnswersFailure(error))
        })
    },

  getActivityWithAnswersSuccess: (activity, hideGradingCard) => ({
    type: types.STUDENT_ACTIVITY_SUCCESS,
    payload: { activity, hideGradingCard },
  }),
  getActivityWithAnswersFailure: (error) => ({
    type: types.STUDENT_ACTIVITY_FAILURE,
    payload: {
      error,
    },
  }),

  // classroomID is kept for compatibility with existing code, should be removed when possible
  getActivity: (classroomID, activityID) => (dispatch) => {
    dispatch({ type: types.ACTIVITY_REQUEST })

    return API.Activities.get(activityID)
      .then((response) => {
        const { activity } = response.data.description
        dispatch(actions.getActivitySuccess(activity))
        dispatch(editorActions.setRawEditorState(activity))
      })
      .catch((error) => {
        dispatch(actions.getActivityFailure(error))
      })
  },

  getActivitySuccess: (activity) => ({
    type: types.ACTIVITY_SUCCESS,
    payload: {
      activity,
    },
  }),
  getActivityFailure: (error) => ({
    type: types.ACTIVITY_FAILURE,
    payload: {
      error,
      isDeleted: error.status.response === 404,
    },
  }),

  getLibraryActivity:
    (activityID, hideAssignment = false, orgID = 0) =>
    (dispatch) => {
      dispatch({ type: types.LIBRARY_ACTIVITY_REQUEST })

      const requestList = [
        API.Library.getPremiumActivity(activityID),
        API.Activities.get(activityID),
      ]

      return axios
        .all(requestList)
        .then(async (responses) => {
          const { premiumActivity: premiumActivity } = responses[0].data.description
          const { activity } = responses[1].data.description

          const licensesOrgID = orgID === 0 ? premiumActivity.organizationId : orgID
          const withLicenses = await getWithLicenses(licensesOrgID, activityID)

          const combinedActivity = {
            ...premiumActivity,
            ...activity,
            premiumActivityDescription: premiumActivity.description,
            title: premiumActivity.title,
          }

          dispatch(
            actions.getLibraryActivitySuccess(combinedActivity, hideAssignment, withLicenses)
          )
          dispatch(editorActions.setRawEditorState(combinedActivity))
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.LIBRARY_ACTIVITY_FAILURE, dispatch)
        })
    },

  getLibraryActivitySuccess: (activity, hideAssignment, withLicenses) => ({
    type: types.LIBRARY_ACTIVITY_SUCCESS,
    payload: {
      activity,
      hideAssignment,
      withLicenses,
    },
  }),

  closeModal: () => (dispatch) => {
    dispatch(editorActions.clear())
    dispatch({ type: types.CLOSE_MODAL })
    dispatch(studentListActions.setClose())
  },

  downloadFile: (fileID, fileName) => (dispatch) => {
    dispatch({
      type: types.DOWNLOAD_FILE_REQUEST,
      payload: {
        id: fileID,
      },
    })

    const config = { responseType: 'blob' }
    return apiPrivate
      .get(filesUrl + `/v1/files/${fileID}/download`, config)
      .then((response) => {
        fileDownload(response.data, fileName)
        dispatch(actions.downloadFileSuccess())
      })
      .catch((error) => {
        handleErrorsWithAction(error, types.DOWNLOAD_FILE_FAILURE, dispatch)
      })
  },
  downloadFileSuccess: (activityID) => ({
    type: types.DOWNLOAD_FILE_SUCCESS,
    payload: { activityID },
  }),
  getRobotsStudentProgress: (activityID, studentID) => (dispatch) => {
    dispatch({ type: types.ROBOTS_STUDENT_PROGRESS_REQUEST })

    return API.Robots.getStudentProgress(activityID, studentID)
      .then((response) => {
        const { progress } = response.data.description
        if (!progress) {
          dispatch(actions.getRobotsStudentProgressSuccess(null))
          return
        }
        const visual = {
          type: progress.type,
          image: progress.image,
          svg: progress.svg,
        }
        dispatch(actions.getRobotsStudentProgressSuccess(visual))
      })
      .catch((error) => {
        handleErrorsWithAction(error, types.ROBOTS_STUDENT_PROGRESS_FAILURE, dispatch)
      })
  },

  getRobotsStudentProgressSuccess: (visual) => ({
    type: types.ROBOTS_STUDENT_PROGRESS_SUCCESS,
    payload: {
      visual,
    },
  }),

  setCollabMiniatureStarted: (started) => ({
    type: types.SET_COLLAB_MINIATURE_STARTED,
    payload: {
      started,
    },
  }),
}

export default actions
