import type { Facilitator, Group, Institution } from '@/api/ted/types'
import type { SetField } from '@/legacy/redux/types/generic'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'

export interface ContentsMainViewState {
  selectedTab: number
  groups: Group[]
  selectedGroup: Group
  institution: Institution
  facilitators: Facilitator[]
  loadingGroup: boolean
  loadingGroups: boolean
  loadingInstitution: boolean
  loadingFacilitators: boolean
  error: string
}

export const GET_GROUP_REQUEST = 'TED/CONTENTS/GET_GROUP_REQUEST'
export const GET_GROUP_SUCCESS = 'TED/CONTENTS/GET_GROUP_SUCCESS'
export const GET_GROUP_FAILURE = 'TED/CONTENTS/GET_GROUP_FAILURE'

export const LIST_GROUPS_REQUEST = 'TED/CONTENTS/LIST_GROUPS_REQUEST'
export const LIST_GROUPS_SUCCESS = 'TED/CONTENTS/LIST_GROUPS_SUCCESS'
export const LIST_GROUPS_FAILURE = 'TED/CONTENTS/LIST_GROUPS_FAILURE'

export const GET_INSTITUTION_REQUEST = 'TED/CONTENTS/GET_INSTITUTION_REQUEST'
export const GET_INSTITUTION_SUCCESS = 'TED/CONTENTS/GET_INSTITUTION_SUCCESS'
export const GET_INSTITUTION_FAILURE = 'TED/CONTENTS/GET_INSTITUTION_FAILURE'

export const LIST_FACILITATORS_REQUEST = 'TED/CONTENTS/LIST_FACILITATORS_REQUEST'
export const LIST_FACILITATORS_SUCCESS = 'TED/CONTENTS/LIST_FACILITATORS_SUCCESS'
export const LIST_FACILITATORS_FAILURE = 'TED/CONTENTS/LIST_FACILITATORS_FAILURE'

export type GetGroupRequest = GenericActionWithType<typeof GET_GROUP_REQUEST>

export type GetGroupSuccess = GenericActionWithType<typeof GET_GROUP_SUCCESS, { group: Group }>

export type GetGroupFailure = GenericActionWithType<typeof GET_GROUP_FAILURE, ErrorPayload>

export type GetGroupAction = GetGroupRequest | GetGroupSuccess | GetGroupFailure

export type ListGroupsRequest = GenericActionWithType<typeof LIST_GROUPS_REQUEST>

export type ListGroupsSuccess = GenericActionWithType<
  typeof LIST_GROUPS_SUCCESS,
  {
    groups: Group[]
  }
>

export type ListGroupsFailure = GenericActionWithType<typeof LIST_GROUPS_FAILURE, ErrorPayload>

export type ListGroupsAction = ListGroupsRequest | ListGroupsSuccess | ListGroupsFailure

export type GetInstitutionRequest = GenericActionWithType<typeof GET_INSTITUTION_REQUEST>

export type GetInstitutionSuccess = GenericActionWithType<
  typeof GET_INSTITUTION_SUCCESS,
  {
    institution: Institution
  }
>

export type GetInstitutionFailure = GenericActionWithType<
  typeof GET_INSTITUTION_FAILURE,
  ErrorPayload
>

export type GetInstitutionAction =
  | GetInstitutionRequest
  | GetInstitutionSuccess
  | GetInstitutionFailure

export type ListFacilitatorsRequest = GenericActionWithType<typeof LIST_FACILITATORS_REQUEST>

export type ListFacilitatorsSuccess = GenericActionWithType<
  typeof LIST_FACILITATORS_SUCCESS,
  {
    facilitators: Facilitator[]
  }
>

export type ListFacilitatorsFailure = GenericActionWithType<
  typeof LIST_FACILITATORS_FAILURE,
  ErrorPayload
>

export type ListFacilitatorsAction =
  | ListFacilitatorsRequest
  | ListFacilitatorsSuccess
  | ListFacilitatorsFailure

export type ContentsMainViewAction =
  | GetGroupAction
  | ListGroupsAction
  | GetInstitutionAction
  | ListFacilitatorsAction
  | SetField<'selectedGroup', Group>
