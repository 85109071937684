import type { FEATURE_FLAG } from '@aula/config'
import { useAppSelector } from '@/state/hooks'

const useFeature = (feature: FEATURE_FLAG) => {
  const features = useAppSelector((state) => state.features.features)
  return features
    .filter((f) => f.value === 'true')
    .map((f) => f.name)
    .includes(feature)
}

export default useFeature
