import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/content-creator/sequenceDrawer/types/books'

const initialState: Types.BooksState = {
  status: REQUEST_STATUS.IDLE,
  data: [],
}

const drawerReducer = (
  state: Types.BooksState = initialState,
  action: Types.BooksAction
): Types.BooksState => {
  switch (action.type) {
    case Types.BOOKS_TYPES.GET_BOOKS_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.BOOKS_TYPES.GET_BOOKS_SUCCESS: {
      const { data } = action.payload

      return {
        status: REQUEST_STATUS.SUCCESS,
        data,
      }
    }
    case Types.BOOKS_TYPES.GET_BOOKS_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.BOOKS_TYPES.CLEAN_BOOKS: {
      return initialState
    }
    default:
      return state
  }
}

export default drawerReducer
