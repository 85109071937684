import type { SideDrawerContent } from '@/sections/report-card/grading/sideDrawer/containers/sideDrawerWrapper'

export interface ReportCardSideDrawerState {
  open: boolean
  drawerContent: SideDrawerContent
}

export const OPEN_DRAWER = 'REPORT_CARD/GRADING/SIDE_DRAWER/OPEN_DRAWER'
export const CLOSE_DRAWER = 'REPORT_CARD/GRADING/SIDE_DRAWER/CLOSE_DRAWER'

export type OpenDrawer = {
  type: typeof OPEN_DRAWER
  payload: { drawerContent: SideDrawerContent }
}
export type CloseDrawer = {
  type: typeof CLOSE_DRAWER
}

export type ReportCardSideDrawerAction = OpenDrawer | CloseDrawer
