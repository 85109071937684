import clsx from 'clsx'
import type { AvatarProps } from '@mui/material/Avatar'
import Avatar from '@mui/material/Avatar'
import type { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { generateRandomColor } from '@aula/tools/color'

interface StylesProps {
  avatarBackgroundColor: string
}

const useStyles = makeStyles<Theme, StylesProps>(() => ({
  avatar: ({ avatarBackgroundColor }) => ({
    background: avatarBackgroundColor,
  }),
}))

type Variant = 'circle' | 'rounded' | 'square'

interface UserAvatarProps extends AvatarProps {
  name: string
  color?: string
  variant?: Variant
  className?: string
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  name,
  color,
  variant = 'circle',
  className,
  ...props
}) => {
  const randomColor = color ?? generateRandomColor(name)
  const classes = useStyles({ avatarBackgroundColor: randomColor })
  const firstNameLetter = name.substr(0, 1).toUpperCase()
  return (
    <Avatar className={clsx(className, classes.avatar)} title={name} variant={variant} {...props}>
      {firstNameLetter}
    </Avatar>
  )
}

export default UserAvatar
