import axios from 'axios'

import { updateToken as updateTokenV2 } from '@/legacy/services/api/api'
import { onRequestError, onRequest, onResponseError, onResponse } from './interceptors'

export const apiExternal = axios.create()

export const apiPublic = axios.create()

export const apiPrivate = axios.create({
  headers: { Authorization: ' ' },
})

apiExternal.interceptors.response.use(onResponse, onResponseError)
apiPublic.interceptors.response.use(onResponse, onResponseError)
apiPrivate.interceptors.response.use(onResponse, onResponseError)

apiPublic.interceptors.request.use(onRequest, onRequestError)
apiPrivate.interceptors.request.use(onRequest, onRequestError)

export const updateToken = (token: string) => {
  apiPrivate.defaults.headers.Authorization = 'Bearer ' + token
  updateTokenV2(token)
}
