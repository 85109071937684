import type { LocaleSpecification } from 'moment'
import type { Tag } from '@/locales/types'
import moment from 'moment'
import { es, en, fr, pt, ko, zh, de, ru, tw, ja } from './translations'

export const getLocale = (locale: Tag): LocaleSpecification =>
  ({ es, en, fr, pt, ko, zh, de, ru, tw, ja }[locale])

export const updateLocale = (locale: Tag) => {
  moment.updateLocale(locale, getLocale(locale))
}
