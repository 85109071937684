import React, { useState } from 'react'
import { CircularProgress, Grid, Typography, useMediaQuery, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  isActivityFinished,
  isAwaitingApproval,
  isCompleted,
  isDelayed,
} from '@/legacy/components/v2/ribbons/states'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Teacher } from '@/legacy/svgs/activityRibbon'
import { CalendarComplete } from '@/legacy/svgs/activityRibbon'
import MailIcon from '@mui/icons-material/Mail'
import OpenMailIcon from '@mui/icons-material/Drafts'
import { CustomPopper } from '@/legacy/components/v1'
import WeekdayCalendar from '@/legacy/components/v3/weekdayCalendar'
import { useTranslation } from 'react-i18next'
import { getScoreText } from '@/legacy/utils/scores'
import { ActivityTypes } from '@/api/lms/activities/activities'
import moment from 'moment'

const useDateStyles = makeStyles((theme) => ({
  dateContainer: {
    display: 'flex',
    height: 'fit-content',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      paddingTop: 10,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 9.5,
    },
  },
  calendarContainer: {
    [theme.breakpoints.up('md')]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 8,
      '& svg': {
        height: 16,
        width: 16,
      },
    },
    '& svg g': {
      clipPath: 'none',
    },
  },
  dateLabel: {
    color: '#6D7878',
    fontSize: 14,
    lineHeight: '18px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 6,
    },
  },
  date: ({ state }) => ({
    color: isDelayed(state) ? '#F45C3A' : '#1A1A1A',
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 'bold',
  }),
  dateParent: {
    whiteSpace: 'nowrap',
  },
}))

export const DateUnit = ({ state, activity }) => {
  const classes = useDateStyles({ state })
  const typesOfLiveActivities = [
    ActivityTypes.AVV,
    ActivityTypes.EXOR,
    ActivityTypes.EXES,
    ActivityTypes.COLLAB_MINIATURE,
  ]
  const isLiveActivity = typesOfLiveActivities.includes(activity.type) || activity.robotsIsRemote
  const correctDate = isLiveActivity ? activity.startDate : activity.date

  return (
    <Grid item xs={12} md={4} className={classes.dateContainer}>
      <div className={classes.calendarContainer}>
        {isCompleted(state) ? <CalendarComplete /> : <WeekdayCalendar date={correctDate} />}
      </div>
      <Grid className={classes.dateParent}>
        <Typography className={classes.date}>{moment(correctDate).format('LL')}</Typography>
        {isLiveActivity && (
          <Typography className={classes.date}>{moment(correctDate).format('LT')}</Typography>
        )}
      </Grid>
    </Grid>
  )
}

const useProgressStyles = makeStyles((theme) => ({
  progressContainer: {
    display: 'flex',
    height: 'fit-content',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      paddingTop: 10,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 8,
    },
  },
  completedIconContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      paddingRight: 16,
    },
  },
  progressIconContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      paddingRight: 18,
      minWidth: 20,
      height: 20,
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 8,
      minWidth: 16,
      height: 16,
    },
  },
  progress: {
    position: 'absolute',
    color: '#50d073',
    zIndex: 1,
  },
  progressBackground: ({ progress }) => ({
    position: 'absolute',
    border: `4px solid ${progress <= 0 ? '#DFDFDA' : '#DCF6E3'}`,
    borderRadius: 20,
    [theme.breakpoints.up('md')]: {
      width: 16,
      height: 16,
    },
    [theme.breakpoints.down('sm')]: {
      width: 8,
      height: 8,
    },
  }),
  progressLabel: {
    color: '#6D7878',
    fontSize: 14,
    lineHeight: '18px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 6,
    },
  },
  progressNumber: {
    color: '#1A1A1A',
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 'bold',
  },
  gradeTitle: {
    color: '#6D7878',
    fontSize: 14,
    lineHeight: '18px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 6,
    },
  },
  grade: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    color: '#1A1A1A',
    width: '100px',
  },
  gradeText: {
    lineHeight: '18px',
    fontSize: 14,
    fontWeight: 'bold',
    display: 'inline-flex',
    marginRight: '4px',
  },
  iconButton: {
    padding: '0',
  },
  envelope: {
    lineHeight: '18px',
    fontSize: '16px',
  },
  feedback: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '220px',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
  },
  feedbackTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: 2,
  },
}))

export const ProgressUnit = ({ progress, state, grade, feedback }) => {
  const { t } = useTranslation()
  const classes = useProgressStyles({ progress })
  const lowerThanSM = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const higherThanMD = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleEnvelopeClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  const getProgress = () => {
    if (isCompleted(state)) return t('components.ribbon.student.activityCompleted')
    if (isAwaitingApproval(state)) return t('components.ribbon.student.awaitingApproval ')
    if (progress === 0) return '-'
    return `${progress}%`
  }

  const checkIconStyle = {
    color: '#50D174',
    ...(lowerThanSM && { fontSize: 16 }),
    ...(higherThanMD && { height: 24, width: 24 }),
  }

  const renderGradingIcon = () => <CheckCircleIcon style={checkIconStyle} />

  return (
    <Grid item xs={12} md={2} className={classes.progressContainer}>
      {feedback && (
        <CustomPopper
          isOpen={open}
          anchorEl={anchorEl}
          withArrow
          placement="bottom"
          setOpen={setOpen}
        >
          <div className={classes.feedback}>
            <span className={classes.feedbackTitle}>
              {t('components.ribbon.student.teacherComment')}
            </span>
            {feedback}
          </div>
        </CustomPopper>
      )}
      {isActivityFinished(state) ? (
        <div className={classes.completedIconContainer}>
          {isCompleted(state) && renderGradingIcon()}
          {isAwaitingApproval(state) && <Teacher />}
        </div>
      ) : (
        <div className={classes.progressIconContainer}>
          <CircularProgress
            classes={{ root: classes.progress }}
            size={lowerThanSM ? 16 : 24}
            thickness={8}
            value={progress}
            variant="determinate"
          />
          <div className={classes.progressBackground} />
        </div>
      )}
      <Grid container alignContent="flex-start">
        {!isActivityFinished(state) && (
          <Grid item md={12}>
            <Typography className={classes.progressLabel}>{t('student.progressCell')}</Typography>
          </Grid>
        )}
        <Grid item md={12}>
          {grade ? (
            <>
              <Typography className={classes.gradeTitle}>
                {t('components.ribbon.student.grade')}
              </Typography>
              <div className={classes.grade}>
                <Typography className={classes.gradeText}>{formatGrade(grade, t)}</Typography>
                {feedback && (
                  <IconButton
                    onClick={handleEnvelopeClick}
                    className={classes.iconButton}
                    aria-label="feedback"
                  >
                    {!open ? (
                      <MailIcon className={classes.envelope} />
                    ) : (
                      <OpenMailIcon className={classes.envelope} />
                    )}
                  </IconButton>
                )}
              </div>
            </>
          ) : (
            <Typography className={classes.progressNumber}>{getProgress()}</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export function formatGrade(grade, t) {
  const value = grade.unprocessedValue
  const evaluationType =
    grade.type === 'conceptual' ? 1 : grade.type === 'numeric' && grade.max === 10 ? 2 : 3
  return getScoreText(value, evaluationType, t, true)
}
