import type { AcademicPeriod } from '@/api/lms/user'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { Subject } from '@/legacy/redux/types/subjects'
import type { PendingSubject } from '@/legacy/services/api/lms/pendingSubjects/types'

export interface PendingSubjectState {
  status: REQUEST_STATUS
  error: string
  pendingSubjects: PendingSubject[]
  searchText: string
  pagination: PagesMore
  modal: {
    isOpen: boolean
    isEditMode: boolean
    createEditStatus: REQUEST_STATUS
    status: REQUEST_STATUS
    currentStep: PENDING_SUBJECTS_MODAL_STEPS
    students: {
      list: Student[]
      selectedStudents: Student[]
    }
    academic: {
      list: AcademicPeriod[]
      selectedPeriod: number
      selectedYear: string
    }
    subjects: {
      list: Subject[]
      selectedSubject: number
    }
    error: string
  }
  deleteModal: {
    isOpen: boolean
    status: REQUEST_STATUS
    error: string
  }
  selectedSubject: PendingSubject
}

export type Year = { label: string; id: number }

export type PagesMore = {
  offset: number
  limit: number
  more: boolean
}

export interface Student {
  id: number
  name: string
  lastName: string
  avatar: string
}

export enum PENDING_SUBJECTS_MODAL_STEPS {
  INITIAL = 0,
  LAST = 1,
}

export enum PENDING_SUBJECT_STATE {
  SET_OPEN_MODAL = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/SET_OPEN_MODAL',
  SET_SELECTED_STUDENT = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/SET_SELECTED_STUDENT',
  SET_CLOSE_MODAL = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/SET_CLOSE_MODAL',
  SET_CURRENT_STEP = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/SET_CURRENT_STEP',
  GET_PENDING_SUBJECT_REQUEST = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/GET_PENDING_SUBJECT_REQUEST',
  GET_PENDING_SUBJECT_SUCCESS = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/GET_PENDING_SUBJECT_SUCCESS',
  GET_PENDING_SUBJECT_FAILURE = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/GET_PENDING_SUBJECT_FAILURE',
  CREATE_PENDING_SUBJECT_REQUEST = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/CREATE_PENDING_SUBJECT_REQUEST',
  CREATE_PENDING_SUBJECT_SUCCESS = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/CREATE_PENDING_SUBJECT_SUCCESS',
  CREATE_PENDING_SUBJECT_FAILURE = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/CREATE_PENDING_SUBJECT_FAILURE',
  EDIT_PENDING_SUBJECT_REQUEST = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/EDIT_PENDING_SUBJECT_REQUEST',
  EDIT_PENDING_SUBJECT_SUCCESS = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/EDIT_PENDING_SUBJECT_SUCCESS',
  EDIT_PENDING_SUBJECT_FAILURE = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/EDIT_PENDING_SUBJECT_FAILURE',
  DELETE_PENDING_SUBJECT_REQUEST = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/DELETE_PENDING_SUBJECT_REQUEST',
  DELETE_PENDING_SUBJECT_SUCCESS = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/DELETE_PENDING_SUBJECT_SUCCESS',
  DELETE_PENDING_SUBJECT_FAILURE = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/DELETE_PENDING_SUBJECT_FAILURE',
  GET_STUDENTS_LIST_REQUEST = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/GET_STUDENTS_LIST_REQUEST',
  GET_STUDENTS_LIST_SUCCESS = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/GET_STUDENTS_LIST_SUCCESS',
  GET_STUDENTS_LIST_FAILURE = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/GET_STUDENTS_LIST_FAILURE',
  GET_SUBJECTS_LIST_REQUEST = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/GET_SUBJECTS_LIST_REQUEST',
  GET_SUBJECTS_LIST_SUCCESS = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/GET_SUBJECTS_LIST_SUCCESS',
  GET_SUBJECTS_LIST_FAILURE = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/GET_SUBJECTS_LIST_FAILURE',
  SET_SEARCH_TEXT = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/SET_SEARCH_TEXT',
  SET_ACADEMIC_PERIOD = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/SET_ACADEMIC_PERIOD',
  SET_YEAR_SELECTED = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/SET_YEAR_SELECTED',
  SET_PERIOD_SELECTED = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/SET_PERIOD_SELECTED',
  SET_SUBJECT_SELECTED = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/SET_SUBJECT_SELECTED',
  CLEAR_MODAL_STATE = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/CLEAR_MODAL_STATE',
  SET_OPEN_DELETE_MODAL = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/SET_OPEN_DELETE_MODAL',
  SET_CLOSE_DELETE_MODAL = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/SET_CLOSE_DELETE_MODAL',
  SET_SELECTED_SUBJECT = 'REPORT_CARD/GRADING/TABS/PENDING_SUBJECT/SET_SELECTED_SUBJECT',
}

export type GetPendingSubjectRequestAction = {
  type: PENDING_SUBJECT_STATE.GET_PENDING_SUBJECT_REQUEST
}

export type GetPendingSubjectSuccessAction = {
  type: PENDING_SUBJECT_STATE.GET_PENDING_SUBJECT_SUCCESS
  payload: { pendingSubjects: PendingSubject[]; count: number; more: boolean; page: number }
}

export type GetPendingSubjectFailureAction = {
  type: PENDING_SUBJECT_STATE.GET_PENDING_SUBJECT_FAILURE
  payload: { error: string }
}

export type GetStudentsListRequestAction = {
  type: PENDING_SUBJECT_STATE.GET_STUDENTS_LIST_REQUEST
}

export type GetStudentsListSuccessAction = {
  type: PENDING_SUBJECT_STATE.GET_STUDENTS_LIST_SUCCESS
  payload: { students: Student[]; count: number; more: boolean; page: number }
}

export type GetStudentsListFailureAction = {
  type: PENDING_SUBJECT_STATE.GET_STUDENTS_LIST_FAILURE
  payload: { error: string }
}

export type GetSubjectsListRequestAction = {
  type: PENDING_SUBJECT_STATE.GET_SUBJECTS_LIST_REQUEST
}

export type GetSubjectsListSuccessAction = {
  type: PENDING_SUBJECT_STATE.GET_SUBJECTS_LIST_SUCCESS
  payload: { subjects: Subject[] }
}

export type GetSubjectsListFailureAction = {
  type: PENDING_SUBJECT_STATE.GET_SUBJECTS_LIST_FAILURE
  payload: { error: string }
}

export type SetSearchTextAction = {
  type: PENDING_SUBJECT_STATE.SET_SEARCH_TEXT
  payload: { searchText: string }
}

export type SetAcademicPeriodsAction = {
  type: PENDING_SUBJECT_STATE.SET_ACADEMIC_PERIOD
  payload: { academicPeriods: AcademicPeriod[] }
}

export type SetCurrentStepAction = {
  type: PENDING_SUBJECT_STATE.SET_CURRENT_STEP
  payload: { currentStep: PENDING_SUBJECTS_MODAL_STEPS }
}

export type SetOpenModalAction = {
  type: PENDING_SUBJECT_STATE.SET_OPEN_MODAL
}

export type SetCloseModalAction = {
  type: PENDING_SUBJECT_STATE.SET_CLOSE_MODAL
}

export type SetOpenDeleteModalAction = {
  type: PENDING_SUBJECT_STATE.SET_OPEN_DELETE_MODAL
}

export type SetCloseDeleteModalAction = {
  type: PENDING_SUBJECT_STATE.SET_CLOSE_DELETE_MODAL
}

export type SetSelectedStudentAction = {
  type: PENDING_SUBJECT_STATE.SET_SELECTED_STUDENT
  payload: { student: Student }
}

export type ClearModalStateAction = {
  type: PENDING_SUBJECT_STATE.CLEAR_MODAL_STATE
}

export type SetYearSelectedAction = {
  type: PENDING_SUBJECT_STATE.SET_YEAR_SELECTED
  payload: { year: string }
}

export type SetSubjectSelectedAction = {
  type: PENDING_SUBJECT_STATE.SET_SUBJECT_SELECTED
  payload: { subject: number }
}

export type SetPeriodSelectedAction = {
  type: PENDING_SUBJECT_STATE.SET_PERIOD_SELECTED
  payload: { period: number }
}

export type CreatePendingSubjectsRequestAction = {
  type: PENDING_SUBJECT_STATE.CREATE_PENDING_SUBJECT_REQUEST
}

export type CreatePendingSubjectsSuccessAction = {
  type: PENDING_SUBJECT_STATE.CREATE_PENDING_SUBJECT_SUCCESS
}

export type CreatePendingSubjectsFailureAction = {
  type: PENDING_SUBJECT_STATE.CREATE_PENDING_SUBJECT_FAILURE
  payload: { error: string }
}

export type EditPendingSubjectsRequestAction = {
  type: PENDING_SUBJECT_STATE.EDIT_PENDING_SUBJECT_REQUEST
}

export type EditPendingSubjectsSuccessAction = {
  type: PENDING_SUBJECT_STATE.EDIT_PENDING_SUBJECT_SUCCESS
}

export type EditPendingSubjectsFailureAction = {
  type: PENDING_SUBJECT_STATE.EDIT_PENDING_SUBJECT_FAILURE
  payload: { error: string }
}

export type DeletePendingSubjectsRequestAction = {
  type: PENDING_SUBJECT_STATE.DELETE_PENDING_SUBJECT_REQUEST
}

export type DeletePendingSubjectsSuccessAction = {
  type: PENDING_SUBJECT_STATE.DELETE_PENDING_SUBJECT_SUCCESS
}

export type DeletePendingSubjectsFailureAction = {
  type: PENDING_SUBJECT_STATE.DELETE_PENDING_SUBJECT_FAILURE
  payload: { error: string }
}

export type SetSelecteSubjectAction = {
  type: PENDING_SUBJECT_STATE.SET_SELECTED_SUBJECT
  payload: { selectedSubject: PendingSubject; isEditMode: boolean }
}

export type PendingSubjectActions =
  | CreatePendingSubjectsRequestAction
  | CreatePendingSubjectsSuccessAction
  | CreatePendingSubjectsFailureAction
  | EditPendingSubjectsRequestAction
  | EditPendingSubjectsSuccessAction
  | EditPendingSubjectsFailureAction
  | DeletePendingSubjectsRequestAction
  | DeletePendingSubjectsSuccessAction
  | DeletePendingSubjectsFailureAction
  | SetYearSelectedAction
  | SetPeriodSelectedAction
  | SetSubjectSelectedAction
  | SetSearchTextAction
  | GetPendingSubjectRequestAction
  | GetPendingSubjectSuccessAction
  | GetPendingSubjectFailureAction
  | GetStudentsListRequestAction
  | GetStudentsListSuccessAction
  | GetStudentsListFailureAction
  | GetSubjectsListRequestAction
  | GetSubjectsListSuccessAction
  | GetSubjectsListFailureAction
  | SetSelectedStudentAction
  | ClearModalStateAction
  | SetAcademicPeriodsAction
  | SetCurrentStepAction
  | SetOpenModalAction
  | SetCloseModalAction
  | SetOpenDeleteModalAction
  | SetCloseDeleteModalAction
  | SetSelecteSubjectAction
