import type { Moment } from 'moment'
import type { ClearState, ToggleOpen } from '@/legacy/redux/types/generic'
import type { GenericAction, GenericActionWithType, GenericPayload } from '@/legacy/redux/utils'
import type { SaveOrEditReportCardConfigSuccess } from '@/sections/report-card/config/types/apiInteractions'
import type { ReportCardPeriod } from '@/sections/report-card/config/types/entities'

export interface ReportCardConfigPeriodsState {
  periods: ReportCardPeriod[]
  extraPeriods: ReportCardPeriod[]
  newPeriodOpen: boolean
  newPeriodIsExtra: boolean
  newPeriod: ReportCardPeriod
  newExtraPeriod: ReportCardPeriod
  finalMark: boolean
}

export const SET_PERIODS = 'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/PERIODS/SET_PERIODS'
interface SetPeriodsPayload extends GenericPayload {
  periods: ReportCardPeriod[]
  isExtra: boolean
}
export interface SetPeriods extends GenericAction {
  type: typeof SET_PERIODS
  payload: SetPeriodsPayload
}

export const SET_NEW_PERIOD_OPEN =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/PERIODS/SET_NEW_PERIOD_OPEN'
interface SetNewPeriodOpenPayload extends GenericPayload {
  open: boolean
  isExtra: boolean
}
export interface SetNewPeriodOpen extends GenericAction {
  type: typeof SET_NEW_PERIOD_OPEN
  payload: SetNewPeriodOpenPayload
}

export const SET_NEW_PERIOD_NAME =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/PERIODS/SET_NEW_PERIOD_NAME'
interface SetNewPeriodNamePayload extends GenericPayload {
  name: string
  isExtra: boolean
}
export interface SetNewPeriodName extends GenericAction {
  type: typeof SET_NEW_PERIOD_NAME
  payload: SetNewPeriodNamePayload
}

export const SET_NEW_PERIOD_START_DATE =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/PERIODS/SET_NEW_PERIOD_START_DATE'

interface SetNewPeriodStartDatePayload extends GenericPayload {
  startDate: Moment
  isExtra: boolean
}
export interface SetNewPeriodStartDate extends GenericAction {
  type: typeof SET_NEW_PERIOD_START_DATE
  payload: SetNewPeriodStartDatePayload
}
export const SET_NEW_PERIOD_END_DATE =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/PERIODS/SET_NEW_PERIOD_END_DATE'

interface SetNewPeriodEndDatePayload extends GenericPayload {
  endDate: Moment
  isExtra: boolean
}
export interface SetNewPeriodEndDate extends GenericAction {
  type: typeof SET_NEW_PERIOD_END_DATE
  payload: SetNewPeriodEndDatePayload
}

export const ADD_NEW_PERIOD = 'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/PERIODS/ADD_NEW_PERIOD'

export interface AddNewPeriod extends GenericAction {
  type: typeof ADD_NEW_PERIOD
}

export const ADD_NEW_SUPPORT_PERIOD =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/PERIODS/ADD_NEW_SUPPORT_PERIOD'

export interface AddNewSupportPeriod extends GenericAction {
  type: typeof ADD_NEW_SUPPORT_PERIOD
}

export const SET_FINAL_MARK = 'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/PERIODS/SET_FINAL_MARK'
interface AddFinalMark extends GenericPayload {
  finalMark: boolean
}
export interface SetFinalMark extends GenericAction {
  type: typeof SET_FINAL_MARK
  payload: AddFinalMark
}

export const SET_VALUES_FOR_EDITION =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/PERIODS/SET_VALUES_FOR_EDITION'

interface SetValuesForEditionPayload extends GenericPayload {
  periodsState: ReportCardConfigPeriodsState
}

export type SetValuesForEdition = GenericActionWithType<
  typeof SET_VALUES_FOR_EDITION,
  SetValuesForEditionPayload
>

export type ReportCardConfigPeriodAction =
  | ToggleOpen
  | SetPeriods
  | SetNewPeriodEndDate
  | SetNewPeriodStartDate
  | SetNewPeriodOpen
  | SetNewPeriodName
  | AddNewPeriod
  | AddNewSupportPeriod
  | SaveOrEditReportCardConfigSuccess
  | ClearState
  | SetFinalMark
  | SetValuesForEdition
