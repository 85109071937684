import { Avatar, Grid, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { Button, CustomTooltip } from '@/legacy/components/v1'
import { isEmpty } from 'ramda'
import { useTranslation } from 'react-i18next'
import { Messages, NewMessages } from '@/legacy/svgs/activityRibbon'
import { theme } from '@aula/config'
import ConditionalTooltip from '@/legacy/components/conditionalTooltip'
import SubjectChip from '@/legacy/components/v2/subjectChip.jsx'
import {
  iconAnimations,
  iconCss,
  stateAnimations,
  stateCss,
} from '@/legacy/components/v2/ribbons/animations'
import {
  isCompleted,
  isNew,
  shouldShowStateLabel,
  states,
} from '@/legacy/components/v2/ribbons/states'
import { DateUnit, ProgressUnit } from './units'
import { ActivityTypes } from '@/api/lms/activities/activities'
import { TermsAndConditionsExamModal } from './termsAndConditionsExamModal'
import { useState } from 'react'

const useStyles = makeStyles((theme) => ({
  ...iconAnimations,
  root: {
    width: 'inherit',
    flexDirection: 'row',
    boxSizing: 'border-box',
    border: '1px solid #D8DBE8',
    borderRadius: 8,
    '&:hover': {
      ...iconCss,
    },
  },
  activityContainer: ({ state, disabled }) => ({
    display: 'flex',
    background: isCompleted(state) ? '#F8F8F6' : isNew(state) ? '#F4FAFF' : 'inherit',
    borderRadius: 8,
    cursor: disabled ? 'default' : 'pointer',
    transition: 'background-color 100ms',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
    [theme.breakpoints.up('md')]: {
      padding: 16,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 12,
    },
  }),
  avatarContainer: {
    width: 'fit-content',
  },
  avatar: {
    background: 'transparent',
    [theme.breakpoints.up('md')]: {
      height: 56,
      width: 56,
    },
    [theme.breakpoints.down('sm')]: {
      height: 40,
      width: 40,
    },
  },
  contentContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 24,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 8,
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  type: {
    fontSize: 12,
    lineHeight: '16px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  title: {
    fontWeight: 500,
    color: '#1A1A1A',
    wordBreak: 'break-word',
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
      lineHeight: '23px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '21px',
    },
  },
  ...stateAnimations,
  state: {
    marginTop: 10,
    padding: '2px 4px',
    width: 'fit-content',
    borderRadius: 3,
    overflow: 'hidden',
    ...stateCss,
  },
  stateText: {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '16px',
    whiteSpace: 'nowrap',
  },
  disabled: {
    color: '#a3a7a8',
  },
  buttonContainer: {
    paddingTop: 8,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 0,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 8,
    },
    textAlign: 'right',
  },
  button: {
    background: theme.palette.primary.main,
    boxShadow: '0 0 0 1px ' + theme.palette.primary.main,
    paddingTop: 9.5,
    paddingBottom: 9.5,
  },
  subjects: {
    borderTop: '1px solid #DFDFDA',
    padding: '8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  messagesContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    gap: 7,
  },
  querieText: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '13px',
    color: theme.palette.text.secondary,
  },
  messages: {
    marginLeft: 8,
    color: '#1A1A1A',
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 'bold',
  },
  disabledMessagesContainer: {
    cursor: 'default',
    display: 'flex',
    gap: 7,
    alignItems: 'center',
    justifyContent: 'center',
  },
  querieEnabledIcon: {
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
  disabledMessages: {
    '& path': {
      fill: '#d8d8d8',
    },
  },
  subjectChipContainer: {
    marginRight: 10,
    overflow: 'hidden',
  },
}))

const ActivityRibbonBase = (props) => {
  const {
    id,
    Icon,
    type,
    subject,
    title,
    date,
    progress,
    grade,
    buttonLabel,
    buttonClick,
    subjectClick,
    messageClick,
    buttonEnabled,
    state,
    buttonProps,
    showMessages,
    messages,
    hideProgress,
    messagesDisabledTooltip,
    feedback,
    buttonDisabledReason,
    unparsedDate,
    activity,
  } = props
  const { IconLeft, iconLeftClassname = '' } = buttonProps
  const [isOpen, setIsOpen] = useState(false)
  const completed = typeof props.completed === 'undefined' ? progress === 100 : props.completed
  const { t } = useTranslation()
  const disabled = !buttonEnabled
  const classes = useStyles({ progress, completed, state, disabled })

  const onToggleModal = () => {
    setIsOpen(!isOpen)
  }

  const handleButtonClick = () => {
    if (disabled) return
    if (activity.type === ActivityTypes.EXES) {
      onToggleModal()
      return
    }
    buttonClick()
  }
  return (
    <Paper elevation={0} classes={{ root: clsx(classes.root) }} id={`student-ribbon-${id}`}>
      <div className={classes.activityContainer} onClick={disabled ? () => {} : handleButtonClick}>
        <div className={classes.avatarContainer}>
          <Avatar variant="rounded" className={classes.avatar}>
            {Icon !== undefined && <Icon />}
          </Avatar>
        </div>
        <Grid container>
          <Grid item container xs={12} sm={9} className={classes.contentContainer}>
            <Grid item xs={12} md={6} className={classes.titleContainer}>
              <Typography className={classes.type}>{t(type)}</Typography>
              <Typography className={classes.title}>{title}</Typography>
              {shouldShowStateLabel(state) && (
                <div
                  className={classes.state}
                  style={{
                    background: isNew(state) ? theme.palette.secondary.main : '#FEEFEC',
                    color: isNew(state) ? 'white' : '#F45C3A',
                  }}
                >
                  <Typography className={classes.stateText}>
                    {isNew(state)
                      ? t('components.ribbon.student.new')
                      : t('components.ribbon.student.delayed')}
                  </Typography>
                </div>
              )}
            </Grid>
            <DateUnit state={state} activity={activity} />
            {!hideProgress && (
              <ProgressUnit state={state} progress={progress} grade={grade} feedback={feedback} />
            )}
          </Grid>
          <Grid item xs={12} sm={3} className={classes.buttonContainer}>
            {!isCompleted(state) && (
              <ConditionalTooltip
                title={buttonDisabledReason}
                withTooltip={disabled && !isEmpty(buttonDisabledReason)}
              >
                <Button
                  id={`student-activity-ribbon-button-${id}`}
                  IconLeft={IconLeft}
                  iconLeftClassname={iconLeftClassname}
                  dense
                  className={classes.button}
                  disabled={disabled}
                >
                  {t(buttonLabel)}
                </Button>
              </ConditionalTooltip>
            )}
          </Grid>
        </Grid>
      </div>
      <div className={classes.subjects}>
        <div
          id="student-activity-ribbon-button"
          className={classes.subjectChipContainer}
          onClick={() => subjectClick(subject)}
        >
          <SubjectChip subject={subject} />
        </div>
        {showMessages ? (
          <CustomTooltip title={t('chats.openChatClass')}>
            <div className={classes.messagesContainer} onClick={() => messageClick(id)}>
              <span className={classes.querieText}>{t('chats.queriesEnabled')}</span>
              <div className={classes.querieEnabledIcon}>
                {messages > 0 ? <NewMessages /> : <Messages />}
                {messages > 0 && (
                  <Typography className={classes.messages}>
                    {`¡${t('chats.newMessage', { messageCount: messages })}${
                      messages >= 2 ? t('chats.letterS') : ''
                    }!`}
                  </Typography>
                )}
              </div>
            </div>
          </CustomTooltip>
        ) : (
          <div className={classes.disabledMessagesContainer}>
            <span className={classes.querieText}>{t('chats.queriesDisabled')}</span>
            <CustomTooltip title={t(messagesDisabledTooltip)}>
              <Messages className={classes.disabledMessages} />
            </CustomTooltip>
          </div>
        )}
      </div>
      <TermsAndConditionsExamModal
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        onHandleClick={buttonClick}
      />
    </Paper>
  )
}

ActivityRibbonBase.defaultProps = {
  subject: {},
  state: states.DEFAULT,
  buttonEnabled: true,
  buttonProps: {},
  messages: 0,
  completedLabel: 'teacher.activities.completedLabel',
  messagesDisabledTooltip: 'components.ribbon.student.messagesDisabledTooltip',
  buttonDisabledReason: '',
}

export default ActivityRibbonBase
