import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import { SET_INITIAL_VALUES, ConfirmFinishExamModalAction, ConfirmFinishExamModalState } from '@/sections/management/types/confirmFinishExamModal'
import {
  EDIT_EXAMS_AS_ADMIN_REQUEST,
  EDIT_EXAMS_AS_ADMIN_SUCCESS,
  EDIT_EXAMS_AS_ADMIN_FAILURE,
} from '@/sections/eb-admin/management/types/examsDrawer'

const initialState: ConfirmFinishExamModalState = {
  open: false,
  loading: false,
  error: '',
  examToFinish: {
    id: '',
    name: '',
    aulaId: '',
    organizationId: '',
    exam: {
      datetime: '',
      deadline: '',
      duration: 0,
      games: [],
      theme: 0,
      type: 0,
      stage: 0,
    },
  },
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_FINISH_EXAM_CONFIRM_MODAL

const ConfirmFinishExamModalReducer: Reducer<
  ConfirmFinishExamModalState,
  ConfirmFinishExamModalAction
> = (state = initialState, action): ConfirmFinishExamModalState => {
  switch (action.type) {
    case SET_INITIAL_VALUES: {
      return {
        ...state,
        open: true,
        examToFinish: action.payload.examToFinish,
      }
    }
    case EDIT_EXAMS_AS_ADMIN_REQUEST:
      return { ...state, loading: true }
    case Redux.Types.Generic.CLEAR_STATE:
    case EDIT_EXAMS_AS_ADMIN_SUCCESS:
      return initialState
    case EDIT_EXAMS_AS_ADMIN_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, ConfirmFinishExamModalReducer, requestDestination)
