import type { ClassroomResponse } from '@/api/report-card/bulletinsAudit'

export interface GenerateBulletinsModalState {
  isOpenModal: boolean
  isLoading: boolean
  error: Error | null
  periods: { name: string }[]
  classrooms: ClassroomResponse[]
  selectedClassrooms: ClassroomResponse[]
  selectedPeriod: string
  currentStep: GenerateBulletinsSteps
  typeOfConfig: TypeOfConfig | ''
  sections: {
    enableScores: boolean
    enableAttitudinal: boolean
    enableCompetences: boolean
    enableReviews: boolean
    enableAttendances: boolean
  }
  pagination: {
    limit: number
    offset: number
    more: boolean
  }
}

export enum GenerateBulletinsSteps {
  INITIAL = 0,
  FIRST = 1,
  SECOND = 2,
}

export enum TypeOfConfig {
  SEND = 'send',
  EXPORT = 'export',
}

export enum GENERATE_BULLETINS_MODAL_TYPES {
  OPEN_MODAL = 'REPORT_CARD/GENERATE_BULLETINS_MODAL/OPEN_MODAL',
  CLOSE_MODAL = 'REPORT_CARD/GENERATE_BULLETINS_MODAL/CLOSE_MODAL',
  GET_PERIODS_REQUEST = 'REPORT_CARD/GENERATE_BULLETINS_MODAL/GET_PERIODS_REQUEST',
  GET_PERIODS_SUCCESS = 'REPORT_CARD/GENERATE_BULLETINS_MODAL/GET_PERIODS_SUCCESS',
  GET_PERIODS_FAILURE = 'REPORT_CARD/GENERATE_BULLETINS_MODAL/GET_PERIODS_FAILURE',
  GET_CLASSROOMS_REQUEST = 'REPORT_CARD/GENERATE_BULLETINS_MODAL/GET_CLASSROOMS_REQUEST',
  GET_CLASSROOMS_SUCCESS = 'REPORT_CARD/GENERATE_BULLETINS_MODAL/GET_CLASSROOMS_SUCCESS',
  GET_CLASSROOMS_FAILURE = 'REPORT_CARD/GENERATE_BULLETINS_MODAL/GET_CLASSROOMS_FAILURE',
  SET_SELECTED_PERIOD = 'REPORT_CARD/GENERATE_BULLETINS_MODAL/SET_SELECTED_PERIOD',
  SET_CURRENT_STEP = 'REPORT_CARD/GENERATE_BULLETINS_MODAL/SET_CURRENT_STEP',
  SET_TYPE_OF_CONFIG = 'REPORT_CARD/GENERATE_BULLETINS_MODAL/SET_TYPE_OF_CONFIG',
  SET_SECTION = 'REPORT_CARD/GENERATE_BULLETINS_MODAL/SET_SECTION',
  SET_SELECTED_CLASSROOMS = 'REPORT_CARD/GENERATE_BULLETINS_MODAL/SET_SELECTED_CLASSROOMS',
  SELECT_ALL_CLASSROOMS = 'REPORT_CARD/GENERATE_BULLETINS_MODAL/SELECT_ALL_CLASSROOMS',
  GENERATE_BULLETINS_REQUEST = 'REPORT_CARD/GENERATE_BULLETINS_MODAL/GENERATE_BULLETINS_REQUEST',
  GENERATE_BULLETINS_SUCCESS = 'REPORT_CARD/GENERATE_BULLETINS_MODAL/GENERATE_BULLETINS_SUCCESS',
  GENERATE_BULLETINS_FAILURE = 'REPORT_CARD/GENERATE_BULLETINS_MODAL/GENERATE_BULLETINS_FAILURE',
  SET_PAGINATION_OFFSET = 'REPORT_CARD/GENERATE_BULLETINS_MODAL/SET_PAGINATION_OFFSET',
}

// Modal actions
export type OpenModalAction = {
  type: GENERATE_BULLETINS_MODAL_TYPES.OPEN_MODAL
}

export type CloseModalAction = {
  type: GENERATE_BULLETINS_MODAL_TYPES.CLOSE_MODAL
}

// Get periods actions
export type GetPeriodsRequestAction = {
  type: GENERATE_BULLETINS_MODAL_TYPES.GET_PERIODS_REQUEST
}

export type GetPeriodsSuccessAction = {
  type: GENERATE_BULLETINS_MODAL_TYPES.GET_PERIODS_SUCCESS
  payload: {
    periods: { name: string }[]
  }
}

export type GetPeriodsFailureAction = {
  type: GENERATE_BULLETINS_MODAL_TYPES.GET_PERIODS_FAILURE
  payload: {
    error: Error
  }
}

export type GetPeriodsActions =
  | GetPeriodsRequestAction
  | GetPeriodsSuccessAction
  | GetPeriodsFailureAction

// Get classrooms actions
export type GetClassroomsRequestAction = {
  type: GENERATE_BULLETINS_MODAL_TYPES.GET_CLASSROOMS_REQUEST
}

export type GetClassroomsSuccessAction = {
  type: GENERATE_BULLETINS_MODAL_TYPES.GET_CLASSROOMS_SUCCESS
  payload: {
    classrooms: ClassroomResponse[]
    more: boolean
  }
}

export type GetClassroomsFailureAction = {
  type: GENERATE_BULLETINS_MODAL_TYPES.GET_CLASSROOMS_FAILURE
  payload: {
    error: Error
  }
}

export type GetClassroomsAction =
  | GetClassroomsRequestAction
  | GetClassroomsSuccessAction
  | GetClassroomsFailureAction

// Set current step modal action
export type SetCurrentStepAction = {
  type: GENERATE_BULLETINS_MODAL_TYPES.SET_CURRENT_STEP
  payload: {
    newStep: GenerateBulletinsSteps
  }
}

// Set selected period action
export type SetSelectedPeriodAction = {
  type: GENERATE_BULLETINS_MODAL_TYPES.SET_SELECTED_PERIOD
  payload: {
    newPeriod: string
  }
}

// Set type of config action
export type SetTypeOfConfigAction = {
  type: GENERATE_BULLETINS_MODAL_TYPES.SET_TYPE_OF_CONFIG
  payload: {
    newTypeOfConfig: TypeOfConfig
  }
}

// Set section action
export type SetSectionAction = {
  type: GENERATE_BULLETINS_MODAL_TYPES.SET_SECTION
  payload: {
    sectionKey: keyof GenerateBulletinsModalState['sections'] | 'all'
    canEnableSection: boolean
  }
}

// Generate bulletins actions
export type GenerateBulletinsRequestAction = {
  type: GENERATE_BULLETINS_MODAL_TYPES.GENERATE_BULLETINS_REQUEST
}

export type GenerateBulletinsSuccessAction = {
  type: GENERATE_BULLETINS_MODAL_TYPES.GENERATE_BULLETINS_SUCCESS
}

export type GenerateBulletinsFailureAction = {
  type: GENERATE_BULLETINS_MODAL_TYPES.GENERATE_BULLETINS_FAILURE
  payload: {
    error: Error
  }
}

export type GenerateBulletinsAction =
  | GenerateBulletinsRequestAction
  | GenerateBulletinsSuccessAction
  | GenerateBulletinsFailureAction

// Set selected classrooms ids action
export type SetSelectedClassroomsAction = {
  type: GENERATE_BULLETINS_MODAL_TYPES.SET_SELECTED_CLASSROOMS
  payload: { classroom: ClassroomResponse }
}

// Set pagination offset action
export type SetPaginationOffsetAction = {
  type: GENERATE_BULLETINS_MODAL_TYPES.SET_PAGINATION_OFFSET
  payload: {
    offsetType: OffsetType
  }
}

export enum OffsetType {
  PREV = 'prev',
  NEXT = 'next',
}

// Select all classrooms action
export type SelectAllClassroomsAction = {
  type: GENERATE_BULLETINS_MODAL_TYPES.SELECT_ALL_CLASSROOMS
  payload: {
    canSelectAll: boolean
  }
}

// Actions
export type GenerateBulletinsModalAction =
  | OpenModalAction
  | CloseModalAction
  | GetPeriodsActions
  | GetClassroomsAction
  | SetCurrentStepAction
  | SetSelectedPeriodAction
  | SetTypeOfConfigAction
  | SetSectionAction
  | GenerateBulletinsAction
  | SetSelectedClassroomsAction
  | SetPaginationOffsetAction
  | SelectAllClassroomsAction
