import type { GenericActionWithType } from '@/legacy/redux/utils'
import type { DeleteExamAsMgmtAction } from '@/sections/eb-admin/management/types/examsDrawer'
import type { ClearState } from '@/legacy/redux/types/generic'

export type DeleteExamConfirmModalState = {
  examId: string | null
  open: boolean
  loading: boolean
  error: string
}

// Set initial values
export const SET_INITIAL_VALUES = 'MANAGEMENT/EXAMS/DELETE_MODAL/SET_INITIAL_VALUES'

// Set initial values
export type SetInitialValues = GenericActionWithType<
  typeof SET_INITIAL_VALUES,
  {
    examId: string
  }
>

export type DeleteExamConfirmModalAction = DeleteExamAsMgmtAction | SetInitialValues | ClearState
