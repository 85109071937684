import { makeStyles } from '@mui/styles'
import { CustomTooltip } from '@/legacy/components/v1'

const useStyles = makeStyles((theme) => ({
  container: ({ onClick }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    cursor: onClick ? 'pointer' : 'default',
  }),
  iconLabel: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    marginLeft: 8,
  },
}))

const LabeledIcon = ({
  Icon,
  iconProps = {},
  label = '',
  labelProps = {},
  type = 'tooltip',
  tooltipProps = {},
  onClick = () => {},
}) => {
  const classes = useStyles({ onClick })

  if (type === 'tooltip') {
    return (
      <CustomTooltip title={label} {...tooltipProps}>
        <Icon
          {...iconProps}
          onClick={onClick}
          style={{ cursor: onClick ? 'pointer' : 'default' }}
        />
      </CustomTooltip>
    )
  }

  if (type === 'label') {
    return (
      <div className={classes.container} onClick={onClick}>
        <Icon {...iconProps} />
        <span className={classes.iconLabel} {...labelProps}>
          {label}
        </span>
      </div>
    )
  }

  return null
}

export default LabeledIcon
