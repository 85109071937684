import type { Moment } from 'moment'

export const clampText = (str: string, limit: number, ellipsis = '...') =>
  str.length > limit ? `${str.substr(0, limit)}${ellipsis}` : str

export const replaceEmptyForDash = (text: string | number): string =>
  text && text.toString()?.trim() !== '' ? text.toString() : '-'

export const formatMomentOrDash = (moment: Moment, format: string): string =>
  moment.isValid() ? moment.format(format) : '-'

export const capitalizeEveryWord = (string: string): string => {
  return string.toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())
}

/**
 * Convierte un valor string 'true' or 'false' a booleano.
 * @example stringToBoolean('true') // true
 * @example stringToBoolean('false') // false
 */
export const stringToBoolean = (str: 'true' | 'false') => str === 'true'
