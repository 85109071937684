import { combineReducers } from 'redux'
import addEditModal from './addEditSubjectModal'
import deleteConfirmModal from './deleteSubjectConfirmModal'

const subjectsReducer = combineReducers({
  addEditModal,
  deleteConfirmModal,
})

export default subjectsReducer
