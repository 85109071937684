import type { Theme } from '@mui/material'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import type { AttendanceStatus } from '@/api/attendances/config'
import {
  ABSCENCES_CELL_HEIGHT,
  EMPTY_SPACE_HEIGHT,
} from '@/sections/report-card/grading/components/tabs/abscencesDrawer'

const useStyles = makeStyles(() => ({
  emptySpace: {
    height: EMPTY_SPACE_HEIGHT,
  },
}))

interface StatusStylesProps {
  color: string
}

const useStatusStyles = makeStyles<Theme, StatusStylesProps>((theme) => ({
  colorDot: {
    height: 8,
    width: 8,
    borderRadius: 4,
    background: ({ color }) => color,
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    height: ABSCENCES_CELL_HEIGHT + 33,
  },
}))

export interface StatusesNamesProps {
  attendanceStatuses: AttendanceStatus[]
}

export default function StatusesNames({ attendanceStatuses }: StatusesNamesProps) {
  return (
    <div>
      <EmptySpace />
      {attendanceStatuses.map((status) => {
        return <StatusName status={status} />
      })}
    </div>
  )
}

interface StatusNameProps {
  status: AttendanceStatus
}

export function StatusName({ status }: StatusNameProps) {
  const { color, description } = status
  const C = useStatusStyles({ color })

  return (
    <div className={C.statusContainer}>
      <div className={C.colorDot}></div>
      <Typography>{description}</Typography>
    </div>
  )
}

function EmptySpace() {
  const C = useStyles()
  return <div className={C.emptySpace}></div>
}
