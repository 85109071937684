import type { Pages } from '@/legacy/components/v3/moreTablePagination'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { Student } from '@/sections/report-card/grading/types/tabs/pendingSubjects'
import type * as StudentsDrawerTypes from './studentsDrawer'

export interface MigrationClassroomsState {
  oldClassrooms: Classroom[]
  newClassrooms: Classroom[]
  pagination: Pages
  oldClassroomStatus: REQUEST_STATUS
  newClassroomStatus: REQUEST_STATUS
  migrationCompletionStatus: REQUEST_STATUS
  classroomsToMigrate: ClassroomToMigrate[]
  migrations: Migration[]
  error: string
  classroomVerification: {
    studentsList: StudentWithClassroom[]
    status: REQUEST_STATUS
    error: string
  }
  alertModal: {
    isOpen: boolean
  }
}

export type StudentWithClassroom = {
  student: Student
  classroom: Classroom | null
  toClassroomId: number
  isNew: boolean
}

export const GET_CLASSROOMS_REQUEST = 'CLASSROOM_MIGRATION/GET_CLASSROOMS_REQUEST'
export const GET_CLASSROOMS_SUCCESS = 'CLASSROOM_MIGRATION/GET_CLASSROOMS_SUCCESS'
export const GET_CLASSROOMS_FAILURE = 'CLASSROOM_MIGRATION/GET_CLASSROOMS_FAILURE'

export const COMPLETE_MIGRATION_REQUEST = 'CLASSROOM_MIGRATION/COMPLETE_MIGRATION_REQUEST'
export const COMPLETE_MIGRATION_SUCCESS = 'CLASSROOM_MIGRATION/COMPLETE_MIGRATION_SUCCESS'
export const COMPLETE_MIGRATION_FAILURE = 'CLASSROOM_MIGRATION/COMPLETE_MIGRATION_FAILURE'

export const SET_CLASSROOM_TO_MIGRATE = 'CLASSROOM_MIGRATION/SET_CLASSROOM_TO_MIGRATE'
export const SET_CLASSROOM_TO_MIGRATE_V2 = 'CLASSROOM_MIGRATION/SET_CLASSROOM_TO_MIGRATE_V2'

export const GET_CLASSROOM_STUDENTS_REQUEST = 'CLASSROOM_MIGRATION/GET_CLASSROOM_STUDENTS_REQUEST'
export const GET_CLASSROOM_STUDENTS_SUCCESS = 'CLASSROOM_MIGRATION/GET_CLASSROOM_STUDENTS_SUCCESS'
export const GET_CLASSROOM_STUDENTS_FAILURE = 'CLASSROOM_MIGRATION/GET_CLASSROOM_STUDENTS_FAILURE'

export const SET_ALERT_MODAL_OPEN = 'CLASSROOM_MIGRATION/SET_ALERT_MODAL_OPEN'
export const SET_ALERT_MODAL_CLOSE = 'CLASSROOM_MIGRATION/SET_ALERT_MODAL_CLOSE'
export const CLEAR_MIGRATION_STATE = 'CLASSROOM_MIGRATION/CLEAR_MIGRATION_STATE'

export const MIGRATION_CLASSROOMS_LIMIT = 30

export type ClassroomAge = 'old' | 'new'

export type Migration = {
  fromClassroomsList: Classroom[]
  toClassroom: Classroom
  studentsToRemove: number[]
  studentsToAdd: number[]
  students: number[]
  verified: boolean
}

export type Classroom = {
  id: number
  stage: string
  grade: string
  division: string
  shift: string
  year: number
  organizationId: number
  organizationName: string
  studentsCount: number
}

export type ClassroomToMigrate = {
  oldClassroom: Classroom
  newClassroom: Classroom
  studentsToRemove: number[]
  studentsToAdd: number[]
  verified: boolean
}

export interface GetClassroomsRequest {
  type: typeof GET_CLASSROOMS_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
    type: ClassroomAge
    offset: number
  }
}

export interface GetClassroomsSuccess {
  type: typeof GET_CLASSROOMS_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    classrooms: Classroom[]
    type: ClassroomAge
    more: boolean
    count: number
  }
}

export interface GetClassroomsFailure {
  type: typeof GET_CLASSROOMS_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    type: ClassroomAge
    error: string
  }
}

export interface SetClassroomToMigrate {
  type: typeof SET_CLASSROOM_TO_MIGRATE
  payload: {
    requestOrigin: ReduxRequestOrigin
    oldClassroom: Classroom
    newClassroom?: Classroom
  }
}
export interface SetClassroomToMigrateV2 {
  type: typeof SET_CLASSROOM_TO_MIGRATE_V2
  payload: {
    requestOrigin: ReduxRequestOrigin
    oldClassroom: Classroom
    newClassroom?: Classroom
  }
}

export interface CompleteMigrationRequest {
  type: typeof COMPLETE_MIGRATION_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface CompleteMigrationSuccess {
  type: typeof COMPLETE_MIGRATION_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface CompleteMigrationFailure {
  type: typeof COMPLETE_MIGRATION_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface GetClassroomStudentsRequest {
  type: typeof GET_CLASSROOM_STUDENTS_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetClassroomStudentsSuccess {
  type: typeof GET_CLASSROOM_STUDENTS_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    studentsWithClassroom: StudentWithClassroom[]
  }
}

export interface GetClassroomStudentsFailure {
  type: typeof GET_CLASSROOM_STUDENTS_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface SetAlertModalOpenAction {
  type: typeof SET_ALERT_MODAL_OPEN
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}
export interface SetAlertModalCloseAction {
  type: typeof SET_ALERT_MODAL_CLOSE
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}
export interface ClearMigrationStateAction {
  type: typeof CLEAR_MIGRATION_STATE
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export type MigrationClassroomsActions =
  | SetAlertModalOpenAction
  | SetAlertModalCloseAction
  | GetClassroomsRequest
  | GetClassroomsSuccess
  | GetClassroomsFailure
  | SetClassroomToMigrate
  | SetClassroomToMigrateV2
  | CompleteMigrationRequest
  | CompleteMigrationSuccess
  | CompleteMigrationFailure
  | GetClassroomStudentsRequest
  | GetClassroomStudentsSuccess
  | GetClassroomStudentsFailure
  | ClearMigrationStateAction
  | StudentsDrawerTypes.VerifyClassroom
  | StudentsDrawerTypes.AddNewStudentsSelectionAction
