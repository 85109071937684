import localforage from 'localforage'
import thunk from 'redux-thunk'
import { persistReducer, persistStore } from 'redux-persist'
import { configureStore } from '@reduxjs/toolkit'
import { updateToken } from '@/api'
import { token } from '@/modules/auth/state/auth'
import middlewares from '@/legacy/state/middlewares'
import reducers from './reducer'

const persistConfig = {
  key: 'root',
  storage: localforage,
  whitelist: ['user'],
}

const store = configureStore({
  reducer: persistReducer(persistConfig, reducers),
  // @ts-ignore
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    })

    return middleware.concat(thunk, ...middlewares)
  },
  devTools: process.env.NODE_ENV !== 'production',
})

// if user is logged, update axios api token
export const persistor = persistStore(store, null, () => {
  token && updateToken(token)
})

export type AppDispatch = typeof store.dispatch
export default store
