import { pathOr } from 'ramda'
import { LOGOUT } from '@/sections/header/types/logout'
import { persistor } from '@/state/store'

export const errorStrings = {
  SessionExpired: 'handleError.endpoint.sessionExpired',
  NoPermissions: 'handleError.endpoint.noPermissions',
  SomethingWentWrong: 'handleError.endpoint.somethingWentWrong',
  InternetMayBeTheProblem: 'handleError.endpoint.internetMayBeTheProblem',
  NoPermissionsConference: 'handleError.endpoint.noPermissionsConference',
}

export const parseTimeoutError = (error) => {
  const regexp = /in timeout, ([0-9]+) minutes remaining/
  const res = error.match(regexp)
  if (!res) return null // didn't match regexp
  return +res[1]
}

export const getErrorMessage = (error) => {
  const errorDescription = pathOr(
    errorStrings.SomethingWentWrong,
    ['response', 'data', 'description'],
    error
  )

  // Errors from new backend developments are objects, but old errors are strings (TODO: Make this more robust)
  if (typeof errorDescription === 'string') return errorDescription
  else return pathOr(errorStrings.SomethingWentWrong, ['description'], errorDescription)
}

export const handleErrorsWithAction = (error, action, dispatch, optionalProps = {}) => {
  let message = ''
  if (error.response) {
    switch (error.response.status) {
      case 401:
        dispatch({ type: LOGOUT })
        message = errorStrings.SessionExpired
        persistor.flush().then(() => window.location.assign('/login'))
        break
      case 403:
        message = pathOr(errorStrings.NoPermissions, ['response', 'data', 'description'], error)
        break
      default:
        message = getErrorMessage(error)
    }
  } else if (error.request) {
    message = errorStrings.InternetMayBeTheProblem
  } else {
    message = errorStrings.SomethingWentWrong
  }
  dispatch({
    type: action,
    payload: {
      error: message,
      ...optionalProps,
    },
  })
  return message
}

export const handleErrorsWithNoAction = (error) => {
  let message = ''

  if (error.response) {
    switch (error.response.status) {
      case 401:
        localStorage.clear()
        message = errorStrings.SessionExpired
        persistor.flush().then(() => window.location.assign('/login'))
        break
      case 403:
        message = pathOr(errorStrings.NoPermissions, ['response', 'data', 'description'], error)
        break
      case 404:
        message = errorStrings.SomethingWentWrong
        break
      default:
        message = getErrorMessage(error)
    }
  } else if (error.request) {
    message = errorStrings.InternetMayBeTheProblem
  } else {
    message = errorStrings.SomethingWentWrong
  }
  return message
}

export const handleErrorsWithNoActionLogin = (error) => {
  let message = ''
  if (error.response) {
    switch (error.response.status) {
      case 403:
        message = pathOr(errorStrings.NoPermissions, ['response', 'data', 'description'], error)
        break
      case 404:
        message = errorStrings.SomethingWentWrong
        break
      default:
        message = getErrorMessage(error)
    }
  } else if (error.request) {
    message = errorStrings.InternetMayBeTheProblem
  } else {
    message = errorStrings.SomethingWentWrong
  }
  alert(message)
}

export const handleErrorsWithActionLogin = (error, action, dispatch) => {
  let message = ''
  if (error.response) {
    switch (error.response.status) {
      case 401:
        message = pathOr('login.wrongCredentials', ['response', 'data', 'description'], error)
        if (message === '') message = 'login.wrongCredentials'
        break
      case 403:
        message = pathOr(errorStrings.NoPermissions, ['response', 'data', 'description'], error)
        break
      case 404:
        message = errorStrings.SomethingWentWrong
        break
      default:
        message = getErrorMessage(error)
    }
  } else if (error.request) {
    message = errorStrings.InternetMayBeTheProblem
  } else {
    message = errorStrings.SomethingWentWrong
  }
  dispatch({
    type: action,
    payload: {
      error: message,
    },
  })
}

export const handleErrorsWithActionConference = (error, action, dispatch) => {
  let message = ''
  if (error.response) {
    switch (error.response.status) {
      case 401:
        dispatch({ type: LOGOUT })
        message = errorStrings.SessionExpired
        persistor.flush().then(() => window.location.assign('/login'))
        break
      case 403:
        message = pathOr(errorStrings.NoPermissions, ['response', 'data', 'description'], error)
        break
      default:
        message = getErrorMessage(error)
    }
  } else if (error.request) {
    message = errorStrings.InternetMayBeTheProblem
  } else {
    message = errorStrings.SomethingWentWrong
  }
  dispatch({
    type: action,
    payload: {
      error: message,
    },
  })
}
