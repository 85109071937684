import { API } from '@/api/lms'
import type { CreateClassroomParams, EditClassroomParams } from '@/api/lms/classrooms'
import messagesActions from '@/sections/header/actions/messages'
import { ManagementRole } from '@/legacy/roles'
import type { AppThunk } from '@/state/thunk'
import { pagesToLimit } from '@/legacy/utils/generalUtils'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type {
  CreateClassroomAsAdminRequest,
  CreateClassroomAsAdminSuccess,
  CreateClassroomAsPrincipalRequest,
  CreateClassroomAsPrincipalSuccess,
  DeleteClassroomAsMgmtRequest,
  DeleteClassroomAsMgmtSuccess,
  EditClassroomAsAdminRequest,
  EditClassroomAsAdminSuccess,
  EditClassroomAsPrincipalRequest,
  EditClassroomAsPrincipalSuccess,
  GetClassroomRoleRequest,
  GetClassroomRoleSuccess,
  GetClassroomsAsPrincipalRequest,
  GetClassroomsAsPrincipalSuccess,
  GetClassroomSubjectsAsMgmtRequest,
  GetClassroomSubjectsAsMgmtSuccess,
  ListClassroomsAsAdminRequest,
  ListClassroomsAsAdminSuccess,
  ListClassroomsAsPrincipalRequest,
  ListClassroomsAsPrincipalSuccess,
} from '@/legacy/redux/types/classrooms'
import {
  CREATE_CLASSROOM_AS_ADMIN_FAILURE,
  CREATE_CLASSROOM_AS_ADMIN_REQUEST,
  CREATE_CLASSROOM_AS_ADMIN_SUCCESS,
  CREATE_CLASSROOM_AS_PRINCIPAL_FAILURE,
  CREATE_CLASSROOM_AS_PRINCIPAL_REQUEST,
  CREATE_CLASSROOM_AS_PRINCIPAL_SUCCESS,
  DELETE_CLASSROOM_AS_MGMT_FAILURE,
  DELETE_CLASSROOM_AS_MGMT_REQUEST,
  DELETE_CLASSROOM_AS_MGMT_SUCCESS,
  EDIT_CLASSROOM_AS_ADMIN_FAILURE,
  EDIT_CLASSROOM_AS_ADMIN_REQUEST,
  EDIT_CLASSROOM_AS_ADMIN_SUCCESS,
  EDIT_CLASSROOM_AS_PRINCIPAL_FAILURE,
  EDIT_CLASSROOM_AS_PRINCIPAL_REQUEST,
  EDIT_CLASSROOM_AS_PRINCIPAL_SUCCESS,
  GET_CLASSROOMS_AS_PRINCIPAL_FAILURE,
  GET_CLASSROOMS_AS_PRINCIPAL_REQUEST,
  GET_CLASSROOMS_AS_PRINCIPAL_SUCCESS,
  GET_CLASSROOM_ROLE_REQUEST,
  GET_CLASSROOM_ROLE_SUCCESS,
  GET_CLASSROOM_SUBJECTS_AS_MGMT_FAILURE,
  GET_CLASSROOM_SUBJECTS_AS_MGMT_REQUEST,
  GET_CLASSROOM_SUBJECTS_AS_MGMT_SUCCESS,
  LIST_CLASSROOMS_AS_ADMIN_FAILURE,
  LIST_CLASSROOMS_AS_ADMIN_REQUEST,
  LIST_CLASSROOMS_AS_ADMIN_SUCCESS,
  LIST_CLASSROOMS_AS_PRINCIPAL_FAILURE,
  LIST_CLASSROOMS_AS_PRINCIPAL_REQUEST,
  LIST_CLASSROOMS_AS_PRINCIPAL_SUCCESS,
} from '@/legacy/redux/types/classrooms'
import type { GenericPagination } from '@/legacy/redux/utils'
import dashboardActions from '@/sections/principal/dashboard/actions/dashboard'
import PrincipalClassroomsDrawerActionCreators from '@/sections/principal/management/actions/classroomsDrawer'
import { initialPagination } from '@/sections/management/types/classrooms/classroomsDrawer'
import AdminClassroomsDrawerActionCreators from '@/sections/eb-admin/management/actions/classroomsDrawer'

const ClassroomsActionCreators = {
  getClassroomsAsPrincipal:
    (
      requestOrigins: ReduxRequestOrigin[],
      organizationID: number,
      pagination: { page: number; rowsPerPage: number },
      search?: string,
      toMigrate?: boolean
    ): AppThunk =>
    async (dispatch, getState) => {
      const classroomsPagination = pagesToLimit(pagination)

      const request: GetClassroomsAsPrincipalRequest = {
        type: GET_CLASSROOMS_AS_PRINCIPAL_REQUEST,
        payload: {
          requestOrigins,
          page: pagination?.page,
          rowsPerPage: pagination?.rowsPerPage,
        },
      }
      dispatch(request)

      try {
        const { rows, total } = await API.Classrooms.DEPRECATED_getAsPrincipal({
          limit: classroomsPagination?.limit,
          offset: classroomsPagination?.offset,
          organizationId: organizationID,
          search: search,
          toMigrate: toMigrate,
          academicPeriodId: getState().user.selectedAcademicPeriod.id,
        })

        const success: GetClassroomsAsPrincipalSuccess = {
          type: GET_CLASSROOMS_AS_PRINCIPAL_SUCCESS,
          payload: {
            requestOrigins,
            classrooms: rows,
            count: total,
          },
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_CLASSROOMS_AS_PRINCIPAL_FAILURE, dispatch, {
          requestOrigins,
        })
      }
    },
  getClassroomRole:
    (requestOrigins: ReduxRequestOrigin[], classroomId: number): AppThunk =>
    async (dispatch) => {
      const request: GetClassroomRoleRequest = {
        type: GET_CLASSROOM_ROLE_REQUEST,
        payload: {
          requestOrigins,
        },
      }

      dispatch(request)

      try {
        const {
          data: { description },
        } = await API.Classrooms.getClassroomRole(classroomId)
        if (!description.role) throw new Error('No se encontró ningún rol')

        const success: GetClassroomRoleSuccess = {
          type: GET_CLASSROOM_ROLE_SUCCESS,
          payload: {
            requestOrigins,
            role: description.role,
          },
        }
        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_CLASSROOM_ROLE_SUCCESS, dispatch, {
          requestOrigins,
        })
      }
    },
  listAsAdmin:
    (
      requestOrigins: ReduxRequestOrigin[],
      pagination: GenericPagination,
      search?: string,
      organizationId?: number
    ): AppThunk =>
    async (dispatch) => {
      const request: ListClassroomsAsAdminRequest = {
        type: LIST_CLASSROOMS_AS_ADMIN_REQUEST,
        payload: {
          page: pagination.page,
          rowsPerPage: pagination.rowsPerPage,
          requestOrigins,
        },
      }
      dispatch(request)

      try {
        const params = {
          ...pagesToLimit(pagination),
          ...(search?.length && { search }),
          ...(organizationId && { organizationId }),
        }

        const { total, classrooms } = await API.Classrooms.DEPRECATED_getAsAdmin(params)

        const success: ListClassroomsAsAdminSuccess = {
          type: LIST_CLASSROOMS_AS_ADMIN_SUCCESS,
          payload: {
            requestOrigins,
            count: total,
            classrooms,
          },
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, LIST_CLASSROOMS_AS_ADMIN_FAILURE, dispatch, {
          requestOrigins,
        })
      }
    },
  createAsAdmin:
    (requestOrigins: ReduxRequestOrigin[], classroom: CreateClassroomParams): AppThunk =>
    async (dispatch) => {
      const request: CreateClassroomAsAdminRequest = {
        type: CREATE_CLASSROOM_AS_ADMIN_REQUEST,
        payload: {
          requestOrigins,
        },
      }
      dispatch(request)

      try {
        await API.Classrooms.createAsAdmin(classroom)

        const success: CreateClassroomAsAdminSuccess = {
          type: CREATE_CLASSROOM_AS_ADMIN_SUCCESS,
          payload: {
            requestOrigins,
          },
        }

        dispatch(success)
        dispatch(
          messagesActions.showToast(
            'dashboard.addEditClassroomModal.classroomCreated',
            'dashboard.addEditClassroomModal.createClassroomSuccess'
          )
        )
        dispatch(ClassroomsActionCreators.refreshClassroomsDrawerAsAdmin())
      } catch (error) {
        handleErrorsWithAction(error, CREATE_CLASSROOM_AS_ADMIN_FAILURE, dispatch, {
          requestOrigins,
        })
        dispatch(
          messagesActions.showToast(
            'dashboard.addEditClassroomModal.createClassroomError',
            // @ts-ignore
            error.response?.data.description || error,
            {
              error: true,
            }
          )
        )
      }
    },
  editAsAdmin:
    (
      requestOrigins: ReduxRequestOrigin[],
      classroomId: number,
      classroom: EditClassroomParams,
      subjectIds: number[]
    ): AppThunk =>
    async (dispatch) => {
      const request: EditClassroomAsAdminRequest = {
        type: EDIT_CLASSROOM_AS_ADMIN_REQUEST,
        payload: {
          requestOrigins,
        },
      }
      dispatch(request)

      try {
        await API.Classrooms.editAsAdmin(classroomId, classroom)
        await API.Classrooms.ClassroomSubjects.updateAsAdmin(classroomId, subjectIds)

        const success: EditClassroomAsAdminSuccess = {
          type: EDIT_CLASSROOM_AS_ADMIN_SUCCESS,
          payload: {
            requestOrigins,
          },
        }

        dispatch(success)
        dispatch(
          messagesActions.showToast(
            'dashboard.addEditClassroomModal.classroomEdited',
            'dashboard.addEditClassroomModal.editClassroomSuccess'
          )
        )
        dispatch(ClassroomsActionCreators.refreshClassroomsDrawerAsAdmin())
      } catch (error) {
        handleErrorsWithAction(error, EDIT_CLASSROOM_AS_ADMIN_FAILURE, dispatch, {
          requestOrigins,
        })
        dispatch(
          messagesActions.showToast(
            'dashboard.addEditClassroomModal.editClassroomError',
            // @ts-ignore
            error.response?.data.description || error,
            {
              error: true,
            }
          )
        )
      }
    },
  listAsPrincipal:
    (
      requestOrigins: ReduxRequestOrigin[],
      pagination: GenericPagination,
      search?: string,
      organizationId?: number
    ): AppThunk =>
    async (dispatch) => {
      const request: ListClassroomsAsPrincipalRequest = {
        type: LIST_CLASSROOMS_AS_PRINCIPAL_REQUEST,
        payload: {
          page: pagination.page,
          rowsPerPage: pagination.rowsPerPage,
          requestOrigins,
        },
      }
      dispatch(request)

      try {
        const params = {
          ...pagesToLimit(pagination),
          ...(search?.length && { search }),
          ...(organizationId && { organizationId }),
        }

        const { total, rows: classrooms } = await API.Classrooms.DEPRECATED_getAsPrincipal(params)

        const success: ListClassroomsAsPrincipalSuccess = {
          type: LIST_CLASSROOMS_AS_PRINCIPAL_SUCCESS,
          payload: {
            requestOrigins,
            count: total,
            classrooms,
          },
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, LIST_CLASSROOMS_AS_PRINCIPAL_FAILURE, dispatch, {
          requestOrigins,
        })
      }
    },
  createAsPrincipal:
    (requestOrigins: ReduxRequestOrigin[], classroom: CreateClassroomParams): AppThunk =>
    async (dispatch, getState) => {
      const request: CreateClassroomAsPrincipalRequest = {
        type: CREATE_CLASSROOM_AS_PRINCIPAL_REQUEST,
        payload: {
          requestOrigins,
        },
      }
      dispatch(request)

      try {
        await API.Classrooms.createAsPrincipal(classroom)

        const success: CreateClassroomAsPrincipalSuccess = {
          type: CREATE_CLASSROOM_AS_PRINCIPAL_SUCCESS,
          payload: {
            requestOrigins,
          },
        }
        const dashboardState = getState().principal.dashboard.dashboard
        dispatch(success)
        dispatch(
          messagesActions.showToast(
            'dashboard.addEditClassroomModal.classroomCreated',
            'dashboard.addEditClassroomModal.createClassroomSuccess'
          )
        )

        dispatch(ClassroomsActionCreators.refreshClassroomsDrawerAsPrincipal())

        if (!dashboardState.selectedOrganization) return
        dispatch(dashboardActions.getDashboard(dashboardState.selectedOrganization.value))
      } catch (error) {
        handleErrorsWithAction(error, CREATE_CLASSROOM_AS_PRINCIPAL_FAILURE, dispatch, {
          requestOrigins,
        })
        dispatch(
          messagesActions.showToast(
            'dashboard.addEditClassroomModal.createClassroomError',
            // @ts-ignore
            error.response?.data.description || error,
            {
              error: true,
            }
          )
        )
      }
    },
  editAsPrincipal:
    (
      requestOrigins: ReduxRequestOrigin[],
      classroomId: number,
      classroom: EditClassroomParams,
      subjectIds: number[]
    ): AppThunk =>
    async (dispatch) => {
      const request: EditClassroomAsPrincipalRequest = {
        type: EDIT_CLASSROOM_AS_PRINCIPAL_REQUEST,
        payload: {
          requestOrigins,
        },
      }
      dispatch(request)

      try {
        await API.Classrooms.editAsPrincipal(classroomId, classroom)
        await API.Classrooms.ClassroomSubjects.updateAsPrincipal(classroomId, subjectIds)

        const success: EditClassroomAsPrincipalSuccess = {
          type: EDIT_CLASSROOM_AS_PRINCIPAL_SUCCESS,
          payload: {
            requestOrigins,
          },
        }

        dispatch(success)
        dispatch(
          messagesActions.showToast(
            'dashboard.addEditClassroomModal.classroomEdited',
            'dashboard.addEditClassroomModal.editClassroomSuccess'
          )
        )
        dispatch(ClassroomsActionCreators.refreshClassroomsDrawerAsPrincipal())
      } catch (error) {
        handleErrorsWithAction(error, EDIT_CLASSROOM_AS_PRINCIPAL_FAILURE, dispatch, {
          requestOrigins,
        })
        dispatch(
          messagesActions.showToast(
            'dashboard.addEditClassroomModal.editClassroomError',
            // @ts-ignore
            error.response?.data.description || error,
            {
              error: true,
            }
          )
        )
      }
    },
  getClassroomSubjectsAsMgmt:
    (requestOrigins: ReduxRequestOrigin[], classroomId: number, role: ManagementRole): AppThunk =>
    async (dispatch) => {
      const request: GetClassroomSubjectsAsMgmtRequest = {
        type: GET_CLASSROOM_SUBJECTS_AS_MGMT_REQUEST,
        payload: {
          requestOrigins,
        },
      }
      dispatch(request)

      try {
        const { subjects } = await API.Classrooms.ClassroomSubjects.getAsMgmt(classroomId, role)

        const success: GetClassroomSubjectsAsMgmtSuccess = {
          type: GET_CLASSROOM_SUBJECTS_AS_MGMT_SUCCESS,
          payload: {
            requestOrigins,
            subjects,
          },
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_CLASSROOM_SUBJECTS_AS_MGMT_FAILURE, dispatch, {
          requestOrigins,
        })
      }
    },
  deleteClassroomAsMgmt:
    (requestOrigins: ReduxRequestOrigin[], classroomId: number, role: ManagementRole): AppThunk =>
    async (dispatch, getState) => {
      const request: DeleteClassroomAsMgmtRequest = {
        type: DELETE_CLASSROOM_AS_MGMT_REQUEST,
        payload: {
          requestOrigins,
        },
      }
      dispatch(request)

      try {
        await API.Classrooms.remove(classroomId, role)

        const success: DeleteClassroomAsMgmtSuccess = {
          type: DELETE_CLASSROOM_AS_MGMT_SUCCESS,
          payload: {
            requestOrigins,
          },
        }
        const dashboardState = getState().principal.dashboard.dashboard
        dispatch(success)

        role === ManagementRole.PRINCIPAL
          ? dispatch(ClassroomsActionCreators.refreshClassroomsDrawerAsPrincipal())
          : dispatch(ClassroomsActionCreators.refreshClassroomsDrawerAsAdmin())

        if (!dashboardState.selectedOrganization) return
        dispatch(dashboardActions.getDashboard(dashboardState.selectedOrganization.value))
      } catch (error) {
        handleErrorsWithAction(error, DELETE_CLASSROOM_AS_MGMT_FAILURE, dispatch, {
          requestOrigins,
        })
      }
    },
  refreshClassroomsDrawerAsAdmin: (): AppThunk => {
    return (dispatch, getState) => {
      const organizationId = getState().ebAdmin.dashboard.dashboard.selectedOrganization.value
      dispatch(
        AdminClassroomsDrawerActionCreators.getClassrooms({ ...initialPagination, organizationId })
      )
    }
  },
  refreshClassroomsDrawerAsPrincipal: (): AppThunk => {
    return (dispatch, getState) => {
      const { selectedOrganization } = getState().principal.dashboard.dashboard
      const academicPeriodId = getState().user.selectedAcademicPeriod.id
      if (!selectedOrganization?.value) return

      dispatch(
        PrincipalClassroomsDrawerActionCreators.getClassrooms({
          organizationId: selectedOrganization.value,
          academicPeriodId,
          ...initialPagination,
        })
      )
    }
  },
}

export default ClassroomsActionCreators
