import { MESSAGE_STATUS } from '@/api/bulletin/types'
import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import { MESSAGE_MANAGER_TYPES } from '@/sections/teacher/bulletin/types/messageManager'
import { MESSAGE_MARK_AS_READ_TYPE } from '@/sections/teacher/bulletin/types/messageMarkAsRead'
import type { MessagesActions, MessagesState } from '@/sections/teacher/bulletin/types/messages'
import { MESSAGE_TYPES } from '@/sections/teacher/bulletin/types/messages'

const initialState: MessagesState = {
  error: null,
  loading: false,
  status: REQUEST_STATUS.IDLE,
  messages: [],
  filters: {
    read: false,
    category: 'normal',
  },
  pagination: {
    total: 0,
    offset: 0,
    limit: 10,
    page: 0,
  },
  messageSelected: {
    id: 0,
    edited: false,
    type: 'other',
    title: '',
    body: '',
    date: '',
    status: MESSAGE_STATUS.READ,
    to: [],
    toList: [],
    attachments: [],
    hasUnreadResponses: false,
    isRichText: false,
    allowsReply: false,
    sendTime: '',
    from: {
      id: 0,
      name: '',
      avatar: '',
      asParent: false,
      children: [],
    },
    elements: [],
    lastActivityAt: '',
  },
}

const messagesReducer = (
  state: MessagesState = initialState,
  action: MessagesActions
): MessagesState => {
  switch (action.type) {
    case MESSAGE_MANAGER_TYPES.MESSAGE_EDIT_SUCCESS: {
      const { message } = action.payload
      const messageIndex = state.messages.findIndex((msg) => msg.id === message.id)
      const messages = [...state.messages]
      messages[messageIndex] = message
      return {
        ...state,
        messages,
      }
    }
    case MESSAGE_MARK_AS_READ_TYPE.MARK_AS_READ_SUCCESS: {
      const { messageId } = action.payload
      const messages = state.messages.map((message) => {
        if (message.id === messageId) {
          return {
            ...message,
            status: MESSAGE_STATUS.READ,
            hasUnreadResponses: false,
          }
        }
        return message
      })

      return {
        ...state,
        messages,
      }
    }
    case MESSAGE_TYPES.CLEAN_MESSAGES: {
      return initialState
    }
    case MESSAGE_TYPES.DELETE_MESSAGE_SUCCESS: {
      const { messageId } = action.payload
      const messages = state.messages.filter((message) => message.id !== messageId)
      return {
        ...state,
        messages,
      }
    }
    case MESSAGE_TYPES.SET_MESSAGES_FILTER: {
      const { prop, value } = action.payload
      const filters = {
        ...state.filters,
        [prop]: value,
      }

      return {
        ...state,
        filters,
        pagination: initialState.pagination,
      }
    }
    case MESSAGE_TYPES.SET_SELECTED_MESSAGE: {
      const { messageSelected } = action.payload
      return {
        ...state,
        messageSelected,
      }
    }
    case MESSAGE_TYPES.CLEAN_SELECTED_MESSAGES: {
      return {
        ...state,
        messageSelected: initialState.messageSelected,
      }
    }
    case MESSAGE_TYPES.GET_LAST_MESSAGE: {
      return {
        ...state,
        loading: true,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case MESSAGE_TYPES.GET_LAST_MESSAGE_SUCCESS: {
      const { messages } = action.payload
      return {
        ...state,
        loading: false,
        status: REQUEST_STATUS.SUCCESS,
        messages: [...messages, ...state.messages],
      }
    }
    case MESSAGE_TYPES.GET_MESSAGES: {
      const { page, offset, limit } = action.payload
      return {
        ...state,
        loading: true,
        status: REQUEST_STATUS.LOADING,
        pagination: {
          ...state.pagination,
          page,
          limit,
          offset,
        },
      }
    }
    case MESSAGE_TYPES.GET_MESSAGES_SUCCESS: {
      const { total, messages } = action.payload
      return {
        ...state,
        loading: false,
        status: REQUEST_STATUS.SUCCESS,
        messages,
        pagination: {
          ...state.pagination,
          total,
        },
      }
    }
    case MESSAGE_TYPES.GET_LAST_MESSAGE_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case MESSAGE_TYPES.GET_MESSAGES_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    default:
      return state
  }
}

export default messagesReducer
