import { reportCardUrl } from '@aula/config'
import { ApiPrivate } from '@/legacy/services/api/api'
import { ScoreTypes } from '@/legacy/services/api/reportCard/gradables/types'
import type {
  EditConceptFinalScoreBody,
  EditConceptualScoreBody,
  EditConceptualScoreResponse,
  GetOneConceptualScoreResponse,
  GradableConceptScoreResponse,
  SaveConceptScoreResponse,
} from './types'

interface GetScoresParams {
  organizationId: number
  classroomId: number
  periodId: number
  type?: ScoreTypes
}

/**
 * Obtiene las notas conceptuales para un calificable
 * @doc {@link https://gitlab.com/elishrid/report-card/-/blob/develop/docs/swagger.yaml#/Concept/get_v2_information_concept_scores__organizationID__classroomID Documentacion}
 */
export const getScores = ({
  organizationId,
  classroomId,
  periodId,
  type = ScoreTypes.SUBJECT,
}: GetScoresParams) => {
  const params = {
    periodId,
    type,
  }
  return ApiPrivate.get<GradableConceptScoreResponse>(
    reportCardUrl + `/v2/information/concept_scores/${organizationId}/${classroomId}`,
    { params }
  ).then((response) => response.data)
}

/**
 * Obtiene una nota conceptual para un estudiante determinado en un período determinado en una materia determinada de un aula determinada.
 */
export function getOneScore(
  periodId: number,
  classroomId: number,
  subjectId: number,
  studentId: number
) {
  const params = { periodId, classroomId, subjectId, studentId }
  return ApiPrivate.get<GetOneConceptualScoreResponse>(
    reportCardUrl + `/v1/information/concept_scores/student`,
    { params }
  ).then((response) => response.data)
}

interface EditScoresParams {
  reportCardId: number
  periodId: number
  scores: EditConceptualScoreBody
  type?: ScoreTypes
}

/**
 * Actualiza una nota conceptual para un calificable
 * @doc {@link https://gitlab.com/elishrid/report-card/-/blob/develop/docs/swagger.yaml#/Concept/put_v2_values_concept_scores__report_card_id Documentacion}
 */
export const editScore = ({
  reportCardId,
  periodId,
  scores,
  type = ScoreTypes.SUBJECT,
}: EditScoresParams) => {
  const params = {
    periodId,
    type,
  }
  return ApiPrivate.put<EditConceptualScoreResponse>(
    reportCardUrl + `/v2/values/concept_scores/${reportCardId}`,
    scores,
    { params }
  ).then((response) => response.data)
}

interface EditFinalScoreParams {
  reportCardId: number
  periodId: number
  scores: EditConceptFinalScoreBody
  type?: ScoreTypes
}

/**
 * Edita una nota final conceptual para un calificable
 */
export const editFinalScore = ({
  reportCardId,
  periodId,
  scores,
  type = ScoreTypes.SUBJECT,
}: EditFinalScoreParams) => {
  const params = {
    periodId,
    type,
  }
  return ApiPrivate.put<SaveConceptScoreResponse>(
    reportCardUrl + `/v2/values/concept_scores/${reportCardId}`,
    scores,
    { params }
  ).then((response) => response.data)
}
