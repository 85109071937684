import * as Types from '@/sections/content-creator/newBooks/types/steps/first'
import { REQUEST_STATUS } from '@/legacy/redux/types/status'

const initialState: Types.ActivitiesState = {
  activities: [],
  modal: {
    isOpenModal: false,
    status: REQUEST_STATUS.IDLE,
    activities: [],
    selectedActivities: [],
    searchText: '',
    pagination: {
      total: 0,
      offset: 0,
      limit: 10,
    },
  },
}

const firstStepReducer = (
  state: Types.ActivitiesState = initialState,
  action: Types.ActivitiesAction
): Types.ActivitiesState => {
  switch (action.type) {
    case Types.ACTIVITIES_TYPES.OPEN_MODAL: {
      return {
        ...state,
        modal: {
          ...state.modal,
          isOpenModal: true,
        },
      }
    }
    case Types.ACTIVITIES_TYPES.CLOSE_MODAL: {
      return {
        ...state,
        modal: {
          ...state.modal,
          isOpenModal: false,
        },
      }
    }
    case Types.ACTIVITIES_TYPES.GET_ACTIVITIES_REQUEST: {
      return {
        ...state,
        modal: {
          ...state.modal,
          status: REQUEST_STATUS.LOADING,
        },
      }
    }
    case Types.ACTIVITIES_TYPES.GET_ACTIVITIES_SUCCESS: {
      const { activities, total } = action.payload

      return {
        ...state,
        modal: {
          ...state.modal,
          status: REQUEST_STATUS.SUCCESS,
          activities,
          pagination: {
            ...state.modal.pagination,
            total,
          },
        },
      }
    }
    case Types.ACTIVITIES_TYPES.GET_ACTIVITIES_FAILURE: {
      return {
        ...state,
        modal: {
          ...state.modal,
          status: REQUEST_STATUS.FAILURE,
        },
      }
    }
    case Types.ACTIVITIES_TYPES.SET_ACTIVITIES: {
      return {
        ...state,
        activities: action.payload.activities,
      }
    }
    case Types.ACTIVITIES_TYPES.REMOVE_ACTIVITY: {
      const newActivities = state.activities.filter(({ id }) => id !== action.payload.idToRemove)

      return {
        ...state,
        activities: newActivities,
      }
    }
    case Types.ACTIVITIES_TYPES.SORT_ACTIVITIES: {
      return {
        ...state,
        activities: action.payload.newSortedActivities,
      }
    }
    case Types.ACTIVITIES_TYPES.CLEAN_MODAL: {
      return {
        ...state,
        modal: initialState.modal,
      }
    }
    case Types.ACTIVITIES_TYPES.SET_PAGINATION_OFFSET: {
      const { offset } = action.payload

      return {
        ...state,
        modal: {
          ...state.modal,
          pagination: {
            ...state.modal.pagination,
            offset,
          },
        },
      }
    }
    case Types.ACTIVITIES_TYPES.SET_SEARCH_TEXT: {
      const { newValue } = action.payload

      return {
        ...state,
        modal: {
          ...state.modal,
          searchText: newValue.trim(),
        },
      }
    }
    case Types.ACTIVITIES_TYPES.SET_SELECTED_ACTIVITY: {
      const { newActivity } = action.payload

      return {
        ...state,
        modal: {
          ...state.modal,
          selectedActivities: [...state.modal.selectedActivities, newActivity],
        },
      }
    }
    case Types.ACTIVITIES_TYPES.REMOVE_SELECTED_ACTIVITY: {
      const { idToRemove } = action.payload

      return {
        ...state,
        modal: {
          ...state.modal,
          selectedActivities: state.modal.selectedActivities.filter(
            (activity) => activity.id !== idToRemove
          ),
        },
      }
    }
    case Types.ACTIVITIES_TYPES.CLEAN_STEP: {
      return initialState
    }
    default:
      return state
  }
}

export default firstStepReducer
