import type { Classroom, Subject } from '@/sections/teacher/studentList/types/studentList'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { Moment } from 'moment'
import type { Subgroup } from './libraryBooks'
import type { Course } from './libraryCourses'

export const SET_CLASSROOM = 'TEACHER/COURSES/SET_CLASSROOM'
export const SET_CLASSROOM_SEARCH = 'TEACHER/COURSES/SET_CLASSROOM_SEARCH'
export const SET_SUBJECT = 'TEACHER/COURSES/SET_SUBJECT'
export const SET_GLOBAL_START_DATE = 'TEACHER/COURSES/SET_GLOBAL_START_DATE'
export const SET_GLOBAL_END_DATE = 'TEACHER/COURSES/SET_GLOBAL_END_DATE'
export const ENABLE_GLOBAL_END_DATE = 'TEACHER/COURSES/ENABLE_GLOBAL_END_DATE'
export const TOGGLE_SEQUENCE_MODAL_OPEN = 'TEACHER/COURSES/TOGGLE_SEQUENCE_MODAL_OPEN'

export const ASSIGN_COURSE_REQUEST = 'TEACHER/COURSES/ASSIGN_COURSE_REQUEST'
export const ASSIGN_COURSE_SUCCESS = 'TEACHER/COURSES/ASSIGN_COURSE_SUCCESS'
export const ASSIGN_COURSE_FAILURE = 'TEACHER/COURSES/ASSIGN_COURSE_FAILURE'

export const GET_CLASSROOMS_REQUEST = 'TEACHER/COURSES/GET_CLASSROOMS_REQUEST'
export const GET_CLASSROOMS_SUCCESS = 'TEACHER/COURSES/GET_CLASSROOMS_SUCCESS'
export const GET_CLASSROOMS_FAILURE = 'TEACHER/COURSES/GET_CLASSROOMS_FAILURE'

export const GET_SUBJECTS_REQUEST = 'TEACHER/COURSES/GET_SUBJECTS_REQUEST'
export const GET_SUBJECTS_SUCCESS = 'TEACHER/COURSES/GET_SUBJECTS_SUCCESS'
export const GET_SUBJECTS_FAILURE = 'TEACHER/COURSES/GET_SUBJECTS_FAILURE'

export const GET_CLASSROOM_SUBGROUPS_REQUEST = 'TEACHER/COURSES/GET_CLASSROOM_SUBGROUPS_REQUEST'
export const GET_CLASSROOM_SUBGROUPS_SUCCESS = 'TEACHER/COURSES/GET_CLASSROOM_SUBGROUPS_SUCCESS'
export const GET_CLASSROOM_SUBGROUPS_FAILURE = 'TEACHER/COURSES/GET_CLASSROOM_SUBGROUPS_FAILURE'

export const SELECT_SUBGROUPS = 'LIBRARY_TEACHER/SELECT_SUBGROUPS'

export const SET_STEP = 'LIBRARY_TEACHER/SET_STEP'
export const SET_TABLE_DATE = 'LIBRARY_TEACHER/SET_TABLE_DATE'
export const SET_TABLE_TIME = 'LIBRARY_TEACHER/SET_TABLE_TIME'
export const SET_TABLE_SWITCH = 'LIBRARY_TEACHER/SET_TABLE_SWITCH'
export const SET_TABLE_DURATION = 'LIBRARY_TEACHER/SET_TABLE_DURATION'

export interface ToggleCourseModalOpen {
  type: typeof TOGGLE_SEQUENCE_MODAL_OPEN
  payload: {
    requestOrigin: ReduxRequestOrigin
    open: boolean
    course?: Course
  }
}

export interface SetStep {
  type: typeof SET_STEP
  payload: {
    requestOrigin: ReduxRequestOrigin
    step: number
  }
}

export interface SetTableDate {
  type: typeof SET_TABLE_DATE
  payload: {
    requestOrigin: ReduxRequestOrigin
    index: number
    date: any
    type: string
  }
}

export interface SetTableTime {
  type: typeof SET_TABLE_TIME
  payload: {
    requestOrigin: ReduxRequestOrigin
    index: number
    time: any
  }
}

export interface SetTableSwitch {
  type: typeof SET_TABLE_SWITCH
  payload: {
    requestOrigin: ReduxRequestOrigin
    index: number
    toggled: boolean
    type: string
  }
}

export interface SetTableDuration {
  type: typeof SET_TABLE_DURATION
  payload: {
    requestOrigin: ReduxRequestOrigin
    index: number
    duration: number
  }
}

export interface SetClassroom {
  type: typeof SET_CLASSROOM
  payload: {
    requestOrigin: ReduxRequestOrigin
    classroom: Classroom
  }
}
export interface SetSubject {
  type: typeof SET_SUBJECT
  payload: {
    requestOrigin: ReduxRequestOrigin
    subject: Subject
  }
}
export interface SetGlobalStartDate {
  type: typeof SET_GLOBAL_START_DATE
  payload: {
    requestOrigin: ReduxRequestOrigin
    globalStartDate: Moment
  }
}
export interface SetGlobalEndDate {
  type: typeof SET_GLOBAL_END_DATE
  payload: {
    requestOrigin: ReduxRequestOrigin
    globalEndDate: Moment
  }
}
export interface EnableGlobalEndDate {
  type: typeof ENABLE_GLOBAL_END_DATE
  payload: {
    requestOrigin: ReduxRequestOrigin
    globalEndDateEnabled: boolean
  }
}
export interface AssignCourseRequest {
  type: typeof ASSIGN_COURSE_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}
export interface AssignCourseSuccess {
  type: typeof ASSIGN_COURSE_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}
export interface AssignCourseFailure {
  type: typeof ASSIGN_COURSE_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface GetClassroomsRequest {
  type: typeof GET_CLASSROOMS_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
    optionsLoading: boolean
    lastSearchID: number
  }
}

export interface GetClassroomsSuccess {
  type: typeof GET_CLASSROOMS_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    classrooms: Classroom[]
    optionsLoading: boolean
  }
}

export interface GetClassroomsFailure {
  type: typeof GET_CLASSROOMS_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface GetClassroomSubgroupsRequest {
  type: typeof GET_CLASSROOM_SUBGROUPS_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetClassroomSubgroupsSuccess {
  type: typeof GET_CLASSROOM_SUBGROUPS_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    subgroups: Subgroup[]
  }
}

export interface GetClassroomSubgroupsFailure {
  type: typeof GET_CLASSROOM_SUBGROUPS_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface GetSubjectsRequest {
  type: typeof GET_SUBJECTS_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetSubjectsSuccess {
  type: typeof GET_SUBJECTS_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    subjects: Subject[]
  }
}

export interface GetSubjectsFailure {
  type: typeof GET_SUBJECTS_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface SelectSubgroups {
  type: typeof SELECT_SUBGROUPS
  payload: {
    requestOrigin: ReduxRequestOrigin
    selectedSubgroups: Subgroup[]
  }
}

export interface SetClassroomSearch {
  type: typeof SET_CLASSROOM_SEARCH
  payload: {
    requestOrigin: ReduxRequestOrigin
    classroomSearch: string
  }
}

export interface CourseAssignmentModalState {
  open: boolean
  course: Course | null
  subject: Subject | null
  selectedClassroom: Classroom | null
  classrooms: Classroom[]
  subgroups: Subgroup[]
  selectedSubgroups: Subgroup[]
  subjects: Subject[]
  loadingClassroomOptions: boolean
  loadingClassrooms: boolean
  loadingSubgroups: boolean
  loadingSubjects: boolean
  loadingAssignment: boolean
  error: string
  lastClassroomID: number
  classroomSearch: string
  step: number
  globalStartDate: Moment
  globalEndDate: Moment
  globalEndDateEnabled: boolean
}

export type CourseAssignmentModalAction =
  | ToggleCourseModalOpen
  | SetClassroom
  | SetClassroomSearch
  | SetSubject
  | AssignCourseRequest
  | AssignCourseSuccess
  | AssignCourseFailure
  | GetClassroomsRequest
  | GetClassroomsSuccess
  | GetClassroomsFailure
  | GetClassroomSubgroupsRequest
  | GetClassroomSubgroupsSuccess
  | GetClassroomSubgroupsFailure
  | SelectSubgroups
  | GetSubjectsRequest
  | GetSubjectsSuccess
  | GetSubjectsFailure
  | SetStep
  | SetTableDate
  | SetTableTime
  | SetTableSwitch
  | SetTableDuration
  | EnableGlobalEndDate
  | SetGlobalStartDate
  | SetGlobalEndDate
