import { reduxRequestOriginMap } from "@/legacy/redux/constants"
import { DELETE_FAILURE, DELETE_REQUEST, DELETE_SUCCESS, DeleteThemeModalAction, DeleteThemeModalState, SET_INITIAL_VALUES } from "../types/deleteThemeModal"
import { Reducer } from "redux"
import { checkRequestOrigin } from "@/legacy/redux/utils"

const initialState: DeleteThemeModalState = {
  themeId: 0,
  loading: false,
  open: false,
  error: '',
}

const requestDestination = reduxRequestOriginMap.ADMIN_DELETE_THEME_MODAL

const AdminDeleteThemeModalReducer: Reducer<DeleteThemeModalState, DeleteThemeModalAction> = (
  state = initialState,
  action
): DeleteThemeModalState => {
  switch (action.type) {
    case SET_INITIAL_VALUES:
      return {
        loading: false,
        open: true,
        themeId: action.payload.themeId,
        error: '',
      }
    case DELETE_REQUEST:
      return { ...state, loading: true }
    case DELETE_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case DELETE_SUCCESS:
    default:
      return initialState
  }
}

export default checkRequestOrigin(initialState, AdminDeleteThemeModalReducer, requestDestination)
