import type { Moment } from 'moment'
import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '@/state/hooks'
import { bindActionCreators } from 'redux'
import type { AttendanceStatus } from '@/api/attendances/config'
import type { StudentAttendancesArray } from '@/api/attendances/records'
import genericActionCreators from '@/legacy/redux/actions/generic'
import StudentsActionCreators from '@/legacy/redux/actions/students'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { makeDatesArray } from '@/legacy/utils/time'
import { toDays } from '@/sections/attendances/records/utils'
import AbscencesDrawerActionCreators from '@/sections/report-card/grading/actions/abscencesDrawer'
import AbscencesDrawer from '@/sections/report-card/grading/components/tabs/abscencesDrawer'
import type {
  AbscencesTableColumn,
  AbscencesTableRow,
} from '@/sections/report-card/grading/components/tabs/atomic/abscencesTable'
import attendanceRecordsMainViewActionCreators from '@/sections/attendances/records/actions/mainView'
import type { ROLE } from '@aula/config'

function makeRows(attendanceStatuses: AttendanceStatus[]): AbscencesTableRow[] {
  return attendanceStatuses.map((att) => {
    return {
      ...att,
      date: moment(),
      isSelected: false,
      rowId: att.abbreviation,
    }
  })
}

const defaultRequestOrigins = [reduxRequestOriginMap.REPORT_CARD_GRADING_ABSENCES_DRAWER]
const attendancesRequestOrigins = [reduxRequestOriginMap.ATTENDANCES_RECORDS_MAIN_VIEW]

export default function AbscencesDrawerContainer() {
  const dispatch = useDispatch()
  // Selectors
  const mainViewState = useAppSelector((state) => state.reportCard.grading.mainView)
  const drawerState = useAppSelector((state) => state.reportCard.grading.tabs.abscences.drawer)
  const selectedStudent = drawerState.students[drawerState.selectedStudentIndex]
  const role = useAppSelector((state) => state.user.role) as ROLE
  const { classroomId } = useAppSelector((state) => state.reportCard.config.config)
  const { config: attendancesConfig, studentAttendances } = useAppSelector(
    (state) => state.attendances.records.mainView
  )
  const { selectedPeriodId, periods, selectedAbsenceSubjectId, classroomStudentsPagination } =
    mainViewState
  const statuses = useAppSelector((state) => state.attendances.records.mainView.config.statuses)
  const selectedPeriod = periods.find((p) => {
    return p.id === selectedPeriodId
  })

  // Actions
  const { clearState, getAbscences, updateAbscenceRadio, validateAbsence } = bindActionCreators(
    AbscencesDrawerActionCreators,
    dispatch
  )
  const genericActions = bindActionCreators(genericActionCreators, dispatch)
  const attendanceRecordsMainView = bindActionCreators(
    attendanceRecordsMainViewActionCreators,
    dispatch
  )
  const listClassroomStudents = bindActionCreators(
    StudentsActionCreators.listClassroomStudents,
    dispatch
  )

  // Handlers
  function handleRadioChange(date: Moment, status: AttendanceStatus) {
    updateAbscenceRadio(defaultRequestOrigins, date, status)
  }
  function handleInputChange(value: string) {}

  function handleSave() {
    if (selectedPeriodId) {
      const records: StudentAttendancesArray[] = drawerState.abscences.records.map((r) => {
        return { date: r.date, statusId: r.statusId }
      })
      validateAbsence(
        defaultRequestOrigins,
        attendancesConfig.id,
        selectedPeriodId,
        selectedStudent.id,
        records,
        selectedAbsenceSubjectId
      )
    }
  }
  function handleClickPrevious() {
    if (drawerState.selectedStudentIndex == 0) {
      const totalPages = Math.floor(
        classroomStudentsPagination.count / classroomStudentsPagination.rowsPerPage
      )

      const newPage =
        classroomStudentsPagination.page == 0 ? totalPages : classroomStudentsPagination.page - 1

      const pagination = { ...classroomStudentsPagination, page: newPage }
      listClassroomStudents(
        [
          reduxRequestOriginMap.REPORT_CARD_GRADING_MAIN_VIEW,
          reduxRequestOriginMap.REPORT_CARD_GRADING_ABSENCES_DRAWER,
        ],
        classroomId,
        pagination,
        undefined,
        false
      )

      return
    }

    genericActions.setField(
      defaultRequestOrigins,
      'selectedStudentIndex',
      drawerState.selectedStudentIndex - 1
    )
  }

  function handleClickNext() {
    if (drawerState.selectedStudentIndex == drawerState.students.length - 1) {
      const totalPages = Math.floor(
        classroomStudentsPagination.count / classroomStudentsPagination.rowsPerPage
      )
      const newPage =
        classroomStudentsPagination.page == totalPages ? 0 : classroomStudentsPagination.page + 1

      const pagination = { ...classroomStudentsPagination, page: newPage }
      listClassroomStudents(
        [
          reduxRequestOriginMap.REPORT_CARD_GRADING_MAIN_VIEW,
          reduxRequestOriginMap.REPORT_CARD_GRADING_ABSENCES_DRAWER,
        ],
        classroomId,
        pagination,
        undefined,
        true
      )

      return
    }
    genericActions.setField(
      defaultRequestOrigins,
      'selectedStudentIndex',
      drawerState.selectedStudentIndex + 1
    )
  }

  useEffect(() => {
    if (!selectedPeriod) return
    attendanceRecordsMainView.getClassroomAttendances(
      attendancesRequestOrigins,
      classroomId,
      selectedPeriod.startDate,
      selectedPeriod.endDate,
      selectedAbsenceSubjectId
    )
  }, [selectedPeriodId])

  useEffect(() => {
    if (selectedPeriodId && selectedStudent) {
      const params = {
        classroomId: classroomId,
        subjectId: selectedAbsenceSubjectId,
        periodId: selectedPeriodId,
        studentId: selectedStudent.id,
        role,
      }
      getAbscences(defaultRequestOrigins, params)
    }
  }, [selectedPeriodId, classroomId, selectedStudent])

  useEffect(() => {
    return () => {
      clearState(defaultRequestOrigins)
    }
  }, [])

  const configuredAbsencesColumns: AbscencesTableColumn[] = useMemo(() => {
    return makeDatesArray({
      startDate: selectedPeriod?.startDate,
      endDate: selectedPeriod?.endDate,
      enabledDays: toDays(attendancesConfig.days),
    }).map((date) => {
      return {
        id: date.format(),
        date,
        isHoliday: attendancesConfig.absences.some((absence) =>
          moment(absence.date).isSame(moment(date), 'day')
        ),
      }
    })
  }, [])

  const dynamicAbsencesColumns: AbscencesTableColumn[] = studentAttendances
    .filter((a) => a.subjectId === selectedAbsenceSubjectId && a.studentId === selectedStudent.id)
    .map(({ date }) => ({
      id: date.format(),
      date,
      isHoliday: attendancesConfig.absences.some((absence) =>
        moment(absence.date).isSame(moment(date), 'day')
      ),
    }))

  function columnsDates(
    configuredColumns: AbscencesTableColumn[],
    dynamicColumns: AbscencesTableColumn[]
  ) {
    if (selectedAbsenceSubjectId === 0) {
      return configuredColumns
    }

    const combinedDates = [...configuredColumns, ...dynamicColumns].sort((a, b) => {
      return a.date.diff(b.date) // Sorts dates in ascending order 1st to 31st
    })

    const uniqueDates = combinedDates.reduce((acc, current) => {
      const dateString = current.date.format('YYYY-MM-DD') // Dates has differents hours, so we need to format them to compare
      if (!acc.has(dateString)) {
        acc.set(dateString, current)
      }
      return acc
    }, new Map())

    return Array.from(uniqueDates.values())
  }

  const columns = columnsDates(configuredAbsencesColumns, dynamicAbsencesColumns)
  return (
    <AbscencesDrawer
      {...drawerState}
      attendanceStatuses={statuses}
      columns={columns}
      inputOnChange={handleInputChange}
      nextButtonDisabled={false}
      onClickNext={handleClickNext}
      onClickPrevious={handleClickPrevious}
      onSave={handleSave}
      onStatusRadioChange={handleRadioChange}
      previousButtonDisabled={false}
      rows={makeRows(statuses)}
      selectedPeriod={selectedPeriod}
      selectedStudent={selectedStudent}
    />
  )
}
