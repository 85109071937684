import { ChatSwitchKeys, Types } from './constants'

export const formatChannels = (channels, extraProperties = {}) =>
  channels.map((channel) => formatChannel(channel, extraProperties))

export const formatChannel = (channel, extraProperties) => {
  if (channel.classroom) {
    const { organization, classroom, subjects } = channel
    const { id, grade, shift, division } = classroom
    const { name } = organization
    return {
      id,
      type: Types.Classroom,
      grade,
      shift,
      organizationName: name,
      subjects: formatChannels(subjects),
      division,
    }
  }
  switch (channel.channelType) {
    case Types.Subject: {
      const { name, channels, activities, classroomId: classroomID, subjectId: subjectID } = channel
      return {
        id: subjectID,
        classroomID,
        type: Types.Subject,
        name,
        unreadMessages: channel.unreadMessages,
        channels,
        activities: formatChannels(activities, { subject: name, subjectID, classroomID }),
      }
    }
    case Types.Activity: {
      const { name, channels } = channel
      const { subject, subjectID, classroomID } = extraProperties
      return {
        id: channel.activityId,
        subjectID,
        classroomID,
        type: Types.Activity,
        activityType: channel.activityType,
        name,
        description: subject,
        unreadMessages: channel.unreadMessages,
        channels,
      }
    }
    default:
      return {}
  }
}

export const updateActiveChat = (item, channels) => {
  const { type, id } = item

  return channels.map((channel) => {
    // Match channel type and channel id
    if (type === channel.type && id === channel.id) {
      if (channel.type === Types.Subject) {
        // FOR TEACHER: match if the selected channel is in the same classroom
        if (item.classroomID === channel.classroomID) {
          return {
            ...channel,
            isActive: true,
            activities: updateActiveChat(item, channel.activities),
            open: !channel.open,
          }
        }
      } else {
        return { ...channel, isActive: true, open: !channel.open }
      }
    }

    // should not change isActive value if the selected channel is classroom
    const isActive = isClassroomChannel(item) ? channel.isActive : false

    if (channel.type === Types.Classroom) {
      const isParent = isActivityChannel(item) && item.classroomID === channel.id
      return {
        ...channel,
        subjects: updateActiveChat(item, channel.subjects),
        open: isParent ? true : channel.open,
      }
    }
    if (channel.type === Types.Subject) {
      const isParent = isActivityChannel(item) && item.subjectID === channel.id
      return {
        ...channel,
        isActive,
        activities: updateActiveChat(item, channel.activities),
        open: isParent ? true : channel.open,
      }
    }

    return { ...channel, isActive }
  })
}

export const findChannel = (id, type, channels) => {
  for (let i = 0; i < channels.length; i++) {
    const channel = channels[i]
    if (type === channel.type && id === channel.id) return channel
    if (channel.type === Types.Classroom) {
      const foundChannel = findChannel(id, type, channel.subjects)
      if (foundChannel) return foundChannel
    }
    if (channel.type === Types.Subject) {
      const foundChannel = findChannel(id, type, channel.activities)
      if (foundChannel) return foundChannel
    }
  }
  return false
}

export const getMessageParams = (item, channelType, userID) => {
  const { type, id, classroomID } = item

  const commonParams = { type, destinatary: channelType }
  const subjectParams = { subject: id, classroom: classroomID }
  const activityParams = { activity: id }
  const teacherParams = { user: userID }

  return {
    ...commonParams,
    ...(type === Types.Subject && subjectParams),
    ...(type === Types.Activity && activityParams),
    ...(channelType === ChatSwitchKeys.Teacher && teacherParams),
  }
}

export const getSocketMessageParams = (item, channelType, userID) => {
  const { type, id, classroomID } = item

  const commonParams = { channelType: type, destinatary: channelType, userId: userID }
  const subjectParams = { subjectId: id, classroomId: classroomID }
  const activityParams = { activityId: id }

  return {
    ...commonParams,
    ...(type === Types.Subject && subjectParams),
    ...(type === Types.Activity && activityParams),
  }
}

export const isClassroomChannel = (item) => item.type === Types.Classroom
export const isSubjectChannel = (item) => item.type === Types.Subject
export const isActivityChannel = (item) => item.type === Types.Activity

export const reconcileChannels = (newChannels, oldChannels) =>
  newChannels.map((newChannel) => {
    const oldChannel = oldChannels.find((oldChannel) => oldChannel.id === newChannel.id)
    switch (newChannel.type) {
      case Types.Classroom: {
        if (oldChannel)
          return {
            ...oldChannel,
            subjects: reconcileChannels(newChannel.subjects, oldChannel.subjects),
          }
        return newChannel
      }
      case Types.Subject: {
        if (oldChannel)
          return {
            ...oldChannel,
            activities: reconcileChannels(newChannel.activities, oldChannel.activities),
          }
        return newChannel
      }
      case Types.Activity:
      default: {
        if (oldChannel) return { ...oldChannel, ...newChannel }
        return newChannel
      }
    }
  })

export const addNewChannel = (channels, newChannel) => {
  const existChannelType = channels.some((channel) => channel.id === newChannel.id)
  if (!existChannelType) return [...channels, newChannel]
  return channels.map((channel) => {
    if (channel.id === newChannel.id) {
      switch (channel.type) {
        case Types.Classroom: {
          return { ...channel, subjects: addNewChannel(channel.subjects, newChannel.subjects[0]) }
        }
        case Types.Subject: {
          return {
            ...channel,
            activities: addNewChannel(channel.activities, newChannel.activities[0]),
          }
        }
      }
    }
    return channel
  })
}
