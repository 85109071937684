export const VIDEOCALL_ORIGIN = {
  CHAT: 'CHAT',
  EXOR: 'EXOR',
  EXES: 'EXES',
}

export const LEAVE_VIDEOCALL_REASONS = {
  CLOSE_POPUP: 'CLOSE_POPUP',
  HANG_UP: 'HANG_UP',
  ONLY_ONE_PARTICIPANT_LEFT: 'ONLY_ONE_PARTICIPANT_LEFT',
  EXOR_EVALUATED: 'EXOR_EVALUATED',
}
