import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/principal/documentation/types/approvalModal'

const initialState: Types.ApprovalModalState = {
  isOpenModal: false,
  status: REQUEST_STATUS.IDLE,
  classrooms: [],
  infoForApproval: {
    student: {
      id: 0,
      name: '',
      lastName: '',
    },
    selectedClassroom: { id: 0, name: '' },
    receiver: '',
    documentId: 0,
  },
}

const approvalModalReducer = (
  state: Types.ApprovalModalState = initialState,
  action: Types.ApprovalModalAction
): Types.ApprovalModalState => {
  switch (action.type) {
    case Types.APPROVAL_MODAL_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isOpenModal: true,
      }
    }
    case Types.APPROVAL_MODAL_TYPES.CLOSE_MODAL: {
      return {
        ...state,
        isOpenModal: false,
      }
    }
    case Types.APPROVAL_MODAL_TYPES.GET_CLASSROOMS_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.APPROVAL_MODAL_TYPES.GET_CLASSROOMS_SUCCESS: {
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        classrooms: action.payload,
      }
    }
    case Types.APPROVAL_MODAL_TYPES.GET_CLASSROOMS_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.APPROVAL_MODAL_TYPES.SET_BASIC_INFO_FOR_APPROVAL: {
      return {
        ...state,
        infoForApproval: {
          ...state.infoForApproval,
          ...action.payload,
        },
      }
    }
    case Types.APPROVAL_MODAL_TYPES.SET_SELECTED_CLASSROOM: {
      return {
        ...state,
        infoForApproval: {
          ...state.infoForApproval,
          selectedClassroom: action.payload,
        },
      }
    }
    case Types.APPROVAL_MODAL_TYPES.APPROVE_DOCUMENT_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.APPROVAL_MODAL_TYPES.APPROVE_DOCUMENT_SUCCESS: {
      return initialState
    }
    case Types.APPROVAL_MODAL_TYPES.APPROVE_DOCUMENT_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
      }
    }
    case Types.APPROVAL_MODAL_TYPES.CLEAN_MODAL: {
      return initialState
    }
    default:
      return state
  }
}

export default approvalModalReducer
