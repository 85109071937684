import type { ParsedBORParticipant } from '@/sections/conference/components/breakoutRoomsModal'
import type { IndividualRole } from '@/legacy/roles'

export const GET_BREAKOUT_ROOMS_REQUEST = 'CONFERENCE/BREAKOUT_ROOMS/GET_BREAKOUT_ROOMS_REQUEST'
export const GET_BREAKOUT_ROOMS_SUCCESS = 'CONFERENCE/BREAKOUT_ROOMS/GET_BREAKOUT_ROOMS_SUCCESS'
export const GET_BREAKOUT_ROOMS_FAILURE = 'CONFERENCE/BREAKOUT_ROOMS/GET_BREAKOUT_ROOMS_FAILURE'

export const UPDATE_BREAKOUT_ROOMS_REQUEST =
  'CONFERENCE/BREAKOUT_ROOMS/UPDATE_BREAKOUT_ROOMS_REQUEST'
export const UPDATE_BREAKOUT_ROOMS_SUCCESS =
  'CONFERENCE/BREAKOUT_ROOMS/UPDATE_BREAKOUT_ROOMS_SUCCESS'
export const UPDATE_BREAKOUT_ROOMS_FAILURE =
  'CONFERENCE/BREAKOUT_ROOMS/UPDATE_BREAKOUT_ROOMS_FAILURE'

export const MODIFY_LOCAL_BOR_COPY = 'CONFERENCE/BREAKOUT_ROOMS/MODIFY_LOCAL_BOR_COPY'
export const DISCARD_LOCAL_BOR_COPY = 'CONFERENCE/BREAKOUT_ROOMS/DISCARD_LOCAL_BOR_COPY'

export const SET_BOR_MODAL_OPEN = 'CONFERENCE/BREAKOUT_ROOMS/SET_BOR_MODAL_OPEN'
export const SET_BOR_DRAWER_OPEN = 'CONFERENCE/BREAKOUT_ROOMS/SET_BOR_DRAWER_OPEN'

export const UPDATE_BOR_FROM_SOCKET = 'CONFERENCE/BREAKOUT_ROOMS/UPDATE_BOR_FROM_SOCKET'

export const SET_SHOW_ONLY_STUDENTS = 'CONFERENCE/BREAKOUT_ROOMS/SET_SHOW_ONLY_STUDENTS'

export interface BreakoutRoomParticipant {
  id: number
  name: string
  role: IndividualRole
}

export interface BreakoutRoom {
  id: number
  lobby: boolean
  name: string
  participants: BreakoutRoomParticipant[]
  url: string
}

export type BreakoutRoomUpdate = {
  id: number
  name: string
  participants: number[]
}

export interface BreakoutRoomsState {
  modalOpen: boolean
  drawerOpen: boolean
  breakoutRooms: BreakoutRoom[]
  localBORCopy: BreakoutRoom[]
  loading: boolean
  error: string
  showOnlyStudents: boolean
}

export interface GetBreakoutRoomsRequest {
  type: typeof GET_BREAKOUT_ROOMS_REQUEST
}

export interface GetBreakoutRoomsSuccess {
  type: typeof GET_BREAKOUT_ROOMS_SUCCESS
  payload: {
    userId: number
    breakoutRooms: BreakoutRoom[]
  }
}

export interface GetBreakoutRoomsFailure {
  type: typeof GET_BREAKOUT_ROOMS_FAILURE
  payload: {
    error: string
  }
}

export interface UpdateBreakoutRoomsRequest {
  type: typeof UPDATE_BREAKOUT_ROOMS_REQUEST
}

export interface UpdateBreakoutRoomsSuccess {
  type: typeof UPDATE_BREAKOUT_ROOMS_SUCCESS
}

export interface UpdateBreakoutRoomsFailure {
  type: typeof UPDATE_BREAKOUT_ROOMS_FAILURE
  payload: {
    error: string
  }
}

export interface ModifyLocalBORCopy {
  type: typeof MODIFY_LOCAL_BOR_COPY
  payload: {
    participant: ParsedBORParticipant
    newRoomId: number
  }
}

export interface DiscardLocalBORCopy {
  type: typeof DISCARD_LOCAL_BOR_COPY
}

export interface SetBORModalOpen {
  type: typeof SET_BOR_MODAL_OPEN
  payload: {
    modalOpen: boolean
  }
}

export interface SetBORDrawerOpen {
  type: typeof SET_BOR_DRAWER_OPEN
  payload: {
    drawerOpen: boolean
  }
}

export interface UpdateBORFromSocket {
  type: typeof UPDATE_BOR_FROM_SOCKET
  payload: {
    breakoutRooms: BreakoutRoom[]
    userId: number
  }
}

export interface SetShowOnlyStudents {
  type: typeof SET_SHOW_ONLY_STUDENTS
  payload: {
    showOnlyStudents: boolean
  }
}

export type BreakoutRoomsAction =
  | GetBreakoutRoomsRequest
  | GetBreakoutRoomsSuccess
  | GetBreakoutRoomsFailure
  | UpdateBreakoutRoomsRequest
  | UpdateBreakoutRoomsSuccess
  | UpdateBreakoutRoomsFailure
  | ModifyLocalBORCopy
  | DiscardLocalBORCopy
  | SetBORModalOpen
  | SetBORDrawerOpen
  | UpdateBORFromSocket
  | SetShowOnlyStudents
