import { combineReducers } from 'redux'
import library from './reducers/library'
import libraryActivities from './reducers/libraryActivities'
import libraryBooks from './reducers/libraryBooks'
import libraryCourses from './reducers/libraryCourses'
import librarySequences from './reducers/librarySequences'
import assignCourse from './reducers/assignCourse'
import sequenceAssignmentModal from './reducers/sequenceAssignmentModal'
import booksDrawer from './reducers/booksDrawer'
import assigBookModal from './reducers/assignBookModal'

export default combineReducers({
  assigBookModal,
  booksDrawer,
  library,
  libraryActivities,
  libraryBooks,
  libraryCourses,
  librarySequences,
  assignCourse,
  sequenceAssignmentModal,
})
