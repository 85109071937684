import React from 'react'
import type { Theme } from '@mui/styles'
import { makeStyles } from '@mui/styles'

export interface StyleProps {
  size?: number
  white?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  anibot: ({ size, white }) => ({
    height: size,
    width: size,
    backgroundColor: 'transparent',
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/anibot-large-${
      white ? 'white' : 'black'
    }.gif)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: size,
  }),
}))

export interface AnibotProps {
  size?: number
  white?: boolean
}

export const Anibot = React.forwardRef(
  ({ size = 50, white = false }: AnibotProps, ref: React.LegacyRef<HTMLDivElement>) => {
    const classes = useStyles({ size, white })
    return <div ref={ref} className={classes.anibot} />
  }
)

export default Anibot
