import React from 'react'
import { Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  popper: ({ topOffset, leftOffset }) => ({
    marginLeft: leftOffset || 0,
    marginTop: topOffset || 0,
  }),
  tooltip: {
    background: 'rgba(26, 26, 26, 0.9)',
  },
  arrow: {
    '&::before': {
      background: 'rgba(26, 26, 26, 0.9)',
    },
  },
}))

const CustomTooltip = ({
  title,
  topOffset = 0,
  leftOffset = 0,
  arrow = true,
  children,
  placement = 'bottom',
  ...props
}) => {
  const classes = useStyles({ topOffset, leftOffset })

  return (
    <Tooltip
      classes={{ popper: classes.popper, tooltip: classes.tooltip, arrow: classes.arrow }}
      placement={placement}
      title={title}
      arrow={arrow}
      {...props}
    >
      {children}
    </Tooltip>
  )
}

export default CustomTooltip
