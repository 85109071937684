import moment from 'moment'
import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import { SAVE_OR_EDIT_REPORT_CARD_CONFIG_SUCCESS } from '@/sections/report-card/config/types/apiInteractions'
import * as Types from '@/sections/report-card/config/types/stepperModalSteps/periods'

const initialState: Types.ReportCardConfigPeriodsState = {
  periods: [],
  extraPeriods: [],
  newPeriodOpen: false,
  newPeriodIsExtra: false,
  newPeriod: {
    startDate: moment(),
    endDate: moment().add(1, 'day'),
    name: '',
    id: 0,
    editable: true,
    extra: false,
  },
  newExtraPeriod: {
    startDate: moment(),
    endDate: moment().add(1, 'day'),
    name: '',
    id: 0,
    editable: true,
    extra: true,
  },
  finalMark: true,
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_CONFIG_PERIODS_STEP

const ReportCardConfigPeriodsStep: Reducer<
  Types.ReportCardConfigPeriodsState,
  Types.ReportCardConfigPeriodAction
> = (state = initialState, action): Types.ReportCardConfigPeriodsState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case Redux.Types.Generic.TOGGLE_OPEN:
      if (!action.payload.open)
        return { ...state, newPeriodOpen: false, newPeriod: initialState.newPeriod }
      return {
        ...state,
        newPeriodOpen: action.payload.open,
      }
    case Types.SET_PERIODS: {
      if (action.payload.isExtra) {
        return { ...state, extraPeriods: action.payload.periods }
      } else {
        return { ...state, periods: action.payload.periods }
      }
    }
    case Types.SET_NEW_PERIOD_OPEN: {
      const p = action.payload

      if (p.open) {
        // If we're opening the modal
        return { ...state, newPeriodOpen: p.open, newPeriodIsExtra: p.isExtra }
      } else {
        // If we're closing it
        return { ...state, newPeriodOpen: p.open, newPeriodIsExtra: false }
      }
    }
    case Types.SET_NEW_PERIOD_NAME: {
      if (action.payload.isExtra) {
        return {
          ...state,
          newExtraPeriod: { ...state.newExtraPeriod, name: action.payload.name },
        }
      } else {
        return { ...state, newPeriod: { ...state.newPeriod, name: action.payload.name } }
      }
    }
    case Types.SET_NEW_PERIOD_START_DATE:
      if (action.payload.isExtra) {
        return {
          ...state,
          newExtraPeriod: { ...state.newExtraPeriod, startDate: action.payload.startDate },
        }
      } else {
        return { ...state, newPeriod: { ...state.newPeriod, startDate: action.payload.startDate } }
      }
    case Types.SET_NEW_PERIOD_END_DATE:
      if (action.payload.isExtra) {
        return {
          ...state,
          newExtraPeriod: { ...state.newExtraPeriod, endDate: action.payload.endDate },
        }
      } else {
        return { ...state, newPeriod: { ...state.newPeriod, endDate: action.payload.endDate } }
      }
    case Types.ADD_NEW_PERIOD:
      return {
        ...state,
        newPeriod: initialState.newPeriod,
        newPeriodIsExtra: false,
        newPeriodOpen: false,
        periods: [
          ...state.periods,
          {
            startDate: state.newPeriod.startDate,
            endDate: state.newPeriod.endDate,
            name: state.newPeriod.name,
            id: 0,
            editable: true,
            extra: false,
          },
        ],
      }
    case Types.ADD_NEW_SUPPORT_PERIOD:
      return {
        ...state,
        newExtraPeriod: initialState.newExtraPeriod,
        newPeriodIsExtra: false,
        newPeriodOpen: false,
        extraPeriods: [
          ...state.extraPeriods,
          {
            startDate: state.newExtraPeriod.startDate,
            endDate: state.newExtraPeriod.endDate,
            name: state.newExtraPeriod.name,
            id: 0,
            editable: true,
            extra: true,
          },
        ],
      }
    case Types.SET_FINAL_MARK:
      return {
        ...state,
        finalMark: action.payload.finalMark,
      }
    case Types.SET_VALUES_FOR_EDITION: {
      const { periodsState } = action.payload
      return periodsState
    }
    case SAVE_OR_EDIT_REPORT_CARD_CONFIG_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, ReportCardConfigPeriodsStep, requestDestination)
