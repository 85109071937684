import type { GetFeatureActions, FeaturesState } from './types'
import {
  GET_ORGANIZATION_FEATURES_REQUEST,
  GET_ORGANIZATION_FEATURES_SUCCESS,
  GET_ORGANIZATION_FEATURES_FAILURE,
} from './types'

const initialState: FeaturesState = {
  features: [],
  loading: false,
  error: '',
}

function root(state = initialState, action: GetFeatureActions): FeaturesState {
  switch (action.type) {
    case GET_ORGANIZATION_FEATURES_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case GET_ORGANIZATION_FEATURES_SUCCESS: {
      const { features } = action.payload
      return {
        ...state,
        loading: false,
        features,
      }
    }
    case GET_ORGANIZATION_FEATURES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: '',
      }
    }

    default:
      return state
  }
}

export default root
