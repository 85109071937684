import { persistReducer } from 'redux-persist'
import localforage from 'localforage'
import type {
  LibraryActivitiesActions,
  LibraryActivitiesState,
} from '@/sections/teacher/library/types/libraryActivities'
import {
  GET_PREMIUM_ACTIVITIES_FAILURE,
  GET_PREMIUM_ACTIVITIES_REQUEST,
  GET_PREMIUM_ACTIVITIES_SUCCESS,
  GET_ALL_TAGS_SUCCESS,
  GET_ALL_TAGS_FAILURE,
  SET_CONTACT_MODAL_OPEN,
  SET_ONLY_WITH_LICENSES,
  SET_VIEW,
  RESET_PAGINATION,
  SET_SEARCH,
} from '@/sections/teacher/library/types/libraryActivities'
import { SET_FILTERS } from '@/sections/teacher/library/types/library'

const initialState: LibraryActivitiesState = {
  open: false,
  loading: false,
  activities: [],
  error: '',
  premiumActivitiesPagination: {
    page: 0,
    rowsPerPage: 12,
    count: 0,
  },
  search: '',
  tags: [],
  contactOpen: false,
  onlyWithLicenses: false,
  view: '',
}

function root(
  state: LibraryActivitiesState = initialState,
  action: LibraryActivitiesActions
): LibraryActivitiesState {
  switch (action.type) {
    case GET_PREMIUM_ACTIVITIES_REQUEST: {
      return {
        ...state,
        loading: true,
        premiumActivitiesPagination: {
          ...state.premiumActivitiesPagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    }
    case GET_PREMIUM_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        activities: action.payload.activities,
        error: '',
        premiumActivitiesPagination: {
          ...state.premiumActivitiesPagination,
          count: action.payload.count,
        },
      }
    }
    case GET_PREMIUM_ACTIVITIES_FAILURE: {
      return { ...state, loading: false, activities: [], error: action.payload.error }
    }

    case GET_ALL_TAGS_SUCCESS: {
      return { ...state, tags: action.payload.tagCategories }
    }
    case GET_ALL_TAGS_FAILURE: {
      return { ...state, tags: [], error: action.payload.error }
    }
    case SET_FILTERS: {
      return {
        ...state,
        premiumActivitiesPagination: { ...state.premiumActivitiesPagination, page: 0 },
      }
    }
    case SET_CONTACT_MODAL_OPEN: {
      return { ...state, contactOpen: action.payload }
    }
    case SET_ONLY_WITH_LICENSES: {
      return { ...state, onlyWithLicenses: action.payload }
    }
    case SET_VIEW: {
      return { ...state, view: action.payload }
    }
    case RESET_PAGINATION: {
      return { ...state, premiumActivitiesPagination: initialState.premiumActivitiesPagination }
    }
    case SET_SEARCH: {
      return { ...state, search: action.payload }
    }
    default:
      return state
  }
}

const persistConfig = {
  key: 'library-activities-teacher',
  storage: localforage,
  whitelist: [],
}

export default persistReducer(persistConfig, root)
