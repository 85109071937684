import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/principal/forms/academicModal/types/academicModal'

const initialState: Types.AcademicModalState = {
  currentStep: Types.ACADEMIC_MODAL_STEPS.INITIAL,
  isModalOpen: false,
  status: REQUEST_STATUS.IDLE,
  periodAndGradeList: [],
  selectedPeriodsAndGrades: [],
  grades: [],
  periods: [],
  error: '',
}

const AcademicModalReducer = (
  state: Types.AcademicModalState = initialState,
  action: Types.AcademicModalActions
): Types.AcademicModalState => {
  switch (action.type) {
    case Types.ACADEMIC_MODAL_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isModalOpen: true,
      }
    }
    case Types.ACADEMIC_MODAL_TYPES.CLOSE_MODAL: {
      if (state.selectedPeriodsAndGrades.length > 0) {
        return { ...state, isModalOpen: false }
      }
      return initialState
    }
    case Types.ACADEMIC_MODAL_TYPES.SET_CURRENT_STEP: {
      const { newStep } = action.payload
      return {
        ...state,
        currentStep: newStep,
      }
    }
    case Types.ACADEMIC_MODAL_TYPES.GET_ACADEMIC_PERIODS_AND_GRADE_REQUEST: {
      return { ...state, status: REQUEST_STATUS.LOADING }
    }
    case Types.ACADEMIC_MODAL_TYPES.GET_ACADEMIC_PERIODS_AND_GRADE_SUCCESS: {
      const { periodAndGradeList } = action.payload
      const selectedPeriods = periodAndGradeList
        .filter((pag) =>
          state.selectedPeriodsAndGrades.some((period) => period.meta.name === pag.meta.name)
        )
        .map((selectedPeriodAndGrade) => selectedPeriodAndGrade) as Types.AcademicPeriod[]

      const selectedGrades = state.selectedPeriodsAndGrades
        .map((spg) => {
          return spg.meta.grades.map((g) => ({ ...g, periodName: spg.meta.name })) as Types.Grade[]
        })
        .flat()

      return {
        ...state,
        periodAndGradeList,
        status: REQUEST_STATUS.SUCCESS,
        periods: selectedPeriods,
        grades: selectedGrades,
      }
    }
    case Types.ACADEMIC_MODAL_TYPES.GET_ACADEMIC_PERIODS_AND_GRADE_FAILURE: {
      const { error } = action.payload
      return { ...state, error, status: REQUEST_STATUS.FAILURE }
    }
    case Types.ACADEMIC_MODAL_TYPES.SET_ALL_TOGGLE_PERIODS_ACTION: {
      const list = state.periodAndGradeList.map((pad) => {
        return {
          ...pad,
          meta: {
            ...pad.meta,
            grades: [],
          },
        }
      })
      if (list.length === state.periods.length) {
        return { ...state, periods: [] }
      }
      return { ...state, periods: list }
    }
    case Types.ACADEMIC_MODAL_TYPES.SET_ACADEMIC_PERIOD: {
      const { period } = action.payload
      const isAlreadyAdded = state.periods.some((p) => p.meta.id === period.meta.id)
      if (isAlreadyAdded) {
        const updatedList = state.periods.filter((p) => p.meta.id !== period.meta.id)
        const updatedGrade = state.grades.filter((grade) => grade.periodName !== period.meta.name)
        return { ...state, periods: updatedList, grades: updatedGrade }
      }
      return { ...state, periods: [...state.periods, period] }
    }
    case Types.ACADEMIC_MODAL_TYPES.SET_ALL_TOGGLE_GRADES_ACTION: {
      const gradesList = state.periods
        .map((spg) => {
          const samePeriod = state.periodAndGradeList.find((pgl) => pgl.meta.id === spg.meta.id)
          if (samePeriod) {
            return {
              ...spg,
              meta: {
                ...spg.meta,
                grades: samePeriod.meta.grades.map((grade) => {
                  return {
                    ...grade,
                    periodName: spg.meta.name,
                  }
                }),
              },
            }
          }
          return spg
        })
        .map((g) => g.meta.grades)

      const allGradesLength = gradesList.reduce((acc, val) => acc + val.length, 0)

      if (allGradesLength === state.grades.length) {
        return { ...state, grades: [] }
      }
      return { ...state, grades: gradesList.flat() }
    }
    case Types.ACADEMIC_MODAL_TYPES.SET_ACADEMIC_GRADE: {
      const { grade, periodName } = action.payload

      const alreadyExist = state.grades.some((g) => g.id === grade.id)
      if (alreadyExist) {
        const updatedList = state.grades.filter((g) => g.id !== grade.id)
        return { ...state, grades: updatedList }
      }
      const updatedGrade = { ...grade, periodName }
      return { ...state, grades: [...state.grades, updatedGrade] }
    }
    case Types.ACADEMIC_MODAL_TYPES.SAVE_SELECTED_PERIODS_AND_GRADES: {
      return {
        ...state,
        selectedPeriodsAndGrades: state.periods.map((period) => ({
          ...period,
          meta: {
            ...period.meta,
            grades: state.grades
              .filter((grade) => grade.periodName === period.name)
              .map((grade) => {
                const clonedGrade = { ...grade }
                delete clonedGrade['periodName']
                return {
                  ...clonedGrade,
                }
              }),
          },
        })),
        isModalOpen: false,
        currentStep: Types.ACADEMIC_MODAL_STEPS.INITIAL,
      }
    }
    case Types.ACADEMIC_MODAL_TYPES.SET_SELECTED_PERIODS_AND_GRADES: {
      const { periodsAndGrades } = action.payload
      return {
        ...state,
        selectedPeriodsAndGrades: [...state.selectedPeriodsAndGrades, ...periodsAndGrades],
      }
    }
    case Types.ACADEMIC_MODAL_TYPES.CLEAR_ACADEMIC_MODAL: {
      return { ...initialState }
    }
    default:
      return state
  }
}

export default AcademicModalReducer
