import type { Profile } from '@/api/lms/organizations'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

// State.
export interface DeleteUserProfileModalState {
  isOpenModal: boolean
  status: REQUEST_STATUS
  selectedProfile: Profile
}

// Types.
export enum MODAL_TYPES {
  OPEN_MODAL = 'MANAGEMENT/USERS/DELETE_USER_PROFILE_MODAL/OPEN_MODAL',
  SET_SELECTED_PROFILE = 'MANAGEMENT/USERS/DELETE_USER_PROFILE_MODAL/SET_SELECTED_PROFILE',
  DELETE_USER_PROFILE_REQUEST = 'MANAGEMENT/USERS/DELETE_USER_PROFILE_MODAL/DELETE_USER_PROFILE_REQUEST',
  DELETE_USER_PROFILE_SUCCESS = 'MANAGEMENT/USERS/DELETE_USER_PROFILE_MODAL/DELETE_USER_PROFILE_SUCCESS',
  DELETE_USER_PROFILE_FAILURE = 'MANAGEMENT/USERS/DELETE_USER_PROFILE_MODAL/DELETE_USER_PROFILE_FAILURE',
  CLEAN_MODAL = 'MANAGEMENT/USERS/DELETE_USER_PROFILE_MODAL/CLEAN_MODAL',
}

// Actions.

// Open modal action.
export type OpenModalAction = {
  type: MODAL_TYPES.OPEN_MODAL
}

// Set selected profile action.
export type SetSelectedProfileAction = {
  type: MODAL_TYPES.SET_SELECTED_PROFILE
  payload: Profile
}

// Delete user profile actions.
export type DeleteUserProfileRequestAction = {
  type: MODAL_TYPES.DELETE_USER_PROFILE_REQUEST
}

export type DeleteUserProfileSuccessAction = {
  type: MODAL_TYPES.DELETE_USER_PROFILE_SUCCESS
}

export type DeleteUserProfileFailureAction = {
  type: MODAL_TYPES.DELETE_USER_PROFILE_FAILURE
}

export type DeleteUserProfileAction =
  | DeleteUserProfileRequestAction
  | DeleteUserProfileSuccessAction
  | DeleteUserProfileFailureAction

// Clean modal action.
export type CleanModalAction = {
  type: MODAL_TYPES.CLEAN_MODAL
}

// Action.
export type DeleteUserProfileModalAction =
  | OpenModalAction
  | SetSelectedProfileAction
  | DeleteUserProfileAction
  | CleanModalAction
