export interface BulletinState {
  openDraftMessageModal: boolean
}

export enum BULLETIN_TYPES {
  OPEN_DRAFT_MESSAGE_MODAL = 'TEACHER/BULLETIN/OPEN_DRAFT_MESSAGE_MODAL',
  CLOSE_DRAFT_MESSAGE_MODAL = 'TEACHER/BULLETIN/CLOSE_DRAFT_MESSAGE_MODAL',
}

export type OpenDraftMessageModal = {
  type: BULLETIN_TYPES.OPEN_DRAFT_MESSAGE_MODAL
}

export type CloseDraftMessageModal = {
  type: BULLETIN_TYPES.CLOSE_DRAFT_MESSAGE_MODAL
}

export type BulletinActions = OpenDraftMessageModal | CloseDraftMessageModal
