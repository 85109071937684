import type { Pages } from '@/legacy/components/v3/moreTablePagination'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { GenericActionWithType } from '@/legacy/redux/utils'
import type { ColumnAnswer } from '@/sections/principal/forms/answers/types/answersDrawer'
export interface FormsState {
  status: REQUEST_STATUS
  error: string
  loading: boolean
  organizationId: number
  forms: Form[]
  pagination: Pages
  count: number
  selectedForm: Form
}

export interface Form {
  id: number
  name: string
  description: string
  ownerName: string
  ownerLastName: string
  ownerAvatar: string
  updatedAt: string
  closesAt: string
  opensAt: string
  uuid: string
  answersTable: ColumnAnswer[]
  templateId: number
}

export enum FORMS_TYPES {
  GET_FORMS_REQUEST = 'FORMS/GET_FORMS_REQUEST',
  GET_FORMS_SUCCESS = 'FORMS/GET_FORMS_SUCCESS',
  GET_FORMS_FAILURE = 'FORMS/GET_FORMS_FAILURE',
  SET_PAGINATION_OFFSET = 'FORMS/SET_PAGINATION_OFFSET',
  SET_SELECTED_FORM = 'FORMS/SET_SELECTED_FORM',
}

export type GetFormsRequest = {
  type: FORMS_TYPES.GET_FORMS_REQUEST
  payload: {
    organizationId: number
  }
}

export type GetFormsSuccess = {
  type: FORMS_TYPES.GET_FORMS_SUCCESS
  payload: { forms: Form[]; count: number; page: number; more: boolean }
}

export type GetFormsFailure = GenericActionWithType<
  typeof FORMS_TYPES.GET_FORMS_FAILURE,
  { error: string }
>
export type SetPaginationOffset = {
  type: typeof FORMS_TYPES.SET_PAGINATION_OFFSET
  payload: { offset: number }
}

export type SetSelectedFormAction = {
  type: FORMS_TYPES.SET_SELECTED_FORM
  payload: {
    form: Form
  }
}

export type FormsActionsCreator =
  | GetFormsRequest
  | GetFormsSuccess
  | GetFormsFailure
  | SetPaginationOffset
  | SetSelectedFormAction
