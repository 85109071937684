import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { SetOpen } from '@/sections/student/collabMiniature/types/collabMiniatureWaitingRoom'
import { SET_OPEN } from '@/sections/student/collabMiniature/types/collabMiniatureWaitingRoom'

const CollabMiniatureWaitingRoomActionCreators = {
  setOpen: (requestOrigins: ReduxRequestOrigin[], open: boolean, activityId?: number): SetOpen => ({
    type: SET_OPEN,
    payload: { requestOrigins, open, activityId },
  }),
}

export default CollabMiniatureWaitingRoomActionCreators
