import React from 'react'
import { makeStyles } from '@mui/styles'
import MUITextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import classNames from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 4,
    border: '1px solid rgba(0,0,0,0)',
    backgroundColor: '#edeff8!important',
  },
  label: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: '18px',
    marginBottom: 5,
    textTransform: 'uppercase',
    borderWidth: 1,
  },
  multiline: {
    padding: 10,
  },
  focused: {
    boxShadow: '0px 0px 1px 3px rgba(79, 54, 214, 0.15)',
    border: '1px solid' + theme.palette.secondary.main,
    backgroundColor: '#fff',
  },
  helperContained: {
    marginLeft: 0,
  },
  select: {
    paddingTop: 10,
  },
}))

const TextField = ({
  containerClass = '',
  InputClasses = {},
  children,
  withoutColon = false,
  noLabel,
  value,
  onChange,
  label,
  helperText,
  error,
  required,
  InputProps = {},
  inputProps = {},
  ...props
}) => {
  const classes = useStyles()
  return (
    <div className={containerClass}>
      {!noLabel && (
        <Typography className={classes.label}>
          {label}
          {!withoutColon && ':'}
        </Typography>
      )}
      <MUITextField
        {...props}
        value={value}
        onChange={onChange}
        helperText={error && error !== ' ' ? error : helperText}
        error={!!error}
        InputProps={{
          classes: {
            focused: classes.focused,
            root: classNames(classes.root, InputClasses.root),
            multiline: classes.multiline,
          },
          disableUnderline: true,
          ...InputProps,
        }}
        inputProps={{
          ...inputProps,
          style: {
            paddingTop: 10,
          },
        }}
        SelectProps={{
          classes: {
            select: classes.select,
          },
        }}
        FormHelperTextProps={{
          classes: { contained: classes.helperContained },
        }}
        required={required}
        variant="filled"
      >
        {children}
      </MUITextField>
    </div>
  )
}

export default TextField
