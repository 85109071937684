import type { ManagementClassroom } from '@/api/lms/classrooms'
import type { ManagementNeighborhood } from '@/api/lms/organizations'
import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type {
  ErrorPayload,
  GenericActionWithType,
  GenericPagination,
} from '@/legacy/redux/utils'
import type { Pages } from '@/sections/content-creator/dashboard/types/dashboard'
import type { ClassroomsOption } from '@/sections/eb-admin/management/types/examsDrawer'

export type ClassroomSelectorModalState = {
  open: boolean
  loading: boolean
  error: string
  pagination: Pages
  classrooms: ManagementClassroom[]
  classroomOptions: ClassroomsOption[]
  search: string
  grade: string
  management: number
  neighborhood: number
  organizationId: number
  neighborhoods: ManagementNeighborhood[]
  allSelected: boolean
}

export const REMOVE_CLASSROOM = 'MANAGEMENT/CLASSROOM_SELECTOR_MODAL/REMOVE_CLASSROOM'
export type RemoveClassroom = GenericActionWithType<
  typeof REMOVE_CLASSROOM,
  { classroom: ManagementClassroom }
>

export const ADD_CLASSROOM = 'MANAGEMENT/CLASSROOM_SELECTOR_MODAL/ADD_CLASSROOM'
export type AddClassroom = GenericActionWithType<
  typeof ADD_CLASSROOM,
  { classroom: ManagementClassroom }
>

export const SET_OPEN = 'MANAGEMENT/CLASSROOM_SELECTOR_MODAL/SET_OPEN'
export type SetOpen = GenericActionWithType<
  typeof SET_OPEN,
  { classroomOptions: ClassroomsOption[] }
>

export const LIST_CLASSROOMS_AS_ADMIN_REQUEST =
  'MANAGEMENT/CLASSROOM_SELECTOR_MODAL/LIST_CLASSROOMS_AS_ADMIN_REQUEST'
export const LIST_CLASSROOMS_AS_ADMIN_SUCCESS =
  'MANAGEMENT/CLASSROOM_SELECTOR_MODAL/LIST_CLASSROOMS_AS_ADMIN_SUCCESS'
export const LIST_CLASSROOMS_AS_ADMIN_FAILURE =
  'MANAGEMENT/CLASSROOM_SELECTOR_MODAL/LIST_CLASSROOMS_AS_ADMIN_FAILURE'

export type ListClassroomsAsAdminRequest = GenericActionWithType<
  typeof LIST_CLASSROOMS_AS_ADMIN_REQUEST,
  GenericPagination
>
export type ListClassroomsAsAdminSuccess = GenericActionWithType<
  typeof LIST_CLASSROOMS_AS_ADMIN_SUCCESS,
  { classrooms: ManagementClassroom[]; count: number }
>
export type ListClassroomsAsAdminFailure = GenericActionWithType<
  typeof LIST_CLASSROOMS_AS_ADMIN_FAILURE,
  ErrorPayload
>
export type ListClassroomsAsAdminAction =
  | ListClassroomsAsAdminRequest
  | ListClassroomsAsAdminSuccess
  | ListClassroomsAsAdminFailure

// Get Neighborhoods
export const LIST_NEIGHBORHOODS_AS_ADMIN_REQUEST =
  'MANAGEMENT/CLASSROOM_SELECTOR_MODAL/LIST_NEIGHBORHOODS_AS_ADMIN_REQUEST'
export const LIST_NEIGHBORHOODS_AS_ADMIN_SUCCESS =
  'MANAGEMENT/CLASSROOM_SELECTOR_MODAL/LIST_NEIGHBORHOODS_AS_ADMIN_SUCCESS'
export const LIST_NEIGHBORHOODS_AS_ADMIN_FAILURE =
  'MANAGEMENT/CLASSROOM_SELECTOR_MODAL/LIST_NEIGHBORHOODS_AS_ADMIN_FAILURE'

export type listNeighborhoodsRequest = GenericActionWithType<
  typeof LIST_NEIGHBORHOODS_AS_ADMIN_REQUEST
>
export type listNeighborhoodsSuccess = GenericActionWithType<
  typeof LIST_NEIGHBORHOODS_AS_ADMIN_SUCCESS,
  {
    neighborhoods: ManagementNeighborhood[]
  }
>
export type listNeighborhoodsFailure = GenericActionWithType<
  typeof LIST_NEIGHBORHOODS_AS_ADMIN_FAILURE,
  ErrorPayload
>
export type listNeighborhoodsAction =
  | listNeighborhoodsRequest
  | listNeighborhoodsSuccess
  | listNeighborhoodsFailure

export type ClassroomSelectorModalAction =
  | ListClassroomsAsAdminAction
  | listNeighborhoodsAction
  | RemoveClassroom
  | AddClassroom
  | SetOpen
  | ClearState
  | SetField<'search', string>
