import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import { ScoreType } from '@/sections/report-card/types/common'
import {
  GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_SUCCESS,
  SAVE_OR_EDIT_REPORT_CARD_CONFIG_SUCCESS,
} from '@/sections/report-card/config/types/apiInteractions'
import * as Types from '@/sections/report-card/config/types/stepperModalSteps/subjects'

export const STUDENT_DUTTIES_NAME = 'Quehaceres del estudiante'
export const COEXISTENCE_NAME = 'Convivencia'

const initialState: Types.ReportCardConfigSubjectsState = {
  subjects: [],
  loading: false,
  error: '',
  subitemsModalOpen: false,
  attitudinalModalOpen: false,
  attitudinalItems: [
    {
      name: COEXISTENCE_NAME,
      type: ScoreType.CONCEPTUAL,
      enabled: false,
      subitems: [],
      orderValue: 0,
      editable: true,
    },
    {
      name: STUDENT_DUTTIES_NAME,
      type: ScoreType.CONCEPTUAL,
      enabled: false,
      subitems: [],
      orderValue: 1,
      editable: true,
    },
  ],
  reviewsByAttitudinal: false,
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_CONFIG_SUBJECTS_STEP

const ReportCardConfigSubjectsStep: Reducer<
  Types.ReportCardConfigSubjectsState,
  Types.ReportCardConfigSubjectsAction
> = (state = initialState, action): Types.ReportCardConfigSubjectsState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_SUCCESS:
      return { ...state, subjects: action.payload.subjects }
    case Types.TOGGLE_SUBJECT_SCORE_TYPE: {
      const { subject } = action.payload

      const newSubjects = state.subjects.map((s) => {
        if (s.subjectId === subject.subjectId) {
          return {
            ...s,
            scoreType:
              s.scoreType === ScoreType.NUMERICAL ? ScoreType.CONCEPTUAL : ScoreType.NUMERICAL,
          }
        } else {
          return s
        }
      })

      return { ...state, subjects: newSubjects }
    }
    case Types.CHANGE_GRADABLES_SCORE_TYPE: {
      const { newScoreType } = action.payload

      const newSubjects = state.subjects.map((s) => ({ ...s, scoreType: newScoreType }))

      const newAttitudinalItems = state.attitudinalItems.map((ai) => ({
        ...ai,
        type: newScoreType,
      }))

      return {
        ...state,
        subjects: newSubjects,
        attitudinalItems: newAttitudinalItems,
      }
    }
    case Types.CONFIRM_SUBITEMS:
      return {
        ...state,
        subjects: action.payload.subjects,
        attitudinalItems: action.payload.attitudinalItems,
        subitemsModalOpen: false,
      }
    case Types.TOGGLE_ATTITUDINAL_ITEM_SCORE_TYPE: {
      const { attitudinalItem } = action.payload

      return {
        ...state,
        attitudinalItems: state.attitudinalItems.map((ai) =>
          ai.name === attitudinalItem.name
            ? {
                ...ai,
                type: ai.type === ScoreType.CONCEPTUAL ? ScoreType.NUMERICAL : ScoreType.CONCEPTUAL,
              }
            : ai
        ),
      }
    }

    case Types.REORDER_SUBJECTS: {
      const { subjects } = action.payload
      const newSubjects = subjects.map((s, i) => {
        const sub = { ...s, orderValue: i }
        return sub
      })
      return {
        ...state,
        subjects: newSubjects,
      }
    }
    case Types.REORDER_ATTITUDINALS: {
      const { attitudinalItems } = action.payload
      const newAttitudinals = attitudinalItems.map((at, i) => ({ ...at, orderValue: i }))
      return {
        ...state,
        attitudinalItems: newAttitudinals,
      }
    }

    case Types.SET_VALUES_FOR_EDITION: {
      const { subjectsState } = action.payload

      const attitudinalItems = initialState.attitudinalItems.map((initialAttitudinalItem) => {
        const existingAttitudinalItem = subjectsState.attitudinalItems.find(
          ({ name }) => name === initialAttitudinalItem.name
        )
        return existingAttitudinalItem || initialAttitudinalItem
      })

      return {
        ...subjectsState,
        attitudinalItems,
      }
    }
    case SAVE_OR_EDIT_REPORT_CARD_CONFIG_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, ReportCardConfigSubjectsStep, requestDestination)
