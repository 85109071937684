import type {
  Activity,
  ActivitySubmission,
  AllowResubmissionSuccess,
  ChangeEvaluationTypeSuccess,
  CloseStudentList,
  EvaluateSubmissionSuccess,
  GetStudentListSuccess,
  Grade,
  SetFeedback,
  SetGrade,
  SetGradeEditable,
  SetQuestionGrade,
  SortedStudents,
  Student,
  Note,
  AddQuestionNote,
  RemoveQuestionNote,
  GetStudentSuspiciousActivityRequest,
  GetStudentSuspiciousActivitySuccess,
  ReplaceQuestionNote,
} from '@/sections/teacher/studentList/types/studentList'
import {
  ALLOW_RESUBMISSION_FAILURE,
  ALLOW_RESUBMISSION_REQUEST,
  ALLOW_RESUBMISSION_SUCCESS,
  CHANGE_EVALUATION_TYPE_FAILURE,
  CHANGE_EVALUATION_TYPE_REQUEST,
  CHANGE_EVALUATION_TYPE_SUCCESS,
  EVALUATE_SUBMISSION_FAILURE,
  EVALUATE_SUBMISSION_REQUEST,
  EVALUATE_SUBMISSION_SUCCESS,
  GET_STUDENT_LIST_FAILURE,
  GET_STUDENT_LIST_REQUEST,
  GET_STUDENT_LIST_SUCCESS,
  SET_CLOSE,
  SET_FEEDBACK,
  SET_GRADE,
  SET_GRADE_EDITABLE,
  SET_OPEN,
  SET_QUESTION_GRADE,
  SET_SELECTED_STUDENT,
  ADD_QUESTION_NOTE,
  REMOVE_QUESTION_NOTE,
  GET_STUDENT_SUSPICIOUS_ACTIVITY_REQUEST,
  GET_STUDENT_SUSPICIOUS_ACTIVITY_SUCCESS,
  GET_STUDENT_SUSPICIOUS_ACTIVITY_FAILURE,
  REPLACE_QUESTION_NOTE,
} from '@/sections/teacher/studentList/types/studentList'
import type { AppThunk } from '@/state/thunk'
import { apiPrivate } from '@/api'
import { url } from '@aula/config'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import activityActions from '@/sections/teacher/dashboard/actions/activity'
import messagesActions from '@/sections/header/actions/messages'
import { API } from '@/api/lms'
import { AVVEvent } from '@/api/lms/avv'
import { ActivityTypes } from '@/api/lms/activities/activities'
import type { Pages } from '@/sections/teacher/overview/types/overview'
import { pagesToLimit } from '@/legacy/utils/generalUtils'
import { setScoreByEvaluationType } from '@aula/tools/scores'
import overviewActions from '@/sections/teacher/overview/actions/overview'
import dashboardActions from '@/sections/teacher/dashboard/actions/dashboard'
import evaluationActions from '@/sections/teacher/evaluation/actions/evaluation'
import moment from 'moment'

const getSortedStudents = (actualStudent: Student, students: Student[]): SortedStudents => {
  let actualStudentVisited = false
  const previousStudents: Student[] = []
  const nextStudents: Student[] = []
  students.forEach((student) => {
    if (student.id === actualStudent.id) {
      actualStudentVisited = true
      return
    } else {
      if (actualStudentVisited) {
        nextStudents.push(student)
        return
      }
      previousStudents.push(student)
    }
  })
  return { previousStudents, nextStudents }
}

const actions = {
  setOpen:
    (activity: Activity): AppThunk<void> =>
    (dispatch, getState) => {
      dispatch({
        type: SET_OPEN,
        payload: {
          activity,
        },
      })
      dispatch(
        actions.getStudentList(activity, getState().teacher.studentList.studentListPagination)
      )
    },
  setClose: (): CloseStudentList => ({ type: SET_CLOSE }),
  setSelectedStudent:
    (student: Student): AppThunk<void> =>
    (dispatch) => {
      dispatch({
        type: SET_SELECTED_STUDENT,
        payload: { student },
      })
    },
  setGrade: (grade: number): SetGrade => ({ type: SET_GRADE, payload: { grade } }),
  setFeedback: (feedback: string): SetFeedback => ({ type: SET_FEEDBACK, payload: { feedback } }),
  setGradeEditable: (editable: boolean): SetGradeEditable => ({
    type: SET_GRADE_EDITABLE,
    payload: { editable },
  }),
  setQuestionGrade: (questionId: number, score: number, comment = ''): SetQuestionGrade => ({
    type: SET_QUESTION_GRADE,
    payload: { questionId, comment, score },
  }),
  addQuestionNote: (questionId: number, note: Note): AddQuestionNote => ({
    type: ADD_QUESTION_NOTE,
    payload: { questionId, note },
  }),
  removeQuestionNote: (questionId: number, noteId: number): RemoveQuestionNote => ({
    type: REMOVE_QUESTION_NOTE,
    payload: { questionId, noteId },
  }),
  replaceQuestionNote: (questionId: number, note: Note): ReplaceQuestionNote => ({
    type: REPLACE_QUESTION_NOTE,
    payload: { questionId, note },
  }),

  evaluateSubmission:
    (activity: ActivitySubmission, grade: Grade, t, evaluationType: number): AppThunk =>
    (dispatch, getState) => {
      dispatch({ type: EVALUATE_SUBMISSION_REQUEST })
      const {
        id: activityID,
        classroom: { id: classroomID },
        lastSubmission: { id: submissionID },
      } = activity

      // @ts-ignore
      const { selectedStudent } = getState().teacher.studentList
      const { gradingPerAnswer } = getState().teacher.dashboard.activity
      const organizationId = getState().user.selectedOrganization.id
      const formattedGrade = {
        ...grade,
        value: gradingPerAnswer ? 100 : setScoreByEvaluationType(grade.value, evaluationType),
        items: grade.items?.map(({ ...item }) => ({
          ...item,
          score: parseFloat(String(item.score)),
          notes: item.notes
            ? item.notes.filter(
                (note) =>
                  typeof note.startIndex !== 'undefined' && typeof note.endIndex !== 'undefined'
              )
            : [],
        })),
      }

      return apiPrivate
        .post(
          url +
            `/v1/classroom/${classroomID}/activities/${activityID}/submissions/${submissionID}/evaluate`,
          formattedGrade
        )
        .then((response) => {
          const { evaluation } = response.data.description
          const message = t('studentList.sharedNote') + selectedStudent.name
          dispatch(actions.evaluateSubmissionSuccess(evaluation, message))
          dispatch(
            evaluationActions.updateStudentEvaluation(selectedStudent.id, activity.id, evaluation)
          )
          dispatch(
            actions.getStudentList(activity, getState().teacher.studentList.studentListPagination)
          )
          dispatch(
            overviewActions.getUncorrectedActivities(classroomID, organizationId, {
              count: 0,
              page: 0,
              rowsPerPage: 4,
            })
          )
          return { success: true }
        })
        .catch((error) => {
          handleErrorsWithAction(error, EVALUATE_SUBMISSION_FAILURE, dispatch)
          return { success: false }
        })
    },

  evaluateSubmissionSuccess: (evaluation: Grade, message: string): EvaluateSubmissionSuccess => ({
    type: EVALUATE_SUBMISSION_SUCCESS,
    payload: {
      evaluation,
      message,
    },
  }),

  allowResubmission:
    (
      activity: ActivitySubmission,
      deadline: string,
      isDashboardViewActive: boolean,
      date: string
    ): AppThunk =>
    (dispatch, getState) => {
      dispatch({ type: ALLOW_RESUBMISSION_REQUEST })
      const {
        id: activityID,
        classroom: { id: classroomID },
        lastSubmission: { id: submissionID },
      } = activity

      const formData = new FormData()
      formData.append('deadline', deadline)

      return apiPrivate
        .post(
          url +
            `/v1/classroom/${classroomID}/activities/${activityID}/submissions/${submissionID}/resubmission`,
          formData
        )
        .then((response) => {
          dispatch(actions.allowResubmissionSuccess())
          dispatch(
            actions.getStudentList(activity, getState().teacher.studentList.studentListPagination)
          )
          dispatch(
            messagesActions.showToast('studentList.send', 'studentList.redeliveryRequestSent')
          )
          dispatch(actions.refreshActivitiesList(classroomID, isDashboardViewActive, date))
        })
        .catch((error) => {
          handleErrorsWithAction(error, ALLOW_RESUBMISSION_FAILURE, dispatch)
        })
    },

  refreshActivitiesList: (
    classroomId: number,
    isDashboardViewActive: boolean,
    date: string
  ): AppThunk => {
    return (dispatch, getState) => {
      const { selectedOrganization, selectedAcademicPeriod } = getState().user

      if (isDashboardViewActive) {
        dispatch(dashboardActions.getActivitiesRefresher({ silentLoading: false }))
        dispatch(
          dashboardActions.getDashboardV4(selectedOrganization.id, selectedAcademicPeriod.id, date)
        )
      } else {
        dispatch(overviewActions.getActivitiesRefresher(classroomId, { silentLoading: false }))
      }
    }
  },

  allowResubmissionSuccess: (): AllowResubmissionSuccess => ({
    type: ALLOW_RESUBMISSION_SUCCESS,
  }),

  getStudentList:
    (activity: Activity | ActivitySubmission, pages: Pages): AppThunk =>
    (dispatch) => {
      const pagination = pagesToLimit(pages)

      dispatch({
        type: GET_STUDENT_LIST_REQUEST,
        payload: {
          page: pages.page,
          rowsPerPage: pages.rowsPerPage,
          count: pages.count,
        },
      })

      const {
        id: activityID,
        classroom: { id: classroomID },
      } = activity

      const params = { limit: pagination.limit, offset: pagination.offset }

      return apiPrivate
        .get(url + `/v1/classroom/${classroomID}/activities/${activityID}/students`, { params })
        .then((response) => {
          const { students, total } = response.data.description
          dispatch(actions.getStudentListSuccess(students, total))
        })
        .catch((error) => {
          handleErrorsWithAction(error, GET_STUDENT_LIST_FAILURE, dispatch)
        })
    },

  getStudentListSuccess: (students: Student[], total: number): GetStudentListSuccess => ({
    type: GET_STUDENT_LIST_SUCCESS,
    payload: {
      students,
      total,
    },
  }),

  changeEvaluationType:
    (activityID: number, classroomID: number, newScale: number): AppThunk<void> =>
    (dispatch, getState) => {
      dispatch({ type: CHANGE_EVALUATION_TYPE_REQUEST })

      const form = {
        evaluationType: newScale,
      }

      return apiPrivate
        .patch(url + `/v1/classroom/${classroomID}/activities/${activityID}`, form)
        .then((response) => {
          const { activity } = response.data.description

          dispatch(actions.changeEvaluationTypeSuccess(activity))
        })
        .catch((error) => {
          handleErrorsWithAction(error, CHANGE_EVALUATION_TYPE_FAILURE, dispatch)
        })
    },

  changeEvaluationTypeSuccess: (activity: Activity): ChangeEvaluationTypeSuccess => ({
    type: CHANGE_EVALUATION_TYPE_SUCCESS,
    payload: {
      activity,
    },
  }),

  moveToNextStudent:
    (actualStudent: Student, students: Student[], activity: Activity): AppThunk =>
    (dispatch) => {
      const { nextStudents, previousStudents } = getSortedStudents(actualStudent, students)
      const sortedStudents = [...nextStudents, ...previousStudents]
      const nextSubmittedStudent = sortedStudents.find((student) => student.submitted)
      if (nextSubmittedStudent) {
        dispatch(actions.setSelectedStudent(nextSubmittedStudent))
        dispatch(
          activityActions.getActivityWithStudentAnswers(
            activity.classroom.id,
            activity.id,
            nextSubmittedStudent.id
          )
        )
        if (activity.type === ActivityTypes.ROBOTS || activity.type === ActivityTypes.ANIMATIONS) {
          dispatch(activityActions.getRobotsStudentProgress(activity.id, nextSubmittedStudent.id))
        }
      }
    },

  moveToPreviousStudent:
    (actualStudent: Student, students: Student[], activity: Activity): AppThunk =>
    (dispatch) => {
      const { nextStudents, previousStudents } = getSortedStudents(actualStudent, students)
      const sortedStudents = [...previousStudents.reverse(), ...nextStudents.reverse()]
      const previousSubmittedStudent = sortedStudents.find((student) => student.submitted)
      if (previousSubmittedStudent) {
        dispatch(actions.setSelectedStudent(previousSubmittedStudent))
        dispatch(
          activityActions.getActivityWithStudentAnswers(
            activity.classroom.id,
            activity.id,
            previousSubmittedStudent.id
          )
        )
        if (activity.type === ActivityTypes.ROBOTS || activity.type === ActivityTypes.ANIMATIONS) {
          dispatch(
            activityActions.getRobotsStudentProgress(activity.id, previousSubmittedStudent.id)
          )
        }
      }
    },

  getStudentSuspiciousActivity:
    (activityId: number, studentId: number, event: AVVEvent = AVVEvent.LOST_FOCUS): AppThunk =>
    async (dispatch) => {
      const request: GetStudentSuspiciousActivityRequest = {
        type: GET_STUDENT_SUSPICIOUS_ACTIVITY_REQUEST,
      }

      dispatch(request)

      try {
        const { dateTimes } = await API.Activities.getActivityEventDates({
          activityId,
          userId: studentId,
          event,
        })

        const success: GetStudentSuspiciousActivitySuccess = {
          type: GET_STUDENT_SUSPICIOUS_ACTIVITY_SUCCESS,
          payload: { dates: dateTimes },
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_STUDENT_SUSPICIOUS_ACTIVITY_FAILURE, dispatch)
      }
    },
}

export default actions
