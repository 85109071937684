import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSaturday = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.822 1v3h10V1h2v3h2a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3h-18a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h2V1h2Zm12 5h-16a1 1 0 0 0-1 1v2h20V7a1 1 0 0 0-1-1h-2Zm-17 5v10a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V11h-20Z"
      fill="#1A1A1A"
    />
    <path
      d="M6.906 19.578a3.01 3.01 0 0 1-1.224-.234 2.012 2.012 0 0 1-.837-.684c-.204-.3-.309-.663-.315-1.09h1.215a1.1 1.1 0 0 0 .315.748c.204.198.483.297.837.297.306 0 .55-.072.73-.216.18-.15.27-.348.27-.594 0-.258-.082-.46-.244-.603a1.748 1.748 0 0 0-.63-.351l-.846-.288c-.486-.168-.858-.384-1.116-.648-.252-.264-.378-.615-.378-1.053-.006-.372.081-.69.261-.954.186-.27.438-.477.756-.621.318-.15.684-.225 1.098-.225.42 0 .79.075 1.107.225.324.15.576.36.756.63.186.27.285.59.297.963H7.725a.836.836 0 0 0-.26-.585c-.163-.174-.39-.261-.685-.261a.986.986 0 0 0-.639.189c-.168.126-.252.312-.252.558 0 .21.066.378.198.504.132.12.312.222.54.306.228.084.49.174.783.27.312.108.597.234.855.378s.465.336.621.576c.156.234.234.537.234.909 0 .33-.084.636-.252.918-.168.282-.417.51-.747.684-.33.168-.735.252-1.215.252Zm2.814-.108 2.304-6.3h1.296l2.304 6.3H14.4l-.504-1.458H11.44l-.513 1.458H9.72Zm2.034-2.358h1.827l-.918-2.62-.909 2.62Zm4.682 2.358v-6.3h2.529c.654 0 1.152.153 1.494.459.348.3.522.687.522 1.16 0 .397-.108.715-.324.955a1.58 1.58 0 0 1-.774.477c.36.072.657.252.89.54.235.282.352.612.352.99 0 .498-.18.909-.54 1.233-.36.324-.87.486-1.53.486h-2.62Zm1.152-3.654h1.206c.324 0 .573-.075.747-.225.174-.15.26-.363.26-.64 0-.263-.086-.47-.26-.62-.168-.156-.423-.234-.765-.234h-1.188v1.719Zm0 2.718h1.287c.342 0 .606-.078.792-.234.192-.162.288-.387.288-.675 0-.294-.1-.525-.297-.693-.198-.168-.465-.252-.801-.252h-1.27v1.854Z"
      fill="#000"
    />
  </svg>
)
export default SvgSaturday
