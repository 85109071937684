import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { SetField } from '@/legacy/redux/types/generic'
import type { ListClassroomStudentsAction } from '@/legacy/redux/types/students'
import type { Pages } from '@/sections/content-creator/dashboard/types/dashboard'

export const LIST_SUBGROUPS_REQUEST = 'STUDENTS_TEACHER/SUBGROUPS/LIST_SUBGROUPS_REQUEST'
export const LIST_SUBGROUPS_SUCCESS = 'STUDENTS_TEACHER/SUBGROUPS/LIST_SUBGROUPS_SUCCESS'
export const LIST_SUBGROUPS_FAILURE = 'STUDENTS_TEACHER/SUBGROUPS/LIST_SUBGROUPS_FAILURE'

export const GET_SUBGROUP_REQUEST = 'STUDENTS_TEACHER/SUBGROUPS/GET_SUBGROUP_REQUEST'
export const GET_SUBGROUP_SUCCESS = 'STUDENTS_TEACHER/SUBGROUPS/GET_SUBGROUP_SUCCESS'
export const GET_SUBGROUP_FAILURE = 'STUDENTS_TEACHER/SUBGROUPS/GET_SUBGROUP_FAILURE'

export const SET_SUBGROUPS_DRAWER_OPEN = 'STUDENTS_TEACHER/SUBGROUPS/SET_SUBGROUPS_DRAWER_OPEN'
export const SET_SUBGROUPS_DRAWER_MODE = 'STUDENTS_TEACHER/SUBGROUPS/SET_SUBGROUPS_DRAWER_MODE'
export const SET_SUBGROUP_NAME = 'STUDENTS_TEACHER/SUBGROUPS/SET_SUBGROUP_NAME'
export const SET_SUBGROUP_NAME_DUPLICATED =
  'STUDENTS_TEACHER/SUBGROUPS/SET_SUBGROUP_NAME_DUPLICATED'

export const TOGGLE_STUDENT_IN_SUBGROUP = 'STUDENTS_TEACHER/SUBGROUPS/TOGGLE_STUDENT_IN_SUBGROUP'

export const LIST_SUBGROUP_STUDENT_IDS_REQUEST =
  'STUDENTS_TEACHER/SUBGROUPS/LIST_SUBGROUP_STUDENT_IDS_REQUEST'
export const LIST_SUBGROUP_STUDENT_IDS_SUCCESS =
  'STUDENTS_TEACHER/SUBGROUPS/LIST_SUBGROUP_STUDENT_IDS_SUCCESS'
export const LIST_SUBGROUP_STUDENT_IDS_FAILURE =
  'STUDENTS_TEACHER/SUBGROUPS/LIST_SUBGROUP_STUDENT_IDS_FAILURE'

export const CREATE_SUBGROUP_REQUEST = 'STUDENTS_TEACHER/SUBGROUPS/CREATE_SUBGROUP_REQUEST'
export const CREATE_SUBGROUP_SUCCESS = 'STUDENTS_TEACHER/SUBGROUPS/CREATE_SUBGROUP_SUCCESS'
export const CREATE_SUBGROUP_FAILURE = 'STUDENTS_TEACHER/SUBGROUPS/CREATE_SUBGROUP_FAILURE'

export const EDIT_SUBGROUP_REQUEST = 'STUDENTS_TEACHER/SUBGROUPS/EDIT_SUBGROUP_REQUEST'
export const EDIT_SUBGROUP_SUCCESS = 'STUDENTS_TEACHER/SUBGROUPS/EDIT_SUBGROUP_SUCCESS'
export const EDIT_SUBGROUP_FAILURE = 'STUDENTS_TEACHER/SUBGROUPS/EDIT_SUBGROUP_FAILURE'

export const DELETE_SUBGROUP_REQUEST = 'STUDENTS_TEACHER/SUBGROUPS/DELETE_SUBGROUP_REQUEST'
export const DELETE_SUBGROUP_SUCCESS = 'STUDENTS_TEACHER/SUBGROUPS/DELETE_SUBGROUP_SUCCESS'
export const DELETE_SUBGROUP_FAILURE = 'STUDENTS_TEACHER/SUBGROUPS/DELETE_SUBGROUP_FAILURE'

export const SET_DELETE_MODAL_OPEN = 'STUDENTS_TEACHER/SUBGROUPS/SET_DELETE_MODAL_OPEN'

export interface Student {
  createdAt: string
  dni: string
  dateOfBirth: string
  deletedAt: string | null
  email: string
  gender: string
  id: number
  lastName: string
  name: string
  phone: string
  tags: string[]
  updatedAt: string
  avatar: string
}

export interface BaseSubgroup {
  id: number
  name: string
  total: number
}

export interface SubgroupWithStudents extends BaseSubgroup {
  students: Student[]
}

export type Subgroup = BaseSubgroup | SubgroupWithStudents

export type DrawerMode = 'read' | 'create' | 'edit'

export const SubgroupStudentsSearchField = 'subgroupStudentsSearch'

export interface SubgroupsState {
  drawerOpen: boolean
  drawerMode: DrawerMode
  deleteModalOpen: boolean
  subgroups: SubgroupWithStudents[]
  selectedSubgroup: {
    id: number
    name: string
    students: Student[]
    studentIds: number[]
  }
  openSubgroup: {
    id: number
    name: string
    studentIds: number[]
  }
  classroomStudents: Student[]
  classroomStudentsPagination: Pages
  subgroupStudentsPagination: Pages
  loadingAllSubgroups: boolean
  loadingSubgroup: boolean
  loadingClassroomStudents: boolean
  loadingSubgroupStudentIds: boolean
  error: string
  [SubgroupStudentsSearchField]: string
  classroomStudentsSearch: string
}

export interface SetSubgroupsDrawerOpen {
  type: typeof SET_SUBGROUPS_DRAWER_OPEN
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    open: boolean
  }
}

export interface SetSubgroupsDrawerMode {
  type: typeof SET_SUBGROUPS_DRAWER_MODE
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    drawerMode: DrawerMode
  }
}

export interface SetSubgroupName {
  type: typeof SET_SUBGROUP_NAME
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    name: string
  }
}

export interface SetSubgroupNameDuplicated {
  type: typeof SET_SUBGROUP_NAME_DUPLICATED
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    error: string
    name: string
  }
}

export interface ListSubgroupsRequest {
  type: typeof LIST_SUBGROUPS_REQUEST
  payload: {
    requestOrigins: ReduxRequestOrigin[]
  }
}

export interface ListSubgroupsSuccess {
  type: typeof LIST_SUBGROUPS_SUCCESS
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    subgroups: SubgroupWithStudents[]
  }
}
export interface ListSubgroupsFailure {
  type: typeof LIST_SUBGROUPS_FAILURE
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    error: string
  }
}

export interface GetSubgroupRequest {
  type: typeof GET_SUBGROUP_REQUEST
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    page: number
    rowsPerPage: number
  }
}

export interface GetSubgroupSuccess {
  type: typeof GET_SUBGROUP_SUCCESS
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    subgroup: SubgroupWithStudents
    count: number
  }
}
export interface GetSubgroupFailure {
  type: typeof GET_SUBGROUP_FAILURE
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    error: string
  }
}

export interface ListSubgroupStudentIdsRequest {
  type: typeof LIST_SUBGROUP_STUDENT_IDS_REQUEST
  payload: {
    requestOrigins: ReduxRequestOrigin[]
  }
}

export interface ListSubgroupStudentIdsSuccess {
  type: typeof LIST_SUBGROUP_STUDENT_IDS_SUCCESS
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    studentIDS: number[]
  }
}

export interface ListSubgroupStudentIdsFailure {
  type: typeof LIST_SUBGROUP_STUDENT_IDS_FAILURE
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    error: string
  }
}

export interface ToggleStudentInSubgroup {
  type: typeof TOGGLE_STUDENT_IN_SUBGROUP
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    studentID: number
  }
}

export interface CreateSubgroupRequest {
  type: typeof CREATE_SUBGROUP_REQUEST
  payload: {
    requestOrigins: ReduxRequestOrigin[]
  }
}

export interface CreateSubgroupSuccess {
  type: typeof CREATE_SUBGROUP_SUCCESS
  payload: {
    requestOrigins: ReduxRequestOrigin[]
  }
}

export interface CreateSubgroupFailure {
  type: typeof CREATE_SUBGROUP_FAILURE
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    error: string
  }
}

export interface EditSubgroupRequest {
  type: typeof EDIT_SUBGROUP_REQUEST
  payload: {
    requestOrigins: ReduxRequestOrigin[]
  }
}

export interface EditSubgroupSuccess {
  type: typeof EDIT_SUBGROUP_SUCCESS
  payload: {
    requestOrigins: ReduxRequestOrigin[]
  }
}

export interface EditSubgroupFailure {
  type: typeof EDIT_SUBGROUP_FAILURE
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    error: string
  }
}

export interface DeleteSubgroupRequest {
  type: typeof DELETE_SUBGROUP_REQUEST
  payload: {
    requestOrigins: ReduxRequestOrigin[]
  }
}

export interface DeleteSubgroupSuccess {
  type: typeof DELETE_SUBGROUP_SUCCESS
  payload: {
    requestOrigins: ReduxRequestOrigin[]
  }
}

export interface DeleteSubgroupFailure {
  type: typeof DELETE_SUBGROUP_FAILURE
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    error: string
  }
}

export interface SetDeleteModalOpen {
  type: typeof SET_DELETE_MODAL_OPEN
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    open: boolean
  }
}

export type SubgroupsAction =
  | SetSubgroupsDrawerOpen
  | SetSubgroupsDrawerMode
  | SetSubgroupName
  | SetSubgroupNameDuplicated
  | ListSubgroupsRequest
  | ListSubgroupsSuccess
  | ListSubgroupsFailure
  | GetSubgroupRequest
  | GetSubgroupSuccess
  | GetSubgroupFailure
  | ListSubgroupStudentIdsRequest
  | ListSubgroupStudentIdsSuccess
  | ListSubgroupStudentIdsFailure
  | ToggleStudentInSubgroup
  | CreateSubgroupRequest
  | CreateSubgroupSuccess
  | CreateSubgroupFailure
  | EditSubgroupRequest
  | EditSubgroupSuccess
  | EditSubgroupFailure
  | DeleteSubgroupRequest
  | DeleteSubgroupSuccess
  | DeleteSubgroupFailure
  | SetDeleteModalOpen
  | SetField<typeof SubgroupStudentsSearchField, string>
  | ListClassroomStudentsAction
