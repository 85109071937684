import { reduxRequestOriginMap } from "@/legacy/redux/constants"
import { AddEditThemeModalAction, AddEditThemeModalState, CREATE_FAILURE, CREATE_REQUEST, CREATE_SUCCESS, EDIT_FAILURE, EDIT_REQUEST, EDIT_SUCCESS, SET_EDIT_INITIAL_VALUES } from "../types/addEditThemeModal"
import { Reducer } from "redux"
import { CLEAR_STATE, SET_FIELD } from "@/legacy/redux/types/generic"
import { checkRequestOrigin, setGenericField } from "@/legacy/redux/utils"

const initialState: AddEditThemeModalState = {
  editMode: false,
  label: '',
  games: [],
  grade: 0,
  exportEnabled: false,
  open: false,
  loading: false,
  error: ''
}

const requestDestination = reduxRequestOriginMap.ADMIN_ADD_EDIT_THEME_MODAL

type SetFieldValue = Extract<AddEditThemeModalAction, { type: typeof SET_FIELD }>['payload']['fieldValue']

const AdminAddEditThemeModalReducer: Reducer<AddEditThemeModalState, AddEditThemeModalAction> = (
  state = initialState,
  action
): AddEditThemeModalState => {
  switch (action.type) {
    case SET_FIELD:
      return setGenericField<AddEditThemeModalState, SetFieldValue>(state, action.payload)
    case SET_EDIT_INITIAL_VALUES:
      return {
        editMode: true,
        label: action.payload.theme.label,
        games: action.payload.theme.games,
        grade: action.payload.theme.grade ?? 0,
        exportEnabled: action.payload.theme.exportEnabled,
        open: true,
        loading: false,
        error: '',
        id: action.payload.theme.id,
      }
    case CREATE_REQUEST:
    case EDIT_REQUEST:
      return { ...state, loading: true }
    case CREATE_FAILURE:
    case EDIT_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case CREATE_SUCCESS:
    case EDIT_SUCCESS:
    case CLEAR_STATE:
      return initialState
  }
}

export default checkRequestOrigin(initialState, AdminAddEditThemeModalReducer, requestDestination)
