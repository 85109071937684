import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import type {
  DeleteExamConfirmModalAction,
  DeleteExamConfirmModalState,
} from '@/sections/management/types/deleteExamConfirmModal'
import { SET_INITIAL_VALUES } from '@/sections/management/types/deleteExamConfirmModal'
import {
  DELETE_EXAM_AS_MGMT_REQUEST,
  DELETE_EXAM_AS_MGMT_SUCCESS,
  DELETE_EXAM_AS_MGMT_FAILURE,
} from '@/sections/eb-admin/management/types/examsDrawer'

const initialState: DeleteExamConfirmModalState = {
  open: false,
  loading: false,
  error: '',
  examId: null,
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_DELETE_EXAM_CONFIRM_MODAL

const DeleteExamConfirmModalReducer: Reducer<
  DeleteExamConfirmModalState,
  DeleteExamConfirmModalAction
> = (state = initialState, action): DeleteExamConfirmModalState => {
  switch (action.type) {
    case SET_INITIAL_VALUES: {
      return {
        ...state,
        open: true,
        examId: action.payload.examId,
      }
    }
    case DELETE_EXAM_AS_MGMT_REQUEST:
      return { ...state, loading: true }
    case Redux.Types.Generic.CLEAR_STATE:
    case DELETE_EXAM_AS_MGMT_SUCCESS:
      return initialState
    case DELETE_EXAM_AS_MGMT_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, DeleteExamConfirmModalReducer, requestDestination)
