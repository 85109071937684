import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  toolTip: {
    background: 'rgba(26, 26, 26, 0.9)',
    fontSize: 14,
    width: 220,
    fontWeight: 'normal',
    padding: '8px 12px',
    textAlign: 'center',
    boxSizing: 'border-box',
    borderRadius: 10,
    lineHeight: '18px',
  },
  arrow: {
    color: 'rgba(26, 26, 26, 0.9)',
  },
  icon: {
    verticalAlign: 'bottom',
  },
}))

const HelpTooltip = (props) => {
  const { title, placement, iconSize, iconColor } = props
  const classes = useStyles()

  return (
    <Tooltip
      title={title}
      classes={{ tooltip: classes.toolTip, arrow: classes.arrow }}
      placement={placement}
      arrow
    >
      <HelpIcon fontSize={iconSize} color={iconColor} className={classes.icon} />
    </Tooltip>
  )
}

HelpTooltip.defaultProps = {
  placement: 'top',
  iconSize: 'small',
  iconColor: 'disabled',
}

export default HelpTooltip
