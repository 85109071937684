import { ApiPrivate } from '@/legacy/services/api/api'
import { webBffUrl } from '@aula/config'

export function getStudentAttendances(classroomId: number, studentId?: number) {
  const params = {
    classroomId,
    studentId,
  }

  return ApiPrivate.get(webBffUrl + `/v1/users/attendances`, { params })
    .then((response) => {
      return {
        attendances: response.data.records,
      }
    })
    .catch((err) => {
      throw err.response.data
    })
}
