import { reportCardUrl } from '@aula/config'
import { ApiPrivate } from '@/legacy/services/api/api'
import { ScoreTypes } from '@/legacy/services/api/reportCard/gradables/types'
import type {
  AttitudinalNumericalScore,
  EditNumericalFinalScoreBody,
  EditNumericalScoreBody,
  EditNumericalScoreResponse,
  GetOneNumericalScoreResponse,
  SaveNumericalScoreResponse,
} from './types'

interface GetScoresParams {
  organizationId: number
  classroomId: number
  periodId: number
  type?: ScoreTypes
}

/**
 * Obtiene las notas numericas para un calificable
 * @doc {@link https://gitlab.com/elishrid/report-card/-/blob/develop/docs/swagger.yaml#/Numerical/get_v2_information_numerical_scores__organizationID__classroomID Documentacion}
 */
export const getScores = ({
  organizationId,
  classroomId,
  periodId,
  type = ScoreTypes.SUBJECT,
}: GetScoresParams) => {
  const params = {
    periodId,
    type,
  }
  return ApiPrivate.get<AttitudinalNumericalScore>(
    reportCardUrl + `/v2/information/numerical_scores/${organizationId}/${classroomId}`,
    { params }
  ).then((response) => response.data)
}

/**
 * Obtiene una nota numérica para un estudiante determinado en un período determinado en una materia determinada de un aula determinada.
 */
export function getOneScore(
  periodId: number,
  classroomId: number,
  subjectId: number,
  studentId: number
) {
  const params = { periodId, classroomId, subjectId, studentId }
  return ApiPrivate.get<GetOneNumericalScoreResponse>(
    reportCardUrl + `/v1/information/numerical_scores/student`,
    {
      params,
    }
  ).then((response) => response.data)
}

interface EditScoresParams {
  reportCardId: number
  periodId: number
  scores: EditNumericalScoreBody
  type?: ScoreTypes
}

/**
 * Actualiza una nota numerica para un calificable
 * @doc {@link https://gitlab.com/elishrid/report-card/-/blob/develop/docs/swagger.yaml#/Numerical/put_v2_values_numerical_scores__report_card_id Documentacion}
 */
export const editScore = ({
  reportCardId,
  periodId,
  scores,
  type = ScoreTypes.SUBJECT,
}: EditScoresParams) => {
  const params = {
    periodId,
    type,
  }
  return ApiPrivate.put<EditNumericalScoreResponse>(
    reportCardUrl + `/v2/values/numerical_scores/${reportCardId}`,
    scores,
    { params }
  ).then((response) => response.data)
}

interface EditFinalScoreParams {
  reportCardId: number
  periodId: number
  scores: EditNumericalFinalScoreBody
  type?: ScoreTypes
}

/**
 * Edita una nota final numerica para un calificable
 */
export const editFinalScore = ({
  reportCardId,
  periodId,
  scores,
  type = ScoreTypes.SUBJECT,
}: EditFinalScoreParams) => {
  const params = {
    periodId,
    type,
  }
  return ApiPrivate.put<SaveNumericalScoreResponse>(
    reportCardUrl + `/v2/values/numerical_scores/${reportCardId}`,
    scores,
    { params }
  ).then((response) => response.data)
}
