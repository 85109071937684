import type { GenericActionWithType } from '@/legacy/redux/utils'

export type VideosModalState = {
  videoId: number
  openAttach: boolean
  openReview: boolean
  openEdit: boolean
  openDelete: boolean
}

export const OPEN_ATTACH_VIDEO_MODAL = 'TED/VIDEOS/MODALS/OPEN_ATTACH_VIDEO_MODAL'
export const CLOSE_ATTACH_VIDEO_MODAL = 'TED/VIDEOS/MODALS/CLOSE_ATTACH_VIDEO_MODAL'
export const OPEN_EDIT_VIDEO_MODAL = 'TED/VIDEOS/MODALS/OPEN_EDIT_VIDEO_MODAL'
export const CLOSE_EDIT_VIDEO_MODAL = 'TED/VIDEOS/MODALS/CLOSE_EDIT_VIDEO_MODAL'
export const OPEN_DELETE_MODAL = 'TED/VIDEOS/MODALS/OPEN_DELETE_MODAL'
export const CLOSE_DELETE_MODAL = 'TED/VIDEOS/MODALS/CLOSE_DELETE_MODAL'
export const OPEN_REVIEW_VIDEO_MODAL = 'TED/VIDEOS/MODALS/OPEN_REVIEW_VIDEO_MODAL'
export const CLOSE_REVIEW_VIDEO_MODAL = 'TED/VIDEOS/MODALS/CLOSE_REVIEW_VIDEO_MODAL'

export type OpenAttachModal = GenericActionWithType<
  typeof OPEN_ATTACH_VIDEO_MODAL,
  { open: boolean }
>

export type CloseAttachModal = GenericActionWithType<
  typeof CLOSE_ATTACH_VIDEO_MODAL,
  { open: boolean }
>

export type OpenEditVideo = GenericActionWithType<
  typeof OPEN_EDIT_VIDEO_MODAL,
  { open: boolean; id: number }
>

export type CloseEditVideo = GenericActionWithType<typeof CLOSE_EDIT_VIDEO_MODAL, { open: boolean }>

export type OpenDeleteModal = GenericActionWithType<
  typeof OPEN_DELETE_MODAL,
  { open: boolean; id: number }
>

export type CloseDeleteModal = GenericActionWithType<typeof CLOSE_DELETE_MODAL, { open: boolean }>

export type OpenReviewModal = GenericActionWithType<
  typeof OPEN_REVIEW_VIDEO_MODAL,
  { open: boolean; id: number }
>

export type CloseReviewModal = GenericActionWithType<
  typeof CLOSE_REVIEW_VIDEO_MODAL,
  { open: boolean }
>

export type VideosModalActions =
  | OpenAttachModal
  | CloseAttachModal
  | OpenEditVideo
  | CloseEditVideo
  | OpenDeleteModal
  | CloseDeleteModal
  | OpenReviewModal
  | CloseReviewModal
