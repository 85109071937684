import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTuesday = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 1v3h10V1h2v3h2a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h2V1h2Zm12 5H3a1 1 0 0 0-1 1v2h20V7a1 1 0 0 0-1-1h-2ZM2 11v10a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V11H2Z"
      fill="#1A1A1A"
    />
    <path
      d="M5.484 19.47v-6.3h1.368l2.007 3.996 1.98-3.996h1.368v6.3h-1.152v-4.347L9.3 18.57h-.909l-1.755-3.447v4.347H5.484Zm7.54 0 2.304-6.3h1.296l2.304 6.3h-1.224l-.504-1.458h-2.457l-.513 1.458h-1.206Zm2.034-2.358h1.827l-.918-2.62-.909 2.62Z"
      fill="#000"
    />
  </svg>
)
export default SvgTuesday
