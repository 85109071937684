import React from 'react'
import { makeStyles } from '@mui/styles'
import classNames from 'clsx'
import MUIButton from '@mui/material/Button'
import { CircularProgress } from '@mui/material'
import chroma from 'chroma-js'

const useStyles = makeStyles((theme) => ({
  Button: ({ dense }) => ({
    boxSizing: 'border-box',
    borderRadius: 3,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '21px',
    textTransform: 'none',
    padding: dense ? '7.5px 16px' : '13.5px 16px',
  }),
  disabled: ({ variant }) => ({
    opacity: 0.3,
    border: 'none',
    boxShadow: '0 0 0 1px ' + theme.palette.border + '!important',
    ...(variant === 'primary' && { color: 'white !important' }),
    ...(variant === 'text' && { color: theme.palette.primary.main + ' !important' }),
  }),
  primary: {
    background: theme.palette.primary.main,
    boxShadow: '0 0 0 1px ' + theme.palette.primary.main,
    color: '#FFF',
    '&:hover': {
      background: theme.palette.secondary.main,
      boxShadow: '0 0 0 1px ' + theme.palette.secondary.main,
      color: '#FFF',
    },
    '&:active': {
      background: chroma(theme.palette.secondary.main).brighten(0.5).hex(),
      boxShadow: '0 0 0 1px ' + chroma(theme.palette.secondary.main).brighten(0.5).hex(),
      color: '#FFF',
    },
  },
  text: {
    color: theme.palette.primary.main,
    background: 'rgba(0,0,0,0)',
    '&:hover': {
      background: 'rgba(0,0,0,0)', // Overwrite default behavior
      color: theme.palette.secondary.main,
      '& span span svg path': {
        fill: theme.palette.secondary.main,
      },
    },
    '&:active': {
      background: 'rgba(0,0,0,0)', // Overwrite default behavior
      color: chroma(theme.palette.secondary.main).brighten(0.5).hex(),
      '& span span svg path': {
        fill: chroma(theme.palette.secondary.main).brighten(0.5).hex(),
      },
    },
  },
  iconLeft: {
    justifyItems: 'center',
    display: 'flex',
    marginRight: 10,
  },
  iconRight: {
    justifyItems: 'center',
    display: 'flex',
    marginLeft: 10,
  },
}))

const Button = ({
  children,
  dense = false,
  onClick,
  variant = 'primary',
  loading,
  className,
  IconLeft,
  iconLeftClassname,
  IconRight,
  iconRightClassname,
  ...props
}) => {
  const classes = useStyles({ dense, variant })
  return (
    <MUIButton
      {...props}
      variant="text"
      onClick={onClick}
      disabled={props.disabled || loading}
      disableRipple
      className={classNames(
        classes.Button,
        { [classes.disabled]: props.disabled || loading },
        {
          [classes.primary]: variant === 'primary',
          [classes.text]: variant === 'text',
        },
        className
      )}
    >
      {IconLeft && (
        <span className={classes.iconLeft}>
          <IconLeft className={iconLeftClassname} />
        </span>
      )}
      {loading ? <CircularProgress size={24} /> : children}
      {IconRight && (
        <span className={classes.iconRight}>
          <IconRight className={iconRightClassname} />
        </span>
      )}
    </MUIButton>
  )
}

export default Button
