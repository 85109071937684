import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export interface Book {
  id: number
  createdAt: string
  userId: number
  organizationId: number
  title: string
  description: string
  availability: AVAILABILITY
  isbn: string
  fileId: number
  url: string
  coverUrl: string
  tags: Tag[]
  organizationLogo: string
  organizationName: string
  amount: number
  visibilityTags: VisibilityTag[]
}

export enum AVAILABILITY {
  FREE = 'free',
  PREMIUM = 'premium',
}

export interface Tag {
  resourceType: RESOURCE_TYPE
  resourceId: number
  tagId: number
  tagValue: number
}

enum RESOURCE_TYPE {
  BOOK = 'book',
  ACTIVITY = 'activity',
  SEQUENCE = 'sequence',
  COURSE = 'course',
}

interface VisibilityTag {
  id: number
  name: string
  value: string
}

export interface BooksState {
  status: REQUEST_STATUS
  data: Book[]
}

export enum BOOKS_TYPES {
  GET_BOOKS_REQUEST = 'CONTENT_CREATOR/NEW_SEQUENCES/BOOKS/GET_BOOKS_REQUEST',
  GET_BOOKS_SUCCESS = 'CONTENT_CREATOR/NEW_SEQUENCES/BOOKS/GET_BOOKS_SUCCESS',
  GET_BOOKS_FAILURE = 'CONTENT_CREATOR/NEW_SEQUENCES/BOOKS/GET_BOOKS_FAILURE',
  CLEAN_BOOKS = 'CONTENT_CREATOR/NEW_SEQUENCES/BOOKS/CLEAN_BOOKS',
}

// Get books actions.
export type GetBooksRequestAction = {
  type: BOOKS_TYPES.GET_BOOKS_REQUEST
}

export type GetBooksSuccessAction = {
  type: BOOKS_TYPES.GET_BOOKS_SUCCESS
  payload: {
    data: Book[]
  }
}

export type GetBooksFailureAction = {
  type: BOOKS_TYPES.GET_BOOKS_FAILURE
}

export type GetBooksAction = GetBooksRequestAction | GetBooksSuccessAction | GetBooksFailureAction

// Clean books action.
export type CleanBooksAction = {
  type: BOOKS_TYPES.CLEAN_BOOKS
}

export type BooksAction = GetBooksAction | CleanBooksAction
