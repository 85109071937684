import * as React from 'react'
import type { SVGProps } from 'react'
const SvgReading = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
    className="reading-svg"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect opacity={0.15} width={56} height={56} rx={6} fill="#01CEAA" />
    <rect x={10} y={18} width={36} height={24} rx={1} fill="#4F36D6" />
    <rect x={10} y={18} width={36} height={24} rx={1} fill="#1A1A1A" fillOpacity={0.6} />
    <path d="M12 18a4 4 0 0 1 4-4h12v26H13a1 1 0 0 1-1-1V18Z" fill="#01CEAA" />
    <path d="M28 27H14v2h14v-2ZM28 31H14v2h14v-2ZM22 23h-8v2h8v-2ZM28 35H14v2h14v-2Z" fill="#fff" />
    <path d="M44 18a4 4 0 0 0-4-4H28v26h15a1 1 0 0 0 1-1V18Z" fill="#01CEAA" />
    <path d="M42 27H28v2h14v-2ZM38 31H28v2h10v-2ZM42 35H28v2h14v-2Z" fill="#fff" />
    <g opacity={0.2}>
      <path d="M28 14h12a4 4 0 0 1 4 4v21a1 1 0 0 1-1 1H28V14Z" fill="#4F36D6" />
      <path d="M28 14h12a4 4 0 0 1 4 4v21a1 1 0 0 1-1 1H28V14Z" fill="#1A1A1A" />
    </g>
    <path d="M44 18a4 4 0 0 0-4-4H28v26h15a1 1 0 0 0 1-1V18Z" fill="#01CEAA" />
    <path d="M28 27h14v2H28v-2ZM28 31h14v2H28v-2ZM34 23h8v2h-8v-2ZM28 35h14v2H28v-2Z" fill="#fff" />
    <path d="M44 18a4 4 0 0 0-4-4H28v26h15a1 1 0 0 0 1-1V18Z" fill="#01CEAA" />
    <path d="M42 27H28v2h14v-2ZM38 31H28v2h10v-2ZM42 35H28v2h14v-2Z" fill="#fff" />
    <g opacity={0.2}>
      <path d="M28 14h12a4 4 0 0 1 4 4v21a1 1 0 0 1-1 1H28V14Z" fill="#4F36D6" />
      <path d="M28 14h12a4 4 0 0 1 4 4v21a1 1 0 0 1-1 1H28V14Z" fill="#1A1A1A" />
    </g>
  </svg>
)
export default SvgReading
