import { useState } from 'react'

// useDisclosure is a custom hook used to help handle common open, close, or toggle scenarios.
// It can be used to control feedback component such as Modal, AlertDialog, Drawer, etc.
export default function useDisclosure() {
  const [isOpen, setIsOpen] = useState(false)

  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)
  const onToggle = () => setIsOpen(!isOpen)

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  }
}
