import { COSMOS_KEY } from './names'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface LogAsCosmosState {
  selected: COSMOS_KEY
  canRefresh: boolean
}

export interface LogAsCosmosActions {
  setSelectedKey: (key: COSMOS_KEY) => void
  setCanRefresh: (canRefresh: boolean) => void
  clear: () => void
}

export const useLogAsCosmosStore = create<LogAsCosmosState & LogAsCosmosActions>()(
  persist(
    (set) => ({
      canRefresh: true,
      selected: COSMOS_KEY.AULA,
      setCanRefresh: (canRefresh) => set({ canRefresh }),
      setSelectedKey: (selected) => set({ selected }),
      clear: () => set({ selected: COSMOS_KEY.AULA, canRefresh: true }),
    }),
    {
      name: '@aula/logAs',
    }
  )
)
