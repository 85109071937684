import type { Reducer } from 'redux'
import { DEFAULT_DOC_TYPE } from '@/legacy/redux/types/docTypesOptions'
import * as Types from '@/legacy/components/v3/inputs/childrenAssignments/types/childrenAssignmentModal'
import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { ROLE } from '@aula/config'

const initialState: Types.ChildrenAssignmentsModalState = {
  open: false,
  loading: false,
  error: '',
  currentChildren: [],
  childrenDocument: {
    ...DEFAULT_DOC_TYPE,
    value: '',
    instanceId: 0,
  },
  organization: {
    label: '',
    id: 0,
  },
  RelationshipAssigmentChild: {
    relatedToId: 0,
    userId: 0,
    organizationId: 0,
    relation: '',
    role: '' as ROLE,
  },
  statusCreateOrModify: REQUEST_STATUS.IDLE,
  loadingCreateOrModify: false,
  statusRemoveRelationship: REQUEST_STATUS.IDLE,
  loadingRemoveRelationship: false,
}

const AssignmentsInputReducer: Reducer<
  Types.ChildrenAssignmentsModalState,
  Types.AssignmentsInputsAction
> = (state = initialState, action): Types.ChildrenAssignmentsModalState => {
  switch (action.type) {
    case Types.OPEN_MODAL:
      return {
        ...state,
        open: true,
        currentChildren: action.payload.children,
      }
    case Types.CLOSE_MODAL:
      return initialState
    case Types.SELECT_ORGANIZATION_SUGGESTION: {
      return {
        ...state,
        organization: {
          ...state.organization,
          label: action.payload.label,
          id: action.payload.id,
        },
      }
    }
    case Types.CHANGE_DOCUMENT_TYPE: {
      return {
        ...state,
        childrenDocument: {
          ...action.payload.docType,
          value: state.childrenDocument.value || '',
          instanceId: state.childrenDocument?.instanceId || 0,
        },
      }
    }
    case Types.CHANGE_DOCUMENT_VALUE: {
      return {
        ...state,
        childrenDocument: {
          ...state.childrenDocument,
          instanceId: state.childrenDocument.instanceId,
          value: action.payload.newValue,
        },
      }
    }
    case Types.ADD_CHILD_REQUEST:
      return { ...state, loading: true }
    case Types.ADD_CHILD_SUCCESS: {
      const { child: newChild } = action.payload
      const isChildAlreadyIncluded = state.currentChildren.some((child) => child.id === newChild.id)
      return {
        ...state,
        currentChildren: isChildAlreadyIncluded
          ? state.currentChildren
          : state.currentChildren.concat(newChild),
        loading: false,
        childrenDocument: initialState.childrenDocument,
      }
    }
    case Types.ADD_CHILD_FAILURE:
      return { ...state, loading: false }
    case Types.REMOVE_CHILD: {
      return {
        ...state,
        currentChildren: state.currentChildren.filter((child) => {
          return child.id !== action.payload.child.id
        }),
      }
    }
    case Types.REMOVE_ALL_CHILDS: {
      return {
        ...state,
        currentChildren: [],
      }
    }
    case Types.ADD_NEW_RELATIONSHIP_CHILD_REQUEST:
      return { ...state, loadingCreateOrModify: true, statusCreateOrModify: REQUEST_STATUS.LOADING }
    case Types.ADD_NEW_RELATIONSHIP_CHILD_SUCCESS:
      return {
        ...state,
        loadingCreateOrModify: false,
        statusCreateOrModify: REQUEST_STATUS.SUCCESS,
      }
    case Types.ADD_NEW_RELATIONSHIP_CHILD_FAILURE:
      return {
        ...state,
        loadingCreateOrModify: false,
        statusCreateOrModify: REQUEST_STATUS.FAILURE,
      }
    case Types.REMOVE_RELATIONSHIP_CHILD_REQUEST:
      return {
        ...state,
        loadingRemoveRelationship: true,
        statusRemoveRelationship: REQUEST_STATUS.LOADING,
      }
    case Types.REMOVE_RELATIONSHIP_CHILD_SUCCESS:
      return {
        ...state,
        loadingRemoveRelationship: false,
        statusRemoveRelationship: REQUEST_STATUS.SUCCESS,
      }
    case Types.REMOVE_RELATIONSHIP_CHILD_FAILURE:
      return {
        ...state,
        loadingRemoveRelationship: false,
        statusRemoveRelationship: REQUEST_STATUS.FAILURE,
      }
    case Types.CLEAR_STATE:
      return { ...initialState }
    default:
      return state
  }
}

export default AssignmentsInputReducer
