import type { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { Anibot } from '@/legacy/components/v2/anibot'
import { scrollbarStyles } from '@/legacy/components/v3/appBar/styles/scrollbars'

interface StyleProps {
  loading?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    ...scrollbarStyles,
    overflowX: 'hidden',
    overflowY: 'auto',
    boxSizing: 'border-box',
    opacity: ({ loading }) => (loading ? 0 : 1),
  },
  loadingIndicatorContainer: {
    display: ({ loading }) => (loading ? 'flex' : 'none'),
    position: 'absolute',
    top: 'calc(50% - 25px)',
    zIndex: 900,
  },
}))

interface ContentProps {
  children: React.ReactNode
  contentClass?: string
  loading?: boolean
}

const Content = (props: ContentProps) => {
  const { children, loading = false, contentClass = '' } = props
  const C = useStyles({ loading })

  return (
    <>
      <div className={C.loadingIndicatorContainer}>
        <Anibot />
      </div>
      <div className={clsx(C.contentContainer, contentClass)}>{children}</div>
    </>
  )
}

export default Content
