import React from 'react'
import type { Theme } from '@mui/material'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ButtonV2 } from '@/legacy/components/v1'

const useStyles = makeStyles((theme: Theme) => ({
  container: ({ paddingTop, paddingBottom }: Props) => ({
    textAlign: 'center',
    paddingTop: paddingTop || 0,
    paddingBottom: paddingBottom || 0,
    overflow: 'hidden',
  }),
  image: ({ width, height, opacity }: Props) => ({
    maxWidth: '100%',
    width: width || 340,
    height: height || 'inherit',
    padding: 18,
    boxSizing: 'border-box',
    opacity: opacity || '100%',
  }),
  title: ({ titleLowercase, titleFontsize }) => ({
    fontWeight: 'bold',
    fontSize: titleFontsize || 18,
    color: '#6D7878',
    lineHeight: '23px',
    marginTop: 24,
    textTransform: titleLowercase ? 'initial' : 'uppercase',
  }),
  subtitle: ({ title, subtitleFontsize }) => ({
    fontSize: subtitleFontsize || 18,
    color: '#6D7878',
    lineHeight: '23px',
    marginTop: title ? 10 : 0,
  }),
  additionalText: ({ title }) => ({
    fontSize: 18,
    color: '#6D7878',
    lineHeight: '23px',
    marginTop: title ? 10 : 0,
  }),
  button: {
    marginTop: 40,
  },
}))
export type EmptyStateVariant =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
interface Props {
  title?: string
  subtitle?: string
  titleLowercase?: boolean
  titleFontsize?: number
  subtitleFontsize?: number
  additionalText?: string
  variant?: EmptyStateVariant
  buttonLabel?: string
  onClickButton?(): void
  width?: string
  height?: string
  opacity?: string
  paddingTop?: string
  paddingBottom?: string
  hideImage?: boolean
  label?: Function
  element?: Function
}

const EmptyState: React.FunctionComponent<Props> = (props) => {
  const {
    title,
    subtitle,
    additionalText,
    variant = 0,
    buttonLabel,
    onClickButton,
    hideImage,
    label,
    element,
  } = props
  const classes = useStyles(props)
  const url = `${process.env.PUBLIC_URL}/images/emptyStates/emptyState${variant}.svg`
  return (
    <div className={classes.container}>
      {!hideImage && <img className={classes.image} alt="empty-state" src={url} />}
      {title && (
        <Typography id="hello-teacher" className={classes.title}>
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography id="activity-none" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
      {additionalText && (
        <Typography id="activity-none" className={classes.additionalText}>
          {additionalText}
        </Typography>
      )}
      {label && label()}
      {buttonLabel && onClickButton && (
        <ButtonV2 id="create-activity" onClick={onClickButton} className={classes.button} dense>
          {buttonLabel} {element && element()}
        </ButtonV2>
      )}
    </div>
  )
}

export default EmptyState
