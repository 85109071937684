import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type {
  AudienceModalActions,
  InitialState,
} from '@/sections/teacher/activities/types/audienceModal'
import { AUDIENCE_MODAL } from '@/sections/teacher/activities/types/audienceModal'

const initialState: InitialState = {
  isOpen: false,
  status: REQUEST_STATUS.IDLE,
  audiences: [],
  selectedAudiences: [],
  error: '',
  pagination: {
    count: 0,
    more: false,
    page: 0,
    rowsPerPage: 10,
  },
}

const AudienceModalReducer = (
  state: InitialState = initialState,
  action: AudienceModalActions
): InitialState => {
  switch (action.type) {
    case AUDIENCE_MODAL.SET_MODAL_OPEN:
      return { ...state, isOpen: true }

    case AUDIENCE_MODAL.SET_MODAL_CLOSE:
      return { ...state, isOpen: false }

    case AUDIENCE_MODAL.GET_AUDIENCE_REQUEST:
      return { ...state, status: REQUEST_STATUS.LOADING }

    case AUDIENCE_MODAL.GET_AUDIENCE_SUCCESS: {
      const { audiences, count, more, page } = action.payload
      return {
        ...state,
        audiences,
        status: REQUEST_STATUS.SUCCESS,
        pagination: { ...state.pagination, count, more, page },
      }
    }

    case AUDIENCE_MODAL.GET_AUDIENCE_FAILURE: {
      const { error } = action.payload
      return { ...state, error, status: REQUEST_STATUS.FAILURE }
    }

    case AUDIENCE_MODAL.TOGGLE_SELECTED_AUDIENCE: {
      const { audience } = action.payload
      const audienceAlreadyAdded = state.selectedAudiences.find((aud) => aud.id === audience.id)
      if (audienceAlreadyAdded) {
        return {
          ...state,
          selectedAudiences: state.selectedAudiences.filter(
            (aud) => aud.id !== audienceAlreadyAdded.id
          ),
        }
      }
      return { ...state, selectedAudiences: [...state.selectedAudiences, audience] }
    }

    case AUDIENCE_MODAL.SET_SELECTED_AUDIENCES: {
      const { audiences } = action.payload
      return { ...state, selectedAudiences: audiences }
    }

    case AUDIENCE_MODAL.CLEAR_SELECTED_AUDIENCES:
      return { ...state, selectedAudiences: [] }

    case AUDIENCE_MODAL.CLEAR_STATE:
      return initialState

    default: {
      return state
    }
  }
}

export default AudienceModalReducer
