import React from 'react'
import { Grid, Typography, Tabs, Tab, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

const SubjectCard = (props) => {
  const { subject, classes } = props
  const { t } = useTranslation()
  const xs = useMediaQuery((theme) => theme.breakpoints.only('xs'))
  const scrollButtons = xs ? 'on' : 'off'

  return (
    <div className={classes.subjectCard}>
      <Grid container>
        <Typography className={classes.subjectName}>{subject.name}</Typography>
      </Grid>
      <div className={classes.subjectCardBottom}>
        <Tabs
          variant="scrollable"
          scrollButtons={scrollButtons}
          aria-label={t('student.activitiesOfSubject')}
          value={props.value}
          TabIndicatorProps={{ className: classes.tabIndicator }}
          textColor="inherit"
        >
          <Tab
            className={classes.tab}
            label={t('student.dashboard.pendingActivities')}
            value={0}
            onClick={props.onClickLeft}
          />
          <Tab
            className={classes.tab}
            label={t('student.dashboard.historyActivities')}
            value={1}
            onClick={props.onClickRight}
          />
        </Tabs>
      </div>
    </div>
  )
}

export default SubjectCard
