import moment from 'moment'
import * as Types from '@/sections/teacher/bulletin/types/scheduleMessageModal'

const initialState: Types.ScheduleMessageModalState = {
  open: false,
  loading: false,
  date: moment(),
  time: moment(),
  step: Types.ModalStep.SCHEDULE,
}

export default function scheduleMessageModalReducer(
  state = initialState,
  action: Types.ScheduleMessageModalActions
): Types.ScheduleMessageModalState {
  switch (action.type) {
    case Types.SCHEDULE_MESSAGE_MODAL_TYPES.CLEAR_STATE:
      return initialState
    case Types.SCHEDULE_MESSAGE_MODAL_TYPES.SET_OPEN: {
      const { open } = action.payload
      if (open) {
        return { ...state, open: action.payload.open }
      } else {
        return initialState
      }
    }
    case Types.SCHEDULE_MESSAGE_MODAL_TYPES.SET_DATE:
      return { ...state, date: action.payload.date }
    case Types.SCHEDULE_MESSAGE_MODAL_TYPES.SET_TIME:
      return { ...state, time: action.payload.time }
    case Types.SCHEDULE_MESSAGE_MODAL_TYPES.SET_STEP: // CHECK: maybe this is useless
      return { ...state, step: action.payload.step }

    case Types.SCHEDULE_MESSAGE_MODAL_TYPES.SCHEDULE_MESSAGE_REQUEST:
      return { ...state, loading: true }
    case Types.SCHEDULE_MESSAGE_MODAL_TYPES.SCHEDULE_MESSAGE_SUCCESS:
      return { ...state, loading: false, step: Types.ModalStep.SENT }
    case Types.SCHEDULE_MESSAGE_MODAL_TYPES.SCHEDULE_MESSAGE_FAILURE:
      return { ...state, loading: false }

    default:
      return state
  }
}
