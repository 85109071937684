export const SET_OPEN = 'LIBRARY_TEACHER/OPEN_PDF_VIEWER'
export const SET_CLOSE = 'LIBRARY_TEACHER/CLOSE_PDF_VIEWER'
export const SET_FILTERS_OPEN = 'LIBRARY_TEACHER/SET_FILTERS_OPEN'
export const SET_FILTERS_CLOSED = 'LIBRARY_TEACHER/SET_FILTERS_CLOSED'
export const SET_FILTERS = 'LIBRARY_TEACHER/SET_FILTERS'
export const RESET_FILTERS = 'LIBRARY_TEACHER/RESET_FILTERS'
export const SET_ACTIVE_TAB = 'LIBRARY_TEACHER/SET_ACTIVE_TAB'

interface Book {
  img: string
  pdf: string
}

export interface SetPDFViewerOpen {
  type: typeof SET_OPEN
  payload: {
    pdf: string
  }
}

export interface SetPDFViewerClose {
  type: typeof SET_CLOSE
}

export interface SetFiltersOpen {
  type: typeof SET_FILTERS_OPEN
}

export interface SetFiltersClosed {
  type: typeof SET_FILTERS_CLOSED
}

export interface ResetFilters {
  type: typeof RESET_FILTERS
}

export interface Filter {
  id: number
  name: string
}

export interface SetFilters {
  type: typeof SET_FILTERS
  payload: {
    filter: Filter
  }
}

export interface SetActiveTabAction {
  type: typeof SET_ACTIVE_TAB
  payload: LIBRARY_TAB
}

export enum LIBRARY_TAB {
  ACTIVITIES = 'activities',
  SEQUENCES = 'sequences',
  BOOKS = 'books',
  COURSES = 'courses',
  GAMES = 'games',
}

export interface LibraryState {
  open: boolean
  selectedPDF: string
  filtersOpen: boolean
  filters: Filter[]
  activeTab: LIBRARY_TAB
}

export type LibraryActions =
  | SetPDFViewerOpen
  | SetPDFViewerClose
  | SetFiltersOpen
  | SetFiltersClosed
  | SetFilters
  | ResetFilters
  | SetActiveTabAction
