import { apiPrivate } from './..'
import { url } from '@aula/config'

export const ChatAPI = {
  getChannelByActivityID,
  getChannels,
}

/**
 * Get Channel By Activity ID
 *
 * @role Student / Teacher
 * @param activityID ActivityID
 */

function getChannelByActivityID(activityID: number) {
  return apiPrivate.get(url + `/v1/chat/channel/activity/${activityID}`)
}

/**
 * Get Channels
 *
 * @role Student / Teacher
 */

function getChannels() {
  return apiPrivate.get(url + `/v1/chat/channels`)
}
