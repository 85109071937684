import moment from 'moment'
import { ACTIVITY_KEY_WRITTEN_EXAM } from '@/legacy/components/v2/ribbons/types'

export const TABS = {
  TODAY: 'today',
  FUTURE: 'future',
  PASSED: 'passed',
}

export const getTimeRange = (tab) => {
  switch (tab) {
    case TABS.TODAY:
      return {
        from: moment().startOf('day').format(),
        to: moment().endOf('day').format(),
      }
    case TABS.FUTURE: {
      return {
        from: moment().add(1, 'days').startOf('day').format(),
        to: moment().add(1, 'years').format(),
      }
    }
    case TABS.PASSED: {
      return {
        from: moment().subtract(1, 'years').format(),
        to: moment().subtract(1, 'days').endOf('day').format(),
      }
    }
    default:
      return { from: moment(), to: moment() }
  }
}

const MinutesBeforeExamStarts = 10
export const editExesValidation = ({ type, endDate, startDate, showError }) => {
  if (type === ACTIVITY_KEY_WRITTEN_EXAM) {
    if (moment().isAfter(endDate)) {
      showError(
        'studentList.activityWrittenExam.editExam',
        'studentList.activityWrittenExam.noEditExamFinish'
      )
      return false
    }
    if (moment().isAfter(startDate)) {
      showError(
        'studentList.activityWrittenExam.editExam',
        'studentList.activityWrittenExam.noEditExamStart'
      )
      return false
    }
    if (moment().add(MinutesBeforeExamStarts, 'minutes').isAfter(startDate)) {
      showError(
        'studentList.activityWrittenExam.editExam',
        'studentList.activityWrittenExam.noEditExamBeforeStart',
        { descriptionValues: { MinutesBeforeExamStarts } }
      )
      return false
    }
  }

  return true
}

export const defaultClassroomOption = {
  value: 0,
  label: 'studentList.activityWrittenExam.allClassrooms',
}
