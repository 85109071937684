import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { Course } from './courses'

export const SET_OPEN = 'DASHBOARD/COURSES/SET_OPEN'
export const SET_CLOSED = 'DASHBOARD/COURSES/SET_CLOSED'

export const GET_COURSE_REQUEST = 'DASHBOARD/COURSES/GET_COURSE_REQUEST'
export const GET_COURSE_SUCCESS = 'DASHBOARD/COURSES/GET_COURSE_SUCCESS'
export const GET_COURSE_FAILURE = 'DASHBOARD/COURSES/GET_COURSE_FAILURE'

export interface GetCourseRequest {
  type: typeof GET_COURSE_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
    courseId: number
  }
}

export interface GetCourseSuccess {
  type: typeof GET_COURSE_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    course: Course
  }
}

export interface GetCourseFailure {
  type: typeof GET_COURSE_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
    closeModal: boolean
  }
}

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    requestOrigin: ReduxRequestOrigin
    courseID: number
  }
}

export interface SetClosed {
  type: typeof SET_CLOSED
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export type DashboardCourseState = {
  open: boolean
  loadingCourse: boolean
  course: Course
  courseID: number
  error: string
}

export type DashboardCourseAction =
  | GetCourseRequest
  | GetCourseSuccess
  | GetCourseFailure
  | SetOpen
  | SetClosed
