import type { AxiosResponse } from 'axios'
import { apiPrivate } from './..'
import type { ROLE } from '@aula/config'
import { url } from '@aula/config'
import type { Params } from '@/sections/principal/dashboard/types/common'
import type { Moment } from 'moment'
import moment from 'moment'
import type {
  AUTHORIZATION_STATUS_TYPE,
  CONTACT_RELATION_TYPE,
} from '@/sections/student/summary/constants/summary'
import type { DescriptionResponseShortener } from '@/api/types'

export const StudentsAPI = {
  getStudentsAsPrincipal,
  getClassroomStudents,
  getActivityStudents,
  getDashboardSummary,
  getDashboardActivities,
  getStudentInformationAsStudent,
  getStudentInformationAsTeacher,
  getStudentInformationAsAPrincipal,
  getStudentResponsibles,
  getAuthorizedContacts,
  getRestrictions,
  getStudentRestrictions,
}

/**
 * Gets all students a principal has access to.
 *
 * @role Principal
 */

function getStudentsAsPrincipal(params: Params) {
  return apiPrivate.get(url + '/v1/principal/students', { params })
}

/**
 * Gets students for a given classroom.
 *
 * @role Any
 * @param classroomID
 * @param params
 */

function getClassroomStudents(
  classroomID: number,
  params: Params & { withSubgroups?: boolean } & { format?: string },
  version = 1
) {
  switch (version) {
    default:
    case 1:
      // deprecated
      return apiPrivate.get(url + `/v1/classroom/${classroomID}/students`, { params })
    case 2:
      return apiPrivate.get(url + `/v1/views/teacher/classroom/${classroomID}/students`, {
        params,
        ...(params.format === 'XLSX' && { responseType: 'blob' }),
      })
  }
}

function getActivityStudents(classroomID: number, activityID: number, params: Params) {
  return apiPrivate.get(url + `/v1/classroom/${classroomID}/activities/${activityID}/students`, {
    params,
  })
}

interface GetDashboardSummaryData {
  description: {
    summary: SubjectSummary[]
    user: APIDashboardSummaryUser
  }
}

export interface SubjectSummary {
  subject: Subject
  pending: number
  today: number
}

interface Subject {
  id: number
  name: string
  color: string
}

interface APIDashboardSummaryUser {
  avatar: string
  email: string
  id: number
  lastName: string
  name: string
  dateOfBirth: Moment | null
}

export interface DashboardSummaryUser {
  avatar: string
  email: string
  id: number
  lastName: string
  name: string
}

/**
 * Get student dashboard summary.
 */
function getDashboardSummary(
  organizationID: number,
  academicPeriodID: number,
  date: string
): Promise<{ summary: SubjectSummary[]; user: DashboardSummaryUser }> {
  return apiPrivate
    .get(url + '/v1/dashboard/student/summary', {
      params: {
        organizationId: organizationID,
        academicPeriodId: academicPeriodID,
        date,
      },
    })
    .then((response: AxiosResponse<GetDashboardSummaryData>) => {
      const d = response.data.description
      return {
        summary: d.summary,
        user: {
          avatar: d.user.avatar,
          email: d.user.email,
          id: d.user.id,
          lastName: d.user.lastName,
          name: d.user.name,
          dateOfBirth: d.user.dateOfBirth ? moment(d.user.dateOfBirth) : null,
        },
      }
    })
    .catch((err) => {
      throw err
    })
}

interface GetDashboardActivitiesData {
  description: {
    activities: APIActivity[]
    total: number
  }
}

interface APIActivity {
  activities: {}[]
  chatEnabled: boolean
  classroomId: number
  completed: string
  date: string
  deadline: string
  distributeTime: boolean
  endDate: string
  evaluationType: number
  firstEditorId: number
  id: number
  isZoom: boolean
  lastSubmission:
    | {}
    | {
        date: string
        evaluation: null // TODO type
        final: boolean
        id: number
        progress: number
        type: string
      }
  maxScore: number
  originOrganizationId: number
  robotsBoardId: number
  robotsBoardType: string
  robotsId: number
  robotsIsAnimations: boolean
  robotsIsRemote: boolean
  startDate: string
  subject: Subject
  time: number
  title: string
  videocallUrl: string
}

interface GetDashboardActivitiesConfig {
  subjectID: number
  organizationID: number
  academicPeriodID: number
  fromDate: string
  toDate: string
  offset: number
  limit: number
  completed: boolean
  orderBy?: string
  orderDir?: string
  types?: string
}

/**
 * Get student dashboard summary activities.
 */
function getDashboardActivities(
  config: GetDashboardActivitiesConfig
): Promise<{ activities: APIActivity[]; total: number }> {
  const params = {
    subjectId: config.subjectID,
    organizationId: config.organizationID,
    academicPeriodId: config.academicPeriodID,
    fromDate: config.fromDate,
    toDate: config.toDate,
    offset: config.offset,
    limit: config.limit,
    completed: config.completed,
    orderBy: config.orderBy,
    orderDir: config.orderDir,
    types: config.types,
  }

  return apiPrivate
    .get(url + `/v1/dashboard/student/activities`, { params })
    .then((response: AxiosResponse<GetDashboardActivitiesData>) => {
      const d = response.data.description
      // TODO should turn APIActivity into Activity (with camelCase properties) but that would break too many things to fix now
      return { activities: d.activities, total: d.total }
    })
    .catch((err) => {
      throw err
    })
}

export type Classroom = {
  id: number
  name: string
}

type GetStudentInformationResponse = {
  description: {
    classrooms: Classroom[]
  }
}

/**
 * Get all classroms of the current student.
 *
 */
function getStudentInformationAsStudent() {
  return apiPrivate
    .get<GetStudentInformationResponse>(url + '/v2/user/student-info')
    .then((response) => response.data)
    .then((response) => response.description)
}

function getStudentInformationAsTeacher(userId: number) {
  return apiPrivate
    .get<GetStudentInformationResponse>(url + `/v1/teacher/student-classrooms/${userId}`)
    .then((response) => response.data)
    .then((response) => response.description)
}

interface GetStudentInformationAsAPrincipalParams {
  studentId: number
  organizationId: number
  academicPeriodId: number
}

function getStudentInformationAsAPrincipal(
  params: GetStudentInformationAsAPrincipalParams
): Promise<Classroom[]> {
  const { studentId, ...otherParams } = params

  return apiPrivate
    .get<GetStudentInformationResponse>(url + `/v1/principal/student-classrooms/${studentId}`, {
      params: otherParams,
    })
    .then((response) => response.data.description.classrooms)
}

export interface Responsible {
  userId: number
  name: string
  lastName: string
  email: string
  phone?: string
  avatar?: string
  relation: string
}

export interface StudentResponsiblesResponse {
  description: {
    relationships: Responsible[]
  }
}

export interface AuthorizedContacts {
  id: number
  name: string
  lastName: string
  avatar: string
  authorizationType: string
  contactRelation: CONTACT_RELATION_TYPE
  organizations: Organization[]
  children: Child[]
  authorizationStatus: AUTHORIZATION_STATUS_TYPE
}

export interface Organization {
  id: number
  name: string
  emblem: string
}

export interface Child {
  id: number
  name: string
  lastName: string
}

export interface AuthorizedContactsResponse {
  description: {
    authorizedContacts: AuthorizedContacts[]
  }
}

/**
 * get responsibles for the student, by userId and classroomId.
 *
 */

function getStudentResponsibles(userId: number, classroomId: number): Promise<Responsible[]> {
  const requestUrl = `${url}/v1/user/${userId}/parents?classroom_id=${classroomId}`
  return apiPrivate
    .get<StudentResponsiblesResponse>(requestUrl)
    .then((resp) => resp.data)
    .then((data) => data.description.relationships)
}

/**
 * get authorized contacts for the student, by userId and classroomId.
 *
 */
function getAuthorizedContacts(userId: number, classroomId: number): Promise<AuthorizedContacts[]> {
  const requestUrl = `${url}/v1/user/${userId}/classrooms/${classroomId}/authorized-contacts`
  return apiPrivate
    .get<AuthorizedContactsResponse>(requestUrl)
    .then((resp) => resp.data)
    .then((data) => data.description.authorizedContacts)
}

export enum STUDENT_RESTRICTION {
  VIEW_REPORT_CARD = 'view_report_card',
}

type GetRestrictionsParams = {
  role: ROLE
  organizationId: number
}

type GetRestrictionsResponse = {
  restrictions: STUDENT_RESTRICTION[]
}

function getRestrictions({
  organizationId,
  role,
}: GetRestrictionsParams): Promise<GetRestrictionsResponse> {
  return apiPrivate
    .get<DescriptionResponseShortener<GetRestrictionsResponse>>(url + `/v1/${role}/restrictions`, {
      params: { organizationId },
    })
    .then((response) => response.data.description)
}

type GetStudentRestrictionParams = GetRestrictionsParams & { studentId: number }

type GetStudentRestrictionResponse = {
  restrictions: { organizationId: number; restriction: STUDENT_RESTRICTION; studentId: number }[]
}
function getStudentRestrictions({
  organizationId,
  role,
  studentId,
}: GetStudentRestrictionParams): Promise<GetStudentRestrictionResponse> {
  return apiPrivate
    .get<DescriptionResponseShortener<GetStudentRestrictionResponse>>(
      url + `/v1/${role}/student-restrictions/${studentId}`,
      {
        params: { organizationId },
      }
    )
    .then((response) => response.data.description)
}
