import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import { AVAILABILITY } from '@/sections/content-creator/sequenceDrawer/types/books'
import * as Types from '@/sections/content-creator/sequenceDrawer/types/publication'

const initialState: Types.PublicationState = {
  availability: AVAILABILITY.FREE,
  audienceModal: {
    isOpenModal: false,
    isEditMode: false,
    audiences: [],
    status: REQUEST_STATUS.IDLE,
    searchText: '',
    pagination: {
      count: 0,
      more: true,
      page: 0,
      rowsPerPage: 5,
    },
    selectedAudiences: [],
  },
  audiences: [],
}

const publicationReducer = (
  state: Types.PublicationState = initialState,
  action: Types.PublicationAction
): Types.PublicationState => {
  switch (action.type) {
    case Types.PUBLICATION_TYPES.SET_AVAILABILITY: {
      const { newAvailability } = action.payload

      return {
        ...state,
        availability: newAvailability,
      }
    }
    case Types.PUBLICATION_TYPES.CLEAN_PUBLICATION: {
      return initialState
    }
    case Types.PUBLICATION_TYPES.OPEN_MODAL: {
      return {
        ...state,
        audienceModal: {
          ...state.audienceModal,
          isOpenModal: true,
        },
      }
    }
    case Types.PUBLICATION_TYPES.CLOSE_MODAL: {
      return {
        ...state,
        audienceModal: {
          ...state.audienceModal,
          isOpenModal: false,
        },
      }
    }
    case Types.PUBLICATION_TYPES.SET_SEARCH_TEXT: {
      const { newSearchText } = action.payload

      return {
        ...state,
        audienceModal: {
          ...state.audienceModal,
          searchText: newSearchText,
        },
      }
    }
    case Types.PUBLICATION_TYPES.GET_AUDIENCES_REQUEST: {
      return {
        ...state,
        audienceModal: {
          ...state.audienceModal,
          status: REQUEST_STATUS.LOADING,
        },
      }
    }
    case Types.PUBLICATION_TYPES.GET_AUDIENCES_SUCCESS: {
      const { audiences, count, more, page } = action.payload

      return {
        ...state,
        audienceModal: {
          ...state.audienceModal,
          status: REQUEST_STATUS.SUCCESS,
          audiences,
          pagination: {
            ...state.audienceModal.pagination,
            count,
            more,
            page,
          },
        },
      }
    }
    case Types.PUBLICATION_TYPES.GET_AUDIENCES_FAILURE: {
      return {
        ...state,
        audienceModal: {
          ...state.audienceModal,
          status: REQUEST_STATUS.FAILURE,
        },
      }
    }
    case Types.PUBLICATION_TYPES.SET_SELECTED_AUDIENCES: {
      const { newSelectedAudiences } = action.payload

      return {
        ...state,
        audienceModal: {
          ...state.audienceModal,
          selectedAudiences: newSelectedAudiences,
        },
      }
    }
    case Types.PUBLICATION_TYPES.SET_SELECTED_AUDIENCE: {
      const { audience, isAddition } = action.payload

      const newSelectedAudiences = isAddition
        ? [...state.audienceModal.selectedAudiences, audience]
        : state.audienceModal.selectedAudiences.filter(
            (selectedAudience) => selectedAudience.id !== audience.id
          )

      return {
        ...state,
        audienceModal: {
          ...state.audienceModal,
          selectedAudiences: newSelectedAudiences,
        },
      }
    }
    case Types.PUBLICATION_TYPES.SET_AUDIENCES: {
      const { audiences } = action.payload

      return {
        ...state,
        audiences,
      }
    }
    case Types.PUBLICATION_TYPES.CLEAN_MODAL: {
      return {
        ...state,
        audienceModal: initialState.audienceModal,
      }
    }
    default:
      return state
  }
}

export default publicationReducer
