import React from 'react'
import MUITabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { makeStyles } from '@mui/styles'
import AdminIconActive from '@/legacy/components/v1/svgs/header/ebAdminActive'

const useStyles = makeStyles((theme) => ({
  indicator: {
    height: 0,
  },
  selected: {},
  wrapper: {
    flexDirection: 'row',
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 'bold',
    '& :first-child': {
      marginRight: '8px',
      marginBottom: '0px !important',
    },
  },
}))

const Tabs = (props) => {
  const { tabs, value, onChange } = props

  const classes = useStyles()

  const changeValue = (event, newValue) => onChange(newValue)

  return (
    <MUITabs
      value={value}
      onChange={changeValue}
      classes={{
        indicator: classes.indicator,
      }}
    >
      {tabs.map(({ disabled, Icon, label, value: tabValue, onClick = () => {} }) => (
        <Tab
          key={tabValue}
          classes={{
            wrapper: classes.wrapper,
            selected: classes.selected,
          }}
          onClick={onClick}
          disabled={disabled}
          disableRipple
          icon={<Icon active={value === tabValue} />}
          label={label}
          value={tabValue}
        />
      ))}
    </MUITabs>
  )
}

Tabs.defaultProps = {
  tabs: [{ Icon: () => <AdminIconActive />, label: 'dummy', value: 1 }],
  onChange: () => {},
  value: 'test',
}

export default Tabs
