import { apiPrivate } from './..'
import { url } from '@aula/config'
import type { QuestionType } from './activities/activities'

export const SubmissionsAPI = {
  create,
  allowResubmission,
}

export enum SubmissionType {
  READ = 'read',
  PARTICIPATED = 'participated',
  VIEWED = 'viewed',
  ANSWERED = 'answered',
  ATTACHED = 'attached',
}

type CreateRequest = {
  activityID: number
  type: SubmissionType
  answers: Array<ChoiceAnswer | WritingAnswer | AttachmentAnswer | FillableAnswer | UnionAnswer>
  final: boolean
}

type ChoiceAnswer = {
  questionID: number
  type: QuestionType.CHOICE
  optionID: number
}

type WritingAnswer = {
  questionID: number
  type: QuestionType.WRITING
  answer: string
}

type AttachmentAnswer = {
  questionID: number
  type: QuestionType.ATTACHMENT
  files: Array<{
    fileID: number
    name: string
    url: string
    description: string
  }>
}

type FillableAnswer = {
  questionID: number
  type: QuestionType.FILLABLE
  filled: Array<{
    number: number
    value: string
  }>
}

type UnionAnswer = {
  questionID: number
  type: QuestionType.UNION
  matches: Array<{
    column: number
    order: number
    matchesWith: number
  }>
}

/**
 * Creates a submission.
 *
 * @param request
 */
function create(request: CreateRequest) {
  // TODO: implement
  return apiPrivate.post(url + `/v1/submissions`)
}

/**
 * Allows a student to resubmit.
 *
 * @role Teacher
 * @param id Submission's id
 */
function allowResubmission(id: number) {
  return apiPrivate.post(url + `/v1/submissions/${id}/resubmission`)
}
