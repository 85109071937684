export const BEGIN_EXAM_EVENT = 'begin-exam'
export const SHARE_SCREEN_EVENT = 'share-screen'
export const RECORDING_STARTED_EVENT = 'RECORDING_STARTED'
export const CAMERA_STARTED_EVENT = 'CAMERA_STARTED'
export const GET_EXAM_EVENT = 'get-exam'
export const YOU_CAN_BEGIN_EVENT = 'you-can-begin'
export const EXAM_STARTED_EVENT = 'exam-started'
export const EXAM_FINISHED_EVENT = 'exam-finished'
export const PING = 'PING'

export const CALL_JOINED = 'CALL_JOINED'
export const CALL_STARTED = 'CALL_STARTED'
export const CALL_ENDED = 'CALL_ENDED'
export const EXOR_PICK_UP = 'EXOR_PICK_UP'

export const RECORDING_STOPPED_EVENT = 'RECORDING_STOPPED'
export const CAMERA_STOPPED_EVENT = 'CAMERA_STOPPED'
export const CONSOLE_OPEN_EVENT = 'CONSOLE_OPEN'
