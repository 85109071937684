// State.
export interface RejectionDetailsModalState {
  isOpenModal: boolean
  documentId: number
}

// Types.
export enum REJECTION_DETAILS_MODAL_TYPES {
  OPEN_MODAL = 'PRINCIPAL/DOCUMENTATION/REJECTION_DETAILS_MODAL/OPEN_MODAL',
  CLEAN_MODAL = 'PRINCIPAL/DOCUMENTATION/REJECTION_DETAILS_MODAL/CLEAN_MODAL',
}

// Actions.

// Open modal action.
export type OpenModalAction = {
  type: REJECTION_DETAILS_MODAL_TYPES.OPEN_MODAL
  payload: number
}

// Clean modal action.
export type CleanModalAction = {
  type: REJECTION_DETAILS_MODAL_TYPES.CLEAN_MODAL
}

// Action.
export type RejectionDetailsAction = OpenModalAction | CleanModalAction
