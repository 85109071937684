import type { ManagementSubject } from '@/api/lms/subjects'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { SetField } from '@/legacy/redux/types/generic'
import type { GenericActionWithType } from '@/legacy/redux/utils'
import type { DeleteSubjectAsPrincipalAction } from '@/legacy/redux/types/subjects'

export type DeleteSubjectConfirmModalState = {
  organizationId: number
  subjectId: number
  open: boolean
  loading: boolean
  error: string
}

interface SetModalInitialValuesPayload {
  subject: ManagementSubject
  organizationId: number
  requestOrigins: ReduxRequestOrigin[]
}
// Clear fields
export const CLEAR_FIELDS = 'MANAGEMENT/SUBJECTS/DELETE_MODAL/CLEAR_FIELDS'
// Set initial values
export const SET_INITIAL_VALUES = 'MANAGEMENT/SUBJECTS/DELETE_MODAL/SET_INITIAL_VALUES'

// Clear fields
export type ClearAllFields = GenericActionWithType<typeof CLEAR_FIELDS>
// Set initial values
export type SetInitialValues = GenericActionWithType<
  typeof SET_INITIAL_VALUES,
  SetModalInitialValuesPayload
>

export type DeleteSubjectConfirmModalAction =
  | SetField<'open' | 'loading' | 'error', string | boolean>
  | ClearAllFields
  | SetInitialValues
  | DeleteSubjectAsPrincipalAction
