import type { CodeLoginActions, CodeLoginState } from '@/sections/auth/codeLogin/types/codeLogin'
import {
  GET_DOC_TYPES_FAILURE,
  GET_DOC_TYPES_REQUEST,
  GET_DOC_TYPES_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  SET_CODE,
  SET_DNI,
  SET_DOCUMENT_TYPE,
  SET_LAST_NAME,
  SET_NAME,
  SET_PASSWORD,
  SET_STEP,
  VERIFY_CODE_FAILURE,
  VERIFY_CODE_REQUEST,
  VERIFY_CODE_SUCCESS,
  WHICH_LOGIN_FAILURE,
  WHICH_LOGIN_REQUEST,
  WHICH_LOGIN_SUCCESS,
} from '@/sections/auth/codeLogin/types/codeLogin'
import { LOGIN_SUCCESS as LOGIN_WITH_PASSWORD_SUCCESS } from '@/sections/auth/login/types/login'
import { FLAGS_MAP } from '@aula/config'
import { CLAIM_CODE_SUCCESS } from '@/sections/backpack/types/codeClaimModal'

const DEFAULT_DOC_TYPE = { value: 1, label: 'DNI', flag: FLAGS_MAP[1] }
const DEFAULT_PASSWORD_TYPE = { id: 1, inputType: 'number', max: 4, min: 4, name: 'number' }

const initialState: CodeLoginState = {
  code: '',
  name: '',
  lastName: '',
  dni: '',

  selectedDocType: 1,
  docTypesOptions: [DEFAULT_DOC_TYPE],
  docTypes: [],
  docTypesFetched: false,

  organization: {
    id: 0,
    name: '',
    logo: '',
  },

  passwordModalOpen: false,
  password: '',
  passwordRepeat: '',

  selectedPasswordType: DEFAULT_PASSWORD_TYPE,

  step: '1',
  loading: false,
  error: '',
  loginWithPasswordSuccess: false,
}

function root(state = initialState, action: CodeLoginActions): CodeLoginState {
  switch (action.type) {
    case SET_STEP:
      return { ...state, step: action.payload.step }
    case LOGIN_REQUEST:
      return { ...state, loading: true, error: '' }
    case LOGIN_SUCCESS:
      return { ...initialState, passwordModalOpen: true }
    case LOGIN_FAILURE:
      return { ...state, error: action.payload.error, loading: false }

    case VERIFY_CODE_REQUEST:
      return { ...state, loading: true, error: '' }
    case VERIFY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        organization: action.payload.organization,
        step: '2',
      }
    case VERIFY_CODE_FAILURE:
      return { ...state, loading: false, error: action.payload.error }

    case GET_DOC_TYPES_REQUEST:
      return { ...state, loading: true, error: '' }
    case GET_DOC_TYPES_SUCCESS: {
      const { docTypes, docTypesOptions, selectedDocType } = action.payload
      return {
        ...state,
        loading: false,
        docTypes,
        docTypesOptions,
        selectedDocType,
        docTypesFetched: true,
      }
    }
    case GET_DOC_TYPES_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case SET_CODE:
      return { ...state, code: action.payload.code }
    case SET_DOCUMENT_TYPE:
      return { ...state, selectedDocType: action.payload.selectedDocType, dni: '' }
    case SET_DNI:
      return { ...state, dni: action.payload.dni }
    case SET_NAME:
      return { ...state, name: action.payload.name }
    case SET_LAST_NAME:
      return { ...state, lastName: action.payload.lastName }
    case SET_PASSWORD:
      return { ...state, password: action.payload.password }
    case WHICH_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case WHICH_LOGIN_SUCCESS:
      return {
        ...state,
        step: action.payload.step,
        error: '',
        selectedPasswordType: action.payload.selectedPasswordType,
        password: '',
        loading: false,
      }
    case WHICH_LOGIN_FAILURE:
      const { error, step } = action.payload
      return {
        ...state,
        error,
        step: step ? step : state.step,
        name: '',
        lastName: '',
        loading: false,
      }
    case LOGIN_WITH_PASSWORD_SUCCESS:
      return { ...state, loginWithPasswordSuccess: true }
    case CLAIM_CODE_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default root
