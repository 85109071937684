export type ContactType = 'classroom' | 'student'

export type MessageType =
  | 'administrative'
  | 'authorization'
  | 'institutional'
  | 'disciplinary'
  | 'important'
  | 'report-card'
  | 'report-card-2'
  | 'other'
  | 'school-record'

export enum MESSAGE_STATUS {
  READ = 'read',
  UNREAD = 'unread',
  DELETED = 'deleted',
}

export type User = {
  id: number
  name: string
  avatar: string
  children: Children[]
}

export type Children = {
  id: number
  name: string
  lastName?: string
  avatar?: string
}

export type Contact = {
  id: number
  name: string
  lastName: string
  type: ContactType
  avatar: string
}

export type Attachment = {
  url: string
  name: string
  fileId: number
  mimeType: string
  description: string
}

export type MessageFrom = {
  id: number
  name: string
  avatar: string
  asParent: boolean
  children?: Children[]
}

export type MessageToList = {
  id: number
  name: string
  firstName: string
  lastName: string
  studentId: number
  avatar: string
  read?: boolean
  parentExists?: boolean
}

export type MessageCategoryFilter = 'normal' | 'draft' | 'schedule' | 'endorsement'

export type MessageFilters = {
  read: boolean
  category: MessageCategoryFilter
}

export interface MessageElementBase {
  id: number
}

export interface EventElement extends MessageElementBase {
  type: 'event'
  messageId: number
  startingAt: string
  endingAt: string
  location: string
}

export interface FileElement extends MessageElementBase {
  type: 'file'
  url: string
  name: string
  fileId: number
  mimeType: string
  description: string
}

export interface VideoElement extends MessageElementBase {
  type: 'video'
  url: string
  uploadLink: string
}

export type MessageElement = EventElement | FileElement | VideoElement

export interface Message {
  id: number
  type: MessageType
  title: string
  body: string
  date: string
  status: MESSAGE_STATUS
  from: MessageFrom
  to: MessageToList[]
  toList: MessageToList[]
  attachments: Attachment[]
  hasUnreadResponses: boolean
  isRichText: boolean
  allowsReply: boolean
  edited: boolean
  category?: string
  sendTime: string
  elements: MessageElement[]
  lastActivityAt: string
  communicationType: 'message' | 'reply'
  isFirst: boolean
}

export interface MessageEdited {
  id: number
  posterId: number
  type: MessageType
  title: string
  body: string
  isRichText: boolean
  date: string
  updatedAt: string
  edited: boolean
  attachments: Attachment[]
  allowsReply: boolean
  category?: string
  sendTime: string
}

interface BasicInfo {
  avatar: string
  id: number
  name: string
}

export interface MessageThreadReply extends Omit<Message, 'toList'> {
  communicationType: 'message' | 'reply'
  isFirst: boolean
  toList: BasicInfo[]
}

export type MessageThread = {
  messageId: number
  threadOwnerId: number
  status: string
  owner: User
  lastReply: MessageThreadReply
}

// REQUEST PAYLOADS

export type AttachmentPayload = {
  url: string
  name: string
  fileId: number
  mimeType: string
  description?: string
}

export type MessagePayload = {
  title: string
  type: string
  text: string
  attachments: AttachmentPayload[]
  userId: number
  isRichText: boolean
  allowsReply: boolean
  bulletinIds: number[]
  organizationId: number
  category?: string
}

export interface MessageEditPayload
  extends Omit<MessagePayload, 'organizationId' | 'userId' | 'bulletinIds' | 'isRichText'> {
  posterId: number
  messageId: number
  send?: boolean
}

export type ReplyToMessageThreadPayload = {
  text: string
  isRichText: boolean
  title?: string
  attachments?: AttachmentPayload[]
}

export interface BaseReceiver {
  id: number
  name: string
  lastName: string
  avatar: string
  read: boolean
}

export interface Receiver extends BaseReceiver {
  parents: BaseReceiver[]
}
