const types = {
  TEACHER_CLASSROOMS_PAGINATION_REQUEST: 'TEACHER_CLASSROOMS_PAGINATION/REQUEST_CLASSROOMS',
  TEACHER_CLASSROOMS_PAGINATION_REQUEST_SUCCESS:
    'TEACHER_CLASSROOMS_PAGINATION/REQUEST_CLASSROOMS_SUCCESS',
  TEACHER_CLASSROOMS_PAGINATION_REQUEST_FAILURE:
    'TEACHER_CLASSROOMS_PAGINATION/REQUEST_CLASSROOMS_FAILURE',
  TEACHER_CLASSROOMS_PAGINATION_CLEAN: 'TEACHER_CLASSROOMS_PAGINATION/CLEAN_CLASSROOMS',
  TEACHER_CLASSROOMS_PAGINATION_GO_TO_NEXT_PAGE: 'TEACHER_CLASSROOMS_PAGINATION/GO_TO_NEXT_PAGE',
  TEACHER_CLASSROOMS_PAGINATION_GO_TO_BACK_PAGE: 'TEACHER_CLASSROOMS_PAGINATION/GO_TO_BACk_PAGE',
  TEACHER_CLASSROOMS_PAGINATION_SET_CLASSROOMS_BY_PAGE:
    'TEACHER_CLASSROOMS_PAGINATION/SET_CLASSROOMS_BY_PAGE',
  TEACHER_CLASSROOMS_PAGINATION_SET_REQUEST_CANCEL_TOKEN:
    'TEACHER_CLASSROOMS_PAGINATION/SET_REQUEST_CANCEL_TOKEN',
}

export default types
