import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type { Reducer } from 'redux'
import { Redux } from '@/legacy/redux'
import type {
  ReviewsGradingFormAction,
  ReviewsGradingFormState,
} from '@/sections/report-card/grading/types/gradingForms/reviews'
import {
  INIT_STUDENT_REVIEW,
  SAVE_REVIEW_FAILURE,
  SAVE_REVIEW_REQUEST,
  SAVE_REVIEW_SUCCESS,
  UPDATE_REVIEW_FAILURE,
  UPDATE_REVIEW_REQUEST,
  UPDATE_REVIEW_SUCCESS,
} from '@/sections/report-card/grading/types/gradingForms/reviews'

const initialState: ReviewsGradingFormState = {
  loading: false,
  error: '',
  currentValue: '',
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_GRADING_REVIEWS_FORM

const ReportCardGradingAttitudinalScoresForm: Reducer<
  ReviewsGradingFormState,
  ReviewsGradingFormAction
> = (state = initialState, action): ReviewsGradingFormState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case Redux.Types.Generic.SET_FIELD:
      return setGenericField(state, action.payload)
    case INIT_STUDENT_REVIEW: {
      const { currentValue } = action.payload
      return {
        ...state,
        currentValue: currentValue || initialState.currentValue,
      }
    }
    case SAVE_REVIEW_REQUEST:
    case UPDATE_REVIEW_REQUEST:
      return { ...state, loading: true, error: initialState.error }
    case SAVE_REVIEW_SUCCESS:
    case UPDATE_REVIEW_SUCCESS:
      return { ...state, loading: false, currentValue: action.payload.value }
    case SAVE_REVIEW_FAILURE:
    case UPDATE_REVIEW_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(
  initialState,
  ReportCardGradingAttitudinalScoresForm,
  requestDestination
)
