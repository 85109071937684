import React from 'react'
import { states } from '@/legacy/components/v2/ribbons/states'
import ActivityRibbonBase from './activityRibbonBase'
import { activityRibbonMap } from '@/legacy/components/v2/ribbons/types'
export default function AVVRibbon(props) {
  const { avv } = activityRibbonMap
  return <ActivityRibbonBase Icon={avv.icon} type={avv.type} hideProgress {...props} />
}

AVVRibbon.defaultProps = {
  subject: {},
  state: states.DEFAULT,
  buttonEnabled: true,
  buttonLabel: 'menuOptions.watch',
  buttonProps: {},
  calendarLabel: 'student.utils.transmit',
  messages: 0,
}
