import type {
  ImportPasswordsActions,
  ImportPasswordsState,
} from '@/sections/eb-admin/dashboard/types/importPasswords'
import {
  SET_FILE,
  SET_OPEN,
  SET_ORGANIZATION,
  ORGANIZATIONS_REQUEST,
  ORGANIZATIONS_SUCCESS,
  ORGANIZATIONS_FAILURE,
  IMPORT_PASSWORDS_SUCCESS,
  IMPORT_PASSWORDS_FAILURE,
  IMPORT_PASSWORDS_REQUEST,
} from '@/sections/eb-admin/dashboard/types/importPasswords'

const initialState: ImportPasswordsState = {
  open: false,
  file: null,
  loading: false,
  error: '',
  organization: null,
  organizationOptions: [],
  loadingOrganizations: false,
  lastOrganizationID: 0,
}

function root(state = initialState, action: ImportPasswordsActions) {
  switch (action.type) {
    case SET_OPEN: {
      const open = action.payload.open
      return open ? { ...state, open } : initialState
    }
    case SET_FILE:
      return { ...state, file: action.payload.file }

    case SET_ORGANIZATION:
      return {
        ...state,
        organization: action.payload.organization,
      }
    case ORGANIZATIONS_REQUEST:
      return {
        ...state,
        loadingOrganizations: true,
        error: '',
        lastOrganizationID: action.payload.lastSearchID,
      }
    case ORGANIZATIONS_SUCCESS: {
      const { organizationOptions } = action.payload
      return { ...state, loadingOrganizations: false, organizationOptions }
    }
    case ORGANIZATIONS_FAILURE:
      return { ...state, loadingOrganizations: false, error: action.payload.error }
    case IMPORT_PASSWORDS_REQUEST:
      return { ...state, loading: true, error: '' }
    case IMPORT_PASSWORDS_SUCCESS: {
      return initialState
    }
    case IMPORT_PASSWORDS_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default root
