export enum FONT_FAMILY {
  BOLD = 'DM Sans Bold ',
  REGULAR = 'DM Sans',
  MEDIUM = 'DM Sans medium',
}

export enum FONT_SIZE {
  TINY = 8,
  SMALL = 12,
  REGULAR = 14,
  LARGE = 16,
  HUGE = 20,
  GIANT = 24,
}

export enum FONT_WEIGHT {
  REGULAR = 400,
  MEDIUM = 500,
  BOLD = 700,
}
