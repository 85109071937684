import type { ManagementOrganization, PrincipalOrganization } from '@/api/lms/organizations'
import type {
  ErrorPayload,
  GenericActionWithType,
  GenericPagination,
} from '@/legacy/redux/utils'

// Admin
export const LIST_ORGANIZATIONS_AS_ADMIN_REQUEST =
  'ORGANIZATIONS/LIST_ORGANIZATIONS_AS_ADMIN_REQUEST'
export const LIST_ORGANIZATIONS_AS_ADMIN_SUCCESS =
  'ORGANIZATIONS/LIST_ORGANIZATIONS_AS_ADMIN_SUCCESS'
export const LIST_ORGANIZATIONS_AS_ADMIN_FAILURE =
  'ORGANIZATIONS/LIST_ORGANIZATIONS_AS_ADMIN_FAILURE'

export type ListOrganizationsAsAdminRequest = GenericActionWithType<
  typeof LIST_ORGANIZATIONS_AS_ADMIN_REQUEST,
  GenericPagination
>
export type ListOrganizationsAsAdminSuccess = GenericActionWithType<
  typeof LIST_ORGANIZATIONS_AS_ADMIN_SUCCESS,
  { organizations: ManagementOrganization[]; count: number }
>
export type ListOrganizationsAsAdminFailure = GenericActionWithType<
  typeof LIST_ORGANIZATIONS_AS_ADMIN_FAILURE,
  ErrorPayload
>

export type ListOrganizationsAsAdmin =
  | ListOrganizationsAsAdminRequest
  | ListOrganizationsAsAdminSuccess
  | ListOrganizationsAsAdminFailure

// Principal
export const LIST_ORGANIZATIONS_AS_PRINCIPAL_REQUEST =
  'ORGANIZATIONS/LIST_ORGANIZATIONS_AS_PRINCIPAL_REQUEST'
export const LIST_ORGANIZATIONS_AS_PRINCIPAL_SUCCESS =
  'ORGANIZATIONS/LIST_ORGANIZATIONS_AS_PRINCIPAL_SUCCESS'
export const LIST_ORGANIZATIONS_AS_PRINCIPAL_FAILURE =
  'ORGANIZATIONS/LIST_ORGANIZATIONS_AS_PRINCIPAL_FAILURE'

export type ListOrganizationsAsPrincipalRequest = GenericActionWithType<
  typeof LIST_ORGANIZATIONS_AS_PRINCIPAL_REQUEST,
  void
>
export type ListOrganizationsAsPrincipalSuccess = GenericActionWithType<
  typeof LIST_ORGANIZATIONS_AS_PRINCIPAL_SUCCESS,
  { organization: PrincipalOrganization }
>
export type ListOrganizationsAsPrincipalFailure = GenericActionWithType<
  typeof LIST_ORGANIZATIONS_AS_PRINCIPAL_FAILURE,
  ErrorPayload
>

export type ListOrganizationsAsPrincipal =
  | ListOrganizationsAsPrincipalRequest
  | ListOrganizationsAsPrincipalSuccess
  | ListOrganizationsAsPrincipalFailure
