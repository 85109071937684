export const scrollbarStyles = {
  '&::-webkit-scrollbar': {
    width: 8,
  },
  '&::-webkit-scrollbar-track': {
    marginTop: 5,
    marginBottom: 5,
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 4,
    background: '#c1c1bf',
  },
}
