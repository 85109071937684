import type { Option } from '@/legacy/components/v3/selects/selectSimple'

export interface FormState {
  title: string
  description: string
  selectedBook: Option
  availableInLibrary: boolean
}

export enum FORM_TYPES {
  SET_FORM = 'CONTENT_CREATOR/NEW_SEQUENCES/FORM/SET_FORM',
  CLEAN_FORM = 'CONTENT_CREATOR/NEW_SEQUENCES/FORM/CLEAN_FORM',
}

// Set form action.
export type SetFormAction = {
  type: FORM_TYPES.SET_FORM
  payload: FormState
}

// Clean form action.
export type CleanFormAction = {
  type: FORM_TYPES.CLEAN_FORM
}

export type FormAction = SetFormAction | CleanFormAction
