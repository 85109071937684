import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/content-creator/deleteBookModal/types/deleteBookModal'

const initialState: Types.DeleteBookModalState = {
  isOpenModal: false,
  status: REQUEST_STATUS.IDLE,
  bookIdToDelete: 0,
}

const deleteBookModalReducer = (
  state: Types.DeleteBookModalState = initialState,
  action: Types.DeleteBookModalAction
): Types.DeleteBookModalState => {
  switch (action.type) {
    case Types.DELETE_BOOK_MODAL_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isOpenModal: true,
      }
    }
    case Types.DELETE_BOOK_MODAL_TYPES.SET_BOOK_ID_TO_DELETE: {
      return {
        ...state,
        bookIdToDelete: action.payload,
      }
    }
    case Types.DELETE_BOOK_MODAL_TYPES.DELETE_BOOK_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.DELETE_BOOK_MODAL_TYPES.DELETE_BOOK_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.DELETE_BOOK_MODAL_TYPES.DELETE_BOOK_SUCCESS:
    case Types.DELETE_BOOK_MODAL_TYPES.CLEAN_MODAL: {
      return initialState
    }
    default:
      return state
  }
}

export default deleteBookModalReducer
