import React from 'react'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import renderEmoji from '@/legacy/components/v1/utils/renderEmoji'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '16px 0px',
    width: '100%',
    background: 'linear-gradient(0deg, rgba(80, 209, 116, 0.2), rgba(80, 209, 116, 0.2)), #FFFFFF',
    borderRadius: 8,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: '500',
    lineHeight: '23px',
    fontSize: 18,
  },
  description: {
    lineHeight: '23px',
    fontSize: 18,
  },
}))

const AllTasksCompleted = (props) => {
  const { subject } = props
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        {renderEmoji('😎')} ¡Ya completaste todas las actividades del día de {subject}!
      </Typography>
      <Typography className={classes.description}>
        Podés ir adelantando más actividades, o completar tu tarea de otras materias.
      </Typography>
    </div>
  )
}

export default AllTasksCompleted
