export const RECORDER_OPTIONS = {
  type: 'video',
  video: {
    width: 1280,
    height: 720,
  },
  mimeType: 'video/webm',
  fileExtension: 'webm',
  timeSlice: 1000,
  audioBitsPerSecond: 100000,
}

export const ANDROID_PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.educabot.conectados'
export const IOS_APP_STORE_URL = 'https://apps.apple.com/us/app/educabot-conectados/id1537157914'

export const breakoutRoomsColors = [
  '#302666',
  '#FF6428',
  '#01CEAA',
  '#9B00AF',
  '#FFB800',
  '#675CFF',
  '#E84F92',
  '#42B3FF',
]
