import React from 'react'
import { makeStyles } from '@mui/styles'
import { TextField, InputAdornment, IconButton } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import RemoveIcon from '@mui/icons-material/Remove'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  questionContainer: {},
  questionInput: {
    borderRadius: '3px',
    padding: '7px 16px',
    boxSizing: 'border-box',
    border: '1px solid #D8DBE8',
  },
  questionLegend: {
    textTransform: 'uppercase',
    fontSize: '18px',
    lineHeight: '23px',
  },
  questionInputText: {
    fontSize: '18px',
    lineHeight: '23px',
  },
  isEdit: ({ correct }) => ({
    backgroundColor: correct ? '#cdf8cc' : '#EDEFF8',
  }),
  checkButton: {
    padding: 0,
    marginRight: 16,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  removeButton: {
    border: '1px solid #4F36D6',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    color: '#4F36D6',
  },
}))

const MultipleChoiceQuestion = (props) => {
  const {
    id,
    value,
    isEdit,
    questionLegend,
    handleCorrect,
    handleDelete,
    handleChange,
    fullWidth,
    correct,
  } = props
  const classes = useStyles({ correct })

  return (
    <div className={classes.questionContainer}>
      <TextField
        fullWidth={fullWidth}
        value={value}
        id={id}
        className={clsx(classes.questionInput, { [classes.isEdit]: isEdit })}
        onChange={(e) => handleChange(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.questionLegend}>
              {questionLegend}.
            </InputAdornment>
          ),
          endAdornment: isEdit ? (
            <InputAdornment position="end">
              {!correct && value && (
                <IconButton
                  id={`${id}-correct`}
                  aria-label="Seleccionar respuesta correcta"
                  disableRipple
                  className={classes.checkButton}
                  onClick={handleCorrect}
                >
                  <CheckIcon className={classes.icon} />
                </IconButton>
              )}
              <IconButton
                id={`${id}-remove`}
                disableRipple
                className={classes.removeButton}
                onClick={handleDelete}
              >
                <RemoveIcon className={classes.icon} />
              </IconButton>
            </InputAdornment>
          ) : null,
          readOnly: !isEdit,
          disableUnderline: true,
          classes: {
            input: classes.questionInputText,
          },
        }}
      />
    </div>
  )
}

MultipleChoiceQuestion.defaultProps = {
  handleChange: () => {},
  handleDelete: () => {},
  handleCorrect: () => {},
  correct: false,
  fullWidth: true,
}

export default MultipleChoiceQuestion
