import type { ManagementOrganization } from '@/api/lms/organizations'
import type { ManagementSubject } from '@/api/lms/subjects'
import type { MultiTextInputValue } from '@/legacy/components/inputFields/multiTextInputField'
import type { Suggestion } from '@/legacy/components/v3/inputs/autosuggestInput'
import type { Option } from '@/legacy/components/v3/selects/selectSimple'
import type {
  CreateClassroomAsAdminAction,
  CreateClassroomAsPrincipalAction,
  EditClassroomAsAdminAction,
  EditClassroomAsPrincipalAction,
  GetClassroomSubjectsAsMgmtAction,
  ListClassroomsAsAdminAction,
  ListClassroomsAsPrincipalAction,
} from '@/legacy/redux/types/classrooms'
import type { SetField } from '@/legacy/redux/types/generic'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type { Classroom } from '@/legacy/services/api/lms/classrooms/types'

export type Stage = {
  id: number
  label: string
  masterSeries: {
    id: number
    label: string
    steps: {
      id: number
      label: string
    }[]
  }
}

export type AddEditClassroomModalState = {
  academicPeriod: Option | null
  academicPeriodsOptions: Option[]
  classroomDivision: string
  classroomId: number
  divisions: MultiTextInputValue[]
  editMode: boolean
  error: string
  grade: string
  loading: boolean
  loadingSubjects: boolean
  open: boolean
  organization: {
    error: string
    id: number
    label: string
    loading: boolean
    suggestions: Suggestion[]
  }
  organizationInput: string
  organizationOptions: Option[]
  shift: Option | null
  shiftOptions: Option[]
  stage: Option | null
  stageOptions: Stage[]
  subjects: ManagementSubject[]
  initialSubjects: ManagementSubject[]
  enrollmentCapacity: string
  k: Option | null
}

export type OnClickCreateOrEditClassroomParams = Omit<
  AddEditClassroomModalState,
  | 'open'
  | 'loading'
  | 'error'
  | 'organizationOptions'
  | 'stageOptions'
  | 'shiftOptions'
  | 'academicPeriodsOptions'
  | 'loadingSubjects'
  | 'organizationInput'
  | 'initialSubjects'
>

// Clear fields
export const CLEAR_FIELDS = 'MANAGEMENT/CLASSROOMS/ADD_EDIT_MODAL/CLEAR_FIELDS'
export type ClearAllFields = GenericActionWithType<typeof CLEAR_FIELDS>

// Edit
export const OPEN_AND_POPULATE_EDIT_MODAL_REQUEST =
  'MANAGEMENT/CLASSROOMS/ADD_EDIT_MODAL/OPEN_AND_POPULATE_EDIT_MODAL_REQUEST'
export const OPEN_AND_POPULATE_EDIT_MODAL_SUCCESS =
  'MANAGEMENT/CLASSROOMS/ADD_EDIT_MODAL/OPEN_AND_POPULATE_EDIT_MODAL_SUCCESS'
export const OPEN_AND_POPULATE_EDIT_MODAL_FAILURE =
  'MANAGEMENT/CLASSROOMS/ADD_EDIT_MODAL/OPEN_AND_POPULATE_EDIT_MODAL_FAILURE'

export type OpenAndPopulateEditModalRequest = GenericActionWithType<
  typeof OPEN_AND_POPULATE_EDIT_MODAL_REQUEST
>
export type OpenAndPopulateEditModalSuccess = GenericActionWithType<
  typeof OPEN_AND_POPULATE_EDIT_MODAL_SUCCESS,
  { classroom: Classroom; organization: ManagementOrganization }
>
export type OpenAndPopulateEditModalFailure = GenericActionWithType<
  typeof OPEN_AND_POPULATE_EDIT_MODAL_FAILURE,
  ErrorPayload
>

export type OpenAndPopulateEditModalAction =
  | OpenAndPopulateEditModalRequest
  | OpenAndPopulateEditModalSuccess
  | OpenAndPopulateEditModalFailure

// Get Organization
export const GET_ORGANIZATION_REQUEST =
  'MANAGEMENT/CLASSROOMS/ADD_EDIT_MODAL/GET_ORGANIZATION_REQUEST'
export const GET_ORGANIZATION_SUCCESS =
  'MANAGEMENT/CLASSROOMS/ADD_EDIT_MODAL/GET_ORGANIZATION_SUCCESS'
export const GET_ORGANIZATION_FAILURE =
  'MANAGEMENT/CLASSROOMS/ADD_EDIT_MODAL/GET_ORGANIZATION_FAILURE'
export type GetOrganizationRequest = GenericActionWithType<typeof GET_ORGANIZATION_REQUEST>
export type GetOrganizationSuccess = GenericActionWithType<
  typeof GET_ORGANIZATION_SUCCESS,
  { organization: ManagementOrganization }
>
export type GetOrganizationFailure = GenericActionWithType<
  typeof GET_ORGANIZATION_FAILURE,
  ErrorPayload
>
export type GetOrganizationAction =
  | GetOrganizationRequest
  | GetOrganizationSuccess
  | GetOrganizationFailure

// Get organization suggestions
export const GET_ORGANIZATION_SUGGESTIONS_REQUEST =
  'MANAGEMENT/CLASSROOMS/ADD_EDIT_MODAL/GET_ORGANIZATION_SUGGESTIONS_REQUEST'
export const GET_ORGANIZATION_SUGGESTIONS_SUCCESS =
  'MANAGEMENT/CLASSROOMS/ADD_EDIT_MODAL/GET_ORGANIZATION_SUGGESTIONS_SUCCESS'
export const GET_ORGANIZATION_SUGGESTIONS_FAILURE =
  'MANAGEMENT/CLASSROOMS/ADD_EDIT_MODAL/GET_ORGANIZATION_SUGGESTIONS_FAILURE'

export type GetOrganizationSuggestionsRequest = GenericActionWithType<
  typeof GET_ORGANIZATION_SUGGESTIONS_REQUEST
>
export type GetOrganizationSuggestionsSuccess = GenericActionWithType<
  typeof GET_ORGANIZATION_SUGGESTIONS_SUCCESS,
  { suggestions: Suggestion[] }
>
export type GetOrganizationSuggestionsFailure = GenericActionWithType<
  typeof GET_ORGANIZATION_SUGGESTIONS_FAILURE,
  ErrorPayload
>
export type GetOrganizationSuggestionsAction =
  | GetOrganizationSuggestionsRequest
  | GetOrganizationSuggestionsSuccess
  | GetOrganizationSuggestionsFailure

// Select organization suggestion
export const SELECT_ORGANIZATION_SUGGESTION =
  'MANAGEMENT/CLASSROOMS/ADD_EDIT_MODAL/SELECT_ORGANIZATION_SUGGESTION'

export type SelectOrganizationSuggestion = GenericActionWithType<
  typeof SELECT_ORGANIZATION_SUGGESTION,
  { suggestion: Suggestion }
>
// Clear organization suggestions
export const CLEAR_ORGANIZATION_SUGGESTIONS =
  'MANAGEMENT/CLASSROOMS/ADD_EDIT_MODAL/CLEAR_ORGANIZATION_SUGGESTIONS'

export type ClearOrganizationSuggestions = GenericActionWithType<
  typeof CLEAR_ORGANIZATION_SUGGESTIONS
>

export type AddEditClassroomModalAction =
  | SetField<'open' | 'organizationOptions' | 'subjects', boolean | Option[] | ManagementSubject[]>
  | ClearAllFields
  | OpenAndPopulateEditModalAction
  | GetOrganizationAction
  | ListClassroomsAsAdminAction
  | CreateClassroomAsAdminAction
  | EditClassroomAsAdminAction
  | ListClassroomsAsPrincipalAction
  | CreateClassroomAsPrincipalAction
  | EditClassroomAsPrincipalAction
  | GetClassroomSubjectsAsMgmtAction
  | GetOrganizationSuggestionsAction
  | SelectOrganizationSuggestion
  | ClearOrganizationSuggestions
