import * as React from 'react'
import type { SVGProps } from 'react'
const SvgWrittenExam = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="written-exam-svg"
    {...props}
  >
    <rect opacity={0.15} width={56} height={56} rx={6} fill="#ef4a3e" />
    <g className="page" fill="#ef4a3e">
      <g className="fold">
        <path
          className="fold-shadow"
          d="M22.723 11.31v6.818c0 .988-.801 1.79-1.79 1.79h-6.981c-.113 0-.2-.1-.186-.213.117-.93.54-1.8 1.21-2.468l4.883-4.884a4.237 4.237 0 0 1 2.66-1.23.188.188 0 0 1 .204.187Z"
          fillOpacity={0.51}
        />
        <path
          className="actual-fold"
          d="M20.934 21.027h-6.982a1.297 1.297 0 0 1-1.287-1.46 5.373 5.373 0 0 1 1.526-3.116l4.883-4.883a5.351 5.351 0 0 1 3.355-1.55 1.298 1.298 0 0 1 1.404 1.292v6.818c0 1.599-1.3 2.9-2.9 2.9Zm-5.745-2.22h5.745a.68.68 0 0 0 .678-.679v-5.65a3.123 3.123 0 0 0-.967.66L15.76 18.02c-.231.231-.424.497-.572.786Z"
        />
      </g>
      <path
        className="page-outline"
        d="M38.723 46H17.277a4.66 4.66 0 0 1-4.654-4.654v-21.11a5.351 5.351 0 0 1 1.569-3.785l4.882-4.883A5.328 5.328 0 0 1 22.859 10h15.864a4.66 4.66 0 0 1 4.654 4.654V31.33a1.11 1.11 0 0 1-2.22 0V14.654a2.436 2.436 0 0 0-2.433-2.434H22.858l-.107.002a3.112 3.112 0 0 0-2.107.916L15.76 18.02a3.13 3.13 0 0 0-.918 2.215v21.11a2.436 2.436 0 0 0 2.433 2.434h21.447a2.436 2.436 0 0 0 2.434-2.434v-3.354a1.11 1.11 0 0 1 2.22 0v3.354A4.659 4.659 0 0 1 38.723 46Z"
      />
      <path
        className="letter-a text"
        d="M21.412 35.716c0-.054.018-.127.036-.2l3.386-11.105c.2-.637 1.02-.947 1.858-.947.837 0 1.656.31 1.857.947l3.404 11.105c.018.073.036.146.036.2 0 .674-1.037 1.166-1.82 1.166-.455 0-.82-.146-.929-.529l-.618-2.275H24.78l-.619 2.275c-.11.383-.473.529-.928.529-.784 0-1.821-.492-1.821-1.166Zm6.608-3.823-1.329-4.879-1.329 4.879h2.658Z"
      />
      <path
        className="plus-sign text"
        d="M32.405 26.873V25.58h-1.292c-.373 0-.626-.333-.626-.839 0-.48.253-.852.626-.852h1.292v-1.28c0-.32.373-.639.853-.639.506 0 .84.32.84.64v1.279h1.278c.32 0 .64.373.64.852 0 .506-.32.84-.64.84h-1.279v1.292c0 .373-.333.626-.84.626-.479 0-.852-.253-.852-.626Z"
      />
    </g>
  </svg>
)
export default SvgWrittenExam
