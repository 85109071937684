import { persistReducer } from 'redux-persist'
import localforage from 'localforage'
import type { DisplayActions, DisplayState } from './types'
import { ACTIVITY_DISPLAY_TYPE_RIBBON, SET_ACTIVITY_DISPLAY_TYPE } from './types'

const initialState: DisplayState = {
  activityDisplayType: ACTIVITY_DISPLAY_TYPE_RIBBON,
}

function root(state = initialState, action: DisplayActions): DisplayState {
  switch (action.type) {
    case SET_ACTIVITY_DISPLAY_TYPE:
      return { ...state, activityDisplayType: action.payload.activityDisplayType }
    default:
      return state
  }
}

const persistConfig = {
  key: 'activityDisplayType',
  storage: localforage,
  blacklist: [],
}

export default persistReducer(persistConfig, root)
