import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export interface UploadVimeoState {
  error: Error | null
  uploading: boolean
  status: REQUEST_STATUS
  playerEmbedUrl: string
  uploadLink: string
  progress: number
  uploaded: boolean
}

export enum UPLOAD_VIMEO_VIDEO_TYPES {
  CLEAN_VIDEO = 'TEACHER/BULLETIN/UPLOAD_VIMEO_VIDEO/CLEAN_VIDEO',
  CREATE_VIDEO = 'TEACHER/BULLETIN/UPLOAD_VIMEO_VIDEO/CREATE_VIDEO',
  CREATE_VIDEO_FAILURE = 'TEACHER/BULLETIN/UPLOAD_VIMEO_VIDEO/CREATE_VIDEO_FAILURE',
  CREATE_VIDEO_SUCCESS = 'TEACHER/BULLETIN/UPLOAD_VIMEO_VIDEO/CREATE_VIDEO_SUCCESS',
  UPLOAD_VIDEO_START = 'TEACHER/BULLETIN/UPLOAD_VIMEO_VIDEO/UPLOAD_VIDEO_START',
  UPLOAD_VIDEO_ABORT = 'TEACHER/BULLETIN/UPLOAD_VIMEO_VIDEO/UPLOAD_VIDEO_ABORT',
  UPLOAD_VIDEO_FAILURE = 'TEACHER/BULLETIN/UPLOAD_VIMEO_VIDEO/UPLOAD_VIDEO_FAILURE',
  UPLOAD_VIDEO_SUCCESS = 'TEACHER/BULLETIN/UPLOAD_VIMEO_VIDEO/UPLOAD_VIDEO_SUCCESS',
  UPLOAD_VIDEO_PROGRESS = 'TEACHER/BULLETIN/UPLOAD_VIMEO_VIDEO/UPLOAD_VIMEO_VIDEO_PROGRESS',
}

type ClearVimeoVideo = {
  type: UPLOAD_VIMEO_VIDEO_TYPES.CLEAN_VIDEO
}

type CreateVimeoVideo = {
  type: UPLOAD_VIMEO_VIDEO_TYPES.CREATE_VIDEO
}

type CreateVimeoVideoSuccess = {
  type: UPLOAD_VIMEO_VIDEO_TYPES.CREATE_VIDEO_SUCCESS
  payload: {
    file: File
    uploadLink: string
    playerEmbedUrl: string
  }
}

type CreateVimeoVideoFailure = {
  type: UPLOAD_VIMEO_VIDEO_TYPES.CREATE_VIDEO_FAILURE
  payload: {
    error: Error
  }
}

type UploadVimeoVideoStart = {
  type: UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_START
}

type UploadVimeoVideoAbort = {
  type: UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_ABORT
}

type UploadVimeoVideoSuccess = {
  type: UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_SUCCESS
}

type UploadVimeoVideoFailure = {
  type: UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_FAILURE
  payload: {
    error: Error
  }
}

type UploadVimeoVideoProgress = {
  type: UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_PROGRESS
  payload: {
    total: number
    uploaded: number
  }
}

export type UploadVimeoActions =
  | ClearVimeoVideo
  | CreateVimeoVideo
  | CreateVimeoVideoSuccess
  | CreateVimeoVideoFailure
  | UploadVimeoVideoStart
  | UploadVimeoVideoAbort
  | UploadVimeoVideoSuccess
  | UploadVimeoVideoFailure
  | UploadVimeoVideoProgress
