import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type { ScoreType } from '@/sections/report-card/types/common'
import type { StudentAttitudinalScore } from '@/sections/report-card/grading/types/tabs/attitudinalScoresTab'
import type { CreatedReportCardConfigRangeScore } from '@/sections/report-card/grading/types/entities'
import type { FindClassroomReportCardConfigSuccess } from '@/sections/report-card/grading/types/mainView'
import type { StudentScoreSubitem } from '@/sections/report-card/grading/types/tabs/scoresTab'

export interface SubitemMap {
  [orderValue: number]: SubitemMapItem
}

export type SubitemMapItem = StudentScoreSubitem & { alreadyExists: boolean } // Items already graded can´t be overwritten with 0

export interface AttitudinalSubitem {
  name: string
  orderValue: number
  valueId?: number
  score?: string
  value?: number
}

export interface AttitudinalScoreValue {
  name: string
  orderValue: number
  reportId: number
  scoreType: ScoreType
  subitems: {
    name: string
    orderValue: number
  }[]
  subjectId: number
}

export interface AttitudinalScoresGradingFormState {
  scoreType: ScoreType
  conceptScoreValues: CreatedReportCardConfigRangeScore[]
  numericalScoreValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  attitudinalScoreValues: CreatedReportCardConfigRangeScore[]
  loading: boolean
  error: string
  currentValue: number
  mainValueAlreadyExists: boolean
  subitems: AttitudinalSubitem[]
}

export const INIT_STUDENT_ATTITUDINAL_SCORE =
  'REPORT_CARD/GRADING/FORMS/ATTITUDINAL_SCORES/INIT_STUDENT_ATTITUDINAL_SCORE'

export type InitStudentAttitudinalScore = GenericActionWithType<
  typeof INIT_STUDENT_ATTITUDINAL_SCORE,
  {
    subject: AttitudinalScoreValue
    score?: StudentAttitudinalScore
  }
>
export const SET_ATTITUDINAL_SUBITEM_SCORE =
  'REPORT_CARD/GRADING/FORMS/ATTITUDINAL_SCORES/SET_ATTITUDINAL_SUBITEM_SCORE'
export type SetAttitunidalSubitemScore = GenericActionWithType<
  typeof SET_ATTITUDINAL_SUBITEM_SCORE,
  { orderValue: number; value: number }
>

export const SAVE_ATTITUDINAL_SCORE_REQUEST =
  'REPORT_CARD/GRADING/FORMS/ATTITUDINAL_SCORES/SAVE_ATTITUDINAL_SCORE_REQUEST'
export const SAVE_ATTITUDINAL_SCORE_SUCCESS =
  'REPORT_CARD/GRADING/FORMS/ATTITUDINAL_SCORES/SAVE_ATTITUDINAL_SCORE_SUCCESS'
export const SAVE_ATTITUDINAL_SCORE_FAILURE =
  'REPORT_CARD/GRADING/FORMS/ATTITUDINAL_SCORES/SAVE_ATTITUDINAL_SCORE_FAILURE'

export type SaveAttitudinalScoreRequest = GenericActionWithType<
  typeof SAVE_ATTITUDINAL_SCORE_REQUEST
>
export type SaveAttitudinalScoreSuccess = GenericActionWithType<
  typeof SAVE_ATTITUDINAL_SCORE_SUCCESS
>
export type SaveAttitudinalScoreFailure = GenericActionWithType<
  typeof SAVE_ATTITUDINAL_SCORE_FAILURE,
  ErrorPayload
>

export type SaveAttitudinalScoreAction =
  | SaveAttitudinalScoreRequest
  | SaveAttitudinalScoreSuccess
  | SaveAttitudinalScoreFailure

export const UPDATE_ATTITUDINAL_SCORE_REQUEST =
  'REPORT_CARD/GRADING/FORMS/ATTITUDINAL_SCORES/UPDATE_ATTITUDINAL_SCORE_REQUEST'
export const UPDATE_ATTITUDINAL_SCORE_SUCCESS =
  'REPORT_CARD/GRADING/FORMS/ATTITUDINAL_SCORES/UPDATE_ATTITUDINAL_SCORE_SUCCESS'
export const UPDATE_ATTITUDINAL_SCORE_FAILURE =
  'REPORT_CARD/GRADING/FORMS/ATTITUDINAL_SCORES/UPDATE_ATTITUDINAL_SCORE_FAILURE'

export type UpdateAttitudinalScoreRequest = GenericActionWithType<
  typeof UPDATE_ATTITUDINAL_SCORE_REQUEST
>
export type UpdateAttitudinalScoreSuccess = GenericActionWithType<
  typeof UPDATE_ATTITUDINAL_SCORE_SUCCESS
>
export type UpdateAttitudinalScoreFailure = GenericActionWithType<
  typeof UPDATE_ATTITUDINAL_SCORE_FAILURE,
  ErrorPayload
>

export type UpdateAttitudinalScoreAction =
  | UpdateAttitudinalScoreRequest
  | UpdateAttitudinalScoreSuccess
  | UpdateAttitudinalScoreFailure

export type AttitudinalScoresGradingFormAction =
  | InitStudentAttitudinalScore
  | SetAttitunidalSubitemScore
  | FindClassroomReportCardConfigSuccess
  | ClearState
  | SetField<'currentValue', number>
  | SaveAttitudinalScoreAction
  | UpdateAttitudinalScoreAction
