import type { Count } from '@/api/lms/activities/types'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export interface CountState {
  status: REQUEST_STATUS
  data: Count
}

export enum COUNT_TYPES {
  GET_COUNT_REQUEST = 'STUDENT/SUMMARY/TABS/ACTIVITIES/COUNT/GET_COUNT_REQUEST',
  GET_COUNT_SUCCESS = 'STUDENT/SUMMARY/TABS/ACTIVITIES/COUNT/GET_COUNT_SUCCESS',
  GET_COUNT_FAILURE = 'STUDENT/SUMMARY/TABS/ACTIVITIES/COUNT/GET_COUNT_FAILURE',
  CLEAN_COUNT = 'STUDENT/SUMMARY/TABS/ACTIVITIES/COUNT/CLEAN_COUNT',
}

// Get count actions.
export type GetCountRequestAction = {
  type: COUNT_TYPES.GET_COUNT_REQUEST
}

export type GetCountSuccessAction = {
  type: COUNT_TYPES.GET_COUNT_SUCCESS
  payload: {
    data: CountState['data']
  }
}

export type GetCountFailureAction = {
  type: COUNT_TYPES.GET_COUNT_FAILURE
}

export type GetCountAction = GetCountRequestAction | GetCountSuccessAction | GetCountFailureAction

// Clean count.
export type CleanCountAction = {
  type: COUNT_TYPES.CLEAN_COUNT
}

export type CountAction = GetCountAction | CleanCountAction
