// State.
export interface UploadImagesState {
  images: { url: string; type: string; name: string }[]
}

// Types.
export enum UPLOAD_IMAGES_TYPES {
  SET_UPLOAD_IMAGES = 'TEACHER/BULLETIN/UPLOAD_IMAGES/SET_UPLOAD_IMAGES',
  CLEAN_IMAGES = 'TEACHER/BULLETIN/UPLOAD_IMAGES/CLEAN_IMAGES',
}

// Set upload images action.
export type SetUploadImagesAction = {
  type: UPLOAD_IMAGES_TYPES.SET_UPLOAD_IMAGES
  payload: {
    images: UploadImagesState['images']
  }
}

// Clean images action.
export type CleanImagesAction = {
  type: UPLOAD_IMAGES_TYPES.CLEAN_IMAGES
}

// Actions.
export type UploadImagesActions = SetUploadImagesAction | CleanImagesAction
