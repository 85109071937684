import { apiPrivate } from './..'
import { url } from '@aula/config'

export const QuizAPI = {
  onboarding,
  getOrCreateSubmission,
  getQuestion,
  getAnswer,
  answer,
  complete,
}

/**
 * Creates a submission or returns an existing one.
 *
 * @role Any
 * @param activityID
 */
function getOrCreateSubmission(activityID: number) {
  return apiPrivate.put(url + `/v1/quizzes/${activityID}/submissions`)
}

/**
 * Get a question from the quiz.
 *
 * @role Any
 * @param activityID
 * @param submissionID
 * @param questionID
 */
function getQuestion(activityID: number, submissionID: number, questionID: number) {
  return apiPrivate.get(
    url + `/v1/quizzes/${activityID}/submissions/${submissionID}/questions/${questionID}`
  )
}

/**
 * Choose answer
 *
 * @role Any
 * @param activityID
 * @param submissionID
 * @param questionID
 * @param optionID 0 means that time ran out and nothing was chosen
 */
function answer(activityID: number, submissionID: number, questionID: number, optionID: number) {
  const body = {
    questionId: questionID,
    optionId: optionID,
    onTime: optionID !== 0,
  }
  return apiPrivate.post(
    url + `/v1/quizzes/${activityID}/submissions/${submissionID}/answers`,
    body
  )
}

/**
 * Get the answer for a question.
 *
 * @role Any
 * @param activityID
 * @param submissionID
 * @param questionID
 */
function getAnswer(activityID: number, submissionID: number, questionID: number) {
  return apiPrivate.get(
    url + `/v1/quizzes/${activityID}/submissions/${submissionID}/questions/${questionID}/answer`
  )
}

/**
 * Marks the quiz as completed.
 *
 * @role Any
 * @param activityID
 * @param submissionID
 */
function complete(activityID: number, submissionID: number) {
  return apiPrivate.put(url + `/v1/quizzes/${activityID}/submissions/${submissionID}/final`)
}

/**
 * Returns whether this is the first quiz the user has ever done.
 *
 * @role Any
 */
function onboarding() {
  return apiPrivate.get(url + `/v1/onboarding/quizzes`)
}
