import type { ManagementSubject } from '@/api/lms/subjects'
import type { Suggestion } from '@/legacy/components/v3/inputs/autosuggestInput'
import type { ColorOption } from '@/legacy/components/v3/selects/colorSelectSimple'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type {
  CreateSubjectAsAdminAction,
  CreateSubjectAsPrincipalAction,
  EditSubjectAsAdminAction,
} from '@/legacy/redux/types/subjects'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type { OrganizationOption } from '@/sections/eb-admin/dashboard/types/common'

type SubjectName = {
  value: string
  suggestions: Suggestion[]
  loading: boolean
  error: string
}

export type AddEditSubjectModalState = {
  subjectId: number
  organization: OrganizationOption
  open: boolean
  loading: boolean
  error: string
  colorOptions: ColorOption[]
  color: ColorOption
  colorDisabled: boolean
  editMode: boolean
  name: SubjectName
}

interface SetModalInitialValuesPayload {
  subject: ManagementSubject
  organization: OrganizationOption
  requestOrigins: ReduxRequestOrigin[]
}

// Edit
export const SET_INITIAL_VALUES = 'MANAGEMENT/SUBJECTS/ADD_EDIT_MODAL/SET_INITIAL_VALUES'
// Get suggestions
export const GET_SUGGESTIONS_REQUEST = 'MANAGEMENT/SUBJECTS/ADD_EDIT_MODAL/GET_SUGGESTIONS_REQUEST'
export const GET_SUGGESTIONS_SUCCESS = 'MANAGEMENT/SUBJECTS/ADD_EDIT_MODAL/GET_SUGGESTIONS_SUCCESS'
export const GET_SUGGESTIONS_FAILURE = 'MANAGEMENT/SUBJECTS/ADD_EDIT_MODAL/GET_SUGGESTIONS_FAILURE'
// Select suggestion
export const SELECT_SUGGESTION = 'MANAGEMENT/SUBJECTS/ADD_EDIT_MODAL/SELECT_SUGGESTION'
// Clear suggestions
export const CLEAR_SUGGESTIONS = 'MANAGEMENT/SUBJECTS/ADD_EDIT_MODAL/CLEAR_SUGGESTIONS'

// Edit
export type SetInitialValues = GenericActionWithType<
  typeof SET_INITIAL_VALUES,
  SetModalInitialValuesPayload
>
// Get suggestions
export type GetSuggestionsRequest = GenericActionWithType<typeof GET_SUGGESTIONS_REQUEST>
export type GetSuggestionsSuccess = GenericActionWithType<
  typeof GET_SUGGESTIONS_SUCCESS,
  { suggestions: Suggestion[] }
>
export type GetSuggestionsFailure = GenericActionWithType<
  typeof GET_SUGGESTIONS_FAILURE,
  ErrorPayload
>
export type GetSuggestionsAction =
  | GetSuggestionsRequest
  | GetSuggestionsSuccess
  | GetSuggestionsFailure

// Select suggestion
export type SelectSuggestion = GenericActionWithType<
  typeof SELECT_SUGGESTION,
  { suggestion: Suggestion }
>
// Clear suggestions
export type ClearSuggestions = GenericActionWithType<typeof CLEAR_SUGGESTIONS>

export type AddEditSubjectModalAction =
  | SetField<
      | 'open'
      | 'loading'
      | 'error'
      | 'name'
      | 'organizationName'
      | 'colorOptions'
      | 'color'
      | 'editMode'
      | 'name',
      string | boolean | File | null | SubjectName
    >
  | ClearState
  | SetInitialValues
  | CreateSubjectAsAdminAction
  | EditSubjectAsAdminAction
  | CreateSubjectAsPrincipalAction
  | GetSuggestionsAction
  | SelectSuggestion
  | ClearSuggestions
