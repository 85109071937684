import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { ContactsActions, ContactsState } from '@/sections/teacher/bulletin/types/contacts'
import { CONTACTS_TYPES } from '@/sections/teacher/bulletin/types/contacts'

const initialState: ContactsState = {
  status: REQUEST_STATUS.IDLE,
  loading: false,
  error: null,
  contacts: [],
}

export default function contactsReducer(
  state: ContactsState = initialState,
  action: ContactsActions
): ContactsState {
  switch (action.type) {
    case CONTACTS_TYPES.GET_CONTACTS: {
      return {
        ...state,
        loading: true,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case CONTACTS_TYPES.GET_CONTACTS_SUCCESS: {
      const { contacts } = action.payload
      return {
        ...state,
        loading: false,
        status: REQUEST_STATUS.SUCCESS,
        contacts,
      }
    }
    case CONTACTS_TYPES.GET_CONTACTS_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        loading: false,
        status: REQUEST_STATUS.FAILURE,
        error,
      }
    }
    default:
      return state
  }
}
