import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { OrganizationCourse } from '@/sections/courses/dashboard/types/courses'

export const SET_OPEN = 'LIBRARY/COURSES/SET_OPEN'
export const SET_CLOSED = 'LIBRARY/COURSES/SET_CLOSED'

export const SET_ASSIGN_MODAL_OPEN = 'LIBRARY/COURSES/SET_ASSIGN_MODAL_OPEN'
export const SET_ASSIGN_MODAL_CLOSED = 'LIBRARY/COURSES/SET_ASSIGN_MODAL_CLOSED<'

export const GET_COURSES_REQUEST = 'LIBRARY/COURSES/GET_COURSES_REQUEST'
export const GET_COURSES_SUCCESS = 'LIBRARY/COURSES/GET_COURSES_SUCCESS'
export const GET_COURSES_FAILURE = 'LIBRARY/COURSES/GET_COURSES_FAILURE'

export const COURSE_TYPES = {
  AUTONOMOUS: 'autonomous',
  GUIDED: 'guided',
}

export type CourseType = typeof COURSE_TYPES.AUTONOMOUS | typeof COURSE_TYPES.GUIDED

export const CURRENCY_TYPES = {
  ARS: 1,
  USD: 2,
}

export type Currency = typeof CURRENCY_TYPES.ARS | typeof CURRENCY_TYPES.USD

export const COURSE_ENTITY_TYPES = {
  ACTIVITY: 'activity',
  UNIT: 'unit',
}
export type CourseEntityType = typeof COURSE_ENTITY_TYPES.ACTIVITY | typeof COURSE_ENTITY_TYPES.UNIT

export interface CourseEntity {
  title: string
  order: number
}

export interface CourseActivity extends CourseEntity {
  activityId: number
  entityType?: typeof COURSE_ENTITY_TYPES.ACTIVITY
}

export interface CourseUnit extends CourseEntity {
  unitId: number
  entityType?: typeof COURSE_ENTITY_TYPES.UNIT
}

export type Pages = {
  page: number
  rowsPerPage: number
  count: number
}
export type Classroom = {
  id: number
  stage: string
  grade: string
  division: string
  shift: string
  year: number
  organizationId: number
}

export type Course = {
  organization: OrganizationCourse
  id: number
  organizationId: number
  name: string
  shortDescription: string
  longDescription: string
  requirements: string
  addressedTo: string
  classroom: Classroom
  currency: Currency
  price: number
  duration: number
  type: CourseType
  coverPhoto: string
  content: CourseActivity[]
  units: CourseUnit[]
  tags: string[]
  hasLicense: boolean
  activityCount: number
  availableInLibrary: boolean
}

export interface GetCoursesRequest {
  type: typeof GET_COURSES_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
    courseId: number
    page: number
    rowsPerPage: number
  }
}

export interface GetCoursesSuccess {
  type: typeof GET_COURSES_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    courses: Course[]
    total: number
  }
}

export interface GetCoursesFailure {
  type: typeof GET_COURSES_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface SetClosed {
  type: typeof SET_CLOSED
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface AssignModalOpen {
  type: typeof SET_ASSIGN_MODAL_OPEN
  payload: { selectedCourse: Course; requestOrigin: ReduxRequestOrigin }
}

export interface AssignModalClose {
  type: typeof SET_ASSIGN_MODAL_CLOSED
  payload: { requestOrigin: ReduxRequestOrigin }
}

export type LibraryCourseState = {
  assignModalOpen: boolean
  selectedCourse: Course | null
  open: boolean
  loadingCourses: boolean
  courses: Course[]
  coursePagination: Pages
  error: ''
}

export type LibraryCourseAction =
  | AssignModalOpen
  | AssignModalClose
  | GetCoursesRequest
  | GetCoursesSuccess
  | GetCoursesFailure
  | SetOpen
  | SetClosed
