import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import moment from 'moment'
import type {
  CourseAssignmentModalAction,
  CourseAssignmentModalState,
} from '@/sections/teacher/library/types/assignCourse'
import {
  SET_CLASSROOM,
  GET_CLASSROOMS_FAILURE,
  GET_CLASSROOMS_SUCCESS,
  TOGGLE_SEQUENCE_MODAL_OPEN,
  GET_CLASSROOMS_REQUEST,
  GET_CLASSROOM_SUBGROUPS_REQUEST,
  GET_CLASSROOM_SUBGROUPS_SUCCESS,
  GET_CLASSROOM_SUBGROUPS_FAILURE,
  SELECT_SUBGROUPS,
  GET_SUBJECTS_REQUEST,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_FAILURE,
  SET_SUBJECT,
  SET_CLASSROOM_SEARCH,
  ASSIGN_COURSE_REQUEST,
  ASSIGN_COURSE_SUCCESS,
  ASSIGN_COURSE_FAILURE,
  SET_STEP,
  SET_TABLE_DATE,
  SET_TABLE_TIME,
  SET_TABLE_SWITCH,
  SET_TABLE_DURATION,
  SET_GLOBAL_START_DATE,
  SET_GLOBAL_END_DATE,
  ENABLE_GLOBAL_END_DATE,
} from '@/sections/teacher/library/types/assignCourse'
import { formatClassroomLabel } from '@/sections/principal/classroom-migration/components/migrationTab'
import { COURSE_ENTITY_TYPES } from '@/sections/content-creator/courses/types/courses'
import { clone } from 'ramda'

export enum AssignCourseSteps {
  MAIN = 0,
  TABLE = 1,
}

export enum TableDates {
  DATE = 'date',
  DELIVER = 'deliver',
  PUBLISH = 'publish',
}

export enum TableSwitches {
  DISTRIBUTE = 'distribute',
  CHAT_ENABLED = 'chat_enabled',
}

const initialState: CourseAssignmentModalState = {
  open: false,
  course: null,
  subject: {
    id: 0,
    name: '',
    color: '',
  },
  selectedClassroom: {
    id: 0,
    division: '',
    grade: '',
    organization: {
      id: 0,
      name: '',
    },
    organizationType: '',
    shift: '',
    year: 0,
  },
  classrooms: [],
  subgroups: [],
  selectedSubgroups: [],
  subjects: [],
  loadingClassrooms: false,
  loadingClassroomOptions: false,
  loadingSubgroups: false,
  loadingSubjects: false,
  loadingAssignment: false,
  error: '',
  lastClassroomID: 0,
  classroomSearch: '',
  step: AssignCourseSteps.MAIN,
  globalStartDate: moment(),
  globalEndDate: moment(),
  globalEndDateEnabled: false,
}

const requestDestination = reduxRequestOriginMap.TEACHER_LIBRARY_COURSE_ASSIGNMENT_MODAL

function root(
  state: CourseAssignmentModalState = initialState,
  action: CourseAssignmentModalAction
): CourseAssignmentModalState {
  if (action.payload?.requestOrigin !== requestDestination) return state

  switch (action.type) {
    case GET_CLASSROOMS_REQUEST:
      return {
        ...state,
        loadingClassrooms: !action.payload.optionsLoading,
        loadingClassroomOptions: action.payload.optionsLoading,
        lastClassroomID: action.payload.lastSearchID,
      }
    case GET_CLASSROOMS_SUCCESS: {
      const { classrooms, optionsLoading } = action.payload

      return {
        ...state,
        loadingClassroomOptions: false,
        loadingClassrooms: false,
        classrooms: classrooms,
        selectedClassroom:
          optionsLoading && classrooms.length ? state.selectedClassroom : classrooms[0],
        classroomSearch:
          optionsLoading && classrooms.length
            ? state.classroomSearch
            : // @ts-ignore
              formatClassroomLabel(classrooms[0]),
      }
    }
    case GET_CLASSROOMS_FAILURE:
      return {
        ...state,
        loadingClassroomOptions: false,
        loadingClassrooms: false,
        error: action.payload.error,
      }
    case TOGGLE_SEQUENCE_MODAL_OPEN:
      if (!action.payload.open) return initialState
      return { ...state, open: action.payload.open, course: action.payload.course || null }
    case SET_CLASSROOM:
      return { ...state, selectedClassroom: action.payload.classroom }
    case GET_CLASSROOM_SUBGROUPS_REQUEST:
      return { ...state, loadingSubgroups: true }
    case GET_CLASSROOM_SUBGROUPS_SUCCESS: {
      const { subgroups } = action.payload
      return {
        ...state,
        loadingSubgroups: false,
        subgroups,
        selectedSubgroups: [],
      }
    }
    case GET_CLASSROOM_SUBGROUPS_FAILURE:
      return { ...state, loadingSubgroups: false, error: action.payload.error }
    case SELECT_SUBGROUPS:
      return {
        ...state,
        selectedSubgroups: action.payload.selectedSubgroups,
      }
    case GET_SUBJECTS_REQUEST: {
      return { ...state, loadingSubjects: true }
    }
    case GET_SUBJECTS_SUCCESS: {
      return {
        ...state,
        loadingSubjects: false,
        subjects: action.payload.subjects,
        subject: action.payload.subjects[0] || null,
      }
    }
    case GET_SUBJECTS_FAILURE: {
      return { ...state, loadingSubjects: false, error: action.payload.error }
    }
    case SET_SUBJECT: {
      return { ...state, subject: action.payload.subject }
    }
    case SET_GLOBAL_START_DATE: {
      return {
        ...state,
        globalStartDate: action.payload.globalStartDate,
        globalEndDate: state.globalEndDate.isBefore(action.payload.globalStartDate)
          ? action.payload.globalStartDate
          : state.globalEndDate,
        course: state.course
          ? {
              ...state.course,
              content: state.course.content.map((a) => ({
                ...a,
                date: action.payload.globalStartDate.format(),
                dateDeliver: action.payload.globalStartDate.format(),
                datePublish: action.payload.globalStartDate.format(),
              })),
            }
          : null,
      }
    }
    case SET_GLOBAL_END_DATE: {
      return { ...state, globalEndDate: action.payload.globalEndDate }
    }
    case ENABLE_GLOBAL_END_DATE: {
      return { ...state, globalEndDateEnabled: action.payload.globalEndDateEnabled }
    }
    case SET_CLASSROOM_SEARCH: {
      return { ...state, classroomSearch: action.payload.classroomSearch }
    }
    case ASSIGN_COURSE_REQUEST: {
      return { ...state, loadingAssignment: true, error: '' }
    }
    case ASSIGN_COURSE_SUCCESS: {
      return initialState
    }
    case ASSIGN_COURSE_FAILURE: {
      return { ...state, loadingAssignment: false, error: action.payload.error }
    }
    case SET_STEP: {
      return { ...state, step: action.payload.step }
    }
    case SET_TABLE_DATE: {
      const { course } = state
      // @ts-ignore
      const { content } = course

      const activityIndex = content.findIndex(
        (a) => a.type === COURSE_ENTITY_TYPES.ACTIVITY && a.id === action.payload.index
      )
      if (activityIndex === -1) return state
      const activity = content[activityIndex]

      switch (action.payload.type) {
        case TableDates.DATE:
          activity.date = action.payload.date
          break
        case TableDates.DELIVER:
          activity.dateDeliver = action.payload.date
          break
        case TableDates.PUBLISH:
          activity.datePublish = action.payload.date
          break
      }

      // @ts-ignore
      course.content = [...content]
      // @ts-ignore
      course.content[activityIndex] = activity

      return { ...state, course: course }
    }
    case SET_TABLE_TIME: {
      const { course } = state
      const clonedCourse = clone(course)
      // @ts-ignore
      const { content } = clonedCourse

      const activityIndex = content.findIndex(
        (a) => a.type === COURSE_ENTITY_TYPES.ACTIVITY && a.id === action.payload.index
      )
      if (activityIndex === -1) return state
      const activity = content[activityIndex]

      activity.time = action.payload.time

      // @ts-ignore
      clonedCourse.content[activityIndex] = activity

      return { ...state, course: clonedCourse }
    }
    case SET_TABLE_SWITCH: {
      const { course } = state
      // @ts-ignore
      const { content } = course

      const activityIndex = content.findIndex(
        (a) => a.type === COURSE_ENTITY_TYPES.ACTIVITY && a.id === action.payload.index
      )
      if (activityIndex === -1) return state
      const activity = content[activityIndex]

      switch (action.payload.type) {
        case TableSwitches.CHAT_ENABLED:
          activity.toggled = action.payload.toggled
          break
        case TableSwitches.DISTRIBUTE:
          activity.distribute = action.payload.toggled
          break
      }

      // @ts-ignore
      course.content = [...content]
      // @ts-ignore
      course.content[activityIndex] = activity

      return { ...state, course: course }
    }
    case SET_TABLE_DURATION: {
      const { course } = state
      // @ts-ignore
      const { content } = course

      const activityIndex = content.findIndex(
        (a) => a.type === COURSE_ENTITY_TYPES.ACTIVITY && a.id === action.payload.index
      )
      if (activityIndex === -1) return state
      const activity = content[activityIndex]

      activity.duration = action.payload.duration

      // @ts-ignore
      course.content = [...content]
      // @ts-ignore
      course.content[activityIndex] = activity

      return { ...state, course: course }
    }

    default:
      return state
  }
}

export default root
