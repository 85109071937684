import { FormControlLabel, Switch as MUISwitch } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 49,
    height: 30,
    padding: 0,
    margin: '0 8px',
  },
  switchBase: {
    padding: 3,
    '&$checked': {
      transform: 'translateX(19px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: 'transparent',
        opacity: 1,
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 30,
    boxSizing: 'border-box',
    border: '1px solid #D8DBE8',
    backgroundColor: '#FFF',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
  removeCircleIcon: {
    fill: '#C4C4C4',
  },
  checkCircleIcon: {
    fill: '#50D174',
  },
  controlLabel: {
    margin: 0,
  },
}))

const Switch = ({ selected, setSelected, label, disabled }) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      label={label}
      disabled={disabled}
      className={classes.controlLabel}
      control={
        <MUISwitch
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          value={selected}
          onChange={(e) => setSelected(e.target.checked)}
          icon={<RemoveCircleIcon className={classes.removeCircleIcon} />}
          checkedIcon={<CheckCircleIcon className={classes.checkCircleIcon} />}
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
        />
      }
    />
  )
}

export default Switch
