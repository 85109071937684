import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { DEFAULT_DOC_TYPE } from '@/legacy/redux/types/docTypesOptions'
import type { Reducer } from 'redux'
import { Redux } from '@/legacy/redux'
import * as Types from '@/sections/ted/types/editGroupModal'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type { Option } from '@/legacy/components/v3/selects/selectSimple'
import { GroupStatus, Meeting } from '@/api/ted/main'

export const statusOptions = [
  { id: 1, label: GroupStatus.ACTIVE, color: '#50D174' },
  { id: 2, label: GroupStatus.INACTIVE, color: '#F45C3A' },
  { id: 3, label: GroupStatus.PAUSED, color: '#FFB800' },
]
export const stageOptions: Option[] = [
  { id: 1, label: 'Etapa Descubrir - Encuentro 1', value: Meeting.MEETING_1 },
  { id: 2, label: 'Etapa Descubrir - Encuentro 2', value: Meeting.MEETING_2 },
  { id: 3, label: 'Etapa Construir - Encuentro 3', value: Meeting.MEETING_3 },
  { id: 4, label: 'Etapa Construir - Encuentro 4', value: Meeting.MEETING_4 },
  { id: 5, label: 'Etapa Construir - Encuentro 5', value: Meeting.MEETING_5 },
  { id: 6, label: 'Etapa Comunicar - Encuentro 6', value: Meeting.MEETING_6 },
  { id: 7, label: 'Etapa Comunicar - Encuentro 7', value: Meeting.MEETING_7 },
  { id: 8, label: 'Etapa Comunicar - Encuentro 8', value: Meeting.MEETING_8 },
  { id: 9, label: 'Evento Final', value: Meeting.FINAL_EVENT },
]

const initialState: Types.EditGroupModalState = {
  id: 1,
  open: false,
  loading: false,
  error: '',
  editMode: true,
  selectedStatus: GroupStatus.ACTIVE,
  groupId: 0,
  name: '',
  currentStage: Meeting.MEETING_1,
  finalEventCompleted: true,
  totalParticipants: 0,
  completedTalksCount: 0,
  teachersCount: 0,
}

const requestDestination = reduxRequestOriginMap.TED_EDIT_MODAL

const EditGroupModalReducer: Reducer<Types.EditGroupModalState, Types.EditGroupModalAction> = (
  state = initialState,
  action
): Types.EditGroupModalState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case Redux.Types.Generic.CLEAR_STATE:
      return { ...initialState }

    case Types.OPEN_EDIT_MODAL_REQUEST:
      return { ...state, open: true, loading: true, editMode: true }

    case Types.CLOSE_EDIT_MODAL:
      return { ...state, open: false, loading: false, editMode: false }

    case Types.OPEN_EDIT_MODAL_SUCCESS: {
      const {
        id,
        name,
        currentStage,
        finalEventCompleted,
        totalParticipants,
        completedTalksCount,
        teachersCount,
        status,
      } = action.payload.group

      return {
        ...state,
        open: true,
        loading: false,
        editMode: true,
        groupId: id,
        name,
        currentStage,
        teachersCount,

        finalEventCompleted,
        completedTalksCount,
        totalParticipants,
        selectedStatus: status,
      }
    }
    case Types.EDIT_GROUP_DATA_SUCCESS:
      return initialState

    case Types.OPEN_EDIT_MODAL_FAILURE:
      return { ...state, loading: false, error: action.payload.error }

    default:
      return state
  }
}

export default checkRequestOrigin(initialState, EditGroupModalReducer, requestDestination)
