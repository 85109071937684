import React from 'react'
import { states } from '@/legacy/components/v2/ribbons/states'
import ActivityRibbonBase from './activityRibbonBase'
import { activityRibbonMap } from '@/legacy/components/v2/ribbons/types'
export default function QuestionsRibbon(props) {
  const { questions } = activityRibbonMap

  return (
    <ActivityRibbonBase
      Icon={questions.icon}
      type={questions.type}
      calendarLabel="components.ribbon.student.deliveryDate"
      {...props}
    />
  )
}

QuestionsRibbon.defaultProps = {
  subject: {},
  state: states.DEFAULT,
  buttonEnabled: true,
  buttonLabel: 'generic.continue',
  buttonProps: {},
  messages: 0,
}
