import React, { useState } from 'react'
import { ClickAwayListener, Paper, Popper, Fade } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: theme.zIndex.tooltip,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-1em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent rgba(26, 26, 26, 0.9) transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-1em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `rgba(26, 26, 26, 0.9) transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-1em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent rgba(26, 26, 26, 0.9) transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-1em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent rgba(26, 26, 26, 0.9)`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    display: 'flex',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  popperPaper: {
    padding: '8px 12px',
    display: 'flex',
    flexWrap: 'nowrap',
    backgroundColor: 'rgba(26, 26, 26, 0.9)',
    color: '#FFF',
    borderColor: '8px',
    borderRadius: '8px',
    fontFamily: theme.typography.fontFamily,
  },
}))

const CustomPopper = ({ children, anchorEl, withArrow, setOpen, isOpen, placement, flip }) => {
  const classes = useStyles()

  const [arrowRef, setArrowRef] = useState(null)
  const [arrow] = useState(withArrow)

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorEl}
      placement={placement}
      transition
      className={classes.popper}
      modifiers={{
        arrow: {
          enabled: arrow,
          element: arrowRef,
        },
        flip: {
          enabled: flip,
        },
      }}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Fade {...TransitionProps} timeout={350}>
            <div>
              {arrow ? <span className={classes.arrow} ref={setArrowRef} /> : null}
              <Paper className={classes.popperPaper}>{children}</Paper>
            </div>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  )
}

export default CustomPopper

CustomPopper.defaultProps = {
  placement: 'bottom',
  withArrow: true,
  flip: true,
}
