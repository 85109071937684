import type { LocaleSpecification } from 'moment'
import moment from 'moment'

export const ru: LocaleSpecification = {
  months: [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
  ],
  monthsShort: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
  weekdays: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
  weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
  weekdaysMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
  longDateFormat: {
    LT: 'H:mm',
    LTS: 'H:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D MMMM YYYY г.',
    LLL: 'D MMMM YYYY г., H:mm',
    LLLL: 'dddd, D MMMM YYYY г., H:mm',
  },
  calendar: {
    sameDay: function () {
      return '[Сегодня в] LT'
    },
    nextDay: function () {
      return '[Завтра в] LT'
    },
    nextWeek: function () {
      return 'dddd [в] LT'
    },
    lastDay: function () {
      return '[Вчера в] LT'
    },
    lastWeek: function () {
      return 'dddd [в] LT'
    },
    sameElse: 'L',
  },
  relativeTime: {
    future: 'через %s',
    past: '%s назад',
    s: 'несколько секунд',
    m: 'минута',
    mm: '%d минут',
    h: 'час',
    hh: '%d часов',
    d: 'день',
    dd: '%d дней',
    M: 'месяц',
    MM: '%d месяцев',
    y: 'год',
    yy: '%d лет',
  },
  ordinal: function (n: number) {
    const lastDigit = n % 10
    const lastTwoDigits = n % 100

    if (lastDigit === 1 && lastTwoDigits !== 11) {
      return `${n}-й`
    } else if (
      (lastDigit === 2 || lastDigit === 3 || lastDigit === 4) &&
      (lastTwoDigits < 10 || lastTwoDigits > 20)
    ) {
      return `${n}-й`
    } else {
      return `${n}-я`
    }
  },
  week: {
    dow: 1,
    doy: 7,
  },
}

moment.locale('ru', ru)
