import ButtonBase from '@mui/material/ButtonBase'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { CircularProgress } from '@mui/material'
import type { Theme } from '@mui/material'

type StylesProps = {
  variant: ButtonVariant
  disabled: boolean
  loading: boolean
  fullWidth?: boolean
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  button: ({ fullWidth }) => ({
    cursor: 'pointer',
    width: fullWidth ? '100%' : 'initial',
  }),
  buttonSize: ({ fullWidth }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 100,
    width: fullWidth ? '100%' : 'initial',
    height: 40,
    borderRadius: 3,
    paddingRight: 16,
    paddingLeft: 16,
    '& span': {
      transition: 'all 300ms ease',
    },
  }),
  mainColor: {
    background: theme.palette.primary.main,
    border: '1px solid transparent',
    transition: 'all 300ms ease',
    '& span': {
      color: 'white',
    },
    '&:hover': {
      background: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  secondaryColor: {
    background: 'transparent',
    border: `1px solid ${theme.palette.primary.main}`,
    transition: 'all 300ms ease',
    '& span': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      background: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
      '& span': {
        color: 'white',
      },
    },
  },
  disabledColor: {
    background: '#DFDFDA',
    border: `1px solid #DFDFDA`,
    transition: 'all 300ms ease',
    '& span': {
      color: 'white',
    },
  },
  label: ({ loading }) => ({
    fontSize: '16px',
    fontFamily: 'DM Sans',
    fontWeight: 700,
    // textTransform: 'capitalize',
    opacity: loading ? 0 : 1,
  }),
  loadingIndicator: ({ variant, disabled, loading }) => ({
    color: variant === 'primary' || disabled ? 'white' : theme.palette.primary.main,
    position: 'absolute',
    opacity: !loading ? 0 : 1,
  }),
}))

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export interface ModalButtonProps {
  label: string
  variant?: ButtonVariant
  fullWidth?: boolean
  onClick: () => void
  disabled?: boolean
  loading?: boolean
}

const ModalActionButton: React.FC<ModalButtonProps> = ({
  label,
  variant = ButtonVariant.PRIMARY,
  disabled = false,
  loading = false,
  fullWidth = false,
  ...props
}) => {
  const S = useStyles({ variant, disabled, loading, fullWidth })
  return (
    <ButtonBase className={S.button} aria-label={label} disabled={disabled}>
      <div
        {...props}
        className={clsx(
          S.buttonSize,
          disabled ? S.disabledColor : variant === 'primary' ? S.mainColor : S.secondaryColor
        )}
      >
        <CircularProgress size={15} className={S.loadingIndicator} />
        <span className={S.label}>{label}</span>
      </div>
    </ButtonBase>
  )
}

export default ModalActionButton
