import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type { CreatedCompetenceType } from '@/sections/report-card/grading/types/entities'
import type { FindClassroomReportCardConfigSuccess } from '@/sections/report-card/grading/types/mainView'

export interface ReportCardGradingCompetenceScoresTabState {
  error: string
  loadingCompetenceScores: boolean
  studentCompetenceScores: StudentCompetenceScore[]
  competenceTypes: CreatedCompetenceType[]
}

export interface StudentCompetenceScore {
  studentId: number
  valueId: number
  value: string
  typeId: number
  type: string
}

export const GET_STUDENT_COMPETENCE_SCORES_REQUEST =
  'REPORT_CARD/GRADING/TABS/COMPETENCE_SCORES/GET_STUDENT_COMPETENCE_SCORES_REQUEST'
export const GET_STUDENT_COMPETENCE_SCORES_SUCCESS =
  'REPORT_CARD/GRADING/TABS/COMPETENCE_SCORES/GET_STUDENT_COMPETENCE_SCORES_SUCCESS'
export const GET_STUDENT_COMPETENCE_SCORES_FAILURE =
  'REPORT_CARD/GRADING/TABS/COMPETENCE_SCORES/GET_STUDENT_COMPETENCE_SCORES_FAILURE'

export type GetStudentCompetenceScoresRequest = GenericActionWithType<
  typeof GET_STUDENT_COMPETENCE_SCORES_REQUEST
>

export type GetStudentCompetenceScoresSuccess = GenericActionWithType<
  typeof GET_STUDENT_COMPETENCE_SCORES_SUCCESS,
  { studentCompetenceScores: StudentCompetenceScore[] }
>

export type GetStudentCompetenceScoresFailure = GenericActionWithType<
  typeof GET_STUDENT_COMPETENCE_SCORES_FAILURE,
  ErrorPayload
>

export type GetStudentCompetenceScores =
  | GetStudentCompetenceScoresRequest
  | GetStudentCompetenceScoresSuccess
  | GetStudentCompetenceScoresFailure

export type ReportCardGradingCompetenceScoresTabAction =
  | GetStudentCompetenceScores
  | FindClassroomReportCardConfigSuccess
