import { combineReducers } from 'redux'
import dashboard from './dashboard/reducers/courses'
import course from './dashboard/reducers/courseOverview'

const rootReducer = combineReducers({
  course,
  dashboard,
})

export default rootReducer
