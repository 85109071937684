import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from '@mui/material/styles'
import { useTheme } from '@aula/config'
import store, { persistor } from '@/state/store'
import { QueryClientProvider } from '@tanstack/react-query'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { queryClient } from '@/state/query'
import Head from './head'
import Router from './router'

// move to vite env
const clientId = '35003362827-orfemfi6jp83mpa7r125nqv6336pr3sv.apps.googleusercontent.com'

const App = () => {
  const theme = useTheme()
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={clientId}>
          <ThemeProvider theme={theme}>
            <Head />
            <PersistGate persistor={persistor}>
              <Router />
            </PersistGate>
          </ThemeProvider>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </Provider>
  )
}

export default App
