import type { ResittingStudent } from '@/api/report-card/resit'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type { Either } from '@/legacy/utils/types'
import type { SubjectSubitem } from '@/sections/report-card/config/types/entities'
import type { ScoreType } from '@/sections/report-card/types/common'
import type { ScoreSubject } from '@/sections/report-card/grading/types/entities'
import type { FindClassroomReportCardConfigSuccess } from '@/sections/report-card/grading/types/mainView'

export interface ReportCardGradingScoresTabState {
  error: string
  loadingScores: boolean
  loadingResittingStudents: boolean
  studentScores: StudentScore[]
  numericalSubjects: ScoreSubject[]
  conceptSubjects: ScoreSubject[]
  resittingStudents: ResittingStudent[]
}

type ExtraConceptFields = {
  valueId: number
  score: string
}

type ExtraNumericFields = {
  value: number
}

type ExtraSubitemFields = Either<ExtraConceptFields, ExtraNumericFields>

export type StudentScoreSubitem = SubjectSubitem & ExtraSubitemFields

export interface StudentScore {
  studentId: number
  subjectId: number
  subjectName: string
  score: number | string
  type: ScoreType
  valueId: number | null
  subitems?: StudentScoreSubitem[]
  observation: string
  resit: boolean
}

export const GET_STUDENT_SCORES_REQUEST =
  'REPORT_CARD/GRADING/TABS/SCORES/GET_STUDENT_SCORES_REQUEST'
export const GET_STUDENT_SCORES_SUCCESS =
  'REPORT_CARD/GRADING/TABS/SCORES/GET_STUDENT_SCORES_SUCCESS'
export const GET_STUDENT_SCORES_FAILURE =
  'REPORT_CARD/GRADING/TABS/SCORES/GET_STUDENT_SCORES_FAILURE'

export type GetStudentScoresRequest = GenericActionWithType<typeof GET_STUDENT_SCORES_REQUEST>

export type GetStudentScoresSuccess = GenericActionWithType<
  typeof GET_STUDENT_SCORES_SUCCESS,
  { studentScores: StudentScore[] }
>

export type GetStudentScoresFailure = GenericActionWithType<
  typeof GET_STUDENT_SCORES_FAILURE,
  ErrorPayload
>

export const GET_RESITTING_STUDENTS_REQUEST =
  'REPORT_CARD/GRADING/TABS/SCORES/GET_RESITTING_STUDENTS_REQUEST'
export const GET_RESITTING_STUDENTS_SUCCESS =
  'REPORT_CARD/GRADING/TABS/SCORES/GET_RESITTING_STUDENTS_SUCCESS'
export const GET_RESITTING_STUDENTS_FAILURE =
  'REPORT_CARD/GRADING/TABS/SCORES/GET_RESITTING_STUDENTS_FAILURE'

export type GetStudentScores =
  | GetStudentScoresRequest
  | GetStudentScoresSuccess
  | GetStudentScoresFailure

export type GetResittingStudentsRequest = GenericActionWithType<
  typeof GET_RESITTING_STUDENTS_REQUEST
>

export type GetResittingStudentsSuccess = GenericActionWithType<
  typeof GET_RESITTING_STUDENTS_SUCCESS,
  { resittingStudents: ResittingStudent[] }
>

export type GetResittingStudentsFailure = GenericActionWithType<
  typeof GET_RESITTING_STUDENTS_FAILURE,
  ErrorPayload
>

export type GetResittingStudents =
  | GetResittingStudentsRequest
  | GetResittingStudentsSuccess
  | GetResittingStudentsFailure

export type ReportCardGradingScoresTabAction =
  | GetStudentScores
  | GetResittingStudents
  | FindClassroomReportCardConfigSuccess
