import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ToggleButton, ToggleButtonGroup } from '@mui/lab'
import React from 'react'
import ViewStreamIcon from '@mui/icons-material/ViewStream'
import ViewListIcon from '@mui/icons-material/ViewList'
import {
  ACTIVITY_DISPLAY_TYPE_RIBBON,
  ACTIVITY_DISPLAY_TYPE_ROW,
} from '@/sections/display/types'
import LabeledIcon from './labeledIcon'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  viewPickerText: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    fontSize: 14,
    marginRight: 8,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  viewPickerButton: {
    border: 0,
    backgroundColor: 'transparent',
    color: '#C1C1BF',
  },
  selectedViewPickerButton: {
    backgroundColor: 'transparent !important',
    color: `${theme.palette.secondary.main} !important`,
  },
}))

const DisplayToggleGroup = ({ activityDisplayType, handleDisplayTypeClick }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const onChange = (val) => {
    if (val !== null) handleDisplayTypeClick(val)
  }

  return (
    <>
      <Typography className={classes.viewPickerText}>{t('teacher.dashboard.view')}:</Typography>
      <ToggleButtonGroup
        value={activityDisplayType}
        size="small"
        exclusive
        onChange={(_, val) => onChange(val)}
      >
        <ToggleButton
          classes={{ root: classes.viewPickerButton, selected: classes.selectedViewPickerButton }}
          value={ACTIVITY_DISPLAY_TYPE_RIBBON}
          aria-label="ribbon"
        >
          <LabeledIcon Icon={ViewStreamIcon} label={t('components.dashboard.slatView')} />
        </ToggleButton>
        <ToggleButton
          classes={{ root: classes.viewPickerButton, selected: classes.selectedViewPickerButton }}
          value={ACTIVITY_DISPLAY_TYPE_ROW}
          aria-label="row"
        >
          <LabeledIcon Icon={ViewListIcon} label={t('components.dashboard.tableView')} />
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  )
}

export default DisplayToggleGroup
