import type { ClearState } from '@/legacy/redux/types/generic'
import type { GenericAction, GenericActionWithType, GenericPayload } from '@/legacy/redux/utils'
import type { Scale } from '@/legacy/services/api/reportCard/config/types/get'
import type { CompetencesIds } from '@/sections/report-card/common/competences'
import type { ScoreType } from '@/sections/report-card/types/common'
import type { SaveOrEditReportCardConfigSuccess } from '@/sections/report-card/config/types/apiInteractions'
import type {
  CardId,
  ReportCardConfigAbsences,
  ReportCardConfigAttitudinalScores,
  ReportCardConfigCompetenceScores,
  ReportCardConfigRangeScore,
  ReportCardConfigReviews,
  ReportCardConfigScores,
} from '@/sections/report-card/config/types/entities'

export interface ReportCardConfigScoresState {
  scores: ReportCardConfigScores & { numericalOn: boolean; conceptOn: boolean }
  attitudinalScores: ReportCardConfigAttitudinalScores
  competenceScores: ReportCardConfigCompetenceScores
  reviews: ReportCardConfigReviews
  absences: ReportCardConfigAbsences
}

export type ScoreCategory = keyof ReportCardConfigScoresState | 'scales'

export const SET_SCORE_VALUES = 'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SCORES/SET_SCORE_VALUES'

interface SetScoreValuesPayload extends GenericPayload {
  scoreCategory: ScoreCategory
  values: ReportCardConfigRangeScore[]
  scaleId?: number
}

export interface SetScoreValues extends GenericAction {
  type: typeof SET_SCORE_VALUES
  payload: SetScoreValuesPayload
}

export const SET_CATEGORY_AVAILABLE =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SCORES/SET_CATEGORY_AVAILABLE'

interface SetCategoryAvailablePayload extends GenericPayload {
  scoreCategory: ScoreCategory
  available: boolean
}

export interface SetCategoryAvailable extends GenericAction {
  type: typeof SET_CATEGORY_AVAILABLE
  payload: SetCategoryAvailablePayload
}

export const SET_REVIEWS_BY_SUBJECT =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SCORES/SET_REVIEWS_BY_SUBJECT'

interface SetReviewsBySubjectPayload extends GenericPayload {
  bySubject: boolean
}

export interface SetReviewsBySubject extends GenericAction {
  type: typeof SET_REVIEWS_BY_SUBJECT
  payload: SetReviewsBySubjectPayload
}

export const SET_COMPETENCE_TYPES =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SCORES/SET_COMPETENCE_TYPES'

interface SetCompetenceTypesPayload extends GenericPayload {
  competenceTypes: CompetencesIds[]
}
export interface SetCompetenceTypes extends GenericAction {
  type: typeof SET_COMPETENCE_TYPES
  payload: SetCompetenceTypesPayload
}

export const SET_NUMERICAL_OR_CONCEPT_ON =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SCORES/SET_NUMERICAL_OR_CONCEPTUAL_ON'

export type SetNumericalOrConceptOn = GenericActionWithType<
  typeof SET_NUMERICAL_OR_CONCEPT_ON,
  { toggleType: ScoreType }
>

export const SET_CARD_NAME = 'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SCORES/SET_CARD_NAME'

export type SetCardName = GenericActionWithType<
  typeof SET_CARD_NAME,
  { cardId: CardId; newName: string }
>

export const SET_VALUES_FOR_EDITION =
  'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SCORES/SET_VALUES_FOR_EDITION'

interface SetValuesForEditionPayload extends GenericPayload {
  scoresState: ReportCardConfigScoresState
}

export type SetValuesForEdition = GenericActionWithType<
  typeof SET_VALUES_FOR_EDITION,
  SetValuesForEditionPayload
>

export const ADD_NEW_SCALE = 'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SCORES/ADD_NEW_SCALE'

export type AddNewScaleAction = GenericActionWithType<typeof ADD_NEW_SCALE, { newScale: Scale }>

export const REMOVE_SCALE = 'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SCORES/REMOVE_SCALE'

export type RemoveScaleAction = GenericActionWithType<typeof REMOVE_SCALE, { scaleId: number }>

export const SET_SCALE_NAME = 'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SCORES/SET_SCALE_NAME'

export type SetScaleNameAction = GenericActionWithType<
  typeof SET_SCALE_NAME,
  { scaleId: number; newName: string }
>

export const REMOVE_SCALE_VALUE = 'REPORT_CARD/CONFIG/STEPPER_MODAL_STEPS/SCORES/REMOVE_SCALE_VALUE'
export type RemoveScaleValueAction = GenericActionWithType<
  typeof REMOVE_SCALE_VALUE,
  { scaleId: number; indexOfValueToDelete: number }
>

export type ReportCardConfigScoresAction =
  | SetScoreValues
  | SetCategoryAvailable
  | SetNumericalOrConceptOn
  | SetReviewsBySubject
  | SetCompetenceTypes
  | SaveOrEditReportCardConfigSuccess
  | ClearState
  | SetCardName
  | SetValuesForEdition
  | AddNewScaleAction
  | RemoveScaleAction
  | SetScaleNameAction
  | RemoveScaleValueAction
