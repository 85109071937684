import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type {
  PendingSubjectActions,
  PendingSubjectState,
} from '@/sections/report-card/grading/types/tabs/pendingSubjects'
import {
  PENDING_SUBJECTS_MODAL_STEPS,
  PENDING_SUBJECT_STATE,
} from '@/sections/report-card/grading/types/tabs/pendingSubjects'

const initialState: PendingSubjectState = {
  status: REQUEST_STATUS.IDLE,
  error: '',
  searchText: '',
  pendingSubjects: [],
  pagination: {
    more: false,
    offset: 0,
    limit: 10,
  },
  modal: {
    isOpen: false,
    isEditMode: false,
    status: REQUEST_STATUS.IDLE,
    createEditStatus: REQUEST_STATUS.IDLE,
    currentStep: 0,
    students: {
      list: [],
      selectedStudents: [],
    },
    academic: {
      list: [],
      selectedPeriod: 0,
      selectedYear: '',
    },
    subjects: {
      list: [],
      selectedSubject: 0,
    },
    error: '',
  },
  deleteModal: {
    isOpen: false,
    status: REQUEST_STATUS.IDLE,
    error: '',
  },
  selectedSubject: {
    academicPeriod: '',
    academicPeriodId: 0,
    id: 0,
    organizationId: 0,
    student: { avatar: '', id: 0, name: '', lastName: '' },
    subjectId: 0,
    subjectName: '',
    year: '',
  },
}

const ReportCardGradingPendingSubjectsTab = (
  state: PendingSubjectState = initialState,
  action: PendingSubjectActions
): PendingSubjectState => {
  switch (action.type) {
    case PENDING_SUBJECT_STATE.GET_PENDING_SUBJECT_REQUEST: {
      return { ...state, status: REQUEST_STATUS.LOADING }
    }
    case PENDING_SUBJECT_STATE.GET_PENDING_SUBJECT_SUCCESS: {
      const { pendingSubjects, more, page } = action.payload
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        pendingSubjects,
        pagination: { ...state.pagination, more, offset: page },
      }
    }
    case PENDING_SUBJECT_STATE.GET_PENDING_SUBJECT_FAILURE: {
      const { error } = action.payload
      return { ...state, status: REQUEST_STATUS.FAILURE, error }
    }
    case PENDING_SUBJECT_STATE.SET_SEARCH_TEXT: {
      const { searchText } = action.payload
      return { ...state, searchText }
    }
    case PENDING_SUBJECT_STATE.SET_OPEN_MODAL: {
      if (state.modal.isEditMode) {
        return {
          ...state,
          modal: { ...state.modal, isOpen: true, currentStep: PENDING_SUBJECTS_MODAL_STEPS.LAST },
        }
      }
      return { ...state, modal: { ...state.modal, isOpen: true } }
    }
    case PENDING_SUBJECT_STATE.SET_CLOSE_MODAL: {
      return { ...state, modal: { ...state.modal, isOpen: false } }
    }
    case PENDING_SUBJECT_STATE.SET_OPEN_DELETE_MODAL: {
      return { ...state, deleteModal: { ...state.deleteModal, isOpen: true } }
    }
    case PENDING_SUBJECT_STATE.SET_CLOSE_DELETE_MODAL: {
      return { ...state, deleteModal: { ...state.deleteModal, isOpen: false } }
    }
    case PENDING_SUBJECT_STATE.SET_CURRENT_STEP: {
      const { currentStep } = action.payload
      return { ...state, modal: { ...state.modal, currentStep } }
    }
    case PENDING_SUBJECT_STATE.GET_STUDENTS_LIST_REQUEST: {
      return { ...state, modal: { ...state.modal, status: REQUEST_STATUS.LOADING } }
    }
    case PENDING_SUBJECT_STATE.GET_STUDENTS_LIST_SUCCESS: {
      const { students } = action.payload
      return {
        ...state,
        modal: {
          ...state.modal,
          students: { ...state.modal.students, list: students },
          status: REQUEST_STATUS.SUCCESS,
        },
      }
    }
    case PENDING_SUBJECT_STATE.GET_STUDENTS_LIST_FAILURE: {
      const { error } = action.payload
      return { ...state, modal: { ...state.modal, status: REQUEST_STATUS.FAILURE, error } }
    }
    case PENDING_SUBJECT_STATE.SET_SELECTED_STUDENT: {
      const { student } = action.payload
      const alreadyAdded = state.modal.students.selectedStudents.some((st) => st.id === student.id)
      if (alreadyAdded) {
        return {
          ...state,
          modal: {
            ...state.modal,
            students: {
              ...state.modal.students,
              selectedStudents: [
                ...state.modal.students.selectedStudents.filter((st) => st.id !== student.id),
              ],
            },
          },
        }
      }
      return {
        ...state,
        modal: {
          ...state.modal,
          students: {
            ...state.modal.students,
            selectedStudents: [...state.modal.students.selectedStudents, student],
          },
        },
      }
    }
    case PENDING_SUBJECT_STATE.SET_ACADEMIC_PERIOD: {
      const { academicPeriods } = action.payload
      return {
        ...state,
        modal: { ...state.modal, academic: { ...state.modal.academic, list: academicPeriods } },
      }
    }
    case PENDING_SUBJECT_STATE.GET_SUBJECTS_LIST_REQUEST:
      return { ...state, modal: { ...state.modal, status: REQUEST_STATUS.LOADING } }
    case PENDING_SUBJECT_STATE.GET_SUBJECTS_LIST_SUCCESS: {
      const { subjects } = action.payload
      return {
        ...state,
        modal: {
          ...state.modal,
          status: REQUEST_STATUS.SUCCESS,
          subjects: { ...state.modal.subjects, list: subjects },
        },
      }
    }
    case PENDING_SUBJECT_STATE.GET_SUBJECTS_LIST_FAILURE: {
      const { error } = action.payload
      return { ...state, modal: { ...state.modal, status: REQUEST_STATUS.FAILURE, error } }
    }
    case PENDING_SUBJECT_STATE.SET_PERIOD_SELECTED: {
      const { period } = action.payload
      return {
        ...state,
        modal: { ...state.modal, academic: { ...state.modal.academic, selectedPeriod: period } },
      }
    }
    case PENDING_SUBJECT_STATE.SET_YEAR_SELECTED: {
      const { year } = action.payload
      return {
        ...state,
        modal: { ...state.modal, academic: { ...state.modal.academic, selectedYear: year } },
      }
    }
    case PENDING_SUBJECT_STATE.SET_SUBJECT_SELECTED: {
      const { subject } = action.payload
      return {
        ...state,
        modal: { ...state.modal, subjects: { ...state.modal.subjects, selectedSubject: subject } },
      }
    }
    case PENDING_SUBJECT_STATE.CREATE_PENDING_SUBJECT_REQUEST: {
      return { ...state, modal: { ...state.modal, createEditStatus: REQUEST_STATUS.LOADING } }
    }
    case PENDING_SUBJECT_STATE.CREATE_PENDING_SUBJECT_SUCCESS: {
      return { ...state, modal: { ...state.modal, createEditStatus: REQUEST_STATUS.SUCCESS } }
    }
    case PENDING_SUBJECT_STATE.CREATE_PENDING_SUBJECT_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        modal: { ...state.modal, error, createEditStatus: REQUEST_STATUS.FAILURE },
      }
    }
    case PENDING_SUBJECT_STATE.EDIT_PENDING_SUBJECT_REQUEST: {
      return { ...state, modal: { ...state.modal, createEditStatus: REQUEST_STATUS.LOADING } }
    }
    case PENDING_SUBJECT_STATE.EDIT_PENDING_SUBJECT_SUCCESS: {
      return { ...state, modal: { ...state.modal, createEditStatus: REQUEST_STATUS.SUCCESS } }
    }
    case PENDING_SUBJECT_STATE.EDIT_PENDING_SUBJECT_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        modal: { ...state.modal, error, createEditStatus: REQUEST_STATUS.FAILURE },
      }
    }
    case PENDING_SUBJECT_STATE.DELETE_PENDING_SUBJECT_REQUEST: {
      return { ...state, deleteModal: { ...state.deleteModal, status: REQUEST_STATUS.LOADING } }
    }
    case PENDING_SUBJECT_STATE.DELETE_PENDING_SUBJECT_SUCCESS: {
      return {
        ...state,
        deleteModal: { ...state.deleteModal, status: REQUEST_STATUS.SUCCESS, isOpen: false },
      }
    }
    case PENDING_SUBJECT_STATE.DELETE_PENDING_SUBJECT_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        deleteModal: { ...state.deleteModal, status: REQUEST_STATUS.FAILURE, error },
      }
    }
    case PENDING_SUBJECT_STATE.SET_SELECTED_SUBJECT: {
      const { selectedSubject, isEditMode } = action.payload
      if (!isEditMode) {
        return { ...state, selectedSubject, modal: { ...state.modal, isEditMode } }
      }
      return {
        ...state,
        selectedSubject,
        modal: {
          ...state.modal,
          isEditMode,
          academic: {
            ...state.modal.academic,
            selectedPeriod: selectedSubject.academicPeriodId,
            selectedYear: selectedSubject.year,
          },
          subjects: {
            ...state.modal.subjects,
            selectedSubject: selectedSubject.subjectId,
          },
        },
      }
    }
    case PENDING_SUBJECT_STATE.CLEAR_MODAL_STATE:
      return { ...state, modal: { ...initialState.modal } }
    default:
      return state
  }
}

export default ReportCardGradingPendingSubjectsTab
