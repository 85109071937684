import type { AxiosResponse } from 'axios'
import { cabaGamesUrl } from '@aula/config'
import { apiExternal } from './..'
import type {
  ManagementExam,
  ExamParams,
  EditExamParams,
  CreateExamParam,
} from '@/sections/eb-admin/management/types/examsDrawer'
import type { DescriptionResponseShortener } from '@/api/types'
import type { ThemeOptions } from '@/sections/management/types/addEditExamModal'
import type { Game, GetGamesParams } from '@/sections/management/types/customizeThemeModal'
import snakecaseKeys from 'snakecase-keys'
import { GetThemesParams } from '@/sections/eb-evaluator/management/types/themesDrawer'
import { CreateThemeParams } from '@/sections/eb-evaluator/management/types/addEditThemeModal'

type GetAsAdminResponse = {
  exams: ManagementExam[]
  rows: ManagementExam[]
  total: number
}

type GetExamsThemesResponse = {
  rows: ThemeOptions[]
  total: number
}

type GetGamesResponse = {
  rows: Game[]
  total: number
}

function exportExams(params: ExamParams): void {
  const paramArray: string[] = []
  for (const [key, value] of Object.entries(params)) {
    paramArray.push(`${key}=${value}`)
  }

  window.open(cabaGamesUrl + '/classrooms/export?' + paramArray.join('&'), '_blank')
}

function exportMetrics(): void {
  window.open(cabaGamesUrl + '/metrics/export', '_blank')
}

function exportMetricsCSV(stages?: string): void {
  window.open(
    `${cabaGamesUrl}/metrics/exportCSVStorage${stages ? `?stage=${stages}` : ''}`,
    '_blank'
  )
}

function getAsAdmin(args: ExamParams): Promise<GetAsAdminResponse> {
  const params = snakecaseKeys(args, { deep: true })
  return apiExternal
    .get(cabaGamesUrl + '/classrooms', {
      params,
    })
    .then((resp: AxiosResponse<GetAsAdminResponse>) => {
      return {
        ...resp.data,
        exams: resp.data.rows,
        total: resp.data.total,
      }
    })
    .catch((err) => {
      throw err
    })
}

function createAsAdmin(params: CreateExamParam): Promise<void> {
  return apiExternal
    .post(cabaGamesUrl + '/classrooms', params)
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

function editAsAdmin(params: EditExamParams): Promise<void> {
  return apiExternal
    .put(cabaGamesUrl + '/classrooms', params)
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

function remove(examId: string): Promise<void> {
  return apiExternal
    .delete(cabaGamesUrl + '/classrooms/' + examId)
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

function getExamsThemes(params?: GetThemesParams): Promise<GetExamsThemesResponse> {
  return apiExternal
    .get(cabaGamesUrl + '/metrics/themes', { params })
    .then((resp: AxiosResponse<GetExamsThemesResponse>) => {
      return resp.data
    })
    .catch((err) => {
      throw err
    })
}

function getGames(params: GetGamesParams): Promise<GetGamesResponse> {
  return apiExternal
    .get(cabaGamesUrl + '/games', { params })
    .then((response: AxiosResponse) => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}

function deleteTheme(id: number): Promise<void> {
  return apiExternal.delete(cabaGamesUrl + `/metrics/themes/${id}`)
}

function createTheme(params: CreateThemeParams): Promise<void> {
  return apiExternal
    .post(cabaGamesUrl + '/metrics/themes', params)
    .then((resp: AxiosResponse<DescriptionResponseShortener<void>>) => {
      return resp.data.description
    })
}

function editTheme(id: number, params: CreateThemeParams): Promise<void> {
  return apiExternal
    .put(cabaGamesUrl + `/metrics/themes/${id}`, params)
    .then((resp: AxiosResponse<DescriptionResponseShortener<void>>) => {
      return resp.data.description
    })
}


export const ExamsAPI = {
  exportExams,
  exportMetrics,
  exportMetricsCSV,
  getAsAdmin,
  createAsAdmin,
  editAsAdmin,
  remove,
  getExamsThemes,
  getGames,
  deleteTheme,
  createTheme,
  editTheme,
}
