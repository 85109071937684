import { combineReducers } from 'redux'
import activities from './reducers/activities'
import create from './reducers/create'
import deleteReducer from './reducers/delete'
import createExtraInfo from './reducers/createExtraInfo'
import collabMiniatureAssign from './reducers/collabMiniatureAssign'
import AudienceModalReducer from './reducers/audienceModal'

const rootReducer = combineReducers({
  activities,
  create,
  createExtraInfo,
  delete: deleteReducer,
  collabMiniatureAssign,
  audienceModal: AudienceModalReducer,
})

export default rootReducer
