import type {
  MessageCloningActions,
  MessageCloningState,
} from '@/sections/teacher/bulletin/types/messageCloning'
import { MESSAGE_CLONING_TYPES } from '@/sections/teacher/bulletin/types/messageCloning'

const initialState: MessageCloningState = {
  classroomIdForCloning: 0,
  classroomsForCloning: [],
  loading: false,
  error: null,
}

const messageCloningReducer = (
  state: MessageCloningState = initialState,
  action: MessageCloningActions
): MessageCloningState => {
  switch (action.type) {
    case MESSAGE_CLONING_TYPES.GET_CLASSROOMS_FOR_CLONING_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }
    case MESSAGE_CLONING_TYPES.GET_CLASSROOMS_FOR_CLONING_SUCCESS: {
      const { classrooms, defaultClassroomId } = action.payload

      return {
        ...state,
        classroomIdForCloning: defaultClassroomId,
        loading: false,
        classroomsForCloning: classrooms,
      }
    }
    case MESSAGE_CLONING_TYPES.GET_CLASSROOMS_FOR_CLONING_FAILURE: {
      const { error } = action.payload

      return {
        ...state,
        classroomsForCloning: [],
        loading: false,
        error,
      }
    }
    case MESSAGE_CLONING_TYPES.SET_CLASSROOM_ID_FOR_CLONING: {
      const { classroomId } = action.payload

      return {
        ...state,
        classroomIdForCloning: classroomId,
      }
    }
    default: {
      return state
    }
  }
}

export default messageCloningReducer
