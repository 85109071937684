import React from 'react'
import {
  Typography,
  ButtonGroup,
  IconButton,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import EditIcon from '@/legacy/components/v1/svgs/multipleChoice/edit'
import DeleteIcon from '@/legacy/components/v1/svgs/multipleChoice/delete'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import CheckIcon from '@mui/icons-material/Check'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import clsx from 'clsx'
import QUESTIONS_TYPES from '@/legacy/components/v1/types/editorConstants'
import TextArea from '@/legacy/components/v1/atomic/v1/textArea'

const useStyles = makeStyles((theme) => ({
  questionContainer: {
    border: '1px solid #D8DBE8',
    borderRadius: '16px',
    padding: '24px',
  },
  isActive: {
    borderColor: '#4F36D6',
  },
  titleContainer: {
    display: 'flex',
  },
  title: {
    flex: 1,
    fontSize: '18px',
    fontWeight: '500',
  },
  button: {
    padding: '0 0 0 20px',
    minWidth: '20px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  radio: {
    padding: '8px 12px 8px 9px',
    border: '1px solid #D8DBE8',
    width: '30px',
    height: '30px',
    boxSizing: 'border-box',
    borderRadius: '50%',
  },
  checkedRadio: {
    color: '#D8DBE8',
    width: '30px',
    height: '30px',
    padding: 0,
  },
  checkedActiveRadio: {
    color: '#4F36D6',
  },
  textAreaContainer: {
    marginTop: 16,
  },
}))

const useStylesV2 = makeStyles((theme) => ({
  questionContainer: {
    borderBottom: '1px solid #D8DBE8',
    padding: '24px 0 15px',
    margin: '0 24px',
  },
  isActive: {
    borderColor: '#D8DBE8',
  },
  titleContainer: {
    display: 'flex',
  },
  title: {
    flex: 1,
    fontSize: '16px',
    lineHeight: '21px',
    marginBottom: 5,
    fontWeight: '500',
  },
  button: {
    padding: '0 0 0 20px',
    minWidth: '20px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  radio: {
    padding: '8px 12px 8px 9px',
    border: '1px solid #D8DBE8',
    width: '30px',
    height: '30px',
    boxSizing: 'border-box',
    borderRadius: '50%',
  },
  checkedRadio: {
    color: '#D8DBE8',
    width: '30px',
    height: '30px',
    padding: 5,
    borderColor: '#4F36D6',
  },
  checkedActiveRadio: {
    color: '#4F36D6',
  },
  textAreaContainer: {
    marginTop: 16,
    marginBottom: 10,
  },
}))

export const QUESTION_STATUS = {
  EDIT: 'edit',
  BLOCKED: 'blocked',
  ANSWER: 'answer',
}

const getOptionName = (text, idx) => {
  return `${String.fromCharCode(65 + idx)}. ${text}`
}

const useStylesVersion = (version) => {
  const classesV2 = useStylesV2()
  const classes = useStyles()
  return version === 'V2' ? classesV2 : classes
}

const QuestionCard = (props) => {
  const {
    type,
    title,
    onDelete,
    onEdit,
    onMoveUp,
    onMoveDown,
    isActive,
    status,
    isFirst,
    isLast,
    version,
  } = props
  const classes = useStylesVersion(version)

  const isEdit = status === QUESTION_STATUS.EDIT

  const renderInput = () => {
    switch (type) {
      case QUESTIONS_TYPES.WRITING:
        return <WrittenQuestion {...props} />
      case QUESTIONS_TYPES.CHOICE:
      default:
        return <MultipleChoice {...props} />
    }
  }

  return (
    <div className={clsx(classes.questionContainer, { [classes.isActive]: isActive })}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>{title}</Typography>
        {isEdit ? (
          <ButtonGroup disableRipple className={classes.buttonContainer}>
            <IconButton className={classes.button} onClick={onEdit}>
              <EditIcon />
            </IconButton>
            <IconButton className={classes.button} onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
            {!isFirst && (
              <IconButton className={classes.button} onClick={onMoveUp}>
                <ArrowDropUpIcon />
              </IconButton>
            )}
            {!isLast && (
              <IconButton className={classes.button} onClick={onMoveDown}>
                <ArrowDropDownIcon />
              </IconButton>
            )}
          </ButtonGroup>
        ) : null}
      </div>
      {renderInput()}
    </div>
  )
}

export default QuestionCard

QuestionCard.defaultProps = {
  version: 'V1',
}

const MultipleChoice = (props) => {
  const { options, onChange, isActive, selected, status, version } = props
  const classes = useStylesVersion(version)

  const isEdit = status === QUESTION_STATUS.EDIT
  const isBlocked = status === QUESTION_STATUS.BLOCKED

  const CheckIconV1 = () => (
    <CheckCircleIcon
      className={clsx(classes.radio, classes.checkedRadio, {
        [classes.checkedActiveRadio]: isActive,
      })}
    />
  )
  const CheckIconV2 = () => (
    <CheckIcon
      className={clsx(classes.radio, classes.checkedRadio, {
        [classes.checkedActiveRadio]: isActive,
      })}
    />
  )

  return (
    <FormControl component="fieldset">
      <RadioGroup aria-label="options" name="options">
        {options.map((option, idx) => (
          <FormControlLabel
            key={idx}
            disabled={isBlocked}
            label={version === 'V2' ? option.text : getOptionName(option.text, idx)}
            value={option.id}
            id={`question-option-${option.id}`}
            control={
              <Radio
                checkedIcon={version === 'V2' ? <CheckIconV2 /> : <CheckIconV1 />}
                onChange={status !== QUESTION_STATUS.BLOCKED ? () => onChange(option.id) : null}
                checked={selected === option.id || (isEdit && option.correct)}
                icon={<span className={classes.radio} />}
              />
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

const WrittenQuestion = (props) => {
  const { onChange, status, value, version } = props

  const classes = useStylesVersion(version)

  const isEdit = status === QUESTION_STATUS.EDIT
  const isBlocked = status === QUESTION_STATUS.BLOCKED

  const readOnly = isEdit || isBlocked

  return (
    <div className={classes.textAreaContainer}>
      <TextArea
        disabled={readOnly}
        rowsMin={6}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  )
}
