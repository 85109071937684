import { attendancesUrl } from '@aula/config'
import type { AxiosResponse } from 'axios'
import type { Moment } from 'moment'
import moment from 'moment'
import { apiPrivate } from '..'
import type { SubjectXDay } from '@/sections/attendances/config/types/entities'
import type { DescriptionResponseShortener } from '@/api/types'

export const AttendancesConfigAPI = {
  save,
  update,
  get,
  getOrganizationConfigs,
  getClassroomConfig,
}

export enum AttendancesDay {
  SUNDAY = 1,
  MONDAY = 2,
  TUESDAY = 3,
  WEDNESDAY = 4,
  THURSDAY = 5,
  FRIDAY = 6,
  SATURDAY = 7,
}
export type DayOff = { date: Moment; reason: string }
export type AttendanceStatus = {
  id?: number
  description: string
  abbreviation: string
  color: string
  value: number
}
export type AttendancesConfig = {
  id: number
  classroomId: number
  days: SubjectXDay[]
  absences: DayOff[] // Feriados
  statuses: AttendanceStatus[]
  defaultStatus?: AttendanceStatus // The status with value = 0 (should be one and only one)
  generalAttendances: boolean
  attendancesBySubject: boolean
}

const parseConfig = (config: AttendancesConfig): AttendancesConfig => ({
  ...config,
  absences: config.absences.map((absence) => ({ ...absence, date: moment(absence.date) })),
})

/**
 * Save attendance configuration for given classrooms.
 * @param classroomIds
 * @param organizationId
 * @param days
 * @param absences
 * @param statuses
 * @param generalAttendances
 * @param attendancesBySubject
 * @returns null
 */

function save(
  classroomIds: number[],
  organizationId: number,
  subjectsXDays: SubjectXDay[],
  absences: DayOff[],
  statuses: AttendanceStatus[],
  generalAttendances: boolean,
  attendancesBySubject: boolean
): Promise<null> {
  const payload = {
    classroomIds,
    organizationId,
    days: subjectsXDays,
    absences: absences.map((absence) => ({ ...absence, date: absence.date.format() })),
    statuses,
    generalAttendances,
    attendancesBySubject,
  }

  return apiPrivate.post(attendancesUrl + '/v2/configuration', payload)
}

/**
 * Update attendance configuration for given classrooms.
 * @param classroomIds
 * @param organizationId
 * @param days
 * @param dayOffs
 * @param statuses
 * @param attendanceConfigId
 * @param generalAttendances
 * @param attendancesBySubject
 * @returns null
 */
function update(
  classroomIds: number[],
  organizationId: number,
  subjectsXDays: SubjectXDay[],
  dayOffs: DayOff[],
  statuses: AttendanceStatus[],
  attendanceConfigId: number,
  generalAttendances: boolean,
  attendancesBySubject: boolean
): Promise<null> {
  const payload = {
    classroomId: classroomIds[0],
    organizationId,
    days: subjectsXDays,
    // absences will sometimes bring strings instead of Moments, so we convert them just in case
    absences: dayOffs.map((absence) => ({ ...absence, date: moment(absence.date).format() })),
    statuses,
    generalAttendances,
    attendancesBySubject,
  }

  return apiPrivate.put(attendancesUrl + `/v2/configuration/${attendanceConfigId}`, payload)
}

/**
 * Get a given attendance configuration.
 * @param attendanceConfigId
 */
function get(attendanceConfigId: number): Promise<AttendancesConfig> {
  return apiPrivate
    .get(attendancesUrl + `/v2/configuration/${attendanceConfigId}`)
    .then((resp: AxiosResponse<DescriptionResponseShortener<AttendancesConfig>>) => {
      return parseConfig(resp.data.description)
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Get already configured attendance configs for a given organization.
 * @param organizationId
 */

function getOrganizationConfigs(organizationId: number): Promise<AttendancesConfig[]> {
  const params = {
    organizationId,
  }

  return apiPrivate
    .get(attendancesUrl + `/v2/configuration`, { params })
    .then((response: AxiosResponse<AttendancesConfig[]>) => {
      return response.data.map((config) => parseConfig(config))
    })
    .catch((err) => {
      throw err
    })
}

interface ApiAttendanceStatus {
  id?: number
  description: string
  abbreviation: string
  color: string
  value: string
}
interface ApiAttendancesConfig {
  statuses: ApiAttendanceStatus[]
  defaultStatus?: ApiAttendanceStatus
  absences: DayOff[] // Feriados
  id: number
  classroomId: number
  days: SubjectXDay[]
  generalAttendances: boolean
  attendancesBySubject: boolean
}

/**
 * Get already configured attendance configs for a given organization.
 * @param organizationId
 */

function getClassroomConfig(classroomId: number): Promise<AttendancesConfig> {
  return apiPrivate
    .get<ApiAttendancesConfig>(attendancesUrl + `/v2/classrooms/${classroomId}/configuration`)
    .then((resp) => {
      const statuses = resp.data.statuses.map((status) => {
        return { ...status, value: parseFloat(status.value) }
      })

      return {
        ...resp.data,
        dayOffs: resp.data.absences.map((abs) => ({ ...abs, date: moment(abs.date) })),
        statuses,
        defaultStatus: statuses.find((s) => s.value === 0),
      }
    })
    .catch((err) => {
      throw err
    })
}
