import moment from 'moment'
import type { Reducer } from 'redux'
import { ManagementGender } from '@/api/lms/managementUsers'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { DEFAULT_DOC_TYPE } from '@/legacy/redux/types/docTypesOptions'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import { getManagementGender } from '@/legacy/utils/getManagementGender'
import { formatMomentOrDash } from '@/legacy/utils/text'
import type {
  UserDetailsModalAction,
  UserDetailsModalState,
} from '@/sections/management/types/users/userDetailsModal'
import {
  OPEN_AND_POPULATE_MODAL_FAILURE,
  OPEN_AND_POPULATE_MODAL_REQUEST,
  OPEN_AND_POPULATE_MODAL_SUCCESS,
} from '@/sections/management/types/users/userDetailsModal'
import { parseApiAssignmentsToLocal } from '@/sections/management/utils/users/parseAssignments'

const initialState: UserDetailsModalState = {
  open: false,
  loading: false,
  error: '',
  name: '',
  lastName: '',
  document: {
    ...DEFAULT_DOC_TYPE,
    value: '',
    instanceId: 0,
  },
  email: '',
  birthday: '',
  phone: '',
  ppi: false,
  gender: ManagementGender.OTHER,
  assignmentsList: [],
  relationshipAssignments: [],
  studentRestrictions: [],
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_USER_DETAILS_MODAL

const UserDetailsModalReducer: Reducer<UserDetailsModalState, UserDetailsModalAction> = (
  state = initialState,
  action
): UserDetailsModalState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState

    case OPEN_AND_POPULATE_MODAL_REQUEST:
      return { ...state, loading: true, open: true }

    case OPEN_AND_POPULATE_MODAL_SUCCESS: {
      const {
        name,
        lastName,
        identification,
        emailIdentification,
        gender,
        dateOfBirth,
        phone,
        ppi,
        assignments,
        relationshipAssignments,
        restrictions,
      } = action.payload.user

      const newAssignments = parseApiAssignmentsToLocal(assignments)

      return {
        ...state,
        loading: false,
        name,
        lastName,
        document: {
          ...action.payload.docType,
          value: identification?.value || '',
          instanceId: 0,
        },
        email: emailIdentification?.value || '',
        birthday: formatMomentOrDash(moment(dateOfBirth), 'D [de] MMM [de] YYYY'),
        phone,
        ppi,
        gender: getManagementGender(gender),
        assignmentsList: newAssignments,
        relationshipAssignments: relationshipAssignments,
        studentRestrictions: restrictions,
      }
    }
    case OPEN_AND_POPULATE_MODAL_FAILURE:
      return { ...state, loading: false, error: action.payload.error }

    default:
      return state
  }
}

export default checkRequestOrigin(initialState, UserDetailsModalReducer, requestDestination)
