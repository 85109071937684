import * as React from 'react'
import type { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M15.833 7.501 12.5 4.168" stroke="#4F36D6" strokeWidth={2} strokeMiterlimit={10} />
    <path
      d="m5.833 17.501-4.167.834.833-4.167L15 1.668l3.334 3.333-12.5 12.5Z"
      stroke="#4F36D6"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </svg>
)

export default SvgComponent
