import type { CancelTokenSource } from 'axios'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { GenericActionWithType } from '@/legacy/redux/utils'
import type { Video, VideoAuthorization } from '@/legacy/services/api/ted/videos/types'

export type VideosState = {
  videos: VideoCustom[]
  pagination: PagesMore
  filters: VideoFilters
  loadingVideos: boolean
  loadingRejectVideo: boolean
  loadingApproveVideo: boolean
  error: Error | null
}

export type PagesMore = {
  page: number
  limit: number
  more: boolean
}

export type VideoFilters = {
  completed: boolean
  search?: string
}

export enum VIDEO_STATE {
  PREVIEW = 'PREVIEW',
  UPLOADED = 'UPLOADED',
}

export type SetAuthField = (
  id: number,
  field: keyof VideoAuthForm,
  value: VideoAuthForm[keyof VideoAuthForm]
) => void

export type VideoAuthForm = {
  speakers: string[]
  tags: string[]
  title: string
  name: string
  description: string
  authorization: VideoAuthorization
}

export interface VideoCustomBase extends Video {
  status: REQUEST_STATUS
  deleted: boolean
  deleting: boolean
  updating: boolean
  uploading: boolean
  toggleVideoDetails: boolean
  cancelToken: CancelTokenSource | null
}

export interface VideoCustomPreview extends VideoCustomBase {
  state: VIDEO_STATE.PREVIEW
  file: File
}

export interface VideoCustomUploaded extends VideoCustomBase {
  state: VIDEO_STATE.UPLOADED
  file: null
}

export type VideoCustom = VideoCustomPreview | VideoCustomUploaded

export type TableElement = JSX.Element[] | JSX.Element | Element | Element[]

export type TableColumns = {
  id: string
  label?: string
  maxWidth?: string | number
  validateHeader?: TableElement
  customHeader?: TableElement
  sort?: boolean
}

export type TableDisplay = {
  id: string | number
  open: boolean
}

export type TableExpandableRows = {
  key?: string | number
  label?: string
}

// RESTORE VIDEOS STATE
export const CLEAR_VIDEOS = 'TED/VIDEOS/CLEAR_VIDEOS'

// CREATE VIDEO
export const CREATE_VIDEO_REQUEST = 'TED/VIDEOS/CREATE_VIDEO_REQUEST'
export const CREATE_VIDEO_RETRY_REQUEST = 'TED/VIDEOS/CREATE_VIDEO_RETRY_REQUEST'
export const CREATE_VIDEO_SUCCESS = 'TED/VIDEOS/CREATE_VIDEO_SUCCESS'
export const CREATE_VIDEO_FAILURE = 'TED/VIDEOS/CREATE_VIDEO_FAILURE'

// GET ALL VIDEOS
export const GET_VIDEOS_REQUEST = 'TED/VIDEOS/GET_VIDEOS_REQUEST'
export const GET_VIDEOS_SUCCESS = 'TED/VIDEOS/GET_VIDEOS_SUCCESS'
export const GET_VIDEOS_FAILURE = 'TED/VIDEOS/GET_VIDEOS_FAILURE'

// UPDATE VIDEO
export const UPDATE_VIDEO_REQUEST = 'TED/VIDEOS/UPDATE_VIDEO_REQUEST'
export const UPDATE_VIDEO_SUCCESS = 'TED/VIDEOS/UPDATE_VIDEO_SUCCESS'
export const UPDATE_VIDEO_FAILURE = 'TED/VIDEOS/UPDATE_VIDEO_FAILURE'

// DELETE ANY VIDEO
export const DELETE_VIDEO_REQUEST = 'TED/VIDEOS/DELETE_VIDEO_REQUEST'
export const DELETE_VIDEO_SUCCESS = 'TED/VIDEOS/DELETE_VIDEO_SUCCESS'
export const DELETE_VIDEO_FAILURE = 'TED/VIDEOS/DELETE_VIDEO_FAILURE'

// VIDEO DETAILS
export const SET_VIDEO_AUTH_FIELD = 'TED/VIDEOS/DETAILS/SET_VIDEO_AUTH_FIELD'
export const TOGGLE_VIDEO_DETAILS = 'TED/VIDEOS/DETAILS/TOGGLE_VIDEO_DETAILS'

// VIDEO FILTERS
export const SET_VIDEO_FILTER = 'TED/VIDEOS/FILTERS/SET_VIDEO_FILTER'

// APPROVE VIDEO
export const APPROVE_VIDEO_REQUEST = 'TED/VIDEOS/APPROVE_VIDEO_REQUEST'
export const APPROVE_VIDEO_SUCCESS = 'TED/VIDEOS/APPROVE_VIDEO_SUCCESS'
export const APPROVE_VIDEO_FAILURE = 'TED/VIDEOS/APPROVE_VIDEO_FAILURE'

// REJECT VIDEO
export const REJECT_VIDEO_REQUEST = 'TED/VIDEOS/REJECT_VIDEO_REQUEST'
export const REJECT_VIDEO_SUCCESS = 'TED/VIDEOS/REJECT_VIDEO_SUCCESS'
export const REJECT_VIDEO_FAILURE = 'TED/VIDEOS/REJECT_VIDEO_FAILURE'

// RESTORE VIDEOS STATE
export type ClearVideos = GenericActionWithType<typeof CLEAR_VIDEOS>

// CREATE VIDEO
export type CreateVideoRequest = GenericActionWithType<
  typeof CREATE_VIDEO_REQUEST,
  { id: number; cancelToken: CancelTokenSource | null; file: File }
>
export type CreateVideoRetryRequest = GenericActionWithType<
  typeof CREATE_VIDEO_RETRY_REQUEST,
  { id: number; cancelToken: CancelTokenSource | null; file: File }
>
export type CreateVideoSuccess = GenericActionWithType<
  typeof CREATE_VIDEO_SUCCESS,
  { id: number; video: Video }
>
export type CreateVideoFailure = GenericActionWithType<
  typeof CREATE_VIDEO_FAILURE,
  { id: number; error: Error }
>

// DELETE VIDEO
export type DeleteVideoRequest = GenericActionWithType<typeof DELETE_VIDEO_REQUEST, { id: number }>
export type DeleteVideoSuccess = GenericActionWithType<typeof DELETE_VIDEO_SUCCESS, { id: number }>
export type DeleteVideoFailure = GenericActionWithType<
  typeof DELETE_VIDEO_FAILURE,
  { id: number; error: Error }
>

// GET ALL VIDEOS
export type GetVideosRequest = GenericActionWithType<
  typeof GET_VIDEOS_REQUEST,
  {
    page: number
    rowsPerPage: number
  }
>
export type GetVideosSuccess = GenericActionWithType<
  typeof GET_VIDEOS_SUCCESS,
  { videos: Video[]; more: boolean }
>
export type GetVideosFailure = GenericActionWithType<typeof GET_VIDEOS_FAILURE, { error: Error }>

// UPDATE VIDEO
export type UpdateVideoRequest = GenericActionWithType<typeof UPDATE_VIDEO_REQUEST, { id: number }>
export type UpdateVideoSuccess = GenericActionWithType<typeof UPDATE_VIDEO_SUCCESS, { id: number }>
export type UpdateVideoFailure = GenericActionWithType<
  typeof UPDATE_VIDEO_FAILURE,
  { id: number; error: Error }
>

// APPROVE VIDEO
export type ApproveVideoRequest = GenericActionWithType<typeof APPROVE_VIDEO_REQUEST>
export type ApproveVideoSuccess = GenericActionWithType<
  typeof APPROVE_VIDEO_SUCCESS,
  { id: number; video: Video }
>
export type ApproveVideoFailure = GenericActionWithType<
  typeof APPROVE_VIDEO_FAILURE,
  { error: Error }
>

// REJECT VIDEO
export type RejectVideoRequest = GenericActionWithType<typeof REJECT_VIDEO_REQUEST>
export type RejectVideoSuccess = GenericActionWithType<
  typeof REJECT_VIDEO_SUCCESS,
  { id: number; video: Video }
>
export type RejectVideoFailure = GenericActionWithType<
  typeof REJECT_VIDEO_FAILURE,
  { error: Error }
>

// VIDEO DETAILS
export type SetVideoAuthField = GenericActionWithType<
  typeof SET_VIDEO_AUTH_FIELD,
  {
    id: number
    field: keyof VideoAuthForm
    value: VideoAuthForm[keyof VideoAuthForm]
  }
>

// VIDEO FILTERS
export type SetVideoFilter = GenericActionWithType<
  typeof SET_VIDEO_FILTER,
  {
    filter: keyof VideoFilters
    value: string | boolean
  }
>

export type ToggleVideoDetails = GenericActionWithType<typeof TOGGLE_VIDEO_DETAILS, { id: number }>

export type VideosActions =
  | ClearVideos
  | GetVideosRequest
  | CreateVideoRetryRequest
  | RejectVideoRequest
  | RejectVideoSuccess
  | RejectVideoFailure
  | GetVideosSuccess
  | GetVideosFailure
  | CreateVideoRequest
  | CreateVideoSuccess
  | CreateVideoFailure
  | DeleteVideoRequest
  | DeleteVideoSuccess
  | DeleteVideoFailure
  | UpdateVideoRequest
  | UpdateVideoSuccess
  | UpdateVideoFailure
  | SetVideoAuthField
  | ToggleVideoDetails
  | ApproveVideoRequest
  | ApproveVideoSuccess
  | ApproveVideoFailure
  | SetVideoFilter
