import type { Theme } from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { makeStyles, withStyles } from '@mui/styles'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { scrollbarMenu } from '@/legacy/styles/scrollbars'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

type Keyframes = {
  [x: string]: {
    '0%': {
      transform: string
    }
    '50%': {
      transform: string
    }
    '100%': {
      transform: string
    }
  }
}

const showMenuAnimation = (name: string, animation: string): [string, Keyframes] => {
  const animationWithName = `$${name} ${animation}`
  const keyframes = {
    [`@keyframes ${name}`]: {
      '0%': {
        transform: 'translateY(0px)',
      },
      '50%': {
        transform: 'translateY(8px)',
      },
      '100%': {
        transform: 'translateY(0px)',
      },
    },
  }

  return [animationWithName, keyframes]
}

const [animation, keyframes] = showMenuAnimation('dropdown', '300ms ease')

interface Styles {
  width?: string | number
  height?: string | number
  padding?: string | number
  menuBorder?: string
  selectBorder?: string
  selectBorderError?: string
  background?: string
  backgroundSelected?: string
  colorPointers?: boolean
  disabled?: boolean
  color?: string
  withIcon?: boolean
  disabledFullWidthForLabel?: boolean
}

const useStyles = makeStyles<Theme, Styles>((theme) => ({
  ...keyframes,
  root: {
    width: ({ width }) => width ?? 'fit-content',
    height: ({ height }) => height,
  },
  wrapper: {
    width: ({ width }) => width ?? 'fit-content',
    height: ({ height }) => height,
  },
  selectWrapperBase: {
    width: ({ width }) => width,
    height: '100%',
    display: 'flex',
    userSelect: 'none',
    borderRadius: 4,
    boxSizing: 'border-box',
    transition: 'background 200ms ease',
  },
  selectWrapper: {
    padding: ({ padding }) => padding,
    background: ({ background, disabled }) =>
      disabled ? theme.palette.disabled.secondary : background,
    border: ({ selectBorder }) => selectBorder,
  },
  selectWrapperSelected: {
    background: ({ backgroundSelected, disabled }) =>
      disabled ? theme.palette.disabled.secondary : backgroundSelected,
    border: ({ disabled, selectBorder }) =>
      disabled ? selectBorder : `2px solid ${theme.palette.secondary.main} !important`,
    padding: '0 !important',
  },
  selectWrapperSelectedError: {
    background: ({ backgroundSelected }) => backgroundSelected,
    padding: '0 !important',
    border: ({ selectBorderError }) => `${selectBorderError} !important`,
  },
  selectLabelContainer: {
    width: ({ disabledFullWidthForLabel }) => (disabledFullWidthForLabel ? 'auto' : '100%'),
    maxWidth: ({ disabledFullWidthForLabel }) => (disabledFullWidthForLabel ? '100%' : 'none'),
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
  },
  selectAnchorContainer: {
    width: 50,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      alignSelf: 'center',
      fill: '#1a1a1a',
      width: '1.3rem',
    },
  },
  selectLabel: {
    maxWidth: 'calc(100% - 14px)',
    paddingLeft: 14,
    alignSelf: 'center',
    color: ({ disabled, color }) => (disabled ? 'rgba(170, 170, 170)' : color ? color : '#1a1a1a'),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    fontFamily: 'DM Sans',
    fontWeight: 500,
    letterSpacing: '0.01em',
  },
  menu: {
    width: ({ width }) => width,
    height: 'fit-content',
    maxHeight: 250,
    top: 6,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    borderRadius: 4,
    background: '#FFF',
    animation,
    boxShadow: '0px 1px 3px #e6e5e5',
    border: ({ menuBorder }) => menuBorder,
    overflowY: 'auto',
    zIndex: 1500,
    ...scrollbarMenu,
  },
  menuItemContainer: {
    height: '100%',
    display: 'flex',
    minHeight: ({ height }) => height,
    userSelect: 'none',
    cursor: 'pointer',
    flexDirection: 'column',
    padding: ({ withIcon }) => (withIcon ? '0 16px' : 0),
    '&:hover': {
      background: ({ withIcon }) => (withIcon ? 'transparent' : '#C2DBFF'),
    },
  },
  menuItem: {
    height: '100%',
    display: 'flex',
    borderBottom: ({ withIcon }) => (withIcon ? theme.globals.common.borders.main : 'none'),
    alignItems: 'center',
  },
  menuItemLabel: {
    width: '100%',
    minHeight: ({ height }) => height,
    color: ({ disabled, color }) => (disabled ? 'rgba(170, 170, 170)' : color ? color : '#1a1a1a'),
    alignSelf: 'center',
    paddingLeft: ({ colorPointers }) => (colorPointers ? 0 : 14),
    paddingRight: 14,
    maxWidth: 'calc(100% - 20px)',
    overflow: 'hidden',
    whiteSpace: 'pre-wrap',
    fontFamily: 'DM Sans',
    fontWeight: 500,
    letterSpacing: '0.01em',
    wordBreak: 'break-all',
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: ({ withIcon }) => (withIcon ? theme.palette.secondary.main : '#1a1a1a'),
    },
  },
  menuItemIdColor: {
    width: 40,
    height: ({ height }) => height,
    display: 'flex',
    justifyContent: 'center',
  },
  separator: {
    width: '100%',
    height: 1,
    background: 'rgb(241 241 241)',
    alignSelf: 'center',
  },
  icon: {
    height: '20px',
    color: theme.palette.secondary.main,
  },
  activeColor: {
    color: `${theme.palette.secondary.main} !important`,
  },
}))

const StyledItemPointer = withStyles({
  root: {
    width: 14,
    alignSelf: 'center',
    color: ({ pointercolor }: { pointercolor: string }) => pointercolor,
  },
})(FiberManualRecordIcon)

const defaultStyles = {
  width: 250,
  height: 40,
  padding: 0,
  menuBorder: '1px solid rgb(223, 223, 218)',
  selectBorder: '1px solid rgb(223, 223, 218)',
  selectBorderError: '2px solid #ff4040',
  background: 'transparent',
  backgroundSelected: 'rgb(235 235 235 / 47%)',
  color: '#1a1a1a',
}

export type Option = {
  id: number
  color?: string
  label: string
  value?: string
}

export interface SelectSimpleProps {
  value: string
  options: Option[]
  error?: boolean
  disabled?: boolean
  colorPointers?: boolean
  onChange?: (option: Option) => void
  styles?: Omit<Styles, 'colorPointers'>
  withIcon?: boolean
  disabledFullWidthForLabel?: boolean
}

const SelectSimple: React.FC<SelectSimpleProps> = ({
  value,
  options,
  error = false,
  colorPointers = false,
  onChange = () => {
    return
  },
  styles: {
    color = defaultStyles.color,
    width = defaultStyles.width,
    height = defaultStyles.height,
    padding = defaultStyles.padding,
    menuBorder = defaultStyles.menuBorder,
    selectBorder = defaultStyles.selectBorder,
    selectBorderError = defaultStyles.selectBorderError,
    background = defaultStyles.background,
    backgroundSelected = defaultStyles.backgroundSelected,
  } = defaultStyles,
  disabled = false,
  withIcon = false,
  disabledFullWidthForLabel = false,
  ...props
}) => {
  const classes = useStyles({
    width,
    height,
    padding,
    menuBorder,
    selectBorder,
    selectBorderError,
    background,
    backgroundSelected,
    colorPointers,
    disabled,
    withIcon,
    disabledFullWidthForLabel,
    color,
  })
  const [toggleMenu, setToggleMenu] = useState(false)
  const [optSelected, setOptSelected] = useState({
    label: value,
  })

  useEffect(() => {
    if (value) {
      const selectedOption = options.find((option) => option.value === value)
      setOptSelected(selectedOption || { label: value })
    }
  }, [value, options])

  const onClickAway = () => {
    setToggleMenu(false)
  }

  const onClickInputSelectToggleMenu = () => {
    setToggleMenu((state) => !state)
  }

  const onChangeOption = (option: Option) => () => {
    setToggleMenu(false)
    setOptSelected(option)
    const selectedValue = option.value
    onChange({
      id: option.id,
      label: option.label,
      value: selectedValue,
    })
  }

  return (
    <div className={classes.wrapper} {...props}>
      <ClickAwayListener onClickAway={onClickAway}>
        <div className={classes.root}>
          <div
            className={clsx(classes.selectWrapperBase, {
              [classes.selectWrapper]: !toggleMenu,
              [classes.selectWrapperSelectedError]: error,
              [classes.selectWrapperSelected]: toggleMenu,
            })}
            onClick={onClickInputSelectToggleMenu}
          >
            <div className={classes.selectLabelContainer}>
              <span className={classes.selectLabel} id="selectLabel">
                {optSelected.label}
              </span>
            </div>
            {!disabled && (
              <div className={classes.selectAnchorContainer}>
                {toggleMenu ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </div>
            )}
          </div>
          <div
            className={classes.menu}
            style={{ display: toggleMenu && !disabled ? 'flex' : 'none' }}
          >
            {options.map((option, key) => {
              const isActiveValue = withIcon && option.label === optSelected.label

              return (
                <div className={classes.menuItemContainer} key={key}>
                  <div className={classes.menuItem} onClick={onChangeOption(option)}>
                    {colorPointers && option.color && (
                      <span className={classes.menuItemIdColor}>
                        <StyledItemPointer pointercolor={option.color} />
                      </span>
                    )}
                    <span
                      className={clsx(classes.menuItemLabel, {
                        [classes.activeColor]: isActiveValue,
                      })}
                    >
                      {option.label}
                    </span>
                    {isActiveValue ? <CheckCircleIcon className={classes.icon} /> : null}
                  </div>
                  <span className={classes.separator} />
                </div>
              )
            })}
          </div>
        </div>
      </ClickAwayListener>
    </div>
  )
}

export default SelectSimple
