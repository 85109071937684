import type { OrganizationOption } from './common'

export const SET_OPEN = 'IMPORT_PASSWORDS/SET_OPEN'
export const SET_FILE = 'IMPORT_PASSWORDS/SET_FILE'
export const SET_ORGANIZATION = 'IMPORT_PASSWORDS/SET_ORGANIZATION'
export const ORGANIZATIONS_REQUEST = 'IMPORT_PASSWORDS/ORGANIZATIONS_REQUEST'
export const ORGANIZATIONS_SUCCESS = 'IMPORT_PASSWORDS/ORGANIZATIONS_SUCCESS'
export const ORGANIZATIONS_FAILURE = 'IMPORT_PASSWORDS/ORGANIZATIONS_FAILURE'
export const IMPORT_PASSWORDS_REQUEST = 'IMPORT_PASSWORDS/IMPORT_PASSWORDS_REQUEST'
export const IMPORT_PASSWORDS_SUCCESS = 'IMPORT_PASSWORDS/IMPORT_PASSWORDS_SUCCESS'
export const IMPORT_PASSWORDS_FAILURE = 'IMPORT_PASSWORDS/IMPORT_PASSWORDS_FAILURE'

export interface ImportPasswordsState {
  open: boolean
  file: File | null
  loading: boolean
  error: string
  organization: OrganizationOption | null
  organizationOptions: OrganizationOption[]
  loadingOrganizations: boolean
  lastOrganizationID: number
}

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    open: boolean
  }
}

export interface SetFile {
  type: typeof SET_FILE
  payload: {
    file: File
  }
}

export interface SetOrganization {
  type: typeof SET_ORGANIZATION
  payload: {
    index?: number
    organization: OrganizationOption
  }
}

export interface OrganizationsRequest {
  type: typeof ORGANIZATIONS_REQUEST
  payload: {
    index?: number
    lastSearchID: number
  }
}

export interface OrganizationsSuccess {
  type: typeof ORGANIZATIONS_SUCCESS
  payload: {
    index?: number
    organizationOptions: OrganizationOption[]
  }
}

export interface OrganizationsFailure {
  type: typeof ORGANIZATIONS_FAILURE
  payload: {
    error: string
  }
}
export interface ImportPasswordsRequest {
  type: typeof IMPORT_PASSWORDS_REQUEST
}

export interface ImportPasswordsSuccess {
  type: typeof IMPORT_PASSWORDS_SUCCESS
}

export interface ImportPasswordsFailure {
  type: typeof IMPORT_PASSWORDS_FAILURE
  payload: {
    error: string
  }
}

export type ImportPasswordsActions =
  | SetOpen
  | SetFile
  | SetOrganization
  | OrganizationsRequest
  | OrganizationsSuccess
  | OrganizationsFailure
  | ImportPasswordsRequest
  | ImportPasswordsSuccess
  | ImportPasswordsFailure
