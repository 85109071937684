import type { CancelTokenSource } from 'axios'
import type { MessageThread } from '@/api/bulletin/types'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export interface MessageThreadsState {
  error: Error | string
  isMessageThreadDeleted: boolean
  loading: boolean
  status: REQUEST_STATUS
  total: number
  replies: MessageThread[]
  cancelToken: CancelTokenSource | null
  messageSelectedId: number
  classroomName: string
}

export enum MESSAGE_THREADS_TYPES {
  GET_MESSAGE_THREADS = 'TEACHER/BULLETIN/MESSAGE_THREADS/GET_MESSAGE_THREADS',
  GET_MESSAGE_THREADS_SUCCESS = 'TEACHER/BULLETIN/MESSAGE_THREADS/GET_MESSAGE_THREADS_SUCCESS',
  GET_MESSAGE_THREADS_FAILURE = 'TEACHER/BULLETIN/MESSAGE_THREADS/GET_MESSAGE_THREADS_FAILURE',
  CLEAN_MESSAGE_THREADS = 'TEACHER/BULLETIN/MESSAGE_THREADS/CLEAN_MESSAGE_THREADS',
  MARK_MESSAGE_THREADS_AS_READ = 'TEACHER/BULLETIN/MESSAGE_THREADS/MARK_MESSAGE_THREADS_AS_READ',
  MARK_MESSAGE_THREADS_AS_READ_SUCCESS = 'TEACHER/BULLETIN/MESSAGE_THREADS/MARK_MESSAGE_THREADS_AS_READ_SUCCESS',
  MARK_MESSAGE_THREADS_AS_READ_FAILURE = 'TEACHER/BULLETIN/MESSAGE_THREADS/MARK_MESSAGE_THREADS_AS_READ_FAILURE',
  SET_MESSAGE_THREADS_CANCEL_TOKEN = 'TEACHER/BULLETIN/MESSAGE_THREADS/SET_MESSAGE_THREADS_CANCEL_TOKEN',
  SET_MESSAGE_SELECTED_ID = 'TEACHER/BULLETIN/MESSAGE_THREADS/SET_MESSAGE_SELECTED_ID',
}

export type GetMessageThreads = {
  type: MESSAGE_THREADS_TYPES.GET_MESSAGE_THREADS
  payload: {
    messageId: number
  }
}

export type GetMessageThreadsSuccess = {
  type: MESSAGE_THREADS_TYPES.GET_MESSAGE_THREADS_SUCCESS
  payload: {
    total: number
    replies: MessageThread[]
    classroomName: string
  }
}

export type GetMessageThreadsFailure = {
  type: MESSAGE_THREADS_TYPES.GET_MESSAGE_THREADS_FAILURE
  payload: {
    error: Error
    isDeleted: boolean
  }
}

export type ClearMessageThreads = {
  type: MESSAGE_THREADS_TYPES.CLEAN_MESSAGE_THREADS
}

export type SetMessageThreadsCancelToken = {
  type: MESSAGE_THREADS_TYPES.SET_MESSAGE_THREADS_CANCEL_TOKEN
  payload: {
    token: CancelTokenSource
  }
}

export type SetMessageThreadSelectedId = {
  type: MESSAGE_THREADS_TYPES.SET_MESSAGE_SELECTED_ID
  payload: {
    messageSelectedId: number
  }
}

export type MessageThreadsActions =
  | ClearMessageThreads
  | GetMessageThreads
  | GetMessageThreadsSuccess
  | GetMessageThreadsFailure
  | SetMessageThreadsCancelToken
  | SetMessageThreadSelectedId
