import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

// State.
export type DeleteOrganizationModalState = {
  isOpenModal: boolean
  selectedOrganization: {
    id: number
    name: string
  }
  status: REQUEST_STATUS
}

// Types.
export enum DELETE_ORGANIZATION_MODAL_TYPES {
  OPEN_MODAL = 'MANAGEMENT/ORGANIZATIONS/DELETE_ORGANIZATION_MODAL/OPEN_MODAL',
  CLEAN_MODAL = 'MANAGEMENT/ORGANIZATIONS/DELETE_ORGANIZATION_MODAL/CLEAN_MODAL',
  SCHEDULE_ORG_DELETION_BY_DATE_REQUEST = 'MANAGEMENT/ORGANIZATIONS/DELETE_ORGANIZATION_MODAL/SCHEDULE_ORG_DELETION_BY_DATE_REQUEST',
  SCHEDULE_ORG_DELETION_BY_DATE_SUCCESS = 'MANAGEMENT/ORGANIZATIONS/DELETE_ORGANIZATION_MODAL/SCHEDULE_ORG_DELETION_BY_DATE_SUCCESS',
  SCHEDULE_ORG_DELETION_BY_DATE_FAILURE = 'MANAGEMENT/ORGANIZATIONS/DELETE_ORGANIZATION_MODAL/SCHEDULE_ORG_DELETION_BY_DATE_FAILURE',
}

// Actions.
// Open modal action.
export type OpenModalAction = {
  type: DELETE_ORGANIZATION_MODAL_TYPES.OPEN_MODAL
  payload: DeleteOrganizationModalState['selectedOrganization']
}

// Clean modal action.
export type CleanModalAction = {
  type: DELETE_ORGANIZATION_MODAL_TYPES.CLEAN_MODAL
}

// Schedule org deletion by date actions.
export type ScheduleOrgDeletionByDateRequestAction = {
  type: DELETE_ORGANIZATION_MODAL_TYPES.SCHEDULE_ORG_DELETION_BY_DATE_REQUEST
}

export type ScheduleOrgDeletionByDateSuccessAction = {
  type: DELETE_ORGANIZATION_MODAL_TYPES.SCHEDULE_ORG_DELETION_BY_DATE_SUCCESS
}

export type ScheduleOrgDeletionByDateFailureAction = {
  type: DELETE_ORGANIZATION_MODAL_TYPES.SCHEDULE_ORG_DELETION_BY_DATE_FAILURE
}

export type ScheduleOrgDeletionByDateAction =
  | ScheduleOrgDeletionByDateRequestAction
  | ScheduleOrgDeletionByDateSuccessAction
  | ScheduleOrgDeletionByDateFailureAction

// Action.
export type DeleteOrganizationModalAction =
  | OpenModalAction
  | CleanModalAction
  | ScheduleOrgDeletionByDateAction
