import type { AxiosResponse } from 'axios'
import type { Moment } from 'moment'
import moment from 'moment'
import { url } from '@aula/config'
import { apiPrivate } from '@/api/index'
import type { DescriptionResponseShortener } from '@/api/types'
import type { AVVEvent } from '@/api/lms/avv'
import { CollabMiniatureAPI } from './collabMiniature'
import { EducationalInfoAPI } from './educationalInfo'
import type {
  Count,
  GetActivitiesCountParams,
  GetActivitiesCountResponse,
  GetActivitiesParams,
  GetActivitiesResponse,
} from './types'
import type { ActivityPayloadConfiguration } from '@/sections/teacher/library/types/sequenceAssignmentModal'

export const ActivitiesAPI = {
  get,
  getStudents,
  create,
  update,
  updatePartially,
  remove,
  assignSequence,
  getStudentSubjectEvaluations,
  createOffline,
  evaluateOffline,
  getActivityEventDates,
  educationalInfo: EducationalInfoAPI,
  collabMiniature: CollabMiniatureAPI,
  getActivities,
  getActivitiesCount,
}

export enum ActivityTypes {
  QUESTIONS = 'questions',
  READING = 'reading',
  AVV = 'avv',
  ROBOTS = 'robots-activity',
  ANIMATIONS = 'animations',
  GAMES = 'games',
  EXES = 'exes',
  EXOR = 'exor',
  QUIZ = 'quiz',
  COLLAB_MINIATURE = 'collab-miniature',
  OFFLINE = 'offline',
}

type getOptions = {
  /**
   * Whether to include student submissions or not. Must only be used when the user is a teacher,
   * students' requests already include the submissions.
   */
  withSubmissions: {
    studentId: number
    allSubmissions?: boolean
  }
}

/**
 * Get activity.
 *
 * @role Any
 * @param id Activity's id
 * @param options
 */
function get(id: number, options?: getOptions) {
  if (options?.withSubmissions?.studentId) {
    const studentID = options.withSubmissions.studentId
    return apiPrivate.get(url + `/v1/activities/${id}/students/${studentID}/submissions`)
  }

  return apiPrivate.get(url + `/v2/activities/${id}`)
}

/**
 * Get users with student access to an activity.
 *
 * @role Teacher
 * @param id Activity's id
 */
function getStudents(id: number) {
  return apiPrivate.get(url + `/v1/activities/${id}/students`)
}

type CreateRequest = {
  classroomID: number
  subjectID: number
  subgroupIDs?: number[]
  activityType: ActivityTypes
  date: Date
  publicationDate?: Date
  start?: Date
  end?: Date
  deadline?: Date
  title: string
  description: string
  enableChat: boolean
  hasDeliverable: boolean
  submissionsAfterDeadline: boolean
  robotsRemoteProject: {
    password: string
    board: number
  }
  distribute: boolean
  questions: Array<
    MultipleChoiceQuestion | WritingQuestion | FillableQuestion | UnionQuestion | TextQuestion
  >
  files: Array<{
    fileID: number
    name: string
    url: string
    description: string
  }>
}

export enum QuestionType {
  CHOICE = 'choice',
  WRITING = 'writing',
  ATTACHMENT = 'attachment',
  FILLABLE = 'fillable',
  UNION = 'union',
  TEXT = 'text',
}

type MultipleChoiceQuestion = {
  type: QuestionType.CHOICE
  text: string
  url: string
  options: Array<{
    type: 'text' | 'image'
    text: string
    url: string
    order: number
    correct: boolean
  }>
  order: number
}

type WritingQuestion = {
  type: QuestionType.WRITING
  text: string
  url: string
  order: number
  minLength: number
  maxLength: number
}

type FillableQuestion = {
  type: QuestionType.FILLABLE
  text: string
  body: string
  url: string
  order: number
}

type UnionQuestion = {
  type: QuestionType.UNION
  text: string
  url: string
  options: Array<{
    text: string
    url: string
    column: number
    matchesWith: number
    order: number
  }>
  order: number
}

type TextQuestion = {
  type: QuestionType.TEXT
  text: string
  body: string
  order: number
}

/**
 * Create activity.
 *
 * @role Teacher
 * @param request
 */
function create(request: CreateRequest) {
  throw new Error('not implemented')
  // TODO transform request into form
  // const form = {}
  // return apiPrivate.post(url + `/v1/activities`, form)
}

/**
 * Update activity completely.
 *
 * @role Teacher
 */
function update() {
  // TODO implement once backend refactor is done
  throw new Error('not implemented')
}

type UpdatePartiallyRequest = {
  title?: string
  enableChat?: boolean
  evaluationType?: number
  dates?: {
    date: Date
    publicationDate: Date
    start?: Date
    end?: Date
    deadline?: Date
  }
}

/**
 * Update activity partially.
 *
 * @role Teacher
 */
function updatePartially(request: UpdatePartiallyRequest) {
  // TODO transform request into form
  throw new Error('not implemented')
}

/**
 * Delete activity.
 *
 * @role Teacher
 * @param id Activity's id
 */
function remove(id: number) {
  return apiPrivate.delete(url + `/v1/activities/${id}`)
}

/**
 * Create a sequence of activities.
 *
 * @role Teacher
 * @param classroomId
 * @param subjectId
 * @param date
 * @param start
 * @param end
 * @param deadline
 * @param publicationDate
 * @param enableChat
 * @param activityIds
 * @param activityIds
 */
function assignSequence(
  classroomId: number,
  subjectId: number,
  subgroupIds: number[],
  activities: ActivityPayloadConfiguration[]
) {
  const data = {
    classroomId: classroomId,
    subjectId: subjectId,
    subgroupIds: subgroupIds,
    activities: activities,
  }

  return apiPrivate.post(url + `/v2/activities/sequence`, data)
}

/**
 * Get all evaluations for a given student in a given period of time.
 *
 * @role Teacher
 * @param studentId
 * @param subjectId
 * @param classroomId
 * @param from
 * @param to
 * @param role
 * @param filterByDates
 */

function getStudentSubjectEvaluations(
  classroomId: number,
  offset: number,
  limit: number,
  role: string,
  subjectId?: number,
  filterByDates?: boolean,
  from?: Moment,
  to?: Moment,
  studentId?: number
) {
  const params = {
    studentId,
    subjectId,
    classroomId,
    from: moment(from).format(),
    to: moment(to).format(),
    offset,
    limit,
    filterByDates,
  }

  const r = role === 'student' ? role : 'teacher'
  return apiPrivate.get(url + `/v1/views/${r}/evaluations`, { params })
}

/**
 * Create offline activity.
 *
 * @role Teacher
 * @param classroomId
 * @param organizationId
 * @param subjectId
 * @param subgroupIds
 * @param date
 * @param title
 * @param evaluationType
 */

function createOffline(
  classroomId: number,
  organizationId: number,
  subjectId: number,
  subgroupIds: number[],
  date: Moment,
  title: string,
  evaluationType: number
) {
  const data = {
    classroomId,
    organizationId,
    subjectId,
    subgroupIds,
    date: date.format(),
    title,
    evaluationType,
  }

  return apiPrivate.post(url + `/v1/activities/create/offline`, data)
}

/**
 * Evaluate offline activity.
 *
 * @role Teacher
 * @param classroomId
 * @param activityId
 * @param studentId
 * @param value
 */

type EvaluateOfflineResponse = {
  id: number
  evaluatorId: number
  evaluateeId: number
  submissionId: number
  activityId: number
  value: number
  feedback: string
}

function evaluateOffline(
  classroomId: number,
  activityId: number,
  studentId: number,
  value: number,
  feedback: string
): Promise<EvaluateOfflineResponse> {
  const data = {
    studentId,
    value,
    feedback,
  }

  return apiPrivate
    .post(
      `${url}/v1/classroom/${classroomId}/activities/${activityId}/submissions/evaluate/offline`,
      data,
      { params: { base1000: true } }
    )
    .then((resp: AxiosResponse<DescriptionResponseShortener<EvaluateOfflineResponse>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Gets list of dates in which a specific event was triggered by a given user during a given activity
 *
 * @param activityId
 * @param userId
 * @param eventType
 */

type GetActivityEventDatesResponse = {
  dateTimes: Moment[]
}

type GetActivityEventDatesParams = {
  activityId: number
  userId: number
  event: AVVEvent
}

function getActivityEventDates({
  activityId,
  userId,
  event,
}: GetActivityEventDatesParams): Promise<GetActivityEventDatesResponse> {
  const params = {
    userId: userId,
    type: event,
  }

  return apiPrivate
    .get(`${url}/v1/activities/${activityId}/events`, { params })
    .then((resp: AxiosResponse<DescriptionResponseShortener<GetActivityEventDatesResponse>>) => {
      const formattedDates: Moment[] = resp.data.description.dateTimes.reduce(
        (acc: Moment[], d) => (moment(d).isValid() ? [...acc, moment(d)] : acc),
        []
      )

      const formattedResponse = {
        ...resp.data.description,
        dateTimes: formattedDates,
      }

      return formattedResponse
    })
    .catch((err) => {
      throw err
    })
}

function getActivities(params: GetActivitiesParams): Promise<GetActivitiesResponse['description']> {
  const { from, to, subjectId, limit, offset } = params

  const validParams = {
    role: params.role,
    studentId: params.studentId,
    classroomId: params.classroomId,
    ...(from && { from }),
    ...(to && { to }),
    ...(subjectId && { subjectId }),
    ...(limit && { limit }),
    ...(offset && { offset }),
  }

  return apiPrivate
    .get<GetActivitiesResponse>(`${url}/v1/activities`, { params: validParams })
    .then((resp) => resp.data.description)
}

function getActivitiesCount(
  classroomId: number,
  studentId: number,
  params: GetActivitiesCountParams
): Promise<Count> {
  const { from, to, subjectId } = params

  const validParams = {
    ...(from && { from }),
    ...(to && { to }),
    ...(subjectId && { subjectId }),
  }

  return apiPrivate
    .get<GetActivitiesCountResponse>(
      `${url}/v1/classroom/${classroomId}/students/${studentId}/activities-count`,
      { params: validParams }
    )
    .then((resp) => resp.data.description)
}
