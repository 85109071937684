import type { PrincipalClassroom } from '@/legacy/redux/types/classrooms'

export interface ReportCardConfigeditModalState {
  open: boolean
  loading: boolean
  error: string
  classroom: PrincipalClassroom | null
}

export const SET_OPEN = 'REPORT_CARD/CONFIG/EDIT_MODAL/SET_OPEN'
export const SET_CLOSE = 'REPORT_CARD/CONFIG/EDIT_MODAL/SET_CLOSE'

export type SetOpen = { type: typeof SET_OPEN; payload: { classroom: PrincipalClassroom } }

export type SetClose = { type: typeof SET_CLOSE }

export const CLEAR_STATE = 'REPORT_CARD/CONFIG/EDIT_MODAL/CLEAR_STATE'
export type ClearState = { type: typeof CLEAR_STATE }

export type ReportCardConfigDeletionModalAction = SetOpen | SetClose | ClearState
