import type { AxiosResponse } from 'axios'
import { url } from '@aula/config'
import type { Subject } from '@/legacy/redux/types/subjects'
import type { OrganizationOption } from '@/sections/principal/dashboard/types/common'
import type { Params } from '@/sections/teacher/students/types/students'
import { ManagementRole } from '@/legacy/roles'
import { apiPrivate } from './..'
import type { DescriptionResponseShortener, GenericParams } from '@/api/types'

export type ManagementClassroom = {
  id: number
  division: string
  grade: string
  stage: string
  organizationCue: string
  organizationNeighborhood: string
  organization: OrganizationOption | null
  organizationId: number
  organizationName: string
  organizationType?: string
  shift: string
  year: number
  classTeacherId: number
  classTeacherName: string
  studentsCount: number
  academicPeriodId: number
  fullName: string
}

export const ClassroomsAPI = {
  DEPRECATED_getAsAdmin,
  DEPRECATED_getAsPrincipal,
  DEPRECATED_createAsPrincipal,
  createAsAdmin,
  editAsAdmin,
  getAsTeacher,
  createAsPrincipal,
  editAsPrincipal,
  migrateClassrooms,
  getClassroomSubjects,
  getClassroomRole,
  remove,
  ClassroomSubjects: {
    getAsMgmt: getClassroomSubjectsAsManagement,
    updateAsAdmin: (classroomId: number, subjectIds: number[]) =>
      updateClassroomSubjectsAsManagement(classroomId, ManagementRole.ADMIN, subjectIds),
    updateAsPrincipal: (classroomId: number, subjectIds: number[]) =>
      updateClassroomSubjectsAsManagement(classroomId, ManagementRole.PRINCIPAL, subjectIds),
  },
}

export type MigrationClassroom = {
  srcId: number
  dstId: number
  add?: number[]
  remove?: number[]
}

export interface CreateClassroomParams {
  organizationId: number
  stage: string
  grade: string
  divisions: string[]
  shift: string
  academicPeriodId: number
  subjectIds: number[]
  enrollmentCapacity: number
  k: number
}
export interface EditClassroomParams {
  stage: string
  grade: string
  division: string
  shift: string
  academicPeriodId: number
  enrollmentCapacity: number
  k: number
}

/**
 * Gets classrooms as teacher.
 *
 * @role Teacher
 * @param params
 * @param organizationID
 */
function getAsTeacher(params: Params, organizationID = 0) {
  const organizationParam = organizationID > 0 ? '?organization_id=' + organizationID : ''
  return apiPrivate.get(url + '/v1/classrooms/teacher' + organizationParam, { params })
}


interface GetAsAdminParams extends Partial<GenericParams> {
    isEvaluator?: boolean
    academicPeriodId?: number
    organizationId?: number
}

type GetAsAdminResponse = {
  classrooms: ManagementClassroom[]
  rows: ManagementClassroom[]
  total: number
}

// deprecated: see src/services/api/lms/classrooms/index.ts
function DEPRECATED_getAsAdmin(params: GetAsAdminParams): Promise<GetAsAdminResponse> {
  return apiPrivate
    .get(url + '/v1/admin/classrooms', {
      params: {
        ...params,
        ...(params.isEvaluator ? { isEvaluator: params.isEvaluator } : {}),
      },
    })
    .then((resp: AxiosResponse<DescriptionResponseShortener<GetAsAdminResponse>>) => {
      return { ...resp.data.description, classrooms: resp.data.description.rows }
    })
    .catch((err) => {
      throw err
    })
}

function createAsAdmin(body: CreateClassroomParams): Promise<void> {
  return apiPrivate
    .post(url + '/v4/admin/classrooms', body)
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

function editAsAdmin(classroomId: number, body: EditClassroomParams): Promise<void> {
  return apiPrivate
    .put(url + '/v3/admin/classrooms/' + classroomId, body)
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}


interface GetAsPrincipalParams extends Partial<GenericParams> {
  organizationId?: number
  year?: number
  toMigrate?: boolean
  academicPeriodId?: number
}

type GetAsPrincipalResponse = {
  classrooms: ManagementClassroom[]
  rows: ManagementClassroom[]
  total: number
}

// deprecated: see src/services/api/lms/classrooms/index.ts
function DEPRECATED_getAsPrincipal(params: GetAsPrincipalParams): Promise<GetAsPrincipalResponse> {
  return apiPrivate
    .get(url + '/v1/principal/classrooms', {
      params,
    })
    .then((resp: AxiosResponse<DescriptionResponseShortener<GetAsPrincipalResponse>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

function createAsPrincipal(body: CreateClassroomParams): Promise<void> {
  return apiPrivate
    .post(url + '/v4/principal/classrooms', body)
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

function editAsPrincipal(classroomId: number, body: EditClassroomParams): Promise<void> {
  return apiPrivate
    .put(url + '/v3/principal/classrooms/' + classroomId, body)
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Creates a classroom as principal.
 *
 * @role Principal
 * @param organizationID
 * @param stage
 * @param grade
 * @param division
 * @param shift
 * @param year
 */

function DEPRECATED_createAsPrincipal(
  organizationID: number,
  stage: string,
  grade: string,
  division: string,
  shift: string,
  year: number
) {
  const data = {
    organizationId: organizationID,
    stage,
    grade,
    division,
    shift,
    year,
  }

  return apiPrivate.post(url + '/v1/principal/classrooms', data)
}

/**
 * Completes classroom migration as principal.
 *
 * @role Principal
 * @param migrationClassrooms
 */

function migrateClassrooms(migrate: MigrationClassroom[]) {
  const data = {
    migrate,
  }

  return apiPrivate.post(url + '/v1/principal/classroom-migration', data)
}

type ClassroomSubjectsDescription = {
  subjects: Subject[]
}

/**
 * Gets subjects for a given classroom.
 *
 * @role Teacher
 * @param classroomID
 * @param fetchAll
 */
function getClassroomSubjects(classroomID: number, getAllSubjects = false) {
  const params = {
    getAllSubjects,
  }

  return apiPrivate.get<DescriptionResponseShortener<ClassroomSubjectsDescription>>(
    url + `/v2/classroom/${classroomID}/subjects`,
    { params }
  )
}

/**
 * Gets the user's highest role in a given classroom.
 *
 * @role Any
 * @param classroomID
 */

function getClassroomRole(classroomID: number) {
  return apiPrivate.get(url + `/v1/classroom/${classroomID}/role`)
}

/**
 * Gets subjects associated with a specific classroom.
 *
 * @role Principal / Admin
 * @param classroomID
 */

export type GetClassroomSubjectsAsManagementResponse = {
  subjects: Subject[]
}

function getClassroomSubjectsAsManagement(
  classroomId: number,
  role: 'principal' | 'admin'
): Promise<GetClassroomSubjectsAsManagementResponse> {
  return apiPrivate
    .get(url + `/v1/${role}/classrooms/${classroomId}/subjects`)
    .then(
      (
        resp: AxiosResponse<DescriptionResponseShortener<GetClassroomSubjectsAsManagementResponse>>
      ) => resp.data.description
    )
    .catch((err) => {
      throw err
    })
}

/**
 * Gets subjects associated with a specific classroom.
 *
 * @role Principal / Admin
 * @param classroomID
 */

function updateClassroomSubjectsAsManagement(
  classroomId: number,
  role: ManagementRole,
  subjectIds: number[]
) {
  const data = {
    subjectIds,
  }

  return apiPrivate.put(url + `/v1/${role}/classrooms/${classroomId}/subjects`, data)
}

function remove(classroomId: number, role: ManagementRole) {
  return apiPrivate.delete(url + `/v1/${role}/classrooms/${classroomId}`)
}
