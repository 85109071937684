import * as Types from '@/sections/report-card/generateBulletinsModal/types/generateBulletinsModal'

export const generateBulletinsPaginationLimit = 5

const initialState: Types.GenerateBulletinsModalState = {
  isOpenModal: false,
  isLoading: false,
  periods: [],
  classrooms: [],
  selectedClassrooms: [],
  selectedPeriod: '',
  currentStep: Types.GenerateBulletinsSteps.INITIAL,
  error: null,
  typeOfConfig: '',
  sections: {
    enableScores: false,
    enableAttitudinal: false,
    enableCompetences: false,
    enableReviews: false,
    enableAttendances: false,
  },
  pagination: {
    limit: generateBulletinsPaginationLimit,
    offset: 0,
    more: true,
  },
}

const GenerateBulletinsModalReducer = (
  state: Types.GenerateBulletinsModalState = initialState,
  action: Types.GenerateBulletinsModalAction
): Types.GenerateBulletinsModalState => {
  switch (action.type) {
    case Types.GENERATE_BULLETINS_MODAL_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isOpenModal: true,
      }
    }
    case Types.GENERATE_BULLETINS_MODAL_TYPES.CLOSE_MODAL: {
      return initialState
    }
    case Types.GENERATE_BULLETINS_MODAL_TYPES.GET_PERIODS_REQUEST:
    case Types.GENERATE_BULLETINS_MODAL_TYPES.GET_CLASSROOMS_REQUEST:
    case Types.GENERATE_BULLETINS_MODAL_TYPES.GENERATE_BULLETINS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    }
    case Types.GENERATE_BULLETINS_MODAL_TYPES.GET_PERIODS_SUCCESS: {
      const { periods } = action.payload

      return {
        ...state,
        isLoading: false,
        error: null,
        periods,
      }
    }
    case Types.GENERATE_BULLETINS_MODAL_TYPES.GET_CLASSROOMS_SUCCESS: {
      const { classrooms, more } = action.payload

      return {
        ...state,
        isLoading: false,
        error: null,
        classrooms,
        pagination: {
          ...state.pagination,
          more,
        },
      }
    }
    case Types.GENERATE_BULLETINS_MODAL_TYPES.GENERATE_BULLETINS_SUCCESS: {
      return {
        ...state,
        currentStep: Types.GenerateBulletinsSteps.SECOND,
        isLoading: false,
        error: null,
      }
    }
    case Types.GENERATE_BULLETINS_MODAL_TYPES.GET_PERIODS_FAILURE:
    case Types.GENERATE_BULLETINS_MODAL_TYPES.GET_CLASSROOMS_FAILURE:
    case Types.GENERATE_BULLETINS_MODAL_TYPES.GENERATE_BULLETINS_FAILURE: {
      const { error } = action.payload

      return {
        ...state,
        isLoading: false,
        error,
      }
    }
    case Types.GENERATE_BULLETINS_MODAL_TYPES.SET_CURRENT_STEP: {
      const { newStep } = action.payload

      return {
        ...state,
        currentStep: newStep,
      }
    }
    case Types.GENERATE_BULLETINS_MODAL_TYPES.SET_SELECTED_PERIOD: {
      const { newPeriod } = action.payload
      return {
        ...state,
        selectedPeriod: newPeriod,
        pagination: initialState.pagination,
      }
    }
    case Types.GENERATE_BULLETINS_MODAL_TYPES.SET_TYPE_OF_CONFIG: {
      const { newTypeOfConfig } = action.payload

      return {
        ...state,
        typeOfConfig: newTypeOfConfig,
      }
    }
    case Types.GENERATE_BULLETINS_MODAL_TYPES.SET_SECTION: {
      const { sectionKey, canEnableSection } = action.payload

      if (sectionKey === 'all') {
        return {
          ...state,
          sections: {
            enableScores: canEnableSection,
            enableAttitudinal: canEnableSection,
            enableCompetences: canEnableSection,
            enableReviews: canEnableSection,
            enableAttendances: canEnableSection,
          },
        }
      } else {
        return {
          ...state,
          sections: {
            ...state.sections,
            [sectionKey]: canEnableSection,
          },
        }
      }
    }
    case Types.GENERATE_BULLETINS_MODAL_TYPES.SET_SELECTED_CLASSROOMS: {
      const { classroom } = action.payload
      const hasTheClassroom = state.selectedClassrooms.some(
        ({ periodId }) => periodId === classroom.periodId
      )

      return {
        ...state,
        selectedClassrooms: hasTheClassroom
          ? state.selectedClassrooms.filter(({ periodId }) => periodId !== classroom.periodId)
          : [...state.selectedClassrooms, classroom],
      }
    }
    case Types.GENERATE_BULLETINS_MODAL_TYPES.SET_PAGINATION_OFFSET: {
      const { offsetType } = action.payload
      const { limit, offset } = state.pagination

      const newOffset = offsetType === Types.OffsetType.NEXT ? offset + limit : offset - limit

      return {
        ...state,
        pagination: {
          ...state.pagination,
          offset: newOffset,
        },
      }
    }
    case Types.GENERATE_BULLETINS_MODAL_TYPES.SELECT_ALL_CLASSROOMS: {
      const { canSelectAll } = action.payload
      const { classrooms, selectedClassrooms } = state

      const classroomsNotSelected = classrooms.filter((classroom) => {
        return !selectedClassrooms.some((selected) => selected.periodId === classroom.periodId)
      })

      const selectedClassroomsWithoutCurrentClassrooms = selectedClassrooms.filter((selected) => {
        return !classrooms.some((classroom) => classroom.periodId === selected.periodId)
      })

      return {
        ...state,
        selectedClassrooms: canSelectAll
          ? [...selectedClassrooms, ...classroomsNotSelected]
          : [...selectedClassroomsWithoutCurrentClassrooms],
      }
    }
    default:
      return state
  }
}

export default GenerateBulletinsModalReducer
