import { makeStyles } from '@mui/styles'
import { Anibot } from '@/legacy/components/v2/anibot'
import LoadingContainer from '@/legacy/components/v3/loadingContainer'
import type { Student } from '@/legacy/redux/types/students'
import { scrollBarStyles } from '@/sections/teacher/activities/styles'
import type { AbscencesDrawerState } from '@/sections/report-card/grading/types/abscencesDrawer'
import type { AbscencesOverviewProps } from './atomic/abscencesOverview'
import AbscencesOverview from './atomic/abscencesOverview'
import type { AbscencesTableProps } from './atomic/abscencesTable'
import AbscencesTable from './atomic/abscencesTable'
import type { StatusesNamesProps } from './atomic/statusesNames'
import StatusesNames from './atomic/statusesNames'

export const ABSCENCES_CELL_HEIGHT = 30
export const ABSCENCES_HEADER_HEIGHT = 60
export const EMPTY_SPACE_HEIGHT = ABSCENCES_HEADER_HEIGHT + 33

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '24px',
    boxSizing: 'border-box',
    height: 'calc(100% - 76px)', // CHECK:
    position: 'relative',
    display: 'flex',
    gap: 24,
  },
  tableContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',
    gap: 12,
    ...scrollBarStyles,
  },
  cardContainer: {
    width: 290,
  },
}))

type ComponentProps = AbscencesOverviewProps &
  AbscencesTableProps &
  StatusesNamesProps &
  AbscencesDrawerState

export interface AbscencesDrawerProps extends ComponentProps {
  selectedStudent: Student
}

export default function AbscencesDrawer({
  abscences,
  attendanceStatuses,
  columns,
  inputOnChange,
  loading,
  nextButtonDisabled,
  onClickNext,
  onClickPrevious,
  onSave,
  onStatusRadioChange,
  previousButtonDisabled,
  rows,
  selectedPeriod,
  selectedStudent,
}: AbscencesDrawerProps) {
  const C = useStyles()

  if (loading)
    return (
      <LoadingContainer positionAbsolute backgroundColor="transparent">
        <Anibot />
      </LoadingContainer>
    )

  return (
    <div className={C.root}>
      <div className={C.tableContainer}>
        <StatusesNames attendanceStatuses={attendanceStatuses} />
        <AbscencesTable
          abscences={abscences}
          columns={columns}
          onStatusRadioChange={onStatusRadioChange}
          rows={rows}
        />
      </div>
      <div className={C.cardContainer}>
        <AbscencesOverview
          abscences={abscences}
          inputOnChange={inputOnChange}
          nextButtonDisabled={nextButtonDisabled}
          onClickNext={onClickNext}
          onClickPrevious={onClickPrevious}
          onSave={onSave}
          attendanceStatuses={attendanceStatuses}
          previousButtonDisabled={previousButtonDisabled}
          selectedPeriod={selectedPeriod}
          selectedStudent={selectedStudent}
        />
      </div>
    </div>
  )
}
