import type { AVVActions, AVVState } from '@/sections/avv/types/avv'
import {
  GET_UPCOMING_ACTIVITIES_FAILURE,
  GET_UPCOMING_ACTIVITIES_REQUEST,
  GET_UPCOMING_ACTIVITIES_SUCCESS,
  REMOVE_DELETED_ACTIVITY,
  REMOVE_EXPIRED_ACTIVITIES,
} from '@/sections/avv/types/avv'
import moment from 'moment'

const initialState: AVVState = {
  loading: false,
  upcomingActivities: [],
  error: '',
}

function root(state = initialState, action: AVVActions): AVVState {
  switch (action.type) {
    case GET_UPCOMING_ACTIVITIES_REQUEST:
      return { ...state, loading: true }
    case GET_UPCOMING_ACTIVITIES_SUCCESS: {
      const { upcomingActivities } = action.payload

      const filtered = upcomingActivities.filter(
        (act) => !state.upcomingActivities.find((a) => a.id === act.id)
      )

      return {
        ...state,
        loading: false,
        upcomingActivities: [...state.upcomingActivities, ...filtered],
      }
    }
    case GET_UPCOMING_ACTIVITIES_FAILURE:
      return { ...state, error: action.payload.error, loading: false }
    case REMOVE_EXPIRED_ACTIVITIES:
      return {
        ...state,
        upcomingActivities: state.upcomingActivities.filter((act) =>
          moment(act.endDate).isAfter(moment())
        ),
      }
    case REMOVE_DELETED_ACTIVITY:
      return {
        ...state,
        upcomingActivities: state.upcomingActivities.filter(
          (act) => act.id !== action.payload.activityId
        ),
      }
    default:
      return state
  }
}

export default root
