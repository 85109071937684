import { FeatureFlag } from '@/api/lms/features';
import { REQUEST_STATUS } from '@/legacy/redux/types/status';

// State.
export interface AddEditFeatureFlagsModalState {
    isOpen: boolean
    isEditMode: boolean
    status: REQUEST_STATUS
    selectedFeature: FeatureFlag
};

// Types.
export enum ADD_EDIT_FEATURE_FLAGS_MODAL {
    OPEN_MODAL = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/ADD_EDIT_MODAL/OPEN_MODAL',
    TOGGLE_EDIT_MODE = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/ADD_EDIT_MODAL/TOGGLE_EDIT_MODE',
    SET_FEATURE_TO_EDIT = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/ADD_EDIT_MODAL/SET_FEATURE_TO_EDIT',
    CLEAR_MODAL = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/ADD_EDIT_MODAL/CLEAR_MODAL',
    CREATE_FEATURE_FLAG_REQUEST = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/ADD_EDIT_MODAL/CREATE_FEATURE_FLAG_REQUEST',
    CREATE_FEATURE_FLAG_SUCCESS = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/ADD_EDIT_MODAL/CREATE_FEATURE_FLAG_SUCCESS',
    CREATE_FEATURE_FLAG_FAILURE = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/ADD_EDIT_MODAL/CREATE_FEATURE_FLAG_FAILURE',
    EDIT_FEATURE_FLAG_REQUEST = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/ADD_EDIT_MODAL/EDIT_FEATURE_FLAG_REQUEST',
    EDIT_FEATURE_FLAG_SUCCESS = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/ADD_EDIT_MODAL/EDIT_FEATURE_FLAG_SUCCESS',
    EDIT_FEATURE_FLAG_FAILURE = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/ADD_EDIT_MODAL/EDIT_FEATURE_FLAG_FAILURE',
};

// Actions.
// Open modal action.
export type OpenModalAction = {
    type: ADD_EDIT_FEATURE_FLAGS_MODAL.OPEN_MODAL,
};

// Toggle edit mode action.
export type ToggleEditModeAction = {
    type: ADD_EDIT_FEATURE_FLAGS_MODAL.TOGGLE_EDIT_MODE,
}

// Set feature to edit action.
export type SetFeatureToEditAction = {
    type: ADD_EDIT_FEATURE_FLAGS_MODAL.SET_FEATURE_TO_EDIT,
    payload: FeatureFlag,
}

// Clear modal action.
export type ClearModalAction = {
    type: ADD_EDIT_FEATURE_FLAGS_MODAL.CLEAR_MODAL,
}

// Create feature flag actions.
export type CreateFeatureFlagRequestAction = {
    type: ADD_EDIT_FEATURE_FLAGS_MODAL.CREATE_FEATURE_FLAG_REQUEST,
}

export type CreateFeatureFlagSuccessAction = {
    type: ADD_EDIT_FEATURE_FLAGS_MODAL.CREATE_FEATURE_FLAG_SUCCESS,
}

export type CreateFeatureFlagFailureAction = {
    type: ADD_EDIT_FEATURE_FLAGS_MODAL.CREATE_FEATURE_FLAG_FAILURE,
}

export type CreateFeatureFlagAction =
    | CreateFeatureFlagRequestAction
    | CreateFeatureFlagSuccessAction
    | CreateFeatureFlagFailureAction;

// Edit feature flag actions.
export type EditFeatureFlagRequestAction = {
    type: ADD_EDIT_FEATURE_FLAGS_MODAL.EDIT_FEATURE_FLAG_REQUEST,
}

export type EditFeatureFlagSuccessAction = {
    type: ADD_EDIT_FEATURE_FLAGS_MODAL.EDIT_FEATURE_FLAG_SUCCESS,
}

export type EditFeatureFlagFailureAction = {
    type: ADD_EDIT_FEATURE_FLAGS_MODAL.EDIT_FEATURE_FLAG_FAILURE,
}

export type EditFeatureFlagAction =
    | EditFeatureFlagRequestAction
    | EditFeatureFlagSuccessAction
    | EditFeatureFlagFailureAction;

// Action.
export type AddEditFeatureFlagsModalAction =
    | OpenModalAction
    | ToggleEditModeAction
    | SetFeatureToEditAction
    | ClearModalAction
    | CreateFeatureFlagAction
    | EditFeatureFlagAction;