import type { LocaleSpecification } from 'moment'

export const ko: LocaleSpecification = {
  months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  monthsShort: [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ],
  weekdays: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
  weekdaysShort: ['일', '월', '화', '수', '목', '금', '토'],
  weekdaysMin: ['일', '월', '화', '수', '목', '금', '토'],
  longDateFormat: {
    LT: 'A h:mm',
    LTS: 'A h:mm:ss',
    L: 'YYYY/MM/DD',
    LL: 'YYYY년 MMMM D일',
    LLL: 'YYYY년 MMMM D일 LT',
    LLLL: 'YYYY년 MMMM D일 dddd LT',
  },
  calendar: {
    sameDay: function () {
      return '[오늘] LT'
    },
    nextDay: function () {
      return '[내일] LT'
    },
    nextWeek: function () {
      return 'dddd [at] LT'
    },
    lastDay: function () {
      return '[어제] LT'
    },
    lastWeek: function () {
      return 'dddd [at] LT'
    },
    sameElse: 'L',
  },
  relativeTime: {
    future: '%s 후',
    past: '%s 전',
    s: '몇 초',
    m: '일 분',
    mm: '%d 분',
    h: '한 시간',
    hh: '%d 시간',
    d: '하루',
    dd: '%d 일',
    M: '한 달',
    MM: '%d 달',
    y: '일 년',
    yy: '%d 년',
  },
  ordinalParse: /\d{1,2}(일|월|화|수|목|금|토)/,
  ordinal: function (val: number) {
    return val + '일'
  },
  week: {
    dow: 0,
    doy: 4,
  },
}
