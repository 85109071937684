import { combineReducers } from 'redux'
import messages from './reducer/messages'
import classroomsPagination from './reducer/classroomsPagination'

const rootReducer = combineReducers({
  messages,
  classroomsPagination,
})

export default rootReducer
