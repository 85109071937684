import type { CancelTokenSource } from 'axios'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { AttachFileCustom } from '@/legacy/components/v3/files/fileUploader/types'

export interface UploadFilesState {
  error: Error | null
  loading: boolean
  status: REQUEST_STATUS
  uploadedFiles: UploadFile[]
  attachedFiles: AttachFileCustom[]
  uploadedFilesProgress: Map<number, UploadFileProgress>
  uploadedFilesProgressTotal: number
  uploadedFilesProgressPercent: number
  uploadedFilesCancelTokens: CancelTokenSource[]
}

export enum ATTACH_FILES_TYPES {
  ADD_ATTACH_FILE = 'TEACHER/BULLETIN/ATTACH_FILES/ADD_ATTACH_FILE',
  ADD_ATTACH_FILES = 'TEACHER/BULLETIN/ATTACH_FILES/ADD_ATTACH_FILES',
  REMOVE_ATTACH_FILE = 'TEACHER/BULLETIN/ATTACH_FILES/REMOVE_ATTACH_FILE',
  RESET_ATTACH_FILES = 'TEACHER/BULLETIN/ATTACH_FILES/RESET_ATTACH_FILES',
  UPLOAD_FILES = 'TEACHER/BULLETIN/ATTACH_FILES/UPLOAD_FILES',
  UPLOAD_FILES_FAILURE = 'TEACHER/BULLETIN/ATTACH_FILES/UPLOAD_FILES_FAILURE',
  UPLOAD_FILES_SUCCESS = 'TEACHER/BULLETIN/ATTACH_FILES/UPLOAD_FILES_SUCCESS',
  UPLOAD_FILES_CANCEL = 'TEACHER/BULLETIN/ATTACH_FILES/UPLOAD_FILES_CANCEL',
  SET_UPLOAD_FILE_PROGRESS = 'TEACHER/BULLETIN/ATTACH_FILES/SET_UPLOAD_FILE_PROGRESS',
  SET_UPLOAD_FILE_PROGRESS_TOTAL = 'TEACHER/BULLETIN/ATTACH_FILES/SET_UPLOAD_FILE_PROGRESS_TOTAL',
  SET_UPLOAD_FILES_CANCEL_TOKEN = 'TEACHER/BULLETIN/ATTACH_FILES/SET_UPLOAD_FILES_CANCEL_TOKEN',
}

export interface UploadFile {
  id: number
  url: string
  name: string
  type: string
  description?: string
}

export interface ProgressEvent {
  total: number
  loaded: number
}
export interface UploadFileProgress {
  loaded: number
}

export type AttachFile = {
  type: ATTACH_FILES_TYPES.ADD_ATTACH_FILE
  payload: {
    file: AttachFileCustom
  }
}

export type AttachFiles = {
  type: ATTACH_FILES_TYPES.ADD_ATTACH_FILES
  payload: {
    files: AttachFileCustom[]
  }
}

export type RemoveAttachFile = {
  type: ATTACH_FILES_TYPES.REMOVE_ATTACH_FILE
  payload: {
    id: number
  }
}

export type SetUploadFileProgressTotal = {
  type: ATTACH_FILES_TYPES.SET_UPLOAD_FILE_PROGRESS_TOTAL
  payload: {
    total: number
  }
}

export type SetUploadFileProgress = {
  type: ATTACH_FILES_TYPES.SET_UPLOAD_FILE_PROGRESS
  payload: {
    id: number
    loaded: number
  }
}

export type SetUploadFilesCancelToken = {
  type: ATTACH_FILES_TYPES.SET_UPLOAD_FILES_CANCEL_TOKEN
  payload: {
    token: CancelTokenSource
  }
}

export type UploadFiles = {
  type: ATTACH_FILES_TYPES.UPLOAD_FILES
}

export type UploadFilesSuccess = {
  type: ATTACH_FILES_TYPES.UPLOAD_FILES_SUCCESS
  payload: {
    uploadedFiles: UploadFile[]
  }
}

export type UploadFilesError = {
  type: ATTACH_FILES_TYPES.UPLOAD_FILES_FAILURE
  payload: {
    error: Error
  }
}

export type UploadFilesCancel = {
  type: ATTACH_FILES_TYPES.UPLOAD_FILES_CANCEL
}

export type ResetAttachFiles = {
  type: ATTACH_FILES_TYPES.RESET_ATTACH_FILES
}

export type UploadFilesActions =
  | SetUploadFilesCancelToken
  | SetUploadFileProgressTotal
  | SetUploadFileProgress
  | UploadFiles
  | UploadFilesSuccess
  | UploadFilesError
  | UploadFilesCancel
  | AttachFile
  | AttachFiles
  | RemoveAttachFile
  | ResetAttachFiles
