import * as React from 'react'
import type { SVGProps } from 'react'
const SvgFriday = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.645 1v3h10V1h2v3h2a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3h-18a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h2V1h2Zm12 5h-16a1 1 0 0 0-1 1v2h20V7a1 1 0 0 0-1-1h-2Zm-17 5v10a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V11h-20Z"
      fill="#1A1A1A"
    />
    <path
      d="m8.15 19.47-2.304-6.3h1.233l1.782 5.12 1.79-5.12h1.216l-2.304 6.3H8.15Zm4.466 0v-6.3h1.152v6.3h-1.152Zm2.373 0v-6.3h4.113v.927H16.14v1.728h2.69v.9h-2.69v1.818h2.96v.927H14.99Z"
      fill="#000"
    />
  </svg>
)
export default SvgFriday
