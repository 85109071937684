import moment from 'moment'
import { ResourceTypeActivity, ResourceTypeBook } from '@/api/lms/library'
import {
  BookLicenses,
  Classroom,
  Organization,
  Subject,
  Teacher,
  Exams,
  FeatureFlag,
  Audience,
} from '@/legacy/svgs/eb-admin/dashboard'

export const LIMIT = 10
export const ALL_ORGANIZATIONS = { label: 'ebAdmin.dashboard.allOrganizations', value: 0 }

export const staticticsMap = {
  organizations: {
    Icon: () => <Organization />,
    label: 'dashboard.organizations',
    backgroundIcon: 'rgba(2,207,170, 0.15)',
  },
  classrooms: {
    Icon: () => <Classroom />,
    label: 'dashboard.classrooms',
    backgroundIcon: 'rgba(39,168,255, 0.15)',
  },
  subjects: {
    Icon: () => <Subject />,
    label: 'dashboard.subjects',
  },
  bookLicenses: {
    Icon: () => <BookLicenses />,
    label: 'dashboard.bookLicenses',
  },
  premiumActivityLicenses: {
    Icon: () => <Subject />,
    label: 'dashboard.premiumActivityLicenses',
    backgroundIcon: 'rgba(255,184,0, 0.15)',
  },
  users: {
    Icon: () => <Teacher />,
    label: 'dashboard.users.users',
    backgroundIcon: 'rgba(255,184,0, 0.15)',
  },
  exams: {
    Icon: () => <Exams />,
    label: 'dashboard.exams',
    backgroundIcon: 'rgba(155,0,175, 0.15)',
  },
  featureFlags: {
    Icon: () => <FeatureFlag />,
    label: 'dashboard.featureFlags',
  },
  audience: {
    Icon: () => <Audience />,
    label: 'dashboard.audience',
  },
}

export const roleOptions = [
  { value: 'teacher', label: 'roleOptions.teacher' },
  { value: 'student', label: 'roleOptions.student' },
  { value: 'principal', label: 'roleOptions.principal' },
  { value: 'vice-principal', label: 'roleOptions.vicePrincipal' },
  { value: 'class-teacher', label: 'roleOptions.classTeacher' },
  { value: 'eb-admin', label: 'roleOptions.ebAdmin' },
  { value: 'content-creator', label: 'roleOptions.contentCreator' },
]

export const organizationTypeOptions = [
  { value: 'private', label: 'organizationTypeOptions.private' },
  { value: 'public', label: 'organizationTypeOptions.public' },
]

export const CLASSROOM_DEFAULT_STATE = {
  id: 0,
  organization: null,
  organizationId: 0,
  stage: '',
  grade: '',
  division: '',
  shift: '',
  year: moment().year(),
  classTeacherId: 0,
  classTeacherName: '',
}

export const STUDENT_DEFAULT_STATE = {
  docType: 1,
  dateOfBirth: null,
  identification: '',
  email: '',
  gender: '',
  lastName: '',
  mainRole: 'student',
  name: '',
  phone: '',
  organization: null,
  altRoles: [],
  classroom: null,
  subjectOption: null,
}

export const TEACHER_DEFAULT_STATE = {
  docType: 1,
  dateOfBirth: null,
  identification: '',
  email: '',
  gender: '',
  lastName: '',
  mainRole: 'teacher',
  name: '',
  phone: '',
  organization: null,
  altRoles: [],
}

export const ORGANIZATION_DEFAULT_STATE = {
  name: '',
  countryId: 0,
  state: '',
  city: '',
  type: 'private',
  address: '',
  phone: '',
  legalRepName: '',
  legalRepPhone: '',
  legalRepEmail: '',
}

export const SUBJECT_DEFAULT_STATE = {
  id: null,
  color: '#FFFFFF',
  name: '',
}

export const ASSIGNMENT_DEFAULT_STATE = {
  organization: null,
  teacher: null,
  classroom: null,
  subject: null,
}

export const BOOK_LICENSE_DEFAULT_STATE = {
  id: 0,
  createdAt: null,
  resourceId: 0,
  resourceType: ResourceTypeBook,
  organizationId: 0,
  expiry: moment().clone().add(1, 'days'),
  amount: 0,
  remaining: 0,
  title: '',
}

export const PREMIUM_ACTIVITY_LICENSE_DEFAULT_STATE = {
  id: 0,
  createdAt: null,
  resourceId: 0,
  resourceType: ResourceTypeActivity,
  organizationId: 0,
  expiry: moment().clone().add(1, 'days'),
  amount: 0,
  remaining: 0,
  title: '',
}
