import type { StudentFinalAppreciation } from '@/api/report-card/finalAppreciations'
import type { SetField } from '@/legacy/redux/types/generic'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'

export interface FinalAppreciationsState {
  loading: boolean
  studentFinalAppreciations: StudentFinalAppreciation[]
  selectedStudentIndex: number
  isNext: boolean
}

export const SET_SELECTED_STUDENT_INDEX =
  'REPORT_CARD/GRADING/TABS/FINAL_APPRECIATIONS/SET_SELECTED_STUDENT_INDEX'
export const GET_FINAL_APPRECIATIONS_REQUEST =
  'REPORT_CARD/GRADING/TABS/FINAL_APPRECIATIONS/GET_FINAL_APPRECIATIONS_REQUEST'
export const GET_FINAL_APPRECIATIONS_SUCCESS =
  'REPORT_CARD/GRADING/TABS/FINAL_APPRECIATIONS/GET_FINAL_APPRECIATIONS_SUCCESS'
export const GET_FINAL_APPRECIATIONS_FAILURE =
  'REPORT_CARD/GRADING/TABS/FINAL_APPRECIATIONS/GET_FINAL_APPRECIATIONS_FAILURE'
export const UPDATE_FINAL_APPRECIATION_REQUEST =
  'REPORT_CARD/GRADING/TABS/FINAL_APPRECIATIONS/UPDATE_FINAL_APPRECIATION_REQUEST'
export const UPDATE_FINAL_APPRECIATION_SUCCESS =
  'REPORT_CARD/GRADING/TABS/FINAL_APPRECIATIONS/UPDATE_FINAL_APPRECIATION_SUCCESS'
export const UPDATE_FINAL_APPRECIATION_FAILURE =
  'REPORT_CARD/GRADING/TABS/FINAL_APPRECIATIONS/UPDATE_FINAL_APPRECIATION_FAILURE'

export type SetSelectedStudentIndex = GenericActionWithType<
  typeof SET_SELECTED_STUDENT_INDEX,
  { selectedStudentIndex: number }
>
export type GetFinalAppreciationsRequest = GenericActionWithType<
  typeof GET_FINAL_APPRECIATIONS_REQUEST
>
export type GetFinalAppreciationsSuccess = GenericActionWithType<
  typeof GET_FINAL_APPRECIATIONS_SUCCESS,
  { studentFinalAppreciations: StudentFinalAppreciation[] }
>
export type GetFinalAppreciationsFailure = GenericActionWithType<
  typeof GET_FINAL_APPRECIATIONS_FAILURE,
  ErrorPayload
>
export type UpdateFinalAppreciationRequest = GenericActionWithType<
  typeof UPDATE_FINAL_APPRECIATION_REQUEST
>
export type UpdateFinalAppreciationSuccess = GenericActionWithType<
  typeof UPDATE_FINAL_APPRECIATION_SUCCESS
>
export type UpdateFinalAppreciationFailure = GenericActionWithType<
  typeof UPDATE_FINAL_APPRECIATION_FAILURE,
  ErrorPayload
>

export type FinalAppreciationsActions =
  | SetField<'isNext', boolean>
  | SetSelectedStudentIndex
  | GetFinalAppreciationsRequest
  | GetFinalAppreciationsSuccess
  | GetFinalAppreciationsFailure
  | UpdateFinalAppreciationRequest
  | UpdateFinalAppreciationSuccess
  | UpdateFinalAppreciationFailure
