import { combineReducers } from 'redux'
import abscences from '@/sections/report-card/grading/reducers/abscences'
import attitudinalScoresTab from './attitudinalScoresTab'
import competenceScoresTab from './competenceScoresTab'
import pendingSubjects from './pendingSubjects'
import reviewsTab from './reviewsTab'
import scoresTab from './scoresTab'
import finalAppreciations from '@/sections/report-card/grading/reducers/finalAppreciations'

export default combineReducers({
  abscences,
  attitudinalScoresTab,
  competenceScoresTab,
  pendingSubjects,
  reviewsTab,
  scoresTab,
  finalAppreciations,
})
