import React from 'react'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import { generateRandomColor } from '@aula/tools/color'

interface StylesProps {
  color: string
  bgColor: string
}
const useStyles = makeStyles<void, StylesProps>(() => ({
  chip: ({ color, bgColor }) => ({
    backgroundColor: bgColor,
    color,
    display: 'flex',
    alignItems: 'center',
    padding: '2px 4px',
    borderRadius: 2,
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'background-color 100ms',
  }),
  label: ({ color }) => ({
    color,
    fontSize: 12,
    padding: 0,
    lineHeight: '16px',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
}))
export interface BaseChipProps {
  label: string
  color?: string
  bgColor?: string
  className?: string
}

const BaseChip: React.FC<BaseChipProps> = ({ label, color, bgColor, className, ...props }) => {
  const randomColor = generateRandomColor(label)
  const classes = useStyles({ color: color ?? randomColor, bgColor: bgColor ?? randomColor })
  return (
    <div className={clsx(classes.chip, className)} {...props}>
      <Typography className={classes.label}>{label}</Typography>
    </div>
  )
}

export default BaseChip
