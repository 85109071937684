import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import {
  GET_USERS_AS_MANAGEMENT_FAILURE,
  GET_USERS_AS_MANAGEMENT_REQUEST,
  GET_USERS_AS_MANAGEMENT_SUCCESS,
} from '@/legacy/redux/types/managementUsers'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import { getManagementGender } from '@/legacy/utils/getManagementGender'
import type {
  UsersDrawerAction,
  UsersDrawerState,
} from '@/sections/management/types/users/usersDrawer'

const initialState: UsersDrawerState = {
  users: [],
  loading: false,
  pagination: {
    page: 0,
    rowsPerPage: 12,
    count: -1, // See https://v4.mui.com/api/table-pagination/#tablepagination-api
  },
  error: '',
  search: '',
  sort: 'id',
  sortDir: 'desc',
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_USERS_DRAWER

const AdminUsersDrawerReducer: Reducer<UsersDrawerState, UsersDrawerAction> = (
  state = initialState,
  action
): UsersDrawerState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case GET_USERS_AS_MANAGEMENT_REQUEST:
      return {
        ...state,
        loading: true,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    case GET_USERS_AS_MANAGEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.users.map((user) => {
          return { ...user, gender: getManagementGender(user.gender) }
        }),
        pagination: {
          ...state.pagination,
        },
      }
    case GET_USERS_AS_MANAGEMENT_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, AdminUsersDrawerReducer, requestDestination)
