import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import type {
  CollabMiniatureAssignAction,
  CollabMiniatureAssignState,
  CollabStudent,
} from '@/sections/teacher/activities/types/collabMiniatureAssign'
import {
  ASSIGN_STUDENTS_FAILURE,
  ASSIGN_STUDENTS_REQUEST,
  ASSIGN_STUDENTS_SUCCESS,
  SET_OPEN,
  SET_STUDENT_AS_LEADER,
  SET_STUDENT_SCENARIO,
} from '@/sections/teacher/activities/types/collabMiniatureAssign'
import type { Reducer } from 'redux'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'

const initialState: CollabMiniatureAssignState = {
  scenarios: [],
  students: [],
  studentsDistribution: [],
  step: 'distribute',
  loading: false,
  error: '',
  open: false,
  activityId: null,
  classroomId: null,
  studentsPagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0,
  },
}

const requestDestination = reduxRequestOriginMap.COLLAB_MINIATURE_ASSIGNMENT

const CollabMiniature: Reducer<CollabMiniatureAssignState, CollabMiniatureAssignAction> = (
  state = initialState,
  action
): CollabMiniatureAssignState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD: {
      return {
        ...setGenericField(state, action.payload),
      }
    }
    case Redux.Types.Students.LIST_ACTIVITY_STUDENTS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
        studentsPagination: {
          ...state.studentsPagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    }
    case Redux.Types.Students.LIST_ACTIVITY_STUDENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        students: action.payload.students,
        studentsPagination: {
          ...state.studentsPagination,
          count: action.payload.count,
        },
      }
    }
    case Redux.Types.Students.LIST_ACTIVITY_STUDENTS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    }
    case ASSIGN_STUDENTS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: initialState.error,
      }
    }
    case ASSIGN_STUDENTS_SUCCESS: {
      return initialState
    }
    case ASSIGN_STUDENTS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    }
    case SET_OPEN: {
      const { open, activityId, classroomId, scenarios } = action.payload

      return {
        ...state,
        open,
        scenarios: scenarios || initialState.scenarios,
        activityId: activityId || initialState.activityId,
        classroomId: classroomId || initialState.classroomId,
      }
    }
    case SET_STUDENT_AS_LEADER: {
      const { student } = action.payload

      const newStudents: CollabStudent[] = state.studentsDistribution.map<CollabStudent>((st) => {
        if (student.reservationId === st.reservationId)
          return { ...st, role: st.id === student.id ? 'leader' : 'observer' }
        else return st
      })

      return {
        ...state,
        studentsDistribution: newStudents,
      }
    }
    case SET_STUDENT_SCENARIO: {
      const { newReservationId, student } = action.payload
      let distributed: CollabStudent[] = state.studentsDistribution
      const existingDistributed = distributed.find((st) => st.id === student.id)
      if (!existingDistributed) {
        distributed.push({
          ...student,
          role: 'observer',
          reservationId: newReservationId,
        })
      } else {
        if (newReservationId) {
          distributed = distributed.map<CollabStudent>((st) =>
            st.id === student.id ? { ...st, role: 'observer', reservationId: newReservationId } : st
          )
        } else {
          distributed = distributed.filter((st) => st.id !== student.id)
        }
      }

      return {
        ...state,
        studentsDistribution: distributed,
      }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, CollabMiniature, requestDestination)
