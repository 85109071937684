import React from 'react'
import { makeStyles } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Tabs from './tabs'
import UserAvatar from './userAvatar'
import NewMessages from '@/legacy/components/v1/svgs/header/newMessages'
import Messages from '@/legacy/components/v1/svgs/header/messages'
import Logo from '@/legacy/components/v1/svgs/logo'
import CustomTooltip from '@/legacy/components/v1/atomic/v2/customTooltip'

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.25)',
    height: 72,
    background: '#fff',
    minWidth: 972,
  },
  tabsContainer: {
    width: 'fit-content',
    margin: 'auto',
  },
  logoContainer: ({ button }) => ({
    marginRight: button ? 207 : 0,
  }),
  menuButton: {
    background: 'none',
  },
  title: {
    flexGrow: 1,
    justifyItems: 'center',
  },
  buttonContainer: {
    marginRight: 20,
    display: 'inline',
  },
}))

export default function MenuAppBar(props) {
  const {
    newMessages = false,
    openMessages = () => {},
    tabs,
    tabsChange,
    tabValue,
    name,
    lastName,
    Button,
    showMessageButton = true,
    menuItems = [],
  } = props
  const classes = useStyles({ button: !!Button })

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const avatarName = name + ' ' + lastName

  const handleClickOnLogo = () => props.onLogoClick()

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItem = (clickFn) => {
    handleClose()
    clickFn()
  }

  return (
    <AppBar
      position="fixed"
      color="transparent"
      className={classes.appBar}
      component="div" // Scripts from the landing site add effects we don't want to headers
    >
      <Toolbar>
        <IconButton
          disableRipple
          edge="start"
          aria-label="menu"
          color="inherit"
          onClick={handleClickOnLogo}
          className={classes.logoContainer}
        >
          <Avatar className={classes.menuButton}>
            <Logo />
          </Avatar>
        </IconButton>
        <div className={classes.title}>
          <div className={classes.tabsContainer}>
            <Tabs onChange={tabsChange} tabs={tabs} value={tabValue} />
          </div>
        </div>
        <div>
          {Button && (
            <div className={classes.buttonContainer}>
              <Button />
            </div>
          )}
          {showMessageButton && (
            <IconButton
              className={classes.messageButton}
              disableRipple
              size="small"
              color="inherit"
              onClick={openMessages}
            >
              <CustomTooltip title="Abrir chat" topOffset={6}>
                {newMessages ? <NewMessages /> : <Messages />}
              </CustomTooltip>
            </IconButton>
          )}
          <IconButton
            disableRipple
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <UserAvatar name={avatarName} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            {menuItems.map(({ onClick, label }, index) => (
              <MenuItem key={index} onClick={() => handleMenuItem(onClick)}>
                {label}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  )
}
