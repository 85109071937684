import type { AcademicPeriod } from '@/api/lms/user'
import type { Pages } from '@/legacy/components/v3/moreTablePagination'
import type { SetField } from '@/legacy/redux/types/generic'
import type { ErrorPayload } from '@/legacy/redux/utils'
import type { ReportCardVersion } from '@/legacy/services/api/web/audit/types'
import type { Classroom } from '@/sections/principal/classroom-migration/types/migrationClassrooms'

export interface ReportCardAuditState {
  error: string
  loadingClassrooms: boolean
  loadingVersions: boolean
  classrooms: Classroom[]
  versions: ReportCardVersion[]
  selectedClassroom?: Classroom
  selectedAcademicPeriod: AcademicPeriod
  pagination: Pages
  versionIdToDownload: number
  isOpenAddressesModal: boolean
  sendBulletinsModal: {
    isOpen: boolean
    versionToSend: ReportCardVersion | null
    isLoading: boolean
    error: Error | null
  }
}

export const GET_CLASSROOMS_REQUEST = 'REPORT_CARD/AUDIT/GET_CLASSROOMS_REQUEST'
export const GET_CLASSROOMS_SUCCESS = 'REPORT_CARD/AUDIT/GET_CLASSROOMS_SUCCESS'
export const GET_CLASSROOMS_FAILURE = 'REPORT_CARD/AUDIT/GET_CLASSROOMS_FAILURE'

export type GetClassroomsRequest = { type: typeof GET_CLASSROOMS_REQUEST }
export type GetClassroomsSuccess = {
  type: typeof GET_CLASSROOMS_SUCCESS
  payload: { classrooms: Classroom[] }
}
export type GetClassroomsFailure = {
  type: typeof GET_CLASSROOMS_FAILURE
  payload: ErrorPayload
}

export const GET_REPORT_CARD_VERSIONS_REQUEST = 'REPORT_CARD/AUDIT/GET_REPORT_CARD/VERSIONS_REQUEST'
export const GET_REPORT_CARD_VERSIONS_SUCCESS = 'REPORT_CARD/AUDIT/GET_REPORT_CARD/VERSIONS_SUCCESS'
export const GET_REPORT_CARD_VERSIONS_FAILURE = 'REPORT_CARD/AUDIT/GET_REPORT_CARD/VERSIONS_FAILURE'

export type GetReportCardVersionsRequest = { type: typeof GET_REPORT_CARD_VERSIONS_REQUEST }
export type GetReportCardVersionsSuccess = {
  type: typeof GET_REPORT_CARD_VERSIONS_SUCCESS
  payload: { versions: ReportCardVersion[]; count: number; more: boolean; page: number }
}
export type GetReportCardVersionsFailure = {
  type: typeof GET_REPORT_CARD_VERSIONS_FAILURE
  payload: ErrorPayload
}

export const SET_VERSION_ID_TO_DOWNLOAD = 'REPORT_CARD/AUDIT/SET_VERSION_ID_TO_DOWNLOAD'

export type SetVersionIdToDownloadAction = {
  type: typeof SET_VERSION_ID_TO_DOWNLOAD
  payload: {
    versionId: number
  }
}

export const TOGGLE_ADDRESSES_MODAL_OPEN = 'REPORT_CARD/AUDIT/TOGGLE_ADDRESSES_MODAL_OPEN'

export type ToggleAddressesModalOpenAction = {
  type: typeof TOGGLE_ADDRESSES_MODAL_OPEN
  payload: {
    canOpenModal: boolean
  }
}

export const TOGGLE_SEND_BULLETINS_MODAL_OPEN = 'REPORT_CARD/AUDIT/TOGGLE_SEND_BULLETINS_MODAL_OPEN'

export type ToggleSendBulletinsModalOpenAction = {
  type: typeof TOGGLE_SEND_BULLETINS_MODAL_OPEN
  payload: {
    canOpenModal: boolean
  }
}

export const SET_VERSION_TO_SEND = 'REPORT_CARD/AUDIT/SET_VERSION_TO_SEND'

export type SetVersionToSendAction = {
  type: typeof SET_VERSION_TO_SEND
  payload: {
    version: ReportCardVersion
  }
}

export const SEND_BULLETINS_REQUEST = 'REPORT_CARD/AUDIT/SEND_BULLETINS_REQUEST'
export const SEND_BULLETINS_SUCCESS = 'REPORT_CARD/AUDIT/SEND_BULLETINS_SUCCESS'
export const SEND_BULLETINS_FAILURE = 'REPORT_CARD/AUDIT/SEND_BULLETINS_FAILURE'

export type SendBulletinsRequestAction = {
  type: typeof SEND_BULLETINS_REQUEST
}

export type SendBulletinsSuccessAction = {
  type: typeof SEND_BULLETINS_SUCCESS
}

export type SendBulletinsFailureAction = {
  type: typeof SEND_BULLETINS_FAILURE
  payload: {
    error: Error
  }
}

export type ReportCardAuditAction =
  | SetField<'selectedAcademicPeriod' | 'selectedClassroom', AcademicPeriod | Classroom | undefined>
  | GetClassroomsRequest
  | GetClassroomsSuccess
  | GetClassroomsFailure
  | GetReportCardVersionsRequest
  | GetReportCardVersionsSuccess
  | GetReportCardVersionsFailure
  | SetVersionIdToDownloadAction
  | ToggleAddressesModalOpenAction
  | ToggleSendBulletinsModalOpenAction
  | SetVersionToSendAction
  | SendBulletinsRequestAction
  | SendBulletinsSuccessAction
  | SendBulletinsFailureAction
