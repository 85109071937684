import type { COSMOS_KEY } from '@aula/config'
import type {
  AcademicPeriod,
  BasicAudience,
  ManagementOrganization,
  Profile,
  VisibilityTag,
  Idj,
} from '@/api/lms/organizations'
import type { GenericEntity } from '@/api/types'
import type { Option } from '@/legacy/components/v3/selects/selectSimple'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { SetField } from '@/legacy/redux/types/generic'
import type { Plan } from '@/legacy/redux/types/plans'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type { IdjType } from '@/services/api/lms/idj/v1/getIdjTypes'

export type AddEditOrganizationsModalState = {
  open: boolean
  loading: boolean
  error: string
  editMode: boolean
  detailsMode: boolean
  managementOptions: Option[]
  planOptions: Plan[]
  countriesOptions: Option[]
  provincesOptions: Option[]
  orgId: number
  name: string
  management: Option
  country: Option
  province: Option
  plan: Plan
  address: string
  phone: string
  legalRepresentativeName: string
  legalRepresentativeEmail: string
  url: string
  stages: GenericEntity[]
  shifts: GenericEntity[]
  profiles: Profile[]
  organizationprofiles: Profile[]
  academicPeriods: AcademicPeriod[]
  emblem: string
  emblemFile: File | null
  logo: string
  logoFile: File | null
  signature: string
  signatureFile: File | null
  seal: string
  sealFile: File | null
  cosmos: COSMOS_KEY[]
  cue: string
  visibilityTags: VisibilityTag[]
  timeZone: string
  audiences: BasicAudience[]
  isDemo: boolean
  removeAt: string | null
  idjs: ModalIdj[]
}

export type ModalIdj = Idj & Omit<IdjType, 'id' | 'name'> & { error: string }

interface SetModalInitialValuesPayload {
  organization: ManagementOrganization
  provincesOptions: GenericEntity[]
  requestOrigins: ReduxRequestOrigin[]
  cosmos?: COSMOS_KEY[]
  activeFeaturesIds: number[]
}

export const CLEAR_FIELDS = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/CLEAR_FIELDS'
// Get countries
export const GET_COUNTRIES_REQUEST = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/GET_COUNTRIES_REQUEST'
export const GET_COUNTRIES_SUCCESS = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/GET_COUNTRIES_SUCCESS'
export const GET_COUNTRIES_FAILURE = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/GET_COUNTRIES_FAILURE'
// Get provinces
export const GET_PROVINCES_REQUEST = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/GET_PROVINCES_REQUEST'
export const GET_PROVINCES_SUCCESS = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/GET_PROVINCES_SUCCESS'
export const GET_PROVINCES_FAILURE = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/GET_PROVINCES_FAILURE'
// Get plans
export const GET_PLANS_REQUEST = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/GET_PLANS_REQUEST'
export const GET_PLANS_SUCCESS = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/GET_PLANS_SUCCESS'
export const GET_PLANS_FAILURE = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/GET_PLANS_FAILURE'
// Create organization
export const CREATE_ORGANIZATION_REQUEST =
  'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/CREATE_ORGANIZATION_REQUEST'
export const CREATE_ORGANIZATION_SUCCESS =
  'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/CREATE_ORGANIZATION_SUCCESS'
export const CREATE_ORGANIZATION_FAILURE =
  'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/CREATE_ORGANIZATION_FAILURE'
// Edit
export const SET_INITIAL_VALUES_REQUEST =
  'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/SET_INITIAL_VALUES_REQUEST'
export const SET_INITIAL_VALUES_SUCCESS =
  'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/SET_INITIAL_VALUES_SUCCESS'
export const SET_INITIAL_VALUES_FAILURE =
  'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/SET_INITIAL_VALUES_FAILURE'
export const EDIT_ORGANIZATION_REQUEST =
  'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/EDIT_ORGANIZATION_REQUEST'
export const EDIT_ORGANIZATION_SUCCESS =
  'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/EDIT_ORGANIZATION_SUCCESS'
export const EDIT_ORGANIZATION_FAILURE =
  'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/EDIT_ORGANIZATION_FAILURE'
export const TOGGLE_DEMO_CHECKBOX = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/TOGGLE_DEMO_CHECKBOX'
export const SET_ACADEMIC_PERIOD_START = 'MANAGEMENT/ORGANIZATIONS/SET_ACADEMIC_PERIOD_START'
export const SET_ACADEMIC_PERIOD_END = 'MANAGEMENT/ORGANIZATIONS/SET_ACADEMIC_PERIOD_END'

export type ClearAllFields = GenericActionWithType<typeof CLEAR_FIELDS>
// Get countries
export type GetCountriesRequest = GenericActionWithType<typeof GET_COUNTRIES_REQUEST>
export type GetCountriesSuccess = GenericActionWithType<
  typeof GET_COUNTRIES_SUCCESS,
  {
    countries: GenericEntity[]
    provinces: GenericEntity[]
  }
>
export type GetCountriesFailure = GenericActionWithType<typeof GET_COUNTRIES_FAILURE, ErrorPayload>
export type GetCountriesAction = GetCountriesRequest | GetCountriesSuccess | GetCountriesFailure
// Get provinces
export type GetProvincesRequest = GenericActionWithType<typeof GET_PROVINCES_REQUEST>
export type GetProvincesSuccess = GenericActionWithType<
  typeof GET_PROVINCES_SUCCESS,
  {
    provinces: GenericEntity[]
  }
>
export type GetProvincesFailure = GenericActionWithType<typeof GET_PROVINCES_FAILURE, ErrorPayload>
export type GetProvincesAction = GetProvincesRequest | GetProvincesSuccess | GetProvincesFailure
// Get plans
export type GetPlansRequest = GenericActionWithType<typeof GET_PLANS_REQUEST>
export type GetPlansSuccess = GenericActionWithType<
  typeof GET_PLANS_SUCCESS,
  {
    plans: Plan[]
  }
>
export type GetPlansFailure = GenericActionWithType<typeof GET_PLANS_FAILURE, ErrorPayload>
export type GetPlansAction = GetPlansRequest | GetPlansSuccess | GetPlansFailure

// Create organization
export type CreateOrganizationRequest = GenericActionWithType<typeof CREATE_ORGANIZATION_REQUEST>
export type CreateOrganizationSuccess = GenericActionWithType<typeof CREATE_ORGANIZATION_SUCCESS>
export type CreateOrganizationFailure = GenericActionWithType<
  typeof CREATE_ORGANIZATION_FAILURE,
  ErrorPayload
>
export type CreateOrganizationAction =
  | CreateOrganizationRequest
  | CreateOrganizationSuccess
  | CreateOrganizationFailure
// Edit
export type SetInitialValuesRequest = GenericActionWithType<typeof SET_INITIAL_VALUES_REQUEST>
export type SetInitialValuesSuccess = GenericActionWithType<
  typeof SET_INITIAL_VALUES_SUCCESS,
  SetModalInitialValuesPayload
>
export type SetInitialValuesFailure = GenericActionWithType<
  typeof SET_INITIAL_VALUES_FAILURE,
  ErrorPayload
>
export type SetInitialValueAction =
  | SetInitialValuesRequest
  | SetInitialValuesSuccess
  | SetInitialValuesFailure

export type EditOrganizationRequest = GenericActionWithType<typeof EDIT_ORGANIZATION_REQUEST>
export type EditOrganizationSuccess = GenericActionWithType<
  typeof EDIT_ORGANIZATION_SUCCESS,
  { organizationId: number; emblem: string }
>
export type EditOrganizationFailure = GenericActionWithType<
  typeof EDIT_ORGANIZATION_FAILURE,
  ErrorPayload
>
export type EditOrganizationAction =
  | EditOrganizationRequest
  | EditOrganizationSuccess
  | EditOrganizationFailure

export const CUSTOMIZE_PLAN = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/CUSTOMIZE_PLAN'
export type CustomizePlan = GenericActionWithType<
  typeof CUSTOMIZE_PLAN,
  { activeFeaturesIds: number[] }
>

export const ADD_PROFILE_TO_ORG = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/ADD_PROFILE_TO_ORG'
export type AddProfileToOrgAction = GenericActionWithType<
  typeof ADD_PROFILE_TO_ORG,
  { profile: Profile }
>

export const REMOVE_PROFILE = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/REMOVE_PROFILE'
export type RemoveProfileAction = GenericActionWithType<
  typeof REMOVE_PROFILE,
  { profileId: number }
>

export const EDIT_PROFILE = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/EDIT_PROFILE'
export type EditProfileAction = GenericActionWithType<
  typeof EDIT_PROFILE,
  { newProfileName: string; profileId: number }
>

export const SET_AUDIENCES = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/SET_AUDIENCES'
export type SetAudiencesAction = GenericActionWithType<
  typeof SET_AUDIENCES,
  { newAudiences: BasicAudience[] }
>

export type ToggleDemoCheckboxAction = GenericActionWithType<typeof TOGGLE_DEMO_CHECKBOX>

export type SetAcademicPeriodStartAction = GenericActionWithType<
  typeof SET_ACADEMIC_PERIOD_START,
  { index: number; date: string }
>

export type SetAcademicPeriodEndAction = GenericActionWithType<
  typeof SET_ACADEMIC_PERIOD_END,
  { index: number; date: string }
>

// Add idj action.
export const ADD_IDJ = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/ADD_IDJ'
export type AddIdjAction = GenericActionWithType<typeof ADD_IDJ, { newIdj: Idj }>

// Delete idj action.
export const DELETE_IDJ = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/DELETE_IDJ'
export type DeleteIdjAction = GenericActionWithType<typeof DELETE_IDJ, { id: number }>

// Edit idj action.
export const EDIT_IDJ = 'MANAGEMENT/ORGANIZATIONS/ADD_EDIT_MODAL/EDIT_IDJ'
export type EditIdjAction = GenericActionWithType<
  typeof EDIT_IDJ,
  Partial<ModalIdj> & { id: number }
>

export type AddEditOrganizationModalAction =
  | SetField<
      | 'name'
      | 'type'
      | 'management'
      | 'country'
      | 'state'
      | 'address'
      | 'phone'
      | 'legalRepresentativePhone'
      | 'legalRepresentativeName'
      | 'legalRepresentativeEmail'
      | 'url'
      | 'stages'
      | 'shifts'
      | 'academicPeriods'
      | 'plan'
      | 'emblem'
      | 'logo'
      | 'cue'
      | 'detailsMode',
      string | boolean | File | null
    >
  | ClearAllFields
  | GetPlansAction
  | GetCountriesAction
  | GetProvincesAction
  | CreateOrganizationAction
  | SetInitialValueAction
  | EditOrganizationAction
  | CustomizePlan
  | AddProfileToOrgAction
  | RemoveProfileAction
  | EditProfileAction
  | SetAudiencesAction
  | ToggleDemoCheckboxAction
  | SetAcademicPeriodStartAction
  | SetAcademicPeriodEndAction
  | AddIdjAction
  | DeleteIdjAction
  | EditIdjAction
