import { combineReducers } from 'redux'
import educationalInfo from './educationalInfo'
import collabMiniature from './collabMiniature'

const createExtraInfo = combineReducers({
  educationalInfo,
  collabMiniature,
  //rubric
})

export default createExtraInfo
