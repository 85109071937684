import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type {
  ResponseToMessageThreadActions,
  ResponseToMessageThreadState,
} from '@/sections/teacher/bulletin/types/messageThreadResponse'
import { MESSAGE_THREADS_RESPONSE_TYPES } from '@/sections/teacher/bulletin/types/messageThreadResponse'

const initialState: ResponseToMessageThreadState = {
  error: null,
  loading: false,
  status: REQUEST_STATUS.IDLE,
}

export default function messageThreadResponseReducer(
  state: ResponseToMessageThreadState = initialState,
  action: ResponseToMessageThreadActions
): ResponseToMessageThreadState {
  switch (action.type) {
    case MESSAGE_THREADS_RESPONSE_TYPES.RESPONSE_CLEAR: {
      return initialState
    }
    case MESSAGE_THREADS_RESPONSE_TYPES.RESPONSE_REQUEST: {
      return {
        ...state,
        loading: true,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case MESSAGE_THREADS_RESPONSE_TYPES.RESPONSE_SUCCESS: {
      return {
        ...state,
        loading: false,
        status: REQUEST_STATUS.SUCCESS,
      }
    }
    case MESSAGE_THREADS_RESPONSE_TYPES.RESPONSE_FAILURE: {
      return {
        ...state,
        loading: false,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    default:
      return state
  }
}
