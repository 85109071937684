import type { Document } from '@/api/lms/library'
import type { Classroom } from '@/api/lms/students'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

// State.
export interface ApprovalModalState {
  isOpenModal: boolean
  status: REQUEST_STATUS
  classrooms: Classroom[]
  infoForApproval: {
    student: Document['student']
    selectedClassroom: Classroom
    receiver: string
    documentId: number
  }
}

// Types.
export enum APPROVAL_MODAL_TYPES {
  OPEN_MODAL = 'PRINCIPAL/DOCUMENTATION/APPROVAL_MODAL/OPEN_MODAL',
  CLOSE_MODAL = 'PRINCIPAL/DOCUMENTATION/APPROVAL_MODAL/CLOSE_MODAL',
  GET_CLASSROOMS_REQUEST = 'PRINCIPAL/DOCUMENTATION/APPROVAL_MODAL/GET_CLASSROOMS_REQUEST',
  GET_CLASSROOMS_SUCCESS = 'PRINCIPAL/DOCUMENTATION/APPROVAL_MODAL/GET_CLASSROOMS_SUCCESS',
  GET_CLASSROOMS_FAILURE = 'PRINCIPAL/DOCUMENTATION/APPROVAL_MODAL/GET_CLASSROOMS_FAILURE',
  SET_BASIC_INFO_FOR_APPROVAL = 'PRINCIPAL/DOCUMENTATION/APPROVAL_MODAL/SET_BASIC_INFO_FOR_APPROVAL',
  SET_SELECTED_CLASSROOM = 'PRINCIPAL/DOCUMENTATION/APPROVAL_MODAL/SET_SELECTED_CLASSROOM',
  APPROVE_DOCUMENT_REQUEST = 'PRINCIPAL/DOCUMENTATION/APPROVAL_MODAL/APPROVE_DOCUMENT_REQUEST',
  APPROVE_DOCUMENT_SUCCESS = 'PRINCIPAL/DOCUMENTATION/APPROVAL_MODAL/APPROVE_DOCUMENT_SUCCESS',
  APPROVE_DOCUMENT_FAILURE = 'PRINCIPAL/DOCUMENTATION/APPROVAL_MODAL/APPROVE_DOCUMENT_FAILURE',
  CLEAN_MODAL = 'PRINCIPAL/DOCUMENTATION/APPROVAL_MODAL/CLEAN_MODAL',
}

// Actions.
// Open modal.
export type OpenModalAction = {
  type: APPROVAL_MODAL_TYPES.OPEN_MODAL
}

// Close modal.
export type CloseModalAction = {
  type: APPROVAL_MODAL_TYPES.CLOSE_MODAL
}

// Get classrooms request.
export type GetClassroomsRequestAction = {
  type: APPROVAL_MODAL_TYPES.GET_CLASSROOMS_REQUEST
}

// Get classrooms success.
export type GetClassroomsSuccessAction = {
  type: APPROVAL_MODAL_TYPES.GET_CLASSROOMS_SUCCESS
  payload: Classroom[]
}

// Get classrooms failure.
export type GetClassroomsFailureAction = {
  type: APPROVAL_MODAL_TYPES.GET_CLASSROOMS_FAILURE
}

// Get classrooms.
export type GetClassroomsAction =
  | GetClassroomsRequestAction
  | GetClassroomsSuccessAction
  | GetClassroomsFailureAction

// Set basic info for approval.
export type SetBasicInfoForApprovalAction = {
  type: APPROVAL_MODAL_TYPES.SET_BASIC_INFO_FOR_APPROVAL
  payload: Omit<ApprovalModalState['infoForApproval'], 'selectedClassroom'>
}

// Set selected classroom.
export type SetSelectedClassroomAction = {
  type: APPROVAL_MODAL_TYPES.SET_SELECTED_CLASSROOM
  payload: Classroom
}

// Approve document request.
export type ApproveDocumentRequestAction = {
  type: APPROVAL_MODAL_TYPES.APPROVE_DOCUMENT_REQUEST
}

// Approve document success.
export type ApproveDocumentSuccessAction = {
  type: APPROVAL_MODAL_TYPES.APPROVE_DOCUMENT_SUCCESS
}

// Approve document failure.
export type ApproveDocumentFailureAction = {
  type: APPROVAL_MODAL_TYPES.APPROVE_DOCUMENT_FAILURE
}

// Approve document action.
export type ApproveDocumentAction =
  | ApproveDocumentRequestAction
  | ApproveDocumentSuccessAction
  | ApproveDocumentFailureAction

// Clean modal action.
export type CleanModalAction = {
  type: APPROVAL_MODAL_TYPES.CLEAN_MODAL
}

// Action.
export type ApprovalModalAction =
  | OpenModalAction
  | CloseModalAction
  | GetClassroomsAction
  | SetBasicInfoForApprovalAction
  | SetSelectedClassroomAction
  | ApproveDocumentAction
  | CleanModalAction
