import type {
  Activity,
  OverviewActions,
  OverviewState,
  OverviewTab,
  RequestOptions,
} from '@/sections/teacher/overview/types/overview'
import {
  GET_ACTIVITIES_FAILURE,
  GET_ACTIVITIES_REQUEST,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITIES_SUMMARY_FAILURE,
  GET_ACTIVITIES_SUMMARY_REQUEST,
  GET_ACTIVITIES_SUMMARY_SUCCESS,
  GET_ACTIVITY_FOR_STUDENT_LIST_FAILURE,
  GET_ACTIVITY_FOR_STUDENT_LIST_REQUEST,
  GET_ACTIVITY_FOR_STUDENT_LIST_SUCCESS,
  GET_ATTENDANCE_SUMMARY_FAILURE,
  GET_ATTENDANCE_SUMMARY_REQUEST,
  GET_ATTENDANCE_SUMMARY_SUCCESS,
  GET_COMPLETION_SUMMARY_FAILURE,
  GET_COMPLETION_SUMMARY_REQUEST,
  GET_COMPLETION_SUMMARY_SUCCESS,
  GET_EVALUATIONS_SUMMARY_FAILURE,
  GET_EVALUATIONS_SUMMARY_REQUEST,
  GET_EVALUATIONS_SUMMARY_SUCCESS,
  GET_UNCORRECTED_ACTIVITIES_FAILURE,
  GET_UNCORRECTED_ACTIVITIES_REQUEST,
  GET_UNCORRECTED_ACTIVITIES_SUCCESS,
  RESET_CLASSROOM,
} from '@/sections/teacher/overview/types/overview'
import { LIMIT } from '@/sections/teacher/overview/constants'
import { TABS } from '@/sections/teacher/utils/activities'
import moment from 'moment'

const initialState: OverviewState = {
  loadingActivities: false,
  future: [],
  passed: [],
  today: [],
  total: 0,
  error: '',
  tab: TABS.TODAY,
  offset: 0,
  limit: LIMIT,
  completionLoading: false,
  completionSummary: {
    delayedRatio: 0,
    reallyDelayedRatio: 0,
    upToDateRatio: 0,
    total: 0,
  },
  activitiesSummaryLoading: false,
  activitiesSummary: {
    avv: 0,
    exes: 0,
    exor: 0,
    questions: 0,
    reading: 0,
    robotsRemote: 0,
    total: 0,
    cacheLastUpdated: moment(),
  },
  attendanceLoading: false,
  attendanceSummary: {
    startDate: moment(),
    calendar: [],
    growth: 0,
    cacheLastUpdated: moment(),
  },
  evaluationsLoading: false,
  evaluationsSummary: [],
  // UNCORRECTED_ACTIVITIES
  uncorrectedActivities: [],
  uncorrectedActivitiesTotal: 0,
  uncorrectedActivitiesLoading: false,
  uncorrectedActivitiesPagination: {
    page: 0,
    rowsPerPage: 4,
    count: 0,
  },
}

const getUpdatedActivities = (
  state: OverviewState,
  activities: { today: Activity[]; future: Activity[]; passed: Activity[] },
  total: number,
  tab: OverviewTab,
  options: RequestOptions
) => {
  const { shouldOverrideActivities } = options
  return {
    // @ts-ignore
    [tab]: shouldOverrideActivities ? activities[tab] : [...state[tab], ...activities[tab]],
    total,
  }
}

function root(state = initialState, action: OverviewActions): OverviewState {
  switch (action.type) {
    case GET_ACTIVITIES_REQUEST: {
      const { tab, silentLoading, reset } = action.payload
      return {
        ...state,
        loadingActivities: silentLoading ? state.loadingActivities : true,
        error: '',
        tab,
        // @ts-ignore
        ...(reset && { [tab]: state[tab].slice(0, LIMIT), offset: 0, total: 0 }),
      }
    }
    case GET_ACTIVITIES_SUCCESS: {
      const {
        today,
        future,
        passed,
        offset,
        doNotSetPagination,
        shouldOverrideActivities,
        tab,
        total,
      } = action.payload

      const updatedActivities = getUpdatedActivities(state, { today, future, passed }, total, tab, {
        shouldOverrideActivities,
      })

      return {
        ...state,
        loadingActivities: false,
        ...updatedActivities,
        ...(!doNotSetPagination && { offset }),
      }
    }
    case GET_ACTIVITIES_FAILURE:
      return { ...state, loadingActivities: false, error: action.payload.error }
    case RESET_CLASSROOM:
      return {
        ...state,
        future: [],
        passed: [],
        today: [],
      }
    case GET_COMPLETION_SUMMARY_REQUEST: {
      return { ...state, completionLoading: true, error: '' }
    }
    case GET_COMPLETION_SUMMARY_SUCCESS: {
      return {
        ...state,
        completionSummary: action.payload.completionSummary,
        completionLoading: false,
        error: '',
      }
    }
    case GET_COMPLETION_SUMMARY_FAILURE: {
      return { ...state, completionLoading: false, error: action.payload.error }
    }
    case GET_ACTIVITIES_SUMMARY_REQUEST: {
      return { ...state, activitiesSummaryLoading: true, error: '' }
    }
    case GET_ACTIVITIES_SUMMARY_SUCCESS: {
      return {
        ...state,
        activitiesSummary: action.payload.activitiesSummary,
        activitiesSummaryLoading: false,
        error: '',
      }
    }
    case GET_ACTIVITIES_SUMMARY_FAILURE: {
      return { ...state, activitiesSummaryLoading: false, error: action.payload.error }
    }
    case GET_ATTENDANCE_SUMMARY_REQUEST: {
      return { ...state, attendanceLoading: true, error: '' }
    }
    case GET_ATTENDANCE_SUMMARY_SUCCESS: {
      return {
        ...state,
        attendanceSummary: action.payload.attendanceSummary,
        attendanceLoading: false,
        error: '',
      }
    }
    case GET_ATTENDANCE_SUMMARY_FAILURE: {
      return { ...state, attendanceLoading: false, error: action.payload.error }
    }
    case GET_EVALUATIONS_SUMMARY_REQUEST: {
      return { ...state, evaluationsLoading: true, error: '' }
    }
    case GET_EVALUATIONS_SUMMARY_SUCCESS: {
      return {
        ...state,
        evaluationsSummary: action.payload.evaluationsSummary,
        evaluationsLoading: false,
        error: '',
      }
    }
    case GET_EVALUATIONS_SUMMARY_FAILURE: {
      return { ...state, evaluationsLoading: false, error: action.payload.error }
    }
    case GET_ACTIVITY_FOR_STUDENT_LIST_REQUEST: {
      return { ...state, evaluationsLoading: true, error: '' }
    }
    case GET_ACTIVITY_FOR_STUDENT_LIST_SUCCESS: {
      return { ...state, evaluationsLoading: false, error: '' }
    }
    case GET_ACTIVITY_FOR_STUDENT_LIST_FAILURE: {
      return { ...state, evaluationsLoading: false, error: action.payload.error }
    }
    case GET_UNCORRECTED_ACTIVITIES_REQUEST: {
      const { page, rowsPerPage, count } = action.payload
      return {
        ...state,
        error: '',
        uncorrectedActivitiesLoading: true,
        uncorrectedActivitiesPagination: {
          page,
          rowsPerPage,
          count,
        },
      }
    }
    case GET_UNCORRECTED_ACTIVITIES_SUCCESS: {
      const { uncorrectedActivities, total } = action.payload
      return {
        ...state,
        error: '',
        uncorrectedActivities,
        uncorrectedActivitiesTotal: total,
        uncorrectedActivitiesLoading: false,
      }
    }
    case GET_UNCORRECTED_ACTIVITIES_FAILURE: {
      return { ...state, uncorrectedActivitiesLoading: false, error: action.payload.error }
    }
    default:
      return state
  }
}

export default root
