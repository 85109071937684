import type { ManagementSubject } from '@/api/lms/subjects'
import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type {
  AssignUserFailure,
  AssignUserRequest,
  AssignUserSuccess,
  EditUserAssignmentLevelsAction,
  UnassignUserFromOrganizationFailure,
  UnassignUserFromOrganizationRequest,
  UnassignUserFromOrganizationSuccess,
} from '@/legacy/redux/types/managementUsers'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type { Option } from '@/legacy/components/v3/selects/selectSimple'
import type { Profile } from '@/api/lms/organizations'

export interface AcademicPeriod {
  id: number
  name: string
  default: boolean
}

export enum AssignmentMethods {
  SUBJECTS = 'subjects',
  CLASSROOM = 'classroom',
}

export interface OrgSuggestion {
  id: number
  label: string
  stages: StageOption[]
  academicPeriods?: AcademicPeriod[]
}

export type AssignmentOrganization = {
  label: string
  suggestions: OrgSuggestion[]
  loading: boolean
  error: string
  id: number
  academicPeriods?: AcademicPeriod[]
}

export interface ClassroomOption extends Option {
  stage: string
  academicPeriod: number
}

export function toBasicOption(classroomOption: ClassroomOption): Option {
  return { id: classroomOption.id, color: classroomOption.color, label: classroomOption.label }
}

export interface StageOption {
  id: number
  name: string
}

export interface AssignmentsInputState {
  open: boolean
  loading: boolean
  isEditMode: boolean
  error: string
  roleOptions: Option[]
  selectedRole: Option
  organizationInput: string
  organization: AssignmentOrganization
  selectedAssignmentMethod: AssignmentMethods
  stages: StageOption[]
  selectedStages: StageOption[]
  selectedClassroom: ClassroomOption
  subjects: ManagementSubject[]
  profiles: Profile[]
  selectedProfile: Profile
  selectedAcademicPeriod: { id: number; name: string }
}

// Toggle modal open
export const TOGGLE_MODAL_OPEN = 'INPUTS/ASSIGNMENTS_INPUT/TOGGLE_MODAL_OPEN'
export type ToggleModalOpen = GenericActionWithType<typeof TOGGLE_MODAL_OPEN>

// Open and populate modal
export const OPEN_AND_POPULATE_MODAL_REQUEST =
  'INPUTS/ASSIGNMENTS_INPUT/OPEN_AND_POPULATE_MODAL_REQUEST'
export const OPEN_AND_POPULATE_MODAL_SUCCESS =
  'INPUTS/ASSIGNMENTS_INPUT/OPEN_AND_POPULATE_MODAL_SUCCESS'
export const OPEN_AND_POPULATE_MODAL_FAILURE =
  'INPUTS/ASSIGNMENTS_INPUT/OPEN_AND_POPULATE_MODAL_FAILURE'
export const SET_IS_EDIT_MODE = 'INPUTS/ASSIGNMENTS_INPUT/SET_IS_EDIT_MODE'
export const SET_STAGES = 'INPUTS/ASSIGNMENTS_INPUT/SET_STAGES'

export type OpenAndPopulateModalRequest = GenericActionWithType<
  typeof OPEN_AND_POPULATE_MODAL_REQUEST
>
export type OpenAndPopulateModalSuccess = GenericActionWithType<
  typeof OPEN_AND_POPULATE_MODAL_SUCCESS,
  {
    selectedRole: string
    classroomOptions: ClassroomOption[]
    subjects: ManagementSubject[]
  }
>
export type SetIsEditModeAction = GenericActionWithType<
  typeof SET_IS_EDIT_MODE,
  {
    isEditMode: boolean
  }
>
export type SetStagesAction = GenericActionWithType<
  typeof SET_STAGES,
  {
    stages: StageOption[]
  }
>
export type OpenAndPopulateModalFailure = GenericActionWithType<
  typeof OPEN_AND_POPULATE_MODAL_FAILURE,
  ErrorPayload
>
export type OpenAndPopulateModalAction =
  | OpenAndPopulateModalRequest
  | OpenAndPopulateModalSuccess
  | OpenAndPopulateModalFailure

// Select role
export const SELECT_ROLE = 'INPUTS/ASSIGNMENTS_INPUT/SELECT_ROLE'
export type SelectRole = GenericActionWithType<typeof SELECT_ROLE, { roleId: number }>

// Get organization suggestions
export const GET_ORGANIZATION_SUGGESTIONS_REQUEST =
  'INPUTS/ASSIGNMENTS_INPUT/GET_ORGANIZATION_SUGGESTIONS_REQUEST'
export const GET_ORGANIZATION_SUGGESTIONS_SUCCESS =
  'INPUTS/ASSIGNMENTS_INPUT/GET_ORGANIZATION_SUGGESTIONS_SUCCESS'
export const GET_ORGANIZATION_SUGGESTIONS_FAILURE =
  'INPUTS/ASSIGNMENTS_INPUT/GET_ORGANIZATION_SUGGESTIONS_FAILURE'

export type GetOrganizationSuggestionsRequest = GenericActionWithType<
  typeof GET_ORGANIZATION_SUGGESTIONS_REQUEST
>
export type GetOrganizationSuggestionsSuccess = GenericActionWithType<
  typeof GET_ORGANIZATION_SUGGESTIONS_SUCCESS,
  {
    suggestions: OrgSuggestion[]
  }
>
export type GetOrganizationSuggestionsFailure = GenericActionWithType<
  typeof GET_ORGANIZATION_SUGGESTIONS_FAILURE,
  ErrorPayload
>
export type GetOrganizationSuggestionsAction =
  | GetOrganizationSuggestionsRequest
  | GetOrganizationSuggestionsSuccess
  | GetOrganizationSuggestionsFailure

// Select organization suggestion
export const SELECT_ORGANIZATION_SUGGESTION =
  'INPUTS/ASSIGNMENTS_INPUT/SELECT_ORGANIZATION_SUGGESTION'

export type SelectOrganizationSuggestion = GenericActionWithType<
  typeof SELECT_ORGANIZATION_SUGGESTION,
  { suggestion: OrgSuggestion }
>
// Clear organization suggestions
export const CLEAR_ORGANIZATION_SUGGESTIONS =
  'INPUTS/ASSIGNMENTS_INPUT/CLEAR_ORGANIZATION_SUGGESTIONS'

export type ClearOrganizationSuggestions = GenericActionWithType<
  typeof CLEAR_ORGANIZATION_SUGGESTIONS
>

// Select classroom
export const SELECT_CLASSROOM = 'INPUTS/ASSIGNMENTS_INPUT/SELECT_CLASSROOM'
export type SelectClassroom = GenericActionWithType<typeof SELECT_CLASSROOM, { classroom: Option }>

// Assign user
export type AssignUserAction = AssignUserRequest | AssignUserSuccess | AssignUserFailure

// Unassign user from organization
export type UnassignUserFromOrganizationAction =
  | UnassignUserFromOrganizationRequest
  | UnassignUserFromOrganizationSuccess
  | UnassignUserFromOrganizationFailure

// Toggle stage action
export const TOGGLE_STAGE = 'INPUTS/ASSIGNMENTS_INPUT/TOGGLE_STAGE'
export type ToggleStageAction = GenericActionWithType<typeof TOGGLE_STAGE, { stage: StageOption }>

// Select stages action
export const SET_SELECTED_STAGES = 'INPUTS/ASSIGNMENTS_INPUT/SET_SELECTED_STAGES'
export type SetSelectedStagesAction = GenericActionWithType<
  typeof SET_SELECTED_STAGES,
  { selectedStages: StageOption[] }
>

// Get profiles
export const GET_PROFILES_REQUEST = 'INPUTS/ASSIGNMENTS_INPUT/GET_PROFILES_REQUEST'
export type GetProfilesRequestAction = GenericActionWithType<typeof GET_PROFILES_REQUEST>

export const GET_PROFILES_SUCCESS = 'INPUTS/ASSIGNMENTS_INPUT/GET_PROFILES_SUCCESS'
export type GetProfilesSuccessAction = GenericActionWithType<
  typeof GET_PROFILES_SUCCESS,
  { profiles: Profile[] }
>

export const GET_PROFILES_FAILURE = 'INPUTS/ASSIGNMENTS_INPUT/GET_PROFILES_FAILURE'
export type GetProfilesFailureAction = GenericActionWithType<
  typeof GET_PROFILES_FAILURE,
  ErrorPayload
>

export type GetProfilesAction =
  | GetProfilesRequestAction
  | GetProfilesSuccessAction
  | GetProfilesFailureAction

// Select profile
export const SELECT_PROFILE = 'INPUTS/ASSIGNMENTS_INPUT/SELECT_PROFILE'
export type SelectProfileAction = GenericActionWithType<typeof SELECT_PROFILE, { profile: Profile }>

// Remove role action
export const REMOVE_ROLE = 'INPUTS/ASSIGNMENTS_INPUT/REMOVE_ROLE'
export type RemoveRoleAction = GenericActionWithType<typeof REMOVE_ROLE, { role: Option }>

// Preload organization suggestion
export const PRELOAD_ORG_SUGGESTION_REQUEST =
  'INPUTS/ASSIGNMENTS_INPUT/PRELOAD_ORG_SUGGESTION_REQUEST'
export type PreloadOrgSuggestionActionRequest = GenericActionWithType<
  typeof PRELOAD_ORG_SUGGESTION_REQUEST
>

export const PRELOAD_ORG_SUGGESTION_SUCCESS =
  'INPUTS/ASSIGNMENTS_INPUT/PRELOAD_ORG_SUGGESTION_SUCCESS'
export type PreloadOrgSuggestionActionSuccess = GenericActionWithType<
  typeof PRELOAD_ORG_SUGGESTION_SUCCESS,
  { profiles: Profile[] }
>

export const PRELOAD_ORG_SUGGESTION_FAILURE =
  'INPUTS/ASSIGNMENTS_INPUT/PRELOAD_ORG_SUGGESTION_FAILURE'
export type PreloadOrgSuggestionActionFailure = GenericActionWithType<
  typeof PRELOAD_ORG_SUGGESTION_FAILURE,
  ErrorPayload
>

export type PreloadOrgSuggestionAction =
  | PreloadOrgSuggestionActionRequest
  | PreloadOrgSuggestionActionSuccess
  | PreloadOrgSuggestionActionFailure

// Set selected academic period action.
export const SET_SELECTED_ACADEMIC_PERIOD = 'INPUTS/ASSIGNMENTS_INPUT/SET_SELECTED_ACADEMIC_PERIOD'
export type SetSelectedAcademicPeriodAction = GenericActionWithType<
  typeof SET_SELECTED_ACADEMIC_PERIOD,
  { newAcademicPeriod: AssignmentsInputState['selectedAcademicPeriod'] }
>

export type AssignmentsInputsAction =
  | ClearState
  | SetField<
      'open' | 'selectedAssignmentMethod' | 'subjects',
      boolean | AssignmentMethods | ManagementSubject[]
    >
  | ToggleModalOpen
  | OpenAndPopulateModalAction
  | SelectRole
  | GetOrganizationSuggestionsAction
  | SelectOrganizationSuggestion
  | ClearOrganizationSuggestions
  | SelectClassroom
  | AssignUserAction
  | UnassignUserFromOrganizationAction
  | ToggleStageAction
  | SetSelectedStagesAction
  | RemoveRoleAction
  | GetProfilesAction
  | SelectProfileAction
  | SetIsEditModeAction
  | SetStagesAction
  | EditUserAssignmentLevelsAction
  | PreloadOrgSuggestionAction
  | SetSelectedAcademicPeriodAction
