import * as Types from '@/sections/content-creator/sequenceDrawer/types/form'

export const initialState: Types.FormState = {
  title: '',
  description: '',
  selectedBook: {
    id: 0,
    label: '',
  },
}

const formReducer = (
  state: Types.FormState = initialState,
  action: Types.FormAction
): Types.FormState => {
  switch (action.type) {
    case Types.FORM_TYPES.SET_FORM: {
      const newForm = action.payload
      return newForm
    }
    case Types.FORM_TYPES.CLEAN_FORM: {
      return initialState
    }
    default:
      return state
  }
}

export default formReducer
