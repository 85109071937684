import config from '../config'
import { pickCosmos } from '../cosmos'
import type { Config } from '../config/types'

/**
 * Get some url using cosmos config prop, or using the project config
 *
 * @param {keyof Config} key key id of configutaion url
 * @param {Config} baseConfig project base configuration
 */
export const pickUrl = (key: keyof Config, baseConfig: Config): string => {
  const { config: cosmosConfig } = pickCosmos()
  // search the url key in the cosmos config prop eg: (config: { URL: '' })
  if (cosmosConfig) {
    const url = cosmosConfig[key]
    if (url) return url as string
  }

  // search the url key in the project config eg: (environment variables names)
  if (baseConfig) {
    const url = baseConfig[key]
    if (url) return url as string
  }

  console.error(`url env picker: the environment variable: ${key}, is not defined`)
  return ''
}

/**
 * Get some url from the environment variables or from cosmos
 * TODO exclude keys of the config that can content booleans
 *
 * @param {keyof Config} configKey url key of the resurce
 */
export const getUrl = (configKey: keyof Config) => pickUrl(configKey, config)
