import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import * as Types from '@/sections/principal/classroom-migration/types/migrationClassrooms'
import * as StudentsDrawerTypes from '@/sections/principal/classroom-migration/types/studentsDrawer'
import { REQUEST_STATUS } from '@/legacy/redux/types/status'

const initialState: Types.MigrationClassroomsState = {
  newClassrooms: [],
  oldClassrooms: [],
  oldClassroomStatus: REQUEST_STATUS.IDLE,
  newClassroomStatus: REQUEST_STATUS.IDLE,
  pagination: {
    count: 0,
    more: false,
    page: 0,
    rowsPerPage: 10,
  },
  migrationCompletionStatus: REQUEST_STATUS.IDLE,
  classroomsToMigrate: [],
  error: '',
  migrations: [],
  classroomVerification: {
    status: REQUEST_STATUS.IDLE,
    studentsList: [],
    error: '',
  },
  alertModal: {
    isOpen: false,
  },
}

const requestDestination = reduxRequestOriginMap.PRINCIPAL_MIGRATION_TAB

const MigrationClassroomsReducer = (
  state: Types.MigrationClassroomsState = initialState,
  action: Types.MigrationClassroomsActions
): Types.MigrationClassroomsState => {
  if (action.payload?.requestOrigin !== requestDestination) return state

  switch (action.type) {
    case Types.GET_CLASSROOMS_REQUEST: {
      const { type, offset } = action.payload

      if (type === 'old') {
        return {
          ...state,
          oldClassroomStatus: REQUEST_STATUS.LOADING,
          oldClassrooms: [],
          pagination: { ...state.pagination, page: offset },
        }
      } else {
        return {
          ...state,
          newClassrooms: [],
          newClassroomStatus: REQUEST_STATUS.LOADING,
        }
      }
    }
    case Types.GET_CLASSROOMS_SUCCESS: {
      const { type, classrooms, count, more } = action.payload

      if (type === 'old') {
        return {
          ...state,
          oldClassroomStatus: REQUEST_STATUS.SUCCESS,
          oldClassrooms: classrooms,
          pagination: { ...state.pagination, count: count ?? 0, more: more ?? false },
        }
      } else {
        return {
          ...state,
          newClassrooms: classrooms,
          newClassroomStatus: REQUEST_STATUS.SUCCESS,
        }
      }
    }
    case Types.GET_CLASSROOMS_FAILURE: {
      const { type, error } = action.payload

      if (type === 'old') {
        return {
          ...state,
          error,
          oldClassroomStatus: REQUEST_STATUS.FAILURE,
        }
      } else {
        return {
          ...state,
          error,
          newClassroomStatus: REQUEST_STATUS.FAILURE,
        }
      }
    }
    case Types.SET_CLASSROOM_TO_MIGRATE_V2: {
      const { oldClassroom, newClassroom } = action.payload
      if (!newClassroom) {
        return {
          ...state,
          migrations: [
            ...state.migrations
              .map((m) => {
                return {
                  ...m,
                  fromClassroomsList: [
                    ...m.fromClassroomsList.filter((c) => c.id !== oldClassroom.id),
                  ],
                }
              })
              .filter((m) => m.fromClassroomsList.length > 0),
          ],
        }
      }
      if (newClassroom) {
        const existingMigrationEntry = state.migrations.find(
          (m) => m.toClassroom.id === newClassroom.id
        )

        if (existingMigrationEntry) {
          const updatedMigrationEntry = {
            ...existingMigrationEntry,
            fromClassroomsList: [
              ...existingMigrationEntry.fromClassroomsList.filter(
                (classroom) => classroom.id !== oldClassroom.id
              ),
              oldClassroom,
            ],
          }
          return {
            ...state,
            migrations: [
              ...state.migrations.filter((m) => m.toClassroom.id !== newClassroom.id),
              updatedMigrationEntry,
            ],
          }
        }

        const newMigrationEntry = {
          fromClassroomsList: [oldClassroom],
          studentsToAdd: [],
          students: [],
          studentsToRemove: [],
          verified: false,
          toClassroom: newClassroom,
        }
        return {
          ...state,
          migrations: [...state.migrations, newMigrationEntry],
        }
      }
      return { ...state }
    }
    case StudentsDrawerTypes.VERIFY_CLASSROOM: {
      const { migration } = action.payload
      const existMigration = state.migrations.find(
        (m) => m.toClassroom.id === migration.toClassroom.id
      )
      if (existMigration) {
        const updatedMigration = {
          ...migration,
          verified: true,
        }
        return {
          ...state,
          migrations: [
            ...state.migrations.filter((m) => m.toClassroom.id !== updatedMigration.toClassroom.id),
            updatedMigration,
          ],
        }
      }
      return { ...state }
    }
    case Types.SET_CLASSROOM_TO_MIGRATE: {
      const { oldClassroom, newClassroom } = action.payload

      let classroomsToMigrate = state.classroomsToMigrate.slice()

      const existingCtm = classroomsToMigrate.find((ctm) => ctm.oldClassroom.id === oldClassroom.id)

      if (existingCtm)
        classroomsToMigrate = classroomsToMigrate.filter(
          (ctm) => ctm.oldClassroom.id !== oldClassroom.id
        )
      if (newClassroom && newClassroom.id !== 0)
        classroomsToMigrate.push({
          oldClassroom,
          newClassroom,
          verified: false,
          studentsToAdd: [],
          studentsToRemove: [],
        })

      return {
        ...state,
        classroomsToMigrate,
      }
    }
    case Types.COMPLETE_MIGRATION_REQUEST: {
      return { ...state, migrationCompletionStatus: REQUEST_STATUS.LOADING }
    }
    case Types.COMPLETE_MIGRATION_SUCCESS: {
      return { ...state, migrationCompletionStatus: REQUEST_STATUS.SUCCESS }
    }
    case Types.COMPLETE_MIGRATION_FAILURE: {
      return {
        ...state,
        migrationCompletionStatus: REQUEST_STATUS.FAILURE,
        error: action.payload.error,
      }
    }
    case Types.GET_CLASSROOM_STUDENTS_REQUEST:
      return {
        ...state,
        classroomVerification: { ...state.classroomVerification, status: REQUEST_STATUS.LOADING },
      }
    case Types.GET_CLASSROOM_STUDENTS_SUCCESS: {
      const { studentsWithClassroom } = action.payload
      const filteredStudentsWithClassroom = studentsWithClassroom.filter(
        (swc) => swc.student.id !== 0
      )
      const updatedMigrations = state.migrations.map((m) => {
        return {
          ...m,
          students: [
            ...filteredStudentsWithClassroom
              .filter((st) => st.toClassroomId === m.toClassroom.id)
              .map((s) => s.student.id),
          ],
        }
      })

      return {
        ...state,
        migrations: updatedMigrations,
        classroomVerification: {
          ...state.classroomVerification,
          status: REQUEST_STATUS.SUCCESS,
          studentsList: filteredStudentsWithClassroom,
        },
      }
    }
    case Types.GET_CLASSROOM_STUDENTS_FAILURE:
      return {
        ...state,
        classroomVerification: {
          ...state.classroomVerification,
          status: REQUEST_STATUS.FAILURE,
          error: action.payload.error,
        },
      }
    case StudentsDrawerTypes.ADD_NEW_STUDENTS_SELECTION: {
      const { newStudentsList } = action.payload
      return {
        ...state,
        classroomVerification: {
          ...state.classroomVerification,
          studentsList: [...state.classroomVerification.studentsList, ...newStudentsList],
        },
      }
    }
    case Types.SET_ALERT_MODAL_OPEN: {
      return { ...state, alertModal: { isOpen: true } }
    }
    case Types.SET_ALERT_MODAL_CLOSE: {
      return { ...state, alertModal: { isOpen: false } }
    }
    case Types.CLEAR_MIGRATION_STATE: {
      return initialState
    }
    default: {
      return state
    }
  }
}

export default MigrationClassroomsReducer
