import { API } from '@/api/lms'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import { robotsApiUrl } from '@aula/config'
import type { AppThunk } from '@/state/thunk'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import type {
  GetCollabTokenRequest,
  GetCollabTokenSuccess,
} from '@/legacy/redux/types/collabMiniature'
import {
  GET_COLLAB_TOKEN_FAILURE,
  GET_COLLAB_TOKEN_REQUEST,
  GET_COLLAB_TOKEN_SUCCESS,
} from '@/legacy/redux/types/collabMiniature'

export const assembleCollabUrl = (token) => robotsApiUrl + '/collaboration/login/' + token

const CollabMiniatureActionCreators = {
  getCollabToken:
    (requestOrigins: ReduxRequestOrigin[], activityId: number): AppThunk =>
    async (dispatch) => {
      const request: GetCollabTokenRequest = {
        type: GET_COLLAB_TOKEN_REQUEST,
        payload: {
          requestOrigins,
        },
      }
      dispatch(request)

      try {
        const {
          data: {
            description: { token },
          },
        } = await API.Activities.collabMiniature.getToken(activityId)

        const success: GetCollabTokenSuccess = {
          type: GET_COLLAB_TOKEN_SUCCESS,
          payload: {
            requestOrigins,
            token: token || null,
            activityId,
          },
        }

        dispatch(success)
        return token
      } catch (error) {
        handleErrorsWithAction(error, GET_COLLAB_TOKEN_FAILURE, dispatch, { requestOrigins })
      }
    },
}

export default CollabMiniatureActionCreators
