import { apiPrivate } from './..'
import { url } from '@aula/config'
import { AVVEvent } from './avv'

export const ExesAPI = {
  getStatus,
  listHelpRequests,
  createHelpRequest,
  updateHelpRequest,
  getLogs,
}

/**
 * Get EXES' started and ended dates.
 *
 * @role Student
 * @param activityID
 */
function getStatus(activityID: number) {
  return apiPrivate.get(url + `/v1/exes/${activityID}/status`)
}

/**
 * List user's help requests for an activity.
 *
 * @role Student
 * @param activityID
 */
function listHelpRequests(activityID: number) {
  return apiPrivate.get(url + `/v1/exes/${activityID}/help-requests`)
}

/**
 * Student asks for help in an EXES.
 *
 * @role Student
 * @param activityID
 */
function createHelpRequest(activityID: number) {
  return apiPrivate.post(url + `/v1/exes/${activityID}/help-requests`)
}

export enum HelpRequestStatus {
  ACCEPTED = 'accepted',
  CANCELED = 'canceled',
}

/**
 * Updates a help request's status. Teachers can only accept help requests and students can only
 * cancel help requests.
 *
 * @role Any
 * @param activityID
 * @param helpRequestID
 * @param status
 */
function updateHelpRequest(activityID: number, helpRequestID: number, status: HelpRequestStatus) {
  const formData = new FormData()
  formData.append('status', status)
  return apiPrivate.put(url + `/v1/exes/${activityID}/help-requests/${helpRequestID}`, formData)
}

export interface Log {
  type: AVVEvent
  dateTime: string
}
interface ParamsGetLogs {
  activityId: number
  userId: number
  offset: number
  limit: number
}

interface GetLogsResponse {
  more: boolean
  count: number
  rows: Log[]
}

function getLogs(params: ParamsGetLogs): Promise<GetLogsResponse> {
  return apiPrivate
    .get(url + `/v2/activities/${params.activityId}/events`, {
      params: {
        userID: params.userId,
        offset: params.offset,
        limit: params.limit,
      },
    })
    .then((response) => response.data.description)
}
