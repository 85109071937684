import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { pickCosmos, useTheme } from '@aula/config'
import { pickCosmosLogos } from '@aula/config'

const Head = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { title } = pickCosmos()
  const { favicon } = pickCosmosLogos()

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={t('app.description')} />
      <link rel="icon" href={favicon} />
      <meta name="theme-color" content={theme.palette.secondary.main} />
    </Helmet>
  )
}

export default Head
