export const Types = Object.freeze({
  Classroom: 'classroom',
  Group: 'group',
  Teacher: 'teacher',
  Student: 'student',
  Subject: 'subject',
  Activity: 'activity',
})

export const ChatSwitchKeys = {
  Classroom: 'classroom',
  Teacher: 'teacher',
}
