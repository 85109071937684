import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { Student } from '@/sections/student/summary/types/common'

export interface StudentInfoState {
  status: REQUEST_STATUS
  info: Student
}

export enum STUDENT_INFO_TYPES {
  GET_STUDENT_INFO_REQUEST = 'STUDENT/SUMMARY/DRAWER/STUDENT/GET_STUDENT_INFO_REQUEST',
  GET_STUDENT_INFO_SUCCESS = 'STUDENT/SUMMARY/DRAWER/STUDENT/GET_STUDENT_INFO_SUCCESS',
  GET_STUDENT_INFO_FAILURE = 'STUDENT/SUMMARY/DRAWER/STUDENT/GET_STUDENT_INFO_FAILURE',
  CLEAN_STUDENT_INFO = 'STUDENT/SUMMARY/DRAWER/STUDENT/CLEAN_STUDENT_INFO',
}

// Get student info actions.
export type GetStudentInfoRequestAction = {
  type: STUDENT_INFO_TYPES.GET_STUDENT_INFO_REQUEST
}

export type GetStudentInfoSuccessAction = {
  type: STUDENT_INFO_TYPES.GET_STUDENT_INFO_SUCCESS
  payload: {
    student: Student
  }
}

export type GetStudentInfoFailureAction = {
  type: STUDENT_INFO_TYPES.GET_STUDENT_INFO_FAILURE
}

export type GetStudentInfoAction =
  | GetStudentInfoRequestAction
  | GetStudentInfoSuccessAction
  | GetStudentInfoFailureAction

// Clean student info.
export type CleanStudentInfoAction = {
  type: STUDENT_INFO_TYPES.CLEAN_STUDENT_INFO
}

export type StudentInfoAction = GetStudentInfoAction | CleanStudentInfoAction
