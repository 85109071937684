import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type { Reducer } from 'redux'
import type {
  OfflineActivitiesModalAction,
  OfflineActivitiesModalState,
} from '@/sections/offlineActivities/types/offlineActivitiesModal'
import moment from 'moment'
import { Redux } from '@/legacy/redux'
import type { Subject } from '@/legacy/redux/types/subjects'

const EMPTY_SUBJECT: Subject = {
  id: 0,
  name: 'No hay materias',
  color: '#FFF',
}

const initialState: OfflineActivitiesModalState = {
  open: false,
  loadingSubjects: false,
  loadingSubgroups: false,
  loadingSubmit: false,
  error: '',
  subgroups: [],
  subjects: [],
  title: '',
  date: moment(),
  selectedGradingScale: 1,
  selectedSubject: EMPTY_SUBJECT,
  selectedSubgroups: [],
}

const requestDestination = reduxRequestOriginMap.OFFLINE_ACTIVITIES_MODAL

const OfflineActivitiesModal: Reducer<OfflineActivitiesModalState, OfflineActivitiesModalAction> = (
  state = initialState,
  action
): OfflineActivitiesModalState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case Redux.Types.Subgroups.LIST_SUBGROUPS_AS_TEACHER_REQUEST:
      return { ...state, loadingSubgroups: true, error: initialState.error }
    case Redux.Types.Subgroups.LIST_SUBGROUPS_AS_TEACHER_SUCCESS:
      return { ...state, loadingSubgroups: false, subgroups: action.payload.subgroups }
    case Redux.Types.Subgroups.LIST_SUBGROUPS_AS_TEACHER_FAILURE:
      return { ...state, loadingSubgroups: false, error: action.payload.error }
    case Redux.Types.Subjects.GET_SUBJECTS_AS_TEACHER_REQUEST:
      return { ...state, loadingSubjects: true, error: initialState.error }
    case Redux.Types.Subjects.GET_SUBJECTS_AS_TEACHER_SUCCESS:
      return {
        ...state,
        loadingSubjects: false,
        subjects: action.payload.subjects,
        selectedSubject: action.payload.subjects[0] || initialState.selectedSubject,
      }
    case Redux.Types.Subjects.GET_SUBJECTS_AS_TEACHER_FAILURE:
      return { ...state, loadingSubjects: false, error: action.payload.error }
    case Redux.Types.Activities.CREATE_OFFLINE_ACTIVITY_REQUEST:
      return { ...state, loadingSubmit: true, error: initialState.error }
    case Redux.Types.Activities.CREATE_OFFLINE_ACTIVITY_SUCCESS:
      return initialState
    case Redux.Types.Activities.CREATE_OFFLINE_ACTIVITY_FAILURE:
      return { ...state, loadingSubmit: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, OfflineActivitiesModal, requestDestination)
