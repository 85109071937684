import { reportCardUrl } from '@aula/config'
import { ApiPrivate } from '@/legacy/services/api/api'
import type { ClassroomAbscences } from './types'

/**
 * Obtiene el historal de inasistencias de un aula por período y por subjectId
 * @doc https://gitlab.com/elishrid/report-card/-/blob/develop/docs/swagger.yaml#/Attendances/get_v1_information_attendances
 */
export const getByClassroom = (periodId: number, subjectId: number) =>
  ApiPrivate.get<ClassroomAbscences>(reportCardUrl + `/v1/information/attendances`, {
    params: { periodId, subjectId },
  }).then((response) => response.data)

interface SaveAttendancesBody {
  periodId: number
  studentId: number
  subjectId: number
}
/**
 * Guarda el estado actual de inasistencias
 * @doc https://gitlab.com/elishrid/report-card/-/blob/develop/docs/swagger.yaml#/Attendances/put_v1_values_attendances
 */
export const saveAttendances = (body: SaveAttendancesBody) =>
  ApiPrivate.put<void>(reportCardUrl + `/v1/values/attendances`, body).then(
    (response) => response.data
  )
