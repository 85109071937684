import { combineReducers } from 'redux'
import configurationDrawer from './config/reducer'
import grading from './grading/reducer'
import config from './config/reducers/config'
import generateBulletinsModal from './generateBulletinsModal/reducers/generateBulletinsModal'
import audit from './config/reducers/audit'

export default combineReducers({
  configurationDrawer,
  grading,
  config,
  generateBulletinsModal,
  audit,
})
