import { combineReducers } from 'redux'
import dashboard from './reducers/dashboard'
import tabs from './reducers/tabs'

const rootReducer = combineReducers({
  dashboard,
  tabs,
})

export default rootReducer
