import { combineReducers } from 'redux'
import addEditOrganizationModal from './addEditOrganizationModal'
import featuresModal from './featuresModal'
import deleteOrganizationModal from '@/sections/management/reducers/organizations/deleteOrganizationModal'
import cancelOrgDeletionModal from '@/sections/management/reducers/organizations/cancelOrgDeletionModal'
import cloneOrganizationModal from '@/sections/management/reducers/organizations/cloneOrganizationModal'

const organizationsReducer = combineReducers({
  addEditOrganizationModal,
  featuresModal,
  deleteOrganizationModal,
  cancelOrgDeletionModal,
  cloneOrganizationModal,
})

export default organizationsReducer
