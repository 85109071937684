import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type { Reducer } from 'redux'
import type {
  ReportCardConfigMainTabAction,
  ReportCardConfigMainTabState,
} from '@/sections/report-card/config/types/mainTab'
import {
  DESELECT_ALL_CLASSROOMS,
  SELECT_SINGLE_CLASSROOM,
  TOGGLE_CLASSROOM,
} from '@/sections/report-card/config/types/mainTab'
import { Redux } from '@/legacy/redux'
import {
  GET_ORGANIZATION_REPORT_CARDS_FAILURE,
  GET_ORGANIZATION_REPORT_CARDS_REQUEST,
  GET_ORGANIZATION_REPORT_CARDS_SUCCESS,
} from '@/sections/report-card/config/types/apiInteractions'

const initialState: ReportCardConfigMainTabState = {
  classrooms: [],
  configuredClassrooms: [],
  configuredReportCards: [],
  loadingClassrooms: false,
  loadingconfiguredClassrooms: false,
  classroomsPagination: {
    page: 0,
    rowsPerPage: 6,
    count: 0,
  },
  selectedClassrooms: [],
  error: '',
  search: '',
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_CONFIG_MAIN_TAB

const ReportCardConfigMainTab: Reducer<
  ReportCardConfigMainTabState,
  ReportCardConfigMainTabAction
> = (state = initialState, action): ReportCardConfigMainTabState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case GET_ORGANIZATION_REPORT_CARDS_REQUEST:
      return { ...state, loadingconfiguredClassrooms: true, error: initialState.error }
    case GET_ORGANIZATION_REPORT_CARDS_SUCCESS: {
      const configuredClassrooms = action.payload.reportCards.map(
        ({ classroomId, enableScores }) => {
          return {
            id: classroomId,
            enableScores,
          }
        }
      )
      return {
        ...state,
        loadingconfiguredClassrooms: false,
        configuredClassrooms,
        configuredReportCards: action.payload.reportCards,
        selectedClassrooms: action.payload.resetSelection ? [] : state.selectedClassrooms,
      }
    }
    case GET_ORGANIZATION_REPORT_CARDS_FAILURE:
      return { ...state, loadingconfiguredClassrooms: false, error: action.payload.error }
    case TOGGLE_CLASSROOM: {
      const { classroom } = action.payload

      const filtered = state.selectedClassrooms.filter((sc) => sc.id !== classroom.id)

      if (filtered.length === state.selectedClassrooms.length) filtered.push(classroom)

      return { ...state, selectedClassrooms: filtered }
    }

    case SELECT_SINGLE_CLASSROOM: {
      const { classroom } = action.payload
      return { ...state, selectedClassrooms: [classroom] }
    }

    case DESELECT_ALL_CLASSROOMS: {
      return { ...state, selectedClassrooms: [] }
    }

    case Redux.Types.Classrooms.GET_CLASSROOMS_AS_PRINCIPAL_REQUEST:
      return {
        ...state,
        loadingClassrooms: true,
        error: initialState.error,
        classroomsPagination: {
          ...state.classroomsPagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    case Redux.Types.Classrooms.GET_CLASSROOMS_AS_PRINCIPAL_SUCCESS:
      return {
        ...state,
        loadingClassrooms: false,
        classrooms: action.payload.classrooms,
        classroomsPagination: {
          ...state.classroomsPagination,
          count: action.payload.count,
        },
      }
    case Redux.Types.Classrooms.GET_CLASSROOMS_AS_PRINCIPAL_FAILURE:
      return { ...state, loadingClassrooms: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, ReportCardConfigMainTab, requestDestination)
