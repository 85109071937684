import types from '@/sections/editor/types/editor'
import { editorStateFromRaw } from 'megadraft'
import linkDecorator from '@/sections/editor/plugins/linkTargetBlank/linkDecorator'

const initialState = {
  startingState: editorStateFromRaw(null, linkDecorator),
  currentState: null,
}

function root(state = initialState, action) {
  switch (action.type) {
    case types.SET_EDITOR_STATE:
    case types.SET_RAW_EDITOR_STATE:
    case types.CLEAR:
      return {
        ...state,
        startingState: action.payload.state,
        currentState: action.payload.state,
      }
    case types.SET_CURRENT_STATE:
      return { ...state, currentState: action.payload.state }
    default:
      return state
  }
}

export default root
