import React from 'react'
import { CircularProgress, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'

const useStyles = makeStyles((theme) => ({
  chipContainer: {
    padding: '1px 1px 1px 5px',
    backgroundColor: '#fff',
    border: '1px solid #D8DBE8',
    boxSizing: 'border-box',
    borderRadius: '15px',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    cursor: ({ downloadable }) => (downloadable ? 'pointer' : 'unset'),
    '&:hover': {
      backgroundColor: ({ downloadable }) => (downloadable ? '#f9f9f9' : '#fff'),
    },
    '&:active': {
      backgroundColor: ({ downloadable }) => (downloadable ? '#f4f4f4' : '#fff'),
    },
  },
  chipContainerLink: {
    display: 'flex',
    color: 'inherit',
    textDecoration: 'none',
    marginRight: 3,
    cursor: 'pointer',
  },
  fileExtension: {
    background: 'rgba(39, 168, 255, 0.1)',
    border: '1px solid rgba(39, 168, 255, 0.2)',
    boxSizing: 'border-box',
    borderRadius: '10px',
    fontSize: 8,
    fontWeight: 'bold',
    lineHeight: '10px',
    textAlign: 'center',
    color: '#27A8FF',
    textTransform: 'uppercase',
    alignSelf: 'center',
    padding: '2px 4px',
    margin: '3px 3px 3px 1px',
  },
  fileName: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 'bold',
    width: 'fit-content',
    marginRight: 3,
    wordBreak: 'break-all',
  },
  deleteButton: {
    padding: '2px',
    height: '22px',
    boxSizing: 'border-box',
  },
  closeIcon: {
    zIndex: 10,
    color: '#6D7878',
    width: '18px',
    height: '18px',
  },
  spinner: {
    marginRight: 3,
  },
}))

const AttachedFileChip = (props) => {
  const { extension, name, isEdit, downloadable, onRemove, onClick, downloading } = props
  const classes = useStyles({ isEdit, downloadable })

  const icon = downloading ? (
    <CircularProgress size={18} className={classes.spinner} />
  ) : isEdit ? (
    <EditButton onRemove={onRemove} />
  ) : null

  return (
    <div className={classes.chipContainer}>
      <DownloadWrapper onClick={onClick}>
        <Typography className={classes.fileExtension}>{extension}</Typography>
        <Typography className={classes.fileName}>{name}</Typography>
      </DownloadWrapper>
      {icon}
    </div>
  )
}

const DownloadWrapper = ({ onClick, children }) => {
  const classes = useStyles()
  return (
    <div className={classes.chipContainerLink} onClick={onClick}>
      {children}
    </div>
  )
}

AttachedFileChip.defaultProps = {
  onRemove: () => {},
  onClick: () => {},
}

const EditButton = ({ onRemove }) => {
  const classes = useStyles()
  return (
    <IconButton
      disableRipple
      aria-label="delete"
      className={classes.deleteButton}
      onClick={onRemove}
    >
      <CloseIcon className={classes.closeIcon} />
    </IconButton>
  )
}

export default AttachedFileChip
