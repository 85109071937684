import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import {
  DELETE_REPORT_CARD_CONFIG_FAILURE,
  DELETE_REPORT_CARD_CONFIG_REQUEST,
  DELETE_REPORT_CARD_CONFIG_SUCCESS,
} from '@/sections/report-card/config/types/apiInteractions'
import type {
  ReportCardConfigDeletionModalAction,
  ReportCardConfigDeletionModalState,
} from '@/sections/report-card/config/types/deletionModal'
import { SET_OPEN } from '@/sections/report-card/config/types/deletionModal'

const initialState: ReportCardConfigDeletionModalState = {
  open: false,
  loading: false,
  error: '',
  configIdToDelete: 0,
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_CONFIG_DELETION_MODAL

const ReportCardConfigDeletionModal: Reducer<
  ReportCardConfigDeletionModalState,
  ReportCardConfigDeletionModalAction
> = (state = initialState, action): ReportCardConfigDeletionModalState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case SET_OPEN:
      return { ...state, open: true, configIdToDelete: action.payload.configIdToDelete }
    case DELETE_REPORT_CARD_CONFIG_REQUEST:
      return { ...state, loading: true, error: initialState.error }
    case DELETE_REPORT_CARD_CONFIG_SUCCESS:
      return initialState
    case DELETE_REPORT_CARD_CONFIG_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, ReportCardConfigDeletionModal, requestDestination)
