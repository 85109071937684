import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type { Reducer } from 'redux'
import { Redux } from '@/legacy/redux'
import {
  LIST_ORGANIZATIONS_AS_PRINCIPAL_REQUEST,
  LIST_ORGANIZATIONS_AS_PRINCIPAL_SUCCESS,
  LIST_ORGANIZATIONS_AS_PRINCIPAL_FAILURE,
} from '@/legacy/redux/types/organizations'
import type {
  OrganizationsDrawerAction,
  OrganizationsDrawerState,
} from '@/sections/principal/management/types/organizationsDrawer'

const initialState: OrganizationsDrawerState = {
  organizations: [],
  loading: false,
  error: '',
}

const requestDestination = reduxRequestOriginMap.PRINCIPAL_ORGANIZATIONS_DRAWER

const AdminOrganizationsDrawerReducer: Reducer<
  OrganizationsDrawerState,
  OrganizationsDrawerAction
> = (state = initialState, action): OrganizationsDrawerState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case LIST_ORGANIZATIONS_AS_PRINCIPAL_REQUEST:
      return {
        ...state,
        loading: true,
        error: initialState.error,
      }
    case LIST_ORGANIZATIONS_AS_PRINCIPAL_SUCCESS:
      return {
        ...state,
        loading: false,
        organizations: [action.payload.organization],
      }
    case LIST_ORGANIZATIONS_AS_PRINCIPAL_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, AdminOrganizationsDrawerReducer, requestDestination)
