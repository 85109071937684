import { apiPrivate } from './..'
import { url } from '@aula/config'

export const RobotsAPI = {
  goToRobots,
  addEvent,
  getStudentProgress,
}

/**
 * Redirect to robots.
 *
 * @role Any
 * @param id Activity's id
 */
function goToRobots(id: number) {
  return apiPrivate.post(url + `/v1/robots/${id}/redirect`)
}

export enum RobotsEvent {
  JOINED = 'joined',
}

/**
 * Adds an event to an robots activity.
 *
 * @role Any
 * @param id
 * @param event
 */
function addEvent(id: number, event: RobotsEvent) {
  return apiPrivate.post(url + `/v1/robots/${id}/events?event=${event}`)
}

/**
 * Adds an event to an robots activity.
 *
 * @role Teacher
 * @param activityID
 * @param studentID
 */
function getStudentProgress(activityID: number, studentID: number) {
  return apiPrivate.post(url + `/v1/robots/${activityID}/student-progress?studentID=${studentID}`)
}
