import type { PremiumActivity } from '@/api/lms/library'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { Entity } from './entities'

// State.
export interface ActivitiesState {
  isOpenModal: boolean
  status: REQUEST_STATUS
  activities: PremiumActivity[]
  selectedEntities: Entity[]
  searchText: string
  pagination: {
    total: number
    limit: number
    offset: number
  }
}

export enum ACTIVITIES_TYPES {
  OPEN_MODAL = 'CONTENT_CREATOR/NEW_SEQUENCES/ACTIVITIES/OPEN_MODAL',
  CLOSE_MODAL = 'CONTENT_CREATOR/NEW_SEQUENCES/ACTIVITIES/CLOSE_MODAL',
  GET_ACTIVITIES_REQUEST = 'CONTENT_CREATOR/NEW_SEQUENCES/ACTIVITIES/GET_ACTIVITIES_REQUEST',
  GET_ACTIVITIES_SUCCESS = 'CONTENT_CREATOR/NEW_SEQUENCES/ACTIVITIES/GET_ACTIVITIES_SUCCESS',
  GET_ACTIVITIES_FAILURE = 'CONTENT_CREATOR/NEW_SEQUENCES/ACTIVITIES/GET_ACTIVITIES_FAILURE',
  CLEAN_ACTIVITIES = 'CONTENT_CREATOR/NEW_SEQUENCES/ACTIVITIES/CLEAN_ACTIVITIES',
  SET_PAGINATION_OFFSET = 'CONTENT_CREATOR/NEW_SEQUENCES/ACTIVITIES/SET_PAGINATION_OFFSET',
  SET_SEARCH_TEXT = 'CONTENT_CREATOR/NEW_SEQUENCES/ACTIVITIES/SET_SEARCH_TEXT',
  SET_SELECTED_ENTITY = 'CONTENT_CREATOR/NEW_SEQUENCES/ACTIVITIES/SET_SELECTED_ENTITY',
  REMOVE_SELECTED_ENTITY = 'CONTENT_CREATOR/NEW_SEQUENCES/ACTIVITIES/REMOVE_SELECTED_ENTITY',
}

// Actions.

// Open modal action.
export type OpenModalAction = {
  type: ACTIVITIES_TYPES.OPEN_MODAL
}

// Close modal action.
export type CloseModalAction = {
  type: ACTIVITIES_TYPES.CLOSE_MODAL
}

// Get activities actions.
export type GetActivitiesRequestAction = {
  type: ACTIVITIES_TYPES.GET_ACTIVITIES_REQUEST
}

export type GetActivitiesSuccessAction = {
  type: ACTIVITIES_TYPES.GET_ACTIVITIES_SUCCESS
  payload: {
    activities: PremiumActivity[]
    total: number
  }
}

export type GetActivitiesFailureAction = {
  type: ACTIVITIES_TYPES.GET_ACTIVITIES_FAILURE
}

export type GetActivitiesAction =
  | GetActivitiesRequestAction
  | GetActivitiesSuccessAction
  | GetActivitiesFailureAction

// Clean activities action.
export type CleanActivitiesAction = {
  type: ACTIVITIES_TYPES.CLEAN_ACTIVITIES
}

// Set pagination action.
export type SetPaginationOffsetAction = {
  type: ACTIVITIES_TYPES.SET_PAGINATION_OFFSET
  payload: {
    offset: number
  }
}

// Set search text action.
export type SetSearchTextAction = {
  type: ACTIVITIES_TYPES.SET_SEARCH_TEXT
  payload: {
    newValue: string
  }
}

// Set selected entity action.
export type SetSelectedEntityAction = {
  type: ACTIVITIES_TYPES.SET_SELECTED_ENTITY
  payload: {
    newEntity: Entity
  }
}

// Remove selected entity action.
export type RemoveSelectedEntityAction = {
  type: ACTIVITIES_TYPES.REMOVE_SELECTED_ENTITY
  payload: {
    idToRemove: number
  }
}

export type ActivitiesAction =
  | OpenModalAction
  | CloseModalAction
  | GetActivitiesAction
  | CleanActivitiesAction
  | SetPaginationOffsetAction
  | SetSearchTextAction
  | SetSelectedEntityAction
  | RemoveSelectedEntityAction
