import type { Audience } from '@/api/lms/library'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

// State.
export interface AudienceState {
  audiences: Audience[]
  status: REQUEST_STATUS
  searchText: string
  pagination: {
    count: number
    limit: number
    offset: number
    more: boolean
  }
}

export enum AUDIENCE_TYPES {
  GET_AUDIENCES_REQUEST = 'EBADMIN/MANAGEMENT/AUDIENCE/GET_AUDIENCES_REQUEST',
  GET_AUDIENCES_SUCCESS = 'EBADMIN/MANAGEMENT/AUDIENCE/GET_AUDIENCES_SUCCESS',
  GET_AUDIENCES_FAILURE = 'EBADMIN/MANAGEMENT/AUDIENCE/GET_AUDIENCES_FAILURE',
  SET_SEARCH_TEXT = 'EBADMIN/MANAGEMENT/AUDIENCE/SET_SEARCH_TEXT',
  SET_PAGINATION_OFFSET = 'EBADMIN/MANAGEMENT/AUDIENCE/SET_PAGINATION_OFFSET',
  CLEAN_AUDIENCE = 'EBADMIN/MANAGEMENT/AUDIENCE/CLEAN_AUDIENCE',
}

// Actions.
export type GetAudiencesRequestAction = {
  type: AUDIENCE_TYPES.GET_AUDIENCES_REQUEST
}

export type GetAudiencesSuccessAction = {
  type: AUDIENCE_TYPES.GET_AUDIENCES_SUCCESS
  payload: {
    audiences: AudienceState['audiences']
    count: number
    more: boolean
  }
}

export type GetAudiencesFailureAction = {
  type: AUDIENCE_TYPES.GET_AUDIENCES_FAILURE
}

export type GetAudiencesAction =
  | GetAudiencesRequestAction
  | GetAudiencesSuccessAction
  | GetAudiencesFailureAction

export type SetSearchTextAction = {
  type: AUDIENCE_TYPES.SET_SEARCH_TEXT
  payload: {
    newText: string
  }
}

export type SetPaginationOffsetAction = {
  type: AUDIENCE_TYPES.SET_PAGINATION_OFFSET
  payload: {
    newOffset: number
  }
}

export type CleanAudienceAction = {
  type: AUDIENCE_TYPES.CLEAN_AUDIENCE
}

// Action.
export type AudienceAction =
  | GetAudiencesAction
  | SetSearchTextAction
  | SetPaginationOffsetAction
  | CleanAudienceAction
