import * as React from 'react'
import type { SVGProps } from 'react'
const SvgNewMessages = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={13} cy={3} r={3} fill="#27A8FF" />
    <path
      d="M14.976 7.595a5 5 0 0 1-6.561-6.591 29.393 29.393 0 0 0-4.828.344c-1.184.181-2.121 1.06-2.324 2.253C1.12 4.434 1 5.491 1 6.671s.121 2.238.263 3.07A2.725 2.725 0 0 0 3.1 11.877v2.413a.7.7 0 0 0 1.056.61l4.28-2.561c1.55-.026 2.94-.184 3.978-.344 1.183-.182 2.12-1.06 2.323-2.254a18.69 18.69 0 0 0 .239-2.146Z"
      fill="#1A1A1A"
    />
  </svg>
)
export default SvgNewMessages
