import { isEmpty, mergeDeepLeft } from 'ramda'
import QUESTIONS_TYPES from '@/legacy/components/v1/types/editorConstants'

export const getFilledOptions = (options) => options.filter((option) => option.text !== '')

export const isSomeCorrectOption = (options) => options.some((option) => option.correct)

export const getFormattedOptions = (options) =>
  getFilledOptions(options).map((option, index) => ({ ...option, order: index + 1 }))

export const reorderQuestions = (questions) =>
  questions.map((question, index) => ({ ...question, order: index + 1 }))

export const retrieveQuestionsFromAPI = (questions) =>
  questions.map((question) => ({ ...question, options: question.options || [] }))

export const getFileExtension = (fileName) => {
  const re = /(?:\.([^.]+))?$/
  return re.exec(fileName)[1]
}

const filterQuestionTypes = (allowedTypes) => (question) => allowedTypes.includes(question.type)

export const getAnswersTemplates = (questions, answers = []) => {
  const filterFn = filterQuestionTypes([QUESTIONS_TYPES.WRITING, QUESTIONS_TYPES.CHOICE])
  const answersTemplate = questions.filter(filterFn).reduce(
    (acc, { type, id }) => ({
      ...acc,
      [id]: { questionId: id, type, optionId: 0, answer: '' },
    }),
    {}
  )
  const answersFiltered = answers.filter(filterFn)

  if (!isEmpty(answers)) {
    const formattedAnswers = answersFiltered.reduce(
      (acc, answer) => ({
        ...acc,
        [answer.question_id]: {
          type: answer.type,
          questionId: answer.question_id,
          optionId: answer.option_id || 0,
          answer: answer.answer || '',
        },
      }),
      {}
    )
    return { ...answersTemplate, ...formattedAnswers }
  }

  return answersTemplate
}

export const getAttachmentAnswersTemplates = (questions, answers = []) => {
  const filterFn = filterQuestionTypes([QUESTIONS_TYPES.ATTACHMENT])
  const answersTemplate = questions.filter(filterFn).reduce(
    (acc, { type, id }) => ({
      ...acc,
      [id]: { questionId: id, files: [], filesToUpload: [], error: '' },
    }),
    {}
  )
  const answersFiltered = answers.filter(filterFn)

  if (!isEmpty(answersFiltered)) {
    const formattedAnswers = answersFiltered.reduce(
      (acc, answer) => ({ ...acc, [answer.question_id]: { files: answer.files } }),
      {}
    )
    return mergeDeepLeft(formattedAnswers, answersTemplate)
  }
  return answersTemplate
}

export const filterUnansweredQuestions = (answers) =>
  Object.values(answers).filter((answer) => !(answer.option_id === 0 && answer.answer === ''))

export const filterUnattachedFilesQuestions = (answers) =>
  answers.filter((answer) => !isEmpty(answer.files))

export const acceptTypesBuilder = (types) => {
  const { documents, pdf, images, powerpoint, excel } = types
  const keys = []
  if (documents)
    keys.push(
      'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    )
  if (pdf) keys.push('application/pdf')
  if (images) keys.push('image/*')
  if (powerpoint)
    keys.push(
      'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation'
    )
  if (excel)
    keys.push(
      'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    )
  return keys.join(',')
}
