import type { Pages } from '@/legacy/components/v3/moreTablePagination'
import type { DocTypeOption } from '@/legacy/redux/types/docTypesOptions'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { AnswerSection } from './answersModal'

export interface AnswersDrawerState {
  isDrawerOpen: boolean
  isOpenModal: boolean
  isLoading: boolean
  answers: PreviewAnswers[]
  error: string
  columns: ColumnAnswer[]
  pagination: Pages
  actionStatus: REQUEST_STATUS
  isModalLoading: boolean
  modalAnswerFields: AnswerSection[]
  selectedAnswerId: number
  docTypesOptions: DocTypeOption[]
  answerFieldStatus: REQUEST_STATUS
  docTypesStatus: REQUEST_STATUS
  exportModal: {
    isOpen: boolean
    status: REQUEST_STATUS
  }
}

export interface AdmissionAnswers {
  id: number
  lastName: string
  name: string
  createdAt: string
  grade: string
  importUser: boolean
}

export type PreviewAnswers = AdmissionAnswers

export type ColumnAnswer = {
  meta: AnswerMeta
}

export type AnswerMeta = {
  name: string
  info?: string
  action?: string
  buttonLabel?: string
  represents?: string
}

export enum ANSWERS_DRAWER_TYPES {
  OPEN_DRAWER = 'FORMS/ANSWERS_DRAWER/OPEN_DRAWER',
  CLOSE_DRAWER = 'FORMS/ANSWERS_DRAWER/CLOSE_DRAWER',
  OPEN_MODAL = 'FORMS/ANSWERS_DRAWER/OPEN_MODAL',
  CLOSE_MODAL = 'FORMS/ANSWERS_DRAWER/CLOSE_MODAL',
  OPEN_EXPORT_ANSWERS_MODAL = 'FORMS/ANSWERS_DRAWER/OPEN_EXPORT_ANSWERS_MODAL',
  CLOSE_EXPORT_ANSWERS_MODAL = 'FORMS/ANSWERS_DRAWER/CLOSE_EXPORT_ANSWERS_MODAL',
  GET_PREVIEW_ANSWERS_REQUEST = 'FORMS/ANSWERS_DRAWER/GET_PREVIEW_ANSWERS_REQUEST',
  GET_PREVIEW_ANSWERS_SUCCESS = 'FORMS/ANSWERS_DRAWER/GET_PREVIEW_ANSWERS_SUCCESS',
  GET_PREVIEW_ANSWERS_FAILURE = 'FORMS/ANSWERS_DRAWER/GET_PREVIEW_ANSWERS_FAILURE',
  GET_COMPLETE_ANSWERS_REQUEST = 'FORMS/ANSWERS_DRAWER/GET_COMPLETE_ANSWERS_REQUEST',
  GET_COMPLETE_ANSWERS_SUCCESS = 'FORMS/ANSWERS_DRAWER/GET_COMPLETE_ANSWERS_SUCCESS',
  GET_COMPLETE_ANSWERS_FAILURE = 'FORMS/ANSWERS_DRAWER/GET_COMPLETE_ANSWERS_FAILURE',
  GET_ANSWERS_COLUMNS = 'FORMS/ANSWERS_DRAWER/ANSWERS_COLUMNS',
  ANSWER_ACTIONS_REQUEST = 'FORMS/ANSWERS_DRAWER/ANSWER_ACTIONS_REQUEST',
  ANSWER_ACTIONS_SUCCESS = 'FORMS/ANSWERS_DRAWER/ANSWER_ACTIONS_SUCCESS',
  ANSWER_ACTIONS_FAILURE = 'FORMS/ANSWERS_DRAWER/ANSWER_ACTIONS_FAILURE',
  GET_ANSWER_FIELDS_REQUEST = 'FORMS/ANSWERS_DRAWER/GET_ANSWER_FIELDS_REQUEST',
  GET_ANSWER_FIELDS_SUCCESS = 'FORMS/ANSWERS_DRAWER/GET_ANSWER_FIELDS_SUCCESS',
  GET_ANSWER_FIELDS_FAILURE = 'FORMS/ANSWERS_DRAWER/GET_ANSWER_FIELDS_FAILURE',
  SET_SELECTED_ANSWER_ID = 'FORMS/ANSWERS_DRAWER/SET_SELECTED_ANSWER_ID',
  GET_DOC_TYPES_OPTIONS_REQUEST = 'FORMS/ANSWERS_DRAWER/GET_DOC_TYPES_OPTIONS_REQUEST',
  GET_DOC_TYPES_OPTIONS_SUCCESS = 'FORMS/ANSWERS_DRAWER/GET_DOC_TYPES_OPTIONS_SUCCESS',
  GET_DOC_TYPES_OPTIONS_FAILURE = 'FORMS/ANSWERS_DRAWER/GET_DOC_TYPES_OPTIONS_FAILURE',
  EXPORT_ANSWERS_REQUEST = 'FORMS/ANSWERS_DRAWER/EXPORT_ANSWERS_REQUEST',
  EXPORT_ANSWERS_SUCCESS = 'FORMS/ANSWERS_DRAWER/EXPORT_ANSWERS_SUCCESS',
  EXPORT_ANSWERS_FAILURE = 'FORMS/ANSWERS_DRAWER/EXPORT_ANSWERS_FAILURE',
  CLEAR_EXPORT_ANSWERS_MODAL = 'FORMS/ANSWERS_DRAWER/CLEAR_EXPORT_ANSWERS_MODAL',
}

// Drawer actions
export type OpenDrawerAction = {
  type: ANSWERS_DRAWER_TYPES.OPEN_DRAWER
}

export type CloseDrawerAction = {
  type: ANSWERS_DRAWER_TYPES.CLOSE_DRAWER
}
// Modal actions
export type OpenModalAction = {
  type: ANSWERS_DRAWER_TYPES.OPEN_MODAL
}

export type CloseModalAction = {
  type: ANSWERS_DRAWER_TYPES.CLOSE_MODAL
}

export type AnswerActionsRequestAction = {
  type: ANSWERS_DRAWER_TYPES.ANSWER_ACTIONS_REQUEST
}

export type AnswerActionsSuccessAction = {
  type: ANSWERS_DRAWER_TYPES.ANSWER_ACTIONS_SUCCESS
}

export type AnswerActionsFailureAction = {
  type: ANSWERS_DRAWER_TYPES.ANSWER_ACTIONS_FAILURE
  payload: {
    error: string
  }
}

export type AnswersActions =
  | AnswerActionsRequestAction
  | AnswerActionsSuccessAction
  | AnswerActionsFailureAction

export type GetPreviewAnswersRequestAction = {
  type: ANSWERS_DRAWER_TYPES.GET_PREVIEW_ANSWERS_REQUEST
}

export type GetPreviewAnswersSuccessAction = {
  type: ANSWERS_DRAWER_TYPES.GET_PREVIEW_ANSWERS_SUCCESS
  payload: {
    answers: PreviewAnswers[]
    page: number
    count: number
    more: boolean
  }
}

export type GetPreviewAnswersFailureAction = {
  type: ANSWERS_DRAWER_TYPES.GET_PREVIEW_ANSWERS_FAILURE
  payload: {
    error: string
  }
}

export type GetColumnsAnswersAction = {
  type: ANSWERS_DRAWER_TYPES.GET_ANSWERS_COLUMNS
  payload: {
    columns: ColumnAnswer[]
  }
}

export type GetAnswerFieldsRequestAction = {
  type: ANSWERS_DRAWER_TYPES.GET_ANSWER_FIELDS_REQUEST
}

export type GetAnswerFieldsSuccessAction = {
  type: ANSWERS_DRAWER_TYPES.GET_ANSWER_FIELDS_SUCCESS
  payload: { answerFields: AnswerSection[] }
}

export type GetAnswerFieldsFailureAction = {
  type: ANSWERS_DRAWER_TYPES.GET_ANSWER_FIELDS_FAILURE
  payload: { error: string }
}

export type GetDocTypesOptionsRequestAction = {
  type: ANSWERS_DRAWER_TYPES.GET_DOC_TYPES_OPTIONS_REQUEST
}

export type GetDocTypesOptionsSuccessAction = {
  type: ANSWERS_DRAWER_TYPES.GET_DOC_TYPES_OPTIONS_SUCCESS
  payload: { docTypesOptions: DocTypeOption[] }
}

export type GetDocTypesOptionsFailureAction = {
  type: ANSWERS_DRAWER_TYPES.GET_DOC_TYPES_OPTIONS_FAILURE
  payload: { error: string }
}

export type ExportAnswersRequestAction = {
  type: ANSWERS_DRAWER_TYPES.EXPORT_ANSWERS_REQUEST
}

export type ExportAnswersSuccessAction = {
  type: ANSWERS_DRAWER_TYPES.EXPORT_ANSWERS_SUCCESS
}

export type ExportAnswersFailureAction = {
  type: ANSWERS_DRAWER_TYPES.EXPORT_ANSWERS_FAILURE
}

export type ClearExportAnswersModal = {
  type: ANSWERS_DRAWER_TYPES.CLEAR_EXPORT_ANSWERS_MODAL
}

export type SetSelectedAnswerIdAction = {
  type: ANSWERS_DRAWER_TYPES.SET_SELECTED_ANSWER_ID
  payload: { answerId: number }
}

export type OpenExportAnswersModalAction = {
  type: ANSWERS_DRAWER_TYPES.OPEN_EXPORT_ANSWERS_MODAL
}

export type CloseExportAnswersModalAction = {
  type: ANSWERS_DRAWER_TYPES.CLOSE_EXPORT_ANSWERS_MODAL
}

export type AnswersDrawerActions =
  | OpenDrawerAction
  | CloseDrawerAction
  | AnswersActions
  | AnswerActionsRequestAction
  | AnswerActionsSuccessAction
  | AnswerActionsFailureAction
  | OpenModalAction
  | CloseModalAction
  | GetPreviewAnswersRequestAction
  | GetPreviewAnswersSuccessAction
  | GetPreviewAnswersFailureAction
  | GetAnswerFieldsRequestAction
  | GetAnswerFieldsSuccessAction
  | GetAnswerFieldsFailureAction
  | GetDocTypesOptionsRequestAction
  | GetDocTypesOptionsSuccessAction
  | GetDocTypesOptionsFailureAction
  | ExportAnswersRequestAction
  | ExportAnswersSuccessAction
  | ExportAnswersFailureAction
  | ClearExportAnswersModal
  | SetSelectedAnswerIdAction
  | GetColumnsAnswersAction
  | OpenExportAnswersModalAction
  | CloseExportAnswersModalAction
