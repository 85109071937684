import { combineReducers } from 'redux'
import addEditClassroomModal from './reducers/addEditClassroomModal'
import addEditExamModal from './reducers/addEditExamModal'
import deleteExamConfirmModal from './reducers/deleteExamConfirmModal'
import detailsExamModal from './reducers/detailsExamModal'
import classroomSubjectsModal from './reducers/classroomSubjectsModal'
import classroomSelectorModal from './reducers/classroomSelectorModal'
import stageSelectorModal from './reducers/stageSelectorModal'
import cosmosModal from './reducers/cosmosModal'
import deleteClassroomConfirmModal from './reducers/deleteClassroomConfirmModal'
import organizations from './reducers/organizations'
import subjects from './reducers/subjects'
import users from './reducers/users'
import addProfileModal from './reducers/addProfileModal'
import customizeThemeModal from './reducers/customizeThemeModal'
import audienceModal from './reducers/audienceModal'
import confirmFinishExamModal from './reducers/confirmFinishExamModal'

const managementReducer = combineReducers({
  deleteClassroomConfirmModal,
  addEditClassroomModal,
  addEditExamModal,
  deleteExamConfirmModal,
  detailsExamModal,
  classroomSubjectsModal,
  classroomSelectorModal,
  stageSelectorModal,
  cosmosModal,
  audienceModal,
  users,
  organizations,
  subjects,
  addProfileModal,
  customizeThemeModal,
  confirmFinishExamModal,
})

export default managementReducer
