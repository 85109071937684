import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCollabMiniature = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={69}
    height={69}
    viewBox="0 0 69 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect opacity={0.15} width={69} height={69} rx={6} fill="#FD5" />
    <path
      d="M56.274 50.668h-3.728v-26.06a.726.726 0 0 0-.726-.726h-.87v-4.357a.726.726 0 0 0-.727-.726h-1.089v-1.742a.726.726 0 0 0-.726-.727h-.654v-1.016a.726.726 0 1 0-1.452 0v1.016h-.653a.726.726 0 0 0-.726.726V18.8h-1.09a.726.726 0 0 0-.725.726v4.357h-.871a.726.726 0 0 0-.727.726V43.6h-4.985V28.32c0-.402-.325-.727-.727-.727H31.49v-3.328a5.598 5.598 0 0 0 4.945-5.553 5.597 5.597 0 0 0-5.591-5.59 5.585 5.585 0 0 0-5.362 4h-2.268a.726.726 0 0 0-.726.727v12.449H18.18a.726.726 0 0 0-.726.726v19.644h-3.728a.726.726 0 1 0 0 1.452h42.548a.726.726 0 1 0 0-1.452Zm-33.787 0h-3.58V48.57h1.876a.726.726 0 0 0 0-1.453h-1.877V45.84h1.877a.726.726 0 0 0 0-1.452h-1.877V43.11h1.877a.726.726 0 0 0 0-1.452h-1.877V40.38h1.877a.726.726 0 0 0 0-1.452h-1.877v-1.278h1.877a.726.726 0 0 0 0-1.452h-1.877v-1.278h1.877a.726.726 0 1 0 0-1.452h-1.877V31.75h3.581v18.918Zm23.888-32.885h1.307v1.016h-1.307v-1.016ZM44.56 20.25h4.937v3.63H44.56v-3.63Zm-13.717-5.678a4.143 4.143 0 0 1 4.14 4.139A4.145 4.145 0 0 1 31.49 22.8v-4.951a.726.726 0 0 0-.726-.726h-3.745a4.136 4.136 0 0 1 3.824-2.55Zm-2.596 4.523a.726.726 0 0 0-.726.726V50.668H23.94V18.575h6.099v9.018h-1.065v-7.771a.726.726 0 0 0-.726-.726Zm4.308 25.23v6.342h-3.581V29.045h6.098V43.6h-1.79c-.402 0-.727.325-.727.726ZM47.9 50.668H34.007v-5.616H47.9v5.616Zm3.194 0h-1.742v-6.342a.726.726 0 0 0-.726-.726h-5.663V25.334h8.13v25.334Z"
      fill="#FD5"
    />
    <path
      d="M56.274 53.427H13.726a.726.726 0 0 0 0 1.452h42.548a.726.726 0 1 0 0-1.452ZM25.73 19.096a.726.726 0 0 0-.726.726v29.6a.726.726 0 0 0 1.452 0v-29.6a.726.726 0 0 0-.726-.726ZM30.764 29.536a.726.726 0 0 0-.726.726v19.19a.726.726 0 0 0 1.453 0v-19.19a.726.726 0 0 0-.727-.726ZM33.281 29.536a.726.726 0 0 0-.726.726v11.482a.726.726 0 1 0 1.452 0V30.262a.726.726 0 0 0-.726-.726ZM45.431 26.302a.726.726 0 0 0-.726.726v14.716a.726.726 0 0 0 1.452 0V27.028a.726.726 0 0 0-.726-.726ZM48.626 26.302a.726.726 0 0 0-.726.726v14.716a.726.726 0 1 0 1.452 0V27.028a.726.726 0 0 0-.726-.726ZM45.963 20.888a.726.726 0 0 0-.726.726v.905a.726.726 0 1 0 1.453 0v-.905a.726.726 0 0 0-.727-.726ZM48.093 20.888a.726.726 0 0 0-.726.726v.905a.726.726 0 1 0 1.452 0v-.905a.726.726 0 0 0-.726-.726ZM46.278 45.988h-10.65a.726.726 0 1 0 0 1.452h10.65a.726.726 0 1 0 0-1.452ZM46.278 48.182h-10.65a.726.726 0 1 0 0 1.453h10.65a.726.726 0 1 0 0-1.453Z"
      fill="#FDD429"
    />
  </svg>
)
export default SvgCollabMiniature
