export default {
  DASHBOARD_REQUEST: 'DASHBOARD_TEACHER/DASHBOARD_REQUEST',
  DASHBOARD_SUCCESS: 'DASHBOARD_TEACHER/DASHBOARD_SUCCESS',
  DASHBOARD_FAILURE: 'DASHBOARD_TEACHER/DASHBOARD_FAILURE',
  DASHBOARD_V4_REQUEST: 'DASHBOARD_TEACHER/DASHBOARD_V4_REQUEST',
  DASHBOARD_V4_SUCCESS: 'DASHBOARD_TEACHER/DASHBOARD_V4_SUCCESS',
  DASHBOARD_V4_FAILURE: 'DASHBOARD_TEACHER/DASHBOARD_V4_FAILURE',
  ACTIVITIES_REQUEST: 'DASHBOARD_TEACHER/ACTIVITIES_REQUEST',
  ACTIVITIES_SUCCESS: 'DASHBOARD_TEACHER/ACTIVITIES_SUCCESS',
  ACTIVITIES_FAILURE: 'DASHBOARD_TEACHER/ACTIVITIES_FAILURE',
  SET_CLASSROOM: 'DASHBOARD_TEACHER/SET_CLASSROOM',
  RESET_CLASSROOM: 'DASHBOARD_TEACHER/RESET_CLASSROOM',
  CLASSROOMS_REQUEST: 'DASHBOARD_TEACHER/CLASSROOMS_REQUEST',
  CLASSROOMS_SUCCESS: 'DASHBOARD_TEACHER/CLASSROOMS_SUCCESS',
  CLASSROOMS_FAILURE: 'DASHBOARD_TEACHER/CLASSROOMS_FAILURE',
  TOGGLE_SEARCH_BAR: 'DASHBOARD_TEACHER/TOGGLE_SEARCH_BAR',
  SET_SEARCH: 'DASHBOARD_TEACHER/SET_SEARCH',
  SEARCH_REQUEST: 'DASHBOARD_TEACHER/SEARCH_REQUEST',
  SEARCH_SUCCESS: 'DASHBOARD_TEACHER/SEARCH_SUCCESS',
  SEARCH_FAILURE: 'DASHBOARD_TEACHER/SEARCH_FAILURE',
  MODAL_CONFIRM_CERTIFICATE: 'DASHBOARD_TEACHER/MODAL_CONFIRM_CERTIFICATE',
  MODAL_CLOSE_CONFIRM_CERTIFICATE: 'DASHBOARD_TEACHER/MODAL_CLOSE_CONFIRM_CERTIFICATE',
}
