import * as React from 'react'
import type { SVGProps } from 'react'
const SvgMonday = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.823 1v3h10V1h2v3h2a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3h-18a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h2V1h2Zm12 5h-16a1 1 0 0 0-1 1v2h20V7a1 1 0 0 0-1-1h-2Zm-17 5v10a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V11h-20Z"
      fill="#1A1A1A"
    />
    <path
      d="M4.787 19.47v-6.3h1.152v5.4h2.79v.9H4.787Zm7.114.108c-.462 0-.879-.09-1.25-.27a2.135 2.135 0 0 1-.892-.837c-.216-.372-.324-.843-.324-1.413V13.17h1.152v3.897c0 .492.117.858.351 1.098.24.24.57.36.99.36.414 0 .741-.12.981-.36s.36-.606.36-1.098V13.17h1.152v3.888c0 .57-.114 1.04-.342 1.413a2.166 2.166 0 0 1-.918.837c-.378.18-.798.27-1.26.27Zm3.696-.108v-6.3h1.152l2.961 4.437V13.17h1.152v6.3H19.71l-2.96-4.428v4.428h-1.153Z"
      fill="#000"
    />
  </svg>
)
export default SvgMonday
