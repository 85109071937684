import type { ROLE } from '@aula/config'
import type { DocTypeOption, Document } from '@/legacy/redux/types/docTypesOptions'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { User } from '@/legacy/services/api/lms/users/types'
import type { Suggestion } from '@/legacy/components/v3/inputs/autosuggestInput'

export type RelationshipAssigmentChild = {
  userId: number
  relatedToId: number
  role: ROLE
  relation: string
  organizationId?: number
}

export type ChildrenAssignmentOrganization = {
  label: string
  id: number
}

export interface ChildOrganization {
  label: string
  id: number
}

export interface Child extends User {
  organization: ChildOrganization
}

export interface ChildrenAssignmentsModalState {
  open: boolean
  loading: boolean
  error: string
  currentChildren: Child[]
  childrenDocument: Document
  organization: ChildrenAssignmentOrganization
  RelationshipAssigmentChild: RelationshipAssigmentChild
  statusCreateOrModify: REQUEST_STATUS
  loadingCreateOrModify: boolean
  statusRemoveRelationship: REQUEST_STATUS
  loadingRemoveRelationship: boolean
}

// Toggle modal open
export const OPEN_MODAL = 'INPUTS/CHILDREN_ASSIGNMENTS_INPUT/OPEN_MODAL'
export type OpenModal = {
  type: typeof OPEN_MODAL
  payload: { children: Child[] }
}
export const CLOSE_MODAL = 'INPUTS/CHILDREN_ASSIGNMENTS_INPUT/CLOSE_MODAL'
export type CloseModal = {
  type: typeof CLOSE_MODAL
}

// Select organization suggestion
export const SELECT_ORGANIZATION_SUGGESTION =
  'INPUTS/CHILDREN_ASSIGNMENTS_INPUT/SELECT_ORGANIZATION_SUGGESTION'
export type SelectOrganizationSuggestion = {
  type: typeof SELECT_ORGANIZATION_SUGGESTION
  payload: { id: number; label: string }
}

// Document input
export const CHANGE_DOCUMENT_TYPE = 'INPUTS/CHILDREN_ASSIGNMENTS_INPUT/CHANGE_DOCUMENT_TYPE'
export type ChangeDocumentType = {
  type: typeof CHANGE_DOCUMENT_TYPE
  payload: { docType: DocTypeOption }
}
export const CHANGE_DOCUMENT_VALUE = 'INPUTS/CHILDREN_ASSIGNMENTS_INPUT/CHANGE_DOCUMENT_VALUE'
export type ChangeDocumentValue = {
  type: typeof CHANGE_DOCUMENT_VALUE
  payload: { newValue: string }
}

// Add child
export const ADD_CHILD_REQUEST = 'INPUTS/CHILDREN_ASSIGNMENTS_INPUT/ADD_CHILD_REQUEST'
export type AddChildRequest = {
  type: typeof ADD_CHILD_REQUEST
}
export const ADD_CHILD_SUCCESS = 'INPUTS/CHILDREN_ASSIGNMENTS_INPUT/ADD_CHILD_SUCCESS'
export type AddChildSuccess = {
  type: typeof ADD_CHILD_SUCCESS
  payload: { child: Child }
}
export const ADD_CHILD_FAILURE = 'INPUTS/CHILDREN_ASSIGNMENTS_INPUT/ADD_CHILD_FAILURE'
export type AddChildFailure = {
  type: typeof ADD_CHILD_FAILURE
}

// Remove child
export const REMOVE_CHILD = 'INPUTS/CHILDREN_ASSIGNMENTS_INPUT/REMOVE_CHILD'
export type RemoveSelectedChild = {
  type: typeof REMOVE_CHILD
  payload: { child: Child }
}

// Remove all childs
export const REMOVE_ALL_CHILDS = 'INPUTS/CHILDREN_ASSIGNMENTS_INPUT/REMOVE_ALL_CHILDS'
export type RemoveAllSelectedChilds = {
  type: typeof REMOVE_ALL_CHILDS
}

export const ADD_NEW_RELATIONSHIP_CHILD_REQUEST =
  'INPUTS/CHILDREN_ASSIGNMENTS_INPUT/ADD_NEW_RELATIONSHIP_CHILD_REQUEST'
export type AddNewRelationshipChildRequest = {
  type: typeof ADD_NEW_RELATIONSHIP_CHILD_REQUEST
}
export const ADD_NEW_RELATIONSHIP_CHILD_SUCCESS =
  'INPUTS/CHILDREN_ASSIGNMENTS_INPUT/ADD_NEW_RELATIONSHIP_CHILD_SUCCESS'
export type AddNewRelationshipChildSuccess = {
  type: typeof ADD_NEW_RELATIONSHIP_CHILD_SUCCESS
}
export const ADD_NEW_RELATIONSHIP_CHILD_FAILURE =
  'INPUTS/CHILDREN_ASSIGNMENTS_INPUT/ADD_NEW_RELATIONSHIP_CHILD_FAILURE'
export type AddNewRelationshipChildFailure = {
  type: typeof ADD_NEW_RELATIONSHIP_CHILD_FAILURE
}

export const REMOVE_RELATIONSHIP_CHILD_REQUEST =
  'INPUTS/CHILDREN_ASSIGNMENTS_INPUT/REMOVE_RELATIONSHIP_CHILD_REQUEST'
export type RemoveRelationshipChildRequest = {
  type: typeof REMOVE_RELATIONSHIP_CHILD_REQUEST
}

export const REMOVE_RELATIONSHIP_CHILD_SUCCESS =
  'INPUTS/CHILDREN_ASSIGNMENTS_INPUT/REMOVE_RELATIONSHIP_CHILD_SUCCESS'
export type RemoveRelationshipChildSuccess = {
  type: typeof REMOVE_RELATIONSHIP_CHILD_SUCCESS
}

export const REMOVE_RELATIONSHIP_CHILD_FAILURE =
  'INPUTS/CHILDREN_ASSIGNMENTS_INPUT/REMOVE_RELATIONSHIP_CHILD_FAILURE'
export type RemoveRelationshipChildFailure = {
  type: typeof REMOVE_RELATIONSHIP_CHILD_FAILURE
}

export const CLEAR_STATE = 'INPUTS/CHILDREN_ASSIGNMENTS_INPUT/CLEAR_STATE'
export type ClearState = {
  type: typeof CLEAR_STATE
}

export type AssignmentsInputsAction =
  | OpenModal
  | CloseModal
  | SelectOrganizationSuggestion
  | ChangeDocumentType
  | ChangeDocumentValue
  | AddChildRequest
  | AddChildSuccess
  | AddChildFailure
  | RemoveSelectedChild
  | RemoveAllSelectedChilds
  | AddNewRelationshipChildRequest
  | AddNewRelationshipChildSuccess
  | AddNewRelationshipChildFailure
  | RemoveRelationshipChildRequest
  | RemoveRelationshipChildSuccess
  | RemoveRelationshipChildFailure
  | ClearState
