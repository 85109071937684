import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { Pages } from '@/legacy/components/v3/moreTablePagination'
import { FeatureFlag } from '@/api/lms/features'

// State.
export interface FeatureFlagDrawerState {
    featuresList: FeatureFlag[]
    status: REQUEST_STATUS
    pagination: Pages
}

// Types.
export enum FEATURE_FLAG_DRAWER_TYPES {
    GET_FEATURE_FLAGS_REQUEST = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/DRAWER/GET_FEATURE_FLAGS_REQUEST',
    GET_FEATURE_FLAGS_FAILURE = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/DRAWER/GET_FEATURE_FLAGS_FAILURE',
    GET_FEATURE_FLAGS_SUCCESS = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/DRAWER/GET_FEATURE_FLAGS_SUCCESS',
};

// Actions.
// Get feature flags actions.
export type GetFeatureFlagsRequestAction = {
    type: FEATURE_FLAG_DRAWER_TYPES.GET_FEATURE_FLAGS_REQUEST,
};

export type GetFeatureFlagsSuccessAction = {
    type: FEATURE_FLAG_DRAWER_TYPES.GET_FEATURE_FLAGS_SUCCESS,
    payload: {
        count: number
        more: boolean
        page: number
        features: FeatureFlag[]
    }
};

export type GetFeatureFlagsFailureAction = {
    type: FEATURE_FLAG_DRAWER_TYPES.GET_FEATURE_FLAGS_FAILURE,
};

export type GetFeatureFlagsAction = GetFeatureFlagsRequestAction | GetFeatureFlagsSuccessAction | GetFeatureFlagsFailureAction;

// Action.
export type FeatureFlagDrawerAction = GetFeatureFlagsAction;