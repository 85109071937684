import type { Moment } from 'moment'
import type { AttendancesDay, AttendanceStatus } from '@/api/attendances/config'
import type {
  GetClassroomsAsPrincipal,
  PrincipalClassroom,
} from '@/legacy/redux/types/classrooms'
import type { SetField } from '@/legacy/redux/types/generic'
import type { GenericActionWithType } from '@/legacy/redux/utils'
import type { Pages } from '@/sections/content-creator/dashboard/types/dashboard'
import type {
  GetAndValidateClassroomsSubjectsAction,
  GetClassroomAttendancesConfigAction,
  GetOrganizationAttendanceConfigsAction,
  SaveAttendanceConfigAction,
  UpdateAttendanceConfigAction,
} from './apiInteractions'
import type * as datesModalTypes from './datesModal'
import type { AttendancesSubject, SubjectXDay } from './entities'

export const TOGGLE_CLASSROOM = 'ATTENDANCES/CONFIG/MAIN_TAB/TOGGLE_CLASSROOM'

export type ToggleClassroom = GenericActionWithType<
  typeof TOGGLE_CLASSROOM,
  { classroom: PrincipalClassroom }
>

export enum AttendanceConfigStep {
  CLASSROOM_SELECTION = 'classroom-selection',
  DAYS_AND_STATUSES = 'days-and-statuses',
}

export type DayOffCategory = {
  reason: string
  dates: Moment[]
}

export const REMOVE_ABSENCE_CATEGORY = 'ATTENDANCES/CONFIG/MAIN_TAB/REMOVE_ABSENCE_CATEGORY'

export type RemoveAbsenceCategory = GenericActionWithType<
  typeof REMOVE_ABSENCE_CATEGORY,
  { absenceReason: string }
>

export const ADD_STATUS = 'ATTENDANCES/CONFIG/MAIN_TAB/ADD_STATUS'
export type AddStatus = GenericActionWithType<typeof ADD_STATUS>

export const EDIT_STATUS = 'ATTENDANCES/CONFIG/MAIN_TAB/EDIT_STATUS'
export type EditStatus = GenericActionWithType<
  typeof EDIT_STATUS,
  { index: number; status: AttendanceStatus }
>

export const REMOVE_STATUS = 'ATTENDANCES/CONFIG/MAIN_TAB/REMOVE_STATUS'
export type RemoveStatus = GenericActionWithType<typeof REMOVE_STATUS, { index: number }>

export const SET_STEP = 'ATTENDANCES/CONFIG/MAIN_TAB/SET_STEP'
export type SetStep = GenericActionWithType<
  typeof SET_STEP,
  {
    step: AttendanceConfigStep
    editingClassroom?: PrincipalClassroom
  }
>

export const SET_DAYS = 'ATTENDANCES/CONFIG/MAIN_TAB/SET_DAYS'
export type SetDays = GenericActionWithType<
  typeof SET_DAYS,
  {
    days: number[]
  }
>

export const SET_GENERAL_ATTENDANCES = 'ATTENDANCES/CONFIG/MAIN_TAB/SET_GENERAL_ATTENDANCES'
export type SetGeneralAttendances = GenericActionWithType<
  typeof SET_GENERAL_ATTENDANCES,
  {
    setGeneralAttendances: boolean
  }
>

export const SET_ATTENDANCES_BY_SUBJECT = 'ATTENDANCES/CONFIG/MAIN_TAB/SET_ATTENDANCES_BY_SUBJECT'
export type SetAttendancesBySubject = GenericActionWithType<
  typeof SET_ATTENDANCES_BY_SUBJECT,
  {
    setAttendancesBySubject: boolean
  }
>

export const SET_SUBJECT_X_DAY = 'ATTENDANCES/CONFIG/MAIN_TAB/SET_SUBJECT_X_DAY'
export type SetSubjectXDay = GenericActionWithType<
  typeof SET_SUBJECT_X_DAY,
  {
    subject: number
    day: number
    selected: boolean
  }
>

export interface AttendancesConfigMainTabState {
  step: AttendanceConfigStep
  classrooms: PrincipalClassroom[]
  configuredClassroomIds: number[]
  saving: boolean
  loadingClassrooms: boolean
  loadingConfiguredClassroomIds: boolean
  loadingExistingConfig: boolean
  classroomsPagination: Pages // TODO: Define pages globally
  selectedClassrooms: PrincipalClassroom[]
  error: string
  search: string
  days: AttendancesDay[]
  absenceCategories: DayOffCategory[]
  statuses: AttendanceStatus[]
  editMode: {
    active: boolean
    configId: number
    classroomId: number
  }
  generalAttendances: boolean
  attendancesBySubject: boolean
  subjects: AttendancesSubject[]
  validSubjects: boolean
  subjectsXDays: SubjectXDay[]
  alreadyConfiguredSubjects: number[]
  alreadyConfiguredDays: number[]
}

export type AttendancesConfigMainTabAction =
  | SetField<'open' | 'days', boolean | AttendancesDay[]>
  | SetStep
  | GetClassroomAttendancesConfigAction
  | GetOrganizationAttendanceConfigsAction
  | SaveAttendanceConfigAction
  | UpdateAttendanceConfigAction
  | GetClassroomsAsPrincipal
  | ToggleClassroom
  | RemoveAbsenceCategory
  | AddStatus
  | EditStatus
  | RemoveStatus
  | datesModalTypes.ConfirmDates
  | SetGeneralAttendances
  | SetAttendancesBySubject
  | GetAndValidateClassroomsSubjectsAction
  | SetSubjectXDay
  | SetDays
