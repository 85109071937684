import type { Moment } from 'moment'
import type { EvaluateOfflineActivityAction } from '@/legacy/redux/types/activities'

export const SET_OPEN = 'STUDENT_LIST/OPEN_STUDENT_LIST'
export const SET_CLOSE = 'STUDENT_LIST/CLOSE_STUDENT_LIST'
export const SET_SELECTED_STUDENT = 'STUDENT_LIST/SET_SELECTED_STUDENT'
export const SET_GRADE = 'STUDENT_LIST/SET_GRADE'
export const SET_FEEDBACK = 'STUDENT_LIST/SET_FEEDBACK'
export const SET_GRADE_EDITABLE = 'STUDENT_LIST/SET_GRADE_EDITABLE'
export const GET_STUDENT_LIST_REQUEST = 'STUDENT_LIST/GET_STUDENT_LIST_REQUEST'
export const GET_STUDENT_LIST_SUCCESS = 'STUDENT_LIST/GET_STUDENT_LIST_SUCCESS'
export const GET_STUDENT_LIST_FAILURE = 'STUDENT_LIST/GET_STUDENT_LIST_FAILURE'
export const EVALUATE_SUBMISSION_REQUEST = 'STUDENT_LIST/EVALUATE_SUBMISSION_REQUEST'
export const EVALUATE_SUBMISSION_SUCCESS = 'STUDENT_LIST/EVALUATE_SUBMISSION_SUCCESS'
export const EVALUATE_SUBMISSION_FAILURE = 'STUDENT_LIST/EVALUATE_SUBMISSION_FAILURE'
export const ALLOW_RESUBMISSION_REQUEST = 'STUDENT_LIST/ALLOW_RESUBMISSION_REQUEST'
export const ALLOW_RESUBMISSION_SUCCESS = 'STUDENT_LIST/ALLOW_RESUBMISSION_SUCCESS'
export const ALLOW_RESUBMISSION_FAILURE = 'STUDENT_LIST/ALLOW_RESUBMISSION_FAILURE'
export const CHANGE_EVALUATION_TYPE_REQUEST = 'STUDENT_LIST/CHANGE_EVALUATION_TYPE_REQUEST'
export const CHANGE_EVALUATION_TYPE_SUCCESS = 'STUDENT_LIST/CHANGE_EVALUATION_TYPE_SUCCESS'
export const CHANGE_EVALUATION_TYPE_FAILURE = 'STUDENT_LIST/CHANGE_EVALUATION_TYPE_FAILURE'
export const SET_QUESTION_GRADE = 'STUDENT_LIST/SET_QUESTION_GRADE'
export const ADD_QUESTION_NOTE = 'STUDENT_LIST/ADD_QUESTION_NOTE'
export const REMOVE_QUESTION_NOTE = 'STUDENT_LIST/REMOVE_QUESTION_NOTE'
export const REPLACE_QUESTION_NOTE = 'STUDENT_LIST/REPLACE_QUESTION_NOTE'
export const GET_STUDENT_SUSPICIOUS_ACTIVITY_REQUEST =
  'STUDENT_LIST/GET_STUDENT_SUSPICIOUS_ACTIVITY_REQUEST'
export const GET_STUDENT_SUSPICIOUS_ACTIVITY_SUCCESS =
  'STUDENT_LIST/GET_STUDENT_SUSPICIOUS_ACTIVITY_SUCCESS'
export const GET_STUDENT_SUSPICIOUS_ACTIVITY_FAILURE =
  'STUDENT_LIST/GET_STUDENT_SUSPICIOUS_ACTIVITY_FAILURE'

// TODO Add remain props
export interface LastSubmission {
  id: number
  date: string
}

export interface Note {
  id: number
  startIndex: number
  endIndex: number
  color: string
  comment: string
}

export interface QuestionGrade {
  questionId: number
  score: number
  comment: string
  maxScore: number
  notes: Note[]
}

export interface Grade {
  value: number
  feedback: string
  items?: QuestionGrade[]
}

export interface Evaluation {
  date: string
  feedback: string
  id: number
  value: number
}

export interface Student {
  email: string // TODO: this is not coming in some responses
  evaluation: Evaluation | null
  id: number
  robotsId: number
  lastName: string
  name: string
  submitted: boolean
  avatar: string
}

export interface Organization {
  id: number
  name: string
}

export interface Classroom {
  id: number
  division: string
  grade: string
  organization: Organization
  organizationType: string
  shift: string
  year: number
}

interface ClassroomSubmission {
  id: number
  division: string
  grade: string
  shift: string
  stage: string
  year: number
}

export interface Subject {
  id: number
  name: string
  color: string
}

export interface ActivitySubmission {
  id: number
  classroom: ClassroomSubmission
  subject: Subject
  organization: Organization
  title: string
  type: string
  lastSubmission: LastSubmission
  evaluationType: number
}

export interface Activity {
  id: number
  classroom: Classroom
  subject: Subject
  title: string
  type: string
  evaluationType: number
}

export interface OpenStudentList {
  type: typeof SET_OPEN
  payload: {
    activity: Activity
  }
}

export interface SetSelectedStudent {
  type: typeof SET_SELECTED_STUDENT
  payload: {
    student: Student
  }
}

export interface SetGrade {
  type: typeof SET_GRADE
  payload: {
    grade: number
  }
}

export interface SetFeedback {
  type: typeof SET_FEEDBACK
  payload: {
    feedback: string
  }
}

export interface SetGradeEditable {
  type: typeof SET_GRADE_EDITABLE
  payload: {
    editable: boolean
  }
}

export interface CloseStudentList {
  type: typeof SET_CLOSE
}

export interface GetStudentListRequest {
  type: typeof GET_STUDENT_LIST_REQUEST
  payload: {
    count: number
    page: number
    rowsPerPage: number
  }
}

export interface GetStudentListSuccess {
  type: typeof GET_STUDENT_LIST_SUCCESS
  payload: {
    students: Student[]
    total: number
  }
}

export interface GetStudentListFailure {
  type: typeof GET_STUDENT_LIST_FAILURE
  payload: {
    error: string
  }
}

export interface EvaluateSubmissionRequest {
  type: typeof EVALUATE_SUBMISSION_REQUEST
}

export interface EvaluateSubmissionSuccess {
  type: typeof EVALUATE_SUBMISSION_SUCCESS
  payload: {
    evaluation: Grade
    message: string
  }
}

export interface EvaluateSubmissionFailure {
  type: typeof EVALUATE_SUBMISSION_FAILURE
  payload: {
    error: string
  }
}

export interface AllowResubmissionRequest {
  type: typeof ALLOW_RESUBMISSION_REQUEST
}

export interface AllowResubmissionSuccess {
  type: typeof ALLOW_RESUBMISSION_SUCCESS
}

export interface AllowResubmissionFailure {
  type: typeof ALLOW_RESUBMISSION_FAILURE
  payload: {
    error: string
  }
}

export interface StudentListState {
  open: boolean
  activity: Activity
  activityToGrade: ActivitySubmission
  students: Student[]
  selectedStudent: Student
  loading: boolean
  loadingGrading: boolean
  grade: Grade
  gradeEditable: boolean
  error: string
  message: string
  loadingResubmission: boolean
  errorResubmission: string
  hideGradingCard: boolean
  suspiciousActivityDates: Moment[]
  studentListTotal: number
  studentListPagination: {
    page: number
    rowsPerPage: number
    count: number
  }
}

export interface ChangeEvaluationTypeRequest {
  type: typeof CHANGE_EVALUATION_TYPE_REQUEST
}

export interface ChangeEvaluationTypeSuccess {
  type: typeof CHANGE_EVALUATION_TYPE_SUCCESS
  payload: {
    activity: Activity
  }
}

export interface ChangeEvaluationTypeFailure {
  type: typeof CHANGE_EVALUATION_TYPE_FAILURE
  payload: {
    error: string
  }
}

export interface SetQuestionGrade {
  type: typeof SET_QUESTION_GRADE
  payload: {
    questionId: number
    score: number
    comment: string
  }
}

export interface SortedStudents {
  nextStudents: Student[]
  previousStudents: Student[]
}

export interface AddQuestionNote {
  type: typeof ADD_QUESTION_NOTE
  payload: {
    note: Note
    questionId: number
  }
}

export interface RemoveQuestionNote {
  type: typeof REMOVE_QUESTION_NOTE
  payload: {
    noteId: number
    questionId: number
  }
}

export interface ReplaceQuestionNote {
  type: typeof REPLACE_QUESTION_NOTE
  payload: {
    note: Note
    questionId: number
  }
}

export interface GetStudentSuspiciousActivityRequest {
  type: typeof GET_STUDENT_SUSPICIOUS_ACTIVITY_REQUEST
}
export interface GetStudentSuspiciousActivitySuccess {
  type: typeof GET_STUDENT_SUSPICIOUS_ACTIVITY_SUCCESS
  payload: {
    dates: Moment[]
  }
}
export interface GetStudentSuspiciousActivityFailure {
  type: typeof GET_STUDENT_SUSPICIOUS_ACTIVITY_FAILURE
  payload: {
    error: string
  }
}

type GetStudentSuspiciousActivityAction =
  | GetStudentSuspiciousActivityRequest
  | GetStudentSuspiciousActivitySuccess
  | GetStudentSuspiciousActivityFailure

export type StudentListActions =
  | OpenStudentList
  | CloseStudentList
  | SetSelectedStudent
  | SetGrade
  | SetFeedback
  | SetGradeEditable
  | GetStudentListRequest
  | GetStudentListSuccess
  | GetStudentListFailure
  | EvaluateSubmissionRequest
  | EvaluateSubmissionSuccess
  | EvaluateSubmissionFailure
  | AllowResubmissionRequest
  | AllowResubmissionSuccess
  | AllowResubmissionFailure
  | ChangeEvaluationTypeRequest
  | ChangeEvaluationTypeSuccess
  | ChangeEvaluationTypeFailure
  | SetQuestionGrade
  | AddQuestionNote
  | RemoveQuestionNote
  | ReplaceQuestionNote
  | EvaluateOfflineActivityAction
  | GetStudentSuspiciousActivityAction
