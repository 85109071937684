import { combineReducers } from 'redux'
import drawer from './drawer'
import student from './student'
import classrooms from './classrooms'

export default combineReducers({
  drawer,
  student,
  classrooms,
})
