import type { Moment } from 'moment'
import { API } from '@/api/lms'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { AppThunk } from '@/state/thunk'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import type {
  CreateOfflineActivityRequest,
  CreateOfflineActivitySuccess,
  EvaluateOfflineActivityRequest,
  EvaluateOfflineActivitySuccess,
} from '@/legacy/redux/types/activities'
import {
  CREATE_OFFLINE_ACTIVITY_FAILURE,
  CREATE_OFFLINE_ACTIVITY_REQUEST,
  CREATE_OFFLINE_ACTIVITY_SUCCESS,
  EVALUATE_OFFLINE_ACTIVITY_FAILURE,
  EVALUATE_OFFLINE_ACTIVITY_REQUEST,
  EVALUATE_OFFLINE_ACTIVITY_SUCCESS,
} from '@/legacy/redux/types/activities'
import evaluationActions from '@/sections/teacher/evaluation/actions/evaluation'
import teacherActivitiesActions from '@/sections/teacher/activities/actions/activities'

const ActivitiesActionCreators = {
  createOfflineActivity:
    (
      requestOrigins: ReduxRequestOrigin[],
      classroomID: number,
      organizationId: number,
      subjectId: number,
      subgroupIds: number[],
      date: Moment,
      title: string,
      evaluationType: number
    ): AppThunk =>
    async (dispatch) => {
      const request: CreateOfflineActivityRequest = {
        type: CREATE_OFFLINE_ACTIVITY_REQUEST,
        payload: {
          requestOrigins,
        },
      }
      dispatch(request)

      try {
        await API.Activities.createOffline(
          classroomID,
          organizationId,
          subjectId,
          subgroupIds,
          date,
          title,
          evaluationType
        )

        const success: CreateOfflineActivitySuccess = {
          type: CREATE_OFFLINE_ACTIVITY_SUCCESS,
          payload: {
            requestOrigins,
          },
        }

        dispatch(success)
        dispatch(teacherActivitiesActions.getClassroomActivities(classroomID, date, true))
      } catch (error) {
        handleErrorsWithAction(error, CREATE_OFFLINE_ACTIVITY_FAILURE, dispatch, {
          requestOrigins,
        })
      }
    },
  evaluateOfflineActivity:
    (
      requestOrigins: ReduxRequestOrigin[],
      classroomId: number,
      activityId: number,
      studentId: number,
      value: number,
      feedback: string
    ): AppThunk =>
    async (dispatch) => {
      const request: EvaluateOfflineActivityRequest = {
        type: EVALUATE_OFFLINE_ACTIVITY_REQUEST,
        payload: {
          requestOrigins,
        },
      }
      dispatch(request)

      try {
        await API.Activities.evaluateOffline(classroomId, activityId, studentId, value, feedback)

        const evaluation = { value, feedback, id: 0, date: '' }

        const success: EvaluateOfflineActivitySuccess = {
          type: EVALUATE_OFFLINE_ACTIVITY_SUCCESS,
          payload: {
            requestOrigins,
            evaluation,
          },
        }

        dispatch(success)
        dispatch(evaluationActions.updateStudentEvaluation(studentId, activityId, evaluation))
      } catch (error) {
        handleErrorsWithAction(error, EVALUATE_OFFLINE_ACTIVITY_FAILURE, dispatch, {
          requestOrigins,
        })
      }
    },
}

export default ActivitiesActionCreators
