import React from 'react'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import { Emojione } from 'react-emoji-render'

const useStyles = makeStyles((theme) => ({
  container: {
    width: 'inherit',
    minWidth: 290,
    minHeight: 128,
    padding: '12px 16px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    border: '1px solid #E5E5E5',
    cursor: 'pointer',
    transition: 'background-color 100ms',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
    '&:active': {
      backgroundColor: '#f4f4f4',
    },
  },
  title: {
    fontSize: 18,
    lineHeight: '23px',
    marginBottom: 14,
  },
  subTitle: {
    fontSize: 16,
    marginBottom: 0,
  },
  labelsContainer: {
    marginTop: 20,
    display: 'flex',
    width: '100%',
  },
  label: {
    minHeight: 20,
    minWidth: 100,
    padding: '2px 6px',
    backgroundColor: '#EDEFF8',
    borderRadius: 16,
    display: 'flex',
    justifyContent: 'center',
    '&:not(:last-child)': {
      marginRight: 8,
    },
  },
  peopleText: {
    margin: 0,
    fontWeight: 'bold',
    fontSize: 14,
  },
  emoji: {
    fontSize: 12,
  },
}))

const ClassroomCard = (props) => {
  const classes = useStyles()
  return (
    <Card className={classes.container} variant="outlined" onClick={props.onClick}>
      <Typography className={classes.title} variant="h6" gutterBottom>
        {props.title}
      </Typography>
      <Typography className={classes.subTitle} variant="body2" gutterBottom>
        {props.text}
      </Typography>
      <div className={classes.labelsContainer}>
        <div className={classes.label}>
          <Typography className={classes.peopleText} variant="body2" gutterBottom>
            <Emojione text=" 👦👧 " className={classes.emoji} />
            {props.people}
          </Typography>
        </div>
        <div className={classes.label}>
          <Typography className={classes.peopleText} variant="body2" gutterBottom>
            <Emojione text=" ✍ " className={classes.emoji} />
            {props.activities}
          </Typography>
        </div>
      </div>
    </Card>
  )
}

export default ClassroomCard
