import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import { CONTACTS_TYPES } from '@/sections/teacher/bulletin/types/contacts'
import type {
  MessageManagerActions,
  MessageManagerState,
} from '@/sections/teacher/bulletin/types/messageManager'
import {
  MESSAGE_MANAGER_TYPES,
  MESSAGE_MODE,
} from '@/sections/teacher/bulletin/types/messageManager'
import type { Contact } from '@/api/bulletin'
import { CONTACT_TYPE } from '@/sections/teacher/bulletin/constants/contacts'

const initialState: MessageManagerState = {
  error: null,
  loading: false,
  status: REQUEST_STATUS.IDLE,
  open: false,
  isNested: false,
  currentStep: 0,
  mode: MESSAGE_MODE.CREATE,
  cancelToken: null,
  values: {
    type: -1,
    title: '',
    message: '',
    bulletinIds: [],
    attachments: [],
    allowsReply: true,
    elements: [],
    from: {
      id: 0,
      name: '',
      avatar: '',
      asParent: false,
      children: [],
    },
  },
}

export default function messageManagerReducer(
  state: MessageManagerState = initialState,
  action: MessageManagerActions
): MessageManagerState {
  switch (action.type) {
    case CONTACTS_TYPES.GET_CONTACTS_SUCCESS: {
      const { contacts } = action.payload
      const bulletinIds =
        state.values.bulletinIds.length <= 0 || state.mode === MESSAGE_MODE.CLONE
          ? initiallyCheckedBulletins(contacts)
          : state.values.bulletinIds
      return {
        ...state,
        values: {
          ...state.values,
          bulletinIds,
        },
      }
    }
    case MESSAGE_MANAGER_TYPES.SET_MESSAGES_MANAGER_NESTED: {
      const { isNested } = action.payload
      return {
        ...state,
        isNested,
      }
    }
    case MESSAGE_MANAGER_TYPES.OPEN_MESSAGES_MANAGER: {
      return {
        ...state,
        open: true,
      }
    }
    case MESSAGE_MANAGER_TYPES.CLOSE_MESSAGES_MANAGAR: {
      return {
        ...state,
        open: false,
      }
    }
    case MESSAGE_MANAGER_TYPES.MESSAGE_EDIT: {
      return {
        ...state,
        mode: MESSAGE_MODE.EDIT,
      }
    }
    case MESSAGE_MANAGER_TYPES.MESSAGE_CLONE: {
      return {
        ...state,
        mode: MESSAGE_MODE.CLONE,
      }
    }
    case MESSAGE_MANAGER_TYPES.GO_TO_NEXT_STEP: {
      return {
        ...state,
        currentStep: state.currentStep + 1,
      }
    }
    case MESSAGE_MANAGER_TYPES.GO_TO_PREVIOUS_STEP: {
      return {
        ...state,
        currentStep: state.currentStep - 1,
      }
    }
    case MESSAGE_MANAGER_TYPES.SET_CURRENT_STEP: {
      const { currentStep } = action.payload
      return {
        ...state,
        currentStep,
      }
    }
    case MESSAGE_MANAGER_TYPES.SET_FORM_VALUE: {
      const { prop, value } = action.payload
      return {
        ...state,
        values: {
          ...state.values,
          [prop]: value,
        },
      }
    }
    case MESSAGE_MANAGER_TYPES.SET_FORM_VALUES: {
      const { values } = action.payload
      return {
        ...state,
        values: {
          ...state.values,
          ...values,
        },
      }
    }
    case MESSAGE_MANAGER_TYPES.SET_VALUES: {
      const { values } = action.payload
      return {
        ...state,
        values,
      }
    }
    case MESSAGE_MANAGER_TYPES.MESSAGE_CREATE: {
      const { token } = action.payload
      return {
        ...state,
        loading: true,
        status: REQUEST_STATUS.LOADING,
        cancelToken: token,
      }
    }
    case MESSAGE_MANAGER_TYPES.MESSAGE_CANCEL: {
      return {
        ...state,
        loading: true,
        status: REQUEST_STATUS.CANCELLED,
      }
    }
    case MESSAGE_MANAGER_TYPES.MESSAGE_EDIT_SUCCESS:
    case MESSAGE_MANAGER_TYPES.MESSAGE_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        status: REQUEST_STATUS.SUCCESS,
      }
    }
    case MESSAGE_MANAGER_TYPES.MESSAGE_CREATE_ENDORSEMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        status: REQUEST_STATUS.SUCCESS,
        mode: MESSAGE_MODE.ENDORSEMENT,
      }
    }
    case MESSAGE_MANAGER_TYPES.MESSAGE_EDIT_FAILURE:
    case MESSAGE_MANAGER_TYPES.MESSAGE_CREATE_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case MESSAGE_MANAGER_TYPES.MESSAGE_SET_STATUS: {
      const { status } = action.payload
      return {
        ...state,
        status,
      }
    }
    case MESSAGE_MANAGER_TYPES.CLEAN: {
      return initialState
    }
    default:
      return state
  }
}

/**
 * Checks the first classroom bulletin found.
 *
 * @param contacts
 */
export const initiallyCheckedBulletins = (contacts: Contact[]) => {
  const contact = contacts.find((contact) => contact.type === CONTACT_TYPE.CLASSROOM)
  return contact ? [contact.id] : []
}
