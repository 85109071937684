import { reduxRequestOriginMap } from "@/legacy/redux/constants"
import { LIST_FAILURE, LIST_REQUEST, LIST_SUCCESS, ThemesDrawerAction, ThemesDrawerState } from "../types/themesDrawer"
import { Reducer } from "redux"
import { checkRequestOrigin, setGenericField } from "@/legacy/redux/utils"
import { SET_FIELD } from "@/legacy/redux/types/generic"
import { GET_GAMES_FAILURE, GET_GAMES_REQUEST, GET_GAMES_SUCCESS } from "@/sections/management/types/customizeThemeModal"

const initialState: ThemesDrawerState = {
  loadingThemes: false,
  loadingGames: false,
  themes: [],
  pagination: {
    page: 0,
    rowsPerPage: 12,
    count: 0,
  },
  error: '',
  selected: 0,
  gameDetails: [],
  search: '',
}

const requestDestination = reduxRequestOriginMap.ADMIN_THEMES_DRAWER

const AdminThemesDrawerReducer: Reducer<ThemesDrawerState, ThemesDrawerAction> = (
  state = initialState,
  action
): ThemesDrawerState => {
  switch (action.type) {
    case SET_FIELD:
      return setGenericField(state, action.payload)
    case LIST_REQUEST:
      return {
        ...state,
        loadingThemes: true,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
        search: action.payload.search,
      }
    case LIST_SUCCESS:
      return {
        ...state,
        loadingThemes: false,
        pagination: {
          ...state.pagination,
          count: action.payload.count,
        },
        themes: action.payload.themes,
      }
    case LIST_FAILURE:
      return {
        ...state,
        loadingThemes: false,
        error: action.payload.error,
      }
    case GET_GAMES_REQUEST:
      return {
        ...state,
        loadingGames: true,
      }
    case GET_GAMES_SUCCESS:
      return {
        ...state,
        loadingGames: false,
        gameDetails: action.payload.games,
      }
    case GET_GAMES_FAILURE:
      return {
        ...state,
        loadingGames: false,
        error: action.payload.error,
      }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, AdminThemesDrawerReducer, requestDestination)
