import * as React from 'react'
import type { SVGProps } from 'react'
const SvgGames = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect opacity={0.15} width={56} height={56} rx={6} fill="#01CEAA" />
    <g clipPath="url(#a)">
      <path
        d="M27.505 24.06a1.189 1.189 0 0 1-1.189-1.19v-4.236a6.094 6.094 0 0 1 6.088-6.087h10.424a1.189 1.189 0 1 1 0 2.377H32.403a3.714 3.714 0 0 0-3.71 3.71v4.237c0 .656-.532 1.188-1.188 1.188Z"
        fill="#2A205E"
      />
      <path
        d="M41.624 42.21a6.077 6.077 0 0 0 4.134-7.523l-3.256-11.2a6.1 6.1 0 0 0-5.827-4.378 6.08 6.08 0 0 0-1.697.243 6.016 6.016 0 0 0-3.267 2.332h-8.42a6.016 6.016 0 0 0-3.267-2.332 6.08 6.08 0 0 0-1.697-.243 6.1 6.1 0 0 0-5.826 4.377L9.244 34.687a6.03 6.03 0 0 0 .51 4.618 6.03 6.03 0 0 0 3.624 2.906 6.08 6.08 0 0 0 1.697.242 6.1 6.1 0 0 0 5.827-4.377l.798-2.744h11.603l.797 2.744a6.1 6.1 0 0 0 5.827 4.377c.572 0 1.143-.081 1.697-.242Z"
        fill="#01CEAA"
      />
      <path
        d="M17.2 23.773a4.704 4.704 0 0 0-4.698 4.699 4.704 4.704 0 0 0 4.699 4.698 4.704 4.704 0 0 0 4.698-4.698 4.704 4.704 0 0 0-4.698-4.699ZM37.762 23.773a4.704 4.704 0 0 0-4.699 4.699 4.704 4.704 0 0 0 4.699 4.698 4.704 4.704 0 0 0 4.698-4.698 4.704 4.704 0 0 0-4.698-4.699Z"
        fill="#09AB9B"
      />
      <path
        d="M37.762 25.188a3.289 3.289 0 0 0-3.285 3.285 3.289 3.289 0 0 0 3.285 3.285 3.289 3.289 0 0 0 3.285-3.285 3.289 3.289 0 0 0-3.285-3.285ZM31.233 28.472c0 .656-.532 1.188-1.189 1.188h-5.087a1.189 1.189 0 0 1 0-2.377h5.087c.657 0 1.189.532 1.189 1.189ZM17.2 24.887c-.656 0-1.188.532-1.188 1.188v4.994a1.189 1.189 0 0 0 2.377 0v-4.994c0-.656-.532-1.188-1.188-1.188Z"
        fill="#2A205E"
      />
      <path
        d="M19.698 27.385h-4.994a1.189 1.189 0 1 0 0 2.377h4.994a1.189 1.189 0 0 0 0-2.377Z"
        fill="#2A205E"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(9 9)" d="M0 0h37v37H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgGames
