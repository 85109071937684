import React from 'react'
import { TextareaAutosize } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 4,
    border: '1px solid rgba(0,0,0,0)',
    backgroundColor: '#edeff8!important',
    width: 'calc(100% - 20px)',
    maxWidth: 'calc(100% - 20px)',
    fontSize: 14,
    padding: '10px 10px',
    fontFamily: '"DM Sans"',
    resize: 'vertical',
    '&:hover': {
      backgroundColor: '#dde1ef !important',
    },
    '&:focus': {
      boxShadow: '0px 0px 1px 3px rgba(79, 54, 214, 0.15)',
      border: '1px solid #4f36d6',
      outline: 'none',
      backgroundColor: '#fff',
    },
  },
}))

const TextArea = ({ rowsMin, disabled, onChange, value }) => {
  const classes = useStyles()
  return (
    <TextareaAutosize
      className={classes.root}
      disabled={disabled}
      rowsMin={rowsMin}
      onChange={onChange}
      value={value}
    />
  )
}

export default TextArea
