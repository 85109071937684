import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type * as MigrationClassroomsTypes from './migrationClassrooms'

export interface MigrationTabState {
  activeStep: number
}

export const SET_MIGRATION_TAB_OPEN = 'MIGRATION_TAB/SET_MIGRATION_TAB_OPEN'
export const SET_MIGRATION_TAB_CLOSED = 'MIGRATION_TAB/SET_MIGRATION_TAB_CLOSED'
export const SET_ACTIVE_STEP = 'MIGRATION_TAB/SET_ACTIVE_STEP'

export interface SetActiveStep {
  type: typeof SET_ACTIVE_STEP
  payload: {
    requestOrigin: ReduxRequestOrigin
    activeStep: number
  }
}

export const CLEAR_STATE = 'MIGRATION_TAB/CLEAR_STATE'

export interface ClearState {
  type: typeof CLEAR_STATE
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export type MigrationTabActions =
  | ClearState
  | SetActiveStep
  | MigrationClassroomsTypes.CompleteMigrationRequest
  | MigrationClassroomsTypes.CompleteMigrationSuccess
  | MigrationClassroomsTypes.CompleteMigrationFailure
