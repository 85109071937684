import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type {
  UploadFilesActions,
  UploadFilesState,
} from '@/sections/teacher/bulletin/types/uploadFiles'
import { ATTACH_FILES_TYPES } from '@/sections/teacher/bulletin/types/uploadFiles'

const initialState: UploadFilesState = {
  error: null,
  loading: false,
  status: REQUEST_STATUS.IDLE,
  uploadedFiles: [],
  attachedFiles: [],
  uploadedFilesProgressPercent: 0,
  uploadedFilesProgress: new Map(),
  uploadedFilesProgressTotal: 0,
  uploadedFilesCancelTokens: [],
}

const uploadFilesReducer = (
  state: UploadFilesState = initialState,
  action: UploadFilesActions
): UploadFilesState => {
  switch (action.type) {
    case ATTACH_FILES_TYPES.SET_UPLOAD_FILES_CANCEL_TOKEN: {
      const { token } = action.payload
      const uploadedFilesCancelTokens = [...state.uploadedFilesCancelTokens, token]
      return {
        ...state,
        uploadedFilesCancelTokens,
      }
    }
    case ATTACH_FILES_TYPES.ADD_ATTACH_FILE: {
      const { file } = action.payload
      return {
        ...state,
        attachedFiles: [...state.attachedFiles, file],
      }
    }
    case ATTACH_FILES_TYPES.ADD_ATTACH_FILES: {
      const { files } = action.payload
      return {
        ...state,
        attachedFiles: [...state.attachedFiles, ...files],
      }
    }
    case ATTACH_FILES_TYPES.REMOVE_ATTACH_FILE: {
      const { id } = action.payload
      const attachedFiles = state.attachedFiles.filter((file) => file.id !== id)
      return {
        ...state,
        attachedFiles,
      }
    }
    case ATTACH_FILES_TYPES.UPLOAD_FILES: {
      return {
        ...state,
        loading: true,
        status: REQUEST_STATUS.LOADING,
        uploadedFilesProgressPercent: 0,
        uploadedFilesProgress: new Map(),
        uploadedFilesProgressTotal: 0,
        uploadedFilesCancelTokens: [],
      }
    }
    case ATTACH_FILES_TYPES.UPLOAD_FILES_SUCCESS: {
      const { uploadedFiles } = action.payload
      return {
        ...state,
        loading: false,
        status: REQUEST_STATUS.SUCCESS,
        uploadedFiles,
        uploadedFilesProgressPercent: 100,
      }
    }
    case ATTACH_FILES_TYPES.UPLOAD_FILES_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
        status: REQUEST_STATUS.FAILURE,
        uploadedFilesProgressPercent: 100,
      }
    }
    case ATTACH_FILES_TYPES.UPLOAD_FILES_CANCEL: {
      return {
        ...state,
        loading: false,
        status: REQUEST_STATUS.CANCELLED,
        uploadedFilesProgressPercent: 0,
      }
    }
    case ATTACH_FILES_TYPES.SET_UPLOAD_FILE_PROGRESS_TOTAL: {
      const { total } = action.payload
      const uploadedFilesProgressTotal = state.uploadedFilesProgressTotal + total
      return {
        ...state,
        uploadedFilesProgressTotal,
      }
    }
    case ATTACH_FILES_TYPES.SET_UPLOAD_FILE_PROGRESS: {
      const { id, loaded } = action.payload
      const total = state.uploadedFilesProgressTotal
      const uploadedFilesProgress = new Map(state.uploadedFilesProgress)
      uploadedFilesProgress.set(id, {
        loaded,
      })

      const uploadTotalLoaded = Array.from(uploadedFilesProgress.entries())
        .map(([, file]) => file.loaded)
        .reduce((cv, pv) => cv + pv, 0)

      const uploadedFilesProgressPercent = Math.floor((uploadTotalLoaded / total) * 100)
      // handle axios bad file size calculation
      const handleFileSizePercentDiference =
        uploadedFilesProgressPercent <= 100 ? uploadedFilesProgressPercent : 100
      return {
        ...state,
        uploadedFilesProgress,
        uploadedFilesProgressPercent: handleFileSizePercentDiference,
      }
    }
    case ATTACH_FILES_TYPES.RESET_ATTACH_FILES: {
      return initialState
    }
    default:
      return state
  }
}

export default uploadFilesReducer
