import React from 'react'
import { Box, Modal, Typography } from '@mui/material'
import Button, { Variant } from '@/legacy/components/atomic/button/button'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  onClose: () => void
  onHandleClick: () => void
}

export const TermsAndConditionsExamModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onHandleClick,
}) => {
  const { t } = useTranslation()

  const handleConfirm = () => {
    onClose()
    onHandleClick()
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 500,
          bgcolor: 'background.paper',
          boxShadow: 0,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 2,
          }}
        >
          <Box
            sx={(theme) => ({
              p: 3.5,
              backgroundColor: theme.palette.secondary.main,
              borderTopRightRadius: 4,
              borderTopLeftRadius: 4,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            })}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.common.white,
                fontSize: 18,
                fontWeight: theme.typography.fontWeightMedium,
              })}
              id="modal-modal-title"
            >
              {t('student.writtenExam.termsAndConditionsModal.title')}
            </Typography>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: 3.5,
              p: 3.5,
              maxHeight: 350,
              overflowY: 'auto',
              ...theme.globals.common.scrollbars.main,
            })}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
            >
              {t('student.writtenExam.termsAndConditionsModal.monitoringExam')}
            </Typography>

            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
            >
              {t('student.writtenExam.termsAndConditionsModal.notAllowed')}
            </Typography>

            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
            >
              {t('student.writtenExam.termsAndConditionsModal.cheatAttempt')}
            </Typography>

            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
            >
              {t('student.writtenExam.termsAndConditionsModal.examDuration')}
            </Typography>

            <Typography
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
            >
              {t('student.writtenExam.termsAndConditionsModal.examFinished')}
            </Typography>
          </Box>
          <Box
            sx={{
              p: 3.5,
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
            }}
          >
            <Button variant={Variant.DEFAULT} onClick={handleConfirm}>
              {t('generic.acceptAndContinue')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
