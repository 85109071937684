import { nanoid } from 'nanoid'
import type { ApiAssignmentsList } from '@/api/lms/managementUsers'
import type { AssignmentsList } from '@/legacy/redux/types/assignments'

export function parseApiAssignmentsToLocal(assignments: ApiAssignmentsList[]): AssignmentsList[] {
  return assignments?.length
    ? assignments.map((assgnmnt) => {
        return {
          ...assgnmnt,
          assignmentListId: nanoid(),
          organizationName: assgnmnt.name,
          assignments: assgnmnt.assignments.map((a) => {
            return { ...a, assignmentId: nanoid() }
          }),
        }
      })
    : []
}
