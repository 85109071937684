import type { ManagementSubject } from '@/api/lms/subjects'
import type { GetClassroomSubjectsAsMgmtAction } from '@/legacy/redux/types/classrooms'
import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type {
  ListSubjectsAsAdminAction,
  ListSubjectsAsPrincipalAction,
} from '@/legacy/redux/types/subjects'
import type { GenericActionWithType } from '@/legacy/redux/utils'
import type { Pages } from '@/sections/content-creator/dashboard/types/dashboard'

export type ClassroomsSubjectModalState = {
  open: boolean
  loading: boolean
  error: string
  pagination: Pages
  subjects: ManagementSubject[]
  selectedSubjects: ManagementSubject[]
  search: string
}

export const TOGGLE_SUBJECT = 'MANAGEMENT/CLASSROOM_SUBJECTS_MODAL/TOGGLE_SUBJECT'
export type ToggleSubject = GenericActionWithType<
  typeof TOGGLE_SUBJECT,
  { subject: ManagementSubject }
>

export const SET_OPEN = 'MANAGEMENT/CLASSROOM_SUBJECTS_MODAL/SET_OPEN'
export type SetOpen = GenericActionWithType<
  typeof SET_OPEN,
  { selectedSubjects: ManagementSubject[] }
>

export type ClassroomSubjectModalAction =
  | ListSubjectsAsAdminAction
  | ListSubjectsAsPrincipalAction
  | ToggleSubject
  | SetOpen
  | ClearState
  | SetField<'search', string>
  | GetClassroomSubjectsAsMgmtAction
