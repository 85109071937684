import type { Reducer } from 'redux'
import { Redux } from '@/legacy/redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import * as ReduxTypes from '@/legacy/redux/types/classrooms'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import * as Types from '@/sections/management/types/addEditClassroomModal'
import { getOptions, getSelected, getStageOptions } from '@/sections/management/utils/classrooms'

const initialState: Types.AddEditClassroomModalState = {
  academicPeriod: null,
  academicPeriodsOptions: [],
  classroomDivision: '',
  classroomId: 0,
  divisions: [],
  editMode: false,
  error: '',
  grade: '',
  loading: false,
  loadingSubjects: false,
  open: false,
  organization: {
    error: '',
    id: 0,
    label: '',
    loading: false,
    suggestions: [],
  },
  organizationInput: '', // string showed for organization name (only for admins)
  organizationOptions: [],
  shift: null,
  shiftOptions: [],
  stage: null,
  stageOptions: [],
  subjects: [],
  initialSubjects: [],
  enrollmentCapacity: '',
  k: null,
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_ADD_EDIT_CLASSROOM_MODAL

const managementClassroomModalReducer: Reducer<
  Types.AddEditClassroomModalState,
  Types.AddEditClassroomModalAction
> = (state = initialState, action): Types.AddEditClassroomModalState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case Types.CLEAR_FIELDS:
    case ReduxTypes.CREATE_CLASSROOM_AS_ADMIN_SUCCESS:
    case ReduxTypes.CREATE_CLASSROOM_AS_PRINCIPAL_SUCCESS:
    case ReduxTypes.EDIT_CLASSROOM_AS_PRINCIPAL_SUCCESS:
    case ReduxTypes.EDIT_CLASSROOM_AS_ADMIN_SUCCESS: {
      return {
        ...initialState,
        stageOptions: state.stageOptions,
        shiftOptions: state.shiftOptions,
        academicPeriodsOptions: state.academicPeriodsOptions,
        organization: state.organization,
        organizationOptions: state.organizationOptions,
        initialSubjects: state.initialSubjects,
        stage: state.stageOptions[0],
        shift: state.shiftOptions[0],
        academicPeriod: state.academicPeriodsOptions[0],
      }
    }
    case Types.OPEN_AND_POPULATE_EDIT_MODAL_SUCCESS: {
      const { classroom, organization } = action.payload

      const shiftOptions = getOptions(organization.shifts)
      const academicPeriodsOptions = getOptions(organization.academicPeriods)
      const stageOptions = getStageOptions(organization.stages)
      const selectedStage = getSelected(stageOptions, classroom.stageId)
      const selectedShift = getSelected(shiftOptions, classroom.shiftId)
      const selectedAcademicPeriod = getSelected(academicPeriodsOptions, classroom.academicPeriodId)
      const selectedGrade = getSelected(
        stageOptions.find((s) => s.id === selectedStage?.id)?.masterSeries.steps || [
          { id: 0, label: '-' },
        ],
        classroom.k
      )

      return {
        ...state,
        editMode: true,
        open: true,
        loading: false,
        organization: { ...state.organization, id: organization.id, label: organization.name },
        stageOptions,
        shiftOptions,
        academicPeriodsOptions,
        classroomId: classroom.id,
        grade: classroom.grade,
        stage: selectedStage,
        shift: selectedShift,
        academicPeriod: selectedAcademicPeriod,
        classroomDivision: classroom.division,
        organizationInput: organization.name,
        enrollmentCapacity: classroom.enrollmentCapacity.toString(),
        k: selectedGrade,
      }
    }

    case ReduxTypes.CREATE_CLASSROOM_AS_ADMIN_REQUEST:
    case ReduxTypes.CREATE_CLASSROOM_AS_PRINCIPAL_REQUEST:
    case ReduxTypes.EDIT_CLASSROOM_AS_PRINCIPAL_REQUEST:
    case ReduxTypes.EDIT_CLASSROOM_AS_ADMIN_REQUEST:
    case Types.OPEN_AND_POPULATE_EDIT_MODAL_REQUEST: {
      return {
        ...state,
        open: true,
        loading: true,
      }
    }
    case Types.GET_ORGANIZATION_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case Types.GET_ORGANIZATION_SUCCESS: {
      const { organization } = action.payload

      const shiftOptions = getOptions(organization.shifts)
      const academicPeriodsOptions = getOptions(organization.academicPeriods)
      const stageOptions = getStageOptions(organization.stages)

      return {
        ...state,
        loading: false,
        organization: { ...state.organization, id: organization.id, label: organization.name },
        stage: stageOptions[0],
        stageOptions,
        shift: shiftOptions[0],
        shiftOptions,
        academicPeriod: academicPeriodsOptions[0],
        academicPeriodsOptions,
        k: { id: 0, label: '-' },
      }
    }
    case ReduxTypes.CREATE_CLASSROOM_AS_ADMIN_FAILURE:
    case ReduxTypes.CREATE_CLASSROOM_AS_PRINCIPAL_FAILURE:
    case ReduxTypes.EDIT_CLASSROOM_AS_PRINCIPAL_FAILURE:
    case ReduxTypes.EDIT_CLASSROOM_AS_ADMIN_FAILURE:
    case Types.OPEN_AND_POPULATE_EDIT_MODAL_FAILURE:
    case Types.GET_ORGANIZATION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    }
    case ReduxTypes.GET_CLASSROOM_SUBJECTS_AS_MGMT_REQUEST:
      return { ...state, loadingSubjects: true }
    case ReduxTypes.GET_CLASSROOM_SUBJECTS_AS_MGMT_SUCCESS:
      return {
        ...state,
        loadingSubjects: false,
        subjects: action.payload.subjects,
        initialSubjects: action.payload.subjects,
      }
    case ReduxTypes.GET_CLASSROOM_SUBJECTS_AS_MGMT_FAILURE:
      return { ...state, loadingSubjects: false, error: action.payload.error }

    // TODO propio reducer
    case Types.GET_ORGANIZATION_SUGGESTIONS_REQUEST:
      return {
        ...state,
        organization: {
          ...state.organization,
          loading: true,
        },
      }
    case Types.GET_ORGANIZATION_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        organization: {
          ...state.organization,
          loading: false,
          suggestions: action.payload.suggestions,
        },
      }
    case Types.GET_ORGANIZATION_SUGGESTIONS_FAILURE:
      return {
        ...state,
        organization: {
          ...state.organization,
          loading: false,
          error: action.payload.error,
        },
      }
    case Types.SELECT_ORGANIZATION_SUGGESTION: {
      const isTheSameOrg = state.organization.id === action.payload.suggestion.id

      return {
        ...state,
        organization: {
          ...state.organization,
          label: action.payload.suggestion.label,
          id: action.payload.suggestion.id || 0, // FIX: this happens because id on type Suggestion is optional
          suggestions: [],
        },
        organizationInput: action.payload.suggestion.label,
        subjects: isTheSameOrg ? state.subjects : [],
      }
    }
    case Types.CLEAR_ORGANIZATION_SUGGESTIONS:
      return {
        ...state,
        organization: {
          ...state.organization,
          suggestions: [],
        },
      }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, managementClassroomModalReducer, requestDestination)
