export const CABA_PALETTE = {
  primary: {
    light: '#407CC9',
    main: '#4E99F9',
    dark: '#407CC9',
    contrastText: '#FFF',
  },
  secondary: {
    light: '#4E99F9',
    main: '#407CC9',
    dark: '#4E99F9',
  },
}
