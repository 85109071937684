import LeftArrowIcon from '@/legacy/components/v1/svgs/arrowLeft'
import RightArrowIcon from '@/legacy/components/v1/svgs/arrowRight'
import { Typography, Link, IconButton, Box } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import chroma from 'chroma-js'
import { useTranslation } from 'react-i18next'
import { CalendarIcon, DateCalendar, LocalizationProvider } from '@mui/x-date-pickers'
import useDisclosure from '@/hooks/useDisclosure'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 36,
    position: 'relative',
    width: 'fit-content',
    padding: '5px 0',
    background: '#fff',
    border: '1px solid #D8DBE8',
    borderRadius: 3,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  arrowContainer: ({ disabled }) => ({
    minWidth: 32,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      '&:hover': {
        fill: disabled ? '#000' : theme.palette.secondary.main,
      },
      '&:active': {
        fill: chroma(theme.palette.secondary.main).brighten(0.5).hex(),
      },
    },
  }),
  picker: {
    color: '#1A1A1A',
    textAlign: 'center',
    fontSize: 16,
    lineHeight: '21px',
    padding: '0 8px',
    borderLeft: '1px solid #D8DBE8',
    borderRight: '1px solid #D8DBE8',
  },
}))

const WeekPicker = (props) => {
  const { startDate, endDate, onChange, disabled } = props
  const classes = useStyles({ disabled })
  const { t } = useTranslation()
  const { isOpen, onToggle, onClose } = useDisclosure()
  const theme = useTheme()

  const strings = {
    week: t('generic.week'),
    to: t('moment.toThe'),
  }

  const onBack = () => {
    if (!disabled) props.onBack()
  }

  const onNext = () => {
    if (!disabled) props.onNext()
  }

  const handleChangeDate = (value) => {
    onChange(value)
    onClose()
  }

  return (
    <div className={classes.container}>
      <Link className={`${classes.arrowContainer} ${classes.arrowLeft}`} onClick={onBack}>
        <LeftArrowIcon width={16} height={16} fill="#000" />
      </Link>
      <Typography component="div" className={classes.picker}>
        {strings.week}
        <b>{` ${startDate.format('DD/MM')} ${strings.to} ${endDate.format('DD/MM')}`}</b>
        <IconButton
          onClick={onToggle}
          sx={{ paddingY: 0, paddingX: 2, m: 0, marginLeft: 1, borderRadius: 0 }}
        >
          <CalendarIcon />
        </IconButton>
      </Typography>

      <Link className={`${classes.arrowContainer} ${classes.arrowRight}`} onClick={onNext}>
        <RightArrowIcon width={16} height={16} fill="#000" />
      </Link>

      {isOpen && (
        <Box
          component="section"
          sx={{
            position: 'absolute',
            top: { xs: 70, md: 40 },
            left: 0,
            bottom: 0,
            zIndex: 20,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateCalendar
              value={startDate}
              disabled={disabled}
              onChange={handleChangeDate}
              sx={{
                backgroundColor: theme.palette.background.paper,
                border: theme.globals.common.borders.main,
                borderRadius: 2,
              }}
            />
          </LocalizationProvider>
        </Box>
      )}
    </div>
  )
}

export default WeekPicker
