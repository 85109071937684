import type { Checkmark, Task } from '@/api/ted/types'
import { Facilitator } from '@/api/ted/types'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type { GetInstitutionAction, ListFacilitatorsAction } from './contents'

export type GatheringsState = {
  tasks: Task[]
  checkmarks: Checkmark[]
  loadingTasks: boolean
  loadingCheckmarks: boolean
  creatingCheckmarkForTaskIds: number[]
  error: string
}

export const LIST_TASKS_REQUEST = 'TED/GATHERINGS/LIST_TASKS_REQUEST'
export const LIST_TASKS_SUCCESS = 'TED/GATHERINGS/LIST_TASKS_SUCCESS'
export const LIST_TASKS_FAILURE = 'TED/GATHERINGS/LIST_TASKS_FAILURE'

export const LIST_CHECKMARKS_REQUEST = 'TED/GATHERINGS/LIST_CHECKMARKS_REQUEST'
export const LIST_CHECKMARKS_SUCCESS = 'TED/GATHERINGS/LIST_CHECKMARKS_SUCCESS'
export const LIST_CHECKMARKS_FAILURE = 'TED/GATHERINGS/LIST_CHECKMARKS_FAILURE'

export const CREATE_CHECKMARK_REQUEST = 'TED/GATHERINGS/CREATE_CHECKMARK_REQUEST'
export const CREATE_CHECKMARK_SUCCESS = 'TED/GATHERINGS/CREATE_CHECKMARK_SUCCESS'
export const CREATE_CHECKMARK_FAILURE = 'TED/GATHERINGS/CREATE_CHECKMARK_FAILURE'

export type ListTasksRequest = GenericActionWithType<typeof LIST_TASKS_REQUEST>

export type ListTasksSuccess = GenericActionWithType<typeof LIST_TASKS_SUCCESS, { tasks: Task[] }>

export type ListTasksFailure = GenericActionWithType<typeof LIST_TASKS_FAILURE, ErrorPayload>

export type ListTasksAction = ListTasksRequest | ListTasksSuccess | ListTasksFailure

export type ListCheckmarksRequest = GenericActionWithType<typeof LIST_CHECKMARKS_REQUEST>

export type ListCheckmarksSuccess = GenericActionWithType<
  typeof LIST_CHECKMARKS_SUCCESS,
  { checkmarks: Checkmark[] }
>

export type ListCheckmarksFailure = GenericActionWithType<
  typeof LIST_CHECKMARKS_FAILURE,
  { error: string }
>

export type ListCheckmarksAction =
  | ListCheckmarksRequest
  | ListCheckmarksSuccess
  | ListCheckmarksFailure

export type CreateCheckmarkRequest = GenericActionWithType<
  typeof CREATE_CHECKMARK_REQUEST,
  { checkmarkId: number }
>

export type CreateCheckmarkSuccess = GenericActionWithType<
  typeof CREATE_CHECKMARK_SUCCESS,
  { checkmarkId: number }
>

export type CreateCheckmarkFailure = GenericActionWithType<
  typeof CREATE_CHECKMARK_FAILURE,
  { error: string; checkmarkId: number }
>

export type CreateCheckmarkAction =
  | CreateCheckmarkRequest
  | CreateCheckmarkSuccess
  | CreateCheckmarkFailure

export type GatheringsAction =
  | ListTasksAction
  | ListCheckmarksAction
  | CreateCheckmarkAction
  | GetInstitutionAction
  | ListFacilitatorsAction
