import { clone } from 'ramda'
import { API } from '@/api/lms'
import type { AppThunk } from '@/state/thunk'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import type { ParsedBORParticipant } from '@/sections/conference/components/breakoutRoomsModal'
import type {
  BreakoutRoom,
  BreakoutRoomParticipant,
  BreakoutRoomUpdate,
  GetBreakoutRoomsSuccess,
  ModifyLocalBORCopy,
  SetBORDrawerOpen,
  SetBORModalOpen,
  SetShowOnlyStudents,
  UpdateBORFromSocket,
  UpdateBreakoutRoomsSuccess,
} from '@/sections/conference/types/breakoutRooms'
import {
  DISCARD_LOCAL_BOR_COPY,
  GET_BREAKOUT_ROOMS_FAILURE,
  GET_BREAKOUT_ROOMS_REQUEST,
  GET_BREAKOUT_ROOMS_SUCCESS,
  MODIFY_LOCAL_BOR_COPY,
  SET_BOR_DRAWER_OPEN,
  SET_BOR_MODAL_OPEN,
  SET_SHOW_ONLY_STUDENTS,
  UPDATE_BOR_FROM_SOCKET,
  UPDATE_BREAKOUT_ROOMS_FAILURE,
  UPDATE_BREAKOUT_ROOMS_REQUEST,
  UPDATE_BREAKOUT_ROOMS_SUCCESS,
} from '@/sections/conference/types/breakoutRooms'

export const moveParticipantFromBOR = (
  rooms: BreakoutRoom[],
  participant: BreakoutRoomParticipant,
  oldRoomId: number,
  newRoomId: number
) => {
  const updatedRooms = rooms.map((room) => {
    const clonedRoom = clone(room)

    if (clonedRoom.id === oldRoomId) {
      const filteredParticipants = clonedRoom.participants.filter((p) => p.id !== participant.id)
      return {
        ...clonedRoom,
        participants: filteredParticipants,
      }
    }

    if (clonedRoom.id === newRoomId) {
      clonedRoom.participants = [...clonedRoom.participants, participant]
    }

    return clonedRoom
  })

  return updatedRooms
}

const actions = {
  getBreakoutRooms:
    (activityId): AppThunk =>
    async (dispatch, getState) => {
      dispatch({ type: GET_BREAKOUT_ROOMS_REQUEST })

      const userId = getState().user.user.id

      try {
        const response = await API.AVV.getBreakoutRooms(activityId)

        const { config } = response.data.description

        dispatch(actions.getBreakoutRoomsSuccess(userId, config))
      } catch (error) {
        handleErrorsWithAction(error, GET_BREAKOUT_ROOMS_FAILURE, dispatch)
      }
    },
  getBreakoutRoomsSuccess: (
    userId: number,
    breakoutRooms: BreakoutRoom[]
  ): GetBreakoutRoomsSuccess => ({
    type: GET_BREAKOUT_ROOMS_SUCCESS,
    payload: {
      userId,
      breakoutRooms,
    },
  }),
  updateBreakoutRooms:
    (activityId: number, newRooms: BreakoutRoom[]): AppThunk =>
    async (dispatch) => {
      dispatch({ type: UPDATE_BREAKOUT_ROOMS_REQUEST })

      const parsedNewRooms: BreakoutRoomUpdate[] = newRooms.map((newRoom) => ({
        ...newRoom,
        participants: newRoom.participants.map((p) => p.id),
      }))

      try {
        await API.AVV.updateBreakoutRooms(activityId, parsedNewRooms)

        dispatch(actions.updateBreakoutRoomsSuccess())
      } catch (error) {
        handleErrorsWithAction(error, UPDATE_BREAKOUT_ROOMS_FAILURE, dispatch)
      }
    },
  updateBreakoutRoomsSuccess: (): UpdateBreakoutRoomsSuccess => ({
    type: UPDATE_BREAKOUT_ROOMS_SUCCESS,
  }),
  modifyLocalBORCopy: (
    participant: ParsedBORParticipant,
    newRoomId: number
  ): ModifyLocalBORCopy => ({ type: MODIFY_LOCAL_BOR_COPY, payload: { participant, newRoomId } }),
  discardLocalBORCopy: () => ({ type: DISCARD_LOCAL_BOR_COPY }),
  setBORModalOpen: (modalOpen: boolean): SetBORModalOpen => ({
    type: SET_BOR_MODAL_OPEN,
    payload: { modalOpen },
  }),
  setBORDrawerOpen: (drawerOpen: boolean): SetBORDrawerOpen => ({
    type: SET_BOR_DRAWER_OPEN,
    payload: { drawerOpen },
  }),
  updateBORFromSocket: (userId: number, breakoutRooms: BreakoutRoom[]): UpdateBORFromSocket => ({
    type: UPDATE_BOR_FROM_SOCKET,
    payload: { breakoutRooms, userId },
  }),
  setShowOnlyStudents: (showOnlyStudents: boolean): SetShowOnlyStudents => ({
    type: SET_SHOW_ONLY_STUDENTS,
    payload: { showOnlyStudents },
  }),
}

export default actions
