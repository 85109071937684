import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type * as booksTypes from '@/sections/content-creator/books/types/books'
import type { ActivityType } from '@/sections/content-creator/premium-activities/types/premium-activities'

export const LIST_SEQUENCES_REQUEST = 'CONTENT_CREATOR/SEQUENCES/LIST_SEQUENCES_REQUEST'
export const LIST_SEQUENCES_SUCCESS = 'CONTENT_CREATOR/SEQUENCES/LIST_SEQUENCES_SUCCESS'
export const LIST_SEQUENCES_FAILURE = 'CONTENT_CREATOR/SEQUENCES/LIST_SEQUENCES_FAILURE'

export type SequenceActivity = {
  id: number
  activityId: number
  title: string
  type: ActivityType
}

export type SequenceBook = {
  title: string
  id: number
}

export type Sequence = {
  id: number
  createdAt: string
  organizationId: number
  organizationName: string
  bookId: number
  bookTitle: string
  title: string
  availability: booksTypes.Availability
  description: string
  activities: SequenceActivity[]
  activityCount: number
  amount?: number
  availableInLibrary: boolean
}

export interface ListSequencesRequest {
  type: typeof LIST_SEQUENCES_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
    page: number
    rowsPerPage: number
  }
}

export interface ListSequencesSuccess {
  type: typeof LIST_SEQUENCES_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    sequences: Sequence[]
    count: number
  }
}

export interface ListSequencesFailure {
  type: typeof LIST_SEQUENCES_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface SequencesState {
  open: boolean
  loading: boolean
  error: string
  deleteMode: boolean
  selectedSequenceId: number
}

export type SequencesAction = ListSequencesRequest | ListSequencesSuccess | ListSequencesFailure
