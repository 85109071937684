import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type { ErrorPayload } from '@/legacy/redux/utils'
import type { Activity } from '@/sections/teacher/overview/types/overview'

export const SET_TAGS = 'EDUCATIONAL_INFO/SET_TAGS'

export const GET_EDUCATIONAL_TAG_CATEGORIES_REQUEST =
  'EDUCATIONAL_INFO/GET_EDUCATIONAL_TAG_CATEGORIES_REQUEST'
export const GET_EDUCATIONAL_TAG_CATEGORIES_SUCCESS =
  'EDUCATIONAL_INFO/GET_EDUCATIONAL_TAG_CATEGORIES_SUCCESS'
export const GET_EDUCATIONAL_TAG_CATEGORIES_FAILURE =
  'EDUCATIONAL_INFO/GET_EDUCATIONAL_TAG_CATEGORIES_FAILURE'

export const GET_ACTIVITY_SUCCESS = 'TEACHER_ACTIVITY/GET_ACTIVITY_SUCCESS' // TODO: REPLACE THIS ONCE CREATE FLOW IS MOVED TO TYPESCRIPT

export type EducationalTag = {
  id: number
  name: string
  tagValue: number
}

export type CreateEducationalTagMap = {
  id: number
  tagValue: number
}

export type EducationalTagsMap = {
  [category: string]: EducationalTag[]
}

export interface EducationalInfoStepState {
  description: string
  descriptionIsRichText: boolean
  tagMap: EducationalTagsMap
  loading: boolean
  error: string
}

export interface SetTags {
  type: typeof SET_TAGS
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    category: string
    tags: EducationalTag[]
  }
}

export interface GetEducationalTagCategoriesRequest {
  type: typeof GET_EDUCATIONAL_TAG_CATEGORIES_REQUEST
  payload: {
    requestOrigins: ReduxRequestOrigin[]
  }
}

export interface GetEducationalTagCategoriesSuccess {
  type: typeof GET_EDUCATIONAL_TAG_CATEGORIES_SUCCESS
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    categories: string[]
  }
}

export interface GetEducationalTagCategoriesFailure {
  type: typeof GET_EDUCATIONAL_TAG_CATEGORIES_FAILURE
  payload: ErrorPayload
}

export interface GetActivitySuccess {
  type: typeof GET_ACTIVITY_SUCCESS // TODO: REPLACE THIS ONCE CREATE FLOW IS MOVED TO TYPESCRIPT
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    activity: Activity
  }
}

export type EducationalInfoStepAction =
  | SetTags
  | GetEducationalTagCategoriesRequest
  | GetEducationalTagCategoriesSuccess
  | GetEducationalTagCategoriesFailure
  | SetField<'description', string>
  | ClearState
  | GetActivitySuccess
