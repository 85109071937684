import React from 'react'
import { Box, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { H3Title, Subtitle } from '@/legacy/components/v1'
import Ribbon from '@/sections/student/dashboard/containers/ribbon'
import RowHeader from './header'
import SubjectCard from './subjectCard'
import ButtonTabs from '@/legacy/components/v2/buttonTabs'
import { getToDoList } from '@/sections/student/dashboard/utils'
import { LIMIT } from '@/sections/student/dashboard/constants'
import { Anibot } from '@/legacy/components/v2/anibot'
import EmptyState from '@/legacy/components/v2/emptyState'
import DisplayToggleGroup from '@/legacy/components/v3/displayToggleGroup'
import { ACTIVITY_DISPLAY_TYPE_ROW } from '@/sections/display/types'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  activity: {
    padding: '4px 0',
  },
  content: {
    padding: '26px 24px',
    maxWidth: 975,
    margin: 'auto',
  },
  activityTabs: {
    margin: '32px 0 24px 0',
  },
  loadingContainer: {
    width: '100%',
    height: 'calc(100% - 72px)', // adjustment due to header
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    background: theme.palette.background.default,
  },
  loadingActivitiesContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 4,
  },
  subjectCard: ({ color }) => ({
    backgroundColor: color,
    border: '1px solid #DFDFDA',
    boxSizing: 'border-box',
    position: 'relative',
    borderRadius: 8,
    paddingTop: 24,
    paddingLeft: 0,
    paddingBottom: 0,
    paddingRight: 0,
  }),
  subjectName: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '31px',
    letterSpacing: '0.01em',
    color: 'white',
    marginLeft: 24,
    height: 100,
  },
  subjectCardBottom: {
    backgroundColor: '#00000021', // darken
    height: '48px',
    color: 'white',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 12,
    },
  },
  tab: {
    textTransform: 'none',
    fontSize: 16,
  },
  tabIndicator: {
    background: 'white',
  },
  historyCircularProgress: {
    marginTop: '18px',
  },
}))

export const TABS = {
  TO_DO: 'to_do',
  NOT_DONE: 'not_done',
  HISTORY: 'history',
}

const Subject = React.memo((props) => {
  const { subject, organizationID, academicPeriodID, loading, loadingActivities, tab } = props
  const classes = useStyles({ color: subject.color })
  const { todayList, thisWeekList, futureList, notDoneList, historyList } = props
  const { activityDisplayType, setActivityDisplayType } = props
  const { getActivitiesToComplete, getActivitiesNotDone, getActivitiesHistory } = props
  const { t } = useTranslation()
  const toDoList = getToDoList({ todayList, thisWeekList, futureList })

  const tabToDo = tab === TABS.TO_DO
  const tabNotDone = tab === TABS.NOT_DONE
  const tabHistory = tab === TABS.HISTORY

  if (loading)
    return (
      <div className={classes.loadingContainer}>
        <Anibot />
      </div>
    )

  const commonActionOptions = { reset: true, shouldOverrideActivities: true }

  const header = (
    <Header
      today={todayList.length + thisWeekList.length + futureList.length}
      notDone={notDoneList.length}
      onClickLeft={() =>
        getActivitiesToComplete(organizationID, academicPeriodID, subject.id, 0, LIMIT, {
          ...commonActionOptions,
          silentLoading: toDoList.length > 0,
        })
      }
      onClickRight={() =>
        getActivitiesNotDone(organizationID, academicPeriodID, subject.id, 0, LIMIT, {
          ...commonActionOptions,
          silentLoading: notDoneList.length > 0,
        })
      }
      value={tabToDo ? 0 : 1}
    />
  )

  const showEmptyState =
    (tabToDo && todayList.length === 0 && thisWeekList.length === 0 && futureList.length === 0) ||
    (tabNotDone && notDoneList.length === 0) ||
    (tabHistory && historyList.length === 0)

  return (
    <div>
      <div className={classes.content}>
        {subject && (
          <SubjectCard
            subject={subject}
            classes={classes}
            value={tabHistory ? 1 : 0}
            color={subject.color}
            onClickLeft={() =>
              getActivitiesToComplete(organizationID, academicPeriodID, subject.id, 0, LIMIT, {
                ...commonActionOptions,
                silentLoading: toDoList.length > 0,
              })
            }
            onClickRight={() =>
              getActivitiesHistory(organizationID, academicPeriodID, subject.id, 0, LIMIT, {
                ...commonActionOptions,
                silentLoading: historyList.length > 0,
              })
            }
          />
        )}

        {tabToDo || tabNotDone ? header : null}

        {tabToDo && (
          <div>
            {todayList.length > 0 && (
              <TasksSection
                title={t('student.subject.today')}
                tasks={todayList}
                activityDisplayType={activityDisplayType}
                setActivityDisplayType={setActivityDisplayType}
              />
            )}
            {thisWeekList.length > 0 && (
              <TasksSection
                title={t('student.subject.thisWeek')}
                hideToggle={todayList.length > 0}
                tasks={thisWeekList}
                activityDisplayType={activityDisplayType}
                setActivityDisplayType={setActivityDisplayType}
              />
            )}
            {futureList.length > 0 && (
              <TasksSection
                title={t('student.subject.later')}
                hideToggle={todayList.length > 0 || thisWeekList > 0}
                tasks={futureList}
                activityDisplayType={activityDisplayType}
                setActivityDisplayType={setActivityDisplayType}
              />
            )}
          </div>
        )}

        {tabNotDone && (
          <div>
            {notDoneList.length > 0 && (
              <TasksSection
                title={t('student.subject.delayedActivities')}
                tasks={notDoneList}
                activityDisplayType={activityDisplayType}
                setActivityDisplayType={setActivityDisplayType}
              />
            )}
          </div>
        )}

        {tabHistory && (
          <div>
            {historyList.length > 0 && (
              <TasksSection
                title={t('student.subject.myHistory')}
                tasks={historyList}
                activityDisplayType={activityDisplayType}
                setActivityDisplayType={setActivityDisplayType}
              />
            )}
          </div>
        )}

        {loadingActivities && (
          <div className={classes.loadingActivitiesContainer}>
            <Anibot />
          </div>
        )}

        {showEmptyState && !loadingActivities && (
          <EmptyState
            variant={1}
            paddingTop={1}
            title={t('student.congratulations')}
            subtitle={t('student.noPlanner')}
          />
        )}
      </div>
    </div>
  )
})

export default Subject

const useHeaderStyles = makeStyles((theme) => ({
  bar: {
    padding: '32px 4px 16px',
  },
  title: {
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      marginBottom: 10,
    },
  },
  activityButtons: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}))

const Header = ({ today, notDone, onClickLeft, onClickRight, value }) => {
  const classes = useHeaderStyles()
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginTop: 4,
        width: '100%',
      }}
    >
      <H3Title component="span" className={classes.title}>
        {t('student.questionComplete')}
      </H3Title>
      <ButtonTabs
        value={value}
        leftLabel={t('student.onDate')}
        rightLabel={t('student.delayed')}
        leftQuantity={today}
        rightQuantity={notDone}
        onClickLeft={onClickLeft}
        onClickRight={onClickRight}
      />
    </Box>
  )
}

const useTaskSectionStyles = makeStyles((theme) => ({
  container: {
    marginTop: '18px',
  },
  subtitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  subtitle: {
    fontSize: 16,
    display: 'inline-flex',
  },
  viewPicker: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.35em',
    flex: 1,
    justifyContent: 'flex-end',
  },
}))

const TasksSection = ({
  title,
  tasks,
  hideToggle,
  activityDisplayType,
  setActivityDisplayType,
}) => {
  const classes = useTaskSectionStyles()
  return (
    <div className={classes.container}>
      <Grid className={classes.gridContainer} container>
        <Grid item className={classes.subtitleContainer} id="next-activities">
          <Subtitle className={classes.subtitle}>{title}</Subtitle>
        </Grid>
        {!hideToggle && (
          <Grid className={classes.viewPicker} item>
            <DisplayToggleGroup
              activityDisplayType={activityDisplayType}
              handleDisplayTypeClick={setActivityDisplayType}
            />
          </Grid>
        )}
      </Grid>
      {activityDisplayType === ACTIVITY_DISPLAY_TYPE_ROW && tasks.length > 0 && <RowHeader />}
      {tasks && tasks.map((a) => <Ribbon activity={a} key={a.id} />)}
    </div>
  )
}
