import moment from 'moment'
import type { Reducer } from 'redux'
import { ManagementGender } from '@/api/lms/managementUsers'
import type { Option } from '@/legacy/components/v3/selects/selectSimple'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { DEFAULT_DOC_TYPE } from '@/legacy/redux/types/docTypesOptions'
import {
  CREATE_USER_AS_MANAGEMENT_FAILURE,
  CREATE_USER_AS_MANAGEMENT_REQUEST,
  CREATE_USER_AS_MANAGEMENT_SUCCESS,
  EDIT_USER_DATA_FAILURE,
  EDIT_USER_DATA_REQUEST,
  EDIT_USER_DATA_SUCCESS,
} from '@/legacy/redux/types/managementUsers'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import { getManagementGender } from '@/legacy/utils/getManagementGender'
import * as Types from '@/sections/management/types/users/addEditUserModal'
import { parseApiAssignmentsToLocal } from '@/sections/management/utils/users/parseAssignments'
import { REQUEST_STATUS } from '@/legacy/redux/types/status'

const genderOptions: Option[] = [
  { id: 1, label: ManagementGender.FEMALE },
  { id: 2, label: ManagementGender.MALE },
]

const initialState: Types.AddEditUserModalState = {
  open: false,
  loading: false,
  error: '',
  editMode: false,
  userId: 0,
  name: '',
  lastName: '',
  avatar: '',
  ppi: false,
  document: {
    ...DEFAULT_DOC_TYPE,
    value: '',
    instanceId: 0,
  },
  email: {
    id: 0,
    typeId: 0,
    value: '',
  },
  birthday: moment('2000-01-01'),
  phone: '',
  genderOptions,
  selectedGender: { id: 0, label: ManagementGender.OTHER },
  password: '',
  docTypesOptions: [DEFAULT_DOC_TYPE],
  selectedDocType: DEFAULT_DOC_TYPE,
  assignmentsList: [],
  userChildren: [],
  relationshipAssignments: [],
  restrictions: {
    list: [],
    selected: [],
    status: REQUEST_STATUS.IDLE,
    error: '',
  },
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_ADD_EDIT_USER_MODAL

const AddEditUserModalReducer: Reducer<
  Types.AddEditUserModalState,
  Types.AddEditUserModalAction
> = (state = initialState, action): Types.AddEditUserModalState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case Redux.Types.Generic.CLEAR_STATE:
      return { ...initialState, docTypesOptions: state.docTypesOptions }
    case Types.OPEN_MODAL_REQUEST:
      return { ...state, open: true, loading: true }
    case Types.OPEN_MODAL_SUCCESS:
      return { ...state, loading: false, docTypesOptions: action.payload.docTypesOptions }
    case Types.OPEN_MODAL_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case Types.SET_EMAIL:
      return { ...state, email: action.payload.email }
    case Types.SET_DATE:
      return { ...state, birthday: action.payload.date }
    case Types.SET_PPI:
      return { ...state, ppi: !state.ppi }
    case Types.SET_ASSIGNMENTS: {
      const isOrgAlreadyPresent = state.assignmentsList.some((al) => {
        return al.organizationId === action.payload.assignments.organizationId
      })

      if (isOrgAlreadyPresent) {
        // CASE: not the first assignment of this organization
        return {
          ...state,
          assignmentsList: state.assignmentsList.map((al) => {
            if (al.organizationId === action.payload.assignments.organizationId) {
              // CASE: this is the already present organization (the one that the new assignment belongs to)
              const assignments = al.assignments.concat(action.payload.assignments.assignments)
              return {
                ...al,
                assignments,
                roles: Array.from(new Set(assignments.map(({ role }) => role))),
              }
            } else {
              return al
            }
          }),
        }
      } else {
        // CASE: first assignment of this organization
        return {
          ...state,
          assignmentsList: state.assignmentsList.concat([action.payload.assignments]),
        }
      }
    }
    case Types.DELETE_ASSIGNMENT_ROW:
      return {
        ...state,
        assignmentsList: state.assignmentsList.map((a) => {
          return {
            ...a,
            assignments: a.assignments.filter((d) => {
              return d.assignmentId !== action.payload.assignmentId
            }),
          }
        }),
      }
    case Types.DELETE_ASSIGNMENT_LIST:
      return {
        ...state,
        assignmentsList: state.assignmentsList.filter((al) => {
          return al.assignmentListId !== action.payload.assignmentListId
        }),
      }
    case Types.SET_CHILDREN_ASSIGNMENTS:
      return {
        ...state,
        userChildren: action.payload.children,
      }
    case Types.DELETE_CHILDREN_ASSIGNMENT: {
      const { child } = action.payload
      return {
        ...state,
        userChildren: state.userChildren.filter((ca) => {
          return ca.id !== child.id
        }),
      }
    }
    case Types.DELETE_ALL_CHILDREN_ASSIGNMENTS: {
      return {
        ...state,
        userChildren: [],
      }
    }
    case CREATE_USER_AS_MANAGEMENT_REQUEST:
      return { ...state, loading: true }
    case CREATE_USER_AS_MANAGEMENT_SUCCESS:
      return { ...initialState, docTypesOptions: state.docTypesOptions }
    case CREATE_USER_AS_MANAGEMENT_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case Types.OPEN_EDIT_MODAL_REQUEST:
      return { ...state, open: true, loading: true, editMode: true }
    case Types.CLOSE_EDIT_MODAL:
      return { ...state, open: false, loading: false, editMode: false }
    case Types.OPEN_EDIT_MODAL_SUCCESS: {
      const {
        id,
        name,
        avatar,
        ppi,
        lastName,
        emailIdentification,
        identification,
        phone,
        gender,
        assignments,
        dateOfBirth,
        relationshipAssignments,
        restrictions = [],
      } = action.payload.user

      const newAssignments = parseApiAssignmentsToLocal(assignments)

      const selectedDocType = action.payload.docTypesOptions.find(
        ({ id }) => id === identification?.typeId
      )

      return {
        ...state,
        loading: false,
        name,
        lastName,
        avatar,
        ppi,
        userId: id,
        relationshipAssignments,
        document:
          identification && selectedDocType
            ? {
                country: selectedDocType?.country,
                hidden: selectedDocType?.hidden,
                id: selectedDocType?.id,
                inputType: selectedDocType?.inputType,
                max: selectedDocType?.max,
                min: selectedDocType?.min,
                name: selectedDocType?.name,
                icon: selectedDocType?.icon,
                value: identification.value,
                instanceId: identification.id,
              }
            : { ...DEFAULT_DOC_TYPE, value: '', instanceId: 0 },
        email: emailIdentification,
        birthday: dateOfBirth ? moment(dateOfBirth) : moment(),
        phone,
        selectedGender:
          genderOptions.find((o) => {
            return o.label === getManagementGender(gender)
          }) || initialState.selectedGender,
        password: '',
        selectedDocType:
          state.docTypesOptions.find((dto) => {
            return dto.id === identification?.typeId
          }) || DEFAULT_DOC_TYPE,
        assignmentsList: newAssignments,
        docTypesOptions: action.payload.docTypesOptions,
        restrictions: { ...state.restrictions, selected: [...restrictions] },
      }
    }
    case Types.TOGGLE_SELECTED_RESTRICTION: {
      const { restriction } = action.payload

      const alreadyAdded = state.restrictions.selected.some((r) => r === restriction)
      if (alreadyAdded) {
        return {
          ...state,
          restrictions: {
            ...state.restrictions,
            selected: state.restrictions.selected.filter((r) => r !== restriction),
          },
        }
      }
      return {
        ...state,
        restrictions: {
          ...state.restrictions,
          selected: [...state.restrictions.selected, restriction],
        },
      }
    }
    case Types.OPEN_EDIT_MODAL_FAILURE:
      return { ...state, loading: false, error: action.payload.error }

    case EDIT_USER_DATA_REQUEST:
      return { ...state, loading: true }
    case EDIT_USER_DATA_SUCCESS:
      return { ...initialState, docTypesOptions: state.docTypesOptions }
    case EDIT_USER_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case Types.GET_STUDENT_RESTRICTIONS_REQUEST: {
      return { ...state, restrictions: { ...state.restrictions, status: REQUEST_STATUS.LOADING } }
    }
    case Types.GET_STUDENT_RESTRICTIONS_SUCCESS: {
      return {
        ...state,
        restrictions: {
          ...state.restrictions,
          status: REQUEST_STATUS.SUCCESS,
          list: action.payload.restrictions,
        },
      }
    }
    case Types.GET_STUDENT_RESTRICTIONS_FAILURE: {
      return {
        ...state,
        restrictions: {
          ...state.restrictions,
          status: REQUEST_STATUS.FAILURE,
          error: action.payload.error,
        },
      }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, AddEditUserModalReducer, requestDestination)
