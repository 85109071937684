import types from '@/sections/header/types/messages'

const defaultOptions = {
  error: false,
  color: null,
  icon: null,
  withCloseButton: false,
  closeButtonFn: () => {},
  buttons: [],
  autoHideDuration: 3000,
  titleValues: {},
  descriptionValues: {},
}

const messageActionCreators = {
  showToast: (title, description, options) => {
    const {
      error,
      color,
      icon,
      withCloseButton,
      closeButtonFn,
      buttons,
      autoHideDuration,
      titleValues,
      descriptionValues,
    } = { ...defaultOptions, ...options }
    return {
      type: types.SHOW_TOAST,
      payload: {
        title,
        description,
        error,
        color,
        icon,
        withCloseButton,
        closeButtonFn,
        buttons,
        autoHideDuration,
        titleValues,
        descriptionValues,
      },
    }
  },

  close: () => ({ type: types.CLOSE }),
}

export default messageActionCreators
