import type { Moment } from 'moment'
import type { ActivityTypes } from '@/api/lms/activities/activities'
import type { EducationalTagsMap } from '@/sections/teacher/activities/types/createExtraInfo/educationalInfo/educationalInfoStep'
import type { TABS } from '@/sections/teacher/utils/activities'

export const GET_ACTIVITIES_REQUEST = 'CLASSROOM_OVERVIEW/GET_ACTIVITIES_REQUEST'
export const GET_ACTIVITIES_SUCCESS = 'CLASSROOM_OVERVIEW/GET_ACTIVITIES_SUCCESS'
export const GET_ACTIVITIES_FAILURE = 'CLASSROOM_OVERVIEW/GET_ACTIVITIES_FAILURE'
export const SET_NEXT_LIVE_CLOSE = 'CLASSROOM_OVERVIEW/SET_NEXT_LIVE_CLOSE'
export const SET_NEXT_LIVE_OPEN = 'CLASSROOM_OVERVIEW/SET_NEXT_LIVE_OPEN'
export const RESET_CLASSROOM = 'CLASSROOM_OVERVIEW/RESET_CLASSROOM'

export const GET_COMPLETION_SUMMARY_REQUEST = 'CLASSROOM_OVERVIEW/GET_COMPLETION_SUMMARY_REQUEST'
export const GET_COMPLETION_SUMMARY_SUCCESS = 'CLASSROOM_OVERVIEW/GET_COMPLETION_SUMMARY_SUCCESS'
export const GET_COMPLETION_SUMMARY_FAILURE = 'CLASSROOM_OVERVIEW/GET_COMPLETION_SUMMARY_FAILURE'

export const GET_ACTIVITIES_SUMMARY_REQUEST = 'CLASSROOM_OVERVIEW/GET_ACTIVITIES_SUMMARY_REQUEST'
export const GET_ACTIVITIES_SUMMARY_SUCCESS = 'CLASSROOM_OVERVIEW/GET_ACTIVITIES_SUMMARY_SUCCESS'
export const GET_ACTIVITIES_SUMMARY_FAILURE = 'CLASSROOM_OVERVIEW/GET_ACTIVITIES_SUMMARY_FAILURE'

export const GET_ATTENDANCE_SUMMARY_REQUEST = 'CLASSROOM_OVERVIEW/GET_ATTENDANCE_SUMMARY_REQUEST'
export const GET_ATTENDANCE_SUMMARY_SUCCESS = 'CLASSROOM_OVERVIEW/GET_ATTENDANCE_SUMMARY_SUCCESS'
export const GET_ATTENDANCE_SUMMARY_FAILURE = 'CLASSROOM_OVERVIEW/GET_ATTENDANCE_SUMMARY_FAILURE'

export const GET_EVALUATIONS_SUMMARY_REQUEST = 'CLASSROOM_OVERVIEW/GET_EVALUATIONS_SUMMARY_REQUEST'
export const GET_EVALUATIONS_SUMMARY_SUCCESS = 'CLASSROOM_OVERVIEW/GET_EVALUATIONS_SUMMARY_SUCCESS'
export const GET_EVALUATIONS_SUMMARY_FAILURE = 'CLASSROOM_OVERVIEW/GET_EVALUATIONS_SUMMARY_FAILURE'

export const GET_ACTIVITY_FOR_STUDENT_LIST_REQUEST =
  'CLASSROOM_OVERVIEW/GET_ACTIVITY_FOR_STUDENT_LIST_REQUEST'
export const GET_ACTIVITY_FOR_STUDENT_LIST_SUCCESS =
  'CLASSROOM_OVERVIEW/GET_ACTIVITY_FOR_STUDENT_LIST_SUCCESS'
export const GET_ACTIVITY_FOR_STUDENT_LIST_FAILURE =
  'CLASSROOM_OVERVIEW/GET_ACTIVITY_FOR_STUDENT_LIST_FAILURE'

export const GET_UNCORRECTED_ACTIVITIES_REQUEST =
  'CLASSROOM_OVERVIEW/GET_UNCORRECTED_ACTIVITIES_REQUEST'
export const GET_UNCORRECTED_ACTIVITIES_SUCCESS =
  'CLASSROOM_OVERVIEW/GET_UNCORRECTED_ACTIVITIES_SUCCESS'
export const GET_UNCORRECTED_ACTIVITIES_FAILURE =
  'CLASSROOM_OVERVIEW/GET_UNCORRECTED_ACTIVITIES_FAILURE'

export type OverviewTabKeys = keyof typeof TABS
export type OverviewTab = (typeof TABS)[OverviewTabKeys]

export interface Organization {
  id: number
  name: string
}

export interface RequestOptions {
  silentLoading?: boolean
  reset?: boolean
  doNotSetPagination?: boolean
  shouldOverrideActivities?: boolean
}

export interface Pagination {
  offset: number
  limit: number
}

export type Pages = {
  page: number
  rowsPerPage: number
  count: number
}

export interface Classroom {
  division: string
  grade: string
  id: number
  organization: Organization
  organizationType: string
  shift: string
  year: number
}

export interface Subject {
  color: string
  id: number
  name: string
}

export interface Activity {
  chatEnabled: boolean
  classroom: Classroom
  completedCurrent: number
  createdAt: string
  date: string
  deadline: string
  endDate: string
  evaluationType: number
  gradedCurrent: number
  id: number
  publicationDate: string
  startDate: string
  studentsTotal: number
  subject: Subject
  title: string
  type: string
  videocallUrl: string
  educationalInfo: {
    description: string
    descriptionIsRichText: boolean
    tags: EducationalTagsMap
  }
  collabMiniatureInfo?: {
    guided: boolean
    started: boolean
    scenarios: {
      id: number
      name: string
      reservationId: number
    }[]
  }
}
export interface UncorrectedActivity {
  id: number
  title: string
  submissionId: number
  type: ActivityTypes
  studentId: number
  studentName: string
  studentAvatar: string
}

export interface CompletionSummary {
  delayedRatio: number
  reallyDelayedRatio: number
  upToDateRatio: number
  total: number
}

export interface ActivitiesSummary {
  avv: number
  exes: number
  exor: number
  questions: number
  reading: number
  robotsRemote: number
  total: number
  cacheLastUpdated: Moment
}

export interface AttendanceSummary {
  calendar: number[]
  growth: number
  startDate: Moment
  cacheLastUpdated: Moment
}

export interface Evaluation {
  activityId: number
  evaluated: number
  name: string
  submitted: number
}

export interface OverviewState {
  future: Activity[]
  today: Activity[]
  passed: Activity[]
  total: number
  offset: number
  loadingActivities: boolean
  tab: OverviewTab
  error: string
  limit: number
  completionSummary: CompletionSummary
  activitiesSummary: ActivitiesSummary
  attendanceSummary: AttendanceSummary
  evaluationsSummary: Evaluation[]
  attendanceLoading: boolean
  completionLoading: boolean
  activitiesSummaryLoading: boolean
  evaluationsLoading: boolean
  uncorrectedActivitiesLoading: boolean
  uncorrectedActivities: UncorrectedActivity[]
  uncorrectedActivitiesTotal: number
  uncorrectedActivitiesPagination: Pages
}

export interface ActivitiesSuccessPayload {
  total: number
  tab: OverviewTab
  doNotSetPagination: boolean
  future: Activity[]
  today: Activity[]
  passed: Activity[]
  offset: number
  shouldOverrideActivities: boolean
}

export interface GetActivitiesRequest {
  type: typeof GET_ACTIVITIES_REQUEST
  payload: {
    silentLoading: boolean
    tab: OverviewTab
    reset: boolean
  }
}

export interface GetActivitiesSuccess {
  type: typeof GET_ACTIVITIES_SUCCESS
  payload: ActivitiesSuccessPayload
}

export interface GetActivitiesFailure {
  type: typeof GET_ACTIVITIES_FAILURE
  payload: {
    error: string
  }
}

export interface SetNextLiveOpen {
  type: typeof SET_NEXT_LIVE_OPEN
}

export interface SetNextLiveClose {
  type: typeof SET_NEXT_LIVE_CLOSE
}

export interface ResetClassroom {
  type: typeof RESET_CLASSROOM
}

export interface GetCompletionSummaryRequest {
  type: typeof GET_COMPLETION_SUMMARY_REQUEST
}

export interface GetCompletionSummarySuccess {
  type: typeof GET_COMPLETION_SUMMARY_SUCCESS
  payload: {
    completionSummary: CompletionSummary
  }
}

export interface GetCompletionSummaryFailure {
  type: typeof GET_COMPLETION_SUMMARY_FAILURE
  payload: {
    error: string
  }
}

export interface GetActivitiesSummaryRequest {
  type: typeof GET_ACTIVITIES_SUMMARY_REQUEST
}

export interface GetActivitiesSummarySuccess {
  type: typeof GET_ACTIVITIES_SUMMARY_SUCCESS
  payload: {
    activitiesSummary: ActivitiesSummary
  }
}

export interface GetActivitiesSummaryFailure {
  type: typeof GET_ACTIVITIES_SUMMARY_FAILURE
  payload: {
    error: string
  }
}

export interface GetAttendanceSummaryRequest {
  type: typeof GET_ATTENDANCE_SUMMARY_REQUEST
}

export interface GetAttendanceSummarySuccess {
  type: typeof GET_ATTENDANCE_SUMMARY_SUCCESS
  payload: {
    attendanceSummary: AttendanceSummary
  }
}

export interface GetAttendanceSummaryFailure {
  type: typeof GET_ATTENDANCE_SUMMARY_FAILURE
  payload: {
    error: string
  }
}

export interface GetEvaluationsSummaryRequest {
  type: typeof GET_EVALUATIONS_SUMMARY_REQUEST
}

export interface GetEvaluationsSummarySuccess {
  type: typeof GET_EVALUATIONS_SUMMARY_SUCCESS
  payload: {
    evaluationsSummary: Evaluation[]
  }
}

export interface GetEvaluationsSummaryFailure {
  type: typeof GET_EVALUATIONS_SUMMARY_FAILURE
  payload: {
    error: string
  }
}

export interface GetActivityForStudentListRequest {
  type: typeof GET_ACTIVITY_FOR_STUDENT_LIST_REQUEST
}

export interface GetActivityForStudentListSuccess {
  type: typeof GET_ACTIVITY_FOR_STUDENT_LIST_SUCCESS
}

export interface GetActivityForStudentListFailure {
  type: typeof GET_ACTIVITY_FOR_STUDENT_LIST_FAILURE
  payload: {
    error: string
  }
}

export interface GetUncorrectedActivitiesRequest {
  type: typeof GET_UNCORRECTED_ACTIVITIES_REQUEST
  payload: {
    count: number
    page: number
    rowsPerPage: number
  }
}

export interface GetUncorrectedActivitiesSuccess {
  type: typeof GET_UNCORRECTED_ACTIVITIES_SUCCESS
  payload: {
    total: number
    uncorrectedActivities: UncorrectedActivity[]
  }
}

export interface GetUncorrectedActivitiesFailure {
  type: typeof GET_UNCORRECTED_ACTIVITIES_FAILURE
  payload: {
    error: string
  }
}

export type OverviewActions =
  | GetActivitiesRequest
  | GetActivitiesSuccess
  | GetActivitiesFailure
  | SetNextLiveOpen
  | SetNextLiveClose
  | ResetClassroom
  | GetCompletionSummaryRequest
  | GetCompletionSummarySuccess
  | GetCompletionSummaryFailure
  | GetActivitiesSummaryRequest
  | GetActivitiesSummarySuccess
  | GetActivitiesSummaryFailure
  | GetAttendanceSummaryRequest
  | GetAttendanceSummarySuccess
  | GetAttendanceSummaryFailure
  | GetEvaluationsSummaryRequest
  | GetEvaluationsSummarySuccess
  | GetEvaluationsSummaryFailure
  | GetActivityForStudentListRequest
  | GetActivityForStudentListSuccess
  | GetActivityForStudentListFailure
  | GetUncorrectedActivitiesRequest
  | GetUncorrectedActivitiesSuccess
  | GetUncorrectedActivitiesFailure
