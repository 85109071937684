import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/principal/forms/formView/types/forms'

const initialState: Types.FormsState = {
  error: '',
  status: REQUEST_STATUS.IDLE,
  loading: false,
  organizationId: 0,
  forms: [],
  count: 0,
  selectedForm: {
    answersTable: [],
    closesAt: '',
    opensAt: '',
    id: 0,
    name: '',
    description: '',
    ownerAvatar: '',
    ownerName: '',
    ownerLastName: '',
    updatedAt: '',
    uuid: '',
    templateId: 0,
  },
  pagination: { more: false, page: 0, rowsPerPage: 10, count: 0 },
}

const FormsReducer = (
  state: Types.FormsState = initialState,
  action: Types.FormsActionsCreator
) => {
  switch (action.type) {
    case Types.FORMS_TYPES.GET_FORMS_REQUEST: {
      const { organizationId } = action.payload
      return { ...state, loading: true, status: REQUEST_STATUS.LOADING, organizationId }
    }
    case Types.FORMS_TYPES.GET_FORMS_SUCCESS: {
      const { forms, count, more, page } = action.payload
      return {
        ...state,
        loading: false,
        status: REQUEST_STATUS.SUCCESS,
        forms,
        pagination: {
          ...state.pagination,
          count,
          page,
          more,
        },
      }
    }
    case Types.FORMS_TYPES.GET_FORMS_FAILURE: {
      const error = action.status
      return { ...state, loading: false, status: REQUEST_STATUS.FAILURE, error }
    }
    case Types.FORMS_TYPES.SET_PAGINATION_OFFSET:
      return { ...state, pagination: { ...state.pagination, offset: action.payload.offset } }
    case Types.FORMS_TYPES.SET_SELECTED_FORM: {
      const { form } = action.payload
      return { ...state, selectedForm: form }
    }
    default:
      return state
  }
}

export default FormsReducer
