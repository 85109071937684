import { apiPublic } from '..'
import { vimeoCreateVideoUrl } from '@aula/config'
import type { UploadVideoPayload } from './types'

type CreateVideoResponse = {
  description: {
    uri: string
    uploadLink: string
    playerEmbedUrl: string
  }
}

/**
 * Upload video to vimeo service
 *
 */
export function createVideo({ size, name, description = '' }: UploadVideoPayload) {
  const params = {
    name,
    size,
    description,
  }

  return apiPublic
    .get<CreateVideoResponse>(vimeoCreateVideoUrl, { params })
    .then((resp) => resp.data.description)
}
