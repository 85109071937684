import type {
  ContentCreatorDashboardState,
  ContentCreatorActions,
} from '@/sections/content-creator/dashboard/types/dashboard'
import * as booksTypes from '@/sections/content-creator/books/types/books'
import * as premiumActivitiesTypes from '@/sections/content-creator/premium-activities/types/premium-activities'
import * as sequencesTypes from '@/sections/content-creator/sequences/types/sequences'
import * as coursesTypes from '@/sections/content-creator/courses/types/courses'
import localforage from 'localforage'
import { persistReducer } from 'redux-persist'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { anyPass, isEmpty, isNil, mergeDeepRight, reject } from 'ramda'

const initialState: ContentCreatorDashboardState = {
  books: [],
  booksPagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0,
  },
  premiumActivities: [],
  premiumActivitiesPagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0,
  },
  sequences: [],
  sequencesPagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0,
  },
  courses: [],
  coursesPagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0,
  },
  loadingActivities: false,
  loadingBooks: false,
  loadingSequences: false,
  loadingCourses: false,
  error: '',
}

const requestDestination = reduxRequestOriginMap.CONTENT_CREATOR_DASHBOARD

function root(state = initialState, action: ContentCreatorActions) {
  switch (action.type) {
    case booksTypes.GET_ORGANIZATION_BOOKS_REQUEST: {
      if (action.payload.requestOrigin === requestDestination) {
        return {
          ...state,
          loadingBooks: true,
          booksPagination: {
            ...state.booksPagination,
            page: action.payload.page,
            rowsPerPage: action.payload.rowsPerPage,
          },
        }
      } else {
        return state
      }
    }
    case booksTypes.GET_ORGANIZATION_BOOKS_SUCCESS: {
      if (action.payload.requestOrigin === requestDestination) {
        return {
          ...state,
          loadingBooks: false,
          error: '',
          books: action.payload.books,
          booksPagination: {
            ...state.booksPagination,
            count: action.payload.count,
          },
        }
      } else {
        return state
      }
    }
    case booksTypes.GET_ORGANIZATION_BOOKS_FAILURE: {
      if (action.payload.requestOrigin === requestDestination) {
        return { ...state, loadingBooks: false, error: '' }
      } else {
        return state
      }
    }
    case premiumActivitiesTypes.GET_PREMIUM_ACTIVITIES_REQUEST: {
      if (action.payload.requestOrigin === requestDestination) {
        return {
          ...state,
          loadingActivities: true,
          premiumActivitiesPagination: {
            ...state.premiumActivitiesPagination,
            page: action.payload.page,
            rowsPerPage: action.payload.rowsPerPage,
          },
        }
      } else {
        return state
      }
    }
    case premiumActivitiesTypes.GET_PREMIUM_ACTIVITIES_SUCCESS: {
      if (action.payload.requestOrigin === requestDestination) {
        return {
          ...state,
          loadingActivities: false,
          error: '',
          premiumActivities: action.payload.premiumActivities,
          premiumActivitiesPagination: {
            ...state.premiumActivitiesPagination,
            count: action.payload.count,
          },
        }
      } else {
        return state
      }
    }
    case premiumActivitiesTypes.GET_PREMIUM_ACTIVITIES_FAILURE: {
      if (action.payload.requestOrigin === requestDestination) {
        return { ...state, loadingActivities: false, error: '' }
      } else {
        return state
      }
    }
    case premiumActivitiesTypes.CREATE_PREMIUM_SUCCESS: {
      return {
        ...state,
        premiumActivities: [...state.premiumActivities, action.payload.premiumActivity],
      }
    }
    case premiumActivitiesTypes.EDIT_PREMIUM_SUCCESS: {
      const { premiumActivity: updatedActivity } = action.payload

      const trimmedActivity = reject(anyPass([isEmpty, isNil]))(updatedActivity)

      const premiumActivities = state.premiumActivities.slice()
      const actIndex = premiumActivities.findIndex(
        (b) => b.activityID === updatedActivity.activityID
      )

      // @ts-ignore
      premiumActivities[actIndex] = mergeDeepRight(premiumActivities[actIndex], trimmedActivity)

      return { ...state, premiumActivities }
    }
    case premiumActivitiesTypes.DELETE_PREMIUM_ACTIVITY_SUCCESS: {
      const { activityID } = action.payload
      const premiumActivities = state.premiumActivities.filter(
        (act) => act.activityID !== activityID
      )

      return { ...state, premiumActivities }
    }
    case sequencesTypes.LIST_SEQUENCES_REQUEST: {
      if (action.payload.requestOrigin === requestDestination) {
        return {
          ...state,
          loadingSequences: true,
          sequencesPagination: {
            ...state.sequencesPagination,
            page: action.payload.page,
            rowsPerPage: action.payload.rowsPerPage,
          },
        }
      } else return state
    }
    case sequencesTypes.LIST_SEQUENCES_SUCCESS: {
      if (action.payload.requestOrigin === requestDestination) {
        return {
          ...state,
          loadingSequences: false,
          error: '',
          sequences: action.payload.sequences,
          sequencesPagination: {
            ...state.sequencesPagination,
            count: action.payload.count,
          },
        }
      } else return state
    }
    case sequencesTypes.LIST_SEQUENCES_FAILURE: {
      if (action.payload.requestOrigin === requestDestination) {
        return { ...state, loadingSequences: false, error: action.payload.error }
      } else return state
    }
    case coursesTypes.LIST_COURSES_REQUEST: {
      if (action.payload.requestOrigin === requestDestination) {
        return {
          ...state,
          loadingCourses: true,
          coursesPagination: {
            ...state.coursesPagination,
            page: action.payload.page,
            rowsPerPage: action.payload.rowsPerPage,
          },
        }
      } else return state
    }
    case coursesTypes.LIST_COURSES_SUCCESS: {
      if (action.payload.requestOrigin === requestDestination) {
        return {
          ...state,
          loadingCourses: false,
          error: '',
          courses: action.payload.courses,
          coursesPagination: {
            ...state.coursesPagination,
            count: action.payload.count,
          },
        }
      } else return state
    }
    case coursesTypes.LIST_COURSES_FAILURE: {
      if (action.payload.requestOrigin === requestDestination) {
        return { ...state, loadingCourses: false, error: action.payload.error }
      } else return state
    }
    default:
      return state
  }
}

const persistConfig = {
  key: 'content-creator-dashboard',
  whitelist: [],
  storage: localforage,
}

export default persistReducer(persistConfig, root)
