import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export const SET_CODE_CLAIM_MODAL_OPEN = 'BACKPACK/CODE_CLAIM_MODAL/SET_CODE_CLAIM_MODAL_OPEN'

export const SET_CODE = 'BACKPACK/CODE_CLAIM_MODAL/SET_CODE'

export const CLAIM_CODE_REQUEST = 'BACKPACK/CODE_CLAIM_MODAL/CLAIM_CODE_REQUEST'
export const CLAIM_CODE_SUCCESS = 'BACKPACK/CODE_CLAIM_MODAL/CLAIM_CODE_SUCCESS'
export const CLAIM_CODE_FAILURE = 'BACKPACK/CODE_CLAIM_MODAL/CLAIM_CODE_FAILURE'

export interface SetCodeClaimModalOpen {
  type: typeof SET_CODE_CLAIM_MODAL_OPEN
  payload: {
    requestOrigin: ReduxRequestOrigin
    open: boolean
  }
}

export interface SetCode {
  type: typeof SET_CODE
  payload: {
    requestOrigin: ReduxRequestOrigin
    code: string
  }
}

export interface ClaimCodeRequest {
  type: typeof CLAIM_CODE_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface ClaimCodeSuccess {
  type: typeof CLAIM_CODE_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface ClaimCodeFailure {
  type: typeof CLAIM_CODE_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface CodeClaimModalState {
  open: boolean
  code: string
  loading: boolean
  error: string
  status: REQUEST_STATUS
}

export type CodeClaimModalActions =
  | SetCode
  | SetCodeClaimModalOpen
  | ClaimCodeRequest
  | ClaimCodeSuccess
  | ClaimCodeFailure
