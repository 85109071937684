import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type {
  MessageThreadRepliesActions,
  MessageThreadRepliesState,
} from '@/sections/student/summary/types/tabs/schoolRecords/messageThreadReplies'
import { MESSAGE_THREAD_REPLIES_TYPES } from '@/sections/student/summary/types/tabs/schoolRecords/messageThreadReplies'

const initialState: MessageThreadRepliesState = {
  error: null,
  loading: false,
  status: REQUEST_STATUS.IDLE,
  total: 0,
  replies: [],
  cancelToken: null,
  messageId: 0,
  threadOwnerId: 0,
}

export default function messageThreadRepliesReducer(
  state: MessageThreadRepliesState = initialState,
  action: MessageThreadRepliesActions
): MessageThreadRepliesState {
  switch (action.type) {
    case MESSAGE_THREAD_REPLIES_TYPES.GET_MESSAGE_THREADS: {
      const { messageId, threadOwnerId } = action.payload
      return {
        ...state,
        loading: true,
        status: REQUEST_STATUS.LOADING,
        messageId,
        threadOwnerId,
      }
    }
    case MESSAGE_THREAD_REPLIES_TYPES.GET_MESSAGE_THREADS_SUCCESS: {
      const { total, replies } = action.payload
      return {
        ...state,
        total,
        replies,
        loading: false,
        status: REQUEST_STATUS.SUCCESS,
      }
    }
    case MESSAGE_THREAD_REPLIES_TYPES.GET_MESSAGE_THREADS_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case MESSAGE_THREAD_REPLIES_TYPES.SET_MESSAGE_THREAD_CANCEL_TOKEN: {
      const { token } = action.payload
      return {
        ...state,
        cancelToken: token,
      }
    }
    case MESSAGE_THREAD_REPLIES_TYPES.CLEAN_MESSAGE_THREADS: {
      return initialState
    }
    default:
      return state
  }
}
