export const LETS_GO_FOR_MORE_PALETTE = {
  primary: {
    light: '#5FA8B3',
    main: '#30559F',
    dark: '#031A39',
    contrastText: '#FFF',
  },
  secondary: {
    light: '#5FA8B3',
    main: '#031A39',
    dark: '#031A39',
  },
}
