import type { LocaleSpecification } from 'moment'

export const fr: LocaleSpecification = {
  months: [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'aout',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ],
  monthsShort: ['jan', 'fév', 'mar', 'avr', 'mai', 'jui', 'jui', 'aoû', 'sep', 'oct', 'nov', 'déc'],
  weekdays: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
  weekdaysShort: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
  weekdaysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
  longDateFormat: {
    LT: 'H:mm',
    LTS: 'LT:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY LT',
    LLLL: 'dddd, D MMMM YYYY LT',
  },
  calendar: {
    sameDay: function () {
      return `[Aujourd'hui à] LT`
    },
    nextDay: function () {
      return '[Demain à] LT'
    },
    nextWeek: function () {
      return 'dddd [à] LT'
    },
    lastDay: function () {
      return '[Hier à] LT'
    },
    lastWeek: function () {
      return 'dddd [dernier à] LT'
    },
    sameElse: 'L',
  },
  relativeTime: {
    future: 'dans %s',
    past: 'il y a %s',
    s: 'quelques secondes',
    m: 'une minute',
    mm: '%d minutes',
    h: 'une heure',
    hh: '%d heures',
    d: 'un jour',
    dd: '%d jours',
    M: 'un mois',
    MM: '%d mois',
    y: 'un an',
    yy: '%d ans',
  },
  ordinalParse: /\d{1,2}(er|e)/,
  ordinal: function (number) {
    return number + (number === 1 ? 'er' : 'e')
  },
  week: {
    dow: 1, // Sunday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
}
