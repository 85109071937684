export type UserParam = {
  name: string
  surname: string
}

/**
 * General purpose utility to standardize/format the full username
 *
 * @param user user
 * @param user.name user name
 * @param user.surname user surname
 * @param inv invert name and surname order
 */
export const formatUsername = ({ name, surname }: UserParam, inv = false) => {
  const n = [surname, name]
  return inv ? n.reverse().join(' ') : n.join(' ')
}
