import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/student/summary/types/tabs/activities/periods'

export const initialState: Types.PeriodsState = {
  status: REQUEST_STATUS.IDLE,
  data: [],
  selectedPeriod: {
    id: 0,
    name: '',
    startDate: '',
    endDate: '',
    final: false,
  },
}

const periodsReducer = (
  state: Types.PeriodsState = initialState,
  action: Types.PeriodsAction
): Types.PeriodsState => {
  switch (action.type) {
    case Types.PERIODS_TYPES.GET_PERIODS_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.PERIODS_TYPES.GET_PERIODS_SUCCESS: {
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        data: action.payload.data,
      }
    }
    case Types.PERIODS_TYPES.GET_PERIODS_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.PERIODS_TYPES.SET_SELECTED_PERIOD: {
      const { newSelectedPeriod } = action.payload

      return {
        ...state,
        selectedPeriod: newSelectedPeriod,
      }
    }
    case Types.PERIODS_TYPES.CLEAN_PERIODS: {
      return initialState
    }
    default:
      return state
  }
}

export default periodsReducer
