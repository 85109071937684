import type { Pages } from '@/sections/content-creator/dashboard/types/dashboard'

export const GET_ROOT_ITEMS_REQUEST = 'BACKPACK/GET_ROOT_ITEMS_REQUEST'
export const GET_ROOT_ITEMS_SUCCESS = 'BACKPACK/GET_ROOT_ITEMS_SUCCESS'
export const GET_ROOT_ITEMS_FAILURE = 'BACKPACK/GET_ROOT_ITEMS_FAILURE'

export const GET_FOLDER_ITEMS_REQUEST = 'BACKPACK/GET_FOLDER_ITEMS_REQUEST'
export const GET_FOLDER_ITEMS_SUCCESS = 'BACKPACK/GET_FOLDER_ITEMS_SUCCESS'
export const GET_FOLDER_ITEMS_FAILURE = 'BACKPACK/GET_FOLDER_ITEMS_FAILURE'

export const RENAME_ITEM_REQUEST = 'BACKPACK/RENAME_ITEM_REQUEST'
export const RENAME_ITEM_SUCCESS = 'BACKPACK/RENAME_ITEM_SUCCESS'
export const RENAME_ITEM_FAILURE = 'BACKPACK/RENAME_ITEM_FAILURE'

export const DELETE_ITEM_REQUEST = 'BACKPACK/DELETE_ITEM_REQUEST'
export const DELETE_ITEM_SUCCESS = 'BACKPACK/DELETE_ITEM_SUCCESS'
export const DELETE_ITEM_FAILURE = 'BACKPACK/DELETE_ITEM_FAILURE'

export const DRAG_FILE_REQUEST = 'BACKPACK/DRAG_FILE_REQUEST'
export const DRAG_FILE_SUCCESS = 'BACKPACK/DRAG_FILE_SUCCESS'
export const DRAG_FILE_FAILURE = 'BACKPACK/DRAG_FILE_FAILURE'

export const DRAG_FOLDER_REQUEST = 'BACKPACK/DRAG_FOLDER_REQUEST'
export const DRAG_FOLDER_SUCCESS = 'BACKPACK/DRAG_FOLDER_SUCCESS'
export const DRAG_FOLDER_FAILURE = 'BACKPACK/DRAG_FOLDER_FAILURE'

export const CREATE_FOLDER_REQUEST = 'BACKPACK/CREATE_FOLDER_REQUEST'
export const CREATE_FOLDER_SUCCESS = 'BACKPACK/CREATE_FOLDER_SUCCESS'
export const CREATE_FOLDER_FAILURE = 'BACKPACK/CREATE_FOLDER_FAILURE'

export const ADD_FILES_TO_UPLOAD = 'BACKPACK/ADD_FILES_TO_UPLOAD'
export const REMOVE_FILE_TO_UPLOAD = 'BACKPACK/REMOVE_FILE_TO_UPLOAD'

export const UPLOAD_FILES_REQUEST = 'BACKPACK/UPLOAD_FILES_REQUEST'
export const UPLOAD_FILES_SUCCESS = 'BACKPACK/UPLOAD_FILES_SUCCESS'
export const UPLOAD_FILES_FAILURE = 'BACKPACK/UPLOAD_FILES_FAILURE'

export const SET_MODAL_OPEN = 'BACKPACK/SET_MODAL_OPEN'
export const SET_MODAL_CLOSED = 'BACKPACK/SET_MODAL_CLOSED'

export const SELECT_ITEM = 'BACKPACK/SELECT_ITEM'

export const SET_NEW_FILENAME = 'BACKPACK/SET_NEW_FILENAME'

export const GET_BACKPACK_BOOKS_REQUEST = 'BACKPACK/GET_BACKPACK_BOOKS_REQUEST'
export const GET_BACKPACK_BOOKS_SUCCESS = 'BACKPACK/GET_BACKPACK_BOOKS_SUCCESS'
export const GET_BACKPACK_BOOKS_FAILURE = 'BACKPACK/GET_BACKPACK_BOOKS_FAILURE'

export const GET_BACKPACK_RECORDINGS_REQUEST = 'BACKPACK/GET_BACKPACK_RECORDINGS_REQUEST'
export const GET_BACKPACK_RECORDINGS_SUCCESS = 'BACKPACK/GET_BACKPACK_RECORDINGS_SUCCESS'
export const GET_BACKPACK_RECORDINGS_FAILURE = 'BACKPACK/GET_BACKPACK_RECORDINGS_FAILURE'

export const TOGGLE_RECORDING_OPEN = 'BACKPACK/TOGGLE_RECORDING_OPEN'

export const rootTypeFiles = 'root-files'
export const rootTypeBooks = 'root-books'
export const rootTypeActivities = 'root-activities'
export const rootTypeRecordings = 'root-recordings'

export type BackpackRootType =
  | typeof rootTypeFiles
  | typeof rootTypeBooks
  | typeof rootTypeActivities
  | typeof rootTypeRecordings

export interface BackpackItem {
  id: number
  type: string
  name: string
  fileId: number
  parentId: number
  key: string
  createdAt: string
  updatedAt: string
  userId: number
  size: number
  modified: number
  rootType: string
  file: {
    createdAt: string
    description: string
    id: number
    name: string
    ownerId: number
    public: boolean
    sizeKb: number
    type: string
    updatedAt: string
    url: string
  }
}

export interface BackpackRecording {
  title: string
  videoSource: string
  videoId: string
  datetime: string
  subject: string
  subjectColor: string
  activityId?: string
  videos: Array<{
    activityId: number
    part: number
    videoId: string
    videoSource: string
  }>
}

export interface BackpackState {
  myFiles: BackpackItem[]
  myFilesRootId: number
  loading: boolean
  loadingBooks: boolean
  books: BackpackItem[]
  recordings: BackpackRecording[]
  loadingRecordings: boolean
  recordingsPagination: Pages
  openRecording: BackpackRecording | null
  error: string
  selectedItem: BackpackItem | null
  modalOpen: boolean
  modalType: string
  newFilename: string
  currentFileExtension: string
  filesToUpload: File[]
  openFolders: number[]
}

export interface GetRootItemsRequest {
  type: typeof GET_ROOT_ITEMS_REQUEST
}

export interface GetRootItemsSuccess {
  type: typeof GET_ROOT_ITEMS_SUCCESS
  payload: {
    items: BackpackItem[]
    myFilesRootId: number
  }
}

export interface GetRootItemsFailure {
  type: typeof GET_ROOT_ITEMS_FAILURE
  payload: {
    error: string
  }
}

export interface GetFolderItemsRequest {
  type: typeof GET_FOLDER_ITEMS_REQUEST
}

export interface GetFolderItemsSuccess {
  type: typeof GET_FOLDER_ITEMS_SUCCESS
  payload: {
    items: BackpackItem[]
  }
}

export interface GetFolderItemsFailure {
  type: typeof GET_FOLDER_ITEMS_FAILURE
  payload: {
    error: string
  }
}
export interface GetBackpackBooksRequest {
  type: typeof GET_BACKPACK_BOOKS_REQUEST
}

export interface GetBackpackBooksSuccess {
  type: typeof GET_BACKPACK_BOOKS_SUCCESS
  payload: {
    items: BackpackItem[]
  }
}

export interface GetBackpackBooksFailure {
  type: typeof GET_BACKPACK_BOOKS_FAILURE
  payload: {
    error: string
  }
}

export interface GetBackpackRecordingsRequest {
  type: typeof GET_BACKPACK_RECORDINGS_REQUEST
  payload: {
    page: number
    rowsPerPage: number
  }
}

export interface GetBackpackRecordingsSuccess {
  type: typeof GET_BACKPACK_RECORDINGS_SUCCESS
  payload: {
    recordings: BackpackRecording[]
    count: number
  }
}

export interface GetBackpackRecordingsFailure {
  type: typeof GET_BACKPACK_RECORDINGS_FAILURE
  payload: {
    error: string
  }
}

export interface RenameItemRequest {
  type: typeof RENAME_ITEM_REQUEST
}

export interface RenameItemSuccess {
  type: typeof RENAME_ITEM_SUCCESS
  payload: {
    item: BackpackItem
    oldKey: string
  }
}

export interface RenameItemFailure {
  type: typeof RENAME_ITEM_FAILURE
  payload: {
    error: string
  }
}

export interface SelectItem {
  type: typeof SELECT_ITEM
  payload: {
    selectedItem: BackpackItem
  }
}

export interface DragFileRequest {
  type: typeof DRAG_FILE_REQUEST
}

export interface DragFileSuccess {
  type: typeof DRAG_FILE_SUCCESS
  payload: {
    file: BackpackItem
  }
}

export interface DragFolderFailure {
  type: typeof DRAG_FOLDER_FAILURE
  payload: {
    error: string
  }
}

export interface DragFolderRequest {
  type: typeof DRAG_FOLDER_REQUEST
}

export interface DragFolderSuccess {
  type: typeof DRAG_FOLDER_SUCCESS
  payload: {
    item: BackpackItem
    oldKey: string
  }
}

export interface DragFileFailure {
  type: typeof DRAG_FILE_FAILURE
  payload: {
    error: string
  }
}

export interface SetModalOpen {
  type: typeof SET_MODAL_OPEN
  payload: {
    modalType: string
  }
}

export interface SetModalClosed {
  type: typeof SET_MODAL_CLOSED
}

export interface SetNewFilename {
  type: typeof SET_NEW_FILENAME
  payload: {
    newFilename: string
  }
}

export interface CreateFolderRequest {
  type: typeof CREATE_FOLDER_REQUEST
}

export interface CreateFolderSuccess {
  type: typeof CREATE_FOLDER_SUCCESS
  payload: {
    file: BackpackItem
  }
}

export interface CreateFolderFailure {
  type: typeof CREATE_FOLDER_FAILURE
  payload: {
    error: string
  }
}

export interface DeleteItemRequest {
  type: typeof DELETE_ITEM_REQUEST
}

export interface DeleteItemSuccess {
  type: typeof DELETE_ITEM_SUCCESS
  payload: {
    item: BackpackItem
  }
}

export interface DeleteItemFailure {
  type: typeof DELETE_ITEM_FAILURE
  payload: {
    error: string
  }
}

export interface AddFilesToUpload {
  type: typeof ADD_FILES_TO_UPLOAD
  payload: {
    files: File[]
  }
}

export interface RemoveFileToUpload {
  type: typeof REMOVE_FILE_TO_UPLOAD
  payload: {
    file: File
  }
}

export interface UploadFilesRequest {
  type: typeof UPLOAD_FILES_REQUEST
}

export interface UploadFilesSuccess {
  type: typeof UPLOAD_FILES_SUCCESS
  payload: {
    files: BackpackItem[]
  }
}

export interface UploadFilesFailure {
  type: typeof UPLOAD_FILES_FAILURE
  payload: {
    error: string
  }
}

export interface ToggleRecordingOpen {
  type: typeof TOGGLE_RECORDING_OPEN
  payload: {
    recording: BackpackRecording | null
  }
}

export type BackpackActions =
  | GetRootItemsRequest
  | GetRootItemsSuccess
  | GetRootItemsFailure
  | GetFolderItemsRequest
  | GetFolderItemsSuccess
  | GetFolderItemsFailure
  | GetBackpackBooksRequest
  | GetBackpackBooksSuccess
  | GetBackpackBooksFailure
  | GetBackpackRecordingsRequest
  | GetBackpackRecordingsSuccess
  | GetBackpackRecordingsFailure
  | SelectItem
  | RenameItemRequest
  | RenameItemSuccess
  | RenameItemFailure
  | DeleteItemRequest
  | DeleteItemSuccess
  | DeleteItemFailure
  | DragFileRequest
  | DragFileSuccess
  | DragFileFailure
  | DragFolderRequest
  | DragFolderSuccess
  | DragFolderFailure
  | CreateFolderRequest
  | CreateFolderSuccess
  | CreateFolderFailure
  | SetModalOpen
  | SetModalClosed
  | SetNewFilename
  | AddFilesToUpload
  | RemoveFileToUpload
  | UploadFilesRequest
  | UploadFilesSuccess
  | UploadFilesFailure
  | ToggleRecordingOpen
