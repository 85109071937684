import * as React from 'react'
import type { SVGProps } from 'react'
const SvgClassroom = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={31}
    height={36}
    viewBox="0 0 31 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.063 2.813h2.812c.621 0 1.125.503 1.125 1.124v6.47a.563.563 0 0 1-.997.357l-3.937-4.782a.562.562 0 0 1-.128-.357V3.937c0-.62.503-1.124 1.125-1.124ZM7.328 2.813H4.515c-.621 0-1.125.503-1.125 1.124v6.47a.562.562 0 0 0 .997.357l3.938-4.782a.562.562 0 0 0 .128-.357V3.937c0-.62-.504-1.124-1.125-1.124Z"
      fill="#238BD1"
    />
    <path
      d="M19.703 4.5a.562.562 0 0 1-.563-.563V2.25a.563.563 0 0 0-.562-.563h-6.75a.562.562 0 0 0-.563.563v1.688a.562.562 0 1 1-1.125 0V2.25A2.25 2.25 0 0 1 12.39 0h5.625a2.25 2.25 0 0 1 2.25 2.25v1.688c0 .31-.251.562-.562.562Z"
      fill="#5C546A"
    />
    <path
      d="M2.828 19.125H1.703a1.69 1.69 0 0 0-1.688 1.688v10.125c0 .93.757 1.687 1.688 1.687h1.125c.31 0 .562-.252.562-.563V19.688a.562.562 0 0 0-.562-.563Z"
      fill="#27A8FF"
    />
    <path d="M2.813 22.5H0v1.125h2.813V22.5Z" fill="#238BD1" />
    <path
      d="M27.557 19.125h1.115c.922 0 1.672.757 1.672 1.688v10.125c0 .93-.75 1.687-1.672 1.687h-1.115a.56.56 0 0 1-.557-.563V19.688c0-.311.25-.563.557-.563Z"
      fill="#27A8FF"
    />
    <path d="M30.36 22.5h-2.787v1.125h2.787V22.5Z" fill="#238BD1" />
    <path
      d="M15.203 2.813c-7.134 0-12.938 5.803-12.938 12.937v15.188A5.068 5.068 0 0 0 7.328 36h15.75a5.068 5.068 0 0 0 5.062-5.063V15.75c0-7.134-5.803-12.938-12.937-12.938Z"
      fill="#2F2565"
    />
    <path
      d="M23.078 19.125H7.328a1.69 1.69 0 0 0-1.688 1.688v10.125c0 .93.757 1.687 1.688 1.687h15.75a1.69 1.69 0 0 0 1.687-1.688V20.813a1.69 1.69 0 0 0-1.687-1.687ZM19.84 8.703a8.393 8.393 0 0 0-4.637-1.39c-1.655 0-3.258.48-4.636 1.39a2.224 2.224 0 0 0-.99 1.867v5.18c0 .31.252.563.563.563h10.125c.311 0 .563-.252.563-.563v-5.18c0-.76-.37-1.459-.989-1.867Z"
      fill="#27A8FF"
    />
    <path
      d="M16.313 11.25h-2.22a1.125 1.125 0 0 1 0-2.25h2.22a1.125 1.125 0 0 1 0 2.25ZM24.75 22.5H5.625v1.125H24.75V22.5Z"
      fill="#238BD1"
    />
    <path
      d="M9.578 25.875a.562.562 0 0 1-.563-.563v-2.25a.562.562 0 1 1 1.125 0v2.25c0 .311-.251.563-.562.563Z"
      fill="#5C546A"
    />
    <path
      d="M10.125 28.125H9a.563.563 0 0 1-.563-.563v-1.687a1.125 1.125 0 1 1 2.25 0v1.688c0 .31-.251.562-.562.562Z"
      fill="#2F2565"
    />
    <path
      d="M26.394 15.188a.569.569 0 0 1-.558-.523 10.65 10.65 0 0 0-10.633-9.602A10.65 10.65 0 0 0 4.57 14.665a.569.569 0 0 1-.558.523H2.28c-.008.186-.015.373-.015.562v.563h1.747c.868 0 1.589-.66 1.677-1.535a9.528 9.528 0 0 1 9.514-8.59c4.93 0 9.02 3.693 9.514 8.59a1.69 1.69 0 0 0 1.677 1.534h1.731c.006 0 .01-.003.015-.003v-.559c0-.189-.006-.376-.014-.562H26.395Z"
      fill="#27A8FF"
    />
  </svg>
)
export default SvgClassroom
