import type { Audience } from '@/api/lms/library'
import type { BasicAudience } from '@/api/lms/organizations'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { AVAILABILITY } from '@/sections/content-creator/sequenceDrawer/types/books'

// State.
export interface SecondStepState {
  availability: AVAILABILITY
  audienceModal: {
    isOpenModal: boolean
    isEditMode: boolean
    audiences: Audience[]
    status: REQUEST_STATUS
    searchText: string
    pagination: {
      count: number
      more: boolean
      page: number
      rowsPerPage: number
    }
    selectedAudiences: BasicAudience[]
  }
  audiences: BasicAudience[]
  status: REQUEST_STATUS
}

// Types.
export enum SECOND_STEP_TYPES {
  SET_AVAILABILITY = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/SECOND/SET_AVAILABILITY',
  CLEAN_STEP = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/SECOND/CLEAN_STEP',
  OPEN_MODAL = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/SECOND/OPEN_MODAL',
  CLOSE_MODAL = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/SECOND/CLOSE_MODAL',
  SET_SEARCH_TEXT = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/SECOND/SET_SEARCH_TEXT',
  GET_AUDIENCES_REQUEST = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/SECOND/GET_AUDIENCES_REQUEST',
  GET_AUDIENCES_SUCCESS = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/SECOND/GET_AUDIENCES_SUCCESS',
  GET_AUDIENCES_FAILURE = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/SECOND/GET_AUDIENCES_FAILURE',
  SET_SELECTED_AUDIENCES = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/SECOND/SET_SELECTED_AUDIENCES',
  SET_SELECTED_AUDIENCE = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/SECOND/SET_SELECTED_AUDIENCE',
  SET_AUDIENCES = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/SECOND/SET_AUDIENCES',
  CLEAN_MODAL = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/SECOND/CLEAN_MODAL',
  CREATE_BOOK_REQUEST = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/SECOND/CREATE_BOOK_REQUEST',
  CREATE_BOOK_SUCCESS = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/SECOND/CREATE_BOOK_SUCCESS',
  CREATE_BOOK_FAILURE = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/SECOND/CREATE_BOOK_FAILURE',
  EDIT_BOOK_REQUEST = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/SECOND/EDIT_BOOK_REQUEST',
  EDIT_BOOK_SUCCESS = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/SECOND/EDIT_BOOK_SUCCESS',
  EDIT_BOOK_FAILURE = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/SECOND/EDIT_BOOK_FAILURE',
}

// Set availability action.
export type SetAvailabilityAction = {
  type: SECOND_STEP_TYPES.SET_AVAILABILITY
  payload: {
    newAvailability: AVAILABILITY
  }
}

// Clean second step action.
export type CleanSecondStepAction = {
  type: SECOND_STEP_TYPES.CLEAN_STEP
}

// Open modal action.
export type OpenModalAction = {
  type: SECOND_STEP_TYPES.OPEN_MODAL
}

// Close modal action.
export type CloseModalAction = {
  type: SECOND_STEP_TYPES.CLOSE_MODAL
}

// Set search text action.
export type SetSearchTextAction = {
  type: SECOND_STEP_TYPES.SET_SEARCH_TEXT
  payload: {
    newSearchText: string
  }
}

// Get audience actions.
export type GetAudiencesRequestAction = {
  type: SECOND_STEP_TYPES.GET_AUDIENCES_REQUEST
}

export type GetAudiencesSuccessAction = {
  type: SECOND_STEP_TYPES.GET_AUDIENCES_SUCCESS
  payload: {
    audiences: Audience[]
    count: number
    more: boolean
    page: number
  }
}

export type GetAudiencesFailureAction = {
  type: SECOND_STEP_TYPES.GET_AUDIENCES_FAILURE
}

export type GetAudiencesAction =
  | GetAudiencesRequestAction
  | GetAudiencesSuccessAction
  | GetAudiencesFailureAction

// Set selected audiences action.
export type SetSelectedAudiencesAction = {
  type: SECOND_STEP_TYPES.SET_SELECTED_AUDIENCES
  payload: {
    newSelectedAudiences: BasicAudience[]
  }
}

// Set selected audience action.
export type SetSelectedAudienceAction = {
  type: SECOND_STEP_TYPES.SET_SELECTED_AUDIENCE
  payload: {
    audience: Audience
    isAddition: boolean
  }
}

// Set selected audiences action.
export type SetAudiencesAction = {
  type: SECOND_STEP_TYPES.SET_AUDIENCES
  payload: {
    audiences: BasicAudience[]
  }
}

// Clean modal action.
export type CleanModalAction = {
  type: SECOND_STEP_TYPES.CLEAN_MODAL
}

// Create book actions.
export type CreateBookRequestAction = {
  type: SECOND_STEP_TYPES.CREATE_BOOK_REQUEST
}

export type CreateBookSuccessAction = {
  type: SECOND_STEP_TYPES.CREATE_BOOK_SUCCESS
}

export type CreateBookFailureAction = {
  type: SECOND_STEP_TYPES.CREATE_BOOK_FAILURE
}

export type CreateBookAction =
  | CreateBookRequestAction
  | CreateBookSuccessAction
  | CreateBookFailureAction

// Edit book actions.
export type EditBookRequestAction = {
  type: SECOND_STEP_TYPES.EDIT_BOOK_REQUEST
}

export type EditBookSuccessAction = {
  type: SECOND_STEP_TYPES.EDIT_BOOK_SUCCESS
}

export type EditBookFailureAction = {
  type: SECOND_STEP_TYPES.EDIT_BOOK_FAILURE
}

export type EditBookAction = EditBookRequestAction | EditBookSuccessAction | EditBookFailureAction

// Actions.
export type SecondStepAction =
  | SetAvailabilityAction
  | CleanSecondStepAction
  | OpenModalAction
  | CloseModalAction
  | SetSearchTextAction
  | GetAudiencesAction
  | SetSelectedAudiencesAction
  | SetSelectedAudienceAction
  | SetAudiencesAction
  | CleanModalAction
  | CreateBookAction
  | EditBookAction
