import type { STUDENT_SUMMARY_TABS } from '@/sections/student/summary/constants/summary'

export interface StudentSummaryDrawerState {
  isOpen: boolean
  activeTab: STUDENT_SUMMARY_TABS
}

export enum STUDENT_SUMMARY_DRAWER_TYPES {
  OPEN_DRAWER = 'STUDENT/SUMMARY/DRAWER/OPEN_DRAWER',
  CLOSE_DRAWER = 'STUDENT/SUMMARY/DRAWER/CLOSE_DRAWER',
  SET_ACTIVE_TAB = 'STUDENT/SUMMARY/DRAWER/SET_ACTIVE_TAB',
  CLEAN_DRAWER = 'STUDENT/SUMMARY/DRAWER/CLEAN_DRAWER',
}

// Open and close drawer.
export type OpenDrawerAction = {
  type: STUDENT_SUMMARY_DRAWER_TYPES.OPEN_DRAWER
}

export type CloseDrawerAction = {
  type: STUDENT_SUMMARY_DRAWER_TYPES.CLOSE_DRAWER
}

// Set active tab.
export type SetActiveTabAction = {
  type: STUDENT_SUMMARY_DRAWER_TYPES.SET_ACTIVE_TAB
  payload: {
    newActiveTab: STUDENT_SUMMARY_TABS
  }
}

// Clean drawer.
export type CleanDrawerAction = {
  type: STUDENT_SUMMARY_DRAWER_TYPES.CLEAN_DRAWER
}

export type StudentSummaryDrawerAction =
  | OpenDrawerAction
  | CloseDrawerAction
  | SetActiveTabAction
  | CleanDrawerAction
