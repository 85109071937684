import moment from 'moment'
import { Sunday } from '@/legacy/svgs/calendarDays'
import { Monday } from '@/legacy/svgs/calendarDays'
import { Tuesday } from '@/legacy/svgs/calendarDays'
import { Wednesday } from '@/legacy/svgs/calendarDays'
import { Thursday } from '@/legacy/svgs/calendarDays'
import { Friday } from '@/legacy/svgs/calendarDays'
import { Saturday } from '@/legacy/svgs/calendarDays'

const WeekdayCalendar = ({ date, ...props }) => {
  const weekDayNumber = moment(date).weekday()

  const WeekDayIconsConfig = {
    0: Sunday,
    1: Monday,
    2: Tuesday,
    3: Wednesday,
    4: Thursday,
    5: Friday,
    6: Saturday,
  }

  const IconByWeekDay = WeekDayIconsConfig[weekDayNumber]
  return <IconByWeekDay {...props} />
}

export default WeekdayCalendar
