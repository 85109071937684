import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import { CreateSuccess, EditSuccess } from '@/sections/eb-evaluator/management/types/addEditThemeModal'
import type { Pages } from '@/sections/teacher/overview/types/overview'

export type Game = {
  id: number
  name: string
}

export enum CustomizeThemeStep {
  SELECT_GAMES = 'select-games',
  ORDER_GAMES = 'order-games',
}

export type CustomizeThemeModalState = {
  open: boolean
  games: Game[]
  selectedGames: Game[]
  loading: boolean
  error: string
  pagination: Pages
  step: CustomizeThemeStep
  search: string
}

export type GetGamesParams = {
  page: number
  limit: number
  search?: string
}

export const GET_GAMES_REQUEST = 'MANAGEMENT/EXAMS/CUSTOMIZE_THEME_MODAL/GET_GAMES_REQUEST'
export const GET_GAMES_SUCCESS = 'MANAGEMENT/EXAMS/CUSTOMIZE_THEME_MODAL/GET_GAMES_SUCCESS'
export const GET_GAMES_FAILURE = 'MANAGEMENT/EXAMS/CUSTOMIZE_THEME_MODAL/GET_GAMES_FAILURE'

export type GetGamesRequest = GenericActionWithType<typeof GET_GAMES_REQUEST, { search: string }>
export type GetGamesSuccess = GenericActionWithType<
  typeof GET_GAMES_SUCCESS,
  { games: Game[]; pagination: Pages }
>
export type GetGamesFailure = GenericActionWithType<typeof GET_GAMES_FAILURE, ErrorPayload>

export const ADD_GAME_TO_SELECTED = 'MANAGEMENT/EXAMS/CUSTOMIZE_THEME_MODAL/ADD_GAME_TO_SELECTED'
export const REMOVE_GAME_FROM_SELECTED =
  'MANAGEMENT/EXAMS/CUSTOMIZE_THEME_MODAL/REMOVE_GAME_FROM_SELECTED'

export type AddGameToSelected = GenericActionWithType<typeof ADD_GAME_TO_SELECTED, { id: number }>
export type RemoveGameFromSelected = GenericActionWithType<
  typeof REMOVE_GAME_FROM_SELECTED,
  { id: number }
>

export type GetGamesAction = GetGamesRequest | GetGamesSuccess | GetGamesFailure

export type CustomizeThemeModalAction =
  | ClearState
  | SetField<'open' | 'step' | 'selectedGames', boolean | CustomizeThemeStep | Game[]>
  | GetGamesAction
  | AddGameToSelected
  | RemoveGameFromSelected
  | CreateSuccess // when a theme is created/edited, we want to clear this modal
  | EditSuccess
