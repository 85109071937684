import { apiPrivate } from './..'
import { url } from '@aula/config'
import type { BreakoutRoomUpdate } from '@/sections/conference/types/breakoutRooms'

export const AvvAPI = {
  get,
  start,
  shouldJoin,
  hasJoined,
  generateToken,
  addEvent,
  setRecordingWatched,
  getBreakoutRooms,
  updateBreakoutRooms,
  getUpcoming,
}

/**
 * Get AVV.
 *
 * @role Any
 * @param id AVV's id
 */
function get(id: number) {
  return apiPrivate.get(url + `/v1/avv/${id}`)
}

/**
 * Start AVV.
 *
 * @role Teacher
 * @param id AVV's id
 */
function start(id: number) {
  return apiPrivate.put(url + `/v1/avv/${id}/start`)
}

/**
 * Returns whether the user should join the AVV.
 *
 * @role Any
 * @param id AVV's id
 */
function shouldJoin(id: number) {
  return apiPrivate.get(url + `/v1/avv/${id}/join`)
}

/**
 * Returns whether the user has joined the AVV.
 *
 * @role Any
 * @param id AVV's id
 */
function hasJoined(id: number) {
  return apiPrivate.get(url + `/v1/avv/${id}/joined`)
}

/**
 * Generates and returns a token to join an AVV.
 *
 * @role Any
 * @param id AVV's id
 */
function generateToken(id: number) {
  return apiPrivate.post(url + `/v1/avv/${id}/token`)
}

export enum AVVEvent {
  JOINED = 'joined',
  PING = 'ping',
  LEFT = 'left',
  RECORDING_STARTED = 'recording-started',
  RECORDING_STOPPED = 'recording-stopped',
  RECORDING_ERROR = 'recording-error',
  LOST_FOCUS = 'focus-lost',
  WINDOW_EXIT = 'window-exit',
  TAB_EXIT = 'tab-exit',
  PASTE = 'paste',
  COPY = 'copy',
  ESC_PRESSED = 'esc-pressed',
}

/**
 * Adds an event to an AVV.
 *
 * @role Any
 * @param id
 * @param event
 * @param content
 */
function addEvent(id: number, event: AVVEvent, content: string) {
  return apiPrivate.post(url + `/v1/avv/${id}/events?event=${event}&content=${content}`)
}

/**
 * Sets the recording as watched so the progress of the course this AVV belongs to gets updated.
 *
 * @role Student
 * @param activityID
 */
function setRecordingWatched(activityID: number) {
  return apiPrivate.post(url + `/v1/avv/${activityID}/watched-recording`)
}

/**
 * Gets a list of breakout rooms and its participants
 *
 * @role Teacher
 * @param activityID
 */
function getBreakoutRooms(activityID: number) {
  return apiPrivate.get(url + `/v1/avv/${activityID}/bor`)
}

/**
 * Updates the list of breakout rooms
 *
 * @role Teacher
 * @param activityID
 */
function updateBreakoutRooms(activityID: number, updatedBreakoutRooms: BreakoutRoomUpdate[]) {
  const data = {
    config: updatedBreakoutRooms,
  }

  return apiPrivate.put(url + `/v1/avv/${activityID}/bor`, data)
}

/**
 * Fetches a list of upcoming AVVs
 *
 * @role Teacher
 * @param activityID
 */

type GetUpcomingParams = { date: string; anticipationMinutes: number }

function getUpcoming(params: GetUpcomingParams) {
  return apiPrivate.get(url + '/v2/classrooms/upcoming', { params })
}
