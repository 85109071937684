import type {
  ManagementExam,
  ListExamsAsAdminAction,
  CreateExamAsAdminAction,
  EditExamAsAdminAction,
  GetExamSubjectsAsMgmtAction,
  ClassroomsOption,
} from '@/sections/eb-admin/management/types/examsDrawer'
import type { ManagementOrganization } from '@/api/lms/organizations'
import type { ManagementClassroom } from '@/api/lms/classrooms'
import type { ManagementSubject } from '@/api/lms/subjects'
import type { Option } from '@/legacy/components/v3/selects/selectSimple'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { SetField } from '@/legacy/redux/types/generic'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'

export interface Organization {
  id: number
  name: string
}

export interface ThemeOptions {
  id: number
  label: string
  games: number[]
  exportEnabled: boolean
  grade?: number
}

export interface Classroom {
  id: number
  division: string
  grade: string
  organization: Organization
  organizationType: string
  shift: string
  year: number
}

export type AddEditExamModalState = {
  open: boolean
  loading: boolean
  error: string
  editMode: boolean
  id: string
  name: string
  classroomOptions: ClassroomsOption[]
  classroomId: number
  organizationId: number
  division: string
  grade: string
  shift: string
  cue: string
  neighborhood: string
  theme: number
  themes: ThemeOptions[]
  games: number[]
  datetime: string
  deadline: string
  duration: number
  type: number
  stage: number
}

export const THEME_CUSTOM = 12

interface SetModalInitialValuesPayload {
  exam: ManagementExam
  requestOrigins: ReduxRequestOrigin[]
}

// Clear fields
export const CLEAR_FIELDS = 'MANAGEMENT/EXAMS/ADD_EDIT_MODAL/CLEAR_FIELDS'
// Edit
export const SET_INITIAL_VALUES = 'MANAGEMENT/EXAMS/ADD_EDIT_MODAL/SET_INITIAL_VALUES'
// Get Organization
export const GET_ORGANIZATION_REQUEST = 'MANAGEMENT/EXAMS/ADD_EDIT_MODAL/GET_ORGANIZATION_REQUEST'
export const GET_ORGANIZATION_SUCCESS = 'MANAGEMENT/EXAMS/ADD_EDIT_MODAL/GET_ORGANIZATION_SUCCESS'
export const GET_ORGANIZATION_FAILURE = 'MANAGEMENT/EXAMS/ADD_EDIT_MODAL/GET_ORGANIZATION_FAILURE'

// Clear fields
export type ClearAllFields = GenericActionWithType<typeof CLEAR_FIELDS>
// Edit
export type SetInitialValues = GenericActionWithType<
  typeof SET_INITIAL_VALUES,
  SetModalInitialValuesPayload
>
// Get Organization
export type GetOrganizationRequest = GenericActionWithType<typeof GET_ORGANIZATION_REQUEST>
export type GetOrganizationSuccess = GenericActionWithType<
  typeof GET_ORGANIZATION_SUCCESS,
  { organization: ManagementOrganization }
>
export type GetOrganizationFailure = GenericActionWithType<
  typeof GET_ORGANIZATION_FAILURE,
  ErrorPayload
>
export type GetOrganizationAction =
  | GetOrganizationRequest
  | GetOrganizationSuccess
  | GetOrganizationFailure

export type AddEditExamModalAction =
  | SetField<
      'open' | 'organizationOptions' | 'classroomOptions' | 'subjects',
      boolean | Option[] | ManagementClassroom[] | ManagementSubject[]
    >
  | ClearAllFields
  | SetInitialValues
  | GetOrganizationAction
  | ListExamsAsAdminAction
  | CreateExamAsAdminAction
  | EditExamAsAdminAction
  | GetExamSubjectsAsMgmtAction
