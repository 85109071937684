import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  toolTip: ({ widthAuto }) => ({
    background: 'rgba(26, 26, 26, 0.9)',
    fontSize: 14,
    width: widthAuto ? 'auto' : 220,
    fontWeight: 'normal',
    padding: '8px 12px',
    textAlign: 'center',
    boxSizing: 'border-box',
    borderRadius: 10,
    lineHeight: '18px',
  }),
  arrow: {
    color: 'rgba(26, 26, 26, 0.9)',
  },
  icon: {
    verticalAlign: 'bottom',
  },
}))

const ConditionalTooltip = (props) => {
  const { withTooltip, title, placement, children, tooltipClassname, widthAuto } = props
  const classes = useStyles({ widthAuto })

  if (!withTooltip) return children

  return (
    <Tooltip
      title={title}
      classes={{ tooltip: clsx(classes.toolTip, tooltipClassname), arrow: classes.arrow }}
      placement={placement}
      arrow
    >
      <span>{children}</span>
    </Tooltip>
  )
}

ConditionalTooltip.defaultProps = {
  tooltipClassname: '',
  placement: 'top',
  withTooltip: false,
  widthAuto: false,
}

export default ConditionalTooltip
