import type { AxiosResponse } from 'axios'
import { apiPrivate } from '..'
import { url } from '@aula/config'
import type {
  CreateSubjectAsAdminParams,
  CreateSubjectAsPrincipalParams,
  DeleteSubjectAsPrincipalParams,
  EditSubjectAsAdminParams,
} from '@/legacy/redux/types/subjects'
import type { Params } from '@/sections/teacher/students/types/students'
import type { DescriptionResponseShortener, GenericParams } from '@/api/types'

export const SubjectsAPI = {
  DEPRECATED_getAsPrincipal,
  getAsPrincipal,
  createAsPrincipal,
  deleteAsPrincipal,
  getAsAdmin,
  editAsAdmin,
  createAsAdmin,
}

export type ManagementSubject = {
  color: string
  name: string
  id: number
}

/**
 * Gets subjects as principal.
 *
 * @role Principal
 * @param params
 * @param organizationID
 */
function DEPRECATED_getAsPrincipal(params: Params) {
  return apiPrivate.get(url + '/v1/principal/subjects', { params })
}

type GetAsPrincipalResponse = {
  rows: ManagementSubject[]
  total: number
}

interface GetAsPrincipalParams extends GenericParams {
  organizationId: number
}

function getAsPrincipal(
  params: GetAsPrincipalParams,
  getAll = false
): Promise<GetAsPrincipalResponse> {
  return apiPrivate
    .get(url + '/v1/principal/subjects', {
      params: {
        ...params,
        getAll,
      },
    })
    .then(
      (resp: AxiosResponse<DescriptionResponseShortener<GetAsPrincipalResponse>>) =>
        resp.data.description
    )
    .catch((err) => {
      throw err
    })
}

function createAsPrincipal(data: CreateSubjectAsPrincipalParams): Promise<GetAsAdminResponse> {
  const params = {
    organizationId: data.organizationId,
    subjectName: data.name,
    subjectColor: data.color,
  }

  return apiPrivate
    .post(url + '/v2/principal/subjects', params)
    .then(
      (resp: AxiosResponse<DescriptionResponseShortener<GetAsAdminResponse>>) =>
        resp.data.description
    )
    .catch((err) => {
      throw err
    })
}

function deleteAsPrincipal(data: DeleteSubjectAsPrincipalParams): Promise<GetAsAdminResponse> {
  return apiPrivate
    .delete(url + '/v1/principal/subjects', { data })
    .then(
      (resp: AxiosResponse<DescriptionResponseShortener<GetAsAdminResponse>>) =>
        resp.data.description
    )
    .catch((err) => {
      throw err
    })
}

/**
 * Gets subjects as administrator.
 *
 * @role Administrator
 * @param limit
 * @param offset
 * @param search
 */

type GetAsAdminResponse = {
  subjects: ManagementSubject[]
  rows: ManagementSubject[]
  total: number
}

function getAsAdmin(params: GenericParams): Promise<GetAsAdminResponse> {
  return apiPrivate
    .get(url + '/v1/admin/subjects', {
      params,
    })
    .then((resp: AxiosResponse<DescriptionResponseShortener<GetAsAdminResponse>>) => {
      return { ...resp.data.description, subjects: resp.data.description.rows }
    })
    .catch((err) => {
      throw err
    })
}

function createAsAdmin(params: CreateSubjectAsAdminParams): Promise<GetAsAdminResponse> {
  const { name: subjectName, color: subjectColor, ...alterParams } = params
  return apiPrivate
    .post(url + '/v2/admin/subjects', { ...alterParams, subjectName, subjectColor })
    .then((response: AxiosResponse<DescriptionResponseShortener<GetAsAdminResponse>>) => {
      return { ...response.data.description, subjects: response.data.description.rows }
    })
    .catch((err) => {
      throw err
    })
}

function editAsAdmin(
  params: EditSubjectAsAdminParams,
  subjectId: number
): Promise<GetAsAdminResponse> {
  const snakeParams = {
    ...params,
  }

  return apiPrivate
    .put(url + '/v2/admin/subjects/' + subjectId, snakeParams)
    .then((resp: AxiosResponse<DescriptionResponseShortener<GetAsAdminResponse>>) => {
      return { ...resp.data.description, subjects: resp.data.description.rows }
    })
    .catch((err) => {
      throw err
    })
}
