import type { ManagementSubject } from '@/api/lms/subjects'
import type {
  ErrorPayload,
  GenericAction,
  GenericActionWithType,
  GenericPagination,
  GenericPayload,
} from '@/legacy/redux/utils'

export interface Subject {
  id: number
  name: string
  color: string
}

// Role: PRINCIPAL
// Get
export const GET_SUBJECTS_AS_PRINCIPAL_REQUEST = 'SUBJECTS/GET_SUBJECTS_AS_PRINCIPAL_REQUEST'
export const GET_SUBJECTS_AS_PRINCIPAL_SUCCESS = 'SUBJECTS/GET_SUBJECTS_AS_PRINCIPAL_SUCCESS'
export const GET_SUBJECTS_AS_PRINCIPAL_FAILURE = 'SUBJECTS/GET_SUBJECTS_AS_PRINCIPAL_FAILURE'

interface GetSubjectsAsPrincipalRequestPayload extends GenericPayload {
  page: number
  rowsPerPage: number
}
interface GetSubjectsAsPrincipalSuccessPayload extends GenericPayload {
  subjects: Subject[]
  count: number
}

export interface GetSubjectsAsPrincipalRequest extends GenericAction {
  type: typeof GET_SUBJECTS_AS_PRINCIPAL_REQUEST
  payload: GetSubjectsAsPrincipalRequestPayload
}

export interface GetSubjectsAsPrincipalSuccess {
  type: typeof GET_SUBJECTS_AS_PRINCIPAL_SUCCESS
  payload: GetSubjectsAsPrincipalSuccessPayload
}

export interface GetSubjectsAsPrincipalFailure extends GenericAction {
  type: typeof GET_SUBJECTS_AS_PRINCIPAL_FAILURE
  payload: ErrorPayload
}

export type GetSubjectsAsPrincipalAction =
  | GetSubjectsAsPrincipalRequest
  | GetSubjectsAsPrincipalSuccess
  | GetSubjectsAsPrincipalFailure

// List
export const LIST_SUBJECTS_AS_PRINCIPAL_REQUEST = 'SUBJECTS/LIST_SUBJECTS_AS_PRINCIPAL_REQUEST'
export const LIST_SUBJECTS_AS_PRINCIPAL_SUCCESS = 'SUBJECTS/LIST_SUBJECTS_AS_PRINCIPAL_SUCCESS'
export const LIST_SUBJECTS_AS_PRINCIPAL_FAILURE = 'SUBJECTS/LIST_SUBJECTS_AS_PRINCIPAL_FAILURE'
// Edit
export const CREATE_SUBJECT_AS_PRINCIPAL_REQUEST = 'SUBJECTS/CREATE_SUBJECT_AS_PRINCIPAL_REQUEST'
export const CREATE_SUBJECT_AS_PRINCIPAL_SUCCESS = 'SUBJECTS/CREATE_SUBJECT_AS_PRINCIPAL_SUCCESS'
export const CREATE_SUBJECT_AS_PRINCIPAL_FAILURE = 'SUBJECTS/CREATE_SUBJECT_AS_PRINCIPAL_FAILURE'
// Delete
export const DELETE_SUBJECT_AS_PRINCIPAL_REQUEST = 'SUBJECTS/DELETE_SUBJECTS_AS_PRINCIPAL_REQUEST'
export const DELETE_SUBJECT_AS_PRINCIPAL_SUCCESS = 'SUBJECTS/DELETE_SUBJECTS_AS_PRINCIPAL_SUCCESS'
export const DELETE_SUBJECT_AS_PRINCIPAL_FAILURE = 'SUBJECTS/DELETE_SUBJECTS_AS_PRINCIPAL_FAILURE'

// List
export type ListSubjectsAsPrincipalRequest = GenericActionWithType<
  typeof LIST_SUBJECTS_AS_PRINCIPAL_REQUEST,
  GetSubjectsAsPrincipalRequestPayload
>
export type ListSubjectsAsPrincipalSuccess = GenericActionWithType<
  typeof LIST_SUBJECTS_AS_PRINCIPAL_SUCCESS,
  { subjects: ManagementSubject[]; count: number }
>
export type ListSubjectsAsPrincipalFailure = GenericActionWithType<
  typeof LIST_SUBJECTS_AS_PRINCIPAL_FAILURE,
  ErrorPayload
>
export type ListSubjectsAsPrincipalAction =
  | ListSubjectsAsPrincipalRequest
  | ListSubjectsAsPrincipalSuccess
  | ListSubjectsAsPrincipalFailure

// Create
export interface CreateSubjectAsPrincipalParams {
  name: string
  organizationId: number
  color: string
}

export type CreateSubjectsAsPrincipalRequest = GenericActionWithType<
  typeof CREATE_SUBJECT_AS_PRINCIPAL_REQUEST,
  CreateSubjectAsPrincipalParams
>

export interface CreateSubjectsAsPrincipalSuccess {
  type: typeof CREATE_SUBJECT_AS_PRINCIPAL_SUCCESS
}
export interface CreateSubjectsAsPrincipalFailure extends GenericAction {
  type: typeof CREATE_SUBJECT_AS_PRINCIPAL_FAILURE
  payload: ErrorPayload
}
export type CreateSubjectAsPrincipalAction =
  | CreateSubjectsAsPrincipalRequest
  | CreateSubjectsAsPrincipalSuccess
  | CreateSubjectsAsPrincipalFailure

// Delete
export interface DeleteSubjectAsPrincipalParams {
  organizationId: number
  subjectId: number
}

export type DeleteSubjectAsPrincipalRequest = GenericActionWithType<
  typeof DELETE_SUBJECT_AS_PRINCIPAL_REQUEST
>
export type DeleteSubjectAsPrincipalSuccess = GenericActionWithType<
  typeof DELETE_SUBJECT_AS_PRINCIPAL_SUCCESS
>
export type DeleteSubjectAsPrincipalFailure = GenericActionWithType<
  typeof DELETE_SUBJECT_AS_PRINCIPAL_FAILURE,
  ErrorPayload
>
export type DeleteSubjectAsPrincipalAction =
  | DeleteSubjectAsPrincipalRequest
  | DeleteSubjectAsPrincipalSuccess
  | DeleteSubjectAsPrincipalFailure

export type PrincipalSubjectsActions =
  | ListSubjectsAsPrincipalAction
  | CreateSubjectAsPrincipalAction
  | DeleteSubjectAsPrincipalAction

// Role: TEACHER
export const GET_SUBJECTS_AS_TEACHER_REQUEST = 'SUBJECTS/GET_SUBJECTS_AS_TEACHER_REQUEST'
export const GET_SUBJECTS_AS_TEACHER_SUCCESS = 'SUBJECTS/GET_SUBJECTS_AS_TEACHER_SUCCESS'
export const GET_SUBJECTS_AS_TEACHER_FAILURE = 'SUBJECTS/GET_SUBJECTS_AS_TEACHER_FAILURE'

interface GetSubjectsAsTeacherRequestPayload extends GenericPayload {
  fetchAll: boolean
}
export interface GetSubjectsAsTeacherRequest {
  type: typeof GET_SUBJECTS_AS_TEACHER_REQUEST
  payload: GetSubjectsAsTeacherRequestPayload
}

interface GetSubjectsAsTeacherSuccessPayload extends GenericPayload {
  subjects: Subject[]
  fetchAll: boolean
}

export interface GetSubjectsAsTeacherSuccess {
  type: typeof GET_SUBJECTS_AS_TEACHER_SUCCESS
  payload: GetSubjectsAsTeacherSuccessPayload
}

interface GetSubjectsAsTeacherFailurePayload extends ErrorPayload {
  fetchAll: boolean
}

export interface GetSubjectsAsTeacherFailure {
  type: typeof GET_SUBJECTS_AS_TEACHER_FAILURE
  payload: GetSubjectsAsTeacherFailurePayload
}

export type GetSubjectsAsTeacherAction =
  | GetSubjectsAsTeacherRequest
  | GetSubjectsAsTeacherSuccess
  | GetSubjectsAsTeacherFailure

// Role: ADMIN
export const LIST_SUBJECTS_AS_ADMIN_REQUEST = 'SUBJECTS/LIST_SUBJECTS_AS_ADMIN_REQUEST'
export const LIST_SUBJECTS_AS_ADMIN_SUCCESS = 'SUBJECTS/LIST_SUBJECTS_AS_ADMIN_SUCCESS'
export const LIST_SUBJECTS_AS_ADMIN_FAILURE = 'SUBJECTS/LIST_SUBJECTS_AS_ADMIN_FAILURE'
// Create
export const CREATE_SUBJECT_AS_ADMIN_REQUEST = 'SUBJECTS/CREATE_SUBJECT_AS_ADMIN_REQUEST'
export const CREATE_SUBJECT_AS_ADMIN_SUCCESS = 'SUBJECTS/CREATE_SUBJECT_AS_ADMIN_SUCCESS'
export const CREATE_SUBJECT_AS_ADMIN_FAILURE = 'SUBJECTS/CREATE_SUBJECT_AS_ADMIN_FAILURE'
// Edit
export const EDIT_SUBJECT_AS_ADMIN_REQUEST = 'SUBJECTS/EDIT_SUBJECT_AS_ADMIN_REQUEST'
export const EDIT_SUBJECT_AS_ADMIN_SUCCESS = 'SUBJECTS/EDIT_SUBJECT_AS_ADMIN_SUCCESS'
export const EDIT_SUBJECT_AS_ADMIN_FAILURE = 'SUBJECTS/EDIT_SUBJECT_AS_ADMIN_FAILURE'

export interface CreateSubjectAsAdminParams {
  name: string
  color: string
  organizationId: number
}
export interface EditSubjectAsAdminParams {
  name: string
  color: string
  subjectId: number
}

// Get
export type ListSubjectsAsAdminRequest = GenericActionWithType<
  typeof LIST_SUBJECTS_AS_ADMIN_REQUEST,
  GenericPagination
>
export type ListSubjectsAsAdminSuccess = GenericActionWithType<
  typeof LIST_SUBJECTS_AS_ADMIN_SUCCESS,
  { subjects: ManagementSubject[]; count: number }
>
export type ListSubjectsAsAdminFailure = GenericActionWithType<
  typeof LIST_SUBJECTS_AS_ADMIN_FAILURE,
  ErrorPayload
>
export type ListSubjectsAsAdminAction =
  | ListSubjectsAsAdminRequest
  | ListSubjectsAsAdminSuccess
  | ListSubjectsAsAdminFailure

// Create
export type CreateSubjectAsAdminRequest = GenericActionWithType<
  typeof CREATE_SUBJECT_AS_ADMIN_REQUEST,
  CreateSubjectAsAdminParams
>
export type CreateSubjectAsAdminSuccess = GenericActionWithType<
  typeof CREATE_SUBJECT_AS_ADMIN_SUCCESS
>
export type CreateSubjectAsAdminFailure = GenericActionWithType<
  typeof CREATE_SUBJECT_AS_ADMIN_FAILURE,
  ErrorPayload
>
export type CreateSubjectAsAdminAction =
  | CreateSubjectAsAdminRequest
  | CreateSubjectAsAdminSuccess
  | CreateSubjectAsAdminFailure
// Edit
export type EditSubjectAsAdminRequest = GenericActionWithType<
  typeof EDIT_SUBJECT_AS_ADMIN_REQUEST,
  EditSubjectAsAdminParams
>
export type EditSubjectAsAdminSuccess = GenericActionWithType<typeof EDIT_SUBJECT_AS_ADMIN_SUCCESS>
export type EditSubjectAsAdminFailure = GenericActionWithType<
  typeof EDIT_SUBJECT_AS_ADMIN_FAILURE,
  ErrorPayload
>

export type EditSubjectAsAdminAction =
  | EditSubjectAsAdminRequest
  | EditSubjectAsAdminSuccess
  | EditSubjectAsAdminFailure

export type AdminSubjectsActions =
  | ListSubjectsAsAdminAction
  | CreateSubjectAsAdminAction
  | EditSubjectAsAdminAction
