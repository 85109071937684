import { combineReducers } from 'redux'
import organizationsDrawer from './reducers/organizationsDrawer'
import classroomsDrawer from './reducers/classroomsDrawer'
import subjectsDrawer from './reducers/subjectsDrawer'
import examsDrawer from './reducers/examsDrawer'
import audience from './reducers/audience'
import addEditAudienceModalState from './reducers/addEditAudienceModal'
import audienceDetailsModal from './reducers/audienceDetailsModal'
import deleteAudienceModal from './reducers/deleteAudienceModal'

const managementReducer = combineReducers({
  organizationsDrawer,
  classroomsDrawer,
  subjectsDrawer,
  examsDrawer,
  audience,
  addEditAudienceModalState,
  audienceDetailsModal,
  deleteAudienceModal,
})

export default managementReducer
