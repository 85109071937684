import * as React from 'react'
import type { SVGProps } from 'react'
const SvgAvv = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="avv-svg"
    {...props}
  >
    <rect opacity={0.15} width={56} height={56} rx={6} fill="#F45C3A" />
    <path
      d="M45.766 30.876c1.588-9.812-5.078-19.054-14.89-20.642-9.812-1.588-19.054 5.078-20.642 14.89-1.588 9.812 5.078 19.054 14.89 20.642 9.812 1.588 19.054-5.078 20.642-14.89Z"
      fill="#F45C3A"
    />
    <path
      d="M26.4 20.8c-.989-.742-2.4-.036-2.4 1.2v12c0 1.236 1.411 1.942 2.4 1.2l8-6c.8-.6.8-1.8 0-2.4l-8-6Z"
      fill="#fff"
    />
  </svg>
)
export default SvgAvv
