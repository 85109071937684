import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type { GenericActionWithType } from '@/legacy/redux/utils'
import type { Option } from '@/legacy/components/v3/selects/selectSimple'

export type StageSelectorModalState = {
  open: boolean
  stageOptions: Option[]
  allSelected: boolean
}

export const REMOVE_STAGE = 'MANAGEMENT/STAGE_SELECTOR_MODAL/REMOVE_STAGE'
export type RemoveStage = GenericActionWithType<typeof REMOVE_STAGE, { stage: Option }>

export const ADD_STAGE = 'MANAGEMENT/STAGE_SELECTOR_MODAL/ADD_STAGE'
export type AddStage = GenericActionWithType<typeof ADD_STAGE, { stage: Option }>

export const SET_OPEN = 'MANAGEMENT/STAGE_SELECTOR_MODAL/SET_OPEN'
export type SetOpen = GenericActionWithType<typeof SET_OPEN, {}>

export type StageSelectorModalAction =
  | RemoveStage
  | AddStage
  | SetOpen
  | ClearState
  | SetField<'allSelected', boolean>
