import type { PremiumActivity } from '@/api/lms/library'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

// State.
export interface ActivitiesState {
  activities: SelectedActivity[]
  modal: {
    isOpenModal: boolean
    status: REQUEST_STATUS
    activities: PremiumActivity[]
    selectedActivities: SelectedActivity[]
    searchText: string
    pagination: {
      total: number
      limit: number
      offset: number
    }
  }
}

// Types.
export type SelectedActivity = Pick<PremiumActivity, 'id' | 'title' | 'type'>

export enum ACTIVITIES_TYPES {
  OPEN_MODAL = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/FIRST/MODAL/OPEN_MODAL',
  CLOSE_MODAL = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/FIRST/MODAL/CLOSE_MODAL',
  GET_ACTIVITIES_REQUEST = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/FIRST/MODAL/GET_ACTIVITIES_REQUEST',
  GET_ACTIVITIES_SUCCESS = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/FIRST/MODAL/GET_ACTIVITIES_SUCCESS',
  GET_ACTIVITIES_FAILURE = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/FIRST/MODAL/GET_ACTIVITIES_FAILURE',
  SET_ACTIVITIES = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/FIRST/ACTIVITIES/SET_ACTIVITIES',
  REMOVE_ACTIVITY = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/FIRST/ACTIVITIES/REMOVE_ACTIVITY',
  SORT_ACTIVITIES = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/FIRST/ACTIVITIES/SORT_ACTIVITIES',
  CLEAN_MODAL = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/FIRST/MODAL/CLEAN_MODAL',
  SET_PAGINATION_OFFSET = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/FIRST/MODAL/SET_PAGINATION_OFFSET',
  SET_SEARCH_TEXT = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/FIRST/MODAL/SET_SEARCH_TEXT',
  SET_SELECTED_ACTIVITY = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/FIRST/MODAL/SET_SELECTED_ACTIVITY',
  REMOVE_SELECTED_ACTIVITY = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/FIRST/MODAL/REMOVE_SELECTED_ACTIVITY',
  CLEAN_STEP = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/FIRST/MODAL/CLEAN_STEP',
}

// Open modal action.
export type OpenModalAction = {
  type: ACTIVITIES_TYPES.OPEN_MODAL
}

// Close modal action.
export type CloseModalAction = {
  type: ACTIVITIES_TYPES.CLOSE_MODAL
}

// Get activities actions.
export type GetActivitiesRequestAction = {
  type: ACTIVITIES_TYPES.GET_ACTIVITIES_REQUEST
}

export type GetActivitiesSuccessAction = {
  type: ACTIVITIES_TYPES.GET_ACTIVITIES_SUCCESS
  payload: {
    activities: PremiumActivity[]
    total: number
  }
}

export type GetActivitiesFailureAction = {
  type: ACTIVITIES_TYPES.GET_ACTIVITIES_FAILURE
}

export type GetActivitiesAction =
  | GetActivitiesRequestAction
  | GetActivitiesSuccessAction
  | GetActivitiesFailureAction

// Set activities action.
export type SetActivitiesAction = {
  type: ACTIVITIES_TYPES.SET_ACTIVITIES
  payload: {
    activities: SelectedActivity[]
  }
}

export type RemoveActivityAction = {
  type: ACTIVITIES_TYPES.REMOVE_ACTIVITY
  payload: {
    idToRemove: number
  }
}

export type SortActivitiesAction = {
  type: ACTIVITIES_TYPES.SORT_ACTIVITIES
  payload: {
    newSortedActivities: SelectedActivity[]
  }
}

// Clean modal action.
export type CleanModalAction = {
  type: ACTIVITIES_TYPES.CLEAN_MODAL
}

// Set pagination action.
export type SetPaginationOffsetAction = {
  type: ACTIVITIES_TYPES.SET_PAGINATION_OFFSET
  payload: {
    offset: number
  }
}

// Set search text action.
export type SetSearchTextAction = {
  type: ACTIVITIES_TYPES.SET_SEARCH_TEXT
  payload: {
    newValue: string
  }
}

// Set selected activity action.
export type SetSelectedActivityAction = {
  type: ACTIVITIES_TYPES.SET_SELECTED_ACTIVITY
  payload: {
    newActivity: SelectedActivity
  }
}

// Remove selected activity action.
export type RemoveSelectedActivityAction = {
  type: ACTIVITIES_TYPES.REMOVE_SELECTED_ACTIVITY
  payload: {
    idToRemove: number
  }
}

// Clean first step action.
export type CleanFirstStepAction = {
  type: ACTIVITIES_TYPES.CLEAN_STEP
}

export type ActivitiesAction =
  | OpenModalAction
  | CloseModalAction
  | GetActivitiesAction
  | SetActivitiesAction
  | RemoveActivityAction
  | SortActivitiesAction
  | CleanModalAction
  | SetPaginationOffsetAction
  | SetSearchTextAction
  | SetSelectedActivityAction
  | RemoveSelectedActivityAction
  | CleanFirstStepAction
