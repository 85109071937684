import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import type { VideosModalActions, VideosModalState } from '@/sections/ted/types/videoModals'
import {
  OPEN_ATTACH_VIDEO_MODAL,
  CLOSE_ATTACH_VIDEO_MODAL,
  OPEN_EDIT_VIDEO_MODAL,
  CLOSE_EDIT_VIDEO_MODAL,
  OPEN_DELETE_MODAL,
  CLOSE_DELETE_MODAL,
  OPEN_REVIEW_VIDEO_MODAL,
  CLOSE_REVIEW_VIDEO_MODAL,
} from '@/sections/ted/types/videoModals'

const initialState: VideosModalState = {
  videoId: 0,
  openAttach: false,
  openReview: false,
  openDelete: false,
  openEdit: false,
}

const requestDestination = reduxRequestOriginMap.TED_VIDEOS

const TedVideosReducer: Reducer<VideosModalState, VideosModalActions> = (
  state = initialState,
  action
): VideosModalState => {
  switch (action.type) {
    case OPEN_REVIEW_VIDEO_MODAL: {
      const { open, id } = action.payload
      return { ...state, openReview: open, videoId: id }
    }
    case CLOSE_REVIEW_VIDEO_MODAL: {
      const { open } = action.payload
      return { ...state, openReview: open }
    }
    case OPEN_ATTACH_VIDEO_MODAL: {
      const { open } = action.payload
      return { ...state, openAttach: open }
    }
    case CLOSE_ATTACH_VIDEO_MODAL: {
      const { open } = action.payload
      return { ...state, openAttach: open }
    }
    case OPEN_EDIT_VIDEO_MODAL: {
      const { open, id } = action.payload
      return { ...state, openEdit: open, videoId: id }
    }
    case CLOSE_EDIT_VIDEO_MODAL: {
      const { open } = action.payload
      return { ...state, openEdit: open }
    }
    case OPEN_DELETE_MODAL: {
      const { open, id } = action.payload
      return { ...state, openDelete: open, videoId: id }
    }
    case CLOSE_DELETE_MODAL: {
      const { open } = action.payload
      return { ...state, openDelete: open }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, TedVideosReducer, requestDestination)
