import types from '@/sections/teacher/dashboard/types'
import { ALLOW_RESUBMISSION_SUCCESS } from '@/sections/teacher/studentList/types/studentList'

const initialState = {
  loading: false,
  open: false,
  activity: {},
  downloading: 0,
  libraryMode: false,
  hideAssignment: false,
  gradedMode: false,
  gradingPerAnswer: false,
  robotsVisual: null,
  collabMiniatureStarted: false,
  withLicenses: false,
  error: null,
  isActivityDeleted: false,
}

function root(state = initialState, action) {
  switch (action.type) {
    case ALLOW_RESUBMISSION_SUCCESS:
    case types.CLOSE_MODAL:
      return initialState
    case types.STUDENT_ACTIVITY_REQUEST: {
      const { notOpen } = action.payload
      return { ...state, loading: true, open: !notOpen, error: null, isActivityDeleted: false }
    }
    case types.LIBRARY_ACTIVITY_REQUEST:
    case types.ACTIVITY_REQUEST:
      return { ...state, loading: true, open: true, error: '', isActivityDeleted: false }
    case types.STUDENT_ACTIVITY_SUCCESS: {
      const { activity } = action.payload
      const isExes = activity.type === 'exes'
      const lastSubmission = activity.lastSubmission.final
      return {
        ...state,
        loading: false,
        activity,
        gradedMode: !(isExes && !lastSubmission),
        gradingPerAnswer: isExes && lastSubmission,
        libraryMode: false,
        hideAssignment: false,
      }
    }
    case types.ACTIVITY_SUCCESS: {
      const { activity } = action.payload
      return {
        ...state,
        loading: false,
        activity,
        gradedMode: false,
        libraryMode: false,
        hideAssignment: false,
      }
    }
    case types.LIBRARY_ACTIVITY_SUCCESS: {
      const { activity, hideAssignment, withLicenses } = action.payload
      return {
        ...state,
        loading: false,
        activity,
        gradedMode: false,
        libraryMode: true,
        hideAssignment,
        withLicenses,
      }
    }
    case types.LIBRARY_ACTIVITY_FAILURE:
    case types.STUDENT_ACTIVITY_FAILURE: {
      const error = action.payload.error
      return {
        ...state,
        loading: false,
        isActivityDeleted:
          error.response?.status === 404 || error === 'Actividad no encontrada' ? true : false,
        error: action.payload.error,
      }
    }
    case types.ACTIVITY_FAILURE: {
      const { error, isDeleted } = action.payload
      return {
        ...state,
        loading: false,
        isActivityDeleted: isDeleted,
        error,
      }
    }
    case types.DOWNLOAD_FILE_REQUEST:
      return { ...state, downloading: action.payload.id, error: '' }
    case types.DOWNLOAD_FILE_SUCCESS:
      return { ...state, downloading: 0 }
    case types.DOWNLOAD_FILE_FAILURE:
      return { ...state, downloading: 0, error: action.payload.error }
    case types.ROBOTS_STUDENT_PROGRESS_REQUEST:
      return { ...state, loading: true, robotsVisual: null, error: null }
    case types.ROBOTS_STUDENT_PROGRESS_SUCCESS:
      return { ...state, loading: false, robotsVisual: action.payload.visual, error: null }
    case types.ROBOTS_STUDENT_PROGRESS_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case types.SET_COLLAB_MINIATURE_STARTED:
      return { ...state, collabMiniatureStarted: action.payload.started }
    default:
      return state
  }
}

export default root
