import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type { Reducer } from 'redux'
import * as Types from '@/sections/report-card/grading/types/studentDrawer'
import { Redux } from '@/legacy/redux'
import { LIST_CLASSROOM_STUDENTS_SUCCESS } from '@/legacy/redux/types/students'

const initialState: Types.ReportCardStudentDrawerState = {
  open: false,
  loadingEvaluations: false,
  evaluations: [],
  subject: null,
  scoreCategory: null,
  evaluationsPagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0,
  },
  error: '',
  selectedPeriod: null,
  student: null,
  editMode: false,
  scoresPeriods: [],
  sugesstedScore: '' || 0,
  sugesstedScoreId: 0,
  sendFinalMark: false,
  isNextStudent: false,
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_GRADING_STUDENT_DRAWER

const ReportCardGradingStudentDrawer: Reducer<
  Types.ReportCardStudentDrawerState,
  Types.ReportCardStudentDrawerAction
> = (state = initialState, action): Types.ReportCardStudentDrawerState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case Types.GET_STUDENT_EVALUATIONS_REQUEST:
      return {
        ...state,
        loadingEvaluations: true,
        error: initialState.error,
        evaluationsPagination: {
          ...state.evaluationsPagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    case Types.GET_CONCEPTUAL_STUDENT_EVALUATIONS_SUCCESS:
      return {
        ...state,
        loadingEvaluations: false,
        evaluations: action.payload.evaluations,
        evaluationsPagination: {
          ...state.evaluationsPagination,
          count: action.payload.count,
        },
        sugesstedScore: action.payload.suggestedScore,
        sugesstedScoreId: action.payload.suggestedScoreId,
        scoresPeriods: action.payload.scoresPeriods,
        sendFinalMark: action.payload.sendFinalMark,
      }
    case Types.GET_NUMERICAL_STUDENT_EVALUATIONS_SUCCESS:
      return {
        ...state,
        loadingEvaluations: false,
        evaluations: action.payload.evaluations,
        evaluationsPagination: {
          ...state.evaluationsPagination,
          count: action.payload.count,
        },
        sugesstedScore: action.payload.suggestedScore,
        scoresPeriods: action.payload.scoresPeriods,
        sendFinalMark: action.payload.sendFinalMark,
      }
    case Types.GET_STUDENT_EVALUATIONS_FAILURE:
      return {
        ...state,
        loadingEvaluations: false,
        error: action.payload.error,
      }
    case Types.SET_STUDENT_OPEN: {
      const {
        selectedPeriod,
        student,
        subject,
        scoreCategory,
        editMode,
        scoresPeriods,
        sugesstedScore,
        sugesstedScoreId,
        sendFinalMark,
      } = action.payload

      return {
        ...state,
        open: true,
        selectedPeriod,
        student,
        subject,
        scoreCategory,
        editMode,
        scoresPeriods,
        sugesstedScore,
        sugesstedScoreId,
        sendFinalMark,
      }
    }
    case Types.SET_IS_NEXT_STUDENT: {
      return {
        ...state,
        isNextStudent: action.payload.isNext,
      }
    }
    case Types.SET_NEW_STUDENT: {
      return {
        ...state,
        student: action.payload.student,
      }
    }
    case LIST_CLASSROOM_STUDENTS_SUCCESS: {
      return {
        ...state,
        student: action.payload.students[action.payload.nextStudentIndex] ?? state.student,
      }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, ReportCardGradingStudentDrawer, requestDestination)
