import type { ErrorPayload, GenericPayload } from '@/legacy/redux/utils'

// GET_COLLAB_TOKEN
export const GET_COLLAB_TOKEN_REQUEST = 'COLLAB_MINIATURE/GET_COLLAB_TOKEN_REQUEST'
export const GET_COLLAB_TOKEN_SUCCESS = 'COLLAB_MINIATURE/GET_COLLAB_TOKEN_SUCCESS'
export const GET_COLLAB_TOKEN_FAILURE = 'COLLAB_MINIATURE/GET_COLLAB_TOKEN_FAILURE'

interface GetCollabTokenSuccessPayload extends GenericPayload {
  activityId: number
}

export interface GetCollabTokenRequest {
  type: typeof GET_COLLAB_TOKEN_REQUEST
  payload: GenericPayload
}

interface GetCollabTokenSuccessPayload extends GenericPayload {
  token: string
  activityId: number
}

export interface GetCollabTokenSuccess {
  type: typeof GET_COLLAB_TOKEN_SUCCESS
  payload: GetCollabTokenSuccessPayload
}

export interface GetCollabTokenFailure {
  type: typeof GET_COLLAB_TOKEN_FAILURE
  payload: ErrorPayload
}

export type GetCollabTokenAction =
  | GetCollabTokenRequest
  | GetCollabTokenSuccess
  | GetCollabTokenFailure
