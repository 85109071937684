import * as Types from '@/sections/principal/forms/generateFormsModal/types/generateFormsModal'
import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import { FIELD_TYPE } from '@/sections/principal/forms/generateFormsModal/types'

const initialState: Types.GenerateFormsModalState = {
  isOpenModal: false,
  status: REQUEST_STATUS.IDLE,
  currentStep: Types.GENERATE_FORMS_STEPS.INITIAL,
  error: null,
  selectedFormId: 0,
  isSubmitting: false,
  hasAnswers: { value: false, status: REQUEST_STATUS.IDLE, error: '' },
  isEditMode: false,
  form: {
    description: '',
    sections: [],
    opensAt: '',
    closesAt: '',
    name: '',
    organization: {
      emblem: null,
      id: 0,
      name: '',
    },
    templateId: 0,
  },
  template: {
    isTemplateListLoading: false,
    isTemplateContentLoading: false,
    templateList: [],
    templateSelected: null,
  },
  customField: {
    createEditField: {
      text: '',
      sectionName: '',
    },
    isEditField: false,
    selectedCustomFieldToEdit: null,
  },
}

const GenerateFormsModalReducer = (
  state: Types.GenerateFormsModalState = initialState,
  action: Types.GenerateFormsModalActions
): Types.GenerateFormsModalState => {
  switch (action.type) {
    case Types.GENERATE_FORMS_MODAL_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isOpenModal: true,
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.CLOSE_MODAL: {
      return initialState
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.SET_CURRENT_STEP: {
      const { newStep } = action.payload
      if (state.currentStep === Types.GENERATE_FORMS_STEPS.SECOND) {
        return {
          ...state,
          currentStep: newStep,
          customField: {
            ...state.customField,
            createEditField: {
              text: '',
              sectionName: '',
            },
          },
        }
      }
      return {
        ...state,
        currentStep: newStep,
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.SET_OPEN_DATE: {
      const { date } = action.payload
      return {
        ...state,
        form: {
          ...state.form,
          opensAt: date,
        },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.SET_CLOSE_DATE: {
      const { date } = action.payload
      return {
        ...state,
        form: {
          ...state.form,
          closesAt: date,
        },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.SET_ORGANIZATION_DATA: {
      const { organizationEmblem, organizationId, organizationName } = action.payload
      return {
        ...state,
        form: {
          ...state.form,
          organization: {
            id: organizationId,
            name: organizationName,
            emblem: organizationEmblem,
          },
        },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.SET_FORM_NAME: {
      const { name } = action.payload
      return {
        ...state,
        form: {
          ...state.form,
          name,
        },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.SET_FORM_DESCRIPTION: {
      const { description } = action.payload
      return {
        ...state,
        form: {
          ...state.form,
          description,
        },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.GET_TEMPLATE_CONTENT_REQUEST: {
      return {
        ...state,
        template: { ...state.template, isTemplateContentLoading: true },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.GET_TEMPLATE_CONTENT_SUCCESS: {
      const { content } = action.payload

      if (state.isEditMode && !state.hasAnswers.value) {
        const mergeSections = content.sections.map((section) => {
          const isSameSection = state.form.sections.find(
            (sec) => section.meta.name === sec.meta.name
          )
          const fieldsRemoved = section.fields.map((field) => ({ ...field, hide: true }))
          if (isSameSection) {
            const mergedFields = [
              ...isSameSection.fields,
              ...fieldsRemoved.filter(
                (f) => !isSameSection.fields.some((field) => field.meta.name === f.meta.name)
              ),
            ]
            return { ...section, fields: mergedFields }
          }
          return { ...section, fields: section.fields.map((field) => ({ ...field, hide: true })) }
        })
        return {
          ...state,
          template: { ...state.template, isTemplateContentLoading: false },
          form: { ...state.form, sections: mergeSections },
        }
      }

      if (!state.isEditMode) {
        return {
          ...state,
          template: { ...state.template, isTemplateContentLoading: false },
          form: {
            ...state.form,
            sections: content.sections.map((section) => {
              return {
                ...section,
                fields: section.fields.map((field) => {
                  return {
                    ...field,
                    hide: false,
                  }
                }),
              }
            }),
          },
        }
      }

      return { ...state, template: { ...state.template, isTemplateContentLoading: false } }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.HIDE_OR_SHOW_FIELD_VISIBILITY_BY_NAME: {
      const { sectionName, fieldName, hide } = action.payload

      const updatedContent = state.form.sections.map((section) => {
        if (section.meta.name === sectionName) {
          // Encuentra la sección por su nombre
          return {
            ...section,
            fields: section.fields.map((field) => {
              if (field.meta.name === fieldName) {
                return {
                  ...field,
                  hide,
                }
              }
              return field
            }),
          }
        }
        return section
      })

      return {
        ...state,
        form: {
          ...state.form,
          sections: updatedContent,
        },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.GET_TEMPLATE_CONTENT_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        error,
        template: { ...state.template, isTemplateContentLoading: false },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.SORT_DRAGGABLE_FIELDS: {
      const { sectionName, fields: sortedFields } = action.payload

      const updatedContent = state.form.sections.map((section) => {
        if (section.meta.name === sectionName) {
          return {
            ...section,
            fields: sortedFields,
          }
        }
        return section
      })

      return {
        ...state,
        form: {
          ...state.form,
          sections: updatedContent,
        },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.GET_TEMPLATE_INFO_REQUEST: {
      return {
        ...state,
        template: { ...state.template, isTemplateListLoading: true },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.GET_TEMPLATE_INFO_SUCCESS: {
      const templates = action.payload.templates
      return {
        ...state,
        template: {
          ...state.template,
          isTemplateListLoading: false,
          templateList: [...templates],
          templateSelected: templates[0],
        },
        form: { ...state.form, templateId: templates[0].id },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.GET_TEMPLATE_INFO_FAILURE: {
      return {
        ...state,
        template: { ...state.template, isTemplateListLoading: false },
        error: action.payload.error,
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.SET_SELECTED_TEMPLATE: {
      const { template } = action.payload
      return {
        ...state,
        template: { ...state.template, templateSelected: template },
        form: { ...state.form, templateId: template.id },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.CREATE_FORM_REQUEST: {
      return {
        ...state,
        isSubmitting: true,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.CREATE_FORM_SUCCESS: {
      return {
        ...state,
        isSubmitting: false,
        status: REQUEST_STATUS.SUCCESS,
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.CREATE_FORM_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        isSubmitting: false,
        status: REQUEST_STATUS.FAILURE,
        error,
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.SET_CUSTOM_FIELD_TEXT: {
      const { text } = action.payload
      return {
        ...state,
        customField: {
          ...state.customField,
          createEditField: {
            ...state.customField.createEditField,
            text,
          },
        },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.SET_SECTION_NAME_TO_CUSTOM_FIELD: {
      const { name } = action.payload
      return {
        ...state,
        customField: {
          ...state.customField,
          createEditField: {
            ...state.customField.createEditField,
            sectionName: name,
          },
        },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.CREATE_CUSTOM_FIELD: {
      const { field } = action.payload

      const updatedContent = state.form.sections.map((section) => {
        if (section.meta.name === field.sectionName) {
          return {
            ...section,
            fields: [
              ...section.fields,
              {
                label: '',
                answerRequired: false,
                inclusionRequired: false,
                meta: { name: field.text },
                options: {},
                type: FIELD_TYPE.PLAIN_TEXT,
                hide: false,
                isCustom: true,
                subfields: {},
              },
            ],
          }
        }
        return section
      })

      return {
        ...state,
        customField: {
          ...state.customField,
          createEditField: {
            sectionName: '',
            text: '',
          },
        },
        form: { ...state.form, sections: updatedContent },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.EDIT_CUSTOM_FIELD: {
      const fieldToEdit = state.customField.createEditField
      const fieldToUpdate = state.customField.selectedCustomFieldToEdit

      const updatedSections = state.form.sections.map((section) => {
        if (section.meta.name === fieldToEdit.sectionName) {
          const updatedFields = section.fields.map((field) => {
            if (field.meta.name === fieldToUpdate?.meta.name) {
              return {
                ...field,
                meta: {
                  ...field.meta,
                  name: fieldToEdit.text,
                },
              }
            }
            return field
          })

          return {
            ...section,
            fields: updatedFields,
          }
        }
        return section
      })
      return {
        ...state,
        form: { ...state.form, sections: updatedSections },
        customField: {
          isEditField: false,
          selectedCustomFieldToEdit: null,
          createEditField: {
            text: '',
            sectionName: '',
          },
        },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.SET_CUSTOM_FIELD_TO_EDIT: {
      const { fieldToEdit } = action.payload

      const fieldExists =
        state.form.sections
          .find((section) => section.meta.name === fieldToEdit.sectionName)
          ?.fields.find((field) => field.meta.name === fieldToEdit.text) ?? null

      return {
        ...state,
        customField: {
          isEditField: true,
          createEditField: { ...fieldToEdit },
          selectedCustomFieldToEdit: fieldExists,
        },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.REMOVE_CUSTOM_FIELD: {
      const { sectionName, fieldName } = action.payload
      const updatedContent = state.form.sections.map((section) => {
        if (section.meta.name === sectionName) {
          return {
            ...section,
            fields: section.fields.filter((field) => field.meta.name !== fieldName),
          }
        }
        return section
      })

      return {
        ...state,
        form: { ...state.form, sections: updatedContent },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.SET_FORM_TO_EDIT_REQUEST: {
      return { ...state, isOpenModal: true, isEditMode: true, status: REQUEST_STATUS.LOADING }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.SET_FORM_TO_EDIT_SUCCESS: {
      const { form, formId } = action.payload
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        form,
        selectedFormId: formId,
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.GET_ANSWERS_REQUEST:
      return { ...state, hasAnswers: { ...state.hasAnswers, status: REQUEST_STATUS.LOADING } }
    case Types.GENERATE_FORMS_MODAL_TYPES.GET_ANSWERS_SUCCESS: {
      const { hasAnswers: value } = action.payload
      return {
        ...state,
        hasAnswers: { ...state.hasAnswers, value, status: REQUEST_STATUS.SUCCESS },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.GET_ANSWERS_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        hasAnswers: { ...state.hasAnswers, error, status: REQUEST_STATUS.FAILURE },
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.SET_FORM_TO_EDIT_FAILURE: {
      const { error } = action.payload
      return { ...state, error, status: REQUEST_STATUS.FAILURE }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.CLEAR_CREATE_FORM_MODAL: {
      return initialState
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.UPDATE_FORM_REQUEST: {
      return { ...state, status: REQUEST_STATUS.LOADING }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.UPDATE_FORM_SUCCESS: {
      return {
        ...state,
        isSubmitting: false,
        status: REQUEST_STATUS.SUCCESS,
      }
    }
    case Types.GENERATE_FORMS_MODAL_TYPES.UPDATE_FORM_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        isSubmitting: false,
        status: REQUEST_STATUS.FAILURE,
        error,
      }
    }
    default:
      return state
  }
}
export default GenerateFormsModalReducer
