import types from '@/sections/header/types/messages'
import activitiesTeacherTypes from '@/sections/teacher/activities/types/activities'
import {
  CANCEL_HELP_REQUEST_FAILURE,
  CREATE_HELP_REQUEST_FAILURE,
} from '@/sections/student/writtenExam/types/writtenExam'

const initialState = {
  showToast: false,
  title: '',
  description: '',
  error: false,
  color: null,
  icon: null,
  withCloseButton: false,
  closeButtonFn: () => {},
  buttons: [],
  autoHideDuration: 3000,
  titleValues: {},
  descriptionValues: {},
}

function root(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_TOAST: {
      const {
        title,
        description,
        error,
        color,
        icon,
        withCloseButton,
        closeButtonFn,
        buttons,
        autoHideDuration,
        titleValues,
        descriptionValues,
      } = action.payload
      return {
        ...state,
        showToast: true,
        title,
        description,
        error,
        color,
        icon,
        withCloseButton,
        closeButtonFn,
        buttons,
        autoHideDuration,
        titleValues,
        descriptionValues,
      }
    }
    case CREATE_HELP_REQUEST_FAILURE:
    case CANCEL_HELP_REQUEST_FAILURE:
    case activitiesTeacherTypes.CHANGE_DATE_FAILURE:
      return {
        ...state,
        showToast: true,
        title: 'Error',
        description: action.payload.error,
        error: true,
      }
    case types.CLOSE:
      return { ...state, showToast: false }
    default:
      return state
  }
}

export default root
