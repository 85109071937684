import zh from '@/locales/translations/zh/zh.json'
import common from '@/locales/translations/zh/common.json'
import glossary from '@/locales/translations/zh/glossary.json'
import validation from '@/locales/translations/zh/validation.json'

export default {
  translation: zh,
  common: common,
  glossary: glossary,
  validation: validation,
}
