/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ROLE } from '@aula/config'
import { url, webBffUrl } from '@aula/config'
import type { AxiosResponse } from 'axios'
import moment from 'moment'
import { apiPrivate } from '..'
import type { IndividualAssignment } from '@/legacy/redux/types/assignments'
import { ManagementRole } from '@/legacy/roles'
import { removeNullOrEmptyParams } from '@/legacy/utils/common'
import type { DescriptionResponseShortener } from '@/api/types'
import type { Profile } from './organizations'
import type { STUDENT_RESTRICTION } from './students'

export const ManagementUsersAPI = {
  get,
  getOne,
  create,
  removeAsAdmin,
  assignUserPassword,
  editUserData,
  assignUser,
  unassignUser,
  unassignUserFromOrganization,
  assignStages,
  deleteUserProfile,
}

export enum ManagementGender {
  MALE = 'M',
  FEMALE = 'F',
  OTHER = '-',
}

export interface ManagementUser {
  id: number
  name: string
  lastName: string
  identification: UserId
  emailIdentification: UserEmailId
  gender: ManagementGender
  roles: string[]
  profiles: Profile[] | null
}

type UserId = {
  id: number
  typeId: number
  value: string
}

export type UserEmailId = {
  id: number
  typeId: number
  value: string
}

export type ApiAssignmentsList = {
  organizationId: number
  logo: string
  name: string
  roles: ROLE[]
  assignments: Omit<IndividualAssignment, 'assignmentId'>[]
  stageAssignments?: {
    stageId: number
    stage: string
    role: ROLE | string
  }[]
}

export interface ApiManagementUser extends ManagementUser {
  phone: string
  avatar: string
  dateOfBirth: string
  assignments: ApiAssignmentsList[]
}

export type SortBy = 'id' | 'name' | 'last_name'
export type SortDirection = 'desc' | 'asc'

// Get users
export interface GetUsersParams {
  size?: number
  page?: number
  search?: string
  organizationId?: number
  sort?: SortBy
  sortDir?: SortDirection
  role: string // TODO: use Role typing once we modify it (eb-admin instead of admin) see src/state/role
}

export type GetAsAdminUser = {
  id: number
  name: string
  lastName: string
  identification: UserId
  emailIdentification: UserEmailId
  gender: string
}

type Identification = {
  id: number
  typeId: number
  value: string
} | null

export interface UserWithProfile {
  avatar: string
  dateOfBirth: string
  emailIdentification: Identification
  gender: string
  id: number
  identification: Identification
  lastName: string
  name: string
  phone: string
  profiles: Profile[] | null
}

interface GetAsAdminResponse {
  list: UserWithProfile[]
  more: boolean
  failed: string[]
}

export interface ApiRelationshipAssignment {
  name: string
  lastName: string
  relation: 'parent'
  relatedToId: number
}

function get(params: GetUsersParams): Promise<GetAsAdminResponse> {
  return apiPrivate
    .get<{ description: GetAsAdminResponse }>(webBffUrl + '/v2/users', {
      params: removeNullOrEmptyParams(params),
    })
    .then((resp) => {
      return resp.data.description
    })
    .then((data: GetAsAdminResponse) => {
      data.list = data.list.map((u) => {
        u.dateOfBirth = moment.parseZone(u.dateOfBirth).local(true).toString()
        return u
      })
      return data
    })
    .catch((err) => {
      throw err
    })
}

// Get one user
export type ApiGetOneUser = {
  id: number
  name: string
  lastName: string
  identification: UserId | null
  emailIdentification: UserEmailId | null
  gender: string
  phone: string
  avatar: string
  ppi: boolean
  dateOfBirth: string | null
  assignments: ApiAssignmentsList[]
  relationshipAssignments: ApiRelationshipAssignment[]
  restrictions?: STUDENT_RESTRICTION[]
}
interface GetOneUserResponse {
  user: ApiGetOneUser
  failed: string[]
}

function getOne(
  id: number,
  role: ManagementRole,
  organizationId?: number
): Promise<GetOneUserResponse> {
  const orgId =
    role === ManagementRole.PRINCIPAL && organizationId ? `&organization_id=${organizationId}` : ''
  return apiPrivate
    .get(webBffUrl + `/v1/users/${id}?role=${role}` + orgId)
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return resp.data.description
    })
    .then((data) => {
      data.user.dateOfBirth = moment.parseZone(data.user.dateOfBirth).local(true).toString()
      return data
    })
    .catch((err) => {
      throw err
    })
}

export interface StageAssignment {
  stageId: number
  role: string // TODO: This should be Role type.
}

// Create user
export type CreateUserAssignmentsList = {
  organizationId: number
  logo: string
  assignments: {
    role: string // TODO: use Role typing once we modify it (eb-admin instead of admin) see src/state/role
    classroomId: number
    subjectId: number
  }[]
  profiles: number[]
  stageAssignments: StageAssignment[]
}

export interface RelationshipAssignment {
  name: string
  lastName: string
  relatedToId: number
  relation: string
}

export interface CreateUserParams {
  name: string
  lastName: string
  identification: { typeId: number; value: string } | null
  emailIdentification: { typeId: 12; value: string } | null
  dateOfBirth: string // moment.format()
  phone: string
  ppi: boolean
  gender: ManagementGender
  password: string
  passwordTypeId: number
  assignments: CreateUserAssignmentsList[]
  relationshipAssignments: RelationshipAssignment[]
}

export interface CreateUserResponse {
  user: {
    id: number
    name: string
    lastName: string
    identification: UserId
    emailIdentification: UserEmailId
    gender: string
    assignments: ApiAssignmentsList
  }
  failed: string[]
}

function create(body: CreateUserParams, role: ManagementRole): Promise<CreateUserResponse> {
  return apiPrivate
    .post(webBffUrl + `/v2/users?role=${role}`, body)
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

// Delete user
function removeAsAdmin(userId: number) {
  return apiPrivate.delete(webBffUrl + `/v1/users/${userId}?role=admin`).catch((err) => {
    throw err
  })
}

// Assign password
type AssignUserPasswordParams = {
  passwordTypeId: number
  password: string
  userId: number
  role: ROLE
  organizationId?: number // Admins don't need to pass this parameter
  classroomId?: number // Only teachers need to pass this parameter
}

function assignUserPassword({
  role,
  userId,
  password,
  classroomId,
  passwordTypeId,
  organizationId,
}: AssignUserPasswordParams): Promise<any> {
  const form = new FormData()
  form.append('password', password)
  form.append('password_type_id', passwordTypeId.toString())

  organizationId && form.append('organization_id', organizationId.toString())
  classroomId && form.append('classroom_id', classroomId.toString())

  return apiPrivate.post(url + `/v1/${role}/users/${userId}/password`, form)
}

// Edit user data
export type EditUserDataParams = {
  id: number
  name: string
  avatar: string
  lastName: string
  identification: UserId | null
  emailIdentification: UserEmailId | null
  dateOfBirth: string
  phone: string
  ppi: boolean
  gender: string
  organizationId: number
  restrictions: STUDENT_RESTRICTION[]
}

type EditUserDataResponse = {
  user: EditUserDataParams
  failed: string[]
}

function editUserData(
  userId: number,
  body: EditUserDataParams,
  userRole: ManagementRole
): Promise<any> {
  return apiPrivate
    .put(webBffUrl + `/v1/users/${userId}?role=${userRole}`, body)
    .then((resp: AxiosResponse<DescriptionResponseShortener<EditUserDataResponse>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

// Assign user to classroom or subject
export type AssignUserParams = {
  organizationId: number
  role: string
  classroomId: number | null
  subjectId: number | null
  userId: number
}

export type AssignUserResponse = {
  organizationId: number
  organizationName: string
  logo: string
  role: string
  classroomId: number
  classroomName: string
  subjectId: number
  subjectName: string
}

function assignUser(body: Partial<AssignUserParams>, userRole: ManagementRole): Promise<any> {
  return apiPrivate
    .put(webBffUrl + `/v1/assignments?role=${userRole}`, body)
    .then((resp: AxiosResponse<DescriptionResponseShortener<AssignUserResponse>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

export interface AssignStagesBody {
  organizationId: number
  assignments: {
    roleId: string
    stageId: number
  }[]
}

function assignStages(body: AssignStagesBody, userId: number, userRole: string): Promise<any> {
  return apiPrivate
    .put(url + `/v1/${userRole}/users/${userId}/stages`, body)
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

// Unassign user from classroom or subject
export type UnassignUserParams = {
  role: string
  classroomId: number
  subjectId: number | null
  userId: number
}

function unassignUser(data: UnassignUserParams, userRole: ManagementRole): Promise<void> {
  return apiPrivate
    .delete(webBffUrl + `/v1/assignments?role=${userRole}`, { data })
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

// Unassign user from organization
export type UnassignUserFromOrgParams = {
  profiles: number[]
  organizationId: number
  userId: number
}

export type UnassignUserFromOrgResponse = {
  failed: string[]
}

function unassignUserFromOrganization(
  data: UnassignUserFromOrgParams,
  userRole: ManagementRole
): Promise<UnassignUserFromOrgResponse> {
  return apiPrivate
    .delete(webBffUrl + `/v2/assignments-org?role=${userRole}`, { data })
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

interface DeleteUserProfileParams {
  managementRole: ManagementRole
  userId: number
  profileId: number
  organizationId: number
}

function deleteUserProfile(params: DeleteUserProfileParams): Promise<void> {
  const { managementRole, userId, profileId, organizationId } = params
  return apiPrivate.delete(url + `/v1/${managementRole}/user/${userId}/profiles/${profileId}`, {
    params: { organizationId },
  })
}
