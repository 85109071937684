import type { ClassroomAbscences } from '@/legacy/services/api/reportCard/attendances/types'

export interface AbscencesTabState {
  loading: boolean
  error: string
  studentsAbscences: {
    score: number
    studentId: number
  }[]
}

export const GET_ABSCENCES_REQUEST = 'REPORT_CARD/GRADING/TABS/ABSCENCES/GET_ABSCENCES_REQUEST'
export const GET_ABSCENCES_SUCCESS = 'REPORT_CARD/GRADING/TABS/ABSCENCES/GET_ABSCENCES_SUCCESS'
export const GET_ABSCENCES_FAILURE = 'REPORT_CARD/GRADING/TABS/ABSCENCES/GET_ABSCENCES_FAILURE'

export type GetAbscencesRequest = {
  type: typeof GET_ABSCENCES_REQUEST
}
export type GetAbscencesSuccess = {
  type: typeof GET_ABSCENCES_SUCCESS
  payload: { abscences: ClassroomAbscences }
}
export type GetAbscencesFailure = {
  type: typeof GET_ABSCENCES_FAILURE
  payload: { error: string }
}

export type AbscencesTabActions = GetAbscencesRequest | GetAbscencesSuccess | GetAbscencesFailure
