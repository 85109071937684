import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'

export interface BaseSubgroup {
  id: number
  name: string
  total: number
}

// Get classroom role
export const LIST_SUBGROUPS_AS_TEACHER_REQUEST = 'SUBGROUPS/LIST_SUBGROUPS_AS_TEACHER_REQUEST'
export const LIST_SUBGROUPS_AS_TEACHER_SUCCESS = 'SUBGROUPS/LIST_SUBGROUPS_AS_TEACHER_SUCCESS'
export const LIST_SUBGROUPS_AS_TEACHER_FAILURE = 'SUBGROUPS/LIST_SUBGROUPS_AS_TEACHER_FAILURE'

export type ListSubgroupsAsTeacherRequest = GenericActionWithType<
  typeof LIST_SUBGROUPS_AS_TEACHER_REQUEST
>
export type ListSubgroupsAsTeacherSuccess = GenericActionWithType<
  typeof LIST_SUBGROUPS_AS_TEACHER_SUCCESS,
  { subgroups: BaseSubgroup[] }
>
export type ListSubgroupsAsTeacherFailure = GenericActionWithType<
  typeof LIST_SUBGROUPS_AS_TEACHER_FAILURE,
  ErrorPayload
>

export type ListSubgroupsAsTeacherAction =
  | ListSubgroupsAsTeacherRequest
  | ListSubgroupsAsTeacherSuccess
  | ListSubgroupsAsTeacherFailure
