import * as React from 'react'
import type { SVGProps } from 'react'
const SvgSunday = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.616 1v3h10V1h2v3h2a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3h-18a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h2V1h2Zm12 5h-16a1 1 0 0 0-1 1v2h20V7a1 1 0 0 0-1-1h-2Zm-17 5v10a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V11h-20Z"
      fill="#1A1A1A"
    />
    <path
      d="M6.47 19.47v-6.3h2.15c.739 0 1.345.132 1.819.396.48.258.834.624 1.062 1.098.234.468.35 1.02.35 1.656 0 .636-.117 1.19-.35 1.665a2.468 2.468 0 0 1-1.063 1.098c-.473.258-1.08.387-1.817.387H6.47Zm1.152-.99h.945c.528 0 .945-.084 1.25-.252.306-.174.525-.42.658-.738.132-.324.197-.714.197-1.17 0-.45-.066-.837-.197-1.161a1.48 1.48 0 0 0-.657-.747c-.306-.174-.723-.261-1.251-.261h-.945v4.329Zm8.198 1.098c-.63 0-1.182-.138-1.656-.414a2.981 2.981 0 0 1-1.107-1.143c-.264-.492-.396-1.06-.396-1.701 0-.642.132-1.206.396-1.692.27-.492.639-.876 1.107-1.152.474-.276 1.026-.414 1.656-.414.624 0 1.173.138 1.647.414.474.276.843.66 1.107 1.152.264.486.396 1.05.396 1.692 0 .642-.132 1.209-.396 1.7a2.898 2.898 0 0 1-1.107 1.144c-.474.276-1.023.414-1.647.414Zm0-1.035c.6 0 1.077-.198 1.431-.594.36-.396.54-.94.54-1.63s-.18-1.232-.54-1.628c-.354-.396-.831-.594-1.431-.594-.6 0-1.08.198-1.44.594-.36.396-.54.939-.54 1.629 0 .69.18 1.233.54 1.629.36.396.84.594 1.44.594Z"
      fill="#000"
    />
  </svg>
)
export default SvgSunday
