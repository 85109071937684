import type { CancelTokenSource } from 'axios'
import type { FormikErrors } from 'formik'
import type { Message, MessageElement, MessageType, MessageFrom } from '@/api/bulletin/types'
import type { UploadFileData } from '@/api/files/types'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { GetContactsSuccess } from './contacts'
export interface MessageManagerState {
  error: Error | null
  loading: boolean
  mode: MESSAGE_MODE
  status: REQUEST_STATUS
  currentStep: number
  open: boolean
  isNested: boolean
  values: MessageValues
  cancelToken: CancelTokenSource | null
}

export enum MESSAGE_MANAGER_TYPES {
  SET_MESSAGES_MANAGER_NESTED = 'TEACHER/BULLETIN/MESSAGE_MANAGER/NESTED',
  OPEN_MESSAGES_MANAGER = 'TEACHER/BULLETIN/MESSAGE_MANAGER/OPEN',
  CLOSE_MESSAGES_MANAGAR = 'TEACHER/BULLETIN/MESSAGE_MANAGER/CLOSE',
  SET_FORM_VALUE = 'TEACHER/BULLETIN/MESSAGE_MANAGER/SET_FORM_VALUE',
  SET_FORM_VALUES = 'TEACHER/BULLETIN/MESSAGE_MANAGER/SET_FORM_VALUES',
  SET_VALUES = 'TEACHER/BULLETIN/MESSAGE_MANAGER/SET_VALUES',
  SET_CURRENT_STEP = 'TEACHER/BULLETIN/MESSAGE_MANAGER/SET_CURRENT_STEP',
  GO_TO_NEXT_STEP = 'TEACHER/BULLETIN/MESSAGE_MANAGER/GO_TO_NEXT_STEP',
  GO_TO_PREVIOUS_STEP = 'TEACHER/BULLETIN/MESSAGE_MANAGER/GO_TO_PREVIOUS_STEP',
  CLEAN = 'TEACHER/BULLETIN/MESSAGE_MANAGER/CLEAN',
  MESSAGE_EDIT = 'TEACHER/BULLETIN/MESSAGE_MANAGER/MESSAGE_EDIT',
  MESSAGE_CLONE = 'TEACHER/BULLETIN/MESSAGE_MANAGER/MESSAGE_CLONE',
  MESSAGE_CREATE = 'TEACHER/BULLETIN/MESSAGE_MANAGER/MESSAGE_CREATE',
  MESSAGE_CANCEL = 'TEACHER/BULLETIN/MESSAGE_MANAGER/MESSAGE_CANCEL',
  MESSAGE_CREATE_SUCCESS = 'TEACHER/BULLETIN/MESSAGE_MANAGER/MESSAGE_CREATE_SUCCESS',
  MESSAGE_CREATE_ENDORSEMENT_SUCCESS = 'TEACHER/BULLETIN/MESSAGE_MANAGER/MESSAGE_CREATE_ENDORSEMENT_SUCCESS',
  MESSAGE_EDIT_SUCCESS = 'TEACHER/BULLETIN/MESSAGE_MANAGER/MESSAGE_EDIT_SUCCESS',
  MESSAGE_EDIT_FAILURE = 'TEACHER/BULLETIN/MESSAGE_MANAGER/MESSAGE_EDIT_FAILURE',
  MESSAGE_CREATE_FAILURE = 'TEACHER/BULLETIN/MESSAGE_MANAGER/MESSAGE_CREATE_FAILURE',
  MESSAGE_SET_STATUS = 'TEACHER/BULLETIN/MESSAGE_MANAGER/MESSAGE_SET_STATUS',
}

export type AttachmentsOmitables =
  | 'ownerId'
  | 'public'
  | 'sizeKb'
  | 'updatedAt'
  | 'coverUrl'
  | 'createdAt'

export enum MESSAGE_MODE {
  CREATE = 'create',
  EDIT = 'edit',
  CLONE = 'clone',
  DELETE = 'delete',
  ENDORSEMENT = 'endorsement',
}

export type MessageTypeOption = {
  id: number
  color: string
  label: MessageType
}

export type MessageValue = {
  [key: string]: {
    color: string
    bgColor: string
    label: MessageType
  }
}

export type AddValueToForm = <T extends FormValues, K extends keyof T>(
  field: K,
  value: T[K]
) => Promise<void> | Promise<FormikErrors<FormValues>>

export type FormValues = {
  type: number
  title: string
  bulletinIds: number[]
}

export interface MessageValues extends FormValues {
  message: string
  allowsReply: boolean
  attachments: Omit<UploadFileData, AttachmentsOmitables>[]
  elements: MessageElement[]
  from: MessageFrom
}

export type OpenCreateMessageManager = {
  type: MESSAGE_MANAGER_TYPES.OPEN_MESSAGES_MANAGER
}

export type CloseCreateMessageManager = {
  type: MESSAGE_MANAGER_TYPES.CLOSE_MESSAGES_MANAGAR
}

export type SetNested = {
  type: MESSAGE_MANAGER_TYPES.SET_MESSAGES_MANAGER_NESTED
  payload: {
    isNested: boolean
  }
}

export type GoToNextStep = {
  type: MESSAGE_MANAGER_TYPES.GO_TO_NEXT_STEP
}

export type GoToPreviousStep = {
  type: MESSAGE_MANAGER_TYPES.GO_TO_PREVIOUS_STEP
}

export type SetFormValue = {
  type: MESSAGE_MANAGER_TYPES.SET_FORM_VALUE
  payload: {
    prop: keyof MessageValues
    value: MessageValues[keyof MessageValues]
  }
}
export type SetFormValues = {
  type: MESSAGE_MANAGER_TYPES.SET_FORM_VALUES
  payload: {
    values: FormValues
  }
}

export type SetValues = {
  type: MESSAGE_MANAGER_TYPES.SET_VALUES
  payload: {
    values: MessageValues
  }
}

export type SetCreateAnnoucementStep = {
  type: MESSAGE_MANAGER_TYPES.SET_CURRENT_STEP
  payload: {
    currentStep: number
  }
}

export type CreateMessage = {
  type: MESSAGE_MANAGER_TYPES.MESSAGE_CREATE
  payload: {
    token: CancelTokenSource
  }
}

export type EditMessage = {
  type: MESSAGE_MANAGER_TYPES.MESSAGE_EDIT
  payload: {
    values: MessageValues
    messageId: number
  }
}

export type CloneMessage = {
  type: MESSAGE_MANAGER_TYPES.MESSAGE_CLONE
  payload: {
    values: MessageValues
  }
}

export type CreateMessageCancel = {
  type: MESSAGE_MANAGER_TYPES.MESSAGE_CANCEL
}

export type CleanMessage = {
  type: MESSAGE_MANAGER_TYPES.CLEAN
}

export type EditMessageSuccess = {
  type: MESSAGE_MANAGER_TYPES.MESSAGE_EDIT_SUCCESS
  payload: {
    message: Message
  }
}

export type EditMessageFailure = {
  type: MESSAGE_MANAGER_TYPES.MESSAGE_EDIT_FAILURE
  payload: {
    error: Error
  }
}

export type CreateMessageFailure = {
  type: MESSAGE_MANAGER_TYPES.MESSAGE_CREATE_FAILURE
  payload: {
    error: Error
  }
}

export type CreateMessageSuccess = {
  type: MESSAGE_MANAGER_TYPES.MESSAGE_CREATE_SUCCESS
}

export type CreateEndorsementMessageSuccess = {
  type: MESSAGE_MANAGER_TYPES.MESSAGE_CREATE_ENDORSEMENT_SUCCESS
}

export type SetMessageStatus = {
  type: MESSAGE_MANAGER_TYPES.MESSAGE_SET_STATUS
  payload: {
    status: REQUEST_STATUS
  }
}

export type MessageManagerActions =
  | OpenCreateMessageManager
  | CloseCreateMessageManager
  | SetNested
  | GetContactsSuccess
  | GoToNextStep
  | GoToPreviousStep
  | SetFormValue
  | SetFormValues
  | SetValues
  | SetCreateAnnoucementStep
  | CreateMessageCancel
  | CleanMessage
  | CreateMessage
  | CreateMessageFailure
  | CreateMessageSuccess
  | CreateEndorsementMessageSuccess
  | EditMessage
  | EditMessageSuccess
  | EditMessageFailure
  | CloneMessage
  | SetMessageStatus
