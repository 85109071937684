import { formatClassroomName } from '@aula/tools/common'
import { Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'

import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { Option } from '@/legacy/components/v3/selects/selectSimple'
import type {
  ClassroomToMigrate,
  Migration,
} from '@/sections/principal/classroom-migration/types/migrationClassrooms'
import type { DynamicTableColumn } from '@/legacy/components/v3/dynamicTable'
import DynamicTable from '@/legacy/components/v3/dynamicTable'
import BaseChip from '@/legacy/components/v3/chips/baseChip'
import chroma from 'chroma-js'
import Button, { Variant } from '@/legacy/components/atomic/button/button'
import { REQUEST_STATUS, isFailure, isSuccess } from '@/legacy/redux/types/status'
import Anibot from '@/legacy/components/spinners/anibot'
import EmptyState from '@/legacy/components/v2/emptyState'
import { Cancel, CheckCircle } from '@mui/icons-material'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1280,
    margin: '24px auto',
    padding: 12,
  },
  stdCell: {
    fontFamily: theme.typography.fontFamily,
  },
  cursorNotPointer: {
    cursor: 'default',
  },
  studentsToMigrateText: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.secondary,
  },
  button: {
    width: 105,
    height: 36,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
  },
  messageStatus: {
    margin: '24px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 12,
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 20,
  },
  subtitle: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 16,
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  iconSize: {
    fontSize: 45,
  },
}))

export type VerifyStudentsStepProps = {
  classroomsToMigrate: ClassroomToMigrate[]
  newYearId: number
  academicPeriodsOptions: Option[]
  migrations: Migration[]
  verificationTableStatus: REQUEST_STATUS
  migrationCompletionStatus: REQUEST_STATUS
  openStudentsDrawer: () => void
  setSelectedMigration: (migration: Migration) => void
  getClassroomStudents: () => void
}

const VerifyStudentsStep = (props: VerifyStudentsStepProps): ReactElement => {
  const styles = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()

  useEffect(() => {
    props.getClassroomStudents()
  }, [])

  const columns: DynamicTableColumn[] = [
    {
      id: 'classroomDestiny',
      label: t('principal.classroomMigration.classroomDestiny'),
    },
    {
      id: 'students',
      label: t('principal.classroomMigration.studentToMigrate'),
    },
    ...(isSuccess(props.migrationCompletionStatus)
      ? []
      : [{ id: 'status', label: t('generic.status') }]),
    ...(isSuccess(props.migrationCompletionStatus)
      ? []
      : [
          {
            id: 'actions',
            label: '',
            maxWidth: 'min-content',
          },
        ]),
  ]

  const handleClick = (m: Migration) => {
    props.setSelectedMigration(m)
    props.openStudentsDrawer()
  }

  return (
    <div className={styles.container}>
      {props.verificationTableStatus === REQUEST_STATUS.LOADING && (
        <div>
          <Anibot size={70} />
        </div>
      )}
      {isSuccess(props.verificationTableStatus) && (
        <>
          {isFailure(props.migrationCompletionStatus) && (
            <div className={styles.messageStatus}>
              <Cancel className={clsx(styles.error, styles.iconSize)} />
              <Typography className={clsx(styles.title, styles.error)}>
                {t('principal.classroomMigration.migratedClassesError')}
              </Typography>
              <Typography className={styles.subtitle}>
                {t('principal.classroomMigration.migratedClassesFailure')}
              </Typography>
            </div>
          )}
          {isSuccess(props.migrationCompletionStatus) && (
            <div className={styles.messageStatus}>
              <CheckCircle className={clsx(styles.success, styles.iconSize)} />
              <Typography className={clsx(styles.title, styles.success)}>
                {t('principal.classroomMigration.migratedClasses')}
              </Typography>
              <Typography className={styles.subtitle}>
                {t('principal.classroomMigration.migratedClassesSuccess')}
              </Typography>
            </div>
          )}
          <DynamicTable columns={columns}>
            {props.migrations.map((migration, i) => (
              <tr key={i}>
                <td className={styles.stdCell}>
                  {formatClassroomName({
                    grade: migration.toClassroom.grade,
                    division: migration.toClassroom.division,
                    shift: migration.toClassroom.shift,
                    stage: migration.toClassroom.stage,
                  })}
                </td>
                <td className={styles.studentsToMigrateText}>
                  {migration.students.length +
                    migration.studentsToAdd.length -
                    migration.studentsToRemove.length}
                </td>
                {!isSuccess(props.migrationCompletionStatus) && (
                  <>
                    {' '}
                    <td>
                      <BaseChip
                        className={styles.cursorNotPointer}
                        label={t(
                          `principal.classroomMigration.${
                            migration.verified ? 'verified' : 'notVerified'
                          }`
                        )}
                        color={
                          migration.verified
                            ? theme.palette.success.main
                            : theme.palette.text.secondary
                        }
                        bgColor={
                          migration.verified
                            ? chroma(theme.palette.success.main).alpha(0.15).hex()
                            : theme.palette.border
                        }
                      />
                    </td>
                    <td>
                      <Button
                        className={styles.button}
                        variant={migration.verified ? Variant.OUTLINE : Variant.DEFAULT}
                        onClick={() => handleClick(migration)}
                      >
                        {t(migration.verified ? 'generic.edit' : 'generic.verify')}
                      </Button>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </DynamicTable>
        </>
      )}
      {isFailure(props.verificationTableStatus) && (
        <EmptyState
          variant={8}
          title={t('conference.somethingWentWrong')}
          subtitle={t('generic.tryAgainLater')}
        />
      )}
    </div>
  )
}

export default VerifyStudentsStep
