import type { Reducer } from 'redux'
import { SideDrawerContent } from '@/sections/report-card/grading/sideDrawer/containers/sideDrawerWrapper'
import * as Types from '@/sections/report-card/grading/sideDrawer/types/sideDrawer'
import type { ReportCardSideDrawerState } from '@/sections/report-card/grading/sideDrawer/types/sideDrawer'

const initialState: ReportCardSideDrawerState = {
  open: false,
  drawerContent: SideDrawerContent.INITIAL,
}

const ReportCardGradingStudentDrawer: Reducer<
  ReportCardSideDrawerState,
  Types.ReportCardSideDrawerAction
> = (state = initialState, action): Types.ReportCardSideDrawerState => {
  switch (action.type) {
    case Types.OPEN_DRAWER:
      return {
        ...state,
        open: true,
        drawerContent: action.payload.drawerContent,
      }
    case Types.CLOSE_DRAWER:
      return initialState

    default:
      return state
  }
}

export default ReportCardGradingStudentDrawer
