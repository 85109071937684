import moment from 'moment'
import {
  CollabMiniature,
  Avv as LiveIcon,
  Offline as OfflineIcon,
  Quiz,
} from '@/legacy/svgs/activityRibbon'
import { Questions as QuestionsIcon } from '@/legacy/svgs/activityRibbon'
import { Reading as ReadingIcon } from '@/legacy/svgs/activityRibbon'
import { WrittenExam } from '@/legacy/svgs/activityRibbon'
import { OralExam } from '@/legacy/svgs/activityRibbon'
import { RobotsActivity as RobotsRemoteIcon } from '@/legacy/svgs/activityRibbon'
import { Animations as BlocksIcon } from '@/legacy/svgs/activityRibbon'
import { isClassTeacherEquivalent } from '@/legacy/rolesManagement'
import {
  ACTIVITY_KEY_AVV,
  ACTIVITY_KEY_ORAL_EXAM,
  ACTIVITY_KEY_QUESTIONS,
  ACTIVITY_KEY_READING,
  ACTIVITY_KEY_ROBOTS_ACTIVITY,
  ACTIVITY_KEY_ANIMATIONS_ACTIVITY,
  ACTIVITY_KEY_WRITTEN_EXAM,
  ACTIVITY_KEY_OFFLINE,
  ACTIVITY_KEY_QUIZ,
  ACTIVITY_KEY_COLLAB_MINIATURE,
} from '@/legacy/components/v2/ribbons/types'
import { ActivityTypes } from '@/api/lms/activities/activities'

export const monday = moment().weekday(1)
export const sunday = moment().weekday(0)

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const getTitleColumn = (startDate, today, t, locale) => {
  const startDateTranslated = startDate.locale(locale)
  return `${startDateTranslated.format('DD/MM')}, ${
    isToday(startDateTranslated, today)
      ? t('activityCell.today')
      : capitalizeFirstLetter(startDateTranslated.format('dddd'))
  }`
}

export function getColumnsForBoard(
  startDate,
  activities,
  teacherID,
  menuOptions,
  role,
  t,
  showWeekend,
  locale
) {
  const now = moment()
  const today = now.clone().startOf('day')
  const columns = []

  for (let i = 0; i < 7; i++) {
    const date = startDate.clone().add(i, 'days')
    const title = getTitleColumn(date, today, t, locale)
    const todayCheck = isToday(date, today)
    columns.push({
      title,
      todayCheck,
      date,
      id: i,
      cards: [],
    })
  }
  activities.forEach((activity) => {
    const {
      id,
      completed,
      date,
      startDate: startDateActivity,
      teachers,
      subject: { color, name },
      type,
    } = activity
    const filteredMenuOptions =
      type === ActivityTypes.OFFLINE
        ? menuOptions.filter((option) => option.label === t('menuOptions.delete'))
        : menuOptions
    const formattedActivity = {
      id,
      completed,
      locked: false,
      teachers,
      teacherID,
      tasksFinished: t('teacher.activities.completedLabel'),
      finishTask: t('teacher.activities.gradedLabel'),
      menuOptions: filteredMenuOptions,
      subject: { color, name },
      color,
      text: name,
      description: '',
      type,
      startDateActivity,
      activity,
    }

    const teacher = teachers.find((teacher) => teacher.id === teacherID)
    if (teacher) {
      const { id, name, lastName: lastName } = teacher
      formattedActivity.teacher = { id: id, fullname: `${name} ${lastName}` }
    } else {
      if (!isClassTeacherEquivalent(role)) formattedActivity.locked = true
      const { name, lastName: lastName } =
        teachers.length > 0 ? teachers[0] : { name: '', lastName: '' }
      formattedActivity.teacher = { id: id, fullname: `${name} ${lastName}` }
    }

    // TODO right emoji
    const timeDay = isToday(moment(date), today)
      ? t('activityCell.today')
      : moment(date).clone().format('dddd')

    formattedActivity.title = type && activity.title
    formattedActivity.time =
      activity.type && timeDay + ' ' + moment(startDateActivity).format('LT') + 'Hs'

    switch (activity.type) {
      case ACTIVITY_KEY_AVV:
        formattedActivity.leftIcon = <LiveIcon height="24" width="24" />
        formattedActivity.calendarLabel = t('activityCell.transmition')
        break
      case ACTIVITY_KEY_QUESTIONS:
        formattedActivity.leftIcon = <QuestionsIcon height="24" width="24" />
        break
      case ACTIVITY_KEY_READING:
      default:
        formattedActivity.leftIcon = <ReadingIcon height="24" width="24" />
        break
      case ACTIVITY_KEY_WRITTEN_EXAM:
        formattedActivity.leftIcon = <WrittenExam height="24" width="24" />
        formattedActivity.calendarLabel = t('activityCell.start')
        break
      case ACTIVITY_KEY_ORAL_EXAM:
        formattedActivity.leftIcon = <OralExam height="24" width="24" />
        formattedActivity.calendarLabel = t('activityCell.start')
        break
      case ACTIVITY_KEY_ROBOTS_ACTIVITY:
        formattedActivity.leftIcon = <RobotsRemoteIcon height="24" width="24" />
        break
      case ACTIVITY_KEY_ANIMATIONS_ACTIVITY:
        formattedActivity.leftIcon = <BlocksIcon height="24" width="24" />
        break
      case ACTIVITY_KEY_OFFLINE:
        formattedActivity.leftIcon = <OfflineIcon height="24" width="24" />
        break
      case ACTIVITY_KEY_QUIZ:
        formattedActivity.leftIcon = <Quiz height="24" width="24" />
        break
      case ACTIVITY_KEY_COLLAB_MINIATURE:
        formattedActivity.leftIcon = <CollabMiniature height="24" width="24" />
        break
    }
    let index = moment(date).get('weekday') - 1
    if (index < 0) index = 6
    columns[index].cards.push(formattedActivity)
  })

  return columns.slice(0, showWeekend ? 7 : 5)
}

function isToday(reference, date) {
  const today = reference.clone().startOf('day')
  return date.isSame(today, 'd')
}

export function parseTags(tagCategories) {
  const tags = []
  Object.values(tagCategories).forEach((tagCategory) => {
    tagCategory.forEach((t) => tags.push(t.id))
  })
  return tags
}
