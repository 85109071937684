import type { Moment } from 'moment'
import type { AttendanceStatus } from '@/api/attendances/config'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { SetField } from '@/legacy/redux/types/generic'
import type { ListClassroomStudentsSuccess, Student } from '@/legacy/redux/types/students'
import type { ErrorPayload, GenericPayload } from '@/legacy/redux/utils'
import type {
  AttendanceRecord,
  ClassroomAttendancesByStudent,
} from '@/legacy/services/api/attendances/types'

export type PreviousRecord = AttendanceRecord | null
export enum SaveRecordsRequestStatus {
  PENDING = 'pending',
  IDLE = 'idle',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export interface AbscencesDrawerState {
  loading: boolean
  error: string
  abscences: ClassroomAttendancesByStudent
  students: Student[]
  selectedStudentIndex: number
  previousRecord: PreviousRecord
  saveRecordsRequestStatus: SaveRecordsRequestStatus
}

export const CLEAR_STATE = 'REPORT_CARD/GRADING/TABS/ABSCENCES/ABSCENCES_DRAWER/CLEAR_STATE'
export type ClearState = {
  type: typeof CLEAR_STATE
  payload: GenericPayload
}

export const INIT_DRAWER = 'REPORT_CARD/GRADING/TABS/ABSCENCES/ABSCENCES_DRAWER/INIT_DRAWER'
export type InitDrawer = {
  type: typeof INIT_DRAWER
  payload: { requestOrigins: ReduxRequestOrigin[]; selectedStudent: Student; students: Student[] }
}

export const GET_ABSCENCES_REQUEST =
  'REPORT_CARD/GRADING/TABS/ABSCENCES/ABSCENCES_DRAWER/GET_ABSCENCES_REQUEST'
export const GET_ABSCENCES_SUCCESS =
  'REPORT_CARD/GRADING/TABS/ABSCENCES/ABSCENCES_DRAWER/GET_ABSCENCES_SUCCESS'
export const GET_ABSCENCES_FAILURE =
  'REPORT_CARD/GRADING/TABS/ABSCENCES/ABSCENCES_DRAWER/GET_ABSCENCES_FAILURE'

export type GetAbscencesRequest = {
  type: typeof GET_ABSCENCES_REQUEST
  payload: GenericPayload
}
export type GetAbscencesSuccess = {
  type: typeof GET_ABSCENCES_SUCCESS
  payload: { requestOrigins: ReduxRequestOrigin[]; abscences: ClassroomAttendancesByStudent }
}
export type GetAbscencesFailure = {
  type: typeof GET_ABSCENCES_FAILURE
  payload: ErrorPayload
}

export const UPDATE_ABSCENCE_REQUEST =
  'REPORT_CARD/GRADING/TABS/ABSCENCES/ABSCENCES_DRAWER/UPDATE_ABSCENCE_REQUEST'
export const UPDATE_ABSCENCE_SUCCESS =
  'REPORT_CARD/GRADING/TABS/ABSCENCES/ABSCENCES_DRAWER/UPDATE_ABSCENCE_SUCCESS'
export const UPDATE_ABSCENCE_FAILURE =
  'REPORT_CARD/GRADING/TABS/ABSCENCES/ABSCENCES_DRAWER/UPDATE_ABSCENCE_FAILURE'

export type UpdateAbscenceRequest = {
  type: typeof UPDATE_ABSCENCE_REQUEST
  payload: { requestOrigins: ReduxRequestOrigin[]; date: Moment; status: AttendanceStatus }
}
export type UpdateAbscenceSuccess = {
  type: typeof UPDATE_ABSCENCE_SUCCESS
  payload: { requestOrigins: ReduxRequestOrigin[]; date: Moment; status: AttendanceStatus }
}
export type UpdateAbscenceFailure = {
  type: typeof UPDATE_ABSCENCE_FAILURE
  payload: { requestOrigins: ReduxRequestOrigin[]; error: string; date: Moment }
}

export const SAVE_ABSCENCES_REQUEST =
  'REPORT_CARD/GRADING/TABS/ABSCENCES/ABSCENCES_DRAWER/SAVE_ABSCENCES_REQUEST'
export const SAVE_ABSCENCES_SUCCESS =
  'REPORT_CARD/GRADING/TABS/ABSCENCES/ABSCENCES_DRAWER/SAVE_ABSCENCES_SUCCESS'
export const SAVE_ABSCENCES_FAILURE =
  'REPORT_CARD/GRADING/TABS/ABSCENCES/ABSCENCES_DRAWER/SAVE_ABSCENCES_FAILURE'

export type SaveAbscencesRequest = {
  type: typeof SAVE_ABSCENCES_REQUEST
  payload: GenericPayload
}
export type SaveAbscencesSuccess = {
  type: typeof SAVE_ABSCENCES_SUCCESS
  payload: GenericPayload
}
export type SaveAbscencesFailure = {
  type: typeof SAVE_ABSCENCES_FAILURE
  payload: ErrorPayload
}

export type AbscencesDrawerActions =
  | SetField<'selectedStudentIndex', number>
  | ClearState
  | InitDrawer
  | GetAbscencesRequest
  | GetAbscencesSuccess
  | GetAbscencesFailure
  | UpdateAbscenceRequest
  | UpdateAbscenceSuccess
  | UpdateAbscenceFailure
  | SaveAbscencesRequest
  | SaveAbscencesSuccess
  | SaveAbscencesFailure
  | ListClassroomStudentsSuccess
