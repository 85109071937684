import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { Receiver } from '@/api/bulletin'

export interface MessageReceiversState {
  status: REQUEST_STATUS
  loading: boolean
  error: Error | null
  receivers: Receiver[]
  messageId: number
}

export enum MESSAGE_RECEIVERS_TYPE {
  GET_RECEIVERS = 'TEACHER/BULLETIN/MESSAGE_RECEIVERS/GET_RECEIVERS',
  CLEAN_RECEIVERS = 'TEACHER/BULLETIN/MESSAGE_RECEIVERS/CLEAN_RECEIVERS',
  GET_RECEIVERS_SUCCESS = 'TEACHER/BULLETIN/MESSAGE_RECEIVERS/GET_RECEIVERS_SUCCESS',
  GET_RECEIVERS_FAILURE = 'TEACHER/BULLETIN/MESSAGE_RECEIVERS/GET_RECEIVERS_FAILURE',
  SET_MESSAGE_ID = 'TEACHER/BULLETIN/MESSAGE_RECEIVERS/SET_MESSAGE_ID',
}

export type SetMessageID = {
  type: MESSAGE_RECEIVERS_TYPE.SET_MESSAGE_ID
  payload: {
    messageId: number
  }
}

export type CleanMessageReceivers = {
  type: MESSAGE_RECEIVERS_TYPE.CLEAN_RECEIVERS
}

export type GetMessageReceivers = {
  type: MESSAGE_RECEIVERS_TYPE.GET_RECEIVERS
}

export type GetMessageReceiversSuccess = {
  type: MESSAGE_RECEIVERS_TYPE.GET_RECEIVERS_SUCCESS
  payload: {
    receivers: Receiver[]
  }
}

export type GetMessageReceiversFailure = {
  type: MESSAGE_RECEIVERS_TYPE.GET_RECEIVERS_FAILURE
  payload: {
    error: Error
  }
}

export type MessageReceiversActions =
  | GetMessageReceivers
  | GetMessageReceiversSuccess
  | GetMessageReceiversFailure
  | CleanMessageReceivers
  | SetMessageID
