import type { VisibilityTag } from '@/api/lms/organizations'
import type { Tag } from '@/sections/content-creator/sequenceDrawer/types/books'
import type { Book as BookContentCreator } from '@/sections/content-creator/books/types/books'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { LibraryResourceTagCategory } from '@/sections/teacher/library/types/libraryBooks'
import type { PremiumActivityByAudience } from '@/api/lms/library'

export interface BooksDrawerState {
  isOpen: boolean
  selectedBook: Book
  currentStep: BOOKS_DRAWER_STEPS
  tags: {
    list: LibraryResourceTagCategory[]
    error: string
    status: REQUEST_STATUS
  }
  activities: {
    selected: PremiumActivityByAudience[]
    count: number
    status: REQUEST_STATUS
    error: string
  }
}

export interface Book extends BookContentCreator {
  tags: Tag[]
  visibilityTags: VisibilityTag[]
  amount: number
  organizationName: string
  organizationLogo: string
  audiences: { id: number; name: string }[]
}

export enum BOOKS_DRAWER_STEPS {
  DETAILS = 'DETAILS',
  ACTIVITIES = 'ACTIVITIES',
}

export enum BOOKS_DRAWER_TYPES {
  SET_OPEN = 'CONTENT-CREATOR/BOOKS_DRAWER/SET_BOOK_OPEN',
  SET_CLOSE = 'CONTENT-CREATOR/BOOKS_DRAWER/SET_BOOK_CLOSE',
  SET_SELECTED_BOOK = 'CONTENT-CREATOR/BOOKS_DRAWER/SET_SELECTED_BOOK',
  SET_SELECTED_STEP = 'CONTENT-CREATOR/BOOKS_DRAWER/SET_SELECTED_STEP',
  GET_ALL_TAGS_REQUEST = 'CONTENT-CREATOR/BOOKS_DRAWER/GET_ALL_TAGS_REQUEST',
  GET_ALL_TAGS_SUCCESS = 'CONTENT-CREATOR/BOOKS_DRAWER/GET_ALL_TAGS_SUCCESS',
  GET_ALL_TAGS_FAILURE = 'CONTENT-CREATOR/BOOKS_DRAWER/GET_ALL_TAGS_FAILURE',
  GET_BOOK_ACTIVITIES_REQUEST = 'CONTENT-CREATOR/BOOKS_DRAWER/GET_BOOK_ACTIVITIES_REQUEST',
  GET_BOOK_ACTIVITIES_SUCCESS = 'CONTENT-CREATOR/BOOKS_DRAWER/GET_BOOK_ACTIVITIES_SUCCESS',
  GET_BOOK_ACTIVITIES_FAILURE = 'CONTENT-CREATOR/BOOKS_DRAWER/GET_BOOK_ACTIVITIES_FAILURE',
}

export type SetOpenBooksDrawerAction = {
  type: BOOKS_DRAWER_TYPES.SET_OPEN
}

export type SetCloseBooksDrawerAction = {
  type: BOOKS_DRAWER_TYPES.SET_CLOSE
}
export type SetSelectedBookAction = {
  type: BOOKS_DRAWER_TYPES.SET_SELECTED_BOOK
  payload: {
    book: Book
  }
}

export type SetSelectedStepAction = {
  type: BOOKS_DRAWER_TYPES.SET_SELECTED_STEP
  payload: {
    currentStep: BOOKS_DRAWER_STEPS
  }
}

export interface GetAllTagsRequestAction {
  type: BOOKS_DRAWER_TYPES.GET_ALL_TAGS_REQUEST
}

export interface GetAllTagsSuccessAction {
  type: BOOKS_DRAWER_TYPES.GET_ALL_TAGS_SUCCESS
  payload: {
    tagCategories: LibraryResourceTagCategory[]
  }
}

export interface GetAllTagsFailureAction {
  type: BOOKS_DRAWER_TYPES.GET_ALL_TAGS_FAILURE
  payload: {
    error: string
  }
}

export interface GetBookActivitiesRequest {
  type: BOOKS_DRAWER_TYPES.GET_BOOK_ACTIVITIES_REQUEST
  payload: {
    page: number
    rowsPerPage: number
  }
}

export interface GetBookActivitiesSuccess {
  type: BOOKS_DRAWER_TYPES.GET_BOOK_ACTIVITIES_SUCCESS
  payload: {
    bookActivities: PremiumActivityByAudience[]
    count: number
  }
}

export interface GetBookActivitiesFailure {
  type: BOOKS_DRAWER_TYPES.GET_BOOK_ACTIVITIES_FAILURE
  payload: {
    error: string
  }
}

export type BookDrawersActions =
  | SetOpenBooksDrawerAction
  | SetCloseBooksDrawerAction
  | SetSelectedBookAction
  | SetSelectedStepAction
  | GetAllTagsRequestAction
  | GetAllTagsSuccessAction
  | GetAllTagsFailureAction
  | GetBookActivitiesRequest
  | GetBookActivitiesSuccess
  | GetBookActivitiesFailure
