import clsx from 'clsx'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import type { OverridableComponent } from '@mui/material/OverridableComponent'
import type { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

interface StyleProps {
  size: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  Button: ({ size }) => ({
    boxSizing: 'border-box',
    borderRadius: 3,
    fontWeight: 'bold',
    fontSize: size === 'large' ? 16 : 14,
    lineHeight: size === 'large' ? '21px' : '18px',
    textTransform: 'none',
    padding: 0,
    color: theme.palette.primary.main,
    background: 'rgba(0,0,0,0)',
    '& span span svg path': {
      fill: theme.palette.primary.main,
    },
    '&:hover': {
      background: 'rgba(0,0,0,0)', // Overwrite default behavior
      color: theme.palette.primary.main,
      '& span span svg path': {
        fill: theme.palette.primary.main,
      },
    },
    '&:active': {
      background: 'rgba(0,0,0,0)', // Overwrite default behavior
      color: theme.palette.primary.main,
      textShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
      '& span span svg': {
        filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.25))',
      },
      '& span span svg path': {
        fill: theme.palette.primary.main + '!important',
      },
    },
  }),
  disabled: {
    opacity: 0.3,
    border: 'none',
    color: theme.palette.primary.main + ' !important',
  },
  iconLeft: {
    justifyItems: 'center',
    display: 'flex',
    marginRight: 10,
  },
  iconRight: {
    justifyItems: 'center',
    display: 'flex',
    marginLeft: 10,
  },
  loadingContainer: {
    height: 21,
    display: 'flex',
    alignItems: 'center',
  },
}))

interface TextButtonProps {
  size?: string
  loading?: boolean
  disabled?: boolean
  className?: string
  IconLeft?: OverridableComponent<any>
  iconLeftClassname?: string
  IconRight?: OverridableComponent<any>
  iconRightClassname?: string
  onClick?: () => void
  children: JSX.Element | JSX.Element[] | string
  component?: string
}

const TextButton: React.FC<TextButtonProps> = ({
  children,
  size = 'large',
  onClick,
  loading = false,
  className,
  IconLeft,
  iconLeftClassname,
  IconRight,
  iconRightClassname,
  ...props
}) => {
  const classes = useStyles({ size })
  return (
    <Button
      {...props}
      onClick={onClick}
      disabled={props.disabled || loading}
      disableRipple
      className={clsx(classes.Button, { [classes.disabled]: props.disabled }, className)}
    >
      {IconLeft && !loading && (
        <span className={classes.iconLeft}>
          <IconLeft className={iconLeftClassname} />
        </span>
      )}
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress color="primary" size={18} />
        </div>
      ) : (
        children
      )}
      {IconRight && !loading && (
        <span className={classes.iconRight}>
          <IconRight className={iconRightClassname} />
        </span>
      )}
    </Button>
  )
}

export default TextButton
