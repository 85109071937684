import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export const types = {
  GET_STUDENTS_DATA_REQUEST: 'STUDENTS_TEACHER/GET_STUDENTS_DATA_REQUEST',
  GET_STUDENTS_DATA_SUCCESS: 'STUDENTS_TEACHER/GET_STUDENTS_DATA_SUCCESS',
  GET_STUDENTS_DATA_FAILURE: 'STUDENTS_TEACHER/GET_STUDENTS_DATA_FAILURE',
  SET_PAGINATION: 'STUDENTS_TEACHER/SET_PAGINATION',
  CLEAR_PARAMS: 'STUDENTS_TEACHER/CLEAR_PARAMS',
  SET_CREATING: 'STUDENTS_TEACHER/SET_CREATING',
  DOWNLOAD_STUDENTS_DATA_REQUEST: 'STUDENTS_TEACHER/DOWNLOAD_STUDENTS_DATA_REQUEST',
  DOWNLOAD_STUDENTS_DATA_SUCCESS: 'STUDENTS_TEACHER/DOWNLOAD_STUDENTS_DATA_SUCCESS',
  DOWNLOAD_STUDENTS_DATA_FAILURE: 'STUDENTS_TEACHER/DOWNLOAD_STUDENTS_DATA_FAILURE',
  SET_OPEN_EXPORT_STUDENTS_MODAL: 'STUDENTS_TEACHER/SET_OPEN_EXPORT_STUDENTS_MODAL',
  SET_CLOSE_EXPORT_STUDENTS_MODAL: 'STUDENTS_TEACHER/SET_CLOSE_EXPORT_STUDENTS_MODAL',
}

export interface Student {
  id: number
  name: string
  lastName: string
  on?: boolean
  temporal?: boolean
  classroomActivities: {
    completed: number
    total: number
    percentage: number
  }
  exportModal: {
    status: REQUEST_STATUS
    isOpen: boolean
  }
}

export interface StudentsTable {
  head: {
    activities: HeaderActivity[]
  }
}

export interface HeaderActivity {
  id: number
  avgStudentsValue: number
  date: string
  deadline: string
  studentsType: number
  title: string
  type: string
}

export interface RowActivity {
  activityId: number
  deadline: string
  students: null
  studentsType: number
  studentId: number
  submission: null
}

export interface StudentsRow {
  activities: RowActivity[]
  student: Student
}

export interface Pagination {
  current: number
  total: number
}

export interface Params {
  limit: number
  offset: number
  search?: string
  orderBy?: string
  orderDir?: string
  academicPeriodId?: number
}

export interface Group {
  id: number
  name: string
  tempName?: string
  students: Student[]
}

export interface StudentsState {
  rows: Student[]
  total: number
  loading: boolean
  error: string
  params: Params
  pagination: Pagination
  flag: boolean
}

export interface GetStudentsDataRequest {
  type: typeof types.GET_STUDENTS_DATA_REQUEST
  payload: {
    params: Params
  }
}

export interface GetStudentsDataSuccess {
  type: typeof types.GET_STUDENTS_DATA_SUCCESS
  payload: {
    students: Student[]
  }
}

export interface GetStudentsDataFailure {
  type: typeof types.GET_STUDENTS_DATA_FAILURE
  payload: {
    error: string
  }
}

export interface SetPagination {
  type: typeof types.SET_PAGINATION
  payload: {
    pagination: Pagination
  }
}

export interface ClearParams {
  type: typeof types.CLEAR_PARAMS
}

export type SetOpenExportStudentsModalAction = {
  type: typeof types.SET_OPEN_EXPORT_STUDENTS_MODAL
}

export type SetCloseExportStudentsModalAction = {
  type: typeof types.SET_CLOSE_EXPORT_STUDENTS_MODAL
}

export type DownloadStudentDataRequestAction = {
  type: typeof types.DOWNLOAD_STUDENTS_DATA_REQUEST
}

export type DownloadStudentDataSuccessAction = {
  type: typeof types.DOWNLOAD_STUDENTS_DATA_SUCCESS
}

export type DownloadStudentDataFailureAction = {
  type: typeof types.DOWNLOAD_STUDENTS_DATA_FAILURE
}

export type StudentsActions =
  | GetStudentsDataRequest
  | GetStudentsDataSuccess
  | GetStudentsDataFailure
  | ClearParams
  | SetPagination
  | DownloadStudentDataRequestAction
  | DownloadStudentDataSuccessAction
  | DownloadStudentDataFailureAction
  | SetOpenExportStudentsModalAction
  | SetCloseExportStudentsModalAction
