import { apiPrivate } from './..'
import { url } from '@aula/config'
import type { Pagination } from './library'

export const SubgroupsAPI = {
  create,
  get,
  getSubgroupStudentIds,
  list,
  update,
  remove,
}

type CreatePayload = {
  name: string
  classroomId: number
  studentIds: number[]
}

/**
 * Creates subgroup.
 *
 * @role Teacher
 * @param payload
 */
function create(payload: CreatePayload) {
  return apiPrivate.post(url + '/v1/subgroups', payload)
}

/**
 * Gets a specific subgroup.
 *
 * @role Teacher
 * @param subgroupId
 * @param classroomId
 * @param pagination
 *
 */
function get(subgroupId: number, classroomId: number, pagination: Pagination) {
  const params = {
    ...pagination,
    classroomId,
  }

  return apiPrivate.get(url + `/v1/subgroups/${subgroupId}`, { params })
}

/**
 * Gets the user IDs for a specific subgroup.
 *
 * @role Teacher
 * @param subgroupId
 * @param classroomId
 */
function getSubgroupStudentIds(subgroupId: number, classroomId: number) {
  return apiPrivate.get(url + `/v1/subgroups/${subgroupId}/users?classroomID=${classroomId}`)
}

/**
 * List classroom's subgroups.
 *
 * @role Teacher
 * @param classroomId
 */
function list(classroomId: number) {
  return apiPrivate.get(url + `/v1/subgroups?classroomID=${classroomId}`)
}

type UpdatePayload = {
  name: string
  classroomId: number
  studentIds: number[]
}

/**
 * Update subgroup.
 *
 * @role Teacher
 * @param id
 * @param payload
 */
function update(id: number, payload: UpdatePayload) {
  return apiPrivate.put(url + `/v1/subgroups/${id}`, payload)
}

/**
 * Delete subgroup.
 *
 * @role Teacher
 * @param id
 */
function remove(id: number, classroomId: number) {
  return apiPrivate.delete(url + `/v1/subgroups/${id}?classroomID=${classroomId}`)
}
