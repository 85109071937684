import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { Meta } from '@/sections/principal/forms/generateFormsModal/types'

export interface AcademicModalState {
  currentStep: ACADEMIC_MODAL_STEPS
  status: REQUEST_STATUS
  isModalOpen: boolean
  error: string
  periodAndGradeList: AcademicPeriodAndGrade[]
  selectedPeriodsAndGrades: AcademicPeriodAndGrade[]
  grades: Grade[]
  periods: AcademicPeriod[]
}

export enum ACADEMIC_MODAL_STEPS {
  INITIAL = 0,
  FIRST = 1,
  SECOND = 2,
}

export enum ACADEMIC_MODAL_TYPES {
  OPEN_MODAL = 'FORMS/ACADEMIC_MODAL_TYPES/OPEN_MODAL',
  CLOSE_MODAL = 'FORMS/ACADEMIC_MODAL_TYPES/CLOSE_MODAL',
  SET_CURRENT_STEP = 'FORMS/ACADEMIC_MODAL_TYPES/SET_CURRENT_STEP',
  GET_ACADEMIC_PERIODS_AND_GRADE_REQUEST = 'FORMS/ACADEMIC_MODAL_TYPES/GET_ACADEMIC_PERIODS_AND_GRADE_REQUEST',
  GET_ACADEMIC_PERIODS_AND_GRADE_SUCCESS = 'FORMS/ACADEMIC_MODAL_TYPES/GET_ACADEMIC_PERIODS_AND_GRADE_SUCCESS',
  GET_ACADEMIC_PERIODS_AND_GRADE_FAILURE = 'FORMS/ACADEMIC_MODAL_TYPES/GET_ACADEMIC_PERIODS_AND_GRADE_FAILURE',
  SET_ALL_TOGGLE_PERIODS_ACTION = 'FORMS/ACADEMIC_MODAL_TYPES/SET_ALL_TOGGLE_PERIODS_ACTION',
  SET_ALL_TOGGLE_GRADES_ACTION = 'FORMS/ACADEMIC_MODAL_TYPES/SET_ALL_TOGGLE_GRADES_ACTION',
  SET_ACADEMIC_PERIOD = 'FORMS/ACADEMIC_MODAL_TYPES/SET_ACADEMIC_PERIOD',
  SET_ACADEMIC_GRADE = 'FORMS/ACADEMIC_MODAL_TYPES/SET_ACADEMIC_GRADE',
  SAVE_SELECTED_PERIODS_AND_GRADES = 'FORMS/ACADEMIC_MODAL_TYPES/SAVE_SELECTED_PERIODS_AND_GRADES',
  SET_SELECTED_PERIODS_AND_GRADES = 'FORMS/ACADEMIC_MODAL_TYPES/SET_SELECTED_PERIODS_AND_GRADES',
  CLEAR_ACADEMIC_MODAL = 'FORMS/ACADEMIC_MODAL_TYPES/CLEAR_ACADEMIC_MODAL',
}

export type AcademicPeriodAndGrade = {
  name: string
  meta: AcademicPeriodAndGradeMeta
}
export type AcademicPeriod = {
  name: string
  meta: AcademicPeriodAndGradeMeta
}

export type Grade = {
  name: string
  periodName?: string
  id: number
}
interface AcademicPeriodAndGradeMeta extends Meta {
  id: number
  grades: Grade[]
}

// Modal actions
export type OpenModalAction = {
  type: ACADEMIC_MODAL_TYPES.OPEN_MODAL
}

export type CloseModalAction = {
  type: ACADEMIC_MODAL_TYPES.CLOSE_MODAL
}

export type ClearAcademicModalAction = {
  type: ACADEMIC_MODAL_TYPES.CLEAR_ACADEMIC_MODAL
}

// Set current step modal action
export type SetCurrentStepAction = {
  type: ACADEMIC_MODAL_TYPES.SET_CURRENT_STEP
  payload: {
    newStep: ACADEMIC_MODAL_STEPS
  }
}

export type GetAcademicPeriodAndGradesRequestAction = {
  type: ACADEMIC_MODAL_TYPES.GET_ACADEMIC_PERIODS_AND_GRADE_REQUEST
}

export type GetAcademicPeriodAndGradesSuccessAction = {
  type: ACADEMIC_MODAL_TYPES.GET_ACADEMIC_PERIODS_AND_GRADE_SUCCESS
  payload: {
    periodAndGradeList: AcademicPeriodAndGrade[]
  }
}

export type GetAcademicPeriodAndGradesFailureAction = {
  type: ACADEMIC_MODAL_TYPES.GET_ACADEMIC_PERIODS_AND_GRADE_FAILURE
  payload: {
    error: string
  }
}

export type SetAllTogglePeriodsAction = {
  type: ACADEMIC_MODAL_TYPES.SET_ALL_TOGGLE_PERIODS_ACTION
}
export type SetAllToggleGradesAction = {
  type: ACADEMIC_MODAL_TYPES.SET_ALL_TOGGLE_GRADES_ACTION
}

export type SetAcademicPeriodAction = {
  type: ACADEMIC_MODAL_TYPES.SET_ACADEMIC_PERIOD
  payload: {
    period: AcademicPeriod
  }
}
export type SetAcademicGradeAction = {
  type: ACADEMIC_MODAL_TYPES.SET_ACADEMIC_GRADE
  payload: {
    grade: Grade
    periodName: string
  }
}
export type SaveSelectedPeriosAndGradesAction = {
  type: ACADEMIC_MODAL_TYPES.SAVE_SELECTED_PERIODS_AND_GRADES
}

export type SetSelectedPeriosAndGradesAction = {
  type: ACADEMIC_MODAL_TYPES.SET_SELECTED_PERIODS_AND_GRADES
  payload: {
    periodsAndGrades: AcademicPeriodAndGrade[]
  }
}

export type GetAcademicPeriodAndGrades =
  | GetAcademicPeriodAndGradesRequestAction
  | GetAcademicPeriodAndGradesSuccessAction
  | GetAcademicPeriodAndGradesFailureAction

export type AcademicModalActions =
  | OpenModalAction
  | CloseModalAction
  | SetCurrentStepAction
  | SetAllTogglePeriodsAction
  | SetAllToggleGradesAction
  | SetAcademicPeriodAction
  | SetAcademicGradeAction
  | GetAcademicPeriodAndGrades
  | GetAcademicPeriodAndGradesRequestAction
  | GetAcademicPeriodAndGradesSuccessAction
  | GetAcademicPeriodAndGradesFailureAction
  | SaveSelectedPeriosAndGradesAction
  | SetSelectedPeriosAndGradesAction
  | ClearAcademicModalAction
