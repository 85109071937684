import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import {
  CREATE_EXAMS_AS_ADMIN_FAILURE,
  CREATE_EXAMS_AS_ADMIN_REQUEST,
  CREATE_EXAMS_AS_ADMIN_SUCCESS,
  EDIT_EXAMS_AS_ADMIN_FAILURE,
  EDIT_EXAMS_AS_ADMIN_REQUEST,
  EDIT_EXAMS_AS_ADMIN_SUCCESS,
} from '@/sections/eb-admin/management/types/examsDrawer'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type {
  AddEditExamModalAction,
  AddEditExamModalState,
} from '@/sections/management/types/addEditExamModal'
import {
  CLEAR_FIELDS,
  GET_ORGANIZATION_FAILURE,
  GET_ORGANIZATION_REQUEST,
  GET_ORGANIZATION_SUCCESS,
  SET_INITIAL_VALUES,
} from '@/sections/management/types/addEditExamModal'

const initialState: AddEditExamModalState = {
  open: false,
  loading: false,
  error: '',
  editMode: false,
  id: '',
  name: '',
  classroomOptions: [],
  classroomId: 0,
  organizationId: 0,
  division: '',
  grade: '',
  shift: '',
  cue: '',
  neighborhood: '',
  theme: 0,
  themes: [],
  games: [],
  datetime: '',
  deadline: '',
  duration: 0,
  type: 0,
  stage: 0,
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_ADD_EDIT_EXAM_MODAL

const AdminOrganizationsModalReducer: Reducer<AddEditExamModalState, AddEditExamModalAction> = (
  state = initialState,
  action
): AddEditExamModalState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case CLEAR_FIELDS:
      return {
        ...initialState,
      }
    case SET_INITIAL_VALUES: {
      const { exam } = action.payload
      return {
        ...state,
        editMode: true,
        open: true,
        id: exam.id,
        name: exam.name,
        classroomId: exam.aulaId,
        organizationId: exam.organizationId,
        division: exam.division,
        grade: exam.grade,
        shift: exam.shift,
        cue: exam.cue,
        neighborhood: exam.neighborhood,
        theme: exam.exam.theme,
        datetime: exam.exam.datetime,
        deadline: exam.exam.deadline,
        duration: exam.exam.duration,
        type: exam.exam.type,
        stage: exam.exam.stage,
        games:
          exam.exam.games && exam.exam.games.length > 0
            ? exam.exam.games
            : exam.exam.theme && state.themes[exam.exam.theme]?.games
            ? state.themes[exam.exam.theme].games
            : [],
      }
    }

    case CREATE_EXAMS_AS_ADMIN_REQUEST:
    case EDIT_EXAMS_AS_ADMIN_REQUEST:
    case GET_ORGANIZATION_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case CREATE_EXAMS_AS_ADMIN_SUCCESS:
    case EDIT_EXAMS_AS_ADMIN_SUCCESS: {
      return {
        ...initialState,
        classroomOptions: [],
      }
    }
    case GET_ORGANIZATION_SUCCESS: {
      const { organization } = action.payload

      return {
        ...state,
        loading: false,
        // organization: { id: organization.id, label: organization.name },
        organizationId: organization.id,
      }
    }
    case CREATE_EXAMS_AS_ADMIN_FAILURE:
    case EDIT_EXAMS_AS_ADMIN_FAILURE:
    case GET_ORGANIZATION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, AdminOrganizationsModalReducer, requestDestination)
