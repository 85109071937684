import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/content-creator/deleteSequenceModal/types/deleteSequenceModal'

const initialState: Types.DeleteSequenceModalState = {
  isOpenModal: false,
  status: REQUEST_STATUS.IDLE,
  sequenceIdToDelete: 0,
}

const deleteSequenceModalReducer = (
  state: Types.DeleteSequenceModalState = initialState,
  action: Types.DeleteSequenceModalAction
): Types.DeleteSequenceModalState => {
  switch (action.type) {
    case Types.DELETE_SEQUENCE_MODAL_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isOpenModal: true,
        sequenceIdToDelete: action.payload,
      }
    }
    case Types.DELETE_SEQUENCE_MODAL_TYPES.CLEAN_MODAL: {
      return initialState
    }
    case Types.DELETE_SEQUENCE_MODAL_TYPES.DELETE_SEQUENCE_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.DELETE_SEQUENCE_MODAL_TYPES.DELETE_SEQUENCE_SUCCESS: {
      return initialState
    }
    case Types.DELETE_SEQUENCE_MODAL_TYPES.DELETE_SEQUENCE_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    default:
      return state
  }
}

export default deleteSequenceModalReducer
