import { combineReducers } from 'redux'
import dashboard from './dashboard/reducer'
import management from './management/reducer'

const rootReducer = combineReducers({
  dashboard,
  management,
})

export default rootReducer
