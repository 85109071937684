import { combineReducers } from 'redux'
import schoolRecords from './schoolRecords'
import messageThreads from './messageThreads'
import messageThreadReplies from './messageThreadReplies'

export default combineReducers({
  schoolRecords,
  messageThreads,
  messageThreadReplies,
})
