import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/student/summary/types/tabs/information'

const initialState: Types.InformationTabState = {
  status: REQUEST_STATUS.IDLE,
  data: [],
}

const informationTabReducer = (
  state: Types.InformationTabState = initialState,
  action: Types.InformationTabAction
): Types.InformationTabState => {
  switch (action.type) {
    case Types.INFORMATION_TAB_TYPES.GET_STUDENT_GUARDIANS_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.INFORMATION_TAB_TYPES.GET_STUDENT_GUARDIANS_SUCCESS: {
      return {
        status: REQUEST_STATUS.SUCCESS,
        data: action.payload.data,
      }
    }
    case Types.INFORMATION_TAB_TYPES.GET_STUDENT_GUARDIANS_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.INFORMATION_TAB_TYPES.RESET_TAB: {
      return initialState
    }
    default:
      return state
  }
}

export default informationTabReducer
