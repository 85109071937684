import React from 'react'
import { Hidden } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { tableStyles } from '@/legacy/components/v3/tableStyles'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(tableStyles)

const StudentActivityHeader = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <div className={clsx(classes.studentRow, classes.headerRow)}>
      <div className={classes.headerCell}>{t('student.activityCell')}</div>
      <div className={classes.headerCell}>{t('student.dateCell')}</div>
      <Hidden xsDown>
        <div className={classes.headerCell}>{t('student.progressCell')}</div>
      </Hidden>
      <Hidden smDown>
        <div className={classes.headerCell}>{t('student.chatCell')}</div>
      </Hidden>
      <Hidden smDown>
        <div className={classes.headerCell}>{t('student.subjectCell')}</div>
      </Hidden>
      <div className={classes.headerCell} />
    </div>
  )
}

export default StudentActivityHeader
