import type { ErrorPayload } from '@/legacy/redux/utils'
import type { GetReportCardConfigResponse } from '@/legacy/services/api/reportCard/config/types/get'

export interface LocalConfigPeriod {
  id: number
  isFinal: boolean
  editable: boolean
  extra: boolean
  isLastExtraPeriod: boolean
}

interface LocalConfig extends Omit<GetReportCardConfigResponse, 'periods'> {
  periods: LocalConfigPeriod[]
}

export interface ReportCardConfigState {
  loading: boolean
  error: string
  config: LocalConfig
}

export const REPORT_CARD_ENABLE_SCORES_REQUEST = 'REPORT_CARD/ENABLE_SCORES/PUT_REQUEST'
export const REPORT_CARD_ENABLE_SCORES_SUCCESS = 'REPORT_CARD/ENABLE_SCORES/PUT_SUCCESS'
export const REPORT_CARD_ENABLE_SCORES_FAILURE = 'REPORT_CARD/ENABLE_SCORES/PUT_FAILURE'

export type PutReportCardConfigRequest = {
  type: typeof REPORT_CARD_ENABLE_SCORES_REQUEST
}
export type PutReportCardConfigSuccess = {
  type: typeof REPORT_CARD_ENABLE_SCORES_SUCCESS
  payload: { enableScores: boolean }
}

export type PutReportCardConfigFailure = {
  type: typeof REPORT_CARD_ENABLE_SCORES_FAILURE
  payload: ErrorPayload
}

export const GET_REPORT_CARD_CONFIG_REQUEST = 'REPORT_CARD/CONFIG/GET_REQUEST'
export const GET_REPORT_CARD_CONFIG_SUCCESS = 'REPORT_CARD/CONFIG/GET_SUCCESS'
export const GET_REPORT_CARD_CONFIG_FAILURE = 'REPORT_CARD/CONFIG/GET_FAILURE'

export type GetReportCardConfigRequest = {
  type: typeof GET_REPORT_CARD_CONFIG_REQUEST
}
export type GetReportCardConfigSuccess = {
  type: typeof GET_REPORT_CARD_CONFIG_SUCCESS
  payload: { config: LocalConfig }
}

export type GetReportCardConfigFailure = {
  type: typeof GET_REPORT_CARD_CONFIG_FAILURE
  payload: ErrorPayload
}

export type ReportCardConfigAction =
  | GetReportCardConfigRequest
  | GetReportCardConfigSuccess
  | GetReportCardConfigFailure
  | PutReportCardConfigRequest
  | PutReportCardConfigSuccess
  | PutReportCardConfigFailure
