import type { Feature } from '@/legacy/redux/types/features'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'

export type FeaturesModalState = {
  open: boolean
  loading: boolean
  error: string
  featuresList: Feature[]
  activeFeatures: Feature[]
}

export const CLEAR_FIELDS = 'MANAGEMENT/ORGANIZATIONS/FEATURES_MODAL/CLEAR_FIELDS'
export type ClearAllFields = GenericActionWithType<typeof CLEAR_FIELDS>

export const OPEN_MODAL_REQUEST = 'MANAGEMENT/ORGANIZATIONS/FEATURES_MODAL/OPEN_MODAL_REQUEST'
export const OPEN_MODAL_SUCCESS = 'MANAGEMENT/ORGANIZATIONS/FEATURES_MODAL/OPEN_MODAL_SUCCESS'
export const OPEN_MODAL_FAILURE = 'MANAGEMENT/ORGANIZATIONS/FEATURES_MODAL/OPEN_MODAL_FAILURE'

export type OpenModalRequest = GenericActionWithType<typeof OPEN_MODAL_REQUEST>
export type OpenModalSuccess = GenericActionWithType<
  typeof OPEN_MODAL_SUCCESS,
  { featuresList: Feature[]; activeFeatures: Feature[] }
>
export type OpenModalFailure = GenericActionWithType<typeof OPEN_MODAL_FAILURE, ErrorPayload>
export type OpenModalAction = OpenModalRequest | OpenModalSuccess | OpenModalFailure

export const TOGGLE_SWITCH = 'MANAGEMENT/ORGANIZATIONS/FEATURES_MODAL/TOGGLE_SWITCH'
export type ToggleSwitch = GenericActionWithType<typeof TOGGLE_SWITCH, { feature: Feature }>

export type FeaturesModalAction = ClearAllFields | OpenModalAction | ToggleSwitch
