import type { ExorInstance, ExorStatus } from '@/sections/teacher/oralExams/types/oralExams'
import type { ClosePopup } from '@/sections/videocall/types/videocall'

export const SET_ONBOARDING_MODAL_OPEN = 'EXAMS_STUDENT/SET_ONBOARDING_MODAL_OPEN'
export const SET_ONBOARDING_MODAL_CLOSED = 'EXAMS_STUDENT/SET_ONBOARDING_MODAL_CLOSED'
export const GET_EXOR_INSTANCE_REQUEST = 'EXAMS_STUDENT/GET_EXOR_INSTANCE_REQUEST'
export const GET_EXOR_INSTANCE_SUCCESS = 'EXAMS_STUDENT/GET_EXOR_INSTANCE_SUCCESS'
export const GET_EXOR_INSTANCE_FAILURE = 'EXAMS_STUDENT/GET_EXOR_INSTANCE_FAILURE'

export const GET_EXOR_ACTIVITY_REQUEST = 'EXAMS_STUDENT/GET_EXOR_ACTIVITY_REQUEST'
export const GET_EXOR_ACTIVITY_SUCCESS = 'EXAMS_STUDENT/GET_EXOR_ACTIVITY_SUCCESS'
export const GET_EXOR_ACTIVITY_FAILURE = 'EXAMS_STUDENT/GET_EXOR_ACTIVITY_FAILURE'

export const GET_EXOR_STATUS_REQUEST = 'EXAMS_STUDENT/GET_EXOR_STATUS_REQUEST'
export const GET_EXOR_STATUS_SUCCESS = 'EXAMS_STUDENT/GET_EXOR_STATUS_SUCCESS'
export const GET_EXOR_STATUS_FAILURE = 'EXAMS_STUDENT/GET_EXOR_STATUS_FAILURE'

export const UPDATE_EXOR_STATUS_REQUEST = 'EXAMS_STUDENT/UPDATE_EXOR_STATUS_REQUEST'
export const UPDATE_EXOR_STATUS_SUCCESS = 'EXAMS_STUDENT/UPDATE_EXOR_STATUS_SUCCESS'
export const UPDATE_EXOR_STATUS_FAILURE = 'EXAMS_STUDENT/UPDATE_EXOR_STATUS_FAILURE'

export const SET_EXOR_INCOMING_CALL = 'EXAMS_STUDENT/SET_EXOR_INCOMING_CALL'
export const SET_PICKED_CALL = 'EXAMS_STUDENT/SET_PICKED_CALL'
export const SET_LOST_CALL = 'EXAMS_STUDENT/SET_LOST_CALL'
export const SET_EXOR_EVALUATED = 'EXAMS_STUDENT/SET_EXOR_EVALUATED'

export interface Activity {
  type: string
  classroomId: number
  id: number
  startDate: string
  endDate: string
}

export interface SetOnboardingModalOpen {
  type: typeof SET_ONBOARDING_MODAL_OPEN
  payload: {
    selectedActivity: Activity
  }
}

export interface SetOnboardingModalClosed {
  type: typeof SET_ONBOARDING_MODAL_CLOSED
}

export interface GetEXORInstanceRequest {
  type: typeof GET_EXOR_INSTANCE_REQUEST
}

export interface GetEXORInstanceSuccess {
  type: typeof GET_EXOR_INSTANCE_SUCCESS
  payload: {
    estimatedTime: Date
    instances: ExorInstance[]
  }
}

export interface GetEXORInstanceFailure {
  type: typeof GET_EXOR_INSTANCE_FAILURE
  payload: {
    error: string
  }
}

export interface GetEXORStatusRequest {
  type: typeof GET_EXOR_STATUS_REQUEST
}

export interface GetEXORStatusSuccess {
  type: typeof GET_EXOR_STATUS_SUCCESS
  payload: {
    status: ExorStatus
  }
}

export interface GetEXORStatusFailure {
  type: typeof GET_EXOR_STATUS_FAILURE
  payload: {
    error: string
  }
}

export interface UpdateEXORStatusRequest {
  type: typeof UPDATE_EXOR_STATUS_REQUEST
}

export interface UpdateEXORStatusSuccess {
  type: typeof UPDATE_EXOR_STATUS_SUCCESS
  payload: {
    status: ExorStatus
  }
}

export interface UpdateEXORStatusFailure {
  type: typeof UPDATE_EXOR_STATUS_FAILURE
  payload: {
    error: string
  }
}

export interface SetOnboardingModalOpen {
  type: typeof SET_ONBOARDING_MODAL_OPEN
  payload: {
    selectedActivity: Activity
  }
}

export interface SetOnboardingModalClosed {
  type: typeof SET_ONBOARDING_MODAL_CLOSED
}

export interface SetIncomingCall {
  type: typeof SET_EXOR_INCOMING_CALL
  payload: {
    callInfo: ExorInstance
    fromSocket: boolean
  }
}

export interface SetLostCall {
  type: typeof SET_LOST_CALL
}

export interface GetEXORActivityRequest {
  type: typeof GET_EXOR_ACTIVITY_REQUEST
}

export interface GetEXORActivitySuccess {
  type: typeof GET_EXOR_ACTIVITY_SUCCESS
  payload: {
    activity: Activity
  }
}

export interface GetEXORActivityFailure {
  type: typeof GET_EXOR_ACTIVITY_FAILURE
  payload: {
    error: string
  }
}

export interface PickedCall {
  type: typeof SET_PICKED_CALL
  payload: {
    instance: ExorInstance
  }
}

export interface SetEXOREvaluated {
  type: typeof SET_EXOR_EVALUATED
}

export interface OnboardingModalState {
  selectedActivity: Activity
  open: boolean
  loading: boolean
  lostCall: boolean
  incomingCall: boolean
  callsInfo: ExorInstance[]
  error: string
  estimatedTime: Date | null
  pickedCall: ExorInstance | null
  exorStatus: ExorStatus | null
  loadingStatus: boolean
}

export type EXORSocketParams = ExorInstance | {}
export type OnboardingModalActions =
  | SetOnboardingModalOpen
  | SetOnboardingModalClosed
  | GetEXORInstanceRequest
  | GetEXORInstanceSuccess
  | GetEXORInstanceFailure
  | GetEXORActivityRequest
  | GetEXORActivitySuccess
  | GetEXORActivityFailure
  | GetEXORStatusRequest
  | GetEXORStatusSuccess
  | GetEXORStatusFailure
  | UpdateEXORStatusRequest
  | UpdateEXORStatusSuccess
  | UpdateEXORStatusFailure
  | SetIncomingCall
  | SetLostCall
  | PickedCall
  | ClosePopup
  | SetEXOREvaluated
