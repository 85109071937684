import { COMMON_COLOR } from '.'

export enum PRIMARY_COLOR {
  P100 = '#DFD8F7',
  P200 = '#BFB3EF',
  P300 = '#9182D1',
  P400 = '#6356A3',
  P500 = '#302666',
  P600 = '#241B57',
  P700 = '#191349',
  P800 = '#110C3B',
  P900 = '#0B0730',
  LIGHT = '#DFD8F7',
  MAIN = '#2A205E',
  DARK = '#0B0730',
}

export enum SECONDARY_COLOR {
  P100 = '#D3F7FF',
  P200 = '#A8EBFF',
  P300 = '#7DD9FF',
  P400 = '#5DC7FF',
  P500 = '#27a8ff',
  P600 = '#1C83DB',
  P700 = '#1362B7',
  P800 = '#0C4593',
  P900 = '#07317A',
  LIGHT = '#D3F7FF',
  MAIN = '#42B3FF',
  DARK = '#07317A',
}

export const AULA_PALETTE = {
  primary: {
    light: PRIMARY_COLOR.P300,
    main: PRIMARY_COLOR.MAIN,
    dark: PRIMARY_COLOR.DARK,
    contrastText: COMMON_COLOR.WHITE,
  },
  secondary: {
    light: SECONDARY_COLOR.P300,
    main: SECONDARY_COLOR.MAIN,
    dark: SECONDARY_COLOR.DARK,
    contrastText: COMMON_COLOR.WHITE,
  },
}
