import type { TeacherFilesState, TeacherFilesTypes } from '@/sections/editor/types/teacherFiles'
import {
  ADD_FILES_TO_UPLOAD,
  REMOVE_FILE_TO_UPLOAD,
  REMOVE_UPLOADED_FILE,
  SET_CLOSE,
  SET_FILES,
  SET_OPEN,
  UPLOAD_FILES_FAILURE,
  UPLOAD_FILES_REQUEST,
  UPLOAD_FILES_SUCCESS,
} from '@/sections/editor/types/teacherFiles'

const initialState: TeacherFilesState = {
  open: false,
  error: '',
  loading: false,
  files: [],
  filesToUpload: [],
}

function root(state = initialState, action: TeacherFilesTypes): TeacherFilesState {
  switch (action.type) {
    case SET_OPEN:
      return { ...state, open: true, error: '' }
    case SET_CLOSE:
      return { ...state, open: false }
    case ADD_FILES_TO_UPLOAD:
      return { ...state, filesToUpload: [...state.filesToUpload, ...action.payload.files] }
    case REMOVE_FILE_TO_UPLOAD:
      return {
        ...state,
        filesToUpload: state.filesToUpload.filter((file) => file !== action.payload.file),
      }
    case UPLOAD_FILES_REQUEST:
      return { ...state, loading: true, error: '' }
    case UPLOAD_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        files: [...state.files, ...action.payload.files],
        filesToUpload: [],
        open: false,
      }
    case UPLOAD_FILES_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case REMOVE_UPLOADED_FILE:
      return { ...state, files: state.files.filter((file) => file.id !== action.payload.fileID) }
    case SET_FILES:
      return { ...state, files: [...action.payload.files] }
    default:
      return state
  }
}

export default root
