import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import { AVAILABILITY_FREE } from '@/sections/content-creator/books/types/books'
import type {
  BookDrawersActions,
  BooksDrawerState,
} from '@/sections/teacher/library/types/booksDrawer'
import {
  BOOKS_DRAWER_TYPES,
  BOOKS_DRAWER_STEPS,
} from '@/sections/teacher/library/types/booksDrawer'
import {
  GET_ALL_TAGS_SUCCESS,
  GET_ALL_TAGS_FAILURE,
  GET_ALL_TAGS_REQUEST,
} from '@/sections/teacher/library/types/libraryBooks'

const initialState: BooksDrawerState = {
  isOpen: false,
  currentStep: BOOKS_DRAWER_STEPS.DETAILS,
  tags: {
    list: [],
    error: '',
    status: REQUEST_STATUS.IDLE,
  },
  selectedBook: {
    id: 0,
    userID: 0,
    fileID: 0,
    title: '',
    description: '',
    isbn: '',
    url: '',
    organizationID: 0,
    coverURL: '',
    availability: AVAILABILITY_FREE,
    createdAt: '',
    organizationLogo: '',
    organizationName: '',
    amount: 0,
    audiences: [],
    tags: [],
    visibilityTags: [],
  },
  activities: {
    status: REQUEST_STATUS.IDLE,
    selected: [],
    count: 0,
    error: '',
  },
}

function booksDrawerReducer(
  state: BooksDrawerState = initialState,
  action: BookDrawersActions
): BooksDrawerState {
  switch (action.type) {
    case BOOKS_DRAWER_TYPES.SET_OPEN:
      return { ...state, isOpen: true }
    case BOOKS_DRAWER_TYPES.SET_CLOSE:
      return initialState
    case BOOKS_DRAWER_TYPES.SET_SELECTED_BOOK: {
      const { book } = action.payload
      return { ...state, selectedBook: { ...state.selectedBook, ...book } }
    }
    case BOOKS_DRAWER_TYPES.SET_SELECTED_STEP: {
      const { currentStep } = action.payload
      return { ...state, currentStep }
    }
    case BOOKS_DRAWER_TYPES.GET_BOOK_ACTIVITIES_REQUEST: {
      return { ...state, activities: { ...state.activities, status: REQUEST_STATUS.LOADING } }
    }
    case BOOKS_DRAWER_TYPES.GET_BOOK_ACTIVITIES_SUCCESS: {
      const { bookActivities, count } = action.payload

      const filteredActivities = bookActivities.filter((act) => {
        const commonAudience = act.audiences.find((a) =>
          state.selectedBook.audiences.some((audience) => audience.id === a.id)
        )
        return commonAudience
      })

      return {
        ...state,
        activities: {
          ...state.activities,
          count,
          selected: filteredActivities,
          status: REQUEST_STATUS.SUCCESS,
        },
      }
    }
    case BOOKS_DRAWER_TYPES.GET_BOOK_ACTIVITIES_FAILURE: {
      return {
        ...state,
        activities: {
          ...state.activities,
          status: REQUEST_STATUS.FAILURE,
          error: action.payload.error,
        },
      }
    }
    case GET_ALL_TAGS_REQUEST: {
      return { ...state, tags: { ...state.tags, status: REQUEST_STATUS.LOADING } }
    }
    case GET_ALL_TAGS_SUCCESS: {
      return {
        ...state,
        tags: {
          ...state.tags,
          list: action.payload.tagCategories,
          status: REQUEST_STATUS.SUCCESS,
        },
      }
    }
    case GET_ALL_TAGS_FAILURE: {
      return {
        ...state,
        tags: {
          ...state.tags,
          error: action.payload.error,
          status: REQUEST_STATUS.FAILURE,
        },
      }
    }
    default:
      return state
  }
}

export default booksDrawerReducer
