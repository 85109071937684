import type {
  ApiGetOneUser,
  ManagementGender,
  RelationshipAssignment,
} from '@/api/lms/managementUsers'
import type { STUDENT_RESTRICTION } from '@/api/lms/students'
import type { AssignmentsList } from '@/legacy/redux/types/assignments'
import type { DocTypeOption, Document } from '@/legacy/redux/types/docTypesOptions'
import type { ClearState } from '@/legacy/redux/types/generic'
import type { ErrorPayload, GenericAction, GenericActionWithType } from '@/legacy/redux/utils'

export interface UserDetailsModalState {
  open: boolean
  loading: boolean
  error: string
  name: string
  lastName: string
  document: Document
  email: string
  birthday: string
  phone: string
  ppi: boolean
  gender: ManagementGender
  assignmentsList: AssignmentsList[]
  relationshipAssignments: RelationshipAssignment[]
  studentRestrictions: STUDENT_RESTRICTION[]
}

// Get one user
export const OPEN_AND_POPULATE_MODAL_REQUEST = 'USERS/OPEN_AND_POPULATE_MODAL_REQUEST'
export const OPEN_AND_POPULATE_MODAL_SUCCESS = 'USERS/OPEN_AND_POPULATE_MODAL_SUCCESS'
export const OPEN_AND_POPULATE_MODAL_FAILURE = 'USERS/OPEN_AND_POPULATE_MODAL_FAILURE'

export type OpenAndPopulateModalRequest = GenericActionWithType<
  typeof OPEN_AND_POPULATE_MODAL_REQUEST,
  { id: number }
>

export type OpenAndPopulateModalSuccess = GenericActionWithType<
  typeof OPEN_AND_POPULATE_MODAL_SUCCESS,
  { user: ApiGetOneUser; docType: DocTypeOption; failed: string[] }
>

export interface OpenAndPopulateModalFailure extends GenericAction {
  type: typeof OPEN_AND_POPULATE_MODAL_FAILURE
  payload: ErrorPayload
}

export type OpenAndPopulateModalAction =
  | OpenAndPopulateModalRequest
  | OpenAndPopulateModalSuccess
  | OpenAndPopulateModalFailure

export type UserDetailsModalAction = ClearState | OpenAndPopulateModalAction
