import pt from '@/locales/translations/pt/pt.json'
import common from '@/locales/translations/pt/common.json'
import glossary from '@/locales/translations/pt/glossary.json'
import validation from '@/locales/translations/pt/validation.json'

export default {
  translation: pt,
  common: common,
  glossary: glossary,
  validation: validation,
}
