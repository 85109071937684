import type { METHODS } from '@/sections/auth/constants'
import type {
  GetQuestionsFailure,
  LoginFailure as AnswerLoginFailure,
  LoginTimeout as AnswerLoginTimeout,
} from '@/sections/auth/answersLogin/types/answersLogin'
import { GetQuestionsTimeout } from '@/sections/auth/answersLogin/types/answersLogin'
import type { AcademicPeriod, Organization } from '@/api/lms/user'

export const GET_DOC_TYPES_REQUEST = 'LOGIN/GET_DOC_TYPES_REQUEST'
export const GET_DOC_TYPES_SUCCESS = 'LOGIN/GET_DOC_TYPES_SUCCESS'
export const GET_DOC_TYPES_FAILURE = 'LOGIN/GET_DOC_TYPES_FAILURE'
export const SET_DOCUMENT_TYPE = 'LOGIN/SET_DOCUMENT_TYPE'
export const SET_DNI = 'LOGIN/SET_DNI'
export const SET_PASSWORD = 'LOGIN/SET_PASSWORD'
export const SET_STEP = 'LOGIN/SET_STEP'
export const WHICH_LOGIN_REQUEST = 'LOGIN/WHICH_LOGIN_REQUEST'
export const WHICH_LOGIN_SUCCESS = 'LOGIN/WHICH_LOGIN_SUCCESS'
export const WHICH_LOGIN_FAILURE = 'LOGIN/WHICH_LOGIN_FAILURE'
export const LOGIN_REQUEST = 'LOGIN/LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN/LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN/LOGIN_FAILURE'
export const GOOGLE_LOGIN_FAILURE = 'LOGIN/GOOGLE_LOGIN_FAILURE'
export const GET_PASSWORD_TYPES_REQUEST = 'LOGIN/GET_PASSWORD_TYPES_REQUEST'
export const GET_PASSWORD_TYPES_SUCCESS = 'LOGIN/GET_PASSWORD_TYPES_SUCCESS'
export const GET_PASSWORD_TYPES_FAILURE = 'LOGIN/GET_PASSWORD_TYPES_FAILURE'
export const SELECT_ORGANIZATION = 'LOGIN/SELECT_ORGANIZATION'
export const SELECT_ACADEMIC_PERIOD = 'LOGIN/SELECT_ACADEMIC_PERIOD'
export const MICROSOFT_LOGIN_FAILURE = 'LOGIN/MICROSOFT_LOGIN_FAILURE'

export type Method = typeof METHODS.PASSWORD | typeof METHODS.QUESTIONS

export type Classroom = {
  division: string
  grade: string
  id: number
  shift: string
  stage: string
  year: 2020
}

export type Country = {
  id: number
  name: string
  flag: string
}
export type DocType = {
  country: Country
  id: number
  inputType: string
  max: number
  min: number
  name: string
  hidden: boolean
  icon: string
}
export type DocTypeOption = {
  value: number
  label: string
  flag: string
}

export type PasswordType = {
  id: number
  inputType: string
  max: number
  min: number
  name: string
}

export interface SetDocumentType {
  type: typeof SET_DOCUMENT_TYPE
  payload: {
    selectedDocType: number
  }
}

export interface SetDNI {
  type: typeof SET_DNI
  payload: {
    dni: string
  }
}

export interface SetPassword {
  type: typeof SET_PASSWORD
  payload: {
    password: string
  }
}

export interface SetStep {
  type: typeof SET_STEP
  payload: {
    step: string
  }
}

export interface GetDocTypesRequest {
  type: typeof GET_DOC_TYPES_REQUEST
}

export interface GetDocTypesSuccess {
  type: typeof GET_DOC_TYPES_SUCCESS
  payload: {
    docTypes: DocType[]
    docTypesOptions: DocTypeOption[]
    selectedDocType: number
  }
}

export interface GetDocTypesFailure {
  type: typeof GET_DOC_TYPES_FAILURE
  payload: {
    error: string
  }
}

export interface WhichLoginRequest {
  type: typeof WHICH_LOGIN_REQUEST
}

export interface WhichLoginSuccess {
  type: typeof WHICH_LOGIN_SUCCESS
  payload: {
    step: Method
    selectedPasswordType: PasswordType
  }
}

export interface WhichLoginFailure {
  type: typeof WHICH_LOGIN_FAILURE
  payload: {
    error: string
  }
}

export interface LoginRequest {
  type: typeof LOGIN_REQUEST
}

export interface LoginSuccess {
  type: typeof LOGIN_SUCCESS
  payload: {
    token: string
  }
}

export interface LoginFailure {
  type: typeof LOGIN_FAILURE
  payload: {
    error: string
  }
}
export interface GoogleLoginFailure {
  type: typeof GOOGLE_LOGIN_FAILURE
}

export interface SelectOrganization {
  type: typeof SELECT_ORGANIZATION
  payload: {
    selectedOrganization: Organization
  }
}

export interface SelectAcademicPeriod {
  type: typeof SELECT_ACADEMIC_PERIOD
  payload: {
    selectedAcademicPeriod: AcademicPeriod
  }
}

export type LoginState = {
  step: string
  selectedDocType: number
  docTypesOptions: DocTypeOption[]
  docTypes: DocType[]
  docTypesFetched: boolean
  selectedPasswordType: PasswordType
  passwordTypes: PasswordType[]
  passwordTypesFetched: boolean
  loading: false
  dni: ''
  password: ''
  error: ''
}

export interface GetPasswordTypesRequest {
  type: typeof GET_PASSWORD_TYPES_REQUEST
}

export interface GetPasswordTypesSuccess {
  type: typeof GET_PASSWORD_TYPES_SUCCESS
  payload: {
    passwordTypes: PasswordType[]
  }
}

export interface GetPasswordTypesFailure {
  type: typeof GET_PASSWORD_TYPES_FAILURE
  payload: {
    error: string
  }
}

export interface MicrosoftLoginFailure {
  type: typeof MICROSOFT_LOGIN_FAILURE
}

export type LoginActions =
  | GetDocTypesRequest
  | GetDocTypesSuccess
  | GetDocTypesFailure
  | SetDocumentType
  | SetDNI
  | SetPassword
  | SetStep
  | WhichLoginRequest
  | WhichLoginSuccess
  | WhichLoginFailure
  | LoginRequest
  | LoginSuccess
  | LoginFailure
  | GoogleLoginFailure
  | AnswerLoginFailure
  | AnswerLoginTimeout
  | GetQuestionsFailure
  | GetPasswordTypesRequest
  | GetPasswordTypesSuccess
  | GetPasswordTypesFailure
  | SelectOrganization
  | MicrosoftLoginFailure
