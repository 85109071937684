import type {
  BEGIN_EXAM_EVENT,
  CAMERA_STARTED_EVENT,
  CAMERA_STOPPED_EVENT,
  CONSOLE_OPEN_EVENT,
  EXAM_FINISHED_EVENT,
  EXAM_STARTED_EVENT,
  GET_EXAM_EVENT,
  RECORDING_STARTED_EVENT,
  RECORDING_STOPPED_EVENT,
  SHARE_SCREEN_EVENT,
  YOU_CAN_BEGIN_EVENT,
} from '@/sections/student/writtenExam/events'
import type { SocketMessage } from '@/sections/chat/types/chat'

export const BEGIN_EXAM = 'STUDENT_WRITTEN_EXAM/BEGIN-EXAM'
export const SHARE_SCREEN = 'STUDENT_WRITTEN_EXAM/SHARE_SCREEN'
export const SHARE_SCREEN_FAILED = 'STUDENT_WRITTEN_EXAM/SHARE_SCREEN_FAILED'
export const BEGIN_CAMERA = 'STUDENT_WRITTEN_EXAM/BEGIN_CAMERA'
export const YOU_CAN_BEGIN = 'STUDENT_WRITTEN_EXAM/YOU_CAN_BEGIN'
export const EXAM_FINISHED = 'STUDENT_WRITTEN_EXAM/EXAM_FINISHED'

export const RESHARE_SCREEN = 'STUDENT_WRITTEN_EXAM/RESHARE_SCREEN'
export const BEGIN_CAMERA_ERROR = 'STUDENT_WRITTEN_EXAM/BEGIN_CAMERA_ERROR'

export const INCREASE_SOCKET_ID = 'STUDENT_WRITTEN_EXAM/INCREASE_SOCKET_ID'
export const SET_SOCKET_AUTHENTICATION_STATUS =
  'STUDENT_WRITTEN_EXAM/SET_SOCKET_AUTHENTICATION_STATUS'

export const ADD_STATUS_FAILED = 'STUDENT_WRITTEN_EXAM/ADD_STATUS_FAILED'
export const REMOVE_STATUS_FAILED = 'STUDENT_WRITTEN_EXAM/REMOVE_STATUS_FAILED'
export const SET_SEND_ANSWERS_MODAL_OPEN = 'STUDENT_WRITTEN_EXAM/SET_SEND_ANSWERS_MODAL_OPEN'

export const HAS_EXES_STARTED_REQUEST = 'STUDENT_WRITTEN_EXAM/HAS_EXES_STARTED_REQUEST'
export const HAS_EXES_STARTED_SUCCESS = 'STUDENT_WRITTEN_EXAM/HAS_EXES_STARTED_SUCCESS'
export const HAS_EXES_STARTED_FAILURE = 'STUDENT_WRITTEN_EXAM/HAS_EXES_STARTED_FAILURE'

export const CREATE_HELP_REQUEST_REQUEST = 'STUDENT_WRITTEN_EXAM/CREATE_HELP_REQUEST_REQUEST'
export const CREATE_HELP_REQUEST_SUCCESS = 'STUDENT_WRITTEN_EXAM/CREATE_HELP_REQUEST_SUCCESS'
export const CREATE_HELP_REQUEST_FAILURE = 'STUDENT_WRITTEN_EXAM/CREATE_HELP_REQUEST_FAILURE'
export const CANCEL_HELP_REQUEST_REQUEST = 'STUDENT_WRITTEN_EXAM/CANCEL_HELP_REQUEST_REQUEST'
export const CANCEL_HELP_REQUEST_SUCCESS = 'STUDENT_WRITTEN_EXAM/CANCEL_HELP_REQUEST_SUCCESS'
export const CANCEL_HELP_REQUEST_FAILURE = 'STUDENT_WRITTEN_EXAM/CANCEL_HELP_REQUEST_FAILURE'
export const GET_HELP_REQUESTS_REQUEST = 'STUDENT_WRITTEN_EXAM/GET_HELP_REQUESTS_REQUEST'
export const GET_HELP_REQUESTS_SUCCESS = 'STUDENT_WRITTEN_EXAM/GET_HELP_REQUESTS_SUCCESS'
export const GET_HELP_REQUESTS_FAILURE = 'STUDENT_WRITTEN_EXAM/GET_HELP_REQUESTS_FAILURE'
export const UPDATE_HELP_REQUEST = 'STUDENT_WRITTEN_EXAM/UPDATE_HELP_REQUEST'

export const ACTIVITY_REQUEST = 'STUDENT_WRITTEN_EXAM/ACTIVITY_REQUEST'
export const ACTIVITY_SUCCESS = 'STUDENT_WRITTEN_EXAM/ACTIVITY_SUCCESS'
export const ACTIVITY_FAILURE = 'STUDENT_WRITTEN_EXAM/ACTIVITY_FAILURE'
export const MARK_AS_COMPLETED_REQUEST = 'STUDENT_WRITTEN_EXAM/MARK_AS_COMPLETED_STUDENT_REQUEST'
export const MARK_AS_COMPLETED_SUCCESS = 'STUDENT_WRITTEN_EXAM/MARK_AS_COMPLETED_STUDENT_SUCCESS'
export const MARK_AS_COMPLETED_PARTIAL_SUCCESS =
  'STUDENT_WRITTEN_EXAM/MARK_AS_COMPLETED_PARTIAL_SUCCESS'
export const MARK_AS_COMPLETED_FAILURE = 'STUDENT_WRITTEN_EXAM/MARK_AS_COMPLETED_STUDENT_FAILURE'
export const DOWNLOAD_FILE_REQUEST = 'STUDENT_WRITTEN_EXAM/DOWNLOAD_FILE_REQUEST'
export const DOWNLOAD_FILE_SUCCESS = 'STUDENT_WRITTEN_EXAM/DOWNLOAD_FILE_SUCCESS'
export const DOWNLOAD_FILE_FAILURE = 'STUDENT_WRITTEN_EXAM/DOWNLOAD_FILE_FAILURE'

export const ADD_TEACHER_MESSAGE = 'STUDENT_WRITTEN_EXAM/ADD_TEACHER_MESSAGE'
export const ADD_SANDBOX_TEACHER_MESSAGE = 'STUDENT_WRITTEN_EXAM/ADD_SANDBOX_TEACHER_MESSAGE'
export const SET_RECORDING = 'STUDENT_WRITTEN_EXAM/SET_RECORDING'
export const RESET_EXAM = 'STUDENT_WRITTEN_EXAM/RESET_EXAM'
export const SET_FONT_SIZE = 'STUDENT_WRITTEN_EXAM/SET_FONT_SIZE'

export type Status =
  | typeof BEGIN_EXAM_EVENT
  | typeof SHARE_SCREEN_EVENT
  | typeof RECORDING_STARTED_EVENT
  | typeof CAMERA_STARTED_EVENT
  | typeof GET_EXAM_EVENT
  | typeof YOU_CAN_BEGIN_EVENT
  | typeof EXAM_STARTED_EVENT
  | typeof EXAM_FINISHED_EVENT

export type StatusFailed =
  | typeof RECORDING_STOPPED_EVENT
  | typeof CAMERA_STOPPED_EVENT
  | typeof CONSOLE_OPEN_EVENT

export interface ExamFinished {
  type: typeof EXAM_FINISHED
}

export interface Organization {
  id: number
  name: string
}

export interface Classroom {
  division: string
  grade: string
  id: number
  organization: Organization
  organizationType: string
  shift: string
  year: number
}

export interface Subject {
  color: string
  id: number
  name: string
}

export interface Activity {
  chatEnabled: boolean
  classroom: Classroom
  completedCurrent: number
  createdAt: string
  date: string
  deadline: string
  endDate: string
  evaluationType: number
  gradedCurrent: number
  id: number
  publicationDate: string
  startDate: string
  subject: Subject
  title: string
  type: string
}

export interface HelpRequest {
  activityId: number
  callerId: number
  createdAt: string
  id: number
  responderId: number
  status: string
  videocallUrl: string
}

export interface ExamStatus {
  started?: boolean
  finished?: boolean
}

interface InternalRecorder {
  resetVideoStreams: (unactiveStreams: MediaStream[]) => void
  addStreams: (newStreams: MediaStream[]) => void
}

export interface RecordRTC {
  startRecording: () => void
  stopRecording: () => void
  getInternalRecorder: () => InternalRecorder
  getBlob: () => Blob
}

export interface IncreaseSocketID {
  type: typeof INCREASE_SOCKET_ID
}

export interface SetSocketAuthenticationStatus {
  type: typeof SET_SOCKET_AUTHENTICATION_STATUS
  payload: {
    sockedAuthenticated: boolean
  }
}

export interface BeginExam {
  type: typeof BEGIN_EXAM
  payload: {
    activityID: number
    classroomID: number
  }
}

export interface ShareScreenFailed {
  type: typeof SHARE_SCREEN_FAILED
  payload: {
    error: string
  }
}

export interface ShareScreen {
  type: typeof SHARE_SCREEN
  payload: {
    recorder: RecordRTC
    streams: MediaStream[]
    cameraDelay: number
  }
}

export interface BeginCamera {
  type: typeof BEGIN_CAMERA
}

export interface BeginCameraError {
  type: typeof BEGIN_CAMERA_ERROR
  payload: {
    error: string
  }
}

export interface YouCanBegin {
  type: typeof YOU_CAN_BEGIN
}

export interface AddStatusFailed {
  type: typeof ADD_STATUS_FAILED
  payload: {
    statusFailed: StatusFailed
  }
}

export interface RemoveStatusFailed {
  type: typeof REMOVE_STATUS_FAILED
  payload: {
    statusFailed: StatusFailed
  }
}

export interface ReshareScreen {
  type: typeof RESHARE_SCREEN
  payload: {
    recorder: RecordRTC
    streams: MediaStream[]
  }
}

export interface HasEXESStartedRequest {
  type: typeof HAS_EXES_STARTED_REQUEST
}

export interface HasEXESStartedSuccess {
  type: typeof HAS_EXES_STARTED_SUCCESS
  payload: {
    status: ExamStatus
  }
}

export interface HasEXESStartedFailure {
  type: typeof HAS_EXES_STARTED_FAILURE
  payload: {
    error: string
  }
}

export interface ActivityRequest {
  type: typeof ACTIVITY_REQUEST
}

export interface ActivitySuccess {
  type: typeof ACTIVITY_SUCCESS
  payload: {
    activity: Activity
  }
}

export interface ActivityFailure {
  type: typeof ACTIVITY_FAILURE
  payload: {
    error: string
  }
}

export interface CreateHelpRequestRequest {
  type: typeof CREATE_HELP_REQUEST_REQUEST
}

export interface CreateHelpRequestSuccess {
  type: typeof CREATE_HELP_REQUEST_SUCCESS
  payload: {
    helpRequest: HelpRequest
  }
}

export interface CreateHelpRequestFailure {
  type: typeof CREATE_HELP_REQUEST_FAILURE
  payload: {
    error: string
  }
}

export interface AcceptedHelpRequest {
  type: typeof UPDATE_HELP_REQUEST
}

export interface CancelHelpRequestRequest {
  type: typeof CANCEL_HELP_REQUEST_REQUEST
}

export interface CancelHelpRequestSuccess {
  type: typeof CANCEL_HELP_REQUEST_SUCCESS
  payload: {
    helpRequest: HelpRequest
  }
}

export interface CancelHelpRequestFailure {
  type: typeof CANCEL_HELP_REQUEST_FAILURE
  payload: {
    error: string
  }
}

export interface GetHelpRequestsRequest {
  type: typeof GET_HELP_REQUESTS_REQUEST
}

export interface GetHelpRequestsSuccess {
  type: typeof GET_HELP_REQUESTS_SUCCESS
}

export interface GetHelpRequestsFailure {
  type: typeof GET_HELP_REQUESTS_FAILURE
  payload: {
    error: string
  }
}

export interface MarkAsCompleted {
  type: typeof MARK_AS_COMPLETED_REQUEST
  payload: {
    partial: boolean
  }
}

export interface MarkAsCompletedSuccess {
  type: typeof MARK_AS_COMPLETED_SUCCESS
  payload: {
    activityID: number
  }
}

export interface MarkAsCompletedPartialSuccess {
  type: typeof MARK_AS_COMPLETED_PARTIAL_SUCCESS
}

export interface MarkAsCompletedFailure {
  type: typeof MARK_AS_COMPLETED_FAILURE
  payload: {
    error: string
  }
}

export interface DownloadFileRequest {
  type: typeof DOWNLOAD_FILE_REQUEST
  payload: {
    id: number
  }
}

export interface DownloadFileSuccess {
  type: typeof DOWNLOAD_FILE_SUCCESS
}

export interface DownloadFileFailure {
  type: typeof DOWNLOAD_FILE_FAILURE
  payload: {
    error: string
  }
}

export interface SetSendAnswersModalOpen {
  type: typeof SET_SEND_ANSWERS_MODAL_OPEN
  payload: {
    open: boolean
  }
}

export interface AddTeacherMessage {
  type: typeof ADD_TEACHER_MESSAGE
  payload: {
    teacherMessage: SocketMessage
  }
}

export interface AddTeacherSandboxMessage {
  type: typeof ADD_SANDBOX_TEACHER_MESSAGE
  payload: {
    teacherMessage: SocketMessage
  }
}

export interface SetRecording {
  type: typeof SET_RECORDING
  payload: {
    recording: boolean
  }
}

export interface ResetExam {
  type: typeof RESET_EXAM
}
export interface SetFontSize {
  type: typeof SET_FONT_SIZE
  payload: {
    fontSize: string
  }
}

export interface WrittenExamState {
  fontSize: string
  socketID: number
  socketAuthenticated: boolean
  helpRequest: HelpRequest | null
  status: Status
  statusFailed: StatusFailed[]
  recorder: RecordRTC | null
  streams: MediaStream[]
  loading: boolean
  loadingMarkAsCompleted: boolean
  loadingHelpRequest: boolean
  finished: boolean
  error: string
  errorModal: string
  errorHelpRequest: string
  open: boolean
  sendAnswersModalOpen: boolean
  activity: Activity
  teacherMessages: SocketMessage[]
  downloading: number
  recording: boolean
  cameraDelay: number
  extraTime: number
}

export type WrittenExamActions =
  | IncreaseSocketID
  | SetSocketAuthenticationStatus
  | BeginExam
  | ShareScreen
  | ShareScreenFailed
  | BeginCamera
  | BeginCameraError
  | YouCanBegin
  | AddStatusFailed
  | RemoveStatusFailed
  | ReshareScreen
  | HasEXESStartedRequest
  | HasEXESStartedSuccess
  | HasEXESStartedFailure
  | ActivityRequest
  | ActivitySuccess
  | ActivityFailure
  | CreateHelpRequestRequest
  | CreateHelpRequestSuccess
  | CreateHelpRequestFailure
  | AcceptedHelpRequest
  | CancelHelpRequestRequest
  | CancelHelpRequestSuccess
  | CancelHelpRequestFailure
  | GetHelpRequestsRequest
  | GetHelpRequestsSuccess
  | GetHelpRequestsFailure
  | MarkAsCompleted
  | MarkAsCompletedSuccess
  | MarkAsCompletedFailure
  | DownloadFileRequest
  | DownloadFileFailure
  | DownloadFileSuccess
  | SetSendAnswersModalOpen
  | AddTeacherMessage
  | AddTeacherSandboxMessage
  | SetRecording
  | ExamFinished
  | ResetExam
  | SetFontSize
