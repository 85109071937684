import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import {
  GET_CLASSROOM_SUBJECTS_AS_MGMT_FAILURE,
  GET_CLASSROOM_SUBJECTS_AS_MGMT_REQUEST,
  GET_CLASSROOM_SUBJECTS_AS_MGMT_SUCCESS,
} from '@/legacy/redux/types/classrooms'
import {
  LIST_SUBJECTS_AS_ADMIN_FAILURE,
  LIST_SUBJECTS_AS_ADMIN_REQUEST,
  LIST_SUBJECTS_AS_ADMIN_SUCCESS,
  LIST_SUBJECTS_AS_PRINCIPAL_FAILURE,
  LIST_SUBJECTS_AS_PRINCIPAL_REQUEST,
  LIST_SUBJECTS_AS_PRINCIPAL_SUCCESS,
} from '@/legacy/redux/types/subjects'
import { checkRequestOrigin, setGenericField, toggleEntity } from '@/legacy/redux/utils'
import type {
  ClassroomsSubjectModalState,
  ClassroomSubjectModalAction,
} from '@/sections/management/types/classroomSubjectsModal'
import { SET_OPEN, TOGGLE_SUBJECT } from '@/sections/management/types/classroomSubjectsModal'

const initialState: ClassroomsSubjectModalState = {
  open: false,
  loading: false,
  error: '',
  pagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0,
  },
  subjects: [],
  selectedSubjects: [],
  search: '',
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_CLASSROOM_SUBJECTS_MODAL

const AdminOrganizationsModalReducer: Reducer<
  ClassroomsSubjectModalState,
  ClassroomSubjectModalAction
> = (state = initialState, action): ClassroomsSubjectModalState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case SET_OPEN:
      return { ...state, open: true, selectedSubjects: action.payload.selectedSubjects }
    case LIST_SUBJECTS_AS_ADMIN_REQUEST:
    case LIST_SUBJECTS_AS_PRINCIPAL_REQUEST:
      return {
        ...state,
        loading: true,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    case LIST_SUBJECTS_AS_ADMIN_SUCCESS:
    case LIST_SUBJECTS_AS_PRINCIPAL_SUCCESS:
      return {
        ...state,
        loading: false,
        subjects: action.payload.subjects,
        pagination: {
          ...state.pagination,
          count: action.payload.count,
        },
      }
    case LIST_SUBJECTS_AS_ADMIN_FAILURE:
    case LIST_SUBJECTS_AS_PRINCIPAL_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case GET_CLASSROOM_SUBJECTS_AS_MGMT_REQUEST:
      return { ...state, loading: true }
    case GET_CLASSROOM_SUBJECTS_AS_MGMT_SUCCESS:
      return { ...state, loading: false, subjects: action.payload.subjects }
    case GET_CLASSROOM_SUBJECTS_AS_MGMT_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case TOGGLE_SUBJECT: {
      const { subject } = action.payload

      const subjectsAfterToggle = toggleEntity(state.selectedSubjects, subject, 'id')

      return {
        ...state,
        selectedSubjects: subjectsAfterToggle,
      }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, AdminOrganizationsModalReducer, requestDestination)
