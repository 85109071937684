import type { Filter, Pagination, Params, SearchFilters, Sorter } from './common'

export const ORGANIZATION_REQUEST = 'EB_ADMIN/ORGANIZATIONS/ORGANIZATION_REQUEST'
export const ORGANIZATION_SUCCESS = 'EB_ADMIN/ORGANIZATIONS/ORGANIZATION_SUCCESS'
export const ORGANIZATION_FAILURE = 'EB_ADMIN/ORGANIZATIONS/ORGANIZATION_FAILURE'
export const SET_SORTER = 'EB_ADMIN/ORGANIZATIONS/SET_SORTER'
export const SET_FILTER = 'EB_ADMIN/ORGANIZATIONS/SET_FILTER'
export const SET_PAGINATION = 'EB_ADMIN/ORGANIZATIONS/SET_PAGINATION'
export const CLEAR_PARAMS = 'EB_ADMIN/ORGANIZATIONS/CLEAR_PARAMS'
export const SET_OPEN = 'EB_ADMIN/ORGANIZATIONS/SET_OPEN'

export const GET_COUNTRIES_REQUEST = 'EB_ADMIN/ORGANIZATIONS/GET_COUNTRIES_REQUEST'
export const GET_COUNTRIES_SUCCESS = 'EB_ADMIN/ORGANIZATIONS/GET_COUNTRIES_SUCCESS'
export const GET_COUNTRIES_FAILURE = 'EB_ADMIN/ORGANIZATIONS/GET_COUNTRIES_FAILURE'

export const SET_NAME = 'EB_ADMIN/ORGANIZATIONS/SET_NAME'
export const SET_COUNTRY = 'EB_ADMIN/ORGANIZATIONS/SET_COUNTRY'
export const SET_STATE = 'EB_ADMIN/ORGANIZATIONS/SET_STATE'
export const SET_CITY = 'EB_ADMIN/ORGANIZATIONS/SET_CITY'
export const SET_TYPE = 'EB_ADMIN/ORGANIZATIONS/SET_TYPE'
export const SET_ADDRESS = 'EB_ADMIN/ORGANIZATIONS/SET_ADDRESS'
export const SET_PHONE = 'EB_ADMIN/ORGANIZATIONS/SET_PHONE'
export const SET_LEGAL_REP_NAME = 'EB_ADMIN/ORGANIZATIONS/SET_LEGAL_REP_NAME'
export const SET_LEGAL_REP_PHONE = 'EB_ADMIN/ORGANIZATIONS/SET_LEGAL_REP_PHONE'
export const SET_LEGAL_REP_EMAIL = 'EB_ADMIN/ORGANIZATIONS/SET_LEGAL_REP_EMAIL'

export interface SetName {
  type: typeof SET_NAME
  payload: {
    name: string
  }
}

export interface SetCountry {
  type: typeof SET_COUNTRY
  payload: {
    countryId: number
  }
}

export interface SetState {
  type: typeof SET_STATE
  payload: {
    state: string
  }
}

export interface SetCity {
  type: typeof SET_CITY
  payload: {
    city: string
  }
}

export interface SetType {
  type: typeof SET_TYPE
  payload: {
    type: string
  }
}

export interface SetAddress {
  type: typeof SET_ADDRESS
  payload: {
    address: string
  }
}

export interface SetPhone {
  type: typeof SET_PHONE
  payload: {
    phone: string
  }
}

export interface SetLegalRepName {
  type: typeof SET_LEGAL_REP_NAME
  payload: {
    legalRepName: string
  }
}

export interface SetLegalRepPhone {
  type: typeof SET_LEGAL_REP_PHONE
  payload: {
    legalRepPhone: string
  }
}

export interface SetLegalRepEmail {
  type: typeof SET_LEGAL_REP_EMAIL
  payload: {
    legalRepEmail: string
  }
}

export interface SetName {
  type: typeof SET_NAME
  payload: {
    name: string
  }
}

export interface SetName {
  type: typeof SET_NAME
  payload: {
    name: string
  }
}

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    open: boolean
    organization?: OrganizationRequest
  }
}

export interface ClearParams {
  type: typeof CLEAR_PARAMS
}

export interface GetOrganizationRequest {
  type: typeof ORGANIZATION_REQUEST
}

export interface GetOrganizationSuccess {
  type: typeof ORGANIZATION_SUCCESS
  payload: {
    organizations: Organization[]
    total: number
    params: Params
    resetPagination?: boolean
  }
}

export interface GetOrganizationFailure {
  type: typeof ORGANIZATION_FAILURE
  payload: {
    error: string
  }
}

export interface GetCountriesRequest {
  type: typeof GET_COUNTRIES_REQUEST
}

export interface GetCountriesSuccess {
  type: typeof GET_COUNTRIES_SUCCESS
  payload: {
    countries: Country[]
    countryOptions: CountryOption[]
  }
}

export interface GetCountriesFailure {
  type: typeof GET_COUNTRIES_FAILURE
  payload: {
    error: string
  }
}

export interface SetSorter {
  type: typeof SET_SORTER
  payload: {
    sorter: Sorter
  }
}

export interface SetFilter {
  type: typeof SET_FILTER
  payload: {
    filter: Filter
  }
}

export interface SetPagination {
  type: typeof SET_PAGINATION
  payload: {
    pagination: Pagination
  }
}

export interface OrganizationRequest {
  id?: number
  name: string
  countryId: number
  state: string
  city: string
  type: string
  address: string
  phone: string
  legalRepName: string
  legalRepPhone: string
  legalRepEmail: string
}

export interface Organization {
  id?: number
  name: string
  country: Country
  state: string
  city: string
  type: string
  address: string
  phone: string
  legalRepName: string
  legalRepPhone: string
  legalRepEmail: string
}

export interface Country {
  id: number
  name: string
}

export type CountryOption = {
  value: number
  label: string
  flag: string
}

export interface OrganizationState {
  countryOptions: CountryOption[]
  organizations: Organization[]
  organization: OrganizationRequest
  error: string
  loading: boolean
  loadingModify: boolean
  params: Params
  total: number
  sorter: Sorter
  filter: Filter
  searchFilters: SearchFilters[]
  pagination: Pagination
}

export type OrganizationActions =
  | GetOrganizationRequest
  | GetOrganizationSuccess
  | GetOrganizationFailure
  | ClearParams
  | SetSorter
  | SetFilter
  | SetPagination
  | SetOpen
  | GetCountriesRequest
  | GetCountriesSuccess
  | GetCountriesFailure
  | SetName
  | SetCountry
  | SetState
  | SetCity
  | SetType
  | SetAddress
  | SetPhone
  | SetLegalRepName
  | SetLegalRepPhone
  | SetLegalRepEmail
