import type { ReduxRequestOrigin } from '@/legacy/redux/constants'

export const SET_FIELD = 'GENERIC/SET_FIELD'
export const TOGGLE_OPEN = 'GENERIC/TOGGLE_OPEN'
export const CLEAR_STATE = 'GENERIC/CLEAR_STATE'

export interface BasePayload {
  requestOrigins: ReduxRequestOrigin[]
}

export interface GenericFieldPayload<N, V> extends BasePayload {
  fieldName: N
  fieldValue: V
}

export interface SetField<N, V> {
  type: typeof SET_FIELD
  payload: GenericFieldPayload<N, V>
}

export interface ClearState {
  type: typeof CLEAR_STATE
  payload: BasePayload
}

export interface ToggleOpenPayload extends BasePayload {
  open: boolean
}

export interface ToggleOpen {
  type: typeof TOGGLE_OPEN
  payload: ToggleOpenPayload
}
