import React from 'react'
import { Avatar, Paper, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Button from './button'
import classNames from 'clsx'
import chroma from 'chroma-js'
import Title from './title'
import Subtitle from './subtitle'
import LiveSvg from '@/legacy/components/v1/svgs/activityRibbon/avv'

const ActivityCardLive = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only('xs'))
  if (isMobile) return <ActivityCardLiveMobile {...props} />
  return <ActivityCardLiveDesktop {...props} />
}

ActivityCardLive.defaultProps = {
  Icon: ({ className }) => <LiveSvg className={className} alt="live" />,
  subject: {},
  buttonEnabled: true,
  buttonLabel: '',
  description: '',
  buttonProps: {},
  iconProps: {
    avatarColor: 'linear-gradient(0deg, rgba(244, 92, 58, 0.1), rgba(244, 92, 58, 0.1)), #FFFFFF;',
  },
}

export default ActivityCardLive

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'row',
    borderRadius: '8px',
    alignItems: 'center',
    margin: '0',
    display: 'flex',
    padding: '12px',
    transition: 'background-color 100ms',
    cursor: 'pointer',
    background: theme.palette.primary.main,
  },
  avatarContainer: {
    margin: '10px 0',
    padding: '0 15px',
    width: 'fit-content',
  },
  titleContainer: {
    marginLeft: 24,
  },
  firstCircle: {
    backgroundColor: 'rgba(252,217,209, 0.1)',
    width: 96,
    height: 96,
    borderRadius: 96,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  secondCircle: {
    backgroundColor: 'rgba(252,217,209, 0.3)',
    width: 80,
    height: 80,
    borderRadius: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: ({ avatarColor }) => ({
    background: avatarColor,
    width: 64,
    height: 64,
  }),
  title: {
    color: '#FFF',
    margin: 0,
  },
  description: {
    color: 'white',
    fontWeight: 'initial',
  },
  buttonContainer: {
    marginRight: 24,
    width: 200,
    textAlign: 'right',
    flex: 1,
  },
  button: {
    background: 'white',
    boxShadow: '0 0 0 1px ' + theme.palette.primary.main,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    padding: '13.5px 16px',
    fontSize: 16,
    lineHeight: '18px',
    '& span span svg path': {
      fill: theme.palette.primary.main,
    },
    '&:hover': {
      background: theme.palette.secondary.main,
      boxShadow: '0 0 0 1px ' + theme.palette.secondary.main,
      color: '#FFF',
      '& span span svg path': {
        fill: '#FFF',
      },
    },
    '&:active': {
      background: chroma(theme.palette.secondary.main).brighten(0.5).hex(),
      boxShadow: '0 0 0 1px ' + chroma(theme.palette.secondary.main).brighten(0.5).hex(),
      color: '#FFF',
    },
  },
  iconRight: {
    justifyItems: 'center',
    display: 'flex',
    marginLeft: 10,
  },
  avatarImg: {
    width: 64,
    height: 64,
  },
}))

const ActivityCardLiveDesktop = (props) => {
  const {
    Icon,
    iconProps: { avatarColor },
    title,
    description,
    buttonLabel,
    buttonClick,
    buttonEnabled,
    buttonProps,
  } = props

  const { IconRight, iconRightClassname = '' } = buttonProps

  const classes = useStyles({ avatarColor })

  const disabled = !buttonEnabled

  return (
    <Paper
      elevation={0}
      classes={{
        root: classNames(classes.root),
      }}
    >
      <div className={classes.avatarContainer}>
        <div className={classes.firstCircle}>
          <div className={classes.secondCircle}>
            <Avatar className={classes.avatar}>
              <Icon className={classes.avatarImg} />
            </Avatar>
          </div>
        </div>
      </div>
      <div className={classes.titleContainer}>
        <Title className={classes.title}>{title}</Title>
        <Subtitle className={classes.description}>{description}</Subtitle>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          IconRight={IconRight}
          iconRightClassname={iconRightClassname}
          className={classNames(classes.button, { [classes.buttonDisabled]: disabled })}
          onClick={buttonClick}
          disabled={disabled}
        >
          {buttonLabel}
        </Button>
      </div>
    </Paper>
  )
}

const useMobileStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '8px',
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    transition: 'background-color 100ms',
    cursor: 'pointer',
    background: theme.palette.primary.main,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    color: '#FFF',
    margin: 0,
    fontSize: 18,
  },
  description: {
    color: 'white',
    fontWeight: 'initial',
    fontSize: 14,
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    marginTop: 8,
  },
  button: {
    background: 'white',
    boxShadow: '0 0 0 1px ' + theme.palette.primary.main,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    padding: '13.5px 16px',
    fontSize: 16,
    lineHeight: '18px',
    '& span span svg path': {
      fill: theme.palette.primary.main,
    },
    '&:hover': {
      background: theme.palette.secondary.main,
      boxShadow: '0 0 0 1px ' + theme.palette.secondary.main,
      color: '#FFF',
      '& span span svg path': {
        fill: '#FFF',
      },
    },
    '&:active': {
      background: chroma(theme.palette.secondary.main).brighten(0.5).hex(),
      boxShadow: '0 0 0 1px ' + chroma(theme.palette.secondary.main).brighten(0.5).hex(),
      color: '#FFF',
    },
  },
  iconRight: {
    justifyItems: 'center',
    display: 'flex',
    marginLeft: 10,
  },
}))

const ActivityCardLiveMobile = (props) => {
  const { title, description, buttonLabel, buttonClick, buttonEnabled, buttonProps } = props

  const { IconRight, iconRightClassname = '' } = buttonProps

  const classes = useMobileStyles()

  const disabled = !buttonEnabled

  return (
    <Paper
      elevation={0}
      classes={{
        root: classNames(classes.root),
      }}
    >
      <div className={classes.row}>
        <div>
          <Title className={classes.title}>{title}</Title>
          <Subtitle className={classes.description}>{description}</Subtitle>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          IconRight={IconRight}
          iconRightClassname={iconRightClassname}
          className={classNames(classes.button, { [classes.buttonDisabled]: disabled })}
          onClick={buttonClick}
          disabled={disabled}
        >
          {buttonLabel}
        </Button>
      </div>
    </Paper>
  )
}
