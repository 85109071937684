import types, { START_RECORDING, STOP_RECORDING } from '@/sections/conference/types/conference'
import { persistReducer } from 'redux-persist'
import localforage from 'localforage'
import * as BORtypes from '@/sections/conference/types/breakoutRooms'

const initialState = {
  recorder: null,
  streams: [],
  room: '',
  classroomID: 0,
  activityID: 0,
  activityTitle: '',
  started: false,
  loading: false,
  error: '',
  token: '',
  invalidURL: false,
  recordingEnabled: false,
}

function root(state = initialState, { type, payload }) {
  switch (type) {
    case START_RECORDING:
      return { ...state, recorder: payload.recorder, streams: payload.streams }
    case STOP_RECORDING:
      return { ...state, recorder: null }
    case types.SET_ROOM:
      return {
        ...state,
        room: payload.room,
        classroomID: payload.classroomID,
        activityID: payload.activityID,
        activityTitle: payload.activityTitle,
        recordingEnabled: payload.recordingEnabled,
      }
    case types.SET_ROOM_WITH_URL_PARAMS: {
      return {
        ...state,
        room: payload.room,
        classroomID: payload.classroomID,
        activityID: payload.activityID,
        activityTitle: payload.activityTitle,
        recordingEnabled: payload.recordingEnabled,
        invalidURL: false, // TODO this should validate whether classroomID, activityID, etc are 0 or ''
      }
    }
    case types.OPEN_AVV:
      return state
    case types.START_AVV_REQUEST:
      return { ...state, loading: true }
    case types.START_AVV_SUCCESS:
      return { ...state, loading: false, started: true }
    case types.START_AVV_FAILURE:
      return { ...state, loading: false, error: payload.error }
    case types.HAS_STARTED_REQUEST:
      return state
    case types.HAS_STARTED_SUCCESS:
      return { ...state, started: payload.started }
    case types.HAS_STARTED_FAILURE:
      return { ...state, error: payload.error }
    case types.ADD_EVENT_REQUEST:
      return state
    case types.ADD_EVENT_SUCCESS:
      return state
    case types.ADD_EVENT_FAILURE:
      return state
    case types.GET_TOKEN_REQUEST:
      return { ...state, loading: true, error: '' }
    case types.GET_TOKEN_SUCCESS:
      return { ...state, token: payload.token, loading: false }
    case types.GET_TOKEN_FAILURE:
      return { ...state, loading: false, error: payload.error }
    case BORtypes.GET_BREAKOUT_ROOMS_SUCCESS:
    case BORtypes.UPDATE_BOR_FROM_SOCKET: {
      const { breakoutRooms, userId } = payload

      const newRoom = breakoutRooms.find((room) => room.participants.find((p) => p.id === userId))

      if (!newRoom) return state

      return { ...state, room: newRoom.url, activityTitle: newRoom.name }
    }
    default:
      return state
  }
}

const persistConfig = {
  key: 'conference',
  storage: localforage,
  whitelist: ['room', 'classroomID', 'activityID', 'activityTitle'],
}

export default persistReducer(persistConfig, root)
