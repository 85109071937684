import type { Moment } from 'moment'
import { apiPrivate } from '@/api'
import { url } from '@aula/config'
import { addNewCall, getLastCall, TEACHER_ACTIVITIES } from '@/legacy/debounce'
import type { AppThunk } from '@/state/thunk'
import { pagesToLimit } from '@/legacy/utils/generalUtils'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import studentListActions from '@/sections/teacher/studentList/actions/studentList'
import { getTimeRange, TABS } from '@/sections/teacher/utils/activities'
import { LIMIT } from '@/sections/teacher/overview/constants'
import type {
  ActivitiesSuccessPayload,
  ActivitiesSummary,
  AttendanceSummary,
  CompletionSummary,
  Evaluation,
  GetActivitiesSummarySuccess,
  GetAttendanceSummarySuccess,
  GetCompletionSummarySuccess,
  GetEvaluationsSummarySuccess,
  GetUncorrectedActivitiesSuccess,
  OverviewTab,
  Pages,
  Pagination,
  RequestOptions,
  UncorrectedActivity,
} from '@/sections/teacher/overview/types/overview'
import {
  GET_ACTIVITIES_FAILURE,
  GET_ACTIVITIES_REQUEST,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITIES_SUMMARY_FAILURE,
  GET_ACTIVITIES_SUMMARY_REQUEST,
  GET_ACTIVITIES_SUMMARY_SUCCESS,
  GET_ACTIVITY_FOR_STUDENT_LIST_FAILURE,
  GET_ACTIVITY_FOR_STUDENT_LIST_REQUEST,
  GET_ACTIVITY_FOR_STUDENT_LIST_SUCCESS,
  GET_ATTENDANCE_SUMMARY_FAILURE,
  GET_ATTENDANCE_SUMMARY_REQUEST,
  GET_ATTENDANCE_SUMMARY_SUCCESS,
  GET_COMPLETION_SUMMARY_FAILURE,
  GET_COMPLETION_SUMMARY_REQUEST,
  GET_COMPLETION_SUMMARY_SUCCESS,
  GET_EVALUATIONS_SUMMARY_FAILURE,
  GET_EVALUATIONS_SUMMARY_REQUEST,
  GET_EVALUATIONS_SUMMARY_SUCCESS,
  GET_UNCORRECTED_ACTIVITIES_FAILURE,
  GET_UNCORRECTED_ACTIVITIES_REQUEST,
  GET_UNCORRECTED_ACTIVITIES_SUCCESS,
  RESET_CLASSROOM,
} from '@/sections/teacher/overview/types/overview'

const actions = {
  getActivities:
    (
      tab: OverviewTab,
      classroomID = 0,
      pagination: Pagination = { offset: 0, limit: LIMIT },
      options: RequestOptions = {}
    ): AppThunk<void> =>
    (dispatch) => {
      const { offset, limit } = pagination
      const {
        silentLoading = false,
        reset = false,
        doNotSetPagination = false,
        shouldOverrideActivities = false,
      } = options

      const call = addNewCall(TEACHER_ACTIVITIES)

      dispatch({ type: GET_ACTIVITIES_REQUEST, payload: { silentLoading, tab, reset } })

      const { from, to } = getTimeRange(tab)

      const params = {
        fromDate: from,
        toDate: to,
        classroom: classroomID,
        offset: offset,
        limit,
        orderBy: 'date',
        orderDir: tab === TABS.FUTURE ? 'asc' : 'desc',
      }

      return apiPrivate
        .get(url + '/v3/dashboard/teacher/activities', { params })
        .then((response) => {
          if (call !== getLastCall(TEACHER_ACTIVITIES)) return

          const { activities, total } = response.data.description

          const today = tab === TABS.TODAY ? activities : null
          const future = tab === TABS.FUTURE ? activities : null
          const passed = tab === TABS.PASSED ? activities : null

          const payload = {
            tab,
            today,
            future,
            passed,
            offset,
            doNotSetPagination,
            shouldOverrideActivities,
            total,
          }

          dispatch(actions.getActivitiesSuccess(payload))
        })
        .catch((error) => {
          handleErrorsWithAction(error, GET_ACTIVITIES_FAILURE, dispatch)
        })
    },

  getActivitiesSuccess: (payload: ActivitiesSuccessPayload) => ({
    type: GET_ACTIVITIES_SUCCESS,
    payload,
  }),

  getActivitiesRefresher:
    (classroomID: number, options = {}): AppThunk<void> =>
    (dispatch, getState) => {
      // @ts-ignore
      const { tab, ...otherProps } = getState().teacher.overview
      // @ts-ignore
      const tabsLength = otherProps[tab].length
      const limit = tabsLength > LIMIT ? tabsLength : LIMIT

      dispatch(
        actions.getActivities(
          tab,
          classroomID,
          { offset: 0, limit },
          {
            silentLoading: true,
            doNotSetPagination: true,
            reset: false,
            shouldOverrideActivities: true,
            ...options,
          }
        )
      )
    },

  resetClassroom:
    (classroomID: number): AppThunk<void> =>
    (dispatch) => {
      dispatch({ type: RESET_CLASSROOM })

      dispatch(actions.getActivities(TABS.TODAY, classroomID, { offset: 0, limit: LIMIT }))
    },

  getCompletionSummary:
    (classroomID: number): AppThunk<void> =>
    (dispatch) => {
      dispatch({ type: GET_COMPLETION_SUMMARY_REQUEST })

      return apiPrivate
        .get(url + `/v1/classroom/${classroomID}/dashboard/teacher/summary/students`)
        .then((response) => {
          const { description: completionSummary } = response.data
          dispatch(actions.getCompletionSummarySuccess(completionSummary))
        })
        .catch((error) => {
          handleErrorsWithAction(error, GET_COMPLETION_SUMMARY_FAILURE, dispatch)
        })
    },

  getCompletionSummarySuccess: (
    completionSummary: CompletionSummary
  ): GetCompletionSummarySuccess => ({
    type: GET_COMPLETION_SUMMARY_SUCCESS,
    payload: {
      completionSummary,
    },
  }),

  getActivitiesSummary:
    (classroomID: number): AppThunk<void> =>
    (dispatch) => {
      dispatch({ type: GET_ACTIVITIES_SUMMARY_REQUEST })

      return apiPrivate
        .get(url + `/v1/classroom/${classroomID}/dashboard/teacher/summary/activities`)
        .then((response) => {
          const { description: activitiesSummary } = response.data
          dispatch(actions.getActivitiesSummarySuccess(activitiesSummary))
        })
        .catch((error) => {
          handleErrorsWithAction(error, GET_ACTIVITIES_SUMMARY_FAILURE, dispatch)
        })
    },

  getActivitiesSummarySuccess: (
    activitiesSummary: ActivitiesSummary
  ): GetActivitiesSummarySuccess => ({
    type: GET_ACTIVITIES_SUMMARY_SUCCESS,
    payload: {
      activitiesSummary,
    },
  }),

  getAttendanceSummary:
    (classroomID: number, startDate: Moment): AppThunk<void> =>
    (dispatch) => {
      dispatch({ type: GET_ATTENDANCE_SUMMARY_REQUEST })

      const params = {
        fromDate: startDate.format(),
      }

      return apiPrivate
        .get(url + `/v1/classroom/${classroomID}/dashboard/teacher/summary/attendance`, { params })
        .then((response) => {
          const { calendar, growth, cacheLastUpdated: cacheLastUpdated } = response.data.description
          dispatch(
            actions.getAttendanceSummarySuccess({ calendar, growth, startDate, cacheLastUpdated })
          )
        })
        .catch((error) => {
          handleErrorsWithAction(error, GET_ATTENDANCE_SUMMARY_FAILURE, dispatch)
        })
    },

  getAttendanceSummarySuccess: (
    attendanceSummary: AttendanceSummary
  ): GetAttendanceSummarySuccess => ({
    type: GET_ATTENDANCE_SUMMARY_SUCCESS,
    payload: {
      attendanceSummary,
    },
  }),

  getEvaluationsSummary:
    (classroomID: number): AppThunk<void> =>
    (dispatch) => {
      dispatch({ type: GET_EVALUATIONS_SUMMARY_REQUEST })

      return apiPrivate
        .get(url + `/v1/classroom/${classroomID}/dashboard/teacher/summary/evaluations`)
        .then((response) => {
          const { activities } = response.data.description
          dispatch(actions.getEvaluationsSummarySuccess(activities))
        })
        .catch((error) => {
          handleErrorsWithAction(error, GET_EVALUATIONS_SUMMARY_FAILURE, dispatch)
        })
    },

  getEvaluationsSummarySuccess: (
    evaluationsSummary: Evaluation[]
  ): GetEvaluationsSummarySuccess => ({
    type: GET_EVALUATIONS_SUMMARY_SUCCESS,
    payload: {
      evaluationsSummary,
    },
  }),

  getActivityForStudentList:
    (classroomID: number, activityID: number): AppThunk<void> =>
    (dispatch) => {
      dispatch({ type: GET_ACTIVITY_FOR_STUDENT_LIST_REQUEST })

      return apiPrivate
        .get(url + `/v1/classroom/${classroomID}/activities/${activityID}`)
        .then((response) => {
          const { activity } = response.data.description

          dispatch(studentListActions.setOpen(activity))
          dispatch({ type: GET_ACTIVITY_FOR_STUDENT_LIST_SUCCESS })
        })
        .catch((error) => {
          handleErrorsWithAction(error, GET_ACTIVITY_FOR_STUDENT_LIST_FAILURE, dispatch)
        })
    },
  getUncorrectedActivities:
    (classroomID: number, organizationID: number, pages: Pages): AppThunk<void> =>
    (dispatch) => {
      const pagination = pagesToLimit(pages)
      dispatch({
        type: GET_UNCORRECTED_ACTIVITIES_REQUEST,
        payload: {
          page: pages.page,
          rowsPerPage: pages.rowsPerPage,
          count: pages.count,
        },
      })

      const params = {
        classroomId: classroomID,
        organizationId: organizationID,
        limit: pagination.limit,
        offset: pagination.offset,
      }

      return apiPrivate
        .get(url + `/v1/activities/search/uncorrected`, { params })
        .then((response) => response.data.description)
        .then((data) => {
          dispatch(actions.getUncorrectedActivitiesSuccess(data.activities, data.pagination.total))
        })
        .catch((error) => {
          handleErrorsWithAction(error, GET_UNCORRECTED_ACTIVITIES_FAILURE, dispatch)
        })
    },
  getUncorrectedActivitiesSuccess: (
    uncorrectedActivities: UncorrectedActivity[],
    total: number
  ): GetUncorrectedActivitiesSuccess => ({
    type: GET_UNCORRECTED_ACTIVITIES_SUCCESS,
    payload: {
      uncorrectedActivities,
      total,
    },
  }),
}

export default actions
