import { combineReducers } from 'redux'
import competencesModal from './competencesModal'
import periods from './periods'
import rangePickerModal from './rangePickerModal'
import scores from './scores'
import signatures from './signatures'
import subjects from './subjects'

export default combineReducers({
  periods,
  scores,
  subjects,
  signatures,
  rangePickerModal,
  competencesModal,
})
