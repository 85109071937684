import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type {
  PendingExor,
  WrittenExamActions,
  WrittenExamsState,
} from '@/sections/teacher/oralExams/types/oralExams'
import {
  CLOSE_GRADING_CARD,
  CREATE_EXOR_INSTANCE_FAILURE,
  CREATE_EXOR_INSTANCE_REQUEST,
  CREATE_EXOR_INSTANCE_SUCCESS,
  EVALUATE_EXOR_SUCCESS,
  GET_EXOR_STUDENTS_FAILURE,
  GET_EXOR_STUDENTS_QUANTITY_FAILURE,
  GET_EXOR_STUDENTS_QUANTITY_REQUEST,
  GET_EXOR_STUDENTS_QUANTITY_SUCCESS,
  GET_EXOR_STUDENTS_REQUEST,
  GET_EXOR_STUDENTS_SUCCESS,
  GET_PENDING_EXOR_FAILURE,
  GET_PENDING_EXOR_QUANTITY_FAILURE,
  GET_PENDING_EXOR_QUANTITY_REQUEST,
  GET_PENDING_EXOR_QUANTITY_SUCCESS,
  GET_PENDING_EXOR_REQUEST,
  GET_PENDING_EXOR_SUCCESS,
  OPEN_GRADING_CARD,
  SET_CURRENTLY_CALLING,
  SET_EXAM_MESSAGE,
} from '@/sections/teacher/oralExams/types/oralExams'

const initialState: WrittenExamsState = {
  pendingExors: [],
  exorStudents: [],
  pendingExorsQuantity: 0,
  exorStudentsQuantity: 0,
  pendingExorsStatus: REQUEST_STATUS.IDLE,
  loadingExorStudents: false,
  loadingPendingExorsQuantity: false,
  loadingExorStudentsQuantity: false,
  errorPendingExors: '',
  errorExorStudents: '',
  errorPendingExorsQuantity: '',
  errorExorStudentsQuantity: '',
  openEvaluation: false,
  examMessage: {
    open: false,
    message: '',
    color: '',
  },
  currentlyCalling: null,
}

const isStudentExor = (pendingExor: PendingExor, studentId: number, activityId: number): boolean =>
  pendingExor.student.id === studentId && pendingExor.activity.id === activityId

function root(state = initialState, action: WrittenExamActions) {
  switch (action.type) {
    case OPEN_GRADING_CARD:
      return { ...state, openEvaluation: true }
    case CLOSE_GRADING_CARD:
      return { ...state, openEvaluation: false }
    case GET_PENDING_EXOR_REQUEST:
      return { ...state, pendingExorsStatus: REQUEST_STATUS.LOADING }
    case GET_PENDING_EXOR_SUCCESS:
      return {
        ...state,
        pendingExorsStatus: REQUEST_STATUS.SUCCESS,
        pendingExors: action.payload.exor,
        pendingExorsQuantity: action.payload.exor.length,
      }
    case GET_PENDING_EXOR_FAILURE:
      return {
        ...state,
        pendingExorsStatus: REQUEST_STATUS.FAILURE,
        errorPendingExors: action.payload.error,
      }
    case GET_EXOR_STUDENTS_REQUEST:
      return { ...state, loadingExorStudents: true }
    case GET_EXOR_STUDENTS_SUCCESS:
      return {
        ...state,
        loadingExorStudents: false,
        exorStudents: action.payload.exor,
        exorStudentsQuantity: action.payload.exor.length,
      }
    case GET_EXOR_STUDENTS_FAILURE:
      return { ...state, loadingExorStudents: false, errorExorStudents: action.payload.error }
    case GET_PENDING_EXOR_QUANTITY_REQUEST:
      return { ...state, loadingPendingExorsQuantity: true }
    case GET_PENDING_EXOR_QUANTITY_SUCCESS:
      return {
        ...state,
        loadingPendingExorsQuantity: false,
        pendingExorsQuantity: action.payload.exorQuantity,
      }
    case GET_PENDING_EXOR_QUANTITY_FAILURE:
      return {
        ...state,
        loadingPendingExorsQuantity: false,
        errorPendingExorsQuantity: action.payload.error,
      }
    case GET_EXOR_STUDENTS_QUANTITY_REQUEST:
      return { ...state, loadingExorStudentsQuantity: true }
    case GET_EXOR_STUDENTS_QUANTITY_SUCCESS:
      return {
        ...state,
        loadingExorStudentsQuantity: false,
        exorStudentsQuantity: action.payload.exorQuantity,
      }
    case GET_EXOR_STUDENTS_QUANTITY_FAILURE:
      return {
        ...state,
        loadingExorStudentsQuantity: false,
        errorExorStudentsQuantity: action.payload.error,
      }
    case CREATE_EXOR_INSTANCE_REQUEST: {
      const { evaluateeId, activityId } = action.payload
      return {
        ...state,
        pendingExors: state.pendingExors.map((pendingExor) =>
          isStudentExor(pendingExor, evaluateeId, activityId)
            ? { ...pendingExor, loadingVideocall: true }
            : pendingExor
        ),
      }
    }
    case CREATE_EXOR_INSTANCE_SUCCESS: {
      const { evaluateeId, activityId } = action.payload
      return {
        ...state,
        pendingExors: state.pendingExors.map((pendingExor) =>
          isStudentExor(pendingExor, evaluateeId, activityId)
            ? { ...pendingExor, loadingVideocall: false, submitted: true }
            : pendingExor
        ),
        currentlyCalling: action.payload,
      }
    }
    case CREATE_EXOR_INSTANCE_FAILURE:
      return {
        ...state,
        loadingExorStudentsQuantity: false,
        errorExorStudentsQuantity: action.payload.error,
      }
    case EVALUATE_EXOR_SUCCESS: {
      const { studentId, activityId } = action.payload
      const updatedPendingExors = state.pendingExors.filter(
        (pendingExor) => !isStudentExor(pendingExor, studentId, activityId)
      )
      return {
        ...state,
        pendingExors: updatedPendingExors,
        pendingExorsQuantity: updatedPendingExors.length,
      }
    }
    case SET_EXAM_MESSAGE: {
      return { ...state, examMessage: action.payload }
    }
    case SET_CURRENTLY_CALLING: {
      return { ...state, currentlyCalling: action.payload }
    }
    default:
      return state
  }
}

export default root
