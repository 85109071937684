import React from 'react'
import { makeStyles } from '@mui/styles'
import CheckIcon from '@/legacy/components/v1/svgs/js/Tick'
import ClearIcon from '@mui/icons-material/Clear'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import CustomTooltip from '@/legacy/components/v1/atomic/v2/customTooltip'
import { isEmpty } from 'ramda'
import ButtonV2 from '@/legacy/components/v1/atomic/v2/buttonV2'

const useStyles = makeStyles((theme, color) => ({
  container: ({ error, color, withCloseButton }) => ({
    position: 'relative',
    background: color ? color : error ? '#F45C3A' : '#50D174',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
  }),
  closeButtonContainer: {
    position: 'absolute',
    right: 8,
    top: 8,
    fill: 'white',
  },
  closeButton: {
    padding: 8,
  },
  notificationContent: ({ withCloseButton }) => ({
    padding: withCloseButton ? '32px 60px 0 16px' : 16,
    width: 'fit-content',
    height: 72,
    display: 'flex',
    alignItems: 'center',
  }),
  icon: {
    width: 40,
    height: 40,
    borderRadius: 40,
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    height: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    lineHeight: '1.4rem',
    fontSize: 16,
    color: '#fff',
    fontFamily: 'DM Sans',
    marginLeft: 16,
  },
  errorIcon: {
    fill: '#F45C3A',
  },
  buttonsContainer: {
    padding: '0 16px 16px 16px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginRight: 16,
    '&:last-child': {
      marginRight: 0,
    },
  },
}))

const ToastMessage = ({
  error,
  color = null,
  icon = null,
  withCloseButton,
  closeButtonFn = () => {},
  buttons = [],
  ...props
}) => {
  const classes = useStyles({ error, color, withCloseButton })
  return (
    <div className={classes.container}>
      {withCloseButton && (
        <CustomTooltip title="Cerrar">
          <div className={classes.closeButtonContainer}>
            <IconButton className={classes.closeButton} onClick={closeButtonFn} disableRipple>
              <Close style={{ color: 'white' }} />
            </IconButton>
          </div>
        </CustomTooltip>
      )}
      <div className={classes.notificationContent}>
        <div className={classes.icon}>
          {icon ? (
            icon
          ) : error ? (
            <ClearIcon className={classes.errorIcon} />
          ) : (
            <CheckIcon fill="#50D174" width={20} height={15} />
          )}
        </div>
        <div className={classes.text}>
          <b>{props.title}</b>
          <span>{props.description}</span>
        </div>
      </div>
      {!isEmpty(buttons) && (
        <div className={classes.buttonsContainer}>
          {buttons.map((button) => (
            <ButtonV2 className={classes.button} dense onClick={button.onClick}>
              {button.label}
            </ButtonV2>
          ))}
        </div>
      )}
    </div>
  )
}

ToastMessage.defaultProps = {
  error: false,
}

export default ToastMessage
