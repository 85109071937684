import { webBffUrl } from '@aula/config'
import { ApiPrivate } from '@/legacy/services/api/api'
import type { GetReportCardVersionsResponse } from './types'

export const getReportCardVersions = (
  limit: number,
  offset: number,
  organizationId: number,
  academicPeriodId: number,
  classroomId?: number
): Promise<GetReportCardVersionsResponse> => {
  const params = { organizationId, academicPeriodId, classroomId, limit, offset }
  return ApiPrivate.get(webBffUrl + `/v1/reportcard-versions`, {
    params,
  })
    .then((resp) => resp.data)
    .catch((err) => {
      throw err
    })
}
