import i18n from 'i18next'
import { initReactI18next as I18nNext } from 'react-i18next'
import { mergeDeepRight } from 'ramda'
import LanguageDetector from 'i18next-browser-languagedetector'
import { Locales, pickCosmos } from '@aula/config'
import type { Tag } from '@/locales/types'
import { getLocale } from '@/locales/utils'
import { updateLocale } from '@/locales/moment'

import es from './resources/es'
import en from './resources/en'
import de from './resources/de'
import fr from './resources/fr'
import ja from './resources/ja'
import ko from './resources/ko'
import pt from './resources/pt'
import ru from './resources/ru'
import tw from './resources/tw'
import zh from './resources/zh'

export const i18next = i18n.use(I18nNext).use(LanguageDetector)

export function init() {
  const cosmos = pickCosmos()
  const locale = getLocale() ?? 'es'

  updateLocale(locale as Tag)

  return i18next.init({
    fallbackLng: locale,
    compatibilityJSON: 'v4',
    ns: ['translation', 'common', 'glossary', 'validation'],
    interpolation: {
      escapeValue: false,
    },
    resources: {
      es: {
        ...es,
        translation: mergeDeepRight(es.translation, Locales[cosmos.name.toLocaleLowerCase()] ?? {}),
      },
      en,
      de,
      fr,
      ja,
      ko,
      pt,
      ru,
      tw,
      zh,
    },
  })
}
