import types from '@/sections/student/dashboard/types'

const initialState = {
  loading: false,
  loadingSummary: false,
  done: [],
  future: [],
  today: [],
  summary: [],
  user: {},
  error: '',
  dashboardSummary: {
    pending: 0,
    subject: {},
    todayDone: 0,
    todayTotal: 0,
    weekDone: 0,
    weekTotal: 0,
  },
  dashboardV4: {
    todayLiveActivities: 0,
    todayLiveActivitiesDone: 0,
    monthExams: 0,
    monthExamsDone: 0,
    weekActivities: 0,
    todayActivitiesToSubmit: 0,
    pendingActivitiesToSubmit: 0,
  },
  searchBarOpen: false,
  search: '',
  loadingSearch: false,
  searchResults: [],
  lastSearchID: 0,
}

function root(state = initialState, action) {
  switch (action.type) {
    case types.DASHBOARD_REQUEST:
      return { ...state, loading: action.payload.silentLoading ? state.loading : true, error: '' }
    case types.DASHBOARD_SUCCESS: {
      const { done, future, pending, today, dashboardSummary } = action.payload
      return { ...state, loading: false, done, future, pending, today, dashboardSummary }
    }
    case types.DASHBOARD_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case types.DASHBOARD_V4_REQUEST:
      return { ...state, loadingDashboardV4: true }
    case types.DASHBOARD_V4_SUCCESS: {
      const {
        todayLiveActivities,
        todayLiveActivitiesDone,
        monthExams,
        monthExamsDone,
        weekActivities,
        todayActivitiesToSubmit,
        pendingActivitiesToSubmit,
      } = action.payload
      return {
        ...state,
        loadingDashboardV4: false,
        dashboardV4: {
          todayLiveActivities,
          todayLiveActivitiesDone,
          monthExams,
          monthExamsDone,
          weekActivities,
          todayActivitiesToSubmit,
          pendingActivitiesToSubmit,
        },
      }
    }
    case types.DASHBOARD_V4_FAILURE:
      return { ...state, loadingDashboardV4: false, error: action.payload.error }
    case types.SUMMARY_REQUEST:
      return {
        ...state,
        loadingSummary: action.payload.silentLoading ? state.loadingSummary : true,
        error: '',
      }
    case types.SUMMARY_SUCCESS:
      return {
        ...state,
        loadingSummary: false,
        summary: action.payload.summary,
        user: action.payload.user,
      }
    case types.SUMMARY_FAILURE:
      return { ...state, loadingSummary: false, error: action.payload.error }
    case types.TOGGLE_SEARCH_BAR:
      return { ...state, searchBarOpen: action.payload.open }
    case types.SET_SEARCH:
      return { ...state, search: action.payload.search }
    case types.SEARCH_REQUEST:
      return { ...state, lastSearchID: action.payload.lastSearchID, loadingSearch: true }
    case types.SEARCH_SUCCESS:
      return { ...state, searchResults: action.payload.activities, loadingSearch: false }
    case types.SEARCH_FAILURE:
      return { ...state, loadingSearch: false }
    default:
      return state
  }
}

export default root
