import type {
  CreateClassroomAsAdminAction,
  EditClassroomAsAdminAction,
} from '@/legacy/redux/types/classrooms'
import type { Classroom } from '@/legacy/services/api/lms/classrooms/types'
import type { LimitOffsetPagination } from '@/sections/management/types/classrooms/classroomsDrawer'

export type ClassroomsDrawerState = {
  classrooms: Classroom[]
  count: number
  error: string
  loading: boolean
  pagination: LimitOffsetPagination
  search: string
}

// Clear state
export const CLEAR_STATE = 'ADMIN/MANAGEMENT/CLASSROOM_DRAWER/CLEAR_STATE'
export type ClearState = {
  type: typeof CLEAR_STATE
}

// Handle pagination
export const SET_PAGINATION_OFFSET = 'ADMIN/MANAGEMENT/CLASSROOM_DRAWER/SET_PAGINATION_OFFSET'
export type SetPaginationOffset = {
  type: typeof SET_PAGINATION_OFFSET
  payload: { offset: number }
}

// Handle search
export const SET_SEARCH = 'ADMIN/MANAGEMENT/CLASSROOM_DRAWER/SET_SEARCH'
export type SetSearch = {
  type: typeof SET_SEARCH
  payload: { search: string }
}

// Get classrooms
export const GET_CLASSROOMS_REQUEST = 'ADMIN/MANAGEMENT/CLASSROOM_DRAWER/GET_CLASSROOMS_REQUEST'
export const GET_CLASSROOMS_SUCCESS = 'ADMIN/MANAGEMENT/CLASSROOM_DRAWER/GET_CLASSROOMS_SUCCESS'
export const GET_CLASSROOMS_FAILURE = 'ADMIN/MANAGEMENT/CLASSROOM_DRAWER/GET_CLASSROOMS_FAILURE'

export type GetClassroomsRequest = {
  type: typeof GET_CLASSROOMS_REQUEST
}
export type GetClassroomsSuccess = {
  type: typeof GET_CLASSROOMS_SUCCESS
  payload: { classrooms: Classroom[]; count: number }
}
export type GetClassroomsFailure = {
  type: typeof GET_CLASSROOMS_FAILURE
}

export type GetClassroomsAction = GetClassroomsRequest | GetClassroomsSuccess | GetClassroomsFailure

export type ClassrooomsDrawerAction =
  | ClearState
  | SetPaginationOffset
  | SetSearch
  | GetClassroomsAction
  | CreateClassroomAsAdminAction
  | EditClassroomAsAdminAction
