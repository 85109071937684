import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import type {
  LibraryCourseAction,
  LibraryCourseState,
} from '@/sections/teacher/library/types/libraryCourses'
import {
  GET_COURSES_FAILURE,
  GET_COURSES_REQUEST,
  GET_COURSES_SUCCESS,
  SET_ASSIGN_MODAL_CLOSED,
  SET_ASSIGN_MODAL_OPEN,
} from '@/sections/teacher/library/types/libraryCourses'

const initialState: LibraryCourseState = {
  assignModalOpen: false,
  open: false,
  loadingCourses: false,
  courses: [],
  selectedCourse: null,
  coursePagination: {
    page: 0,
    rowsPerPage: 12,
    count: 0,
  },
  error: '',
}

const requestDestination = reduxRequestOriginMap.LIBRARY_COURSES

const CoursesReducer = (
  state: LibraryCourseState = initialState,
  action: LibraryCourseAction
): LibraryCourseState => {
  if (action.payload?.requestOrigin !== requestDestination) return state

  switch (action.type) {
    case SET_ASSIGN_MODAL_OPEN:
      return { ...state, assignModalOpen: true, selectedCourse: action.payload.selectedCourse }
    case SET_ASSIGN_MODAL_CLOSED:
      return { ...state, assignModalOpen: false }
    case GET_COURSES_REQUEST: {
      const { page, rowsPerPage } = action.payload
      return {
        ...state,
        loadingCourses: true,
        coursePagination: { ...state.coursePagination, page, rowsPerPage },
      }
    }
    case GET_COURSES_SUCCESS: {
      const { courses, total } = action.payload
      return {
        ...state,
        loadingCourses: false,
        courses,
        coursePagination: { ...state.coursePagination, count: total },
      }
    }
    case GET_COURSES_FAILURE:
      return { ...state, loadingCourses: false, error: '' }
    default:
      return state
  }
}

export default CoursesReducer
