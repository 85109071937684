import type { BackpackActions, BackpackState } from '@/sections/backpack/types/backpack'
import {
  GET_ROOT_ITEMS_REQUEST,
  GET_ROOT_ITEMS_SUCCESS,
  GET_ROOT_ITEMS_FAILURE,
  GET_FOLDER_ITEMS_REQUEST,
  GET_FOLDER_ITEMS_SUCCESS,
  GET_FOLDER_ITEMS_FAILURE,
  SELECT_ITEM,
  RENAME_ITEM_SUCCESS,
  RENAME_ITEM_REQUEST,
  RENAME_ITEM_FAILURE,
  DRAG_FILE_REQUEST,
  DRAG_FILE_FAILURE,
  DRAG_FILE_SUCCESS,
  SET_MODAL_OPEN,
  SET_MODAL_CLOSED,
  SET_NEW_FILENAME,
  DRAG_FOLDER_REQUEST,
  DRAG_FOLDER_FAILURE,
  CREATE_FOLDER_SUCCESS,
  CREATE_FOLDER_REQUEST,
  CREATE_FOLDER_FAILURE,
  DELETE_ITEM_FAILURE,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_REQUEST,
  ADD_FILES_TO_UPLOAD,
  REMOVE_FILE_TO_UPLOAD,
  UPLOAD_FILES_REQUEST,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_FAILURE,
  DRAG_FOLDER_SUCCESS,
  GET_BACKPACK_BOOKS_REQUEST,
  GET_BACKPACK_BOOKS_FAILURE,
  GET_BACKPACK_BOOKS_SUCCESS,
  GET_BACKPACK_RECORDINGS_REQUEST,
  GET_BACKPACK_RECORDINGS_SUCCESS,
  GET_BACKPACK_RECORDINGS_FAILURE,
  TOGGLE_RECORDING_OPEN,
} from '@/sections/backpack/types/backpack'
import { getFileExtension, removeFileExtension } from '@/sections/editor/utils'

const initialState: BackpackState = {
  myFiles: [],
  myFilesRootId: 0,
  books: [],
  loading: false,
  loadingBooks: false,
  recordings: [],
  loadingRecordings: false,
  recordingsPagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0,
  },
  openRecording: null,
  error: '',
  selectedItem: null,
  modalOpen: false,
  modalType: '',
  newFilename: '',
  currentFileExtension: '',
  filesToUpload: [],
  openFolders: [],
}

function root(state = initialState, action: BackpackActions): BackpackState {
  switch (action.type) {
    case DRAG_FILE_REQUEST:
    case DRAG_FOLDER_REQUEST:
    case CREATE_FOLDER_REQUEST:
    case RENAME_ITEM_REQUEST:
    case GET_ROOT_ITEMS_REQUEST:
    case DELETE_ITEM_REQUEST:
    case GET_FOLDER_ITEMS_REQUEST:
      return { ...state, loading: true, error: '' }
    case GET_ROOT_ITEMS_SUCCESS:
      return {
        ...state,
        myFiles: action.payload.items,
        selectedItem: null,
        myFilesRootId: action.payload.myFilesRootId,
        loading: false,
        error: '',
      }
    case GET_FOLDER_ITEMS_SUCCESS:
      return {
        ...state,
        myFiles: [...state.myFiles, ...action.payload.items],
        loading: false,
        error: '',
      }
    case DRAG_FOLDER_SUCCESS:
    case RENAME_ITEM_SUCCESS: {
      const { item, oldKey } = action.payload
      let files = state.myFiles.filter((i) => i.id !== item.id)
      let openFolders = [...state.openFolders]

      if (item.type === 'folder') {
        openFolders = openFolders.filter((f) => f !== item.id)
        files = files.map((file) => {
          if (file.key.indexOf(oldKey) === 0) {
            const newKey = item.key + file.key.slice(oldKey.length)
            return { ...file, key: newKey }
          } else return file
        })
      }
      return {
        ...state,
        myFiles: [...files, item],
        openFolders,
        newFilename: '',
        currentFileExtension: '',
        selectedItem: null,
        loading: false,
        error: '',
        modalOpen: false,
      }
    }
    case DELETE_ITEM_SUCCESS: {
      const myFiles = state.myFiles.filter(
        (item) => action.payload.item.id !== item.id && item.parentId !== action.payload.item.id
      )
      return { ...state, myFiles, selectedItem: null, loading: false, error: '', modalOpen: false }
    }
    case DRAG_FILE_SUCCESS: {
      const files = state.myFiles.filter((file) => file.id !== action.payload.file.id)
      return {
        ...state,
        myFiles: [...files, action.payload.file],
        selectedItem: null,
        loading: false,
        error: '',
      }
    }
    case CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        myFiles: [...state.myFiles, action.payload.file],
        newFilename: '',
        selectedItem: null,
        loading: false,
        error: '',
        modalOpen: false,
      }
    case DRAG_FILE_FAILURE:
    case DRAG_FOLDER_FAILURE:
    case RENAME_ITEM_FAILURE:
    case DELETE_ITEM_FAILURE:
    case CREATE_FOLDER_FAILURE:
    case GET_ROOT_ITEMS_FAILURE:
    case GET_FOLDER_ITEMS_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case SELECT_ITEM: {
      const { selectedItem } = action.payload
      let openFolders = [...state.openFolders]
      if (selectedItem.type === 'folder') {
        const isAlreadyOpen = openFolders.find((folder) => selectedItem.id === folder)
        openFolders = isAlreadyOpen
          ? openFolders.filter((folder) => selectedItem.id !== folder)
          : [...openFolders, selectedItem.id]
      }
      return { ...state, selectedItem, openFolders }
    }
    case SET_MODAL_OPEN:
      return {
        ...state,
        modalOpen: true,
        modalType: action.payload.modalType,
        ...(action.payload.modalType === 'rename' && state.selectedItem
          ? {
              newFilename: removeFileExtension(state.selectedItem.name),
              currentFileExtension: getFileExtension(state.selectedItem.name),
            }
          : {}),
      }
    case SET_MODAL_CLOSED:
      return {
        ...state,
        modalOpen: false,
        modalType: '',
        newFilename: '',
        currentFileExtension: '',
        error: '',
        filesToUpload: [],
      }
    case SET_NEW_FILENAME:
      return { ...state, newFilename: action.payload.newFilename }
    case ADD_FILES_TO_UPLOAD:
      return { ...state, filesToUpload: [...state.filesToUpload, ...action.payload.files] }
    case REMOVE_FILE_TO_UPLOAD:
      return {
        ...state,
        filesToUpload: state.filesToUpload.filter((file) => file !== action.payload.file),
      }
    case UPLOAD_FILES_REQUEST:
      return { ...state, loading: true, error: '' }
    case UPLOAD_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        myFiles: [...state.myFiles, ...action.payload.files],
        filesToUpload: [],
        modalOpen: false,
      }
    case UPLOAD_FILES_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case GET_BACKPACK_BOOKS_REQUEST:
      return { ...state, loadingBooks: true }
    case GET_BACKPACK_BOOKS_SUCCESS:
      return { ...state, loadingBooks: false, books: action.payload.items }
    case GET_BACKPACK_BOOKS_FAILURE:
      return { ...state, loadingBooks: false, error: action.payload.error }
    case GET_BACKPACK_RECORDINGS_REQUEST:
      return {
        ...state,
        loadingRecordings: true,
        recordingsPagination: {
          ...state.recordingsPagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    case GET_BACKPACK_RECORDINGS_SUCCESS:
      return {
        ...state,
        loadingRecordings: false,
        error: '',
        recordings: action.payload.recordings,
        recordingsPagination: {
          ...state.recordingsPagination,
          count: action.payload.count,
        },
      }
    case GET_BACKPACK_RECORDINGS_FAILURE:
      return { ...state, loadingRecordings: false, error: action.payload.error }
    case TOGGLE_RECORDING_OPEN:
      return { ...state, openRecording: action.payload.recording }
    default:
      return state
  }
}

export default root
