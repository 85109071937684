import React from 'react'
import { states } from '@/legacy/components/v2/ribbons/states'
import ActivityRibbonBase from './activityRibbonBase'
import {
  activityRibbonMap,
  ACTIVITY_KEY_ANIMATIONS_ACTIVITY,
} from '@/legacy/components/v2/ribbons/types'

export default function AnimationsRibbon(props) {
  const robotsRemote = activityRibbonMap[ACTIVITY_KEY_ANIMATIONS_ACTIVITY]
  return <ActivityRibbonBase Icon={robotsRemote.icon} type={robotsRemote.type} {...props} />
}

AnimationsRibbon.defaultProps = {
  subject: {},
  state: states.DEFAULT,
  buttonEnabled: true,
  buttonLabel: 'menuOptions.watch',
  buttonProps: {},
  calendarLabel: 'student.utils.transmit',
  messages: 0,
}
