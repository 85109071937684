import { TextField } from '@mui/material'
import { Autocomplete } from '@mui/lab'

const AutocompleteComponent = ({
  size,
  label = '',
  options = [],
  onChange,
  onInputChange,
  textFieldClasses = {},
  loading,
  ...props
}) => {
  return (
    <Autocomplete
      label={label}
      size={size}
      loading={loading}
      onInputChange={onInputChange}
      onChange={onChange}
      options={options}
      getOptionLabel={(option) => option.label}
      filterOptions={(x) => x}
      renderInput={(params) => (
        <TextField label={label} {...params} classes={textFieldClasses} variant="outlined" />
      )}
      {...props}
    />
  )
}

export default AutocompleteComponent
