import { ROLE, url } from '@aula/config'
import { ApiPrivate } from '@/legacy/services/api/api'

type AddChildrenProps = {
  userId: number
  relatedToId: number
  relation: string
  role: ROLE
  organizationId?: number
}

export const add = (props: AddChildrenProps) => {
  const { userId, relatedToId, relation, role, organizationId } = props
  const data = {
    userId,
    relatedToId,
    relation,
    ...(role === ROLE.PRINCIPAL && { organizationId }),
  }
  const userRole = role === ROLE.PRINCIPAL ? ROLE.PRINCIPAL : 'admin'
  return ApiPrivate.put(url + `/v1/${userRole}/relationships`, data)
}

export const remove = (props: AddChildrenProps) => {
  const { userId, relatedToId, relation, role, organizationId } = props
  const data = {
    userId,
    relatedToId,
    relation,
    ...(role === ROLE.PRINCIPAL && { organizationId }),
  }

  const userRole = role !== ROLE.PRINCIPAL ? 'admin' : ROLE.PRINCIPAL
  return ApiPrivate.delete(url + `/v1/${userRole}/relationships`, { data })
}
