import type { LocaleSpecification } from 'moment'

export const es: LocaleSpecification = {
  months: [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ],
  monthsShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
  weekdays: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
  weekdaysShort: ['dom.', 'lun.', 'mar.', 'mié.', 'jue.', 'vie.', 'sáb.'],
  weekdaysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
  longDateFormat: {
    LT: 'H:mm',
    LTS: 'LT:ss',
    L: 'DD/MM/YYYY',
    LL: 'D [de] MMMM [de] YYYY',
    LLL: 'D [de] MMMM [de] YYYY LT',
    LLLL: 'dddd, D [de] MMMM [de] YYYY LT',
  },
  calendar: {
    sameDay: function () {
      return '[hoy a la' + (this.hours ? 's' : '') + '] LT'
    },
    nextDay: function () {
      return '[mañana a la' + (this.hours ? 's' : '') + '] LT'
    },
    nextWeek: function () {
      return 'dddd [a la' + (this.hours ? 's' : '') + '] LT'
    },
    lastDay: function () {
      return '[ayer a la' + (this.hours ? 's' : '') + '] LT'
    },
    lastWeek: function () {
      return '[el] dddd [pasado a la' + (this.hours ? 's' : '') + '] LT'
    },
    sameElse: 'L',
  },
  relativeTime: {
    future: 'en %s',
    past: 'hace %s',
    s: 'unos segundos',
    m: 'un minuto',
    mm: '%d minutos',
    h: 'una hora',
    hh: '%d horas',
    d: 'un día',
    dd: '%d días',
    M: 'un mes',
    MM: '%d meses',
    y: 'un año',
    yy: '%d años',
  },
  ordinal: () => '%dº',
  ordinalParse: /\d{1,2}º/,
  week: {
    dow: 0,
    doy: 4,
  },
}
