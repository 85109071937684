import type { ReactElement } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const useStyles = makeStyles(() => ({
  closeButtonContainer: {
    height: 'fit-content',
    alignSelf: 'center',
    padding: 8,
  },
}))

export type DrawerCloseButtonProps = {
  onClick: () => void
  fill?: string
  closingDisabled?: boolean
}

const DrawerCloseButton = ({
  onClick,
  fill = '#1A1A1A',
  closingDisabled,
}: DrawerCloseButtonProps): ReactElement => {
  const { t } = useTranslation()
  const classes = useStyles()
  const iconButtonAriaLabel = t('components.menuClose')

  return (
    <Button
      aria-label={iconButtonAriaLabel}
      className={classes.closeButtonContainer}
      onClick={onClick}
      disabled={closingDisabled}
    >
      <CloseIcon style={{ fill }} />
    </Button>
  )
}

export default DrawerCloseButton
