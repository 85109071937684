import * as React from 'react'
import type { SVGProps } from 'react'
const SvgAudience = (props: SVGProps<SVGSVGElement>) => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="56" height="56" rx="6" fill="#E9F3FA" />
    <g clip-path="url(#clip0_4559_2066)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.25 10.75C27.25 10.3358 27.5858 10 28 10C28.4142 10 28.75 10.3358 28.75 10.75V12.0173C36.9898 12.3975 43.6025 19.0102 43.9827 27.25H45.25C45.6642 27.25 46 27.5858 46 28C46 28.4142 45.6642 28.75 45.25 28.75H43.9827C43.6025 36.9898 36.9898 43.6025 28.75 43.9827V45.25C28.75 45.6642 28.4142 46 28 46C27.5858 46 27.25 45.6642 27.25 45.25V43.9827C19.0102 43.6025 12.3975 36.9898 12.0173 28.75L10.75 28.75C10.3358 28.75 10 28.4142 10 28C10 27.5858 10.3358 27.25 10.75 27.25L12.0173 27.25C12.3975 19.0102 19.0102 12.3975 27.25 12.0173V10.75ZM42.5693 27.25H40.25C39.8358 27.25 39.5 27.5858 39.5 28C39.5 28.4142 39.8358 28.75 40.25 28.75H42.5693C42.1916 36.21 36.2099 42.1916 28.75 42.5694V40.25C28.75 39.8358 28.4142 39.5 28 39.5C27.5858 39.5 27.25 39.8358 27.25 40.25V42.5694C19.7901 42.1916 13.8084 36.21 13.4307 28.75L15.7501 28.75C16.1643 28.75 16.5001 28.4142 16.5001 28C16.5001 27.5858 16.1643 27.25 15.7501 27.25L13.4307 27.25C13.8086 19.7901 19.7901 13.8086 27.25 13.4308V15.75C27.25 16.1642 27.5858 16.5 28 16.5C28.4142 16.5 28.75 16.1642 28.75 15.75V13.4308C36.2099 13.8086 42.1914 19.7901 42.5693 27.25Z"
        fill="#2A205E"
      />
      <path
        d="M28.0025 28C32.2782 28 34.9028 29.9028 35.2089 33.2155L35.4172 35.9233C35.4619 36.5041 35.0026 37 34.4201 37H21.5818C20.9986 37 20.5391 36.503 20.5848 35.9216L20.7961 33.2367C21.1022 29.9028 23.7268 28 28.0025 28Z"
        fill="#6AACD9"
      />
      <circle cx="28" cy="23" r="4" fill="#6AACD9" />
      <circle cx="21" cy="24" r="2.5" fill="#2F80B7" />
      <circle cx="35" cy="24" r="2.5" fill="#2F80B7" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.0009 27C18.1509 27.0002 16.4015 28.2688 16.1974 30.4912L16.0425 32.4608C16.0196 32.7515 16.2493 33 16.5409 33H19.8789C20.2188 30.4165 21.6676 28.5864 24.015 27.6701C23.2341 27.2316 22.2179 27.0001 21.0009 27Z"
        fill="#2F80B7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.9992 27C37.8492 27.0002 39.5986 28.2688 39.8027 30.4912L39.9577 32.4608C39.9805 32.7515 39.7508 33 39.4592 33H36.1212C35.7813 30.4165 34.3325 28.5864 31.9851 27.6701C32.766 27.2316 33.7822 27.0001 34.9992 27Z"
        fill="#2F80B7"
      />
    </g>
    <defs>
      <clipPath id="clip0_4559_2066">
        <rect width="36" height="36" fill="white" transform="translate(10 10)" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgAudience
