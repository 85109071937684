import React from 'react'
import { theme } from '@aula/config'
import { Writing as WritingSVG } from '@/legacy/svgs/questionTypes'
import { Choice as ChoiceSVG } from '@/legacy/svgs/questionTypes'
import { Union as UnionSVG } from '@/legacy/svgs/questionTypes'
import { Fillable as FillableSVG } from '@/legacy/svgs/questionTypes'

export const QUESTIONS_TYPES = {
  CHOICE: 'choice',
  WRITING: 'writing',
  ATTACHMENT: 'attachment',
  FILLABLE: 'fillable',
  UNION: 'union',
  TEXT: 'text',
  SECTION: 'section',
}

export const QUESTIONS_TYPES_SELECT = [
  {
    id: 1,
    type: QUESTIONS_TYPES.CHOICE,
    title: 'constants.multipleChoice',
    icon: <ChoiceSVG />,
    description: null,
    enabled: true,
  },
  {
    id: 2,
    type: QUESTIONS_TYPES.FILLABLE,
    title: 'constants.fillableQuestion',
    icon: <FillableSVG />,
    description: null,
    enabled: true,
  },
  {
    id: 3,
    type: QUESTIONS_TYPES.UNION,
    title: 'constants.unionQuestion',
    icon: <UnionSVG />,
    description: null,
    enabled: true,
  },
  {
    id: 4,
    type: QUESTIONS_TYPES.WRITING,
    title: 'constants.writingQuestion',
    icon: <WritingSVG />,
    description: null,
    enabled: true,
  },
]

export const UNION_ANSWER_LEFT = 1
export const UNION_ANSWER_RIGHT = 2

export const QUESTIONS_MODAL_TYPE_TEXT = 'modal_type_text'
export const QUESTIONS_MODAL_TYPE_QUESTIONS = 'modal_type_questions'
export const QUESTIONS_MODAL_TYPE_SECTIONS = 'modal_type_sections'

export const UNION_COLORS = [
  '#ffc025',
  theme.palette.primary.main,
  '#de568e',
  '#46c7e8',
  '#01ceaa',
  '#ffc025',
  theme.palette.primary.main,
  '#de568e',
  '#46c7e8',
  '#01ceaa',
]
export const ACTIVITY_FIELDS_MAX_CHARACTERS = {
  GENERIC_TITLE: 255,
  WRITING_TITLE: 512,
  FILLABLE_BODY: 2048,
  FILLABLE_OPTION: 255,
  CHOICE_OPTION: 100,
  UNION_OPTION: 255,
  SECTION_TITLE: 80,
}

export const DEFAULT_EVALUATION_INCREMENT = 2.5
