import { combineReducers } from 'redux'
import documentationTableReducer from './reducers/documentationTable'
import approvalModalReducer from './reducers/approvalModal'
import rejectionModalReducer from './reducers/rejectionModal'
import rejectionDetailsModalReducer from './reducers/rejectionDetailsModal'

export default combineReducers({
  table: documentationTableReducer,
  approvalModal: approvalModalReducer,
  rejectionModal: rejectionModalReducer,
  rejectionDetailsModal: rejectionDetailsModalReducer,
})
