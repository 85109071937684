import type { MessageThreadReply } from '@/api/bulletin'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export interface ResponseToMessageThreadState {
  error: Error | null
  loading: boolean
  status: REQUEST_STATUS
}

export enum MESSAGE_THREADS_RESPONSE_TYPES {
  RESPONSE_CLEAR = 'TEACHER/BULLETIN/MESSAGE_THREAD_RESPONSE/RESPONSE_CLEAR',
  RESPONSE_REQUEST = 'TEACHER/BULLETIN/MESSAGE_THREAD_RESPONSE/RESPONSE_REQUEST',
  RESPONSE_SUCCESS = 'TEACHER/BULLETIN/MESSAGE_THREAD_RESPONSE/RESPONSE_SUCCESS',
  RESPONSE_FAILURE = 'TEACHER/BULLETIN/MESSAGE_THREAD_RESPONSE/RESPONSE_FAILURE',
}

export type ResponseToMessageClear = {
  type: MESSAGE_THREADS_RESPONSE_TYPES.RESPONSE_CLEAR
}

export type ResponseToMessageThread = {
  type: MESSAGE_THREADS_RESPONSE_TYPES.RESPONSE_REQUEST
}

export type ResponseToMessageThreadSuccess = {
  type: MESSAGE_THREADS_RESPONSE_TYPES.RESPONSE_SUCCESS
  payload: {
    message: MessageThreadReply
  }
}

export type ResponseToMessageThreadFailure = {
  type: MESSAGE_THREADS_RESPONSE_TYPES.RESPONSE_FAILURE
  payload: {
    error: Error
  }
}

export type ResponseToMessageThreadActions =
  | ResponseToMessageThread
  | ResponseToMessageClear
  | ResponseToMessageThreadSuccess
  | ResponseToMessageThreadFailure
