import type { LocaleSpecification } from 'moment'
import moment from 'moment'

export const tw: LocaleSpecification = {
  months: [
    '一月',
    '二月',
    '三月',
    '四月',
    '五月',
    '六月',
    '七月',
    '八月',
    '九月',
    '十月',
    '十一月',
    '十二月',
  ],
  monthsShort: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  weekdays: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
  weekdaysShort: ['週日', '週一', '週二', '週三', '週四', '週五', '週六'],
  weekdaysMin: ['日', '一', '二', '三', '四', '五', '六'],
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'YYYY/MM/DD',
    LL: 'YYYY年M月D日',
    LLL: 'YYYY年M月D日 LT',
    LLLL: 'YYYY年M月D日 dddd LT',
  },
  calendar: {
    sameDay: function () {
      return '[今天] LT'
    },
    nextDay: function () {
      return '[明天] LT'
    },
    nextWeek: function () {
      return 'dddd [at] LT'
    },
    lastDay: function () {
      return '[昨天] LT'
    },
    lastWeek: function () {
      return 'dddd [at] LT'
    },
    sameElse: 'L',
  },
  relativeTime: {
    future: '%s後',
    past: '%s前',
    s: '幾秒',
    m: '一分鐘',
    mm: '%d分鐘',
    h: '一小時',
    hh: '%d小時',
    d: '一天',
    dd: '%d天',
    M: '一個月',
    MM: '%d個月',
    y: '一年',
    yy: '%d年',
  },
  ordinalParse: /\d{1,2}日/,
  ordinal: function (val: number) {
    return val + '日'
  },
  week: {
    dow: 0,
    doy: 4,
  },
}
