import React from 'react'
import { makeStyles } from '@mui/styles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import UserAvatar from '@/legacy/components/v1/atomic/v1/userAvatar'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { generateRandomColor } from '@aula/tools/color'
import CustomTooltip from './customTooltip'

const useStyles = makeStyles((theme) => ({
  root: ({ withGrading }) => ({
    width: '100%',
    maxWidth: withGrading ? 350 : 282,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #DFDFDA',
    borderBottom: 0,
  }),
}))

const useStudentStyles = makeStyles((theme) => ({
  avatar: ({ color }) => ({
    background: color,
    width: 30,
    height: 30,
    fontSize: 14,
    marginRight: 10,
  }),
  submitted: ({ submitted }) => ({
    color: submitted ? '#37ab57' : 'black',
    opacity: submitted ? 1 : 0.25,
    cursor: submitted ? 'pointer' : 'auto',
  }),
  studentItem: ({ submitted }) => ({
    borderBottom: '1px solid #DFDFDA',
    paddingTop: 16,
    paddingBottom: 16,
    cursor: submitted ? 'pointer' : 'auto',
    '&:hover': {
      backgroundColor: submitted ? '#f9f9f9' : 'inherit',
    },
  }),
  avatarContainer: {
    minWidth: 32,
  },
  name: {
    '& > span': {
      width: '120px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'block',
      textOverflow: 'ellipsis',
    },
  },
  grade: {
    width: '120px',
    textAlign: 'center',
  },
}))

export default function StudentList(props) {
  const { students, onClick, withGrading, gradingType } = props

  const classes = useStyles({ withGrading })

  return (
    <List disablePadding classes={{ root: classes.root }}>
      {students.map((student) => (
        <StudentItem
          student={student}
          withGrading={withGrading}
          gradingType={gradingType}
          key={student.id}
          onClick={onClick}
        />
      ))}
    </List>
  )
}

StudentList.defaultProps = {
  onClick: () => {},
  withGrading: false,
}

const evaluationTypes = [
  {
    id: 1,
    type: 'conceptual',
    constraints: {
      options: [
        { label: 'Insuficiente', max: 40 },
        { label: 'Regular', max: 60 },
        { label: 'Bien', max: 80 },
        { label: 'Muy bien', max: 90 },
        { label: 'Sobresaliente', max: 100 },
      ],
    },
  },
  {
    id: 2,
    type: 'numeric',
    constraints: {
      min: 1,
      max: 10,
    },
  },
  {
    id: 3,
    type: 'numeric',
    constraints: {
      min: 1,
      max: 100,
    },
  },
]

const StudentItem = (props) => {
  const { student, onClick, withGrading, gradingType } = props
  const { id, lastName, name, submitted, evaluation } = student

  const studentClasses = useStudentStyles({
    color: generateRandomColor(name + lastName),
    submitted,
  })

  const renderEval = () => {
    let str

    const evalType = evaluationTypes.find((et) => et.id === gradingType)

    if (!evalType) return

    switch (evalType.type) {
      case 'conceptual':
        str = evalType.constraints.options.find((c) => c.max >= evaluation.value).label
        break
      case 'numeric':
        str =
          evalType.constraints.max === 10
            ? `${Math.round((evaluation.value + Number.EPSILON) * 100) / 1000}/10`
            : `${evaluation.value}/${evalType.constraints.max}`
        break
      default:
        str = '-'
    }

    return str
  }

  const labelId = `checkbox-list-secondary-label-${id}`
  return (
    <ListItem
      id={`student-item-${id}`}
      classes={{ root: studentClasses.studentItem }}
      disableRipple
      button
      onClick={(e) => onClick(student)}
    >
      <ListItemAvatar className={studentClasses.avatarContainer}>
        <UserAvatar name={name} variant="circle" className={studentClasses.avatar}>
          {name[0]}
        </UserAvatar>
      </ListItemAvatar>
      <ListItemText id={labelId} primary={`${name} ${lastName}`} className={studentClasses.name} />
      {withGrading && (
        <ListItemText
          primary={evaluation && evaluation.value ? renderEval() : '-'}
          className={studentClasses.grade}
        />
      )}
      <ListItemSecondaryAction
        className={studentClasses.submitted}
        onClick={(e) => onClick(student)}
      >
        <CustomTooltip title={submitted ? 'Ver entrega' : 'No hay entrega'}>
          {submitted ? <Visibility /> : <VisibilityOff />}
        </CustomTooltip>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
