import * as React from 'react'
import type { SVGProps } from 'react'
const SvgBookLicenses = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="56" height="56" rx="6" fill="#FF6428" fill-opacity="0.15" />
    <rect
      x="9.5"
      y="21.6283"
      width="33"
      height="21"
      rx="1"
      transform="rotate(-15 9.5 21.6283)"
      fill="#2A205E"
    />
    <path
      d="M12.5 19C12.5 18.4477 12.9477 18 13.5 18H44.5C45.0523 18 45.5 18.4477 45.5 19V38C45.5 38.5523 45.0523 39 44.5 39H13.5C12.9477 39 12.5 38.5523 12.5 38V19Z"
      fill="#FF6428"
    />
    <rect x="15.5" y="24" width="12" height="12" fill="#CC3900" />
    <path d="M12.5 19L44.5 39H13.5C12.9477 39 12.5 38.5523 12.5 38V19Z" fill="#FF7D44" />
    <path d="M20.5 24L27.5 28.375V36H15.5V24H20.5Z" fill="#D95921" />
    <rect x="29.5" y="35" width="5" height="1" fill="#F6F6F7" />
    <rect x="35.5" y="35" width="6" height="1" fill="#F6F6F7" />
    <rect x="29.5" y="32" width="13" height="1" fill="#F6F6F7" />
    <rect x="29.5" y="29" width="13" height="1" fill="#F6F6F7" />
    <rect x="15.5" y="21" width="12" height="1" fill="#F6F6F7" />
  </svg>
)
export default SvgBookLicenses
