import type { AttendancesConfig } from '@/api/attendances/config'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type { AttendancesSubject } from './entities'

// Get Organization Attendance Configs
export const GET_ORGANIZATION_ATTENDANCES_CONFIGS_REQUEST =
  'ATTENDANCES/CONFIG/GET_ORGANIZATION_ATTENDANCES_CONFIGS_REQUEST'
export const GET_ORGANIZATION_ATTENDANCES_CONFIGS_SUCCESS =
  'ATTENDANCES/CONFIG/GET_ORGANIZATION_ATTENDANCES_CONFIGS_SUCCESS'
export const GET_ORGANIZATION_ATTENDANCES_CONFIGS_FAILURE =
  'ATTENDANCES/CONFIG/GET_ORGANIZATION_ATTENDANCES_CONFIGS_FAILURE'

export type GetOrganizationAttendancesConfigsRequest = GenericActionWithType<
  typeof GET_ORGANIZATION_ATTENDANCES_CONFIGS_REQUEST,
  { search?: string }
>

export type GetOrganizationAttendancesConfigsSuccess = GenericActionWithType<
  typeof GET_ORGANIZATION_ATTENDANCES_CONFIGS_SUCCESS,
  { attendanceConfigs: AttendancesConfig[] }
>

export type GetOrganizationAttendancesConfigsFailure = GenericActionWithType<
  typeof GET_ORGANIZATION_ATTENDANCES_CONFIGS_FAILURE,
  ErrorPayload
>

// Get Classroom Attendance Configs
export const GET_CLASSROOM_ATTENDANCES_CONFIG_REQUEST =
  'ATTENDANCES/CONFIG/GET_CLASSROOM_ATTENDANCES_CONFIG_REQUEST'
export const GET_CLASSROOM_ATTENDANCES_CONFIG_SUCCESS =
  'ATTENDANCES/CONFIG/GET_CLASSROOM_ATTENDANCES_CONFIG_SUCCESS'
export const GET_CLASSROOM_ATTENDANCES_CONFIG_FAILURE =
  'ATTENDANCES/CONFIG/GET_CLASSROOM_ATTENDANCES_CONFIG_FAILURE'

export type GetClassroomAttendancesConfigRequest = GenericActionWithType<
  typeof GET_CLASSROOM_ATTENDANCES_CONFIG_REQUEST
>

export type GetClassroomAttendancesConfigSuccess = GenericActionWithType<
  typeof GET_CLASSROOM_ATTENDANCES_CONFIG_SUCCESS,
  { attendanceConfig: AttendancesConfig }
>

export type GetClassroomAttendancesConfigFailure = GenericActionWithType<
  typeof GET_CLASSROOM_ATTENDANCES_CONFIG_FAILURE,
  ErrorPayload
>

// Save attendance config
export const SAVE_ATTENDANCE_CONFIG_REQUEST = 'ATTENDANCES/CONFIG/SAVE_ATTENDANCE_CONFIG_REQUEST'
export const SAVE_ATTENDANCE_CONFIG_SUCCESS = 'ATTENDANCES/CONFIG/SAVE_ATTENDANCE_CONFIG_SUCCESS'
export const SAVE_ATTENDANCE_CONFIG_FAILURE = 'ATTENDANCES/CONFIG/SAVE_ATTENDANCE_CONFIG_FAILURE'

export type SaveAttendanceConfigRequest = GenericActionWithType<
  typeof SAVE_ATTENDANCE_CONFIG_REQUEST
>

export type SaveAttendanceConfigSuccess = GenericActionWithType<
  typeof SAVE_ATTENDANCE_CONFIG_SUCCESS,
  { classroomIds: number[] }
>

export type SaveAttendanceConfigFailure = GenericActionWithType<
  typeof SAVE_ATTENDANCE_CONFIG_FAILURE,
  ErrorPayload
>

// Update attendance config
export const UPDATE_ATTENDANCE_CONFIG_REQUEST =
  'ATTENDANCES/CONFIG/UPDATE_ATTENDANCE_CONFIG_REQUEST'
export const UPDATE_ATTENDANCE_CONFIG_SUCCESS =
  'ATTENDANCES/CONFIG/UPDATE_ATTENDANCE_CONFIG_SUCCESS'
export const UPDATE_ATTENDANCE_CONFIG_FAILURE =
  'ATTENDANCES/CONFIG/UPDATE_ATTENDANCE_CONFIG_FAILURE'

export type UpdateAttendanceConfigRequest = GenericActionWithType<
  typeof UPDATE_ATTENDANCE_CONFIG_REQUEST
>

export type UpdateAttendanceConfigSuccess = GenericActionWithType<
  typeof UPDATE_ATTENDANCE_CONFIG_SUCCESS,
  { classroomIds: number[] }
>

export type UpdateAttendanceConfigFailure = GenericActionWithType<
  typeof UPDATE_ATTENDANCE_CONFIG_FAILURE,
  ErrorPayload
>

// Get classroom subjects and check whether they overlap perfectly
export const GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_REQUEST =
  'ATTENDANCES/CONFIG/GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_REQUEST'
export const GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_SUCCESS =
  'ATTENDANCES/CONFIG/GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_SUCCESS'
export const GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_FAILURE =
  'ATTENDANCES/CONFIG/GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_FAILURE'

export type GetAndValidateClassroomsSubjectsRequest = GenericActionWithType<
  typeof GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_REQUEST
>

export type GetAndValidateClassroomsSubjectsSuccess = GenericActionWithType<
  typeof GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_SUCCESS,
  {
    valid: boolean
    subjects: AttendancesSubject[]
  }
>

export type GetAndValidateClassroomsSubjectsFailure = GenericActionWithType<
  typeof GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_FAILURE,
  ErrorPayload
>

export type GetClassroomAttendancesConfigAction =
  | GetClassroomAttendancesConfigRequest
  | GetClassroomAttendancesConfigSuccess
  | GetClassroomAttendancesConfigFailure

export type GetOrganizationAttendanceConfigsAction =
  | GetOrganizationAttendancesConfigsRequest
  | GetOrganizationAttendancesConfigsSuccess
  | GetOrganizationAttendancesConfigsFailure

export type SaveAttendanceConfigAction =
  | SaveAttendanceConfigRequest
  | SaveAttendanceConfigSuccess
  | SaveAttendanceConfigFailure

export type UpdateAttendanceConfigAction =
  | UpdateAttendanceConfigRequest
  | UpdateAttendanceConfigSuccess
  | UpdateAttendanceConfigFailure

export type GetAndValidateClassroomsSubjectsAction =
  | GetAndValidateClassroomsSubjectsRequest
  | GetAndValidateClassroomsSubjectsSuccess
  | GetAndValidateClassroomsSubjectsFailure
