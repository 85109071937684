import React from 'react'
import { makeStyles } from '@mui/styles'
import Avatar from '@mui/material/Avatar'
import clsx from 'clsx'
import { generateRandomColor } from '@aula/tools/color'

const useStyles = makeStyles((theme) => ({
  avatar: ({ color }) => ({ background: color }),
}))

const UserAvatar = ({ name, color, className, variant, ...props }) => {
  const classes = useStyles({ color: color || generateRandomColor(name) })
  return (
    <Avatar className={clsx(classes.avatar, className)} title={name} variant={variant} {...props}>
      {name[0]}
    </Avatar>
  )
}

export default UserAvatar
