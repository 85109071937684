import type { GenericPayload } from '@/legacy/redux/utils'

export interface CollabMiniatureWaitingRoomState {
  open: boolean
  activityId: number | null
}

/* Set open */
export const SET_OPEN = 'COLLAB_MINIATURE_WAITING_ROOM/SET_OPEN'

interface SetOpenPayload extends GenericPayload {
  open: boolean
  activityId?: number
}
export interface SetOpen {
  type: typeof SET_OPEN
  payload: SetOpenPayload
}

export type CollabMiniatureWaitingRoomAction = SetOpen
