import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { Student } from '@/sections/student/summary/types/common'
import type { Params } from '@/sections/principal/dashboard/types/common'
import type { Migration, StudentWithClassroom } from './migrationClassrooms'

export interface StudentsDrawerState {
  open: boolean
  selectedMigration: Migration
  newStudents: Student[]
  status: REQUEST_STATUS
  total: number
  params: Params
  error: string
}

export const SET_STUDENTS_DRAWER_OPEN = 'STUDENTS_DRAWER/SET_STUDENTS_DRAWER_OPEN'
export const SET_STUDENTS_DRAWER_CLOSED = 'STUDENTS_DRAWER/SET_STUDENTS_DRAWER_CLOSED'

export const ADD_NEW_STUDENTS = 'CLASSROOM_MIGRATION/ADD_NEW_STUDENTS'
export const REMOVE_NEW_STUDENT = 'CLASSROOM_MIGRATION/REMOVE_NEW_STUDENT'

export const SET_SELECTED_MIGRATION = 'CLASSROOM_MIGRATION/SET_SELECTED_MIGRATION'

export const TOGGLE_EXISTING_STUDENT = 'CLASSROOM_MIGRATION/TOGGLE_EXISTING_STUDENT'

export const VERIFY_CLASSROOM = 'CLASSROOM_MIGRATION/VERIFY_CLASSROOM'

export const ADD_NEW_STUDENTS_SELECTION = 'CLASSROOM_MIGRATION/ADD_NEW_STUDENTS_SELECTION'

export const STUDENTS_DRAWER_LIMIT = 10

export interface SetStudentsDrawerOpen {
  type: typeof SET_STUDENTS_DRAWER_OPEN
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface SetStudentsDrawerClosed {
  type: typeof SET_STUDENTS_DRAWER_CLOSED
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface AddNewStudents {
  type: typeof ADD_NEW_STUDENTS
  payload: {
    requestOrigin: ReduxRequestOrigin
    students: Student[]
  }
}

export interface RemoveNewStudent {
  type: typeof REMOVE_NEW_STUDENT
  payload: {
    requestOrigin: ReduxRequestOrigin
    studentId: number
  }
}

export interface VerifyClassroom {
  type: typeof VERIFY_CLASSROOM
  payload: {
    requestOrigin: ReduxRequestOrigin
    migration: Migration
  }
}

export interface SetSelectedMigration {
  type: typeof SET_SELECTED_MIGRATION
  payload: {
    requestOrigin: ReduxRequestOrigin
    migration: Migration
  }
}

export interface ToggleExistingStudent {
  type: typeof TOGGLE_EXISTING_STUDENT
  payload: {
    requestOrigin: ReduxRequestOrigin
    studentId: number
    isNew: boolean
  }
}

export interface AddNewStudentsSelectionAction {
  type: typeof ADD_NEW_STUDENTS_SELECTION
  payload: {
    requestOrigin: ReduxRequestOrigin
    newStudentsList: StudentWithClassroom[]
  }
}

export type StudentsDrawerActions =
  | SetStudentsDrawerOpen
  | SetStudentsDrawerClosed
  | VerifyClassroom
  | AddNewStudents
  | RemoveNewStudent
  | SetSelectedMigration
  | ToggleExistingStudent
  | AddNewStudentsSelectionAction
