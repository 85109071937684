import ko from '@/locales/translations/ko/ko.json'
import common from '@/locales/translations/ko/common.json'
import glossary from '@/locales/translations/ko/glossary.json'
import validation from '@/locales/translations/ko/validation.json'

export default {
  translation: ko,
  common: common,
  glossary: glossary,
  validation: validation,
}
