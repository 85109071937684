import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import type {
  ContentCreatorCourseAction,
  CourseCreationDrawerState,
  CourseEntity,
} from '@/sections/content-creator/courses/types/courses'
import {
  GET_COURSE_SUCCESS,
  COURSE_INFORMATION_STEP,
  COURSE_TYPES,
  CREATE_COURSE_REQUEST,
  CREATE_COURSE_SUCCESS,
  DELETE_COURSE_REQUEST,
  DELETE_COURSE_SUCCESS,
  EDIT_COURSE_REQUEST,
  EDIT_COURSE_SUCCESS,
  GET_COURSE_FAILURE,
  GET_COURSE_REQUEST,
  SET_CLOSED,
  SET_LONG_DESCRIPTION,
  SET_OPEN,
  SET_SHORT_DESCRIPTION,
  SET_NAME,
  COURSE_ENTITY_TYPES,
  ADD_ENTITY,
  REMOVE_ACTIVITY,
  SET_SELECTED_ENTITIES,
  SET_TAGS,
  SET_REQUIREMENTS,
  SET_ADDRESSED_TO,
  SET_CURRENCY,
  SET_PRICE,
  SET_DURATION,
  SET_TYPE,
  SET_COVER_PHOTO,
  ADD_IMAGE_REQUEST,
  ADD_IMAGE_SUCCESS,
  ADD_IMAGE_FAILURE,
  REMOVE_IMAGE,
  REMOVE_ENTITY,
  EDIT_UNIT,
  CREATE_COURSE_FAILURE,
  EDIT_COURSE_FAILURE,
  DELETE_COURSE_FAILURE,
  SET_COURSE_DELETION_MODAL_OPEN,
  SET_CERTIFICATE_ACTIVATED,
  SET_OPEN_MODAL_CERTIFICATE,
  SET_CLOSE_MODAL_CERTIFICATE,
  TOGGLE_AVAILABLE_IN_LIBRARY,
} from '@/sections/content-creator/courses/types/courses'
import * as premiumActivitiesTypes from '@/sections/content-creator/premium-activities/types/premium-activities'
import { clone } from 'ramda'

const initialState: CourseCreationDrawerState = {
  open: false,
  step: COURSE_INFORMATION_STEP,
  name: '',
  shortDescription: '',
  longDescription: '',
  requirements: '',
  addressedTo: '',
  currency: 1,
  price: 0,
  duration: 0,
  type: COURSE_TYPES.GUIDED,
  coverPhoto: null,
  loadingCoverPhoto: false,
  selectedEntities: [],
  tags: [],
  activities: [],
  loadingActivities: false,
  loading: false,
  activitiesPagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0,
  },
  editMode: false,
  cloneMode: false,
  selectedCourseId: 0,
  error: '',
  courseDeletionModalOpen: false,
  certificateActivated: false,
  modalCertificateOpen: false,
  availableInLibrary: false,
}

const requestDestination = reduxRequestOriginMap.CONTENT_CREATOR_COURSE_CREATION_DRAWER

const CourseCreationDrawerReducer = (
  state: CourseCreationDrawerState = initialState,
  action: ContentCreatorCourseAction
): CourseCreationDrawerState => {
  if (action.payload?.requestOrigin !== requestDestination) return state

  switch (action.type) {
    case SET_OPEN:
      return { ...state, open: true, step: action.payload.step || COURSE_INFORMATION_STEP }
    case SET_CLOSED:
      return initialState
    case SET_NAME:
      return { ...state, name: action.payload.name }
    case SET_SHORT_DESCRIPTION:
      return { ...state, shortDescription: action.payload.shortDescription }
    case SET_LONG_DESCRIPTION:
      return { ...state, longDescription: action.payload.longDescription }
    case SET_TAGS: {
      return { ...state, tags: action.payload.tags }
    }
    case SET_REQUIREMENTS: {
      return { ...state, requirements: action.payload.requirements }
    }
    case SET_ADDRESSED_TO: {
      return { ...state, addressedTo: action.payload.addressedTo }
    }
    case SET_CURRENCY: {
      return { ...state, currency: action.payload.currency }
    }
    case SET_PRICE: {
      return { ...state, price: action.payload.price }
    }
    case SET_DURATION: {
      return { ...state, duration: action.payload.duration }
    }
    case SET_TYPE: {
      return { ...state, type: action.payload.type }
    }
    case SET_COVER_PHOTO: {
      return { ...state, coverPhoto: action.payload.coverPhoto }
    }
    case SET_SELECTED_ENTITIES:
      return { ...state, selectedEntities: action.payload.selectedEntities }
    case CREATE_COURSE_REQUEST:
    case EDIT_COURSE_REQUEST:
    case DELETE_COURSE_REQUEST:
      return { ...state, loading: true, error: '' }
    case CREATE_COURSE_SUCCESS:
    case EDIT_COURSE_SUCCESS:
    case DELETE_COURSE_SUCCESS:
      return initialState
    case CREATE_COURSE_FAILURE:
    case EDIT_COURSE_FAILURE:
    case DELETE_COURSE_FAILURE:
    case GET_COURSE_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case GET_COURSE_REQUEST:
      return {
        ...state,
        open: true,
        step: COURSE_INFORMATION_STEP,
        loading: true,
        error: '',
        selectedCourseId: action.payload.courseId,
      }
    case GET_COURSE_SUCCESS: {
      const { course, cloneMode, editMode } = action.payload
      const {
        id,
        name,
        shortDescription,
        longDescription,
        requirements,
        addressedTo,
        currency,
        price,
        duration,
        type,
        coverPhoto,
        content,
        tags,
        availableInLibrary,
      } = course

      const selectedEntities: CourseEntity[] = content
        .map((contentItem) => ({
          title: contentItem.text,
          order: contentItem.order,
          id: contentItem.resourceId,
          entityType: contentItem.type,
          type: contentItem.activity?.type,
        }))
        .sort((entityA, entityB) => entityA.order - entityB.order)

      return {
        ...state,
        open: true,
        loading: false,
        selectedCourseId: id,
        name,
        shortDescription,
        longDescription,
        requirements,
        addressedTo,
        currency,
        price,
        duration,
        type,
        coverPhoto,
        tags,
        selectedEntities,
        editMode,
        cloneMode,
        availableInLibrary,
      }
    }
    case ADD_ENTITY: {
      const { entity } = action.payload

      return { ...state, selectedEntities: [...state.selectedEntities, entity] }
    }
    case REMOVE_ENTITY: {
      const { entityIndex } = action.payload

      const filteredEntities = state.selectedEntities.filter((_, index) => index !== entityIndex)

      return { ...state, selectedEntities: filteredEntities }
    }
    case REMOVE_ACTIVITY: {
      const { activityId } = action.payload

      const filteredEntities = state.selectedEntities.filter(
        (entity) => entity.entityType === COURSE_ENTITY_TYPES.UNIT || entity.id !== activityId
      )

      return {
        ...state,
        selectedEntities: filteredEntities,
      }
    }
    case EDIT_UNIT: {
      const { title, index } = action.payload

      const selectedEntities = clone(state.selectedEntities)

      selectedEntities[index].title = title
      // @ts-ignore
      selectedEntities[index].modified = true

      return { ...state, selectedEntities }
    }
    case ADD_IMAGE_REQUEST: {
      return { ...state, loadingCoverPhoto: true }
    }
    case ADD_IMAGE_SUCCESS: {
      return { ...state, loadingCoverPhoto: false, coverPhoto: action.payload.url }
    }
    case ADD_IMAGE_FAILURE: {
      return { ...state, loadingCoverPhoto: false, error: action.payload.error }
    }
    case REMOVE_IMAGE: {
      return { ...state, coverPhoto: null }
    }
    case SET_COURSE_DELETION_MODAL_OPEN: {
      return {
        ...state,
        courseDeletionModalOpen: action.payload.open,
        selectedCourseId: action.payload.courseId || state.selectedCourseId,
      }
    }
    case SET_CERTIFICATE_ACTIVATED:
      return { ...state, certificateActivated: action.payload.certificateActivated }
    case SET_OPEN_MODAL_CERTIFICATE:
      return { ...state, modalCertificateOpen: action.payload.modalCertificateOpen }
    case SET_CLOSE_MODAL_CERTIFICATE:
      return { ...state, modalCertificateOpen: false }
    case premiumActivitiesTypes.GET_PREMIUM_ACTIVITIES_REQUEST:
      return {
        ...state,
        loadingActivities: true,
        activitiesPagination: {
          ...state.activitiesPagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    case premiumActivitiesTypes.GET_PREMIUM_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        loadingActivities: false,
        activities: action.payload.premiumActivities,
        error: '',
        activitiesPagination: {
          ...state.activitiesPagination,
          count: action.payload.count,
        },
      }
    }
    case premiumActivitiesTypes.GET_PREMIUM_ACTIVITIES_FAILURE: {
      return {
        ...state,
        loadingActivities: false,
        error: action.payload.error,
      }
    }
    case TOGGLE_AVAILABLE_IN_LIBRARY: {
      return { ...state, availableInLibrary: !state.availableInLibrary }
    }
    default:
      return state
  }
}

export default CourseCreationDrawerReducer
