import moment from 'moment'
import type {
  MessageEventsActions,
  MessageEventsState,
  MsgEvent,
} from '@/sections/teacher/bulletin/types/messageEvents'
import { MESSAGE_EVENTS_TYPES } from '@/sections/teacher/bulletin/types/messageEvents'

const initialState: MessageEventsState = {
  isOpenModal: false,
  form: {
    startDate: moment(),
    startTime: moment(),
    endDate: moment(),
    endTime: moment().add(1, 'm'),
    location: '',
  },
  events: [],
}

const messageEventsReducer = (
  state: MessageEventsState = initialState,
  action: MessageEventsActions
): MessageEventsState => {
  switch (action.type) {
    case MESSAGE_EVENTS_TYPES.CLEAN_MESSAGE_EVENTS: {
      return initialState
    }
    case MESSAGE_EVENTS_TYPES.OPEN_MESSAGE_EVENTS_MODAL: {
      const { startDate, startTime, endDate, endTime } = action.payload
      return {
        ...state,
        isOpenModal: true,
        form: {
          startDate,
          startTime,
          endDate,
          endTime,
          location: '',
        },
      }
    }
    case MESSAGE_EVENTS_TYPES.CLOSE_MESSAGE_EVENTS_MODAL: {
      return {
        ...state,
        isOpenModal: false,
      }
    }
    case MESSAGE_EVENTS_TYPES.SET_EVENT_MOMENT: {
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.momentType]: action.payload.newMoment,
        },
      }
    }
    case MESSAGE_EVENTS_TYPES.SET_LOCATION: {
      return {
        ...state,
        form: {
          ...state.form,
          location: action.payload.value.trim(),
        },
      }
    }
    case MESSAGE_EVENTS_TYPES.SET_EVENT_TO_DELETE: {
      return {
        ...state,
        events: state.events.map((event) => {
          return {
            ...event,
            isActiveToDelete: event.id === action.payload.eventId,
          }
        }),
      }
    }
    case MESSAGE_EVENTS_TYPES.ADD_NEW_MESSAGE_EVENT: {
      const { startDate, startTime, endDate, endTime, location } = state.form

      const newEvent: MsgEvent = {
        startDate,
        startTime,
        endDate,
        endTime,
        location,
        id: action.payload.newId,
        isActiveToDelete: false,
      }

      return {
        ...initialState,
        events: [...state.events, newEvent],
      }
    }
    case MESSAGE_EVENTS_TYPES.REMOVE_MESSAGE_EVENT: {
      const idToDelete = state.events.find((event) => event.isActiveToDelete)?.id

      return {
        ...state,
        events: state.events.filter((event) => event.id !== idToDelete),
      }
    }
    case MESSAGE_EVENTS_TYPES.SET_MESSAGE_EVENTS: {
      return {
        ...state,
        events: action.payload.events,
      }
    }
    default:
      return state
  }
}

export default messageEventsReducer
