import type {
  MessageDeleteActions,
  MessageDeleteState,
} from '@/sections/teacher/bulletin/types/messageDelete'
import { MESSAGE_DELETE_TYPES } from '@/sections/teacher/bulletin/types/messageDelete'

const initialState: MessageDeleteState = {
  open: false,
  messageId: 0,
}

export default function messageDeleteReducer(
  state: MessageDeleteState = initialState,
  action: MessageDeleteActions
): MessageDeleteState {
  switch (action.type) {
    case MESSAGE_DELETE_TYPES.OPEN: {
      const { messageId } = action.payload
      return {
        ...state,
        open: true,
        messageId,
      }
    }
    case MESSAGE_DELETE_TYPES.CLOSE: {
      return {
        ...state,
        open: false,
      }
    }
    default:
      return state
  }
}
