export const colorPalette = [
  '#FF6428',
  '#01CEAA',
  '#9B00AF',
  '#FFB800',
  '#675CFF',
  '#F98939',
  '#E84F92',
  '#212C4C',
  '#6AACD9',
  '#2E33A9',
  '#86AA59',
]

/**
 * Generate some random words
 */
const randomWords = () => Math.random().toString(36).substring(2)

/**
 * Utility to generaete random colors.
 * A random color is generated based on length of the string entered as the first argument.
 *
 * @param str some group of words like name and surname
 * @param palette use a provide custom palette to generate the random color
 * @param max maximum number of characters
 */
export const generateRandomColor = (str = randomWords(), palette = colorPalette, max = 27) => {
  let sum = 0
  for (let i = 0; i < Math.min(str.length, max); i++) {
    sum += str.charCodeAt(i)
  }

  return palette[sum % palette.length]
}
