import { Avatar, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import classNames from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'row',
    border: '1px solid #D8DBE8',
    borderRadius: '8px',
    alignItems: 'center',
    margin: '0',
    display: 'flex',
    height: 64,
    transition: 'background-color 100ms',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
  avatar: ({ iconColor }) => ({
    background: iconColor,
  }),
  avatarContainer: {
    margin: '10px 0',
    padding: '0 15px',
    width: 'fit-content',
  },
  line: ({ subjectColor: color }) => ({
    position: 'absolute',
    backgroundColor: color,
    width: 3,
    height: '90%',
    borderRadius: 4,
    left: 0,
    top: '5%',
  }),
  titleContainer: {
    marginLeft: 8,
  },
  title: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  date: {
    fontSize: 14,
    lineHeight: '18px',
  },
  subjectContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    textAlign: 'right',
    flexGrow: 1,
    marginRight: 28,
  },
  subjectDot: ({ subjectColor: color }) => ({
    backgroundColor: color,
    width: 9,
    height: 9,
    borderRadius: 4.5,
    marginLeft: 8,
  }),
}))

export default function TeacherClassroomCard(props) {
  const { Icon, iconColor, subjectColor, onClick } = props

  const classes = useStyles({ iconColor, subjectColor })
  return (
    <Paper elevation={0} classes={{ root: classNames(classes.root) }} onClick={onClick}>
      <div className={classes.line} />
      <div className={classes.avatarContainer}>
        <Avatar className={classes.avatar}>
          <Icon />
        </Avatar>
      </div>
      <div className={classes.titleContainer}>
        <Typography className={`${classes.title}`}>{props.title}</Typography>
        <Typography className={classes.date}>
          <b>{props.subtitle}</b> - {props.description}
        </Typography>
      </div>
      <div className={classes.subjectContainer}>
        <Typography>{props.subject}</Typography>
        {props.subject && <div className={classes.subjectDot} />}
      </div>
    </Paper>
  )
}

TeacherClassroomCard.defaultProps = {
  color: '#675CFF',
  onClick: () => {},
}
