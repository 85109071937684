import { useState } from 'react'
import { AutocompleteComponent } from '@/legacy/components/v1'
import { formatClassroomName } from '@aula/tools/common'
import { Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'

import type { ReactElement } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Anibot } from '@/legacy/components/v2/anibot'
import EmptyState from '@/legacy/components/v2/emptyState'
import LoadingContainer from '@/legacy/components/v3/loadingContainer'
import type { Option } from '@/legacy/components/v3/selects/selectSimple'
import SelectSimple from '@/legacy/components/v3/selects/selectSimple'
import type {
  Classroom,
  ClassroomToMigrate,
} from '@/sections/principal/classroom-migration/types/migrationClassrooms'
import { ArrowForward } from '@mui/icons-material'
import type { Pages } from '@/legacy/components/v3/moreTablePagination'
import MoreTablePagination from '@/legacy/components/v3/moreTablePagination'
import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import BaseChip from '@/legacy/components/v3/chips/baseChip'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1280,
    margin: '24px auto',
    padding: 12,
    [theme.breakpoints.up('md')]: {
      marginBottom: 70,
    },
  },
  columns: {
    margin: 24,
    padding: 16,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 16,
    color: theme.palette.primary.main,
  },

  rowContainer: {
    display: 'contents',
  },
  emptyStateContainer: {
    margin: 40,
  },
  leftItemContainer: {
    height: 48,
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    borderRadius: 3,
    boxSizing: 'border-box',
    border: '1px solid #DFDFDA',
  },
  rightItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  autocompleteInput: {
    padding: '10px 0px 10px 6px !important',
    background: '#FFF !important',
    color: theme.palette.primary.main,
  },
  bottomRowCellContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 22,
  },
  thinOutlineButton: {
    fontSize: 14,
    padding: '4px 10px',
    borderWidth: 1,
  },
  selectorWrapper: {
    marginBottom: 16,
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    padding: 16,
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.common.white,
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    width: '100%',
  },
  selectLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
  },
  classroomsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  classroomContainer: {
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    gap: 16,
  },
  leftSide: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  rightSide: {
    width: '100%',
  },
  classroomLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 32,
  },
  classroomLabel: {
    width: '100%',
    padding: '0 16px',
    color: theme.palette.text.secondary,
  },
  classroomName: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: theme.typography.fontFamily,
  },
  paginationContainer: {
    width: '100%',
    justifySelf: 'end',
  },
  chip: {
    border: '1px solid',
    padding: '2px 12px',
    borderRadius: 16,
    borderColor: theme.palette.border,
    cursor: 'default',
  },
}))

export type ClassroomsSelectionStepProps = {
  pagination: Pages
  newClassroomStatus: REQUEST_STATUS
  oldClassroomStatus: REQUEST_STATUS
  academicPeriodsOptions: Option[]
  classroomsToMigrate: ClassroomToMigrate[]
  lastYearId: number
  newClassroomEnabled?: boolean
  newClassrooms: Classroom[]
  newYearId: number
  oldClassrooms: Classroom[]
  handleOldClassroomPagination: (newPage: number) => void
  getNewClassroomOptions: (search: string) => void
  onLastYearChange: (year: number) => void
  onNewYearChange: (year: number) => void
  setClassroomToMigrate: (oldClassroom: Classroom, newClassroom?: Classroom) => void
  setClassroomToMigrateV2: (oldClassroom: Classroom, newClassroom?: Classroom) => void
  setNewClassroomModalOpen: () => void
}

const ClassroomsSelectionStep = ({ ...props }: ClassroomsSelectionStepProps): ReactElement => {
  const styles = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const [key, setKey] = useState<number>(0)

  const handleClassroomSelect = (oldClassroom: Classroom, newClassroom?: Classroom) => {
    props.setClassroomToMigrate(oldClassroom, newClassroom)
    props.setClassroomToMigrateV2(oldClassroom, newClassroom)
    if (!newClassroom) setKey((prevKey) => prevKey + 1)
  }

  const handleClassroomInputChange = (e: React.ChangeEvent, value: string, reason: string) => {
    if (reason === 'reset') return
    props.getNewClassroomOptions(value)
  }

  const noClassroom = {
    label: t('principal.classroomMigration.unSelected'),
    value: 0,
    classroom: { id: 0 },
  }

  const getAutocompleteDefaultValue = (newClassroomId: number) => {
    const classroomToMigrate = props.classroomsToMigrate.find(
      (ctm) => ctm.oldClassroom.id === newClassroomId
    )

    return classroomToMigrate
      ? {
          label: formatClassroomName(classroomToMigrate.newClassroom),
          value: classroomToMigrate.newClassroom.id,
        }
      : noClassroom
  }

  const getAutocompleteOptions = () => {
    return props.newClassrooms.map((newC) => ({
      label: formatClassroomName(newC),
      classroom: newC,
      value: newC.id,
    }))
  }

  return (
    <div className={styles.container}>
      {props.oldClassroomStatus === REQUEST_STATUS.LOADING ? (
        <LoadingContainer>
          <Anibot />
        </LoadingContainer>
      ) : (
        <>
          <div className={styles.selectorWrapper}>
            <div className={styles.selectContainer}>
              <Typography className={styles.selectLabel}>
                {t('principal.classroomMigration.migrateFrom')}
              </Typography>
              <SelectSimple
                value={
                  props.academicPeriodsOptions.find((ap) => ap.id === props.lastYearId)?.label || ''
                }
                options={props.academicPeriodsOptions}
                onChange={(option: Option) => {
                  props.onLastYearChange(option.id)
                }}
                styles={{
                  background: 'white',
                  backgroundSelected: 'white',
                  width: '100%',
                  color: theme.palette.primary.main,
                }}
              />
            </div>
            <ArrowForward
              style={{ marginTop: 24, color: theme.palette.text.secondary, fontSize: '2em' }}
            />
            <div className={styles.selectContainer}>
              <Typography className={styles.selectLabel}>
                {t('principal.classroomMigration.migrateTo')}
              </Typography>
              <SelectSimple
                value={
                  props.academicPeriodsOptions.find((ap) => ap.id === props.newYearId)?.label || ''
                }
                options={props.academicPeriodsOptions}
                onChange={(option: Option) => {
                  props.onNewYearChange(option.id)
                }}
                styles={{
                  background: 'white',
                  backgroundSelected: 'white',
                  width: '100%',
                  color: theme.palette.primary.main,
                }}
              />
            </div>
          </div>

          <div className={styles.classroomsWrapper}>
            {props.oldClassrooms && props.oldClassrooms.length > 0 && (
              <div className={styles.classroomLabelContainer}>
                <Typography className={styles.classroomLabel}>
                  {t('principal.classroomMigration.classroomOrigin')}
                </Typography>

                <Typography className={styles.classroomLabel}>
                  {t('principal.classroomMigration.classroomDestiny')}
                </Typography>
              </div>
            )}
            {props.oldClassrooms && props.oldClassrooms.length > 0 ? (
              props.oldClassrooms.map((oldC) => (
                <div key={oldC.id} className={styles.classroomContainer}>
                  <div className={styles.leftSide}>
                    <div className={styles.classroomName}>{formatClassroomName(oldC)}</div>
                    <BaseChip
                      label={`${oldC.studentsCount} ${t('generic.students')}`}
                      bgColor={theme.palette.common.white}
                      color={theme.palette.text.secondary}
                      className={styles.chip}
                    />
                  </div>
                  <ArrowForward style={{ color: theme.palette.grey[300], fontSize: 32 }} />
                  <div className={styles.rightSide}>
                    <AutocompleteComponent
                      key={oldC.id + key}
                      onChange={(_, val) =>
                        handleClassroomSelect(oldC, val ? val.classroom : undefined)
                      }
                      onInputChange={handleClassroomInputChange}
                      defaultValue={getAutocompleteDefaultValue(oldC.id)}
                      fullWidth
                      classes={{
                        inputRoot: styles.autocompleteInput,
                      }}
                      size="small"
                      options={getAutocompleteOptions()}
                      loading={props.newClassroomStatus === REQUEST_STATUS.LOADING}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.emptyStateContainer}>
                <EmptyState
                  title={t('principal.classroomMigration.noClassroomsToMigrate')}
                  subtitle={t('principal.classroomMigration.noClassroomsToMigrateSubtitle')}
                  hideImage
                />
              </div>
            )}
            {props.oldClassrooms && props.oldClassrooms.length > 0 && (
              <div className={styles.paginationContainer}>
                <MoreTablePagination
                  pagination={props.pagination}
                  onPageChange={props.handleOldClassroomPagination}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ClassroomsSelectionStep
