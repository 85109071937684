export type AddProfileModalState = {
  isOpen: boolean
}

export enum ADD_PROFILE_STATE_TYPES {
  OPEN_MODAL = 'MANAGEMENT/ADD_PROFILE_MODAL/OPEN_MODAL',
  CLOSE_MODAL = 'MANAGEMENT/ADD_PROFILE_MODAL/CLOSE_MODAL',
}

export type OpenModalAction = {
  type: ADD_PROFILE_STATE_TYPES.OPEN_MODAL
}

export type CloseModalAction = {
  type: ADD_PROFILE_STATE_TYPES.CLOSE_MODAL
}

export type AddProfileModalAction = OpenModalAction | CloseModalAction
