import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type {
  CreatedCompetenceType,
  CreatedReportCardConfigRangeScore,
} from '@/sections/report-card/grading/types/entities'
import type { FindClassroomReportCardConfigSuccess } from '@/sections/report-card/grading/types/mainView'
import type { StudentCompetenceScore } from '@/sections/report-card/grading/types/tabs/competencesTab'

export interface CompetenceScoresGradingFormState {
  competenceScoreTypesAndSelectedValues: CompetenceScoreTypeAndSelectedValue[]
  competenceScoreOptions: CreatedReportCardConfigRangeScore[]
  loading: boolean
  error: string
}

export interface CompetenceScoreTypeAndSelectedValue extends CreatedCompetenceType {
  selectedValueId: number
  competenceTypeId: number // Shadows id
}

export const INIT_STUDENT_COMPETENCE_SCORES =
  'REPORT_CARD/GRADING/FORMS/COMPETENCE_SCORES/INIT_STUDENT_COMPETENCE_SCORES'

export type InitStudentCompetenceScores = GenericActionWithType<
  typeof INIT_STUDENT_COMPETENCE_SCORES,
  { currentValues?: StudentCompetenceScore[] }
>

export const SET_COMPETENCE_VALUE =
  'REPORT_CARD/GRADING/FORMS/COMPETENCE_SCORES/SET_COMPETENCE_VALUE'

export type SetCompetenceValue = GenericActionWithType<
  typeof SET_COMPETENCE_VALUE,
  { newValueId: number; competenceTypeId: number }
>

export const SAVE_COMPETENCE_SCORES_REQUEST =
  'REPORT_CARD/GRADING/FORMS/COMPETENCE_SCORES/SAVE_COMPETENCE_SCORES_REQUEST'
export const SAVE_COMPETENCE_SCORES_SUCCESS =
  'REPORT_CARD/GRADING/FORMS/COMPETENCE_SCORES/SAVE_COMPETENCE_SCORES_SUCCESS'
export const SAVE_COMPETENCE_SCORES_FAILURE =
  'REPORT_CARD/GRADING/FORMS/COMPETENCE_SCORES/SAVE_COMPETENCE_SCORES_FAILURE'

export type SaveCompetenceScoresRequest = GenericActionWithType<
  typeof SAVE_COMPETENCE_SCORES_REQUEST
>
export type SaveCompetenceScoresSuccess = GenericActionWithType<
  typeof SAVE_COMPETENCE_SCORES_SUCCESS
>
export type SaveCompetenceScoresFailure = GenericActionWithType<
  typeof SAVE_COMPETENCE_SCORES_FAILURE,
  ErrorPayload
>

export type SaveCompetenceScoresAction =
  | SaveCompetenceScoresRequest
  | SaveCompetenceScoresSuccess
  | SaveCompetenceScoresFailure

export const UPDATE_COMPETENCE_SCORES_REQUEST =
  'REPORT_CARD/GRADING/FORMS/COMPETENCE_SCORES/UPDATE_COMPETENCE_SCORES_REQUEST'
export const UPDATE_COMPETENCE_SCORES_SUCCESS =
  'REPORT_CARD/GRADING/FORMS/COMPETENCE_SCORES/UPDATE_COMPETENCE_SCORES_SUCCESS'
export const UPDATE_COMPETENCE_SCORES_FAILURE =
  'REPORT_CARD/GRADING/FORMS/COMPETENCE_SCORES/UPDATE_COMPETENCE_SCORES_FAILURE'

export type UpdateCompetenceScoresRequest = GenericActionWithType<
  typeof UPDATE_COMPETENCE_SCORES_REQUEST
>
export type UpdateCompetenceScoresSuccess = GenericActionWithType<
  typeof UPDATE_COMPETENCE_SCORES_SUCCESS
>
export type UpdateCompetenceScoresFailure = GenericActionWithType<
  typeof UPDATE_COMPETENCE_SCORES_FAILURE,
  ErrorPayload
>

export type UpdateCompetenceScoresAction =
  | UpdateCompetenceScoresRequest
  | UpdateCompetenceScoresSuccess
  | UpdateCompetenceScoresFailure

export type CompetenceScoressGradingFormAction =
  | InitStudentCompetenceScores
  | FindClassroomReportCardConfigSuccess
  | ClearState
  | SetField<'currentValue', number>
  | SaveCompetenceScoresAction
  | UpdateCompetenceScoresAction
  | SetCompetenceValue
