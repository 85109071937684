import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import type {
  ContentCreatorModalAction as CourseCertificateAction,
  CourseCertificateState,
} from '@/sections/content-creator/courses/types/certificate'
import {
  SET_INCLUDE_DATE,
  SET_INCLUDE_DURATION,
  SET_INCLUDE_PLACE,
  SET_OPTIONAL_TEXT,
  CLEAR_CERTIFICATE_SUCCESS,
  EDIT_CERTIFICATE_SUCCESS,
  SET_CLOSE_CERTIFICATE,
  SET_ORGANIZATION,
  ADD_ORGANIZATION,
  DELETE_ORGANIZATION,
  ADD_ORG_LOGO_REQUEST,
  ADD_ORG_LOGO_SUCCESS,
  ADD_ORG_LOGO_FAILURE,
  REMOVE_LOGO,
  SET_CERTIFICATE,
  SET_CLARIFICATION,
  SET_POSITION,
  UPLOAD_SIGNATURE_REQUEST,
  UPLOAD_SIGNATURE_SUCCESS,
  UPLOAD_SIGNATURE_FAILURE,
  REMOVE_SIGNATURE,
  SET_SIGNATURE_MODAL_OPEN,
  LIST_SIGNATURES_REQUEST,
  LIST_SIGNATURES_SUCCESS,
  LIST_SIGNATURES_FAILURE,
  CREATE_SIGNATURE_REQUEST,
  CREATE_SIGNATURE_SUCCESS,
  CREATE_SIGNATURE_FAILURE,
  CLEAR_SIGNATURE_IMAGE,
  ADD_SIGNATURE,
  SELECT_SIGNATURE,
  ADD_CUSTOM_BACKGROUND_REQUEST,
  ADD_CUSTOM_BACKGROUND_SUCCESS,
  ADD_CUSTOM_BACKGROUND_FAILURE,
  REMOVE_CUSTOM_BACKGROUND,
} from '@/sections/content-creator/courses/types/certificate'

const initialState: CourseCertificateState = {
  certificate: {
    includeDuration: false,
    includePlace: false,
    includeDate: false,
    organizations: [],
    text: '',
    // position: '',
    // clarification: '',
    // signatureUrl: '',
    signatures: [],
    background: '',
  },
  openSignature: {
    position: '',
    clarification: '',
    signatureUrl: '',
    organizationId: 0,
  },
  error: '',
  logosBeingUploaded: [],
  signatureList: [],
  loadingSignature: false,
  signatureModalOpen: false,
  signatureModalOpenFromIndex: 0,
  loadingBackground: false,
}

const requestDestination = reduxRequestOriginMap.CONTENT_CREATOR_COURSE_CREATION_CERTIFICATE

const CourseCreationCertificate = (
  state: CourseCertificateState = initialState,
  action: CourseCertificateAction
): CourseCertificateState => {
  if (action.payload?.requestOrigin !== requestDestination) return state

  switch (action.type) {
    case SET_CERTIFICATE: {
      const { certificate, overwriteEverything } = action.payload

      return {
        ...state,
        certificate: overwriteEverything ? certificate : { ...state.certificate, ...certificate },
      }
    }
    case SET_INCLUDE_DURATION:
      return {
        ...state,
        certificate: { ...state.certificate, includeDuration: action.payload.includeDuration },
      }
    case SET_INCLUDE_PLACE:
      return {
        ...state,
        certificate: { ...state.certificate, includePlace: action.payload.includePlace },
      }
    case SET_INCLUDE_DATE:
      return {
        ...state,
        certificate: { ...state.certificate, includeDate: action.payload.includeDate },
      }
    case SET_OPTIONAL_TEXT:
      return {
        ...state,
        certificate: { ...state.certificate, text: action.payload.text },
      }
    case SET_CLARIFICATION:
      return {
        ...state,
        openSignature: { ...state.openSignature, clarification: action.payload.clarification },
      }
    case SET_POSITION:
      return {
        ...state,
        openSignature: { ...state.openSignature, position: action.payload.position },
      }
    case SET_ORGANIZATION: {
      const { idx, name, logo } = action.payload

      return {
        ...state,
        certificate: {
          ...state.certificate,
          organizations: state.certificate.organizations.map((org, i) =>
            i !== idx
              ? org
              : {
                  ...org,
                  name: name ?? org.name,
                  logo: logo ?? org.logo,
                }
          ),
        },
      }
    }
    case ADD_ORGANIZATION: {
      return {
        ...state,
        certificate: {
          ...state.certificate,
          organizations: [
            ...state.certificate.organizations,
            { name: action.payload.name ?? '', logo: action.payload.logo ?? '' },
          ],
        },
      }
    }
    case DELETE_ORGANIZATION: {
      const { idx } = action.payload

      return {
        ...state,
        certificate: {
          ...state.certificate,
          organizations: state.certificate.organizations.filter((org, i) => i !== idx),
        },
      }
    }
    case ADD_ORG_LOGO_REQUEST: {
      const { idx } = action.payload

      return {
        ...state,
        logosBeingUploaded: [...state.logosBeingUploaded, idx],
      }
    }
    case ADD_ORG_LOGO_SUCCESS: {
      const { idx, url } = action.payload

      return {
        ...state,
        logosBeingUploaded: state.logosBeingUploaded.filter((i) => i !== idx),
        certificate: {
          ...state.certificate,
          organizations: state.certificate.organizations.map((org, i) =>
            i !== idx ? org : { ...org, logo: url }
          ),
        },
      }
    }
    case ADD_ORG_LOGO_FAILURE: {
      const { idx } = action.payload

      return {
        ...state,
        logosBeingUploaded: state.logosBeingUploaded.filter((i) => i !== idx),
      }
    }
    case REMOVE_LOGO: {
      const { idx } = action.payload

      return {
        ...state,
        certificate: {
          ...state.certificate,
          organizations: state.certificate.organizations.map((org, i) =>
            i !== idx ? org : { ...org, logo: '' }
          ),
        },
      }
    }
    case UPLOAD_SIGNATURE_REQUEST: {
      return {
        ...state,
        loadingSignature: true,
      }
    }
    case UPLOAD_SIGNATURE_SUCCESS: {
      const { url } = action.payload

      return {
        ...state,
        loadingSignature: false,
        openSignature: { ...state.openSignature, signatureUrl: url },
      }
    }
    case UPLOAD_SIGNATURE_FAILURE: {
      return {
        ...state,
        loadingSignature: false,
      }
    }
    case CLEAR_SIGNATURE_IMAGE: {
      return {
        ...state,
        openSignature: {
          ...state.openSignature,
          signatureUrl: initialState.openSignature.signatureUrl,
        },
      }
    }
    case SELECT_SIGNATURE: {
      return {
        ...state,
        certificate: {
          ...state.certificate,
          signatures: state.certificate.signatures.map((sig, idx) =>
            idx === action.payload.index ? action.payload.signature : sig
          ),
        },
      }
    }
    case ADD_SIGNATURE: {
      const selectedSignature =
        state.signatureList.find(
          (sig) => !state.certificate.signatures.find((s) => sig.id === s.id)
        ) || state.signatureList[0]

      return {
        ...state,
        certificate: {
          ...state.certificate,
          signatures: [...state.certificate.signatures, selectedSignature && selectedSignature],
        },
      }
    }
    case REMOVE_SIGNATURE: {
      return {
        ...state,
        certificate: {
          ...state.certificate,
          signatures: state.certificate.signatures.filter((s, idx) => idx !== action.payload.index),
        },
      }
    }
    case SET_SIGNATURE_MODAL_OPEN:
      return {
        ...state,
        signatureModalOpen: action.payload.open,
        signatureModalOpenFromIndex: action.payload.index,
        openSignature: initialState.openSignature,
      }
    case LIST_SIGNATURES_REQUEST:
      return { ...state, loadingSignature: true }
    case LIST_SIGNATURES_SUCCESS:
      return {
        ...state,
        loadingSignature: false,
        signatureList: action.payload.signatures,
        certificate: {
          ...state.certificate,
          signatures: state.certificate.signatures?.length
            ? state.certificate.signatures
            : [action.payload.signatures?.[0]],
        },
      }
    case LIST_SIGNATURES_FAILURE:
      return { ...state, loadingSignature: false, error: action.payload.error }
    case CREATE_SIGNATURE_REQUEST:
      return { ...state, loadingSignature: true }
    case CREATE_SIGNATURE_SUCCESS:
      return {
        ...state,
        loadingSignature: false,
        signatureModalOpen: false,
        signatureModalOpenFromIndex: null,
        openSignature: initialState.openSignature,
        signatureList: [...state.signatureList, action.payload.signature],
        certificate: {
          ...state.certificate,
          signatures: state.certificate.signatures.map((s, i) =>
            i === state.signatureModalOpenFromIndex ? action.payload.signature : s
          ),
        },
      }
    case CREATE_SIGNATURE_FAILURE:
      return { ...state, loadingSignature: false, error: action.payload.error }
    case ADD_CUSTOM_BACKGROUND_REQUEST:
      return { ...state, loadingBackground: true, error: initialState.error }
    case ADD_CUSTOM_BACKGROUND_SUCCESS:
      return {
        ...state,
        loadingBackground: false,
        certificate: { ...state.certificate, background: action.payload.url },
      }
    case ADD_CUSTOM_BACKGROUND_FAILURE:
      return { ...state, loadingBackground: false, error: action.payload.error }
    case REMOVE_CUSTOM_BACKGROUND:
      return {
        ...state,
        certificate: { ...state.certificate, background: initialState.certificate.background },
      }
    case SET_CLOSE_CERTIFICATE:
    case CLEAR_CERTIFICATE_SUCCESS:
    case EDIT_CERTIFICATE_SUCCESS:
      return initialState

    default:
      return state
  }
}

export default CourseCreationCertificate
