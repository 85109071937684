import { moveParticipantFromBOR } from '@/sections/conference/actions/breakoutRooms'
import type {
  BreakoutRoomsAction,
  BreakoutRoomsState,
} from '@/sections/conference/types/breakoutRooms'
import {
  DISCARD_LOCAL_BOR_COPY,
  GET_BREAKOUT_ROOMS_FAILURE,
  GET_BREAKOUT_ROOMS_REQUEST,
  GET_BREAKOUT_ROOMS_SUCCESS,
  MODIFY_LOCAL_BOR_COPY,
  SET_BOR_DRAWER_OPEN,
  SET_BOR_MODAL_OPEN,
  UPDATE_BOR_FROM_SOCKET,
  UPDATE_BREAKOUT_ROOMS_FAILURE,
  UPDATE_BREAKOUT_ROOMS_REQUEST,
  UPDATE_BREAKOUT_ROOMS_SUCCESS,
  SET_SHOW_ONLY_STUDENTS,
} from '@/sections/conference/types/breakoutRooms'

const initialState: BreakoutRoomsState = {
  modalOpen: false,
  drawerOpen: false,
  breakoutRooms: [],
  localBORCopy: [],
  loading: false,
  error: '',
  showOnlyStudents: true,
}

function BORReducer(
  state: BreakoutRoomsState = initialState,
  action: BreakoutRoomsAction
): BreakoutRoomsState {
  switch (action.type) {
    case UPDATE_BREAKOUT_ROOMS_REQUEST:
    case GET_BREAKOUT_ROOMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case UPDATE_BOR_FROM_SOCKET:
    case GET_BREAKOUT_ROOMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        breakoutRooms: action.payload.breakoutRooms,
        localBORCopy: action.payload.breakoutRooms,
        modalOpen: false,
      }
    }
    case UPDATE_BREAKOUT_ROOMS_FAILURE:
    case GET_BREAKOUT_ROOMS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    }
    case UPDATE_BREAKOUT_ROOMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        modalOpen: false,
      }
    }
    case MODIFY_LOCAL_BOR_COPY: {
      const { participant, newRoomId } = action.payload

      const updatedLocalCopy = moveParticipantFromBOR(
        state.localBORCopy,
        participant,
        participant.roomId,
        newRoomId
      )

      return {
        ...state,
        localBORCopy: updatedLocalCopy,
      }
    }
    case DISCARD_LOCAL_BOR_COPY: {
      return { ...state, localBORCopy: state.breakoutRooms, modalOpen: false }
    }
    case SET_BOR_DRAWER_OPEN: {
      return { ...state, drawerOpen: action.payload.drawerOpen }
    }
    case SET_BOR_MODAL_OPEN: {
      return { ...state, modalOpen: action.payload.modalOpen }
    }
    case SET_SHOW_ONLY_STUDENTS: {
      return { ...state, showOnlyStudents: action.payload.showOnlyStudents }
    }
    default:
      return state
  }
}

export default BORReducer
