import { ActivitiesAPI } from './activities/activities'
import { AvvAPI } from './avv'
import { BackpackAPI } from './backpack'
import { ChatAPI } from './chat'
import { ClassroomsAPI } from './classrooms'
import { DocTypesAPI } from './docTypesOptions'
import { EbAdminPrincipalAPI } from './ebadmin-principal'
import { ExesAPI } from './exes'
import { ExorAPI } from './exor'
import { FeaturesAPI } from './features'
import { LibraryAPI } from './library'
import { LoginAPI } from './login'
import { ManagementUsersAPI } from './managementUsers'
import { NotifierAPI } from './notifier'
import { OrganizationsAPI } from './organizations'
import { QuizAPI } from './quiz'
import { RobotsAPI } from './robots'
import { StudentsAPI } from './students'
import { SubgroupsAPI } from './subgroups'
import { SubjectsAPI } from './subjects'
import { SubmissionsAPI } from './submissions'
import { UserAPI } from './user'
import { ExamsAPI } from './exams'

export const API = {
  EbAdminPrincipal: EbAdminPrincipalAPI,
  Activities: ActivitiesAPI,
  Backpack: BackpackAPI,
  Classrooms: ClassroomsAPI,
  AVV: AvvAPI,
  Chat: ChatAPI,
  Robots: RobotsAPI,
  EXES: ExesAPI,
  EXOR: ExorAPI,
  Library: LibraryAPI,
  Login: LoginAPI,
  Notifier: NotifierAPI,
  Students: StudentsAPI,
  Submissions: SubmissionsAPI,
  Subgroups: SubgroupsAPI,
  Subjects: SubjectsAPI,
  Quiz: QuizAPI,
  Organizations: OrganizationsAPI,
  User: UserAPI,
  Views: null,
  ManagementUsers: ManagementUsersAPI,
  DocTypes: DocTypesAPI,
  Features: FeaturesAPI,
  Exams: ExamsAPI,
}
