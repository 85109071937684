import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import * as Types from '@/sections/principal/classroom-migration/types/migrationTab'

const actions = {
  clearState: (requestOrigin: ReduxRequestOrigin): Types.ClearState => ({
    type: Types.CLEAR_STATE,
    payload: { requestOrigin },
  }),
  setActiveStep: (requestOrigin: ReduxRequestOrigin, activeStep: number): Types.SetActiveStep => ({
    type: Types.SET_ACTIVE_STEP,
    payload: { requestOrigin, activeStep },
  }),
}

export default actions
