import { combineReducers } from 'redux'
import initial from './initial'
import first from './first'
import second from './second'

export default combineReducers({
  initial,
  first,
  second,
})
