import types, { START_RECORDING, STOP_RECORDING } from '@/sections/conference/types/conference'
import { handleErrorsWithActionConference } from '@/legacy/utils/HandleErrors'
import { persistor } from '@/state/store'
import queryString from 'query-string'
import { isEmpty } from 'ramda'
import RecordRTC, { invokeSaveAsDialog, getSeekableBlob } from 'recordrtc'
import { RECORDER_OPTIONS } from '@/sections/conference/constants'
import messagesActions from '@/sections/header/actions/messages'
import { API } from '@/api/lms'

const getScreenStream = async (dispatch) => {
  const displayMediaStreamConstraints = {
    video: true,
    audio: true,
  }

  if (!navigator?.mediaDevices?.getDisplayMedia && !navigator?.getDisplayMedia)
    return [null, 'conference.actions.somethingWentWrong']

  let screenStream

  if (navigator.getDisplayMedia) {
    screenStream = await navigator.getDisplayMedia(displayMediaStreamConstraints)
  } else {
    screenStream = await navigator.mediaDevices.getDisplayMedia(displayMediaStreamConstraints)
  }

  const audioTracks = screenStream.getAudioTracks()
  const videoTracks = screenStream.getVideoTracks()

  if (isEmpty(audioTracks)) return [screenStream, 'conference.actions.noAudioSharingScreen']
  if (isEmpty(videoTracks)) return [screenStream, 'conference.actions.noVideoSharingScreen']

  audioTracks[0].onended = function (e) {
    dispatch(actions.stopRecording())
  }

  videoTracks[0].onended = function (e) {
    dispatch(actions.stopRecording())
  }

  return [screenStream, null]
}

const getAudioStream = async () => {
  if (!navigator?.mediaDevices?.getUserMedia) return [null, 'conference.actions.somethingWentWrong']
  const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true })

  const audioTracks = audioStream.getAudioTracks()

  if (isEmpty(audioTracks)) return [null, 'conference.actions.noMicrophone']

  return [audioStream, null]
}

const stopStreaming = (tracks) => tracks.forEach((track) => track.stop())

const actions = {
  setRoomWithURLParams: (classroomID, activityID, room, activityTitle, recordingEnabled) => ({
    type: types.SET_ROOM_WITH_URL_PARAMS,
    payload: { classroomID, activityID, room, activityTitle, recordingEnabled },
  }),

  setRoom: (room, classroomID, activityID, activityTitle, recordingEnabled) => ({
    type: types.SET_ROOM,
    payload: { room, classroomID, activityID, activityTitle, recordingEnabled },
  }),

  openAVV: (room, classroomID, activityID, activityTitle, recordingEnabled) => (dispatch) => {
    dispatch(actions.setRoom(room, classroomID, activityID, activityTitle, recordingEnabled))
    const stringParams = queryString.stringify({
      room,
      classroomID,
      activityID,
      activityTitle,
      recordingEnabled,
    })

    persistor.flush().then(() => {
      setTimeout(
        () => window.open(`/app/conectados?conference=${window.btoa(stringParams)}`, '_blank'),
        100
      )
    })
  },

  startAVV: () => (dispatch, getState) => {
    const { activityID } = getState().conference.conference

    dispatch({ type: types.START_AVV_REQUEST })

    return API.AVV.start(activityID)
      .then((response) => {
        dispatch(actions.startAVVSuccess())
        return true
      })
      .catch((error) => {
        handleErrorsWithActionConference(error, types.START_AVV_FAILURE, dispatch)
        return false
      })
  },

  startAVVSuccess: () => ({
    type: types.START_AVV_SUCCESS,
  }),

  hasStarted: (t) => (dispatch, getState) => {
    const { activityID, invalidURL } = getState().conference.conference

    dispatch({ type: types.HAS_STARTED_REQUEST })

    if (invalidURL) {
      return dispatch({
        type: types.HAS_STARTED_FAILURE,
        payload: {
          error: t('conference.invalidURLDataError.description'),
        },
      })
    }

    return API.AVV.get(activityID)
      .then((response) => {
        const { avv } = response.data.description
        dispatch(actions.hasStartedSuccess(avv.started !== ''))
      })
      .catch((error) => {
        handleErrorsWithActionConference(error, types.HAS_STARTED_FAILURE, dispatch)
      })
  },

  hasStartedSuccess: (started) => ({
    type: types.HAS_STARTED_SUCCESS,
    payload: { started },
  }),

  /**
   * Log conference events.
   *
   * @param event Either 'joined', 'left' or 'ping'
   * @param content Empty or 'mobile'
   * @returns {function: Promise}
   */
  addEvent:
    (event, content = '') =>
    (dispatch, getState) => {
      const { activityID } = getState().conference.conference

      dispatch({ type: types.ADD_EVENT_REQUEST, payload: { event } })

      return API.AVV.addEvent(activityID, event, content)
        .then((response) => {
          dispatch(actions.addEventSuccess())
          return true
        })
        .catch((error) => {
          handleErrorsWithActionConference(error, types.ADD_EVENT_FAILURE, dispatch)
          return false
        })
    },

  addEventSuccess: () => ({
    type: types.ADD_EVENT_SUCCESS,
  }),

  getToken: () => (dispatch, getState) => {
    const { activityID } = getState().conference.conference
    dispatch({ type: types.GET_TOKEN_REQUEST })

    return API.AVV.generateToken(activityID)
      .then((response) => {
        const token = response.data.description
        dispatch(actions.getTokenSuccess(token))
        return token
      })
      .catch((error) => {
        handleErrorsWithActionConference(error, types.GET_TOKEN_FAILURE, dispatch)
        return null
      })
  },

  getTokenSuccess: (token) => ({
    type: types.GET_TOKEN_SUCCESS,
    payload: { token },
  }),

  startRecording: () => async (dispatch) => {
    const [screenStream, errorScreenStream] = await getScreenStream(dispatch)
    if (errorScreenStream) {
      if (screenStream) stopStreaming(screenStream.getTracks())
      return dispatch(actions.recordingError(errorScreenStream))
    }
    const [audioStream, errorAudioStream] = await getAudioStream()
    if (errorAudioStream) {
      if (screenStream) stopStreaming(screenStream.getTracks())
      return dispatch(actions.recordingError(errorScreenStream))
    }

    const recorder = new RecordRTC([screenStream, audioStream], RECORDER_OPTIONS)

    recorder.startRecording()

    dispatch({ type: START_RECORDING, payload: { recorder, streams: [screenStream, audioStream] } })
  },

  recordingError: (description) => (dispatch) =>
    dispatch(messagesActions.showToast('Error', description, { error: true })),

  stopRecording: () => async (dispatch, getState) => {
    const { recorder, streams, activityTitle } = getState().conference.conference

    if (!recorder) return

    const onStopRecording = () => {
      const blob = recorder.getBlob()

      getSeekableBlob(blob, (seekableBlob) => {
        const fileName = `${activityTitle}.webm`

        const file = new File([seekableBlob], fileName)

        invokeSaveAsDialog(file, fileName)

        streams.forEach((stream) => stopStreaming(stream.getTracks()))

        dispatch({ type: STOP_RECORDING })
      })
    }

    await recorder.stopRecording(onStopRecording)
  },
}

export default actions
