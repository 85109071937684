import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { CreateFormPayload, GetTemplateContentResponse } from '@/api/forms/types'
import type { Field, NewFormField, Template } from '.'

export interface GenerateFormsModalState {
  form: CreateFormPayload
  template: {
    isTemplateListLoading: boolean
    isTemplateContentLoading: boolean
    templateSelected: Template | null
    templateList: Template[]
  }
  isOpenModal: boolean
  error: string | null
  currentStep: GENERATE_FORMS_STEPS
  isSubmitting: boolean
  status: REQUEST_STATUS
  customField: {
    selectedCustomFieldToEdit: Field | null
    createEditField: NewFormField
    isEditField: boolean
  }
  isEditMode: boolean
  hasAnswers: {
    value: boolean
    status: REQUEST_STATUS
    error: string
  }
  selectedFormId: number
}

export enum GENERATE_FORMS_STEPS {
  INITIAL = 0,
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
}

export enum GENERATE_FORMS_MODAL_TYPES {
  OPEN_MODAL = 'FORMS/GENERATE_FORMS_MODAL/OPEN_MODAL',
  CLOSE_MODAL = 'FORMS/GENERATE_FORMS_MODAL/CLOSE_MODAL',
  SET_CURRENT_STEP = 'FORMS/GENERATE_FORMS_MODAL/SET_CURRENT_STEP',
  SET_OPEN_DATE = 'FORMS/GENERATE_FORMS_MODAL/SET_OPEN_DATE',
  SET_CLOSE_DATE = 'FORMS/GENERATE_FORMS_MODAL/SET_CLOSE_DATE',
  SET_FORM_NAME = 'FORMS/GENERATE_FORMS_MODAL/SET_FORM_NAME',
  SET_FORM_DESCRIPTION = 'FORMS/GENERATE_FORMS_MODAL/SET_FORM_DESCRIPTION',
  GET_TEMPLATE_CONTENT_REQUEST = 'FORMS/GENERATE_FORMS_MODAL/GET_TEMPLATE_CONTENT_REQUEST',
  GET_TEMPLATE_CONTENT_SUCCESS = 'FORMS/GENERATE_FORMS_MODAL/GET_TEMPLATE_CONTENT_SUCCESS',
  GET_TEMPLATE_CONTENT_FAILURE = 'FORMS/GENERATE_FORMS_MODAL/GET_TEMPLATE_CONTENT_FAILURE',
  SET_ORGANIZATION_DATA = 'FORMS/GENERATE_FORMS_MODAL/SET_ORGANIZATION_DATA',
  SORT_DRAGGABLE_FIELDS = 'FORMS/GENERATE_FORMS_MODAL/SORT_DRAGGABLE_FIELDS',
  GET_TEMPLATE_INFO_REQUEST = 'FORMS/GENERATE_FORMS_MODAL/GET_TEMPLATE_INFO_REQUEST',
  GET_TEMPLATE_INFO_SUCCESS = 'FORMS/GENERATE_FORMS_MODAL/GET_TEMPLATE_INFO_SUCCESS',
  GET_TEMPLATE_INFO_FAILURE = 'FORMS/GENERATE_FORMS_MODAL/GET_TEMPLATE_INFO_FAILURE',
  SET_SELECTED_TEMPLATE = 'FORMS/GENERATE_FORMS_MODAL/SET_SELECTED_TEMPLATE',
  CREATE_FORM_REQUEST = 'FORMS/GENERATE_FORMS_MODAL/CREATE_FORM_REQUEST',
  CREATE_FORM_SUCCESS = 'FORMS/GENERATE_FORMS_MODAL/CREATE_FORM_SUCCESS',
  CREATE_FORM_FAILURE = 'FORMS/GENERATE_FORMS_MODAL/CREATE_FORM_FAILURE',
  HIDE_OR_SHOW_FIELD_VISIBILITY_BY_NAME = 'FORMS/GENERATE_FORMS_MODAL/HIDE_OR_SHOW_FIELD_VISIBILITY_BY_NAME',
  CREATE_CUSTOM_FIELD = 'FORMS/GENERATE_FORMS_MODAL/CREATE_CUSTOM_FIELD',
  EDIT_CUSTOM_FIELD = 'FORMS/GENERATE_FORMS_MODAL/EDIT_CUSTOM_FIELD',
  SET_CUSTOM_FIELD_TO_EDIT = 'FORMS/GENERATE_FORMS_MODAL/SET_CUSTOM_FIELD_TO_EDIT',
  REMOVE_CUSTOM_FIELD = 'FORMS/GENERATE_FORMS_MODAL/REMOVE_CUSTOM_FIELD',
  SET_SECTION_NAME_TO_CUSTOM_FIELD = 'FORMS/GENERATE_FORMS_MODAL/SET_SECTION_NAME_TO_CUSTOM_FIELD',
  SET_CUSTOM_FIELD_TEXT = 'FORMS/GENERATE_FORMS_MODAL/SET_CUSTOM_FIELD_TEXT',
  CLEAR_ACADEMIC_MODAL = 'FORMS/GENERATE_FORMS_MODAL/CLEAR_ACADEMIC_MODAL',
  CLEAR_CREATE_FORM_MODAL = 'FORMS/GENERATE_FORMS_MODAL/CLEAR_CREATE_FORM_MODAL',
  SET_FORM_TO_EDIT_REQUEST = 'FORMS/GENERATE_FORMS_MODAL/SET_FORM_TO_EDIT_REQUEST',
  SET_FORM_TO_EDIT_SUCCESS = 'FORMS/GENERATE_FORMS_MODAL/SET_FORM_TO_EDIT_SUCCESS',
  SET_FORM_TO_EDIT_FAILURE = 'FORMS/GENERATE_FORMS_MODAL/SET_FORM_TO_EDIT_FAILURE',
  GET_ANSWERS_REQUEST = 'FORMS/GENERATE_FORMS_MODAL/GET_ANSWERS_REQUEST',
  GET_ANSWERS_SUCCESS = 'FORMS/GENERATE_FORMS_MODAL/GET_ANSWERS_SUCCESS',
  GET_ANSWERS_FAILURE = 'FORMS/GENERATE_FORMS_MODAL/GET_ANSWERS_FAILURE',
  UPDATE_FORM_REQUEST = 'FORMS/GENERATE_FORMS_MODAL/UPDATE_FORM_REQUEST',
  UPDATE_FORM_SUCCESS = 'FORMS/GENERATE_FORMS_MODAL/UPDATE_FORM_SUCCESS',
  UPDATE_FORM_FAILURE = 'FORMS/GENERATE_FORMS_MODAL/UPDATE_FORM_FAILURE',
}

export type FormToEdit = CreateFormPayload

// Modal actions
export type OpenModalAction = {
  type: GENERATE_FORMS_MODAL_TYPES.OPEN_MODAL
}

export type CloseModalAction = {
  type: GENERATE_FORMS_MODAL_TYPES.CLOSE_MODAL
}

export type ClearAcademicModalAction = {
  type: GENERATE_FORMS_MODAL_TYPES.CLEAR_ACADEMIC_MODAL
}

// Set current step modal action
export type SetCurrentStepAction = {
  type: GENERATE_FORMS_MODAL_TYPES.SET_CURRENT_STEP
  payload: {
    newStep: GENERATE_FORMS_STEPS
  }
}

// Set organization info
export type SetOrganizationDataAction = {
  type: GENERATE_FORMS_MODAL_TYPES.SET_ORGANIZATION_DATA
  payload: {
    organizationId: number
    organizationName: string
    organizationEmblem: string | null
  }
}

export type GetTemplateContentRequestAction = {
  type: GENERATE_FORMS_MODAL_TYPES.GET_TEMPLATE_CONTENT_REQUEST
}

export type GetTemplateContentSuccessAction = {
  type: GENERATE_FORMS_MODAL_TYPES.GET_TEMPLATE_CONTENT_SUCCESS
  payload: { content: GetTemplateContentResponse }
}

export type GetTemplateContentFailureAction = {
  type: GENERATE_FORMS_MODAL_TYPES.GET_TEMPLATE_CONTENT_FAILURE
  payload: { error: string }
}

export type GetTemplateContentActions =
  | GetTemplateContentRequestAction
  | GetTemplateContentSuccessAction
  | GetTemplateContentFailureAction

// Set dates
export type SetOpenDateAction = {
  type: GENERATE_FORMS_MODAL_TYPES.SET_OPEN_DATE
  payload: { date: string }
}

export type SetCloseDateAction = {
  type: GENERATE_FORMS_MODAL_TYPES.SET_CLOSE_DATE
  payload: { date: string }
}

export type SetFormNameAction = {
  type: GENERATE_FORMS_MODAL_TYPES.SET_FORM_NAME
  payload: { name: string }
}

export type SetFormDescriptionAction = {
  type: GENERATE_FORMS_MODAL_TYPES.SET_FORM_DESCRIPTION
  payload: { description: string }
}

export type SortDraggableFieldsAction = {
  type: GENERATE_FORMS_MODAL_TYPES.SORT_DRAGGABLE_FIELDS
  payload: {
    sectionName: string
    fields: Field[]
  }
}

// Get Templates info
export type SetTemplateSelectedAction = {
  type: GENERATE_FORMS_MODAL_TYPES.SET_SELECTED_TEMPLATE
  payload: {
    template: Template
  }
}

export type GetTemplateInfoRequestAction = {
  type: GENERATE_FORMS_MODAL_TYPES.GET_TEMPLATE_INFO_REQUEST
}

export type GetTemplateInfoSuccessAction = {
  type: GENERATE_FORMS_MODAL_TYPES.GET_TEMPLATE_INFO_SUCCESS
  payload: {
    templates: Template[]
  }
}

export type GetTemplateInfoFailureAction = {
  type: GENERATE_FORMS_MODAL_TYPES.GET_TEMPLATE_INFO_FAILURE
  payload: {
    error: string
  }
}

export type GetTemplateInfoActions =
  | GetTemplateInfoRequestAction
  | GetTemplateInfoSuccessAction
  | GetTemplateInfoFailureAction

export type CreateFormRequestAction = {
  type: GENERATE_FORMS_MODAL_TYPES.CREATE_FORM_REQUEST
}

export type CreateFormSuccessAction = {
  type: GENERATE_FORMS_MODAL_TYPES.CREATE_FORM_SUCCESS
}
export type CreateFormFailureAction = {
  type: GENERATE_FORMS_MODAL_TYPES.CREATE_FORM_FAILURE
  payload: { error: string }
}

export type CreateFormActions =
  | CreateFormRequestAction
  | CreateFormSuccessAction
  | CreateFormFailureAction

export type HideOrShowFieldVisibilityByNameAction = {
  type: GENERATE_FORMS_MODAL_TYPES.HIDE_OR_SHOW_FIELD_VISIBILITY_BY_NAME
  payload: {
    sectionName: string
    fieldName: string
    hide: boolean
  }
}

export type CreateCustomFieldAction = {
  type: GENERATE_FORMS_MODAL_TYPES.CREATE_CUSTOM_FIELD
  payload: { field: NewFormField }
}

export type SetCustomFieldToEditAction = {
  type: GENERATE_FORMS_MODAL_TYPES.SET_CUSTOM_FIELD_TO_EDIT
  payload: { fieldToEdit: NewFormField }
}

export type EditCustomFieldAction = {
  type: GENERATE_FORMS_MODAL_TYPES.EDIT_CUSTOM_FIELD
}

export type RemoveCustomFieldAction = {
  type: GENERATE_FORMS_MODAL_TYPES.REMOVE_CUSTOM_FIELD
  payload: { fieldName: string; sectionName: string }
}

export type SetSectionNameToCustomFieldAction = {
  type: GENERATE_FORMS_MODAL_TYPES.SET_SECTION_NAME_TO_CUSTOM_FIELD
  payload: { name: string }
}

export type SetCustomFieldTextAction = {
  type: GENERATE_FORMS_MODAL_TYPES.SET_CUSTOM_FIELD_TEXT
  payload: { text: string }
}

export type SetFormToEditRequestAction = {
  type: GENERATE_FORMS_MODAL_TYPES.SET_FORM_TO_EDIT_REQUEST
}

export type SetFormToEditSuccessAction = {
  type: GENERATE_FORMS_MODAL_TYPES.SET_FORM_TO_EDIT_SUCCESS
  payload: {
    form: FormToEdit
    formId: number
  }
}

export type SetFormToEditFailureAction = {
  type: GENERATE_FORMS_MODAL_TYPES.SET_FORM_TO_EDIT_FAILURE
  payload: { error: string }
}

export type ClearCreateFormModalAction = {
  type: GENERATE_FORMS_MODAL_TYPES.CLEAR_CREATE_FORM_MODAL
}

export type GetAnswersRequestAction = {
  type: GENERATE_FORMS_MODAL_TYPES.GET_ANSWERS_REQUEST
}
export type GetAnswersSuccessAction = {
  type: GENERATE_FORMS_MODAL_TYPES.GET_ANSWERS_SUCCESS
  payload: { hasAnswers: boolean }
}
export type GetAnswersFailureAction = {
  type: GENERATE_FORMS_MODAL_TYPES.GET_ANSWERS_FAILURE
  payload: { error: string }
}
export type UpdateFormRequestAction = {
  type: GENERATE_FORMS_MODAL_TYPES.UPDATE_FORM_REQUEST
}

export type UpdateFormSuccessAction = {
  type: GENERATE_FORMS_MODAL_TYPES.UPDATE_FORM_SUCCESS
}
export type UpdateFormFailureAction = {
  type: GENERATE_FORMS_MODAL_TYPES.UPDATE_FORM_FAILURE
  payload: { error: string }
}

export type GenerateFormsModalActions =
  | OpenModalAction
  | CloseModalAction
  | SetCurrentStepAction
  | SetOpenDateAction
  | SetCloseDateAction
  | SetFormNameAction
  | HideOrShowFieldVisibilityByNameAction
  | SetOrganizationDataAction
  | SortDraggableFieldsAction
  | SetOrganizationDataAction
  | GetTemplateInfoActions
  | GetTemplateContentActions
  | SetTemplateSelectedAction
  | CreateCustomFieldAction
  | EditCustomFieldAction
  | SetCustomFieldToEditAction
  | RemoveCustomFieldAction
  | GetTemplateInfoActions
  | SetCustomFieldTextAction
  | SetSectionNameToCustomFieldAction
  | CreateFormActions
  | ClearAcademicModalAction
  | SetFormDescriptionAction
  | SetFormToEditRequestAction
  | SetFormToEditSuccessAction
  | SetFormToEditFailureAction
  | ClearCreateFormModalAction
  | GetAnswersRequestAction
  | GetAnswersSuccessAction
  | GetAnswersFailureAction
  | UpdateFormRequestAction
  | UpdateFormSuccessAction
  | UpdateFormFailureAction
