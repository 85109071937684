import React, { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import renderEmoji from '@/legacy/components/v1/utils/renderEmoji'
import { isEmpty } from 'ramda'
import UserAvatar from './userAvatar'
import LockIcon from '@/legacy/components/v1/svgs/js/Lock'
import chroma from 'chroma-js'

const useStyles = makeStyles((theme) => ({
  container: ({ locked }) => ({
    minWidth: 232,
    height: 96,
    padding: '12px',
    backgroundColor: locked ? '#fff' : '#F7F8FC',
    position: 'relative',
    boxSizing: 'border-box',
  }),
  dividerLine: ({ color }) => ({
    position: 'absolute',
    backgroundColor: color,
    width: 3,
    height: '95%',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    left: 0,
    top: '2.5%',
  }),
  containerText: {
    fontFamily: 'DM Sans',
    fontSize: 14,
    color: '#1A1A1A',
    overflow: 'hidden',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '50%',
  },
  rowTop: {
    alignItems: 'flex-start',
  },
  rowBottom: {
    alignItems: 'flex-end',
  },
  iconTopRightArrow: {
    cursor: 'pointer',
  },
  iconTopRightLock: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightBottomCorner: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  avatar: {
    width: 20,
    height: 20,
    fontSize: 12,
  },
  emojiContainer: {
    background: '#EDEFF8',
    padding: '1px 6px',
    borderRadius: 16,
    marginRight: 6,
    cursor: 'pointer',
  },
  emojiText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#1A1A1A',
  },
  iconButton: ({ isAnchorEl }) => ({
    width: 24,
    color: '#1A1A1A',
    height: 24,
    padding: 0,
    ...(isAnchorEl && { border: '1px solid ' + theme.palette.primary.color }),
  }),
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
  },
  menuItem: {
    fontWeight: theme.typography.fontWeightRegular,
    padding: '0px 16px',
    '&:last-child > div': {
      borderBottom: 'none',
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&:active': {
      color: chroma(theme.palette.primary.main).brighten(0.5).hex(),
    },
  },
  menuItemContainer: {
    width: '100%',
    display: 'flex',
    padding: '9px 0',
    borderBottom: '1px solid #D8DBE8',
    alignItems: 'center',
  },
}))

const ActivityCell = (props) => {
  const { locked, color, menuOptions = [], rightEmoji } = props
  const [anchorEl, setAnchorEl] = useState(null)

  const isAnchorEl = Boolean(anchorEl)
  const classes = useStyles({ color, locked, isAnchorEl })
  const handleClose = () => setAnchorEl(null)

  const onClick = (onClickFn) => {
    onClickFn(props.activity)
    handleClose()
  }

  return (
    <Paper className={classes.container} variant="outlined">
      <div className={classes.dividerLine} />
      <div className={`${classes.row} ${classes.rowTop}`}>
        <Typography component="div" className={classes.containerText}>
          <div className={classes.text} title={props.text}>
            {props.text}
          </div>
          <div className={classes.title} title={props.title}>
            {props.title}
          </div>
        </Typography>
        {props.locked ? (
          <div className={classes.iconTopRightLock}>
            <LockIcon />
          </div>
        ) : (
          <div>
            <IconButton
              className={classes.iconButton}
              disableRipple
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={isAnchorEl}
              onClose={handleClose}
            >
              {menuOptions.map((option) => (
                <MenuItem
                  disableRipple
                  className={classes.menuItem}
                  key={option.label}
                  onClick={() => onClick(option.onClick)}
                >
                  <Typography className={classes.menuItemContainer}>{option.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}
      </div>
      <div className={`${classes.row} ${classes.rowBottom}`}>
        {props.leftIcon}
        <div className={classes.rightBottomCorner}>
          {!isEmpty(rightEmoji) && (
            <div className={classes.emojiContainer}>
              <Typography className={classes.emojiText}>{renderEmoji(props.rightEmoji)}</Typography>
            </div>
          )}
          <UserAvatar name={props.teacher} className={classes.avatar} />
        </div>
      </div>
    </Paper>
  )
}

ActivityCell.defaultProps = {
  rightEmoji: '',
}

export default ActivityCell
