import type { LocaleSpecification } from 'moment'
import moment from 'moment'

export const zh: LocaleSpecification = {
  months: [
    '一月',
    '二月',
    '三月',
    '四月',
    '五月',
    '六月',
    '七月',
    '八月',
    '九月',
    '十月',
    '十一月',
    '十二月',
  ],
  monthsShort: [
    '一月',
    '二月',
    '三月',
    '四月',
    '五月',
    '六月',
    '七月',
    '八月',
    '九月',
    '十月',
    '十一月',
    '十二月',
  ],
  weekdays: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
  weekdaysShort: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
  weekdaysMin: ['日', '一', '二', '三', '四', '五', '六'],
  longDateFormat: {
    LT: 'A h:mm',
    LTS: 'A h:mm:ss',
    L: 'YYYY/MM/DD',
    LL: 'YYYY年 MMMM D日',
    LLL: 'YYYY年 MMMM D日 LT',
    LLLL: 'YYYY年 MMMM D日 dddd LT',
  },
  calendar: {
    sameDay: function () {
      return '[今天] LT'
    },
    nextDay: function () {
      return '[明天] LT'
    },
    nextWeek: function () {
      return 'dddd [at] LT'
    },
    lastDay: function () {
      return '[昨天] LT'
    },
    lastWeek: function () {
      return 'dddd [at] LT'
    },
    sameElse: 'L',
  },
  relativeTime: {
    future: '在 %s',
    past: '%s前',
    s: '几秒',
    m: '一分钟',
    mm: '%d 分钟',
    h: '一小时',
    hh: '%d 小时',
    d: '一天',
    dd: '%d 天',
    M: '一个月',
    MM: '%d 个月',
    y: '一年',
    yy: '%d 年',
  },
  ordinalParse: /\d{1,2}(st|nd|rd|th)/,
  ordinal: function (val: number) {
    const b = val % 10
    return b === 1 && val !== 11
      ? val + '日'
      : b === 2 && val !== 12
      ? val + '日'
      : b === 3 && val !== 13
      ? val + '日'
      : val + '日'
  },
  week: {
    dow: 0,
    doy: 4,
  },
}

// Set the locale globally
moment.updateLocale('zh', zh)
