import type { AttendanceStatus, DayOff } from '@/api/attendances/config'
import { findDuplicateValuesInObjectArray } from '@/legacy/utils/generalUtils'
import type { DayOffCategory } from './types/mainTab'

export const dayOffToADayOffCategories = (absences: DayOff[]): DayOffCategory[] =>
  absences.reduce<DayOffCategory[]>((acc, abs) => {
    const existingCategory = acc.find((cat) => cat.reason === abs.reason)
    if (existingCategory) {
      return acc.map((cat) =>
        cat.reason === abs.reason ? { ...cat, dates: [...cat.dates, abs.date] } : cat
      )
    }
    return [...acc, { reason: abs.reason, dates: [abs.date] }]
  }, [])

export const dayOffCategoriesToDayOffs = (absenceCategories: DayOffCategory[]): DayOff[] =>
  absenceCategories.reduce<DayOff[]>((acc, absCat) => {
    const individualAbsences = absCat.dates.map((date) => ({
      reason: absCat.reason,
      date,
    }))
    return [...acc, ...individualAbsences]
  }, [])

export const findOverlappingStatuses = (statuses: AttendanceStatus[]): boolean =>
  findDuplicateValuesInObjectArray<AttendanceStatus>(statuses, [
    'description',
    'abbreviation',
    'color',
  ])

export const findOverlappingDayOffCategories = (categories: DayOffCategory[]): boolean =>
  findDuplicateValuesInObjectArray(categories, ['reason'])
