import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'

export type DocTypeOption = {
  country: { id: number; name: string; flag: string }
  hidden: boolean
  id: number
  inputType: string
  min: number
  max: number
  name: string
  icon: string
}

export interface Document extends DocTypeOption {
  value: string

  // instanceId represents a specific document's id, while DocTypeOption.id
  // represents the document type id.
  instanceId: number
}

export const DEFAULT_DOC_TYPE: DocTypeOption = {
  country: {
    id: 1,
    name: 'Argentina',
    flag: 'https://storage.googleapis.com/educabot-aec-cdn-1/identification-types/FlagArgentina.svg',
  },
  hidden: false,
  id: 1,
  inputType: 'number',
  max: 8,
  min: 7,
  name: 'DNI',
  icon: '',
}

// Get docTypesOptions
export const GET_DOCTYPES_OPTIONS_REQUEST = 'DOCTYPES_OPTIONS/GET_DOCTYPES_OPTIONS_REQUEST'
export const GET_DOCTYPES_OPTIONS_SUCCESS = 'DOCTYPES_OPTIONS/GET_DOCTYPES_OPTIONS_SUCCESS'
export const GET_DOCTYPES_OPTIONS_FAILURE = 'DOCTYPES_OPTIONS/GET_DOCTYPES_OPTIONS_FAILURE'

export type GetDocTypesOptionRequest = GenericActionWithType<typeof GET_DOCTYPES_OPTIONS_REQUEST>
export type GetDocTypesOptionSuccess = GenericActionWithType<
  typeof GET_DOCTYPES_OPTIONS_SUCCESS,
  { docTypesOptions: DocTypeOption[] }
>
export type GetDocTypesOptionFailure = GenericActionWithType<
  typeof GET_DOCTYPES_OPTIONS_FAILURE,
  ErrorPayload
>
