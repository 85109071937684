import * as React from 'react'
import type { SVGProps } from 'react'
const SvgOrganization = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={36}
    height={36}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M.46 14.553H0v2.988h.46v-2.988ZM2.145 14.553h-.46v2.988h.46v-2.988ZM3.83 14.553h-.46v2.988h.46v-2.988ZM5.515 14.553h-.46v2.988h.46v-2.988ZM7.277 14.553h-.46v2.988h.46v-2.988ZM8.962 14.553h-.46v2.988h.46v-2.988ZM10.647 14.553h-.46v2.988h.46v-2.988ZM25.813 14.553h-.46v2.988h.46v-2.988ZM27.498 14.553h-.46v2.988h.46v-2.988ZM29.183 14.553h-.46v2.988h.46v-2.988ZM30.868 14.553h-.46v2.988h.46v-2.988ZM32.553 14.553h-.46v2.988h.46v-2.988ZM34.315 14.553h-.46v2.988h.46v-2.988ZM36 14.553h-.46v2.988H36v-2.988Z"
        fill="#2F2565"
      />
      <path d="M36 14.247H0v.536h36v-.536ZM18 0l-6.357 6.128h12.715L18 0Z" fill="#2F2565" />
      <path d="M11.643 17.694h12.715V6.128H11.643v11.566Z" fill="#01CEAA" />
      <path d="M36 17.157H0V36h36V17.157Z" fill="#01CEAA" />
      <path
        d="M36 33.472H0V36h36v-2.528ZM25.43 22.672l1.455-.919L18 12.945l-8.885 8.808 1.455.92h14.86Z"
        fill="#2F2565"
      />
      <path d="M25.353 22.672 18 15.55l-7.353 7.123V36h14.706V22.672Z" fill="#01CEAA" />
      <path
        d="M25.353 22.672 18 15.55l-7.353 7.123V36h14.706V22.672Z"
        fill="#263238"
        fillOpacity={0.2}
      />
      <path d="M20.987 29.183h-6.05V36h6.05v-6.817Z" fill="#3A5569" />
      <path
        d="M7.506 19.992H3.523v4.06h3.983v-4.06ZM7.506 27.268H3.523v4.06h3.983v-4.06ZM32.477 19.992h-3.983v4.06h3.983v-4.06ZM16.698 7.89h-3.14v3.753h3.14V7.889ZM22.442 7.89h-3.14v3.753h3.14V7.889ZM32.477 27.268h-3.983v4.06h3.983v-4.06ZM19.991 22.06H16.01v4.06h3.982v-4.06Z"
        fill="#FAECE1"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgOrganization
