import * as React from 'react'
import type { SVGProps } from 'react'
const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={75}
    height={73}
    viewBox="0 0 75 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={37.5} cy={36.5} r={26.5} fill="#D4D2DF" />
    <path
      d="M37.5 21.292c1.719 0 3.125 1.369 3.125 3.041V36.5c0 1.673-1.406 3.042-3.125 3.042s-3.125-1.369-3.125-3.042V24.334c0-1.673 1.406-3.042 3.125-3.042Zm-.031-15.209C20.219 6.084 6.25 19.71 6.25 36.5s13.969 30.417 31.219 30.417C54.75 66.917 68.75 53.29 68.75 36.5s-14-30.416-31.281-30.416Zm.031 54.75c-13.813 0-25-10.889-25-24.333 0-13.444 11.188-24.333 25-24.333 13.813 0 25 10.889 25 24.333 0 13.444-11.188 24.334-25 24.334Zm3.125-9.124h-6.25v-6.084h6.25v6.084Z"
      fill="#2A205E"
    />
  </svg>
)
export default SvgWarning
