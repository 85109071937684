export default {
  SET_WEEK_DAYS: 'ACTIVITIES_TEACHER/SET_WEEK_DAYS',
  SET_SELECTED_SUBJECTS: 'ACTIVITIES_TEACHER/SET_SELECTED_SUBJECTS',
  SET_ONLY_MY_ACTIVITIES: 'ACTIVITIES_TEACHER/SET_ONLY_MY_ACTIVITIES',
  SET_UPDATE_FILTERS: 'ACTIVITIES_TEACHER/SET_UPDATE_FILTERS',
  ACTIVITIES_REQUEST: 'ACTIVITIES_TEACHER/ACTIVITIES_TEACHER_REQUEST',
  ACTIVITIES_REQUEST_TIMEOUT: 'ACTIVITIES_TEACHER/ACTIVITIES_TEACHER_REQUEST_TIMEOUT',
  ACTIVITIES_SUCCESS: 'ACTIVITIES_TEACHER/ACTIVITIES_TEACHER_SUCCESS',
  ACTIVITIES_FAILURE: 'ACTIVITIES_TEACHER/ACTIVITIES_TEACHER_FAILURE',
  SUBJECTS_REQUEST: 'ACTIVITIES_TEACHER/SUBJECTS_TEACHER_REQUEST',
  SUBJECTS_SUCCESS: 'ACTIVITIES_TEACHER/SUBJECTS_TEACHER_SUCCESS',
  SUBJECTS_FAILURE: 'ACTIVITIES_TEACHER/SUBJECTS_TEACHER_FAILURE',
  CHANGE_DATE_REQUEST: 'ACTIVITIES_TEACHER/CHANGE_DATE_TEACHER_REQUEST',
  CHANGE_DATE_SUCCESS: 'ACTIVITIES_TEACHER/CHANGE_DATE_TEACHER_SUCCESS',
  CHANGE_DATE_FAILURE: 'ACTIVITIES_TEACHER/CHANGE_DATE_TEACHER_FAILURE',
}
