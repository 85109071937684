import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/content-creator/newBooks/types/drawer'

const initialState: Types.DrawerState = {
  isOpen: false,
  isEditMode: false,
  currentStep: Types.DRAWER_STEP.INITIAL,
  status: REQUEST_STATUS.IDLE,
  bookToEdit: null,
}

const addEditBookDrawerReducer = (
  state: Types.DrawerState = initialState,
  action: Types.DrawerAction
): Types.DrawerState => {
  switch (action.type) {
    case Types.DRAWER_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isOpen: true,
      }
    }
    case Types.DRAWER_TYPES.CLOSE_MODAL: {
      return initialState
    }
    case Types.DRAWER_TYPES.SET_CURRENT_STEP: {
      const { newStep } = action.payload

      return {
        ...state,
        currentStep: newStep,
      }
    }
    case Types.DRAWER_TYPES.SET_EDIT_MODE: {
      return {
        ...state,
        isEditMode: action.payload.isEditMode,
      }
    }
    case Types.DRAWER_TYPES.LOAD_BOOK_INFO_TO_EDIT_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.DRAWER_TYPES.LOAD_BOOK_INFO_TO_EDIT_SUCCESS: {
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
      }
    }
    case Types.DRAWER_TYPES.LOAD_BOOK_INFO_TO_EDIT_FAILURE: {
      return initialState
    }
    case Types.DRAWER_TYPES.SET_BOOK_ID_TO_EDIT: {
      return {
        ...state,
        bookToEdit: action.payload.bookToEdit,
      }
    }
    default:
      return state
  }
}

export default addEditBookDrawerReducer
