import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/content-creator/sequenceDrawer/types/activities'

const initialState: Types.ActivitiesState = {
  isOpenModal: false,
  status: REQUEST_STATUS.IDLE,
  activities: [],
  selectedEntities: [],
  searchText: '',
  pagination: {
    total: 0,
    offset: 0,
    limit: 10,
  },
}

const activitiesReducer = (
  state: Types.ActivitiesState = initialState,
  action: Types.ActivitiesAction
): Types.ActivitiesState => {
  switch (action.type) {
    case Types.ACTIVITIES_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isOpenModal: true,
      }
    }
    case Types.ACTIVITIES_TYPES.CLOSE_MODAL: {
      return {
        ...state,
        isOpenModal: false,
      }
    }
    case Types.ACTIVITIES_TYPES.GET_ACTIVITIES_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.ACTIVITIES_TYPES.GET_ACTIVITIES_SUCCESS: {
      const { activities, total } = action.payload

      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        activities,
        pagination: {
          ...state.pagination,
          total,
        },
      }
    }
    case Types.ACTIVITIES_TYPES.GET_ACTIVITIES_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.ACTIVITIES_TYPES.CLEAN_ACTIVITIES: {
      return initialState
    }
    case Types.ACTIVITIES_TYPES.SET_PAGINATION_OFFSET: {
      const { offset } = action.payload

      return {
        ...state,
        pagination: {
          ...state.pagination,
          offset,
        },
      }
    }
    case Types.ACTIVITIES_TYPES.SET_SEARCH_TEXT: {
      const { newValue } = action.payload

      return {
        ...state,
        searchText: newValue.trim(),
      }
    }
    case Types.ACTIVITIES_TYPES.SET_SELECTED_ENTITY: {
      const { newEntity } = action.payload

      return {
        ...state,
        selectedEntities: [...state.selectedEntities, newEntity],
      }
    }
    case Types.ACTIVITIES_TYPES.REMOVE_SELECTED_ENTITY: {
      const { idToRemove } = action.payload

      return {
        ...state,
        selectedEntities: state.selectedEntities.filter((entity) => entity.id !== idToRemove),
      }
    }
    default:
      return state
  }
}

export default activitiesReducer
