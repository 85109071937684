import type { ManagementUser } from '@/api/lms/managementUsers'
import type { ClearState } from '@/legacy/redux/types/generic'
import type { DeleteUserAction } from '@/legacy/redux/types/managementUsers'
import type { GenericActionWithType } from '@/legacy/redux/utils'

export interface DeleteUserModalState {
  open: boolean
  loading: boolean
  error: string
  user: ManagementUser | null
}

export const INIT_MODAL = 'MANAGEMENT/USERS/DELETE_USER_MODAL/INIT_MODAL'
export type InitModal = GenericActionWithType<typeof INIT_MODAL, { user: ManagementUser }>

export type DeleteUserModalAction = InitModal | DeleteUserAction | ClearState
