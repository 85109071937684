import * as React from 'react'
import type { SVGProps } from 'react'
const SvgQuiz = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={69}
    height={69}
    viewBox="0 0 69 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect opacity={0.15} width={69} height={69} rx={6} fill="#2E33A9" />
    <path
      d="M46.68 52H21.32A4.32 4.32 0 0 1 17 47.68V22.32A4.32 4.32 0 0 1 21.32 18h25.36A4.32 4.32 0 0 1 51 22.32v25.36A4.32 4.32 0 0 1 46.68 52Z"
      fill="#556080"
    />
    <path d="M33.37 20.519H21.32c-.994 0-1.801.807-1.801 1.8V34.37H33.37V20.52Z" fill="#F0C419" />
    <path d="M19.519 35.63v12.05c0 .994.807 1.802 1.8 1.802H33.37V35.63H19.52Z" fill="#ED7161" />
    <path d="M46.68 20.519H34.63V34.37H48.48V22.32c0-.994-.807-1.801-1.8-1.801Z" fill="#67B9CC" />
    <path
      d="M34.63 49.482h12.05c.994 0 1.801-.808 1.801-1.802V35.63H34.63v13.852Z"
      fill="#A4E869"
    />
    <path d="M26.13 32.482a4.722 4.722 0 1 0 0-9.445 4.722 4.722 0 0 0 0 9.445Z" fill="#F29C1F" />
    <path d="m41.555 23.038-4.407 4.407 4.407 4.407 4.407-4.407-4.407-4.407Z" fill="#48A0DC" />
    <path d="M30.852 46.333H22.037l4.407-8.814 4.408 8.814Z" fill="#DF4D60" />
    <path
      d="m41.556 37.519 1.591 3.225 3.446.552-2.462 2.475.608 3.546-3.183-1.674-3.184 1.674.608-3.546-2.461-2.475 3.445-.552 1.592-3.225Z"
      fill="#4FBA6F"
    />
  </svg>
)
export default SvgQuiz
