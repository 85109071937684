import {
  getBaseByEvaluationType,
  getConceptualScoreTranslation,
  getScoreByEvaluationType,
  isConceptualScore,
} from '@aula/tools/scores'
import type { TFunction } from 'i18next'

export const getScoreText = (
  value: number,
  evaluationType: number,
  t: TFunction<'translation'>,
  showBase?: boolean
): string => {
  const formattedValue = String(getScoreByEvaluationType(value, evaluationType))
  const number = new Intl.NumberFormat().format(Number(formattedValue))

  const text = isConceptualScore(evaluationType)
    ? getConceptualScoreTranslation(formattedValue, t)
    : showBase
    ? `${number} / ${getBaseByEvaluationType(evaluationType)}`
    : number

  return text
}
