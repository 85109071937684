import { FeatureFlag } from '@/api/lms/features';
import { REQUEST_STATUS } from '@/legacy/redux/types/status';

// State.
export interface DeleteModalState {
    isOpen: boolean
    status: REQUEST_STATUS
    feature: Pick<FeatureFlag, 'id' | 'name'>
}

// Types.
export enum DELETE_MODAL_TYPES {
    OPEN_MODAL = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/DELETE_MODAL/OPEN_MODAL',
    CLOSE_MODAL = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/DELETE_MODAL/CLOSE_MODAL',
    SET_FEATURE_TO_DELETE = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/DELETE_MODAL/SET_FEATURE_TO_DELETE',
    DELETE_FEATURE_FLAG_REQUEST = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/DELETE_MODAL/DELETE_FEATURE_FLAG_REQUEST',
    DELETE_FEATURE_FLAG_SUCCESS = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/DELETE_MODAL/DELETE_FEATURE_FLAG_SUCCESS',
    DELETE_FEATURE_FLAG_FAILURE = 'EB_ADMIN/DASHBOARD/FEATURE_FLAGS/DELETE_MODAL/DELETE_FEATURE_FLAG_FAILURE',
}

// Actions.
// Open modal action.
export type OpenModalAction = {
    type: DELETE_MODAL_TYPES.OPEN_MODAL,
}

// Close modal action.
export type CloseModalAction = {
    type: DELETE_MODAL_TYPES.CLOSE_MODAL,
}

// Set feature to delete action.
export type SetFeatureToDeleteAction = {
    type: DELETE_MODAL_TYPES.SET_FEATURE_TO_DELETE,
    payload: DeleteModalState['feature'],
}

// Delete feature flag actions.
export type DeleteFeatureFlagRequestAction = {
    type: DELETE_MODAL_TYPES.DELETE_FEATURE_FLAG_REQUEST,
}

export type DeleteFeatureFlagSuccessAction = {
    type: DELETE_MODAL_TYPES.DELETE_FEATURE_FLAG_SUCCESS,
}

export type DeleteFeatureFlagFailureAction = {
    type: DELETE_MODAL_TYPES.DELETE_FEATURE_FLAG_FAILURE,
}

export type DeleteFeatureFlagAction =
    | DeleteFeatureFlagRequestAction
    | DeleteFeatureFlagSuccessAction
    | DeleteFeatureFlagFailureAction;

// Action.
export type DeleteModalAction = 
    | OpenModalAction
    | CloseModalAction
    | SetFeatureToDeleteAction
    | DeleteFeatureFlagAction;