import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type {
  StudentsDrawerActions,
  StudentsDrawerState,
} from '@/sections/principal/classroom-migration/types/studentsDrawer'
import {
  SET_STUDENTS_DRAWER_CLOSED,
  SET_STUDENTS_DRAWER_OPEN,
  STUDENTS_DRAWER_LIMIT,
  REMOVE_NEW_STUDENT,
  ADD_NEW_STUDENTS,
  SET_SELECTED_MIGRATION,
  TOGGLE_EXISTING_STUDENT,
  ADD_NEW_STUDENTS_SELECTION,
} from '@/sections/principal/classroom-migration/types/studentsDrawer'

const initialState: StudentsDrawerState = {
  open: false,
  selectedMigration: {
    fromClassroomsList: [],
    studentsToAdd: [],
    students: [],
    studentsToRemove: [],
    verified: false,
    toClassroom: {
      studentsCount: 0,
      division: '',
      stage: '',
      shift: '',
      grade: '',
      id: 0,
      organizationId: 0,
      organizationName: '',
      year: 0,
    },
  },
  newStudents: [],
  status: REQUEST_STATUS.IDLE,
  total: 0,
  params: {
    offset: 0,
    limit: STUDENTS_DRAWER_LIMIT,
  },
  error: '',
}

const requestDestination = reduxRequestOriginMap.PRINCIPAL_MIGRATION_TAB

const StudentsDrawerReducer = (
  state: StudentsDrawerState = initialState,
  action: StudentsDrawerActions
): StudentsDrawerState => {
  if (action.payload?.requestOrigin !== requestDestination) return state

  switch (action.type) {
    case SET_STUDENTS_DRAWER_OPEN:
      return { ...state, open: true }
    case SET_STUDENTS_DRAWER_CLOSED:
      return initialState
    case ADD_NEW_STUDENTS: {
      const newStudents = action.payload.students.filter(
        (st) => !state.newStudents.find((ns) => ns.id === st.id)
      )
      return {
        ...state,
        newStudents: [...state.newStudents, ...newStudents],
        selectedMigration: {
          ...state.selectedMigration,
          studentsToAdd: [
            ...state.selectedMigration.studentsToAdd,
            ...newStudents.map((st) => st.id),
          ],
        },
      }
    }
    case REMOVE_NEW_STUDENT:
      return {
        ...state,
        newStudents: state.newStudents.filter((ns) => ns.id !== action.payload.studentId),
        selectedMigration: {
          ...state.selectedMigration,
          studentsToAdd: state.selectedMigration.studentsToAdd.filter(
            (sta) => sta !== action.payload.studentId
          ),
        },
      }
    case SET_SELECTED_MIGRATION: {
      const { migration } = action.payload
      return { ...state, selectedMigration: migration }
    }
    case TOGGLE_EXISTING_STUDENT: {
      const { studentId, isNew } = action.payload
      const key = isNew ? 'studentsToAdd' : 'studentsToRemove'

      const alreadyAdded = state.selectedMigration[key].includes(studentId)
      const filtered = state.selectedMigration[key].filter((st) => st !== studentId)
      const updated = alreadyAdded ? filtered : [...state.selectedMigration[key], studentId]

      return {
        ...state,
        selectedMigration: {
          ...state.selectedMigration,
          [key]: updated,
        },
      }
    }

    case ADD_NEW_STUDENTS_SELECTION: {
      const { newStudentsList } = action.payload
      return {
        ...state,
        selectedMigration: {
          ...state.selectedMigration,
          studentsToAdd: [
            ...state.selectedMigration.studentsToAdd,
            ...newStudentsList.map((st) => st.student.id),
          ],
        },
      }
    }
    default: {
      return state
    }
  }
}

export default StudentsDrawerReducer
