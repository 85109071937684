import type {
  AddFacilitatorModalActions,
  AddFacilitatorModalState,
} from '@/sections/ted/types/addFacilitatorModal'
import { ADD_FACILITATOR_MODAL_TYPES } from '@/sections/ted/types/addFacilitatorModal'

const initialState: AddFacilitatorModalState = {
  isOpen: false,
  loading: false,
  error: null,
  data: {
    provinces: [],
    participatedEditions: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 0],
  },
}

const AddFacilitatorModalReducer = (
  state: AddFacilitatorModalState = initialState,
  action: AddFacilitatorModalActions
): AddFacilitatorModalState => {
  switch (action.type) {
    case ADD_FACILITATOR_MODAL_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isOpen: true,
      }
    }
    case ADD_FACILITATOR_MODAL_TYPES.CLOSE_MODAL: {
      return {
        ...state,
        isOpen: false,
      }
    }
    case ADD_FACILITATOR_MODAL_TYPES.GET_PROVINCES_REQUEST: {
      return {
        ...state,
        error: null,
        loading: true,
      }
    }
    case ADD_FACILITATOR_MODAL_TYPES.GET_PROVINCES_SUCCESS: {
      const { provinces } = action.payload

      return {
        ...state,
        error: null,
        loading: false,
        data: {
          ...state.data,
          provinces,
        },
      }
    }
    case ADD_FACILITATOR_MODAL_TYPES.GET_PROVINCES_FAILURE: {
      const { error } = action.payload

      return {
        ...state,
        error,
        loading: false,
      }
    }
    case ADD_FACILITATOR_MODAL_TYPES.ADD_FACILITATOR_REQUEST: {
      return {
        ...state,
        error: null,
        loading: true,
      }
    }
    case ADD_FACILITATOR_MODAL_TYPES.ADD_FACILITATOR_SUCCESS: {
      return {
        ...initialState,
      }
    }
    case ADD_FACILITATOR_MODAL_TYPES.ADD_FACILITATOR_FAILURE: {
      const { error } = action.payload

      return {
        ...state,
        error,
        loading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default AddFacilitatorModalReducer
