import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { updateToken as updateTokenV1 } from '@/api'
import { updateToken as updateTokenV2 } from '@/services/helpers'
import { Private as axioscli } from '@/services/clients'

export interface UseAuthStore {
  token: string
  setToken: (token: string) => void
}

export const useAuthStore = create<UseAuthStore>()(
  persist(
    (set) => ({
      token: '',
      setToken: (token: string) => {
        updateTokenV1(token)
        updateTokenV2(axioscli, token)

        return set({ token })
      },
    }),
    {
      name: '@aula/auth',
      partialize: (state) => ({
        token: state.token,
      }),
    }
  )
)

export const { token } = useAuthStore.getState()
