import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import {
  DELETE_SUBJECT_AS_PRINCIPAL_FAILURE,
  DELETE_SUBJECT_AS_PRINCIPAL_REQUEST,
  DELETE_SUBJECT_AS_PRINCIPAL_SUCCESS,
} from '@/legacy/redux/types/subjects'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type {
  DeleteSubjectConfirmModalAction,
  DeleteSubjectConfirmModalState,
} from '@/sections/management/types/subjects/deleteSubjectConfirmModal'
import {
  CLEAR_FIELDS,
  SET_INITIAL_VALUES,
} from '@/sections/management/types/subjects/deleteSubjectConfirmModal'

const initialState: DeleteSubjectConfirmModalState = {
  open: false,
  loading: false,
  error: '',
  organizationId: 0,
  subjectId: 0,
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_DELETE_SUBJECT_CONFIRM_MODAL

const DeleteSubjectConfirmModalReducer: Reducer<
  DeleteSubjectConfirmModalState,
  DeleteSubjectConfirmModalAction
> = (state = initialState, action): DeleteSubjectConfirmModalState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case CLEAR_FIELDS:
      return {
        ...initialState,
        open: state.open,
      }
    case SET_INITIAL_VALUES: {
      return {
        ...state,
        subjectId: action.payload.subject.id,
        organizationId: action.payload.organizationId,
      }
    }
    case DELETE_SUBJECT_AS_PRINCIPAL_REQUEST:
      return { ...state, loading: true }
    case DELETE_SUBJECT_AS_PRINCIPAL_SUCCESS:
      return { ...state, loading: false, open: false }
    case DELETE_SUBJECT_AS_PRINCIPAL_FAILURE:
      return { ...state, loading: false, open: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(
  initialState,
  DeleteSubjectConfirmModalReducer,
  requestDestination
)
