import React from 'react'
import { makeStyles } from '@mui/styles'
import classNames from 'clsx'
import MUIButton from '@mui/material/Button'
import { CircularProgress } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  Button: ({ dense }) => ({
    boxSizing: 'border-box',
    borderRadius: 3,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '21px',
    textTransform: 'none',
    padding: dense ? '8.5px 16px' : '12.5px 16px',
    background: 'rgba(0,0,0,0)',
    color: theme.palette.primary.main,
    border: 'solid 2px ' + theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main,
      color: '#FFF',
      border: 'solid 2px ' + theme.palette.primary.main,
    },
    '&:active': {
      background: 'rgba(0,0,0,0)',
      boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
      color: theme.palette.primary.main,
      border: 'solid 2px ' + theme.palette.primary.main,
    },
  }),
  disabled: {
    opacity: 0.3,
    color: theme.palette.primary.main + ' !important',
  },
  loadingContainer: {
    height: 21,
    display: 'flex',
    alignItems: 'center',
  },
}))

const OutlineButton = ({
  dense = false,
  children,
  onClick,
  loading = false,
  className = '',
  ...props
}) => {
  const classes = useStyles({ dense })

  return (
    <MUIButton
      {...props}
      onClick={onClick}
      disabled={props.disabled || loading}
      disableRipple
      className={classNames(classes.Button, { [classes.disabled]: props.disabled }, className)}
    >
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress color="primary" size={18} />
        </div>
      ) : (
        children
      )}
    </MUIButton>
  )
}

export default OutlineButton
