import axios from 'axios'
import {
  onRequestError,
  onRequestSuccess,
  onResponseError,
  onResponseSuccess,
} from './interceptors'

// instancia para solicitudes externas, como por ejemplo servicios de terceros
export const ApiExternal = axios.create()
// instancia para solicitudes públicas internas
export const ApiPublic = axios.create()
// instancia para solicitudes privadas internas
export const ApiPrivate = axios.create({
  headers: { Authorization: ' ' },
})
export const ApiPrivateNotIntercepted = axios.create({
  headers: { Authorization: ' ' },
})

ApiExternal.interceptors.response.use(onResponseSuccess, onResponseError)
ApiPublic.interceptors.response.use(onResponseSuccess, onResponseError)
ApiPrivate.interceptors.response.use(onResponseSuccess, onResponseError)

ApiPublic.interceptors.request.use(onRequestSuccess, onRequestError)
ApiPrivate.interceptors.request.use(onRequestSuccess, onRequestError)

// actualiza el token de autorización
export const updateToken = (token: string) => {
  ApiPrivateNotIntercepted.defaults.headers.Authorization = 'Bearer ' + token
  ApiPrivate.defaults.headers.Authorization = 'Bearer ' + token
}
