import React from 'react'
import { makeStyles } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Button from '@mui/material/Button'
import Messages from '@/legacy/components/v1/svgs/header/messagesWhite'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    '& > header': {
      backgroundColor: '#675CFF',
      padding: '16px 24px 0 24px',
    },
    '& button': {
      opacity: 1,
      fontSize: 14,
      padding: 0,
      lineHeight: '18px',
      fontWeight: 'bold',
      fontFamily: 'DM sans',
      minWidth: 'fit-content',
      marginRight: '24px',
      textTransform: 'uppercase',
      color: 'white',
    },
  },
  customTab: {
    '& > div > span': {
      height: '4px',
      backgroundColor: 'white',
    },
    marginTop: 40,
    '& > div': {
      '@media (max-width: 576px)': {
        overflow: 'auto !important',
      },
    },
  },
  gradeTitle: {
    fontSize: 32,
    lineHeight: '42px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontFamily: 'DM sans',
  },
  titleFlex: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  btnMessage: {
    '& button': {
      fontSize: 16,
      lineHeight: '21px',
      fontFamily: 'DM sans',
      fontWeight: 'bold',
      fontStyle: 'normal',
      padding: '9px 16px',
      width: '100%',
    },
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'white',
    boxSizing: 'border-box',
    borderRadius: 3,
  },
}))

const messageStyles = {
  width: 20,
}

const SubjectBanner = (props) => {
  const classes = useStyles()
  const [value, setValue] = React.useState('0')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <AppBar position="static">
          <div className={classes.titleFlex}>
            <div className={classes.gradeTitle}>{props.title}</div>
            <div className={classes.btnMessage}>
              <Button>
                Default{' '}
                <span style={{ marginLeft: 10 }}>
                  <Messages style={messageStyles} />
                </span>
              </Button>
            </div>
          </div>
          <div>{props.subtitle}</div>
          <TabList
            className={classes.customTab}
            onChange={handleChange}
            aria-label="simple tabs example"
            textColor="inherit"
          >
            {props.value.map((elem, i) => (
              <Tab label={elem} value={`${i}`} key={i} />
            ))}
          </TabList>
        </AppBar>
        {props.children.map((elem, i) => (
          <TabPanel value={`${i}`} key={i}>
            {elem}
          </TabPanel>
        ))}
      </TabContext>
    </div>
  )
}

export default SubjectBanner
