import type { Sequence } from '@/api/lms/library'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export interface DrawerState {
  isOpen: boolean
  currentStep: DRAWER_STEPS
  isEditMode: boolean
  sequenceToEdit: {
    status: REQUEST_STATUS
    sequence: Sequence | null
  }
}

export enum DRAWER_STEPS {
  INITIAL = 0,
  FIRST = 1,
  SECOND = 2,
}

export enum DRAWER_TYPES {
  OPEN_DRAWER = 'CONTENT_CREATOR/NEW_SEQUENCES/DRAWER/OPEN_DRAWER',
  CLOSE_DRAWER = 'CONTENT_CREATOR/NEW_SEQUENCES/DRAWER/CLOSE_DRAWER',
  SET_CURRENT_STEP = 'CONTENT_CREATOR/NEW_SEQUENCES/DRAWER/SET_CURRENT_STEP',
  TOGGLE_AVAILABLE_IN_LIBRARY = 'CONTENT_CREATOR/NEW_SEQUENCES/DRAWER/TOGGLE_AVAILABLE_IN_LIBRARY',
  OPEN_DRAWER_IN_EDIT_MODE = 'CONTENT_CREATOR/NEW_SEQUENCES/DRAWER/OPEN_DRAWER_IN_EDIT_MODE',
  GET_SEQUENCE_REQUEST = 'CONTENT_CREATOR/NEW_SEQUENCES/DRAWER/GET_SEQUENCE_REQUEST',
  GET_SEQUENCE_SUCCESS = 'CONTENT_CREATOR/NEW_SEQUENCES/DRAWER/GET_SEQUENCE_SUCCESS',
  GET_SEQUENCE_FAILURE = 'CONTENT_CREATOR/NEW_SEQUENCES/DRAWER/GET_SEQUENCE_FAILURE',
  EDIT_SEQUENCE_REQUEST = 'CONTENT_CREATOR/NEW_SEQUENCES/DRAWER/EDIT_SEQUENCE_REQUEST',
  EDIT_SEQUENCE_SUCCESS = 'CONTENT_CREATOR/NEW_SEQUENCES/DRAWER/EDIT_SEQUENCE_SUCCESS',
  EDIT_SEQUENCE_FAILURE = 'CONTENT_CREATOR/NEW_SEQUENCES/DRAWER/EDIT_SEQUENCE_FAILURE',
}

// Open drawer action.
export type OpenDrawerAction = {
  type: DRAWER_TYPES.OPEN_DRAWER
}

// Close drawer action.
export type CloseDrawerAction = {
  type: DRAWER_TYPES.CLOSE_DRAWER
}

export type ToggleAvailableInLibraryAction = {
  type: DRAWER_TYPES.TOGGLE_AVAILABLE_IN_LIBRARY
}

// Set current step action.
export type SetCurrentStepAction = {
  type: DRAWER_TYPES.SET_CURRENT_STEP
  payload: {
    newStep: DRAWER_STEPS
  }
}

// Open drawer in edit mode action.
export type OpenDrawerInEditModeAction = {
  type: DRAWER_TYPES.OPEN_DRAWER_IN_EDIT_MODE
}

// Get sequence actions.
export type GetSequenceRequestAction = {
  type: DRAWER_TYPES.GET_SEQUENCE_REQUEST
}

export type GetSequenceSuccessAction = {
  type: DRAWER_TYPES.GET_SEQUENCE_SUCCESS
  payload: {
    sequence: Sequence
  }
}

export type GetSequenceFailureAction = {
  type: DRAWER_TYPES.GET_SEQUENCE_FAILURE
}

export type GetSequenceAction =
  | GetSequenceRequestAction
  | GetSequenceSuccessAction
  | GetSequenceFailureAction

// Edit sequence actions.
export type EditSequenceRequestAction = {
  type: DRAWER_TYPES.EDIT_SEQUENCE_REQUEST
}

export type EditSequenceSuccessAction = {
  type: DRAWER_TYPES.EDIT_SEQUENCE_SUCCESS
}

export type EditSequenceFailureAction = {
  type: DRAWER_TYPES.EDIT_SEQUENCE_FAILURE
}

export type EditSequenceAction =
  | EditSequenceRequestAction
  | EditSequenceSuccessAction
  | EditSequenceFailureAction

export type DrawerAction =
  | OpenDrawerAction
  | CloseDrawerAction
  | SetCurrentStepAction
  | OpenDrawerInEditModeAction
  | GetSequenceAction
  | EditSequenceAction
  | ToggleAvailableInLibraryAction
