import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/principal/documentation/types/rejectionModal'

export const initialState: Types.RejectionModalState = {
  isOpenModal: false,
  status: REQUEST_STATUS.IDLE,
  reasonForRejection: '',
  documentIdToReject: 0,
}

const rejectionModalReducer = (
  state: Types.RejectionModalState = initialState,
  action: Types.RejectionModalAction
): Types.RejectionModalState => {
  switch (action.type) {
    case Types.REJECTION_MODAL_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isOpenModal: true,
        documentIdToReject: action.payload,
      }
    }
    case Types.REJECTION_MODAL_TYPES.SET_REASON_FOR_REJECTION: {
      return {
        ...state,
        reasonForRejection: action.payload,
      }
    }
    case Types.REJECTION_MODAL_TYPES.REJECT_DOCUMENT_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.REJECTION_MODAL_TYPES.REJECT_DOCUMENT_SUCCESS: {
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
      }
    }
    case Types.REJECTION_MODAL_TYPES.REJECT_DOCUMENT_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.REJECTION_MODAL_TYPES.CLEAN_MODAL: {
      return initialState
    }
    default:
      return state
  }
}

export default rejectionModalReducer
