import { combineReducers } from 'redux'
import bulletin from './reducers/bulletin'
import contacts from './reducers/contacts'
import messageManager from './reducers/messageManager'
import messageMarkAsRead from './reducers/messageMarkAsRead'
import messageReceivers from './reducers/messageReceivers'
import messages from './reducers/messages'
import messageDelete from './reducers/messageDelete'
import messageThreadReplies from './reducers/messageThreadReplies'
import messageThreadResponse from './reducers/messageThreadResponse'
import messageThreads from './reducers/messageThreads'
import messageThreadsDrawer from './reducers/messageThreadsDrawer'
import scheduleMessageModal from './reducers/scheduleMessageModal'
import uploadFiles from './reducers/uploadFiles'
import uploadVimeo from './reducers/uploadVimeo'
import messageEvents from './reducers/messageEvents'
import messageCloning from './reducers/messageCloning'
import uploadImages from './reducers/uploadImages'

export default combineReducers({
  bulletin,
  contacts,
  uploadFiles,
  uploadVimeo,
  messages,
  messageDelete,
  messageManager,
  messageReceivers,
  messageMarkAsRead,
  messageThreads,
  messageThreadReplies,
  messageThreadResponse,
  messageThreadsDrawer,
  scheduleMessageModal,
  messageEvents,
  messageCloning,
  uploadImages,
})
