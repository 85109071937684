import type { Theme } from '@mui/material'
import { AppBar, CircularProgress, createStyles, Tab } from '@mui/material'
import { makeStyles } from '@mui/styles'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import clsx from 'clsx'
import React, { useMemo, useState } from 'react'
import { scrollBarStyles } from '@/sections/teacher/activities/styles'

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    appBar: {
      boxShadow: 'none',
    },
    tabList: {
      height: 48,
      justifyContent: 'center',
      margin: 'auto',
      flexGrow: 1,
      width: '100%',
    },
    tab: {
      fontFamily: theme.typography.fontFamily,
      textTransform: 'initial',
      fontSize: 16,
      color: 'black',
      display: 'block',
    },
    disabledTab: {
      color: 'grey',
    },
    tabIndicator: {
      height: '4px',
      backgroundColor: theme.palette.secondary.main,
    },
    tabPanel: {
      padding: 0,
      width: '100%',
      overflowY: 'auto',
      ...scrollBarStyles,
    },
    loadingIndicatorContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 100,
      minHeight: 100,
    },
    loadingIndicator: {
      color: theme.palette.secondary.main,
    },
  })
)

type TabType = {
  content: React.ReactNode
  disabled: boolean
  id: number
  label: string
  onClick?: () => void
}

interface TabContentProps {
  centered: boolean
  hideDisabledTabs?: boolean
  loading?: boolean
  onTabSelect?: (index: number) => void
  tabClass?: object
  tabPanelClass?: object
  tabs: TabType[]
  variant?: 'scrollable' | 'standard' | 'fullWidth'
}

const TabContent = (props: TabContentProps) => {
  const {
    centered = true,
    hideDisabledTabs = false,
    loading = false,
    onTabSelect = () => {},
    tabClass = {},
    tabPanelClass = {},
    tabs,
    variant = 'standard',
  } = props

  const filteredTabs = useMemo(
    () => tabs.filter((tab) => !(hideDisabledTabs && tab.disabled)),
    [tabs]
  )

  const firstEnabledTab = (tab: TabType) => !tab.disabled
  const [selectedTabId, setSelectedTabId] = useState(filteredTabs.findIndex(firstEnabledTab) || 0)
  const C = useStyles()

  const handleTabSelect = (value: number) => {
    setSelectedTabId(value)
    onTabSelect(value)
  }

  if (loading) {
    return (
      <div className={C.loadingIndicatorContainer}>
        <CircularProgress size={30} className={C.loadingIndicator} />
      </div>
    )
  } else {
    return (
      <TabContext value={selectedTabId.toString()}>
        <AppBar position="static" color="default" className={C.appBar}>
          <TabList
            className={C.tabList}
            onChange={(_, value) => handleTabSelect(value)}
            TabIndicatorProps={{ className: C.tabIndicator }}
            variant={variant}
            centered={centered}
            scrollButtons="on"
            textColor="inherit"
          >
            {filteredTabs.map((tab) => {
              return (
                <Tab
                  className={clsx(C.tab, tab.disabled ? C.disabledTab : {}, tabClass)}
                  label={tab.label}
                  key={tab.id}
                  value={tab.id}
                  disabled={tab.disabled}
                  onClick={() => tab.onClick?.()}
                />
              )
            })}
          </TabList>
        </AppBar>
        {filteredTabs.map((tab) => (
          <TabPanel
            className={clsx(C.tabPanel, tabPanelClass)}
            key={tab.id}
            value={tab.id.toString()}
          >
            {tab.content}
          </TabPanel>
        ))}
      </TabContext>
    )
  }
}

export default TabContent
