import { clone } from 'ramda'
import type { Reducer } from 'redux'
import type { AttendanceStatus } from '@/api/attendances/config'
import { Redux } from '@/legacy/redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import * as ApiInteractionsTypes from '@/sections/attendances/config/types/apiInteractions'
import * as DatesModalTypes from '@/sections/attendances/config/types/datesModal'
import * as Types from '@/sections/attendances/config/types/mainTab'
import { dayOffToADayOffCategories } from '@/sections/attendances/config/utils'

const initialState: Types.AttendancesConfigMainTabState = {
  classrooms: [],
  configuredClassroomIds: [],
  loadingClassrooms: false,
  loadingConfiguredClassroomIds: false,
  loadingExistingConfig: false,
  classroomsPagination: {
    page: 0,
    rowsPerPage: 6,
    count: 0,
  },
  selectedClassrooms: [],
  saving: false,
  error: '',
  search: '',
  step: Types.AttendanceConfigStep.CLASSROOM_SELECTION,
  days: [2, 3, 4, 5, 6],
  absenceCategories: [],
  statuses: [
    {
      description: 'Presente',
      color: '#01CEAA',
      value: 0,
      abbreviation: 'P',
    },
    {
      description: 'Ausente',
      color: '#FF6428',
      value: 1,
      abbreviation: 'A',
    },
    {
      description: 'Llegada tarde',
      color: '#FFB800',
      value: 0.25,
      abbreviation: 'T',
    },
    {
      description: 'Justificado',
      color: '#9B00AF',
      value: 1,
      abbreviation: 'J',
    },
  ],
  editMode: {
    active: false,
    configId: 0,
    classroomId: 0,
  },
  generalAttendances: true,
  attendancesBySubject: false,
  subjects: [],
  validSubjects: true,
  subjectsXDays: [],
  alreadyConfiguredSubjects: [],
  alreadyConfiguredDays: [],
}

const NEW_STATUS: AttendanceStatus = {
  description: '',
  color: '#FFB800',
  value: 1,
  abbreviation: '',
}

const requestDestination = reduxRequestOriginMap.ATTENDANCES_CONFIG_MAIN_TAB

const AttendancesConfigMainTab: Reducer<
  Types.AttendancesConfigMainTabState,
  Types.AttendancesConfigMainTabAction
> = (state = initialState, action): Types.AttendancesConfigMainTabState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case Types.SET_DAYS: {
      const { days } = action.payload
      return {
        ...state,
        days: days,
        subjectsXDays: state.subjectsXDays.filter((SxD) => days.includes(SxD.day)),
      }
    }
    case Types.SET_STEP: {
      const { editingClassroom, step } = action.payload
      if (editingClassroom) {
        return {
          ...state,
          step,
          selectedClassrooms: [editingClassroom],
          editMode: {
            active: true,
            configId: 0,
            classroomId: editingClassroom.id,
          },
        }
      }

      return {
        ...state,
        subjects: initialState.subjects,
        subjectsXDays: initialState.subjectsXDays,
        days: initialState.days,
        generalAttendances: initialState.generalAttendances,
        attendancesBySubject: initialState.attendancesBySubject,
        alreadyConfiguredSubjects: initialState.alreadyConfiguredSubjects,
        alreadyConfiguredDays: initialState.alreadyConfiguredDays,
        editMode: initialState.editMode,
        statuses: initialState.statuses,
        absenceCategories: initialState.absenceCategories,
        step,
        selectedClassrooms:
          step === Types.AttendanceConfigStep.CLASSROOM_SELECTION && state.editMode.active
            ? initialState.selectedClassrooms
            : state.selectedClassrooms,
      }
    }
    case ApiInteractionsTypes.GET_CLASSROOM_ATTENDANCES_CONFIG_REQUEST: {
      return {
        ...state,
        loadingExistingConfig: true,
        error: initialState.error,
      }
    }
    case ApiInteractionsTypes.GET_CLASSROOM_ATTENDANCES_CONFIG_SUCCESS: {
      const {
        classroomId,
        id,
        statuses,
        days,
        absences,
        generalAttendances,
        attendancesBySubject,
      } = action.payload.attendanceConfig
      if (classroomId !== state.editMode.classroomId) {
        return {
          ...state,
          step: Types.AttendanceConfigStep.CLASSROOM_SELECTION,
          error: 'attendances.config.errors.couldntBeFetched',
          loadingExistingConfig: false,
          editMode: initialState.editMode,
          selectedClassrooms: initialState.selectedClassrooms,
        }
      }

      const simpleDays = [...new Set(days.map((d) => d.day))]
      const simpleSubjects = [...new Set(days.map((d) => d.subjectId))]

      return {
        ...state,
        editMode: {
          ...state.editMode,
          configId: id,
        },
        statuses,
        absenceCategories: dayOffToADayOffCategories(absences),
        days: simpleDays,
        subjectsXDays: days,
        loadingExistingConfig: false,
        generalAttendances,
        attendancesBySubject,
        alreadyConfiguredSubjects: simpleSubjects,
        alreadyConfiguredDays: simpleDays,
      }
    }
    case ApiInteractionsTypes.GET_CLASSROOM_ATTENDANCES_CONFIG_FAILURE:
      return {
        ...state,
        step: Types.AttendanceConfigStep.CLASSROOM_SELECTION,
        error: action.payload.error,
        editMode: initialState.editMode,
        loadingExistingConfig: false,
        selectedClassrooms: initialState.selectedClassrooms,
      }
    case ApiInteractionsTypes.GET_ORGANIZATION_ATTENDANCES_CONFIGS_REQUEST:
      return {
        ...state,
        loadingConfiguredClassroomIds: true,
        error: initialState.error,
        search: action.payload.search || initialState.search,
      }
    case ApiInteractionsTypes.GET_ORGANIZATION_ATTENDANCES_CONFIGS_SUCCESS: {
      const configuredClassroomIds = action.payload.attendanceConfigs.map(
        ({ classroomId }) => classroomId
      )
      return { ...state, loadingConfiguredClassroomIds: false, configuredClassroomIds }
    }
    case ApiInteractionsTypes.GET_ORGANIZATION_ATTENDANCES_CONFIGS_FAILURE:
      return { ...state, loadingConfiguredClassroomIds: false, error: action.payload.error }
    case Types.TOGGLE_CLASSROOM: {
      const { classroom } = action.payload

      const filtered = state.selectedClassrooms.filter((sc) => sc.id !== classroom.id)

      if (filtered.length === state.selectedClassrooms.length) filtered.push(classroom)

      return { ...state, selectedClassrooms: filtered }
    }
    case Types.REMOVE_ABSENCE_CATEGORY:
      return {
        ...state,
        absenceCategories: state.absenceCategories.filter(
          (abs) => abs.reason !== action.payload.absenceReason
        ),
      }
    case Types.ADD_STATUS:
      return { ...state, statuses: [...state.statuses, NEW_STATUS] }
    case Types.EDIT_STATUS: {
      const newStatuses = state.statuses.map((status, index) =>
        index === action.payload.index ? action.payload.status : status
      )

      return {
        ...state,
        statuses: newStatuses,
      }
    }
    case Types.REMOVE_STATUS: {
      const newStatuses = state.statuses.filter((_, index) => index !== action.payload.index)

      return {
        ...state,
        statuses: newStatuses,
      }
    }
    case DatesModalTypes.CONFIRM_DATES: {
      const { dates, newReasonName, previousReasonName } = action.payload

      const newAbsences = clone(state.absenceCategories).map((absence) =>
        previousReasonName === absence.reason
          ? { ...absence, reason: newReasonName, dates }
          : absence
      )

      if (!previousReasonName) newAbsences.push({ reason: newReasonName, dates })

      return {
        ...state,
        absenceCategories: newAbsences,
      }
    }
    case Types.SET_GENERAL_ATTENDANCES: {
      const { setGeneralAttendances } = action.payload
      return {
        ...state,
        generalAttendances: setGeneralAttendances,
      }
    }
    case Types.SET_ATTENDANCES_BY_SUBJECT: {
      const { setAttendancesBySubject } = action.payload
      return {
        ...state,
        attendancesBySubject: setAttendancesBySubject,
        subjectsXDays: state.subjectsXDays.filter((SxD) =>
          state.alreadyConfiguredSubjects.includes(SxD.subjectId)
        ),
      }
    }
    case Types.SET_SUBJECT_X_DAY: {
      const { subject, day, selected } = action.payload
      const newSxD = selected
        ? [{ subjectId: subject, day: day }, ...state.subjectsXDays]
        : state.subjectsXDays.filter((SxD) => SxD.subjectId !== subject || SxD.day !== day)
      return {
        ...state,
        subjectsXDays: newSxD,
      }
    }
    case ApiInteractionsTypes.GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_SUCCESS: {
      const { subjects, valid } = action.payload
      return {
        ...state,
        subjects: subjects,
        validSubjects: valid,
      }
    }
    case ApiInteractionsTypes.GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_REQUEST:
      return { ...state }
    case ApiInteractionsTypes.GET_AND_VALIDATE_CLASSROOMS_SUBJECTS_FAILURE:
      return { ...state }
    case ApiInteractionsTypes.SAVE_ATTENDANCE_CONFIG_REQUEST:
    case ApiInteractionsTypes.UPDATE_ATTENDANCE_CONFIG_REQUEST:
      return { ...state, saving: true, error: initialState.error }
    case ApiInteractionsTypes.SAVE_ATTENDANCE_CONFIG_SUCCESS:
    case ApiInteractionsTypes.UPDATE_ATTENDANCE_CONFIG_SUCCESS:
      return {
        ...state,
        configuredClassroomIds: [...state.configuredClassroomIds, ...action.payload.classroomIds],
        selectedClassrooms: [],
        saving: false,
        step: Types.AttendanceConfigStep.CLASSROOM_SELECTION,
        days: initialState.days,
        absenceCategories: initialState.absenceCategories,
        statuses: initialState.statuses,
        editMode: initialState.editMode,
      }
    case ApiInteractionsTypes.SAVE_ATTENDANCE_CONFIG_FAILURE:
    case ApiInteractionsTypes.UPDATE_ATTENDANCE_CONFIG_FAILURE:
      return { ...state, saving: false, error: action.payload.error }
    case Redux.Types.Classrooms.GET_CLASSROOMS_AS_PRINCIPAL_REQUEST:
      return {
        ...state,
        loadingClassrooms: true,
        error: initialState.error,
        classroomsPagination: {
          ...state.classroomsPagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    case Redux.Types.Classrooms.GET_CLASSROOMS_AS_PRINCIPAL_SUCCESS:
      return {
        ...state,
        loadingClassrooms: false,
        classrooms: action.payload.classrooms,
        classroomsPagination: {
          ...state.classroomsPagination,
          count: action.payload.count,
        },
      }
    case Redux.Types.Classrooms.GET_CLASSROOMS_AS_PRINCIPAL_FAILURE:
      return { ...state, loadingClassrooms: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, AttendancesConfigMainTab, requestDestination)
