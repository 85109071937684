import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type {
  CodeClaimModalActions,
  CodeClaimModalState,
} from '@/sections/backpack/types/codeClaimModal'
import {
  CLAIM_CODE_FAILURE,
  CLAIM_CODE_REQUEST,
  CLAIM_CODE_SUCCESS,
  SET_CODE,
  SET_CODE_CLAIM_MODAL_OPEN,
} from '@/sections/backpack/types/codeClaimModal'

const initialState: CodeClaimModalState = {
  open: false,
  code: '',
  loading: false,
  error: '',
  status: REQUEST_STATUS.IDLE,
}

const requestDestination = reduxRequestOriginMap.GENERIC_CODE_CLAIM_MODAL

function root(state = initialState, action: CodeClaimModalActions): CodeClaimModalState {
  if (action.payload?.requestOrigin !== requestDestination) return state

  switch (action.type) {
    case SET_CODE_CLAIM_MODAL_OPEN:
      if (!action.payload.open) return initialState
      return { ...state, open: action.payload.open, status: REQUEST_STATUS.IDLE }
    case SET_CODE: {
      return { ...state, code: action.payload.code }
    }
    case CLAIM_CODE_REQUEST: {
      return { ...state, loading: true, error: '', status: REQUEST_STATUS.LOADING }
    }
    case CLAIM_CODE_SUCCESS: {
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
      }
    }
    case CLAIM_CODE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    default:
      return state
  }
}

export default root
