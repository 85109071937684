import React from 'react'
import { Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Badge from './badgeV2'
import { generateRandomColor } from '@aula/tools/color'
import chroma from 'chroma-js'

const useStyles = makeStyles((theme) => ({
  tile: ({ color }) => ({
    width: '300px',
    position: 'relative',
    height: '120px',
    border: '1px solid #DFDFDA',
    boxSizing: 'border-box',
    borderRadius: 8,
    padding: '8px 13px',
    transition: 'all 100ms',
    '&:hover': {
      border: `1px solid ${color} !important`,
      background: chroma(color).alpha(0.15).hex(),
      '& p': {
        borderColor: '#80808080 !important',
      },
    },
    cursor: 'pointer',
  }),
  coloredBar: ({ color }) => ({
    width: '4px',
    height: '104px',
    borderRadius: '0 3px 3px 0',
    backgroundColor: color,
    position: 'absolute',
    left: 0,
    top: 8,
  }),
  classroomName: ({ color }) => ({
    color: color,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '16px',
    lineHeight: '21px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
  schoolName: {
    fontSize: '16px',
    lineHeight: '21px',
    maxWidth: '90%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  stageName: {
    fontSize: '12px',
    lineHeight: '24px',
    maxWidth: '90%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  badgeContainer: {
    position: 'absolute',
    bottom: '8px',
    display: 'flex',
    '& :not(:last-child)': {
      marginRight: 4,
    },
  },
  badge: {
    transition: 'all 100ms',
    background: 'transparent',
  },
}))

const ClassroomTile = ({ stageName, classroomName, schoolName, activityCount, onClick }) => {
  const color = generateRandomColor(classroomName)
  const classes = useStyles({ color: color || '#D8DBE8' })

  return (
    <Paper elevation={0} className={classes.tile} onClick={onClick}>
      <div className={classes.coloredBar} />
      <Typography className={classes.classroomName}>{classroomName}</Typography>
      <Typography className={classes.schoolName}>
        {schoolName.length > 60 ? `${schoolName.slice(0, 60)}...` : schoolName}
      </Typography>
      {stageName && <Typography className={classes.stageName}>{stageName}</Typography>}
      <div className={classes.badgeContainer}>
        <Badge
          emoji=":calendar:"
          description={`${activityCount || 0} hoy`}
          className={classes.badge}
        />
      </div>
    </Paper>
  )
}

export default ClassroomTile
