import React from 'react'
import { useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { getRibbonContent } from '@/sections/student/dashboard/utils'
import moment from 'moment'
import AVVRibbon from '@/legacy/components/v2/ribbons/student/avvRibbon'
import ReadingRibbon from '@/legacy/components/v2/ribbons/student/readingRibbon'
import QuestionsRibbon from '@/legacy/components/v2/ribbons/student/questionsRibbon'
import {
  activityRibbonMap,
  ACTIVITY_KEY_COLLAB_MINIATURE,
  Types,
} from '@/legacy/components/v2/ribbons/types'
import { states } from '@/legacy/components/v2/ribbons/states'
import useFeature from '@/hooks/useFeature'
import { FEATURE_FLAG } from '@aula/config'
import { pathOr } from 'ramda'
import RobotsRibbon from '@/legacy/components/v2/ribbons/student/robotsRibbon'
import AnimationsRibbon from '@/legacy/components/v2/ribbons/student/animationsRibbon'
import GamesRibbon from '@/legacy/components/v2/ribbons/student/gamesRibbon'
import WrittenExamRibbon from '@/legacy/components/v2/ribbons/student/writtenExamRibbon'
import { getGrade } from '@/legacy/utils/grades'
import OralExamRibbon from '@/legacy/components/v2/ribbons/student/oralExamRibbon'
import StudentActivityRow from '@/legacy/components/v3/studentActivityRow'
import { ACTIVITY_DISPLAY_TYPE_RIBBON } from '@/sections/display/types'
import { useTranslation } from 'react-i18next'
import CollabMiniatureRibbon from '@/legacy/components/v2/ribbons/student/collabMiniatureRibbon'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 14,
  },
}))

const Ribbon = React.memo((props) => {
  const {
    activity,
    channelsByKey,
    classroomID,
    buttonActions,
    navigate,
    goToChat,
    activityDisplayType,
  } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const content = getRibbonContent(activity, buttonActions, classroomID, t)
  const {
    delayed,
    completed,
    buttonClick,
    startTime,
    endTime,
    deadline,
    calendarLabel,
    isNew,
    buttonLabel,
  } = content

  const { i18n } = useTranslation()
  const subjectClick = (subject) => navigate('/alumno/asignaturas/' + subject.id)
  const messageClick = (activityID) => goToChat(activityID, Types.Activity)

  const chatOn = useFeature(FEATURE_FLAG.CHAT_FEATURE)

  const key = Object.keys(channelsByKey).find(
    (key) => channelsByKey[key].activityID === activity.id
  )

  const commonProps = {
    id: activity.id,
    state: getState(completed, isNew, delayed),
    subject: activity.subject,
    title: activity.title,
    buttonClick,
    subjectClick,
    messageClick,
    messages: channelsByKey[key]?.unread || 0,
    progress: activity.lastSubmission?.progress || 0,
    showMessages: chatOn && activity.chatEnabled,
    Icon: activityRibbonMap[activity.type]?.icon || null,
  }

  const isSmall = useMediaQuery('(max-width:880px)')
  const intlButtonLabel = t(buttonLabel)

  function dayFormat() {
    switch (i18n.language) {
      case 'en':
        return isSmall ? 'MM[/]D' : 'Do [of] MMMM'
      default:
        return isSmall ? 'D[/]MM' : `LL`
    }
  }

  let ribbon, row

  switch (activity.type) {
    case 'reading': {
      const date = deadline.format(dayFormat())

      ribbon = (
        <ReadingRibbon
          {...commonProps}
          date={date}
          buttonLabel={intlButtonLabel}
          unparsedDate={deadline}
          activity={activity}
        />
      )
      row = (
        <StudentActivityRow
          {...commonProps}
          date={[date]}
          buttonLabel={intlButtonLabel}
          buttonEnabled
          unparsedDate={deadline}
          activity={activity}
        />
      )
      break
    }
    case 'avv': {
      const date = [startTime.format(dayFormat()), startTime.format('HH:mm')]
      const hasRecording =
        activity.avv?.recordings?.length > 0 && activity.avv?.recordings[0]?.videoId // eslint-disable-line
      const enabled = !isAVVDisabled(activity) || hasRecording

      ribbon = (
        <AVVRibbon
          {...commonProps}
          calendarLabel={calendarLabel}
          date={date}
          buttonEnabled={enabled}
          buttonLabel={intlButtonLabel}
          unparsedDate={startTime}
          activity={activity}
        />
      )
      row = (
        <StudentActivityRow
          {...commonProps}
          calendarLabel={calendarLabel}
          date={date}
          buttonEnabled={enabled}
          buttonLabel={intlButtonLabel}
          hideProgress
          unparsedDate={startTime}
          activity={activity}
        />
      )
      break
    }
    case 'questions': {
      const date = deadline.format(dayFormat())
      let grade
      const value = pathOr(null, ['lastSubmission', 'evaluation', 'value'], activity)
      const feedback = pathOr(null, ['lastSubmission', 'evaluation', 'feedback'], activity)
      const isCompleted = pathOr(false, ['lastSubmission', 'final'], activity)

      if (value !== null) grade = getGrade(value, activity.evaluationType)
      ribbon = (
        <QuestionsRibbon
          {...commonProps}
          date={date}
          grade={grade}
          feedback={feedback}
          completed={isCompleted}
          unparsedDate={deadline}
          activity={activity}
        />
      )
      row = (
        <StudentActivityRow
          {...commonProps}
          date={[date]}
          grade={grade}
          feedback={feedback}
          completed={isCompleted}
          buttonLabel={intlButtonLabel}
          buttonEnabled
          unparsedDate={deadline}
          activity={activity}
        />
      )
      break
    }
    case 'robots-activity': {
      const date = activity.robotsIsRemote
        ? [startTime.format(dayFormat()), startTime.format('HH:mm')]
        : deadline.format(dayFormat())
      const disabled = activity.robotsIsRemote && isAVVDisabled(activity)
      let grade
      const value = pathOr(null, ['lastSubmission', 'evaluation', 'value'], activity)
      const feedback = pathOr(null, ['lastSubmission', 'evaluation', 'feedback'], activity)
      const isCompleted = pathOr(false, ['lastSubmission', 'final'], activity)
      if (value !== null) grade = getGrade(value, activity.evaluationType)

      ribbon = (
        <RobotsRibbon
          {...commonProps}
          calendarLabel={calendarLabel}
          date={date}
          buttonEnabled={!disabled}
          buttonLabel={intlButtonLabel}
          unparsedDate={startTime}
          grade={grade}
          feedback={feedback}
          completed={isCompleted}
          hideProgress={false}
          activity={activity}
        />
      )
      row = (
        <StudentActivityRow
          {...commonProps}
          calendarLabel={calendarLabel}
          date={Array.isArray(date) ? date : [date]}
          buttonEnabled={!disabled}
          buttonLabel={intlButtonLabel}
          unparsedDate={startTime}
          grade={grade}
          feedback={feedback}
          completed={isCompleted}
          hideProgress={activity.robotsIsRemote}
          activity={activity}
        />
      )
      break
    }
    case 'animations': {
      const date = activity.robotsIsRemote
        ? [startTime.format(dayFormat()), startTime.format('HH:mm')]
        : deadline.format(dayFormat())
      const disabled = activity.robotsIsRemote && isAVVDisabled(activity)
      let grade
      const value = pathOr(null, ['lastSubmission', 'evaluation', 'value'], activity)
      const feedback = pathOr(null, ['lastSubmission', 'evaluation', 'feedback'], activity)
      const isCompleted = pathOr(false, ['lastSubmission', 'final'], activity)
      if (value !== null) grade = getGrade(value, activity.evaluationType)

      ribbon = (
        <AnimationsRibbon
          {...commonProps}
          calendarLabel={calendarLabel}
          date={date}
          buttonEnabled={!disabled}
          buttonLabel={intlButtonLabel}
          unparsedDate={startTime}
          grade={grade}
          feedback={feedback}
          completed={isCompleted}
          hideProgress={activity.robotsIsRemote}
          activity={activity}
        />
      )
      row = (
        <StudentActivityRow
          {...commonProps}
          calendarLabel={calendarLabel}
          date={Array.isArray(date) ? date : [date]}
          buttonEnabled={!disabled}
          buttonLabel={intlButtonLabel}
          unparsedDate={startTime}
          grade={grade}
          feedback={feedback}
          completed={isCompleted}
          hideProgress={activity.robotsIsRemote}
          activity={activity}
        />
      )
      break
    }
    case 'games': {
      const date = deadline.format(dayFormat())
      const disabled = false
      let grade
      const value = pathOr(null, ['lastSubmission', 'evaluation', 'value'], activity)
      const feedback = pathOr(null, ['lastSubmission', 'evaluation', 'feedback'], activity)
      const isCompleted = pathOr(false, ['lastSubmission', 'final'], activity)
      if (value !== null) grade = getGrade(value, activity.evaluationType)

      ribbon = (
        <GamesRibbon
          {...commonProps}
          date={date}
          buttonEnabled={!disabled}
          buttonLabel={intlButtonLabel}
          unparsedDate={startTime}
          grade={grade}
          feedback={feedback}
          completed={isCompleted}
          hideProgress={false}
          activity={activity}
        />
      )
      row = (
        <StudentActivityRow
          {...commonProps}
          date={Array.isArray(date) ? date : [date]}
          buttonEnabled={!disabled}
          buttonLabel={intlButtonLabel}
          unparsedDate={startTime}
          grade={grade}
          feedback={feedback}
          completed={isCompleted}
          hideProgress={false}
          activity={activity}
        />
      )
      break
    }
    case 'exes': {
      const startDay = startTime.format(dayFormat())
      const startHour = startTime.format('HH:mm')
      const date = [startDay, startHour]
      let grade
      const value = pathOr(null, ['lastSubmission', 'evaluation', 'value'], activity)
      const feedback = pathOr(null, ['lastSubmission', 'evaluation', 'feedback'], activity)

      const isBeforeExam = moment().add(5, 'minutes').isBefore(startTime)
      const disabled = isBeforeExam || moment().isAfter(endTime) || completed
      const buttonDisabledReason =
        isBeforeExam && moment().isSame(startTime, 'days')
          ? `${t('student.activity.startExam', { startHour })} ${moment().format('HH.mm')}`
          : ''

      if (value !== null) grade = getGrade(value, activity.evaluationType)
      ribbon = (
        <WrittenExamRibbon
          {...commonProps}
          buttonEnabled={!disabled}
          buttonDisabledReason={buttonDisabledReason}
          date={date}
          grade={activity.showEvaluation ? grade : undefined}
          feedback={activity.showEvaluation ? feedback : undefined}
          completed={completed}
          showMessages={false}
          unparsedDate={startTime}
          activity={activity}
        />
      )
      row = (
        <StudentActivityRow
          {...commonProps}
          buttonEnabled={!disabled}
          date={date}
          grade={activity.showEvaluation ? grade : undefined}
          feedback={activity.showEvaluation ? feedback : undefined}
          completed={completed}
          showMessages={false}
          buttonLabel={t('generic.play')}
          unparsedDate={startTime}
          activity={activity}
        />
      )
      break
    }
    case 'exor': {
      const date = [startTime.format(dayFormat()), startTime.format('HH:mm')]
      let grade
      const value = pathOr(null, ['lastSubmission', 'evaluation', 'value'], activity)
      const feedback = pathOr(null, ['lastSubmission', 'evaluation', 'feedback'], activity)
      if (value !== null) grade = getGrade(value, activity.evaluationType)
      const isCompleted = !!value
      commonProps.state = getState(isCompleted, isNew, delayed)

      const extraTime = moment(startTime).add(1, 'days').set({ hour: 3, minute: 0, second: 0 })
      const isOutOfDate = !moment().isBetween(moment(startTime), extraTime)
      const disabled =
        moment().add(5, 'minutes').isBefore(startTime) || Boolean(value) || isOutOfDate

      ribbon = (
        <OralExamRibbon
          {...commonProps}
          buttonEnabled={!disabled}
          date={date}
          grade={activity.showEvaluation ? grade : undefined}
          feedback={activity.showEvaluation ? feedback : undefined}
          completed={isCompleted}
          showMessages={false}
          unparsedDate={startTime}
          activity={activity}
        />
      )
      row = (
        <StudentActivityRow
          {...commonProps}
          buttonEnabled={!disabled}
          date={date}
          grade={activity.showEvaluation ? grade : undefined}
          feedback={activity.showEvaluation ? feedback : undefined}
          completed={isCompleted}
          showMessages={false}
          buttonLabel={t('generic.play')}
          unparsedDate={startTime}
          activity={activity}
        />
      )
      break
    }
    case 'quiz': {
      const date = deadline.format(dayFormat())
      let grade
      const value = pathOr(null, ['lastSubmission', 'evaluation', 'value'], activity)
      const feedback = pathOr(null, ['lastSubmission', 'evaluation', 'feedback'], activity)
      const isCompleted = pathOr(false, ['lastSubmission', 'final'], activity)

      if (value !== null) grade = getGrade(value, activity.evaluationType)
      ribbon = (
        <QuestionsRibbon
          {...commonProps}
          date={date}
          grade={grade}
          feedback={feedback}
          completed={isCompleted}
          unparsedDate={deadline}
          activity={activity}
        />
      )
      row = (
        <StudentActivityRow
          {...commonProps}
          date={[date]}
          grade={grade}
          feedback={feedback}
          completed={isCompleted}
          buttonLabel={intlButtonLabel}
          buttonEnabled
          unparsedDate={deadline}
          activity={activity}
        />
      )
      break
    }
    case ACTIVITY_KEY_COLLAB_MINIATURE: {
      const date = [startTime.format(dayFormat()), startTime.format('HH:mm')]
      const isntTimeYet =
        activity.startDate && moment(activity.startDate).subtract(5, 'minute').isAfter(moment())
      const hasEnded = activity.endDate && moment(activity.endDate).isBefore(moment())

      const enabled = !isntTimeYet && !hasEnded

      ribbon = (
        <CollabMiniatureRibbon
          {...commonProps}
          calendarLabel={calendarLabel}
          date={date}
          buttonEnabled={enabled}
          buttonLabel={intlButtonLabel}
          unparsedDate={startTime}
          activity={activity}
        />
      )
      row = (
        <StudentActivityRow
          {...commonProps}
          calendarLabel={calendarLabel}
          date={date}
          buttonEnabled={enabled}
          buttonLabel={intlButtonLabel}
          hideProgress
          unparsedDate={startTime}
          activity={activity}
        />
      )
      break
    }
    default: {
      ribbon = null
      row = null
    }
  }

  return activityDisplayType === ACTIVITY_DISPLAY_TYPE_RIBBON ? (
    <div className={classes.container}>{ribbon}</div>
  ) : (
    row
  )
})

export default Ribbon

function getState(completed, isNew, delayed) {
  if (completed) return states.COMPLETED
  if (isNew) return states.NEW
  if (delayed) return states.DELAYED
  return states.DEFAULT
}

function isAVVDisabled(activity) {
  const startDate = moment(activity.startDate)
  const timeDiff = startDate.diff(moment(), 'minutes')
  return timeDiff > 10 || timeDiff < -180
}
