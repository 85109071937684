import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { Pagination } from '@/api/lms/library'
import type {
  ACTIVITY_KEY_ANIMATIONS_ACTIVITY,
  ACTIVITY_KEY_AVV,
  ACTIVITY_KEY_ORAL_EXAM,
  ACTIVITY_KEY_QUESTIONS,
  ACTIVITY_KEY_QUIZ,
  ACTIVITY_KEY_READING,
  ACTIVITY_KEY_ROBOTS_ACTIVITY,
  ACTIVITY_KEY_WRITTEN_EXAM,
} from '@/legacy/components/v2/ribbons/types'
import type { ROLE } from '@aula/config'
import type { OrganizationOption } from '@/sections/principal/dashboard/types/common'
import type { Organization } from '@/sections/auth/codeLogin/types/codeLogin'
import type { Moment } from 'moment'

export const GET_COURSES_REQUEST = 'DASHBOARD/COURSES/GET_COURSES_REQUEST'
export const GET_COURSES_SUCCESS = 'DASHBOARD/COURSES/GET_COURSES_SUCCESS'
export const GET_COURSES_FAILURE = 'DASHBOARD/COURSES/GET_COURSES_FAILURE'

export const SET_DELETE_MODAL_OPEN = 'DASHBOARD/COURSES/SET_DELETE_MODAL_OPEN'
export const DELETE_COURSE_ASSIGNMENT_REQUEST = 'DASHBOARD/COURSES/DELETE_COURSE_ASSIGNMENT_REQUEST'
export const DELETE_COURSE_ASSIGNMENT_SUCCESS = 'DASHBOARD/COURSES/DELETE_COURSE_ASSIGNMENT_SUCCESS'
export const DELETE_COURSE_ASSIGNMENT_FAILURE = 'DASHBOARD/COURSES/DELETE_COURSE_ASSIGNMENT_FAILURE'

export const SET_COURSE_STUDENTS_DRAWER_OPEN = 'DASHBOARD/COURSES/SET_COURSE_STUDENTS_DRAWER_OPEN'

export const GET_COURSE_PROGRESS_REQUEST = 'DASHBOARD/COURSES/GET_COURSE_PROGRESS_REQUEST'
export const GET_COURSE_PROGRESS_SUCCESS = 'DASHBOARD/COURSES/GET_COURSE_PROGRESS_SUCCESS'
export const GET_COURSE_PROGRESS_FAILURE = 'DASHBOARD/COURSES/GET_COURSE_PROGRESS_FAILURE'

export const SET_ISSUE_CERTIFICATE_REQUEST = 'DASHBOARD/COURSES/SET_ISSUE_CERTIFICATE_REQUEST'
export const SET_ISSUE_CERTIFICATE_SUCCESS = 'DASHBOARD/COURSES/SET_ISSUE_CERTIFICATE_SUCCESS'
export const SET_ISSUE_CERTIFICATE_FAILURE = 'DASHBOARD/COURSES/SET_ISSUE_CERTIFICATE_FAILURE'

export const GET_CERTIFICATE_REQUEST = 'DASHBOARD/COURSES/GET_CERTIFICATE_REQUEST'
export const GET_CERTIFICATE_SUCCESS = 'DASHBOARD/COURSES/GET_CERTIFICATE_SUCCESS'
export const GET_CERTIFICATE_FAILURE = 'DASHBOARD/COURSES/GET_CERTIFICATE_FAILURE'

export const COURSE_TYPES = {
  AUTONOMOUS: 'AUTONOMOUS',
  GUIDED: 'GUIDED',
}

export type CourseType = typeof COURSE_TYPES.AUTONOMOUS | typeof COURSE_TYPES.GUIDED

export const CURRENCY_TYPES = {
  ARS: 1,
  USD: 2,
}

export type Currency = typeof CURRENCY_TYPES.ARS | typeof CURRENCY_TYPES.USD

export const COURSE_ENTITY_TYPES = {
  ACTIVITY: 'activity',
  UNIT: 'unit',
}
export type CourseEntityType = typeof COURSE_ENTITY_TYPES.ACTIVITY | typeof COURSE_ENTITY_TYPES.UNIT

export interface CourseEntity {
  title: string
  order: number
}

export interface CourseActivity extends CourseEntity {
  activityId: number
  entityType?: typeof COURSE_ENTITY_TYPES.ACTIVITY
}

export interface CourseUnit extends CourseEntity {
  unitId: number
  entityType?: typeof COURSE_ENTITY_TYPES.UNIT
}

export type ActivityTypeCount = {
  [ACTIVITY_KEY_AVV]?: number
  [ACTIVITY_KEY_QUESTIONS]?: number
  [ACTIVITY_KEY_ORAL_EXAM]?: number
  [ACTIVITY_KEY_QUIZ]?: number
  [ACTIVITY_KEY_READING]?: number
  [ACTIVITY_KEY_WRITTEN_EXAM]?: number
  [ACTIVITY_KEY_ANIMATIONS_ACTIVITY]?: number
  [ACTIVITY_KEY_ROBOTS_ACTIVITY]?: number
}

export type OrganizationCourse = {
  bio: string
  logo: string
  logoSquare: string
  name: string
}

export type CourseProgress = {
  id: number
  name: string
  lastName: string
  progress: number
}

type StudentProgress = {
  certificateId?: string
  completedCount: number
  lastActivity: number
}

export type Classroom = {
  id: number
  stage: string
  grade: string
  division: string
  shift: string
  year: number
  organizationId: number
}

export type Activity = {
  id: number
  title: string
  type: string
}

interface ContentGeneric {
  id: number
  text: string
  resourceId: number
  order: number
}

export interface ContentActivity extends ContentGeneric {
  type: 'activity'

  // activity can be undefined if it's deleted
  activity?: Activity
}

export interface ContentUnit extends ContentGeneric {
  type: 'unit'
}

export type Content = ContentUnit | ContentActivity

export interface CourseCertificateConfiguration {
  includeDuration: boolean
  includePlace: boolean
  includeDate: boolean
  optionalText: string
  organization: OrganizationOption | null
  includeSignature: boolean
  error: string
  coverLogo: string | null
  loadingLogoImage: boolean
  nameText: string
  positionText: string
  coverImage: string | null
  loadingPhoto: boolean
}
interface CourseGeneric {
  role: ROLE
  activityCount: number
  activityTypeCount: ActivityTypeCount
  addressedTo: string
  classroom: Classroom
  coverPhoto: string
  currency: Currency
  duration: number
  hasCertificate: boolean
  id: number
  longDescription: string
  name: string
  organization: OrganizationCourse
  requirements: string
  price: number
  shortDescription: string
  tags: string[]
  type: CourseType
  unitCount: number
  content: Content[]
  startDate: Moment | null
  endDate: Moment | null
  certificateConfiguration: CourseCertificateConfiguration | null
  extraButton: React.ReactElement
}

export interface StudentCourse extends CourseGeneric {
  role: ROLE.STUDENT
  studentProgress: StudentProgress
}

export interface TeacherCourse extends CourseGeneric {
  role: ROLE.TEACHER
  classroom: Classroom
}

export type Course = StudentCourse | TeacherCourse

export interface GetCoursesRequest {
  type: typeof GET_COURSES_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
    courseId: number
  }
}

export interface GetCoursesSuccess {
  type: typeof GET_COURSES_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    courses: Course[]
    total: number
    pagination: Pagination
  }
}

export interface GetCoursesFailure {
  type: typeof GET_COURSES_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface GetCourseProgressRequest {
  type: typeof GET_COURSE_PROGRESS_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetCourseProgressSuccess {
  type: typeof GET_COURSE_PROGRESS_SUCCESS
  payload: {
    progress: CourseProgress[]
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetCourseProgressFailure {
  type: typeof GET_COURSE_PROGRESS_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface SetCourseStudentsDrawerOpen {
  type: typeof SET_COURSE_STUDENTS_DRAWER_OPEN
  payload: {
    open: boolean
    courseID: number
    title: string
    classroom: string
    organization: string
    hasCertificate: boolean
    requestOrigin: ReduxRequestOrigin
  }
}

export interface SetDeleteModalOpen {
  type: typeof SET_DELETE_MODAL_OPEN
  payload: {
    requestOrigin: ReduxRequestOrigin
    open: boolean
    courseID: number
  }
}

export interface DeleteCourseAssignmentRequest {
  type: typeof DELETE_COURSE_ASSIGNMENT_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
    courseId: number
  }
}

export interface DeleteCourseAssignmentSuccess {
  type: typeof DELETE_COURSE_ASSIGNMENT_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    courseId: number
  }
}

export interface DeleteCourseAssignmentFailure {
  type: typeof DELETE_COURSE_ASSIGNMENT_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export type DashboardCoursesState = {
  loadingCourses: boolean
  loadingIssueCertificate: boolean
  courses: Course[]
  selectedCertificate: Certificate
  totalCourses: number
  pagination: Pagination
  error: ''

  selectedCourseID: number
  hasCertificate: boolean
  deleteModalOpen: boolean
  loadingDelete: boolean
  studentsDrawerOpen: boolean
  studentsProgress: CourseProgress[]
  studentsProgressLoading: boolean
  studentsDrawerTitle: string
  studentsDrawerClassroom: string
  studentsDrawerOrganization: string
}

export interface IssueCertificateSuccess {
  type: typeof SET_ISSUE_CERTIFICATE_SUCCESS
  payload: {
    message: string
    requestOrigin: ReduxRequestOrigin
  }
}

export interface IssueCertificateRequest {
  type: typeof SET_ISSUE_CERTIFICATE_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

// TODO: Replace with types used in contentcreator
export interface CertificateSignature {
  clarification: string
  position: string
  signatureUrl: string
}

export interface CertificateStudent {
  avatar: string
  email: string
  id: number
  lastName: string
  name: string
}

export interface Certificate {
  loading: boolean
  date: string
  duration: number
  includeDate: boolean
  includeDuration: boolean
  includePlace: boolean
  organizations: Organization[]
  place: string
  signatures: CertificateSignature[]
  student: CertificateStudent
  text: string
  title: string
  background: string
}

export interface GetCertificateRequest {
  type: typeof GET_CERTIFICATE_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetCertificateFailure {
  type: typeof GET_CERTIFICATE_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetCertificateSuccess {
  type: typeof GET_CERTIFICATE_SUCCESS
  payload: {
    certificate: Certificate
    requestOrigin: ReduxRequestOrigin
  }
}

export type DashboardCoursesAction =
  | GetCoursesRequest
  | GetCoursesSuccess
  | GetCoursesFailure
  | DeleteCourseAssignmentRequest
  | DeleteCourseAssignmentSuccess
  | DeleteCourseAssignmentFailure
  | SetDeleteModalOpen
  | SetCourseStudentsDrawerOpen
  | GetCourseProgressRequest
  | GetCourseProgressSuccess
  | GetCourseProgressFailure
  | IssueCertificateSuccess
  | IssueCertificateRequest
  | GetCertificateRequest
  | GetCertificateFailure
  | GetCertificateSuccess
