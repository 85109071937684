import { PUBLIC_URL, IMAGES_COSMOS_URL } from '../config/config'
import type { CosmosLogos, CosmosMap } from './types'

const AULA: CosmosLogos = {
  favicon: `${PUBLIC_URL}/favicon.ico`,
  header: `${IMAGES_COSMOS_URL}/aula/header.svg`,
  login: `${IMAGES_COSMOS_URL}/aula/login.svg`,
  footerLogin: `${IMAGES_COSMOS_URL}/educabot/educabot.svg`,
  footer: `${IMAGES_COSMOS_URL}/aula/footer.svg`,
  footerExtraLogoOne: '',
  footerExtraLogoTwo: '',
  footerCopyright: `® 2024, Educabot`,
  footerBrandText: 'Suite de aplicaciones',
  loginExtraLogos: {
    left: [],
    right: [],
  },
}

const CABA: CosmosLogos = {
  favicon: `${PUBLIC_URL}/favicons/caba.png`,
  login: `${IMAGES_COSMOS_URL}/caba/login.svg`,
  header: `${IMAGES_COSMOS_URL}/caba/header.svg`,
  footer: `${IMAGES_COSMOS_URL}/caba/educacion_digital.svg`,
  footerLogin: `${IMAGES_COSMOS_URL}/educabot/educabot_small.svg`,
  footerExtraLogoOne: `${IMAGES_COSMOS_URL}/caba/ba.png`,
  footerExtraLogoTwo: `${IMAGES_COSMOS_URL}/caba/caba_horizontal.png`,
  footerCopyright: '',
  footerBrandText: 'Powered by',
  loginExtraLogos: {
    left: [`${IMAGES_COSMOS_URL}/caba/educacion_digital.svg`],
    right: [`${IMAGES_COSMOS_URL}/caba/ba.png`, `${IMAGES_COSMOS_URL}/caba/caba_vertical.png`],
  },
}

const LA_RIOJA_MIN_SALUD: CosmosLogos = {
  favicon: `${PUBLIC_URL}/favicons/min_salud_la_rioja.png`,
  login: `${IMAGES_COSMOS_URL}/la_rioja_min_salud/login.svg`,
  header: `${IMAGES_COSMOS_URL}/la_rioja_min_salud/header.svg`,
  footer: `${IMAGES_COSMOS_URL}/la_rioja_min_salud/footer.svg`,
  footerLogin: `${IMAGES_COSMOS_URL}/educabot/power_by_educabot.svg`,
  footerBrandText: 'Powered by',
  loginExtraLogos: {
    left: [`${IMAGES_COSMOS_URL}/la_rioja_min_salud/extra_logo_left.svg`],
    right: [`${IMAGES_COSMOS_URL}/la_rioja_min_salud/extra_logo_right.svg`],
  },
}

const LA_RIOJA: CosmosLogos = {
  favicon: `${PUBLIC_URL}/favicons/la_rioja.png`,
  login: `${IMAGES_COSMOS_URL}/la_rioja/tu_escuela.svg`,
  header: `${IMAGES_COSMOS_URL}/la_rioja/header.svg`,
  footer: `${IMAGES_COSMOS_URL}/la_rioja/footer.svg`,
  footerLogin: `${IMAGES_COSMOS_URL}/la_rioja/rosario_vera.svg`,
  loginExtraLogos: {
    center: [`${IMAGES_COSMOS_URL}/educabot/powered_by_educabot_purple.svg`],
  },
}

const LETS_GO_FOR_MORE: CosmosLogos = {
  favicon: `${PUBLIC_URL}/favicons/lets_go_for_more.png`,
  login: `${IMAGES_COSMOS_URL}/lets_go_for_more/login.svg`,
  header: `${IMAGES_COSMOS_URL}/lets_go_for_more/header.svg`,
  footer: `${IMAGES_COSMOS_URL}/lets_go_for_more/footer.svg`,
  footerLogin: `${IMAGES_COSMOS_URL}/educabot/educabot_blue.svg`,
}

const TED: CosmosLogos = {
  favicon: `${PUBLIC_URL}/favicons/ted.ico`,
  login: `${IMAGES_COSMOS_URL}/ted/login_1.svg`,
  header: `${IMAGES_COSMOS_URL}/ted/header_1.svg`,
  footer: `${IMAGES_COSMOS_URL}/ted/footer_1.svg`,
  footerLogin: `${IMAGES_COSMOS_URL}/ted/login_footer_1.svg`,
  footerBrandText: 'Potenciado por la tecnología educativa de',
}

const ATLAS: CosmosLogos = {
  favicon: `${PUBLIC_URL}/favicons/atlas.png`,
  login: `${IMAGES_COSMOS_URL}/atlas/login.svg`,
  header: `${IMAGES_COSMOS_URL}/atlas/header.svg`,
  footer: `${IMAGES_COSMOS_URL}/atlas/footer.svg`,
  footerLogin: `${IMAGES_COSMOS_URL}/educabot/power_by_educabot.svg`,
  footerBrandText: 'Powered by',
}

const AGENPIA: CosmosLogos = {
  favicon: `${PUBLIC_URL}/favicons/agenpia.ico`,
  login: `${IMAGES_COSMOS_URL}/agenpia/login.svg`,
  header: `${IMAGES_COSMOS_URL}/agenpia/header.svg`,
  footer: `${IMAGES_COSMOS_URL}/agenpia/footer.svg`,
  footerLogin: `${IMAGES_COSMOS_URL}/educabot/power_by_educabot.svg`,
  footerBrandText: 'Powered by',
}

export const LOGOS: CosmosMap<CosmosLogos> = {
  AULA,
  CABA,
  LA_RIOJA_MIN_SALUD,
  LA_RIOJA,
  LETS_GO_FOR_MORE,
  TED,
  ATLAS,
  AGENPIA,
}
