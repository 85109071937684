import type { Moment } from 'moment'
import moment from 'moment'
import { AttendancesAPI } from '@/api/attendances'
import type { StudentAttendance } from '@/api/attendances/records'
import { ClassroomsAPI } from '@/api/lms/classrooms'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { AppThunk } from '@/state/thunk'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import * as Types from '@/sections/attendances/records/types/mainView'

const attendanceRecordsMainViewActionCreators = {
  getClassroomAttendances:
    (
      requestOrigins: ReduxRequestOrigin[],
      classroomId: number,
      startDate: Moment,
      endDate: Moment,
      subjectId: number
    ): AppThunk =>
    async (dispatch, getState) => {
      const request: Types.GetClassroomAttendancesRequest = {
        type: Types.GET_CLASSROOM_ATTENDANCES_REQUEST,
        payload: {
          requestOrigins,
          startDate,
          endDate,
          subjectId,
        },
      }
      dispatch(request)

      try {
        const { config } = getState().attendances.records.mainView
        const { statuses } = config
        const today = moment()

        const { attendances } = await AttendancesAPI.Records.getClassroomAttendances(
          classroomId,
          startDate,
          endDate,
          subjectId
        )

        const success: Types.GetClassroomAttendancesSuccess = {
          type: Types.GET_CLASSROOM_ATTENDANCES_SUCCESS,
          payload: {
            requestOrigins,
            studentAttendances: attendances,
          },
        }

        // INFO: el estado "presente" deberia existir si o si en la config y ser unico
        const presentStatus = statuses.find((status) => {
          return status.value === 0
        })

        if (presentStatus) {
          const todayAttendances: StudentAttendance[] = []
          const pastAttendances: StudentAttendance[] = []
          attendances.forEach((a) => {
            if (today.isSame(a.date, 'day')) {
              todayAttendances.push(a)
            } else {
              pastAttendances.push(a)
            }
          })

          success.payload = {
            requestOrigins,
            studentAttendances: pastAttendances.concat([...todayAttendances]),
          }
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, Types.GET_CLASSROOM_ATTENDANCES_FAILURE, dispatch, {
          requestOrigins,
        })
      }
    },
  updateStudentAttendance:
    (
      requestOrigins: ReduxRequestOrigin[],
      configurationId: number,
      date: Moment,
      studentId: number,
      statusId: number,
      abbreviation: string,
      color: string,
      value: number,
      description: string,
      subjectId: number
    ): AppThunk =>
    async (dispatch) => {
      const studentAttendance = {
        studentId,
        abbreviation,
        color,
        value,
        date,
        description,
        subjectId,
      }

      const request: Types.UpdateStudentAttendanceRequest = {
        type: Types.UPDATE_STUDENT_ATTENDANCE_REQUEST,
        payload: {
          requestOrigins,
          studentAttendance,
        },
      }
      dispatch(request)

      try {
        await AttendancesAPI.Records.update(configurationId, date, studentId, statusId, subjectId)

        const success: Types.UpdateStudentAttendanceSuccess = {
          type: Types.UPDATE_STUDENT_ATTENDANCE_SUCCESS,
          payload: {
            requestOrigins,
          },
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, Types.UPDATE_STUDENT_ATTENDANCE_FAILURE, dispatch, {
          requestOrigins,
          studentAttendance,
        })
      }
    },
  setDateAsEditable: (
    requestOrigins: ReduxRequestOrigin[],
    date: Moment
  ): Types.SetDateAsEditable => ({
    type: Types.SET_DATE_AS_EDITABLE,
    payload: { requestOrigins, date },
  }),
  getSubjects:
    (requestOrigins: ReduxRequestOrigin[], classroomId: number): AppThunk =>
    async (dispatch) => {
      const request: Types.GetSubjectsRequest = {
        type: Types.GET_SUBJECTS_REQUEST,
        payload: {
          requestOrigins,
        },
      }
      dispatch(request)

      try {
        const response = await ClassroomsAPI.getClassroomSubjects(classroomId, true)
        const success: Types.GetSubjectsSuccess = {
          type: Types.GET_SUBJECTS_SUCCESS,
          payload: {
            requestOrigins,
            subjects: response.data.description.subjects.map((s) => ({
              name: s.name,
              subjectId: s.id,
            })),
          },
        }

        dispatch(success)
        return response.data.description.subjects
      } catch (error) {
        handleErrorsWithAction(error, Types.GET_SUBJECTS_FAILURE, dispatch, {
          requestOrigins,
        })
        return null
      }
    },
  getOwnSubjects:
    (requestOrigins: ReduxRequestOrigin[], classroomId: number): AppThunk =>
    async (dispatch) => {
      const request: Types.GetSubjectsRequest = {
        type: Types.GET_SUBJECTS_REQUEST,
        payload: {
          requestOrigins,
        },
      }
      dispatch(request)

      try {
        const response = await ClassroomsAPI.getClassroomSubjects(classroomId, false)
        const success: Types.GetOwnSubjectsSuccess = {
          type: Types.GET_OWN_SUBJECTS_SUCCESS,
          payload: {
            requestOrigins,
            ownSubjects: response.data.description.subjects.map((s) => ({
              color: s.color,
              name: s.name,
              id: s.id,
            })),
          },
        }

        dispatch(success)
        return response.data.description.subjects
      } catch (error) {
        handleErrorsWithAction(error, Types.GET_SUBJECTS_FAILURE, dispatch, {
          requestOrigins,
        })
        return null
      }
    },
  setSelectedSubject: (
    requestOrigins: ReduxRequestOrigin[],
    subjectId: number
  ): Types.SetSelectedSubject => ({
    type: Types.SET_SELECTED_SUBJECT,
    payload: { requestOrigins, subjectId },
  }),
}

export default attendanceRecordsMainViewActionCreators
