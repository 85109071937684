import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type { Reducer } from 'redux'
import { Redux } from '@/legacy/redux'
import type {
  AttendancesConfigAbsencesModalAction,
  AttendancesConfigAbsencesModalState,
} from '@/sections/attendances/config/types/datesModal'
import {
  SET_OPEN,
  CONFIRM_DATES,
  UPDATE_DATES,
} from '@/sections/attendances/config/types/datesModal'

const initialState: AttendancesConfigAbsencesModalState = {
  open: false,
  previousReasonName: '',
  newReasonName: '',
  dates: [],
  disabledDates: [],
  disabledWeekDays: [],
}

const requestDestination = reduxRequestOriginMap.ATTENDANCES_CONFIG_DATES_MODAL

const AttendancesConfigDatesModal: Reducer<
  AttendancesConfigAbsencesModalState,
  AttendancesConfigAbsencesModalAction
> = (state = initialState, action): AttendancesConfigAbsencesModalState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      if (action.payload.fieldName === 'open' && !action.payload.fieldValue) return initialState
      return {
        ...setGenericField(state, action.payload),
      }
    case SET_OPEN: {
      const { dates, disabledDates, disabledWeekDays, absenceReason } = action.payload

      return {
        ...state,
        open: true,
        dates,
        disabledDates,
        disabledWeekDays,
        previousReasonName: absenceReason,
        newReasonName: absenceReason || initialState.newReasonName,
      }
    }
    case UPDATE_DATES:
      return { ...state, dates: action.payload.dates }
    case CONFIRM_DATES:
      return initialState
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, AttendancesConfigDatesModal, requestDestination)
