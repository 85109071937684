import { clone } from 'ramda'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import type { NotifierState, NotifierAction } from '@/sections/notifier/types/notifier'
import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILURE,
  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAILURE,
  CHECK_USER_EMAIL_REQUEST,
  CHECK_USER_EMAIL_SUCCESS,
  CHECK_USER_EMAIL_FAILURE,
  MARK_AS_READ_SUCCESS,
  MARK_ALL_AS_READ_SUCCESS,
  SEE_UNREAD_ONLY_SUCCESS,
  SEE_UNREAD_ONLY_FAILURE,
  FORWARD_MESSAGE_NOTIFICATION_REQUEST,
  FORWARD_MESSAGE_NOTIFICATION_FAILURE,
  FORWARD_MESSAGE_NOTIFICATION_SUCCESS,
} from '@/sections/notifier/types/notifier'
import moment from 'moment'

const initialState: NotifierState = {
  notifications: [],
  notificationsPagination: {
    unread: 0,
    count: 0,
    page: 0,
    rowsPerPage: 15,
  },
  unreadOnly: true,
  loadingNotifications: false,
  error: '',
  subscriptions: [],
  loadingSubscriptions: false,
  emailEnabled: false,
  loadingEmail: false,
}

const requestDestination = reduxRequestOriginMap.NOTIFIER

const CourseCreationDrawerReducer = (
  state: NotifierState = initialState,
  action: NotifierAction
): NotifierState => {
  if (action.payload?.requestOrigin !== requestDestination) return state

  switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loadingNotifications: true,
        notificationsPagination: {
          ...state.notificationsPagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
        notifications: action.payload.overwrite ? initialState.notifications : state.notifications,
        error: '',
      }
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loadingNotifications: false,
        notifications: [...state.notifications, ...action.payload.notifications],
        notificationsPagination: {
          ...state.notificationsPagination,
          count: action.payload.count,
          unread: action.payload.unread,
        },
      }
    case GET_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        loadingNotifications: false,
        error: action.payload.error,
      }
    }
    case GET_SUBSCRIPTIONS_REQUEST: {
      return {
        ...state,
        loadingSubscriptions: true,
      }
    }
    case GET_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        loadingSubscriptions: false,
        subscriptions: action.payload.subscriptions.filter(
          (sub) => sub.notificationType.name !== 'jibri_balance'
        ),
      }
    }
    case GET_SUBSCRIPTIONS_FAILURE: {
      return {
        ...state,
        loadingSubscriptions: false,
        error: action.payload.error,
      }
    }
    case UPDATE_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        loadingSubscriptions: true,
      }
    }
    case UPDATE_SUBSCRIPTION_SUCCESS: {
      const { subscription } = action.payload
      const subscriptions = clone(state.subscriptions)

      const newSubscriptions = subscriptions.map((sub) =>
        sub.notificationTypeId === subscription.notificationTypeId ? subscription : sub
      )

      return {
        ...state,
        loadingSubscriptions: false,
        subscriptions: newSubscriptions,
      }
    }
    case UPDATE_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        loadingSubscriptions: false,
        error: action.payload.error,
      }
    }
    case CHECK_USER_EMAIL_REQUEST: {
      return {
        ...state,
        loadingEmail: true,
      }
    }
    case CHECK_USER_EMAIL_SUCCESS: {
      return {
        ...state,
        loadingEmail: false,
        emailEnabled: action.payload.emailEnabled,
      }
    }
    case CHECK_USER_EMAIL_FAILURE: {
      return {
        ...state,
        loadingSubscriptions: false,
        error: action.payload.error,
      }
    }
    case MARK_AS_READ_SUCCESS: {
      const { notifications } = state

      const notificationsPagination = clone(state.notificationsPagination)
      notificationsPagination.unread--

      return {
        ...state,
        notifications: notifications.map((noti) => {
          if (noti.id === action.payload.notifID) {
            noti.readAt = moment().format()
          }
          return noti
        }),
        notificationsPagination,
      }
    }
    case MARK_ALL_AS_READ_SUCCESS: {
      const { notifications } = state

      const notificationsPagination = clone(state.notificationsPagination)
      notificationsPagination.unread = 0

      return {
        ...state,
        notifications: notifications.map((noti) => {
          noti.readAt = moment().format()
          return noti
        }),
        notificationsPagination,
      }
    }
    case SEE_UNREAD_ONLY_SUCCESS:
      return {
        ...state,
        unreadOnly: action.payload.checked,
      }
    case SEE_UNREAD_ONLY_FAILURE: {
      return {
        ...state,
        loadingNotifications: false,
        error: action.payload.error,
      }
    }
    case FORWARD_MESSAGE_NOTIFICATION_REQUEST: {
      return {
        ...state,
        loadingNotifications: true,
      }
    }
    case FORWARD_MESSAGE_NOTIFICATION_FAILURE: {
      return {
        ...state,
        loadingNotifications: false,
        error: action.payload.error,
      }
    }
    case FORWARD_MESSAGE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        loadingNotifications: false,
      }
    }
    default:
      return state
  }
}

export default CourseCreationDrawerReducer
