import { clone } from 'ramda'
import type {
  EvaluationState,
  EvaluationActions,
} from '@/sections/teacher/evaluation/types/evaluation'
import {
  GET_EVALUATION_DATA_REQUEST,
  GET_EVALUATION_DATA_SUCCESS,
  GET_EVALUATION_DATA_FAILURE,
  GET_ACTIVITY_REQUEST,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_FAILURE,
  SET_PAGINATION,
  CLEAR_PARAMS,
  CLEAR_ACTIVITY_CACHE,
  UPDATE_STUDENT_EVALUATION,
  CLEAR_STATE,
} from '@/sections/teacher/evaluation/types/evaluation'
import { REQUEST_STATUS } from '@/legacy/redux/types/status'

const initialState: EvaluationState = {
  table: {
    head: {
      activities: [],
    },
    rows: [],
  },
  total: 0,
  loading: false,
  error: '',
  params: {
    limit: 5,
    offset: 0,
  },
  pagination: {
    current: 0,
    total: 0,
  },
  selectedActivity: null,
  activityCache: {},
  evaluationStatus: REQUEST_STATUS.IDLE,
}

function root(state = initialState, action: EvaluationActions): EvaluationState {
  switch (action.type) {
    case GET_ACTIVITY_REQUEST:
      return { ...state, loading: true, error: '' }
    case GET_EVALUATION_DATA_REQUEST:
      return { ...state, evaluationStatus: REQUEST_STATUS.LOADING, error: '' }
    case GET_EVALUATION_DATA_SUCCESS:
      return {
        ...state,
        table: action.payload.table,
        total: action.payload.total,
        params: action.payload.params,
        evaluationStatus: REQUEST_STATUS.SUCCESS,
        error: '',
      }
    case GET_EVALUATION_DATA_FAILURE: {
      return { ...state, evaluationStatus: REQUEST_STATUS.FAILURE, error: action.payload.error }
    }
    case GET_ACTIVITY_FAILURE: {
      return { ...state, loading: false, error: action.payload.error }
    }
    case SET_PAGINATION: {
      return { ...state, pagination: action.payload.pagination }
    }
    case CLEAR_PARAMS: {
      return { ...state, params: initialState.params, pagination: initialState.pagination }
    }
    case GET_ACTIVITY_SUCCESS: {
      const { activity } = action.payload
      return {
        ...state,
        loading: false,
        selectedActivity: activity,
        activityCache: { ...state.activityCache, [activity.id]: activity },
      }
    }
    case CLEAR_ACTIVITY_CACHE: {
      return { ...state, activityCache: initialState.activityCache }
    }
    case UPDATE_STUDENT_EVALUATION: {
      const { activityId, studentId, evaluation } = action.payload

      const rows = clone(state.table.rows)

      rows.map((row) => {
        const activity = row.activities.find((a) => a.activityId === activityId)
        if (row.student.id === studentId && activity) {
          activity.evaluation = evaluation
          return {
            ...row,
            activities: {
              ...row.activities.filter((a) => a.activityId !== activityId),
              activity,
            },
          }
        } else return row
      })

      return { ...state, table: { ...state.table, rows } }
    }
    case CLEAR_STATE: {
      return initialState
    }
    default:
      return state
  }
}

export default root
