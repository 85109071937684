import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/student/summary/types/tabs/attendances/attendances'

const initialState: Types.AttendancesState = {
  status: REQUEST_STATUS.IDLE,
  data: [],
  percentage: 0,
}

const attendancesReducer = (
  state: Types.AttendancesState = initialState,
  action: Types.AttendancesAction
): Types.AttendancesState => {
  switch (action.type) {
    case Types.ATTENDANCES_TYPES.GET_ATTENDANCES_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.ATTENDANCES_TYPES.GET_ATTENDANCES_SUCCESS: {
      return {
        status: REQUEST_STATUS.SUCCESS,
        data: action.payload.data,
        percentage: action.payload.percentage,
      }
    }
    case Types.ATTENDANCES_TYPES.GET_ATTENDANCES_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.ATTENDANCES_TYPES.CLEAN_ATTENDANCES: {
      return initialState
    }
    default:
      return state
  }
}

export default attendancesReducer
