import clsx from 'clsx'
import type { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import type { ClassNameMap } from '@mui/styles'

interface StylesProps {
  size: number
  white: boolean
}

const getAnibotUrl = (white: boolean) =>
  `url(${process.env.PUBLIC_URL}/images/anibot-large-${white ? 'white' : 'black'}.gif)`

const useStyles = makeStyles<Theme, StylesProps>(() => ({
  anibotContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  anibot: ({ size, white }) => ({
    width: size,
    height: size,
    backgroundColor: 'transparent',
    backgroundImage: getAnibotUrl(white),
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: size,
    alignSelf: 'center',
  }),
}))

export type AnibotClassKey = 'container' | 'anibot'

export interface AnibotProps {
  size?: number
  white?: boolean
  className?: string
  clssx?: Partial<ClassNameMap<AnibotClassKey>>
}

const Anibot: React.FC<AnibotProps> = ({
  size = 50,
  white = false,
  clssx,
  className,
  ...props
}) => {
  const C = useStyles({ size, white })
  return (
    <div {...props} className={clsx(C.anibotContainer, className, clssx?.container)}>
      <span className={clsx(C.anibot, clssx?.anibot)} />
    </div>
  )
}

export default Anibot
