import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import { SAVE_OR_EDIT_REPORT_CARD_CONFIG_SUCCESS } from '@/sections/report-card/config/types/apiInteractions'
import type {
  ReportCardConfigRangePickerModalAction,
  ReportCardConfigRangePickerModalState,
} from '@/sections/report-card/config/types/stepperModalSteps/rangePickerModal'
import { POPULATE_AND_OPEN_RANGE_PICKER } from '@/sections/report-card/config/types/stepperModalSteps/rangePickerModal'

const initialState: ReportCardConfigRangePickerModalState = {
  ranges: [],
  activeRangeName: '',
  open: false,
  category: 'scores',
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_CONFIG_RANGE_PICKER

const ReportCardConfigRangePicker: Reducer<
  ReportCardConfigRangePickerModalState,
  ReportCardConfigRangePickerModalAction
> = (state = initialState, action): ReportCardConfigRangePickerModalState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case POPULATE_AND_OPEN_RANGE_PICKER: {
      const { ranges, activeRangeName, category, scaleId } = action.payload
      return {
        ...state,
        ranges,
        activeRangeName,
        category,
        scaleId,
        open: true,
      }
    }
    case SAVE_OR_EDIT_REPORT_CARD_CONFIG_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, ReportCardConfigRangePicker, requestDestination)
