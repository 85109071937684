import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { ClearState, SetField, ToggleOpen } from '@/legacy/redux/types/generic'
import { CLEAR_STATE, SET_FIELD, TOGGLE_OPEN } from '@/legacy/redux/types/generic'

const genericActionCreators = {
  setField: <N, V>(
    requestOrigins: ReduxRequestOrigin[],
    fieldName: N,
    fieldValue: V
  ): SetField<N, V> => ({
    type: SET_FIELD,
    payload: {
      requestOrigins,
      fieldName,
      fieldValue,
    },
  }),
  clearState: (requestOrigins: ReduxRequestOrigin[]): ClearState => ({
    type: CLEAR_STATE,
    payload: { requestOrigins },
  }),
  toggleOpen: (requestOrigins: ReduxRequestOrigin[], open: boolean): ToggleOpen => ({
    type: TOGGLE_OPEN,
    payload: { requestOrigins, open },
  }),
}

export default genericActionCreators
