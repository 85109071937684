import type { Reducer } from 'redux'
import { Redux } from '@/legacy/redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type {
  CustomizeThemeModalAction,
  CustomizeThemeModalState,
} from '@/sections/management/types/customizeThemeModal'
import {
  ADD_GAME_TO_SELECTED,
  CustomizeThemeStep,
  GET_GAMES_FAILURE,
  GET_GAMES_REQUEST,
  GET_GAMES_SUCCESS,
  REMOVE_GAME_FROM_SELECTED,
} from '@/sections/management/types/customizeThemeModal'
import { CREATE_SUCCESS, EDIT_SUCCESS } from '@/sections/eb-evaluator/management/types/addEditThemeModal'

const requestDestination = reduxRequestOriginMap.MANAGEMENT_CUSTOMIZE_THEME_MODAL

const initialState: CustomizeThemeModalState = {
  open: false,
  games: [],
  selectedGames: [],
  loading: false,
  error: '',
  pagination: { page: 0, rowsPerPage: 10, count: 0 },
  step: CustomizeThemeStep.SELECT_GAMES,
  search: '',
}

const CustomizeThemeModalReducer: Reducer<CustomizeThemeModalState, CustomizeThemeModalAction> = (
  state = initialState,
  action
): CustomizeThemeModalState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
    case CREATE_SUCCESS:
    case EDIT_SUCCESS:
      return initialState
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case GET_GAMES_REQUEST:
      return { ...state, loading: true, search: action.payload.search }
    case GET_GAMES_SUCCESS:
      return {
        ...state,
        games: action.payload.games,
        loading: false,
        pagination: action.payload.pagination,
      }
    case GET_GAMES_FAILURE:
      return { ...state, error: action.payload.error, loading: false }
    case ADD_GAME_TO_SELECTED:
      return {
        ...state,
        selectedGames: state.selectedGames.concat(
          state.games.find((game) => game.id == action.payload.id) ?? []
        ),
      }
    case REMOVE_GAME_FROM_SELECTED:
      return {
        ...state,
        selectedGames: state.selectedGames.filter((game) => game.id != action.payload.id),
      }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, CustomizeThemeModalReducer, requestDestination)
