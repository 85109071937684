import type { SetField } from '@/legacy/redux/types/generic'
import type { ListActivityStudentsAction, ActivityStudent } from '@/legacy/redux/types/students'
import type { ErrorPayload, GenericPayload } from '@/legacy/redux/utils'
import type { Pages } from '@/sections/content-creator/dashboard/types/dashboard'

export type CollabMiniatureAssignStep = 'distribute' | 'pick-leader'
export type CollabRole = 'leader' | 'observer'

export type ReservationStudent = {
  id: number
  role: CollabRole
}

export type Reservation = {
  reservationId: number
  students: ReservationStudent[]
}

export interface CollabStudent extends ActivityStudent {
  role: CollabRole
  reservationId: number | null
}
export interface Scenario {
  id: number
  reservationId: number
  name: string
}

export interface CollabMiniatureAssignState {
  scenarios: Scenario[]
  step: CollabMiniatureAssignStep
  students: ActivityStudent[]
  studentsDistribution: CollabStudent[]
  loading: boolean
  error: string
  open: boolean
  activityId: number | null
  classroomId: number | null
  studentsPagination: Pages
}

/*Set student scenario */
export const SET_STUDENT_SCENARIO = 'COLLAB_MINIATURE_ASSIGN/SET_STUDENT_SCENARIO'
interface SetStudentScenarioPayload extends GenericPayload {
  student: ActivityStudent
  newReservationId: number | null
}

export interface SetStudentScenario {
  type: typeof SET_STUDENT_SCENARIO
  payload: SetStudentScenarioPayload
}

/* Set student as leader */
export const SET_STUDENT_AS_LEADER = 'COLLAB_MINIATURE_ASSIGN/SET_STUDENT_AS_LEADER'
interface SetStudentAsLeaderPayload extends GenericPayload {
  student: CollabStudent
}
export interface SetStudentAsLeader {
  type: typeof SET_STUDENT_AS_LEADER
  payload: SetStudentAsLeaderPayload
}

/* Set open */
export const SET_OPEN = 'COLLAB_MINIATURE_ASSIGN/SET_OPEN'

interface SetOpenPayload extends GenericPayload {
  open: boolean
  activityId?: number
  classroomId?: number
  scenarios?: Scenario[]
}
export interface SetOpen {
  type: typeof SET_OPEN
  payload: SetOpenPayload
}

/* Assign scenarios */
export const ASSIGN_STUDENTS_REQUEST = 'COLLAB_MINIATURE_ASSIGN/ASSIGN_STUDENTS_REQUEST'
export interface AssignStudentsRequest {
  type: typeof ASSIGN_STUDENTS_REQUEST
  payload: GenericPayload
}

export const ASSIGN_STUDENTS_SUCCESS = 'COLLAB_MINIATURE_ASSIGN/ASSIGN_STUDENTS_SUCCESS'
export interface AssignStudentsSuccess {
  type: typeof ASSIGN_STUDENTS_SUCCESS
  payload: GenericPayload
}

export const ASSIGN_STUDENTS_FAILURE = 'COLLAB_MINIATURE_ASSIGN/ASSIGN_STUDENTS_FAILURE'
export interface AssignStudentsFailure {
  type: typeof ASSIGN_STUDENTS_FAILURE
  payload: ErrorPayload
}

type AssignStudentsAction = AssignStudentsRequest | AssignStudentsSuccess | AssignStudentsFailure

/* Accepted actions */
export type CollabMiniatureAssignAction =
  | ListActivityStudentsAction
  | SetStudentScenario
  | SetStudentAsLeader
  | SetOpen
  | AssignStudentsAction
  | SetField<'step', CollabMiniatureAssignStep>
