import { ThemeOptions } from './types/addEditExamModal'
import type { Option } from '@/legacy/components/v3/selects/selectSimple'

export const SUBJECTS_COLORS = [
  '#FFB800',
  '#F98939',
  '#FF6428',
  '#E84F92',
  '#9B00AF',
  '#675CFF',
  '#2E33A9',
  '#6AACD9',
  '#01CEAA',
  '#86AA59',
  '#5C4D7C',
  '#212C4C',
]

// TO DO: migrate existing games so that 1 means "Primero", 2 means "Segundo", etc.
export const examsTypeOptions: Option[] = [
  {
    id: 3,
    label: 'ordinalNumbers.first',
  },
  {
    id: 4,
    label: 'ordinalNumbers.second',
  },
  {
    id: 1,
    label: 'ordinalNumbers.third',
  },
  {
    id: 5,
    label: 'ordinalNumbers.fourth',
  },
  {
    id: 6,
    label: 'ordinalNumbers.fifth',
  },
  {
    id: 2,
    label: 'ordinalNumbers.sixth',
  },
  {
    id: 7,
    label: 'ordinalNumbers.seventh',
  },
]

export const examsNeighborhoodOptions: Option[] = [
  {
    id: 0,
    label: 'Todos',
  },
  {
    id: 1,
    label: 'Distrito Escolar 1',
  },
  {
    id: 10,
    label: 'Distrito Escolar 10',
  },
  {
    id: 11,
    label: 'Distrito Escolar 11',
  },
  {
    id: 12,
    label: 'Distrito Escolar 12',
  },
  {
    id: 13,
    label: 'Distrito Escolar 13',
  },
  {
    id: 14,
    label: 'Distrito Escolar 14',
  },
  {
    id: 15,
    label: 'Distrito Escolar 15',
  },
  {
    id: 16,
    label: 'Distrito Escolar 16',
  },
  {
    id: 17,
    label: 'Distrito Escolar 17',
  },
  {
    id: 18,
    label: 'Distrito Escolar 18',
  },
  {
    id: 19,
    label: 'Distrito Escolar 18',
  },
  {
    id: 2,
    label: 'Distrito Escolar 2',
  },
  {
    id: 20,
    label: 'Distrito Escolar 20',
  },
  {
    id: 21,
    label: 'Distrito Escolar 21',
  },
  {
    id: 3,
    label: 'Distrito Escolar 3',
  },
  {
    id: 4,
    label: 'Distrito Escolar 4',
  },
  {
    id: 5,
    label: 'Distrito Escolar 5',
  },
  {
    id: 6,
    label: 'Distrito Escolar 6',
  },
  {
    id: 7,
    label: 'Distrito Escolar 7',
  },
  {
    id: 8,
    label: 'Distrito Escolar 8',
  },
  {
    id: 9,
    label: 'Distrito Escolar 9',
  },
]

export const managementTypesOptions: Option[] = [
  {
    id: 0,
    label: 'Todas',
  },
  {
    id: 1,
    color: 'public',
    label: 'Estatales',
  },
  {
    id: 2,
    color: 'private',
    label: 'Privadas',
  },
]

export const examsStageOptions: Option[] = [
  {
    id: 1,
    label: 'Etapa 1',
  },
  {
    id: 2,
    label: 'Etapa 2',
  },
  {
    id: 3,
    label: 'Etapa 3',
  },
  {
    id: 4,
    label: 'Etapa 4',
  },
  {
    id: 5,
    label: 'Etapa 5',
  },
  {
    id: 6,
    label: 'Etapa 6',
  },
  {
    id: 7,
    label: 'Etapa 7',
  },
  {
    id: 8,
    label: 'Etapa 8',
  },
  {
    id: 9,
    label: 'Etapa 9',
  },
  {
    id: 10,
    label: 'Etapa 10',
  },
]

export const TIME_ZONES = [
  'Pacific/Kiritimati (UTC +14:00)',
  'Pacific/Chatham (UTC +13:45)',
  'Antarctica/McMurdo (UTC +13:00)',
  'Pacific/Kanton (UTC +13:00)',
  'Pacific/Auckland (UTC +13:00)',
  'Pacific/Apia (UTC +13:00)',
  'Pacific/Fakaofo (UTC +13:00)',
  'Pacific/Tongatapu (UTC +13:00)',
  'Pacific/Fiji (UTC +12:00)',
  'Pacific/Tarawa (UTC +12:00)',
  'Pacific/Kwajalein (UTC +12:00)',
  'Pacific/Majuro (UTC +12:00)',
  'Pacific/Nauru (UTC +12:00)',
  'Pacific/Norfolk (UTC +12:00)',
  'Asia/Anadyr (UTC +12:00)',
  'Asia/Kamchatka (UTC +12:00)',
  'Pacific/Funafuti (UTC +12:00)',
  'Pacific/Wake (UTC +12:00)',
  'Pacific/Wallis (UTC +12:00)',
  'Antarctica/Casey (UTC +11:00)',
  'Antarctica/Macquarie (UTC +11:00)',
  'Australia/Hobart (UTC +11:00)',
  'Australia/Lord_Howe (UTC +11:00)',
  'Australia/Melbourne (UTC +11:00)',
  'Australia/Sydney (UTC +11:00)',
  'Pacific/Kosrae (UTC +11:00)',
  'Pacific/Pohnpei (UTC +11:00)',
  'Pacific/Noumea (UTC +11:00)',
  'Pacific/Bougainville (UTC +11:00)',
  'Asia/Magadan (UTC +11:00)',
  'Asia/Sakhalin (UTC +11:00)',
  'Asia/Srednekolymsk (UTC +11:00)',
  'Pacific/Guadalcanal (UTC +11:00)',
  'Pacific/Efate (UTC +11:00)',
  'Australia/Adelaide (UTC +10:30)',
  'Australia/Broken_Hill (UTC +10:30)',
  'Antarctica/DumontDUrville (UTC +10:00)',
  'Australia/Brisbane (UTC +10:00)',
  'Australia/Lindeman (UTC +10:00)',
  'Pacific/Guam (UTC +10:00)',
  'Pacific/Chuuk (UTC +10:00)',
  'Pacific/Saipan (UTC +10:00)',
  'Pacific/Port_Moresby (UTC +10:00)',
  'Asia/Ust-Nera (UTC +10:00)',
  'Asia/Vladivostok (UTC +10:00)',
  'Australia/Darwin (UTC +09:30)',
  'Asia/Jayapura (UTC +09:00)',
  'Asia/Tokyo (UTC +09:00)',
  'Asia/Pyongyang (UTC +09:00)',
  'Asia/Seoul (UTC +09:00)',
  'Pacific/Palau (UTC +09:00)',
  'Asia/Chita (UTC +09:00)',
  'Asia/Khandyga (UTC +09:00)',
  'Asia/Yakutsk (UTC +09:00)',
  'Asia/Dili (UTC +09:00)',
  'Australia/Eucla (UTC +08:45)',
  'Australia/Perth (UTC +08:00)',
  'Asia/Brunei (UTC +08:00)',
  'Asia/Shanghai (UTC +08:00)',
  'Asia/Hong_Kong (UTC +08:00)',
  'Asia/Makassar (UTC +08:00)',
  'Asia/Macau (UTC +08:00)',
  'Asia/Kuala_Lumpur (UTC +08:00)',
  'Asia/Kuching (UTC +08:00)',
  'Asia/Choibalsan (UTC +08:00)',
  'Asia/Ulaanbaatar (UTC +08:00)',
  'Asia/Manila (UTC +08:00)',
  'Asia/Irkutsk (UTC +08:00)',
  'Asia/Singapore (UTC +08:00)',
  'Asia/Taipei (UTC +08:00)',
  'Antarctica/Davis (UTC +07:00)',
  'Asia/Phnom_Penh (UTC +07:00)',
  'Indian/Christmas (UTC +07:00)',
  'Asia/Jakarta (UTC +07:00)',
  'Asia/Pontianak (UTC +07:00)',
  'Asia/Vientiane (UTC +07:00)',
  'Asia/Hovd (UTC +07:00)',
  'Asia/Barnaul (UTC +07:00)',
  'Asia/Krasnoyarsk (UTC +07:00)',
  'Asia/Novokuznetsk (UTC +07:00)',
  'Asia/Novosibirsk (UTC +07:00)',
  'Asia/Tomsk (UTC +07:00)',
  'Asia/Bangkok (UTC +07:00)',
  'Asia/Ho_Chi_Minh (UTC +07:00)',
  'Indian/Cocos (UTC +06:30)',
  'Asia/Yangon (UTC +06:30)',
  'Antarctica/Vostok (UTC +06:00)',
  'Asia/Dhaka (UTC +06:00)',
  'Asia/Thimphu (UTC +06:00)',
  'Indian/Chagos (UTC +06:00)',
  'Asia/Urumqi (UTC +06:00)',
  'Asia/Almaty (UTC +06:00)',
  'Asia/Qostanay (UTC +06:00)',
  'Asia/Bishkek (UTC +06:00)',
  'Asia/Omsk (UTC +06:00)',
  'Asia/Kathmandu (UTC +05:45)',
  'Asia/Kolkata (UTC +05:30)',
  'Asia/Colombo (UTC +05:30)',
  'Antarctica/Mawson (UTC +05:00)',
  'Indian/Kerguelen (UTC +05:00)',
  'Asia/Aqtau (UTC +05:00)',
  'Asia/Aqtobe (UTC +05:00)',
  'Asia/Atyrau (UTC +05:00)',
  'Asia/Oral (UTC +05:00)',
  'Asia/Qyzylorda (UTC +05:00)',
  'Indian/Maldives (UTC +05:00)',
  'Asia/Karachi (UTC +05:00)',
  'Asia/Yekaterinburg (UTC +05:00)',
  'Asia/Dushanbe (UTC +05:00)',
  'Asia/Ashgabat (UTC +05:00)',
  'Asia/Samarkand (UTC +05:00)',
  'Asia/Tashkent (UTC +05:00)',
  'Asia/Kabul (UTC +04:30)',
  'Asia/Yerevan (UTC +04:00)',
  'Asia/Baku (UTC +04:00)',
  'Asia/Tbilisi (UTC +04:00)',
  'Indian/Mauritius (UTC +04:00)',
  'Asia/Muscat (UTC +04:00)',
  'Europe/Astrakhan (UTC +04:00)',
  'Europe/Samara (UTC +04:00)',
  'Europe/Saratov (UTC +04:00)',
  'Europe/Ulyanovsk (UTC +04:00)',
  'Indian/Reunion (UTC +04:00)',
  'Indian/Mahe (UTC +04:00)',
  'Asia/Dubai (UTC +04:00)',
  'Asia/Tehran (UTC +03:30)',
  'Antarctica/Syowa (UTC +03:00)',
  'Asia/Bahrain (UTC +03:00)',
  'Europe/Minsk (UTC +03:00)',
  'Indian/Comoro (UTC +03:00)',
  'Africa/Djibouti (UTC +03:00)',
  'Africa/Asmara (UTC +03:00)',
  'Africa/Addis_Ababa (UTC +03:00)',
  'Asia/Baghdad (UTC +03:00)',
  'Asia/Amman (UTC +03:00)',
  'Africa/Nairobi (UTC +03:00)',
  'Asia/Kuwait (UTC +03:00)',
  'Indian/Antananarivo (UTC +03:00)',
  'Indian/Mayotte (UTC +03:00)',
  'Asia/Qatar (UTC +03:00)',
  'Europe/Kirov (UTC +03:00)',
  'Europe/Moscow (UTC +03:00)',
  'Europe/Volgograd (UTC +03:00)',
  'Asia/Riyadh (UTC +03:00)',
  'Africa/Mogadishu (UTC +03:00)',
  'Asia/Damascus (UTC +03:00)',
  'Africa/Dar_es_Salaam (UTC +03:00)',
  'Europe/Istanbul (UTC +03:00)',
  'Africa/Kampala (UTC +03:00)',
  'Europe/Simferopol (UTC +03:00)',
  'Asia/Aden (UTC +03:00)',
  'Africa/Gaborone (UTC +02:00)',
  'Europe/Sofia (UTC +02:00)',
  'Africa/Bujumbura (UTC +02:00)',
  'Africa/Lubumbashi (UTC +02:00)',
  'Asia/Famagusta (UTC +02:00)',
  'Asia/Nicosia (UTC +02:00)',
  'Africa/Cairo (UTC +02:00)',
  'Europe/Tallinn (UTC +02:00)',
  'Europe/Helsinki (UTC +02:00)',
  'Europe/Athens (UTC +02:00)',
  'Asia/Jerusalem (UTC +02:00)',
  'Europe/Riga (UTC +02:00)',
  'Asia/Beirut (UTC +02:00)',
  'Africa/Maseru (UTC +02:00)',
  'Africa/Tripoli (UTC +02:00)',
  'Europe/Vilnius (UTC +02:00)',
  'Africa/Blantyre (UTC +02:00)',
  'Europe/Chisinau (UTC +02:00)',
  'Africa/Maputo (UTC +02:00)',
  'Africa/Windhoek (UTC +02:00)',
  'Asia/Gaza (UTC +02:00)',
  'Asia/Hebron (UTC +02:00)',
  'Europe/Bucharest (UTC +02:00)',
  'Europe/Kaliningrad (UTC +02:00)',
  'Africa/Kigali (UTC +02:00)',
  'Africa/Johannesburg (UTC +02:00)',
  'Africa/Juba (UTC +02:00)',
  'Africa/Khartoum (UTC +02:00)',
  'Africa/Mbabane (UTC +02:00)',
  'Europe/Kyiv (UTC +02:00)',
  'Africa/Lusaka (UTC +02:00)',
  'Africa/Harare (UTC +02:00)',
  'Europe/Mariehamn (UTC +02:00)',
  'Europe/Tirane (UTC +01:00)',
  'Africa/Algiers (UTC +01:00)',
  'Europe/Andorra (UTC +01:00)',
  'Africa/Luanda (UTC +01:00)',
  'Europe/Vienna (UTC +01:00)',
  'Europe/Brussels (UTC +01:00)',
  'Africa/Porto-Novo (UTC +01:00)',
  'Europe/Sarajevo (UTC +01:00)',
  'Africa/Douala (UTC +01:00)',
  'Africa/Bangui (UTC +01:00)',
  'Africa/Ndjamena (UTC +01:00)',
  'Africa/Brazzaville (UTC +01:00)',
  'Africa/Kinshasa (UTC +01:00)',
  'Europe/Zagreb (UTC +01:00)',
  'Europe/Prague (UTC +01:00)',
  'Europe/Copenhagen (UTC +01:00)',
  'Africa/Malabo (UTC +01:00)',
  'Europe/Paris (UTC +01:00)',
  'Africa/Libreville (UTC +01:00)',
  'Europe/Berlin (UTC +01:00)',
  'Europe/Busingen (UTC +01:00)',
  'Europe/Gibraltar (UTC +01:00)',
  'Europe/Vatican (UTC +01:00)',
  'Europe/Budapest (UTC +01:00)',
  'Europe/Rome (UTC +01:00)',
  'Europe/Vaduz (UTC +01:00)',
  'Europe/Luxembourg (UTC +01:00)',
  'Europe/Skopje (UTC +01:00)',
  'Europe/Malta (UTC +01:00)',
  'Europe/Monaco (UTC +01:00)',
  'Europe/Podgorica (UTC +01:00)',
  'Africa/Casablanca (UTC +01:00)',
  'Africa/El_Aaiun (UTC +01:00)',
  'Europe/Amsterdam (UTC +01:00)',
  'Africa/Niamey (UTC +01:00)',
  'Africa/Lagos (UTC +01:00)',
  'Europe/Oslo (UTC +01:00)',
  'Europe/Warsaw (UTC +01:00)',
  'Europe/San_Marino (UTC +01:00)',
  'Europe/Belgrade (UTC +01:00)',
  'Europe/Bratislava (UTC +01:00)',
  'Europe/Ljubljana (UTC +01:00)',
  'Africa/Ceuta (UTC +01:00)',
  'Europe/Madrid (UTC +01:00)',
  'Arctic/Longyearbyen (UTC +01:00)',
  'Europe/Stockholm (UTC +01:00)',
  'Europe/Zurich (UTC +01:00)',
  'Africa/Tunis (UTC +01:00)',
  'Africa/Abidjan (UTC +00:00)',
  'Atlantic/Faroe (UTC +00:00)',
  'Africa/Banjul (UTC +00:00)',
  'Africa/Accra (UTC +00:00)',
  'America/Danmarkshavn (UTC +00:00)',
  'Europe/Guernsey (UTC +00:00)',
  'Africa/Conakry (UTC +00:00)',
  'Africa/Bissau (UTC +00:00)',
  'Atlantic/Reykjavik (UTC +00:00)',
  'Europe/Dublin (UTC +00:00)',
  'Europe/Isle_of_Man (UTC +00:00)',
  'Europe/Jersey (UTC +00:00)',
  'Africa/Monrovia (UTC +00:00)',
  'Africa/Bamako (UTC +00:00)',
  'Africa/Nouakchott (UTC +00:00)',
  'Atlantic/Madeira (UTC +00:00)',
  'Europe/Lisbon (UTC +00:00)',
  'Atlantic/St_Helena (UTC +00:00)',
  'Africa/Sao_Tome (UTC +00:00)',
  'Africa/Dakar (UTC +00:00)',
  'Africa/Freetown (UTC +00:00)',
  'Atlantic/Canary (UTC +00:00)',
  'Africa/Lome (UTC +00:00)',
  'Europe/London (UTC +00:00)',
  'Antarctica/Troll (UTC +00:00)',
  'Africa/Ouagadougou (UTC +00:00)',
  'Atlantic/Cape_Verde (UTC -01:00)',
  'America/Scoresbysund (UTC -01:00)',
  'Atlantic/Azores (UTC -01:00)',
  'America/Noronha (UTC -02:00)',
  'Atlantic/South_Georgia (UTC -02:00)',
  'Antarctica/Palmer (UTC -03:00)',
  'Antarctica/Rothera (UTC -03:00)',
  'America/Argentina/Buenos_Aires (UTC -03:00)',
  'America/Araguaina (UTC -03:00)',
  'America/Bahia (UTC -03:00)',
  'America/Belem (UTC -03:00)',
  'America/Fortaleza (UTC -03:00)',
  'America/Maceio (UTC -03:00)',
  'America/Recife (UTC -03:00)',
  'America/Santarem (UTC -03:00)',
  'America/Sao_Paulo (UTC -03:00)',
  'America/Punta_Arenas (UTC -03:00)',
  'America/Santiago (UTC -03:00)',
  'Atlantic/Stanley (UTC -03:00)',
  'America/Cayenne (UTC -03:00)',
  'America/Nuuk (UTC -03:00)',
  'America/Asuncion (UTC -03:00)',
  'America/Miquelon (UTC -03:00)',
  'America/Paramaribo (UTC -03:00)',
  'America/Montevideo (UTC -03:00)',
  'America/St_Johns (UTC -03:30)',
  'America/Anguilla (UTC -04:00)',
  'America/Antigua (UTC -04:00)',
  'America/Aruba (UTC -04:00)',
  'America/Barbados (UTC -04:00)',
  'Atlantic/Bermuda (UTC -04:00)',
  'America/La_Paz (UTC -04:00)',
  'America/Kralendijk (UTC -04:00)',
  'America/Boa_Vista (UTC -04:00)',
  'America/Campo_Grande (UTC -04:00)',
  'America/Cuiaba (UTC -04:00)',
  'America/Manaus (UTC -04:00)',
  'America/Porto_Velho (UTC -04:00)',
  'America/Blanc-Sablon (UTC -04:00)',
  'America/Glace_Bay (UTC -04:00)',
  'America/Goose_Bay (UTC -04:00)',
  'America/Halifax (UTC -04:00)',
  'America/Moncton (UTC -04:00)',
  'America/Curacao (UTC -04:00)',
  'America/Dominica (UTC -04:00)',
  'America/Santo_Domingo (UTC -04:00)',
  'America/Thule (UTC -04:00)',
  'America/Grenada (UTC -04:00)',
  'America/Guadeloupe (UTC -04:00)',
  'America/Guyana (UTC -04:00)',
  'America/Martinique (UTC -04:00)',
  'America/Montserrat (UTC -04:00)',
  'America/Puerto_Rico (UTC -04:00)',
  'America/St_Barthelemy (UTC -04:00)',
  'America/St_Kitts (UTC -04:00)',
  'America/St_Lucia (UTC -04:00)',
  'America/Marigot (UTC -04:00)',
  'America/St_Vincent (UTC -04:00)',
  'America/Lower_Princes (UTC -04:00)',
  'America/Port_of_Spain (UTC -04:00)',
  'America/Caracas (UTC -04:00)',
  'America/Tortola (UTC -04:00)',
  'America/St_Thomas (UTC -04:00)',
  'America/Nassau (UTC -05:00)',
  'America/Eirunepe (UTC -05:00)',
  'America/Rio_Branco (UTC -05:00)',
  'America/Atikokan (UTC -05:00)',
  'America/Iqaluit (UTC -05:00)',
  'America/Toronto (UTC -05:00)',
  'America/Cayman (UTC -05:00)',
  'Pacific/Easter (UTC -05:00)',
  'America/Bogota (UTC -05:00)',
  'America/Havana (UTC -05:00)',
  'America/Guayaquil (UTC -05:00)',
  'America/Port-au-Prince (UTC -05:00)',
  'America/Jamaica (UTC -05:00)',
  'America/Cancun (UTC -05:00)',
  'America/Panama (UTC -05:00)',
  'America/Lima (UTC -05:00)',
  'America/Grand_Turk (UTC -05:00)',
  'America/Detroit (UTC -05:00)',
  'America/Indiana/Indianapolis (UTC -05:00)',
  'America/Indiana/Marengo (UTC -05:00)',
  'America/Indiana/Petersburg (UTC -05:00)',
  'America/Indiana/Vevay (UTC -05:00)',
  'America/Indiana/Vincennes (UTC -05:00)',
  'America/Indiana/Winamac (UTC -05:00)',
  'America/Kentucky/Louisville (UTC -05:00)',
  'America/Kentucky/Monticello (UTC -05:00)',
  'America/New_York (UTC -05:00)',
  'America/Belize (UTC -06:00)',
  'America/Rankin_Inlet (UTC -06:00)',
  'America/Regina (UTC -06:00)',
  'America/Resolute (UTC -06:00)',
  'America/Swift_Current (UTC -06:00)',
  'America/Winnipeg (UTC -06:00)',
  'America/Costa_Rica (UTC -06:00)',
  'Pacific/Galapagos (UTC -06:00)',
  'America/El_Salvador (UTC -06:00)',
  'America/Guatemala (UTC -06:00)',
  'America/Tegucigalpa (UTC -06:00)',
  'America/Bahia_Banderas (UTC -06:00)',
  'America/Chihuahua (UTC -06:00)',
  'America/Matamoros (UTC -06:00)',
  'America/Merida (UTC -06:00)',
  'America/Mexico_City (UTC -06:00)',
  'America/Monterrey (UTC -06:00)',
  'America/Ojinaga (UTC -06:00)',
  'America/Managua (UTC -06:00)',
  'America/Chicago (UTC -06:00)',
  'America/Indiana/Knox (UTC -06:00)',
  'America/Indiana/Tell_City (UTC -06:00)',
  'America/Menominee (UTC -06:00)',
  'America/North_Dakota/Beulah (UTC -06:00)',
  'America/North_Dakota/Center (UTC -06:00)',
  'America/North_Dakota/New_Salem (UTC -06:00)',
  'America/Cambridge_Bay (UTC -07:00)',
  'America/Creston (UTC -07:00)',
  'America/Dawson (UTC -07:00)',
  'America/Dawson_Creek (UTC -07:00)',
  'America/Edmonton (UTC -07:00)',
  'America/Fort_Nelson (UTC -07:00)',
  'America/Inuvik (UTC -07:00)',
  'America/Whitehorse (UTC -07:00)',
  'America/Yellowknife (UTC -07:00)',
  'America/Ciudad_Juarez (UTC -07:00)',
  'America/Hermosillo (UTC -07:00)',
  'America/Mazatlan (UTC -07:00)',
  'America/Boise (UTC -07:00)',
  'America/Denver (UTC -07:00)',
  'America/Phoenix (UTC -07:00)',
  'America/Vancouver (UTC -08:00)',
  'America/Tijuana (UTC -08:00)',
  'Pacific/Pitcairn (UTC -08:00)',
  'America/Los_Angeles (UTC -08:00)',
  'Pacific/Gambier (UTC -09:00)',
  'America/Anchorage (UTC -09:00)',
  'America/Juneau (UTC -09:00)',
  'America/Metlakatla (UTC -09:00)',
  'America/Nome (UTC -09:00)',
  'America/Sitka (UTC -09:00)',
  'America/Yakutat (UTC -09:00)',
  'Pacific/Marquesas (UTC -09:30)',
  'Pacific/Rarotonga (UTC -10:00)',
  'Pacific/Tahiti (UTC -10:00)',
  'America/Adak (UTC -10:00)',
  'Pacific/Honolulu (UTC -10:00)',
  'Pacific/Pago_Pago (UTC -11:00)',
  'Pacific/Niue (UTC -11:00)',
  'Pacific/Midway (UTC -11:00)',
]
