import { Exams, Subject } from '@/legacy/svgs/eb-admin/dashboard'

export const ALL_ORGANIZATIONS = { label: 'ebAdmin.dashboard.allOrganizations', value: 0 }
export const LIMIT = 10

export const statisticsMap = {
  exams: {
    Icon: () => <Exams />,
    label: 'dashboard.exams',
    backgroundIcon: 'rgba(155,0,175, 0.15)',
  },
  themes: {
    Icon: () => <Subject />,
    label: 'dashboard.themes.title',
    backgroundIcon: 'rgba(39,168,255, 0.15)',
  }
}
