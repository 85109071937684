import type { Action } from 'redux'

export interface MessageThreadsDrawerState {
  open: boolean
}

export enum MESSAGE_THREADS_DRAWER_TYPES {
  OPEN = 'TEACHER/BULLETIN/MESSAGE_THREADS_DRAWER/OPEN',
  CLOSE = 'TEACHER/BULLETIN/MESSAGE_THREADS_DRAWER/CLOSE',
}

export type MessageThreadDrawerActions =
  | Action<MESSAGE_THREADS_DRAWER_TYPES.OPEN>
  | Action<MESSAGE_THREADS_DRAWER_TYPES.CLOSE>
