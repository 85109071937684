/* eslint-disable no-case-declarations */
import type { Reducer } from 'redux'
import { Redux } from '@/legacy/redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import {
  GET_CLASSROOM_ATTENDANCES_CONFIG_FAILURE,
  GET_CLASSROOM_ATTENDANCES_CONFIG_REQUEST,
  GET_CLASSROOM_ATTENDANCES_CONFIG_SUCCESS,
} from '@/sections/attendances/config/types/apiInteractions'
import * as Types from '@/sections/attendances/records/types/mainView'
import type { AttendancesSubject } from '@/sections/attendances/config/types/entities'
import { monday, sunday } from '@/sections/attendances/records/utils'
import {
  GET_CLASSROOM_ROLE_FAILURE,
  GET_CLASSROOM_ROLE_REQUEST,
  GET_CLASSROOM_ROLE_SUCCESS,
} from '@/legacy/redux/types/classrooms'
import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { ROLE } from '@aula/config'

const initialState: Types.AttendancesRecordsMainViewState = {
  loadingConfig: false,
  error: '',
  startDate: monday,
  endDate: sunday,
  config: {
    id: 0,
    classroomId: 0,
    days: [],
    dayOffs: [],
    statuses: [],
    generalAttendances: true,
    attendancesBySubject: false,
  },
  configFetched: false,
  classroomStudents: [],
  loadingClassroomStudents: false,
  studentAttendances: [],
  loadingStudentAttendances: false,
  editableDate: null,
  subjects: [],
  ownSubjects: [],
  loadingSubjects: false,
  selectedSubject: undefined,
  teacherRoleStatus: REQUEST_STATUS.IDLE,
  teacherRole: '' as ROLE,
}

const requestDestination = reduxRequestOriginMap.ATTENDANCES_RECORDS_MAIN_VIEW

const AttendancesConfigMainTab: Reducer<
  Types.AttendancesRecordsMainViewState,
  Types.AttendancesRecordsMainViewAction
> = (state = initialState, action): Types.AttendancesRecordsMainViewState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState

    case Redux.Types.Students.LIST_CLASSROOM_STUDENTS_REQUEST:
      return {
        ...state,
        loadingClassroomStudents: true,
        error: '',
      }
    case Redux.Types.Students.LIST_CLASSROOM_STUDENTS_SUCCESS:
      return {
        ...state,
        loadingClassroomStudents: false,
        classroomStudents: action.payload.students,
      }
    case Redux.Types.Students.LIST_CLASSROOM_STUDENTS_FAILURE:
      return {
        ...state,
        loadingClassroomStudents: false,
        error: action.payload.error,
      }
    case GET_CLASSROOM_ATTENDANCES_CONFIG_REQUEST:
      return {
        ...state,
        loadingConfig: true,
        error: '',
        configFetched: false,
        config: initialState.config,
        studentAttendances: initialState.studentAttendances,
      }
    case GET_CLASSROOM_ATTENDANCES_CONFIG_SUCCESS:
      const bySubject = action.payload.attendanceConfig.attendancesBySubject
      const isGeneral = action.payload.attendanceConfig.generalAttendances
      const bothConfig = bySubject && isGeneral
      let attendancesSubjects: AttendancesSubject[]
      if (bothConfig) {
        attendancesSubjects = [{ subjectId: 0, name: 'General' }, ...state.subjects]
      } else if (bySubject) {
        attendancesSubjects = state.subjects
      } else if (isGeneral) {
        attendancesSubjects = [{ subjectId: 0, name: 'General' }]
      } else {
        attendancesSubjects = []
      }
      return {
        ...state,
        loadingConfig: false,
        config: action.payload.attendanceConfig,
        configFetched: true,
        subjects: attendancesSubjects,
        selectedSubject: attendancesSubjects[0],
      }
    case GET_CLASSROOM_ATTENDANCES_CONFIG_FAILURE:
      return {
        ...state,
        loadingConfig: false,
        configFetched: true,
        config: initialState.config,
        error: action.payload.error,
      }
    case Types.GET_SUBJECTS_REQUEST:
      return { ...state, loadingSubjects: true }
    case Types.GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjects: state.subjects.concat(action.payload.subjects),
        loadingSubjects: false,
        selectedSubject: state.subjects[0],
      }
    case Types.GET_SUBJECTS_FAILURE:
      return { ...state, loadingSubjects: false }
    case Types.GET_OWN_SUBJECTS_REQUEST:
      return { ...state, loadingSubjects: true }
    case Types.GET_OWN_SUBJECTS_SUCCESS:
      return {
        ...state,
        ownSubjects: action.payload.ownSubjects,
      }
    case Types.GET_OWN_SUBJECTS_FAILURE:
      return { ...state, loadingSubjects: false }
    case Types.GET_CLASSROOM_ATTENDANCES_REQUEST:
      return {
        ...state,
        loadingStudentAttendances: true,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        editableDate: null,
      }
    case Types.GET_CLASSROOM_ATTENDANCES_SUCCESS:
      return {
        ...state,
        loadingStudentAttendances: false,
        studentAttendances: action.payload.studentAttendances,
      }
    case Types.GET_CLASSROOM_ATTENDANCES_FAILURE:
      return { ...state, loadingStudentAttendances: false, error: action.payload.error }
    case Types.UPDATE_STUDENT_ATTENDANCE_REQUEST: {
      const { studentAttendance } = action.payload

      const newAttendances = state.studentAttendances
        .filter(
          (attendance) =>
            !attendance.date.isSame(studentAttendance.date, 'day') ||
            attendance.studentId !== studentAttendance.studentId
        )
        .concat([studentAttendance])

      return {
        ...state,
        studentAttendances: newAttendances,
      }
    }
    case Types.UPDATE_STUDENT_ATTENDANCE_FAILURE: {
      const { studentAttendance } = action.payload

      return {
        ...state,
        loadingStudentAttendances: false,
        error: action.payload.error,
        studentAttendances: state.studentAttendances.filter(
          (attendance) =>
            !(
              attendance.date.isSame(studentAttendance.date, 'day') &&
              attendance.studentId === studentAttendance.studentId
            )
        ),
      }
    }
    case Types.SET_DATE_AS_EDITABLE:
      return {
        ...state,
        editableDate: action.payload.date,
      }
    case Types.SET_SELECTED_SUBJECT:
      return {
        ...state,
        selectedSubject:
          state.subjects.find((s) => action.payload.subjectId === s.subjectId) || state.subjects[0],
      }
    case GET_CLASSROOM_ROLE_REQUEST:
      return {
        ...state,
        teacherRoleStatus: REQUEST_STATUS.LOADING,
      }
    case GET_CLASSROOM_ROLE_SUCCESS:
      const { role } = action.payload
      return {
        ...state,
        teacherRoleStatus: REQUEST_STATUS.SUCCESS,
        teacherRole: role as ROLE,
      }
    case GET_CLASSROOM_ROLE_FAILURE:
      return {
        ...state,
        teacherRoleStatus: REQUEST_STATUS.FAILURE,
        error: action.payload.error,
      }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, AttendancesConfigMainTab, requestDestination)
