import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type { Either } from '@/legacy/utils/types'
import type { SubjectSubitem } from '@/sections/report-card/config/types/entities'
import type { ScoreType } from '@/sections/report-card/types/common'
import type { FindClassroomReportCardConfigSuccess } from '@/sections/report-card/grading/types/mainView'

export interface ReportCardGradingAttitudinalScoresTabState {
  error: string
  loadingScores: boolean
  studentAttitudinalScores: StudentAttitudinalScore[]
}

type ExtraConceptFields = {
  valueId: number
  score: string
}

type ExtraNumericFields = {
  value: number
}

type ExtraSubitemFields = Either<ExtraConceptFields, ExtraNumericFields>

export type StudentScoreSubitem = SubjectSubitem & ExtraSubitemFields

export interface StudentAttitudinalScore {
  studentId: number
  subjectId: number
  subjectName: string
  attitudinalScore: string
  type: ScoreType
  valueId: number
  subitems?: StudentScoreSubitem[]
}

export const GET_STUDENT_ATTITUDINAL_SCORES_REQUEST =
  'REPORT_CARD/GRADING/TABS/ATTITUDINAL_SCORES/GET_STUDENT_ATTITUDINAL_SCORES_REQUEST'
export const GET_STUDENT_ATTITUDINAL_SCORES_SUCCESS =
  'REPORT_CARD/GRADING/TABS/ATTITUDINAL_SCORES/GET_STUDENT_ATTITUDINAL_SCORES_SUCCESS'
export const GET_STUDENT_ATTITUDINAL_SCORES_FAILURE =
  'REPORT_CARD/GRADING/TABS/ATTITUDINAL_SCORES/GET_STUDENT_ATTITUDINAL_SCORES_FAILURE'

export type GetStudentAttitudinalScoresRequest = GenericActionWithType<
  typeof GET_STUDENT_ATTITUDINAL_SCORES_REQUEST
>

export type GetStudentAttitudinalScoresSuccess = GenericActionWithType<
  typeof GET_STUDENT_ATTITUDINAL_SCORES_SUCCESS,
  { studentAttitudinalScores: StudentAttitudinalScore[] }
>

export type GetStudentAttitudinalScoresFailure = GenericActionWithType<
  typeof GET_STUDENT_ATTITUDINAL_SCORES_FAILURE,
  ErrorPayload
>

export type GetStudentAttitudinalScores =
  | GetStudentAttitudinalScoresRequest
  | GetStudentAttitudinalScoresSuccess
  | GetStudentAttitudinalScoresFailure

export type ReportCardGradingAttitudinalScoresTabAction =
  | GetStudentAttitudinalScores
  | FindClassroomReportCardConfigSuccess
