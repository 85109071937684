import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type { SetField } from '@/legacy/redux/types/generic'
import type { Group, Institution } from '@/api/ted/types'

export interface GroupsMainState {
  groups: Group[]
  institutions: Institution[]
  selectedInstitution: Institution
  loadingGroups: boolean
  loadingInstitutions: boolean
  error: string
  institutionId: number
  pagination: PagesMore
}
export type PagesMore = {
  page: number
  size: number
  more: boolean
}

export const LIST_GROUPS_REQUEST = 'TED/PROGRESS/LIST_GROUPS_REQUEST'
export const LIST_GROUPS_SUCCESS = 'TED/PROGRESS/LIST_GROUPS_SUCCESS'
export const LIST_GROUPS_FAILURE = 'TED/PROGRESS/LIST_GROUPS_FAILURE'

export const LIST_INSTITUTIONS_REQUEST = 'TED/PROGRESS/LIST_INSTITUTIONS_REQUEST'
export const LIST_INSTITUTIONS_SUCCESS = 'TED/PROGRESS/LIST_INSTITUTIONS_SUCCESS'
export const LIST_INSTITUTIONS_FAILURE = 'TED/PROGRESS/LIST_INSTITUTIONS_FAILURE'

export type ListInstitutionsRequest = GenericActionWithType<typeof LIST_INSTITUTIONS_REQUEST>
export type ListInstitutionsSuccess = GenericActionWithType<
  typeof LIST_INSTITUTIONS_SUCCESS,
  { institutions: Institution[] }
>
export type ListInstitutionsFailure = GenericActionWithType<
  typeof LIST_INSTITUTIONS_FAILURE,
  ErrorPayload
>
export type ListInstitutionsAction =
  | ListInstitutionsRequest
  | ListInstitutionsSuccess
  | ListInstitutionsFailure

export type ListGroupsRequest = GenericActionWithType<
  typeof LIST_GROUPS_REQUEST,
  {
    page: number
    rowsPerPage: number
  }
>
export type ListGroupsSuccess = GenericActionWithType<
  typeof LIST_GROUPS_SUCCESS,
  { groups: Group[]; totalItems: number; more: boolean }
>
export type ListGroupsFailure = GenericActionWithType<typeof LIST_GROUPS_FAILURE, ErrorPayload>
export type ListGroupsAction = ListGroupsRequest | ListGroupsSuccess | ListGroupsFailure

export type GroupMainAction =
  | ListGroupsAction
  | ListInstitutionsAction
  | SetField<'selectedInstitution' | 'pagination', Institution | PagesMore>
