import type { Upload } from 'tus-js-client'
import type { Middleware, Dispatch } from 'redux'
import type { RootState } from '@/state/reducer'
import type { UploadVimeoActions } from '@/sections/teacher/bulletin/types/uploadVimeo'
import { UPLOAD_VIMEO_VIDEO_TYPES } from '@/sections/teacher/bulletin/types/uploadVimeo'
import { createTusUpload } from '@/sections/teacher/bulletin/operations/uploadVimeo'
import {
  uploadVimeoVideoStart,
  uploadVimeoVideoFailure,
  uploadVimeoVideoProgress,
  uploadVimeoVideoSuccess,
} from '@/sections/teacher/bulletin/actions/uploadVimeo'

// uploader instances
const uploader = new Map<symbol, Upload>()
const uploaderId = Symbol()

const onError = (dispatch: Dispatch) => (error: Error) => {
  dispatch(uploadVimeoVideoFailure(error))
}

const onSuccess = (dispatch: Dispatch) => () => {
  dispatch(uploadVimeoVideoSuccess())
}

const onProgress = (dispatch: Dispatch) => (bytesSent: number, bytesTotal: number) => {
  dispatch(uploadVimeoVideoProgress(bytesSent, bytesTotal))
}

export const uploadVimeoMiddleware: Middleware<Dispatch, RootState> =
  (store) => (next: Dispatch) => (action: UploadVimeoActions) => {
    switch (action.type) {
      case UPLOAD_VIMEO_VIDEO_TYPES.CREATE_VIDEO_SUCCESS: {
        const { file, uploadLink } = action.payload
        const upload = createTusUpload({
          file,
          fileSize: file.size,
          uploadLink: uploadLink,
          onError: onError(store.dispatch),
          onSuccess: onSuccess(store.dispatch),
          onProgress: onProgress(store.dispatch),
        })

        uploader.set(uploaderId, upload)
        store.dispatch(uploadVimeoVideoStart())
        break
      }
      case UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_START: {
        const upload = uploader.get(uploaderId)
        if (upload) {
          upload.start()
        }
        break
      }
      case UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_ABORT: {
        const upload = uploader.get(uploaderId)
        if (upload) {
          upload.abort()
          uploader.delete(uploaderId)
        }
        break
      }
      // eslint-disable-next-line no-duplicate-case
      case UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_ABORT:
      case UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_FAILURE:
      case UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_SUCCESS: {
        uploader.delete(uploaderId)
        break
      }
    }

    next(action)
  }
