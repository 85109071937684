import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { Subject } from '@/legacy/redux/types/subjects'

export interface SubjectsState {
  status: REQUEST_STATUS
  data: Subject[]
  selectedSubject: Subject
}

export enum SUBJECTS_TYPES {
  GET_SUBJECTS_REQUEST = 'STUDENT/SUMMARY/TABS/ACTIVITIES/SUBJECTS/GET_SUBJECTS_REQUEST',
  GET_SUBJECTS_SUCCESS = 'STUDENT/SUMMARY/TABS/ACTIVITIES/SUBJECTS/GET_SUBJECTS_SUCCESS',
  GET_SUBJECTS_FAILURE = 'STUDENT/SUMMARY/TABS/ACTIVITIES/SUBJECTS/GET_SUBJECTS_FAILURE',
  SET_SELECTED_SUBJECT = 'STUDENT/SUMMARY/TABS/ACTIVITIES/SUBJECTS/SET_SELECTED_SUBJECT',
  CLEAN_SUBJECTS = 'STUDENT/SUMMARY/TABS/ACTIVITIES/SUBJECTS/CLEAN_SUBJECTS',
}

// Get subjects.
export type GetSubjectsRequestAction = {
  type: SUBJECTS_TYPES.GET_SUBJECTS_REQUEST
}

export type GetSubjectsSuccessAction = {
  type: SUBJECTS_TYPES.GET_SUBJECTS_SUCCESS
  payload: {
    data: Subject[]
  }
}

export type GetSubjectsFailureAction = {
  type: SUBJECTS_TYPES.GET_SUBJECTS_FAILURE
}

export type GetSubjectsAction =
  | GetSubjectsRequestAction
  | GetSubjectsSuccessAction
  | GetSubjectsFailureAction

// Set selected subject.
export type SetSelectedSubjectAction = {
  type: SUBJECTS_TYPES.SET_SELECTED_SUBJECT
  payload: {
    newSelectedSubject: Subject
  }
}

// Clean subjects.
export type CleanSubjectsAction = {
  type: SUBJECTS_TYPES.CLEAN_SUBJECTS
}

export type SubjectsAction = GetSubjectsAction | SetSelectedSubjectAction | CleanSubjectsAction
