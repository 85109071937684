import { combineReducers } from 'redux'
import subjects from './subjects'
import periods from './periods'
import attendances from './attendances'
import config from './config'

export default combineReducers({
  subjects,
  periods,
  attendances,
  config,
})
