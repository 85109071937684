import type { AxiosResponse } from 'axios'
import type { Moment } from 'moment'
import moment from 'moment'
import type { COSMOS_KEY, ROLE } from '@aula/config'
import { url } from '@aula/config'
import type { EbRole } from '@/legacy/roles'
import { generateRandomColor } from '@aula/tools/color'
import { apiPrivate } from './..'
import type { DescriptionResponseShortener } from '@/api/types'
import type { Profile } from './organizations'
import { OrganizationsAPI } from './organizations'
import type { Student } from '@/sections/student/summary/types/common'

export const UserAPI = {
  info,
  uploadAvatar,
  getAvatar,
  updatePassword,
  getStudentInfo,
  setLastActivityAt,
  adminLogAs,
}

interface InfoData {
  description: APIInfo
}

interface APIInfo {
  id: number
  name: string
  lastName: string
  avatar: string
  sandbox: number
  organizations: APIOrganization[]
  ebRoles: EbRole[]
  dateOfBirth: string
  profile: APIProfile
}

export interface APIProfile {
  id: number
  name: string
  role: ROLE
  organizationId: number
}

interface APIOrganization {
  id: number
  name: string
  logo: string
  emblem: string
  academicPeriods: AcademicPeriod[]
  profiles: APIProfile[]
}

export interface AcademicPeriod {
  id: number
  name: string
  default: boolean
}

export interface Organization {
  id: number
  name: string
  logo: string
  emblem: string
  roles: string[]
  academicPeriods: AcademicPeriod[]
  cosmos?: COSMOS_KEY[]
  profiles: Profile[]
}

export interface Info {
  id: number
  color: string
  name: string
  lastName: string
  avatar: string
  sandbox: number
  organizations: Organization[]
  role: string
  profile: Profile
  ebRoles: EbRole[]
  dateOfBirth: Moment | null
}

/**
 * Log user in.
 *
 * @role Any
 */
function info(): Promise<Info> {
  return apiPrivate
    .get(url + '/v3/user/info')
    .then((response: AxiosResponse<InfoData>) => {
      const d = response.data.description
      // TODO move the random color generation logic to backend
      const userName = `${d.name} ${d.lastName}`
      const color = generateRandomColor(userName)
      const info: Info = {
        color,
        id: d.id,
        name: d.name,
        lastName: d.lastName,
        avatar: d.avatar,
        sandbox: d.sandbox,
        dateOfBirth: d.dateOfBirth !== '' ? moment.parseZone(d.dateOfBirth).local(true) : null,
        organizations: d.organizations.map((o) => ({
          id: o.id,
          name: o.name,
          logo: o.logo,
          emblem: o.emblem,
          roles: o.profiles.map(({ role }) => role),
          academicPeriods: o.academicPeriods,
          profiles: o.profiles.map(({ id, name, role, organizationId: organizationId }) => ({
            id,
            name,
            role,
            organizationId,
          })),
        })),
        role: d.profile.role,
        profile: {
          id: d.profile.id,
          name: d.profile.name,
          role: d.profile.role,
          organizationId: d.profile.organizationId,
        },
        ebRoles: d.ebRoles,
      }
      return info
    })
    .then((info) => {
      return Promise.all(
        info.organizations.map((o) => OrganizationsAPI.getOrganizationCosmosList(o.id))
      )
        .then((results) => {
          info.organizations.forEach((o, i) => {
            o.cosmos = results[i]
          })
          return info
        })
        .catch((err) => {
          throw err
        })
    })
    .catch((err) => {
      throw err
    })
}

function uploadAvatar(avatar: string) {
  const formData = new FormData()
  formData.append('avatar', avatar)

  return apiPrivate.put(url + `/v3/user/avatar`, formData)
}

function getAvatar() {
  return apiPrivate.get(url + `/v3/user/avatar`)
}

export interface UpdatePasswordParams {
  currentPassword: string
  newPassword: string
  passwordTypeId: number
}

function updatePassword(params: UpdatePasswordParams): Promise<void> {
  const formData = new FormData()

  formData.append('actual_password', params.currentPassword)
  formData.append('new_password', params.newPassword)
  formData.append('password_type_id', params.passwordTypeId.toString())

  return apiPrivate
    .put(url + '/v1/user/password', formData)
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => resp.data.description)
    .catch((err) => {
      throw err
    })
}

interface GetStudentInfoResponse {
  description: Student
}

function getStudentInfo(studentId: number, classroomId?: number): Promise<Student> {
  const params = {
    ...(classroomId && { classroomId }),
  }

  return apiPrivate
    .get<GetStudentInfoResponse>(url + `/v1/user/${studentId}`, { params })
    .then((resp) => resp.data.description)
    .catch((err) => err)
}

function setLastActivityAt(time: string) {
  return apiPrivate.put(url + '/v2/user/last-activity-at', { time })
}

interface AdminLogAsResponse {
  token: string
}
function adminLogAs(userId: number): Promise<AdminLogAsResponse> {
  return apiPrivate
    .post<DescriptionResponseShortener<AdminLogAsResponse>>(url + `/v1/admin/login-as/${userId}`)
    .then((response) => response.data.description)
}
