import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/eb-admin/management/types/audience'

const initialState: Types.AudienceState = {
  audiences: [],
  status: REQUEST_STATUS.IDLE,
  searchText: '',
  pagination: {
    count: 0,
    limit: 12,
    offset: 0,
    more: true,
  },
}

const audienceReducer = (
  state: Types.AudienceState = initialState,
  action: Types.AudienceAction
): Types.AudienceState => {
  switch (action.type) {
    case Types.AUDIENCE_TYPES.GET_AUDIENCES_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.AUDIENCE_TYPES.GET_AUDIENCES_SUCCESS: {
      const { audiences, count, more } = action.payload

      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        audiences,
        pagination: {
          ...state.pagination,
          count,
          more,
        },
      }
    }
    case Types.AUDIENCE_TYPES.GET_AUDIENCES_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.AUDIENCE_TYPES.SET_SEARCH_TEXT: {
      const { newText } = action.payload

      return {
        ...state,
        searchText: newText,
      }
    }
    case Types.AUDIENCE_TYPES.SET_PAGINATION_OFFSET: {
      const { newOffset } = action.payload

      return {
        ...state,
        pagination: {
          ...state.pagination,
          offset: newOffset,
        },
      }
    }
    case Types.AUDIENCE_TYPES.CLEAN_AUDIENCE: {
      return initialState
    }
    default:
      return state
  }
}

export default audienceReducer
