import type { Moment } from 'moment'
import type { AttendancesConfig } from '@/api/attendances/config'
import type { StudentAttendance } from '@/api/attendances/records'
import type { ClearState } from '@/legacy/redux/types/generic'
import type { ListClassroomStudentsAction, Student } from '@/legacy/redux/types/students'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type { GetClassroomAttendancesConfigAction } from '@/sections/attendances/config/types/apiInteractions'
import type { AttendancesSubject } from '@/sections/attendances/config/types/entities'
import type {
  GetClassroomRoleFailure,
  GetClassroomRoleRequest,
  GetClassroomRoleSuccess,
} from '@/legacy/redux/types/classrooms'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { ROLE } from '@aula/config'

export interface AttendancesRecordsMainViewState {
  error: string
  startDate: Moment
  endDate: Moment
  config: AttendancesConfig
  loadingConfig: boolean
  configFetched: boolean
  classroomStudents: Student[]
  loadingClassroomStudents: boolean
  studentAttendances: StudentAttendance[]
  loadingStudentAttendances: boolean
  editableDate: Moment | null // Used to make an past date column editable
  subjects: AttendancesSubject[]
  ownSubjects: Subject[]
  loadingSubjects: boolean
  selectedSubject?: AttendancesSubject
  teacherRoleStatus: REQUEST_STATUS
  teacherRole: ROLE
}

export interface Subject {
  id: number
  name: string
  color: string
}

export const GET_CLASSROOM_ATTENDANCES_REQUEST =
  'ATTENDANCES/RECORDS/GET_CLASSROOM_ATTENDANCES_REQUEST'
export const GET_CLASSROOM_ATTENDANCES_SUCCESS =
  'ATTENDANCES/RECORDS/GET_CLASSROOM_ATTENDANCES_SUCCESS'
export const GET_CLASSROOM_ATTENDANCES_FAILURE =
  'ATTENDANCES/RECORDS/GET_CLASSROOM_ATTENDANCES_FAILURE'

export type GetClassroomAttendancesRequest = GenericActionWithType<
  typeof GET_CLASSROOM_ATTENDANCES_REQUEST,
  { startDate: Moment; endDate: Moment; subjectId: number }
>

export type GetClassroomAttendancesSuccess = GenericActionWithType<
  typeof GET_CLASSROOM_ATTENDANCES_SUCCESS,
  { studentAttendances: StudentAttendance[] }
>

export type GetClassroomAttendancesFailure = GenericActionWithType<
  typeof GET_CLASSROOM_ATTENDANCES_FAILURE,
  ErrorPayload
>

export type GetClassroomAttendancesAction =
  | GetClassroomAttendancesRequest
  | GetClassroomAttendancesSuccess
  | GetClassroomAttendancesFailure

export const UPDATE_STUDENT_ATTENDANCE_REQUEST =
  'ATTENDANCES/RECORDS/UPDATE_STUDENT_ATTENDANCE_REQUEST'
export const UPDATE_STUDENT_ATTENDANCE_SUCCESS =
  'ATTENDANCES/RECORDS/UPDATE_STUDENT_ATTENDANCE_SUCCESS'
export const UPDATE_STUDENT_ATTENDANCE_FAILURE =
  'ATTENDANCES/RECORDS/UPDATE_STUDENT_ATTENDANCE_FAILURE'

export type UpdateStudentAttendanceRequest = GenericActionWithType<
  typeof UPDATE_STUDENT_ATTENDANCE_REQUEST,
  { studentAttendance: StudentAttendance }
>

export type UpdateStudentAttendanceSuccess = GenericActionWithType<
  typeof UPDATE_STUDENT_ATTENDANCE_SUCCESS
>

export type UpdateStudentAttendanceFailure = GenericActionWithType<
  typeof UPDATE_STUDENT_ATTENDANCE_FAILURE,
  ErrorPayload & { studentAttendance: StudentAttendance }
>

export type UpdateStudentAttendanceAction =
  | UpdateStudentAttendanceRequest
  | UpdateStudentAttendanceSuccess
  | UpdateStudentAttendanceFailure

export const SET_DATE_AS_EDITABLE = 'ATTENDANCES/RECORDS/SET_DATE_AS_EDITABLE'
export type SetDateAsEditable = GenericActionWithType<typeof SET_DATE_AS_EDITABLE, { date: Moment }>

export const GET_SUBJECTS_REQUEST = 'ATTENDANCES/RECORDS/GET_SUBJECTS_REQUEST'
export const GET_SUBJECTS_SUCCESS = 'ATTENDANCES/RECORDS/GET_SUBJECTS_SUCCESS'
export const GET_SUBJECTS_FAILURE = 'ATTENDANCES/RECORDS/GET_SUBJECTS_FAILURE'
export const GET_OWN_SUBJECTS_REQUEST = 'ATTENDANCES/RECORDS/GET_OWN_SUBJECTS_REQUEST'
export const GET_OWN_SUBJECTS_SUCCESS = 'ATTENDANCES/RECORDS/GET_OWN_SUBJECTS_SUCCESS'
export const GET_OWN_SUBJECTS_FAILURE = 'ATTENDANCES/RECORDS/GET_OWN_SUBJECTS_FAILURE'

export type GetSubjectsRequest = GenericActionWithType<typeof GET_SUBJECTS_REQUEST>
export type GetOwnSubjectsRequest = GenericActionWithType<typeof GET_OWN_SUBJECTS_REQUEST>

export type GetSubjectsSuccess = GenericActionWithType<
  typeof GET_SUBJECTS_SUCCESS,
  {
    subjects: AttendancesSubject[]
  }
>

export type GetOwnSubjectsSuccess = GenericActionWithType<
  typeof GET_OWN_SUBJECTS_SUCCESS,
  { ownSubjects: Subject[] }
>

export type GetSubjectsFailure = GenericActionWithType<typeof GET_SUBJECTS_FAILURE, ErrorPayload>
export type GetOwnSubjectsFailure = GenericActionWithType<
  typeof GET_OWN_SUBJECTS_FAILURE,
  ErrorPayload
>

export type GetSubjectsAction =
  | GetSubjectsRequest
  | GetOwnSubjectsRequest
  | GetSubjectsSuccess
  | GetOwnSubjectsSuccess
  | GetSubjectsFailure
  | GetOwnSubjectsFailure

export const SET_SELECTED_SUBJECT = 'ATTENDANCES/RECORDS/SET_SELECTED_SUBJECT'
export type SetSelectedSubject = GenericActionWithType<
  typeof SET_SELECTED_SUBJECT,
  { subjectId: number }
>

export type AttendancesRecordsMainViewAction =
  | ClearState
  | ListClassroomStudentsAction
  | GetClassroomAttendancesConfigAction
  | GetClassroomAttendancesAction
  | UpdateStudentAttendanceAction
  | SetDateAsEditable
  | GetSubjectsAction
  | SetSelectedSubject
  | GetClassroomRoleRequest
  | GetClassroomRoleSuccess
  | GetClassroomRoleFailure
