import { apiPrivate } from '..'
import { url } from '@aula/config'
import type { Pagination } from '@/sections/teacher/overview/types/overview'

export const BackpackAPI = {
  getRecordings,
}

function getRecordings(pagination?: Pagination) {
  const requestParams = new URLSearchParams()

  if (pagination?.limit) requestParams.append('limit', pagination.limit.toString())
  if (pagination?.offset) requestParams.append('offset', pagination.offset.toString())

  const request = {
    params: requestParams,
  }

  return apiPrivate.get(`${url}/v1/backpack/recordings`, request)
}
