export const START_RECORDING = 'CONFERENCE/START_RECORDING'
export const STOP_RECORDING = 'CONFERENCE/STOP_RECORDING'

export default {
  SET_ROOM: 'CONFERENCE/SET_ROOM',
  SET_ROOM_WITH_URL_PARAMS: 'CONFERENCE/SET_ROOM_WITH_URL_PARAMS',
  OPEN_AVV: 'CONFERENCE/OPEN_AVV',
  START_AVV_REQUEST: 'CONFERENCE/START_AVV_REQUEST',
  START_AVV_SUCCESS: 'CONFERENCE/START_AVV_SUCCESS',
  START_AVV_FAILURE: 'CONFERENCE/START_AVV_FAILURE',
  HAS_STARTED_REQUEST: 'CONFERENCE/HAS_STARTED_REQUEST',
  HAS_STARTED_SUCCESS: 'CONFERENCE/HAS_STARTED_SUCCESS',
  HAS_STARTED_FAILURE: 'CONFERENCE/HAS_STARTED_FAILURE',
  ADD_EVENT_REQUEST: 'CONFERENCE/ADD_EVENT_REQUEST',
  ADD_EVENT_SUCCESS: 'CONFERENCE/ADD_EVENT_SUCCESS',
  ADD_EVENT_FAILURE: 'CONFERENCE/ADD_EVENT_FAILURE',
  GET_TOKEN_REQUEST: 'CONFERENCE/GET_TOKEN_REQUEST',
  GET_TOKEN_SUCCESS: 'CONFERENCE/GET_TOKEN_SUCCESS',
  GET_TOKEN_FAILURE: 'CONFERENCE/GET_TOKEN_FAILURE',
}
