import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import type {
  NewClassroomModalActions,
  NewClassroomModalState,
} from '@/sections/principal/classroom-migration/types/newClassroomModal'
import {
  SET_OPEN,
  SET_SHIFT,
  SET_STAGE,
  SET_DIVISION,
  SET_GRADE,
  CREATE_CLASSROOM_REQUEST,
  CREATE_CLASSROOM_SUCCESS,
  CREATE_CLASSROOM_FAILURE,
} from '@/sections/principal/classroom-migration/types/newClassroomModal'

const initialState: NewClassroomModalState = {
  open: false,
  shift: '',
  stage: '',
  division: '',
  grade: '',
  loading: false,
  year: 2021,
  error: '',
}

const requestDestination = reduxRequestOriginMap.PRINCIPAL_NEW_CLASSROOM_MODAL

const MigrationClassroomsReducer = (
  state: NewClassroomModalState = initialState,
  action: NewClassroomModalActions
): NewClassroomModalState => {
  if (action.payload?.requestOrigin !== requestDestination) return state

  switch (action.type) {
    case SET_OPEN:
      return { ...state, open: action.payload.open }
    case SET_SHIFT:
      return { ...state, shift: action.payload.shift }
    case SET_STAGE:
      return { ...state, stage: action.payload.stage }
    case SET_DIVISION:
      return { ...state, division: action.payload.division }
    case SET_GRADE:
      return { ...state, grade: action.payload.grade }
    case CREATE_CLASSROOM_REQUEST:
      return { ...state, loading: true }
    case CREATE_CLASSROOM_SUCCESS:
      return initialState
    case CREATE_CLASSROOM_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default: {
      return state
    }
  }
}

export default MigrationClassroomsReducer
