import types from '@/sections/student/dashboard/types'
import { apiPrivate } from '@/api'
import { filesUrl, url } from '@aula/config'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import editorActions from '@/sections/editor/actions/editor'
import messagesActions from '@/sections/header/actions/messages'
import fileDownload from 'js-file-download'
import SaveIcon from '@mui/icons-material/Save'
import moment from 'moment'
import { getMarkAsCompletedForm } from '@/legacy/entities/students/activities'
import { API } from '@/api/lms'
import dashboardActions from './dashboard'

const actions = {
  // classroomID is kept for compatibility with existing code, should be removed when possible
  getActivity: (classroomID, activityID) => (dispatch, getState) => {
    dispatch({ type: types.ACTIVITY_REQUEST })

    return API.Activities.get(activityID)
      .then((response) => {
        const { activity } = response.data.description
        dispatch(actions.getActivitySuccess(activity))
        dispatch(editorActions.setRawEditorState(activity))
      })
      .catch((error) => {
        const notYetPublishedResponse = 'Actividad no disponible'
        const res = error.response?.data?.description ?? ''

        if (error.response.status === 404 && res.includes(notYetPublishedResponse)) {
          // this only happens if the publicationDate is after the current date
          const courseContent = getState().student.dashboard.activity.course.content
          const activity = courseContent.filter((act) => act.resourceId === activityID)[0].activity
          activity.lastSubmission = []
          dispatch(actions.getActivitySuccess(activity))
        } else {
          dispatch(actions.getActivityFailure(error))
        }
      })
  },

  getActivitySuccess: (activity) => ({
    type: types.ACTIVITY_SUCCESS,
    payload: { activity },
  }),
  getActivityFailure: (error) => ({
    type: types.ACTIVITY_FAILURE,
    payload: {
      error,
      isDeleted: error.response.status === 404,
      isActivityNotAvailableYet: error.response?.data?.code === 'U3' ?? false,
    },
  }),

  setSendAnswersModalOpen: (open) => ({
    type: types.SET_SEND_ANSWERS_MODAL_OPEN,
    payload: { open },
  }),

  setSendAnswersStepperOpen: () => ({
    type: types.SET_SEND_ANSWERS_STEPPER_OPEN,
  }),

  setSendAnswersStepperClosed: () => ({
    type: types.SET_SEND_ANSWERS_STEPPER_CLOSED,
  }),

  setAnswersStepperStep: (step) => ({
    type: types.SET_ANSWERS_STEPPER_STEP,
    payload: {
      step,
    },
  }),

  closeModal: () => (dispatch) => {
    dispatch(editorActions.clear())
    dispatch({ type: types.CLOSE_MODAL })
  },

  setOpenUnit: (openUnitId) => ({
    type: types.SET_OPEN_UNIT,
    payload: {
      openUnitId,
    },
  }),

  markAsCompleted:
    (classroomID, activityID, partial, nonClosing = false, t) =>
    async (dispatch, getState) => {
      dispatch({ type: types.MARK_AS_COMPLETED_REQUEST })

      const [form, error] = await getMarkAsCompletedForm(getState, dispatch, partial, activityID)
      if (error)
        return dispatch({
          type: types.MARK_AS_COMPLETED_FAILURE,
          payload: { error: error.toString() },
        })

      return apiPrivate
        .post(url + `/v1/submissions`, form)
        .then((response) => {
          const { activity } = response.data.description
          const name = getState().user.user.name

          dispatch(actions.markAsCompletedSuccess(activityID, nonClosing))

          const date = moment().format()
          const { selectedOrganization, selectedAcademicPeriod } = getState().user
          dispatch(
            dashboardActions.getStudentDashboard(
              selectedOrganization.id,
              selectedAcademicPeriod.id,
              date
            )
          )
          dispatch(
            dashboardActions.getDashboardV4(
              selectedOrganization.id,
              selectedAcademicPeriod.id,
              date
            )
          )

          const deadline = moment(activity.deadline).format(`DD [${t('moment.of')}] MMMM`)

          const fullNotification = messagesActions.showToast(
            'student.activity.successActivity',
            'student.activity.successActivitySubToast',
            { titleValues: { name } }
          )
          const partialNotification = messagesActions.showToast(
            'student.activity.successActivitySave',
            'student.activity.successActivitySaveSubToast',
            {
              descriptionValues: { deadline },
              error: false,
              color: '#FF6428',
              icon: SaveIcon,
            }
          )

          if (!nonClosing) partial ? dispatch(partialNotification) : dispatch(fullNotification)
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.MARK_AS_COMPLETED_FAILURE, dispatch)
        })
    },
  markAsCompletedSuccess: (activityID, nonClosing) => ({
    type: types.MARK_AS_COMPLETED_SUCCESS,
    payload: { activityID, nonClosing },
  }),

  downloadFile: (fileID, fileName) => (dispatch) => {
    dispatch({
      type: types.DOWNLOAD_FILE_REQUEST,
      payload: {
        id: fileID,
      },
    })

    const config = { responseType: 'blob' }
    return apiPrivate
      .get(filesUrl + `/v1/files/${fileID}/download`, config)
      .then((response) => {
        fileDownload(response.data, fileName)
        dispatch(actions.downloadFileSuccess())
      })
      .catch((error) => {
        handleErrorsWithAction(error, types.DOWNLOAD_FILE_FAILURE, dispatch)
      })
  },

  downloadFileSuccess: (activityID) => ({
    type: types.DOWNLOAD_FILE_SUCCESS,
    payload: { activityID },
  }),

  getCourseContent: (courseID) => (dispatch) => {
    dispatch({ type: types.GET_COURSE_CONTENT_REQUEST })

    return API.Library.getCourseContent(courseID, 'student')
      .then((response) => {
        const { content } = response.data.description
        dispatch(actions.getCourseContentSuccess(content))
      })
      .catch((error) => {
        handleErrorsWithAction(error, types.GET_COURSE_CONTENT_FAILURE, dispatch)
      })
  },
  getCourseContentSuccess: (content) => ({
    type: types.GET_COURSE_CONTENT_SUCCESS,
    payload: { content },
  }),
  setSectionModal: (open, type = '') => ({
    type: types.SET_SECTION_MODAL,
    payload: { open, type },
  }),
  setCurrentSection: (section) => ({
    type: types.SET_CURRENT_SECTION,
    payload: { section },
  }),
  setCompletedSections: (sections) => ({
    type: types.SET_COMPLETED_SECTIONS,
    payload: { sections },
  }),
  setSelectedSection: (section) => ({
    type: types.SET_SELECTED_SECTION,
    payload: { section },
  }),
}

export default actions
