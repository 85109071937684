export default {
  CLOSE_MODAL: 'DASHBOARD_TEACHER/ACTIVITY_CLOSE_MODAL',
  STUDENT_ACTIVITY_REQUEST: 'DASHBOARD_TEACHER/STUDENT_ACTIVITY_REQUEST',
  STUDENT_ACTIVITY_SUCCESS: 'DASHBOARD_TEACHER/STUDENT_ACTIVITY_SUCCESS',
  STUDENT_ACTIVITY_FAILURE: 'DASHBOARD_TEACHER/STUDENT_ACTIVITY_FAILURE',
  ACTIVITY_REQUEST: 'DASHBOARD_TEACHER/ACTIVITY_TEACHER_REQUEST',
  ACTIVITY_SUCCESS: 'DASHBOARD_TEACHER/ACTIVITY_TEACHER_SUCCESS',
  ACTIVITY_FAILURE: 'DASHBOARD_TEACHER/ACTIVITY_TEACHER_FAILURE',
  LIBRARY_ACTIVITY_REQUEST: 'DASHBOARD_TEACHER/LIBRARY_ACTIVITY_TEACHER_REQUEST',
  LIBRARY_ACTIVITY_SUCCESS: 'DASHBOARD_TEACHER/LIBRARY_ACTIVITY_TEACHER_SUCCESS',
  LIBRARY_ACTIVITY_FAILURE: 'DASHBOARD_TEACHER/LIBRARY_ACTIVITY_TEACHER_FAILURE',
  DOWNLOAD_FILE_REQUEST: 'DASHBOARD_TEACHER/DOWNLOAD_FILE_REQUEST',
  DOWNLOAD_FILE_SUCCESS: 'DASHBOARD_TEACHER/DOWNLOAD_FILE_SUCCESS',
  DOWNLOAD_FILE_FAILURE: 'DASHBOARD_TEACHER/DOWNLOAD_FILE_FAILURE',
  ROBOTS_STUDENT_PROGRESS_REQUEST: 'DASHBOARD_TEACHER/ROBOTS_STUDENT_PROGRESS_REQUEST',
  ROBOTS_STUDENT_PROGRESS_SUCCESS: 'DASHBOARD_TEACHER/ROBOTS_STUDENT_PROGRESS_SUCCESS',
  ROBOTS_STUDENT_PROGRESS_FAILURE: 'DASHBOARD_TEACHER/ROBOTS_STUDENT_PROGRESS_FAILURE',
  SET_COLLAB_MINIATURE_STARTED: 'DASHBOARD_TEACHER/SET_COLLAB_MINIATURE_STARTED',
}
