import React from 'react'
import { makeStyles } from '@mui/styles'
import { Avatar, Paper, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import classNames from 'clsx'
import renderEmoji from '@/legacy/components/v1/utils/renderEmoji'
import chroma from 'chroma-js'

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'row',
    border: '1px solid #D8DBE8',
    borderRadius: '8px',
    alignItems: 'center',
    margin: '0',
    display: 'flex',
    height: 64,
    transition: 'background-color 100ms',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
  rootDelayed: {
    background: 'rgba(244, 92, 58, 0.1)',
    border: '1px solid #F45C3A',
    '&:hover': {
      backgroundColor: 'rgba(244, 92, 58, 0.15)',
    },
  },
  avatarContainer: {
    margin: '10px 0',
    padding: '0 15px',
    borderRight: '1px solid #D8DBE8',
    width: 'fit-content',
  },
  titleContainer: {
    marginLeft: 24,
  },
  avatar: ({ avatarColor }) => ({
    background: avatarColor,
  }),
  title: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  disabled: {
    color: '#a3a7a8',
  },
  date: {
    fontSize: 14,
    lineHeight: '18px',
  },
  subjectContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    textAlign: 'right',
    flexGrow: 1,
    marginRight: 28,
  },
  subjectDot: ({ color }) => ({
    backgroundColor: color,
    width: 9,
    height: 9,
    borderRadius: 4.5,
    marginLeft: 8,
  }),
  buttonContainer: {
    marginRight: 24,
    width: 200,
    textAlign: 'right',
  },
  button: {
    background: theme.palette.primary.main,
    boxShadow: '0 0 0 1px ' + theme.palette.primary.main,
    color: '#FFF',
    textTransform: 'uppercase',
    padding: '6px 8px',
    fontSize: 14,
    lineHeight: '18px',
    '& span span svg path': {
      fill: 'white',
    },
    '&:hover': {
      background: theme.palette.secondary.main,
      boxShadow: '0 0 0 1px ' + theme.palette.secondary.main,
      color: '#FFF',
    },
    '&:active': {
      background: chroma(theme.palette.secondary.main).brighten(0.5).hex(),
      boxShadow: '0 0 0 1px ' + chroma(theme.palette.secondary.main).brighten(0.5).hex(),
      color: '#FFF',
    },
  },
  iconLeft: {
    justifyItems: 'center',
    display: 'flex',
    marginRight: 10,
  },
  buttonDisabled: {
    background: '#EDEFF8',
    opacity: 0.5,
    boxShadow: 'none',
    color: chroma(theme.palette.primary.main).brighten(0.5).hex() + ' !important',
    '& span span svg path': {
      fill: chroma(theme.palette.primary.main).brighten(0.5).hex() + ' !important',
    },
  },
}))

export default function ActivityCard(props) {
  const {
    Icon,
    iconProps: { avatarColor },
    subject: { name, color },
    title,
    description,
    completed,
    buttonLabel,
    buttonClick,
    buttonEnabled,
    delayed,
    buttonProps,
  } = props

  const { IconLeft, iconLeftClassname = '' } = buttonProps

  const classes = useStyles({ avatarColor, color })

  const disabled = !buttonEnabled || completed

  return (
    <Paper
      elevation={0}
      onClick={buttonClick}
      classes={{
        root: classNames(classes.root, { [classes.rootDelayed]: delayed }),
      }}
    >
      <div className={classes.avatarContainer}>
        <Avatar className={classes.avatar}>
          <Icon />
        </Avatar>
      </div>
      <div className={classes.titleContainer}>
        <Typography className={`${classes.title} ${completed && classes.disabled}`}>
          {title}
        </Typography>
        <Typography className={classes.date}>{renderEmoji(description)}</Typography>
      </div>
      <div className={classes.subjectContainer}>
        <Typography>{name}</Typography>
        {name && <div className={classes.subjectDot} />}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          disableRipple
          className={classNames(classes.button, { [classes.buttonDisabled]: disabled })}
          disabled={disabled}
        >
          {IconLeft && (
            <span className={classes.iconLeft}>
              <IconLeft className={iconLeftClassname} />
            </span>
          )}
          {buttonLabel}
        </Button>
      </div>
    </Paper>
  )
}

ActivityCard.defaultProps = {
  subject: {},
  completed: false,
  buttonEnabled: true,
  description: '',
  buttonProps: {},
  iconProps: {
    avatarColor: 'transparent',
  },
}
