import type { GenericActionWithType } from '@/legacy/redux/utils'
import type { CreateVideoRequest, CreateVideoSuccess, CreateVideoFailure } from './videos'

export type VideoAttachmentsState = {
  videoId: number
  attachments: VideoAttachment[]
}

export type VideoAttachErrors = {
  size?: boolean
  type?: boolean
}

export type VideoAttachment = {
  id: number
  file: File
  validation: VideoAttachmentValidation
}

export interface VideoAttachmentValidation {
  allowed: boolean
  errors: VideoAttachErrors
}

export const ATTACH_VIDEO_FILE = 'TED/VIDEOS/ATTACH/ATTACH_VIDEO_FILE'
export const REMOVE_ATTACH_VIDEO_FILE = 'TED/VIDEOS/ATTACH/REMOVE_ATTACH_VIDEO_FILE'
export const CLEAR_ATTACHMENTS_FILES = 'TED/VIDEOS/ATTACH/CLEAR_ATTACHMENTS_FILES'

export type AttachVideoFile = GenericActionWithType<typeof ATTACH_VIDEO_FILE, VideoAttachment>

export type RemoveAttachVideoFile = GenericActionWithType<
  typeof REMOVE_ATTACH_VIDEO_FILE,
  { id: number }
>

export type ClearAttachmentsFiles = GenericActionWithType<typeof CLEAR_ATTACHMENTS_FILES>

export type VideoAttachmentsActions =
  | AttachVideoFile
  | RemoveAttachVideoFile
  | ClearAttachmentsFiles
  | CreateVideoRequest
  | CreateVideoSuccess
  | CreateVideoFailure
