import { apiPrivate } from '@/api'
import { filesUrl } from '@aula/config'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import type { AppThunk } from '@/state/thunk'
import messagesActions from '@/sections/header/actions/messages'
import moment from 'moment'
import type {
  BackpackItem,
  GetFolderItemsSuccess,
  GetRootItemsSuccess,
  RenameItemSuccess,
  SelectItem,
  DragFileSuccess,
  SetModalOpen,
  SetModalClosed,
  SetNewFilename,
  DragFolderSuccess,
  CreateFolderSuccess,
  DeleteItemSuccess,
  AddFilesToUpload,
  RemoveFileToUpload,
  GetBackpackBooksSuccess,
  GetBackpackRecordingsSuccess,
  BackpackRecording,
  ToggleRecordingOpen,
} from '@/sections/backpack/types/backpack'
import {
  GET_ROOT_ITEMS_REQUEST,
  GET_ROOT_ITEMS_SUCCESS,
  GET_ROOT_ITEMS_FAILURE,
  GET_FOLDER_ITEMS_REQUEST,
  GET_FOLDER_ITEMS_SUCCESS,
  GET_FOLDER_ITEMS_FAILURE,
  RENAME_ITEM_FAILURE,
  RENAME_ITEM_REQUEST,
  RENAME_ITEM_SUCCESS,
  SELECT_ITEM,
  DRAG_FILE_REQUEST,
  DRAG_FILE_SUCCESS,
  DRAG_FILE_FAILURE,
  SET_MODAL_OPEN,
  SET_MODAL_CLOSED,
  SET_NEW_FILENAME,
  DRAG_FOLDER_REQUEST,
  DRAG_FOLDER_SUCCESS,
  DRAG_FOLDER_FAILURE,
  CREATE_FOLDER_REQUEST,
  CREATE_FOLDER_SUCCESS,
  CREATE_FOLDER_FAILURE,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAILURE,
  ADD_FILES_TO_UPLOAD,
  REMOVE_FILE_TO_UPLOAD,
  UPLOAD_FILES_REQUEST,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_FAILURE,
  GET_BACKPACK_BOOKS_REQUEST,
  rootTypeBooks,
  GET_BACKPACK_BOOKS_SUCCESS,
  GET_BACKPACK_BOOKS_FAILURE,
  GET_BACKPACK_RECORDINGS_REQUEST,
  GET_BACKPACK_RECORDINGS_FAILURE,
  GET_BACKPACK_RECORDINGS_SUCCESS,
  TOGGLE_RECORDING_OPEN,
} from '@/sections/backpack/types/backpack'
import axios from 'axios'
import { getFileExtension } from '@/sections/editor/utils'
import type { TFunction } from 'i18next'
import { pagesToLimit } from '@/legacy/utils/generalUtils'
import { API } from '@/api/lms'

const parseModifiedDate = (date) => (date ? parseInt(moment(date).format('x')) : 0)

const checkDuplicate = (itemType: string, itemArr: BackpackItem[], key: string) => {
  let increment = 0
  let newKey = key
  let keepChecking = true
  const fileExtension = itemType === 'file' ? getFileExtension(key) : null
  const keyWithoutExtension = fileExtension
    ? key.slice(0, key.lastIndexOf(fileExtension) - 1)
    : null

  const ending = itemType === 'folder' ? '/' : ''

  const findDuplicate = (key: string) => itemArr.find((item) => item.key === key + ending)

  while (keepChecking) {
    if (!findDuplicate(newKey)) keepChecking = false
    else {
      increment += 1
      if (itemType === 'file')
        newKey = keyWithoutExtension + ' (' + increment + ').' + fileExtension
      else newKey = key + ' (' + increment + ')'
    }
  }

  return newKey + ending
}

const actions = {
  getRootItems:
    (rootType: string): AppThunk<void> =>
    (dispatch) => {
      dispatch({ type: GET_ROOT_ITEMS_REQUEST })

      const params = {
        rootType,
      }

      return apiPrivate
        .get(filesUrl + '/v1/backpack', { params })
        .then((response) => {
          const { backpack } = response.data.description

          const itemArr: BackpackItem[] = []
          let myFilesRootId = 0

          if (backpack) {
            backpack.forEach((item: BackpackItem) => {
              const modified = parseModifiedDate(item.updatedAt)
              const size = item.file.sizeKb * 1000
              const key = item.name

              const checkedKey = checkDuplicate(item.type, itemArr, key)

              const newItem = { ...item, key: checkedKey, modified, size }
              if (item.rootType === 'root-files') myFilesRootId = item.id
              itemArr.push(newItem)
            })

            if (myFilesRootId === 0) myFilesRootId = itemArr[0].parentId
          }

          dispatch(actions.getRootItemsSuccess(itemArr, myFilesRootId))
          dispatch(actions.setModalClosed())
        })
        .catch((error) => {
          handleErrorsWithAction(error, GET_ROOT_ITEMS_FAILURE, dispatch)
        })
    },

  getRootItemsSuccess: (items: BackpackItem[], myFilesRootId: number): GetRootItemsSuccess => ({
    type: GET_ROOT_ITEMS_SUCCESS,
    payload: {
      items,
      myFilesRootId,
    },
  }),
  getFolderItems:
    (parentFolder: BackpackItem): AppThunk<void> =>
    (dispatch, getState) => {
      // @ts-ignore
      const { myFiles, openFolders } = getState().backpack.backpack

      if (openFolders.indexOf(parentFolder.id) === -1) return

      dispatch({ type: GET_FOLDER_ITEMS_REQUEST })

      const params = {
        parentID: parentFolder.id,
      }

      return apiPrivate
        .get(filesUrl + '/v1/backpack', { params })
        .then((response) => {
          const { backpack } = response.data.description

          const itemArr: BackpackItem[] = []

          backpack.forEach((item: BackpackItem) => {
            const exists = myFiles.find((f: BackpackItem) => f.id === item.id)
            if (!exists) {
              const modified = parseModifiedDate(item.updatedAt)
              const size = item.file.sizeKb * 1000
              const key = parentFolder.key + item.name
              const checkedKey = checkDuplicate(item.type, itemArr, key)

              const newFile = { ...item, key: checkedKey, modified, size }
              itemArr.push(newFile)
            }
          })

          dispatch(actions.getFolderItemsSuccess(itemArr))
        })
        .catch((error) => {
          handleErrorsWithAction(error, GET_FOLDER_ITEMS_FAILURE, dispatch)
        })
    },

  getFolderItemsSuccess: (items: BackpackItem[]): GetFolderItemsSuccess => ({
    type: GET_FOLDER_ITEMS_SUCCESS,
    payload: {
      items,
    },
  }),

  renameItem: (): AppThunk<void> => (dispatch, getState) => {
    dispatch({ type: RENAME_ITEM_REQUEST })

    // @ts-ignore
    const { selectedItem, newFilename, currentFileExtension } = getState().backpack.backpack

    const newName =
      selectedItem.type === 'file' ? newFilename + '.' + currentFileExtension : newFilename

    return apiPrivate
      .put(filesUrl + `/v1/backpack/${selectedItem.id}/name?name=${newName}`)
      .then((response) => {
        const { backpackItem: backpackItem } = response.data.description
        const modified = parseModifiedDate(backpackItem.updatedAt)
        const size = selectedItem.file.sizeKb * 1000

        if (selectedItem.type === 'file') {
          const processedKey = selectedItem.key.split('/').slice(0, -1).join('/')
          const key = processedKey + (processedKey.length > 0 ? '/' : '') + backpackItem.name

          const renamedItem = { ...selectedItem, name: backpackItem.name, key, modified, size }

          dispatch(actions.renameItemSuccess(renamedItem, selectedItem.key))
          dispatch(
            messagesActions.showToast(
              'backpack.actions.changeFile',
              'backpack.actions.changeFileSubToast',
              { descriptionValues: { name: selectedItem.name, newName: newName } }
            )
          )
        } else {
          const processedKey = selectedItem.key.split('/').slice(0, -2).join('/')
          const key = processedKey + (processedKey.length > 0 ? '/' : '') + backpackItem.name + '/'

          const renamedItem = { ...selectedItem, name: backpackItem.name, key, modified, size }
          dispatch(actions.renameItemSuccess(renamedItem, selectedItem.key))
          dispatch(
            messagesActions.showToast(
              'backpack.actions.changeFolder',
              'backpack.actions.changeFolderSubToast',
              { descriptionValues: { name: selectedItem.name, newName: newName } }
            )
          )
        }
      })
      .catch((error) => {
        handleErrorsWithAction(error, RENAME_ITEM_FAILURE, dispatch)
        dispatch(
          messagesActions.showToast(
            'backpack.actions.errorRenameFile',
            'backpack.actions.errorRenameFileSubToast',
            { error: true }
          )
        )
      })
  },

  renameItemSuccess: (item: BackpackItem, oldKey: string): RenameItemSuccess => ({
    type: RENAME_ITEM_SUCCESS,
    payload: {
      item,
      oldKey,
    },
  }),

  selectItem: (item: BackpackItem): SelectItem => ({
    type: SELECT_ITEM,
    payload: {
      selectedItem: item,
    },
  }),

  dragFile:
    (oldKey: string, newKey: string, t): AppThunk<void> =>
    async (dispatch, getState) => {
      // @ts-ignore
      const { myFiles, myFilesRootId } = getState().backpack.backpack

      const currentFile = myFiles.find((file: BackpackItem) => file.key === oldKey)

      const newParentKey = newKey.substring(0, newKey.lastIndexOf('/') + 1)
      const newParent = myFiles.find((file: BackpackItem) => file.key === newParentKey)
      const newParentID = newParent ? newParent.id : myFilesRootId

      if (newParent) await dispatch(actions.getFolderItems(newParent))

      dispatch({ type: DRAG_FILE_REQUEST })

      return apiPrivate
        .put(filesUrl + `/v1/backpack/${currentFile.id}/parentID?parentID=${newParentID}`)
        .then((response) => {
          const { backpackItem: backpackItem } = response.data.description
          const modified = parseModifiedDate(backpackItem.updatedAt)
          const size = currentFile.file.sizeKb * 1000

          const key = newParentKey + currentFile.name

          // @ts-ignore
          const { myFiles: updatedFiles } = getState().backpack.backpack

          const checkedKey = checkDuplicate(backpackItem.type, updatedFiles, key)

          const renamedFile = {
            ...currentFile,
            parentId: newParentID,
            key: checkedKey,
            modified,
            size,
          }

          dispatch(actions.dragFileSuccess(renamedFile))
          dispatch(
            messagesActions.showToast(
              'backpack.actions.movedFile',
              'backpack.actions.movedFileSubToast',
              {
                descriptionValues: {
                  name: currentFile.name,
                  name2: newParent ? newParent.name : t('backpack.actions.principal'),
                },
              }
            )
          )
        })
        .catch((error) => {
          handleErrorsWithAction(error, DRAG_FILE_FAILURE, dispatch)
          dispatch(
            messagesActions.showToast(
              'backpack.actions.errorMoveFile',
              'backpack.actions.errorMoveFileSubToast',
              { error: true }
            )
          )
        })
    },

  dragFileSuccess: (file: BackpackItem): DragFileSuccess => ({
    type: DRAG_FILE_SUCCESS,
    payload: {
      file,
    },
  }),

  dragFolder:
    (oldKey: string, newKey: string, t): AppThunk<void> =>
    (dispatch, getState) => {
      dispatch({ type: DRAG_FOLDER_REQUEST })

      // @ts-ignore
      const { myFiles, myFilesRootId } = getState().backpack.backpack

      const currentFolder = myFiles.find((file: BackpackItem) => file.key === oldKey)

      const newParentKey = newKey.substring(
        0,
        newKey.lastIndexOf('/', newKey.lastIndexOf('/') - 1) + 1
      )
      const newParent = myFiles.find((file: BackpackItem) => file.key === newParentKey)
      const newParentID = newParent ? newParent.id : myFilesRootId

      return apiPrivate
        .put(filesUrl + `/v1/backpack/${currentFolder.id}/parentID?parentID=${newParentID}`)
        .then((response) => {
          const { backpackItem: backpackItem } = response.data.description
          const modified = parseModifiedDate(backpackItem.updatedAt)
          const size = currentFolder.file.sizeKb * 1000

          const key = newParentKey + currentFolder.name + '/'

          // @ts-ignore
          const { myFiles: updatedFiles } = getState().backpack.backpack

          const checkedKey = checkDuplicate(backpackItem.type, updatedFiles, key)

          const renamedItem = {
            ...currentFolder,
            parentId: newParentID,
            key: checkedKey,
            modified,
            size,
          }

          dispatch(actions.dragFolderSuccess(renamedItem, currentFolder.key))
          dispatch(
            messagesActions.showToast(
              'teacher.library.moveFolder',
              'backpack.actions.moveFolderSubToast',
              {
                descriptionValues: {
                  name: currentFolder.name,
                  name2: newParent ? newParent.name : t('backpack.actions.principal'),
                },
              }
            )
          )
        })
        .catch((error) => {
          handleErrorsWithAction(error, DRAG_FOLDER_FAILURE, dispatch)
          dispatch(
            messagesActions.showToast(
              'backpack.actions.errorMoveFolder',
              'backpack.actions.errorMoveFolderSubToast',
              { error: true }
            )
          )
        })
    },

  dragFolderSuccess: (item: BackpackItem, oldKey: 'string'): DragFolderSuccess => ({
    type: DRAG_FOLDER_SUCCESS,
    payload: {
      item,
      oldKey,
    },
  }),

  setModalOpen: (modalType: string): SetModalOpen => ({
    type: SET_MODAL_OPEN,
    payload: {
      modalType,
    },
  }),

  setModalClosed: (): SetModalClosed => ({
    type: SET_MODAL_CLOSED,
  }),

  setNewFilename: (newFilename: string): SetNewFilename => ({
    type: SET_NEW_FILENAME,
    payload: {
      newFilename,
    },
  }),

  createFolder: (): AppThunk<void> => (dispatch, getState) => {
    dispatch({ type: CREATE_FOLDER_REQUEST })

    const { backpack, user } = getState()
    // @ts-ignore
    const { newFilename, selectedItem, myFiles, myFilesRootId, openFolders } = backpack.backpack
    const {
      user: { id },
    } = user

    const body = {
      userID: id,
      name: newFilename,
      type: 'folder',
      fileID: 0,
      parentID: myFilesRootId,
    }

    if (!selectedItem || openFolders.length === 0) {
      body.parentID = myFilesRootId
      // @ts-ignore
      if (myFilesRootId === 0) body.rootType = 'root-files'
    } else if (selectedItem.type === 'folder') body.parentID = selectedItem.id
    else if (selectedItem.type === 'file') body.parentID = selectedItem.parentId

    return apiPrivate
      .post(filesUrl + '/v1/backpack', body)
      .then((response) => {
        const { backpackItem: backpackItem } = response.data.description
        const modified = parseModifiedDate(backpackItem.updatedAt)
        const size = 0

        const keyPrefix =
          body.parentID === myFilesRootId || openFolders.length === 0
            ? ''
            : selectedItem.type === 'folder'
            ? selectedItem.key
            : myFiles.find((file: BackpackItem) => file.id === selectedItem.parentId).key

        const key = keyPrefix + backpackItem.name + '/'

        const newFolder = { ...backpackItem, key, modified, size }

        dispatch(actions.createFolderSuccess(newFolder))
        dispatch(
          messagesActions.showToast('backpack.newFolder', 'backpack.actions.newFolderSubToast', {
            descriptionValues: { newFilename: newFilename },
          })
        )
      })
      .catch((error) => {
        handleErrorsWithAction(error, CREATE_FOLDER_FAILURE, dispatch)
        dispatch(
          messagesActions.showToast(
            'backpack.actions.errorFolder',
            'backpack.actions.errorFolderSubToast',
            { error: true }
          )
        )
      })
  },

  createFolderSuccess: (file: BackpackItem): CreateFolderSuccess => ({
    type: CREATE_FOLDER_SUCCESS,
    payload: {
      file,
    },
  }),

  deleteItem:
    (t): AppThunk<void> =>
    (dispatch, getState) => {
      dispatch({ type: DELETE_ITEM_REQUEST })

      // @ts-ignore
      const { selectedItem } = getState().backpack.backpack

      return apiPrivate
        .delete(filesUrl + '/v1/backpack/' + selectedItem.id)
        .then((_) => {
          dispatch(actions.deleteItemSuccess(selectedItem))
          dispatch(
            messagesActions.showToast(
              `${t('backpack.actions.deleted')} ${
                selectedItem.type === 'folder'
                  ? t('backpack.actions.folder')
                  : t('backpack.actions.file')
              }`,
              t('backpack.actions.deleteSuccess', { name: selectedItem.name })
            )
          )
        })
        .catch((error) => {
          handleErrorsWithAction(error, DELETE_ITEM_FAILURE, dispatch)
          dispatch(
            messagesActions.showToast(
              `${t('backpack.actions.errorDelete')} ${
                selectedItem.type === 'folder'
                  ? t('backpack.actions.folder')
                  : t('backpack.actions.file')
              }`,
              t('backpack.actions.deleteError'),
              { error: true }
            )
          )
        })
    },

  deleteItemSuccess: (item: BackpackItem): DeleteItemSuccess => ({
    type: DELETE_ITEM_SUCCESS,
    payload: {
      item,
    },
  }),

  addFilesToUpload: (files: File[]): AddFilesToUpload => ({
    type: ADD_FILES_TO_UPLOAD,
    payload: { files },
  }),
  removeFileToUpload: (file: File): RemoveFileToUpload => ({
    type: REMOVE_FILE_TO_UPLOAD,
    payload: { file },
  }),

  uploadFiles:
    (files: File[], _, t): AppThunk<void> =>
    (dispatch, getState) => {
      dispatch({ type: UPLOAD_FILES_REQUEST })

      const { backpack, user } = getState()
      // @ts-ignore
      const { selectedItem, myFiles, myFilesRootId, openFolders } = backpack.backpack
      const {
        user: { id },
      } = user

      const requests = files.map((file) => {
        const formData = new FormData()
        formData.append('name', file.name)
        formData.append('file', file)
        formData.append('owner_id', id.toString())
        // @ts-ignore
        formData.append('private', true)
        return apiPrivate.post(filesUrl + '/v1/files', formData).then((response) => {
          const { file } = response.data.description

          const body = {
            userID: id,
            name: file.name,
            type: 'file',
            fileID: file.id,
            parentID: myFilesRootId,
          }

          if (!selectedItem || openFolders.length === 0) {
            body.parentID = myFilesRootId
            // @ts-ignore
            if (myFilesRootId === 0) body.rootType = 'root-files'
          } else if (selectedItem.type === 'folder') body.parentID = selectedItem.id
          else if (selectedItem.type === 'file') body.parentID = selectedItem.parentId

          return apiPrivate.post(filesUrl + '/v1/backpack', body)
        })
      })
      axios
        .all(requests)
        .then(
          axios.spread((...responses) => {
            const files = responses.map((response) => {
              const { backpackItem: backpackItem } = response.data.description
              const modified = parseModifiedDate(backpackItem.updatedAt)
              const size = backpackItem.file.sizeKb * 1000

              const keyPrefix =
                !selectedItem || openFolders.length === 0
                  ? ''
                  : selectedItem.type === 'folder'
                  ? selectedItem.key
                  : myFiles.find((file: BackpackItem) => file.id === selectedItem.parentId).key

              const key = keyPrefix + backpackItem.name

              const checkedKey = checkDuplicate(backpackItem.type, myFiles, key)

              return { ...backpackItem, key: checkedKey, modified, size }
            })

            dispatch({ type: UPLOAD_FILES_SUCCESS, payload: { files } })
            dispatch(
              messagesActions.showToast(
                `${t('backpack.actions.upload')} ${
                  files.length > 1
                    ? files.length + t('backpack.actions.newFiles')
                    : t('backpack.actions.oneNewFile')
                }`,
                `${
                  files.length > 1
                    ? t('backpack.actions.uploadSuccess')
                    : t('backpack.actions.uploadFileCorrect')
                } ${t('backpack.actions.correct')}`
              )
            )
          })
        )
        .catch((error) => handleErrorsWithAction(error, UPLOAD_FILES_FAILURE, dispatch)) // TODO handle when some request succeed but others fail
    },
  getBackpackBooks: (): AppThunk => (dispatch) => {
    dispatch({ type: GET_BACKPACK_BOOKS_REQUEST })

    const params = {
      rootType: rootTypeBooks,
    }

    return apiPrivate
      .get(filesUrl + '/v1/backpack', { params })
      .then((response) => {
        const { backpack } = response.data.description

        dispatch(actions.getBackpackBooksSuccess(backpack))
      })
      .catch((error) => handleErrorsWithAction(error, GET_BACKPACK_BOOKS_FAILURE, dispatch))
  },
  getBackpackBooksSuccess: (items: BackpackItem[]): GetBackpackBooksSuccess => ({
    type: GET_BACKPACK_BOOKS_SUCCESS,
    payload: {
      items,
    },
  }),
  getBackpackRecordings:
    (pages?: { page: number; rowsPerPage: number }): AppThunk =>
    (dispatch) => {
      const pagination = pages ? pagesToLimit(pages) : undefined

      dispatch({
        type: GET_BACKPACK_RECORDINGS_REQUEST,
        payload: {
          page: pages?.page,
          rowsPerPage: pages?.rowsPerPage,
        },
      })

      return API.Backpack.getRecordings(pagination)
        .then((response) => {
          const { recordings, total } = response.data.description

          dispatch(actions.getBackpackRecordingsSuccess(recordings, total))
        })
        .catch((error) => handleErrorsWithAction(error, GET_BACKPACK_RECORDINGS_FAILURE, dispatch))
    },
  getBackpackRecordingsSuccess: (
    recordings: BackpackRecording[],
    count: number
  ): GetBackpackRecordingsSuccess => ({
    type: GET_BACKPACK_RECORDINGS_SUCCESS,
    payload: {
      recordings,
      count,
    },
  }),
  toggleRecordingOpen: (recording: BackpackRecording | null): ToggleRecordingOpen => ({
    type: TOGGLE_RECORDING_OPEN,
    payload: {
      recording,
    },
  }),
  setRecordingWatched:
    (activityID: number): AppThunk =>
    (dispatch) => {
      API.AVV.setRecordingWatched(activityID)
    },
}

export default actions
