import React from 'react'
import { states } from '@/legacy/components/v2/ribbons/states'
import ActivityRibbonBase from './activityRibbonBase'
import { activityRibbonMap } from '@/legacy/components/v2/ribbons/types'

export default function CollabMiniatureRibbon(props) {
  const { 'collab-miniature': collabMiniature } = activityRibbonMap
  return (
    <ActivityRibbonBase
      Icon={collabMiniature.icon}
      type={collabMiniature.type}
      hideProgress
      {...props}
    />
  )
}

CollabMiniatureRibbon.defaultProps = {
  subject: {},
  state: states.DEFAULT,
  buttonEnabled: true,
  buttonLabel: 'menuOptions.watch',
  buttonProps: {},
  calendarLabel: 'student.utils.transmit',
  messages: 0,
}
