import type {
  Filter,
  OrganizationOption,
  Pagination,
  Params,
  SearchFilters,
  Sorter,
} from './common'
import type moment from 'moment'
import type * as booksTypes from '@/sections/content-creator/books/types/books'

export const SET_ORGANIZATION = 'EB_ADMIN/BOOK_LICENSES/SET_ORGANIZATION'
export const SET_RESOURCE_ID = 'EB_ADMIN/BOOK_LICENSES/SET_RESOURCE_ID'
export const SET_EXPIRY = 'EB_ADMIN/BOOK_LICENSES/SET_EXPIRY'
export const SET_AMOUNT = 'EB_ADMIN/BOOK_LICENSES/SET_AMOUNT'
export const ORGANIZATION_REQUEST = 'EB_ADMIN/BOOK_LICENSES/ORGANIZATION_REQUEST'
export const ORGANIZATION_SUCCESS = 'EB_ADMIN/BOOK_LICENSES/ORGANIZATION_SUCCESS'
export const ORGANIZATION_FAILURE = 'EB_ADMIN/BOOK_LICENSES/ORGANIZATION_FAILURE'
export const SET_OPEN = 'EB_ADMIN/BOOK_LICENSES/SET_OPEN'
export const CREATE_BOOK_LICENSE_REQUEST = 'EB_ADMIN/BOOK_LICENSES/CREATE_BOOK_LICENSE_REQUEST'
export const CREATE_BOOK_LICENSE_SUCCESS = 'EB_ADMIN/BOOK_LICENSES/CREATE_BOOK_LICENSE_SUCCESS'
export const CREATE_BOOK_LICENSE_FAILURE = 'EB_ADMIN/BOOK_LICENSES/CREATE_BOOK_LICENSE_FAILURE'
export const MODIFY_BOOK_LICENSE_REQUEST = 'EB_ADMIN/BOOK_LICENSES/MODIFY_BOOK_LICENSE_REQUEST'
export const MODIFY_BOOK_LICENSE_SUCCESS = 'EB_ADMIN/BOOK_LICENSES/MODIFY_BOOK_LICENSE_SUCCESS'
export const MODIFY_BOOK_LICENSE_FAILURE = 'EB_ADMIN/BOOK_LICENSES/MODIFY_BOOK_LICENSE_FAILURE'
export const DELETE_BOOK_LICENSE_REQUEST = 'EB_ADMIN/BOOK_LICENSES/DELETE_BOOK_LICENSE_REQUEST'
export const DELETE_BOOK_LICENSE_SUCCESS = 'EB_ADMIN/BOOK_LICENSES/DELETE_BOOK_LICENSE_SUCCESS'
export const DELETE_BOOK_LICENSE_FAILURE = 'EB_ADMIN/BOOK_LICENSES/DELETE_BOOK_LICENSE_FAILURE'
export const GET_BOOK_LICENSES_REQUEST = 'EB_ADMIN/BOOK_LICENSES/GET_BOOK_LICENSES_REQUEST'
export const GET_BOOK_LICENSES_SUCCESS = 'EB_ADMIN/BOOK_LICENSES/GET_BOOK_LICENSES_SUCCESS'
export const GET_BOOK_LICENSES_FAILURE = 'EB_ADMIN/BOOK_LICENSES/GET_BOOK_LICENSES_FAILURE'
export const SET_SORTER = 'EB_ADMIN/BOOK_LICENSES/SET_SORTER'
export const SET_FILTER = 'EB_ADMIN/BOOK_LICENSES/SET_FILTER'
export const SET_PAGINATION = 'EB_ADMIN/BOOK_LICENSES/SET_PAGINATION'
export const CLEAR_PARAMS = 'EB_ADMIN/BOOK_LICENSES/CLEAR_PARAMS'
export const SET_DELETE_MODAL_OPEN = 'EB_ADMIN/BOOK_LICENSES/SET_DELETE_MODAL_OPEN'

export type LicenseStock = {
  id: number
  createdAt: moment.Moment | null
  resourceId: number
  resourceType: string
  organizationId: number
  expiry: moment.Moment
  amount: number
  remaining: number
  title: string
}

export interface OrganizationRequest {
  type: typeof ORGANIZATION_REQUEST
  payload: {
    index?: number
    lastSearchID: number
  }
}

export interface OrganizationSuccess {
  type: typeof ORGANIZATION_SUCCESS
  payload: {
    index?: number
    organizationOptions: OrganizationOption[]
  }
}

export interface OrganizationFailure {
  type: typeof ORGANIZATION_FAILURE
  payload: {
    error: string
  }
}

export interface SetOrganization {
  type: typeof SET_ORGANIZATION
  payload: {
    index?: number
    organization: OrganizationOption
  }
}

export interface SetResourceID {
  type: typeof SET_RESOURCE_ID
  payload: {
    resourceID: number
  }
}

export interface SetExpiry {
  type: typeof SET_EXPIRY
  payload: {
    expiry: moment.Moment
  }
}

export interface SetAmount {
  type: typeof SET_AMOUNT
  payload: {
    amount: number
  }
}

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    open: boolean
    bookLicense: LicenseStock
  }
}

export interface ClearParams {
  type: typeof CLEAR_PARAMS
}

export interface CreateBookLicenseRequest {
  type: typeof CREATE_BOOK_LICENSE_REQUEST
}

export interface CreateBookLicenseSuccess {
  type: typeof CREATE_BOOK_LICENSE_SUCCESS
  payload: {
    bookLicense: LicenseStock
  }
}

export interface CreateBookLicenseFailure {
  type: typeof CREATE_BOOK_LICENSE_FAILURE
  payload: {
    error: string
  }
}

export interface ModifyBookLicenseRequest {
  type: typeof MODIFY_BOOK_LICENSE_REQUEST
}

export interface ModifyBookLicenseSuccess {
  type: typeof MODIFY_BOOK_LICENSE_SUCCESS
  payload: {
    bookLicense: LicenseStock
  }
}

export interface ModifyBookLicenseFailure {
  type: typeof MODIFY_BOOK_LICENSE_FAILURE
  payload: {
    error: string
  }
}

export interface DeleteBookLicenseRequest {
  type: typeof DELETE_BOOK_LICENSE_REQUEST
}

export interface DeleteBookLicenseSuccess {
  type: typeof DELETE_BOOK_LICENSE_SUCCESS
}

export interface DeleteBookLicenseFailure {
  type: typeof DELETE_BOOK_LICENSE_FAILURE
  payload: {
    error: string
  }
}

export interface GetBookLicensesRequest {
  type: typeof GET_BOOK_LICENSES_REQUEST
}

export interface GetBookLicensesSuccess {
  type: typeof GET_BOOK_LICENSES_SUCCESS
  payload: {
    bookLicenses: LicenseStock[]
    total: number
    params: Params
  }
}

export interface GetBookLicensesFailure {
  type: typeof GET_BOOK_LICENSES_FAILURE
  payload: {
    error: string
  }
}

export interface SetSorter {
  type: typeof SET_SORTER
  payload: {
    sorter: Sorter
  }
}

export interface SetFilter {
  type: typeof SET_FILTER
  payload: {
    filter: Filter
  }
}

export interface SetPagination {
  type: typeof SET_PAGINATION
  payload: {
    pagination: Pagination
  }
}

export interface SetDeleteModalOpen {
  type: typeof SET_DELETE_MODAL_OPEN
  payload: {
    deleteModalOpen: boolean
    bookLicense: LicenseStock
  }
}

export interface BookLicensesState {
  organizationOptions: OrganizationOption[]
  open: boolean
  lastSearchID: number
  bookLicenses: LicenseStock[]
  error: string
  loading: boolean
  loadingLicenses: boolean
  loadingOrganizations: boolean
  loadingBooks: boolean
  params: Params
  total: number
  sorter: Sorter
  filter: Filter
  searchFilters: SearchFilters[]
  pagination: Pagination
  bookLicense: LicenseStock
  loadingBookLicense: boolean
  deleteModalOpen: boolean
  books: booksTypes.Book[]
}

export type BookLicensesActions =
  | GetBookLicensesRequest
  | GetBookLicensesSuccess
  | GetBookLicensesFailure
  | ClearParams
  | SetSorter
  | SetFilter
  | SetPagination
  | SetOpen
  | OrganizationRequest
  | OrganizationSuccess
  | OrganizationFailure
  | SetOrganization
  | SetResourceID
  | SetExpiry
  | SetAmount
  | SetDeleteModalOpen
  | CreateBookLicenseRequest
  | CreateBookLicenseSuccess
  | CreateBookLicenseFailure
  | ModifyBookLicenseRequest
  | ModifyBookLicenseSuccess
  | ModifyBookLicenseFailure
  | DeleteBookLicenseRequest
  | DeleteBookLicenseSuccess
  | DeleteBookLicenseFailure
  | booksTypes.GetOrganizationBooksRequest
  | booksTypes.GetOrganizationBooksSuccess
  | booksTypes.GetOrganizationBooksFailure
