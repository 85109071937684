import * as React from 'react'
import type { SVGProps } from 'react'
const SvgChoice = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={3.938} cy={4.969} r={0.938} fill="#42B3FF" />
    <circle cx={3.938} cy={8.094} r={0.938} fill="#42B3FF" />
    <circle cx={3.938} cy={11.219} r={0.938} fill="#42B3FF" />
    <path
      stroke="#42B3FF"
      strokeWidth={1.875}
      strokeLinecap="round"
      d="M7.063 4.969h5M7.063 8.094h5M7.063 11.219h5"
    />
  </svg>
)
export default SvgChoice
