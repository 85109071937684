import { SetField } from "@/legacy/redux/types/generic"
import { ErrorPayload, GenericActionWithType, GenericPagination } from "@/legacy/redux/utils"
import { Pages } from "@/sections/content-creator/dashboard/types/dashboard"
import { ThemeOptions } from "@/sections/management/types/addEditExamModal"
import { Game, GetGamesAction } from "@/sections/management/types/customizeThemeModal"

export type GetThemesParams = Partial<{
  limit: number
  page: number
  search: string
}>

export type ThemesDrawerState = {
  loadingThemes: boolean
  loadingGames: boolean
  pagination: Pages
  themes: ThemeOptions[]
  error: string
  selected: number
  gameDetails: Game[],
  search: string,
}

export const LIST_REQUEST = 'THEMES/LIST_REQUEST'
export const LIST_SUCCESS = 'THEMES/LIST_SUCCESS'
export const LIST_FAILURE = 'THEMES/LIST_FAILURE'

export type ListRequest = GenericActionWithType<typeof LIST_REQUEST, GenericPagination & { search: string }>

export type ListSuccess = GenericActionWithType<
  typeof LIST_SUCCESS,
  { themes: ThemeOptions[], count: number }
>

export type ListFailure = GenericActionWithType<
  typeof LIST_FAILURE,
  ErrorPayload
>

export type ListAction = ListRequest | ListSuccess | ListFailure

export type ThemesDrawerAction =
  | ListAction 
  | SetField<'selected', number>
  | GetGamesAction
