// @ts-nocheck
import types from '@/sections/teacher/dashboard/types'
import { apiPrivate } from '@/api'
import { url, searchUrl } from '@aula/config'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import { addNewCall, getLastCall, TEACHER_ACTIVITIES, TEACHER_DASHBOARD } from '@/legacy/debounce'
import { defaultClassroomOption, getTimeRange, TABS } from '@/sections/teacher/utils/activities'
import { LIMIT } from '@/sections/teacher/dashboard/constants'

let searchClassroomsTimer
let searchTimer

export const parseClassroomOption = (classroom) => ({
  label: `${classroom.stage ? classroom.stage + ' - ' : ''} ${classroom.grade} ${
    classroom.division
  } ${classroom.shift} - ${classroom.organization.name}`,
  value: classroom.id,
})

const actions = {
  getClassrooms: () => (dispatch, getState) => {
    const orgId = getState().user.selectedOrganization.id
    const aPeriodId = getState().user.selectedAcademicPeriod.id
    const q = `organization_id=${orgId}&academic_period_id=${aPeriodId}`
    apiPrivate(`${url}/v3/dashboard/teacher?${q}`)
      .then((response) => {
        const { classrooms } = response.data.description
        dispatch(actions.getDashboardSuccess(classrooms))
      })
      .catch((error) => {
        handleErrorsWithAction(error, types.DASHBOARD_FAILURE, dispatch)
      })
  },
  // TODO remove unused old dashboard action
  getDashboard:
    (organizationID, academicPeriodID, silentLoading = false) =>
    (dispatch) => {
      const call = addNewCall(TEACHER_DASHBOARD)
      dispatch({ type: types.DASHBOARD_REQUEST, payload: { silentLoading } })

      const q = `organization_id=${organizationID}&academic_period_id=${academicPeriodID}`
      return apiPrivate
        .get(url + `/v3/dashboard/teacher?${q}`)
        .then((response) => {
          if (call !== getLastCall(TEACHER_DASHBOARD)) return
          const data = response.data.description
          const { classrooms } = data
          dispatch(actions.getDashboardSuccess(classrooms))
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.DASHBOARD_FAILURE, dispatch)
        })
    },

  getDashboardSuccess: (classrooms) => ({
    type: types.DASHBOARD_SUCCESS,
    payload: { classrooms },
  }),

  getDashboardV4: (organizationID, academicPeriodID, date) => async (dispatch) => {
    dispatch({ type: types.DASHBOARD_V4_REQUEST })

    try {
      const response = await apiPrivate.get(
        `${url}/v4/dashboard/teacher?organization_id=${organizationID}&academic_period_id=${academicPeriodID}&date=${date}`
      )
      const { description } = response.data
      dispatch({
        type: types.DASHBOARD_V4_SUCCESS,
        payload: description,
      })
    } catch (error) {
      handleErrorsWithAction(error, types.DASHBOARD_V4_FAILURE, dispatch)
    }
  },

  getActivitiesRefresher:
    (options = {}) =>
    (dispatch, getState) => {
      const { tab, selectedClassroom, ...otherProps } = getState().teacher.dashboard.dashboard
      const tabsLength = otherProps[tab].length
      const limit = tabsLength > LIMIT ? tabsLength : LIMIT

      dispatch(
        actions.getActivities(
          tab,
          selectedClassroom.value,
          { offset: 0, limit },
          {
            silentLoading: true,
            doNotSetPagination: true,
            shouldOverrideActivities: true,
            ...options,
          }
        )
      )
    },

  getActivities:
    (tab, classroomID = 0, pagination = {}, options = {}) =>
    (dispatch, getState) => {
      const { offset = 0, limit = LIMIT } = pagination
      const {
        silentLoading = false,
        reset = false,
        doNotSetPagination = false,
        shouldOverrideActivities = false,
      } = options

      const call = addNewCall(TEACHER_ACTIVITIES)

      dispatch({ type: types.ACTIVITIES_REQUEST, payload: { silentLoading, tab, reset } })

      const { from, to } = getTimeRange(tab)

      const params = {
        fromDate: from,
        toDate: to,
        classroom: classroomID,
        offset: offset,
        limit,
        orderBy: 'date',
        orderDir: tab === TABS.FUTURE ? 'asc' : 'desc',

        // It'd be better if these two came as parameters of this function, but
        // I found it incredibly difficult to change without being certain that
        // I wasn't breaking something.
        organizationId: getState().user.selectedOrganization.id,
        academicPeriodId: getState().user.selectedAcademicPeriod.id,
      }

      return apiPrivate
        .get(url + '/v1/dashboard/teacher/activities', { params })
        .then((response) => {
          if (call !== getLastCall(TEACHER_ACTIVITIES)) return

          const { activities, total } = response.data.description

          const today = tab === TABS.TODAY ? activities : null
          const future = tab === TABS.FUTURE ? activities : null
          const passed = tab === TABS.PASSED ? activities : null

          dispatch(
            actions.getActivitiesSuccess({
              tab,
              today,
              future,
              passed,
              offset,
              doNotSetPagination,
              shouldOverrideActivities,
              total,
            })
          )
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.DASHBOARD_FAILURE, dispatch)
        })
    },

  getActivitiesSuccess: (payload) => ({
    type: types.ACTIVITIES_SUCCESS,
    payload,
  }),

  setSelectedClassroom: (classroom) => (dispatch, getState) => {
    if (!classroom) classroom = defaultClassroomOption
    dispatch({ type: types.SET_CLASSROOM, payload: { classroom: classroom } })

    const { tab } = getState().teacher.dashboard.dashboard
    dispatch(actions.getActivities(tab, classroom.value, { offset: 0, limit: LIMIT }))
  },

  resetClassroom: () => ({ type: types.RESET_CLASSROOM }),

  getClassroomOptions: (search) => (dispatch) => {
    if (searchClassroomsTimer) clearTimeout(searchClassroomsTimer)
    searchClassroomsTimer = setTimeout(() => {
      dispatch(actions.getClassroomOptionsCall(search))
    }, 250)
  },

  getClassroomOptionsCall: (search) => (dispatch, getState) => {
    const params = { params: { limit: 80, search } }

    const lastSearchID = new Date().getMilliseconds()

    dispatch({ type: types.CLASSROOMS_REQUEST, payload: { lastSearchID } })

    return apiPrivate
      .get(url + '/v1/classrooms/teacher', params)
      .then((response) => {
        if (lastSearchID !== getState().teacher.dashboard.dashboard.lastClassroomID) return

        const classrooms = response.data.description.classrooms.map((classroom) =>
          parseClassroomOption(classroom)
        )

        dispatch(actions.getClassroomOptionsSuccess(classrooms))
      })
      .catch((error) => {
        handleErrorsWithAction(error, types.CLASSROOMS_FAILURE, dispatch)
      })
  },

  getClassroomOptionsSuccess: (classroomOptions) => ({
    type: types.CLASSROOMS_SUCCESS,
    payload: { classroomOptions },
  }),

  toggleSearchBar: (open = false) => ({
    type: types.TOGGLE_SEARCH_BAR,
    payload: { open },
  }),
  setSearch: (search) => ({
    type: types.SET_SEARCH,
    payload: { search },
  }),

  searchActivities: (search) => (dispatch) => {
    if (searchTimer) clearTimeout(searchTimer)
    searchTimer = setTimeout(() => dispatch(actions.searchActivitiesCall(search)), 250)
  },

  searchActivitiesCall: (search) => (dispatch, getState) => {
    const lastSearchID = new Date().getMilliseconds()
    const call = addNewCall(types.SEARCH_REQUEST)

    dispatch({ type: types.SEARCH_REQUEST, payload: { lastSearchID } })

    const orgID = getState().user.selectedOrganization.id
    const body = {
      term: search,
      orgId: orgID.toString(),
      role: 'teacher',
      subgroups: [],
    }

    return apiPrivate
      .post(searchUrl + '/activities', body)
      .then((response) => {
        if (lastSearchID !== getState().teacher.dashboard.dashboard.lastSearchID) return
        if (call !== getLastCall(types.SEARCH_REQUEST)) return

        dispatch(actions.searchActivitiesSuccess(response.data))
      })
      .catch((error) => {
        handleErrorsWithAction(error, types.SEARCH_FAILURE, dispatch)
      })
  },

  searchActivitiesSuccess: (activities) => ({
    type: types.SEARCH_SUCCESS,
    payload: { activities },
  }),
  openModalConfirmCertificate: (studentId) => ({
    type: types.MODAL_CONFIRM_CERTIFICATE,
    payload: { studentId },
  }),

  closeModalConfirmCertificate: () => ({
    type: types.MODAL_CLOSE_CONFIRM_CERTIFICATE,
  }),
}

export default actions
