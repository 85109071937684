import React from 'react'
import { states } from '@/legacy/components/v2/ribbons/states'
import ActivityRibbonBase from './activityRibbonBase'
import {
  activityRibbonMap,
  ACTIVITY_KEY_GAMES_ACTIVITY,
} from '@/legacy/components/v2/ribbons/types'

export default function GamesRibbon(props) {
  const games = activityRibbonMap[ACTIVITY_KEY_GAMES_ACTIVITY]
  return <ActivityRibbonBase Icon={games.icon} type={games.type} {...props} />
}

GamesRibbon.defaultProps = {
  subject: {},
  state: states.DEFAULT,
  buttonEnabled: true,
  buttonLabel: 'menuOptions.watch',
  buttonProps: {},
  calendarLabel: 'components.ribbon.student.deliveryDate',
  messages: 0,
}
