import type { Moment } from 'moment'
import type { AttendancesDay } from '@/api/attendances/config'
import type { SetField } from '@/legacy/redux/types/generic'
import type { GenericActionWithType } from '@/legacy/redux/utils'

export interface AttendancesConfigAbsencesModalState {
  open: boolean
  previousReasonName: string
  newReasonName: string
  dates: Moment[]
  disabledDates: Moment[]
  disabledWeekDays: AttendancesDay[]
}

export const SET_OPEN = 'ATTENDANCES/CONFIG/ABSENCES_MODAL/SET_OPEN'
export type SetOpen = GenericActionWithType<
  typeof SET_OPEN,
  {
    dates: Moment[]
    disabledDates: Moment[]
    disabledWeekDays: AttendancesDay[]
    absenceReason: string
  }
>

export const CONFIRM_DATES = 'ATTENDANCES/CONFIG/ABSENCES_MODAL/CONFIRM_DATES'
export type ConfirmDates = GenericActionWithType<
  typeof CONFIRM_DATES,
  { dates: Moment[]; newReasonName: string; previousReasonName: string }
>

export const UPDATE_DATES = 'ATTENDANCES/CONFIG/ABSENCES_MODAL/UPDATE_DATES'
export type UpdateDates = GenericActionWithType<typeof UPDATE_DATES, { dates: Moment[] }>

export type AttendancesConfigAbsencesModalAction =
  | SetOpen
  | ConfirmDates
  | UpdateDates
  | SetField<'open' | 'newReason', false | string>
