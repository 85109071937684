import * as React from 'react'
import type { SVGProps } from 'react'
const SvgOralExam = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="oral-exam-svg"
    {...props}
  >
    <rect opacity={0.15} width={56} height={56} rx={6} fill="#4F36D6" />
    <g className="content">
      <path
        className="speech-bubble"
        d="M28 12.641c-8.437 0-15.277 6.84-15.277 15.277 0 3.801 1.393 7.276 3.69 9.95A10.397 10.397 0 0 1 12.79 42.8c1.052.36 2.18.558 3.354.558 2.01 0 3.884-.573 5.474-1.563A15.213 15.213 0 0 0 28 43.194c8.437 0 15.277-6.84 15.277-15.276 0-8.437-6.84-15.277-15.277-15.277Z"
        fill="#2F2565"
      />
      <path
        className="line short-line"
        d="M32.805 34.208H22.486c-.464 0-.84-.448-.84-1 0-.553.376-1 .84-1h10.32c.464 0 .84.447.84 1 0 .552-.377 1-.84 1ZM32.805 23.974H22.486c-.464 0-.84-.448-.84-1 0-.553.376-1 .84-1h10.32c.464 0 .84.447.84 1 0 .552-.377 1-.84 1Z"
        fill="#fff"
      />
      <path
        className="line long-line"
        d="M36.457 29.11H19.205c-.482 0-.874-.447-.874-1 0-.552.392-1 .874-1h17.252c.483 0 .874.448.874 1 0 .553-.391 1-.874 1Z"
        fill="#fff"
      />
    </g>
  </svg>
)
export default SvgOralExam
