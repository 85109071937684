/**
 * Public url
 */
export const PUBLIC_URL = `${process.env.PUBLIC_URL}`

/**
 * Public images url
 */
export const IMAGES_URL = `${PUBLIC_URL}/images`

/**
 * Public cosmos images url
 */
export const IMAGES_COSMOS_URL = `${IMAGES_URL}/cosmos`

/**
 * React idle timer for refresher
 *
 * Set the activity timeout in milliseconds
 */
export const idleRefreshTimeout = 20000

/**
 * Time limit to consider the user as inactive.
 *
 * Expressed in milliseconds.
 */
export const idleActivityTimeout = 1000 * 60 * 1

/**
 * Time interval for sending the user's last activity time.
 *
 * Expressed in milliseconds.
 */
export const userActivityInterval = 1000 * 60 * 5

/**
 * Refresher
 *
 * The min number of milliseconds between runs
 */
export const minRefreshInterval = 25000

/**
 * Refresher
 *
 * The max number of milliseconds between runs
 */
export const maxRefreshInterval = 300000

/**
 * Project config
 *
 * Flag names
 */
export const FLAGS_MAP = {
  1: 'flag_argentina',
  2: 'flag_peru',
  3: 'flag_colombia',
  4: 'flag_mexico',
  5: 'flag_paraguay',
  6: 'flag_uruguay',
  7: 'flag_chile',
  8: 'flag_guatemala',
  9: 'flag_bolivia',
  10: 'flag_ecuador',
  11: 'flag_dominican_republic',
  12: 'email',
  13: 'flag_guatemala',
  14: 'flag_honduras',
  15: 'flag_colombia',
  16: 'flag_ukraine',
  17: 'flag_colombia',
  18: 'passport',
  23: 'flag_china',
  24: 'flag_korea',
  25: 'flag_brasil',
}

/**
 * Activity creator configs
 */
export const FIELD_LENGTHS = {
  REGULAR_INPUT_MAX_LENGTH: 255,
  ACTIVITY_TITLE_MIN_DEFAULT: 0,
  REGULAR_INPUT_MIN_LENGTH: 10,
  ACTIVITY_TITLE_MAX: 80,
  QUESTION_TITLE_MAX: 250,
  QUESTION_OPTION_MAX: 100,
  TEXT_BLOCK_BODY: 200,
}

/**
 * Activity creator configs
 */
export const FIELD_LENGTHS_COURSES = {
  NAME_INPUT_MAX_LENGTH: 200,
  TEXT_INPUT_MAX_LENGTH: 600,
  SHORT_DESCRIPTION_INPUT_MAX_LENGTH: 140,
}

/**
 * Robots
 *
 * Boards types
 */
export const ROBOTS_BOARD_TYPES = {
  CODE: 'code',
  SENIOR: 'senior',
  JUNIOR: 'junior',
  ANIMATIONS: 'animations',
}

/**
 * Robots
 *
 * Boards/brands colors
 */
export const ROBOTS_BOARD_COLOR = {
  'educabot-bhoot': '#F46126',
  'educablocks-uno': '#4f36d6',
  'arduino-nano': '#9E51AF',
  'arduino-leonardo': '#FDB933',
  'arduino-genuino-uno': '#4f36d6',
  'arduino-genuino-mega': '#F46126',
  'arduino-genuino-micro': '#B13E6A',
  'smart-team-1-cap-1': '#B13E6A',
  'smart-team-2-cap-1': '#4f36d6',
  'smart-team-3-cap-1': '#F46126',
  'smart-team-4-cap-1': '#FDB933',
  'smart-team-5-cap-1': '#FDB933',
  'smart-team-6-cap-1': '#B13E6A',
  'smart-team-7-cap-1': '#F46126',
  'smart-team-8-cap-1': '#9E51AF',
  'smart-team-9-cap-1': '#B13E6A',
  drone: '#01CEAA',
  default: '#4f36d6',
  microbit: '#FDB933',
}

export const GAME_IMAGES_TYPES = {
  HEADER: 'header',
  CARD_HEADER: 'card_header',
  LOGO: 'logo',
}
