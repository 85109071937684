import type { GenericActionWithType } from '@/legacy/redux/utils'
import type { ManagementClassroom } from '@/api/lms/classrooms'
import type { DeleteClassroomAsMgmtAction } from '@/legacy/redux/types/classrooms'
import type { ClearState } from '@/legacy/redux/types/generic'

export type DeleteClassroomConfirmModalState = {
  classroom: ManagementClassroom | null
  open: boolean
  loading: boolean
  error: string
}

interface SetModalInitialValuesPayload {
  classroom: ManagementClassroom
}
// Set initial values
export const SET_INITIAL_VALUES = 'MANAGEMENT/CLASSROOMS/DELETE_MODAL/SET_INITIAL_VALUES'

// Set initial values
export type SetInitialValues = GenericActionWithType<
  typeof SET_INITIAL_VALUES,
  SetModalInitialValuesPayload
>

export type DeleteClassroomConfirmModalAction =
  | DeleteClassroomAsMgmtAction
  | SetInitialValues
  | ClearState
