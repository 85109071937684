import { REQUEST_STATUS } from '@/legacy/redux/types/status'

// State.
export interface CancelOrgDeletionModalState {
    isOpenModal: boolean
    selectedOrganization: {
      name: string
      id: number
    }
    status: REQUEST_STATUS
}

// Types.
export enum CANCEL_ORG_DELETION_MODAL_TYPES {
    OPEN_MODAL = 'MANAGEMENT/ORGANIZATIONS/CANCEL_ORG_DELETION_MODAL/OPEN_MODAL',
    CLEAN_MODAL = 'MANAGEMENT/ORGANIZATIONS/CANCEL_ORG_DELETION_MODAL/CLEAN_MODAL',
    CANCEL_ORG_DELETION_REQUEST = 'MANAGEMENT/ORGANIZATIONS/CANCEL_ORG_DELETION_MODAL/CANCEL_ORG_DELETION_REQUEST',
    CANCEL_ORG_DELETION_SUCCESS = 'MANAGEMENT/ORGANIZATIONS/CANCEL_ORG_DELETION_MODAL/CANCEL_ORG_DELETION_SUCCESS',
    CANCEL_ORG_DELETION_FAILURE = 'MANAGEMENT/ORGANIZATIONS/CANCEL_ORG_DELETION_MODAL/CANCEL_ORG_DELETION_FAILURE',
}

// Actions.
// Open modal action.
export type OpenModalAction = {
    type: CANCEL_ORG_DELETION_MODAL_TYPES.OPEN_MODAL,
    payload: CancelOrgDeletionModalState['selectedOrganization'],
}

// Clean modal action.
export type CleanModalAction = {
    type: CANCEL_ORG_DELETION_MODAL_TYPES.CLEAN_MODAL,
}

// Cancel org deletion actions.
export type CancelOrgDeletionRequestAction = {
    type: CANCEL_ORG_DELETION_MODAL_TYPES.CANCEL_ORG_DELETION_REQUEST,
}

export type CancelOrgDeletionSuccessAction = {
    type: CANCEL_ORG_DELETION_MODAL_TYPES.CANCEL_ORG_DELETION_SUCCESS,
}

export type CancelOrgDeletionFailureAction = {
    type: CANCEL_ORG_DELETION_MODAL_TYPES.CANCEL_ORG_DELETION_FAILURE,
}

export type CancelOrgDeletionAction =
    | CancelOrgDeletionRequestAction
    | CancelOrgDeletionSuccessAction
    | CancelOrgDeletionFailureAction;

// Action.
export type CancelOrgDeletionModalAction =
    | OpenModalAction
    | CleanModalAction
    | CancelOrgDeletionAction;