export const GET_HELP_REQUESTS_REQUEST = 'TEACHER_WRITTEN_EXAM/GET_HELP_REQUESTS_REQUEST'
export const GET_HELP_REQUESTS_SUCCESS = 'TEACHER_WRITTEN_EXAM/GET_HELP_REQUESTS_SUCCESS'
export const GET_HELP_REQUESTS_FAILURE = 'TEACHER_WRITTEN_EXAM/GET_HELP_REQUESTS_FAILURE'

export const GET_EXES_STUDENTS_REQUEST = 'TEACHER_WRITTEN_EXAM/GET_EXES_STUDENTS_REQUEST'
export const GET_EXES_STUDENTS_SUCCESS = 'TEACHER_WRITTEN_EXAM/GET_EXES_STUDENTS_SUCCESS'
export const GET_EXES_STUDENTS_FAILURE = 'TEACHER_WRITTEN_EXAM/GET_EXES_STUDENTS_FAILURE'

export const GET_HELP_REQUESTS_QUANTITY_REQUEST =
  'TEACHER_WRITTEN_EXAM/GET_HELP_REQUESTS_QUANTITY_REQUEST'
export const GET_HELP_REQUESTS_QUANTITY_SUCCESS =
  'TEACHER_WRITTEN_EXAM/GET_HELP_REQUESTS_QUANTITY_SUCCESS'
export const GET_HELP_REQUESTS_QUANTITY_FAILURE =
  'TEACHER_WRITTEN_EXAM/GET_HELP_REQUESTS_QUANTITY_FAILURE'

export const GET_EXES_STUDENTS_QUANTITY_REQUEST =
  'TEACHER_WRITTEN_EXAM/GET_EXES_STUDENTS_QUANTITY_REQUEST'
export const GET_EXES_STUDENTS_QUANTITY_SUCCESS =
  'TEACHER_WRITTEN_EXAM/GET_EXES_STUDENTS_QUANTITY_SUCCESS'
export const GET_EXES_STUDENTS_QUANTITY_FAILURE =
  'TEACHER_WRITTEN_EXAM/GET_EXES_STUDENTS_QUANTITY_FAILURE'

export const UPDATE_HELP_REQUEST_REQUEST = 'TEACHER_WRITTEN_EXAM/UPDATE_HELP_REQUEST_REQUEST'
export const UPDATE_HELP_REQUEST_SUCCESS = 'TEACHER_WRITTEN_EXAM/UPDATE_HELP_REQUEST_SUCCESS'
export const UPDATE_HELP_REQUEST_FAILURE = 'TEACHER_WRITTEN_EXAM/UPDATE_HELP_REQUEST_FAILURE'

export const ADD_HELP_REQUEST = 'TEACHER_WRITTEN_EXAM/ADD_HELP_REQUEST'
export const REMOVE_HELP_REQUEST = 'TEACHER_WRITTEN_EXAM/REMOVE_HELP_REQUEST'

export const INCREASE_SOCKET_ID = 'STUDENT_WRITTEN_EXAM/INCREASE_SOCKET_ID'

export interface Subject {
  color: string
  id: number
  name: string
}

export interface Activity {
  chatEnabled: boolean
  classroomId: number
  date: string
  deadline: string
  endDate: string
  evaluationType: number
  id: number
  publicationDate: string
  robotsId: number
  robotsPassword: string
  startDate: string
  subject: Subject
  title: string
  type: string
  videocallUrl: string
}

export interface Classroom {
  division: string
  grade: string
  id: number
  shift: string
  year: number
  stage: string
}

export interface Organization {
  id: number
  name: string
}

export interface Student {
  email: string
  id: number
  lastName: string
  name: string
}

export interface StudentExes {
  activity: Activity
  classroom: Classroom
  organization: Organization
  student: Student
}

export interface Request {
  activityId: number
  callerId: number
  createdAt: string
  id: number
  responderId: 0
  status: string
  videocallUrl: string
}

export interface HelpRequest {
  activity: Activity
  classroom: Classroom
  organization: Organization
  student: Student
  request: Request
}

export interface GetHelpRequestsRequest {
  type: typeof GET_HELP_REQUESTS_REQUEST
}

export interface GetHelpRequestsSuccess {
  type: typeof GET_HELP_REQUESTS_SUCCESS
  payload: {
    requests: HelpRequest[]
  }
}

export interface GetHelpRequestsFailure {
  type: typeof GET_HELP_REQUESTS_FAILURE
  payload: {
    error: string
  }
}

export interface GetHelpRequestsQuantityRequest {
  type: typeof GET_HELP_REQUESTS_QUANTITY_REQUEST
}

export interface GetHelpRequestsQuantitySuccess {
  type: typeof GET_HELP_REQUESTS_QUANTITY_SUCCESS
  payload: {
    requestsQuantity: number
  }
}

export interface GetHelpRequestsQuantityFailure {
  type: typeof GET_HELP_REQUESTS_QUANTITY_FAILURE
  payload: {
    error: string
  }
}

export interface UpdateHelpRequestRequest {
  type: typeof UPDATE_HELP_REQUEST_REQUEST
}

export interface UpdateHelpRequestSuccess {
  type: typeof UPDATE_HELP_REQUEST_SUCCESS
  payload: {
    request: Request
  }
}

export interface UpdateHelpRequestFailure {
  type: typeof UPDATE_HELP_REQUEST_FAILURE
  payload: {
    error: string
  }
}

export interface GetEXESStudentsRequest {
  type: typeof GET_EXES_STUDENTS_REQUEST
}

export interface GetEXESStudentsSuccess {
  type: typeof GET_EXES_STUDENTS_SUCCESS
  payload: {
    exes: StudentExes[]
  }
}

export interface GetEXESStudentsFailure {
  type: typeof GET_EXES_STUDENTS_FAILURE
  payload: {
    error: string
  }
}

export interface GetEXESStudentsQuantityRequest {
  type: typeof GET_EXES_STUDENTS_QUANTITY_REQUEST
}

export interface GetEXESStudentsQuantitySuccess {
  type: typeof GET_EXES_STUDENTS_QUANTITY_SUCCESS
  payload: {
    exesQuantity: number
  }
}

export interface GetEXESStudentsQuantityFailure {
  type: typeof GET_EXES_STUDENTS_QUANTITY_FAILURE
  payload: {
    error: string
  }
}

export interface AddHelpRequest {
  type: typeof ADD_HELP_REQUEST
  payload: {
    request: Request
  }
}

export interface RemoveHelpRequest {
  type: typeof REMOVE_HELP_REQUEST
  payload: {
    request: Request
  }
}

export interface IncreaseSocketID {
  type: typeof INCREASE_SOCKET_ID
}

export interface WrittenExamsState {
  socketID: number
  helpRequests: HelpRequest[]
  exorStudents: StudentExes[]
  helpRequestsQuantity: number
  exorStudentsQuantity: number
  loadingExesStudents: boolean
  loadingHelpRequests: boolean
  loadingExesStudentsQuantity: boolean
  loadingHelpRequestsQuantity: boolean
  errorHelpRequests: string
  errorExesStudents: string
  errorHelpRequestsQuantity: string
  errorExesStudentsQuantity: string
}

export type WrittenExamActions =
  | GetHelpRequestsRequest
  | GetHelpRequestsSuccess
  | GetHelpRequestsFailure
  | GetHelpRequestsQuantityRequest
  | GetHelpRequestsQuantitySuccess
  | GetHelpRequestsQuantityFailure
  | GetEXESStudentsRequest
  | GetEXESStudentsSuccess
  | GetEXESStudentsFailure
  | GetEXESStudentsQuantityRequest
  | GetEXESStudentsQuantitySuccess
  | GetEXESStudentsQuantityFailure
  | UpdateHelpRequestRequest
  | UpdateHelpRequestSuccess
  | UpdateHelpRequestFailure
  | AddHelpRequest
  | RemoveHelpRequest
  | IncreaseSocketID
