import type { Reducer } from 'redux'
import type {
  MessageThreadsDrawerState,
  MessageThreadDrawerActions,
} from '@/sections/teacher/bulletin/types/messageThreadsDrawer'
import { MESSAGE_THREADS_DRAWER_TYPES } from '@/sections/teacher/bulletin/types/messageThreadsDrawer'

const initialState: MessageThreadsDrawerState = {
  open: false,
}

const messageThreadsDrawer: Reducer<MessageThreadsDrawerState, MessageThreadDrawerActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case MESSAGE_THREADS_DRAWER_TYPES.OPEN: {
      return {
        ...state,
        open: true,
      }
    }
    case MESSAGE_THREADS_DRAWER_TYPES.CLOSE: {
      return {
        ...state,
        open: false,
      }
    }
    default:
      return state
  }
}

export default messageThreadsDrawer
