import types from '@/sections/student/dashboard/types'
import { apiPrivate } from '@/api'
import { searchUrl, url } from '@aula/config'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import { addNewCall, getLastCall, STUDENT_DASHBOARD, STUDENT_SUMMARY } from '@/legacy/debounce'
import { API } from '@/api/lms'

let searchTimer

const actions = {
  getStudentSummary:
    (organizationID, academicPeriodID, silentLoading = false, date) =>
    (dispatch) => {
      const call = addNewCall(STUDENT_SUMMARY)
      dispatch({ type: types.SUMMARY_REQUEST, payload: { silentLoading } })

      return API.Students.getDashboardSummary(organizationID, academicPeriodID, date)
        .then((data) => {
          if (call !== getLastCall(STUDENT_SUMMARY)) return
          const { summary, user } = data
          dispatch(actions.getStudentSummarySuccess(summary, user))
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.SUMMARY_FAILURE, dispatch)
        })
    },

  getStudentSummarySuccess: (summary, user) => ({
    type: types.SUMMARY_SUCCESS,
    payload: {
      summary,
      user,
    },
  }),

  getStudentDashboard:
    (organizationID, academicPeriodID, date, silentLoading = false) =>
    (dispatch) => {
      const call = addNewCall(STUDENT_DASHBOARD)

      dispatch({ type: types.DASHBOARD_REQUEST, payload: { silentLoading } })

      return apiPrivate
        .get(`${url}/v3/dashboard/student`, {
          params: {
            organizationId: organizationID,
            academicPeriodId: academicPeriodID,
            date,
          },
        })
        .then((response) => {
          if (call !== getLastCall(STUDENT_DASHBOARD)) return
          const { done, future, pending, today, summary } = response.data.description.dashboard

          dispatch(actions.getStudentDashboardSuccess(done, future, pending, today, summary))
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.DASHBOARD_FAILURE, dispatch)
        })
    },

  getStudentDashboardSuccess: (done, future, pending, today, summary) => ({
    type: types.DASHBOARD_SUCCESS,
    payload: {
      done,
      future,
      pending,
      today,
      dashboardSummary: {
        pending: summary.pending,
        subject: summary.subject,
        todayDone: summary.todayDone,
        todayTotal: summary.todayTotal,
        weekDone: summary.weekDone,
        weekTotal: summary.weekTotal,
      },
    },
  }),

  getDashboardV4: (organizationID, academicPeriodID, date) => async (dispatch) => {
    dispatch({ type: types.DASHBOARD_V4_REQUEST })

    try {
      const response = await apiPrivate.get(
        `${url}/v4/dashboard/student?organization_id=${organizationID}&academic_period_id=${academicPeriodID}&date=${date}`
      )
      const { description } = response.data
      dispatch({
        type: types.DASHBOARD_V4_SUCCESS,
        payload: description,
      })
    } catch (error) {
      handleErrorsWithAction(error, types.DASHBOARD_V4_FAILURE, dispatch)
    }
  },

  toggleSearchBar: (open = false) => ({
    type: types.TOGGLE_SEARCH_BAR,
    payload: { open },
  }),
  setSearch: (search) => ({
    type: types.SET_SEARCH,
    payload: { search },
  }),

  searchActivities: (search) => (dispatch) => {
    if (searchTimer) clearTimeout(searchTimer)
    searchTimer = setTimeout(() => dispatch(actions.searchActivitiesCall(search)), 250)
  },

  searchActivitiesCall: (search) => (dispatch, getState) => {
    const lastSearchID = new Date().getMilliseconds()
    const call = addNewCall(types.SEARCH_REQUEST)

    dispatch({ type: types.SEARCH_REQUEST, payload: { lastSearchID } })

    const orgID = getState().user.selectedOrganization.id
    const body = {
      term: search,
      orgId: orgID.toString(),
      role: 'student',
      subgroups: [],
    }

    return apiPrivate
      .post(searchUrl + '/activities', body)
      .then((response) => {
        if (lastSearchID !== getState().student.dashboard.dashboard.lastSearchID) return
        if (call !== getLastCall(types.SEARCH_REQUEST)) return

        dispatch(actions.searchActivitiesSuccess(response.data))
      })
      .catch((error) => {
        handleErrorsWithAction(error, types.SEARCH_FAILURE, dispatch)
      })
  },

  searchActivitiesSuccess: (activities) => ({
    type: types.SEARCH_SUCCESS,
    payload: { activities },
  }),
}

export default actions
