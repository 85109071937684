import * as React from 'react'
import type { SVGProps } from 'react'
const SvgMessages = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.263 2.601C.466 1.407 1.403.53 2.586.347A29.394 29.394 0 0 1 7 0c1.725 0 3.28.172 4.414.347 1.183.182 2.12 1.06 2.323 2.254.142.833.263 1.89.263 3.07s-.121 2.238-.263 3.07c-.203 1.194-1.14 2.072-2.323 2.254-1.037.16-2.427.318-3.977.344L3.157 13.9a.7.7 0 0 1-1.057-.61v-2.413A2.725 2.725 0 0 1 .263 8.741 18.444 18.444 0 0 1 0 5.671c0-1.18.121-2.237.263-3.07Z"
      fill="#1A1A1A"
    />
  </svg>
)
export default SvgMessages
