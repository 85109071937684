import { combineReducers } from 'redux'
import dashboard from './dashboard/reducer'
import writtenExam from './writtenExam/reducer'
import exams from './exams/reducer'
import collabMiniature from './collabMiniature/reducer'
import summary from './summary/reducer'

const rootReducer = combineReducers({
  dashboard,
  writtenExam,
  exams,
  collabMiniature,
  summary,
})

export default rootReducer
