import type { Classroom } from '@/sections/auth/login/types/login'
import type { User } from '@/sections/user/types'
import type { Organization } from '@/api/lms/user'
import type { SetField } from '@/legacy/redux/types/generic'

export const SET_QUESTION_ANSWER = 'LOGIN/SET_QUESTION_ANSWER'
export const BACK_TO_BEFORE_QUESTION = 'LOGIN/BACK_TO_BEFORE_QUESTION'
export const GO_BACK_TO_LOGIN = 'LOGIN/GO_BACK_TO_LOGIN'
export const GET_QUESTIONS_REQUEST = 'LOGIN/GET_QUESTIONS_REQUEST'
export const GET_QUESTIONS_SUCCESS = 'LOGIN/GET_QUESTIONS_SUCCESS'
export const GET_QUESTIONS_FAILURE = 'LOGIN/GET_QUESTIONS_FAILURE'
export const GET_QUESTIONS_TIMEOUT = 'LOGIN/GET_QUESTIONS_TIMEOUT'
export const LOGIN_REQUEST = 'LOGIN/LOGIN_WITH_QUESTIONS_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN/LOGIN_WITH_QUESTIONS_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN/LOGIN_WITH_QUESTIONS_FAILURE'
export const LOGIN_TIMEOUT = 'LOGIN/LOGIN_WITH_QUESTIONS_TIMEOUT'
export const CLEAR_TIMEOUT_MINUTES = 'LOGIN/CLEAR_TIMEOUT_MINUTES'

export type Option = {
  title: string
  index: number
}

export type QuestionEntity = {
  id: number
  options: Option[]
  question: string
  i18NCode: string
}

export interface SetQuestionAnswer {
  type: typeof SET_QUESTION_ANSWER
  payload: {
    answer: number
    index: number
  }
}

export interface BackToBeforeQuestion {
  type: typeof BACK_TO_BEFORE_QUESTION
}

export interface GoBackToLogin {
  type: typeof GO_BACK_TO_LOGIN
}

export interface GetQuestionsRequest {
  type: typeof GET_QUESTIONS_REQUEST
}

export interface GetQuestionsSuccess {
  type: typeof GET_QUESTIONS_SUCCESS
  payload: {
    id: number
    questions: QuestionEntity[]
  }
}

export interface GetQuestionsTimeout {
  type: typeof GET_QUESTIONS_TIMEOUT
  payload: {
    minutes: number
  }
}

export interface GetQuestionsFailure {
  type: typeof GET_QUESTIONS_FAILURE
  payload: {
    error: string
  }
}

export interface LoginRequest {
  type: typeof LOGIN_REQUEST
}

export interface LoginSuccess {
  type: typeof LOGIN_SUCCESS
  payload: {
    user: User
    token: string
    classroomID: number
    classroom: Classroom
    role: string
    altRoles: string[]
    sandbox: number
    organizations: number[]
    organizationList: Organization[]
  }
}

export interface LoginFailure {
  type: typeof LOGIN_FAILURE
  payload: {
    error: string
  }
}

export interface LoginTimeout {
  type: typeof LOGIN_TIMEOUT
  payload: {
    minutes: number
  }
}

export interface ClearTimeoutMinutes {
  type: typeof CLEAR_TIMEOUT_MINUTES
}

export type AnswerLoginState = {
  loading: boolean
  error: string
  questionAnswer: number
  questions: QuestionEntity[]
  answers: number[]
  timeoutMinutes: number
  modalIsOpen: boolean
  goBack: boolean
  stillInTimeoutMinutes: number
}

export type AnswerLoginActions =
  | SetField<'stillInTimeoutMinutes', number>
  | SetQuestionAnswer
  | BackToBeforeQuestion
  | GoBackToLogin
  | GetQuestionsRequest
  | GetQuestionsSuccess
  | GetQuestionsFailure
  | GetQuestionsTimeout
  | LoginRequest
  | LoginSuccess
  | LoginFailure
  | LoginTimeout
  | ClearTimeoutMinutes
