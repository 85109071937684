import type {
  ApiManagementUser,
  AssignUserResponse,
  CreateUserParams,
  CreateUserResponse,
  EditUserDataParams,
  GetAsAdminUser,
} from '@/api/lms/managementUsers'
import type {
  ErrorPayload,
  GenericAction,
  GenericActionWithType,
  GenericPagination,
} from '@/legacy/redux/utils'

// Get users
export const GET_USERS_AS_MANAGEMENT_REQUEST = 'USERS/GET_USERS_AS_MANAGEMENT_REQUEST'
export const GET_USERS_AS_MANAGEMENT_SUCCESS = 'USERS/GET_USERS_AS_MANAGEMENT_SUCCESS'
export const GET_USERS_AS_MANAGEMENT_FAILURE = 'USERS/GET_USERS_AS_MANAGEMENT_FAILURE'

export type GetUsersRequest = GenericActionWithType<
  typeof GET_USERS_AS_MANAGEMENT_REQUEST,
  GenericPagination
>

type GetUsersSuccessExtraPayload = { users: GetAsAdminUser[]; more: boolean; failed: string[] }

export type GetUsersSuccess = GenericActionWithType<
  typeof GET_USERS_AS_MANAGEMENT_SUCCESS,
  GetUsersSuccessExtraPayload
>

export interface GetUsersFailure extends GenericAction {
  type: typeof GET_USERS_AS_MANAGEMENT_FAILURE
  payload: ErrorPayload
}

export type GetUsersAction = GetUsersRequest | GetUsersSuccess | GetUsersFailure

// Get one user
export const GET_ONE_USER_AS_MANAGEMENT_REQUEST = 'USERS/GET_ONE_USER_AS_MANAGEMENT_REQUEST'
export const GET_ONE_USER_AS_MANAGEMENT_SUCCESS = 'USERS/GET_ONE_USER_AS_MANAGEMENT_SUCCESS'
export const GET_ONE_USER_AS_MANAGEMENT_FAILURE = 'USERS/GET_ONE_USER_AS_MANAGEMENT_FAILURE'

export type GetOneUserRequest = GenericActionWithType<
  typeof GET_ONE_USER_AS_MANAGEMENT_REQUEST,
  { id: number }
>

export type GetOneUserSuccess = GenericActionWithType<
  typeof GET_ONE_USER_AS_MANAGEMENT_SUCCESS,
  { user: ApiManagementUser; failed: string[] }
>

export interface GetOneUserFailure extends GenericAction {
  type: typeof GET_ONE_USER_AS_MANAGEMENT_FAILURE
  payload: ErrorPayload
}

export type GetOneUserAction = GetOneUserRequest | GetOneUserSuccess | GetOneUserFailure

// Create users
export const CREATE_USER_AS_MANAGEMENT_REQUEST = 'USERS/CREATE_USER_AS_MANAGEMENT_REQUEST'
export const CREATE_USER_AS_MANAGEMENT_SUCCESS = 'USERS/CREATE_USER_AS_MANAGEMENT_SUCCESS'
export const CREATE_USER_AS_MANAGEMENT_FAILURE = 'USERS/CREATE_USER_AS_MANAGEMENT_FAILURE'

export type CreateUserRequest = GenericActionWithType<
  typeof CREATE_USER_AS_MANAGEMENT_REQUEST,
  { user: CreateUserParams }
>

export type CreateUserSuccess = GenericActionWithType<
  typeof CREATE_USER_AS_MANAGEMENT_SUCCESS,
  CreateUserResponse
>

export interface CreateUserFailure extends GenericAction {
  type: typeof CREATE_USER_AS_MANAGEMENT_FAILURE
  payload: ErrorPayload
}

export type CreateUserAction = CreateUserRequest | CreateUserSuccess | CreateUserFailure
export type GetUsers = GetUsersRequest | GetUsersSuccess | GetUsersFailure

// Delete user
export const DELETE_USER_REQUEST = 'USERS/DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'USERS/DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = 'USERS/DELETE_USER_FAILURE'

export type DeleteUserRequest = GenericActionWithType<typeof DELETE_USER_REQUEST>
export type DeleteUserSuccess = GenericActionWithType<typeof DELETE_USER_SUCCESS>
export type DeleteUserFailure = GenericActionWithType<typeof DELETE_USER_FAILURE, ErrorPayload>

export type DeleteUserAction = DeleteUserRequest | DeleteUserSuccess | DeleteUserFailure

// Edit user data
export const EDIT_USER_DATA_REQUEST = 'USERS/EDIT_USER_DATA_REQUEST'
export const EDIT_USER_DATA_SUCCESS = 'USERS/EDIT_USER_DATA_SUCCESS'
export const EDIT_USER_DATA_FAILURE = 'USERS/EDIT_USER_DATA_FAILURE'

export type EditUserDataRequest = GenericActionWithType<typeof EDIT_USER_DATA_REQUEST>
export type EditUserDataSuccess = GenericActionWithType<
  typeof EDIT_USER_DATA_SUCCESS,
  { updatedUserData: EditUserDataParams }
>
export type EditUserDataFailure = GenericActionWithType<typeof EDIT_USER_DATA_FAILURE, ErrorPayload>

export type EditUserDataAction = EditUserDataRequest | EditUserDataSuccess | EditUserDataFailure

// Assign user
export const ASSIGN_USER_REQUEST = 'USERS/ASSIGN_USER_REQUEST'
export const ASSIGN_USER_SUCCESS = 'USERS/ASSIGN_USER_SUCCESS'
export const ASSIGN_USER_FAILURE = 'USERS/ASSIGN_USER_FAILURE'

export type AssignUserRequest = GenericActionWithType<typeof ASSIGN_USER_REQUEST>
export type AssignUserSuccess = GenericActionWithType<
  typeof ASSIGN_USER_SUCCESS,
  { assignments: AssignUserResponse[] }
>
export type AssignUserFailure = GenericActionWithType<typeof ASSIGN_USER_FAILURE, ErrorPayload>

export type AssignUserAction = AssignUserRequest | AssignUserSuccess | AssignUserFailure

// Unassign user
export const UNASSIGN_USER_REQUEST = 'USERS/UNASSIGN_USER_REQUEST'
export const UNASSIGN_USER_SUCCESS = 'USERS/UNASSIGN_USER_SUCCESS'
export const UNASSIGN_USER_FAILURE = 'USERS/UNASSIGN_USER_FAILURE'

export type UnassignUserRequest = GenericActionWithType<typeof UNASSIGN_USER_REQUEST>
export type UnassignUserSuccess = GenericActionWithType<typeof UNASSIGN_USER_SUCCESS>
export type UnassignUserFailure = GenericActionWithType<typeof UNASSIGN_USER_FAILURE, ErrorPayload>

export type UnassignUserAction = UnassignUserRequest | UnassignUserSuccess | UnassignUserFailure

// Unassign user from organization
export const UNASSIGN_USER_FROM_ORGANIZATION_REQUEST =
  'USERS/UNASSIGN_USER_FROM_ORGANIZATION_REQUEST'
export const UNASSIGN_USER_FROM_ORGANIZATION_SUCCESS =
  'USERS/UNASSIGN_USER_FROM_ORGANIZATION_SUCCESS'
export const UNASSIGN_USER_FROM_ORGANIZATION_FAILURE =
  'USERS/UNASSIGN_USER_FROM_ORGANIZATION_FAILURE'

export type UnassignUserFromOrganizationRequest = GenericActionWithType<
  typeof UNASSIGN_USER_FROM_ORGANIZATION_REQUEST
>
export type UnassignUserFromOrganizationSuccess = GenericActionWithType<
  typeof UNASSIGN_USER_FROM_ORGANIZATION_SUCCESS
>
export type UnassignUserFromOrganizationFailure = GenericActionWithType<
  typeof UNASSIGN_USER_FROM_ORGANIZATION_FAILURE,
  ErrorPayload
>

export type UnassignUserFromOrganizationAction =
  | UnassignUserFromOrganizationRequest
  | UnassignUserFromOrganizationSuccess
  | UnassignUserFromOrganizationFailure

// Edit user assignment levels.
export const EDIT_USER_ASSIGNMENT_LEVELS_REQUEST = 'USERS/EDIT_USER_ASSIGNMENT_LEVELS_REQUEST'
export const EDIT_USER_ASSIGNMENT_LEVELS_SUCCESS = 'USERS/EDIT_USER_ASSIGNMENT_LEVELS_SUCCESS'
export const EDIT_USER_ASSIGNMENT_LEVELS_FAILURE = 'USERS/EDIT_USER_ASSIGNMENT_LEVELS_FAILURE'

export type EditUserAssignmentLevelsRequestAction = GenericActionWithType<
  typeof EDIT_USER_ASSIGNMENT_LEVELS_REQUEST
>
export type EditUserAssignmentLevelsSuccessAction = GenericActionWithType<
  typeof EDIT_USER_ASSIGNMENT_LEVELS_SUCCESS
>
export type EditUserAssignmentLevelsFailureAction = GenericActionWithType<
  typeof EDIT_USER_ASSIGNMENT_LEVELS_FAILURE,
  ErrorPayload
>

export type EditUserAssignmentLevelsAction =
  | EditUserAssignmentLevelsRequestAction
  | EditUserAssignmentLevelsSuccessAction
  | EditUserAssignmentLevelsFailureAction
