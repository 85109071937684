import studentFilesActions from '@/sections/editor/actions/studentFiles'
import {
  QUESTIONS_TYPES,
  UNION_ANSWER_LEFT,
  UNION_ANSWER_RIGHT,
} from '@/sections/editor/constants'
import { isEmpty } from 'ramda'

// TODO remove state and dispatch to make the function pure
export const getMarkAsCompletedForm = async (state, dispatch, partial, activityID) => {
  const userID = state().user.user.id
  const answers = state().editor.questions.answers
  const attachmentAnswers = state().editor.studentFiles.attachmentAnswers

  const keys = Object.keys(attachmentAnswers)

  // Waiting for upload all files
  const errors = await Promise.all(
    keys.map(async (key) =>
      dispatch(studentFilesActions.uploadFiles(attachmentAnswers[key].filesToUpload, userID, key))
    )
  )

  if (errors.some((error) => error))
    return [null, new Error('teacher.library.errorWhileUploadingFiles')]
  // Fetch updated data from reducer
  const updatedAttachmentAnswers = state().editor.studentFiles.attachmentAnswers
  const filesAnswers = keys.map((key) => ({
    questionId: parseInt(key, 10),
    type: 'attachment',
    files: updatedAttachmentAnswers[key].files,
  }))

  const processedAnswers = filterUnansweredQuestions(answers).map((ans) => {
    switch (ans.type) {
      case QUESTIONS_TYPES.FILLABLE: {
        const filled = []
        Object.keys(ans.filled).forEach((f) =>
          filled.push({ number: parseInt(f), value: ans.filled[f] })
        )
        return { ...ans, filled }
      }
      case QUESTIONS_TYPES.UNION: {
        const matches = Object.values({
          ...ans.matches[UNION_ANSWER_LEFT],
          ...ans.matches[UNION_ANSWER_RIGHT],
        }).filter((m) => m.matchesWith !== -1)
        return { ...ans, matches }
      }
      default:
        return ans
    }
  })

  if (isEmpty(answers) && isEmpty(filesAnswers)) {
    return [{ type: 'read', activityId: activityID }, null]
  }

  const form = {
    activityId: activityID,
    type: 'answered',
    final: !partial,
    answers: [...processedAnswers, ...filterUnattachedFilesQuestions(filesAnswers)],
  }

  return [form, null]
}

const filterUnansweredQuestions = (answers) =>
  Object.values(answers).filter(
    (answer) =>
      !(
        answer.optionId === 0 &&
        answer.answer === '' &&
        !answer.filled &&
        !answers.matched &&
        answer.type !== QUESTIONS_TYPES.UNION
      )
  )

const filterUnattachedFilesQuestions = (answers) =>
  answers.filter((answer) => !isEmpty(answer.files))
