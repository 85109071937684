import { pathOr } from 'ramda'

export const STUDENT_SUMMARY = 'studentSummary'
export const STUDENT_DASHBOARD = 'studentDashboard'
export const STUDENT_SUBJECT = 'studentSubject'
export const TEACHER_DASHBOARD = 'teacherDashboard'
export const STUDENT_SUBJECT_ACTIVITIES = 'studentSubjectActivities'
export const TEACHER_ACTIVITIES = 'teacherActivities'
export const TEACHER_CLASSROOM_ACTIVITIES = 'teacherClassroomActivities'
export const TEACHER_CLASSROOM_STUDENTS = 'teacherClassroomStudents'
export const COURSE_DASHBOARD = 'courseDashboard'

const debounce = {}

// Function to call before the endpoint
export const addNewCall = (endpoint) => {
  debounce[endpoint] = pathOr(0, [endpoint], debounce) + 1
  return debounce[endpoint]
}

// Function to get the last call
export const getLastCall = (endpoint) => debounce[endpoint]
