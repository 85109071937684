import type { Moment, unitOfTime } from 'moment'
import moment from 'moment'
import type { AttendancesDay } from '@/api/attendances/config'

export const getDiffDateFromNow = (
  date: string,
  timeUnit: unitOfTime.Diff,
  callback: (diff: number) => boolean
) => {
  const diff = Math.abs(moment(date).diff(new Date(), timeUnit))
  return callback(diff) // inversion of control
}

/**
 * Formats a recent date or time into a friendly relative format, e.g. "35 seconds ago" or "6 days ago".
 * @link https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/02-fromnow
 * @param {string} date
 */
export const getRelativeDate = (date: string) => moment(date).fromNow()

export const friendlyFormatDate = (date: string, limit: number) =>
  getDiffDateFromNow(date, 'days', (diff) => diff > limit)
    ? moment(date).format('LL')
    : getRelativeDate(date)

interface Config {
  startDate?: Moment
  endDate?: Moment
  enabledDays?: AttendancesDay[]
}

/**
 * Genera un array de fechas entre las fechas de entrada y salida.
 *
 * Si no se provee `startDate` o `endDate`, se genera un semana a partir o hasta la fecha dada.
 * Si no se provee ninguno, genera una semana a partir del dia de la fecha.
 *
 * Si `startDate` es despues de `endDate`, devuelve un array vacio.
 *
 * El parametro enabledDays limita el output de la funcion a solo los dias de la semana que se le pasen.
 * @param startDate Moment
 * @param endDate Moment
 * @param enabledDays  Sunday as 1 and Saturday as 7
 */
export function makeDatesArray({
  startDate,
  endDate,
  enabledDays = [2, 3, 4, 5, 6],
}: Config): Moment[] {
  let start, end

  if (startDate && endDate) {
    if (startDate > endDate) {
      return []
    }
    start = startDate
    end = endDate
  } else if (!startDate && !endDate) {
    start = moment()
    end = start.clone().add(6, 'd')
  } else if (startDate && !endDate) {
    start = startDate
    end = start.clone().add(6, 'd')
  } else if (!startDate && endDate) {
    start = endDate.clone().subtract(6, 'd')
    end = endDate
  }

  const days: Moment[] = []
  let day = start.clone()

  while (day < end.clone().add(1, 'd')) {
    if (enabledDays.includes(day.day() + 1)) {
      days.push(day)
    }
    day = day.clone().add(1, 'd')
  }

  return days
}
