import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { Guardian } from '@/sections/student/summary/types/common'

export interface InformationTabState {
  status: REQUEST_STATUS
  data: Guardian[]
}

export enum INFORMATION_TAB_TYPES {
  GET_STUDENT_GUARDIANS_REQUEST = 'STUDENT/SUMMARY/TABS/INFORMATION/GET_STUDENT_GUARDIANS_REQUEST',
  GET_STUDENT_GUARDIANS_SUCCESS = 'STUDENT/SUMMARY/TABS/INFORMATION/GET_STUDENT_GUARDIANS_SUCCESS',
  GET_STUDENT_GUARDIANS_FAILURE = 'STUDENT/SUMMARY/TABS/INFORMATION/GET_STUDENT_GUARDIANS_FAILURE',
  RESET_TAB = 'STUDENT/SUMMARY/TABS/INFORMATION/RESET_TAB',
}

// Get student guardians.
export type GetStudentGuardiansRequestAction = {
  type: INFORMATION_TAB_TYPES.GET_STUDENT_GUARDIANS_REQUEST
}

export type GetStudentGuardiansSuccessAction = {
  type: INFORMATION_TAB_TYPES.GET_STUDENT_GUARDIANS_SUCCESS
  payload: {
    data: Guardian[]
  }
}

export type GetStudentGuardiansFailureAction = {
  type: INFORMATION_TAB_TYPES.GET_STUDENT_GUARDIANS_FAILURE
}

export type GetStudentGuardiansAction =
  | GetStudentGuardiansRequestAction
  | GetStudentGuardiansSuccessAction
  | GetStudentGuardiansFailureAction

// Reset tab.
export type ResetTabAction = {
  type: INFORMATION_TAB_TYPES.RESET_TAB
}

export type InformationTabAction = GetStudentGuardiansAction | ResetTabAction
