import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import {
  LIST_SUBJECTS_AS_ADMIN_FAILURE,
  LIST_SUBJECTS_AS_ADMIN_REQUEST,
  LIST_SUBJECTS_AS_ADMIN_SUCCESS,
} from '@/legacy/redux/types/subjects'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type {
  SubjectsDrawerAction,
  SubjectsDrawerState,
} from '@/sections/eb-admin/management/types/subjectsDrawer'

const initialState: SubjectsDrawerState = {
  subjects: [],
  loading: false,
  pagination: {
    page: 0,
    rowsPerPage: 12,
    count: 0,
  },
  error: '',
  search: '',
}

const requestDestination = reduxRequestOriginMap.ADMIN_SUBJECTS_DRAWER

const AdminSubjectsDrawerReducer: Reducer<SubjectsDrawerState, SubjectsDrawerAction> = (
  state = initialState,
  action
): SubjectsDrawerState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case LIST_SUBJECTS_AS_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    case LIST_SUBJECTS_AS_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        subjects: action.payload.subjects,
        pagination: {
          ...state.pagination,
          count: action.payload.count,
        },
      }
    case LIST_SUBJECTS_AS_ADMIN_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, AdminSubjectsDrawerReducer, requestDestination)
