import * as React from 'react'
import type { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      clipPath="url(#a)"
      stroke="#4F36D6"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    >
      <path d="m16.667 7.501-.722 10.118a1.666 1.666 0 0 1-1.663 1.549H5.72a1.666 1.666 0 0 1-1.663-1.549L3.334 7.501M.834 4.167h18.333M6.668 4.167v-2.5a.833.833 0 0 1 .833-.833h5a.834.834 0 0 1 .834.833v2.5" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgComponent
