import type { SetFilters } from './library'

export const GET_PREMIUM_ACTIVITIES_REQUEST = 'LIBRARY_TEACHER/GET_PREMIUM_ACTIVITIES_REQUEST'
export const GET_PREMIUM_ACTIVITIES_SUCCESS = 'LIBRARY_TEACHER/GET_PREMIUM_ACTIVITIES_SUCCESS'
export const GET_PREMIUM_ACTIVITIES_FAILURE = 'LIBRARY_TEACHER/GET_PREMIUM_ACTIVITIES_FAILURE'
export const GET_ALL_TAGS_REQUEST = 'LIBRARY_TEACHER/GET_ALL_TAGS_REQUEST'
export const GET_ALL_TAGS_SUCCESS = 'LIBRARY_TEACHER/GET_ALL_TAGS_SUCCESS'
export const GET_ALL_TAGS_FAILURE = 'LIBRARY_TEACHER/GET_ALL_TAGS_FAILURE'
export const SET_CONTACT_MODAL_OPEN = 'LIBRARY_TEACHER/SET_CONTACT_MODAL_OPEN'
export const SET_ONLY_WITH_LICENSES = 'LIBRARY_TEACHER/SET_ONLY_WITH_LICENSES'
export const SET_VIEW = 'LIBRARY_TEACHER/SET_VIEW'
export const RESET_PAGINATION = 'LIBRARY_TEACHER/RESET_PAGINATION'
export const SET_SEARCH = 'LIBRARY_TEACHER/SET_SEARCH'

export interface PremiumActivityListItem {
  activityId: number
  availability: string
  createdAt: string
  description: string
  id: number
  organizationId: number
  origin: number
  title: string
  type: string
  hasQuestions: boolean
  hasFiles: boolean
  hasDeliverables: boolean
}

export type Resource = 'attachment' | 'writtenAnswer' | 'multipleChoice'
export type ActivityType = 'questions' | 'deliverable' | 'printout'

export interface FormattedPremiumActivity {
  activityId: number
  createdAt: string
  description: string
  id: number
  organizationId: number
  origin: number
  title: string
  type: string
  hasQuestions: boolean
  hasFiles: boolean
  hasDeliverables: boolean
  resources: Resource[]
  activityType: ActivityType[]
}

export interface GetPremiumActivitiesRequest {
  type: typeof GET_PREMIUM_ACTIVITIES_REQUEST
  payload: {
    page: number
    rowsPerPage: number
  }
}

export interface GetPremiumActivitiesSuccess {
  type: typeof GET_PREMIUM_ACTIVITIES_SUCCESS
  payload: {
    activities: FormattedPremiumActivity[]
    count: number
  }
}

export interface GetPremiumActivitiesFailure {
  type: typeof GET_PREMIUM_ACTIVITIES_FAILURE
  payload: {
    error: string
  }
}

type Pages = {
  page: number
  rowsPerPage: number
  count: number
}
export interface LibraryActivitiesState {
  open: boolean
  loading: boolean
  activities: FormattedPremiumActivity[]
  error: string
  premiumActivitiesPagination: Pages
  search: string
  tags: LibraryResourceTagCategory[]
  contactOpen: boolean
  onlyWithLicenses: boolean
  view: string
}

export type LibraryResourceTag = {
  id: number
  name: string
}

export type LibraryResourceTagCategory = {
  name: string
  tags: LibraryResourceTag[]
}

export interface GetAllTagsRequest {
  type: typeof GET_ALL_TAGS_REQUEST
}

export interface GetAllTagsSuccess {
  type: typeof GET_ALL_TAGS_SUCCESS
  payload: {
    tagCategories: LibraryResourceTagCategory[]
  }
}

export interface GetAllTagsFailure {
  type: typeof GET_ALL_TAGS_FAILURE
  payload: {
    error: string
  }
}
export interface SetContactModalOpen {
  type: typeof SET_CONTACT_MODAL_OPEN
  payload: boolean
}

export interface SetOnlyWithLicenses {
  type: typeof SET_ONLY_WITH_LICENSES
  payload: boolean
}

export interface SetView {
  type: typeof SET_VIEW
  payload: string
}

export interface ResetPagination {
  type: typeof RESET_PAGINATION
}

export interface SetSearch {
  type: typeof SET_SEARCH
  payload: string
}

export type LibraryActivitiesActions =
  | GetPremiumActivitiesRequest
  | GetPremiumActivitiesSuccess
  | GetPremiumActivitiesFailure
  | GetAllTagsRequest
  | GetAllTagsSuccess
  | GetAllTagsFailure
  | SetFilters
  | SetContactModalOpen
  | SetOnlyWithLicenses
  | SetView
  | ResetPagination
  | SetSearch
