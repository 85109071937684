import { API } from '@/api/lms'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { AppThunk } from '@/state/thunk'
import { pagesToLimit } from '@/legacy/utils/generalUtils'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import type {
  ListActivityStudentsRequest,
  ListActivityStudentsSuccess,
  ListClassroomStudentsRequest,
  ListClassroomStudentsSuccess,
} from '@/legacy/redux/types/students'
import {
  LIST_ACTIVITY_STUDENTS_FAILURE,
  LIST_ACTIVITY_STUDENTS_REQUEST,
  LIST_ACTIVITY_STUDENTS_SUCCESS,
  LIST_CLASSROOM_STUDENTS_FAILURE,
  LIST_CLASSROOM_STUDENTS_REQUEST,
  LIST_CLASSROOM_STUDENTS_SUCCESS,
} from '@/legacy/redux/types/students'

const StudentsActionCreators = {
  listClassroomStudents:
    (
      requestOrigins: ReduxRequestOrigin[],
      classroomID: number,
      pagination: { page: number; rowsPerPage: number },
      search?: string,
      next?: boolean,
      withSubgroups?: boolean
    ): AppThunk =>
    async (dispatch) => {
      const request: ListClassroomStudentsRequest = {
        type: LIST_CLASSROOM_STUDENTS_REQUEST,
        payload: {
          page: pagination.page,
          rowsPerPage: pagination.rowsPerPage,
          requestOrigins,
        },
      }
      dispatch(request)

      try {
        const params = {
          ...pagesToLimit(pagination),
          ...(search?.length && { search }),
          withSubgroups,
        }
        const {
          data: {
            description: { students, total },
          },
        } = await API.Students.getClassroomStudents(classroomID, params, 2)

        const success: ListClassroomStudentsSuccess = {
          type: LIST_CLASSROOM_STUDENTS_SUCCESS,
          payload: {
            requestOrigins,
            students: students ?? [],
            count: total,
            nextStudentIndex: next ? 0 : students.length - 1,
          },
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, LIST_CLASSROOM_STUDENTS_FAILURE, dispatch, { requestOrigins })
      }
    },
  listActivityStudents:
    (
      requestOrigins: ReduxRequestOrigin[],
      classroomID: number,
      activityID: number,
      pagination: { page: number; rowsPerPage: number }
    ): AppThunk =>
    async (dispatch) => {
      const request: ListActivityStudentsRequest = {
        type: LIST_ACTIVITY_STUDENTS_REQUEST,
        payload: {
          page: pagination.page,
          rowsPerPage: pagination.rowsPerPage,
          requestOrigins,
        },
      }
      dispatch(request)

      try {
        const params = {
          ...pagesToLimit(pagination),
        }
        const {
          data: {
            description: { students, total },
          },
        } = await API.Students.getActivityStudents(classroomID, activityID, params)

        const success: ListActivityStudentsSuccess = {
          type: LIST_ACTIVITY_STUDENTS_SUCCESS,
          payload: {
            requestOrigins,
            students,
            count: total,
          },
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, LIST_ACTIVITY_STUDENTS_FAILURE, dispatch, { requestOrigins })
      }
    },
}

export default StudentsActionCreators
