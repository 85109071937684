import type {
  GetClassroomsAsPrincipalFailure,
  GetClassroomsAsPrincipalRequest,
  GetClassroomsAsPrincipalSuccess,
  PrincipalClassroom,
} from '@/legacy/redux/types/classrooms'
import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type { GenericAction, GenericPayload } from '@/legacy/redux/utils'
import type { Pages } from '@/sections/content-creator/dashboard/types/dashboard'
import type {
  GetOrganizationReportCardsFailure,
  GetOrganizationReportCardsRequest,
  GetOrganizationReportCardsSuccess,
} from './apiInteractions'
import type { ConfiguredReportCard } from './entities'

export const TOGGLE_CLASSROOM = 'REPORT_CARD/CONFIG/TOGGLE_CLASSROOM'
export const SELECT_SINGLE_CLASSROOM = 'REPORT_CARD/CONFIG/SELECT_SINGLE_CLASSROOM'
export const DESELECT_ALL_CLASSROOMS = 'REPORT_CARD/CONFIG/DESELECT_ALL_CLASSROOMS'
interface ToggleClassroomPayload extends GenericPayload {
  classroom: PrincipalClassroom
}
export interface ToggleClassroom extends GenericAction {
  type: typeof TOGGLE_CLASSROOM
  payload: ToggleClassroomPayload
}

export interface SelectSingleClassroom extends GenericAction {
  type: typeof SELECT_SINGLE_CLASSROOM
  payload: ToggleClassroomPayload
}

export interface DeselectAllClassrooms extends GenericAction {
  type: typeof DESELECT_ALL_CLASSROOMS
}
export type configuredClassroomss = {
  id: number
  enableScores: boolean
}

export interface ReportCardConfigMainTabState {
  classrooms: PrincipalClassroom[]
  configuredClassrooms: configuredClassroomss[]
  configuredReportCards: ConfiguredReportCard[]
  loadingClassrooms: boolean
  loadingconfiguredClassrooms: boolean
  classroomsPagination: Pages // TODO: Define pages globally
  selectedClassrooms: PrincipalClassroom[]
  error: string
  search: string
}

export type ReportCardConfigMainTabAction =
  | SetField<'open' | 'deletionModalOpen', boolean>
  | ClearState
  | GetOrganizationReportCardsRequest
  | GetOrganizationReportCardsSuccess
  | GetOrganizationReportCardsFailure
  | GetClassroomsAsPrincipalRequest
  | GetClassroomsAsPrincipalSuccess
  | GetClassroomsAsPrincipalFailure
  | ToggleClassroom
  | SelectSingleClassroom
  | DeselectAllClassrooms
