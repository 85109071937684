import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ArrowRight } from '@/legacy/svgs'
import TextButton from '@/legacy/components/v1/atomic/v2/textButton'
import { useTranslation } from 'react-i18next'

const useClasses = makeStyles(() => ({
  container: {
    background: '#FFFFFF',
    border: '1px solid #DFDFDA',
    boxSizing: 'border-box',
    borderRadius: 8,
    padding: '14px 12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '135px',
  },
  iconContainer: ({ backgroundIcon }) => ({
    backgroundColor: backgroundIcon,
    height: 56,
    width: 56,
    minHeight: 56,
    minWidth: 56,
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 14,
  }),
  label: {
    fontSize: 14,
    lineHeight: '21px',
  },
  button: {
    fontSize: 14,
    lineHeight: '21px',
  },
  quantity: {
    fontWeight: 'bold',
    fontSize: 31,
    lineHeight: '42px',
    margin: '9px 0',
  },
  arrowRight: {
    width: 10,
  },
}))

const StatisticCard = (props) => {
  const { t } = useTranslation()
  const { Icon, label, quantity, onClick = () => {}, backgroundIcon } = props
  const classes = useClasses({ backgroundIcon })
  const quantityText = quantity
    ? Number(quantity).toLocaleString('es', { useGrouping: 'always' })
    : quantity === 0
    ? quantity
    : ''
  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        <Icon />
      </div>
      <div>
        <Typography className={classes.label}>{label}</Typography>
        <Typography className={classes.quantity}>{quantityText}</Typography>
        <TextButton
          className={classes.button}
          size="small"
          IconRight={ArrowRight}
          iconRightClassname={classes.arrowRight}
          onClick={onClick}
        >
          {t('generic.seeDetails')}
        </TextButton>
      </div>
    </div>
  )
}

export default StatisticCard
