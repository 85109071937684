import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/eb-admin/management/types/deleteAudienceModal'

const initialState: Types.DeleteAudienceModalState = {
  isOpenModal: false,
  audienceToDelete: {
    cosmos: [],
    createdAt: '',
    id: 0,
    name: '',
  },
  status: REQUEST_STATUS.IDLE,
}

const deleteAudienceModalReducer = (
  state: Types.DeleteAudienceModalState = initialState,
  action: Types.DeleteAudienceModalAction
): Types.DeleteAudienceModalState => {
  switch (action.type) {
    case Types.MODAL_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isOpenModal: true,
      }
    }
    case Types.MODAL_TYPES.CLOSE_MODAL: {
      return {
        ...state,
        isOpenModal: false,
      }
    }
    case Types.MODAL_TYPES.SET_AUDIENCE_TO_DELETE: {
      const { audienceToDelete } = action.payload

      return {
        ...state,
        audienceToDelete,
      }
    }
    case Types.MODAL_TYPES.DELETE_AUDIENCE_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.MODAL_TYPES.DELETE_AUDIENCE_SUCCESS: {
      return initialState
    }
    case Types.MODAL_TYPES.DELETE_AUDIENCE_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.MODAL_TYPES.CLEAN_MODAL: {
      return initialState
    }
    default:
      return state
  }
}
export default deleteAudienceModalReducer
