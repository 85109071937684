import React from 'react'
import { makeStyles } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Hidden from '@mui/material/Hidden'
import Fab from '@mui/material/Fab'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import AddIcon from '@mui/icons-material/Add'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Tabs from './tabsV2'
import UserAvatar from '@/legacy/components/v1/atomic/v1/userAvatar'
import WrittenExam from '@/legacy/components/v1/svgs/header/writtenExam'
import WrittenExamActive from '@/legacy/components/v1/svgs/header/writtenExamActive'
import OralExam from '@/legacy/components/v1/svgs/header/oralExam'
import OralExamActive from '@/legacy/components/v1/svgs/header/oralExamActive'
import NewMessages from '@/legacy/components/v1/svgs/header/newMessages'
import Messages from '@/legacy/components/v1/svgs/header/messages'
import Logo from '@/legacy/components/v1/svgs/logo'
import { Collapse, Popover } from '@mui/material'
import ClassroomList from './classroomList'
import CustomTooltip from './customTooltip'

const drawerBreakPoint = 690

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.25)',
    height: 72,
    background: '#fff',
    justifyContent: 'center',
  },
  tabsContainer: {
    width: 'fit-content',
    margin: 'auto',
    display: 'none',
    [`@media (min-width: ${drawerBreakPoint}px)`]: {
      display: 'block',
    },
  },
  logoContainer: ({ button }) => ({
    marginRight: button ? 207 : 0,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  }),
  menuButton: {
    background: 'none',
  },
  title: {
    flexGrow: 1,
    justifyItems: 'center',
  },
  buttonContainer: {
    marginRight: 20,
    display: 'inline',
    minWidth: '190px',
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  openDrawerButton: {
    marginRight: 10,
    [`@media (min-width: ${drawerBreakPoint}px)`]: {
      display: 'none',
    },
  },
  drawerCloseIcon: {
    display: 'inline-block',
    textAlign: 'left',
    width: '12px',
    position: 'relative',
    left: '31px',
    top: '23px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& span': {
      display: 'inline-block',
      width: 'auto',
      '& svg': {
        width: '12px',
        height: '12px',
      },
    },
  },
  drawerListContainer: {
    marginTop: '50px',
  },
  drawerListItem: {
    minWidth: 262,
    padding: '14px 12px',
    boxSizing: 'border-box',
    position: 'relative',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      bottom: 0,
      left: 12,
      right: 12,
      borderBottom: '1px solid #DFDFDA',
    },
  },
  drawerListItemIcon: {
    minWidth: '40px',
  },
  drawerListItemText: {
    flex: 'unset',
    flexDirection: 'column',
    maxWidth: '200px',
    '& span': {
      maxWidth: '200px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  drawerClassroomName: {
    display: 'block',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '23px',
  },
  drawerSchoolName: {
    display: 'block',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '16px',
    marginTop: '4px',
  },
  messageButton: {
    padding: 12,
    '&:hover': {
      background: 'transparent',
    },
  },
  popover: {
    left: '0 !important',
    minWidth: '100vw',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.25)',
  },
  fab: {
    position: 'fixed',
    bottom: '44px !important',
    right: '28px',
    zIndex: 12,
  },
}))

export default function TeacherMenuAppBarV2(props) {
  const {
    newMessages = false,
    openMessages,
    tabs,
    tabsChange,
    tabValue,
    name,
    lastName,
    Button,
    classrooms,
    onClassroomClick,
    menuItems,
    loading,
    showMessageButton,
    fabAction,
  } = props
  const { showWrittenExamButton, onWrittenExamClick, writtenExamSelected } = props
  const { showOralExamButton, onOralExamClick, oralExamSelected } = props

  const classes = useStyles({ button: !!Button })

  const [dropDownAnchorEl, setDropDownAnchorEl] = React.useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [listOpen, setListOpen] = React.useState(false)
  const open = Boolean(anchorEl)

  const avatarName = name + ' ' + lastName

  const handleClickOnLogo = () => props.onLogoClick()

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItem = (clickFn) => {
    handleClose()
    clickFn()
  }

  const handleDropdownOpen = (e) => {
    setDropDownAnchorEl(e.currentTarget)
  }

  const handleDropdownClose = (e) => {
    setDropDownAnchorEl(null)
  }

  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  const handleToggleList = () => {
    setListOpen(!listOpen)
  }

  return (
    <>
      <AppBar position="fixed" color="transparent" className={classes.appBar}>
        <Toolbar>
          <IconButton
            className={classes.openDrawerButton}
            onClick={handleDrawerOpen}
            color="inherit"
            aria-label="Menu"
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            disableRipple
            edge="start"
            aria-label="menu"
            color="inherit"
            onClick={handleClickOnLogo}
            className={classes.logoContainer}
          >
            <Avatar className={classes.menuButton}>
              <Logo alt="logo" />
            </Avatar>
          </IconButton>
          <div className={classes.title}>
            <div className={classes.tabsContainer}>
              <Tabs
                onChange={tabsChange}
                tabs={tabs}
                value={tabValue}
                handleDropdownOpen={handleDropdownOpen}
                handleDropdownClose={handleDropdownClose}
                dropdownOpen={Boolean(dropDownAnchorEl)}
              />
            </div>
          </div>
          <div className={classes.userContainer}>
            {Button && (
              <Hidden smDown>
                <div className={classes.buttonContainer}>
                  <Button />
                </div>
              </Hidden>
            )}
            {showMessageButton && (
              <IconButton
                className={classes.messageButton}
                disableRipple
                size="small"
                color="inherit"
                onClick={openMessages}
              >
                <CustomTooltip title="Abrir chat" topOffset={6}>
                  {newMessages ? <NewMessages /> : <Messages />}
                </CustomTooltip>
              </IconButton>
            )}
            {showWrittenExamButton && (
              <IconButton
                disableRipple
                className={classes.messageButton}
                onClick={onWrittenExamClick}
                color="inherit"
                aria-label="Menu"
              >
                <CustomTooltip title="Abrir exámenes escritos">
                  {writtenExamSelected ? <WrittenExamActive /> : <WrittenExam />}
                </CustomTooltip>
              </IconButton>
            )}
            {showOralExamButton && (
              <IconButton
                disableRipple
                className={classes.messageButton}
                onClick={onOralExamClick}
                color="inherit"
                aria-label="Menu"
              >
                <CustomTooltip title="Abrir exámenes orales">
                  {oralExamSelected ? <OralExamActive /> : <OralExam />}
                </CustomTooltip>
              </IconButton>
            )}
            <IconButton
              disableRipple
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              id="profile-button"
            >
              <UserAvatar name={avatarName} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              {menuItems.map(({ onClick, label, id }, index) => (
                <MenuItem key={index} id={id} onClick={() => handleMenuItem(onClick)}>
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Popover
        elevation={0}
        classes={{ paper: classes.popover }}
        open={Boolean(dropDownAnchorEl)}
        anchorEl={dropDownAnchorEl}
        onClose={handleDropdownClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ClassroomList
          onMouseLeave={handleDropdownClose}
          classrooms={classrooms}
          onClassroomClick={onClassroomClick}
          loading={loading}
        />
      </Popover>
      <Drawer variant="temporary" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <IconButton
          disableRipple
          size="small"
          color="inherit"
          onClick={handleDrawerClose}
          className={classes.drawerCloseIcon}
        >
          <CloseIcon />
        </IconButton>
        <List component="nav" className={classes.drawerListContainer}>
          {tabs.map(({ label, Icon, value, useDropdown, onClick }) =>
            useDropdown ? (
              <React.Fragment key={value}>
                <ListItem button onClick={handleToggleList} className={classes.drawerListItem}>
                  <ListItemIcon className={classes.drawerListItemIcon}>
                    {Icon({ active: value === tabValue })}
                  </ListItemIcon>
                  <ListItemText className={classes.drawerListItemText}>{label}</ListItemText>
                  <ArrowDropDownIcon />
                </ListItem>
                <Collapse in={listOpen} timeout="auto" unmountOnExit>
                  <List component="div">
                    {classrooms.map((classroom) => (
                      <ListItem
                        key={classroom.id}
                        button
                        onClick={() => {
                          setDrawerOpen(false)
                          onClassroomClick(classroom.id)
                        }}
                        className={classes.drawerListItem}
                      >
                        <ListItemText className={classes.drawerListItemText}>
                          <span className={classes.drawerClassroomName}>
                            {classroom.classroomName}
                          </span>
                          <span className={classes.drawerSchoolName}>{classroom.schoolName}</span>
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ) : (
              <ListItem
                key={value}
                button
                onClick={() => {
                  setDrawerOpen(false)
                  onClick()
                }}
                className={classes.drawerListItem}
              >
                <ListItemIcon className={classes.drawerListItemIcon}>
                  {Icon({ active: value === tabValue })}
                </ListItemIcon>
                <ListItemText className={classes.drawerListItemText}>{label}</ListItemText>
              </ListItem>
            )
          )}
        </List>
      </Drawer>
      <Hidden mdUp>
        <Fab
          color="primary"
          className={classes.fab}
          aria-label="Crear actividad"
          onClick={fabAction}
        >
          <AddIcon />
        </Fab>
      </Hidden>
    </>
  )
}
