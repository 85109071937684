import config from '@aula/config'
import firebase from 'firebase/app'
import 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyAzswZByehtTuIYz6m4HUoqdCmaiqdh-Ro',
  authDomain: 'lateral-rider-273116.firebaseapp.com',
  databaseURL: 'https://lateral-rider-273116.firebaseio.com',
  projectId: 'lateral-rider-273116',
  storageBucket: 'lateral-rider-273116.appspot.com',
  messagingSenderId: '35003362827',
  appId: '1:35003362827:web:c7a5e8f74b5b56f7ada10e',
}

const firebaseWebPushKey =
  'BEhqEqS2Cu5SRT7O9x2tD2GFZ94luGl80Akp1LPt8ZUc6GBdovIpjVc5cQMXFP085uaoe6ZhFJYjsxT-RrSGylc'

export const initialize = () => {
  if (!config.FIREBASE_ENABLED) return
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
  } else {
    firebase.app()
  }

  let messaging: firebase.messaging.Messaging | null = null
  if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging()

    messaging.onMessage((payload) => {
      const notificationTitle = payload.notification.title
      const notificationOptions = {
        body: payload.notification.body,
        icon: '../logo.png',
      }

      if (!('Notification' in window)) {
        console.warn('El navegador no soporta notificaciones')
      } else if (Notification.permission === 'granted') {
        const notification = new Notification(notificationTitle, notificationOptions)
        notification.onclick = (event) => {
          event.preventDefault()
          window.open(payload.notification.clickAction, '_blank')
          notification.close()
        }
      }
    })
  }
}

export const getFirebaseToken = () => {
  if (!config.FIREBASE_ENABLED) return Promise.resolve(null)
  if (!firebase.messaging.isSupported()) return Promise.resolve(null)

  if ('serviceWorker' in navigator) {
    return navigator.serviceWorker
      .register('./../firebase-messaging-sw.js')
      .then((registration) => {
        return firebase
          .messaging()
          .getToken({ vapidKey: firebaseWebPushKey, serviceWorkerRegistration: registration })
          .then((currentToken) => {
            if (currentToken) {
              return { currentToken, error: null }
            } else {
              throw new Error('')
            }
          })
          .catch((error) => ({ currentToken: null, error }))
      })
      .catch((error) => ({ currentToken: null, error }))
  }
  return Promise.resolve(null)
}
