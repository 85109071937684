import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTeacher = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={36}
    height={36}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M36 18c0-9.94-8.06-18-18-18S0 8.06 0 18s8.06 18 18 18 18-8.06 18-18Z" fill="#FFB800" />
    <path
      d="M20.822 20.467h-5.644c.878 2.655.072 3.867.072 3.867l1.952.442h1.596l1.952-.442s-.806-1.212.072-3.867Z"
      fill="#E2A379"
    />
    <path
      d="M18.007 28.302c3.258 0 2.743-3.968 2.743-3.968s-.646 1.255-2.765 1.255c-2.118 0-2.72-1.255-2.72-1.255s-.515 3.968 2.742 3.968Z"
      fill="#E2A379"
    />
    <path
      d="M17.978 24.189c-5.6 0-10.092 1.603-10.621 3.576-.24.886-.508 2.554-.733 4.172a17.938 17.938 0 0 0 11.383 4.056c4.317 0 8.278-1.524 11.383-4.056-.232-1.618-.5-3.294-.732-4.172-.544-1.973-5.086-3.576-10.68-3.576Z"
      fill="#E2A379"
    />
    <path d="M25.328 29.608H10.672l.922-18.5L24.319 9.62l1.009 19.988Z" fill="#141414" />
    <path d="M21.12 23.572h-6.24v1.814h6.24v-1.814Z" fill="#DEE0E2" />
    <path
      d="M17.978 24.189c-5.6 0-10.092 1.603-10.621 3.576-.24.886-.508 2.554-.733 4.172a17.939 17.939 0 0 0 11.383 4.056c4.317 0 8.278-1.524 11.383-4.056-.232-1.618-.5-3.294-.732-4.172-.544-1.973-5.086-3.576-10.68-3.576Z"
      fill="#2F2565"
    />
    <path
      d="M21.323 24.392a27.29 27.29 0 0 0-3.345-.196 26.57 26.57 0 0 0-3.3.196L18 30.994l3.323-6.602Z"
      fill="#DADDE0"
    />
    <path
      d="m12.196 25.915 2.684-2.343 2.322 4.636s-1.828.515-1.85.493l-3.156-2.786ZM23.804 25.915l-2.684-2.343-2.322 4.636s1.828.515 1.85.493l3.156-2.786Z"
      fill="#DADDE0"
    />
    <path
      d="m18 29.899-2.742-5.572s.667-.9 2.713-.9c2.046 0 2.764.907 2.764.907L18 29.899Z"
      fill="#E2A379"
    />
    <path
      d="M20.822 20.467h-5.644c.878 2.655.072 3.867.072 3.867s1.19.442 2.75.442 2.75-.442 2.75-.442-.806-1.212.072-3.867Z"
      fill="#E2A379"
    />
    <path
      d="M23.79 12.711c0-6.704-2.59-8.242-5.79-8.242-3.192 0-5.79 1.538-5.79 8.242 0 2.27.407 4.048 1.016 5.42 1.335 3.018 3.65 4.07 4.774 4.07 1.125 0 3.432-1.052 4.774-4.07.61-1.372 1.016-3.15 1.016-5.42Z"
      fill="#F4B382"
    />
    <path
      d="M20.946 5.158c-1.176-1.24-3.751-.9-3.751-.9-4.992 0-5.507 5.362-5.507 5.362l-.094 1.488c0 1.03.551 2.307 1.088 3.576 0 0-.109-4.186 2.402-5.303 2.51-1.117 6.681-.849 6.5-2.133-.007-.065.109-1.292-.638-2.09Z"
      fill="#141414"
    />
    <path
      d="M19.574 4.36c.77-.355 2.59.552 3.505 1.908a7.518 7.518 0 0 1 1.24 3.352s.022 3.07-.74 4.97c0 0-2.19-6.334-4.578-9.04.008-.036.08-.958.573-1.19Z"
      fill="#141414"
    />
    <path
      d="M11.659 15.069c.312 1.828.696 1.908 1.255 1.814l-.588-3.976c-.558.101-.979.34-.667 2.162ZM23.688 12.914 23.1 16.89c.552.094.944.007 1.255-1.814.305-1.828-.116-2.068-.667-2.162Z"
      fill="#F4B382"
    />
    <path
      d="M19.937 19.14s-1.03.696-1.937.696-1.937-.697-1.937-.697c0-.101.82-.384 1.168-.435.218-.037.769.181.769.181s.544-.21.755-.181c.355.05 1.182.435 1.182.435Z"
      fill="#CC785E"
    />
    <path
      d="M19.937 19.139s-1.03.98-1.937.98-1.937-.98-1.937-.98.472.109 1.422.05c.16-.006.37.117.508.117.123 0 .305-.13.442-.123 1.002.058 1.502-.044 1.502-.044Z"
      fill="#C16952"
    />
    <path
      d="m13.538 14.119-1.016-.051.044-.21 1.008.05-.036.21ZM22.44 14.119l-.043-.21 1.008-.052.044.21-1.009.052Z"
      fill="#141414"
    />
    <path
      d="M17.028 13.016h-3.33a.276.276 0 0 0-.276.275v1.858c0 .152.123.276.276.276h3.33a.276.276 0 0 0 .276-.276v-1.865a.274.274 0 0 0-.276-.268Zm-.138 1.828a.214.214 0 0 1-.218.218H14.04a.214.214 0 0 1-.218-.218v-1.233c0-.124.094-.218.218-.218h2.633c.124 0 .218.094.218.218v1.233ZM22.302 13.016h-3.33a.276.276 0 0 0-.276.275v1.858c0 .152.124.276.276.276h3.33a.276.276 0 0 0 .276-.276v-1.865a.274.274 0 0 0-.276-.268Zm-.13 1.828a.214.214 0 0 1-.218.218H19.32a.214.214 0 0 1-.217-.218v-1.233c0-.124.094-.218.217-.218h2.634c.123 0 .218.094.218.218v1.233Z"
      fill="#141414"
    />
    <path
      d="M17.993 13.466c-.813 0-.893.5-.87.754-.015.21.035.13.035.13a.942.942 0 0 1 .842-.529.95.95 0 0 1 .842.53s.05.08.036-.131c.007-.254-.073-.755-.885-.755Z"
      fill="#141414"
    />
    <path
      d="M22.172 14.844a.214.214 0 0 1-.218.218H19.32a.214.214 0 0 1-.217-.218v-1.233c0-.124.094-.218.217-.218h2.634c.123 0 .218.094.218.218v1.233ZM16.89 14.844a.214.214 0 0 1-.218.218H14.04a.214.214 0 0 1-.218-.218v-1.233c0-.124.094-.218.218-.218h2.633c.124 0 .218.094.218.218v1.233Z"
      fill="#E8D3BB"
    />
  </svg>
)
export default SvgTeacher
