import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  subjectContainer: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #DFDFDA',
    background: '#FFF',
    borderRadius: 16,
    padding: '3px 8px',
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'background-color 100ms',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
    '&:active': {
      backgroundColor: '#f4f4f4',
    },
  },
  subjectDot: ({ color }) => ({
    width: 8,
    minWidth: 8,
    height: 8,
    minHeight: 8,
    marginRight: 4,
    borderRadius: 4,
    background: color,
  }),
  text: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}))

const SubjectChip = ({
  subject: { name, color },
  className = '',
  maxCharacters = null,
  ...props
}) => {
  // adds # to the color if it isn't there
  if (color?.length > 0 && color[0] !== '#') {
    color = '#' + color
  }

  const classes = useStyles({ color })

  return (
    <div className={clsx(classes.subjectContainer, className)}>
      <div className={classes.subjectDot} />
      <Typography className={classes.text}>
        {maxCharacters && name?.length > maxCharacters
          ? `${name.substr(0, maxCharacters)}...`
          : name}
      </Typography>
    </div>
  )
}

SubjectChip.defaultProps = {
  subject: {},
  className: '',
}

export default SubjectChip
