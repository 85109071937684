// cosmos plataform id
export enum COSMOS_KEY {
  AULA = 'aula',
  CABA = 'caba',
  LA_RIOJA = 'larioja',
  LA_RIOJA_MIN_SALUD = 'minsaludlarioja',
  LETS_GO_FOR_MORE = 'letsgoformore',
  TED = 'ted',
  ATLAS = 'atlas',
  AGENPIA = 'agenpia',
}

// available cosmos names
export enum COSMOS_NAME {
  AULA = 'AULA',
  CABA = 'CABA',
  LA_RIOJA = 'LA_RIOJA',
  LA_RIOJA_MIN_SALUD = 'LA_RIOJA_MIN_SALUD',
  LETS_GO_FOR_MORE = 'LETS_GO_FOR_MORE',
  TED = 'TED',
  ATLAS = 'ATLAS',
  AGENPIA = 'AGENPIA',
}
