import { apiPrivate } from './..'
import { url } from '@aula/config'

export const ExorAPI = {
  createInstance,
  listInstance,
  getStudents,
  getPending,
  generateEXORToken,
}

/**
 * Creates an EXOR instance for a student.
 *
 * @role Teacher
 * @param activityID
 * @param evaluateeID
 */
function createInstance(activityID: number, evaluateeID: number) {
  return apiPrivate.put(url + `/v1/exor/${activityID}/instances/${evaluateeID}`)
}

/**
 * Lists instances for a given EXOR.
 *
 * @role Student
 * @param activityID
 */
function listInstance(activityID: number) {
  return apiPrivate.get(url + `/v1/exor/${activityID}/instances`)
}

/**
 * onlyTotal === false: Returns student count for ongoing EXORs.
 * onlyTotal === true: Lists students for all ongoing EXORs.
 *
 * @role Teacher
 * @param onlyTotal
 */
function getStudents(onlyTotal: boolean) {
  return apiPrivate.get(url + `/v1/views/teacher/exor/students?only_total=${onlyTotal}`)
}

/**
 * onlyTotal === false: Returns pending EXOR activity count.
 * onlyTotal === true: Lists pending EXOR activities.
 *
 * @role Teacher
 * @param onlyTotal
 */
function getPending(onlyTotal: boolean) {
  return apiPrivate.get(url + `/v1/views/teacher/exor/pending?only_total=${onlyTotal}`)
}

function generateEXORToken(activityID: number): Promise<string> {
  return apiPrivate
    .post(url + `/v1/exor/${activityID}/token`)
    .then((response) => response.data.description)
    .catch(() => null)
}
