export const SET_OPEN = 'TEACHER_FILES/OPEN_TEACHER_FILES_MODAL'
export const SET_CLOSE = 'TEACHER_FILES/CLOSE_TEACHER_FILES_MODAL'
export const ADD_FILES_TO_UPLOAD = 'TEACHER_FILES/ADD_FILES_TO_UPLOAD'
export const REMOVE_FILE_TO_UPLOAD = 'TEACHER_FILES/REMOVE_FILE_TO_UPLOAD'
export const UPLOAD_FILES_REQUEST = 'TEACHER_FILES/UPLOAD_FILES_REQUEST'
export const UPLOAD_FILES_SUCCESS = 'TEACHER_FILES/UPLOAD_FILES_SUCCESS'
export const UPLOAD_FILES_FAILURE = 'TEACHER_FILES/UPLOAD_FILES_FAILURE'
export const REMOVE_UPLOADED_FILE = 'TEACHER_FILES/REMOVE_UPLOADED_FILE'
export const SET_FILES = 'TEACHER_FILES/SET_FILES'

export interface UploadedFile {
  id: number
  name: string
  url: string
  description: string
}

export interface TeacherFilesState {
  open: boolean
  error: string
  loading: boolean
  files: UploadedFile[]
  filesToUpload: File[]
}

export interface SetOpen {
  type: typeof SET_OPEN
}

export interface SetClose {
  type: typeof SET_CLOSE
}

export interface AddFilesToUpload {
  type: typeof ADD_FILES_TO_UPLOAD
  payload: {
    files: File[]
  }
}

export interface RemoveFileToUpload {
  type: typeof REMOVE_FILE_TO_UPLOAD
  payload: {
    file: File
  }
}

export interface UploadFilesRequest {
  type: typeof UPLOAD_FILES_REQUEST
}

export interface UploadFilesSuccess {
  type: typeof UPLOAD_FILES_SUCCESS
  payload: {
    files: UploadedFile[]
  }
}

export interface UploadFilesFailure {
  type: typeof UPLOAD_FILES_FAILURE
  payload: {
    error: string
  }
}

export interface RemoveUploadedFile {
  type: typeof REMOVE_UPLOADED_FILE
  payload: {
    fileID: number
  }
}

export interface SetFiles {
  type: typeof SET_FILES
  payload: {
    files: UploadedFile[]
  }
}

export type TeacherFilesTypes =
  | SetOpen
  | SetClose
  | AddFilesToUpload
  | RemoveFileToUpload
  | UploadFilesRequest
  | UploadFilesSuccess
  | UploadFilesFailure
  | RemoveUploadedFile
  | SetFiles
