import { TablePagination } from '@mui/material'
import { useTranslation } from 'react-i18next'

export interface Pages {
  more: boolean
  rowsPerPage: number
  page: number
  count: number
}

interface DynamicTablePaginationProps {
  pagination: Pages
  onPageChange: (newPage: number) => void
}

const MoreTablePagination = (props: DynamicTablePaginationProps) => {
  const { pagination, onPageChange } = props
  const { t } = useTranslation()

  return (
    <TablePagination
      count={pagination.more ? -1 : pagination.page * pagination.rowsPerPage + pagination.count}
      component="div"
      labelDisplayedRows={({ from, to }) =>
        `${from}-${to} ${t('moment.of')}` +
        (pagination.more ? ` ${`${t('moment.moreOf')}`}` : '') +
        ` ${to}`
      }
      rowsPerPageOptions={[]}
      rowsPerPage={pagination.rowsPerPage}
      page={pagination.page}
      onPageChange={(_, newPage) => onPageChange(newPage)}
      slotProps={{
        actions: {
          nextButton: {
            title: t('generic.next'),
            disabled: !pagination.more,
          },
          previousButton: {
            title: t('generic.behind'),
          },
        },
      }}
    />
  )
}

export default MoreTablePagination
