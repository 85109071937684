import { url } from '@aula/config'
import { ApiPrivateNotIntercepted } from '@/legacy/services/api/api'

type ImportOrganizationResponse = {}

/**
 * Importa una institucion desde el dashboard del admin
 * @param  {File} file Archivo a importar de formato .xlsx
 * @param  {boolean} allowExistingUsers
 */
export const importOrganization = (file: File, allowExistingUsers: boolean) => {
  const form = new FormData()
  form.append('file', file)
  form.append('allow_existing_users', allowExistingUsers.toString())

  return ApiPrivateNotIntercepted.post<ImportOrganizationResponse>(url + '/v3/admin/import', form)
}
