export const ROBOTS_TOKEN_REQUEST = 'ROBOTS/ROBOTS_TOKEN_REQUEST'
export const ROBOTS_TOKEN_SUCCESS = 'ROBOTS/ROBOTS_TOKEN_SUCCESS'
export const ROBOTS_TOKEN_FAILURE = 'ROBOTS/ROBOTS_TOKEN_FAILURE'

export const ADD_ROBOTS_EVENT_REQUEST = 'ROBOTS/ADD_ROBOTS_EVENT_REQUEST'
export const ADD_ROBOTS_EVENT_SUCCESS = 'ROBOTS/ADD_ROBOTS_EVENT_SUCCESS'
export const ADD_ROBOTS_EVENT_FAILURE = 'ROBOTS/ADD_ROBOTS_EVENT_FAILURE'

export interface RobotsTokenRequest {
  type: typeof ROBOTS_TOKEN_REQUEST
}

export interface RobotsTokenSuccess {
  type: typeof ROBOTS_TOKEN_SUCCESS
}

export interface RobotsTokenFailure {
  type: typeof ROBOTS_TOKEN_FAILURE
  payload: {
    error: string
  }
}

export interface AddRobotsEventRequest {
  type: typeof ADD_ROBOTS_EVENT_REQUEST
}

export interface AddRobotsEventSuccess {
  type: typeof ADD_ROBOTS_EVENT_SUCCESS
}

export interface AddRobotsEventFailure {
  type: typeof ADD_ROBOTS_EVENT_FAILURE
}

export type RobotsState = {
  robotsLogin: boolean
  loadingToken: boolean
  error: string
}

export type RobotsActions =
  | RobotsTokenRequest
  | RobotsTokenSuccess
  | RobotsTokenFailure
  | AddRobotsEventRequest
  | AddRobotsEventSuccess
  | AddRobotsEventFailure
