import { REQUEST_STATUS } from '@/legacy/redux/types/status';
import * as Types from '../../types/organizations/cancelOrgDeletionModal';

const initialState: Types.CancelOrgDeletionModalState = {
    isOpenModal: false,
    selectedOrganization: {
      name: '',
      id: 0,
    },
    status: REQUEST_STATUS.IDLE,
}

const cancelOrgDeletionModalReducer = (
    state: Types.CancelOrgDeletionModalState = initialState,
    action: Types.CancelOrgDeletionModalAction,
): Types.CancelOrgDeletionModalState => {

    switch (action.type) {
        case Types.CANCEL_ORG_DELETION_MODAL_TYPES.OPEN_MODAL: {
            return {
                ...state,
                isOpenModal: true,
                selectedOrganization: action.payload,
            }
        }
        case Types.CANCEL_ORG_DELETION_MODAL_TYPES.CLEAN_MODAL: {
            return initialState;
        }
        case Types.CANCEL_ORG_DELETION_MODAL_TYPES.CANCEL_ORG_DELETION_REQUEST: {
            return {
                ...state,
                status: REQUEST_STATUS.LOADING,
            }
        }
        case Types.CANCEL_ORG_DELETION_MODAL_TYPES.CANCEL_ORG_DELETION_SUCCESS: {
            return initialState;
        }
        case Types.CANCEL_ORG_DELETION_MODAL_TYPES.CANCEL_ORG_DELETION_FAILURE: {
            return {
                ...state,
                status: REQUEST_STATUS.FAILURE,
            }
        }
        default: return state;
    }
    
};

export default cancelOrgDeletionModalReducer;