import type { Theme } from '@mui/material'
import { IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { CustomTooltip } from '@/legacy/components/v1'
import type { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    height: 56,
    minHeight: 56,
    width: '100%',
    background: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'row',
  },
  mainContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 24,
    paddingRight: 24,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 5,
  },
  title: {
    color: 'white',
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
  },
  subtitle: {
    color: 'white',
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
  },
  closeIconContainer: {
    width: 56,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeIcon: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
  },
}))

export type DrawerHeaderProps = {
  title: string
  subtitle?: string
  onClose: () => void
  closeTooltip?: string
}

const DrawerHeader = ({
  title,
  subtitle,
  onClose,
  closeTooltip,
}: DrawerHeaderProps): ReactElement => {
  const C = useStyles()
  const { t } = useTranslation()
  return (
    <div className={C.container}>
      <div className={C.mainContainer}>
        <div className={C.titleContainer}>
          <span className={C.title}>{title}</span>
          {subtitle && <span className={C.subtitle}>{subtitle}</span>}
        </div>
      </div>
      <div className={C.closeIconContainer}>
        <IconButton onClick={onClose}>
          <CustomTooltip topOffset={4} title={closeTooltip || t('generic.close')}>
            <CloseIcon className={C.closeIcon} />
          </CustomTooltip>
        </IconButton>
      </div>
    </div>
  )
}

export default DrawerHeader
