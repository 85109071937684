import { combineReducers } from 'redux'
import drawer from './reducers/drawer'
import books from './reducers/books'
import form from './reducers/form'
import entities from './reducers/entities'
import activities from './reducers/activities'
import publication from './reducers/publication'

export default combineReducers({
  drawer,
  books,
  form,
  entities,
  activities,
  publication,
})
