import types from '@/sections/teacher/activities/types'
import { apiPrivate } from '@/api'
import { url } from '@aula/config'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import moment from 'moment'
import { addNewCall, getLastCall, TEACHER_CLASSROOM_ACTIVITIES } from '@/legacy/debounce'
import { ClassroomsAPI } from '@/api/lms/classrooms'

const actions = {
  setWeekDays: (startDate, endDate, classroom) => (dispatch) => {
    dispatch({ type: types.SET_WEEK_DAYS, payload: { startDate: startDate, endDate: endDate } })
    dispatch(actions.getClassroomActivities(classroom, startDate))
  },
  setSelectedSubjects: (selectedSubjects) => (dispatch) => {
    dispatch({ type: types.SET_SELECTED_SUBJECTS, payload: { selectedSubjects } })
    dispatch(actions.updateFilters())
  },

  setOnlyMyActivities: (onlyMyActivities) => (dispatch) => {
    dispatch({ type: types.SET_ONLY_MY_ACTIVITIES, payload: { onlyMyActivities } })
    dispatch(actions.updateFilters())
  },

  updateFilters: () => (dispatch, getState) => {
    const { user, teacher } = getState()
    const activitiesReducer = teacher.activities.activities
    const teacherID = user.user.id

    const { selectedSubjects, activities, onlyMyActivities } = activitiesReducer
    const hasChosenAllSubjects = selectedSubjects.length === 1

    const filteredByOwnSubjects = activities.filter(({ teachers }) => {
      return onlyMyActivities ? teachers.some(({ id }) => id === teacherID) : true
    })

    const filteredActivities = hasChosenAllSubjects
      ? filteredByOwnSubjects
      : filteredByOwnSubjects.filter(({ subject }) =>
          selectedSubjects.some(({ id }) => subject.id === id)
        )

    dispatch({ type: types.SET_UPDATE_FILTERS, payload: { filteredActivities } })
  },

  getClassroomActivities:
    (classroom, startDate, updateFilters = true, silentLoading = false) =>
    (dispatch) => {
      dispatch({ type: types.ACTIVITIES_REQUEST })
      const timer = setTimeout(
        () =>
          dispatch({
            type: types.ACTIVITIES_REQUEST_TIMEOUT,
            payload: { silentLoading },
          }),
        500
      )

      const params = {}
      if (startDate) params.date = startDate.format('YYYY-MM-DD')

      const call = addNewCall(TEACHER_CLASSROOM_ACTIVITIES)

      return apiPrivate
        .get(url + `/v2/classroom/${classroom}/dashboard/teacher`, { params })
        .then((response) => {
          if (call !== getLastCall(TEACHER_CLASSROOM_ACTIVITIES)) return
          if (timer) clearTimeout(timer)
          const { activities, classrooms: classroom, role } = response.data.description
          dispatch(actions.getClassroomActivitiesSuccess(activities, classroom, role))
          if (updateFilters) dispatch(actions.updateFilters())
        })
        .catch((error) => {
          handleErrorsWithAction(error, types.ACTIVITIES_FAILURE, dispatch)
        })
    },
  getClassroomActivitiesSuccess: (activities, classroom, role) => ({
    type: types.ACTIVITIES_SUCCESS,
    payload: {
      activities,
      classroom,
      role,
    },
  }),
  getSubjects: (classroomId) => async (dispatch) => {
    dispatch({ type: types.SUBJECTS_REQUEST })

    try {
      const { data } = await ClassroomsAPI.getClassroomSubjects(classroomId)
      const { subjects } = data.description
      dispatch(actions.getSubjectsSuccess(subjects))
    } catch (error) {
      handleErrorsWithAction(error, types.SUBJECTS_FAILURE, dispatch)
    }
  },
  getSubjectsSuccess: (subjects) => ({
    type: types.SUBJECTS_SUCCESS,
    payload: {
      subjects,
    },
  }),

  changeDate: (activityID, newDate) => (dispatch, getState) => {
    dispatch({ type: types.CHANGE_DATE_REQUEST })

    const state = getState().teacher.activities.activities
    const classroomID = state.classroom.id
    let activity = null
    for (let i = 0; i < state.activities.length; i++) {
      if (state.activities[i].id === activityID) {
        activity = state.activities[i]
        break
      }
    }
    if (!activity) {
      return dispatch({
        type: types.CHANGE_DATE_FAILURE,
        payload: {
          error: 'teacher.activities.activityNoFind',
        },
      })
    }

    const date = newDate.hours(0).minutes(0).seconds(0).milliseconds(0)

    const publicationDate = moment(activity.publicationDate)

    const dates = {
      date: date.format(),
      publicationDate: publicationDate.isAfter(date) ? date.format() : activity.publicationDate,
    }

    if (activity.startDate !== '') {
      const startDate = moment(activity.startDate)
      if (startDate.isValid()) {
        dates.start = date.clone().hours(startDate.hours()).minutes(startDate.minutes()).format()
      }
    }
    if (activity.endDate !== '') {
      const endDate = moment(activity.endDate)
      if (endDate.isValid()) {
        dates.end = date.clone().hours(endDate.hours()).minutes(endDate.minutes()).format()
      }
    }
    if (activity.deadline !== '') {
      const deadline = moment(activity.deadline)
      if (deadline.isValid()) {
        if (deadline.isBefore(date)) {
          dates.deadline = date.format()
        } else {
          dates.deadline = deadline.format()
        }
      }
    }

    const form = { dates }

    return apiPrivate
      .patch(url + `/v1/classroom/${classroomID}/activities/${activity.id}`, form)
      .then((response) => {
        const { activity } = response.data.description
        dispatch(actions.changeDateSuccess(activity))
        dispatch(actions.getClassroomActivities(classroomID, newDate, true, true)) // TODO not great for performance
        return true
      })
      .catch((error) => {
        handleErrorsWithAction(error, types.CHANGE_DATE_FAILURE, dispatch)
        return false
      })
  },
  changeDateSuccess: (activity) => ({
    type: types.CHANGE_DATE_SUCCESS,
    payload: {
      activity,
    },
  }),
}

export default actions
