import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import type {
  VideoAttachmentsActions,
  VideoAttachmentsState,
} from '@/sections/ted/types/videoAttachments'
import {
  ATTACH_VIDEO_FILE,
  REMOVE_ATTACH_VIDEO_FILE,
  CLEAR_ATTACHMENTS_FILES,
} from '@/sections/ted/types/videoAttachments'

const initialState: VideoAttachmentsState = {
  videoId: 0,
  attachments: [],
}

const requestDestination = reduxRequestOriginMap.TED_VIDEOS

const TedVideosReducer: Reducer<VideoAttachmentsState, VideoAttachmentsActions> = (
  state = initialState,
  action
): VideoAttachmentsState => {
  switch (action.type) {
    case ATTACH_VIDEO_FILE: {
      const attachment = action.payload
      const attachments = [...state.attachments, attachment]
      return { ...state, attachments }
    }
    case REMOVE_ATTACH_VIDEO_FILE: {
      const { id } = action.payload
      const attachments = state.attachments.filter((attach) => attach.id !== id)
      return { ...state, attachments }
    }
    case CLEAR_ATTACHMENTS_FILES: {
      return { ...state, attachments: [] }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, TedVideosReducer, requestDestination)
