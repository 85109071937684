import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type {
  SubgroupsAction,
  SubgroupsState,
} from '@/sections/teacher/students/types/subgroups'
import {
  CREATE_SUBGROUP_FAILURE,
  CREATE_SUBGROUP_REQUEST,
  CREATE_SUBGROUP_SUCCESS,
  DELETE_SUBGROUP_FAILURE,
  DELETE_SUBGROUP_REQUEST,
  DELETE_SUBGROUP_SUCCESS,
  EDIT_SUBGROUP_FAILURE,
  EDIT_SUBGROUP_REQUEST,
  EDIT_SUBGROUP_SUCCESS,
  GET_SUBGROUP_FAILURE,
  GET_SUBGROUP_REQUEST,
  GET_SUBGROUP_SUCCESS,
  LIST_SUBGROUPS_FAILURE,
  LIST_SUBGROUPS_REQUEST,
  LIST_SUBGROUPS_SUCCESS,
  LIST_SUBGROUP_STUDENT_IDS_FAILURE,
  LIST_SUBGROUP_STUDENT_IDS_REQUEST,
  LIST_SUBGROUP_STUDENT_IDS_SUCCESS,
  SET_DELETE_MODAL_OPEN,
  SET_SUBGROUPS_DRAWER_MODE,
  SET_SUBGROUPS_DRAWER_OPEN,
  SET_SUBGROUP_NAME,
  SET_SUBGROUP_NAME_DUPLICATED,
  TOGGLE_STUDENT_IN_SUBGROUP,
} from '@/sections/teacher/students/types/subgroups'
import type { Reducer } from 'redux'

const initialState: SubgroupsState = {
  drawerOpen: false,
  drawerMode: 'read',
  deleteModalOpen: false,
  subgroups: [],
  selectedSubgroup: {
    id: 0,
    name: '',
    studentIds: [],
    students: [],
  },
  openSubgroup: {
    id: 0,
    name: '',
    studentIds: [],
  },
  classroomStudents: [],
  classroomStudentsPagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0,
  },
  subgroupStudentsPagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0,
  },
  loadingAllSubgroups: false,
  loadingSubgroup: false,
  loadingClassroomStudents: false,
  loadingSubgroupStudentIds: false,
  error: '',
  subgroupStudentsSearch: '',
  classroomStudentsSearch: '',
}

const requestDestination = reduxRequestOriginMap.TEACHER_SUBGROUPS_DRAWER

const SubgroupsReducer: Reducer<SubgroupsState, SubgroupsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD: {
      return setGenericField(state, action.payload)
    }
    case SET_SUBGROUPS_DRAWER_OPEN:
      if (!action.payload.open) return initialState
      return { ...state, drawerOpen: action.payload.open }
    case SET_SUBGROUPS_DRAWER_MODE:
      return {
        ...state,
        drawerMode: action.payload.drawerMode,
        classroomStudentsSearch:
          action.payload.drawerMode !== 'edit'
            ? initialState.classroomStudentsSearch
            : state.classroomStudentsSearch,
        openSubgroup:
          action.payload.drawerMode === 'edit'
            ? {
                ...state.selectedSubgroup,
                studentIds: state.selectedSubgroup.studentIds,
              }
            : initialState.openSubgroup,
      }
    case SET_SUBGROUP_NAME:
      return {
        ...state,
        openSubgroup: { ...state.openSubgroup, name: action.payload.name },
        error: '',
      }
    case SET_SUBGROUP_NAME_DUPLICATED:
      return {
        ...state,
        openSubgroup: { ...state.openSubgroup, name: action.payload.name },
        error: action.payload.error,
      }
    case LIST_SUBGROUPS_REQUEST:
      return { ...state, loadingAllSubgroups: true, error: '' }
    case LIST_SUBGROUPS_SUCCESS:
      return { ...state, loadingAllSubgroups: false, subgroups: action.payload.subgroups }
    case LIST_SUBGROUPS_FAILURE:
      return { ...state, loadingAllSubgroups: false, error: action.payload.error }
    case Redux.Types.Students.LIST_CLASSROOM_STUDENTS_REQUEST:
      return {
        ...state,
        loadingClassroomStudents: true,
        error: '',
        classroomStudentsPagination: {
          ...state.classroomStudentsPagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    case Redux.Types.Students.LIST_CLASSROOM_STUDENTS_SUCCESS:
      return {
        ...state,
        loadingClassroomStudents: false,
        classroomStudents: action.payload.students,
        classroomStudentsPagination: {
          ...state.classroomStudentsPagination,
          count: action.payload.count,
        },
      }
    case Redux.Types.Students.LIST_CLASSROOM_STUDENTS_FAILURE:
      return {
        ...state,
        loadingClassroomStudents: false,
        error: action.payload.error,
      }
    case GET_SUBGROUP_REQUEST:
      return {
        ...state,
        loadingSubgroup: true,
        error: '',
        subgroupStudentsPagination: {
          ...state.subgroupStudentsPagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    case GET_SUBGROUP_SUCCESS: {
      const currentSubgroups = state.subgroups

      const updatedSubgroups = currentSubgroups.map((subg) =>
        subg.id !== action.payload.subgroup.id ? subg : action.payload.subgroup
      )
      return {
        ...state,
        loadingSubgroup: false,
        subgroupStudentsSearch:
          action.payload.subgroup.id === state.selectedSubgroup.id
            ? state.subgroupStudentsSearch
            : initialState.subgroupStudentsSearch,
        classroomStudentsSearch:
          action.payload.subgroup.id === state.selectedSubgroup.id
            ? state.classroomStudentsSearch
            : initialState.classroomStudentsSearch,
        selectedSubgroup: {
          id: action.payload.subgroup.id,
          name: action.payload.subgroup.name,
          students: action.payload.subgroup.students,
          studentIds: state.selectedSubgroup.studentIds,
        },
        subgroupStudentsPagination: {
          ...state.subgroupStudentsPagination,
          count: action.payload.count,
        },
        subgroups: updatedSubgroups,
      }
    }
    case GET_SUBGROUP_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      }
    case LIST_SUBGROUP_STUDENT_IDS_REQUEST:
      return {
        ...state,
        loadingSubgroupStudentIds: true,
        error: '',
      }
    case LIST_SUBGROUP_STUDENT_IDS_SUCCESS:
      return {
        ...state,
        loadingSubgroupStudentIds: false,
        selectedSubgroup: {
          ...state.selectedSubgroup,
          studentIds: action.payload.studentIDS,
        },
        openSubgroup: {
          ...state.openSubgroup,
          studentIds: action.payload.studentIDS,
        },
      }
    case LIST_SUBGROUP_STUDENT_IDS_FAILURE:
      return {
        ...state,
        loadingSubgroupStudentIds: false,
        error: action.payload.error,
      }
    case TOGGLE_STUDENT_IN_SUBGROUP: {
      const updatedStudentIDS = state.openSubgroup.studentIds.filter(
        (id) => id !== action.payload.studentID
      )

      if (updatedStudentIDS.length === state.openSubgroup.studentIds.length) {
        updatedStudentIDS.push(action.payload.studentID)
      }

      return {
        ...state,
        openSubgroup: {
          ...state.openSubgroup,
          studentIds: updatedStudentIDS,
        },
      }
    }
    case DELETE_SUBGROUP_REQUEST:
    case EDIT_SUBGROUP_REQUEST:
    case CREATE_SUBGROUP_REQUEST: {
      return {
        ...state,
        loadingSubgroup: true,
      }
    }
    case DELETE_SUBGROUP_SUCCESS: {
      return {
        ...state,
        loadingSubgroup: false,
        drawerMode: 'read',
        deleteModalOpen: false,
      }
    }
    case EDIT_SUBGROUP_SUCCESS:
    case CREATE_SUBGROUP_SUCCESS: {
      return {
        ...state,
        loadingSubgroup: false,
        drawerMode: 'read',
      }
    }
    case DELETE_SUBGROUP_FAILURE:
    case EDIT_SUBGROUP_FAILURE:
    case CREATE_SUBGROUP_FAILURE: {
      return {
        ...state,
        loadingSubgroup: false,
        error: action.payload.error,
      }
    }
    case SET_DELETE_MODAL_OPEN: {
      return {
        ...state,
        deleteModalOpen: action.payload.open,
      }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, SubgroupsReducer, requestDestination)
