import React from 'react'
import { DraftJS, createTypeStrategy } from 'megadraft'

const linkBlock = ({ entityKey, children, contentState }) => {
  const { url } = contentState.getEntity(entityKey).getData()
  return (
    <a target="_blank" className="editor__link" href={url} title={url}>
      {children}
    </a>
  )
}

export default new DraftJS.CompositeDecorator([
  {
    strategy: createTypeStrategy('LINK'),
    component: linkBlock,
  },
])
