export interface MessageDeleteState {
  open: boolean
  messageId: number
}

export enum MESSAGE_DELETE_TYPES {
  OPEN = 'TEACHER/BULLETIN/DELETE_MESSAGE/OPEN',
  CLOSE = 'TEACHER/BULLETIN/DELETE_MESSAGE/CLOSE',
}

export type OpenDeleteMessageModal = {
  type: MESSAGE_DELETE_TYPES.OPEN
  payload: {
    messageId: number
  }
}

export type CloseDeleteMessageModal = {
  type: MESSAGE_DELETE_TYPES.CLOSE
}

export type MessageDeleteActions = OpenDeleteMessageModal | CloseDeleteMessageModal
