import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  bubble: {
    width: 22,
    height: 22,
    border: '1px solid #DFDFDA',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 23,
    alignItems: 'center',
  },
  text: {
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '16px',
    color: 'black',
  },
}))

const NumberBubble = ({ quantity, classNameContainer, classNameLabel }) => {
  const label = quantity > 99 ? '99+' : quantity
  const classes = useStyles()
  return (
    <div className={`${classes.bubble} ${classNameContainer}`}>
      <Typography className={`${classes.text} ${classNameLabel}`}>{label}</Typography>
    </div>
  )
}

NumberBubble.defaultProps = {
  quantity: 0,
  classNameContainer: undefined,
  classNameLabel: undefined,
}

export default NumberBubble
