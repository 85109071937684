import type { VIDEOCALL_ORIGIN } from '@/sections/videocall/constants'

export const SET_POPUP_OPEN = 'VIDEOCALL/OPEN_POPUP'
export const SET_POPUP_CLOSED = 'VIDEOCALL/CLOSE_POPUP'

export type VideocallOrigin =
  | typeof VIDEOCALL_ORIGIN.CHAT
  | typeof VIDEOCALL_ORIGIN.EXES
  | typeof VIDEOCALL_ORIGIN.EXOR

export enum LeaveVideocallReasons {
  CLOSE_POPUP = 'CLOSE_POPUP',
  HANG_UP = 'HANG_UP',
  ONLY_ONE_PARTICIPANT_LEFT = 'ONLY_ONE_PARTICIPANT_LEFT',
  EXOR_EVALUATED = 'EXOR_EVALUATED',
}

export interface VideocallState {
  popupOpen: boolean
  popupUrl: string
  videoTitle: string
  videocallOrigin: VideocallOrigin
}

export interface OpenPopup {
  type: typeof SET_POPUP_OPEN
  payload: {
    popupUrl: string
    videoTitle: string
    videocallOrigin: VideocallOrigin
  }
}

export interface ClosePopup {
  type: typeof SET_POPUP_CLOSED
  payload: {
    reason: LeaveVideocallReasons
  }
}

export type VideocallActions = OpenPopup | ClosePopup
