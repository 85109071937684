import type { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

export interface DefaultResponse<T = any> {
  description: T
}

// any status code within the 2xx range will cause this function to execute.
export function onRequestSuccess(config: AxiosRequestConfig) {
  if (config.params instanceof URLSearchParams) return config
  config.params = config.params ? snakecaseKeys(config.params, { deep: true }) : config.params

  if (config.data instanceof FormData) return config
  config.data = config.data ? snakecaseKeys(config.data, { deep: true }) : config.data
  return config
}

// any status code outside of the 2xx range will cause this function to execute.
export function onRequestError(error: AxiosError) {
  return Promise.reject(error)
}

// any status code within the 2xx range will cause this function to execute.
export function onResponseSuccess(response: AxiosResponse<DefaultResponse>) {
  if (response.config.responseType === 'blob') return response
  if (response.data) {
    response.data = camelcaseKeys(response.data, { deep: true })
  }

  return response
}

// any status code outside of the 2xx range will cause this function to execute.
export function onResponseError(error: AxiosError) {
  if (error.response && error.response.data) {
    error.response.data = camelcaseKeys(error.response.data, { deep: true })
  }

  return Promise.reject(error)
}
