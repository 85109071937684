import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import * as MigrationClassroomsTypes from '@/sections/principal/classroom-migration/types/migrationClassrooms'
import * as Types from '@/sections/principal/classroom-migration/types/migrationTab'

const initialState: Types.MigrationTabState = {
  activeStep: 1,
}

const requestDestination = reduxRequestOriginMap.PRINCIPAL_MIGRATION_TAB

const MigrationTabReducer = (
  state: Types.MigrationTabState = initialState,
  action: Types.MigrationTabActions
): Types.MigrationTabState => {
  if (action.payload?.requestOrigin !== requestDestination) return state // TODO: this is not right

  switch (action.type) {
    case Types.CLEAR_STATE:
      return initialState
    case Types.SET_ACTIVE_STEP:
      return { ...state, activeStep: action.payload.activeStep }
    case MigrationClassroomsTypes.COMPLETE_MIGRATION_REQUEST:
      return { ...state, activeStep: 3 }
    case MigrationClassroomsTypes.COMPLETE_MIGRATION_SUCCESS:
      return { ...state, activeStep: 2 }
    case MigrationClassroomsTypes.COMPLETE_MIGRATION_FAILURE:
      return { ...state, activeStep: 2 }
    default: {
      return state
    }
  }
}

export default MigrationTabReducer
