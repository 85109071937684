import type {
  OnboardingModalActions,
  OnboardingModalState,
} from '@/sections/student/exams/types/onboardingModal'
import {
  GET_EXOR_ACTIVITY_FAILURE,
  GET_EXOR_ACTIVITY_REQUEST,
  GET_EXOR_ACTIVITY_SUCCESS,
  GET_EXOR_INSTANCE_SUCCESS,
  GET_EXOR_STATUS_FAILURE,
  GET_EXOR_STATUS_REQUEST,
  GET_EXOR_STATUS_SUCCESS,
  SET_EXOR_EVALUATED,
  SET_EXOR_INCOMING_CALL,
  SET_LOST_CALL,
  SET_ONBOARDING_MODAL_CLOSED,
  SET_ONBOARDING_MODAL_OPEN,
  SET_PICKED_CALL,
  UPDATE_EXOR_STATUS_FAILURE,
  UPDATE_EXOR_STATUS_REQUEST,
  UPDATE_EXOR_STATUS_SUCCESS,
} from '@/sections/student/exams/types/onboardingModal'
import { LeaveVideocallReasons, SET_POPUP_CLOSED } from '@/sections/videocall/types/videocall'

const initialState: OnboardingModalState = {
  open: false,
  loading: false,
  error: '',
  incomingCall: false,
  lostCall: false,
  callsInfo: [],
  selectedActivity: {
    type: '',
    id: 0,
    classroomId: 0,
    startDate: '',
    endDate: '',
  },
  estimatedTime: null,
  pickedCall: null,
  loadingStatus: false,
  exorStatus: null,
}

function root(state = initialState, action: OnboardingModalActions): OnboardingModalState {
  switch (action.type) {
    case SET_EXOR_INCOMING_CALL: {
      const { callInfo, fromSocket } = action.payload
      return {
        ...state,
        callsInfo: fromSocket ? [...state.callsInfo, callInfo] : state.callsInfo,
        incomingCall: true,
        lostCall: false,
      }
    }
    case SET_LOST_CALL:
      return { ...state, lostCall: true, incomingCall: false }
    case SET_ONBOARDING_MODAL_OPEN:
      return {
        ...state,
        open: true,
        selectedActivity: action.payload.selectedActivity,
        lostCall: false,
      }
    case SET_ONBOARDING_MODAL_CLOSED:
      return { ...state, open: false }
    case GET_EXOR_INSTANCE_SUCCESS: {
      const { instances, estimatedTime } = action.payload
      return { ...state, estimatedTime, callsInfo: instances }
    }
    case GET_EXOR_ACTIVITY_REQUEST:
      return { ...state, loading: true, open: true }
    case GET_EXOR_ACTIVITY_SUCCESS:
      return { ...state, loading: false, selectedActivity: action.payload.activity }
    case GET_EXOR_ACTIVITY_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case SET_PICKED_CALL:
      return { ...state, incomingCall: false, pickedCall: action.payload.instance, lostCall: false }
    case SET_POPUP_CLOSED: {
      const { reason } = action.payload
      const lostCall =
        reason === LeaveVideocallReasons.HANG_UP || reason === LeaveVideocallReasons.CLOSE_POPUP
      return { ...state, pickedCall: null, callsInfo: [], lostCall }
    }
    case GET_EXOR_STATUS_REQUEST:
      return { ...state, loadingStatus: true, open: true }
    case GET_EXOR_STATUS_SUCCESS:
      return { ...state, loadingStatus: false, exorStatus: action.payload.status }
    case GET_EXOR_STATUS_FAILURE:
      return { ...state, loadingStatus: false, error: action.payload.error }
    case UPDATE_EXOR_STATUS_REQUEST:
      return { ...state, loadingStatus: true, open: true }
    case UPDATE_EXOR_STATUS_SUCCESS:
      return { ...state, loadingStatus: false, exorStatus: action.payload.status }
    case UPDATE_EXOR_STATUS_FAILURE:
      return { ...state, loadingStatus: false, error: action.payload.error }
    case SET_EXOR_EVALUATED:
      return initialState
    default:
      return state
  }
}

export default root
