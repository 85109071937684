import type {
  ErrorPayload,
  GenericActionWithType,
  GenericPagination,
} from '@/legacy/redux/utils'
import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type { Pages } from '@/sections/content-creator/dashboard/types/dashboard'
import type { Subject } from '@/legacy/redux/types/subjects'
import type { ThemeOptions } from '@/sections/management/types/addEditExamModal'

export type ExamParams = {
  limit: number
  page: number
  organizationId?: number
  search?: string
  orderBy?: string
  orderDirection?: string
}

export type ManagementExamsExam = {
  datetime: string
  deadline: string
  duration: number
  games: number[]
  theme: number
  type: number
  stage: number
}

export type ManagementExam = {
  id: string
  organizationId: number
  division: string
  grade: string
  shift: string
  cue: string
  neighborhood: string
  aulaId: number
  code: string
  teacherCode: string
  exam: ManagementExamsExam
  maxStudents: number
  name: string
  studentIds: string[]
}

export type ClassroomsOption = {
  classroomId: number
  label?: string
  organizationId: number
  organizationType?: string
  organizationName: string
  studentsCount: number
  division: string
  grade: string
  shift: string
  cue: string
  neighborhood: string
}

export interface CreateExamParams {
  name: string
  classroomOptions: ClassroomsOption[]
  division: string
  grade: string
  shift: string
  cue: string
  neighborhood: string
  aulaId: string
  organizationId: string
  createdByID: number
  createdByName: string
  exam: {
    datetime: string
    deadline: string
    duration: number
    theme: number
    type: number
    games: number[]
    stage: number
  }
}

export interface CreateExamParam {
  name: string
  aulaId: string
  organizationId: string
  organizationType?: string
  organizationName: string
  division: string
  grade: string
  shift: string
  cue: string
  neighborhood: string
  createdByID: number
  createdByName: string
  exam: {
    datetime: string
    deadline: string
    duration: number
    theme: number
    games: number[]
    type: number
    stage: number
  }
  maxStudents: number
  code?: string
  teacherCode?: string
  secret?: string
}
export interface EditExamParams {
  id: string
  name: string
  aulaId: string
  organizationId: string
  exam: {
    datetime: string
    deadline: string
    duration: number
    theme: number
    games: number[]
    type: number
    stage: number
  }
}

export type ExamsDrawerState = {
  pagination: Pages
  orderBy: string
  orderDirection: string
  exams: ManagementExam[]
  themes: ThemeOptions[]
  loadingThemes: boolean
  loadingExams: boolean
  error: string
  search: string
  stage: number
}

// GET
export const LIST_EXAMS_AS_ADMIN_REQUEST = 'EXAMS/LIST_EXAMS_AS_ADMIN_REQUEST'
export const LIST_EXAMS_AS_ADMIN_SUCCESS = 'EXAMS/LIST_EXAMS_AS_ADMIN_SUCCESS'
export const LIST_EXAMS_AS_ADMIN_FAILURE = 'EXAMS/LIST_EXAMS_AS_ADMIN_FAILURE'

// Create
export const CREATE_EXAMS_AS_ADMIN_REQUEST = 'EXAMS/CREATE_EXAMS_AS_ADMIN_REQUEST'
export const CREATE_EXAMS_AS_ADMIN_SUCCESS = 'EXAMS/CREATE_EXAMS_AS_ADMIN_SUCCESS'
export const CREATE_EXAMS_AS_ADMIN_FAILURE = 'EXAMS/CREATE_EXAMS_AS_ADMIN_FAILURE'

// Edit
export const EDIT_EXAMS_AS_ADMIN_REQUEST = 'EXAMS/EDIT_EXAMS_AS_ADMIN_REQUEST'
export const EDIT_EXAMS_AS_ADMIN_SUCCESS = 'EXAMS/EDIT_EXAMS_AS_ADMIN_SUCCESS'
export const EDIT_EXAMS_AS_ADMIN_FAILURE = 'EXAMS/EDIT_EXAMS_AS_ADMIN_FAILURE'

// GET
export type ListExamsAsAdminRequest = GenericActionWithType<
  typeof LIST_EXAMS_AS_ADMIN_REQUEST,
  GenericPagination
>
export type ListExamsAsAdminSuccess = GenericActionWithType<
  typeof LIST_EXAMS_AS_ADMIN_SUCCESS,
  { exams: ManagementExam[]; count: number }
>
export type ListExamsAsAdminFailure = GenericActionWithType<
  typeof LIST_EXAMS_AS_ADMIN_FAILURE,
  ErrorPayload
>

// Create
export type CreateExamAsAdminRequest = GenericActionWithType<typeof CREATE_EXAMS_AS_ADMIN_REQUEST>
export type CreateExamAsAdminSuccess = GenericActionWithType<typeof CREATE_EXAMS_AS_ADMIN_SUCCESS>
export type CreateExamAsAdminFailure = GenericActionWithType<
  typeof CREATE_EXAMS_AS_ADMIN_FAILURE,
  ErrorPayload
>

// Edit
export type EditExamAsAdminRequest = GenericActionWithType<typeof EDIT_EXAMS_AS_ADMIN_REQUEST>
export type EditExamAsAdminSuccess = GenericActionWithType<typeof EDIT_EXAMS_AS_ADMIN_SUCCESS>
export type EditExamAsAdminFailure = GenericActionWithType<
  typeof EDIT_EXAMS_AS_ADMIN_FAILURE,
  ErrorPayload
>

// GET THEMES
export const LIST_EXAMS_THEMES_REQUEST = 'EXAMS/LIST_EXAMS_THEMES_REQUEST'
export const LIST_EXAMS_THEMES_SUCCESS = 'EXAMS/LIST_EXAMS_THEMES_SUCCESS'
export const LIST_EXAMS_THEMES_FAILURE = 'EXAMS/LIST_EXAMS_THEMES_FAILURE'

export type ListExamsThemesRequest = GenericActionWithType<typeof LIST_EXAMS_THEMES_REQUEST>

export type ListExamsThemesSuccess = GenericActionWithType<
  typeof LIST_EXAMS_THEMES_SUCCESS,
  { themes: ThemeOptions[] }
>

export type ListExamsThemesFailure = GenericActionWithType<
  typeof LIST_EXAMS_THEMES_FAILURE,
  ErrorPayload
>

export type ListExamsThemesAction =
  | ListExamsThemesRequest
  | ListExamsThemesSuccess
  | ListExamsThemesFailure

export type ListExamsAsAdminAction =
  | ListExamsAsAdminRequest
  | ListExamsAsAdminSuccess
  | ListExamsAsAdminFailure

export type CreateExamAsAdminAction =
  | CreateExamAsAdminRequest
  | CreateExamAsAdminSuccess
  | CreateExamAsAdminFailure

export type EditExamAsAdminAction =
  | EditExamAsAdminRequest
  | EditExamAsAdminSuccess
  | EditExamAsAdminFailure

export type ExamsDrawerAction =
  | ListExamsAsAdminAction
  | CreateExamAsAdminAction
  | EditExamAsAdminAction
  | ListExamsThemesAction
  | SetField<'search', string>
  | SetField<'orderBy', string>
  | SetField<'orderDirection', string>
  | ClearState

// Delete
export const DELETE_EXAM_AS_MGMT_REQUEST = 'EXAMS/DELETE_EXAM_AS_MGMT_REQUEST'
export const DELETE_EXAM_AS_MGMT_SUCCESS = 'EXAMS/DELETE_EXAM_AS_MGMT_SUCCESS'
export const DELETE_EXAM_AS_MGMT_FAILURE = 'EXAMS/DELETE_EXAM_AS_MGMT_FAILURE'

export type DeleteExamAsMgmtRequest = GenericActionWithType<typeof DELETE_EXAM_AS_MGMT_REQUEST>
export type DeleteExamAsMgmtSuccess = GenericActionWithType<typeof DELETE_EXAM_AS_MGMT_SUCCESS>
export type DeleteExamAsMgmtFailure = GenericActionWithType<
  typeof DELETE_EXAM_AS_MGMT_FAILURE,
  ErrorPayload
>

export type DeleteExamAsMgmtAction =
  | DeleteExamAsMgmtRequest
  | DeleteExamAsMgmtSuccess
  | DeleteExamAsMgmtFailure

export const GET_EXAM_SUBJECTS_AS_MGMT_REQUEST = 'EXAMS/GET_EXAM_SUBJECTS_AS_MGMT_REQUEST'
export const GET_EXAM_SUBJECTS_AS_MGMT_SUCCESS = 'EXAMS/GET_EXAM_SUBJECTS_AS_MGMT_SUCCESS'
export const GET_EXAM_SUBJECTS_AS_MGMT_FAILURE = 'EXAMS/GET_EXAM_SUBJECTS_AS_MGMT_FAILURE'

export type GetExamSubjectsAsMgmtRequest = GenericActionWithType<
  typeof GET_EXAM_SUBJECTS_AS_MGMT_REQUEST
>
export type GetExamSubjectsAsMgmtSuccess = GenericActionWithType<
  typeof GET_EXAM_SUBJECTS_AS_MGMT_SUCCESS,
  { subjects: Subject[] }
>
export type GetExamSubjectsAsMgmtFailure = GenericActionWithType<
  typeof GET_EXAM_SUBJECTS_AS_MGMT_FAILURE,
  ErrorPayload
>

export type GetExamSubjectsAsMgmtAction =
  | GetExamSubjectsAsMgmtRequest
  | GetExamSubjectsAsMgmtSuccess
  | GetExamSubjectsAsMgmtFailure
