import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/eb-admin/management/types/addEditAudienceModal'

export const initialState: Types.AddEditAudienceModalState = {
  isOpenModal: false,
  isEditMode: false,
  cosmos: {
    status: REQUEST_STATUS.IDLE,
    cosmos: [],
    selectedCosmos: [],
  },
  audience: {
    id: 0,
    name: '',
    status: REQUEST_STATUS.IDLE,
    canCreateAudience: true,
  },
}

const addEditAudienceModalState = (
  state: Types.AddEditAudienceModalState = initialState,
  action: Types.AddEditAudienceModalAction
): Types.AddEditAudienceModalState => {
  switch (action.type) {
    case Types.MODAL_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isOpenModal: true,
      }
    }
    case Types.MODAL_TYPES.CLOSE_MODAL: {
      return {
        ...state,
        isOpenModal: false,
      }
    }
    case Types.MODAL_TYPES.GET_COSMOS_REQUEST: {
      return {
        ...state,
        cosmos: {
          ...state.cosmos,
          status: REQUEST_STATUS.LOADING,
        },
      }
    }
    case Types.MODAL_TYPES.GET_COSMOS_SUCCESS: {
      const { cosmos } = action.payload

      return {
        ...state,
        cosmos: {
          ...state.cosmos,
          status: REQUEST_STATUS.SUCCESS,
          cosmos,
        },
      }
    }
    case Types.MODAL_TYPES.GET_COSMOS_FAILURE: {
      return {
        ...state,
        cosmos: {
          ...state.cosmos,
          status: REQUEST_STATUS.FAILURE,
        },
      }
    }
    case Types.MODAL_TYPES.SET_SELECTED_COSMOS: {
      const { cosmoToChange } = action.payload
      const cosmoAlreadyExists = state.cosmos.selectedCosmos.some(
        (selectedCosmo) => cosmoToChange === selectedCosmo
      )

      const newSelectedCosmos = cosmoAlreadyExists
        ? state.cosmos.selectedCosmos.filter((selectedCosmo) => selectedCosmo !== cosmoToChange)
        : [...state.cosmos.selectedCosmos, cosmoToChange]

      return {
        ...state,
        cosmos: {
          ...state.cosmos,
          selectedCosmos: newSelectedCosmos,
        },
      }
    }
    case Types.MODAL_TYPES.SET_SELECTED_ALL_COSMOS: {
      const { canSelectAllCosmos } = action.payload
      const newSelectedCosmos = canSelectAllCosmos
        ? [...state.cosmos.cosmos.map(({ key }) => key)]
        : []

      return {
        ...state,
        cosmos: {
          ...state.cosmos,
          selectedCosmos: newSelectedCosmos,
        },
      }
    }
    case Types.MODAL_TYPES.GET_AUDIENCE_VALIDATION_REQUEST: {
      return {
        ...state,
        audience: {
          ...state.audience,
          status: REQUEST_STATUS.LOADING,
        },
      }
    }
    case Types.MODAL_TYPES.GET_AUDIENCE_VALIDATION_SUCCESS: {
      const { canCreateAudience } = action.payload
      return {
        ...state,
        audience: {
          ...state.audience,
          status: REQUEST_STATUS.SUCCESS,
          canCreateAudience,
        },
      }
    }
    case Types.MODAL_TYPES.GET_AUDIENCE_VALIDATION_FAILURE: {
      return {
        ...state,
        audience: {
          ...state.audience,
          status: REQUEST_STATUS.FAILURE,
        },
      }
    }
    case Types.MODAL_TYPES.SET_AUDIENCE_NAME: {
      const { newName } = action.payload

      return {
        ...state,
        audience: {
          ...state.audience,
          name: newName,
        },
      }
    }
    case Types.MODAL_TYPES.EDIT_AUDIENCE_REQUEST:
    case Types.MODAL_TYPES.CREATE_AUDIENCE_REQUEST: {
      return {
        ...state,
        cosmos: {
          ...state.cosmos,
          status: REQUEST_STATUS.LOADING,
        },
      }
    }
    case Types.MODAL_TYPES.EDIT_AUDIENCE_SUCCESS:
    case Types.MODAL_TYPES.CREATE_AUDIENCE_SUCCESS: {
      return initialState
    }
    case Types.MODAL_TYPES.EDIT_AUDIENCE_FAILURE:
    case Types.MODAL_TYPES.CREATE_AUDIENCE_FAILURE: {
      return {
        ...state,
        cosmos: {
          ...state.cosmos,
          status: REQUEST_STATUS.SUCCESS,
        },
      }
    }
    case Types.MODAL_TYPES.SET_AUDIENCE_TO_EDIT: {
      const { id, name, cosmos } = action.payload.audience

      return {
        ...state,
        isEditMode: true,
        cosmos: {
          ...state.cosmos,
          selectedCosmos: cosmos,
        },
        audience: {
          ...state.audience,
          name,
          id,
        },
      }
    }
    case Types.MODAL_TYPES.CLEAN_MODAL: {
      return initialState
    }
    default:
      return state
  }
}

export default addEditAudienceModalState
