import { apiPrivate } from '..'
import { organizationsUrl } from '@aula/config'
import { stringToBoolean } from '@/legacy/utils/text'
import type { DescriptionResponseShortener } from '@/api/types'

export const FeaturesAPI = {
  getAll,
  getOrganizationActiveFeatures,
  get,
  create,
  modify,
  remove,
}

export interface FeaturesList {
  features: ApiFeature[]
}

interface ApiFeature {
  createdAt: string
  defaultValue: 'true' | 'false'
  defaultVisible: boolean
  description: string
  id: number
  name: string
  updatedAt: string
}

function getAll() {
  return apiPrivate
    .get<DescriptionResponseShortener<FeaturesList>>(organizationsUrl + '/v1/features')
    .then(({ data: { description } }) => {
      const { features } = description
      const parsedFeatures = features.map(({ defaultValue, createdAt, updatedAt, ...f }) => {
        return {
          ...f,
          defaultValue: stringToBoolean(defaultValue),
          name: f.name,
        }
      })
      return { features: parsedFeatures }
    })
}

type GetFeaturesFlagParams = {
  offset: number
  limit: number
  search?: string
}

export type FeatureFlag = {
  id: number
  name: string
  description: string
  createdAt: string
  defaultValue: string
  defaultVisible: boolean
}

export interface FeatureApiResponse {
  configItems: FeatureFlag[]
  more: boolean
  count: number
}

function get(params: GetFeaturesFlagParams) {
  const { search, ...rest } = params
  const validParams = {
    ...rest,
    ...(search && { search }),
  }
  return apiPrivate
    .get<DescriptionResponseShortener<FeatureApiResponse>>(organizationsUrl + '/v1/feature-flags', {
      params: validParams,
    })
    .then(({ data }) => {
      return data.description
    })
    .then((features) => {
      return features
    })
}
function create(name: string, description: string, defaultValue: string) {
  const data = { name, description, defaultValue, defaultVisible: false }
  return apiPrivate
    .post<DescriptionResponseShortener<FeatureApiResponse>>(
      organizationsUrl + '/v1/feature-flags',
      data
    )
    .then((res) => res.data.description)
    .then((features) => features)
}

function modify(id: number, name: string, description: string, defaultValue: string) {
  const data = { name, description, defaultValue, defaultVisible: false }
  return apiPrivate
    .put<DescriptionResponseShortener<FeatureApiResponse>>(
      organizationsUrl + `/v1/feature-flags/${id}`,
      data
    )
    .then((res) => res.data.description)
    .then((features) => features)
}

function remove(id: number) {
  return apiPrivate
    .delete<DescriptionResponseShortener<FeatureApiResponse>>(
      organizationsUrl + `/v1/feature-flags/${id}`
    )
    .then((res) => res.data.description)
    .then((features) => features)
}

interface OrganizationActiveFeatures {
  config: ApiActiveFeature[]
}

interface ApiActiveFeature {
  configItemId: number
  name: string
  orgId: number
  value: 'true' | 'false'
  visible: boolean
}

function getOrganizationActiveFeatures(organizationId: number) {
  return apiPrivate
    .get<DescriptionResponseShortener<OrganizationActiveFeatures>>(
      organizationsUrl + `/v1/organizations/${organizationId}/config`
    )
    .then(({ data: { description } }) => {
      const { config } = description
      const parsedConfig = config
        .map(({ configItemId, ...c }) => {
          return { ...c, value: stringToBoolean(c.value), id: configItemId }
        })
        .filter((c) => c.value)

      return { config: parsedConfig }
    })
}
