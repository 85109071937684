import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/principal/forms/answers/types/answersDrawer'

const initialState: Types.AnswersDrawerState = {
  isLoading: false,
  isModalLoading: false,
  isDrawerOpen: false,
  answers: [],
  error: '',
  columns: [],
  actionStatus: REQUEST_STATUS.IDLE,
  pagination: { more: false, page: 0, rowsPerPage: 10, count: 0 },
  isOpenModal: false,
  modalAnswerFields: [],
  selectedAnswerId: 0,
  docTypesOptions: [],
  answerFieldStatus: REQUEST_STATUS.IDLE,
  docTypesStatus: REQUEST_STATUS.IDLE,
  exportModal: {
    isOpen: false,
    status: REQUEST_STATUS.IDLE,
  },
}

const AnswersDrawerReducer = (
  state: Types.AnswersDrawerState = initialState,
  action: Types.AnswersDrawerActions
): Types.AnswersDrawerState => {
  switch (action.type) {
    case Types.ANSWERS_DRAWER_TYPES.OPEN_DRAWER: {
      return {
        ...state,
        isDrawerOpen: true,
      }
    }
    case Types.ANSWERS_DRAWER_TYPES.OPEN_MODAL: {
      return { ...state, isOpenModal: true }
    }
    case Types.ANSWERS_DRAWER_TYPES.CLOSE_MODAL: {
      return { ...state, isOpenModal: false }
    }
    case Types.ANSWERS_DRAWER_TYPES.CLOSE_DRAWER: {
      return initialState
    }
    case Types.ANSWERS_DRAWER_TYPES.OPEN_EXPORT_ANSWERS_MODAL: {
      return {
        ...state,
        exportModal: {
          ...state.exportModal,
          isOpen: true,
        },
      }
    }
    case Types.ANSWERS_DRAWER_TYPES.CLOSE_EXPORT_ANSWERS_MODAL: {
      return {
        ...state,
        exportModal: {
          ...state.exportModal,
          isOpen: false,
        },
      }
    }
    case Types.ANSWERS_DRAWER_TYPES.EXPORT_ANSWERS_REQUEST: {
      return {
        ...state,
        exportModal: {
          ...state.exportModal,
          status: REQUEST_STATUS.LOADING,
        },
      }
    }
    case Types.ANSWERS_DRAWER_TYPES.EXPORT_ANSWERS_SUCCESS: {
      return {
        ...state,
        exportModal: {
          status: REQUEST_STATUS.SUCCESS,
          isOpen: false,
        },
      }
    }
    case Types.ANSWERS_DRAWER_TYPES.EXPORT_ANSWERS_FAILURE: {
      return {
        ...state,
        exportModal: {
          ...state.exportModal,
          status: REQUEST_STATUS.FAILURE,
        },
      }
    }
    case Types.ANSWERS_DRAWER_TYPES.CLEAR_EXPORT_ANSWERS_MODAL: {
      return { ...state, exportModal: { isOpen: false, status: REQUEST_STATUS.IDLE } }
    }
    case Types.ANSWERS_DRAWER_TYPES.GET_PREVIEW_ANSWERS_REQUEST: {
      return { ...state, isLoading: true }
    }
    case Types.ANSWERS_DRAWER_TYPES.GET_PREVIEW_ANSWERS_SUCCESS: {
      const { answers, count, more, page } = action.payload
      return {
        ...state,
        isLoading: false,
        answers,
        pagination: {
          ...state.pagination,
          count,
          page,
          more,
        },
      }
    }
    case Types.ANSWERS_DRAWER_TYPES.GET_PREVIEW_ANSWERS_FAILURE: {
      const { error } = action.payload
      return { ...state, isLoading: false, error }
    }
    case Types.ANSWERS_DRAWER_TYPES.ANSWER_ACTIONS_REQUEST: {
      return { ...state, actionStatus: REQUEST_STATUS.LOADING }
    }
    case Types.ANSWERS_DRAWER_TYPES.ANSWER_ACTIONS_SUCCESS: {
      return { ...state, actionStatus: REQUEST_STATUS.SUCCESS, isOpenModal: false }
    }
    case Types.ANSWERS_DRAWER_TYPES.ANSWER_ACTIONS_FAILURE: {
      const { error } = action.payload
      return { ...state, isLoading: false, error, actionStatus: REQUEST_STATUS.FAILURE }
    }
    case Types.ANSWERS_DRAWER_TYPES.GET_DOC_TYPES_OPTIONS_REQUEST: {
      return { ...state, isModalLoading: true, answerFieldStatus: REQUEST_STATUS.LOADING }
    }
    case Types.ANSWERS_DRAWER_TYPES.GET_ANSWER_FIELDS_SUCCESS: {
      const { answerFields } = action.payload
      return {
        ...state,
        modalAnswerFields: answerFields,
        isModalLoading: false,
        answerFieldStatus: REQUEST_STATUS.SUCCESS,
      }
    }
    case Types.ANSWERS_DRAWER_TYPES.GET_ANSWER_FIELDS_FAILURE: {
      const { error } = action.payload
      return { ...state, error, isModalLoading: false, answerFieldStatus: REQUEST_STATUS.FAILURE }
    }
    case Types.ANSWERS_DRAWER_TYPES.GET_ANSWER_FIELDS_REQUEST: {
      return { ...state, isModalLoading: true, docTypesStatus: REQUEST_STATUS.LOADING }
    }
    case Types.ANSWERS_DRAWER_TYPES.GET_DOC_TYPES_OPTIONS_SUCCESS: {
      const { docTypesOptions } = action.payload
      return { ...state, docTypesOptions, docTypesStatus: REQUEST_STATUS.SUCCESS }
    }
    case Types.ANSWERS_DRAWER_TYPES.GET_DOC_TYPES_OPTIONS_FAILURE: {
      const { error } = action.payload
      return { ...state, error, docTypesStatus: REQUEST_STATUS.FAILURE }
    }
    case Types.ANSWERS_DRAWER_TYPES.GET_ANSWERS_COLUMNS: {
      const { columns } = action.payload
      return { ...state, columns }
    }
    case Types.ANSWERS_DRAWER_TYPES.SET_SELECTED_ANSWER_ID: {
      const { answerId } = action.payload
      return { ...state, selectedAnswerId: answerId }
    }
    default:
      return state
  }
}
export default AnswersDrawerReducer
