export interface Classroom {
  shift: string
  grade: string
  stage: string
  division: string
}

/**
 * Format classroom name using the grade, division, shift, stage
 *
 * @param classroom Some partial classroom entity
 * @param classroom.shift Afternoon, Morning, Evening
 * @param classroom.grade 1st, 2nd, 3rd
 * @param classroom.stage Primary, secondary, Tertiary
 * @param classroom.division  A, B, C
 */
export const formatClassroomName = (classroom: Partial<Classroom>) => {
  const n = `${classroom.grade} ${classroom.division}, ${classroom.shift} ${classroom.stage ?? ''}`
  return n.trim()
}
