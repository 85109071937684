import type { CosmosThemeOptions, Globals } from '../theme/types/material'
import type { CosmosMap } from './types'
import {
  BACKGROUND_COLOR,
  SURFACE_COLOR,
  COMMON_COLOR,
  DIVIDER_COLOR,
  STATUS_COLOR,
  TEXT_COLOR,
  BORDER_COLOR,
  ALTERNATIVE_COLOR,
  DISABLED_COLOR,
  TOOLTIP_COLOR,
  SCROLLBAR_COLOR,
} from '../theme/colors'
import { FONT_FAMILY, FONT_SIZE, FONT_WEIGHT } from '../theme/fonts'
import { AULA_PALETTE } from '../theme/colors/aula'
import { LETS_GO_FOR_MORE_PALETTE } from '../theme/colors/goForMore'
import { LA_RIOJA_PALETTE } from '../theme/colors/laRioja'
import { CABA_PALETTE } from '../theme/colors/caba'
import { TED_PALETTE } from '../theme/colors/ted'
import { LA_RIOJA_MIN_SALUD_PALETTE } from '../theme/colors/laRiojaMinSalud'
import { ATLAS_PALETTE } from '../theme/colors/atlas'
import { AGENPIA_PALETTE } from '../theme/colors/agenpia'

const globals: Globals = {
  header: {
    height: 56,
  },
  footer: {
    height: 40,
  },
  common: {
    borders: {
      main: `1px solid ${BORDER_COLOR.MAIN}`,
    },
    scrollbars: {
      main: {
        '&::-webkit-scrollbar': {
          width: 8,
        },
        '&::-webkit-scrollbar-thumb': {
          background: `${SCROLLBAR_COLOR.MAIN}`,
          borderRadius: 4,
        },
        paddingRight: 8,
      },
    },
    textOverflow: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    lineClamp: {
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      /* Maximum lines allowed for the paragraph */
      WebkitLineClamp: 4,
      WebkitBoxOrient: 'vertical',
    },
  },
}

const AULA: CosmosThemeOptions = {
  spacing: 4,
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: FONT_FAMILY.REGULAR,
    fontSize: FONT_SIZE.REGULAR,
    fontWeightMedium: FONT_WEIGHT.MEDIUM,
    fontWeightRegular: FONT_WEIGHT.REGULAR,
    fontWeightBold: FONT_WEIGHT.BOLD,
  },
  palette: {
    ...AULA_PALETTE,
    divider: DIVIDER_COLOR.MAIN,
    common: {
      white: COMMON_COLOR.WHITE,
      black: COMMON_COLOR.BACK,
    },
    text: {
      primary: TEXT_COLOR.PRIMARY,
      secondary: TEXT_COLOR.SECONDARY,
    },
    error: {
      main: STATUS_COLOR.ERROR,
    },
    warning: {
      main: STATUS_COLOR.WARNING,
    },
    info: {
      main: STATUS_COLOR.INFO,
    },
    success: {
      main: STATUS_COLOR.SUCCESS,
    },
    background: {
      default: BACKGROUND_COLOR.MAIN,
    },
    surfaces: {
      main: SURFACE_COLOR.MAIN,
      dark: SURFACE_COLOR.DARK,
      light: SURFACE_COLOR.LIGHT,
    },
    alternatives: {
      a1: ALTERNATIVE_COLOR.A1,
      a2: ALTERNATIVE_COLOR.A2,
      a3: ALTERNATIVE_COLOR.A3,
      a4: ALTERNATIVE_COLOR.A4,
      a5: ALTERNATIVE_COLOR.A5,
      a6: ALTERNATIVE_COLOR.A6,
      a7: ALTERNATIVE_COLOR.A7,
      a8: ALTERNATIVE_COLOR.A8,
      a9: ALTERNATIVE_COLOR.A9,
      a10: ALTERNATIVE_COLOR.A10,
      a11: ALTERNATIVE_COLOR.A11,
      a12: ALTERNATIVE_COLOR.A12,
    },
    border: BORDER_COLOR.MAIN,
    disabled: {
      main: DISABLED_COLOR.MAIN,
      secondary: DISABLED_COLOR.SECONDARY,
    },
    tooltip: TOOLTIP_COLOR.MAIN,
  },
  globals,
}

const LETS_GO_FOR_MORE: CosmosThemeOptions = {
  palette: { ...AULA.palette, ...LETS_GO_FOR_MORE_PALETTE },
  globals,
}

const LA_RIOJA: CosmosThemeOptions = {
  palette: { ...AULA.palette, ...LA_RIOJA_PALETTE },
  globals,
}

const CABA: CosmosThemeOptions = {
  palette: { ...AULA.palette, ...CABA_PALETTE },
  globals,
}

const TED: CosmosThemeOptions = {
  palette: { ...AULA.palette, ...TED_PALETTE },
  globals,
}

const LA_RIOJA_MIN_SALUD: CosmosThemeOptions = {
  palette: { ...AULA.palette, ...LA_RIOJA_MIN_SALUD_PALETTE },
  globals,
}

const ATLAS: CosmosThemeOptions = {
  palette: { ...AULA.palette, ...ATLAS_PALETTE },
  globals,
}

const AGENPIA: CosmosThemeOptions = {
  palette: { ...AULA.palette, ...AGENPIA_PALETTE },
  globals,
}

const THEME: CosmosMap<CosmosThemeOptions> = {
  AULA,
  TED,
  CABA,
  ATLAS,
  LETS_GO_FOR_MORE,
  LA_RIOJA,
  LA_RIOJA_MIN_SALUD,
  AGENPIA,
}

export default THEME
