import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { Message, MessageFilters } from '@/api/bulletin/types'
import type { MarkMessageAsReadSuccess } from './messageMarkAsRead'
import type { EditMessageSuccess } from './messageManager'

export interface MessagesState {
  error: Error | null
  loading: boolean
  status: REQUEST_STATUS
  messages: Message[]
  filters: MessageFilters
  messageSelected: Message
  pagination: Pagination
}

export enum MESSAGE_TYPES {
  SET_MESSAGES_FILTER = 'TEACHER/BULLETIN/MESSAGES/SET_MESSAGES_FILTER',
  SET_SELECTED_MESSAGE = 'TEACHER/BULLETIN/MESSAGES/SET_SELECTED_MESSAGE',
  CLEAN_SELECTED_MESSAGES = 'TEACHER/BULLETIN/MESSAGES/CLEAN_SELECTED_MESSAGES',
  GET_LAST_MESSAGE = 'TEACHER/BULLETIN/MESSAGES/GET_LAST_MESSAGE',
  GET_LAST_MESSAGE_FAILURE = 'TEACHER/BULLETIN/MESSAGES/GET_LAST_MESSAGE_FAILURE',
  GET_LAST_MESSAGE_SUCCESS = 'TEACHER/BULLETIN/MESSAGES/GET_LAST_MESSAGE_SUCCESS',
  GET_MESSAGES = 'TEACHER/BULLETIN/MESSAGES/GET_MESSAGES',
  GET_MESSAGES_FAILURE = 'TEACHER/BULLETIN/MESSAGES/GET_MESSAGES_FAILURE',
  GET_MESSAGES_SUCCESS = 'TEACHER/BULLETIN/MESSAGES/GET_MESSAGES_SUCCESS',
  CLEAN_MESSAGES = 'TEACHER/BULLETIN/MESSAGE_MANAGER/CLEAN_MESSAGES',
  DELETE_MESSAGE = 'TEACHER/BULLETIN/MESSAGE_MANAGER/DELETE_MESSAGE',
  DELETE_MESSAGE_FAILURE = 'TEACHER/BULLETIN/MESSAGE_MANAGER/MESSAGE_DELETE_FAILURE',
  DELETE_MESSAGE_SUCCESS = 'TEACHER/BULLETIN/MESSAGE_MANAGER/MESSAGE_DELETE_SUCCESS',
}

export type Pagination = {
  total: number
  offset: number
  limit: number
  page: number
}

export type SetMessagesFilter = {
  type: MESSAGE_TYPES.SET_MESSAGES_FILTER
  payload: {
    prop: keyof MessageFilters
    value: MessageFilters[keyof MessageFilters]
  }
}

export type SetSelectedMessage = {
  type: MESSAGE_TYPES.SET_SELECTED_MESSAGE
  payload: {
    messageSelected: Message
  }
}

export type CleanMessages = {
  type: MESSAGE_TYPES.CLEAN_MESSAGES
}

export type CleanSelectedMessage = {
  type: MESSAGE_TYPES.CLEAN_SELECTED_MESSAGES
}

export type GetLastMessage = {
  type: MESSAGE_TYPES.GET_LAST_MESSAGE
}

export type GetLastMessageSuccess = {
  type: MESSAGE_TYPES.GET_LAST_MESSAGE_SUCCESS
  payload: {
    messages: Message[]
  }
}

export type GetLastMessageFailure = {
  type: MESSAGE_TYPES.GET_LAST_MESSAGE_FAILURE
  payload: {
    error: Error
  }
}

export type GetMessages = {
  type: MESSAGE_TYPES.GET_MESSAGES
  payload: {
    page: number
    limit: number
    offset: number
  }
}

export type DeleteMessage = {
  type: MESSAGE_TYPES.DELETE_MESSAGE
}

export type DeleteMessageSuccess = {
  type: MESSAGE_TYPES.DELETE_MESSAGE_SUCCESS
  payload: {
    messageId: number
  }
}

export type DeleteMessageFailure = {
  type: MESSAGE_TYPES.DELETE_MESSAGE_FAILURE
  payload: {
    error: Error
  }
}

export type GetMessagesSuccess = {
  type: MESSAGE_TYPES.GET_MESSAGES_SUCCESS
  payload: {
    total: number
    messages: Message[]
  }
}

export type GetMessagesFailure = {
  type: MESSAGE_TYPES.GET_MESSAGES_FAILURE
  payload: {
    error: Error
  }
}

export type MessagesActions =
  | SetMessagesFilter
  | SetSelectedMessage
  | CleanMessages
  | CleanSelectedMessage
  | GetLastMessage
  | GetLastMessageFailure
  | GetLastMessageSuccess
  | GetMessages
  | GetMessagesSuccess
  | GetMessagesFailure
  | MarkMessageAsReadSuccess
  | DeleteMessageSuccess
  | EditMessageSuccess
