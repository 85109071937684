import { ActivityTypes } from '@/api/lms/activities/activities'
import {
  Questions,
  Avv as AVV,
  Reading,
  RobotsActivity,
  Animations as AnimationsActivity,
  Games as GamesActivity,
  OralExam,
  WrittenExam,
  Quiz,
  Offline,
  CollabMiniature,
} from '@/legacy/svgs/activityRibbon'

export const ACTIVITY_TYPE_QUESTIONS = 'components.ribbon.types.task'
export const ACTIVITY_TYPE_AVV = 'components.ribbon.types.avv'
export const ACTIVITY_TYPE_READING = 'components.ribbon.types.reading'
export const ACTIVITY_TYPE_ROBOTS_REMOTE = 'components.ribbon.types.robotsRemote'
export const ACTIVITY_TYPE_ROBOTS_ACTIVITY = 'components.ribbon.types.robotsActivity'
export const ACTIVITY_TYPE_ANIMATIONS_ACTIVITY = 'components.ribbon.types.animationsActivity'
export const ACTIVITY_TYPE_GAMES_ACTIVITY = 'components.ribbon.types.gamesActivity'
export const ACTIVITY_TYPE_ORAL_EXAM = 'components.ribbon.types.exor'
export const ACTIVITY_TYPE_WRITTEN_EXAM = 'components.ribbon.types.exes'
export const ACTIVITY_TYPE_QUIZ = 'components.ribbon.types.quiz'
export const ACTIVITY_TYPE_COLLAB_MINIATURE = 'components.ribbon.types.collabMiniature'
export const ACTIVITY_TYPE_OFFLINE = 'components.ribbon.types.offline'

export const ACTIVITY_KEY_AVV = 'avv'
export const ACTIVITY_KEY_QUESTIONS = 'questions'
export const ACTIVITY_KEY_READING = 'reading'
export const ACTIVITY_KEY_ROBOTS_REMOTE = 'robots-remote'
export const ACTIVITY_KEY_ROBOTS_ACTIVITY = 'robots-activity'
export const ACTIVITY_KEY_ANIMATIONS_ACTIVITY = 'animations'
export const ACTIVITY_KEY_GAMES_ACTIVITY = 'games'
export const ACTIVITY_KEY_WRITTEN_EXAM = 'exes'
export const ACTIVITY_KEY_ORAL_EXAM = 'exor'
export const ACTIVITY_KEY_QUIZ = 'quiz'
export const ACTIVITY_KEY_COLLAB_MINIATURE = ActivityTypes.COLLAB_MINIATURE
export const ACTIVITY_KEY_OFFLINE = ActivityTypes.OFFLINE

export const activityTypeNeedsTime = (type) => {
  return (
    type === ACTIVITY_KEY_AVV ||
    type === ACTIVITY_KEY_ANIMATIONS_ACTIVITY ||
    type === ACTIVITY_KEY_ROBOTS_REMOTE ||
    type === ACTIVITY_KEY_ROBOTS_ACTIVITY ||
    type === ACTIVITY_KEY_ORAL_EXAM ||
    type === ACTIVITY_KEY_WRITTEN_EXAM
  )
}

export const activityRibbonMap = {
  [ACTIVITY_KEY_AVV]: {
    id: 1,
    type: ACTIVITY_TYPE_AVV,
    icon: AVV,
    description: 'components.ribbon.types.descriptionAvv',
    enabled: true,
    badges: [
      { label: 'components.ribbon.types.badges.avv', color: '#FFF', backgroundColor: '#B6B6B6' },
    ],
  },
  [ACTIVITY_KEY_QUESTIONS]: {
    id: 2,
    type: ACTIVITY_TYPE_QUESTIONS,
    icon: Questions,
    description: 'components.ribbon.types.descriptionQuestions',
    enabled: true,
  },
  [ACTIVITY_KEY_READING]: {
    id: 3,
    type: ACTIVITY_TYPE_READING,
    icon: Reading,
    description: 'components.ribbon.types.descriptionReading',
    enabled: true,
  },
  [ACTIVITY_KEY_ROBOTS_ACTIVITY]: {
    id: 4,
    type: ACTIVITY_TYPE_ROBOTS_ACTIVITY,
    icon: RobotsActivity,
    description: 'components.ribbon.types.descriptionRobots',
    badges: [
      { label: 'components.ribbon.types.badges.robots', color: '#FFF', backgroundColor: '#B6B6B6' },
    ],
    enabled: true,
  },
  [ACTIVITY_KEY_ANIMATIONS_ACTIVITY]: {
    id: 5,
    type: ACTIVITY_TYPE_ANIMATIONS_ACTIVITY,
    icon: AnimationsActivity,
    description: 'components.ribbon.types.descriptionAnimations',
    badges: [
      {
        label: 'components.ribbon.types.badges.animations',
        color: '#FFF',
        backgroundColor: '#B6B6B6',
      },
    ],
    enabled: true,
  },
  [ACTIVITY_KEY_GAMES_ACTIVITY]: {
    id: 6,
    type: ACTIVITY_TYPE_GAMES_ACTIVITY,
    icon: GamesActivity,
    description: 'components.ribbon.types.descriptionGames',
    enabled: false,
  },
  [ACTIVITY_KEY_ORAL_EXAM]: {
    id: 7,
    type: ACTIVITY_TYPE_ORAL_EXAM,
    icon: OralExam,
    description: 'components.ribbon.types.descriptionExor',
    enabled: true,
  },
  [ACTIVITY_KEY_WRITTEN_EXAM]: {
    id: 8,
    type: ACTIVITY_TYPE_WRITTEN_EXAM,
    icon: WrittenExam,
    description: 'components.ribbon.types.descriptionExes',
    enabled: true,
  },
  [ACTIVITY_KEY_QUIZ]: {
    id: 9,
    type: ACTIVITY_TYPE_QUIZ,
    icon: Quiz,
    description: 'components.ribbon.types.descriptionQuiz',
    enabled: true,
  },
  [ACTIVITY_KEY_COLLAB_MINIATURE]: {
    id: 10,
    type: ACTIVITY_TYPE_COLLAB_MINIATURE,
    icon: CollabMiniature,
    description: 'components.ribbon.types.descriptionCollabMiniature',
    enabled: true,
  },
  [ACTIVITY_KEY_OFFLINE]: {
    id: 11,
    type: ACTIVITY_TYPE_OFFLINE,
    icon: Offline,
    description: 'components.ribbon.types.descriptionOffline',
    enabled: false,
  },
}

export const Types = Object.freeze({
  Classroom: 'classroom',
  Group: 'group',
  Teacher: 'teacher',
  Student: 'student',
  Subject: 'subject',
  Activity: 'activity',
})

export const getActivityIcon = (activityType, className = '', ribbonMap = activityRibbonMap) => {
  const Icon = ribbonMap[activityType]?.icon
  return Icon ? <Icon className={className} /> : null
}

export const getActivityName = (activityType, ribbonMap = activityRibbonMap) => {
  const activityName = ribbonMap[activityType]?.type
  return activityName || ''
}
