import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'
import Modal from '@mui/material/Modal'

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxHeight: '95%',
    maxWidth: 500,
    [theme.breakpoints.down('md')]: {
      maxWidth: '95%',
    },
    borderRadius: 8,
    background: 'white',
    overflow: 'hidden',
  },
}))

interface GenericModalProps {
  open: boolean
  onClose: () => void
  children: React.ReactNode
  containerStyle?: string
  closingDisabled?: boolean
}

const GenericModal = (props: GenericModalProps) => {
  const { children, open, onClose, containerStyle = '', closingDisabled } = props
  const C = useStyles()

  const onCloseFn = () => {
    if (!closingDisabled) {
      onClose()
    }
  }

  return (
    <Modal open={open} onClose={onCloseFn} className={C.modalContainer}>
      <Paper className={clsx(C.modal, containerStyle)}>{children}</Paper>
    </Modal>
  )
}

export default GenericModal
