import type { GenericActionWithType } from '@/legacy/redux/utils'
import type { EditExamAsAdminAction, EditExamParams } from '@/sections/eb-admin/management/types/examsDrawer'
import type { ClearState } from '@/legacy/redux/types/generic'

export type ConfirmFinishExamModalState = {
  examToFinish: EditExamParams
  open: boolean
  loading: boolean
  error: string
}

// Set initial values
export const SET_INITIAL_VALUES = 'MANAGEMENT/EXAMS/FINISH_MODAL/SET_INITIAL_VALUES'

// Set initial values
export type SetInitialValues = GenericActionWithType<
  typeof SET_INITIAL_VALUES,
  {
    examToFinish: EditExamParams
  }
>

export type ConfirmFinishExamModalAction = EditExamAsAdminAction | SetInitialValues | ClearState
