import type { Moment } from 'moment'

export enum ModalStep {
  SCHEDULE = 'schedule',
  SENT = 'sent',
}

export interface ScheduleMessageModalState {
  open: boolean
  loading: boolean
  date: Moment
  time: Moment
  step: ModalStep
}

export enum SCHEDULE_MESSAGE_MODAL_TYPES {
  CLEAR_STATE = 'TEACHER/BULLETIN/SCHEDULE_MESSAGE_MODAL/CLEAR_STATE',
  SET_OPEN = 'TEACHER/BULLETIN/SCHEDULE_MESSAGE_MODAL/SET_OPEN',
  SET_DATE = 'TEACHER/BULLETIN/SCHEDULE_MESSAGE_MODAL/SET_DATE',
  SET_TIME = 'TEACHER/BULLETIN/SCHEDULE_MESSAGE_MODAL/SET_TIME',
  SET_STEP = 'TEACHER/BULLETIN/SCHEDULE_MESSAGE_MODAL/SET_STEP',
  SCHEDULE_MESSAGE_REQUEST = 'TEACHER/BULLETIN/SCHEDULE_MESSAGE_MODAL/SCHEDULE_MESSAGE_REQUEST',
  SCHEDULE_MESSAGE_SUCCESS = 'TEACHER/BULLETIN/SCHEDULE_MESSAGE_MODAL/SCHEDULE_MESSAGE_SUCCESS',
  SCHEDULE_MESSAGE_FAILURE = 'TEACHER/BULLETIN/SCHEDULE_MESSAGE_MODAL/SCHEDULE_MESSAGE_FAILURE',
}

export interface ClearStateAction {
  type: SCHEDULE_MESSAGE_MODAL_TYPES.CLEAR_STATE
}

export interface SetOpenAction {
  type: SCHEDULE_MESSAGE_MODAL_TYPES.SET_OPEN
  payload: { open: boolean }
}

export interface SetDateAction {
  type: SCHEDULE_MESSAGE_MODAL_TYPES.SET_DATE
  payload: { date: Moment }
}

export interface SetTimeAction {
  type: SCHEDULE_MESSAGE_MODAL_TYPES.SET_TIME
  payload: { time: Moment }
}

export interface SetStepAction {
  type: SCHEDULE_MESSAGE_MODAL_TYPES.SET_STEP
  payload: { step: ModalStep }
}

export interface ScheduleMessageRequest {
  type: SCHEDULE_MESSAGE_MODAL_TYPES.SCHEDULE_MESSAGE_REQUEST
}

export interface ScheduleMessageSuccess {
  type: SCHEDULE_MESSAGE_MODAL_TYPES.SCHEDULE_MESSAGE_SUCCESS
}
export interface ScheduleMessageFailure {
  type: SCHEDULE_MESSAGE_MODAL_TYPES.SCHEDULE_MESSAGE_FAILURE
}

export type ScheduleMessageAction =
  | ScheduleMessageRequest
  | ScheduleMessageSuccess
  | ScheduleMessageFailure

export type ScheduleMessageModalActions =
  | ClearStateAction
  | SetOpenAction
  | SetDateAction
  | SetTimeAction
  | SetStepAction
  | ScheduleMessageAction
