import * as React from 'react'
import type { SVGProps } from 'react'
const SvgOffline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect opacity={0.15} width={56} height={56} rx={6} fill="#42B3FF" />
    <path
      d="m41.375 26.958 3.083-3.083c-5.75-5.75-13.674-7.94-21.12-6.645l3.977 3.978c5.088-.03 10.19 1.88 14.06 5.75Zm-3.083 3.084a15.255 15.255 0 0 0-5.735-3.592l4.655 4.655 1.08-1.063Zm-15.417 6.166 4.625 4.625 4.625-4.625c-2.544-2.559-6.69-2.559-9.25 0Zm-8.618-23.68-2.174 2.174 4.702 4.702a24.208 24.208 0 0 0-6.243 4.471l3.083 3.083a19.5 19.5 0 0 1 6.429-4.285l3.453 3.453a15.143 15.143 0 0 0-6.799 3.916l3.084 3.083a10.789 10.789 0 0 1 7.538-3.176l10.915 10.915 2.174-2.174-26.162-26.162Z"
      fill="#42B3FF"
    />
  </svg>
)
export default SvgOffline
