import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/management/types/audienceModal'

export const initialState: Types.AudienceModalState = {
  isOpenModal: false,
  isEditMode: false,
  audiences: [],
  status: REQUEST_STATUS.IDLE,
  searchText: '',
  pagination: {
    count: 0,
    more: true,
    page: 0,
    rowsPerPage: 5,
  },
  selectedAudiences: [],
}

const audiecenModalReducer = (
  state: Types.AudienceModalState = initialState,
  action: Types.AudienceModalAction
): Types.AudienceModalState => {
  switch (action.type) {
    case Types.AUDIENCE_MODAL_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isOpenModal: true,
      }
    }
    case Types.AUDIENCE_MODAL_TYPES.CLOSE_MODAL: {
      return {
        ...state,
        isOpenModal: false,
      }
    }
    case Types.AUDIENCE_MODAL_TYPES.SET_SEARCH_TEXT: {
      const { newSearchText } = action.payload

      return {
        ...state,
        searchText: newSearchText,
      }
    }
    case Types.AUDIENCE_MODAL_TYPES.GET_AUDIENCES_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.AUDIENCE_MODAL_TYPES.GET_AUDIENCES_SUCCESS: {
      const { audiences, count, more, page } = action.payload

      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        audiences,
        pagination: {
          ...state.pagination,
          count,
          more,
          page,
        },
      }
    }
    case Types.AUDIENCE_MODAL_TYPES.GET_AUDIENCES_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.AUDIENCE_MODAL_TYPES.SET_SELECTED_AUDIENCES: {
      const { newSelectedAudiences } = action.payload

      return {
        ...state,
        selectedAudiences: newSelectedAudiences,
      }
    }
    case Types.AUDIENCE_MODAL_TYPES.CLEAN_MODAL: {
      return initialState
    }
    default:
      return state
  }
}

export default audiecenModalReducer
