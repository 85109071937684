export enum PALETTE_TYPE {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum DIVIDER_COLOR {
  MAIN = 'rgba(0, 0, 0, 0.12)',
}

export enum BACKGROUND_COLOR {
  MAIN = '#f7f8fc',
}

export enum SURFACE_COLOR {
  MAIN = '#F8F8F6',
  LIGHT = '#E1E1DF',
  DARK = '#D0D0CE',
}

export enum BORDER_COLOR {
  MAIN = '#DFDFDA',
}

export enum SCROLLBAR_COLOR {
  MAIN = '#CBCBCB',
}

export enum DISABLED_COLOR {
  MAIN = '#CDCDCD',
  SECONDARY = '#F5F5F5',
}

export enum TOOLTIP_COLOR {
  MAIN = 'rgba(26, 26, 26, 0.9)',
}

export enum TEXT_COLOR {
  PRIMARY = '#1A1A1A',
  SECONDARY = '#6D7878',
}

export enum COMMON_COLOR {
  WHITE = '#FFF',
  BACK = '#080808',
}

export enum ALTERNATIVE_COLOR {
  A1 = '#FF6428',
  A2 = '#01CEAA',
  A3 = '#9B00AF',
  A4 = '#FFB800',
  A5 = '#675CFF',
  A6 = '#F98939',
  A7 = '#E84F92',
  A8 = '#212C4C',
  A9 = '#6AACD9',
  A10 = '#5C4D7C',
  A11 = '#2E33A9',
  A12 = '#86AA59',
}

export enum STATUS_COLOR {
  INFO = '#3e96d1',
  ERROR = '#F45C3A',
  SUCCESS = '#50D174',
  WARNING = '#ffb800',
}
