import type { ClearState } from '@/legacy/redux/types/generic'
import type { GenericActionWithType } from '@/legacy/redux/utils'
import type { DeleteReportCardConfigAction } from './apiInteractions'

export interface ReportCardConfigDeletionModalState {
  open: boolean
  loading: boolean
  error: string
  configIdToDelete: number
}

export const SET_OPEN = 'REPORT_CARD/CONFIG/DELETION_MODAL/SET_OPEN'

export type SetOpen = GenericActionWithType<typeof SET_OPEN, { configIdToDelete: number }>

export type ReportCardConfigDeletionModalAction =
  | SetOpen
  | ClearState
  | DeleteReportCardConfigAction
