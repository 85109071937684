import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/student/summary/types/tabs/bulletin'

const initialState: Types.BulletinTabState = {
  status: REQUEST_STATUS.IDLE,
  data: {
    downloadUrl: '',
    periodName: '',
  },
  config: {
    status: REQUEST_STATUS.IDLE,
  },
  restriction: {
    blockDownload: false,
    status: REQUEST_STATUS.IDLE,
  },
}

const bulletinTabReducer = (
  state: Types.BulletinTabState = initialState,
  action: Types.BulletinTabAction
): Types.BulletinTabState => {
  switch (action.type) {
    case Types.BULLETIN_TAB_TYPES.GET_BULLETIN_PDF_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.BULLETIN_TAB_TYPES.GET_BULLETIN_PDF_SUCCESS: {
      const { downloadUrl, periodName } = action.payload

      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        data: {
          downloadUrl,
          periodName,
        },
      }
    }
    case Types.BULLETIN_TAB_TYPES.GET_BULLETIN_PDF_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.BULLETIN_TAB_TYPES.GET_CONFIG_REQUEST: {
      return { ...state, config: { status: REQUEST_STATUS.LOADING } }
    }
    case Types.BULLETIN_TAB_TYPES.GET_CONFIG_SUCCESS: {
      return { ...state, config: { status: REQUEST_STATUS.SUCCESS } }
    }
    case Types.BULLETIN_TAB_TYPES.GET_CONFIG_FAILURE: {
      return { ...state, config: { status: REQUEST_STATUS.FAILURE } }
    }
    case Types.BULLETIN_TAB_TYPES.GET_STUDENT_RESTRICTIONS_REQUEST: {
      return { ...state, restriction: { ...state.restriction, status: REQUEST_STATUS.LOADING } }
    }
    case Types.BULLETIN_TAB_TYPES.GET_STUDENT_RESTRICTIONS_SUCCESS: {
      return {
        ...state,
        restriction: { status: REQUEST_STATUS.SUCCESS, blockDownload: action.payload.block },
      }
    }
    case Types.BULLETIN_TAB_TYPES.GET_STUDENT_RESTRICTIONS_FAILURE: {
      return { ...state, restriction: { ...state.restriction, status: REQUEST_STATUS.FAILURE } }
    }
    case Types.BULLETIN_TAB_TYPES.RESET_TAB: {
      return initialState
    }
    default:
      return state
  }
}

export default bulletinTabReducer
