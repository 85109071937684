import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import CheckIcon from '@mui/icons-material/Check'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    maxWidth: 300,
  },
  select: {
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #D8DBE8',
    fontSize: 16,
    padding: '8px 26px 7px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#A051E6',
    },
  },
  menuItem: {
    fontWeight: theme.typography.fontWeightRegular,
    padding: '0px 16px',
    '&:last-child > div': {
      borderBottom: 'none',
    },
  },
  menuItemContainer: {
    width: '100%',
    display: 'flex',
    padding: '9px 0',
    borderBottom: '1px solid #D8DBE8',
    alignItems: 'center',
  },
  selectedMenuItem: {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#7D00DC',
    backgroundColor: 'inherit !important',
  },
  checkIcon: {
    fontSize: '15px',
    position: 'absolute',
    display: 'inline',
    right: '16px',
  },
  paper: {
    border: '1px solid #D8DBE8',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    width: 260,
  },
}))

function getBulletStyles(subjectColor) {
  return {
    height: '10px',
    width: '10px',
    backgroundColor: subjectColor,
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '10px',
  }
}

const SubjectPicker = (props) => {
  const { selectedSubjects, setSelectedSubjects, subjects } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const handleChange = (event) => {
    const clickedSubject = event.target.value.at(-1)

    if (clickedSubject.id === 0) {
      setSelectedSubjects([clickedSubject])
    } else {
      const isAlreadySelected = selectedSubjects.some(({ id }) => id === clickedSubject.id)

      const newSubjects = isAlreadySelected
        ? selectedSubjects.filter(({ id }) => id !== clickedSubject.id)
        : [...selectedSubjects, clickedSubject]

      setSelectedSubjects(newSubjects)
    }
  }

  const MenuProps = {
    PaperProps: {
      elevation: 0,
    },
    classes: { list: classes.list, paper: classes.paper },
  }

  const hasChosenAllSubjects = selectedSubjects.length === 1 // Because "all subjects" option is ALWAYS the first option in the array.

  const getRenderValue = () => {
    const [first, ...restOfSubjects] = selectedSubjects
    return hasChosenAllSubjects ? first?.name : restOfSubjects.map(({ name }) => name).join(', ')
  }

  return (
    <FormControl className={clsx(classes.formControl, classes.noLabel)}>
      <Select
        multiple
        displayEmpty
        variant="outlined"
        value={selectedSubjects}
        onChange={handleChange}
        classes={{ select: classes.select }}
        renderValue={(selected) => getRenderValue()}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {[{ id: 0, name: t('teacher.activities.allSubjects') }, ...subjects].map((subject) => {
          const selected =
            subject.id === 0
              ? hasChosenAllSubjects
              : selectedSubjects.some(({ id }) => id === subject.id)

          return (
            <MenuItem
              disableRipple
              key={subject.id}
              value={subject}
              classes={{ root: classes.menuItem, selected: classes.selectedMenuItem }}
              selected={selected}
            >
              <div className={classes.menuItemContainer}>
                {subject.id !== 0 ? <span style={getBulletStyles(subject.color)} /> : null}
                {subject.name}
                {selected && <CheckIcon className={clsx(classes.checkIcon)} />}
              </div>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default SubjectPicker
