import { REQUEST_STATUS } from '@/legacy/redux/types/status';
import * as Types from '../../types/featureFlag/deleteModal';

const initialState: Types.DeleteModalState = {
    isOpen: false,
    status: REQUEST_STATUS.IDLE,
    feature: { id: 0, name: '' },
}

const deleteModalReducer = (
    state: Types.DeleteModalState = initialState,
    action: Types.DeleteModalAction,
): Types.DeleteModalState => {
    switch (action.type) {
        case Types.DELETE_MODAL_TYPES.OPEN_MODAL: {
            return {
                ...state,
                isOpen: true,
            }
        }
        case Types.DELETE_MODAL_TYPES.CLOSE_MODAL: {
            return {
                ...state,
                isOpen: false,
            }
        }
        case Types.DELETE_MODAL_TYPES.SET_FEATURE_TO_DELETE: {
            return {
                ...state,
                feature: action.payload,
            }
        }
        case Types.DELETE_MODAL_TYPES.DELETE_FEATURE_FLAG_REQUEST: {
            return {
                ...state,
                status: REQUEST_STATUS.LOADING,
            }
        }
        case Types.DELETE_MODAL_TYPES.DELETE_FEATURE_FLAG_SUCCESS: {
            return initialState;
        }
        case Types.DELETE_MODAL_TYPES.DELETE_FEATURE_FLAG_FAILURE: {
            return {
                ...state,
                status: REQUEST_STATUS.FAILURE,
            }
        }
        default: return state;
    }
};

export default deleteModalReducer;