import types from '@/sections/editor/plugins/image-uploader/types/image-uploader'
import { pathOr } from 'ramda'

const initialState = {}

function root(state = initialState, action) {
  const key = pathOr('', ['payload', 'key'], action)
  switch (action.type) {
    case types.SET_URL:
      return { ...state, [key]: { file: { url: action.payload.url }, loading: false, error: '' } }
    case types.UPLOAD_FILE:
      return { ...state, [key]: { ...state[key], loading: true, error: '' } }
    case types.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        [key]: { ...state[key], file: action.payload.file, loading: false, error: '' },
      }
    case types.UPLOAD_FILE_FAILURE:
      return { ...state, [key]: { ...state[key], loading: false, error: action.payload.error } }
    default:
      return state
  }
}

export default root
