import type { AuthorizedContacts } from '@/api/lms/students'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export interface AuthorizedTabState {
  status: REQUEST_STATUS
  data: AuthorizedContacts[]
}

export enum AUTHORIZED_TAB_TYPES {
  GET_AUTHORIZED_REQUEST = 'STUDENT/SUMMARY/TABS/AUTHORIZED/GET_AUTHORIZED_REQUEST',
  GET_AUTHORIZED_SUCCESS = 'STUDENT/SUMMARY/TABS/AUTHORIZED/GET_AUTHORIZED_SUCCESS',
  GET_AUTHORIZED_FAILURE = 'STUDENT/SUMMARY/TABS/AUTHORIZED/GET_AUTHORIZED_FAILURE',
  RESET_TAB = 'STUDENT/SUMMARY/TABS/AUTHORIZED/RESET_TAB',
}

// Get authorized.
export type GetAuthorizedRequestAction = {
  type: AUTHORIZED_TAB_TYPES.GET_AUTHORIZED_REQUEST
}

export type GetAuthorizedSuccessAction = {
  type: AUTHORIZED_TAB_TYPES.GET_AUTHORIZED_SUCCESS
  payload: {
    data: AuthorizedContacts[]
  }
}

export type GetAuthorizedFailureAction = {
  type: AUTHORIZED_TAB_TYPES.GET_AUTHORIZED_FAILURE
}

export type GetAuthorizedAction =
  | GetAuthorizedRequestAction
  | GetAuthorizedSuccessAction
  | GetAuthorizedFailureAction

// Reset tab.
export type ResetTabAction = {
  type: AUTHORIZED_TAB_TYPES.RESET_TAB
}

export type AuthorizedTabAction = GetAuthorizedAction | ResetTabAction
