import type { Audience } from '@/api/lms/library'
import type { Pages } from '@/legacy/components/v3/moreTablePagination'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export interface InitialState {
  isOpen: boolean
  audiences: Audience[]
  selectedAudiences: Audience[]
  status: REQUEST_STATUS
  pagination: Pages
  error: string
}

export enum AUDIENCE_MODAL {
  SET_MODAL_OPEN = 'ACTIVITIES_TEACHER/AUDIENCE_MODAL/SET_MODAL_OPEN',
  SET_MODAL_CLOSE = 'ACTIVITIES_TEACHER/AUDIENCE_MODAL/SET_MODAL_CLOSE',
  GET_AUDIENCE_REQUEST = 'ACTIVITIES_TEACHER/AUDIENCE_MODAL/GET_AUDIENCE_REQUEST',
  GET_AUDIENCE_SUCCESS = 'ACTIVITIES_TEACHER/AUDIENCE_MODAL/GET_AUDIENCE_SUCCESS',
  GET_AUDIENCE_FAILURE = 'ACTIVITIES_TEACHER/AUDIENCE_MODAL/GET_AUDIENCE_FAILURE',
  TOGGLE_SELECTED_AUDIENCE = 'ACTIVITIES_TEACHER/AUDIENCE_MODAL/TOGGLE_SELECTED_AUDIENCE',
  CLEAR_STATE = 'ACTIVITIES_TEACHER/AUDIENCE_MODAL/CLEAR_STATE',
  CLEAR_SELECTED_AUDIENCES = 'ACTIVITIES_TEACHER/AUDIENCE_MODAL/CLEAR_SELECTED_AUDIENCES',
  SET_SELECTED_AUDIENCES = 'ACTIVITIES_TEACHER/AUDIENCE_MODAL/SET_SELECTED_AUDIENCES',
}

export type SetAudienceModalOpenAction = {
  type: AUDIENCE_MODAL.SET_MODAL_OPEN
}

export type SetAudienceModalCloseAction = {
  type: AUDIENCE_MODAL.SET_MODAL_CLOSE
}

export type GetAudienceRequestAction = {
  type: AUDIENCE_MODAL.GET_AUDIENCE_REQUEST
}

export type GetAudienceSuccessAction = {
  type: AUDIENCE_MODAL.GET_AUDIENCE_SUCCESS
  payload: { audiences: Audience[]; more: boolean; count: number; page: number }
}

export type GetAudienceFailureAction = {
  type: AUDIENCE_MODAL.GET_AUDIENCE_FAILURE
  payload: { error: string }
}

export type ClearStateAction = {
  type: AUDIENCE_MODAL.CLEAR_STATE
}
export type ClearSelectedAudienceAction = {
  type: AUDIENCE_MODAL.CLEAR_SELECTED_AUDIENCES
}

export type ToggleSelectedAudienceAction = {
  type: AUDIENCE_MODAL.TOGGLE_SELECTED_AUDIENCE
  payload: { audience: Audience }
}
export type SetSelectedAudiencesAction = {
  type: AUDIENCE_MODAL.SET_SELECTED_AUDIENCES
  payload: { audiences: Audience[] }
}

export type AudienceModalActions =
  | SetAudienceModalOpenAction
  | SetAudienceModalCloseAction
  | GetAudienceRequestAction
  | GetAudienceSuccessAction
  | GetAudienceFailureAction
  | ToggleSelectedAudienceAction
  | ClearSelectedAudienceAction
  | SetSelectedAudiencesAction
  | ClearStateAction
