import { combineReducers } from 'redux'
import educationalInfoStep from './educationalInfoStep'
import tagsModal from './tagsModal'

const createExtraInfo = combineReducers({
  educationalInfoStep,
  tagsModal,
})

export default createExtraInfo
