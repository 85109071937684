import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { SET_FIELD } from '@/legacy/redux/types/generic'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type {
  ContentsMainViewAction,
  ContentsMainViewState,
} from '@/sections/ted/types/contents'
import {
  GET_GROUP_FAILURE,
  GET_GROUP_REQUEST,
  GET_GROUP_SUCCESS,
  GET_INSTITUTION_FAILURE,
  GET_INSTITUTION_REQUEST,
  GET_INSTITUTION_SUCCESS,
  LIST_FACILITATORS_FAILURE,
  LIST_FACILITATORS_REQUEST,
  LIST_FACILITATORS_SUCCESS,
  LIST_GROUPS_FAILURE,
  LIST_GROUPS_REQUEST,
  LIST_GROUPS_SUCCESS,
} from '@/sections/ted/types/contents'

const initialState: ContentsMainViewState = {
  selectedTab: 1,
  groups: [],
  selectedGroup: {
    id: 0,
    type: '',
    name: '',
    totalParticipants: 0,
    classroomId: 0,
    institutionId: 0,
    institutionName: '',
    route: '',
  },
  institution: {
    id: 0,
    name: '-',
    address: '-',
    city: '-',
    cue: '-',
    email: '-',
    formal: false,
    hub: '-',
    mainFacilitatorId: 0,
    province: '-',
    totalFacilitators: 0,
    totalGroups: 0,
    years: [],
  },
  facilitators: [],
  loadingGroup: false,
  loadingGroups: false,
  loadingInstitution: false,
  loadingFacilitators: false,
  error: '',
}

const requestDestination = reduxRequestOriginMap.TED_MAIN

const TedMainViewReducer: Reducer<ContentsMainViewState, ContentsMainViewAction> = (
  state = initialState,
  action
): ContentsMainViewState => {
  switch (action.type) {
    case SET_FIELD:
      return setGenericField(state, action.payload)
    case GET_GROUP_REQUEST:
      return { ...state, loadingGroup: true }
    case GET_GROUP_SUCCESS:
      return { ...state, loadingGroup: false, selectedGroup: action.payload.group }
    case GET_GROUP_FAILURE:
      return { ...state, loadingGroup: false, error: action.payload.error }
    case LIST_GROUPS_REQUEST:
      return { ...state, loadingGroups: true }
    case LIST_GROUPS_SUCCESS:
      return {
        ...state,
        loadingGroups: false,
        groups: action.payload.groups,
        selectedGroup: action.payload.groups.length
          ? action.payload.groups[0]
          : initialState.selectedGroup,
      }
    case LIST_GROUPS_FAILURE:
      return { ...state, loadingGroups: false, error: action.payload.error }
    case GET_INSTITUTION_REQUEST:
      return { ...state, loadingInstitution: true }
    case GET_INSTITUTION_SUCCESS:
      return { ...state, loadingInstitution: false, institution: action.payload.institution }
    case GET_INSTITUTION_FAILURE:
      return { ...state, loadingInstitution: false, error: action.payload.error }
    case LIST_FACILITATORS_REQUEST:
      return { ...state, loadingFacilitators: true }
    case LIST_FACILITATORS_SUCCESS:
      return { ...state, loadingFacilitators: false, facilitators: action.payload.facilitators }
    case LIST_FACILITATORS_FAILURE:
      return { ...state, loadingFacilitators: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, TedMainViewReducer, requestDestination)
