import moment from 'moment'
import { pathOr } from 'ramda'
import { robotsApiUrl } from '@aula/config'
import {
  ACTIVITY_KEY_COLLAB_MINIATURE,
  ACTIVITY_KEY_ROBOTS_ACTIVITY,
} from '@/legacy/components/v2/ribbons/types'

const getImportantDates = (activity) => {
  const now = moment()
  const today = now.clone().startOf('day')

  const completed = moment(activity.completed).startOf('day')

  const date = moment(activity.date).startOf('day')

  const deadline = moment(activity.deadline).isValid()
    ? moment(activity.deadline).startOf('day')
    : moment(activity.date).startOf('day')

  const startTime = moment(activity.startDate).isValid() ? moment(activity.startDate) : date

  const endTime = moment(activity.endDate).isValid() ? moment(activity.endDate) : startTime
  return { now, today, completed, date, deadline, startTime, endTime }
}

export const formatDate = (date, t) => {
  return moment(date).format(
    `D [${t('moment.of')}] MMMM [${t('moment.at')}] h:mm[${t('moment.hours')}]`
  )
}

export function getRibbonContent(activity, buttonActions, classroomID, t) {
  const { now, today, completed, deadline, startTime, endTime, date } = getImportantDates(activity)

  let calendarLabel

  let isCompleted = completed.isValid()

  let buttonClick = () => {}
  let buttonLabel = activity.lastSubmission?.progress > 0 ? 'generic.continue' : 'generic.start'

  const isNew = isToday(now, date)
  const delayed = deadline.isBefore(today, 'day') && !isCompleted

  switch (activity.type) {
    case 'avv': {
      calendarLabel = now.isAfter(endTime) ? 'student.utils.transmitted' : 'student.utils.transmit'
      const room = pathOr('', ['videocallUrl'], activity)

      isCompleted = now.isAfter(endTime)

      const hasRecording =
        activity.avv?.recordings?.length > 0 && activity.avv?.recordings[0]?.videoId // eslint-disable-line

      buttonLabel = hasRecording ? 'backpack.recordings.watchRecording' : buttonLabel

      buttonClick = () =>
        hasRecording
          ? buttonActions.toggleRecordingOpen({
              activityId: activity.id,
              title: activity.title,
              videoSource: activity.avv.recordings[0].videoSource,
              videoId: activity.avv.recordings[0].videoId, // eslint-disable-line
              datetime: activity.date,
              subject: activity.subject?.name,
              subjectColor: activity.subject?.color,
              videos: activity.avv?.recordings ? activity.avv?.recordings : [],
            })
          : activity.isZoom
          ? window.open(activity.videocallUrl, '_blank')
          : buttonActions.openAVV(room, activity.classroomId, activity.id, activity.title)
      break
    }
    case 'robots-activity':
    case 'animations': {
      if (activity.robotsIsRemote) {
        calendarLabel = now.isAfter(endTime)
          ? 'student.utils.transmitted'
          : 'student.utils.transmit'
      } else {
        calendarLabel =
          activity.type === ACTIVITY_KEY_ROBOTS_ACTIVITY
            ? 'student.utils.transmit'
            : 'student.utils.deadline'
      }
      buttonClick = () =>
        buttonActions.getRobotsToken(0, activity.id).then((token) => {
          if (token) window.open(robotsApiUrl + '/assignment/login/' + token)
        })
      break
    }
    case 'games': {
      calendarLabel = 'student.utils.deadline'
      buttonClick = () =>
        buttonActions.getRobotsToken(0, activity.id).then((token) => {
          if (token)
            window.open(robotsApiUrl + '/games/' + activity.robotsGamesCodeName + '/login/' + token)
        })
      break
    }
    case 'questions':
      buttonClick = () => buttonActions.getActivity(classroomID, activity.id)
      break
    case 'quiz':
      if (!isCompleted) buttonClick = () => buttonActions.getQuiz(activity.id)
      break
    case 'exor':
    case 'exes':
      buttonClick = () => buttonActions.openExamModal(activity)
      break
    case ACTIVITY_KEY_COLLAB_MINIATURE:
      buttonClick = () => buttonActions.openCollabWaitingRoom(activity.id)
      break
    case 'reading':
    default:
      buttonClick = () => buttonActions.getActivity(classroomID, activity.id)
      break
  }

  return {
    calendarLabel,
    startTime,
    endTime,
    delayed,
    completed: isCompleted,
    buttonClick,
    deadline,
    isNew,
    buttonLabel,
  }
}

function isToday(reference, date) {
  const today = reference.clone().startOf('day')
  return date.isSame(today, 'd')
}

// function isWithinAWeekInTheFuture (reference, date) {
//   const aWeekFromNow = reference.clone().add(7, 'days').startOf('day')
//   return date.isBefore(aWeekFromNow)
// }

export const getToDoList = ({ todayList, thisWeekList, futureList }) => [
  ...todayList,
  ...thisWeekList,
  ...futureList,
]
