import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type {
  AssignBookModalActions,
  AssignBookModalState,
} from '@/sections/teacher/library/types/assignBookModal'
import { ASSIGN_BOOK_MODAL_TYPE } from '@/sections/teacher/library/types/assignBookModal'

const initialState: AssignBookModalState = {
  isOpen: false,
  classrooms: {
    status: REQUEST_STATUS.IDLE,
    error: '',
    list: [],
    classroomId: 0,
    selectedClassroom: {
      admLevel1: {
        id: 0,
        name: '',
      },
      admLevel2: {
        id: 0,
        name: '',
      },
      country: {
        id: 0,
        name: '',
      },
      division: '',
      enrollmentCapacity: 0,
      grade: '',
      id: 0,
      organization: {
        id: 0,
        name: '',
      },
      organizationType: '',
      shift: '',
      stage: '',
      year: 0,
    },
  },
  subgroups: {
    list: [],
    selectedSubgroups: [],
    status: REQUEST_STATUS.IDLE,
    error: '',
  },
  associateStatus: REQUEST_STATUS.IDLE,
}

function assignBookModalReducer(
  state: AssignBookModalState = initialState,
  action: AssignBookModalActions
): AssignBookModalState {
  switch (action.type) {
    case ASSIGN_BOOK_MODAL_TYPE.SET_OPEN:
      return { ...state, isOpen: true }
    case ASSIGN_BOOK_MODAL_TYPE.SET_CLOSE:
      return initialState
    case ASSIGN_BOOK_MODAL_TYPE.SELECT_CLASSROOM:
      return {
        ...state,
        classrooms: {
          ...state.classrooms,
          selectedClassroom:
            state.classrooms.list.find((s) => s.id === action.payload.classroomId) ||
            initialState.classrooms.selectedClassroom,
        },
      }
    case ASSIGN_BOOK_MODAL_TYPE.SELECT_SUBGROUPS:
      return {
        ...state,
        subgroups: { ...state.subgroups, selectedSubgroups: action.payload.selectedSubgroups },
      }
    case ASSIGN_BOOK_MODAL_TYPE.GET_CLASSROOMS_REQUEST: {
      return { ...state, classrooms: { ...state.classrooms, status: REQUEST_STATUS.LOADING } }
    }
    case ASSIGN_BOOK_MODAL_TYPE.GET_CLASSROOMS_SUCCESS: {
      const { classrooms } = action.payload
      return {
        ...state,
        classrooms: {
          ...state.classrooms,
          status: REQUEST_STATUS.SUCCESS,
          list: classrooms,
        },
      }
    }
    case ASSIGN_BOOK_MODAL_TYPE.GET_CLASSROOMS_FAILURE: {
      return {
        ...state,
        classrooms: {
          ...state.classrooms,
          status: REQUEST_STATUS.FAILURE,
          error: action.payload.error,
        },
      }
    }
    case ASSIGN_BOOK_MODAL_TYPE.GET_CLASSROOM_SUBGROUPS_REQUEST:
      return { ...state }
    case ASSIGN_BOOK_MODAL_TYPE.GET_CLASSROOM_SUBGROUPS_SUCCESS: {
      const { subgroups } = action.payload
      return {
        ...state,
        subgroups: { ...state.subgroups, list: subgroups, selectedSubgroups: [] },
      }
    }
    case ASSIGN_BOOK_MODAL_TYPE.GET_CLASSROOM_SUBGROUPS_FAILURE:
      return { ...state, subgroups: { ...state.subgroups, error: action.payload.error } }
    case ASSIGN_BOOK_MODAL_TYPE.ASSOCIATE_BOOK_WITH_CLASSROOM_REQUEST: {
      return { ...state, associateStatus: REQUEST_STATUS.LOADING }
    }
    case ASSIGN_BOOK_MODAL_TYPE.ASSOCIATE_BOOK_WITH_CLASSROOM_SUCCESS: {
      return { ...state, associateStatus: REQUEST_STATUS.SUCCESS }
    }
    case ASSIGN_BOOK_MODAL_TYPE.ASSOCIATE_BOOK_WITH_CLASSROOM_FAILURE: {
      return { ...state, associateStatus: REQUEST_STATUS.FAILURE }
    }
    default:
      return state
  }
}

export default assignBookModalReducer
