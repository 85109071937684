import { REQUEST_STATUS } from '@/legacy/redux/types/status';
import * as Types from '../../types/featureFlag/drawer';

const initialState: Types.FeatureFlagDrawerState = {
    featuresList: [],
    status: REQUEST_STATUS.IDLE,
    pagination: { count: 0, more: false, page: 0, rowsPerPage: 10 },
};

const featureFlagsDrawerReducer = (
    state: Types.FeatureFlagDrawerState = initialState,
    action: Types.FeatureFlagDrawerAction,
): Types.FeatureFlagDrawerState => {
    switch (action.type) {
        case Types.FEATURE_FLAG_DRAWER_TYPES.GET_FEATURE_FLAGS_REQUEST: {
            return {
                ...state,
                status: REQUEST_STATUS.LOADING,
            }
        }
        case Types.FEATURE_FLAG_DRAWER_TYPES.GET_FEATURE_FLAGS_SUCCESS: {
            const { count, more, features, page } = action.payload
            return {
                ...state,
                status: REQUEST_STATUS.SUCCESS,
                pagination: { ...state.pagination, count, more, page },
                featuresList: features,
            }
        }
        case Types.FEATURE_FLAG_DRAWER_TYPES.GET_FEATURE_FLAGS_FAILURE: {
            return {
                ...state,
                status: REQUEST_STATUS.FAILURE,
            }
        }
        default: return state;
    }
};

export default featureFlagsDrawerReducer;