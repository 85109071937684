import localforage from 'localforage'
import { persistReducer } from 'redux-persist'
import { ALL_ORGANIZATIONS } from '@/sections/eb-evaluator/dashboard/constants'
import type {
  EbEvaluatorActions,
  EbEvaluatorState,
} from '@/sections/eb-evaluator/dashboard/types/dashboard'
import {
  DASHBOARD_FAILURE,
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  ORGANIZATION_FAILURE,
  ORGANIZATION_REQUEST,
  ORGANIZATION_SUCCESS,
  SET_GLOBAL_SEARCH,
  SET_ORGANIZATION,
  OPEN_DRAWER,
  CLOSE_DRAWER,
} from '@/sections/eb-evaluator/dashboard/types/dashboard'

const initialState: EbEvaluatorState = {
  open: false,
  selected: '',
  selectedOrganization: ALL_ORGANIZATIONS,
  lastSearchID: 0,
  loadingOrganizations: false,
  loading: false,
  organizationOptions: [ALL_ORGANIZATIONS],
  organizations: 0,
  globalSearch: '',
  exams: 0,
  themes: 0,
}

function root(state = initialState, action: EbEvaluatorActions) {
  switch (action.type) {
    case OPEN_DRAWER: {
      return {
        ...state,
        open: true,
        selected: action.payload.selected,
      }
    }
    case CLOSE_DRAWER: {
      return {
        ...state,
        open: false,
      }
    }
    case SET_ORGANIZATION:
      return { ...state, selectedOrganization: action.payload.selectedOrganization }
    case SET_GLOBAL_SEARCH:
      return { ...state, globalSearch: action.payload.search }
    case DASHBOARD_REQUEST:
      return { ...state, loading: true, error: '' }
    case DASHBOARD_SUCCESS: {
      const { exams, themes } = action.payload
      return {
        ...state,
        loading: false,
        exams,
        themes,
      }
    }
    case DASHBOARD_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case ORGANIZATION_REQUEST:
      return {
        ...state,
        loadingOrganizations: true,
        error: '',
        lastSearchID: action.payload.lastSearchID,
      }
    case ORGANIZATION_SUCCESS: {
      const { organizations } = action.payload
      return { ...state, loadingOrganizations: false, organizationOptions: organizations }
    }
    case ORGANIZATION_FAILURE:
      return { ...state, loadingOrganizations: false, error: action.payload.error }
    default:
      return state
  }
}

const persistConfig = {
  key: 'eb-admin-dashboard',
  storage: localforage,
  whitelist: ['selectedOrganization'],
}

export default persistReducer(persistConfig, root)
