import moment from 'moment'
import type { Reducer } from 'redux'
import { ActivityTypes } from '@/api/lms/activities/activities'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type {
  CollabMiniatureAction,
  CollabMiniatureState,
  Scenario,
} from '@/sections/teacher/activities/types/createExtraInfo/collabMiniature/collabMiniature'
import {
  GET_TIMESLOTS_REQUEST,
  GET_TIMESLOTS_SUCCESS,
  GET_TIMESLOTS_FAILURE,
  GET_SCENARIOS_REQUEST,
  GET_SCENARIOS_SUCCESS,
  GET_SCENARIOS_FAILURE,
  TOGGLE_SCENARIO,
  TOGGLE_ALL_SCENARIOS,
} from '@/sections/teacher/activities/types/createExtraInfo/collabMiniature/collabMiniature'
import { GET_ACTIVITY_SUCCESS } from '@/sections/teacher/activities/types/createExtraInfo/educationalInfo/educationalInfoStep'

const initialState: CollabMiniatureState = {
  loadingScenarios: false,
  loadingTimeslots: false,
  error: '',
  timeslots: [],
  scenarios: [],
  selectedScenarios: [],
  date: moment(),
  selectedTimeslot: null,
  guided: true,
}

const requestDestination = reduxRequestOriginMap.COLLAB_MINIATURE_CREATION

const CollabMiniature: Reducer<CollabMiniatureState, CollabMiniatureAction> = (
  state = initialState,
  action
): CollabMiniatureState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE: {
      return initialState
    }
    case Redux.Types.Generic.SET_FIELD: {
      return {
        // @ts-ignore
        ...setGenericField(state, action.payload),
        selectedScenarios: ['date', 'selectedTimeslot'].includes(action.payload.fieldName)
          ? initialState.selectedScenarios
          : state.selectedScenarios,
      }
    }
    case GET_ACTIVITY_SUCCESS: {
      const { activity } = action.payload

      if (activity?.type !== ActivityTypes.COLLAB_MINIATURE || !activity.collabMiniatureInfo)
        return state

      const { guided, scenarios } = activity.collabMiniatureInfo

      const scenariosWithAvailability: Scenario[] = scenarios.map((s) => ({
        ...s,
        available: true,
      }))

      const startTime = moment(activity.startDate).format('HH:mm')
      const endTime = moment(activity.endDate).format('HH:mm')

      return {
        ...state,
        guided,
        selectedScenarios: scenariosWithAvailability,
        selectedTimeslot: {
          startTime,
          endTime,
          id: 0,
        },
      }
    }
    case TOGGLE_ALL_SCENARIOS: {
      const availableScenarios = state.scenarios.filter((s) => s.available)

      if (availableScenarios.length === state.selectedScenarios.length) {
        return { ...state, selectedScenarios: initialState.selectedScenarios }
      }

      return {
        ...state,
        selectedScenarios: availableScenarios,
      }
    }
    case GET_TIMESLOTS_REQUEST:
      return { ...state, loadingTimeslots: true, error: initialState.error }
    case GET_TIMESLOTS_SUCCESS: {
      const { timeslots } = action.payload
      if (!timeslots?.length) return state

      let selectedTimeslot = timeslots[0]
      if (state.selectedTimeslot) {
        const found = timeslots.find(
          (timeslot) =>
            timeslot.startTime === state.selectedTimeslot?.startTime &&
            timeslot.endTime === state.selectedTimeslot?.endTime
        )

        if (found) selectedTimeslot = found
      }

      return {
        ...state,
        loadingTimeslots: false,
        timeslots,
        selectedTimeslot,
      }
    }
    case GET_TIMESLOTS_FAILURE:
      return { ...state, loadingTimeslots: false, error: action.payload.error }
    case GET_SCENARIOS_REQUEST:
      return { ...state, loadingScenarios: true, error: initialState.error }
    case GET_SCENARIOS_SUCCESS:
      return { ...state, loadingScenarios: false, scenarios: action.payload.scenarios }
    case GET_SCENARIOS_FAILURE:
      return { ...state, loadingScenarios: false, error: action.payload.error }
    case TOGGLE_SCENARIO: {
      const { scenario } = action.payload

      const filtered = state.selectedScenarios.filter((sc) => sc.id !== scenario.id)
      if (filtered.length === state.selectedScenarios.length) filtered.push(scenario)

      return {
        ...state,
        selectedScenarios: filtered,
      }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, CollabMiniature, requestDestination, [
  GET_ACTIVITY_SUCCESS,
])
