import type {
  ClassroomState,
  ClassroomActions,
} from '@/sections/teacher/classroom/types/classroom'
import {
  GET_CLASSROOM_REQUEST,
  GET_CLASSROOM_SUCCESS,
  GET_CLASSROOM_FAILURE,
  SET_ACTIVE_TAB,
} from '@/sections/teacher/classroom/types/classroom'

const initialState: ClassroomState = {
  classroom: {
    division: '',
    grade: '',
    id: 0,
    organization: {
      id: 0,
      name: '',
    },
    shift: '',
    stage: '',
    year: 0,
    pendingActivities: 0,
  },
  loading: false,
  error: '',
  activeTab: 0,
}

function root(state = initialState, action: ClassroomActions): ClassroomState {
  switch (action.type) {
    case GET_CLASSROOM_REQUEST:
      return { ...state, loading: true, error: '' }
    case GET_CLASSROOM_SUCCESS:
      return { ...state, classroom: action.payload.classroom, loading: false, error: '' }
    case GET_CLASSROOM_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload.activeTab }
    default:
      return state
  }
}

export default root
