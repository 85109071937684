import type {
  WrittenExamActions,
  WrittenExamState,
} from '@/sections/student/writtenExam/types/writtenExam'
import {
  ACTIVITY_FAILURE,
  ACTIVITY_REQUEST,
  ACTIVITY_SUCCESS,
  ADD_SANDBOX_TEACHER_MESSAGE,
  ADD_STATUS_FAILED,
  ADD_TEACHER_MESSAGE,
  BEGIN_CAMERA,
  BEGIN_CAMERA_ERROR,
  BEGIN_EXAM,
  CANCEL_HELP_REQUEST_FAILURE,
  CANCEL_HELP_REQUEST_REQUEST,
  CANCEL_HELP_REQUEST_SUCCESS,
  CREATE_HELP_REQUEST_FAILURE,
  CREATE_HELP_REQUEST_REQUEST,
  CREATE_HELP_REQUEST_SUCCESS,
  DOWNLOAD_FILE_FAILURE,
  DOWNLOAD_FILE_REQUEST,
  DOWNLOAD_FILE_SUCCESS,
  EXAM_FINISHED,
  HAS_EXES_STARTED_SUCCESS,
  INCREASE_SOCKET_ID,
  MARK_AS_COMPLETED_FAILURE,
  MARK_AS_COMPLETED_REQUEST,
  MARK_AS_COMPLETED_SUCCESS,
  REMOVE_STATUS_FAILED,
  RESET_EXAM,
  RESHARE_SCREEN,
  SET_FONT_SIZE,
  SET_RECORDING,
  SET_SEND_ANSWERS_MODAL_OPEN,
  SET_SOCKET_AUTHENTICATION_STATUS,
  SHARE_SCREEN,
  SHARE_SCREEN_FAILED,
  UPDATE_HELP_REQUEST,
  YOU_CAN_BEGIN,
} from '@/sections/student/writtenExam/types/writtenExam'
import {
  BEGIN_EXAM_EVENT,
  CAMERA_STARTED_EVENT,
  EXAM_FINISHED_EVENT,
  EXAM_STARTED_EVENT,
  GET_EXAM_EVENT,
  SHARE_SCREEN_EVENT,
  YOU_CAN_BEGIN_EVENT,
} from '@/sections/student/writtenExam/events'
import { ACTIVITY_TYPE_WRITTEN_EXAM } from '@/legacy/components/v2/ribbons/types'
import localforage from 'localforage'
import { persistReducer } from 'redux-persist'
import { EXES_RECORDING_ENABLED } from '@/sections/student/writtenExam/constants'

const initialState: WrittenExamState = {
  fontSize: '16px',
  socketID: 1,
  socketAuthenticated: false,
  helpRequest: null,
  status: BEGIN_EXAM_EVENT,
  statusFailed: [],
  recorder: null,
  streams: [],
  loading: true,
  loadingMarkAsCompleted: false,
  loadingHelpRequest: false,
  error: '',
  errorModal: '',
  errorHelpRequest: '',
  open: false,
  finished: false,
  sendAnswersModalOpen: false,
  activity: {
    chatEnabled: false,
    classroom: {
      division: '',
      grade: '',
      id: 0,
      organization: {
        id: 0,
        name: '',
      },
      organizationType: '',
      shift: '',
      year: 0,
    },
    completedCurrent: 0,
    createdAt: '',
    date: '',
    deadline: '',
    endDate: '',
    evaluationType: 0,
    gradedCurrent: 0,
    id: 0,
    publicationDate: '',
    startDate: '',
    subject: {
      color: '',
      id: 0,
      name: '',
    },
    title: '',
    type: ACTIVITY_TYPE_WRITTEN_EXAM,
  },
  teacherMessages: [],
  downloading: 0,
  recording: false,
  cameraDelay: 5000,
  extraTime: 60000,
}

function root(state = initialState, action: WrittenExamActions): WrittenExamState {
  switch (action.type) {
    case SET_FONT_SIZE: {
      return {
        ...state,
        fontSize: action.payload.fontSize,
      }
    }
    case BEGIN_EXAM: {
      const { activityID, classroomID } = action.payload
      const recording = EXES_RECORDING_ENABLED && state.recording
      return {
        ...state,
        status: recording ? SHARE_SCREEN_EVENT : GET_EXAM_EVENT,
        finished: false,
        recorder: null,
        streams: [],
        activity: {
          ...state.activity,
          id: activityID,
          classroom: { ...state.activity.classroom, id: classroomID },
        },
      }
    }
    case SHARE_SCREEN: {
      const { recorder, streams, cameraDelay } = action.payload
      return { ...state, recorder, streams, cameraDelay, status: CAMERA_STARTED_EVENT, error: '' }
    }
    case RESHARE_SCREEN: {
      const { recorder, streams } = action.payload
      return { ...state, recorder, streams, error: '' }
    }
    case SHARE_SCREEN_FAILED:
      return { ...state, error: action.payload.error }
    case BEGIN_CAMERA_ERROR:
      return { ...state, error: action.payload.error }
    case BEGIN_CAMERA:
      return { ...state, status: GET_EXAM_EVENT }
    case YOU_CAN_BEGIN:
      return { ...state, status: EXAM_STARTED_EVENT }
    case EXAM_FINISHED:
      return { ...state, status: EXAM_FINISHED_EVENT }
    case ADD_STATUS_FAILED:
      return { ...state, statusFailed: [...state.statusFailed, action.payload.statusFailed] }
    case REMOVE_STATUS_FAILED:
      return {
        ...state,
        statusFailed: state.statusFailed.filter(
          (statusFailed) => statusFailed !== action.payload.statusFailed
        ),
      }
    case INCREASE_SOCKET_ID:
      return { ...state, socketID: state.socketID + 1 }
    case SET_SOCKET_AUTHENTICATION_STATUS:
      return { ...state, socketAuthenticated: action.payload.sockedAuthenticated }
    case ACTIVITY_REQUEST:
      return { ...state, loading: true, open: true, error: '', errorModal: '' }
    case ACTIVITY_SUCCESS: {
      const { activity } = action.payload
      return { ...state, loading: false, activity, status: YOU_CAN_BEGIN_EVENT }
    }
    case ACTIVITY_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case HAS_EXES_STARTED_SUCCESS: {
      const { finished } = action.payload.status
      if (finished) return { ...state, status: EXAM_FINISHED_EVENT }
      return state
    }
    case MARK_AS_COMPLETED_REQUEST: {
      const { partial } = action.payload
      return {
        ...state,
        loadingMarkAsCompleted: partial ? state.loadingMarkAsCompleted : true,
        open: true,
        error: '',
        errorModal: '',
      }
    }
    case MARK_AS_COMPLETED_SUCCESS: {
      return { ...initialState, finished: true }
    }
    case MARK_AS_COMPLETED_FAILURE: {
      const sendAnswersModalOpen = state.sendAnswersModalOpen
      const error = !sendAnswersModalOpen ? action.payload.error : ''
      const errorModal = sendAnswersModalOpen ? action.payload.error : ''
      return { ...state, loadingMarkAsCompleted: false, error, errorModal }
    }
    case CREATE_HELP_REQUEST_REQUEST:
      return { ...state, loadingHelpRequest: true }
    case CREATE_HELP_REQUEST_SUCCESS:
      return { ...state, loadingHelpRequest: false, helpRequest: action.payload.helpRequest }
    case CREATE_HELP_REQUEST_FAILURE:
      return { ...state, loadingHelpRequest: false, errorHelpRequest: action.payload.error }
    case CANCEL_HELP_REQUEST_REQUEST:
      return { ...state, loadingHelpRequest: true }
    case CANCEL_HELP_REQUEST_SUCCESS:
      return { ...state, loadingHelpRequest: false, helpRequest: null }
    case CANCEL_HELP_REQUEST_FAILURE:
      return { ...state, loadingHelpRequest: false, errorHelpRequest: action.payload.error }
    case UPDATE_HELP_REQUEST:
      return { ...state, helpRequest: null }
    case DOWNLOAD_FILE_REQUEST:
      return { ...state, downloading: action.payload.id, error: '' }
    case DOWNLOAD_FILE_SUCCESS:
      return { ...state, downloading: 0 }
    case DOWNLOAD_FILE_FAILURE:
      return { ...state, downloading: 0, error: action.payload.error }
    case SET_SEND_ANSWERS_MODAL_OPEN:
      return { ...state, sendAnswersModalOpen: action.payload.open, error: '', errorModal: '' }
    case ADD_SANDBOX_TEACHER_MESSAGE:
    case ADD_TEACHER_MESSAGE:
      return {
        ...state,
        teacherMessages: [...state.teacherMessages, action.payload.teacherMessage],
      }
    case SET_RECORDING:
      const { streams, recorder } = state
      return { ...initialState, recording: action.payload.recording, streams, recorder }
    case RESET_EXAM:
      return initialState
    default:
      return state
  }
}

const persistConfig = {
  key: 'student-written-exam',
  storage: localforage,
  whitelist: [],
}

export default persistReducer(persistConfig, root)
