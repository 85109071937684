import type { Reducer } from 'redux'
import type {
  ReportCardGradingScoresTabAction,
  ReportCardGradingScoresTabState,
} from '@/sections/report-card/grading/types/tabs/scoresTab'
import {
  GET_RESITTING_STUDENTS_FAILURE,
  GET_RESITTING_STUDENTS_REQUEST,
  GET_RESITTING_STUDENTS_SUCCESS,
  GET_STUDENT_SCORES_FAILURE,
  GET_STUDENT_SCORES_REQUEST,
  GET_STUDENT_SCORES_SUCCESS,
} from '@/sections/report-card/grading/types/tabs/scoresTab'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import { FIND_CLASSROOM_REPORT_CARD_CONFIG_SUCCESS } from '@/sections/report-card/grading/types/mainView'

const initialState: ReportCardGradingScoresTabState = {
  loadingScores: false,
  loadingResittingStudents: false,
  error: '',
  studentScores: [],
  numericalSubjects: [],
  conceptSubjects: [],
  resittingStudents: [],
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_GRADING_SCORES_TAB

const ReportCardGradingScoresTab: Reducer<
  ReportCardGradingScoresTabState,
  ReportCardGradingScoresTabAction
> = (state = initialState, action): ReportCardGradingScoresTabState => {
  switch (action.type) {
    case GET_STUDENT_SCORES_REQUEST:
      return { ...state, loadingScores: true, error: initialState.error }
    case GET_STUDENT_SCORES_SUCCESS:
      return { ...state, loadingScores: false, studentScores: action.payload.studentScores }
    case GET_STUDENT_SCORES_FAILURE:
      return { ...state, loadingScores: false, error: action.payload.error }
    case GET_RESITTING_STUDENTS_REQUEST:
      return { ...state, loadingResittingStudents: true, error: initialState.error }
    case GET_RESITTING_STUDENTS_SUCCESS:
      return {
        ...state,
        loadingResittingStudents: false,
        resittingStudents: action.payload.resittingStudents,
      }
    case GET_RESITTING_STUDENTS_FAILURE:
      return { ...state, loadingResittingStudents: false, error: action.payload.error }
    case FIND_CLASSROOM_REPORT_CARD_CONFIG_SUCCESS: {
      return {
        ...state,
        numericalSubjects: action.payload.config?.scores.numericalScores.subjects || [],
        conceptSubjects: action.payload.config?.scores.conceptScores.subjects || [],
      }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, ReportCardGradingScoresTab, requestDestination)
