import type { AUDIENCE_CONTENT_TYPE, Audience, Content } from '@/api/lms/library'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export interface AudienceDetailsModalState {
  isOpenModal: boolean
  audienceToSee: Audience
  searchText: string
  selectedContentType: {
    id: number
    label: AUDIENCE_CONTENT_TYPE | 'all'
  }
  status: REQUEST_STATUS
  contents: Content[]
  pagination: {
    count: number
    limit: number
    offset: number
    more: boolean
  }
}

export enum AUDIENCE_DETAILS_MODAL_TYPES {
  OPEN_MODAL = 'EBADMIN/MANAGEMENT/AUDIENCE_DETAILS_MODAL/OPEN_MODAL',
  CLOSE_MODAL = 'EBADMIN/MANAGEMENT/AUDIENCE_DETAILS_MODAL/CLOSE_MODAL',
  SET_AUDIENCE_TO_SEE = 'EBADMIN/MANAGEMENT/AUDIENCE_DETAILS_MODAL/SET_AUDIENCE_TO_SEE',
  GET_AUDIENCE_CONTENTS_REQUEST = 'EBADMIN/MANAGEMENT/AUDIENCE_DETAILS_MODAL/GET_AUDIENCE_CONTENTS_REQUEST',
  GET_AUDIENCE_CONTENTS_SUCCESS = 'EBADMIN/MANAGEMENT/AUDIENCE_DETAILS_MODAL/GET_AUDIENCE_CONTENTS_SUCCESS',
  GET_AUDIENCE_CONTENTS_FAILURE = 'EBADMIN/MANAGEMENT/AUDIENCE_DETAILS_MODAL/GET_AUDIENCE_CONTENTS_FAILURE',
  SET_PAGINATION_OFFSET = 'EBADMIN/MANAGEMENT/AUDIENCE_DETAILS_MODAL/SET_PAGINATION_OFFSET',
  SET_SEARCH_TEXT = 'EBADMIN/MANAGEMENT/AUDIENCE_DETAILS_MODAL/SET_SEARCH_TEXT',
  SET_SELECTED_CONTENT_TYPE = 'EBADMIN/MANAGEMENT/AUDIENCE_DETAILS_MODAL/SET_SELECTED_CONTENT_TYPE',
}

// Open modal action.
export type OpenModalAction = {
  type: AUDIENCE_DETAILS_MODAL_TYPES.OPEN_MODAL
}

// Close modal action.
export type CloseModalAction = {
  type: AUDIENCE_DETAILS_MODAL_TYPES.CLOSE_MODAL
}

// Set audience to see action.
export type SetAudienceToSeeAction = {
  type: AUDIENCE_DETAILS_MODAL_TYPES.SET_AUDIENCE_TO_SEE
  payload: {
    audienceToSee: Audience
  }
}

// Get audience contents actions.
export type GetAudienceContentsRequestAction = {
  type: AUDIENCE_DETAILS_MODAL_TYPES.GET_AUDIENCE_CONTENTS_REQUEST
}

export type GetAudienceContentsSuccessAction = {
  type: AUDIENCE_DETAILS_MODAL_TYPES.GET_AUDIENCE_CONTENTS_SUCCESS
  payload: {
    contents: Content[]
    count: number
    more: boolean
  }
}

export type GetAudienceContentsFailureAction = {
  type: AUDIENCE_DETAILS_MODAL_TYPES.GET_AUDIENCE_CONTENTS_FAILURE
}

export type GetAudienceContentsAction =
  | GetAudienceContentsRequestAction
  | GetAudienceContentsSuccessAction
  | GetAudienceContentsFailureAction

// Set pagination offset action.
export type SetPaginationOffsetAction = {
  type: AUDIENCE_DETAILS_MODAL_TYPES.SET_PAGINATION_OFFSET
  payload: {
    newOffset: number
  }
}

// Set search text.
export type SetSearchTextAction = {
  type: AUDIENCE_DETAILS_MODAL_TYPES.SET_SEARCH_TEXT
  payload: {
    newSearchText: string
  }
}

// Set selected content type.
export type SetSelectedContentTypeAction = {
  type: AUDIENCE_DETAILS_MODAL_TYPES.SET_SELECTED_CONTENT_TYPE
  payload: {
    newSelectedContentType: AudienceDetailsModalState['selectedContentType']
  }
}

export type AudienceDetailsModalAction =
  | OpenModalAction
  | CloseModalAction
  | SetAudienceToSeeAction
  | GetAudienceContentsAction
  | SetPaginationOffsetAction
  | SetSearchTextAction
  | SetSelectedContentTypeAction
