import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import classNames from 'clsx'

const useClasses = makeStyles((theme) => ({
  error: {
    color: 'red',
  },
}))

export const Error = ({ className = '', error }) => {
  const classes = useClasses()
  return error && <Typography className={classNames(classes.error, className)}>{error}</Typography>
}

export default Error
