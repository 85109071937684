import { Typography } from '@mui/material'
import classNames from 'clsx'
import renderEmoji from '@/legacy/components/v1/utils/renderEmoji'
import React from 'react'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  badge: ({ description }) => ({
    background: 'white',
    border: '1px solid #DFDFDA',
    color: theme.palette.text.primary,
    borderRadius: 20,
    fontSize: 12,
    padding: '3px 7px',
    lineHeight: '14px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      ...(description && { paddingRight: 4 }),
    },
  }),
}))

const BadgeV2 = ({ description, emoji, className }) => {
  const classes = useStyles({ description })
  return (
    <Typography className={classNames(classes.badge, className)}>
      {emoji && renderEmoji(emoji)} {description}
    </Typography>
  )
}

export default BadgeV2
