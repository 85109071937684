import type { Document } from '@/api/lms/library'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

// State.
export interface DocumentationTableState {
  status: REQUEST_STATUS
  documents: Document[]
  pagination: {
    more: boolean
    count: number
    rowsPerPage: number
    page: number
  }
}

// Types.
export enum DOCUMENTATION_TABLE_TYPES {
  GET_DOCUMENTATION_LIST_REQUEST = 'PRINCIPAL/DOCUMENTATION/TABLE/GET_DOCUMENTATION_LIST_REQUEST',
  GET_DOCUMENTATION_LIST_SUCCESS = 'PRINCIPAL/DOCUMENTATION/TABLE/GET_DOCUMENTATION_LIST_SUCCESS',
  GET_DOCUMENTATION_LIST_FAILURE = 'PRINCIPAL/DOCUMENTATION/TABLE/GET_DOCUMENTATION_LIST_FAILURE',
  CLEAN_DOCUMENTATION_LIST = 'PRINCIPAL/DOCUMENTATION/TABLE/CLEAN_DOCUMENTATION_LIST',
}

// Actions.
// Get documentation list actions.
export type GetDocumentationListRequestAction = {
  type: DOCUMENTATION_TABLE_TYPES.GET_DOCUMENTATION_LIST_REQUEST
}

export type GetDocumentationListSuccessAction = {
  type: DOCUMENTATION_TABLE_TYPES.GET_DOCUMENTATION_LIST_SUCCESS
  payload: {
    documents: Document[]
    count: number
    more: boolean
    newPage: number
  }
}

export type GetDocumentationListFailureAction = {
  type: DOCUMENTATION_TABLE_TYPES.GET_DOCUMENTATION_LIST_FAILURE
}

export type GetDocumentationListAction =
  | GetDocumentationListRequestAction
  | GetDocumentationListSuccessAction
  | GetDocumentationListFailureAction

// Clean documentation list action.
export type CleanDocumentationListAction = {
  type: DOCUMENTATION_TABLE_TYPES.CLEAN_DOCUMENTATION_LIST
}

// Action.
export type DocumentationTableAction = GetDocumentationListAction | CleanDocumentationListAction
