import { API } from '@/api/lms'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { AppThunk } from '@/state/thunk'
import { pagesToLimit } from '@/legacy/utils/generalUtils'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import type {
  ListOrganizationsAsAdminRequest,
  ListOrganizationsAsAdminSuccess,
  ListOrganizationsAsPrincipalRequest,
  ListOrganizationsAsPrincipalSuccess,
} from '@/legacy/redux/types/organizations'
import {
  LIST_ORGANIZATIONS_AS_ADMIN_FAILURE,
  LIST_ORGANIZATIONS_AS_ADMIN_REQUEST,
  LIST_ORGANIZATIONS_AS_ADMIN_SUCCESS,
  LIST_ORGANIZATIONS_AS_PRINCIPAL_REQUEST,
  LIST_ORGANIZATIONS_AS_PRINCIPAL_SUCCESS,
} from '@/legacy/redux/types/organizations'
import type { GenericPagination } from '@/legacy/redux/utils'
import { ListAsManagementParams } from '@/api/lms/organizations'

const OrganizationsActionCreators = {
  listAsAdmin:
    (
      requestOrigins: ReduxRequestOrigin[],
      pagination: GenericPagination,
      search?: string,
      organizationId?: number
    ): AppThunk =>
    async (dispatch) => {
      const request: ListOrganizationsAsAdminRequest = {
        type: LIST_ORGANIZATIONS_AS_ADMIN_REQUEST,
        payload: {
          page: pagination.page,
          rowsPerPage: pagination.rowsPerPage,
          requestOrigins,
        },
      }
      dispatch(request)

      try {
        const params = {
          ...pagesToLimit(pagination),
          ...(search?.length && { search }),
          ...(organizationId && { organizationId }),
          role: 'eb-admin' as ListAsManagementParams['role'],
        }

        const { total, organizations } = await API.Organizations.listAsManagement(params)

        const success: ListOrganizationsAsAdminSuccess = {
          type: LIST_ORGANIZATIONS_AS_ADMIN_SUCCESS,
          payload: {
            requestOrigins,
            count: total,
            organizations,
          },
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, LIST_ORGANIZATIONS_AS_ADMIN_FAILURE, dispatch, {
          requestOrigins,
        })
      }
    },
  listAsPrincipal:
    (requestOrigins: ReduxRequestOrigin[], organizationId: number): AppThunk =>
    async (dispatch) => {
      const request: ListOrganizationsAsPrincipalRequest = {
        type: LIST_ORGANIZATIONS_AS_PRINCIPAL_REQUEST,
        payload: {
          requestOrigins,
        },
      }
      dispatch(request)

      try {
        const organization = await API.Organizations.listAsPrincipal(organizationId)

        const success: ListOrganizationsAsPrincipalSuccess = {
          type: LIST_ORGANIZATIONS_AS_PRINCIPAL_SUCCESS,
          payload: {
            requestOrigins,
            organization,
          },
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, LIST_ORGANIZATIONS_AS_ADMIN_FAILURE, dispatch, {
          requestOrigins,
        })
      }
    },
}

export default OrganizationsActionCreators
