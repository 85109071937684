import type { AnyAction, Reducer } from 'redux'
import type { ReduxRequestOrigin } from './constants'
import type { Either } from '@/legacy/utils/types'
import type { GenericFieldPayload } from './types/generic'

export const toggleEntity = <E>(existingArray: E[], toggledEntity: E, keyToMatch?: keyof E) => {
  const filtered = existingArray.filter((e) =>
    keyToMatch ? e[keyToMatch] !== toggledEntity[keyToMatch] : e !== toggledEntity
  )
  if (filtered.length === existingArray.length) filtered.push(toggledEntity)

  return filtered
}

export const setGenericField = <S, V>(
  previousState: S,
  payload: GenericFieldPayload<string, V>
) => {
  const { fieldName, fieldValue } = payload
  if (!Object.keys(previousState).includes(fieldName)) {
    return previousState
  }

  return {
    ...previousState,
    [fieldName]: fieldValue,
  }
}

export interface GenericPayload {
  requestOrigins: ReduxRequestOrigin[]
}

export interface ErrorPayload {
  error: string
  requestOrigins: ReduxRequestOrigin[]
}

export interface GenericAction extends AnyAction {
  payload: {
    requestOrigin?: ReduxRequestOrigin
    requestOrigins?: ReduxRequestOrigin[]
  }
}

// CURRENT VERSION

type PayloadExtraProps<Type> = {
  [Property in keyof Type]: Type[Property]
}

type SingleRequestOriginType = {
  requestOrigin: ReduxRequestOrigin
}

type MultipleRequestOriginType = {
  requestOrigins: ReduxRequestOrigin[]
}

export interface GenericActionWithType<ActionType, ExtraPayloadProps = void> extends AnyAction {
  type: ActionType
  payload: Either<SingleRequestOriginType, MultipleRequestOriginType> &
    (PayloadExtraProps<ExtraPayloadProps> | ExtraPayloadProps)
}

export const checkRequestOrigin =
  <S, A extends GenericAction>(
    initialState: S,
    reducer: Reducer<S, A>,
    destination: ReduxRequestOrigin,
    exemptTypes?: string[] // types that come from non-TS files but need to be allowed in a TS reducer
  ): Reducer<S, A> =>
  (previousState, action) => {
    if (
      action.payload?.requestOrigin === destination ||
      action.payload?.requestOrigins?.find((ro) => ro === destination) ||
      exemptTypes?.find((type) => type === action.type)
    )
      return reducer(previousState, action)

    if (previousState) return previousState
    return initialState
  }

export type GenericPagination = {
  page: number
  rowsPerPage: number
}
