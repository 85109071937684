import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/content-creator/sequenceDrawer/types/drawer'

const initialState: Types.DrawerState = {
  isOpen: false,
  currentStep: Types.DRAWER_STEPS.INITIAL,
  isEditMode: false,
  sequenceToEdit: {
    status: REQUEST_STATUS.IDLE,
    sequence: null,
  },
}

const drawerReducer = (
  state: Types.DrawerState = initialState,
  action: Types.DrawerAction
): Types.DrawerState => {
  switch (action.type) {
    case Types.DRAWER_TYPES.OPEN_DRAWER: {
      return {
        ...state,
        isOpen: true,
      }
    }
    case Types.DRAWER_TYPES.CLOSE_DRAWER: {
      return initialState
    }
    case Types.DRAWER_TYPES.SET_CURRENT_STEP: {
      return {
        ...state,
        currentStep: action.payload.newStep,
      }
    }
    case Types.DRAWER_TYPES.OPEN_DRAWER_IN_EDIT_MODE: {
      return {
        ...state,
        isOpen: true,
        isEditMode: true,
      }
    }
    case Types.DRAWER_TYPES.GET_SEQUENCE_REQUEST: {
      return {
        ...state,
        sequenceToEdit: {
          ...state.sequenceToEdit,
          status: REQUEST_STATUS.LOADING,
        },
      }
    }
    case Types.DRAWER_TYPES.GET_SEQUENCE_SUCCESS: {
      const { sequence } = action.payload
      return {
        ...state,
        sequenceToEdit: {
          sequence,
          status: REQUEST_STATUS.SUCCESS,
        },
      }
    }
    case Types.DRAWER_TYPES.GET_SEQUENCE_FAILURE: {
      return {
        ...state,
        sequenceToEdit: {
          ...state.sequenceToEdit,
          status: REQUEST_STATUS.FAILURE,
        },
      }
    }
    case Types.DRAWER_TYPES.EDIT_SEQUENCE_REQUEST: {
      return {
        ...state,
        sequenceToEdit: {
          ...state.sequenceToEdit,
          status: REQUEST_STATUS.LOADING,
        },
      }
    }
    case Types.DRAWER_TYPES.EDIT_SEQUENCE_SUCCESS: {
      return initialState
    }
    case Types.DRAWER_TYPES.EDIT_SEQUENCE_FAILURE: {
      return {
        ...state,
        sequenceToEdit: {
          ...state.sequenceToEdit,
          status: REQUEST_STATUS.SUCCESS,
        },
      }
    }
    default:
      return state
  }
}

export default drawerReducer
