import React from 'react'
import classNames from 'clsx'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import renderEmoji from '@/legacy/components/v1/utils/renderEmoji'

const useStyles = makeStyles((theme) => ({
  badge: ({ description }) => ({
    background: '#EDEFF8',
    color: theme.palette.text.primary,
    borderRadius: 20,
    fontSize: 14,
    lineHeight: '18px',
    height: 24,
    padding: '3px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      ...(description && { paddingRight: 5 }),
    },
  }),
}))

const Badge = ({ description, emoji, className }) => {
  const classes = useStyles({ description })
  return (
    <Typography className={classNames(classes.badge, className)}>
      {emoji && renderEmoji(emoji)} {description}
    </Typography>
  )
}

export default Badge
