import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import type { Feature } from '@/legacy/redux/types/features'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import * as Types from '@/sections/management/types/organizations/featuresModal'
import type {
  FeaturesModalAction,
  FeaturesModalState,
} from '@/sections/management/types/organizations/featuresModal'

const initialState: FeaturesModalState = {
  open: false,
  loading: false,
  error: '',
  featuresList: [],
  activeFeatures: [],
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_FEATURES_MODAL

const FeaturesModalReducer: Reducer<FeaturesModalState, FeaturesModalAction> = (
  state = initialState,
  action
): FeaturesModalState => {
  switch (action.type) {
    case Types.CLEAR_FIELDS:
      return initialState
    case Types.OPEN_MODAL_REQUEST:
      return { ...state, open: true, loading: true }
    case Types.OPEN_MODAL_SUCCESS:
      return {
        ...state,
        loading: false,
        featuresList: action.payload.featuresList,
        activeFeatures: action.payload.activeFeatures,
      }
    case Types.OPEN_MODAL_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        featuresList: [],
        activeFeatures: [],
      }
    case Types.TOGGLE_SWITCH: {
      const isSelected = state.activeFeatures.some((f) => f.id === action.payload.feature.id)
      let activeFeatures: Feature[]
      if (isSelected) {
        activeFeatures = state.activeFeatures.filter((sf) => sf.id !== action.payload.feature.id)
      } else {
        activeFeatures = [...state.activeFeatures, action.payload.feature]
      }
      return { ...state, activeFeatures }
    }

    default:
      return state
  }
}

export default checkRequestOrigin(initialState, FeaturesModalReducer, requestDestination)
