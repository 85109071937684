import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type { Reducer } from 'redux'
import { FIND_CLASSROOM_REPORT_CARD_CONFIG_SUCCESS } from '@/sections/report-card/grading/types/mainView'
import { Redux } from '@/legacy/redux'
import type {
  CompetenceScoreTypeAndSelectedValue,
  CompetenceScoresGradingFormState,
  CompetenceScoressGradingFormAction,
} from '@/sections/report-card/grading/types/gradingForms/competenceScores'
import {
  INIT_STUDENT_COMPETENCE_SCORES,
  SAVE_COMPETENCE_SCORES_FAILURE,
  SAVE_COMPETENCE_SCORES_REQUEST,
  SAVE_COMPETENCE_SCORES_SUCCESS,
  SET_COMPETENCE_VALUE,
  UPDATE_COMPETENCE_SCORES_FAILURE,
  UPDATE_COMPETENCE_SCORES_REQUEST,
  UPDATE_COMPETENCE_SCORES_SUCCESS,
} from '@/sections/report-card/grading/types/gradingForms/competenceScores'

const initialState: CompetenceScoresGradingFormState = {
  competenceScoreTypesAndSelectedValues: [],
  competenceScoreOptions: [],
  loading: false,
  error: '',
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_GRADING_COMPETENCE_SCORES_FORM

const ReportCardGradingAttitudinalScoresForm: Reducer<
  CompetenceScoresGradingFormState,
  CompetenceScoressGradingFormAction
> = (state = initialState, action): CompetenceScoresGradingFormState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case Redux.Types.Generic.SET_FIELD:
      return setGenericField(state, action.payload)
    case FIND_CLASSROOM_REPORT_CARD_CONFIG_SUCCESS: {
      const competenceScoreValues = action.payload.config?.competenceScores.values
      const competenceScoreTypes = action.payload.config?.competenceScores.types

      const competenceScoreSelectedValues: CompetenceScoreTypeAndSelectedValue[] =
        !competenceScoreTypes
          ? initialState.competenceScoreTypesAndSelectedValues
          : competenceScoreTypes?.map((scoreType) => ({
              ...scoreType,
              competenceTypeId: scoreType.id,
              selectedValueId: competenceScoreValues?.length ? competenceScoreValues[0].id : 0,
            }))

      return {
        ...state,
        competenceScoreOptions: competenceScoreValues || initialState.competenceScoreOptions,
        competenceScoreTypesAndSelectedValues: competenceScoreSelectedValues,
      }
    }
    case INIT_STUDENT_COMPETENCE_SCORES: {
      const { currentValues } = action.payload

      const competenceScoreSelectedValues: CompetenceScoreTypeAndSelectedValue[] =
        state.competenceScoreTypesAndSelectedValues.map((selectedValue) => ({
          ...selectedValue,
          selectedValueId:
            currentValues?.find((value) => value.typeId === selectedValue.competenceTypeId)
              ?.valueId ?? 0,
        }))

      return {
        ...state,
        competenceScoreTypesAndSelectedValues: competenceScoreSelectedValues,
      }
    }
    case SET_COMPETENCE_VALUE: {
      const { newValueId, competenceTypeId } = action.payload

      const competenceScoreSelectedValues: CompetenceScoreTypeAndSelectedValue[] =
        state.competenceScoreTypesAndSelectedValues.map((selectedValue) =>
          selectedValue.competenceTypeId === competenceTypeId
            ? {
                ...selectedValue,
                selectedValueId: newValueId,
              }
            : selectedValue
        )

      return {
        ...state,
        competenceScoreTypesAndSelectedValues: competenceScoreSelectedValues,
      }
    }
    case SAVE_COMPETENCE_SCORES_REQUEST:
    case UPDATE_COMPETENCE_SCORES_REQUEST:
      return { ...state, loading: true, error: initialState.error }
    case SAVE_COMPETENCE_SCORES_SUCCESS:
    case UPDATE_COMPETENCE_SCORES_SUCCESS:
      return { ...state, loading: false }
    case SAVE_COMPETENCE_SCORES_FAILURE:
    case UPDATE_COMPETENCE_SCORES_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(
  initialState,
  ReportCardGradingAttitudinalScoresForm,
  requestDestination
)
