import type {
  GenericQuestion,
  Matches,
  QuestionsActionTypes,
  QuestionsState,
} from '@/sections/editor/types/questions'
import {
  ADD_IMAGE_TO_OPTION_FAILURE,
  ADD_IMAGE_TO_OPTION_REQUEST,
  ADD_IMAGE_TO_OPTION_SUCCESS,
  ADD_IMAGE_TO_QUESTION_FAILURE,
  ADD_IMAGE_TO_QUESTION_REQUEST,
  ADD_IMAGE_TO_QUESTION_SUCCESS,
  ADD_OPTION,
  ADD_QUESTION,
  ADD_UNION_OPTION,
  EDIT_QUESTION,
  EDIT_SCORE,
  MOVE_QUESTION_DOWN,
  MOVE_QUESTION_UP,
  REMOVE_IMAGE_FROM_OPTION,
  REMOVE_IMAGE_FROM_QUESTION,
  REMOVE_OPTION,
  REMOVE_QUESTION,
  REMOVE_UNION_OPTION,
  SET_BLANK_ANSWER,
  SET_BODY,
  SET_CLOSE,
  SET_CORRECT_OPTION,
  SET_DIVIDER,
  SET_EDIT,
  SET_LINE_HEIGHT,
  SET_MAX_SCORE,
  SET_MIN_LENGTH_TOGGLE,
  SET_OPEN,
  SET_OPTION,
  SET_QUESTION,
  SET_QUESTION_ANSWER,
  SET_QUESTION_IFRAME,
  SET_QUESTION_MIN_LENGTH,
  SET_QUESTION_TYPE,
  SET_QUESTION_WORD_COUNT,
  SET_SCORE,
  SET_TIME,
  SET_TIME_LIMIT_ENABLED,
  SET_UNION_ANSWER,
  SET_UNION_MATCH,
  SET_UNION_OPTION,
  SET_WORD_COUNT_TOGGLE,
} from '@/sections/editor/types/questions'
import createActivityActionTypes from '@/sections/teacher/activities/types/create'
import getActivityStudentActionTypes from '@/sections/student/dashboard/types/activity'
import getActivityTeacherActionTypes from '@/sections/teacher/dashboard/types/activity'
import { ACTIVITY_SUCCESS as WRITTEN_EXAM_ACTIVITY_SUCCESS } from '@/sections/student/writtenExam/types/writtenExam'
import {
  QUESTIONS_MODAL_TYPE_QUESTIONS,
  QUESTIONS_MODAL_TYPE_SECTIONS,
  QUESTIONS_MODAL_TYPE_TEXT,
  QUESTIONS_TYPES,
  UNION_ANSWER_LEFT,
  UNION_ANSWER_RIGHT,
} from '@/sections/editor/constants'
import {
  formatQuestionTemplate,
  sortQuestionsWithSections,
  getAnswersTemplates,
  retrieveQuestionsFromAPI,
} from '@/sections/editor/utils'
import { isNil } from 'ramda'
import { ActivityTypes } from '@/api/lms/activities/activities'
import * as useTranslation from 'i18next'

const initialState: QuestionsState = {
  open: false,
  modalType: QUESTIONS_MODAL_TYPE_QUESTIONS,
  questionTemplate: {
    type: QUESTIONS_TYPES.CHOICE,
    imageLoading: false,
    url: null,
    iframeUrl: '',
    text: '',
    score: 0,
    time: 0,
    lineHeight: 5,
    wordCount: 0,
    divider: true,
    minLength: 0,
    sectionId: null,
    id: '',
    options: [
      { text: '', order: 0, type: 'text', url: '', loading: false },
      { text: '', order: 1, type: 'text', url: '', loading: false },
    ],
    unionOptions: {
      [UNION_ANSWER_LEFT]: [
        {
          text: '',
          order: 1,
          column: UNION_ANSWER_LEFT,
          type: 'text',
          url: '',
          loading: false,
          matchesWith: 1,
        },
      ],
      [UNION_ANSWER_RIGHT]: [
        {
          text: '',
          order: 1,
          column: UNION_ANSWER_RIGHT,
          type: 'text',
          url: '',
          loading: false,
          matchesWith: 1,
        },
      ],
    },
  },
  maxScore: 10,
  timeLimitEnabled: true,
  loading: false,
  error: '',
  questions: [],
  answers: {},
  editingQuestion: null,
  minLengthToggle: false,
  wordCountToggle: false,
}

const getQuestionsCopy = (questions: GenericQuestion[]): GenericQuestion[] => questions.slice()

function root(state = initialState, action: QuestionsActionTypes): QuestionsState {
  const options = state.questionTemplate.options
  switch (action.type) {
    case SET_OPEN: {
      const { modalType } = action.payload
      const questionTemplate = {
        ...initialState.questionTemplate,
        type:
          modalType === QUESTIONS_MODAL_TYPE_TEXT
            ? QUESTIONS_TYPES.TEXT
            : modalType === QUESTIONS_MODAL_TYPE_SECTIONS
            ? QUESTIONS_TYPES.SECTION
            : QUESTIONS_TYPES.CHOICE,
      }
      return { ...state, open: true, questionTemplate, modalType }
    }
    case SET_EDIT: {
      const { index } = action.payload
      return {
        ...state,
        open: true,
        questionTemplate: state.questions[index],
        minLengthToggle: !!state.questions[index].minLength || false,
        wordCountToggle: !!state.questions[index].wordCount || false,
        editingQuestion: index,
      }
    }
    case SET_CLOSE:
      if (!isNil(state.editingQuestion))
        return {
          ...state,
          open: false,
          questionTemplate: initialState.questionTemplate,
          minLengthToggle: false,
          editingQuestion: null,
        }
      return { ...state, open: false }
    case SET_QUESTION_TYPE:
      return {
        ...state,
        questionTemplate: {
          ...initialState.questionTemplate,
          text: state.questionTemplate.text,
          type: action.payload.type,
        },
      }
    case SET_QUESTION:
      return {
        ...state,
        questionTemplate: { ...state.questionTemplate, text: action.payload.text },
      }
    case SET_DIVIDER:
      return {
        ...state,
        questionTemplate: { ...state.questionTemplate, divider: action.payload.divider },
      }
    case SET_BODY:
      return {
        ...state,
        questionTemplate: { ...state.questionTemplate, body: action.payload.body },
      }
    case SET_SCORE: {
      const { score } = action.payload
      return {
        ...state,
        questionTemplate: {
          ...state.questionTemplate,
          score,
        },
      }
    }
    case SET_TIME: {
      const { time } = action.payload
      return {
        ...state,
        questionTemplate: {
          ...state.questionTemplate,
          time,
        },
      }
    }
    case SET_TIME_LIMIT_ENABLED: {
      const { timeLimitEnabled } = action.payload
      return {
        ...state,
        timeLimitEnabled,
        questionTemplate: {
          ...state.questionTemplate,
          ...(!timeLimitEnabled && { time: 0 }),
        },
      }
    }
    case SET_MAX_SCORE: {
      const { maxScore } = action.payload
      return { ...state, maxScore }
    }
    case EDIT_SCORE: {
      const { index, score } = action.payload
      const questions = getQuestionsCopy(state.questions)

      questions[index].score = score

      return {
        ...state,
        questions,
      }
    }
    case SET_QUESTION_MIN_LENGTH:
      return {
        ...state,
        questionTemplate: { ...state.questionTemplate, minLength: action.payload.minLength },
      }
    case SET_QUESTION_WORD_COUNT:
      return {
        ...state,
        questionTemplate: { ...state.questionTemplate, wordCount: action.payload.wordCount },
      }
    case SET_WORD_COUNT_TOGGLE: {
      return {
        ...state,
        questionTemplate: {
          ...state.questionTemplate,
          wordCount: 0,
        },
        wordCountToggle: !state.wordCountToggle,
      }
    }
    case SET_MIN_LENGTH_TOGGLE: {
      return {
        ...state,
        questionTemplate: {
          ...state.questionTemplate,
          minLength: 0,
        },
        minLengthToggle: !state.minLengthToggle,
      }
    }
    case SET_LINE_HEIGHT: {
      return {
        ...state,
        questionTemplate: {
          ...state.questionTemplate,
          lineHeight: action.payload.lineHeight,
        },
      }
    }
    case ADD_OPTION: {
      return {
        ...state,
        questionTemplate: {
          ...state.questionTemplate,
          options: [
            ...options,
            { type: 'text', text: '', url: '', loading: false, order: options.length },
          ],
        },
      }
    }
    case SET_OPTION: {
      const { text, index } = action.payload
      return {
        ...state,
        questionTemplate: {
          ...state.questionTemplate,
          options: options.map((option, i) => (i === index ? { ...option, text } : option)),
        },
      }
    }
    case ADD_UNION_OPTION: {
      const { column } = action.payload
      const unionOptions = state.questionTemplate.unionOptions

      return {
        ...state,
        questionTemplate: {
          ...state.questionTemplate,
          unionOptions: {
            ...unionOptions,
            [column]: [
              ...unionOptions[column],
              {
                type: 'text',
                text: '',
                url: '',
                column,
                loading: false,
                order: unionOptions[column].length + 1,
                matchesWith: 1,
              },
            ],
          },
        },
      }
    }
    case REMOVE_UNION_OPTION: {
      const { column, index } = action.payload
      const unionOptions = state.questionTemplate.unionOptions

      return {
        ...state,
        questionTemplate: {
          ...state.questionTemplate,
          unionOptions: {
            ...unionOptions,
            [column]: [
              ...unionOptions[column]
                .filter((option, i) => i !== index)
                .map((option, i) => {
                  return { ...option, order: i + 1 }
                }),
            ],
            ...(column === UNION_ANSWER_RIGHT
              ? {
                  [UNION_ANSWER_LEFT]: [
                    ...unionOptions[UNION_ANSWER_LEFT].map((option, i) =>
                      option.matchesWith === index + 1 ? { ...option, matchesWith: index } : option
                    ),
                  ],
                }
              : {}),
          },
        },
      }
    }
    case SET_UNION_OPTION: {
      const { column, text, index } = action.payload
      const unionOptions = state.questionTemplate.unionOptions

      return {
        ...state,
        questionTemplate: {
          ...state.questionTemplate,
          unionOptions: {
            ...unionOptions,
            [column]: [
              ...unionOptions[column].map((option, i) =>
                i === index ? { ...option, text } : option
              ),
            ],
          },
        },
      }
    }
    case SET_UNION_MATCH: {
      const { index, match } = action.payload
      const unionOptions = state.questionTemplate.unionOptions

      return {
        ...state,
        questionTemplate: {
          ...state.questionTemplate,
          unionOptions: {
            ...unionOptions,
            [UNION_ANSWER_LEFT]: [
              ...unionOptions[UNION_ANSWER_LEFT].map((option, i) =>
                i === index ? { ...option, matchesWith: match } : option
              ),
            ],
            [UNION_ANSWER_RIGHT]: [
              ...unionOptions[UNION_ANSWER_RIGHT].map((option, i) =>
                i + 1 === match ? { ...option, matchesWith: index + 1 } : option
              ),
            ],
          },
        },
      }
    }
    case ADD_IMAGE_TO_QUESTION_REQUEST: {
      return { ...state, questionTemplate: { ...state.questionTemplate, imageLoading: true } }
    }
    case ADD_IMAGE_TO_QUESTION_SUCCESS: {
      return {
        ...state,
        questionTemplate: {
          ...state.questionTemplate,
          imageLoading: false,
          url: action.payload.url,
        },
      }
    }
    case ADD_IMAGE_TO_QUESTION_FAILURE: {
      return { ...state, questionTemplate: { ...state.questionTemplate, imageLoading: false } }
    }
    case REMOVE_IMAGE_FROM_QUESTION: {
      return { ...state, questionTemplate: { ...state.questionTemplate, url: null } }
    }
    case SET_QUESTION_IFRAME: {
      return {
        ...state,
        questionTemplate: { ...state.questionTemplate, iframeUrl: action.payload.iframeUrl },
      }
    }
    case ADD_IMAGE_TO_OPTION_REQUEST: {
      const { index, column } = action.payload
      const unionOptions = state.questionTemplate.unionOptions

      if (column) {
        return {
          ...state,
          questionTemplate: {
            ...state.questionTemplate,
            unionOptions: {
              ...unionOptions,
              [column]: [
                ...unionOptions[column].map((option, i) =>
                  i === index ? { ...option, loading: true } : option
                ),
              ],
            },
          },
        }
      } else {
        return {
          ...state,
          loading: true,
          error: '',
          questionTemplate: {
            ...state.questionTemplate,
            options: options.map((option, i) =>
              i === index ? { ...option, loading: true } : option
            ),
          },
        }
      }
    }
    case ADD_IMAGE_TO_OPTION_SUCCESS: {
      const { url, index, column } = action.payload
      const unionOptions = state.questionTemplate.unionOptions

      if (column) {
        return {
          ...state,
          questionTemplate: {
            ...state.questionTemplate,
            unionOptions: {
              ...unionOptions,
              [column]: [
                ...unionOptions[column].map((option, i) =>
                  i === index ? { ...option, url, loading: false } : option
                ),
              ],
            },
          },
        }
      } else {
        return {
          ...state,
          loading: false,
          error: '',
          questionTemplate: {
            ...state.questionTemplate,
            options: options.map((option, i) =>
              i === index ? { ...option, type: 'image', url, loading: false } : option
            ),
          },
        }
      }
    }
    case ADD_IMAGE_TO_OPTION_FAILURE: {
      const { error, index, column } = action.payload
      const unionOptions = state.questionTemplate.unionOptions

      if (column) {
        return {
          ...state,
          questionTemplate: {
            ...state.questionTemplate,
            unionOptions: {
              ...unionOptions,
              [column]: [
                ...unionOptions[column].map((option, i) =>
                  i === index ? { ...option, loading: false } : option
                ),
              ],
            },
          },
        }
      } else {
        return {
          ...state,
          loading: false,
          error,
          questionTemplate: {
            ...state.questionTemplate,
            options: options.map((option, i) =>
              i === index ? { ...option, type: 'text', loading: false } : option
            ),
          },
        }
      }
    }
    case REMOVE_IMAGE_FROM_OPTION: {
      const { index, column } = action.payload

      const unionOptions = state.questionTemplate.unionOptions

      if (column) {
        return {
          ...state,
          questionTemplate: {
            ...state.questionTemplate,
            unionOptions: {
              ...unionOptions,
              [column]: [
                ...unionOptions[column].map((option, i) =>
                  i === index ? { ...option, url: null } : option
                ),
              ],
            },
          },
        }
      } else {
        return {
          ...state,
          questionTemplate: {
            ...state.questionTemplate,
            options: options.map((option, i) =>
              i === index ? { ...option, type: 'text', url: null } : option
            ),
          },
        }
      }
    }
    case SET_CORRECT_OPTION: {
      const { index } = action.payload
      return {
        ...state,
        questionTemplate: {
          ...state.questionTemplate,
          options: options.map((option, i) =>
            i === index
              ? { ...option, correct: true }
              : {
                  ...option,
                  correct: false,
                }
          ),
        },
      }
    }
    case SET_QUESTION_ANSWER: {
      const { id, type, answer } = action.payload
      const answersCopy = { ...state.answers }
      switch (type) {
        case QUESTIONS_TYPES.WRITING:
          if (typeof answer === 'string') answersCopy[id].answer = answer
          break
        case QUESTIONS_TYPES.CHOICE:
          if (typeof answer === 'number') answersCopy[id].optionId = answer
          break
      }
      return { ...state, answers: answersCopy }
    }
    case SET_BLANK_ANSWER: {
      const { id, number, answer } = action.payload
      const answersCopy = { ...state.answers }

      const newAnswer = answersCopy[id]
      const filled = newAnswer.filled || {}
      filled[number] = answer

      return { ...state, answers: { ...answersCopy, [id]: newAnswer } }
    }
    case SET_UNION_ANSWER: {
      const { id, leftOrder, rightOrder } = action.payload
      const answersCopy = { ...state.answers }

      const newAnswer = answersCopy[id]
      const matches: Matches = newAnswer.matches || {
        [UNION_ANSWER_LEFT]: {},
        [UNION_ANSWER_RIGHT]: {},
      }

      const leftId = 'col' + UNION_ANSWER_LEFT + 'ord' + leftOrder
      const rightId = 'col' + UNION_ANSWER_RIGHT + 'ord' + rightOrder

      matches[UNION_ANSWER_LEFT][leftId].matchesWith = rightOrder
      if (rightOrder !== 0) matches[UNION_ANSWER_RIGHT][rightId].matchesWith = leftOrder

      return { ...state, answers: { ...answersCopy, [id]: newAnswer } }
    }
    case REMOVE_OPTION: {
      const { index } = action.payload
      return {
        ...state,
        questionTemplate: {
          ...state.questionTemplate,
          options: options.filter((option, i) => i !== index),
        },
      }
    }
    case ADD_QUESTION: {
      const formattedQuestion = formatQuestionTemplate(state.questionTemplate)
      const questions = [...state.questions]

      if (formattedQuestion.type === QUESTIONS_TYPES.SECTION) {
        const newSectionId = crypto.randomUUID()

        if (questions.filter((q) => q.type === QUESTIONS_TYPES.SECTION).length === 0) {
          const firstSection = {
            ...formattedQuestion,
            id: newSectionId,
            text: useTranslation.t('editor.firstSection'),
          }

          questions.forEach((q) => {
            if (q.type !== QUESTIONS_TYPES.SECTION && !q.sectionId) {
              q.sectionId = newSectionId
            }
          })

          formattedQuestion.id = crypto.randomUUID()

          return {
            ...state,
            open: false,
            questionTemplate: initialState.questionTemplate,
            timeLimitEnabled: false,
            questions: sortQuestionsWithSections([...questions, firstSection, formattedQuestion]),
          }
        }

        formattedQuestion.id = newSectionId
        return {
          ...state,
          open: false,
          questionTemplate: initialState.questionTemplate,
          timeLimitEnabled: false,
          questions: sortQuestionsWithSections([...questions, formattedQuestion]),
        }
      }

      if (!formattedQuestion.sectionId) {
        const sections = questions.filter((q) => q.type === QUESTIONS_TYPES.SECTION)

        if (sections.length > 0) {
          const lastSectionWithQuestions = sections.findLastIndex((section) =>
            questions.some((q) => q.sectionId === section.id)
          )

          formattedQuestion.sectionId =
            lastSectionWithQuestions !== -1 ? sections[lastSectionWithQuestions].id : sections[0].id
        }
      }

      const attachments = questions.filter((q) => q.type === 'attachment')
      const nonAttachmentQuestions = questions.filter((q) => q.type !== 'attachment')

      const isQuiz = action.payload.activityType === ActivityTypes.QUIZ
      const firstQuestionIsText = nonAttachmentQuestions?.[0]?.type === QUESTIONS_TYPES.TEXT
      const newQuestionIsText = formattedQuestion.type === QUESTIONS_TYPES.TEXT

      const finalQuestions =
        isQuiz && !firstQuestionIsText && newQuestionIsText
          ? [formattedQuestion, ...nonAttachmentQuestions, ...attachments]
          : sortQuestionsWithSections([...questions, formattedQuestion])

      return {
        ...state,
        open: false,
        questionTemplate: initialState.questionTemplate,
        timeLimitEnabled: false,
        questions: finalQuestions,
      }
    }
    case EDIT_QUESTION: {
      const { editingQuestion, questionTemplate } = state
      const formattedQuestion = formatQuestionTemplate(questionTemplate)
      const questions = getQuestionsCopy(state.questions)
      if (!isNil(editingQuestion)) questions.splice(editingQuestion, 1, formattedQuestion)
      return {
        ...state,
        open: false,
        questionTemplate: initialState.questionTemplate,
        editingQuestion: null,
        questions,
      }
    }
    case REMOVE_QUESTION: {
      const { index } = action.payload
      const questions = [...state.questions]
      const questionToRemove = questions[index]

      if (questionToRemove.type === QUESTIONS_TYPES.SECTION) {
        const remainingSections = questions.filter(
          (q) => q.type === QUESTIONS_TYPES.SECTION && q.id !== questionToRemove.id
        )

        if (remainingSections.length <= 1) {
          const updatedQuestions = questions
            .filter((q) => q.type !== QUESTIONS_TYPES.SECTION)
            .map((q) => ({ ...q, sectionId: null }))

          return { ...state, questions: updatedQuestions }
        }

        const sectionIndex = questions
          .filter((q) => q.type === QUESTIONS_TYPES.SECTION)
          .findIndex((section) => section.id === questionToRemove.id)

        const previousSection =
          sectionIndex > 0
            ? questions.filter((q) => q.type === QUESTIONS_TYPES.SECTION)[sectionIndex - 1]
            : null

        const updatedQuestions = questions
          .filter((q) => q.id !== questionToRemove.id)
          .map((q) =>
            q.sectionId === questionToRemove.id && previousSection
              ? { ...q, sectionId: previousSection.id || null }
              : q
          )

        return { ...state, questions: updatedQuestions }
      }

      const updatedQuestions = questions.filter((_, i) => i !== index)
      return { ...state, questions: updatedQuestions }
    }
    case MOVE_QUESTION_UP: {
      const { index } = action.payload

      if (index === 0) return state

      const questions = [...state.questions]
      const questionToMove = questions[index]
      const prevIndex = index - 1
      const previousItem = questions[prevIndex]

      const firstSectionIndex = questions.findIndex((q) => q.type === QUESTIONS_TYPES.SECTION)

      if (
        questionToMove.type !== QUESTIONS_TYPES.SECTION &&
        previousItem.type === QUESTIONS_TYPES.SECTION &&
        index <= firstSectionIndex + 1
      ) {
        return state
      }

      questions.splice(index, 1)
      questions.splice(prevIndex, 0, questionToMove)

      const updateSectionId = (q) => {
        if (q.type !== QUESTIONS_TYPES.SECTION) {
          const newSection = questions
            .slice(0, questions.indexOf(q))
            .reverse()
            .find((section) => section.type === QUESTIONS_TYPES.SECTION)

          q.sectionId = newSection ? newSection.id : null
        }
        return q
      }

      const updatedQuestions = questions.map(updateSectionId)

      return {
        ...state,
        questions: sortQuestionsWithSections(updatedQuestions),
      }
    }
    case MOVE_QUESTION_DOWN: {
      const { index } = action.payload

      const questions = [...state.questions]

      if (index === questions.length - 1) return state

      const questionToMove = questions[index]
      const nextIndex = index + 1

      questions.splice(index, 1)
      questions.splice(nextIndex, 0, questionToMove)

      const updateSectionId = (q) => {
        if (q.type !== QUESTIONS_TYPES.SECTION) {
          const newSection = questions
            .slice(0, questions.indexOf(q))
            .reverse()
            .find((section) => section.type === QUESTIONS_TYPES.SECTION)

          q.sectionId = newSection ? newSection.id : null
        }
        return q
      }

      const updatedQuestions = questions.map(updateSectionId)

      return {
        ...state,
        questions: sortQuestionsWithSections(updatedQuestions),
      }
    }
    // TODO review this. Typescript doesn't like when import an action type for a file that is not in the question.ts
    case createActivityActionTypes.CREATE_OTHER_SUCCESS: {
      return { ...initialState, questions: state.questions }
    }
    case createActivityActionTypes.EDIT_OTHER_SUCCESS:
    case createActivityActionTypes.SET_CREATE_PREMIUM_MODAL_OPEN:
    case createActivityActionTypes.SET_MODAL_OPEN: {
      return initialState
    }
    case getActivityTeacherActionTypes.STUDENT_ACTIVITY_SUCCESS:
    case WRITTEN_EXAM_ACTIVITY_SUCCESS:
    case getActivityStudentActionTypes.ACTIVITY_SUCCESS: {
      const {
        activity: { questions, lastSubmission, maxScore },
        // @ts-ignore
      } = action.payload

      const formattedQuestions = retrieveQuestionsFromAPI(questions)

      return {
        ...state,
        questions: formattedQuestions,
        answers: getAnswersTemplates(formattedQuestions, lastSubmission?.answers),
        maxScore: maxScore || 10,
      }
    }
    case getActivityTeacherActionTypes.LIBRARY_ACTIVITY_SUCCESS:
    case getActivityTeacherActionTypes.ACTIVITY_SUCCESS:
    case createActivityActionTypes.GET_ACTIVITY_SUCCESS: {
      const {
        activity: { questions, maxScore },
        // @ts-ignore
      } = action.payload

      return { ...state, questions: retrieveQuestionsFromAPI(questions), maxScore: maxScore || 10 }
    }
    default:
      return state
  }
}

export default root
