import type {
  ImportOrganizationActions,
  ImportOrganizationState,
} from '@/sections/eb-admin/dashboard/types/importOrganization'
import {
  PROCESS_ORGANIZATION_FAILURE,
  PROCESS_ORGANIZATION_REQUEST,
  PROCESS_ORGANIZATION_SUCCESS,
  APPEND_ORGANIZATION_FAILURE,
  APPEND_ORGANIZATION_REQUEST,
  APPEND_ORGANIZATION_SUCCESS,
  SET_ALLOW_EXISTING_USERS,
  SET_CREATE_MISSING_STUDENTS,
  SET_CREATE_MISSING_TEACHERS,
  SET_CREATE_MISSING_CLASSROOMS,
  SET_FILE,
  SET_OPEN,
  SET_APPEND_OPEN,
  SET_ORGANIZATION,
  ORGANIZATIONS_REQUEST,
  ORGANIZATIONS_SUCCESS,
  ORGANIZATIONS_FAILURE,
} from '@/sections/eb-admin/dashboard/types/importOrganization'

const initialState: ImportOrganizationState = {
  open: false,
  appendOpen: false,
  file: null,
  allowExistingUsers: false,
  createMissingStudents: false,
  createMissingTeachers: false,
  createMissingClassrooms: false,
  loading: false,
  error: '',
  organization: null,
  organizationOptions: [],
  loadingOrganizations: false,
  lastOrganizationID: 0,
}

function root(state = initialState, action: ImportOrganizationActions) {
  switch (action.type) {
    case SET_OPEN: {
      const open = action.payload.open
      return open ? { ...state, open } : initialState
    }
    case SET_APPEND_OPEN: {
      const open = action.payload.open
      return open ? { ...state, appendOpen: open } : initialState
    }
    case SET_FILE:
      return { ...state, file: action.payload.file }
    case SET_ALLOW_EXISTING_USERS:
      return { ...state, allowExistingUsers: action.payload.allowExistingUsers }
    case SET_CREATE_MISSING_STUDENTS:
      return { ...state, createMissingStudents: action.payload.createMissingStudents }
    case SET_CREATE_MISSING_TEACHERS:
      return { ...state, createMissingTeachers: action.payload.createMissingTeachers }
    case SET_CREATE_MISSING_CLASSROOMS:
      return { ...state, createMissingClassrooms: action.payload.createMissingClassrooms }

    case PROCESS_ORGANIZATION_REQUEST:
      return { ...state, loading: true, error: '' }
    case PROCESS_ORGANIZATION_SUCCESS: {
      return { ...state, loading: false, open: false }
    }
    case PROCESS_ORGANIZATION_FAILURE:
      return { ...state, loading: false, error: action.payload.error }

    case APPEND_ORGANIZATION_REQUEST:
      return { ...state, loading: true, error: '' }
    case APPEND_ORGANIZATION_SUCCESS: {
      return { ...state, loading: false, appendOpen: false }
    }
    case APPEND_ORGANIZATION_FAILURE:
      return { ...state, loading: false, error: action.payload.error }

    case SET_ORGANIZATION:
      return {
        ...state,
        organization: action.payload.organization,
      }
    case ORGANIZATIONS_REQUEST:
      return {
        ...state,
        loadingOrganizations: true,
        error: '',
        lastOrganizationID: action.payload.lastSearchID,
      }
    case ORGANIZATIONS_SUCCESS: {
      const { organizationOptions } = action.payload
      return { ...state, loadingOrganizations: false, organizationOptions }
    }
    case ORGANIZATIONS_FAILURE:
      return { ...state, loadingOrganizations: false, error: action.payload.error }

    default:
      return state
  }
}

export default root
