import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/student/summary/types/drawer/student'

const initialState: Types.StudentInfoState = {
  status: REQUEST_STATUS.IDLE,
  info: {
    avatar: '',
    dateOfBirth: '',
    email: {
      id: 0,
      typeId: 0,
      value: '',
    },
    id: 0,
    identification: {
      id: 0,
      typeId: 0,
      value: '',
    },
    lastActivityAt: null,
    lastName: '',
    name: '',
  },
}

const studentReducer = (
  state: Types.StudentInfoState = initialState,
  action: Types.StudentInfoAction
): Types.StudentInfoState => {
  switch (action.type) {
    case Types.STUDENT_INFO_TYPES.GET_STUDENT_INFO_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.STUDENT_INFO_TYPES.GET_STUDENT_INFO_SUCCESS: {
      return {
        status: REQUEST_STATUS.SUCCESS,
        info: action.payload.student,
      }
    }
    case Types.STUDENT_INFO_TYPES.GET_STUDENT_INFO_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.STUDENT_INFO_TYPES.CLEAN_STUDENT_INFO: {
      return initialState
    }
    default:
      return state
  }
}

export default studentReducer
