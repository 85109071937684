import React from 'react'
import moment from 'moment'
import { useMediaQuery, Hidden } from '@mui/material'
import { makeStyles } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import AulaLogo from '@/legacy/components/v1/svgs/aula/logoHeader'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderTop: '1px solid #CCCCCC',
    background: '#fff',
    boxShadow: 'none',
    top: 'auto',
    bottom: 0,
    height: 40,
  },
  toolbar: {
    minHeight: 40,
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'unset',
    },
  },
  tabsContainer: {
    width: 'fit-content',
    margin: 'auto',
  },
  logo: {
    width: 66,
  },
  title: {
    fontSize: 14,
    lineHeight: '18px',
    marginLeft: 5,
    [theme.breakpoints.up('sm')]: {
      flexGrow: 1,
    },
  },
  registerMark: {
    fontSize: 14,
    lineHeight: '18px',
    width: '100%',
    textAlign: 'center',
    alignSelf: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      width: 'unset',
      textAlign: 'unset',
      alignSelf: 'unset',
    },
  },
  avatar: {
    background: '#FFB800',
  },
  link: {
    fontWeight: 'bold',
  },
}))

export default function Footer({ logo, className }) {
  const classes = useStyles()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const Logo = logo ? logo : AulaLogo
  return (
    <AppBar
      position={isDesktop ? 'fixed' : 'relative'}
      color="transparent"
      className={clsx(classes.appBar, className)}
      component="footer"
    >
      <Toolbar classes={{ root: classes.toolbar }}>
        <Logo className={classes.logo} alt="logo" />
        <Typography className={classes.title}>
          | Suite de aplicaciones{' '}
          <Link
            className={classes.link}
            href="https://educabot.org/"
            target="_blank"
            rel="noopener"
          >
            Educabot
          </Link>
        </Typography>
        <Hidden xsDown>
          <Typography className={classes.registerMark}>
            ® {moment().format('YYYY')}, Educabot
          </Typography>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}
