import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/eb-admin/management/types/audienceDetailsModal'

export const initialState: Types.AudienceDetailsModalState = {
  isOpenModal: false,
  audienceToSee: {
    cosmos: [],
    createdAt: '',
    id: 0,
    name: '',
  },
  searchText: '',
  selectedContentType: {
    id: 0,
    label: 'all',
  },
  status: REQUEST_STATUS.IDLE,
  contents: [],
  pagination: {
    count: 0,
    limit: 6,
    offset: 0,
    more: false,
  },
}

const audienceDetailsModalReducer = (
  state: Types.AudienceDetailsModalState = initialState,
  action: Types.AudienceDetailsModalAction
): Types.AudienceDetailsModalState => {
  switch (action.type) {
    case Types.AUDIENCE_DETAILS_MODAL_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isOpenModal: true,
      }
    }
    case Types.AUDIENCE_DETAILS_MODAL_TYPES.CLOSE_MODAL: {
      return initialState
    }
    case Types.AUDIENCE_DETAILS_MODAL_TYPES.SET_AUDIENCE_TO_SEE: {
      const { audienceToSee } = action.payload

      return {
        ...state,
        audienceToSee,
      }
    }
    case Types.AUDIENCE_DETAILS_MODAL_TYPES.SET_PAGINATION_OFFSET: {
      const { newOffset } = action.payload

      return {
        ...state,
        pagination: {
          ...state.pagination,
          offset: newOffset,
        },
      }
    }
    case Types.AUDIENCE_DETAILS_MODAL_TYPES.GET_AUDIENCE_CONTENTS_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.AUDIENCE_DETAILS_MODAL_TYPES.GET_AUDIENCE_CONTENTS_SUCCESS: {
      const { contents, count, more } = action.payload

      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        contents,
        pagination: {
          ...state.pagination,
          count,
          more,
        },
      }
    }
    case Types.AUDIENCE_DETAILS_MODAL_TYPES.GET_AUDIENCE_CONTENTS_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.AUDIENCE_DETAILS_MODAL_TYPES.SET_SEARCH_TEXT: {
      const { newSearchText } = action.payload

      return {
        ...state,
        searchText: newSearchText,
      }
    }
    case Types.AUDIENCE_DETAILS_MODAL_TYPES.SET_SELECTED_CONTENT_TYPE: {
      const { newSelectedContentType } = action.payload

      return {
        ...state,
        selectedContentType: newSelectedContentType,
      }
    }
    default:
      return state
  }
}

export default audienceDetailsModalReducer
