import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type { ListClassroomStudentsSuccess, Student } from '@/legacy/redux/types/students'
import type { Subject } from '@/legacy/redux/types/subjects'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type { Pages } from '@/sections/content-creator/dashboard/types/dashboard'
import type { ActivityType } from '@/sections/content-creator/premium-activities/types/premium-activities'
import type { ScoreCategory } from '@/sections/report-card/config/types/stepperModalSteps/scores'
import type { CreatedReportCardPeriod } from './entities'

export interface ReportCardStudentDrawerState {
  open: boolean
  loadingEvaluations: boolean
  evaluations: StudentEvaluation[]
  subject: Subject | null
  scoreCategory: ScoreCategory | null
  evaluationsPagination: Pages
  error: string
  selectedPeriod: CreatedReportCardPeriod | null
  student: Student | null
  editMode: boolean
  scoresPeriods: StudentScorePeriods[]
  sendFinalMark: boolean
  sugesstedScore?: number | string
  sugesstedScoreId?: number
  isNextStudent: boolean
}

export type StudentEvaluation = {
  evaluationId: number
  score: number
  activityId: number
  activityType: ActivityType
  activityTitle: string
  evaluationType: 1 | 2 | 3
}

export interface StudentScorePeriods {
  score: number | string
  periodId: number
  label: string
  valueId?: number
}

// Get Student Evalutions
export const GET_STUDENT_EVALUATIONS_REQUEST =
  'REPORT_CARD/GRADING/STUDENT_DRAWER/GET_STUDENT_EVALUATIONS_REQUEST'
export const GET_STUDENT_EVALUATIONS_FAILURE =
  'REPORT_CARD/GRADING/STUDENT_DRAWER/GET_STUDENT_EVALUATIONS_FAILURE'

export type GetStudentEvaluationsRequest = GenericActionWithType<
  typeof GET_STUDENT_EVALUATIONS_REQUEST,
  { page: number; rowsPerPage: number }
>
export type GetStudentEvaluationsFailure = GenericActionWithType<
  typeof GET_STUDENT_EVALUATIONS_FAILURE,
  ErrorPayload
>

export type GetConceptualStudentsEvaluationsAction =
  | GetStudentEvaluationsRequest
  | GetStudentEvaluationsFailure
  | GetConceptualStudentEvaluationsSuccess
  | GetNumericalStudentEvaluationsSuccess

// Get Student Evalutions: Conceptual Score Success
export const GET_CONCEPTUAL_STUDENT_EVALUATIONS_SUCCESS =
  'REPORT_CARD/GRADING/STUDENT_DRAWER/GET_CONCEPTUAL_STUDENT_EVALUATIONS_SUCCESS'

export type GetConceptualStudentEvaluationsSuccess = GenericActionWithType<
  typeof GET_CONCEPTUAL_STUDENT_EVALUATIONS_SUCCESS,
  {
    evaluations: StudentEvaluation[]
    count: number
    suggestedScore: string
    suggestedScoreId: number
    scoresPeriods: StudentScorePeriods[]
    sendFinalMark: boolean
  }
>

// Get Student Evalutions: Numerical Score Success
export const GET_NUMERICAL_STUDENT_EVALUATIONS_SUCCESS =
  'REPORT_CARD/GRADING/STUDENT_DRAWER/GET_NUMERICAL_STUDENT_EVALUATIONS_SUCCESS'

export type GetNumericalStudentEvaluationsSuccess = GenericActionWithType<
  typeof GET_NUMERICAL_STUDENT_EVALUATIONS_SUCCESS,
  {
    evaluations: StudentEvaluation[]
    count: number
    suggestedScore: number
    scoresPeriods: StudentScorePeriods[]
    sendFinalMark: boolean
  }
>

export const SET_STUDENT_OPEN = 'REPORT_CARD/GRADING/STUDENT_DRAWER/SET_STUDENT_OPEN'

export type SetStudentOpen = GenericActionWithType<
  typeof SET_STUDENT_OPEN,
  {
    selectedPeriod: CreatedReportCardPeriod
    student: Student
    subject: Subject | null
    scoreCategory: ScoreCategory
    editMode: boolean
    scoresPeriods: StudentScorePeriods[]
    sendFinalMark: boolean
    sugesstedScore?: number | string
    sugesstedScoreId?: number
  }
>

export const SET_IS_NEXT_STUDENT = 'REPORT_CARD/GRADING/STUDENT_DRAWER/SET_IS_NEXT_STUDENT'

export type SetIsNextStudentAction = GenericActionWithType<
  typeof SET_IS_NEXT_STUDENT,
  {
    isNext: boolean
  }
>

export const SET_NEW_STUDENT = 'REPORT_CARD/GRADING/STUDENT_DRAWER/SET_NEW_STUDENT'

export type SetNewStudentAction = GenericActionWithType<
  typeof SET_NEW_STUDENT,
  {
    student: Student
  }
>

export type ReportCardStudentDrawerAction =
  | SetField<'editMode', boolean>
  | GetConceptualStudentsEvaluationsAction
  | SetStudentOpen
  | ClearState
  | SetIsNextStudentAction
  | SetNewStudentAction
  | ListClassroomStudentsSuccess
