import type {
  ClearParams,
  EvaluationTable,
  GetEvaluationDataSuccess,
  Pagination,
  Params,
  SetPagination,
  ClearActivityCache,
  UpdateStudentEvaluation,
  ClearStateAction,
} from '@/sections/teacher/evaluation/types/evaluation'
import {
  CLEAR_PARAMS,
  GET_EVALUATION_DATA_REQUEST,
  GET_EVALUATION_DATA_SUCCESS,
  GET_EVALUATION_DATA_FAILURE,
  SET_PAGINATION,
  GET_ACTIVITY_REQUEST,
  GET_ACTIVITY_FAILURE,
  GET_ACTIVITY_SUCCESS,
  CLEAR_ACTIVITY_CACHE,
  UPDATE_STUDENT_EVALUATION,
  CLEAR_STATE,
} from '@/sections/teacher/evaluation/types/evaluation'
import type { AppThunk } from '@/state/thunk'
import { apiPrivate } from '@/api'
import { url } from '@aula/config'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import editorActions from '@/sections/editor/actions/editor'
import activityActions from '@/sections/teacher/dashboard/actions/activity'
import studentListActions from '@/sections/teacher/studentList/actions/studentList'
import type { Activity } from '@/sections/teacher/writtenExams/types/writtenExam'
import type { Evaluation, Student } from '@/sections/teacher/studentList/types/studentList'

const actions = {
  getEvaluationData:
    (params: Params): AppThunk =>
    (dispatch) => {
      dispatch({ type: GET_EVALUATION_DATA_REQUEST })

      return apiPrivate
        .get(url + '/v3/views/teacher/evaluations-table', { params })
        .then((response) => {
          const { table, total } = response.data.description
          dispatch(actions.getEvaluationDataSuccess(table, total, params))
        })
        .catch((error) => {
          handleErrorsWithAction(error, GET_EVALUATION_DATA_FAILURE, dispatch)
        })
    },

  getEvaluationDataSuccess: (
    table: EvaluationTable,
    total: number,
    params: Params
  ): GetEvaluationDataSuccess => ({
    type: GET_EVALUATION_DATA_SUCCESS,
    payload: {
      table,
      total,
      params,
    },
  }),

  clearParams: (): ClearParams => ({ type: CLEAR_PARAMS }),

  setPagination: (pagination: Pagination): SetPagination => ({
    type: SET_PAGINATION,
    payload: { pagination },
  }),

  getActivity:
    (classroomID: number, activityID: number): AppThunk =>
    (dispatch) => {
      dispatch({ type: GET_ACTIVITY_REQUEST })

      return apiPrivate
        .get(url + `/v1/classroom/${classroomID}/activities/${activityID}`)
        .then((response) => {
          const { activity } = response.data.description
          dispatch(actions.getActivitySuccess(activity))
          dispatch(editorActions.setRawEditorState(activity))
          return activity
        })
        .catch((error) => {
          handleErrorsWithAction(error, GET_ACTIVITY_FAILURE, dispatch)
          return false
        })
    },

  getActivitySuccess: (activity: Activity) => ({
    type: GET_ACTIVITY_SUCCESS,
    payload: {
      activity,
    },
  }),

  openEvaluationSidebar:
    (
      classroomId: number,
      activityId: number,
      student: Student,
      evaluation: Evaluation,
      isRobotType: boolean
    ): AppThunk =>
    (dispatch) => {
      dispatch(studentListActions.setSelectedStudent({ ...student, evaluation, submitted: true }))
      dispatch(activityActions.getActivityWithStudentAnswers(classroomId, activityId, student.id))

      if (isRobotType) {
        dispatch(activityActions.getRobotsStudentProgress(activityId, student.id))
      }
    },

  clearActivityCache: (): ClearActivityCache => ({ type: CLEAR_ACTIVITY_CACHE }),

  updateStudentEvaluation: (
    studentId: number,
    activityId: number,
    evaluation: Evaluation
  ): UpdateStudentEvaluation => ({
    type: UPDATE_STUDENT_EVALUATION,
    payload: { studentId, activityId, evaluation },
  }),
  clearState: (): ClearStateAction => ({ type: CLEAR_STATE }),
}

export default actions
