import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import {
  CREATE_SUBJECT_AS_ADMIN_FAILURE,
  CREATE_SUBJECT_AS_ADMIN_REQUEST,
  CREATE_SUBJECT_AS_ADMIN_SUCCESS,
  CREATE_SUBJECT_AS_PRINCIPAL_FAILURE,
  CREATE_SUBJECT_AS_PRINCIPAL_REQUEST,
  CREATE_SUBJECT_AS_PRINCIPAL_SUCCESS,
  EDIT_SUBJECT_AS_ADMIN_FAILURE,
  EDIT_SUBJECT_AS_ADMIN_REQUEST,
  EDIT_SUBJECT_AS_ADMIN_SUCCESS,
} from '@/legacy/redux/types/subjects'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import { SUBJECTS_COLORS } from '@/sections/management/constants'
import type {
  AddEditSubjectModalAction,
  AddEditSubjectModalState,
} from '@/sections/management/types/subjects/addEditSubjectModal'
import {
  CLEAR_SUGGESTIONS,
  GET_SUGGESTIONS_FAILURE,
  GET_SUGGESTIONS_REQUEST,
  GET_SUGGESTIONS_SUCCESS,
  SELECT_SUGGESTION,
  SET_INITIAL_VALUES,
} from '@/sections/management/types/subjects/addEditSubjectModal'

const colorOptions = SUBJECTS_COLORS.map((color, i) => {
  return { code: color, id: i }
})

const initialState: AddEditSubjectModalState = {
  open: false,
  loading: false,
  error: '',
  subjectId: 0,
  colorOptions: colorOptions,
  color: colorOptions[0],
  colorDisabled: false,
  organization: { value: 0, label: '' },
  editMode: false,
  name: {
    value: '',
    suggestions: [],
    loading: false,
    error: '',
  },
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_ADD_EDIT_SUBJECT_MODAL

const AdminOrganizationsModalReducer: Reducer<
  AddEditSubjectModalState,
  AddEditSubjectModalAction
> = (state = initialState, action): AddEditSubjectModalState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case GET_SUGGESTIONS_REQUEST:
      return {
        ...state,
        name: { ...state.name, loading: true },
      }
    case GET_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        name: {
          ...state.name,
          loading: false,
          suggestions: action.payload.suggestions,
        },
      }
    case GET_SUGGESTIONS_FAILURE:
      return {
        ...state,
        name: {
          ...state.name,
          loading: false,
          error: action.payload.error,
        },
      }
    case SELECT_SUGGESTION:
      return {
        ...state,
        color: { code: action.payload.suggestion.color || '', id: 0 },
        colorDisabled: true,
        name: {
          ...state.name,
          value: action.payload.suggestion.label,
        },
      }
    case CLEAR_SUGGESTIONS:
      return {
        ...state,
        name: {
          ...state.name,
          suggestions: [],
        },
      }
    case SET_INITIAL_VALUES: {
      const { color, name, id } = action.payload.subject
      return {
        ...state,
        editMode: true,
        subjectId: id,
        color: { code: color, id: 1 },
        organization: action.payload.organization,
        name: { ...state.name, value: name },
      }
    }
    case CREATE_SUBJECT_AS_ADMIN_REQUEST:
    case EDIT_SUBJECT_AS_ADMIN_REQUEST:
    case CREATE_SUBJECT_AS_PRINCIPAL_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case CREATE_SUBJECT_AS_ADMIN_SUCCESS:
    case EDIT_SUBJECT_AS_ADMIN_SUCCESS:
    case CREATE_SUBJECT_AS_PRINCIPAL_SUCCESS: {
      return {
        ...initialState,
        open: false,
      }
    }
    case CREATE_SUBJECT_AS_ADMIN_FAILURE:
    case EDIT_SUBJECT_AS_ADMIN_FAILURE:
    case CREATE_SUBJECT_AS_PRINCIPAL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    }

    default:
      return state
  }
}

export default checkRequestOrigin(initialState, AdminOrganizationsModalReducer, requestDestination)
