import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'

export type ExportOptions = {
  send: boolean
  export: boolean
}

export type ReportCardSubitems = {
  enableScores: boolean
  enableAttitudinal: boolean
  enableCompetences: boolean
  enableReviews: boolean
  enableAttendances: boolean
}

export interface SendReportCardState {
  loadingExport: boolean
  loadingSend: boolean
  error: string
  open: boolean
  exportOptions: ExportOptions
  subitems: ReportCardSubitems
}

export enum SEND_REPORT_CARD_TYPE {
  SEND_REQUEST = 'REPORT_CARDS/SEND/SEND_REQUEST',
  SEND_SUCCESS = 'REPORT_CARDS/SEND/SEND_SUCCESS',
  SEND_FAILURE = 'REPORT_CARDS/SEND/SEND_FAILURE',
  EXPORT_REQUEST = 'REPORT_CARDS/SEND/EXPORT_REQUEST',
  EXPORT_SUCCESS = 'REPORT_CARDS/SEND/EXPORT_SUCCESS',
  EXPORT_FAILURE = 'REPORT_CARDS/SEND/EXPORT_FAILURE',
  SET_EXPORT_OPTIONS = 'REPORT_CARDS/SEND/SET_EXPORT_OPTIONS',
  SET_SUBITEMS = 'REPORT_CARDS/SEND/SET_SUBITEMS',
}

export type SendReportCardsRequest = GenericActionWithType<
  typeof SEND_REPORT_CARD_TYPE.SEND_REQUEST
>

export type SendReportCardsSuccess = GenericActionWithType<
  typeof SEND_REPORT_CARD_TYPE.SEND_SUCCESS
>

export type SendReportCardsFailure = GenericActionWithType<
  typeof SEND_REPORT_CARD_TYPE.SEND_FAILURE,
  ErrorPayload
>
export type ExportReportCardsRequest = GenericActionWithType<
  typeof SEND_REPORT_CARD_TYPE.EXPORT_REQUEST
>

export type ExportReportCardsSuccess = GenericActionWithType<
  typeof SEND_REPORT_CARD_TYPE.EXPORT_SUCCESS
>

export type ExportReportCardsFailure = GenericActionWithType<
  typeof SEND_REPORT_CARD_TYPE.EXPORT_FAILURE,
  ErrorPayload
>

export type SetExportOptions = GenericActionWithType<
  typeof SEND_REPORT_CARD_TYPE.SET_EXPORT_OPTIONS,
  { exportOptions: ExportOptions }
>
export type SetSubitems = GenericActionWithType<
  typeof SEND_REPORT_CARD_TYPE.SET_SUBITEMS,
  { subitems: ReportCardSubitems }
>

export type SendReportCardsAction =
  | ClearState
  | SetField<'open', boolean>
  | SendReportCardsRequest
  | SendReportCardsSuccess
  | SendReportCardsFailure
  | ExportReportCardsRequest
  | ExportReportCardsSuccess
  | ExportReportCardsFailure
  | SetExportOptions
  | SetSubitems
