import type { LocaleSpecification } from 'moment'

export const de: LocaleSpecification = {
  months: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
  weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
  weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  weekdaysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D. MMMM YYYY',
    LLL: 'D. MMMM YYYY LT',
    LLLL: 'dddd, D. MMMM YYYY LT',
  },
  calendar: {
    sameDay: function () {
      return '[Heute um] LT'
    },
    nextDay: function () {
      return '[Morgen um] LT'
    },
    nextWeek: function () {
      return 'dddd [um] LT'
    },
    lastDay: function () {
      return '[Gestern um] LT'
    },
    lastWeek: function () {
      return 'dddd [um] LT'
    },
    sameElse: 'L',
  },
  relativeTime: {
    future: 'in %s',
    past: 'vor %s',
    s: 'ein paar Sekunden',
    m: 'einer Minute',
    mm: '%d Minuten',
    h: 'einer Stunde',
    hh: '%d Stunden',
    d: 'einem Tag',
    dd: '%d Tagen',
    M: 'einem Monat',
    MM: '%d Monaten',
    y: 'einem Jahr',
    yy: '%d Jahren',
  },
  ordinalParse: /\d{1,2}\./,
  ordinal: function (val: number) {
    return val + '.'
  },
  week: {
    dow: 1,
    doy: 4,
  },
}
