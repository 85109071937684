import type {
  BookLicensesActions,
  BookLicensesState,
} from '@/sections/eb-admin/dashboard/types/bookLicenses'
import {
  CLEAR_PARAMS,
  GET_BOOK_LICENSES_FAILURE,
  GET_BOOK_LICENSES_REQUEST,
  GET_BOOK_LICENSES_SUCCESS,
  ORGANIZATION_FAILURE,
  ORGANIZATION_REQUEST,
  ORGANIZATION_SUCCESS,
  SET_AMOUNT,
  SET_RESOURCE_ID,
  SET_EXPIRY,
  SET_FILTER,
  SET_OPEN,
  SET_ORGANIZATION,
  SET_PAGINATION,
  SET_SORTER,
  CREATE_BOOK_LICENSE_REQUEST,
  CREATE_BOOK_LICENSE_SUCCESS,
  CREATE_BOOK_LICENSE_FAILURE,
  MODIFY_BOOK_LICENSE_REQUEST,
  MODIFY_BOOK_LICENSE_SUCCESS,
  MODIFY_BOOK_LICENSE_FAILURE,
} from '@/sections/eb-admin/dashboard/types/bookLicenses'
import { LIMIT, BOOK_LICENSE_DEFAULT_STATE } from '@/sections/eb-admin/dashboard/constants'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import * as booksTypes from '@/sections/content-creator/books/types/books'
import moment from 'moment'

// TODO that can be enhanced, remove unused states
const initialState: BookLicensesState = {
  organizationOptions: [],
  open: false,
  lastSearchID: 0,
  bookLicenses: [],
  error: '',
  loading: false,
  books: [],
  loadingLicenses: false,
  loadingOrganizations: false,
  loadingBooks: false,
  params: {
    limit: LIMIT,
    offset: 0,
  },
  total: 0,
  sorter: {
    // order: 'descend',
    // field: 'date',
    // columnKey: 'date',
  },
  filter: {
    paymentMethod: [],
    statusId: [],
  },
  searchFilters: [
    // {key: 'id', text: ''},
  ],
  pagination: {
    current: 0,
    total: 0,
  },
  bookLicense: BOOK_LICENSE_DEFAULT_STATE,
  loadingBookLicense: false,
  deleteModalOpen: false,
}

const requestDestination = reduxRequestOriginMap.EB_ADMIN_LICENSE_STOCK

function root(state = initialState, action: BookLicensesActions) {
  switch (action.type) {
    case GET_BOOK_LICENSES_REQUEST:
      return { ...state, loading: true, error: '' }
    case GET_BOOK_LICENSES_SUCCESS: {
      const { bookLicenses, total, params } = action.payload
      return { ...state, loading: false, bookLicenses, total, params }
    }
    case GET_BOOK_LICENSES_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case CLEAR_PARAMS: {
      return { ...state, params: initialState.params, pagination: initialState.pagination }
    }
    case SET_PAGINATION:
      return { ...state, pagination: action.payload.pagination }
    case SET_SORTER:
      return { ...state, sorter: action.payload.sorter }
    case SET_FILTER:
      return { ...state, filter: action.payload.filter }
    case SET_OPEN: {
      const { open, bookLicense } = action.payload
      return {
        ...state,
        open,
        bookLicense: { ...bookLicense, expiry: moment(bookLicense.expiry) },
        error: '',
      }
    }
    case ORGANIZATION_REQUEST:
      return {
        ...state,
        loadingOrganizations: true,
        error: '',
        lastSearchID: action.payload.lastSearchID,
      }
    case ORGANIZATION_SUCCESS: {
      const { organizationOptions } = action.payload
      return { ...state, loadingOrganizations: false, organizationOptions }
    }
    case ORGANIZATION_FAILURE:
      return { ...state, loadingOrganizations: false, error: action.payload.error }
    case MODIFY_BOOK_LICENSE_REQUEST:
    case CREATE_BOOK_LICENSE_REQUEST: {
      return { ...state, loadingBookLicense: true, error: '' }
    }
    case MODIFY_BOOK_LICENSE_SUCCESS:
    case CREATE_BOOK_LICENSE_SUCCESS: {
      return { ...state, loadingBookLicense: false, open: false }
    }
    case MODIFY_BOOK_LICENSE_FAILURE:
    case CREATE_BOOK_LICENSE_FAILURE:
      return { ...state, loadingBookLicense: false, error: action.payload.error }
    case SET_EXPIRY:
      return { ...state, bookLicense: { ...state.bookLicense, expiry: action.payload.expiry } }
    case SET_RESOURCE_ID:
      return {
        ...state,
        bookLicense: { ...state.bookLicense, resourceId: action.payload.resourceID },
      }
    case SET_AMOUNT:
      return { ...state, bookLicense: { ...state.bookLicense, amount: action.payload.amount } }
    case SET_ORGANIZATION:
      return {
        ...state,
        bookLicense: { ...state.bookLicense, organizationId: action.payload.organization.value },
      }
    case booksTypes.GET_ORGANIZATION_BOOKS_REQUEST: {
      if (action.payload.requestOrigin === requestDestination) {
        return { ...state, loadingBooks: true }
      } else {
        return state
      }
    }
    case booksTypes.GET_ORGANIZATION_BOOKS_SUCCESS: {
      if (action.payload.requestOrigin === requestDestination) {
        return { ...state, loadingBooks: false, error: '', books: action.payload.books }
      } else {
        return state
      }
    }
    case booksTypes.GET_ORGANIZATION_BOOKS_FAILURE: {
      if (action.payload.requestOrigin === requestDestination) {
        return { ...state, loadingBooks: false, error: '' }
      } else {
        return state
      }
    }
    default:
      return state
  }
}

export default root
