import type { Classroom, Subject } from '@/sections/teacher/studentList/types/studentList'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { Moment } from 'moment'
import type { Subgroup } from './libraryBooks'
import type { Sequence } from '@/api/lms/library'

export const SET_ACTIVITY_IDS = 'TEACHER/SEQUENCES/SET_ACTIVITY_IDS'
export const SET_SELECTED_UNITS = 'TEACHER/SEQUENCES/SET_SELECTED_UNITS'
export const SET_CLASSROOM = 'TEACHER/SEQUENCES/SET_CLASSROOM'
export const SET_CLASSROOM_SEARCH = 'TEACHER/SEQUENCES/SET_CLASSROOM_SEARCH'
export const SET_SUBJECT = 'TEACHER/SEQUENCES/SET_SUBJECT'
export const SET_DATE = 'TEACHER/SEQUENCES/SET_DATE'
export const SET_END_DATE = 'TEACHER/SEQUENCES/SET_END_DATE'
export const SET_START_DATE = 'TEACHER/SEQUENCES/SET_START_DATE'
export const SET_DEADLINE = 'TEACHER/SEQUENCES/SET_DEADLINE'
export const SET_PUBLICATION_DATE = 'TEACHER/SEQUENCES/SET_PUBLICATION_DATE'
export const SET_CHAT_ENABLED = 'TEACHER/SEQUENCES/SET_CHAT_ENABLED'
export const TOGGLE_SEQUENCE_MODAL_OPEN = 'TEACHER/SEQUENCES/TOGGLE_SEQUENCE_MODAL_OPEN'
export const TOGGLE_ASSIGN_MODAL_OPEN = 'TEACHER/SEQUENCES/TOGGLE_ASSIGN_MODAL_OPEN'
export const SET_DURATION = 'TEACHER/SEQUENCES/SET_DURATION'
export const SET_DISTRIBUTE = 'TEACHER/SEQUENCES/SET_DISTRIBUTE'

export const ASSIGN_SEQUENCE_ACTIVITIES_REQUEST =
  'TEACHER/SEQUENCES/ASSIGN_SEQUENCE_ACTIVITIES_REQUEST'
export const ASSIGN_SEQUENCE_ACTIVITIES_SUCCESS =
  'TEACHER/SEQUENCES/ASSIGN_SEQUENCE_ACTIVITIES_SUCCESS'
export const ASSIGN_SEQUENCE_ACTIVITIES_FAILURE =
  'TEACHER/SEQUENCES/ASSIGN_SEQUENCE_ACTIVITIES_FAILURE'

export const GET_CLASSROOMS_REQUEST = 'TEACHER/SEQUENCES/GET_CLASSROOMS_REQUEST'
export const GET_CLASSROOMS_SUCCESS = 'TEACHER/SEQUENCES/GET_CLASSROOMS_SUCCESS'
export const GET_CLASSROOMS_FAILURE = 'TEACHER/SEQUENCES/GET_CLASSROOMS_FAILURE'

export const GET_SUBJECTS_REQUEST = 'TEACHER/SEQUENCES/GET_SUBJECTS_REQUEST'
export const GET_SUBJECTS_SUCCESS = 'TEACHER/SEQUENCES/GET_SUBJECTS_SUCCESS'
export const GET_SUBJECTS_FAILURE = 'TEACHER/SEQUENCES/GET_SUBJECTS_FAILURE'

export const GET_CLASSROOM_SUBGROUPS_REQUEST = 'TEACHER/SEQUENCES/GET_CLASSROOM_SUBGROUPS_REQUEST'
export const GET_CLASSROOM_SUBGROUPS_SUCCESS = 'TEACHER/SEQUENCES/GET_CLASSROOM_SUBGROUPS_SUCCESS'
export const GET_CLASSROOM_SUBGROUPS_FAILURE = 'TEACHER/SEQUENCES/GET_CLASSROOM_SUBGROUPS_FAILURE'

export const SELECT_SUBGROUPS = 'LIBRARY_TEACHER/SELECT_SUBGROUPS'

export const GET_SEQUENCE_REQUEST = 'LIBRARY_TEACHER/SEQUENCES/GET_SEQUENCE_REQUEST'
export const GET_SEQUENCE_SUCCESS = 'LIBRARY_TEACHER/SEQUENCES/GET_SEQUENCE_SUCCESS'
export const GET_SEQUENCE_FAILURE = 'LIBRARY_TEACHER/SEQUENCES/GET_SEQUENCE_FAILURE'

export interface ToggleSequenceModalOpen {
  type: typeof TOGGLE_SEQUENCE_MODAL_OPEN
  payload: {
    requestOrigin: ReduxRequestOrigin
    open: boolean
  }
}
export interface ToggleAssignModalOpen {
  type: typeof TOGGLE_ASSIGN_MODAL_OPEN
  payload: {
    requestOrigin: ReduxRequestOrigin
    open: boolean
    step?: number
  }
}
export interface SetActivityIds {
  type: typeof SET_ACTIVITY_IDS
  payload: {
    requestOrigin: ReduxRequestOrigin
    activityIds: ActivityConfiguration[]
  }
}

export interface SetSelectedUnitsAction {
  type: typeof SET_SELECTED_UNITS
  payload: {
    requestOrigin: ReduxRequestOrigin
    newUnits: number[]
  }
}

export interface SetClassroom {
  type: typeof SET_CLASSROOM
  payload: {
    requestOrigin: ReduxRequestOrigin
    classroom: Classroom
  }
}
export interface SetSubject {
  type: typeof SET_SUBJECT
  payload: {
    requestOrigin: ReduxRequestOrigin
    subjectId: number
  }
}
export interface SetDate {
  type: typeof SET_DATE
  payload: {
    requestOrigin: ReduxRequestOrigin
    activityId: number
    date: Moment
  }
}

export interface SetStartDate {
  type: typeof SET_START_DATE
  payload: {
    requestOrigin: ReduxRequestOrigin
    activityId: number
    startDate: Moment
  }
}
export interface SetDeadline {
  type: typeof SET_DEADLINE
  payload: {
    requestOrigin: ReduxRequestOrigin
    activityId: number
    deadline: Moment
  }
}

export interface SetPublicationDate {
  type: typeof SET_PUBLICATION_DATE
  payload: {
    requestOrigin: ReduxRequestOrigin
    activityId: number
    publicationDate: Moment
  }
}

export interface SetChatEnabled {
  type: typeof SET_CHAT_ENABLED
  payload: {
    requestOrigin: ReduxRequestOrigin
    activityId: number
    chatEnabled: boolean
  }
}

export interface SetDuration {
  type: typeof SET_DURATION
  payload: {
    requestOrigin: ReduxRequestOrigin
    activityId: number
    duration: number
  }
}

export interface SetDistribute {
  type: typeof SET_DISTRIBUTE
  payload: {
    requestOrigin: ReduxRequestOrigin
    activityId: number
    distribute: boolean
  }
}

export interface AssignSequenceActivitiesRequest {
  type: typeof ASSIGN_SEQUENCE_ACTIVITIES_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}
export interface AssignSequenceActivitiesSuccess {
  type: typeof ASSIGN_SEQUENCE_ACTIVITIES_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}
export interface AssignSequenceActivitiesFailure {
  type: typeof ASSIGN_SEQUENCE_ACTIVITIES_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface GetClassroomsRequest {
  type: typeof GET_CLASSROOMS_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
    optionsLoading: boolean
    lastSearchID: number
  }
}

export interface GetClassroomsSuccess {
  type: typeof GET_CLASSROOMS_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    classrooms: Classroom[]
    optionsLoading: boolean
  }
}

export interface GetClassroomsFailure {
  type: typeof GET_CLASSROOMS_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface GetClassroomSubgroupsRequest {
  type: typeof GET_CLASSROOM_SUBGROUPS_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetClassroomSubgroupsSuccess {
  type: typeof GET_CLASSROOM_SUBGROUPS_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    subgroups: Subgroup[]
  }
}

export interface GetClassroomSubgroupsFailure {
  type: typeof GET_CLASSROOM_SUBGROUPS_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface GetSubjectsRequest {
  type: typeof GET_SUBJECTS_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetSubjectsSuccess {
  type: typeof GET_SUBJECTS_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    subjects: Subject[]
  }
}

export interface GetSubjectsFailure {
  type: typeof GET_SUBJECTS_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface SelectSubgroups {
  type: typeof SELECT_SUBGROUPS
  payload: {
    requestOrigin: ReduxRequestOrigin
    selectedSubgroups: Subgroup[]
  }
}

export interface SetClassroomSearch {
  type: typeof SET_CLASSROOM_SEARCH
  payload: {
    requestOrigin: ReduxRequestOrigin
    classroomSearch: string
  }
}

export interface GetSequenceRequest {
  type: typeof GET_SEQUENCE_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetSequenceSuccess {
  type: typeof GET_SEQUENCE_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    sequence: Sequence
  }
}

export interface GetSequenceFailure {
  type: typeof GET_SEQUENCE_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface ActivityPayloadConfiguration {
  id: number
  date: string | null
  deadline: string | null
  start: string | null
  end: string | null
  publicationDate: string | null
  duration: number
  distribute: boolean
  enableChat: boolean
}

export interface ActivityConfiguration {
  id: number
  date: Moment | null
  deadline: Moment | null
  start: Moment | null
  end: Moment | null
  publicationDate: Moment | null
  duration: number
  distribute: boolean
  enableChat: boolean
}

export interface SequenceAssignmentModalState {
  open: boolean
  modalOpen: boolean
  modalStep: number
  activityIds: ActivityConfiguration[]
  selectedUnits: number[]
  date: Moment
  deadline: Moment
  startDate: Moment
  publicationDate: Moment
  chatEnabled: boolean
  subject: Subject | null
  selectedClassroom: Classroom | null
  classrooms: Classroom[]
  subgroups: Subgroup[]
  selectedSubgroups: Subgroup[]
  subjects: Subject[]
  loadingClassroomOptions: boolean
  loadingClassrooms: boolean
  loadingSubgroups: boolean
  loadingSubjects: boolean
  loadingAssignment: boolean
  loadingSequence: boolean
  error: string
  lastClassroomID: number
  classroomSearch: string
  sequence: Sequence
}

export type SequenceAssignmentModalAction =
  | ToggleSequenceModalOpen
  | ToggleAssignModalOpen
  | SetActivityIds
  | SetSelectedUnitsAction
  | SetDuration
  | SetDistribute
  | SetClassroom
  | SetClassroomSearch
  | SetSubject
  | SetDate
  | SetStartDate
  | SetPublicationDate
  | SetDeadline
  | SetChatEnabled
  | AssignSequenceActivitiesRequest
  | AssignSequenceActivitiesSuccess
  | AssignSequenceActivitiesFailure
  | GetClassroomsRequest
  | GetClassroomsSuccess
  | GetClassroomsFailure
  | GetClassroomSubgroupsRequest
  | GetClassroomSubgroupsSuccess
  | GetClassroomSubgroupsFailure
  | SelectSubgroups
  | GetSubjectsRequest
  | GetSubjectsSuccess
  | GetSubjectsFailure
  | GetSequenceRequest
  | GetSequenceSuccess
  | GetSequenceFailure
