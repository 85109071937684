import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'

export interface ReviewsGradingFormState {
  loading: boolean
  error: string
  currentValue: string
}

export const INIT_STUDENT_REVIEW = 'REPORT_CARD/GRADING/FORMS/REVIEWS/INIT_STUDENT_REVIEW'

export type InitStudentReview = GenericActionWithType<
  typeof INIT_STUDENT_REVIEW,
  { currentValue?: string }
>

export const SAVE_REVIEW_REQUEST = 'REPORT_CARD/GRADING/FORMS/REVIEWS/SAVE_REVIEW_REQUEST'
export const SAVE_REVIEW_SUCCESS = 'REPORT_CARD/GRADING/FORMS/REVIEWS/SAVE_REVIEW_SUCCESS'
export const SAVE_REVIEW_FAILURE = 'REPORT_CARD/GRADING/FORMS/REVIEWS/SAVE_REVIEW_FAILURE'

export type SaveReviewRequest = GenericActionWithType<typeof SAVE_REVIEW_REQUEST>
export type SaveReviewSuccess = GenericActionWithType<typeof SAVE_REVIEW_SUCCESS, { value: string }>
export type SaveReviewFailure = GenericActionWithType<typeof SAVE_REVIEW_FAILURE, ErrorPayload>

export type SaveReviewAction = SaveReviewRequest | SaveReviewSuccess | SaveReviewFailure

export const UPDATE_REVIEW_REQUEST = 'REPORT_CARD/GRADING/FORMS/REVIEWS/UPDATE_REVIEW_REQUEST'
export const UPDATE_REVIEW_SUCCESS = 'REPORT_CARD/GRADING/FORMS/REVIEWS/UPDATE_REVIEW_SUCCESS'
export const UPDATE_REVIEW_FAILURE = 'REPORT_CARD/GRADING/FORMS/REVIEWS/UPDATE_REVIEW_FAILURE'

export type UpdateReviewRequest = GenericActionWithType<typeof UPDATE_REVIEW_REQUEST>
export type UpdateReviewSuccess = GenericActionWithType<
  typeof UPDATE_REVIEW_SUCCESS,
  { value: string }
>
export type UpdateReviewFailure = GenericActionWithType<typeof UPDATE_REVIEW_FAILURE, ErrorPayload>

export type UpdateReviewAction = UpdateReviewRequest | UpdateReviewSuccess | UpdateReviewFailure

export type ReviewsGradingFormAction =
  | InitStudentReview
  | ClearState
  | SetField<'currentValue', string>
  | SaveReviewAction
  | UpdateReviewAction
