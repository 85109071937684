import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { Evaluation } from '@/sections/teacher/studentList/types/studentList'
import type { Activity } from '@/sections/teacher/writtenExams/types/writtenExam'

export const GET_EVALUATION_DATA_REQUEST = 'EVALUATION_TEACHER/GET_EVALUATION_DATA_REQUEST'
export const GET_EVALUATION_DATA_SUCCESS = 'EVALUATION_TEACHER/GET_EVALUATION_DATA_SUCCESS'
export const GET_EVALUATION_DATA_FAILURE = 'EVALUATION_TEACHER/GET_EVALUATION_DATA_FAILURE'
export const SET_PAGINATION = 'EVALUATION_TEACHER/SET_PAGINATION'
export const CLEAR_PARAMS = 'EVALUATION_TEACHER/CLEAR_PARAMS'
export const GET_ACTIVITY_REQUEST = 'EVALUATION_TEACHER/GET_ACTIVITY_REQUEST'
export const GET_ACTIVITY_SUCCESS = 'EVALUATION_TEACHER/GET_ACTIVITY_SUCCESS'
export const GET_ACTIVITY_FAILURE = 'EVALUATION_TEACHER/GET_ACTIVITY_FAILURE'
export const SET_POPUP_OPEN = 'EVALUATION_TEACHER/SET_POPUP_OPEN'
export const SET_STUDENT_GRADE = 'EVALUATION_TEACHER/SET_STUDENT_GRADE'
export const CLEAR_ACTIVITY_CACHE = 'EVALUATION_TEACHER/CLEAR_ACTIVITY_CACHE'
export const UPDATE_STUDENT_EVALUATION = 'EVALUATION_TEACHER/UPDATE_STUDENT_EVALUATION'
export const CLEAR_STATE = 'EVALUATION_TEACHER/CLEAR_STATE'

export interface ActivitySubmission {
  date: string
  final: boolean
  id: number
  progress: number
  type: string
}

export interface HeaderActivity {
  id: number
  avgEvaluationValue: number
  date: string
  deadline: string
  evaluationType: number
  title: string
  type: string
}

export interface RowActivity {
  activityId: number
  deadline: string
  evaluation: Evaluation | null
  evaluationType: number
  studentId: number
  submission: ActivitySubmission | null
}

export interface EvaluationRow {
  activities: RowActivity[]
  student: {
    id: number
    name: string
    lastName: string
    robotsId: number
  }
}

export interface EvaluationTable {
  head: {
    activities: HeaderActivity[]
  }
  rows: EvaluationRow[]
}

export interface Pagination {
  current: number
  total: number
}

export interface Params {
  limit: number
  offset: number
}

export interface EvaluationState {
  table: EvaluationTable
  total: number
  loading: boolean
  error: string
  params: Params
  pagination: Pagination
  selectedActivity: Activity | null
  activityCache: {
    [id: number]: Activity
  }
  evaluationStatus: REQUEST_STATUS
}

export interface GetEvaluationDataRequest {
  type: typeof GET_EVALUATION_DATA_REQUEST
}

export interface GetEvaluationDataSuccess {
  type: typeof GET_EVALUATION_DATA_SUCCESS
  payload: {
    table: EvaluationTable
    total: number
    params: Params
  }
}

export interface GetEvaluationDataFailure {
  type: typeof GET_EVALUATION_DATA_FAILURE
  payload: {
    error: string
  }
}

export interface SetPagination {
  type: typeof SET_PAGINATION
  payload: {
    pagination: Pagination
  }
}

export interface ClearParams {
  type: typeof CLEAR_PARAMS
}

export interface GetActivityRequest {
  type: typeof GET_ACTIVITY_REQUEST
}

export interface GetActivitySuccess {
  type: typeof GET_ACTIVITY_SUCCESS
  payload: {
    activity: Activity
  }
}

export interface GetActivityFailure {
  type: typeof GET_ACTIVITY_FAILURE
  payload: {
    error: string
  }
}

export interface SetPopupOpen {
  type: typeof SET_POPUP_OPEN
  payload: {
    open: boolean
  }
}

export interface ClearActivityCache {
  type: typeof CLEAR_ACTIVITY_CACHE
}

export interface UpdateStudentEvaluation {
  type: typeof UPDATE_STUDENT_EVALUATION
  payload: {
    studentId: number
    activityId: number
    evaluation: Evaluation
  }
}

export interface ClearStateAction {
  type: typeof CLEAR_STATE
}

export type EvaluationActions =
  | GetEvaluationDataRequest
  | GetEvaluationDataSuccess
  | GetEvaluationDataFailure
  | ClearParams
  | SetPagination
  | GetActivityRequest
  | GetActivitySuccess
  | GetActivityFailure
  | SetPopupOpen
  | ClearActivityCache
  | UpdateStudentEvaluation
  | ClearStateAction
