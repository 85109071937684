import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import type {
  VideosState,
  VideosActions,
  VideoCustom,
  VideoFilters,
} from '@/sections/ted/types/videos'
import {
  VIDEO_STATE,
  GET_VIDEOS_REQUEST,
  GET_VIDEOS_SUCCESS,
  GET_VIDEOS_FAILURE,
  DELETE_VIDEO_REQUEST,
  DELETE_VIDEO_SUCCESS,
  DELETE_VIDEO_FAILURE,
  CREATE_VIDEO_REQUEST,
  CREATE_VIDEO_RETRY_REQUEST,
  CREATE_VIDEO_SUCCESS,
  CREATE_VIDEO_FAILURE,
  UPDATE_VIDEO_REQUEST,
  UPDATE_VIDEO_SUCCESS,
  UPDATE_VIDEO_FAILURE,
  SET_VIDEO_AUTH_FIELD,
  TOGGLE_VIDEO_DETAILS,
  APPROVE_VIDEO_REQUEST,
  APPROVE_VIDEO_FAILURE,
  APPROVE_VIDEO_SUCCESS,
  CLEAR_VIDEOS,
  SET_VIDEO_FILTER,
  REJECT_VIDEO_REQUEST,
  REJECT_VIDEO_FAILURE,
  REJECT_VIDEO_SUCCESS,
} from '@/sections/ted/types/videos'

const initialState: VideosState = {
  videos: [],
  pagination: {
    page: 0,
    limit: 4,
    more: false,
  },
  filters: {
    completed: true,
    search: '',
  },
  loadingVideos: false,
  loadingRejectVideo: false,
  loadingApproveVideo: false,
  error: null,
}

const requestDestination = reduxRequestOriginMap.TED_VIDEOS

const TedVideosReducer: Reducer<VideosState, VideosActions> = (
  state = initialState,
  action
): VideosState => {
  switch (action.type) {
    case CLEAR_VIDEOS: {
      return initialState
    }
    case REJECT_VIDEO_REQUEST: {
      return {
        ...state,
        loadingRejectVideo: true,
      }
    }
    case REJECT_VIDEO_SUCCESS: {
      return {
        ...state,
        loadingRejectVideo: false,
      }
    }
    case REJECT_VIDEO_FAILURE: {
      return {
        ...state,
        loadingRejectVideo: false,
      }
    }
    case APPROVE_VIDEO_REQUEST: {
      return {
        ...state,
        loadingApproveVideo: true,
      }
    }
    case APPROVE_VIDEO_SUCCESS: {
      const { id } = action.payload
      const videos = state.videos.map((video) => {
        if (id === video.id) {
          return {
            ...video,
            ...action.payload.video,
          }
        }

        return video
      })

      return {
        ...state,
        videos: videos as VideoCustom[],
        loadingApproveVideo: false,
      }
    }
    case APPROVE_VIDEO_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        loadingApproveVideo: false,
        error,
      }
    }
    case TOGGLE_VIDEO_DETAILS: {
      const { id } = action.payload
      const videos = state.videos.map((video) => {
        if (id === video.id) {
          return {
            ...video,
            toggleVideoDetails: !video.toggleVideoDetails,
          }
        }

        return video
      })

      return {
        ...state,
        videos,
      }
    }
    case SET_VIDEO_FILTER: {
      const { filter, value } = action.payload
      const filters = {
        ...state.filters,
        [filter]: value,
      }

      return {
        ...state,
        filters: filters as VideoFilters,
      }
    }
    case SET_VIDEO_AUTH_FIELD: {
      const { id, field, value } = action.payload
      const videos = state.videos.map((video) => {
        if (id === video.id) {
          return {
            ...video,
            [field]: value,
          }
        }

        return video
      })

      return {
        ...state,
        videos,
      }
    }
    case UPDATE_VIDEO_REQUEST: {
      const { id } = action.payload
      const videos = state.videos.map((video) => {
        if (id === video.id) {
          return {
            ...video,
            updating: true,
          }
        }

        return video
      })

      return {
        ...state,
        videos,
      }
    }
    case UPDATE_VIDEO_SUCCESS: {
      const { id } = action.payload
      const videos = state.videos.map((video) => {
        if (id === video.id) {
          return {
            ...video,
            updating: false,
            toggleVideoDetails: false,
          }
        }

        return video
      })

      return {
        ...state,
        videos,
      }
    }
    case UPDATE_VIDEO_FAILURE: {
      const { id } = action.payload
      const videos = state.videos.map((video) => {
        if (id === video.id) {
          return {
            ...video,
            updating: false,
          }
        }

        return video
      })

      return {
        ...state,
        videos,
      }
    }
    case CREATE_VIDEO_RETRY_REQUEST: {
      const { id, file, cancelToken } = action.payload
      const videos = state.videos.map((video) => {
        if (id === video.id) {
          return {
            ...video,
            id,
            file,
            title: file.name,
            state: VIDEO_STATE.PREVIEW,
            status: REQUEST_STATUS.LOADING,
            cancelToken,
          }
        }

        return video
      })

      return {
        ...state,
        videos: videos as VideoCustom[],
      }
    }
    case CREATE_VIDEO_REQUEST: {
      const { id, file, cancelToken } = action.payload
      const videos: VideoCustom[] = [
        {
          name: '',
          feedback: '',
          groupId: 0,
          groupName: '',
          videoId: 0,
          createdAt: '',
          description: '',
          updatedAt: '',
          institutionId: 0,
          institutionName: '',
          hub: '',
          speakers: [],
          tags: [],
          channelId: 0,
          publicUrl: '',
          videoName: '',
          videoStatus: 'pending',
          privacyStatus: 'unlisted',
          toggleVideoDetails: false,
          id,
          file,
          title: file.name,
          state: VIDEO_STATE.PREVIEW,
          status: REQUEST_STATUS.LOADING,
          deleted: false,
          uploading: true,
          deleting: false,
          updating: false,
          cancelToken,
          authorization: {
            id: 0,
            videoId: 0,
            fileName: '',
            extension: '',
          },
          facilitator: {
            id: 0,
            lastName: '',
            name: '',
            userId: 0,
          },
        },
        ...state.videos,
      ]

      return {
        ...state,
        videos: videos as VideoCustom[],
      }
    }
    case CREATE_VIDEO_SUCCESS: {
      const { id } = action.payload
      const videos = state.videos.map((video) => {
        if (id === video.id) {
          return {
            ...video,
            ...action.payload.video,
            state: VIDEO_STATE.UPLOADED,
            status: REQUEST_STATUS.SUCCESS,
            uploading: false,
          }
        }

        return video
      })

      return {
        ...state,
        videos: videos as VideoCustom[],
      }
    }
    case CREATE_VIDEO_FAILURE: {
      const { id } = action.payload
      const videos = state.videos.map((video) => {
        if (id === video.id) {
          return {
            ...video,
            status: REQUEST_STATUS.FAILURE,
            uploading: false,
          }
        }

        return video
      })

      return {
        ...state,
        videos,
      }
    }
    case GET_VIDEOS_REQUEST: {
      const { page, rowsPerPage } = action.payload
      return {
        ...state,
        loadingVideos: true,
        pagination: {
          ...state.pagination,
          page,
          limit: rowsPerPage,
        },
      }
    }
    case GET_VIDEOS_SUCCESS: {
      const { more } = action.payload
      const newVideos = action.payload.videos.map((video) => ({
        state: VIDEO_STATE.UPLOADED,
        ...video,
        title: video.name,
      }))

      // new get videos + current videos (preview | upload)
      const previewVideos = state.videos.filter((video) => video.state === VIDEO_STATE.PREVIEW)
      const videos = [...previewVideos, ...newVideos]

      return {
        ...state,
        videos: (videos as VideoCustom[]) ?? [],
        loadingVideos: false,
        pagination: {
          ...state.pagination,
          more,
        },
      }
    }
    case GET_VIDEOS_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        loadingVideos: false,
        error,
      }
    }
    case DELETE_VIDEO_REQUEST: {
      const { id } = action.payload
      const videos = state.videos.map((video) => {
        if (id === video.id) {
          return {
            ...video,
            status: REQUEST_STATUS.SUCCESS,
            deleting: true,
          }
        }

        return video
      })

      return {
        ...state,
        videos,
      }
    }
    case DELETE_VIDEO_SUCCESS: {
      const { id } = action.payload
      const videos = state.videos.map((video) => {
        if (id === video.id) {
          return {
            ...video,
            status: REQUEST_STATUS.SUCCESS,
            deleted: true,
            deleting: false,
          }
        }

        return video
      })

      return {
        ...state,
        videos,
      }
    }
    case DELETE_VIDEO_FAILURE: {
      const { id, error } = action.payload
      const videos = state.videos.map((video) => {
        if (id === video.id) {
          return {
            ...video,
            status: REQUEST_STATUS.SUCCESS,
            deleting: false,
          }
        }

        return video
      })

      return {
        ...state,
        error,
        videos,
      }
    }

    default:
      return state
  }
}

export default checkRequestOrigin(initialState, TedVideosReducer, requestDestination)
