import * as React from 'react'
import type { SVGProps } from 'react'
const SvgTeacher = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.333a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM6.35.683a2.333 2.333 0 1 1 3.3 3.3 2.333 2.333 0 0 1-3.3-3.3Zm.317 5.984A.667.667 0 0 0 6 7.333v1.334h4V7.333a.667.667 0 0 0-.667-.666H6.667Zm4.666 2V7.333a2 2 0 0 0-2-2H6.667a2 2 0 0 0-2 2v1.334h-4V10H2v6h1.333v-6h9.334v6H14v-6h1.333V8.667h-4Z"
      fill="#1A1A1A"
    />
  </svg>
)
export default SvgTeacher
