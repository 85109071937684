import type { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { iconAnimations, iconCss } from '@/legacy/components/v2/ribbons/animations'

// TODO we should move this to a common place
export const scrollBarStyles = {
  '&::-webkit-scrollbar': { width: '12px' },
  '&::-webkit-scrollbar:horizontal': { height: '12px' },
  '&::-webkit-scrollbar-track': {
    background: '#F8F8F6',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#C1C1BF',
    borderRadius: '10px',
    border: '2px solid #F8F8F6',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#b4b4b4',
  },
  '&::-webkit-scrollbar-corner': {
    background: '#F8F8F6',
  },
}

export const activities = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 24,
  },
  activityBoardContainer: {
    maxWidth: 1600,
    width: '100%',
    margin: '34px 24px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 80px',
    },
  },
  filtersContainer: {
    width: '100%',
    display: 'inline-flex',
    position: 'relative',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'calc(100% - 300px)',
      width: '300px',
    },
  },
  filtersToggle: {
    display: 'none',
    color: '#212c4c',
    fontSize: 16,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex',
    },
  },
  outlineButton: ({ showFilters }) => ({
    padding: '8px 10px',
    marginBottom: 18,
    fontSize: 16,
    color: showFilters ? '#FFF' : theme.palette.secondary.main,
    background: showFilters ? theme.palette.primary.main : 'rgba(0,0,0,0)',
    border: `1px solid ${theme.palette.secondary.main}`,
    '&:active, &:hover': {
      background: theme.palette.primary.main,
      boxShadow: 'none',
      color: '#FFF',
      textShadow: '0',
      border: '1px solid' + theme.palette.primary.main,
      '& span span svg': {
        filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.25))',
      },
      '& span span svg path': {
        fill: theme.palette.primary.main + '!important',
      },
    },
  }),
  filtersPlus: {
    marginRight: 4,
    fontWeight: theme.typography.fontWeightBold,
  },
  filters: ({ showFilters }) => ({
    display: 'flex',
    marginBottom: 12,
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: ({ showFilters }) => ({
      visibility: showFilters ? 'visible' : 'hidden',
      flexDirection: 'column',
      overflow: 'hidden',
      transition: '0.2s all ease-in-out',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #D8DBE8',
      boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.14)',
      zIndex: 10,
      alignItems: 'center',
      position: 'absolute',
      height: showFilters ? 285 : 0,
      width: '100%',
      right: 0,
      padding: showFilters ? 12 : 0,
      boxSizing: 'border-box',
      borderRadius: 3,
    }),
  }),
  filtersTitle: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  leftFilters: {
    display: 'flex',
    justifyContent: 'center',
    '& > div:first-child': {
      marginBottom: 12,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  subjectPicker: {
    marginLeft: 24,
    marginBottom: 12,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0 12px',
      '& > div': {
        width: '100%',
      },
    },
  },
  cell: {
    maxWidth: 240,
    padding: 2,
  },
  rightFilters: {
    display: 'flex',
    marginBottom: 12,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      borderTop: '1px solid #D8DBE8',
    },
  },
  filterSwitch: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 16,
      marginBottom: 4,
      width: '100%',
    },
  },
  boardContainer: {
    display: 'flex',
    flex: '1',
    justifyContent: 'space-between',
  },
  columnHeader: {
    margin: '-2px -2px 0 -2px',
  },
  headerContainer: {
    padding: '8px 16px',
    borderBottom: '1px solid #D8DBE8',
  },
  today: {
    background: theme.palette.secondary.main,
    color: 'white',
  },
  header: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '23px',
  },
  footerContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    borderTop: '1px solid #D8DBE8',
    zIndex: 9,
    backgroundColor: theme.palette.common.white,
    '& > button': {
      color: theme.palette.secondary.main,
    },
  },
  emptyColumn: {
    position: 'absolute',
    height: 'calc(40vh - 76px)',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'initial',

    '& > div': {
      padding: '15px',
      '& > p': {
        fontSize: '16px',
      },
    },
  },
  emptyColumnText: {
    whiteSpace: 'break-spaces',
    textAlign: 'center',
    margin: 24,
  },
  kanbanContainer: {
    position: 'relative',
    width: '100%',
    '& > div > div': {
      scrollSnapType: 'x mandatory',
      ...scrollBarStyles,
      overflow: 'auto',
      scrollPaddingLeft: '5px',
      '& > div': {
        scrollSnapAlign: 'start',
      },
    },
  },
  kanbanLoading: {
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '2px 0',
    borderRadius: 8,
    top: 0,
    width: '100%',
    height: 'calc(100% - 4px)',
  },
})

export const modal = (theme) => ({
  modalContainer: ({ wide }) => ({
    maxWidth: wide ? 1000 : 480,
    maxHeight: '80%',
    margin: '24px auto',
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    position: 'relative',
    '&:focus': {
      outline: 'none',
    },
  }),
  loadingContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    background: 'rgba(255, 255, 255, .9)',
  },
})

interface StepAllStartProps {
  coverURL?: string
  modalLoading?: string
}

export const stepAllStart = makeStyles<Theme, StepAllStartProps>((theme) => ({
  ...iconAnimations,
  audiences: {
    margin: '24px 0',
    gap: 8,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  selectedAudiences: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
  },
  emptyAudienceListText: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey[500],
  },
  audienceButton: {
    margin: 'auto',
  },
  helpIcon: {
    color: theme.palette.grey[500],
    width: 16,
    height: 16,
  },
  audienceHeader: {
    display: 'flex',
    gap: 8,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
    backgroundColor: theme.palette.secondary.main,
    height: 60,
    minHeight: 60,
    padding: '0px 24px',
  },
  title: {
    textAlign: 'center',
    flex: 1,
    gap: 2,
    color: 'white',
    marginBottom: 0,
  },
  body: {
    padding: '0px 24px',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    ...scrollBarStyles,
  },
  classroomSubgroupContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 16,
    '@media (max-width: 600px)': {
      gridTemplateColumns: 'repeat(1, 1fr)',
      gap: 0,
    },
  },
  tableBody: {
    overflow: 'auto',
    ...scrollBarStyles,
  },
  selectContainer: {
    marginBottom: 24,
  },
  label: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: '18px',
    marginBottom: 8,
    textTransform: 'uppercase',
    borderWidth: 1,
  },
  select: {
    width: '100%',
  },
  boxesTitle: {
    marginBottom: 19,
  },
  boxesContainer: {
    display: 'flex',
  },
  errorMsg: {
    fontSize: 12,
    color: theme.palette.error.main,
  },
  radioButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  radioButtonWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 16,
  },
  radioChecked: {
    color: theme.palette.primary.main + '!important',
  },
  box: {
    height: 146,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flex: 1,
    padding: 24,
    border: 'solid 1px #D8DBE8',
    borderRadius: 8,
    '&:first-child': {
      marginRight: 13,
    },
    cursor: 'pointer',
    transition: 'background-color 100ms',
    '&:hover': {
      backgroundColor: '#f5f5fd',
      ...iconCss,
    },
    '&:active': {
      backgroundColor: '#ececf7',
    },
    ...scrollBarStyles,
  },
  boxSelected: {
    border: 'solid 1px ' + theme.palette.secondary.main,
    boxShadow: 'inset 0px 0px 0px 1px ' + theme.palette.secondary.main,
  },
  checkIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  boxLabel: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: '18px',
    marginTop: 20,
    textTransform: 'uppercase',
    borderWidth: 1,
    display: 'inline-block',
    marginRight: 5,
  },
  tooltip: {
    verticalAlign: 'bottom',
  },
  buttonContainerModal: {
    paddingBottom: 24,
    paddingRight: 24,
    marginTop: 24,
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: 4,
  },
  buttonCustomStyle: {
    padding: '10px 16px',
    borderRadius: '4px',
    fontWeight: 700,
  },
  markAsReadIcon: {
    fontSize: 'inherit',
  },
  autocompletePopper: ({ modalLoading }) => ({
    display: modalLoading && 'none',
    '& input': {
      maxHeight: 12,
    },
  }),
  switchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  availabilityLabel: {
    fontSize: 16,
    marginRight: 5,
  },
  availabilityLabelContainer: {
    display: 'flex',
  },
  bookContainer: {
    display: 'flex',
    marginBottom: 24,
  },
  topInfo: {
    alignItems: 'center',
    display: 'flex',
  },
  bookImage: ({ coverURL }) => ({
    backgroundImage: coverURL ? `url(${coverURL})` : theme.palette.secondary.main,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    flex: '0 0 145px',
    height: 191,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    cursor: 'pointer',
  }),
  bookTitle: {
    fontSize: 24,
    marginBottom: 12,
    marginTop: 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  bookDescription: {
    color: '#6D7878',
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    lineHeight: '20px',
    marginBottom: 20,
    height: 60,
    overflow: 'hidden',
  },
  bookInfo: {
    marginLeft: 24,
    width: '100%',
  },
  inputContainer: {
    marginBottom: 5,
  },
  inlineContainer: {
    padding: 3,
    marginBottom: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inlineLabelContainer: {
    display: 'flex',
  },
  inlineLabel: {
    lineHeight: '21px',
    color: theme.palette.text.primary,
    fontSize: 16,
    marginRight: 5,
  },
  tableIcon: {
    width: 28,
    marginRight: 8,
  },
  arrowBack: {
    verticalAlign: 'sub',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    marginRight: 12,
  },
  helperContainer: {
    padding: 3,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  button: {
    background: theme.palette.primary.main,
    boxShadow: '0 0 0 1px ' + theme.palette.primary.main,
  },
}))

export const stepAllStartLibrary = (theme) => ({
  ...iconAnimations,
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 32,
  },
  title: {
    marginBottom: 0,
  },
  body: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    ...scrollBarStyles,
  },
  tableBody: {
    overflow: 'auto',
    ...scrollBarStyles,
  },
  selectContainer: {
    marginBottom: 24,
  },
  label: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: '18px',
    marginBottom: 8,
    textTransform: 'uppercase',
    borderWidth: 1,
  },
  select: {
    width: '100%',
  },
  boxesTitle: {
    marginBottom: 19,
  },
  boxesContainer: {
    display: 'flex',
  },
  errorMsg: {
    fontSize: 12,
    color: theme.palette.error.main,
  },
  box: {
    height: 146,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flex: 1,
    padding: 24,
    border: 'solid 1px #D8DBE8',
    borderRadius: 8,
    '&:first-child': {
      marginRight: 13,
    },
    cursor: 'pointer',
    transition: 'background-color 100ms',
    '&:hover': {
      backgroundColor: '#f5f5fd',
      ...iconCss,
    },
    '&:active': {
      backgroundColor: '#ececf7',
    },
    ...scrollBarStyles,
  },
  boxSelected: {
    border: 'solid 1px ' + theme.palette.secondary.main,
    boxShadow: 'inset 0px 0px 0px 1px ' + theme.palette.secondary.main,
  },
  checkIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  boxLabel: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: '18px',
    marginTop: 20,
    textTransform: 'uppercase',
    borderWidth: 1,
    display: 'inline-block',
    marginRight: 5,
  },
  tooltip: {
    verticalAlign: 'bottom',
  },
  buttonContainerModal: {
    marginTop: 24,
    textAlign: 'right',
  },
  markAsReadIcon: {
    fontSize: 'inherit',
  },
  autocompletePopper: ({ modalLoading }) => ({
    display: modalLoading && 'none',
  }),
  switchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  availabilityLabel: {
    fontSize: 16,
    marginRight: 5,
  },
  availabilityLabelContainer: {
    display: 'flex',
  },
  bookContainer: {
    display: 'flex',
    marginBottom: 24,
  },
  topInfo: {
    alignItems: 'center',
    display: 'flex',
  },
  bookImage: ({ coverURL }) => ({
    backgroundImage: coverURL ? `url(${coverURL})` : theme.palette.secondary.main,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    flex: '0 0 145px',
    height: 191,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    cursor: 'pointer',
  }),
  bookTitle: {
    fontSize: 24,
    marginBottom: 12,
    marginTop: 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  bookDescription: {
    color: '#6D7878',
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    lineHeight: '20px',
    marginBottom: 20,
    height: 60,
    overflow: 'hidden',
  },
  bookInfo: {
    marginLeft: 24,
    width: '100%',
  },
  inputContainer: {
    marginBottom: 5,
  },
  inlineContainer: {
    padding: 3,
    marginBottom: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inlineLabelContainer: {
    display: 'flex',
  },
  inlineLabel: {
    lineHeight: '21px',
    color: theme.palette.text.primary,
    fontSize: 16,
    marginRight: 5,
  },
  tableIcon: {
    width: 28,
    marginRight: 8,
  },
  arrowBack: {
    verticalAlign: 'sub',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    marginRight: 12,
  },
  helperContainer: {
    padding: 3,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  button: {
    background: theme.palette.primary.main,
    boxShadow: '0 0 0 1px ' + theme.palette.primary.main,
  },
  dateContainer: {
    display: 'flex',
    marginRight: '4px',
  },
  datePickContainer: {
    paddingRight: 13,
  },
  timeContainer: {
    flex: 2,
  },
})

export const assignModalSteps = (theme: Theme) => ({
  modalContainer: ({ wide }) => ({
    maxWidth: wide ? '90vw' : '480px',
    minWidth: 480,
    maxHeight: '80%',
    margin: '24px auto',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    position: 'relative',
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 'initial',
      minWidth: 'initial',
      maxHeight: 'initial',
      width: '100vw',
      height: '100dvh',
      margin: 0,
    },
  }),
  loadingContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    background: 'rgba(255, 255, 255, .9)',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px',
    height: 60,
    marginBottom: 32,
    backgroundColor: theme.palette.secondary.main,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
  },
  title: {
    flex: 1,
    marginBottom: 0,
    color: 'white',
    textAlign: 'center',
  },
  subtitle: {
    marginTop: 0,
    marginBottom: 15,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.typography.fontFamily,
  },
  body: {
    padding: '0 24px',
    flex: 1,
    overflow: 'auto',
    ...scrollBarStyles,
  },
  closeIcon: {
    color: 'white',
  },
  autocompletePopper: ({ modalLoading }) => ({
    display: modalLoading && 'none',
  }),
  selectContainer: {
    marginBottom: 24,
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: 16,
    lineHeight: '18px',
    marginBottom: 8,
    borderWidth: 1,
  },
  select: {
    width: '100%',
  },
  buttonsContainer: {
    padding: '30px 24px',
  },
  error: {
    textAlign: 'right',
    padding: '10px 24px',
  },
  tableContainer: ({ loading }) => ({
    position: 'relative',
    overflow: 'auto',
    width: '100%',
    maxHeight: '100%',
    height: 'auto',
    minHeight: loading ? '380px' : 0,
    border: '1px solid #DFDFDA',
    borderRadius: '8px',
    boxSizing: 'border-box',
    scrollSnapType: 'both mandatory',
    scrollPaddingTop: '136px',
    scrollPaddingLeft: '70px',
    '& td': {
      scrollSnapAlign: 'start',
      borderBottom: '1px solid #DFDFDA',
    },
    '& tr:last-child td': {
      borderBottom: 0,
    },
    '& tr:first-child div:first-child': {
      textAlign: 'left',
    },
    ...scrollBarStyles,
  }),
  table: {
    width: '100%',
    maxHeight: '100%',
    margin: 'auto',
    borderSpacing: 0,
    minWidth: 800, // Make sure the table has a minimum width
    border: 0,
    borderCollapse: 'separate',
    tableLayout: 'auto',
    overflowX: 'auto', // Make sure horizontal scroll
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
    },
  },
  tableHead: {
    width: 'min-content',
    '& th': {
      position: 'sticky',
      top: 0,
      zIndex: 1,
      background: theme.palette.background.default,
    },
  },
  tableCell: {
    textAlign: 'center',
  },
  headerCell: {
    padding: '12px 0px 10px 16px',
    boxSizing: 'border-box',
    textAlign: '-webkit-center',
    display: 'table-cell',
    borderBottom: '1px solid #DFDFDA',
    '&:first-of-type': {
      textAlign: 'left',
    },
    fontSize: 14,
    color: theme.palette.primary.main,
    borderLeft: 'none',
    borderRight: 'none',
  },
  cell: {
    padding: 8,
  },
  activitiyName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  unitTitle: {
    padding: '5px 0',
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: 10,
  },
  activitiyIcon: {
    width: 24,
    height: 24,
    marginLeft: 10,
    marginRight: 10,
  },
})

export const stepAVVEnd = (theme: Theme) => ({
  alert: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.error.main,
  },
  startActivityContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '12px 0px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  startActivityText: {
    borderWidth: 1,
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 400,
    width: '100%',
    lineHeight: '21px',
    marginRight: 5,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 32,
    backgroundColor: theme.palette.secondary.main,
    height: 60,
    minHeight: 60,
    padding: '0px 24px',
  },
  titleLeftContainer: {
    display: 'flex',
  },
  margin: {},
  back: {
    width: 32,
    height: 32,
    padding: 3,
    marginRight: 7,
  },
  title: {
    marginBottom: 0,
    color: 'white',
  },
  body: {
    padding: '0px 24px',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    ...scrollBarStyles,
  },
  inputContainer: {
    padding: 3,
    marginBottom: 24,
  },
  helperContainer: {
    padding: 3,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
  },
  momentInputs: {
    display: 'flex',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  inlineLabelContainer: {
    display: 'flex',
  },
  dateContainer: {
    marginRight: '4px',
  },
  datePickContainer: {
    paddingRight: 13,
  },
  timeContainer: {
    flex: 2,
  },
  select: {
    width: '100%',
  },
  label: {
    marginBottom: 8,
    borderWidth: 1,
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    fontSize: 14,
    textTransform: 'uppercase',
  },
  boxesContainer: {
    display: 'flex',
  },
  box: {
    height: 146,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flex: 1,
    padding: 24,
    border: 'solid 1px #D8DBE8',
    borderRadius: 8,
    '&:first-child': {
      marginRight: 13,
    },
    cursor: 'pointer',
    transition: 'background-color 100ms',
    '&:hover': {
      backgroundColor: '#f5f5fd',
    },
    '&:active': {
      backgroundColor: '#ececf7',
    },
  },
  boxSelected: {
    border: 'solid 1px ' + theme.palette.secondary.main,
    boxShadow: 'inset 0px 0px 0px 1px ' + theme.palette.secondary.main,
  },
  checkIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  boxLabel: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: '18px',
    marginTop: 20,
    textTransform: 'uppercase',
    borderWidth: 1,
  },
  buttonContainerModal: {
    paddingTop: 24,
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: 4,
    paddingBottom: 24,
    paddingRight: 24,
  },
  buttonCustomStyle: {
    padding: '10px 16px',
    borderRadius: '4px',
    fontWeight: 700,
  },
  markAsReadIcon: {
    fontSize: 'inherit',
  },
  selectContainer: {
    marginBottom: 24,
  },
  inlineContainer: {
    padding: 3,
    marginBottom: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inlineLabel: {
    lineHeight: '21px',
    color: theme.palette.text.primary,
    fontSize: 16,
    marginRight: 5,
  },
  button: {
    background: theme.palette.primary.main,
    boxShadow: '0 0 0 1px ' + theme.palette.primary.main,
  },
})

export const editorModal = (theme) => ({
  modalContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    background: theme.palette.background.default,
  },
  header: {
    margin: '24px 24px 0 24px',
    paddingBottom: 24,
    borderBottom: 'solid 1px #D8DBE8',
  },
  headerMain: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  titleContainer: {
    display: 'flex',
    minWidth: 250,
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      margin: 0,
      minWidth: '100%',
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  classroomContainer: {
    display: 'flex',
    flex: 1,
    minWidth: 0,
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  classroomChip: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  classroomContainerCenter: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  closeContainer: {
    minWidth: 35,
    marginLeft: 15,
    [theme.breakpoints.down('md')]: {
      visibility: 'hidden',
    },
  },
  closeContainerMobile: {
    minWidth: 35,
    marginLeft: 15,
    [theme.breakpoints.up('md')]: {
      visibility: 'hidden',
    },
  },
  closeButton: {
    padding: 8,
  },
  closeIcon: {
    width: 19,
    height: 19,
  },
  back: {
    width: 32,
    height: 32,
    padding: 3,
    marginRight: 7,
  },
  title: {
    margin: '0 10px 0 0',
  },
  body: {
    padding: '40px 24px',
    flex: 1,
    overflowY: 'auto',
    ...scrollBarStyles,
  },
  bodyContent: {
    maxWidth: 880,
    margin: 'auto',
    padding: 32,
    border: '1px solid #DFDFDA',
    borderRadius: 8,
    background: '#FFF',
    zIndex: 1,
  },
  footer: {
    padding: '13px 32px',
    background: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    boxShadow: '0 0 4px rgba(0, 0, 0, .25)',
    zIndex: 2,
  },
  buttonContainer: {
    display: 'flex',
  },
  saveDraft: {
    display: 'none',
    marginRight: 13,
  },
  score: {
    marginLeft: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '11px 16px',
    border: 'solid 1px #d8dbe8',
    borderRadius: 3,
    fontSize: 16,
    fontWeight: 600,
  },
  scoreReminder: {
    marginRight: 24,
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
  },
  coverReminder: {
    fontSize: 14,
    marginRight: 24,
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  durationContainer: {
    marginBottom: 24,
    display: 'flex',
  },
  durationLabel: {
    textTransform: 'uppercase',
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 18,
  },
  duration: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 18,
    marginLeft: 12,
  },
  complexLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  label: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: '18px',
    textTransform: 'uppercase',
    borderWidth: 1,
  },
  remainingCharactersLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    color: '#6D7878',
    marginLeft: 8,
  },
  button: {
    background: theme.palette.primary.main,
    boxShadow: '0 0 0 1px ' + theme.palette.primary.main,
  },
})

export const deleteActivity = (theme) => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 32,
  },
  title: {
    marginBottom: 0,
  },
  modalContainer: {
    maxWidth: 520,
    margin: '24px auto',
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  buttonContainerModal: {
    marginTop: 24,
    textAlign: 'right',
  },
  button: {
    background: theme.palette.primary.main,
    boxShadow: '0 0 0 1px ' + theme.palette.primary.main,
  },
})

export const ClassDividerColumns = (theme) => ({
  dividerTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: '2rem',
  },
  buttonBack: {
    border: '1px solid #4F36D6',
    color: '#4F36D6',
    width: 81,
  },
  columnHeader: {
    textAlign: 'center',
    margin: '-2px -2px 0 -2px',
  },
  headerContainer: {
    padding: '8px 16px',
    color: '#455A64',
    borderBottom: '1px solid #D8DBE8',
  },
  cell: {
    paddingBottom: 5,
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  users: {
    background: '#4f36d6',
    color: '#fff',
    borderRadius: '8px 8px 0 0',
  },
  emptyColumn: {
    display: 'block',
    width: '100%',
    position: 'absolute',
    margin: '50% auto',
    whiteSpace: 'initial',
    fontSize: 16,
  },
  button: {
    background: theme.palette.primary.main,
    boxShadow: '0 0 0 1px ' + theme.palette.primary.main,
  },
})
