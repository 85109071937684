import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

// State.
export interface RejectionModalState {
  isOpenModal: boolean
  status: REQUEST_STATUS
  reasonForRejection: string
  documentIdToReject: number
}

// Types.
export enum REJECTION_MODAL_TYPES {
  OPEN_MODAL = 'PRINCIPAL/DOCUMENTATION/REJECTION_MODAL/OPEN_MODAL',
  SET_REASON_FOR_REJECTION = 'PRINCIPAL/DOCUMENTATION/REJECTION_MODAL/SET_REASON_FOR_REJECTION',
  REJECT_DOCUMENT_REQUEST = 'PRINCIPAL/DOCUMENTATION/REJECTION_MODAL/REJECT_DOCUMENT_REQUEST',
  REJECT_DOCUMENT_SUCCESS = 'PRINCIPAL/DOCUMENTATION/REJECTION_MODAL/REJECT_DOCUMENT_SUCCESS',
  REJECT_DOCUMENT_FAILURE = 'PRINCIPAL/DOCUMENTATION/REJECTION_MODAL/REJECT_DOCUMENT_FAILURE',
  CLEAN_MODAL = 'PRINCIPAL/DOCUMENTATION/REJECTION_MODAL/CLEAN_MODAL',
}

// Actions.

// Open modal action.
export type OpenModalAction = {
  type: REJECTION_MODAL_TYPES.OPEN_MODAL
  payload: number
}

// Set reason for rejection action.
export type SetReasonForRejectionAction = {
  type: REJECTION_MODAL_TYPES.SET_REASON_FOR_REJECTION
  payload: string
}

// Reject document actions.
export type RejectDocumentRequestAction = {
  type: REJECTION_MODAL_TYPES.REJECT_DOCUMENT_REQUEST
}

export type RejectDocumentSuccessAction = {
  type: REJECTION_MODAL_TYPES.REJECT_DOCUMENT_SUCCESS
}

export type RejectDocumentFailureAction = {
  type: REJECTION_MODAL_TYPES.REJECT_DOCUMENT_FAILURE
}

export type RejectDocumentAction =
  | RejectDocumentRequestAction
  | RejectDocumentSuccessAction
  | RejectDocumentFailureAction

// Clean modal action.
export type CleanModalAction = {
  type: REJECTION_MODAL_TYPES.CLEAN_MODAL
}

// Action.
export type RejectionModalAction =
  | OpenModalAction
  | SetReasonForRejectionAction
  | RejectDocumentAction
  | CleanModalAction
