import type {
  VideoPopupActions,
  VideoPopupState,
} from '@/sections/sandbox/videoPopup/types/videoPopup'
import { SET_POPUP_CLOSED, SET_POPUP_OPEN } from '@/sections/sandbox/videoPopup/types/videoPopup'

const initialState: VideoPopupState = {
  popupOpen: false,
  video: '',
}

function root(state = initialState, action: VideoPopupActions): VideoPopupState {
  switch (action.type) {
    case SET_POPUP_OPEN: {
      return { ...state, popupOpen: true, video: action.payload.video }
    }
    case SET_POPUP_CLOSED: {
      return { ...state, popupOpen: false, video: '' }
    }
    default:
      return state
  }
}

export default root
