import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ActivityCell from './activityCell'
import Button from './button'
import AddCircleIcon from '@mui/icons-material/AddCircle'

const useStyles = makeStyles((theme) => ({
  column: {
    display: 'flex',
    width: 238,
    borderRadius: 8,
    backgroundColor: '#FFF',
    border: '1px solid #D8DBE8',
    flexDirection: 'column',
  },
  header: {
    padding: '9px 16px',
    borderBottom: '1px solid #D8DBE8',
  },
  title: {
    fontColor: '#1A1A1A',
    fontSize: 18,
    lineHeight: '23px',
    fontWeight: 500,
  },
  tasksContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid #D8DBE8',
  },
}))

const ActivityColumn = (props) => {
  const classes = useStyles()
  const { activities, title, createActivityAction } = props

  return (
    <div className={classes.column} onDragEnter={(e) => {}} onDragExit={(e) => {}}>
      <div className={classes.header}>
        <Typography className={classes.title}>{title}</Typography>
      </div>
      <div className={classes.tasksContainer}>
        {activities.map(
          (
            { id, leftIcon, teacher, color, rightEmoji, title, text, locked, menuOptions },
            index
          ) => (
            <ActivityCell
              id={id}
              key={index}
              leftIcon={leftIcon}
              teacher={teacher}
              color={color}
              rightEmoji={rightEmoji}
              title={title}
              text={text}
              locked={locked}
              menuOptions={menuOptions}
            />
          )
        )}
      </div>
      <div className={classes.buttonContainer}>
        <Button IconLeft={AddCircleIcon} variant="text" onClick={createActivityAction}>
          Crear Actividad
        </Button>
      </div>
    </div>
  )
}

export default ActivityColumn
