import type { Action, Reducer } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import localforage from 'localforage'
import courses from '@/sections/courses/reducer'
import avv from '@/sections/avv/reducer'
import chat from '@/sections/chat/reducer'
import teacher from '@/sections/teacher/reducer'
import student from '@/sections/student/reducer'
import contentCreator from '@/sections/content-creator/reducer'
import auth from '@/sections/auth/reducer'
import user from '@/sections/user/reducer'
import display from '@/sections/display/reducer'
import header from '@/sections/header/reducer'
import editor from '@/sections/editor/reducer'
import conference from '@/sections/conference/reducer'
import preview from '@/sections/preview/reducer'
import features from '@/sections/features/reducer'
import ebAdmin from '@/sections/eb-admin/reducer'
import ebEvaluator from '@/sections/eb-evaluator/reducer'
import principal from '@/sections/principal/reducer'
import robots from '@/sections/robots/reducer'
import backpack from '@/sections/backpack/reducer'
import sandbox from '@/sections/sandbox/reducer'
import videocall from '@/sections/videocall/reducer'
import quiz from '@/sections/quiz/reducer'
import notifier from '@/sections/notifier/reducer'
import reportCard from '@/sections/report-card/reducer'
import attendances from '@/sections/attendances/reducer'
import offlineActivities from '@/sections/offlineActivities/reducer'
import management from '@/sections/management/reducer'
import ted from '@/sections/ted/reducer'

// this don't respect the redux architecture
import userProfile from '@/sections/user/profile/reducers/editProfile'
import assignmentsInput from '@/legacy/components/v3/inputs/assignments/reducers/assignmentsInput'
import childrenAssignmentModal from '@/legacy/components/v3/inputs/childrenAssignments/reducers/childrenAssignmentModal'

import { LOGOUT } from '@/sections/header/types/logout'

// If you add a new state, keep the alphabetical order
const rootReducer = combineReducers({
  assignmentsInput,
  attendances,
  auth,
  avv,
  backpack,
  chat,
  childrenAssignmentModal,
  conference,
  contentCreator,
  courses,
  display,
  ebAdmin,
  ebEvaluator,
  editor,
  features,
  header,
  management,
  notifier,
  offlineActivities,
  preview,
  principal,
  quiz,
  reportCard,
  robots,
  sandbox,
  student,
  teacher,
  ted,
  user,
  userProfile,
  videocall,
})

const reducer: Reducer<RootState, Action<string>> = (state, action) => {
  if (action.type === LOGOUT) {
    localforage.removeItem('persist:avv')
    return rootReducer(undefined, action)
  }

  return rootReducer(state, action)
}

export type RootState = ReturnType<typeof rootReducer>
export type GetState = () => RootState
export default reducer
