import { combineReducers } from 'redux'
import migrationTab from './reducers/migrationTab'
import migrationClassrooms from './reducers/migrationClassrooms'
import newClassroomModal from './reducers/newClassroomModal'
import studentsDrawer from './reducers/studentsDrawer'
import newStudentModal from './reducers/newStudentModal'

const rootReducer = combineReducers({
  migrationTab,
  migrationClassrooms,
  newClassroomModal,
  newStudentModal,
  studentsDrawer,
})

export default rootReducer
