import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/student/summary/types/drawer/classrooms'

const initialState: Types.ClassroomsState = {
  status: REQUEST_STATUS.IDLE,
  data: [],
  selectedClassroom: { id: 0, name: '' },
}

const classroomsReducer = (
  state: Types.ClassroomsState = initialState,
  action: Types.ClassroomsAction
): Types.ClassroomsState => {
  switch (action.type) {
    case Types.CLASSROOMS_TYPES.GET_STUDENT_CLASSROOMS_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.CLASSROOMS_TYPES.GET_STUDENT_CLASSROOMS_SUCCESS: {
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        data: action.payload.data,
      }
    }
    case Types.CLASSROOMS_TYPES.GET_STUDENT_CLASSROOMS_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.CLASSROOMS_TYPES.SET_SELECTED_CLASSROOM: {
      return {
        ...state,
        selectedClassroom: action.payload.newClassroom,
      }
    }
    case Types.CLASSROOMS_TYPES.CLEAN_CLASSROOMS: {
      return initialState
    }
    default:
      return state
  }
}

export default classroomsReducer
