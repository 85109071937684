import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import { SAVE_OR_EDIT_REPORT_CARD_CONFIG_SUCCESS } from '@/sections/report-card/config/types/apiInteractions'
import * as Types from '@/sections/report-card/config/types/stepperModalSteps/signatures'
import { getRandomInt } from '@/legacy/utils/randomInt'

const initialState: Types.ReportCardConfigSignaturesState = {
  frontPage: '',
  header: {
    header: '',
    name: '',
    level: '',
    classroomName: '',
    academicPeriod: '',
  },
  secondCover: '',
  uploading: {
    frontPage: false,
    header: false,
    signature: false,
    secondCover: false,
  },
  error: '',
  studentSignature: true,
  newSignature: false,
  signatures: [
    {
      signature: '',
      clarification: '',
      isLoading: false,
      id: getRandomInt(0, 99999999),
    },
  ],
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_CONFIG_SIGNATURES_STEP

const ReportCardConfigPeriodsStep: Reducer<
  Types.ReportCardConfigSignaturesState,
  Types.ReportCardConfigSignatureAction
> = (state = initialState, action): Types.ReportCardConfigSignaturesState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case Types.FILE_UPLOAD_REQUEST:
      if (action.payload.uploadType === 'signature') {
        return {
          ...state,
          signatures: state.signatures.map((signature) => {
            return signature.id !== action.payload.signatureId
              ? signature
              : {
                  ...signature,
                  isLoading: true,
                }
          }),
          uploading: { ...state.uploading, [action.payload.uploadType]: true },
          error: initialState.error,
        }
      } else {
        return {
          ...state,
          uploading: { ...state.uploading, [action.payload.uploadType]: true },
          error: initialState.error,
        }
      }

    case Types.FILE_UPLOAD_SUCCESS:
      if (action.payload.uploadType === 'signature') {
        return {
          ...state,
          signatures: state.signatures.map((signature) => {
            return signature.id !== action.payload.signatureId
              ? signature
              : {
                  ...signature,
                  signature: action.payload.uploadedFile.url,
                  isLoading: false,
                }
          }),
          uploading: { ...state.uploading, [action.payload.uploadType]: false },
        }
      } else if (action.payload.uploadType === 'header') {
        return {
          ...state,
          header: {
            ...state.header,
            header: action.payload.uploadedFile.url,
          },
          uploading: { ...state.uploading, [action.payload.uploadType]: false },
        }
      } else {
        return {
          ...state,
          [action.payload.uploadType]: action.payload.uploadedFile.url,
          uploading: { ...state.uploading, [action.payload.uploadType]: false },
        }
      }
    case Types.FILE_UPLOAD_FAILURE:
      if (action.payload.uploadType === 'signature') {
        return {
          ...state,
          signatures: state.signatures.map((signature) => {
            return signature.id !== action.payload.signatureId
              ? signature
              : {
                  ...signature,
                  isLoading: false,
                }
          }),
          uploading: { ...state.uploading, [action.payload.uploadType]: false },
          error: action.payload.error,
        }
      } else {
        return {
          ...state,
          uploading: { ...state.uploading, [action.payload.uploadType]: false },
          error: action.payload.error,
        }
      }
    case Types.CLEAR_FILE:
      if (action.payload.uploadType === 'signature') {
        return {
          ...state,
          signatures: state.signatures.map((signature) => {
            return signature.id !== action.payload.signatureId
              ? signature
              : {
                  ...signature,
                  signature: '',
                }
          }),
        }
      } else if (action.payload.uploadType === 'header') {
        return {
          ...state,
          header: {
            ...state.header,
            header: '',
          },
        }
      } else {
        return {
          ...state,
          [action.payload.uploadType]: initialState[action.payload.uploadType],
        }
      }

    case Types.SET_VALUES_FOR_EDITION: {
      const { signaturesState } = action.payload
      return signaturesState
    }
    case Types.ADD_NEW_SIGNATURE: {
      const newId = getRandomInt(0, 99999999)
      return {
        ...state,
        signatures: [
          ...state.signatures,
          {
            signature: '',
            clarification: '',
            isLoading: false,
            id: action.payload.isEditMode ? newId * -1 : newId,
          },
        ],
      }
    }
    case Types.REMOVE_SIGNATURE: {
      return {
        ...state,
        signatures: state.signatures.filter(
          (signature) => signature.id !== action.payload.signature.id
        ),
      }
    }
    case Types.SET_SIGNATURE_CLARIFICATION: {
      return {
        ...state,
        signatures: state.signatures.map((signature) => {
          return signature.id !== action.payload.signature.id
            ? signature
            : {
                ...signature,
                clarification: action.payload.newValue,
              }
        }),
      }
    }
    case Types.SET_BULLETIN_HEADER: {
      const { newValue, headerType } = action.payload

      return {
        ...state,
        header: {
          ...state.header,
          [headerType]: newValue,
        },
      }
    }
    case SAVE_OR_EDIT_REPORT_CARD_CONFIG_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, ReportCardConfigPeriodsStep, requestDestination)
