import { Drawer } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import DrawerHeader from '@/legacy/components/v3/newDrawerHeader'

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100vh',
    width: '90vw',
    borderTopLeftRadius: 8,
    overflowX: 'hidden',
  },
}))

export interface ReportCardSideDrawerProps {
  open: boolean
  onClose: () => void
  children: React.ReactNode
  title: string
  subtitle?: string
}

export default function ReportCardSideDrawer(props: ReportCardSideDrawerProps) {
  const { open, onClose, children, title, subtitle = '' } = props
  const C = useStyles()

  return (
    <Drawer classes={{ paper: C.paper }} anchor="right" open={open} onClose={onClose}>
      <DrawerHeader title={title} onClose={onClose} subtitle={subtitle} />
      {children}
    </Drawer>
  )
}
