import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export interface MessageMarkAsReadState {
  status: REQUEST_STATUS
  loading: boolean
  error: Error | null
}

export enum MESSAGE_MARK_AS_READ_TYPE {
  MARK_AS_READ = 'TEACHER/BULLETIN/MESSAGE_MARK_AS_READ/MARK_AS_READ',
  MARK_AS_READ_SUCCESS = 'TEACHER/BULLETIN/MESSAGE_MARK_AS_READ/MARK_AS_READ_SUCCESS',
  MARK_AS_READ_FAILURE = 'TEACHER/BULLETIN/MESSAGE_MARK_AS_READ/MARK_AS_READ_FAILURE',
}

export type MarkMessageAsRead = {
  type: MESSAGE_MARK_AS_READ_TYPE.MARK_AS_READ
}

export type MarkMessageAsReadSuccess = {
  type: MESSAGE_MARK_AS_READ_TYPE.MARK_AS_READ_SUCCESS
  payload: {
    messageId: number
  }
}

export type MarkMessageAsReadFailure = {
  type: MESSAGE_MARK_AS_READ_TYPE.MARK_AS_READ_FAILURE
  payload: {
    error: Error
  }
}

export type MessageMarkAsReadActions =
  | MarkMessageAsRead
  | MarkMessageAsReadSuccess
  | MarkMessageAsReadFailure
