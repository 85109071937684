import type { AxiosResponse } from 'axios'
import { url } from '@aula/config'
import type { PasswordType } from '@/legacy/redux/types/auth'
import type { DocType } from '@/sections/auth/codeLogin/types/codeLogin'
import { apiPrivate, apiPublic } from './..'
import type { DescriptionResponseShortener } from '@/api/types'

export const LoginAPI = {
  getDocTypes,
  DEPRECATED_getPasswordTypes,
  getPasswordTypes,
  whichLogin,
  login,
  loginWithGoogle,
  signupWithCode,
  getQuestions,
  loginWithAnswers,
  assignPassword,
  loginWithMicrosoft,
}

/**
 * Get types of documents.
 *
 * @role Any
 */
function getDocTypes() {
  return apiPublic.get(url + '/v1/login/id-types')
}

/**
 * DEPRECATED: Get types of passwords.
 *
 * @role Any
 */
function DEPRECATED_getPasswordTypes() {
  return apiPublic.get(url + '/v1/login/password-types')
}

interface GetPasswordTypeResponse {
  types: PasswordType[]
}

/**
 * Get types of passwords.
 *
 * @role Any
 */
function getPasswordTypes(): Promise<GetPasswordTypeResponse> {
  return apiPublic
    .get(url + '/v1/login/password-types')
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return resp.data.description
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Assign password.
 *
 * @role Any
 * @param password
 * @param passwordTypeId
 */
function assignPassword(password: string, passwordTypeId: number) {
  const formData = new FormData()

  formData.append('password', password)
  formData.append('password_type_id', passwordTypeId.toString())
  return apiPrivate.post(url + '/v2/user/password', formData)
}

/**
 * Find out which login method to use.
 *
 * @role Any
 * @param id
 * @param idType
 */
function whichLogin(id: string, idType: number) {
  return apiPublic.get(url + '/v3/login/which', { params: { id, idType } })
}

interface V4LoginData {
  description: {
    token: string
  }
}

/**
 * Log user in.
 *
 * @role Any
 * @param id
 * @param password
 * @param idType
 */
function login(id: string, password: string, idType: string): Promise<string> {
  const formData = new FormData()
  formData.append('id', id)
  formData.append('password', password)
  formData.append('id_type', idType)

  return apiPublic
    .post(url + '/v4/login', formData)
    .then((resp: AxiosResponse<V4LoginData>) => {
      return resp.data.description.token
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Log in with Google JWT Id token.
 */

function loginWithGoogle(jwt: string): Promise<string> {
  const formData = new FormData()
  formData.append('jwt', jwt)

  return apiPublic
    .post(url + '/v1/login/google', formData)
    .then((resp: AxiosResponse<V4LoginData>) => {
      return resp.data.description.token
    })
    .catch((err) => {
      throw err
    })
}

interface SignUpWithCodeData {
  description: {
    token: string
  }
}

/**
 * Sign user up with a licence code.
 *
 * @role Any
 * @param code
 * @param docType
 * @param identification
 * @param name
 * @param lastName
 */
function signupWithCode(
  code: string,
  docType: DocType,
  identification: string,
  name: string,
  lastName: string
): Promise<string> {
  const credentials = {
    code: code,
    identificationTypeId: docType.id,
    identification: identification,
    name: name,
    lastName: lastName,
  }

  return apiPublic
    .post(url + '/v1/signup/code', credentials)
    .then((response: AxiosResponse<SignUpWithCodeData>) => {
      return response.data.description.token
    })
    .catch((err) => {
      throw err
    })
}

interface GetQuestionsData {
  description: {
    attempt: Attempt
  }
}

interface Attempt {
  id: number
  questions: Array<{
    id: number
    question: string
    i18NCode: string
    options: Array<{
      title: string
      index: number
    }>
  }>
}

/**
 * Get questions to answer.
 *
 * @role Any
 * @param idType
 * @param id
 * @param cosmos Cosmos key
 */
function getQuestions(idType: number, id: string, cosmos?: string): Promise<Attempt> {
  const params = { idType: idType, id, cosmos: cosmos ?? '' }
  return apiPublic
    .get(url + '/v4/login/questions', { params })
    .then((resp: AxiosResponse<GetQuestionsData>) => {
      return resp.data.description.attempt
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Log in with answers.
 *
 * @role Any
 * @param id
 * @param idType
 * @param answers
 */
function loginWithAnswers(id: string, idType: number, answers: number[]): Promise<string> {
  const data = {
    id,
    idType: idType,
    answers: answers,
  }
  return apiPublic
    .post(url + '/v5/login/questions', data)
    .then((resp: AxiosResponse<V4LoginData>) => {
      return resp.data.description.token
    })
    .catch((err) => {
      throw err
    })
}

async function loginWithMicrosoft(idToken: string): Promise<string> {
  return apiPublic
    .post(url + '/v1/login/microsoft', { idToken })
    .then((resp: AxiosResponse<V4LoginData>) => {
      return resp.data.description.token
    })
}
