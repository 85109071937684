import { apiPrivate } from '@/api'
import { url } from '@aula/config'
import type { Params } from '@/sections/principal/dashboard/types/common'

export const EducationalInfoAPI = {
  getTagCategory,
  listCategories,
}

/**
 * Get tags in a given category.
 *
 * @role Any
 * @param category
 * @param params
 */
function getTagCategory(params: Params, category: string, includeLibraryTags: boolean) {
  const actualParams = {
    ...params,
    category,
    withLibrary: includeLibraryTags,
  }
  return apiPrivate.get(url + `/v1/tags`, { params: actualParams })
}

/**
 * Get a list of tag categories
 *
 * @role Any
 * @param category
 * @param params
 */
function listCategories(includeLibraryTags: boolean) {
  const params = {
    withLibrary: includeLibraryTags,
  }

  return apiPrivate.get(url + `/v1/tag_categories`, { params })
}
