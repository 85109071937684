import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/content-creator/newBooks/types/steps/initial'

const initialState: Types.InitialStepState = {
  tagCategoriesInfo: {
    status: REQUEST_STATUS.IDLE,
    categories: [],
  },
  form: {
    title: '',
    cover: null,
    file: null,
    description: '',
    selectedTagsInfo: {},
    availableInLibrary: false,
  },
}

const initialStepReducer = (
  state: Types.InitialStepState = initialState,
  action: Types.InitialStepAction
): Types.InitialStepState => {
  switch (action.type) {
    case Types.INITIAL_STEP_TYPES.GET_TAGS_REQUEST: {
      return {
        ...state,
        tagCategoriesInfo: {
          ...state.tagCategoriesInfo,
          status: REQUEST_STATUS.LOADING,
        },
      }
    }
    case Types.INITIAL_STEP_TYPES.GET_TAGS_SUCCESS: {
      const { categories } = action.payload

      return {
        ...state,
        tagCategoriesInfo: {
          categories,
          status: REQUEST_STATUS.SUCCESS,
        },
      }
    }
    case Types.INITIAL_STEP_TYPES.GET_TAGS_FAILURE: {
      return {
        ...state,
        tagCategoriesInfo: {
          ...state.tagCategoriesInfo,
          status: REQUEST_STATUS.FAILURE,
        },
      }
    }
    case Types.INITIAL_STEP_TYPES.SET_TITLE: {
      return {
        ...state,
        form: {
          ...state.form,
          title: action.payload.newTitle,
        },
      }
    }
    case Types.INITIAL_STEP_TYPES.SET_COVER: {
      return {
        ...state,
        form: {
          ...state.form,
          cover: action.payload.cover,
        },
      }
    }
    case Types.INITIAL_STEP_TYPES.SET_FILE: {
      return {
        ...state,
        form: {
          ...state.form,
          file: action.payload.file,
        },
      }
    }
    case Types.INITIAL_STEP_TYPES.SET_DESCRIPTION: {
      return {
        ...state,
        form: {
          ...state.form,
          description: action.payload.newDescription,
        },
      }
    }
    case Types.INITIAL_STEP_TYPES.SET_SELECTED_TAGS_INFO: {
      const { tagCategoryName, selectedTags } = action.payload

      return {
        ...state,
        form: {
          ...state.form,
          selectedTagsInfo: {
            ...state.form.selectedTagsInfo,
            [tagCategoryName]: selectedTags,
          },
        },
      }
    }
    case Types.INITIAL_STEP_TYPES.TOGGLE_AVAILABLE_LIBRARY: {
      return {
        ...state,
        form: { ...state.form, availableInLibrary: !state.form.availableInLibrary },
      }
    }
    case Types.INITIAL_STEP_TYPES.SET_AVAILABLE_IN_LIBRARY: {
      return {
        ...state,
        form: { ...state.form, availableInLibrary: action.payload.availableInLibrary },
      }
    }
    case Types.INITIAL_STEP_TYPES.CLEAN_INITIAL_STEP: {
      return initialState
    }
    default:
      return state
  }
}

export default initialStepReducer
