export enum STUDENT_SUMMARY_TABS {
  INFORMATION = 'information',
  AUTHORIZED = 'authorized',
  ATTENDANCES = 'attendances',
  BULLETIN = 'bulletin',
  ACTIVITIES = 'activities',
  SCHOOL_RECORDS = 'schoolRecords',
}

export enum AUTHORIZATION_STATUS_TYPE {
  NONE = 'none',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PROGRAMMED = 'programmed',
}

export enum CONTACT_RELATION_TYPE {
  FAMILIAR = 'familiar',
  RESPONSIBLE = 'responsible',
  GUARDIAN = 'guardian',
  TRANSPORT = 'transport',
  OTHER = 'other',
}
