import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { ChatSwitchKeys, Types } from '@/sections/chat/constants'

export const SET_OPEN = 'CHAT/OPEN_CHAT'
export const SET_CLOSE = 'CHAT/CLOSE_CHAT'
export const SET_CHAT_TYPE = 'CHAT/SET_CHAT_TYPE'
export const SET_ACTIVE_CHAT = 'CHAT/SET_ACTIVE_CHAT'
export const SEND_MESSAGE = 'CHAT/SEND_MESSAGE'
export const SEND_VIDEOCALL_INVITE = 'CHAT/SEND_VIDEOCALL_INVITE'
export const ADD_NEW_MESSAGE = 'CHAT/ADD_NEW_MESSAGE'
export const SET_NEW_MESSAGE_IN_ACTUAL_CHAT = 'CHAT/SET_NEW_MESSAGE_IN_ACTUAL_CHAT'
export const SHOW_NOT_EXISTING_CHANNEL = 'CHAT/SHOW_NOT_EXISTING_CHANNEL'
export const NEW_MESSAGE = 'CHAT/NEW_MESSAGE'
export const CLEAN_MESSAGE_COUNT = 'CHAT/CLEAN_MESSAGE_COUNT'
export const CHANNEL_NOT_FOUND = 'CHAT/CHANNEL_NOT_FOUND'

export const CHANNEL_LIST_REQUEST = 'CHAT/CHANNEL_LIST_REQUEST'
export const CHANNEL_LIST_SUCCESS = 'CHAT/CHANNEL_LIST_SUCCESS'
export const CHANNEL_LIST_FAILURE = 'CHAT/CHANNEL_LIST_FAILURE'
export const MESSAGE_LIST_REQUEST = 'CHAT/MESSAGE_LIST_REQUEST'
export const MESSAGE_LIST_SUCCESS = 'CHAT/MESSAGE_LIST_SUCCESS'
export const MESSAGE_LIST_FAILURE = 'CHAT/MESSAGE_LIST_FAILURE'
export const CHANNEL_BY_ACTIVITY_ID_REQUEST = 'CHAT/CHANNEL_BY_ACTIVITY_ID_REQUEST'
export const CHANNEL_BY_ACTIVITY_ID_SUCCESS = 'CHAT/CHANNEL_BY_ACTIVITY_ID_SUCCESS'
export const CHANNEL_BY_ACTIVITY_ID_FAILURE = 'CHAT/CHANNEL_BY_ACTIVITY_ID_FAILURE'

export type Destinatary = typeof ChatSwitchKeys.Teacher | typeof ChatSwitchKeys.Classroom
export type ChannelType = typeof Types.Classroom | typeof Types.Subject | typeof Types.Activity

export interface ChannelsByKey {
  [any: string]: {
    show?: boolean
    unread?: number
  }
}

export interface ChatChannel {
  activityId: string
  key: string
  destinatary: Destinatary
}

export interface Channel {
  id: number
  channels: ChatChannel[]
  type: ChannelType
  classroomID?: number
  subjectID?: number
  name: string
  description?: string
  isActive: boolean
  unreadMessages: number
  activities?: Channel[]
  open?: boolean
  activityType: string
}

export interface TeacherChannel {
  id: number
  type: ChannelType
  name: string
  description: string
  subjects: Channel[]
}

export type GenericChannel = Channel[] | TeacherChannel[]

export interface User {
  id: number
  lastName: string
  name: string
  role: string
}

export interface Message {
  message: string
  createdAt: string
  updatedAt: string
  _id: string
  channelId: string
  user: User
}

export interface SocketMessage {
  channel: ChatChannel
  date: string
  destinatary: Destinatary
  message: string
  user: User
}

export interface LocalMessage {
  message: string
  date: string
  user: User
}
export interface LocalVideoCall {
  message: string
  date: string
  user: User
  type: string
  payload: {
    room: string
  }
}

export interface GoToChatOptions {
  fromOralExam: boolean
}

export interface GetChannelByActivityIDOptions {
  fromOralExam?: boolean
  fromNewModifyActivity?: boolean
}

export type GenericMessage = Message | SocketMessage | LocalMessage

export interface OpenChat {
  type: typeof SET_OPEN
}

export interface CloseChat {
  type: typeof SET_CLOSE
}

export interface SetChatTypeAction {
  type: typeof SET_CHAT_TYPE
  payload: {
    type: string
  }
}

export interface GetChannelListRequest {
  type: typeof CHANNEL_LIST_REQUEST
}

export interface GetChannelListSuccess {
  type: typeof CHANNEL_LIST_SUCCESS
  payload: {
    channels: GenericChannel
  }
}

export interface GetChannelListFailure {
  type: typeof CHANNEL_LIST_FAILURE
  payload: {
    error: string
  }
}

export interface GetChannelByActivityIDRequest {
  type: typeof CHANNEL_BY_ACTIVITY_ID_REQUEST
}

export interface GetChannelByActivityIDSuccess {
  type: typeof CHANNEL_BY_ACTIVITY_ID_SUCCESS
  payload: {
    channels: GenericChannel
  }
}

export interface GetChannelByActivityIDFailure {
  type: typeof CHANNEL_BY_ACTIVITY_ID_FAILURE
  payload: {
    error: string
  }
}

export interface SetActiveChat {
  type: typeof SET_ACTIVE_CHAT
  payload: {
    channels: GenericChannel
    item: Channel
  }
}

export interface GetMessageListRequest {
  type: typeof MESSAGE_LIST_REQUEST
}

export interface GetMessageListSuccess {
  type: typeof MESSAGE_LIST_SUCCESS
  payload: {
    messages: Message[]
  }
}

export interface GetMessageListFailure {
  type: typeof MESSAGE_LIST_FAILURE
  payload: {
    error: string
  }
}

export interface SendMessage {
  type: typeof SEND_MESSAGE
  payload: {
    message: LocalMessage
  }
}
export interface SendVideoCallInvite {
  type: typeof SEND_VIDEOCALL_INVITE
  payload: {
    message: LocalVideoCall
  }
}

export interface AddNewMessage {
  type: typeof ADD_NEW_MESSAGE
  payload: {
    message: Message
  }
}

export interface SetNewMessageInActualChat {
  type: typeof SET_NEW_MESSAGE_IN_ACTUAL_CHAT
  payload: {
    value: boolean
  }
}

export interface ShowNotExistingChannel {
  type: typeof SHOW_NOT_EXISTING_CHANNEL
  payload: {
    key: string
  }
}

export interface NewMessage {
  type: typeof NEW_MESSAGE
  payload: {
    key: string
    activityID: number
  }
}

export interface CleanMessageCount {
  type: typeof CLEAN_MESSAGE_COUNT
  payload: {
    key: string
  }
}

export interface ShowNotExistingChannel {
  type: typeof SHOW_NOT_EXISTING_CHANNEL
  payload: {
    key: string
  }
}

export interface ChannelNotFound {
  type: typeof CHANNEL_NOT_FOUND
  payload: {
    showChat: boolean
  }
}

export interface ChatState {
  open: boolean
  type: Destinatary
  title: string
  subtitle: string
  channels: GenericChannel
  channelsByKey: ChannelsByKey
  messages: GenericMessage[]
  message: string
  selectedChat: Channel
  selectedChannel: string
  statusChannels: REQUEST_STATUS
  statusMessages: REQUEST_STATUS
  loadingChannels: boolean
  loadingMessages: boolean
  newMessageInActualChat: boolean
  error: string
}

export enum messageTypes {
  VIDEO_CALL = 'video-call',
  TEXT = 'text',
}

export type ChatActions =
  | OpenChat
  | CloseChat
  | SetChatTypeAction
  | GetChannelListRequest
  | GetChannelListSuccess
  | GetChannelListFailure
  | GetChannelByActivityIDRequest
  | GetChannelByActivityIDSuccess
  | GetChannelByActivityIDFailure
  | SetActiveChat
  | GetMessageListRequest
  | GetMessageListSuccess
  | GetMessageListFailure
  | SendMessage
  | SendVideoCallInvite
  | AddNewMessage
  | SetNewMessageInActualChat
  | ShowNotExistingChannel
  | NewMessage
  | CleanMessageCount
  | ChannelNotFound
