import { combineReducers } from 'redux'
import addEditModal from './addEditUserModal'
import deleteUserModal from './deleteUserModal'
import usersDrawer from './usersDrawer'
import userDetailsModal from './userDetailsModal'
import deleteAssignmentModal from './deleteAssignmentModal'
import deleteOrganizationModal from './deleteOrganizationModal'
import deleteUserProfileModal from './deleteUserProfileModal'
import logAsModal from './logAsModal'

const usersReducer = combineReducers({
  usersDrawer,
  addEditModal,
  deleteUserModal,
  userDetailsModal,
  deleteAssignmentModal,
  deleteOrganizationModal,
  deleteUserProfileModal,
  logAsModal,
})

export default usersReducer
