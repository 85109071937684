import type {
  StudentFilesState,
  StudentFilesTypes,
  UploadedFile,
} from '@/sections/editor/types/studentFiles'
import {
  ADD_FILES_TO_UPLOAD,
  DOWNLOAD_FILE_FAILURE,
  DOWNLOAD_FILE_REQUEST,
  DOWNLOAD_FILE_SUCCESS,
  REMOVE_FILE_TO_UPLOAD,
  REMOVE_UPLOADED_FILE,
  UPLOAD_FILES_FAILURE,
  UPLOAD_FILES_REQUEST,
  UPLOAD_FILES_SUCCESS,
  SET_STUDENT_FILES_MODAL_OPEN,
  SET_STUDENT_FILES_MODAL_CLOSED,
} from '@/sections/editor/types/studentFiles'
import getActivityTeacherActionTypes from '@/sections/teacher/dashboard/types/activity'
import getActivityStudentActionTypes from '@/sections/student/dashboard/types/activity'
import { ACTIVITY_SUCCESS as WRITTEN_EXAM_ACTIVITY_SUCCESS } from '@/sections/student/writtenExam/types/writtenExam'
import { getAttachmentAnswersTemplates } from '@/sections/editor/utils'
import { concat, mergeDeepLeft, mergeDeepWith } from 'ramda'

const initialState: StudentFilesState = {
  attachmentAnswers: {},
  selectedQuestion: {
    id: 0,
    text: '',
    type: '',
    options: [],
  },
  version: '',
  filesModalOpen: false,
}

const setPropsToUploadedFile = (
  files: UploadedFile[],
  fileID: number,
  props: object
): UploadedFile[] => files.map((file) => (file.fileId === fileID ? { ...file, ...props } : file))

function root(state = initialState, action: StudentFilesTypes): StudentFilesState {
  switch (action.type) {
    case ADD_FILES_TO_UPLOAD: {
      const { questionID, files } = action.payload

      const attachmentAnswer = { [questionID]: { filesToUpload: files, error: '' } }
      return {
        ...state,
        attachmentAnswers: mergeDeepWith(concat, state.attachmentAnswers, attachmentAnswer),
      }
    }
    case REMOVE_FILE_TO_UPLOAD: {
      const { questionID, file: fileToRemove } = action.payload
      const filesToUpload = state.attachmentAnswers[questionID].filesToUpload.filter(
        (file) => file !== fileToRemove
      )
      const attachmentAnswer = { [questionID]: { filesToUpload: filesToUpload, error: '' } }

      return {
        ...state,
        attachmentAnswers: mergeDeepLeft(attachmentAnswer, state.attachmentAnswers),
      }
    }
    case UPLOAD_FILES_REQUEST: {
      const { questionID } = action.payload
      const attachmentAnswer = { [questionID]: { loading: true, error: '' } }

      return {
        ...state,
        attachmentAnswers: mergeDeepLeft(attachmentAnswer, state.attachmentAnswers),
      }
    }
    case UPLOAD_FILES_SUCCESS: {
      const { questionId: questionID, files } = action.payload
      const actualAttachmentAnswer = state.attachmentAnswers[questionID]
      const attachmentAnswer = {
        [questionID]: {
          loading: false,
          files: [
            ...actualAttachmentAnswer.files,
            ...files.map((file) => ({ ...file, fileId: file.id })),
          ],
          filesToUpload: [],
          error: '',
        },
      }
      return {
        ...state,
        attachmentAnswers: mergeDeepLeft(attachmentAnswer, state.attachmentAnswers),
        filesModalOpen: false,
      }
    }
    case UPLOAD_FILES_FAILURE: {
      const { questionID, error } = action.payload
      const attachmentAnswer = { [questionID]: { loading: false, error } }
      return {
        ...state,
        attachmentAnswers: mergeDeepLeft(attachmentAnswer, state.attachmentAnswers),
      }
    }

    case REMOVE_UPLOADED_FILE: {
      const { questionID, fileID } = action.payload
      const files = state.attachmentAnswers[questionID].files.filter(
        (file) => file.fileId !== fileID
      )
      const attachmentAnswer = { [questionID]: { files } }

      return {
        ...state,
        attachmentAnswers: mergeDeepLeft(attachmentAnswer, state.attachmentAnswers),
      }
    }
    case DOWNLOAD_FILE_REQUEST: {
      const { questionID, fileID } = action.payload
      const files = state.attachmentAnswers[questionID].files
      const attachmentAnswer = {
        [questionID]: {
          files: setPropsToUploadedFile(files, fileID, { downloading: true }),
          error: '',
        },
      }

      return {
        ...state,
        attachmentAnswers: mergeDeepLeft(attachmentAnswer, state.attachmentAnswers),
      }
    }
    case DOWNLOAD_FILE_SUCCESS: {
      const { questionID, fileID } = action.payload
      const files = state.attachmentAnswers[questionID].files
      const attachmentAnswer = {
        [questionID]: { files: setPropsToUploadedFile(files, fileID, { downloading: false }) },
      }

      return {
        ...state,
        attachmentAnswers: mergeDeepLeft(attachmentAnswer, state.attachmentAnswers),
      }
    }
    case DOWNLOAD_FILE_FAILURE: {
      const { questionID, fileID, error } = action.payload
      const files = state.attachmentAnswers[questionID].files
      const attachmentAnswer = {
        [questionID]: {
          files: setPropsToUploadedFile(files, fileID, { downloading: false }),
          error,
        },
      }

      return {
        ...state,
        attachmentAnswers: mergeDeepLeft(attachmentAnswer, state.attachmentAnswers),
      }
    }
    case SET_STUDENT_FILES_MODAL_OPEN:
      return {
        ...state,
        selectedQuestion: action.payload.selectedQuestion,
        version: action.payload.version,
        filesModalOpen: true,
      }
    case SET_STUDENT_FILES_MODAL_CLOSED:
      return { ...state, filesModalOpen: false }
    case getActivityTeacherActionTypes.STUDENT_ACTIVITY_SUCCESS:
    case WRITTEN_EXAM_ACTIVITY_SUCCESS:
    case getActivityStudentActionTypes.ACTIVITY_SUCCESS: {
      const {
        activity: { questions, lastSubmission },
        // @ts-ignore
      } = action.payload
      return {
        ...state,
        attachmentAnswers: getAttachmentAnswersTemplates(questions, lastSubmission?.answers),
      }
    }
    default:
      return state
  }
}

export default root
