import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type { Reducer } from 'redux'
import { Redux } from '@/legacy/redux'
import type {
  OrganizationsDrawerAction,
  OrganizationsDrawerState,
} from '@/sections/eb-admin/management/types/organizationsDrawer'

const initialState: OrganizationsDrawerState = {
  organizations: [],
  loading: false,
  pagination: {
    page: 0,
    rowsPerPage: 12,
    count: 0,
  },
  error: '',
  search: '',
}

const requestDestination = reduxRequestOriginMap.ADMIN_ORGANIZATIONS_DRAWER

const AdminOrganizationsDrawerReducer: Reducer<
  OrganizationsDrawerState,
  OrganizationsDrawerAction
> = (state = initialState, action): OrganizationsDrawerState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case Redux.Types.Organizations.LIST_ORGANIZATIONS_AS_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: initialState.error,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    case Redux.Types.Organizations.LIST_ORGANIZATIONS_AS_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        organizations: action.payload.organizations,
        pagination: {
          ...state.pagination,
          count: action.payload.count,
        },
      }
    case Redux.Types.Organizations.LIST_ORGANIZATIONS_AS_ADMIN_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, AdminOrganizationsDrawerReducer, requestDestination)
