import React, { useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { CircularProgress } from '@mui/material'
import { Error } from '@/legacy/components/v1/atomic/v1/error'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

export default function FileUploader(props) {
  const { loading, dropzoneProps, DropzoneBody, error } = props

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } =
    useDropzone(dropzoneProps)

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...props.baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {loading ? <CircularProgress /> : <DropzoneBody />}
      </div>
      <Error error={error} />
    </div>
  )
}

FileUploader.defaultProps = {
  error: '',
  baseStyle: {},
  DropzoneBody: () => <div />,
}
