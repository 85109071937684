import React from 'react'
import MUITabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { makeStyles } from '@mui/styles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

const useStyles = makeStyles((theme) => ({
  indicator: {
    height: 0,
  },
  selected: {},
  wrapper: {
    flexDirection: 'row',
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 'bold',
    '& :first-child': {
      marginRight: '8px',
      marginBottom: '0px !important',
    },
  },
  tabWrapper: {
    display: 'flex',
  },
  dropdownIcon: {
    alignSelf: 'center',
    position: 'relative',
    left: '-20px',
  },
}))

const WithDropdown = (props) => {
  const classes = useStyles()
  const {
    useDropdown,
    onClick,
    dropdownOpen,
    handleDropDownOpen,
    handleDropDownClose,
    ...propsWithoutDropdown
  } = props
  const Icon = dropdownOpen ? (
    <ArrowDropUpIcon className={classes.dropdownIcon} />
  ) : (
    <ArrowDropDownIcon className={classes.dropdownIcon} />
  )
  const onClickProp = useDropdown
    ? dropdownOpen
      ? handleDropDownClose
      : handleDropDownOpen
    : onClick
  return (
    <div className={classes.tabWrapper}>
      <Tab onClick={onClickProp} {...propsWithoutDropdown} />
      {props.useDropdown ? Icon : null}
    </div>
  )
}

const TabsV2 = (props) => {
  const { tabs, value, onChange, dropdownOpen, handleDropdownOpen, handleDropdownClose } = props

  const classes = useStyles()

  const changeValue = (event, newValue) => onChange(newValue)

  const onMouseEnter = (e) => {
    handleDropdownOpen(e)
  }

  return (
    <MUITabs
      value={value}
      onChange={changeValue}
      classes={{
        indicator: classes.indicator,
      }}
    >
      {tabs.map(({ disabled, Icon, label, value: tabValue, useDropdown, onClick = () => {} }) => (
        <WithDropdown
          key={tabValue}
          classes={{
            wrapper: classes.wrapper,
            selected: classes.selected,
          }}
          onClick={onClick}
          disabled={disabled}
          disableRipple
          icon={<Icon active={value === tabValue} />}
          label={label}
          value={tabValue}
          useDropdown={useDropdown}
          handleDropDownOpen={handleDropdownOpen}
          handleDropDownClose={handleDropdownClose}
          dropdownOpen={dropdownOpen}
          onMouseEnter={useDropdown ? onMouseEnter : () => {}}
        />
      ))}
    </MUITabs>
  )
}

export default TabsV2
