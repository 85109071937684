import { apiPrivate } from './..'
import { url, notifierUrl } from '@aula/config' // TODO: Import notifier url
import type { Pagination } from '@/sections/teacher/overview/types/overview'

export const NotifierAPI = {
  getNotifications,
  getSubscriptions,
  updateSubscription,
  userEmailCheck,
  markAsRead,
  markAllAsRead,
  forwardMessage,
}

/**
 * Get own notifications
 *
 * @role Any
 * @param pagination
 */

function getNotifications(pagination?: Pagination, unreadOnly?: boolean) {
  const requestParams = new URLSearchParams()

  if (pagination?.limit) requestParams.append('limit', pagination.limit.toString())
  if (pagination?.offset) requestParams.append('offset', pagination.offset.toString())
  if (unreadOnly) requestParams.append('read', (!unreadOnly).toString())

  const params = {
    params: requestParams,
  }

  return apiPrivate.get(notifierUrl + '/v1/me/notifications', params)
}

/**
 * Get own subscriptions
 *
 * @role Any
 */

function getSubscriptions() {
  return apiPrivate.get(notifierUrl + '/v1/me/subscriptions')
}

/**
 * Updates a subscription to a specific notification type
 *
 * @role Any
 */
function updateSubscription(
  notificationTypeId: number,
  mobilePush: boolean,
  webPush: boolean,
  email: boolean
) {
  const formData = {
    notificationTypeId: notificationTypeId,
    mobilePush: mobilePush,
    webPush: webPush,
    email,
  }

  return apiPrivate.put(notifierUrl + '/v1/me/subscriptions', formData)
}

/**
 * Finds out whether the user account has an email address associated to it or not.
 *
 * @role Any
 */

function userEmailCheck() {
  return apiPrivate.get(url + '/v1/user/has-mail')
}

/**
 * Marks a notification as read.
 *
 * @role Any
 */

function markAsRead(notifID: number) {
  return apiPrivate.put(notifierUrl + '/v1/me/notifications/read/' + notifID)
}

/**
 * Marks all notifications as read.
 *
 * @role Any
 */

function markAllAsRead() {
  return apiPrivate.put(notifierUrl + '/v1/me/notifications/read/all')
}

/**
 * Notify message again.
 *
 * @role Any
 */
function forwardMessage(messageId: number) {
  const data = {
    messageId: messageId,
    // Allows the backend to validate that forwarding can only be performed by this type of action.
    // A constant is not necessary, but it is necessary if the actions are increasing.
    type: 'bulletin_message',
  }
  return apiPrivate.post(notifierUrl + `/v1/notifications`, data)
}
