import type { AxiosResponse } from 'axios'
import { url } from '@aula/config'
import { formatDocTypesOptions } from '@/legacy/redux/actions/docTypesOptions'
import type { DocTypeOption } from '@/legacy/redux/types/docTypesOptions'
import { apiPublic } from './..'
import type { DescriptionResponseShortener } from '@/api/types'

export const DocTypesAPI = {
  get,
}

interface GetDocTypesResponse {
  docTypesOptions: DocTypeOption[]
}

function get(): Promise<GetDocTypesResponse> {
  return apiPublic
    .get(url + '/v1/login/id-types')
    .then((response: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return { docTypesOptions: formatDocTypesOptions(response.data.description.types) }
    })
    .catch((err) => {
      throw err
    })
}
