import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type {
  ExamsDrawerState,
  ExamsDrawerAction,
} from '@/sections/eb-admin/management/types/examsDrawer'
import {
  LIST_EXAMS_AS_ADMIN_REQUEST,
  LIST_EXAMS_AS_ADMIN_FAILURE,
  LIST_EXAMS_AS_ADMIN_SUCCESS,
  LIST_EXAMS_THEMES_REQUEST,
  LIST_EXAMS_THEMES_SUCCESS,
  LIST_EXAMS_THEMES_FAILURE,
} from '@/sections/eb-admin/management/types/examsDrawer'

const initialState: ExamsDrawerState = {
  exams: [],
  themes: [],
  loadingThemes: false,
  loadingExams: false,
  orderBy: 'datetime',
  orderDirection: 'desc',
  pagination: {
    page: 0,
    rowsPerPage: 12,
    count: 0,
  },
  error: '',
  search: '',
  stage: 0,
}

const requestDestination = reduxRequestOriginMap.ADMIN_EXAMS_DRAWER

const AdminExamsDrawerReducer: Reducer<ExamsDrawerState, ExamsDrawerAction> = (
  state = initialState,
  action
): ExamsDrawerState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case LIST_EXAMS_AS_ADMIN_REQUEST:
      return {
        ...state,
        loadingExams: true,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    case LIST_EXAMS_AS_ADMIN_SUCCESS:
      return {
        ...state,
        loadingExams: false,
        exams: action.payload.exams,
        pagination: {
          ...state.pagination,
          count: action.payload.count,
        },
      }
    case LIST_EXAMS_AS_ADMIN_FAILURE:
      return { ...state, loadingExams: false, error: action.payload.error }
    case LIST_EXAMS_THEMES_REQUEST:
      return {
        ...state,
        loadingThemes: true,
      }
    case LIST_EXAMS_THEMES_SUCCESS:
      return {
        ...state,
        loadingThemes: false,
        themes: action.payload.themes,
      }
    case LIST_EXAMS_THEMES_FAILURE:
      return { ...state, loadingThemes: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, AdminExamsDrawerReducer, requestDestination)
