import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/principal/documentation/types/documentationTable'

const initialState: Types.DocumentationTableState = {
  status: REQUEST_STATUS.IDLE,
  documents: [],
  pagination: {
    more: false,
    count: 0,
    rowsPerPage: 10,
    page: 0,
  },
}

const documentationTableReducer = (
  state: Types.DocumentationTableState = initialState,
  action: Types.DocumentationTableAction
): Types.DocumentationTableState => {
  switch (action.type) {
    case Types.DOCUMENTATION_TABLE_TYPES.GET_DOCUMENTATION_LIST_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.DOCUMENTATION_TABLE_TYPES.GET_DOCUMENTATION_LIST_SUCCESS: {
      const { documents, more, count, newPage } = action.payload
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        documents,
        pagination: {
          ...state.pagination,
          more,
          count,
          page: newPage,
        },
      }
    }
    case Types.DOCUMENTATION_TABLE_TYPES.GET_DOCUMENTATION_LIST_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.DOCUMENTATION_TABLE_TYPES.CLEAN_DOCUMENTATION_LIST: {
      return initialState
    }
    default:
      return state
  }
}

export default documentationTableReducer
