import type { OrganizationOption } from './common'

export const SET_OPEN = 'IMPORT_ORGANIZATION/SET_OPEN'
export const SET_APPEND_OPEN = 'IMPORT_ORGANIZATION/SET_APPEND_OPEN'
export const SET_FILE = 'IMPORT_ORGANIZATION/SET_FILE'
export const SET_ALLOW_EXISTING_USERS = 'IMPORT_ORGANIZATION/SET_ALLOW_EXISTING_USERS'
export const SET_CREATE_MISSING_STUDENTS = 'IMPORT_ORGANIZATION/SET_CREATE_MISSING_STUDENTS'
export const SET_CREATE_MISSING_TEACHERS = 'IMPORT_ORGANIZATION/SET_CREATE_MISSING_TEACHERS'
export const SET_CREATE_MISSING_CLASSROOMS = 'IMPORT_ORGANIZATION/SET_CREATE_MISSING_CLASSROOMS'
export const SET_ORGANIZATION = 'IMPORT_ORGANIZATION/SET_ORGANIZATION'
export const PROCESS_ORGANIZATION_REQUEST = 'IMPORT_ORGANIZATION/PROCESS_ORGANIZATION_REQUEST'
export const PROCESS_ORGANIZATION_SUCCESS = 'IMPORT_ORGANIZATION/PROCESS_ORGANIZATION_SUCCESS'
export const PROCESS_ORGANIZATION_FAILURE = 'IMPORT_ORGANIZATION/PROCESS_ORGANIZATION_FAILURE'
export const APPEND_ORGANIZATION_REQUEST = 'IMPORT_ORGANIZATION/APPEND_ORGANIZATION_REQUEST'
export const APPEND_ORGANIZATION_SUCCESS = 'IMPORT_ORGANIZATION/APPEND_ORGANIZATION_SUCCESS'
export const APPEND_ORGANIZATION_FAILURE = 'IMPORT_ORGANIZATION/APPEND_ORGANIZATION_FAILURE'
export const ORGANIZATIONS_REQUEST = 'IMPORT_ORGANIZATION/ORGANIZATIONS_REQUEST'
export const ORGANIZATIONS_SUCCESS = 'IMPORT_ORGANIZATION/ORGANIZATIONS_SUCCESS'
export const ORGANIZATIONS_FAILURE = 'IMPORT_ORGANIZATION/ORGANIZATIONS_FAILURE'

export interface ImportOrganizationState {
  open: boolean
  appendOpen: boolean
  file: File | null
  allowExistingUsers: boolean
  createMissingStudents: boolean
  createMissingTeachers: boolean
  createMissingClassrooms: boolean
  loading: boolean
  error: string
  organization: OrganizationOption | null
  organizationOptions: OrganizationOption[]
  loadingOrganizations: boolean
  lastOrganizationID: number
}

export interface ImportOrganizationRequest {
  type: typeof PROCESS_ORGANIZATION_REQUEST
}

export interface ImportOrganizationSuccess {
  type: typeof PROCESS_ORGANIZATION_SUCCESS
}

export interface ImportOrganizationFailure {
  type: typeof PROCESS_ORGANIZATION_FAILURE
  payload: {
    error: string
  }
}

export interface AppendOrganizationRequest {
  type: typeof APPEND_ORGANIZATION_REQUEST
}

export interface AppendOrganizationSuccess {
  type: typeof APPEND_ORGANIZATION_SUCCESS
}

export interface AppendOrganizationFailure {
  type: typeof APPEND_ORGANIZATION_FAILURE
  payload: {
    error: string
  }
}

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    open: boolean
  }
}

export interface SetAppendOpen {
  type: typeof SET_APPEND_OPEN
  payload: {
    open: boolean
  }
}

export interface SetFile {
  type: typeof SET_FILE
  payload: {
    file: File
  }
}

export interface SetOrganization {
  type: typeof SET_ORGANIZATION
  payload: {
    index?: number
    organization: OrganizationOption
  }
}

export interface OrganizationsRequest {
  type: typeof ORGANIZATIONS_REQUEST
  payload: {
    index?: number
    lastSearchID: number
  }
}

export interface OrganizationsSuccess {
  type: typeof ORGANIZATIONS_SUCCESS
  payload: {
    index?: number
    organizationOptions: OrganizationOption[]
  }
}

export interface OrganizationsFailure {
  type: typeof ORGANIZATIONS_FAILURE
  payload: {
    error: string
  }
}

export interface SetAllowExistingUsers {
  type: typeof SET_ALLOW_EXISTING_USERS
  payload: {
    allowExistingUsers: boolean
  }
}
export interface SetCreateMissingStudents {
  type: typeof SET_CREATE_MISSING_STUDENTS
  payload: {
    createMissingStudents: boolean
  }
}
export interface SetCreateMissingTeachers {
  type: typeof SET_CREATE_MISSING_TEACHERS
  payload: {
    createMissingTeachers: boolean
  }
}
export interface SetCreateMissingClassrooms {
  type: typeof SET_CREATE_MISSING_CLASSROOMS
  payload: {
    createMissingClassrooms: boolean
  }
}

export type ImportOrganizationActions =
  | ImportOrganizationRequest
  | ImportOrganizationSuccess
  | ImportOrganizationFailure
  | AppendOrganizationRequest
  | AppendOrganizationSuccess
  | AppendOrganizationFailure
  | SetOpen
  | SetAppendOpen
  | SetFile
  | SetAllowExistingUsers
  | SetCreateMissingStudents
  | SetCreateMissingTeachers
  | SetCreateMissingClassrooms
  | SetOrganization
  | OrganizationsRequest
  | OrganizationsSuccess
  | OrganizationsFailure
