import { ApiPrivate } from '@/legacy/services/api/api'
import { url } from '@aula/config'

export function getStudentSubmissions(classroomId: number, role: string, studentId?: number) {
  const params = {
    classroomId,
    studentId,
  }

  return ApiPrivate.get(url + `/v1/${role}/submissions-count`, { params })
    .then((response) => {
      return {
        submissions: response.data,
      }
    })
    .catch((err) => {
      throw err
    })
}
