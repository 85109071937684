import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

// State.
export interface InitialStepState {
  tagCategoriesInfo: {
    status: REQUEST_STATUS
    categories: TagCategory[]
  }
  form: {
    title: string
    cover: File | string | null
    file: File | string | null
    description: string
    selectedTagsInfo: SelectedTagsInfo
    availableInLibrary: boolean
  }
}

type SelectedTagsInfo = {
  [key in TAG_CATEGORY_NAME]?: Tag[]
}

export enum TAG_CATEGORY_NAME {
  SUBJECT = 'Materia / Área',
  AGE = 'Edad',
  COMPETENCES = 'Competencias',
  MODALITY = 'Modalidad',
  ACTIVITY_TYPE = 'Tipo de Actividad',
  RESOURCES = 'Recursos',
  GROUP_DISPOSITION = 'Disposición grupal',
  COLLECTION = 'Colección',
  AVAILABILITY = 'Disponibilidad',
  EXPERIENCE = 'Experiencia',
  CONCEPTS_COVERED = 'Conceptos abordados',
}

export interface Tag {
  id: number
  name: string
}

export interface TagCategory {
  name: TAG_CATEGORY_NAME
  tags: Tag[]
}

// Types.
export enum INITIAL_STEP_TYPES {
  GET_TAGS_REQUEST = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/INITIAL/GET_TAGS_REQUEST',
  GET_TAGS_SUCCESS = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/INITIAL/GET_TAGS_SUCCESS',
  GET_TAGS_FAILURE = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/INITIAL/GET_TAGS_FAILURE',
  CLEAN_INITIAL_STEP = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/INITIAL/CLEAN_INITIAL_STEP',
  SET_TITLE = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/INITIAL/SET_TITLE',
  SET_COVER = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/INITIAL/SET_COVER',
  SET_FILE = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/INITIAL/SET_FILE',
  SET_DESCRIPTION = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/INITIAL/SET_DESCRIPTION',
  SET_AVAILABLE_IN_LIBRARY = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/INITIAL/SET_AVAILABLE_IN_LIBRARY',
  SET_SELECTED_TAGS_INFO = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/INITIAL/SET_SELECTED_TAGS_INFO',
  TOGGLE_AVAILABLE_LIBRARY = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/STEPS/INITIAL/TOGGLE_AVAILABLE_LIBRARY',
}

// Get tags actions.
export type GetTagsRequestAction = {
  type: INITIAL_STEP_TYPES.GET_TAGS_REQUEST
}

export type ToggleAvailableLibraryAction = {
  type: INITIAL_STEP_TYPES.TOGGLE_AVAILABLE_LIBRARY
}

export type GetTagsSuccessAction = {
  type: INITIAL_STEP_TYPES.GET_TAGS_SUCCESS
  payload: {
    categories: TagCategory[]
  }
}

export type GetTagsFailureAction = {
  type: INITIAL_STEP_TYPES.GET_TAGS_FAILURE
}

export type GetTagsAction = GetTagsRequestAction | GetTagsFailureAction | GetTagsSuccessAction

// Clean initial step action.
export type CleanInitialStepAction = {
  type: INITIAL_STEP_TYPES.CLEAN_INITIAL_STEP
}

// Set title action.
export type SetTitleAction = {
  type: INITIAL_STEP_TYPES.SET_TITLE
  payload: {
    newTitle: string
  }
}

// Set available in library action.
export type SetAvailableInLibraryAction = {
  type: INITIAL_STEP_TYPES.SET_AVAILABLE_IN_LIBRARY
  payload: {
    availableInLibrary: boolean
  }
}

// Set cover action.
export type SetCoverAction = {
  type: INITIAL_STEP_TYPES.SET_COVER
  payload: {
    cover: File | string | null
  }
}

// Set file action.
export type SetFileAction = {
  type: INITIAL_STEP_TYPES.SET_FILE
  payload: {
    file: File | string | null
  }
}

// Set description action.
export type SetDescriptionAction = {
  type: INITIAL_STEP_TYPES.SET_DESCRIPTION
  payload: {
    newDescription: string
  }
}

// Set selected tags info action.
export type SetSelectedTagsInfoAction = {
  type: INITIAL_STEP_TYPES.SET_SELECTED_TAGS_INFO
  payload: {
    selectedTags: Tag[]
    tagCategoryName: TAG_CATEGORY_NAME
  }
}

// Action.
export type InitialStepAction =
  | GetTagsAction
  | CleanInitialStepAction
  | SetTitleAction
  | SetCoverAction
  | SetFileAction
  | SetDescriptionAction
  | SetSelectedTagsInfoAction
  | SetAvailableInLibraryAction
  | ToggleAvailableLibraryAction
