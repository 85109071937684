import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type {
  MessageThreadsActions,
  MessageThreadsState,
} from '@/sections/teacher/bulletin/types/messageThreads'
import { MESSAGE_THREADS_TYPES } from '@/sections/teacher/bulletin/types/messageThreads'

const initialState: MessageThreadsState = {
  error: '',
  isMessageThreadDeleted: false,
  loading: true,
  status: REQUEST_STATUS.IDLE,
  total: 0,
  replies: [],
  cancelToken: null,
  messageSelectedId: 0,
  classroomName: '',
}

export default function messageThreadsReducer(
  state: MessageThreadsState = initialState,
  action: MessageThreadsActions
): MessageThreadsState {
  switch (action.type) {
    case MESSAGE_THREADS_TYPES.GET_MESSAGE_THREADS: {
      return {
        ...state,
        loading: true,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case MESSAGE_THREADS_TYPES.GET_MESSAGE_THREADS_SUCCESS: {
      const { total, replies, classroomName } = action.payload
      return {
        ...state,
        total,
        replies,
        loading: false,
        status: REQUEST_STATUS.SUCCESS,
        classroomName,
      }
    }
    case MESSAGE_THREADS_TYPES.GET_MESSAGE_THREADS_FAILURE: {
      const { error, isDeleted } = action.payload
      return {
        ...state,
        error,
        isMessageThreadDeleted: isDeleted,
        loading: false,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case MESSAGE_THREADS_TYPES.SET_MESSAGE_THREADS_CANCEL_TOKEN: {
      const { token } = action.payload
      return {
        ...state,
        cancelToken: token,
      }
    }
    case MESSAGE_THREADS_TYPES.SET_MESSAGE_SELECTED_ID: {
      const { messageSelectedId } = action.payload
      return {
        ...state,
        messageSelectedId,
      }
    }
    case MESSAGE_THREADS_TYPES.CLEAN_MESSAGE_THREADS: {
      return initialState
    }
    default:
      return state
  }
}
