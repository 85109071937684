import Services from '@/legacy/services/api'
import type { GetClassroomsAsAdminParams } from '@/legacy/services/api/lms/classrooms/types'
import type { AppThunk } from '@/state/thunk'
import * as Types from '@/sections/eb-admin/management/types/classroomsDrawer'

const AdminClassroomsDrawerActionCreators = {
  clearState: (): Types.ClearState => ({
    type: Types.CLEAR_STATE,
  }),
  setPaginationOffset: (offset: number): Types.SetPaginationOffset => ({
    type: Types.SET_PAGINATION_OFFSET,
    payload: { offset },
  }),
  setSearch: (search: string): Types.SetSearch => ({
    type: Types.SET_SEARCH,
    payload: { search },
  }),
  getClassrooms:
    (params: GetClassroomsAsAdminParams): AppThunk =>
    async (dispatch) => {
      const request: Types.GetClassroomsRequest = {
        type: Types.GET_CLASSROOMS_REQUEST,
      }
      dispatch(request)

      try {
        const { rows, total } = await Services.Lms.Classrooms.getAsAdmin(params)

        const success: Types.GetClassroomsSuccess = {
          type: Types.GET_CLASSROOMS_SUCCESS,
          payload: {
            classrooms: rows,
            count: total,
          },
        }

        dispatch(success)
      } catch (error) {
        const failure: Types.GetClassroomsFailure = {
          type: Types.GET_CLASSROOMS_FAILURE,
        }

        dispatch(failure)
      }
    },
}

export default AdminClassroomsDrawerActionCreators
