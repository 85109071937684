import fr from '@/locales/translations/fr/fr.json'
import common from '@/locales/translations/fr/common.json'
import glossary from '@/locales/translations/fr/glossary.json'
import validation from '@/locales/translations/fr/validation.json'

export default {
  translation: fr,
  common: common,
  glossary: glossary,
  validation: validation,
}
