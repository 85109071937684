import type { BulletinPeriod } from '@/api/report-card/periods'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export interface PeriodsState {
  status: REQUEST_STATUS
  data: BulletinPeriod[]
  selectedPeriod: BulletinPeriod
}

export enum PERIODS_TYPES {
  GET_PERIODS_REQUEST = 'STUDENT/SUMMARY/TABS/ACTIVITIES/PERIODS/GET_PERIODS_REQUEST',
  GET_PERIODS_SUCCESS = 'STUDENT/SUMMARY/TABS/ACTIVITIES/PERIODS/GET_PERIODS_SUCCESS',
  GET_PERIODS_FAILURE = 'STUDENT/SUMMARY/TABS/ACTIVITIES/PERIODS/GET_PERIODS_FAILURE',
  SET_SELECTED_PERIOD = 'STUDENT/SUMMARY/TABS/ACTIVITIES/PERIODS/SET_SELECTED_PERIOD',
  CLEAN_PERIODS = 'STUDENT/SUMMARY/TABS/ACTIVITIES/PERIODS/CLEAN_PERIODS',
}

// Get periods.
export type GetPeriodsRequestAction = {
  type: PERIODS_TYPES.GET_PERIODS_REQUEST
}

export type GetPeriodsSuccessAction = {
  type: PERIODS_TYPES.GET_PERIODS_SUCCESS
  payload: {
    data: BulletinPeriod[]
  }
}

export type GetPeriodsFailureAction = {
  type: PERIODS_TYPES.GET_PERIODS_FAILURE
}

export type GetPeriodsAction =
  | GetPeriodsRequestAction
  | GetPeriodsSuccessAction
  | GetPeriodsFailureAction

// Set selected period.
export type SetSelectedPeriodAction = {
  type: PERIODS_TYPES.SET_SELECTED_PERIOD
  payload: {
    newSelectedPeriod: BulletinPeriod
  }
}

// Clean periods.
export type CleanPeriodsAction = {
  type: PERIODS_TYPES.CLEAN_PERIODS
}

export type PeriodsAction = GetPeriodsAction | SetSelectedPeriodAction | CleanPeriodsAction
