import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type {
  EditUserProfileActions,
  EditUserProfileState,
} from '@/sections/user/profile/types/editProfile'
import {
  SET_PASSWORD,
  SET_REPEAT_PASSWORD,
  UPDATE_USER_PASSWORD_FAILURE,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPLOAD_AVATAR_FAILURE,
  UPLOAD_AVATAR_REQUEST,
  UPLOAD_AVATAR_SUCCESS,
} from '@/sections/user/profile/types/editProfile'

const initialState: EditUserProfileState = {
  file: null,
  loading: false,
  error: '',
  currentPassword: '',
  newPassword: '',
  repeatPassword: '',
  cancelToken: null,
}

const requestDestination = reduxRequestOriginMap.USER_PROFILE

const EditUserProfileReducer: Reducer<EditUserProfileState, EditUserProfileActions> = (
  state = initialState,
  action
): EditUserProfileState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case SET_PASSWORD:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        newPassword: action.payload.password,
      }
    case SET_REPEAT_PASSWORD:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        repeatPassword: action.payload.repeatPassword,
      }
    case UPDATE_USER_PASSWORD_REQUEST:
      return { ...state, loading: true, error: initialState.error }
    case UPLOAD_AVATAR_REQUEST: {
      const { cancelToken } = action.payload
      return {
        ...state,
        loading: true,
        cancelToken,
        error: initialState.error,
      }
    }
    case UPDATE_USER_PASSWORD_SUCCESS:
    case UPLOAD_AVATAR_SUCCESS:
      return { ...state, loading: false, file: null }
    case UPDATE_USER_PASSWORD_FAILURE:
    case UPLOAD_AVATAR_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, EditUserProfileReducer, requestDestination)
