import type { Audience } from '@/api/lms/library'
import type { BasicAudience } from '@/api/lms/organizations'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export interface AudienceModalState {
  isOpenModal: boolean
  isEditMode: boolean
  audiences: Audience[]
  status: REQUEST_STATUS
  searchText: string
  pagination: {
    count: number
    more: boolean
    page: number
    rowsPerPage: number
  }
  selectedAudiences: BasicAudience[]
}

export enum AUDIENCE_MODAL_TYPES {
  OPEN_MODAL = 'MANAGEMENT/AUDIENCE_MODAL/OPEN_MODAL',
  CLOSE_MODAL = 'MANAGEMENT/AUDIENCE_MODAL/CLOSE_MODAL',
  SET_SEARCH_TEXT = 'MANAGEMENT/AUDIENCE_MODAL/SET_SEARCH_TEXT',
  GET_AUDIENCES_REQUEST = 'MANAGEMENT/AUDIENCE_MODAL/GET_AUDIENCES_REQUEST',
  GET_AUDIENCES_SUCCESS = 'MANAGEMENT/AUDIENCE_MODAL/GET_AUDIENCES_SUCCESS',
  GET_AUDIENCES_FAILURE = 'MANAGEMENT/AUDIENCE_MODAL/GET_AUDIENCES_FAILURE',
  SET_SELECTED_AUDIENCES = 'MANAGEMENT/AUDIENCE_MODAL/SET_SELECTED_AUDIENCES',
  CLEAN_MODAL = 'MANAGEMENT/AUDIENCE_MODAL/CLEAN_MODAL',
}

// Open modal action.
export type OpenModalAction = {
  type: AUDIENCE_MODAL_TYPES.OPEN_MODAL
}

// Close modal action.
export type CloseModalAction = {
  type: AUDIENCE_MODAL_TYPES.CLOSE_MODAL
}

// Set search text action.
export type SetSearchTextAction = {
  type: AUDIENCE_MODAL_TYPES.SET_SEARCH_TEXT
  payload: {
    newSearchText: string
  }
}

// Get audience actions.
export type GetAudiencesRequestAction = {
  type: AUDIENCE_MODAL_TYPES.GET_AUDIENCES_REQUEST
}

export type GetAudiencesSuccessAction = {
  type: AUDIENCE_MODAL_TYPES.GET_AUDIENCES_SUCCESS
  payload: {
    audiences: Audience[]
    count: number
    more: boolean
    page: number
  }
}

export type GetAudiencesFailureAction = {
  type: AUDIENCE_MODAL_TYPES.GET_AUDIENCES_FAILURE
}

export type GetAudiencesAction =
  | GetAudiencesRequestAction
  | GetAudiencesSuccessAction
  | GetAudiencesFailureAction

// Set selected audiences action.
export type SetSelectedAudiencesAction = {
  type: AUDIENCE_MODAL_TYPES.SET_SELECTED_AUDIENCES
  payload: {
    newSelectedAudiences: BasicAudience[]
  }
}

// Clean modal action.
export type CleanModalAction = {
  type: AUDIENCE_MODAL_TYPES.CLEAN_MODAL
}

export type AudienceModalAction =
  | OpenModalAction
  | CloseModalAction
  | SetSearchTextAction
  | GetAudiencesAction
  | SetSelectedAudiencesAction
  | CleanModalAction
