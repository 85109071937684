import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { SET_FIELD } from '@/legacy/redux/types/generic'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type {
  FinalAppreciationsActions,
  FinalAppreciationsState,
} from '@/sections/report-card/grading/types/tabs/finalAppreciations'
import {
  GET_FINAL_APPRECIATIONS_FAILURE,
  GET_FINAL_APPRECIATIONS_REQUEST,
  GET_FINAL_APPRECIATIONS_SUCCESS,
  SET_SELECTED_STUDENT_INDEX,
  UPDATE_FINAL_APPRECIATION_FAILURE,
  UPDATE_FINAL_APPRECIATION_REQUEST,
  UPDATE_FINAL_APPRECIATION_SUCCESS,
} from '@/sections/report-card/grading/types/tabs/finalAppreciations'

const initialState: FinalAppreciationsState = {
  loading: false,
  studentFinalAppreciations: [],
  selectedStudentIndex: 0,
  isNext: true,
}

const ReportCardFinalAppreciationsReducer = (
  state: FinalAppreciationsState = initialState,
  action: FinalAppreciationsActions
) => {
  switch (action.type) {
    case SET_FIELD:
      return setGenericField(state, action.payload)
    case SET_SELECTED_STUDENT_INDEX:
      return { ...state, selectedStudentIndex: action.payload.selectedStudentIndex }
    case GET_FINAL_APPRECIATIONS_REQUEST:
    case UPDATE_FINAL_APPRECIATION_REQUEST:
      return { ...state, loading: true }
    case GET_FINAL_APPRECIATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        studentFinalAppreciations: action.payload.studentFinalAppreciations,
      }
    case UPDATE_FINAL_APPRECIATION_SUCCESS:
      return { ...state, loading: false }
    case GET_FINAL_APPRECIATIONS_FAILURE:
    case UPDATE_FINAL_APPRECIATION_FAILURE:
      return { ...state, loading: false }
    default:
      return state
  }
}

export default checkRequestOrigin(
  initialState,
  ReportCardFinalAppreciationsReducer,
  reduxRequestOriginMap.REPORT_CARD_GRADING_FINAL_APPRECIATIONS
)
