import type {
  ClosePopup,
  OpenPopup,
  VideocallOrigin,
  LeaveVideocallReasons,
} from '@/sections/videocall/types/videocall'
import { SET_POPUP_CLOSED, SET_POPUP_OPEN } from '@/sections/videocall/types/videocall'

const actions = {
  setPopupOpen: (
    popupUrl: string,
    videoTitle: string,
    videocallOrigin: VideocallOrigin
  ): OpenPopup => ({ type: SET_POPUP_OPEN, payload: { popupUrl, videoTitle, videocallOrigin } }),
  setPopupClosed: (reason: LeaveVideocallReasons): ClosePopup => ({
    type: SET_POPUP_CLOSED,
    payload: { reason },
  }),
}

export default actions
