import React, { useRef, useEffect } from 'react'
import { IconButton, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ClassroomTile from './classroomTile'
import Arrow from '@/legacy/components/v1/svgs/arrow'
import { isEmpty, isNil } from 'ramda'
import CircularProgress from '@mui/material/CircularProgress'

const ARROWS_CONTAINER_LENGTH = 140

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: 'fit-content',
  },
  gridList: {
    overflow: 'hidden',
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    padding: '16px 16px 8px 8px',
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.07)',
    backgroundColor: '#fff',
    '&::-webkit-scrollbar': {
      height: 8,
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#d3d3d3',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#b4b4b4',
    },
  },
  tile: {
    padding: '0 8px! important',
  },
  ghostTile: {
    width: ARROWS_CONTAINER_LENGTH,
  },
  arrowContainer: ({ withArrows }) => ({
    position: 'absolute',
    width: ARROWS_CONTAINER_LENGTH,
    background: 'linear-gradient(270deg, #FFFFFF 76.73%, rgba(255, 255, 255, 0) 100%);',
    right: 0,
    top: 0,
    bottom: 0,
    display: withArrows ? 'flex' : 'none',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }),
  arrows: {
    paddingRight: 26,
    '& :not(:last-child)': {
      marginRight: 7,
    },
  },
  leftArrow: {
    transform: 'rotate(-180deg)',
  },
  arrowRoot: {
    width: 24,
    height: 24,
    borderRadius: 3,
    background: 'white',
    border: '1px solid #DFDFDA',
    padding: 0,
    '&:hover': {
      background: 'rgba(0,0,0,0)',
      '& span svg path': {
        fill: theme.palette.primary.main,
      },
    },
    '&:active': {
      background: 'rgba(0,0,0,0)',
      '& span svg path': {
        fill: theme.palette.primary.main,
      },
    },
    '& span svg': {
      width: 15,
    },
    '& span svg path': {
      fill: theme.palette.primary.main,
    },
  },
  loadingContainer: {
    top: 0,
    background: 'rgba(255,255,255,0.8)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    minHeight: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
  },
}))

export default function ClassroomList(props) {
  const { onClassroomClick, classrooms, loading, onMouseLeave } = props

  const gridListRef = useRef(null)
  const refs = useRef([])

  useEffect(() => {
    refs.current = refs.current.slice(0, classrooms.length)
  }, [classrooms])

  /**
   * Scroll back function
   *
   * it will scroll 2 tiles back if it can
   * if not, it will scroll 1 tile
   * if we are in the beginning it won't do anything
   *
   */

  const onBack = () => {
    for (let i = 0; i < refs.current.length; i++) {
      const x = refs.current[i].getBoundingClientRect().x

      if (x > 0) {
        if (i >= 2) refs.current[i - 2].scrollIntoView({ behavior: 'smooth' })
        if (i === 1) refs.current[i - 1].scrollIntoView({ behavior: 'smooth' })
        return
      }
    }
  }

  /**
   * Scroll next function
   *
   * it will scroll 2 tiles next if it can
   * if not, it will scroll 1 tile
   * if we are in the end it won't do anything
   *
   */

  const onNext = () => {
    const gridWidth = gridListRef.current.getBoundingClientRect().width
    const refsLength = refs.current.length

    for (let i = 0; i < refsLength; i++) {
      const { x, width } = refs.current[i].getBoundingClientRect()
      const offset = x + width
      if (offset > gridWidth) {
        if (i + 1 < refsLength) return refs.current[i + 1].scrollIntoView({ behavior: 'smooth' })
        if (i < refsLength) return refs.current[i].scrollIntoView({ behavior: 'smooth' })
        return
      }
    }
  }

  const shouldArrowsVisible = () => {
    if (isEmpty(refs.current) || isNil(gridListRef.current)) return false
    const gridWidth = gridListRef.current.getBoundingClientRect().width

    return refs.current.some((ref) => {
      if (isNil(ref)) return false
      const { x, width } = ref.getBoundingClientRect()
      const offset = x + width
      return offset > gridWidth
    })
  }

  // Without timeout, arrows won't show on popover
  const withArrows = setTimeout(() => shouldArrowsVisible())

  const classes = useStyles({ withArrows })

  return (
    <div className={classes.container} onMouseLeave={onMouseLeave}>
      <Stack ref={gridListRef} className={classes.gridList} cols={0} cellHeight={120} spacing={0}>
        {classrooms.map((tile, i) => (
          <div
            ref={(el) => {
              refs.current[i] = el
            }}
            key={tile.id}
            className={classes.tile}
          >
            <ClassroomTile
              stageName={tile.stageName}
              classroomName={tile.classroomName}
              schoolName={tile.schoolName}
              activityCount={tile.activityCount}
              onClick={() => onClassroomClick(tile.id)}
            />
          </div>
        ))}
        {withArrows && (
          <div
            ref={(el) => {
              refs.current[refs.current.length - 1] = el
            }}
            key={refs.current.length}
            className={classes.ghostTile}
          />
        )}
      </Stack>
      <div className={classes.arrowContainer}>
        <div className={classes.arrows}>
          <IconButton disableRipple classes={{ root: classes.arrowRoot }} onClick={onBack}>
            <Arrow className={classes.leftArrow} />
          </IconButton>
          <IconButton disableRipple classes={{ root: classes.arrowRoot }} onClick={onNext}>
            <Arrow />
          </IconButton>
        </div>
      </div>
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress color="secondary" size="36px" variant="indeterminate" />
        </div>
      )}
    </div>
  )
}

ClassroomList.defaultProps = {
  onMouseLeave: () => {},
}
