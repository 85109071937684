import type { Reducer } from 'redux'
import { Redux } from '@/legacy/redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import { ScoreType } from '@/sections/report-card/types/common'
import * as Types from '@/sections/report-card/grading/types/gradingForms/attitudinalScores'
import { FIND_CLASSROOM_REPORT_CARD_CONFIG_SUCCESS } from '@/sections/report-card/grading/types/mainView'

const initialState: Types.AttitudinalScoresGradingFormState = {
  attitudinalScoreValues: [],
  scoreType: ScoreType.NUMERICAL,
  conceptScoreValues: [],
  numericalScoreValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  loading: false,
  error: '',
  currentValue: 0,
  mainValueAlreadyExists: false,
  subitems: [],
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_GRADING_ATTITUDINAL_SCORES_FORM

const ReportCardGradingAttitudinalScoresForm: Reducer<
  Types.AttitudinalScoresGradingFormState,
  Types.AttitudinalScoresGradingFormAction
> = (state = initialState, action): Types.AttitudinalScoresGradingFormState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case Redux.Types.Generic.SET_FIELD:
      return setGenericField(state, action.payload)
    case FIND_CLASSROOM_REPORT_CARD_CONFIG_SUCCESS: {
      const conceptScoresValues = action.payload.config?.scores.conceptScores.values
      return {
        ...state,
        conceptScoreValues: conceptScoresValues || initialState.conceptScoreValues,
        currentValue: conceptScoresValues?.length
          ? conceptScoresValues[0].id
          : initialState.currentValue,
        attitudinalScoreValues: action.payload.config?.attitudinalScores?.values ?? [],
      }
    }
    case Types.INIT_STUDENT_ATTITUDINAL_SCORE: {
      const { score, subject } = action.payload

      const subitems = subject.subitems.map((subjectSubitem) => {
        const foundScore = score?.subitems?.find((si) => si.name === subjectSubitem.name)
        return {
          ...subjectSubitem,
          value: foundScore?.value ?? 0,
          score: foundScore?.score ?? '',
          valueId: foundScore?.valueId ?? 0,
        }
      })

      return {
        ...state,
        subitems,
        scoreType: subject.scoreType,
        currentValue: score?.valueId || initialState.currentValue,
        mainValueAlreadyExists: Boolean(score?.valueId),
      }
    }
    case Types.SET_ATTITUDINAL_SUBITEM_SCORE: {
      const { subitems, scoreType } = state

      let newSubitems: Types.AttitudinalSubitem[]

      if (scoreType === ScoreType.CONCEPTUAL) {
        newSubitems = subitems.map((si) => {
          if (si.orderValue === action.payload.orderValue) {
            return {
              ...si,
              valueId: action.payload.value || 0,
              score: state.conceptScoreValues.find((csv) => csv.name)?.name || '',
            }
          } else {
            return si
          }
        })
      } else {
        newSubitems = subitems.map((si) => {
          if (si.orderValue === action.payload.orderValue) {
            return {
              ...si,
              value: action.payload.value || 0,
            }
          } else {
            return si
          }
        })
      }

      return {
        ...state,
        subitems: newSubitems,
      }
    }
    case Types.SAVE_ATTITUDINAL_SCORE_REQUEST:
    case Types.UPDATE_ATTITUDINAL_SCORE_REQUEST:
      return { ...state, loading: true, error: initialState.error }
    case Types.SAVE_ATTITUDINAL_SCORE_SUCCESS:
    case Types.UPDATE_ATTITUDINAL_SCORE_SUCCESS:
      return { ...state, loading: false }
    case Types.SAVE_ATTITUDINAL_SCORE_FAILURE:
    case Types.UPDATE_ATTITUDINAL_SCORE_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(
  initialState,
  ReportCardGradingAttitudinalScoresForm,
  requestDestination
)
