import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import type { ModalButtonProps } from './ModalActionButton'
import ModalActionButton from './ModalActionButton'

type StyleProps = {
  vertical: boolean
}

const useStyles = makeStyles(() => ({
  wrapper: ({ vertical }: StyleProps) => ({
    display: 'flex',
    width: '100%',
    minWidth: 200,
    justifyContent: vertical ? 'center' : 'flex-end',
    alignItems: 'center',
    padding: 30,
    boxSizing: 'border-box',
  }),
  buttonsContainer: ({ vertical }: StyleProps) => ({
    display: 'flex',
    flexDirection: vertical ? 'column' : 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
    flexWrap: 'wrap',
  }),
}))

interface ButtonsProps {
  buttons: ModalButtonProps[]
  vertical?: boolean
  classes?: {
    wrapper?: string
    container?: string
  }
}

const Buttons = (props: ButtonsProps) => {
  const { buttons, classes, vertical = false } = props
  const C = useStyles({ vertical })

  if (buttons.length > 0) {
    return (
      <div className={clsx(C.wrapper, classes?.wrapper)}>
        <div className={clsx(C.buttonsContainer, classes?.container)}>
          {buttons.map((btn) => {
            return <ModalActionButton {...btn} />
          })}
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default Buttons
