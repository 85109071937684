import type { ActivitySuccess as WrittenExamActivitySuccess } from '@/sections/student/writtenExam/types/writtenExam'

export const ADD_FILES_TO_UPLOAD = 'STUDENT_FILES/ADD_FILES_TO_UPLOAD'
export const REMOVE_FILE_TO_UPLOAD = 'STUDENT_FILES/REMOVE_FILE_TO_UPLOAD'
export const UPLOAD_FILES_REQUEST = 'STUDENT_FILES/UPLOAD_FILES_REQUEST'
export const UPLOAD_FILES_SUCCESS = 'STUDENT_FILES/UPLOAD_FILES_SUCCESS'
export const UPLOAD_FILES_FAILURE = 'STUDENT_FILES/UPLOAD_FILES_FAILURE'
export const REMOVE_UPLOADED_FILE = 'STUDENT_FILES/REMOVE_UPLOADED_FILE'
export const DOWNLOAD_FILE_REQUEST = 'STUDENT_FILES/DOWNLOAD_FILE_REQUEST'
export const DOWNLOAD_FILE_SUCCESS = 'STUDENT_FILES/DOWNLOAD_FILE_SUCCESS'
export const DOWNLOAD_FILE_FAILURE = 'STUDENT_FILES/DOWNLOAD_FILE_FAILURE'
export const SET_STUDENT_FILES_MODAL_OPEN = 'STUDENT_FILES/SET_STUDENT_FILES_MODAL_OPEN'
export const SET_STUDENT_FILES_MODAL_CLOSED = 'STUDENT_FILES/SET_STUDENT_FILES_MODAL_CLOSED'

export interface Question {
  id: number
  text: string
  type: string
  options: []
}

export interface UploadedFile {
  fileId: number
  name: string
  url: string
  description: string
}

export interface UploadedFileSuccess {
  id: number
  name: string
  url: string
  description: string
}

export interface AttachmentQuestion {
  loading: boolean
  files: UploadedFile[]
  filesToUpload: File[]
  error: string
}

export interface StudentFilesState {
  attachmentAnswers: {
    [questionID: string]: AttachmentQuestion
  }
  selectedQuestion: Question
  version: string
  filesModalOpen: boolean
}

export interface AddFilesToUpload {
  type: typeof ADD_FILES_TO_UPLOAD
  payload: {
    files: File[]
    questionID: number
  }
}

export interface RemoveFileToUpload {
  type: typeof REMOVE_FILE_TO_UPLOAD
  payload: {
    file: File
    questionID: number
  }
}

export interface UploadFilesRequest {
  type: typeof UPLOAD_FILES_REQUEST
  payload: {
    questionID: number
  }
}

export interface UploadFilesSuccess {
  type: typeof UPLOAD_FILES_SUCCESS
  payload: {
    files: UploadedFileSuccess[]
    questionId: number
  }
}

export interface UploadFilesFailure {
  type: typeof UPLOAD_FILES_FAILURE
  payload: {
    error: string
    questionID: number
  }
}

export interface DownloadFileRequest {
  type: typeof DOWNLOAD_FILE_REQUEST
  payload: {
    questionID: number
    fileID: number
  }
}

export interface DownloadFileSuccess {
  type: typeof DOWNLOAD_FILE_SUCCESS
  payload: {
    fileID: number
    questionID: number
  }
}

export interface DownloadFileFailure {
  type: typeof DOWNLOAD_FILE_FAILURE
  payload: {
    error: string
    fileID: number
    questionID: number
  }
}

export interface RemoveUploadedFile {
  type: typeof REMOVE_UPLOADED_FILE
  payload: {
    fileID: number
    questionID: number
  }
}

export interface SetStudentFilesModalOpen {
  type: typeof SET_STUDENT_FILES_MODAL_OPEN
  payload: {
    selectedQuestion: Question
    version: string
  }
}

export interface SetStudentFilesModalClosed {
  type: typeof SET_STUDENT_FILES_MODAL_CLOSED
}

export type StudentFilesTypes =
  | AddFilesToUpload
  | RemoveFileToUpload
  | UploadFilesRequest
  | UploadFilesSuccess
  | UploadFilesFailure
  | RemoveUploadedFile
  | DownloadFileRequest
  | DownloadFileSuccess
  | DownloadFileFailure
  | SetStudentFilesModalOpen
  | SetStudentFilesModalClosed
  | WrittenExamActivitySuccess
