export const SET_OPEN = 'PREVIEW/SET_OPEN'
export const SET_CLOSE = 'PREVIEW/SET_CLOSE'
export const SET_FILES = 'PREVIEW/SET_FILES'
export const SELECT_FILE_REQUEST = 'PREVIEW/SELECT_FILE_REQUEST'
export const SELECT_FILE_SUCCESS = 'PREVIEW/SELECT_FILE_SUCCESS'
export const SELECT_FILE_FAILURE = 'PREVIEW/SELECT_FILE_FAILURE'

export interface File {
  id: number
  name: string
  fileId: number
}

export interface PreviewState {
  open: boolean
  selectedFile: File
  files: File[]
  loading: boolean
  error: string
  url: string
  activityID: number
  method: string
  isBook: boolean
}

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    activityID: number
    files: File[]
    selectedFile: File
    method: string
    doNotOpen: boolean
  }
}

export interface SetClose {
  type: typeof SET_CLOSE
}

export interface SelectFileRequest {
  type: typeof SELECT_FILE_REQUEST
  payload: {
    selectedFile: File
    isBook: boolean
  }
}

export interface SelectFileSuccess {
  type: typeof SELECT_FILE_SUCCESS
  payload: {
    url: string
  }
}

export interface SelectFileFailure {
  type: typeof SELECT_FILE_FAILURE
  payload: {
    error: ''
  }
}

export type PreviewActions =
  | SetOpen
  | SetClose
  | SelectFileRequest
  | SelectFileSuccess
  | SelectFileFailure
