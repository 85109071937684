import { apiPrivate } from '@/api'
import { url } from '@aula/config'
import type { AppThunk } from '@/state/thunk'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import type { OrganizationOption } from '@/sections/eb-admin/dashboard/types/common'
import type {
  SetGlobalSearch,
  SetNoPermissionsModalOpen,
} from '@/sections/principal/dashboard/types/dashboard'
import {
  DASHBOARD_FAILURE,
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  SET_GLOBAL_SEARCH,
  SET_NO_PERMISSIONS_MODAL_OPEN,
  SET_ORGANIZATION,
  OPEN_DRAWER,
  CLOSE_DRAWER,
} from '@/sections/principal/dashboard/types/dashboard'

const actions = {
  openDrawer: (selected: boolean) => ({
    type: OPEN_DRAWER,
    payload: {
      selected,
    },
  }),
  closeDrawer: () => ({
    type: CLOSE_DRAWER,
  }),
  getDashboard:
    (organizationID: number): AppThunk =>
    async (dispatch, getState) => {
      dispatch({ type: DASHBOARD_REQUEST })
      const academicPeriodID = getState().user.selectedAcademicPeriod.id
      const params = {
        params: { organizationId: organizationID, academicPeriodId: academicPeriodID },
      }

      return apiPrivate
        .get(url + '/v2/principal/dashboard', params)
        .then((response) => {
          const { classrooms, organizations, users, subjects } = response.data.description

          dispatch(actions.getDashboardSuccess(classrooms, organizations, users, subjects))
        })
        .catch((error) => {
          if (error?.response?.status === 403) {
            dispatch(actions.setNoPermissionsModalOpen(true))
          }

          handleErrorsWithAction(error, DASHBOARD_FAILURE, dispatch)
        })
    },

  getDashboardSuccess: (
    classrooms: number,
    organizations: number,
    users: number,
    subjects: number
  ) => ({
    type: DASHBOARD_SUCCESS,
    payload: { classrooms, organizations, users, subjects },
  }),

  setGlobalSearch: (search: string): SetGlobalSearch => ({
    type: SET_GLOBAL_SEARCH,
    payload: { search },
  }),

  setSelectedOrganization:
    (selectedOrganization: OrganizationOption): AppThunk =>
    (dispatch) => {
      dispatch({ type: SET_ORGANIZATION, payload: { selectedOrganization } })
      dispatch(actions.getDashboard(selectedOrganization.value))
    },

  setNoPermissionsModalOpen: (open: boolean): SetNoPermissionsModalOpen => ({
    type: SET_NO_PERMISSIONS_MODAL_OPEN,
    payload: { open },
  }),
}

export default actions
