import { url } from '@aula/config'
import { ApiPrivate } from '@/legacy/services/api/api'
import type { GetPendingSubjectResponse } from './types'

type GetPendingSubjectParams = {
  organizationId: number
  classroomId: number
  searchText?: string
  limit: number
  offset: number
}

export const getPendingSubjects = (
  params: GetPendingSubjectParams
): Promise<GetPendingSubjectResponse> =>
  ApiPrivate.get<GetPendingSubjectResponse>(url + `/v1/pending-subjects`, {
    params,
  }).then((response) => response.data)

export type PendingSubjectPayload = {
  organizationId: number
  studentId: number
  subjectId: number
  year: string
  academicPeriodId: number
}

export const createPendingSubject = (data: PendingSubjectPayload) =>
  ApiPrivate.post(url + '/v1/pending-subjects', data).then((response) => response.data)

export type EditPendingSubjectPayload = PendingSubjectPayload & { id: number }

export const editPendingSubject = (data: EditPendingSubjectPayload) =>
  ApiPrivate.put(url + '/v1/pending-subjects', data).then((response) => response.data)

type DeletePendingSubjectParams = {
  organizationId: number
  id: number
}

export const deletePendingSubject = (data: DeletePendingSubjectParams) =>
  ApiPrivate.delete(url + '/v1/pending-subjects', { data }).then((response) => response.data)
