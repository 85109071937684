import type { BooksActions, BooksState } from '@/sections/content-creator/books/types/books'
import {
  AVAILABILITY_FREE,
  BOOK_INFO_MODAL,
  SET_OPEN,
  GET_BOOK_ACTIVITIES_REQUEST,
  GET_BOOK_ACTIVITIES_SUCCESS,
  GET_BOOK_ACTIVITIES_FAILURE,
} from '@/sections/content-creator/books/types/books'

const initialState: BooksState = {
  open: false,
  modalType: BOOK_INFO_MODAL,
  selectedBook: {
    id: 0,
    createdAt: '',
    userID: 0,
    organizationID: 0,
    title: '',
    description: '',
    fileID: 0,
    availability: AVAILABILITY_FREE,
    isbn: '',
    url: '',
    coverURL: '',
  },
  organizationID: 0,
  selectedBookActivities: [],
  loadingBookActivities: false,
}

function booksReducer(state = initialState, action: BooksActions): BooksState {
  switch (action.type) {
    case SET_OPEN: {
      const { open, modalType, selectedBook, organizationID } = action.payload

      if (!open) return initialState
      return {
        ...state,
        open,
        modalType: modalType || state.modalType,
        selectedBook: selectedBook || state.selectedBook,
        organizationID,
      }
    }
    case GET_BOOK_ACTIVITIES_REQUEST: {
      return { ...state, loadingBookActivities: true, selectedBookActivities: [] }
    }
    case GET_BOOK_ACTIVITIES_SUCCESS: {
      const { bookActivities } = action.payload
      return {
        ...state,
        loadingBookActivities: false,
        selectedBookActivities: bookActivities,
      }
    }
    case GET_BOOK_ACTIVITIES_FAILURE: {
      return {
        ...state,
        selectedBookActivities: [],
        loadingBookActivities: false,
      }
    }
    default:
      return state
  }
}

export default booksReducer
