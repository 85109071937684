import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/management/types/users/deleteUserProfileModal'

export const initialState: Types.DeleteUserProfileModalState = {
  isOpenModal: false,
  status: REQUEST_STATUS.IDLE,
  selectedProfile: {
    name: '',
    role: '',
    id: 0,
    organizationId: 0,
  },
}

const deleteUserProfileModalReducer = (
  state: Types.DeleteUserProfileModalState = initialState,
  action: Types.DeleteUserProfileModalAction
): Types.DeleteUserProfileModalState => {
  switch (action.type) {
    case Types.MODAL_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isOpenModal: true,
      }
    }
    case Types.MODAL_TYPES.SET_SELECTED_PROFILE: {
      return {
        ...state,
        selectedProfile: action.payload,
      }
    }
    case Types.MODAL_TYPES.DELETE_USER_PROFILE_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.MODAL_TYPES.DELETE_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
      }
    }
    case Types.MODAL_TYPES.DELETE_USER_PROFILE_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.MODAL_TYPES.CLEAN_MODAL: {
      return initialState
    }
    default:
      return state
  }
}

export default deleteUserProfileModalReducer
