import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import type { Reducer } from 'redux'
import type {
  ReportCardGradingCompetenceScoresTabAction,
  ReportCardGradingCompetenceScoresTabState,
} from '@/sections/report-card/grading/types/tabs/competencesTab'
import {
  GET_STUDENT_COMPETENCE_SCORES_FAILURE,
  GET_STUDENT_COMPETENCE_SCORES_REQUEST,
  GET_STUDENT_COMPETENCE_SCORES_SUCCESS,
} from '@/sections/report-card/grading/types/tabs/competencesTab'
import { FIND_CLASSROOM_REPORT_CARD_CONFIG_SUCCESS } from '@/sections/report-card/grading/types/mainView'

const initialState: ReportCardGradingCompetenceScoresTabState = {
  loadingCompetenceScores: false,
  error: '',
  studentCompetenceScores: [],
  competenceTypes: [],
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_GRADING_COMPETENCE_SCORES_TAB

const ReportCardGradingCompetenceScoresTab: Reducer<
  ReportCardGradingCompetenceScoresTabState,
  ReportCardGradingCompetenceScoresTabAction
> = (state = initialState, action): ReportCardGradingCompetenceScoresTabState => {
  switch (action.type) {
    case GET_STUDENT_COMPETENCE_SCORES_REQUEST:
      return { ...state, loadingCompetenceScores: true, error: initialState.error }
    case GET_STUDENT_COMPETENCE_SCORES_SUCCESS:
      return {
        ...state,
        loadingCompetenceScores: false,
        error: initialState.error,
        studentCompetenceScores: action.payload.studentCompetenceScores,
      }
    case GET_STUDENT_COMPETENCE_SCORES_FAILURE:
      return { ...state, loadingCompetenceScores: false, error: action.payload.error }
    case FIND_CLASSROOM_REPORT_CARD_CONFIG_SUCCESS:
      return {
        ...state,
        competenceTypes: action.payload.config?.competenceScores.types || state.competenceTypes,
      }
    default:
      return state
  }
}

export default checkRequestOrigin(
  initialState,
  ReportCardGradingCompetenceScoresTab,
  requestDestination
)
