import type { Moment } from 'moment'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { PremiumActivity } from '@/sections/content-creator/premium-activities/types/premium-activities'
import type { LicenseStock } from '@/sections/eb-admin/dashboard/types/bookLicenses'
import type { Classroom } from '@/sections/teacher/studentList/types/studentList'
import type * as ContentCreatorBooksTypes from '@/sections/content-creator/books/types/books'
import type { SetFilters } from './library'

export const SET_OPEN = 'LIBRARY_TEACHER/SET_BOOK_OPEN'
export const SET_LICENSE_DELETION_MODAL_OPEN = 'LIBRARY_TEACHER/SET_LICENSE_DELETION_MODAL_OPEN'
export const GET_BOOK_LICENSES_REQUEST = 'LIBRARY_TEACHER/GET_BOOK_LICENSES_REQUEST'
export const GET_BOOK_LICENSES_SUCCESS = 'LIBRARY_TEACHER/GET_BOOK_LICENSES_SUCCESS'
export const GET_BOOK_LICENSES_FAILURE = 'LIBRARY_TEACHER/GET_BOOK_LICENSES_FAILURE'
export const GET_BOOK_ACTIVITIES_REQUEST = 'LIBRARY_TEACHER/GET_BOOK_ACTIVITIES_REQUEST'
export const GET_BOOK_ACTIVITIES_SUCCESS = 'LIBRARY_TEACHER/GET_BOOK_ACTIVITIES_SUCCESS'
export const GET_BOOK_ACTIVITIES_FAILURE = 'LIBRARY_TEACHER/GET_BOOK_ACTIVITIES_FAILURE'
export const GET_LICENSE_STOCK_REQUEST = 'LIBRARY_TEACHER/GET_LICENSE_STOCK_REQUEST'
export const GET_LICENSE_STOCK_SUCCESS = 'LIBRARY_TEACHER/GET_LICENSE_STOCK_SUCCESS'
export const GET_LICENSE_STOCK_FAILURE = 'LIBRARY_TEACHER/GET_LICENSE_STOCK_FAILURE'

export const GET_CLASSROOMS_REQUEST = 'LIBRARY_TEACHER/GET_CLASSROOMS_REQUEST'
export const GET_CLASSROOMS_SUCCESS = 'LIBRARY_TEACHER/GET_CLASSROOMS_SUCCESS'
export const GET_CLASSROOMS_FAILURE = 'LIBRARY_TEACHER/GET_CLASSROOMS_FAILURE'

export const GET_CLASSROOM_SUBGROUPS_REQUEST = 'LIBRARY_TEACHER/GET_CLASSROOM_SUBGROUPS_REQUEST'
export const GET_CLASSROOM_SUBGROUPS_SUCCESS = 'LIBRARY_TEACHER/GET_CLASSROOM_SUBGROUPS_SUCCESS'
export const GET_CLASSROOM_SUBGROUPS_FAILURE = 'LIBRARY_TEACHER/GET_CLASSROOM_SUBGROUPS_FAILURE'

export const SELECT_SUBGROUPS = 'LIBRARY_TEACHER/SELECT_SUBGROUPS'
export const SELECT_CLASSROOM = 'LIBRARY_TEACHER/SELECT_CLASSROOM'
export const SET_EXPIRY = 'LIBRARY_TEACHER/SET_EXPIRY'
export const SET_MODAL_VIEW = 'LIBRARY_TEACHER/SET_MODAL_VIEW'

export const CREATE_LICENSE_REQUEST = 'LIBRARY_TEACHER/CREATE_LICENSE_REQUEST'
export const CREATE_LICENSE_SUCCESS = 'LIBRARY_TEACHER/CREATE_LICENSE_SUCCESS'
export const CREATE_LICENSE_FAILURE = 'LIBRARY_TEACHER/CREATE_LICENSE_FAILURE'

export const REVOKE_BULK_LICENSE_REQUEST = 'LIBRARY_TEACHER/REVOKE_BULK_LICENSE_REQUEST'
export const REVOKE_BULK_LICENSE_SUCCESS = 'LIBRARY_TEACHER/REVOKE_BULK_LICENSE_SUCCESS'
export const REVOKE_BULK_LICENSE_FAILURE = 'LIBRARY_TEACHER/REVOKE_BULK_LICENSE_FAILURE'

export const GET_ALL_TAGS_REQUEST = 'LIBRARY_TEACHER/GET_ALL_TAGS_REQUEST'
export const GET_ALL_TAGS_SUCCESS = 'LIBRARY_TEACHER/GET_ALL_TAGS_SUCCESS'
export const GET_ALL_TAGS_FAILURE = 'LIBRARY_TEACHER/GET_ALL_TAGS_FAILURE'

export const SET_ONLY_WITH_LICENSES = 'LIBRARY_TEACHER/SET_ONLY_WITH_LICENSES'

export type Subgroup = {
  id: number
  name: string
}

export type BookLicense = {
  amount: number
  classroomId: number
  classroomName: string
  groupId: number
  groupName: string
}

export interface SetBookOpen {
  type: typeof SET_OPEN
  payload: {
    open: boolean
    book: ContentCreatorBooksTypes.Book
  }
}

export interface SetLicenseDeletionModalOpen {
  type: typeof SET_LICENSE_DELETION_MODAL_OPEN
  payload: {
    open: boolean
    selectedLicense?: BookLicense
  }
}

type Pages = {
  page: number
  rowsPerPage: number
  count: number
}

export interface LibraryBooksState {
  open: boolean
  loading: boolean
  loadingBookLicenses: boolean
  loadingBookActivities: boolean
  loadingBookLicenseStock: boolean
  loadingNewLicense: boolean
  loadingLicenseDeletion: boolean
  licenseDeletionModalOpen: boolean
  selectedLicense: BookLicense | null
  books: ContentCreatorBooksTypes.Book[]
  selectedBookLicenses: BookLicense[]
  selectedBookActivities: PremiumActivity[]
  selectedBookLicenseStock: LicenseStock | null
  error: string
  classroomID: number
  lastClassroomID: number
  subgroups: Subgroup[]
  selectedSubgroups: Subgroup[]
  loadingClassrooms: boolean
  loadingClassroomOptions: boolean
  classrooms: Classroom[]
  classroom: Classroom
  expiry: Moment
  selectedBook: ContentCreatorBooksTypes.Book
  modalView: ContentCreatorBooksTypes.BookModalView
  booksPagination: Pages
  tags: LibraryResourceTagCategory[]
  onlyWithLicenses: boolean
}

export interface GetBookLicensesRequest {
  type: typeof GET_BOOK_LICENSES_REQUEST
}

export interface GetBookLicensesSuccess {
  type: typeof GET_BOOK_LICENSES_SUCCESS
  payload: {
    bookLicenses: BookLicense[]
  }
}

export interface GetBookLicensesFailure {
  type: typeof GET_BOOK_LICENSES_FAILURE
  payload: {
    error: string
  }
}

export interface GetBookActivitiesRequest {
  type: typeof GET_BOOK_ACTIVITIES_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
    page: number
    rowsPerPage: number
  }
}

export interface GetBookActivitiesSuccess {
  type: typeof GET_BOOK_ACTIVITIES_SUCCESS
  payload: {
    bookActivities: PremiumActivity[]
    requestOrigin: ReduxRequestOrigin
    count: number
  }
}

export interface GetBookActivitiesFailure {
  type: typeof GET_BOOK_ACTIVITIES_FAILURE
  payload: {
    error: string
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetLicenseStockRequest {
  type: typeof GET_LICENSE_STOCK_REQUEST
}

export interface GetLicenseStockSuccess {
  type: typeof GET_LICENSE_STOCK_SUCCESS
  payload: {
    licenseStock: LicenseStock
  }
}

export interface GetLicenseStockFailure {
  type: typeof GET_LICENSE_STOCK_FAILURE
  payload: {
    error: string
  }
}

export interface GetClassroomsRequest {
  type: typeof GET_CLASSROOMS_REQUEST
  payload: {
    optionsLoading: boolean
    lastSearchID: number
  }
}

export interface GetClassroomsSuccess {
  type: typeof GET_CLASSROOMS_SUCCESS
  payload: {
    classrooms: Classroom[]
    selectedClassroom: Classroom
  }
}

export interface GetClassroomsFailure {
  type: typeof GET_CLASSROOMS_FAILURE
  payload: {
    error: string
  }
}

export interface GetClassroomSubgroupsRequest {
  type: typeof GET_CLASSROOM_SUBGROUPS_REQUEST
}

export interface GetClassroomSubgroupsSuccess {
  type: typeof GET_CLASSROOM_SUBGROUPS_SUCCESS
  payload: {
    subgroups: Subgroup[]
  }
}

export interface GetClassroomSubgroupsFailure {
  type: typeof GET_CLASSROOM_SUBGROUPS_FAILURE
  payload: {
    error: string
  }
}

export interface SelectSubgroups {
  type: typeof SELECT_SUBGROUPS
  payload: {
    selectedSubgroups: Subgroup[]
  }
}

export interface SelectClassroom {
  type: typeof SELECT_CLASSROOM
  payload: {
    classroomID: number
  }
}

export interface SetExpiry {
  type: typeof SET_EXPIRY
  payload: {
    expiry: Moment
  }
}

export interface SetModalView {
  type: typeof SET_MODAL_VIEW
  payload: {
    modalView: ContentCreatorBooksTypes.BookModalView
  }
}

export interface SetOnlyWithLicenses {
  type: typeof SET_ONLY_WITH_LICENSES
  payload: boolean
}

export interface CreateLicenseRequest {
  type: typeof CREATE_LICENSE_REQUEST
}

export interface CreateLicenseSuccess {
  type: typeof CREATE_LICENSE_SUCCESS
}

export interface CreateLicenseFailure {
  type: typeof CREATE_LICENSE_FAILURE
  payload: {
    error: ''
  }
}

export interface RevokeBulkLicenseRequest {
  type: typeof REVOKE_BULK_LICENSE_REQUEST
}

export interface RevokeBulkLicenseSuccess {
  type: typeof REVOKE_BULK_LICENSE_SUCCESS
}

export interface RevokeBulkLicenseFailure {
  type: typeof REVOKE_BULK_LICENSE_FAILURE
  payload: {
    error: ''
  }
}

export type LibraryResourceTag = {
  id: number
  name: string
}

export type LibraryResourceTagCategory = {
  name: string
  tags: LibraryResourceTag[]
}

export interface GetAllTagsRequest {
  type: typeof GET_ALL_TAGS_REQUEST
}

export interface GetAllTagsSuccess {
  type: typeof GET_ALL_TAGS_SUCCESS
  payload: {
    tagCategories: LibraryResourceTagCategory[]
  }
}

export interface GetAllTagsFailure {
  type: typeof GET_ALL_TAGS_FAILURE
  payload: {
    error: string
  }
}

export type LibraryBooksActions =
  | SetBookOpen
  | GetBookLicensesRequest
  | GetBookLicensesSuccess
  | GetBookLicensesFailure
  | GetBookActivitiesRequest
  | GetBookActivitiesSuccess
  | GetBookActivitiesFailure
  | GetLicenseStockRequest
  | GetLicenseStockSuccess
  | GetLicenseStockFailure
  | GetClassroomsRequest
  | GetClassroomsSuccess
  | GetClassroomsFailure
  | GetClassroomSubgroupsRequest
  | GetClassroomSubgroupsSuccess
  | GetClassroomSubgroupsFailure
  | SelectClassroom
  | SelectSubgroups
  | SetExpiry
  | SetModalView
  | CreateLicenseRequest
  | CreateLicenseSuccess
  | CreateLicenseFailure
  | RevokeBulkLicenseRequest
  | RevokeBulkLicenseSuccess
  | RevokeBulkLicenseFailure
  | SetLicenseDeletionModalOpen
  | ContentCreatorBooksTypes.GetOrganizationBooksRequest
  | ContentCreatorBooksTypes.GetOrganizationBooksSuccess
  | ContentCreatorBooksTypes.GetOrganizationBooksFailure
  | GetAllTagsRequest
  | GetAllTagsSuccess
  | GetAllTagsFailure
  | SetFilters
  | SetOnlyWithLicenses
