import type {
  openModalAction,
  closeModalAction,
  HookOrigin,
} from '@/sections/report-card/grading/types/gradingModal'
import { OPEN_MODAL, CLOSE_MODAL } from '@/sections/report-card/grading/types/gradingModal'

export interface gradingModalState {
  isOpen: boolean
  origin: HookOrigin
}

export type grandingModalAction = openModalAction | closeModalAction

const initialState: gradingModalState = {
  isOpen: false,
  origin: 'DRAWER',
}

const gradingModal = (state = initialState, action: grandingModalAction) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        isOpen: true,
        origin: action.payload.origin,
      }
    case CLOSE_MODAL:
      return initialState
    default:
      return state
  }
}

export default gradingModal
