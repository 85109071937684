import * as React from 'react'
import type { SVGProps } from 'react'
const SvgRobotsActivity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={69}
    height={69}
    viewBox="0 0 69 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect opacity={0.15} width={69} height={69} rx={6} fill="#27A8FF" />
    <path
      d="M56.39 38.043c1.957-12.09-6.257-23.476-18.347-25.433-12.09-1.957-23.476 6.257-25.433 18.347-1.957 12.09 6.257 23.476 18.347 25.433 12.09 1.957 23.476-6.257 25.433-18.347Z"
      fill="#27A8FF"
    />
    <path
      d="M44.874 31.852h-.823v-1.156c0-1.865-1.529-3.37-3.394-3.37h-5.542v-2.773A2.44 2.44 0 0 0 33.894 20a2.446 2.446 0 0 0-2.448 2.44c0 .903.485 1.691 1.214 2.113v2.773h-5.534a3.36 3.36 0 0 0-3.364 3.37v1.156h-.853c-1.05 0-1.909.87-1.909 1.92v4.108c0 1.05.858 1.911 1.909 1.911h.853v1.166a3.352 3.352 0 0 0 3.364 3.36h1.583v1.781c0 1.05.86 1.902 1.91 1.902h6.544c1.05 0 1.902-.852 1.902-1.902v-1.78h1.592c1.866 0 3.394-1.496 3.394-3.361v-1.165h.823c1.05 0 1.9-.861 1.9-1.911v-4.109c0-1.05-.849-1.92-1.9-1.92Zm-18.391 1.284a2.235 2.235 0 1 1 4.47 0 2.235 2.235 0 0 1-4.47 0Zm10.452 8.152h-6.087a1.822 1.822 0 1 1 0-3.643h6.087a1.822 1.822 0 1 1 0 3.643Zm2.13-5.918a2.234 2.234 0 1 1 0-4.468 2.234 2.234 0 0 1 0 4.468Z"
      fill="#fff"
    />
  </svg>
)
export default SvgRobotsActivity
