import React from 'react'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { Emojione } from 'react-emoji-render'

const useStyles = makeStyles((theme) => ({
  Container: {
    width: '100%',
    height: 104,
    backgroundColor: '#4F36D6',
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleContainer: {
    fontFamily: 'DM Sans',
    marginLeft: 24,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  title: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    fontSize: 28,
    letterSpacing: '0.01em',
  },
  date: {
    fontSize: 16,
    color: '#FFFFFF',
  },
  people: {
    marginRight: 24,
    width: 200,
    textAlign: 'right',
    fontFamily: 'DM Sans',
    fontWeight: 'bold',
    fontSize: 16,
    color: '#fff',
    marginTop: 30,
  },
  progressBannerContainer: {
    display: 'flex',
  },
  progressBannerIcon: {
    marginTop: 25,
    marginLeft: 20,
  },
}))

export default function ClassroomBanner(props) {
  const classes = useStyles()
  if (props.loading) {
    return (
      <div className={classes.Container}>
        <CircularProgress
          className={classes.progressBannerIcon}
          color="secondary"
          size="50px"
          variant="indeterminate"
        />
      </div>
    )
  } else {
    return (
      <div className={classes.Container}>
        <div className={classes.titleContainer}>
          <Typography className={`${classes.title}`}>{props.classroomText}</Typography>
          <Typography className={classes.date}>{props.schoolText}</Typography>
        </div>
        <Typography className={classes.people}>
          <Emojione text=" 👦👧 " />
          {props.people}
        </Typography>
      </div>
    )
  }
}
