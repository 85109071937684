import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { AttendanceRecord } from '@/legacy/services/api/attendances/types'

export interface AttendancesState {
  status: REQUEST_STATUS
  data: AttendanceRecord[]
  percentage: number
}

export enum ATTENDANCES_TYPES {
  GET_ATTENDANCES_REQUEST = 'STUDENT/SUMMARY/TABS/ATTENDANCES/ATTENDANCES/GET_ATTENDANCES_REQUEST',
  GET_ATTENDANCES_SUCCESS = 'STUDENT/SUMMARY/TABS/ATTENDANCES/ATTENDANCES/GET_ATTENDANCES_SUCCESS',
  GET_ATTENDANCES_FAILURE = 'STUDENT/SUMMARY/TABS/ATTENDANCES/ATTENDANCES/GET_ATTENDANCES_FAILURE',
  CLEAN_ATTENDANCES = 'STUDENT/SUMMARY/TABS/ATTENDANCES/ATTENDANCES/CLEAN_ATTENDANCES',
}

// Get attendances.
export type GetAttendancesRequestAction = {
  type: ATTENDANCES_TYPES.GET_ATTENDANCES_REQUEST
}

export type GetAttendancesSuccessAction = {
  type: ATTENDANCES_TYPES.GET_ATTENDANCES_SUCCESS
  payload: {
    data: AttendanceRecord[]
    percentage: number
  }
}

export type GetAttendancesFailureAction = {
  type: ATTENDANCES_TYPES.GET_ATTENDANCES_FAILURE
}

export type GetAttendancesAction =
  | GetAttendancesRequestAction
  | GetAttendancesSuccessAction
  | GetAttendancesFailureAction

// Clean attendances.
export type CleanAttendancesAction = {
  type: ATTENDANCES_TYPES.CLEAN_ATTENDANCES
}

export type AttendancesAction = GetAttendancesAction | CleanAttendancesAction
