import type { AxiosResponse } from 'axios'
import { apiPrivate } from '..'
import { tedUrl } from '@aula/config'
import type { DescriptionResponseShortener } from '@/api/types'
import type { Facilitator, Group, Institution, List, NewFacilitator } from './types'

export const TedMainAPI = {
  getGroup,
  listGroups,
  editGroup,
  listInstitutions,
  getInstitution,
  listFacilitators,
  addFacilitator,
}
export interface EditGroupParams {
  currentStage: Meeting
  name: string
  finalEventCompleted: boolean
  totalParticipants: number
  completedTalksCount: number
  teachersCount: number
  status: string
}

export enum GroupStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PAUSED = 'paused',
}
export enum Meeting {
  MEETING_1 = 'meeting_1',
  MEETING_2 = 'meeting_2',
  MEETING_3 = 'meeting_3',
  MEETING_4 = 'meeting_4',
  MEETING_5 = 'meeting_5',
  MEETING_6 = 'meeting_6',
  MEETING_7 = 'meeting_7',
  MEETING_8 = 'meeting_8',
  FINAL_EVENT = 'final_event',
}

function getGroup(groupId: number): Promise<Group> {
  return apiPrivate
    .get(tedUrl + `/groups/${groupId}`)
    .then((resp: AxiosResponse<DescriptionResponseShortener<Group>>) => resp.data.description)
    .catch((err) => {
      throw err
    })
}

interface ListGroupsResponse {
  list: Group[]
  totalItems: number
  totalPages: number
  more: boolean
}

function listGroups(
  size: number,
  page: number,
  teacher: boolean,
  institutionId: number,
  academicPeriodId: number
): Promise<ListGroupsResponse> {
  const params = {
    size,
    page,
    institutionId,
    teacher,
    academicPeriodId,
  }

  return apiPrivate
    .get(tedUrl + '/groups', { params })
    .then(
      (resp: AxiosResponse<DescriptionResponseShortener<ListGroupsResponse>>) =>
        resp.data.description
    )

    .catch((err) => {
      throw err
    })
}

function editGroup(groupId: number, body: EditGroupParams): Promise<any> {
  const formattedBody: EditGroupParams = {
    ...body,
    totalParticipants: Number(body.totalParticipants),
    completedTalksCount: Number(body.completedTalksCount),
    teachersCount: Number(body.teachersCount),
  }

  return apiPrivate
    .put(tedUrl + `/groups/${groupId}`, formattedBody)
    .then((resp: AxiosResponse<DescriptionResponseShortener<any>>) => {
      return resp.data.description
    })
}

function getInstitution(institutionId: number): Promise<Institution> {
  return apiPrivate
    .get(tedUrl + `/institutions/${institutionId}`)
    .then((resp: AxiosResponse<DescriptionResponseShortener<Institution>>) => resp.data.description)
    .catch((err) => {
      throw err
    })
}

function listInstitutions(
  size: number,
  academicPeriodId: number,
  institutionId: number
): Promise<List<Institution>> {
  const params = {
    size,
    academicPeriodId,
    institutionId,
  }

  return apiPrivate
    .get(tedUrl + '/institutions', { params })
    .then(
      (resp: AxiosResponse<DescriptionResponseShortener<List<Institution>>>) =>
        resp.data.description
    )
    .catch((err) => {
      throw err
    })
}

function listFacilitators(size: number, page: number, groupId: number): Promise<List<Facilitator>> {
  const params = {
    size,
    page,
    groupId,
  }

  return apiPrivate
    .get(tedUrl + '/facilitators', { params })
    .then(
      (resp: AxiosResponse<DescriptionResponseShortener<List<Facilitator>>>) =>
        resp.data.description
    )
    .catch((err) => {
      throw err
    })
}

async function addFacilitator(newFacilitator: NewFacilitator) {
  const body = { ...newFacilitator }
  return apiPrivate.post(`${tedUrl}/facilitators`, body).then((resp) => resp.data)
}
