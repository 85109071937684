import type { Grade } from '@/sections/teacher/studentList/types/studentList'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'

export const CREATE_OFFLINE_ACTIVITY_REQUEST = 'ACTIVITIES/CREATE_OFFLINE_ACTIVITY_REQUEST'
export const CREATE_OFFLINE_ACTIVITY_SUCCESS = 'ACTIVITIES/CREATE_OFFLINE_ACTIVITY_SUCCESS'
export const CREATE_OFFLINE_ACTIVITY_FAILURE = 'ACTIVITIES/CREATE_OFFLINE_ACTIVITY_FAILURE'

export type CreateOfflineActivityRequest = GenericActionWithType<
  typeof CREATE_OFFLINE_ACTIVITY_REQUEST
>
export type CreateOfflineActivitySuccess = GenericActionWithType<
  typeof CREATE_OFFLINE_ACTIVITY_SUCCESS
>
export type CreateOfflineActivityFailure = GenericActionWithType<
  typeof CREATE_OFFLINE_ACTIVITY_FAILURE,
  ErrorPayload
>

export type CreateOfflineActivityAction =
  | CreateOfflineActivityRequest
  | CreateOfflineActivitySuccess
  | CreateOfflineActivityFailure

export const EVALUATE_OFFLINE_ACTIVITY_REQUEST = 'ACTIVITIES/EVALUATE_OFFLINE_ACTIVITY_REQUEST'
export const EVALUATE_OFFLINE_ACTIVITY_SUCCESS = 'ACTIVITIES/EVALUATE_OFFLINE_ACTIVITY_SUCCESS'
export const EVALUATE_OFFLINE_ACTIVITY_FAILURE = 'ACTIVITIES/EVALUATE_OFFLINE_ACTIVITY_FAILURE'

export type EvaluateOfflineActivityRequest = GenericActionWithType<
  typeof EVALUATE_OFFLINE_ACTIVITY_REQUEST
>
export type EvaluateOfflineActivitySuccess = GenericActionWithType<
  typeof EVALUATE_OFFLINE_ACTIVITY_SUCCESS,
  { evaluation: Grade; message?: string }
>
export type EvaluateOfflineActivityFailure = GenericActionWithType<
  typeof EVALUATE_OFFLINE_ACTIVITY_FAILURE,
  ErrorPayload
>

export type EvaluateOfflineActivityAction =
  | EvaluateOfflineActivityRequest
  | EvaluateOfflineActivitySuccess
  | EvaluateOfflineActivityFailure
