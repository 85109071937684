import { combineReducers } from 'redux'
import classroomsDrawer from './reducers/classroomsDrawer'
import subjectsDrawer from './reducers/subjectsDrawer'
import organizationsDrawer from './reducers/organizationsDrawer'

const rootReducer = combineReducers({
  classroomsDrawer,
  subjectsDrawer,
  organizationsDrawer,
})

export default rootReducer
