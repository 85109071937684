import React from 'react'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import classNames from 'clsx'

const useStyles = makeStyles((theme) => ({
  subjectContainer: {
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    border: '1px solid #DFDFDA',
    borderRadius: 16,
    padding: '3px 8px',
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'background-color 100ms',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
    '&:active': {
      backgroundColor: '#f4f4f4',
    },
  },
  subjectDot: ({ color }) => ({
    width: 8,
    height: 8,
    marginRight: 4,
    borderRadius: 4,
    background: color,
  }),
  text: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 'bold',
  },
}))

const SubjectChip = ({ subject: { name, color }, className, ...props }) => {
  const classes = useStyles({ color })
  return (
    <div className={classNames(classes.subjectContainer, className)}>
      <div className={classes.subjectDot} />
      <Typography className={classes.text}>{name}</Typography>
    </div>
  )
}

SubjectChip.defaultProps = {
  subject: {},
  className: '',
}

export default SubjectChip
