import React, { useState } from 'react'
import {
  Typography,
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@mui/material'
import { makeStyles, withStyles } from '@mui/styles'
import { ArrowDropDown, ArrowRight } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    borderTop: '1px solid #D8DBE8',
    borderBottom: '1px solid #D8DBE8',
    background: 'transparent',
  },
  title: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '21px',
  },
}))

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    marginBottom: -1,
    minHeight: 54,
    '&$expanded': {
      minHeight: 54,
    },
    color: '#302666',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: '0 0 16px 7.5px',
  },
}))(MuiAccordionDetails)

const ExpansionPanel = (props) => {
  const { children, defaultExpanded = false, titleClose = '', titleOpen = titleClose } = props
  const classes = useStyles()

  const [isOpen, setOpen] = useState(defaultExpanded)

  return (
    <Accordion
      onChange={(e, expanded) => setOpen(expanded)}
      expanded={isOpen}
      classes={{ root: classes.root }}
      square
    >
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        {isOpen ? <ArrowDropDown /> : <ArrowRight />}
        <Typography className={classes.title} component="span">
          {isOpen ? titleOpen : titleClose}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

export default ExpansionPanel
