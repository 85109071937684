import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

// State.
export type DeleteSequenceModalState = {
  isOpenModal: boolean
  status: REQUEST_STATUS
  sequenceIdToDelete: number
}

// Types.
export enum DELETE_SEQUENCE_MODAL_TYPES {
  OPEN_MODAL = 'CONTENT_CREATOR/DELETE_SEQUENCE_MODAL/OPEN_MODAL',
  CLEAN_MODAL = 'CONTENT_CREATOR/DELETE_SEQUENCE_MODAL/CLEAN_MODAL',
  DELETE_SEQUENCE_REQUEST = 'CONTENT_CREATOR/DELETE_SEQUENCE_MODAL/DELETE_SEQUENCE_REQUEST',
  DELETE_SEQUENCE_SUCCESS = 'CONTENT_CREATOR/DELETE_SEQUENCE_MODAL/DELETE_SEQUENCE_SUCCESS',
  DELETE_SEQUENCE_FAILURE = 'CONTENT_CREATOR/DELETE_SEQUENCE_MODAL/DELETE_SEQUENCE_FAILURE',
}

// Actions.
// Open modal action.
export type OpenModalAction = {
  type: DELETE_SEQUENCE_MODAL_TYPES.OPEN_MODAL
  payload: number
}

// Clean modal action.
export type CleanModalAction = {
  type: DELETE_SEQUENCE_MODAL_TYPES.CLEAN_MODAL
}

// Delete sequence actions.
export type DeleteSequenceRequestAction = {
  type: DELETE_SEQUENCE_MODAL_TYPES.DELETE_SEQUENCE_REQUEST
}

export type DeleteSequenceSuccessAction = {
  type: DELETE_SEQUENCE_MODAL_TYPES.DELETE_SEQUENCE_SUCCESS
}

export type DeleteSequenceFailureAction = {
  type: DELETE_SEQUENCE_MODAL_TYPES.DELETE_SEQUENCE_FAILURE
}

export type DeleteSequenceAction =
  | DeleteSequenceRequestAction
  | DeleteSequenceSuccessAction
  | DeleteSequenceFailureAction

// Action.
export type DeleteSequenceModalAction = OpenModalAction | CleanModalAction | DeleteSequenceAction
