import { SetField, ClearState } from "@/legacy/redux/types/generic"
import { ErrorPayload, GenericActionWithType } from "@/legacy/redux/utils"
import { ThemeOptions } from "@/sections/management/types/addEditExamModal"

export type CreateThemeParams = Omit<ThemeOptions, 'id'>

export type AddEditThemeModalState = {
  id?: number
  editMode: boolean
  label: string
  games: number[]
  grade: number
  exportEnabled: boolean
  open: boolean
  loading: boolean
  error: string
}

export const SET_EDIT_INITIAL_VALUES = 'THEMES/SET_EDIT_INITIAL_VALUES'
export type SetEditInitialValues = GenericActionWithType<
  typeof SET_EDIT_INITIAL_VALUES,
  { theme: ThemeOptions }
>

export const CREATE_REQUEST = 'THEMES/CREATE_REQUEST'
export const CREATE_SUCCESS = 'THEMES/CREATE_SUCCESS'
export const CREATE_FAILURE = 'THEMES/CREATE_FAILURE'

export type CreateRequest = GenericActionWithType<typeof CREATE_REQUEST>
export type CreateSuccess = GenericActionWithType<typeof CREATE_SUCCESS>
export type CreateFailure = GenericActionWithType<typeof CREATE_FAILURE, ErrorPayload>

export type CreateAction = CreateRequest | CreateSuccess | CreateFailure

export const EDIT_REQUEST = 'THEMES/EDIT_REQUEST'
export const EDIT_SUCCESS = 'THEMES/EDIT_SUCCESS'
export const EDIT_FAILURE = 'THEMES/EDIT_FAILURE'

export type EditRequest = GenericActionWithType<typeof EDIT_REQUEST>
export type EditSuccess = GenericActionWithType<typeof EDIT_SUCCESS>
export type EditFailure = GenericActionWithType<typeof EDIT_FAILURE, ErrorPayload>

export type EditAction = EditRequest | EditSuccess | EditFailure

export type AddEditThemeModalAction =
  | SetField<'label', string>
  | SetField<'games', number[]>
  | SetField<'grade', number | undefined>
  | SetField<'exportEnabled', boolean>
  | SetField<'open', boolean>
  | ClearState
  | CreateAction
  | EditAction
  | SetEditInitialValues
