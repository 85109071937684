import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import { competencesIdsArray } from '@/sections/report-card/common/competences'
import { SAVE_OR_EDIT_REPORT_CARD_CONFIG_SUCCESS } from '@/sections/report-card/config/types/apiInteractions'
import type {
  ReportCardConfigCompetencesModalAction,
  ReportCardConfigCompetencesModalState,
} from '@/sections/report-card/config/types/stepperModalSteps/competencesModal'

const initialState: ReportCardConfigCompetencesModalState = {
  open: false,
  competencesIds: competencesIdsArray,
  selectedCompetencesIds: [],
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_CONFIG_COMPETENCES

const ReportCardConfigCompetencesModal: Reducer<
  ReportCardConfigCompetencesModalState,
  ReportCardConfigCompetencesModalAction
> = (state = initialState, action): ReportCardConfigCompetencesModalState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case Redux.Types.Generic.TOGGLE_OPEN:
      return {
        ...state,
        open: action.payload.open,
      }
    case SAVE_OR_EDIT_REPORT_CARD_CONFIG_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default checkRequestOrigin(
  initialState,
  ReportCardConfigCompetencesModal,
  requestDestination
)
