import types from '@/sections/teacher/dashboard/types'
import { defaultClassroomOption, TABS } from '@/sections/teacher/utils/activities'
import { LIMIT } from '@/sections/teacher/dashboard/constants'
import { parseClassroomOption } from '@/sections/teacher/dashboard/actions/dashboard'

const initialState = {
  loading: false,
  loadingActivities: false,
  classrooms: [],
  selectedClassroom: defaultClassroomOption,
  classroomOptions: [],
  loadingClassrooms: false,
  lastClassroomID: 0,
  lastSearchID: 0,
  future: [],
  passed: [],
  today: [],
  summary: {},
  error: '',
  tab: TABS.TODAY,
  offset: 0,
  limit: LIMIT,
  total: 0,
  searchBarOpen: false,
  search: '',
  loadingSearch: false,
  searchResults: [],
  modalConfirmCertificate: false,
  dashboardV4: {
    todayLiveActivities: 0,
    todayLiveActivitiesDone: 0,
    monthExams: 0,
    monthExamsDone: 0,
    weekActivities: 0,
    submissionsToEvaluate: 0,
    delayedStudentSubmissions: 0,
  },
}

const getUpdatedActivities = (state, activities, total, tab, options) => {
  const { shouldOverrideActivities } = options
  return {
    [tab]: shouldOverrideActivities ? activities[tab] : [...state[tab], ...activities[tab]],
    total,
  }
}

function root(state = initialState, action) {
  switch (action.type) {
    case types.DASHBOARD_REQUEST:
      return { ...state, loading: action.payload.silentLoading ? state.loading : true, error: '' }
    case types.DASHBOARD_SUCCESS: {
      const { classrooms } = action.payload
      return {
        ...state,
        loading: false,
        classrooms,
        classroomOptions: state.loading
          ? classrooms.map((classroom) => parseClassroomOption(classroom))
          : state.classroomOptions,
      }
    }
    case types.DASHBOARD_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case types.DASHBOARD_V4_REQUEST:
      return { ...state, loadingDashboardV4: true }
    case types.DASHBOARD_V4_SUCCESS: {
      const {
        todayLiveActivities,
        todayLiveActivitiesDone,
        monthExams,
        monthExamsDone,
        weekActivities,
        submissionsToEvaluate,
        delayedStudentSubmissions,
      } = action.payload
      return {
        ...state,
        loadingDashboardV4: false,
        dashboardV4: {
          todayLiveActivities,
          todayLiveActivitiesDone,
          monthExams,
          monthExamsDone,
          weekActivities,
          submissionsToEvaluate,
          delayedStudentSubmissions,
        },
      }
    }
    case types.DASHBOARD_V4_FAILURE:
      return { ...state, loadingDashboardV4: false, error: action.payload.error }
    case types.ACTIVITIES_REQUEST: {
      const { tab, silentLoading, reset } = action.payload
      return {
        ...state,
        loadingActivities: silentLoading ? state.loadingActivities : true,
        error: '',
        tab,
        ...(reset && { [tab]: state[tab].slice(0, LIMIT), offset: 0, total: 0 }),
      }
    }
    case types.ACTIVITIES_SUCCESS: {
      const {
        today,
        future,
        passed,
        offset,
        doNotSetPagination,
        shouldOverrideActivities,
        tab,
        total,
      } = action.payload

      const updatedActivities = getUpdatedActivities(state, { today, future, passed }, total, tab, {
        shouldOverrideActivities,
      })

      return {
        ...state,
        loadingActivities: false,
        ...updatedActivities,
        ...(!doNotSetPagination && { offset }),
      }
    }
    case types.ACTIVITIES_FAILURE:
      return { ...state, loadingActivities: false, error: action.payload.error }
    case types.SET_CLASSROOM:
      return {
        ...state,
        selectedClassroom: action.payload.classroom,
        future: [],
        passed: [],
        today: [],
      }
    case types.RESET_CLASSROOM:
      return { ...state, selectedClassroom: defaultClassroomOption }
    case types.CLASSROOMS_REQUEST:
      return {
        ...state,
        loadingClassrooms: true,
        error: '',
        lastClassroomID: action.payload.lastSearchID,
      }
    case types.CLASSROOMS_SUCCESS: {
      const { classroomOptions } = action.payload
      return { ...state, loadingClassrooms: false, classroomOptions }
    }
    case types.CLASSROOMS_FAILURE:
      return { ...state, loadingClassrooms: false, error: action.payload.error }
    case types.TOGGLE_SEARCH_BAR:
      return { ...state, searchBarOpen: action.payload.open }
    case types.SET_SEARCH:
      return { ...state, search: action.payload.search }
    case types.SEARCH_REQUEST:
      return { ...state, lastSearchID: action.payload.lastSearchID, loadingSearch: true }
    case types.SEARCH_SUCCESS:
      return { ...state, searchResults: action.payload.activities, loadingSearch: false }
    case types.SEARCH_FAILURE:
      return { ...state, loadingSearch: false }
    case types.MODAL_CONFIRM_CERTIFICATE:
      return { ...state, modalConfirmCertificate: action.payload.studentId }
    case types.MODAL_CLOSE_CONFIRM_CERTIFICATE:
      return { ...state, modalConfirmCertificate: false }
    default:
      return state
  }
}

export default root
