import types from '@/sections/student/dashboard/types'
import moment from 'moment'
import { TABS } from '@/sections/student/dashboard/components/subject'
import { LIMIT } from '@/sections/student/dashboard/constants'

const initialState = {
  loading: true,
  loadingActivities: false,
  tab: TABS.TO_DO,
  todayList: [],
  thisWeekList: [],
  futureList: [],
  totalToDo: 0,
  notDoneList: [],
  totalNotDone: 0,
  historyList: [],
  totalHistory: 0,
  offset: 0,
  limit: 10,
  summary: {
    subject: {
      id: 0,
      color: '',
      name: '',
    },
    today: 0,
    pending: 0,
  },
}

const resetActualList = (state, tab) => {
  const { todayList, thisWeekList, futureList, notDoneList, historyList } = state

  const end = LIMIT

  switch (tab) {
    case TABS.TO_DO: {
      const toDoList = [...todayList, ...thisWeekList, ...futureList].slice(0, end)
      return {
        offset: 0,
        todayList: filterToday(toDoList),
        thisWeekList: filterWeek(toDoList),
        futureList: filterFuture(toDoList),
      }
    }
    case TABS.NOT_DONE:
      return { offset: 0, notDoneList: notDoneList.slice(0, end) }
    case TABS.HISTORY:
      return { offset: 0, historyList: historyList.slice(0, end) }
  }
}

const getUpdatedActivities = (state, activities, total, tab, options) => {
  const { shouldOverrideActivities } = options

  switch (tab) {
    case TABS.TO_DO: {
      const { todayList, thisWeekList, futureList } = state

      return {
        todayList: shouldOverrideActivities
          ? filterToday(activities)
          : [...todayList, ...filterToday(activities)],
        thisWeekList: shouldOverrideActivities
          ? filterWeek(activities)
          : [...thisWeekList, ...filterWeek(activities)],
        futureList: shouldOverrideActivities
          ? filterFuture(activities)
          : [...futureList, ...filterFuture(activities)],
        totalToDo: total,
      }
    }
    case TABS.NOT_DONE: {
      const { notDoneList } = state
      return {
        notDoneList: shouldOverrideActivities ? activities : [...notDoneList, ...activities],
        totalNotDone: total,
      }
    }
    case TABS.HISTORY: {
      const { historyList } = state
      return {
        historyList: shouldOverrideActivities ? activities : [...historyList, ...activities],
        totalHistory: total,
      }
    }
    default:
      return {}
  }
}

function root(state = initialState, action) {
  switch (action.type) {
    case types.SUBJECT_REQUEST:
      return {
        ...state,
        loading: action.payload.silentLoading ? state.loading : true,
        error: '',
        todayList: [],
        thisWeekList: [],
        futureList: [],
        notDoneList: [],
        historyList: [],
      }
    case types.SUBJECT_SUCCESS: {
      const { summary } = action.payload
      return { ...state, loading: false, summary }
    }
    case types.SUBJECT_FAILURE:
      return { ...state, loading: false, error: action.payload.error }

    case types.ACTIVITIES_REQUEST: {
      const { tab, silentLoading, reset } = action.payload
      return {
        ...state,
        tab,
        loadingActivities: silentLoading ? state.loading : true,
        ...(reset && resetActualList(state, tab)),
      }
    }
    case types.ACTIVITIES_SUCCESS: {
      const { activities, total, tab, offset, options } = action.payload
      const { doNotSetPagination } = options

      const updatedActivities = getUpdatedActivities(state, activities, total, tab, options)

      return {
        ...state,
        ...updatedActivities,
        tab,
        loadingActivities: false,
        ...(!doNotSetPagination && { offset: offset }),
      }
    }
    case types.ACTIVITIES_FAILURE:
      return { ...state, loadingActivities: false, error: action.payload.error }
    default:
      return state
  }
}

function filterToday(activities) {
  return activities.filter((a) => {
    const dayDiff = moment(a.deadline || a.date).diff(moment().startOf('day'), 'days')
    return dayDiff === 0
  })
}
function filterWeek(activities) {
  return activities.filter((a) => {
    const dayDiff = moment(a.deadline || a.date).diff(moment().startOf('day'), 'days')
    return dayDiff > 0 && dayDiff <= 7
  })
}
function filterFuture(activities) {
  return activities.filter((a) => {
    const dayDiff = moment(a.deadline || a.date).diff(moment().startOf('day'), 'days')
    return dayDiff > 7
  })
}

export default root
