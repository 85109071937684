import { combineReducers } from 'redux'
import dashboard from './reducers/dashboard'
import subject from './reducers/subject'
import activity from './reducers/activity'

const rootReducer = combineReducers({
  dashboard,
  subject,
  activity,
})

export default rootReducer
