import type { Theme } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'
import { COSMOS } from './cosmos'
import { LOGOS } from './logos'
import type { COSMOS_KEY } from './names'
import THEME from './theme'
import type { Cosmos, CosmosLogos } from './types'

/**
 * Search cosmos using pattern match
 *
 * @param {string} key cosmos key to search
 * @param {string} match value to match
 * @param {Cosmos} def default cosmos, if none found
 */
export const findCosmos = (key: keyof Cosmos, match: string, def: Cosmos): Cosmos =>
  Object.keys(COSMOS).reduce<Cosmos>((current, name) => {
    const cosmo = COSMOS[name]
    if (cosmo[key] === match) return cosmo
    return current
  }, def)

/**
 * Identify cosmos by cosmos by url query param
 *
 * @param {string} search URL's query ej: '?cosmos=aula'
 * @param {Cosmos} def default cosmos, if none found
 */
export const getCosmosByUrlParam = (search: string, def: Cosmos): Cosmos | null => {
  const params = new URLSearchParams(search)
  const param = params.get('cosmos') // can be null
  if (param) return findCosmos('key', param, def)
  return null
}

/**
 * 💅 Detect and pick the cosmos of the project
 *
 * Detect the project cosmos using the browser url domain name eg: https://aula.educabot.com, or
 * through the query param "cosmos" eg: "?cosmos=aula".
 */
export const pickCosmos = (): Cosmos => {
  const cosmo = getCosmosByUrlParam(window.location.search, COSMOS.AULA)
  if (cosmo) return cosmo
  return findCosmos('url', window.location.hostname, COSMOS.AULA)
}

/**
 * 🌀 Pick the logos pack of current cosmos
 */
export const pickCosmosLogos = (): CosmosLogos => {
  const { name } = pickCosmos()
  return {
    ...LOGOS.AULA,
    ...LOGOS[name],
  }
}

/**
 * 🎨 Pick the themes of current cosmos
 */
export const pickTheme = () => {
  const { name } = pickCosmos()
  return {
    ...THEME.AULA,
    ...THEME[name],
  }
}

/**
 * Ⓜ️ Pick and create theme for Material UI v4
 */
export const pickMaterialTheme = (): Theme => {
  const theme = pickTheme()
  return createTheme(theme)
}

/**
 * Check if the current cosmos is the matched cosmos
 *
 * @param {COSMOS_KEY} key the id of the cosmos
 */
export const cosmosIs = (key: COSMOS_KEY) => pickCosmos()['key'] === key
