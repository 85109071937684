import type { Moment } from 'moment'
import moment from 'moment'
import type { AttendancesDay } from '@/api/attendances/config'
import type { SubjectXDay } from '@/sections/attendances/config/types/entities'

type DateRange = {
  startDate: Moment
  endDate: Moment
}

// monday at 18:00:00 UTC
export const monday = moment().weekday(1)
// sunday at 18:00:00 UTC
export const sunday = moment().weekday(0)

export type Direction = 'forwards' | 'backwards'

const momentIsInEnabledDays = (enabledDays: AttendancesDay[], dayToEvaluate: Moment) =>
  enabledDays.find((day) => dayToEvaluate.day() === day - 1)

export const findClosestEnabledDay = (
  date: Moment,
  isBackwards: boolean,
  enabledDays: AttendancesDay[]
): Moment => {
  const dayToEvaluate = isBackwards ? date.clone().subtract(1, 'day') : date.clone().add(1, 'day')
  let found = false

  while (!found) {
    if (momentIsInEnabledDays(enabledDays, dayToEvaluate)) found = true
    else isBackwards ? dayToEvaluate.subtract(1, 'day') : dayToEvaluate.add(1, 'day')
  }

  return dayToEvaluate
}

export const getDateRange = (
  enabledDays: AttendancesDay[],
  direction: Direction,
  daysToGet = 5,
  currentDates?: DateRange
): DateRange => {
  if (!enabledDays.length) throw new Error('No hay ningún día habilitado')
  const sortedDays = enabledDays.sort((dayA, dayB) => (dayA < dayB ? -1 : 1))

  const isBackwards = direction === 'backwards'
  const dayArr: Moment[] = []

  const startingPoint = currentDates
    ? isBackwards
      ? currentDates.startDate.clone()
      : currentDates.endDate.clone()
    : moment()
        .weekday(0)
        .add(isBackwards ? sortedDays[0] : sortedDays[0] - 2, 'day')

  while (dayArr.length < daysToGet) {
    const day = dayArr[dayArr.length - 1] || startingPoint
    const closestDay = findClosestEnabledDay(day, isBackwards, sortedDays)
    dayArr.push(closestDay)
  }

  return {
    startDate: isBackwards ? dayArr[dayArr.length - 1] : dayArr[0],
    endDate: isBackwards ? dayArr[0] : dayArr[dayArr.length - 1],
  }
}

export const getDatesBetween = (
  startDate: Moment,
  endDate: Moment,
  enabledDays: AttendancesDay[],
  includeEndDate?: boolean
) => {
  const dates: Moment[] = []
  const currentDate = startDate.clone()
  while (currentDate.isBefore(endDate)) {
    if (momentIsInEnabledDays(enabledDays, currentDate)) dates.push(currentDate.clone())
    currentDate.add(1, 'day')
  }
  if (includeEndDate) dates.push(endDate)
  return dates
}

export const toDays = (subjectsXDays: SubjectXDay[]) => {
  return [...new Set(subjectsXDays.map((SxD) => SxD.day))]
}

export const toSubjectIDs = (subjectsXDays: SubjectXDay[]) => {
  return [...new Set(subjectsXDays.map((SxD) => SxD.subjectId))]
}
