import type { SetField } from '@/legacy/redux/types/generic'
import type { GetSubjectsAsTeacherSuccess } from '@/legacy/redux/types/subjects'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type { FindClassroomReportCardConfigSuccess } from '@/sections/report-card/grading/types/mainView'

export interface ReportCardGradingReviewsTabState {
  error: string
  loadingReviews: boolean
  studentReviews: StudentReview[]
  bySubject: boolean
  selectedEvaluableConfigurationId: number
}

export interface StudentReview {
  studentId: number
  description: string
  evaluableConfigurationId?: number
}

export const GET_STUDENT_REVIEWS_REQUEST =
  'REPORT_CARD/GRADING/TABS/REVIEWS/GET_STUDENT_REVIEWS_REQUEST'
export const GET_STUDENT_REVIEWS_SUCCESS =
  'REPORT_CARD/GRADING/TABS/REVIEWS/GET_STUDENT_REVIEWS_SUCCESS'
export const GET_STUDENT_REVIEWS_FAILURE =
  'REPORT_CARD/GRADING/TABS/REVIEWS/GET_STUDENT_REVIEWS_FAILURE'

export type GetStudentReviewsRequest = GenericActionWithType<typeof GET_STUDENT_REVIEWS_REQUEST>

export type GetStudentReviewsSuccess = GenericActionWithType<
  typeof GET_STUDENT_REVIEWS_SUCCESS,
  { studentReviews: StudentReview[] }
>

export type GetStudentReviewsFailure = GenericActionWithType<
  typeof GET_STUDENT_REVIEWS_FAILURE,
  ErrorPayload
>

export type GetStudentReviews =
  | GetStudentReviewsRequest
  | GetStudentReviewsSuccess
  | GetStudentReviewsFailure

export type ReportCardGradingReviewsTabAction =
  | GetStudentReviews
  | GetSubjectsAsTeacherSuccess
  | FindClassroomReportCardConfigSuccess
  | SetField<'subjectId', number>
