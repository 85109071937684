import editor from './reducers/editor'
import imageUploader from './plugins/image-uploader/reducers/image-uploader'
import questions from './reducers/questions'
import studentFiles from './reducers/studentFiles'
import teacherFiles from './reducers/teacherFiles'
import { combineReducers } from 'redux'

export default combineReducers({
  editor,
  imageUploader,
  questions,
  studentFiles,
  teacherFiles,
})
