import type { Audience } from '@/api/lms/library'
import type { BasicAudience } from '@/api/lms/organizations'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { AVAILABILITY } from './books'

// State.
export interface PublicationState {
  availability: AVAILABILITY
  audienceModal: {
    isOpenModal: boolean
    isEditMode: boolean
    audiences: Audience[]
    status: REQUEST_STATUS
    searchText: string
    pagination: {
      count: number
      more: boolean
      page: number
      rowsPerPage: number
    }
    selectedAudiences: BasicAudience[]
  }
  audiences: BasicAudience[]
}

// Types.
export enum PUBLICATION_TYPES {
  SET_AVAILABILITY = 'CONTENT_CREATOR/NEW_SEQUENCES/PUBLICATION/SET_AVAILABILITY',
  CLEAN_PUBLICATION = 'CONTENT_CREATOR/NEW_SEQUENCES/PUBLICATION/CLEAN_PUBLICATION',
  OPEN_MODAL = 'CONTENT_CREATOR/NEW_SEQUENCES/PUBLICATION/OPEN_MODAL',
  CLOSE_MODAL = 'CONTENT_CREATOR/NEW_SEQUENCES/PUBLICATION/CLOSE_MODAL',
  SET_SEARCH_TEXT = 'CONTENT_CREATOR/NEW_SEQUENCES/PUBLICATION/SET_SEARCH_TEXT',
  GET_AUDIENCES_REQUEST = 'CONTENT_CREATOR/NEW_SEQUENCES/PUBLICATION/GET_AUDIENCES_REQUEST',
  GET_AUDIENCES_SUCCESS = 'CONTENT_CREATOR/NEW_SEQUENCES/PUBLICATION/GET_AUDIENCES_SUCCESS',
  GET_AUDIENCES_FAILURE = 'CONTENT_CREATOR/NEW_SEQUENCES/PUBLICATION/GET_AUDIENCES_FAILURE',
  SET_SELECTED_AUDIENCES = 'CONTENT_CREATOR/NEW_SEQUENCES/PUBLICATION/SET_SELECTED_AUDIENCES',
  SET_SELECTED_AUDIENCE = 'CONTENT_CREATOR/NEW_SEQUENCES/PUBLICATION/SET_SELECTED_AUDIENCE',
  SET_AUDIENCES = 'CONTENT_CREATOR/NEW_SEQUENCES/PUBLICATION/SET_AUDIENCES',
  CLEAN_MODAL = 'CONTENT_CREATOR/NEW_SEQUENCES/PUBLICATION/CLEAN_MODAL',
}

// Set availability action.
export type SetAvailabilityAction = {
  type: PUBLICATION_TYPES.SET_AVAILABILITY
  payload: {
    newAvailability: AVAILABILITY
  }
}

// Clean publication action.
export type CleanPublicationAction = {
  type: PUBLICATION_TYPES.CLEAN_PUBLICATION
}

// Open modal action.
export type OpenModalAction = {
  type: PUBLICATION_TYPES.OPEN_MODAL
}

// Close modal action.
export type CloseModalAction = {
  type: PUBLICATION_TYPES.CLOSE_MODAL
}

// Set search text action.
export type SetSearchTextAction = {
  type: PUBLICATION_TYPES.SET_SEARCH_TEXT
  payload: {
    newSearchText: string
  }
}

// Get audience actions.
export type GetAudiencesRequestAction = {
  type: PUBLICATION_TYPES.GET_AUDIENCES_REQUEST
}

export type GetAudiencesSuccessAction = {
  type: PUBLICATION_TYPES.GET_AUDIENCES_SUCCESS
  payload: {
    audiences: Audience[]
    count: number
    more: boolean
    page: number
  }
}

export type GetAudiencesFailureAction = {
  type: PUBLICATION_TYPES.GET_AUDIENCES_FAILURE
}

export type GetAudiencesAction =
  | GetAudiencesRequestAction
  | GetAudiencesSuccessAction
  | GetAudiencesFailureAction

// Set selected audiences action.
export type SetSelectedAudiencesAction = {
  type: PUBLICATION_TYPES.SET_SELECTED_AUDIENCES
  payload: {
    newSelectedAudiences: BasicAudience[]
  }
}

// Set selected audience action.
export type SetSelectedAudienceAction = {
  type: PUBLICATION_TYPES.SET_SELECTED_AUDIENCE
  payload: {
    audience: Audience
    isAddition: boolean
  }
}

// Set selected audiences action.
export type SetAudiencesAction = {
  type: PUBLICATION_TYPES.SET_AUDIENCES
  payload: {
    audiences: BasicAudience[]
  }
}

// Clean modal action.
export type CleanModalAction = {
  type: PUBLICATION_TYPES.CLEAN_MODAL
}

// Actions.
export type PublicationAction =
  | SetAvailabilityAction
  | CleanPublicationAction
  | OpenModalAction
  | CloseModalAction
  | SetSearchTextAction
  | GetAudiencesAction
  | SetSelectedAudiencesAction
  | SetSelectedAudienceAction
  | SetAudiencesAction
  | CleanModalAction
