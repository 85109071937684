import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/student/summary/types/tabs/authorized'

const initialState: Types.AuthorizedTabState = {
  status: REQUEST_STATUS.IDLE,
  data: [],
}

const authorizedTabReducer = (
  state: Types.AuthorizedTabState = initialState,
  action: Types.AuthorizedTabAction
): Types.AuthorizedTabState => {
  switch (action.type) {
    case Types.AUTHORIZED_TAB_TYPES.GET_AUTHORIZED_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.AUTHORIZED_TAB_TYPES.GET_AUTHORIZED_SUCCESS: {
      return {
        status: REQUEST_STATUS.SUCCESS,
        data: action.payload.data,
      }
    }
    case Types.AUTHORIZED_TAB_TYPES.GET_AUTHORIZED_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.AUTHORIZED_TAB_TYPES.RESET_TAB: {
      return initialState
    }
    default:
      return state
  }
}

export default authorizedTabReducer
