import type { CreateCSSProperties, CSSProperties } from 'react'

type CssStyledProps = {
  [key: string]: CSSProperties | CreateCSSProperties<{}>
}

// TODO use theme colors
export const scrollbarMenu: CssStyledProps = {
  '&::-webkit-scrollbar': {
    width: 8,
  },
  '&::-webkit-scrollbar-track': {
    marginTop: 1,
    marginBottom: 1,
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 4,
    background: '#e3e3e3',
  },
}

type ScrollBarArgs = {
  track?: CSSProperties
  thumb?: CSSProperties
  scrollbar?: CSSProperties
}

// TODO use theme colors
const defaultThumb = {
  background: '#cbcbcb',
  borderRadius: 4,
}

export const createScrollbar = ({
  thumb = defaultThumb,
  track = {},
  scrollbar = {},
}: ScrollBarArgs = {}): CssStyledProps => ({
  '&::-webkit-scrollbar': {
    width: 8,
    ...scrollbar,
  },
  '&::-webkit-scrollbar-track': {
    ...track,
  },
  '&::-webkit-scrollbar-thumb': {
    background: thumb.background ?? defaultThumb.background,
    borderRadius: thumb.borderRadius ?? defaultThumb.borderRadius,
    ...thumb,
  },
})
