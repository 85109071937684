export const AGENPIA_PALETTE = {
  primary: {
    light: '#DAE9EA',
    main: '#3F9F9B',
    dark: '#2B7573',
  },
  secondary: {
    light: '#DAE9EA',
    main: '#2B7573',
    dark: '#2B7573',
  },
}
