import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import CheckIcon from '@mui/icons-material/Check'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    maxWidth: 300,
    float: 'right',
  },
  select: {
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #D8DBE8',
    fontSize: 16,
    padding: '8px 26px 7px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#A051E6',
    },
  },
  menuItem: {
    fontWeight: theme.typography.fontWeightRegular,
    padding: '0px 16px',
    '&:last-child > div': {
      borderBottom: 'none',
    },
  },
  menuItemContainer: {
    width: '100%',
    display: 'flex',
    padding: '9px 0',
    borderBottom: '1px solid #D8DBE8',
    alignItems: 'center',
  },
  selectedMenuItem: {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#7D00DC',
    backgroundColor: 'inherit !important',
  },
  checkIcon: {
    fontSize: '15px',
    position: 'absolute',
    display: 'inline',
    right: '16px',
  },
  paper: {
    border: '1px solid #D8DBE8',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    width: 260,
  },
}))

const ClassroomPicker = (props) => {
  const { selectedClassroom, setSelectedClassroom, classrooms } = props
  const classes = useStyles()

  const handleChange = (event) => {
    const value = event.target.value
    setSelectedClassroom(value)
  }

  const MenuProps = {
    PaperProps: {
      elevation: 0,
    },
    classes: { list: classes.list, paper: classes.paper },
  }

  const renderClassroom = (classroom) => {
    if (!classroom) return ''
    let c = (
      <b>
        {classroom.grade}
        {classroom.division ? ` ${classroom.division}` : null}, {classroom.shift}
      </b>
    )
    if (classroom.organization)
      c = (
        <span>
          {c} - {classroom.organization.name}
        </span>
      )
    if (classroom.stage)
      c = (
        <span>
          {classroom.stage} {c}
        </span>
      )
    return c
  }

  return (
    <FormControl className={clsx(classes.formControl, classes.noLabel)}>
      <Select
        displayEmpty
        variant="outlined"
        value={selectedClassroom}
        onChange={handleChange}
        classes={{ select: classes.select }}
        renderValue={(selected) => {
          if (!selected) {
            return 'Todas las aulas'
          }

          return renderClassroom(classrooms.find((classroom) => classroom.id === selectedClassroom))
        }}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem
          disableRipple
          value={0}
          classes={{ root: classes.menuItem, selected: classes.selectedMenuItem }}
        >
          <div className={classes.menuItemContainer}>Todas las aulas</div>
        </MenuItem>

        {classrooms.map((classroom) => {
          const selected = classroom.id === selectedClassroom
          return (
            <MenuItem
              disableRipple
              key={classroom.id}
              value={classroom.id}
              classes={{ root: classes.menuItem, selected: classes.selectedMenuItem }}
              selected={selected}
            >
              <div className={classes.menuItemContainer}>
                {renderClassroom(classroom)}
                {selected && <CheckIcon className={clsx(classes.checkIcon)} />}
              </div>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default ClassroomPicker
