import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { SET_FIELD } from '@/legacy/redux/types/generic'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type {
  FinalAppreciationsDrawerActions,
  FinalAppreciationsDrawerState,
} from '@/sections/report-card/grading/types/finalAppreciationsDrawer'

const initialState: FinalAppreciationsDrawerState = {
  open: false,
  currentInput: '',
}

const ReportCardFinalAppreciationsDrawerReducer = (
  state: FinalAppreciationsDrawerState = initialState,
  action: FinalAppreciationsDrawerActions
) => {
  switch (action.type) {
    case SET_FIELD:
      return setGenericField(state, action.payload)
    default:
      return state
  }
}

export default checkRequestOrigin(
  initialState,
  ReportCardFinalAppreciationsDrawerReducer,
  reduxRequestOriginMap.REPORT_CARD_GRADING_FINAL_APPRECIATIONS_DRAWER
)
