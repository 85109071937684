import type { Reducer } from 'redux'
import { initialPagination } from '@/sections/management/types/classrooms/classroomsDrawer'
import * as Types from '@/sections/eb-admin/management/types/classroomsDrawer'
import type {
  ClassroomsDrawerState,
  ClassrooomsDrawerAction,
} from '@/sections/eb-admin/management/types/classroomsDrawer'

const initialState: ClassroomsDrawerState = {
  classrooms: [],
  count: 0,
  error: '',
  loading: false,
  pagination: initialPagination,
  search: '',
}

const AdminClassroomsDrawerReducer: Reducer<ClassroomsDrawerState, ClassrooomsDrawerAction> = (
  state = initialState,
  action
): ClassroomsDrawerState => {
  switch (action.type) {
    case Types.CLEAR_STATE:
      return initialState
    case Types.SET_PAGINATION_OFFSET:
      return { ...state, pagination: { ...state.pagination, offset: action.payload.offset } }
    case Types.SET_SEARCH:
      return { ...state, search: action.payload.search, pagination: initialPagination }
    case Types.GET_CLASSROOMS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case Types.GET_CLASSROOMS_SUCCESS: {
      const { classrooms, count } = action.payload
      return {
        ...state,
        loading: false,
        count,
        classrooms: classrooms,
      }
    }
    case Types.GET_CLASSROOMS_FAILURE:
      return { ...state, loading: false }
    default:
      return state
  }
}

export default AdminClassroomsDrawerReducer
