import { combineReducers } from 'redux'
import scores from './scores'
import attitudinalScores from './attitudinalScores'
import competenceScores from './competenceScores'
import reviews from './reviews'

export default combineReducers({
  scores,
  competenceScores,
  attitudinalScores,
  reviews,
})
