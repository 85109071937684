import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export const GET_PENDING_EXOR_REQUEST = 'TEACHER_ORAL_EXAMS/GET_PENDING_EXOR_REQUEST'
export const GET_PENDING_EXOR_SUCCESS = 'TEACHER_ORAL_EXAMS/GET_PENDING_EXOR_SUCCESS'
export const GET_PENDING_EXOR_FAILURE = 'TEACHER_ORAL_EXAMS/GET_PENDING_EXOR_FAILURE'

export const GET_EXOR_STUDENTS_REQUEST = 'TEACHER_ORAL_EXAMS/GET_EXOR_STUDENTS_REQUEST'
export const GET_EXOR_STUDENTS_SUCCESS = 'TEACHER_ORAL_EXAMS/GET_EXOR_STUDENTS_SUCCESS'
export const GET_EXOR_STUDENTS_FAILURE = 'TEACHER_ORAL_EXAMS/GET_EXOR_STUDENTS_FAILURE'

export const GET_PENDING_EXOR_QUANTITY_REQUEST =
  'TEACHER_ORAL_EXAMS/GET_PENDING_EXOR_QUANTITY_REQUEST'
export const GET_PENDING_EXOR_QUANTITY_SUCCESS =
  'TEACHER_ORAL_EXAMS/GET_PENDING_EXOR_QUANTITY_SUCCESS'
export const GET_PENDING_EXOR_QUANTITY_FAILURE =
  'TEACHER_ORAL_EXAMS/GET_PENDING_EXOR_QUANTITY_FAILURE'

export const GET_EXOR_STUDENTS_QUANTITY_REQUEST =
  'TEACHER_ORAL_EXAMS/GET_EXOR_STUDENTS_QUANTITY_REQUEST'
export const GET_EXOR_STUDENTS_QUANTITY_SUCCESS =
  'TEACHER_ORAL_EXAMS/GET_EXOR_STUDENTS_QUANTITY_SUCCESS'
export const GET_EXOR_STUDENTS_QUANTITY_FAILURE =
  'TEACHER_ORAL_EXAMS/GET_EXOR_STUDENTS_QUANTITY_FAILURE'

export const CREATE_EXOR_INSTANCE_REQUEST = 'TEACHER_ORAL_EXAMS/CREATE_EXOR_INSTANCE_REQUEST'
export const CREATE_EXOR_INSTANCE_SUCCESS = 'TEACHER_ORAL_EXAMS/CREATE_EXOR_INSTANCE_SUCCESS'
export const CREATE_EXOR_INSTANCE_FAILURE = 'TEACHER_ORAL_EXAMS/CREATE_EXOR_INSTANCE_FAILURE'

export const OPEN_GRADING_CARD = 'TEACHER_ORAL_EXAMS/OPEN_GRADING_CARD'
export const CLOSE_GRADING_CARD = 'TEACHER_ORAL_EXAMS/CLOSE_GRADING_CARD'

export const SET_EXAM_MESSAGE = 'TEACHER_ORAL_EXAMS/SET_EXAM_MESSAGE'

export const EXOR_PICKED_UP = 'TEACHER_ORAL_EXAMS/EXOR_PICKED_UP'
export const SET_CURRENTLY_CALLING = 'TEACHER_ORAL_EXAMS/SET_CURRENTLY_CALLING'

export const EVALUATE_EXOR_SUCCESS = 'TEACHER_ORAL_EXAMS/EVALUATE_EXOR_SUCCESS'

export interface Subject {
  color: string
  id: number
  name: string
}

export interface Activity {
  chatEnabled: boolean
  classroomId: number
  date: string
  deadline: string
  endDate: string
  evaluationType: number
  id: number
  publicationDate: string
  robotsId: number
  robotsPassword: string
  startDate: string
  subject: Subject
  title: string
  type: string
  videocallUrl: string
}

export interface Classroom {
  division: string
  grade: string
  id: number
  shift: string
  year: number
  stage: string
}

export interface Organization {
  id: number
  name: string
}

export interface Student {
  email: string
  id: number
  robotsId: number
  lastName: string
  name: string
}

export interface StudentExor {
  activity: Activity
  classroom: Classroom
  organization: Organization
  student: Student
}

export interface ExorInstance {
  activityId: number
  createdAt: string
  updatedAt?: string
  evaluateeId: number
  evaluatorId: number
  id: number
  videocallUrl: string
}

export enum ExorStatusValue {
  NOT_STARTED = 'not-started',
  WAITING = 'waiting',
}

export interface ExorStatus {
  activityId: number
  evaluateeId: number
  value: ExorStatusValue
}

export interface PendingExor {
  activity: Activity
  classroom: Classroom
  organization: Organization
  student: Student
  status: ExorStatus
  loadingVideocall?: boolean
  submitted: boolean
}

export interface GetPendingEXORsRequest {
  type: typeof GET_PENDING_EXOR_REQUEST
}

export interface GetPendingEXORsSuccess {
  type: typeof GET_PENDING_EXOR_SUCCESS
  payload: {
    exor: PendingExor[]
  }
}

export interface GetPendingEXORsFailure {
  type: typeof GET_PENDING_EXOR_FAILURE
  payload: {
    error: string
  }
}

export interface GetPendingEXORsQuantityRequest {
  type: typeof GET_PENDING_EXOR_QUANTITY_REQUEST
}

export interface GetPendingEXORsQuantitySuccess {
  type: typeof GET_PENDING_EXOR_QUANTITY_SUCCESS
  payload: {
    exorQuantity: number
  }
}

export interface GetPendingEXORsQuantityFailure {
  type: typeof GET_PENDING_EXOR_QUANTITY_FAILURE
  payload: {
    error: string
  }
}

export interface GetEXESStudentsRequest {
  type: typeof GET_EXOR_STUDENTS_REQUEST
}

export interface GetEXESStudentsSuccess {
  type: typeof GET_EXOR_STUDENTS_SUCCESS
  payload: {
    exor: StudentExor[]
  }
}

export interface GetEXESStudentsFailure {
  type: typeof GET_EXOR_STUDENTS_FAILURE
  payload: {
    error: string
  }
}

export interface GetEXESStudentsQuantityRequest {
  type: typeof GET_EXOR_STUDENTS_QUANTITY_REQUEST
}

export interface GetEXESStudentsQuantitySuccess {
  type: typeof GET_EXOR_STUDENTS_QUANTITY_SUCCESS
  payload: {
    exorQuantity: number
  }
}

export interface GetEXESStudentsQuantityFailure {
  type: typeof GET_EXOR_STUDENTS_QUANTITY_FAILURE
  payload: {
    error: string
  }
}

export interface CreateEXORInstanceRequest {
  type: typeof CREATE_EXOR_INSTANCE_REQUEST
  payload: {
    evaluateeId: number
    activityId: number
  }
}

export interface CreateEXORInstanceSuccess {
  type: typeof CREATE_EXOR_INSTANCE_SUCCESS
  payload: ExorInstance
}

export interface CreateEXORInstanceFailure {
  type: typeof CREATE_EXOR_INSTANCE_FAILURE
  payload: {
    error: string
  }
}

export interface OpenGradingCard {
  type: typeof OPEN_GRADING_CARD
}

export interface CloseGradingCard {
  type: typeof CLOSE_GRADING_CARD
}

export interface EvaluateEXORSuccess {
  type: typeof EVALUATE_EXOR_SUCCESS
  payload: {
    studentId: number
    activityId: number
  }
}

export interface WrittenExamsState {
  pendingExors: PendingExor[]
  exorStudents: StudentExor[]
  pendingExorsQuantity: number
  exorStudentsQuantity: number
  pendingExorsStatus: REQUEST_STATUS
  loadingExorStudents: boolean
  loadingExorStudentsQuantity: boolean
  loadingPendingExorsQuantity: boolean
  errorPendingExors: string
  errorExorStudents: string
  errorPendingExorsQuantity: string
  errorExorStudentsQuantity: string
  openEvaluation: boolean
  examMessage: {
    open: boolean
    message: string
    color: string
  }
  currentlyCalling: ExorInstance | null
}

export interface SetExamMessage {
  type: typeof SET_EXAM_MESSAGE
  payload: {
    open: boolean
    message: string
    color: string
  }
}

export interface ExorPickedUp {
  type: typeof EXOR_PICKED_UP
  payload: ExorInstance
}

export interface SetCurrentlyCalling {
  type: typeof SET_CURRENTLY_CALLING
  payload: {
    evaluateeId: number
  }
}

export type WrittenExamActions =
  | GetPendingEXORsRequest
  | GetPendingEXORsSuccess
  | GetPendingEXORsFailure
  | GetPendingEXORsQuantityRequest
  | GetPendingEXORsQuantitySuccess
  | GetPendingEXORsQuantityFailure
  | GetEXESStudentsRequest
  | GetEXESStudentsSuccess
  | GetEXESStudentsFailure
  | GetEXESStudentsQuantityRequest
  | GetEXESStudentsQuantitySuccess
  | GetEXESStudentsQuantityFailure
  | CreateEXORInstanceRequest
  | CreateEXORInstanceSuccess
  | CreateEXORInstanceFailure
  | OpenGradingCard
  | CloseGradingCard
  | EvaluateEXORSuccess
  | SetExamMessage
  | ExorPickedUp
  | SetCurrentlyCalling
