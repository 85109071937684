import { combineReducers } from 'redux'
import information from './information'
import authorized from './authorized'
import bulletin from './bulletin'
import attendances from './attendances'
import activities from './activities'
import schoolRecords from './schoolRecords'

export default combineReducers({
  information,
  authorized,
  bulletin,
  attendances,
  activities,
  schoolRecords,
})
