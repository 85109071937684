import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import * as Types from '@/sections/report-card/grading/types/tabs/attitudinalScoresTab'

const initialState: Types.ReportCardGradingAttitudinalScoresTabState = {
  loadingScores: false,
  error: '',
  studentAttitudinalScores: [],
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_GRADING_ATTITUDINAL_SCORES_TAB

const ReportCardGradingAttitudinalScoresTab: Reducer<
  Types.ReportCardGradingAttitudinalScoresTabState,
  Types.ReportCardGradingAttitudinalScoresTabAction
> = (state = initialState, action): Types.ReportCardGradingAttitudinalScoresTabState => {
  switch (action.type) {
    case Types.GET_STUDENT_ATTITUDINAL_SCORES_REQUEST:
      return { ...state, loadingScores: true, error: initialState.error }
    case Types.GET_STUDENT_ATTITUDINAL_SCORES_SUCCESS:
      return {
        ...state,
        loadingScores: false,
        studentAttitudinalScores: action.payload.studentAttitudinalScores,
      }
    case Types.GET_STUDENT_ATTITUDINAL_SCORES_FAILURE:
      return { ...state, loadingScores: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(
  initialState,
  ReportCardGradingAttitudinalScoresTab,
  requestDestination
)
