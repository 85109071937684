export const states = {
  DEFAULT: 'default',
  NEW: 'new',
  DELAYED: 'delayed',
  COMPLETED: 'completed',
  AWAITING_APPROVAL: 'awaiting_approval',
}

export const shouldShowStateLabel = (state) => isNew(state) || isDelayed(state)
export const isActivityFinished = (state) => isAwaitingApproval(state) || isCompleted(state)

export const isNew = (state) => state === states.NEW
export const isDelayed = (state) => state === states.DELAYED
export const isAwaitingApproval = (state) => state === states.AWAITING_APPROVAL
export const isCompleted = (state) => state === states.COMPLETED
