import { clone } from 'ramda'
import type { Reducer } from 'redux'
import { Redux } from '@/legacy/redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import { ScoreType } from '@/sections/report-card/types/common'
import * as Types from '@/sections/report-card/grading/types/gradingForms/scores'
import { FIND_CLASSROOM_REPORT_CARD_CONFIG_SUCCESS } from '@/sections/report-card/grading/types/mainView'

const initialState: Types.ScoresGradingFormState = {
  scoreType: ScoreType.NUMERICAL,
  conceptScoreValues: [],
  numericalScoreValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  loading: false,
  error: '',
  currentValue: 0,
  observation: '',
  mainValueAlreadyExists: false,
  subitems: {},
  resit: false,
  finalScore: '',
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_GRADING_SCORES_FORM

const ReportCardGradingScoresForm: Reducer<
  Types.ScoresGradingFormState,
  Types.ScoresGradingFormAction
> = (state = initialState, action): Types.ScoresGradingFormState => {
  switch (action.type) {
    case Types.CLEAR_STATE:
      return {
        ...initialState,
        conceptScoreValues: state.conceptScoreValues,
        numericalScoreValues: state.numericalScoreValues,
      }
    case Redux.Types.Generic.SET_FIELD:
      return setGenericField(state, action.payload)
    case Types.SET_RESIT:
      return { ...state, resit: action.payload.resit }
    case FIND_CLASSROOM_REPORT_CARD_CONFIG_SUCCESS: {
      const conceptScoresValues = action.payload.config?.scores.conceptScores.values
      return {
        ...state,
        conceptScoreValues: conceptScoresValues || initialState.conceptScoreValues,
        currentValue: conceptScoresValues?.length
          ? conceptScoresValues[0].id
          : initialState.currentValue,
      }
    }
    case Types.INIT_STUDENT_SCORE: {
      const { currentValue, scoreType, subjectSubitems, studentSubitems } = action.payload

      const isConcept = scoreType === ScoreType.CONCEPTUAL
      const subitems: Types.SubitemMap = {}

      if (subjectSubitems) {
        subjectSubitems?.forEach((ss) => {
          subitems[ss.orderValue] = {
            alreadyExists: false,
            orderValue: ss.orderValue,
            name: ss.name,
            ...(isConcept
              ? {
                  valueId: 0,
                  score: '',
                }
              : {
                  value: 0,
                }),
          }
        })

        studentSubitems?.forEach((ss) => {
          if (!subitems[ss.orderValue]) return

          subitems[ss.orderValue] = {
            orderValue: subitems[ss.orderValue].orderValue,
            name: subitems[ss.orderValue].name,
            ...(isConcept
              ? {
                  alreadyExists: Boolean(ss.valueId) && ss.value !== 0,
                  valueId: ss.valueId || 0,
                  score: ss.score || '',
                }
              : {
                  alreadyExists: Boolean(ss.value) && ss.value !== 0,
                  value: ss.value || 0,
                }),
          }
        })
      }

      return {
        ...state,
        subitems,
        scoreType: scoreType,
        currentValue: currentValue || initialState.currentValue,
        observation: action.payload.observation || '',
        mainValueAlreadyExists: Boolean(currentValue),
        finalScore: action.payload.finalScore || '',
        resit: action.payload.resit || false,
      }
    }
    case Types.SET_SUBITEM_SCORE: {
      const { value, orderValue } = action.payload
      const isConcept = state.scoreType === ScoreType.CONCEPTUAL

      let newSubitems = clone(state.subitems)

      if (newSubitems[orderValue]) {
        newSubitems = {
          ...newSubitems,
          [orderValue]: {
            alreadyExists: true,
            orderValue: orderValue,
            name: newSubitems[orderValue].name,
            ...(isConcept
              ? {
                  valueId: value || 0,
                  score: state.conceptScoreValues.find((csv) => csv.name)?.name || '',
                }
              : {
                  value,
                }),
          },
        }
      }

      return {
        ...state,
        subitems: newSubitems,
      }
    }
    case Types.SAVE_OR_EDIT_SCORE_REQUEST:
      return { ...state, loading: true, error: initialState.error }
    case Types.SAVE_OR_EDIT_SCORE_SUCCESS:
      return { ...state, loading: false }
    case Types.SAVE_OR_EDIT_SCORE_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, ReportCardGradingScoresForm, requestDestination)
