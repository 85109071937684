import type { CancelTokenSource } from 'axios'
import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type { ErrorPayload, GenericAction, GenericActionWithType } from '@/legacy/redux/utils'

export interface EditUserProfileState {
  loading: boolean
  error: string
  file: File | null
  currentPassword: string
  newPassword: string
  repeatPassword: string
  cancelToken: CancelTokenSource | null
}

export const SET_PASSWORD = 'AUTH/SET_PASSWORD'
export const SET_REPEAT_PASSWORD = 'AUTH/SET_REPEAT_PASSWORD'

export const UPDATE_USER_PASSWORD_REQUEST = 'USER/PROFILE/UPDATE_USER_PASSWORD_REQUEST'
export const UPDATE_USER_PASSWORD_SUCCESS = 'USER/PROFILE/UPDATE_USER_PASSWORD_SUCCESS'
export const UPDATE_USER_PASSWORD_FAILURE = 'USER/PROFILE/UPDATE_USER_PASSWORD_FAILURE'

export const UPLOAD_AVATAR_REQUEST = 'USER/PROFILE/UPLOAD_AVATAR_REQUEST'
export const UPLOAD_AVATAR_SUCCESS = 'USER/PROFILE/UPLOAD_AVATAR_SUCCESS'
export const UPLOAD_AVATAR_FAILURE = 'USER/PROFILE/UPLOAD_AVATAR_FAILURE'
export const UPLOAD_AVATAR_CANCEL = 'USER/PROFILE/UPLOAD_AVATAR_CANCEL'

export type SetPassword = GenericActionWithType<
  typeof SET_PASSWORD,
  { password: string; error: string }
>

export type SetRepeatPassword = GenericActionWithType<
  typeof SET_REPEAT_PASSWORD,
  { repeatPassword: string; error: string }
>

export interface UpdateUserPasswordRequest extends GenericAction {
  type: typeof UPDATE_USER_PASSWORD_REQUEST
}

export interface UpdateUserPasswordSuccess extends GenericAction {
  type: typeof UPDATE_USER_PASSWORD_SUCCESS
}

export interface UpdateUserPasswordFailure extends GenericAction {
  type: typeof UPDATE_USER_PASSWORD_FAILURE
  payload: ErrorPayload
}

export type UpdateUserPasswordAction =
  | UpdateUserPasswordRequest
  | UpdateUserPasswordSuccess
  | UpdateUserPasswordFailure

export type UploadAvatarRequest = GenericActionWithType<
  typeof UPLOAD_AVATAR_REQUEST,
  { cancelToken: CancelTokenSource }
>

export interface UploadAvatarSuccess extends GenericAction {
  type: typeof UPLOAD_AVATAR_SUCCESS
}

export interface UploadAvatarFailure extends GenericAction {
  type: typeof UPLOAD_AVATAR_FAILURE
  payload: ErrorPayload
}

export interface CancelUploadAvatar extends GenericAction {
  type: typeof UPLOAD_AVATAR_CANCEL
}

export type UploadAvatarAction =
  | UploadAvatarRequest
  | UploadAvatarSuccess
  | UploadAvatarFailure
  | CancelUploadAvatar

export type EditUserProfileActions =
  | SetField<'currentPassword', string>
  | ClearState
  | SetPassword
  | SetRepeatPassword
  | UpdateUserPasswordAction
  | UploadAvatarAction
