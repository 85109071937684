import React from 'react'
import { CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import chroma from 'chroma-js'

const useStyles = makeStyles({
  progressContainer: {
    display: 'inline-flex',
    height: 'fit-content',
    alignItems: 'center',
    position: 'relative',
  },
  progress: ({ color }) => ({
    color: color || '#50d073',
    position: 'relative',
    zIndex: 1,
  }),
  progressBackground: ({ color }) => ({
    color: color ? chroma(color).alpha(0.2).hex() : '#dcf6e3',
    position: 'absolute',
    zIndex: 0,
  }),
})

const ProgressCircle = ({ size, thickness, value, color }) => {
  const classes = useStyles({ color })
  return (
    <div className={classes.progressContainer}>
      <CircularProgress
        classes={{ root: classes.progressBackground }}
        size={size}
        thickness={thickness}
        value={100}
        variant="determinate"
      />
      <CircularProgress
        classes={{ root: classes.progress }}
        size={size}
        thickness={thickness}
        value={value}
        variant="determinate"
      />
    </div>
  )
}

export default ProgressCircle
