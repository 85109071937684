import { ClearState } from "@/legacy/redux/types/generic"
import { ErrorPayload, GenericActionWithType } from "@/legacy/redux/utils"

export type DeleteThemeModalState = {
  themeId: number
  loading: boolean
  open: boolean
  error: string
}

export const SET_INITIAL_VALUES = 'THEMES/DELETE_MODAL/SET_INITIAL_VALUES'

export type SetInitialValues = GenericActionWithType<typeof SET_INITIAL_VALUES, { themeId: number }>

export const DELETE_REQUEST = 'THEMES/DELETE_REQUEST'
export const DELETE_SUCCESS = 'THEMES/DELETE_SUCCESS'
export const DELETE_FAILURE = 'THEMES/DELETE_FAILURE'

export type DeleteRequest = GenericActionWithType<typeof DELETE_REQUEST>
export type DeleteSuccess = GenericActionWithType<typeof DELETE_SUCCESS>
export type DeleteFailure = GenericActionWithType<typeof DELETE_FAILURE, ErrorPayload>

export type DeleteAction = DeleteRequest | DeleteSuccess | DeleteFailure

export type DeleteThemeModalAction = SetInitialValues | DeleteAction | ClearState
