import React from 'react'
import { states } from '@/legacy/components/v2/ribbons/states'
import ActivityRibbonBase from './activityRibbonBase'
import { activityRibbonMap } from '@/legacy/components/v2/ribbons/types'
export default function WrittenExamRibbon(props) {
  const { exes } = activityRibbonMap
  return (
    <ActivityRibbonBase
      Icon={exes.icon}
      type={exes.type}
      calendarLabel="components.ribbon.student.calendarLabel"
      {...props}
    />
  )
}

WrittenExamRibbon.defaultProps = {
  subject: {},
  state: states.DEFAULT,
  buttonEnabled: true,
  buttonLabel: 'generic.play',
  buttonProps: {},
  messages: 0,
}
