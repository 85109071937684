export const SET_POPUP_OPEN = 'VIDEO_POPUP/OPEN_POPUP'
export const SET_POPUP_CLOSED = 'VIDEO_POPUP/CLOSE_POPUP'

export interface OpenPopup {
  type: typeof SET_POPUP_OPEN
  payload: {
    video: string
  }
}

export interface ClosePopup {
  type: typeof SET_POPUP_CLOSED
}

export interface VideoPopupState {
  popupOpen: boolean
  video: string
}

export type VideoPopupActions = OpenPopup | ClosePopup
