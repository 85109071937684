import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { Pages } from '@/sections/content-creator/dashboard/types/dashboard'
import type * as sequencesTypes from '@/sections/content-creator/sequences/types/sequences'

export const SET_SEARCH = 'TEACHER/SEQUENCES/SET_SEARCH'
export const SET_SEQUENCE_CARD_CLOSED = 'TEACHER/SEQUENCES/SET_SEQUENCE_CARD_CLOSED'
export const TOGGLE_ONLY_WITH_LICENSES = 'TEACHER/SEQUENCES/TOGGLE_ONLY_WITH_LICENSES'
export const GET_TEACHER_SEQUENCES_REQUEST = 'TEACHER/SEQUENCES/GET_TEACHER_SEQUENCES_REQUEST'
export const GET_TEACHER_SEQUENCES_SUCCESS = 'TEACHER/SEQUENCES/GET_TEACHER_SEQUENCES_SUCCESS'
export const GET_TEACHER_SEQUENCES_FAILURE = 'TEACHER/SEQUENCES/GET_TEACHER_SEQUENCES_FAILURE'
export const GET_TEACHER_SEQUENCES_ALL_TAGS_REQUEST = 'TEACHER/SEQUENCES/GET_ALL_TAGS_REQUEST'
export const GET_TEACHER_SEQUENCES_ALL_TAGS_SUCCESS = 'TEACHER/SEQUENCES/GET_ALL_TAGS_SUCCESS'
export const GET_TEACHER_SEQUENCES_ALL_TAGS_FAILURE = 'TEACHER/SEQUENCES/GET_ALL_TAGS_FAILURE'

export interface SetSequenceCardClosed {
  type: typeof SET_SEQUENCE_CARD_CLOSED
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface ToggleOnlyWithLicenses {
  type: typeof TOGGLE_ONLY_WITH_LICENSES
  payload: {
    requestOrigin: ReduxRequestOrigin
    onlyWithLicenses: boolean
  }
}

export interface GetTeacherSequencesRequest {
  type: typeof GET_TEACHER_SEQUENCES_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
    page: number
    rowsPerPage: number
  }
}

export interface GetTeacherSequencesSuccess {
  type: typeof GET_TEACHER_SEQUENCES_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    sequences: sequencesTypes.Sequence[]
    count: number
  }
}

export interface GetTeacherSequencesFailure {
  type: typeof GET_TEACHER_SEQUENCES_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}

export interface SetSearch {
  type: typeof SET_SEARCH
  payload: {
    requestOrigin: ReduxRequestOrigin
    text: string
  }
}

export type LibraryResourceTag = {
  id: number
  name: string
}

export type LibraryResourceTagCategory = {
  name: string
  tags: LibraryResourceTag[]
}

export interface GetAllTagsRequest {
  type: typeof GET_TEACHER_SEQUENCES_ALL_TAGS_REQUEST
  payload: {
    requestOrigin: ReduxRequestOrigin
  }
}

export interface GetAllTagsSuccess {
  type: typeof GET_TEACHER_SEQUENCES_ALL_TAGS_SUCCESS
  payload: {
    requestOrigin: ReduxRequestOrigin
    tagCategories: LibraryResourceTagCategory[]
  }
}

export interface GetAllTagsFailure {
  type: typeof GET_TEACHER_SEQUENCES_ALL_TAGS_FAILURE
  payload: {
    requestOrigin: ReduxRequestOrigin
    error: string
  }
}
export interface TeacherLibrarySequencesState {
  sequences: sequencesTypes.Sequence[]
  loadingSequences: boolean
  sequencesPagination: Pages
  onlyWithLicenses: boolean
  error: string
  search: string
  tags: LibraryResourceTagCategory[]
}

export type TeacherLibrarySequencesAction =
  | SetSearch
  | SetSequenceCardClosed
  | GetTeacherSequencesRequest
  | GetTeacherSequencesSuccess
  | GetTeacherSequencesFailure
  | ToggleOnlyWithLicenses
  | sequencesTypes.GetSequenceRequest
  | sequencesTypes.GetSequenceSuccess
  | sequencesTypes.GetSequenceFailure
  | sequencesTypes.SetSelectedActivities
  | sequencesTypes.AddActivity
  | sequencesTypes.RemoveActivity
  | GetAllTagsRequest
  | GetAllTagsSuccess
  | GetAllTagsFailure
