import type { RobotsTokenSuccess, AddRobotsEventSuccess } from './types'
import {
  ROBOTS_TOKEN_REQUEST,
  ROBOTS_TOKEN_SUCCESS,
  ROBOTS_TOKEN_FAILURE,
  ADD_ROBOTS_EVENT_REQUEST,
  ADD_ROBOTS_EVENT_SUCCESS,
  ADD_ROBOTS_EVENT_FAILURE,
} from './types'
import type { AppThunk } from '@/state/thunk'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import { url } from '@aula/config'
import { apiPrivate } from '@/api'

const actions = {
  // classroomID is deprecated and not necessary
  getRobotsToken:
    (classroomID: number, activityID: number): AppThunk =>
    (dispatch) => {
      dispatch({ type: ROBOTS_TOKEN_REQUEST })

      return apiPrivate
        .post(url + `/v1/robots/${activityID}/redirect`)
        .then((response) => {
          const { token } = response.data.description
          dispatch(actions.getRobotsTokenSuccess())
          dispatch(actions.addRobotsEvent(classroomID, activityID))
          return token
        })
        .catch((error) => {
          handleErrorsWithAction(error, ROBOTS_TOKEN_FAILURE, dispatch)
          return false
        })
    },

  getRobotsTokenSuccess: (): RobotsTokenSuccess => ({
    type: ROBOTS_TOKEN_SUCCESS,
  }),

  // classroomID is deprecated and not necessary
  addRobotsEvent:
    (classroomID: number, activityID: number): AppThunk =>
    (dispatch) => {
      dispatch({ type: ADD_ROBOTS_EVENT_REQUEST })

      return apiPrivate
        .post(`${url}/v1/robots/${activityID}/events?event=joined`)
        .then(() => {
          dispatch(actions.addRobotsEventSuccess())
          return true
        })
        .catch((error) => {
          handleErrorsWithAction(error, ADD_ROBOTS_EVENT_FAILURE, dispatch)
          return false
        })
    },

  addRobotsEventSuccess: (): AddRobotsEventSuccess => ({
    type: ADD_ROBOTS_EVENT_SUCCESS,
  }),
}

export default actions
