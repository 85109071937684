import type { COSMOS_KEY } from '@aula/config'
import type { ClearState } from '@/legacy/redux/types/generic'
import type { GenericActionWithType } from '@/legacy/redux/utils'

export type CosmosModalState = {
  open: boolean
  cosmos: COSMOS_KEY[]
  selectedCosmos: COSMOS_KEY[]
}

export const TOGGLE_COSMOS = 'MANAGEMENT/COSMOS_MODAL/TOGGLE_COSMOS'
export type ToggleCosmos = GenericActionWithType<typeof TOGGLE_COSMOS, { cosmos: COSMOS_KEY }>

export const SET_OPEN = 'MANAGEMENT/COSMOS_MODAL/SET_OPEN'
export type SetOpen = GenericActionWithType<typeof SET_OPEN, { selectedCosmos: COSMOS_KEY[] }>

export type CosmosModalAction = ToggleCosmos | SetOpen | ClearState
