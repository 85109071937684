export type ScoreOption = {
  max: number
  value: string
}

// default conceptual score options
export const scoreOptions: ScoreOption[] = [
  { max: 400, value: 'insufficient' },
  { max: 600, value: 'regular' },
  { max: 800, value: 'good' },
  { max: 900, value: 'veryGood' },
  { max: 1000, value: 'outstanding' },
]
