import localforage from 'localforage'
import moment from 'moment'
import { persistReducer } from 'redux-persist'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import * as ContentCreatorBooksTypes from '@/sections/content-creator/books/types/books'
import { SET_FILTERS } from '@/sections/teacher/library/types/library'
import type {
  LibraryBooksActions,
  LibraryBooksState,
} from '@/sections/teacher/library/types/libraryBooks'
import {
  CREATE_LICENSE_FAILURE,
  CREATE_LICENSE_REQUEST,
  CREATE_LICENSE_SUCCESS,
  GET_ALL_TAGS_FAILURE,
  GET_ALL_TAGS_SUCCESS,
  GET_BOOK_ACTIVITIES_FAILURE,
  GET_BOOK_ACTIVITIES_REQUEST,
  GET_BOOK_ACTIVITIES_SUCCESS,
  GET_BOOK_LICENSES_FAILURE,
  GET_BOOK_LICENSES_REQUEST,
  GET_BOOK_LICENSES_SUCCESS,
  GET_CLASSROOMS_FAILURE,
  GET_CLASSROOMS_REQUEST,
  GET_CLASSROOMS_SUCCESS,
  GET_CLASSROOM_SUBGROUPS_FAILURE,
  GET_CLASSROOM_SUBGROUPS_REQUEST,
  GET_CLASSROOM_SUBGROUPS_SUCCESS,
  GET_LICENSE_STOCK_FAILURE,
  GET_LICENSE_STOCK_REQUEST,
  GET_LICENSE_STOCK_SUCCESS,
  REVOKE_BULK_LICENSE_FAILURE,
  REVOKE_BULK_LICENSE_REQUEST,
  REVOKE_BULK_LICENSE_SUCCESS,
  SELECT_CLASSROOM,
  SELECT_SUBGROUPS,
  SET_EXPIRY,
  SET_LICENSE_DELETION_MODAL_OPEN,
  SET_MODAL_VIEW,
  SET_ONLY_WITH_LICENSES,
  SET_OPEN,
} from '@/sections/teacher/library/types/libraryBooks'

const initialState: LibraryBooksState = {
  open: false,
  loading: true,
  loadingBookLicenses: false,
  loadingBookActivities: false,
  loadingBookLicenseStock: false,
  loadingNewLicense: false,
  loadingLicenseDeletion: false,
  licenseDeletionModalOpen: false,
  selectedLicense: null,
  books: [],
  selectedBook: {
    id: 0,
    createdAt: '',
    userID: 0,
    organizationID: 0,
    title: '',
    description: '',
    fileID: 0,
    availability: 'free',
    isbn: '',
    url: '',
    coverURL: '',
  },
  selectedBookLicenses: [],
  selectedBookActivities: [],
  selectedBookLicenseStock: null,
  error: '',
  classroomID: 0,
  lastClassroomID: 0,
  subgroups: [],
  selectedSubgroups: [],
  expiry: moment().clone().add(1, 'days'),
  modalView: ContentCreatorBooksTypes.ACTIVITIES_VIEW,
  loadingClassrooms: false,
  loadingClassroomOptions: false,
  classrooms: [],
  classroom: {
    id: 0,
    division: '',
    grade: '',
    organization: {
      id: 0,
      name: '',
    },
    organizationType: '',
    shift: '',
    year: 0,
  },
  booksPagination: {
    page: 0,
    rowsPerPage: 12,
    count: 0,
  },
  tags: [],
  onlyWithLicenses: false,
}

const requestDestination = reduxRequestOriginMap.TEACHER_LIBRARY_BOOKS

function root(
  state: LibraryBooksState = initialState,
  action: LibraryBooksActions
): LibraryBooksState {
  switch (action.type) {
    case SET_OPEN: {
      const { open, book } = action.payload
      return {
        ...state,
        open,
        selectedBook: open ? book : initialState.selectedBook,
        modalView: initialState.modalView,
      }
    }
    case SET_LICENSE_DELETION_MODAL_OPEN: {
      return {
        ...state,
        licenseDeletionModalOpen: action.payload.open,
        selectedLicense: action.payload.selectedLicense || null,
      }
    }
    case ContentCreatorBooksTypes.GET_ORGANIZATION_BOOKS_REQUEST: {
      if (action.payload.requestOrigin === requestDestination) {
        return {
          ...state,
          loading: true,
          booksPagination: {
            ...state.booksPagination,
            page: action.payload.page,
            rowsPerPage: action.payload.rowsPerPage,
          },
        }
      } else {
        return state
      }
    }
    case ContentCreatorBooksTypes.GET_ORGANIZATION_BOOKS_SUCCESS: {
      if (action.payload.requestOrigin === requestDestination) {
        return {
          ...state,
          loading: false,
          error: '',
          books: action.payload.books,
          booksPagination: {
            ...state.booksPagination,
            count: action.payload.count,
          },
        }
      } else {
        return state
      }
    }
    case ContentCreatorBooksTypes.GET_ORGANIZATION_BOOKS_FAILURE: {
      if (action.payload.requestOrigin === requestDestination) {
        return { ...state, loading: false, error: '' }
      } else {
        return state
      }
    }
    case GET_BOOK_LICENSES_REQUEST: {
      return { ...state, loadingBookLicenses: true, selectedBookLicenses: [] }
    }
    case GET_BOOK_LICENSES_SUCCESS: {
      return {
        ...state,
        loadingBookLicenses: false,
        selectedBookLicenses: action.payload.bookLicenses,
        error: '',
      }
    }
    case GET_BOOK_LICENSES_FAILURE: {
      return {
        ...state,
        selectedBookLicenses: [],
        error: action.payload.error,
        loadingBookLicenses: false,
      }
    }
    case GET_BOOK_ACTIVITIES_REQUEST: {
      const { requestOrigin } = action.payload
      if (requestOrigin === requestDestination) {
        return { ...state, loadingBookActivities: true, selectedBookActivities: [] }
      } else return state
    }
    case GET_BOOK_ACTIVITIES_SUCCESS: {
      const { requestOrigin, bookActivities } = action.payload
      if (requestOrigin === requestDestination) {
        return {
          ...state,
          loadingBookActivities: false,
          selectedBookActivities: bookActivities,
          error: '',
        }
      } else return state
    }
    case GET_BOOK_ACTIVITIES_FAILURE: {
      const { requestOrigin, error } = action.payload

      if (requestOrigin === requestDestination) {
        return {
          ...state,
          selectedBookActivities: [],
          error,
          loadingBookActivities: false,
        }
      } else return state
    }
    case GET_LICENSE_STOCK_REQUEST: {
      return {
        ...state,
        selectedBookLicenseStock: null,
        loadingBookLicenseStock: true,
      }
    }
    case GET_LICENSE_STOCK_SUCCESS: {
      return {
        ...state,
        selectedBookLicenseStock: action.payload.licenseStock,
        loadingBookLicenseStock: false,
        error: '',
      }
    }
    case GET_LICENSE_STOCK_FAILURE: {
      return {
        ...state,
        selectedBookLicenseStock: null,
        loadingBookLicenseStock: false,
        error: action.payload.error,
      }
    }
    case GET_CLASSROOMS_REQUEST:
      return {
        ...state,
        loadingClassrooms: !action.payload.optionsLoading,
        loadingClassroomOptions: action.payload.optionsLoading,
        error: '',
        lastClassroomID: action.payload.lastSearchID,
      }
    case GET_CLASSROOMS_SUCCESS: {
      const { classrooms, selectedClassroom } = action.payload
      return {
        ...state,
        loadingClassrooms: false,
        loadingClassroomOptions: false,
        classrooms,
        classroomID: selectedClassroom?.id || classrooms[0]?.id || state.classroomID,
        classroom: selectedClassroom || classrooms[0] || state.classroom,
      }
    }
    case GET_CLASSROOMS_FAILURE:
      return {
        ...state,
        loadingClassrooms: false,
        loadingClassroomOptions: false,
        error: action.payload.error,
      }
    case GET_CLASSROOM_SUBGROUPS_REQUEST:
      return { ...state }
    case GET_CLASSROOM_SUBGROUPS_SUCCESS: {
      const { subgroups } = action.payload
      return {
        ...state,
        subgroups,
        selectedSubgroups: [],
      }
    }
    case GET_CLASSROOM_SUBGROUPS_FAILURE:
      return { ...state, error: action.payload.error }
    case SELECT_CLASSROOM:
      return {
        ...state,
        classroomID: action.payload.classroomID,
        classroom:
          state.classrooms.find((s) => s.id === action.payload.classroomID) ||
          initialState.classroom,
      }
    case SELECT_SUBGROUPS:
      return {
        ...state,
        selectedSubgroups: action.payload.selectedSubgroups,
      }
    case SET_EXPIRY:
      return {
        ...state,
        expiry: action.payload.expiry,
      }
    case SET_MODAL_VIEW:
      return {
        ...state,
        modalView: action.payload.modalView,
      }
    case CREATE_LICENSE_REQUEST: {
      return { ...state, loadingNewLicense: true }
    }
    case CREATE_LICENSE_SUCCESS: {
      return {
        ...state,
        loadingNewLicense: false,
        modalView: ContentCreatorBooksTypes.ASSIGNMENTS_VIEW,
      }
    }
    case CREATE_LICENSE_FAILURE: {
      return { ...state, loadingNewLicense: false }
    }
    case REVOKE_BULK_LICENSE_REQUEST: {
      return { ...state, loadingLicenseDeletion: true }
    }
    case REVOKE_BULK_LICENSE_SUCCESS: {
      return {
        ...state,
        loadingLicenseDeletion: false,
        licenseDeletionModalOpen: false,
      }
    }
    case REVOKE_BULK_LICENSE_FAILURE: {
      return { ...state, loadingLicenseDeletion: false, error: action.payload.error }
    }

    case GET_ALL_TAGS_SUCCESS: {
      return { ...state, tags: action.payload.tagCategories }
    }
    case GET_ALL_TAGS_FAILURE: {
      return { ...state, tags: [], error: action.payload.error }
    }
    case SET_FILTERS: {
      return { ...state, booksPagination: { ...state.booksPagination, page: 0 } }
    }
    case SET_ONLY_WITH_LICENSES: {
      return { ...state, onlyWithLicenses: action.payload }
    }
    default:
      return state
  }
}

const persistConfig = {
  key: 'library-books-teacher',
  storage: localforage,
  whitelist: [],
}

export default persistReducer(persistConfig, root)
