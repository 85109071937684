import React from 'react'
import { makeStyles } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { pathOr } from 'ramda'
import UserAvatar from './userAvatar'

const useStyles = makeStyles((theme) => ({
  appBar: ({ color }) => ({
    boxShadow: 'none',
    height: 80,
    background: color,
    zIndex: 1,
  }),
  toolbar: {
    height: '100%',
  },
  teacherContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    flexGrow: 1,
    justifyItems: 'center',
    fontSize: 28,
    lineHeight: '36px',
    fontWeight: 'inherit',
  },
  teacherName: {
    fontWeight: 500,
  },
  avatar: {
    marginLeft: 16,
  },
}))

export default function SubjectBar(props) {
  const { subject, teacher } = props
  const { name, lastName, avatarColor } = teacher
  const { name: subjectName, color } = subject

  const classes = useStyles({ color })

  const avatarName = pathOr('', ['0'], name) + pathOr(name ? '' : 'A', ['0'], lastName)

  return (
    <AppBar position="relative" className={classes.appBar} component="div">
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title}>{subjectName}</Typography>
        <div className={classes.teacherContainer}>
          <Typography>
            Docente&nbsp;
            <Typography component="span" className={classes.teacherName}>
              {name} {lastName}
            </Typography>
          </Typography>
          <UserAvatar name={avatarName} className={classes.avatar} color={avatarColor} />
        </div>
      </Toolbar>
    </AppBar>
  )
}

SubjectBar.defaultProps = {
  subject: {},
}
