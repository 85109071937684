import { API } from '@/api/lms'
import { FLAGS_MAP } from '@aula/config'
import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { AppThunk } from '@/state/thunk'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import type {
  DocTypeOption,
  GetDocTypesOptionRequest,
  GetDocTypesOptionSuccess,
} from '@/legacy/redux/types/docTypesOptions'
import {
  GET_DOCTYPES_OPTIONS_FAILURE,
  GET_DOCTYPES_OPTIONS_REQUEST,
  GET_DOCTYPES_OPTIONS_SUCCESS,
} from '@/legacy/redux/types/docTypesOptions'

export const formatDocTypesOptions = (docTypes): DocTypeOption[] =>
  docTypes.map((dt) => ({
    ...dt,
    flag: dt.id ? FLAGS_MAP[dt.id.toString()] : '',
  }))

const DocTypesActionCreators = {
  get:
    (requestOrigins: ReduxRequestOrigin[]): AppThunk =>
    async (dispatch) => {
      const request: GetDocTypesOptionRequest = {
        type: GET_DOCTYPES_OPTIONS_REQUEST,
        payload: {
          requestOrigins,
        },
      }
      dispatch(request)

      try {
        const { docTypesOptions: opts } = await API.DocTypes.get()

        const success: GetDocTypesOptionSuccess = {
          type: GET_DOCTYPES_OPTIONS_SUCCESS,
          payload: {
            requestOrigins,
            docTypesOptions: formatDocTypesOptions(opts),
          },
        }

        dispatch(success)
      } catch (error) {
        handleErrorsWithAction(error, GET_DOCTYPES_OPTIONS_FAILURE, dispatch, {
          requestOrigins,
        })
      }
    },
}

export default DocTypesActionCreators
