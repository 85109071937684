import type { Option, QuizActions, QuizState } from '@/sections/quiz/common/types/quiz'
import {
  ACTIVITY_FAILURE,
  ACTIVITY_REQUEST,
  ACTIVITY_SUCCESS,
  ANSWER_FAILURE,
  ANSWER_REQUEST,
  ANSWER_SUCCESS,
  COMPLETE_QUIZ_FAILURE,
  COMPLETE_QUIZ_REQUEST,
  COMPLETE_QUIZ_SUCCESS,
  GO_TO_STATS,
  LAST_QUESTION,
  NEXT_QUESTION,
  QUESTION_FAILURE,
  QUESTION_REQUEST,
  QUESTION_SUCCESS,
  SEE_ANSWER_REQUEST,
  SEE_ANSWER_SUCCESS,
  SET_OPEN,
  SHOW_ONBOARDING,
  SHOW_ONBOARDING_FAILURE,
  UPDATE_TIMER,
} from '@/sections/quiz/common/types/quiz'

const initialState: QuizState = {
  activityID: 0,
  submissionID: 0,
  questionIDs: [],
  nQuestion: -1,
  totalTime: 0,
  question: {
    id: 0,
    type: '',
    text: '',
    url: '',
    body: '',
    options: [],
    score: 0,
    time: 0,
  },
  timer: 0,
  score: 0,
  maxScore: 0,
  title: '',
  correct: null,
  outOfTime: false,
  lastQuestionAnswered: 0,

  statsScreen: false,
  stats: {
    correct: 0,
    incorrect: 0,
    totalScore: 0,
    elapsed: 0,
  },

  loading: false,
  loadingQuestion: false,
  loadingAnswer: false,
  loadingStats: false,
  loadingAnswerSelection: false,

  error: '',
  open: false,
  onboarding: false,
}

function root(state = initialState, action: QuizActions): QuizState {
  switch (action.type) {
    case SET_OPEN:
      return { ...state, open: action.payload.open }

    case ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        open: true,
        activityID: action.payload.activityID,
        questionIDs: [],
        nQuestion: -1,
        submissionID: 0,
        totalTime: 0,
        title: '',
        lastQuestionAnswered: 0,
        outOfTime: false,
        statsScreen: false,
        score: 0,
      }
    case ACTIVITY_SUCCESS:
      const questionIDs = action.payload.questionIDs
      let nQuestion = 0

      for (let i = 0; i < questionIDs.length; i++) {
        if (questionIDs[i] === action.payload.lastQuestionAnswered) {
          nQuestion = i
        }
      }

      return {
        ...state,
        questionIDs: questionIDs,
        nQuestion: nQuestion,
        submissionID: action.payload.submissionID,
        loading: false,
        error: '',
        totalTime: action.payload.time,
        title: action.payload.title,
        lastQuestionAnswered: action.payload.lastQuestionAnswered,
        maxScore: action.payload.maxScore,
        statsScreen: action.payload.submitted,
      }
    case ACTIVITY_FAILURE:
      return { ...state, loading: false, error: action.payload.error }

    case QUESTION_REQUEST:
      return { ...state, loadingQuestion: true, error: '', correct: null }
    case QUESTION_SUCCESS:
      return {
        ...state,
        question: action.payload.question,
        loadingQuestion: false,
        timer: action.payload.question.time,
      }
    case QUESTION_FAILURE:
      return { ...state, loadingQuestion: false, error: action.payload.error }

    case NEXT_QUESTION:
      return { ...state, nQuestion: state.nQuestion + 1, correct: null }
    case LAST_QUESTION:
      return {
        ...state,
        nQuestion: state.nQuestion > 0 ? state.nQuestion - 1 : state.nQuestion,
        statsScreen: false,
        correct: null,
      }
    case GO_TO_STATS:
      return {
        ...state,
        nQuestion: state.nQuestion + 1,
        statsScreen: true,
        correct: null,
      }

    case UPDATE_TIMER:
      if (
        state.loading ||
        state.loadingQuestion ||
        state.loadingAnswer ||
        state.loadingAnswerSelection
      ) {
        return { ...state }
      }

      const questionIsTimed = state.question.time > 0
      if (!questionIsTimed) return { ...state, outOfTime: false }

      const outOfTime = state.timer < 1
      const alreadyAnswered = state.lastQuestionAnswered >= state.questionIDs[state.nQuestion]

      return {
        ...state,
        timer: state.timer > 0 && !alreadyAnswered ? state.timer - 1 : state.timer,
        outOfTime,
      }
    case ANSWER_REQUEST:
      return { ...state, loadingAnswerSelection: true }
    case ANSWER_SUCCESS:
      const { payload } = action

      const options: Array<Option> = state.question.options.map((o: Option) => {
        if (o.id === payload.optionID) {
          return { ...o, state: action.payload.correct ? 'right_choice' : 'wrong_choice' }
        }
        return { ...o, state: 'not_chosen' }
      })

      return {
        ...state,
        question: {
          ...state.question,
          options,
        },
        lastQuestionAnswered: payload.questionID,
        correct: action.payload.correct,
        score: action.payload.correct ? state.score + state.question.score : state.score,
        loadingAnswerSelection: false,
      }
    case ANSWER_FAILURE:
      return { ...state, error: action.payload.error, loadingAnswerSelection: false }

    case SHOW_ONBOARDING:
      return { ...state, onboarding: true }
    case SHOW_ONBOARDING_FAILURE:
      return { ...state, onboarding: false, error: action.payload.error }

    case SEE_ANSWER_REQUEST:
      return { ...state, loadingAnswer: true }

    case SEE_ANSWER_SUCCESS: {
      const { payload } = action

      let options: Array<Option> = []
      if (state.question.options) {
        options = state.question.options.map((o: Option) => {
          if (o.id === payload.answer) {
            return {
              ...o,
              state: o.id === action.payload.correctOption ? 'right_choice' : 'wrong_choice',
            }
          }
          return { ...o, state: 'not_chosen' }
        })
      }

      return {
        ...state,
        question: {
          ...state.question,
          options,
        },
        correct: !!options.find((o: Option) => o.state === 'right_choice'),
        loadingAnswer: false,
      }
    }

    case COMPLETE_QUIZ_REQUEST: {
      return { ...state, loadingStats: true }
    }
    case COMPLETE_QUIZ_SUCCESS: {
      return { ...state, stats: { ...action.payload }, loadingStats: false }
    }

    case COMPLETE_QUIZ_FAILURE:
      return { ...state, error: action.payload.error, loadingStats: false }

    default:
      return state
  }
}

// const persistConfig = {
//   key: 'student-quiz',
//   storage: localforage
// }

export default root
