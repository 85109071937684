import { combineReducers } from 'redux'
import dashboard from './dashboard/reducer'
import classroomMigration from './classroom-migration/reducer'
import management from './management/reducer'
import forms from './forms/reducer'
import documentation from './documentation/reducer'

const rootReducer = combineReducers({
  dashboard,
  classroomMigration,
  management,
  forms,
  documentation,
})

export default rootReducer
