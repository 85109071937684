import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type {
  UploadVimeoActions,
  UploadVimeoState,
} from '@/sections/teacher/bulletin/types/uploadVimeo'
import { UPLOAD_VIMEO_VIDEO_TYPES } from '@/sections/teacher/bulletin/types/uploadVimeo'

const initialState: UploadVimeoState = {
  error: null,
  uploading: false,
  uploaded: false,
  status: REQUEST_STATUS.IDLE,
  uploadLink: '',
  playerEmbedUrl: '',
  progress: 0,
}

const uploadVimeoReducer = (
  state: UploadVimeoState = initialState,
  action: UploadVimeoActions
): UploadVimeoState => {
  switch (action.type) {
    case UPLOAD_VIMEO_VIDEO_TYPES.CLEAN_VIDEO: {
      return initialState
    }
    case UPLOAD_VIMEO_VIDEO_TYPES.CREATE_VIDEO:
    case UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_START: {
      return {
        ...state,
        uploading: true,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case UPLOAD_VIMEO_VIDEO_TYPES.CREATE_VIDEO_SUCCESS: {
      const { playerEmbedUrl, uploadLink } = action.payload
      return {
        ...state,
        playerEmbedUrl,
        uploadLink,
      }
    }
    case UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_SUCCESS: {
      return {
        ...state,
        uploading: false,
        uploaded: true,
        status: REQUEST_STATUS.SUCCESS,
      }
    }
    case UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_PROGRESS: {
      const { total, uploaded } = action.payload
      const progress = (uploaded / total) * 100
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        progress,
      }
    }
    case UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_FAILURE:
    case UPLOAD_VIMEO_VIDEO_TYPES.CREATE_VIDEO_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        uploading: false,
        status: REQUEST_STATUS.FAILURE,
        error,
      }
    }
    case UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_ABORT: {
      return {
        ...state,
        uploading: false,
        status: REQUEST_STATUS.CANCELLED,
        playerEmbedUrl: '',
        uploadLink: '',
        progress: 0,
      }
    }
    default:
      return state
  }
}

export default uploadVimeoReducer
