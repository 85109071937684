import React from 'react'
import { Radio, RadioGroup, FormControlLabel, FormControl, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CheckIcon from '@mui/icons-material/Check'
import classNames from 'clsx'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  root: {
    justifyContent: 'space-between',
    padding: 16,
    alignItems: 'start',
    margin: 0,
    marginBottom: 16,
    background: '#EDEFF8',
    borderRadius: 6,
  },
  selected: {
    margin: 0,
    marginBottom: 15,
    borderRadius: 6,
    background: 'white',
    border: '1px solid #4F36D6',
    boxShadow: '0px 0px 4px 4px rgba(79, 54, 214, 0.2)',
    '-webkit-box-shadow': '0px 0px 4px 4px rgba(79, 54, 214, 0.2)',
    '-moz-box-shadow': '0px 0px 4px 4px rgba(79, 54, 214, 0.2)',
  },
  noSelectable: {
    cursor: 'auto',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 5,
    lineHeight: '21px',
    color: theme.palette.text.primary,
  },
  description: {
    color: theme.palette.text.secondary,
  },
  radio: {
    padding: 0,
    width: 24,
    marginLeft: 10,
  },
}))

const Label = ({ title, description, classes }) => (
  <div>
    <Typography className={classes.title}>{title}</Typography>
    <Typography className={classes.description}>{description}</Typography>
  </div>
)

export default function Answers({ options, onChange = () => {}, checked, selectable = true }) {
  const classes = useStyles()
  return (
    <FormControl component="fieldset" className={classes.container}>
      <RadioGroup aria-label="position" name="position">
        {options.map(({ title, description, value }) => (
          <FormControlLabel
            key={value || title}
            classes={{
              root: classNames(
                classes.root,
                { [classes.selected]: checked === value && selectable },
                { [classes.noSelectable]: !selectable }
              ),
            }}
            control={
              <Radio
                checkedIcon={<CheckIcon />}
                icon={<span />}
                className={classes.radio}
                color="primary"
                value={value}
                disableRipple
                checked={checked === value && selectable}
                onChange={() => onChange(value)}
                size="small"
              />
            }
            label={<Label title={title} description={description} classes={classes} />}
            labelPlacement="start"
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
