import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export interface ConfigState {
  status: REQUEST_STATUS
  data: {
    attendancesBySubject: boolean
    generalAttendances: boolean
  }
}

export enum CONFIG_TYPES {
  GET_CONFIG_REQUEST = 'STUDENT/SUMMARY/TABS/ATTENDANCES/CONFIG/GET_CONFIG_REQUEST',
  GET_CONFIG_SUCCESS = 'STUDENT/SUMMARY/TABS/ATTENDANCES/CONFIG/GET_CONFIG_SUCCESS',
  GET_CONFIG_FAILURE = 'STUDENT/SUMMARY/TABS/ATTENDANCES/CONFIG/GET_CONFIG_FAILURE',
  CLEAN_CONFIG = 'STUDENT/SUMMARY/TABS/ATTENDANCES/CONFIG/CLEAN_CONFIG',
}

// Get config actions.
export type GetConfigRequestAction = {
  type: CONFIG_TYPES.GET_CONFIG_REQUEST
}

export type GetConfigSuccessAction = {
  type: CONFIG_TYPES.GET_CONFIG_SUCCESS
  payload: {
    attendancesBySubject: boolean
    generalAttendances: boolean
  }
}

export type GetConfigFailureAction = {
  type: CONFIG_TYPES.GET_CONFIG_FAILURE
}

export type GetConfigAction =
  | GetConfigRequestAction
  | GetConfigSuccessAction
  | GetConfigFailureAction

// Clean config action.
export type CleanConfigAction = {
  type: CONFIG_TYPES.CLEAN_CONFIG
}

export type ConfigAction = GetConfigAction | CleanConfigAction
