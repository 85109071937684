import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/student/summary/types/tabs/attendances/config'

export const initialState: Types.ConfigState = {
  status: REQUEST_STATUS.IDLE,
  data: {
    attendancesBySubject: false,
    generalAttendances: false,
  },
}

const periodsReducer = (
  state: Types.ConfigState = initialState,
  action: Types.ConfigAction
): Types.ConfigState => {
  switch (action.type) {
    case Types.CONFIG_TYPES.GET_CONFIG_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.CONFIG_TYPES.GET_CONFIG_SUCCESS: {
      const { attendancesBySubject, generalAttendances } = action.payload

      return {
        status: REQUEST_STATUS.SUCCESS,
        data: {
          attendancesBySubject,
          generalAttendances,
        },
      }
    }
    case Types.CONFIG_TYPES.GET_CONFIG_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.CONFIG_TYPES.CLEAN_CONFIG: {
      return initialState
    }
    default:
      return state
  }
}

export default periodsReducer
