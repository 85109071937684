import React from 'react'
import { states } from '@/legacy/components/v2/ribbons/states'
import ActivityRibbonBase from './activityRibbonBase'
import { activityRibbonMap } from '@/legacy/components/v2/ribbons/types'
export default function ReadingRibbon(props) {
  const { reading } = activityRibbonMap
  return (
    <ActivityRibbonBase
      Icon={reading.icon}
      type={reading.type}
      calendarLabel="components.ribbon.student.deliveryDate"
      {...props}
    />
  )
}

ReadingRibbon.defaultProps = {
  subject: {},
  state: states.DEFAULT,
  buttonEnabled: true,
  buttonLabel: 'generic.continue',
  buttonProps: {},
  messages: 0,
}
