import genericActionCreators from './generic'
import StudentsActionCreators from './students'
import CollabMiniatureActionCreators from './collabMiniature'
import ClassroomsActionCreators from './classrooms'
import OrganizationsActionCreators from './organizations'
import ActivitiesActionCreators from './activities'

const reduxActions = {
  Activities: ActivitiesActionCreators,
  Generic: genericActionCreators,
  Students: StudentsActionCreators,
  CollabMiniature: CollabMiniatureActionCreators,
  Classrooms: ClassroomsActionCreators,
  Organizations: OrganizationsActionCreators,
}

export default reduxActions
