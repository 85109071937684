export const ATLAS_PALETTE = {
  primary: {
    light: '#4D6AA3',
    main: '#1A4382',
    dark: '#0D2741',
  },
  secondary: {
    light: '#FCD460',
    main: '#F8AB26',
    dark: '#B5751A',
  },
}
