import type { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { Variant } from './button'

type StyleProps = {
  color: Variant
  animationDelay?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  '@keyframes dotAnim': {
    '33%': {
      opacity: '30%',
    },
    '66%': {
      opacity: '60%',
    },
    '100%': {
      opacity: '100%',
    },
  },
  dotsContainer: {
    display: 'flex',
    gap: 2,
    justifyContent: 'space-around',
  },
  dot: {
    display: 'flex',
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    backgroundColor: ({ color }) =>
      color === Variant.DEFAULT ? '#FFFFFF' : theme.palette.primary.main,
    animation: '$dotAnim 1.8s ease-in-out infinite',
    animationDelay: ({ animationDelay }) => animationDelay,
  },
}))

interface AnimationProps {
  animationDelay: string
  color: Variant
}

const Animation: React.FC<AnimationProps> = ({ animationDelay, color }: AnimationProps) => {
  const C = useStyles({ color, animationDelay })
  return <span className={C.dot} />
}

type DotsAnimationProps = {
  color: Variant
}

const DotsAnimation: React.FC<DotsAnimationProps> = ({ color }) => {
  const C = useStyles({ color })
  return (
    <div className={C.dotsContainer}>
      <Animation animationDelay={'0s'} color={color} />
      <Animation animationDelay={'600ms'} color={color} />
      <Animation animationDelay={'1200ms'} color={color} />
    </div>
  )
}
export default DotsAnimation
