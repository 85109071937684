import type { GenericActionWithType } from '@/legacy/redux/utils'
import type { ThemeOptions } from './addEditExamModal'

export type DetailsExamModalState = {
  open?: boolean
  loading?: boolean
  error?: string
  id: string
  name: string
  classroomId: number
  organizationId: number
  theme: number
  themes?: ThemeOptions[]
  games: number[]
  datetime: string
  deadline: string
  duration: number
  type: number
  stage: number
  code: string
  teacherCode: string
  maxStudents: number
  maxStudentsCount: number
  cue: string
}

// Set initial values
export const SET_INITIAL_VALUES = 'MANAGEMENT/EXAMS/DETAILS_MODAL/SET_INITIAL_VALUES'

// Set initial values
export type SetInitialValues = GenericActionWithType<
  typeof SET_INITIAL_VALUES,
  {
    exam: DetailsExamModalState
  }
>

export const CLEAR_FIELDS = 'MANAGEMENT/EXAMS/DETAILS_MODAL/CLEAR_FIELDS'
export type ClearAllFields = GenericActionWithType<typeof CLEAR_FIELDS>

export type DetailsExamModalAction = SetInitialValues | ClearAllFields
