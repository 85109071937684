import type {
  Activity,
  EXORSocketParams,
  GetEXORActivitySuccess,
  GetEXORInstanceSuccess,
  GetEXORStatusSuccess,
  SetEXOREvaluated,
  SetLostCall,
  SetOnboardingModalClosed,
  UpdateEXORStatusSuccess,
} from '@/sections/student/exams/types/onboardingModal'
import {
  GET_EXOR_ACTIVITY_FAILURE,
  GET_EXOR_ACTIVITY_REQUEST,
  GET_EXOR_ACTIVITY_SUCCESS,
  GET_EXOR_INSTANCE_FAILURE,
  GET_EXOR_INSTANCE_REQUEST,
  GET_EXOR_INSTANCE_SUCCESS,
  GET_EXOR_STATUS_FAILURE,
  GET_EXOR_STATUS_REQUEST,
  GET_EXOR_STATUS_SUCCESS,
  SET_EXOR_EVALUATED,
  SET_EXOR_INCOMING_CALL,
  SET_LOST_CALL,
  SET_ONBOARDING_MODAL_CLOSED,
  SET_ONBOARDING_MODAL_OPEN,
  SET_PICKED_CALL,
  UPDATE_EXOR_STATUS_FAILURE,
  UPDATE_EXOR_STATUS_REQUEST,
  UPDATE_EXOR_STATUS_SUCCESS,
} from '@/sections/student/exams/types/onboardingModal'
import type { AppThunk } from '@/state/thunk'
import { apiPrivate } from '@/api'
import { url } from '@aula/config'
import { handleErrorsWithAction } from '@/legacy/utils/HandleErrors'
import type { ExorInstance, ExorStatus } from '@/sections/teacher/oralExams/types/oralExams'
import { isEmpty } from 'ramda'
import videocallActionCreators from '@/sections/videocall/actions/videocall'
import chatActions from '@/sections/chat/actions/chat'
import { EXOR_PICK_UP } from '@/sections/student/writtenExam/events'
import { VIDEOCALL_ORIGIN } from '@/sections/videocall/constants'
import moment from 'moment'
import { EXESSocket, getConnectedSocket } from '@/socketConnections'
import { INCREASE_SOCKET_ID } from '@/sections/student/writtenExam/types/writtenExam'
import messagesActions from '@/sections/header/actions/messages'
import { LeaveVideocallReasons } from '@/sections/videocall/types/videocall'

let lastCallID: number

const cleanLostCall = () => {
  if (lastCallID) clearTimeout(lastCallID)
}

const actions = {
  setOpen:
    (selectedActivity: Activity): AppThunk =>
    (dispatch) => {
      cleanLostCall()
      dispatch(chatActions.goToChat(selectedActivity.id, undefined, { fromOralExam: true }))
      dispatch({
        type: SET_ONBOARDING_MODAL_OPEN,
        payload: { selectedActivity: selectedActivity },
      })
    },

  getEXORInstance:
    (classroomID: number, activityID: number): AppThunk =>
    (dispatch) => {
      dispatch({ type: GET_EXOR_INSTANCE_REQUEST })

      return apiPrivate
        .get(url + `/v1/classroom/${classroomID}/activities/${activityID}/exor/instances`)
        .then((resp) => {
          const { instances, estimatedTime } = resp.data.description
          dispatch(actions.getEXORInstanceSuccess(estimatedTime, instances))
          if (!isEmpty(instances)) {
            const instance = instances[0]
            const mUpdatedAt = moment(instance.updatedAt)
            if (mUpdatedAt.add(30, 'seconds').isAfter(moment())) {
              dispatch(actions.handleExorStarted(instance))
            } else {
              dispatch(actions.setLostCall())
            }
          }
        })
        .catch((error) => {
          handleErrorsWithAction(error, GET_EXOR_INSTANCE_FAILURE, dispatch)
        })
    },

  getEXORInstanceSuccess: (
    estimatedTime: Date,
    instances: ExorInstance[]
  ): GetEXORInstanceSuccess => ({
    type: GET_EXOR_INSTANCE_SUCCESS,
    payload: {
      estimatedTime,
      instances,
    },
  }),

  setLostCall: (): SetLostCall => ({ type: SET_LOST_CALL }),

  pickCall: (): AppThunk => (dispatch, getState) => {
    const { callsInfo, selectedActivity } = getState().student.exams
    const instancesOfSelectedActivity = callsInfo.filter(
      (callInfo) => callInfo.activityId === selectedActivity.id
    )
    if (!isEmpty(instancesOfSelectedActivity)) {
      const instance = instancesOfSelectedActivity[0]
      const url = `${instance.videocallUrl}`
      dispatch(
        videocallActionCreators.setPopupOpen(url, 'student.exams.exor', VIDEOCALL_ORIGIN.EXOR)
      )
      dispatch({ type: SET_PICKED_CALL, payload: { instance } })
      cleanLostCall()
      instancesOfSelectedActivity.forEach((i) => {
        dispatch(actions.emitSocketEvent(EXOR_PICK_UP, i))
      })
    }
  },

  handleExorStarted:
    (instance: ExorInstance, fromSocket = false): AppThunk =>
    (dispatch, getState) => {
      const { student, user } = getState()
      const { selectedActivity, open, pickedCall } = student.exams

      if (!pickedCall) {
        dispatch(actions.setIncomingCall(instance, fromSocket))
      }
      if (pickedCall && isSameExorInstance(pickedCall, instance.activityId, instance.evaluateeId)) {
        dispatch(actions.emitSocketEvent(EXOR_PICK_UP, instance))
      }
      if (fromSocket && (!open || selectedActivity.id !== instance.activityId)) {
        dispatch(chatActions.goToChat(instance.activityId, undefined, { fromOralExam: true }))
        dispatch(actions.getActivity(user.classroomID || 0, instance.activityId))
      }
    },

  handleExorEvaluated:
    (instance: ExorInstance): AppThunk =>
    (dispatch, getState) => {
      const { student } = getState()
      const { selectedActivity } = student.exams

      if (selectedActivity.id === instance.activityId) {
        dispatch(messagesActions.showToast('student.exams.exor', 'student.exams.exorFinish'))
        dispatch(videocallActionCreators.setPopupClosed(LeaveVideocallReasons.EXOR_EVALUATED))
        dispatch(actions.setExorEvaluated())
      }
    },

  setExorEvaluated: (): SetEXOREvaluated => ({ type: SET_EXOR_EVALUATED }),

  setIncomingCall:
    (instance: ExorInstance, fromSocket: boolean): AppThunk =>
    (dispatch) => {
      dispatch({ type: SET_EXOR_INCOMING_CALL, payload: { callInfo: instance, fromSocket } })
      const mUpdatedAt = moment(instance.updatedAt)

      cleanLostCall()
      lastCallID = window.setTimeout(() => {
        dispatch(actions.setLostCall())
      }, mUpdatedAt.add(30, 'seconds').diff(moment(), 'milliseconds'))
    },

  setClose: (): SetOnboardingModalClosed => ({ type: SET_ONBOARDING_MODAL_CLOSED }),

  getActivity:
    (classroomID: number, activityID: number): AppThunk =>
    (dispatch) => {
      dispatch({ type: GET_EXOR_ACTIVITY_REQUEST })

      return apiPrivate
        .get(url + `/v1/classroom/${classroomID}/activities/${activityID}`)
        .then((resp) => {
          const { activity } = resp.data.description
          dispatch(actions.getActivitySuccess(activity))
        })
        .catch((error) => {
          handleErrorsWithAction(error, GET_EXOR_ACTIVITY_FAILURE, dispatch)
        })
    },

  getActivitySuccess: (activity: Activity): GetEXORActivitySuccess => ({
    type: GET_EXOR_ACTIVITY_SUCCESS,
    payload: { activity },
  }),

  emitSocketEvent:
    (eventType: string, socketParams: EXORSocketParams): AppThunk =>
    (dispatch) => {
      const socket = getConnectedSocket(EXESSocket)

      socket.emit(eventType, socketParams)

      dispatch({ type: INCREASE_SOCKET_ID })
    },

  getEXORStatus:
    (activityID: number): AppThunk =>
    (dispatch) => {
      dispatch({ type: GET_EXOR_STATUS_REQUEST })

      return apiPrivate
        .get(url + `/v1/exor/${activityID}/status`)
        .then((response) => {
          const { status } = response.data.description
          dispatch(actions.getEXORStatusSuccess(status))
        })
        .catch((error) => {
          handleErrorsWithAction(error, GET_EXOR_STATUS_FAILURE, dispatch)
        })
    },

  getEXORStatusSuccess: (status: ExorStatus): GetEXORStatusSuccess => ({
    type: GET_EXOR_STATUS_SUCCESS,
    payload: { status },
  }),

  updateEXORStatus:
    (activityID: number): AppThunk =>
    (dispatch) => {
      dispatch({ type: UPDATE_EXOR_STATUS_REQUEST })

      return apiPrivate
        .put(url + `/v1/exor/${activityID}/status?status=waiting`)
        .then((response) => {
          const { status } = response.data.description
          dispatch(actions.updateEXORStatusSuccess(status))
        })
        .catch((error) => {
          handleErrorsWithAction(error, UPDATE_EXOR_STATUS_FAILURE, dispatch)
        })
    },

  updateEXORStatusSuccess: (status: ExorStatus): UpdateEXORStatusSuccess => ({
    type: UPDATE_EXOR_STATUS_SUCCESS,
    payload: { status },
  }),
}

export default actions

function isSameExorInstance(
  instance: ExorInstance,
  activityId: number,
  evaluateeId: number
): boolean {
  return instance.activityId === activityId && instance.evaluateeId === evaluateeId
}
