import io from 'socket.io-client'
import { BASE_WEBSOCKET_URL } from '@aula/config'
import camelcaseKeys from 'camelcase-keys'

export const ChatSocket = 'chatSocket'
export const EXESSocket = 'exesSocket'
export const BORSocket = 'borSocket'
export const DashboardSocket = 'dashboardSocket'
export const UpcomingSocket = 'upcomingSocket'
export const CollabMiniatureSocket = 'collabMiniatureSocket'
export const AppLinkerSocket = 'mobile_linker'

const sockets: { [socketName: string]: SocketIOClient.Socket } = {}

/**
 * Initializes a connection to a socket.io server.
 *
 * Currently only websocket transport is supported because sticky sessions are not possible.
 * This should be fixed in the future as there is no fallback.
 *
 * @param name Socket name for reference
 * @param path Path in BASE_WEBSOCKET_URL
 * @param token Authentication token
 * @return {Promise<*>}
 */
export const initializeSocketConnection = (name: string, path: string, token: string | null) => {
  if (!BASE_WEBSOCKET_URL) return

  const socket = io
    .connect(BASE_WEBSOCKET_URL, { path, transports: ['websocket'] })
    .emit('authenticate', { token })

  sockets[name] = socket

  // mockey patch for covert all socket input to camel case
  const on = socket.on
  socket.on = function (n: string, cb) {
    on.call(this, n, (data) => {
      const msg = camelcaseKeys(data, { deep: true })
      cb(msg)
    })

    return socket
  }

  return socket
}

export const getConnectedSocket = (socketName) => sockets[socketName]

export const ChatSocketPath = '/chat/socket.io'
export const EXESSocketPath = '/connector/socket.io'
export const BORSocketPath = '/connector/socket.io'
export const DashboardSocketPath = '/connector/socket.io'
export const UpcomingSocketPath = '/connector/socket.io'
export const CollabMiniatureSocketPath = '/connector/socket.io'
export const AppLinkerSocketPath = '/connector/socket.io'

export const disconnectAllSockets = () => {
  for (const i in sockets) {
    if (sockets[i].connected) sockets[i].disconnect()
    delete sockets[i]
  }
}
