import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type { Reducer } from 'redux'
import type {
  ReportCardGradingMainViewAction,
  ReportCardGradingMainViewState,
  Tab,
  TabMap,
} from '@/sections/report-card/grading/types/mainView'
import { CLEAR_CONFIG_STATE } from '@/sections/report-card/grading/types/mainView'
import { Redux } from '@/legacy/redux'
import {
  FIND_CLASSROOM_REPORT_CARD_CONFIG_FAILURE,
  FIND_CLASSROOM_REPORT_CARD_CONFIG_REQUEST,
  FIND_CLASSROOM_REPORT_CARD_CONFIG_SUCCESS,
} from '@/sections/report-card/grading/types/mainView'
import { clone } from 'ramda'
import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import {
  GET_ORGANIZATION_REPORT_CARDS_FAILURE,
  GET_ORGANIZATION_REPORT_CARDS_REQUEST,
  GET_ORGANIZATION_REPORT_CARDS_SUCCESS,
} from '@/sections/report-card/config/types/apiInteractions'

const initialState: ReportCardGradingMainViewState = {
  config: {
    status: REQUEST_STATUS.IDLE,
    classroomConfigStatus: REQUEST_STATUS.IDLE,
    configFetched: false,
  },
  header: '',
  selectedPeriodId: null,
  periods: [],
  reportCardId: 0,
  error: '',
  tabs: {
    absences: { available: false, name: '' },
    scores: { available: false, name: '' },
    attitudinalScores: { available: false, name: '' },
    competenceScores: { available: false, name: '' },
    reviews: { available: false, name: '' },
  },
  selectedAbsenceSubjectId: 0,
  classroomStudents: [],
  classroomStudentsPagination: {
    page: 0,
    rowsPerPage: 10,
    count: 0,
  },
  loadingClassroomStudents: false,
  allSubjects: [],
  ownSubjects: [],
  loadingAllSubjects: false,
  loadingOwnSubjects: false,
  loadingClassroomRole: false,
  classroomRole: null,
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_GRADING_MAIN_VIEW

const getSubjectKeys = (fetchAll: boolean) => ({
  loadingKey: fetchAll ? 'loadingAllSubjects' : 'loadingOwnSubjects',
  subjectsKey: fetchAll ? 'allSubjects' : 'ownSubjects',
})

const ReportCardGradingMainView: Reducer<
  ReportCardGradingMainViewState,
  ReportCardGradingMainViewAction
> = (state = initialState, action): ReportCardGradingMainViewState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case Redux.Types.Subjects.GET_SUBJECTS_AS_TEACHER_REQUEST: {
      const loadingKey = getSubjectKeys(action.payload.fetchAll).loadingKey
      return { ...state, [loadingKey]: true }
    }
    case Redux.Types.Subjects.GET_SUBJECTS_AS_TEACHER_SUCCESS: {
      const loadingKey = getSubjectKeys(action.payload.fetchAll).loadingKey
      const subjectsKey = getSubjectKeys(action.payload.fetchAll).subjectsKey

      return {
        ...state,
        [loadingKey]: false,
        [subjectsKey]: action.payload.subjects.sort((a, b) => a.name.localeCompare(b.name)),
      }
    }
    case Redux.Types.Subjects.GET_SUBJECTS_AS_TEACHER_FAILURE: {
      const loadingKey = getSubjectKeys(action.payload.fetchAll).loadingKey

      return { ...state, [loadingKey]: false, error: action.payload.error }
    }
    case Redux.Types.Students.LIST_CLASSROOM_STUDENTS_REQUEST:
      return {
        ...state,
        loadingClassroomStudents: true,
        error: '',
        classroomStudentsPagination: {
          ...state.classroomStudentsPagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    case Redux.Types.Students.LIST_CLASSROOM_STUDENTS_SUCCESS:
      return {
        ...state,
        loadingClassroomStudents: false,
        classroomStudents: action.payload.students,
        classroomStudentsPagination: {
          ...state.classroomStudentsPagination,
          count: action.payload.count,
        },
      }
    case Redux.Types.Students.LIST_CLASSROOM_STUDENTS_FAILURE:
      return {
        ...state,
        loadingClassroomStudents: false,
        error: action.payload.error,
      }
    case Redux.Types.Classrooms.GET_CLASSROOM_ROLE_REQUEST:
      return {
        ...state,
        loadingClassroomRole: true,
      }
    case Redux.Types.Classrooms.GET_CLASSROOM_ROLE_SUCCESS:
      return {
        ...state,
        loadingClassroomRole: false,
        classroomRole: action.payload.role,
      }
    case Redux.Types.Classrooms.GET_CLASSROOM_ROLE_FAILURE:
      return {
        ...state,
        loadingClassroomRole: false,
        error: action.payload.error,
      }
    case FIND_CLASSROOM_REPORT_CARD_CONFIG_REQUEST:
      return {
        ...state,
        config: {
          ...state.config,
          classroomConfigStatus: REQUEST_STATUS.LOADING,
          configFetched: false,
        },
        error: initialState.error,
      }
    case FIND_CLASSROOM_REPORT_CARD_CONFIG_SUCCESS: {
      const { found, config } = action.payload

      if (!found || !config) {
        return {
          ...state,
          config: {
            ...state.config,
            classroomConfigStatus: REQUEST_STATUS.FAILURE,
            configFetched: true,
          },
          tabs: initialState.tabs,
        }
      }

      const tabs: TabMap = clone(initialState.tabs)

      Object.keys(state.tabs).forEach((tabKey) => {
        if (config[tabKey]) {
          const updatedTab: Tab = {
            available: config[tabKey].available,
            name: config[tabKey].name,
          }

          tabs[tabKey] = updatedTab
        }
      })

      return {
        ...state,
        header: config.header,
        periods: config.periods,
        reportCardId: config.id,
        selectedPeriodId: config.periods?.length
          ? config.periods[0].id
          : initialState.selectedPeriodId,
        config: {
          ...state.config,
          classroomConfigStatus: REQUEST_STATUS.SUCCESS,
          configFetched: true,
        },
        tabs,
      }
    }
    case FIND_CLASSROOM_REPORT_CARD_CONFIG_FAILURE:
      return {
        ...state,
        config: {
          ...state.config,
          classroomConfigStatus: REQUEST_STATUS.FAILURE,
          configFetched: true,
        },
        error: action.payload.error,
      }
    case GET_ORGANIZATION_REPORT_CARDS_REQUEST: {
      return { ...state, config: { ...state.config, status: REQUEST_STATUS.LOADING } }
    }
    case GET_ORGANIZATION_REPORT_CARDS_SUCCESS: {
      return { ...state, config: { ...state.config, status: REQUEST_STATUS.SUCCESS } }
    }
    case GET_ORGANIZATION_REPORT_CARDS_FAILURE: {
      return { ...state, config: { ...state.config, status: REQUEST_STATUS.FAILURE } }
    }
    case CLEAR_CONFIG_STATE: {
      return { ...state, config: initialState.config }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, ReportCardGradingMainView, requestDestination)
