import type { PreviewState, PreviewActions } from '@/sections/preview/types/preview'
import {
  SET_OPEN,
  SET_CLOSE,
  SELECT_FILE_REQUEST,
  SELECT_FILE_SUCCESS,
  SELECT_FILE_FAILURE,
} from '@/sections/preview/types/preview'

const initialState: PreviewState = {
  open: false,
  selectedFile: {
    id: -1,
    fileId: -1,
    name: '',
  },
  url: '',
  files: [],
  loading: false,
  error: '',
  activityID: 0,
  method: 'activity',
  isBook: false,
}

function root(state = initialState, action: PreviewActions): PreviewState {
  switch (action.type) {
    case SET_OPEN:
      return {
        ...state,
        open: !action.payload.doNotOpen,
        files: action.payload.files,
        selectedFile: action.payload.selectedFile,
        activityID: action.payload.activityID,
        method: action.payload.method,
        loading: true,
      }
    case SET_CLOSE: {
      return initialState
    }
    case SELECT_FILE_REQUEST: {
      return {
        ...state,
        selectedFile: action.payload.selectedFile,
        loading: true,
        error: '',
        isBook: action.payload.isBook,
      }
    }
    case SELECT_FILE_SUCCESS: {
      return { ...state, url: action.payload.url, loading: false, error: '' }
    }
    case SELECT_FILE_FAILURE: {
      return { ...state, loading: false, error: action.payload.error }
    }
    default:
      return state
  }
}

export default root
