export interface FeaturesState {
  features: Feature[]
  loading: boolean
  error: ''
}

export const GET_ORGANIZATION_FEATURES_REQUEST = 'ORGANIZATIONS/GET_ORGANIZATION_FEATURES_REQUEST'
export const GET_ORGANIZATION_FEATURES_SUCCESS = 'ORGANIZATIONS/GET_ORGANIZATION_FEATURES_SUCCESS'
export const GET_ORGANIZATION_FEATURES_FAILURE = 'ORGANIZATIONS/GET_ORGANIZATION_FEATURES_FAILURE'

export type FeatureValue = 'true' | 'false'

export interface Feature {
  orgId: number
  name: string
  visible: boolean
  value: FeatureValue
  configItemId: number
}

export interface GetOrganizationFeaturesRequest {
  type: typeof GET_ORGANIZATION_FEATURES_REQUEST
}

export interface GetOrganizationFeaturesSuccess {
  type: typeof GET_ORGANIZATION_FEATURES_SUCCESS
  payload: {
    active: string[]
    features: Feature[]
  }
}

export interface GetOrganizationFeaturesFailure {
  type: typeof GET_ORGANIZATION_FEATURES_FAILURE
  payload: {
    error: string
  }
}

export type GetFeatureActions =
  | GetOrganizationFeaturesRequest
  | GetOrganizationFeaturesSuccess
  | GetOrganizationFeaturesFailure
