import React from 'react'
import MUITabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import classNames from 'clsx'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    minHeight: 30,
  },
  tabsContainer: {
    '& :first-child': {
      borderRadius: '30px 0px 0px 30px',
    },
    '& :last-child': {
      borderRadius: '0px 30px 30px 0px',
    },
  },
  indicator: {
    height: 0,
  },
  tabRoot: {
    minHeight: 30,
    padding: '6px 10px',
    border: '1px solid #D8DBE8',
    background: 'white',
    transition: 'all 100ms',
  },
  selected: {
    border: '1px solid #4F36D6',
    background: theme.palette.primary.main,
    color: 'white',
    '& span :first-child': {
      fill: theme.palette.secondary.main,
    },
  },
  wrapper: {
    flexDirection: 'row',
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 'bold',
    '& :first-child': {
      marginRight: '8px',
      marginBottom: '0px !important',
    },
  },
}))

const SmallTabs = (props) => {
  const { tabs, value, onChange, tabClasses } = props

  const classes = useStyles()

  const changeValue = (event, newValue) => onChange(newValue)

  return (
    <MUITabs
      value={value}
      onChange={changeValue}
      classes={{
        root: classes.tabsRoot,
        flexContainer: classes.tabsContainer,
        indicator: classes.indicator,
      }}
    >
      {tabs.map(({ label, value: tabValue }) => (
        <Tab
          key={tabValue}
          classes={{
            root: classNames(classes.tabRoot, tabClasses.root),
            wrapper: classes.wrapper,
            selected: classes.selected,
          }}
          disableRipple
          label={label}
          value={tabValue}
        />
      ))}
    </MUITabs>
  )
}

SmallTabs.defaultProps = {
  tabClasses: { root: '' },
}

export default SmallTabs
