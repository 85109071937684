import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import type { GatheringsAction, GatheringsState } from '@/sections/ted/types/gatherings'
import {
  LIST_TASKS_REQUEST,
  LIST_TASKS_SUCCESS,
  LIST_TASKS_FAILURE,
  LIST_CHECKMARKS_REQUEST,
  LIST_CHECKMARKS_SUCCESS,
  LIST_CHECKMARKS_FAILURE,
  CREATE_CHECKMARK_REQUEST,
  CREATE_CHECKMARK_SUCCESS,
  CREATE_CHECKMARK_FAILURE,
} from '@/sections/ted/types/gatherings'

const initialState: GatheringsState = {
  tasks: [],
  checkmarks: [],
  loadingTasks: false,
  loadingCheckmarks: false,
  creatingCheckmarkForTaskIds: [],
  error: '',
}

const requestDestination = reduxRequestOriginMap.TED_GATHERINGS

const TedGatheringsReducer: Reducer<GatheringsState, GatheringsAction> = (
  state = initialState,
  action
): GatheringsState => {
  switch (action.type) {
    case LIST_TASKS_REQUEST:
      return { ...state, loadingTasks: true }
    case LIST_TASKS_SUCCESS:
      return { ...state, loadingTasks: false, tasks: action.payload.tasks }
    case LIST_TASKS_FAILURE:
      return { ...state, loadingTasks: false, error: action.payload.error }
    case LIST_CHECKMARKS_REQUEST:
      return { ...state, loadingCheckmarks: true }
    case LIST_CHECKMARKS_SUCCESS:
      return { ...state, loadingCheckmarks: false, checkmarks: action.payload.checkmarks }
    case LIST_CHECKMARKS_FAILURE:
      return { ...state, loadingCheckmarks: false, error: action.payload.error }
    case CREATE_CHECKMARK_REQUEST:
      return {
        ...state,
        creatingCheckmarkForTaskIds: [
          ...state.creatingCheckmarkForTaskIds,
          action.payload.checkmarkId,
        ],
      }
    case CREATE_CHECKMARK_SUCCESS:
      return {
        ...state,
        creatingCheckmarkForTaskIds: state.creatingCheckmarkForTaskIds.filter(
          (id) => id !== action.payload.checkmarkId
        ),
      }
    case CREATE_CHECKMARK_FAILURE:
      return {
        ...state,
        creatingCheckmarkForTaskIds: state.creatingCheckmarkForTaskIds.filter(
          (id) => id !== action.payload.checkmarkId
        ),
        error: action.payload.error,
      }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, TedGatheringsReducer, requestDestination)
