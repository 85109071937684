import types from '@/sections/teacher/activities/types'
import { monday, sunday } from '@/sections/teacher/activities/utils'

const initialState = {
  onlyMyActivities: false,
  startDate: monday,
  endDate: sunday,
  loading: false,
  loadingActivities: true,
  activities: [],
  filteredActivities: [],
  selectedSubjects: [{ id: 0, name: 'teacher.activities.allSubjects' }],
  subjects: [],
  classroom: {
    organization: {},
  },
  role: '',
  error: '',
}

function root(state = initialState, action) {
  switch (action.type) {
    case types.SET_WEEK_DAYS:
      return { ...state, startDate: action.payload.startDate, endDate: action.payload.endDate }
    case types.SET_SELECTED_SUBJECTS:
      return { ...state, selectedSubjects: action.payload.selectedSubjects }
    case types.SET_ONLY_MY_ACTIVITIES:
      return { ...state, onlyMyActivities: action.payload.onlyMyActivities }
    case types.SET_UPDATE_FILTERS:
      return { ...state, filteredActivities: action.payload.filteredActivities }
    case types.ACTIVITIES_REQUEST:
      return { ...state, error: '' }
    case types.ACTIVITIES_REQUEST_TIMEOUT:
      return {
        ...state,
        error: '',
        loadingActivities: action.payload.silentLoading ? state.loadingActivities : true,
      }
    case types.ACTIVITIES_SUCCESS:
      return {
        ...state,
        loadingActivities: false,
        activities: action.payload.activities,
        classroom: action.payload.classroom,
        role: action.payload.role,
      }
    case types.ACTIVITIES_FAILURE:
      return { ...state, loadingActivities: false, error: action.payload.error }
    case types.SUBJECTS_REQUEST:
      return { ...state, loading: true, error: '' }
    case types.SUBJECTS_SUCCESS:
      return { ...state, loading: false, subjects: action.payload.subjects }
    case types.SUBJECTS_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case types.CHANGE_DATE_REQUEST:
      return { ...state, loading: true, error: '' }
    case types.CHANGE_DATE_SUCCESS:
      return { ...state, loading: false }
    case types.CHANGE_DATE_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case types.DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        activities: state.activities.filter((a) => a.id !== action.payload.activityID),
      }
    default:
      return state
  }
}

export default root
