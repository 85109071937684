import ja from '@/locales/translations/ja/ja.json'
import common from '@/locales/translations/ja/common.json'
import glossary from '@/locales/translations/ja/glossary.json'
import validation from '@/locales/translations/ja/validation.json'

export default {
  translation: ja,
  common: common,
  glossary: glossary,
  validation: validation,
}
