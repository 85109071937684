import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export interface BulletinTabState {
  status: REQUEST_STATUS
  data: {
    downloadUrl: string
    periodName: string
  }
  config: {
    status: REQUEST_STATUS
  }
  restriction: {
    blockDownload: boolean
    status: REQUEST_STATUS
  }
}

export enum BULLETIN_TAB_TYPES {
  GET_BULLETIN_PDF_REQUEST = 'STUDENT/SUMMARY/TABS/BULLETIN/GET_BULLETIN_PDF_REQUEST',
  GET_BULLETIN_PDF_SUCCESS = 'STUDENT/SUMMARY/TABS/BULLETIN/GET_BULLETIN_PDF_SUCCESS',
  GET_BULLETIN_PDF_FAILURE = 'STUDENT/SUMMARY/TABS/BULLETIN/GET_BULLETIN_PDF_FAILURE',
  GET_STUDENT_RESTRICTIONS_REQUEST = 'STUDENT/SUMMARY/TABS/BULLETIN/GET_STUDENT_RESTRICTIONS_REQUEST',
  GET_STUDENT_RESTRICTIONS_SUCCESS = 'STUDENT/SUMMARY/TABS/BULLETIN/GET_STUDENT_RESTRICTIONS_SUCCESS',
  GET_STUDENT_RESTRICTIONS_FAILURE = 'STUDENT/SUMMARY/TABS/BULLETIN/GET_STUDENT_RESTRICTIONS_FAILURE',
  GET_CONFIG_REQUEST = 'STUDENT/SUMMARY/TABS/BULLETIN/CONFIG/GET_CONFIG_REQUEST',
  GET_CONFIG_SUCCESS = 'STUDENT/SUMMARY/TABS/BULLETIN/CONFIG/GET_CONFIG_SUCCESS',
  GET_CONFIG_FAILURE = 'STUDENT/SUMMARY/TABS/BULLETIN/CONFIG/GET_CONFIG_FAILURE',
  CLEAN_CONFIG = 'STUDENT/SUMMARY/TABS/BULLETIN/CONFIG/CLEAN_CONFIG',
  RESET_TAB = 'STUDENT/SUMMARY/TABS/BULLETIN/RESET_TAB',
}

// Get bulletin PDF
export type GetBulletinPdfRequestAction = {
  type: BULLETIN_TAB_TYPES.GET_BULLETIN_PDF_REQUEST
}

export type GetBulletinPdfSuccessAction = {
  type: BULLETIN_TAB_TYPES.GET_BULLETIN_PDF_SUCCESS
  payload: {
    downloadUrl: string
    periodName: string
  }
}

export type GetBulletinPdfFailureAction = {
  type: BULLETIN_TAB_TYPES.GET_BULLETIN_PDF_FAILURE
}

// Get config actions.
export type GetConfigRequestAction = {
  type: BULLETIN_TAB_TYPES.GET_CONFIG_REQUEST
}

export type GetConfigSuccessAction = {
  type: BULLETIN_TAB_TYPES.GET_CONFIG_SUCCESS
}

export type GetConfigFailureAction = {
  type: BULLETIN_TAB_TYPES.GET_CONFIG_FAILURE
}
export type GetStudentRestrictionsRequestAction = {
  type: BULLETIN_TAB_TYPES.GET_STUDENT_RESTRICTIONS_REQUEST
}

export type GetStudentRestrictionsSuccessAction = {
  type: BULLETIN_TAB_TYPES.GET_STUDENT_RESTRICTIONS_SUCCESS
  payload: {
    block: boolean
  }
}

export type GetStudentRestrictionsFailureAction = {
  type: BULLETIN_TAB_TYPES.GET_STUDENT_RESTRICTIONS_FAILURE
}

export type CleanConfigAction = {
  type: BULLETIN_TAB_TYPES.CLEAN_CONFIG
}

export type GetConfigAction =
  | GetConfigRequestAction
  | GetConfigSuccessAction
  | GetConfigFailureAction

export type GetBulletinPdfAction =
  | GetBulletinPdfRequestAction
  | GetBulletinPdfSuccessAction
  | GetBulletinPdfFailureAction

export type GetStudentRestrictionsAction =
  | GetStudentRestrictionsRequestAction
  | GetStudentRestrictionsSuccessAction
  | GetStudentRestrictionsFailureAction

// Reset tab
export type ResetTabAction = {
  type: BULLETIN_TAB_TYPES.RESET_TAB
}

export type BulletinTabAction =
  | GetBulletinPdfAction
  | ResetTabAction
  | GetConfigAction
  | GetStudentRestrictionsAction
  | CleanConfigAction

export interface GetConfigResponse {
  reportCards: {
    id: number
    classroomId: number
    status: 'created'
    enableScores: boolean
  }[]
}
