import React from 'react'
import { makeStyles } from '@mui/styles'
import classNames from 'clsx'
import MUIButton from '@mui/material/Button'
import { CircularProgress } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  button: {
    boxSizing: 'border-box',
    borderRadius: 3,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '21px',
    textTransform: 'none',
    padding: '7.5px 16px',
    background: theme.palette.primary.main,
    color: '#FFF',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: '#FFF',
    },
    '&:active': {
      background: theme.palette.secondary.main,
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
      color: '#FFF',
    },
  },
  disabled: {
    opacity: 0.3,
    border: 'none',
    color: '#FFF !important',
  },
  iconLeft: {
    justifyItems: 'center',
    display: 'flex',
    marginRight: 10,
  },
  iconRight: {
    justifyItems: 'center',
    display: 'flex',
    marginLeft: 10,
  },
  loadingContainer: {
    height: 21,
    display: 'flex',
    alignItems: 'center',
  },
}))

const ButtonV2 = ({
  children,
  dense = false,
  onClick,
  loading = false,
  className = '',
  IconLeft = null,
  iconLeftClassname = '',
  IconRight = null,
  iconRightClassname = '',
  ...props
}) => {
  const classes = useStyles({ dense })
  return (
    <MUIButton
      {...props}
      onClick={onClick}
      disabled={props.disabled || loading}
      disableRipple
      className={classNames(classes.button, { [classes.disabled]: props.disabled }, className)}
    >
      {IconLeft && !loading && (
        <span className={classes.iconLeft}>
          <IconLeft className={iconLeftClassname} />
        </span>
      )}
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress color="secondary" size={18} />
        </div>
      ) : (
        children
      )}
      {IconRight && !loading && (
        <span className={classes.iconRight}>
          <IconRight className={iconRightClassname} />
        </span>
      )}
    </MUIButton>
  )
}

export default ButtonV2
