import type { Dispatch } from 'redux'
import { VimeoAPI } from '@/api/vimeo'
import { UPLOAD_VIMEO_VIDEO_TYPES } from '@/sections/teacher/bulletin/types/uploadVimeo'

export const createVimeoVideo = (file: File, description = '') => {
  return (dispatch: Dispatch) => {
    dispatch({ type: UPLOAD_VIMEO_VIDEO_TYPES.CREATE_VIDEO })
    VimeoAPI.createVideo({ size: file.size, name: file.name, description })
      .then(({ uploadLink, playerEmbedUrl }) => {
        dispatch(createVimeoVideoSuccess(file, uploadLink, playerEmbedUrl))
      })
      .catch((error) => dispatch(createVimeoVideoError(error)))
  }
}

export function uploadVimeoVideoClean() {
  return {
    type: UPLOAD_VIMEO_VIDEO_TYPES.CLEAN_VIDEO,
  }
}

export function uploadVimeoVideoStart() {
  return {
    type: UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_START,
  }
}

export function uploadVimeoVideoAbort() {
  return {
    type: UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_ABORT,
  }
}

export function uploadVimeoVideoProgress(bytesSent: number, bytesTotal: number) {
  return {
    type: UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_PROGRESS,
    payload: {
      total: bytesTotal,
      uploaded: bytesSent,
    },
  }
}

export function uploadVimeoVideoSuccess() {
  return {
    type: UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_SUCCESS,
  }
}

export function uploadVimeoVideoFailure(error: Error) {
  return {
    type: UPLOAD_VIMEO_VIDEO_TYPES.UPLOAD_VIDEO_FAILURE,
    payload: {
      error,
    },
  }
}

function createVimeoVideoSuccess(file: File, uploadLink: string, playerEmbedUrl: string) {
  return {
    type: UPLOAD_VIMEO_VIDEO_TYPES.CREATE_VIDEO_SUCCESS,
    payload: {
      file,
      uploadLink,
      playerEmbedUrl,
    },
  }
}

function createVimeoVideoError(error: Error) {
  return {
    type: UPLOAD_VIMEO_VIDEO_TYPES.CREATE_VIDEO_FAILURE,
    payload: {
      error,
    },
  }
}
