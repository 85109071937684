import { combineReducers } from 'redux'
import answersLogin from './answersLogin/reducer'
import codeLogin from './codeLogin/reducer'
import login from './login/reducer'
import auth from './login/reducers/auth'

const rootReducer = combineReducers({
  answersLogin,
  codeLogin,
  login,
  auth,
})

export default rootReducer
