import type { Moment } from 'moment'
import type {
  ApiGetOneUser,
  ApiRelationshipAssignment,
  UserEmailId,
} from '@/api/lms/managementUsers'
import type { Child } from '@/legacy/components/v3/inputs/childrenAssignments/types/childrenAssignmentModal'
import type { Option } from '@/legacy/components/v3/selects/selectSimple'
import type { AssignmentsList } from '@/legacy/redux/types/assignments'
import type { DocTypeOption, Document } from '@/legacy/redux/types/docTypesOptions'
import type { ClearState, SetField } from '@/legacy/redux/types/generic'
import type { CreateUserAction, EditUserDataAction } from '@/legacy/redux/types/managementUsers'
import type { ErrorPayload, GenericActionWithType } from '@/legacy/redux/utils'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'
import type { STUDENT_RESTRICTION } from '@/api/lms/students'

export interface AddEditUserModalState {
  open: boolean
  loading: boolean
  error: string
  editMode: boolean
  userId: number
  name: string
  lastName: string
  document: Document | null
  email: UserEmailId | null
  birthday: Moment
  avatar: string
  ppi: boolean
  phone: string
  genderOptions: Option[]
  selectedGender: Option
  password: string
  selectedDocType: DocTypeOption
  docTypesOptions: DocTypeOption[]
  assignmentsList: AssignmentsList[]
  userChildren: Child[]
  relationshipAssignments: ApiRelationshipAssignment[]
  restrictions: {
    status: REQUEST_STATUS
    error: string
    list: STUDENT_RESTRICTION[]
    selected: STUDENT_RESTRICTION[]
  }
}

// Toggle modal open
export const OPEN_MODAL_REQUEST = 'MANAGEMENT/USERS/ADD_EDIT_MODAL/OPEN_MODAL_REQUEST'
export const OPEN_MODAL_SUCCESS = 'MANAGEMENT/USERS/ADD_EDIT_MODAL/OPEN_MODAL_SUCCESS'
export const OPEN_MODAL_FAILURE = 'MANAGEMENT/USERS/ADD_EDIT_MODAL/OPEN_MODAL_FAILURE'
export type OpenModalRequest = GenericActionWithType<typeof OPEN_MODAL_REQUEST>
export type OpenModalSuccess = GenericActionWithType<
  typeof OPEN_MODAL_SUCCESS,
  { docTypesOptions: DocTypeOption[] }
>
export type OpenModalFailure = GenericActionWithType<typeof OPEN_MODAL_FAILURE, ErrorPayload>
type OpenModalAction = OpenModalRequest | OpenModalSuccess | OpenModalFailure

// Toggle edit modal open
export const OPEN_EDIT_MODAL_REQUEST = 'MANAGEMENT/USERS/ADD_EDIT_MODAL/OPEN_EDIT_MODAL_REQUEST'
export const OPEN_EDIT_MODAL_SUCCESS = 'MANAGEMENT/USERS/ADD_EDIT_MODAL/OPEN_EDIT_MODAL_SUCCESS'
export const OPEN_EDIT_MODAL_FAILURE = 'MANAGEMENT/USERS/ADD_EDIT_MODAL/OPEN_EDIT_MODAL_FAILURE'

export const CLOSE_EDIT_MODAL = 'MANAGEMENT/USERS/ADD_EDIT_MODAL/CLOSE_EDIT_MODAL'

export type OpenEditModalRequest = GenericActionWithType<typeof OPEN_EDIT_MODAL_REQUEST>
export type OpenEditModalSuccess = GenericActionWithType<
  typeof OPEN_EDIT_MODAL_SUCCESS,
  { user: ApiGetOneUser; docTypesOptions: DocTypeOption[] }
>
export type OpenEditModalFailure = GenericActionWithType<
  typeof OPEN_EDIT_MODAL_FAILURE,
  ErrorPayload
>
type OpenEditModalAction = OpenEditModalRequest | OpenEditModalSuccess | OpenEditModalFailure

// Set date
export const SET_DATE = 'MANAGEMENT/USERS/ADD_EDIT_MODAL/SET_DATE'
export type SetDate = GenericActionWithType<typeof SET_DATE, { date: Moment }>

// Set email
export const SET_EMAIL = 'MANAGEMENT/USERS/ADD_EDIT_MODAL/SET_EMAIL'
export type SetEmail = GenericActionWithType<typeof SET_EMAIL, { email: UserEmailId }>

// Set ppi
export const SET_PPI = 'MANAGEMENT/USERS/ADD_EDIT_MODAL/SET_PPI'
export type SetPPI = GenericActionWithType<typeof SET_PPI>

// Set assignments
export const SET_ASSIGNMENTS = 'MANAGEMENT/USERS/ADD_EDIT_MODAL/SET_ASSIGNMENTS'
export type SetAssignments = GenericActionWithType<
  typeof SET_ASSIGNMENTS,
  { assignments: AssignmentsList }
>

// Delete assignment row
export const DELETE_ASSIGNMENT_ROW = 'MANAGEMENT/USERS/ADD_EDIT_MODAL/DELETE_ASSIGNMENT_ROW'
export type DeleteAssignmentRow = GenericActionWithType<
  typeof DELETE_ASSIGNMENT_ROW,
  { assignmentId: string }
>

// Delete assignment list
export const DELETE_ASSIGNMENT_LIST = 'MANAGEMENT/USERS/ADD_EDIT_MODAL/DELETE_ASSIGNMENT_LIST'
export type DeleteAssignmentList = GenericActionWithType<
  typeof DELETE_ASSIGNMENT_LIST,
  { assignmentListId: string }
>

// Set children assignments
export const SET_CHILDREN_ASSIGNMENTS = 'MANAGEMENT/USERS/ADD_EDIT_MODAL/SET_CHILDREN_ASSIGNMENTS'
export type SetChildrenAssignments = GenericActionWithType<
  typeof SET_CHILDREN_ASSIGNMENTS,
  { children: Child[] }
>

// Delete children assignment
export const DELETE_CHILDREN_ASSIGNMENT =
  'MANAGEMENT/USERS/ADD_EDIT_MODAL/DELETE_CHILDREN_ASSIGNMENT'
export type DeleteChildrenAssignment = GenericActionWithType<
  typeof DELETE_CHILDREN_ASSIGNMENT,
  { child: Child }
>

export const DELETE_ALL_CHILDREN_ASSIGNMENTS =
  'MANAGEMENT/USERS/ADD_EDIT_MODAL/DELETE_ALL_CHILDREN_ASSIGNMENTS'
export type DeleteAllChildrenAssignments = GenericActionWithType<
  typeof DELETE_ALL_CHILDREN_ASSIGNMENTS
>

export const GET_STUDENT_RESTRICTIONS_REQUEST =
  'MANAGEMENT/USERS/ADD_EDIT_MODAL/GET_STUDENT_RESTRICTIONS_REQUEST'
export const GET_STUDENT_RESTRICTIONS_SUCCESS =
  'MANAGEMENT/USERS/ADD_EDIT_MODAL/GET_STUDENT_RESTRICTIONS_SUCCESS'
export const GET_STUDENT_RESTRICTIONS_FAILURE =
  'MANAGEMENT/USERS/ADD_EDIT_MODAL/GET_STUDENT_RESTRICTIONS_FAILURE'
export const TOGGLE_SELECTED_RESTRICTION =
  'MANAGEMENT/USERS/ADD_EDIT_MODAL/TOGGLE_SELECTED_RESTRICTION'

export type GetStudentRestrictionsRequestAction = GenericActionWithType<
  typeof GET_STUDENT_RESTRICTIONS_REQUEST
>
export type GetStudentRestrictionsSuccessAction = GenericActionWithType<
  typeof GET_STUDENT_RESTRICTIONS_SUCCESS,
  { restrictions: STUDENT_RESTRICTION[] }
>
export type GetStudentRestrictionsFailureAction = GenericActionWithType<
  typeof GET_STUDENT_RESTRICTIONS_FAILURE,
  { error: string }
>
export type ToggleSelectedRestriction = GenericActionWithType<
  typeof TOGGLE_SELECTED_RESTRICTION,
  { restriction: STUDENT_RESTRICTION }
>
export type CloseEditModalAction = GenericActionWithType<typeof CLOSE_EDIT_MODAL>

export type AddEditUserModalAction =
  | ClearState
  | SetField<
      'birthday' | 'docNumber' | 'selectedGender' | 'editMode',
      Moment | string | Option | boolean
    >
  | OpenModalAction
  | OpenEditModalAction
  | SetEmail
  | SetPPI
  | SetDate
  | SetAssignments
  | DeleteAssignmentRow
  | DeleteAssignmentList
  | CreateUserAction
  | EditUserDataAction
  | SetChildrenAssignments
  | DeleteChildrenAssignment
  | DeleteAllChildrenAssignments
  | ToggleSelectedRestriction
  | GetStudentRestrictionsRequestAction
  | GetStudentRestrictionsSuccessAction
  | GetStudentRestrictionsFailureAction
  | CloseEditModalAction
