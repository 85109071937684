import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type {
  EducationalInfoStepAction,
  EducationalInfoStepState,
  EducationalTagsMap,
} from '@/sections/teacher/activities/types/createExtraInfo/educationalInfo/educationalInfoStep'
import {
  GET_EDUCATIONAL_TAG_CATEGORIES_FAILURE,
  GET_EDUCATIONAL_TAG_CATEGORIES_REQUEST,
  GET_EDUCATIONAL_TAG_CATEGORIES_SUCCESS,
  SET_TAGS,
  GET_ACTIVITY_SUCCESS,
} from '@/sections/teacher/activities/types/createExtraInfo/educationalInfo/educationalInfoStep'

const initialState: EducationalInfoStepState = {
  description: '',
  descriptionIsRichText: true,
  tagMap: {},
  loading: false,
  error: '',
}

const requestDestination = reduxRequestOriginMap.EDUCATIONAL_INFO_STEP

const EducationalInfoStepReducer: Reducer<EducationalInfoStepState, EducationalInfoStepAction> = (
  state = initialState,
  action
): EducationalInfoStepState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE: {
      return initialState
    }
    case Redux.Types.Generic.SET_FIELD: {
      return setGenericField(state, action.payload)
    }
    case GET_EDUCATIONAL_TAG_CATEGORIES_REQUEST: {
      return { ...state, loading: true }
    }
    case GET_EDUCATIONAL_TAG_CATEGORIES_SUCCESS: {
      const { categories } = action.payload

      const newTags: EducationalTagsMap = {}
      categories.forEach((cat) => (newTags[cat] = state.tagMap[cat] || []))

      return {
        ...state,
        tagMap: newTags,
        loading: false,
      }
    }
    case GET_EDUCATIONAL_TAG_CATEGORIES_FAILURE: {
      const { error } = action.payload

      return {
        ...state,
        error,
        loading: false,
      }
    }
    case SET_TAGS: {
      const { tags, category } = action.payload

      return {
        ...state,
        tagMap: {
          ...state.tagMap,
          [category]: tags,
        },
      }
    }
    case GET_ACTIVITY_SUCCESS: {
      const {
        activity: { educationalInfo: educationalInfo },
      } = action.payload
      if (!educationalInfo) return state

      const { description, descriptionIsRichText, tags } = educationalInfo

      return {
        ...state,
        tagMap: tags || initialState.tagMap,
        description: description || initialState.description,
        descriptionIsRichText: descriptionIsRichText,
      }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, EducationalInfoStepReducer, requestDestination, [
  GET_ACTIVITY_SUCCESS,
])
