import { REQUEST_STATUS } from '@/legacy/redux/types/status';
import * as Types from '../../types/organizations/cloneOrganizationModal';

const initialState: Types.CloneOrganizationModalState = {
    isOpenModal: false,
    selectedOrganization: {
        id: 0,
        name: '',
    },
    status: REQUEST_STATUS.IDLE,
}

const cloneOrganizationModalReducer = (
    state: Types.CloneOrganizationModalState = initialState,
    action: Types.CloneOrganizationModalAction,
): Types.CloneOrganizationModalState => {

    switch (action.type) {
        case Types.CLONE_ORGANIZATION_MODAL_TYPES.OPEN_MODAL: {
            return {
                ...state,
                isOpenModal: true,
                selectedOrganization: action.payload,
            }
        }
        case Types.CLONE_ORGANIZATION_MODAL_TYPES.CLEAN_MODAL: {
            return initialState;
        }
        case Types.CLONE_ORGANIZATION_MODAL_TYPES.CLONE_ORGANIZATION_REQUEST: {
            return {
                ...state,
                status: REQUEST_STATUS.LOADING,
            }
        }
        case Types.CLONE_ORGANIZATION_MODAL_TYPES.CLONE_ORGANIZATION_SUCCESS: {
            return initialState;
        }
        case Types.CLONE_ORGANIZATION_MODAL_TYPES.CLONE_ORGANIZATION_FAILURE: {
            return {
                ...state,
                status: REQUEST_STATUS.FAILURE,
            }
        }
        default: return state;
    }
    
};

export default cloneOrganizationModalReducer;