import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/student/summary/types/tabs/activities/activities'

const initialState: Types.ActivitiesState = {
  status: REQUEST_STATUS.IDLE,
  data: [],
  pagination: {
    count: 0,
    total: 0,
    offset: 0,
    limit: 10,
  },
}

const activitiesReducer = (
  state: Types.ActivitiesState = initialState,
  action: Types.ActivitiesAction
): Types.ActivitiesState => {
  switch (action.type) {
    case Types.ACTIVITIES_TYPES.GET_ACTIVITIES_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.ACTIVITIES_TYPES.GET_ACTIVITIES_SUCCCESS: {
      const { activities, count, total } = action.payload

      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        data: activities,
        pagination: {
          ...state.pagination,
          count,
          total,
        },
      }
    }
    case Types.ACTIVITIES_TYPES.GET_ACTIVITIES_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    case Types.ACTIVITIES_TYPES.SET_PAGINATION_OFFSET: {
      const { newOffset } = action.payload

      return {
        ...state,
        pagination: {
          ...state.pagination,
          offset: newOffset,
        },
      }
    }
    case Types.ACTIVITIES_TYPES.CLEAN_ACTIVITIES: {
      return initialState
    }
    default:
      return state
  }
}

export default activitiesReducer
