import { REQUEST_STATUS } from '@/legacy/redux/types/status'
import * as Types from '@/sections/report-card/config/types/pdfPreviewModal'

const initialState: Types.PdfPreviewModalState = {
  isOpenModal: false,
  status: REQUEST_STATUS.IDLE,
  url: '',
}

const pdfPreviewModalReducer = (
  state: Types.PdfPreviewModalState = initialState,
  action: Types.PdfPreviewModalAction
): Types.PdfPreviewModalState => {
  switch (action.type) {
    case Types.PDF_PREVIEW_MODAL_TYPES.OPEN_MODAL: {
      return {
        ...state,
        isOpenModal: true,
      }
    }
    case Types.PDF_PREVIEW_MODAL_TYPES.CLEAN_MODAL: {
      return initialState
    }
    case Types.PDF_PREVIEW_MODAL_TYPES.GENERATE_REPORT_CARD_PDF_REQUEST: {
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
      }
    }
    case Types.PDF_PREVIEW_MODAL_TYPES.GENERATE_REPORT_CARD_PDF_SUCCESS: {
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        url: action.payload.url,
      }
    }
    case Types.PDF_PREVIEW_MODAL_TYPES.GENERATE_REPORT_CARD_PDF_FAILURE: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILURE,
      }
    }
    default:
      return state
  }
}

export default pdfPreviewModalReducer
