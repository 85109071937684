import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import classroom from '@/sections/teacher/classroom/containers/classroom'
import type {
  DashboardCoursesAction,
  DashboardCoursesState,
} from '@/sections/courses/dashboard/types/courses'
import {
  GET_COURSES_FAILURE,
  GET_COURSES_REQUEST,
  GET_COURSES_SUCCESS,
  DELETE_COURSE_ASSIGNMENT_REQUEST,
  DELETE_COURSE_ASSIGNMENT_SUCCESS,
  DELETE_COURSE_ASSIGNMENT_FAILURE,
  SET_DELETE_MODAL_OPEN,
  SET_COURSE_STUDENTS_DRAWER_OPEN,
  GET_COURSE_PROGRESS_FAILURE,
  GET_COURSE_PROGRESS_SUCCESS,
  GET_COURSE_PROGRESS_REQUEST,
  GET_CERTIFICATE_REQUEST,
  GET_CERTIFICATE_FAILURE,
  GET_CERTIFICATE_SUCCESS,
  SET_ISSUE_CERTIFICATE_REQUEST,
  SET_ISSUE_CERTIFICATE_SUCCESS,
} from '@/sections/courses/dashboard/types/courses'

const initialState: DashboardCoursesState = {
  loadingCourses: false,
  loadingIssueCertificate: false,
  courses: [],
  selectedCertificate: {
    loading: false,
    date: '',
    duration: 0,
    includeDate: false,
    includeDuration: false,
    includePlace: false,
    organizations: [],
    place: '',
    signatures: [],
    background: '',
    student: {
      avatar: '',
      email: '',
      id: 0,
      lastName: '',
      name: '',
    },
    text: '',
    title: '',
  },
  totalCourses: 0,
  pagination: {
    limit: 10,
    offset: 0,
  },
  error: '',
  selectedCourseID: 0,
  hasCertificate: false,
  deleteModalOpen: false,
  loadingDelete: false,
  studentsDrawerOpen: false,
  studentsProgress: [],
  studentsProgressLoading: false,
  studentsDrawerTitle: '',
  studentsDrawerClassroom: '',
  studentsDrawerOrganization: '',
}

const requestDestination = reduxRequestOriginMap.DASHBOARD_COURSES

const CoursesReducer = (
  state: DashboardCoursesState = initialState,
  action: DashboardCoursesAction
): DashboardCoursesState => {
  if (action.payload?.requestOrigin !== requestDestination) return state

  switch (action.type) {
    case GET_COURSES_REQUEST:
      return { ...state, loadingCourses: true }
    case GET_COURSES_SUCCESS: {
      const { courses, total, pagination } = action.payload
      return { ...state, loadingCourses: false, courses, totalCourses: total, pagination }
    }
    case GET_COURSES_FAILURE:
      return { ...state, loadingCourses: false, error: '' }
    case DELETE_COURSE_ASSIGNMENT_REQUEST:
      return { ...state, loadingDelete: true }
    case DELETE_COURSE_ASSIGNMENT_SUCCESS:
      return { ...state, loadingDelete: false, deleteModalOpen: false, selectedCourseID: 0 }
    case DELETE_COURSE_ASSIGNMENT_FAILURE:
      return { ...state, loadingDelete: false, deleteModalOpen: false, selectedCourseID: 0 }
    case SET_DELETE_MODAL_OPEN:
      return {
        ...state,
        deleteModalOpen: action.payload.open,
        selectedCourseID: action.payload.courseID,
      }
    case SET_COURSE_STUDENTS_DRAWER_OPEN:
      return {
        ...state,
        studentsDrawerOpen: action.payload.open,
        selectedCourseID: action.payload.courseID,
        hasCertificate: action.payload.hasCertificate,
        studentsDrawerTitle: action.payload.title,
        studentsDrawerClassroom: action.payload.classroom,
        studentsDrawerOrganization: action.payload.organization,
        studentsProgress: [],
      }
    case GET_COURSE_PROGRESS_REQUEST:
      return { ...state, studentsProgressLoading: true }
    case GET_COURSE_PROGRESS_FAILURE:
      return { ...state, studentsProgressLoading: false }
    case GET_COURSE_PROGRESS_SUCCESS:
      return { ...state, studentsProgress: action.payload.progress, studentsProgressLoading: false }
    case GET_CERTIFICATE_REQUEST:
      return { ...state, selectedCertificate: { ...state.selectedCertificate, loading: true } }
    case GET_CERTIFICATE_FAILURE:
      return { ...state, selectedCertificate: { ...state.selectedCertificate, loading: false } }
    case GET_CERTIFICATE_SUCCESS: {
      const certificate = action.payload.certificate
      certificate.loading = false
      return { ...state, selectedCertificate: certificate }
    }
    case SET_ISSUE_CERTIFICATE_REQUEST:
      return { ...state, loadingIssueCertificate: true }
    case SET_ISSUE_CERTIFICATE_SUCCESS:
      return { ...state, loadingIssueCertificate: false }

    default:
      return state
  }
}

export default CoursesReducer
