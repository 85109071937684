import { Radio } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CustomTooltip } from '@/legacy/components/v1'

type StyleProps = {
  color: string
  checked: boolean
}

const useStyles = makeStyles((theme) => ({
  radioContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
  },
  radioRoot: {
    color: '#DFDFDA',
    padding: 0,
  },
  radioChecked: ({ color }: StyleProps) => ({
    color: color + ' !important',
  }),
  label: ({ color, checked }: StyleProps) => ({
    color: checked ? color : '#DFDFDA',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
  }),
}))

export const StatusRadioButton = ({
  label = '',
  color,
  tooltipText = '',
  handleChange,
  checked,
}: StatusRadioButtonProps) => {
  const C = useStyles({ color, checked })

  return (
    <div className={C.radioContainer}>
      {label.length ? <span className={C.label}>{label}</span> : null}
      <CustomTooltip topOffset={-8} title={tooltipText}>
        <Radio
          checked={checked}
          onChange={handleChange}
          value={label}
          name="radio-button"
          inputProps={{ 'aria-label': label }}
          classes={{
            root: C.radioRoot,
            checked: C.radioChecked,
          }}
        />
      </CustomTooltip>
    </div>
  )
}

interface StatusRadioButtonProps {
  checked: boolean
  label?: string
  color: string
  tooltipText?: string
  handleChange: () => void
}
