import { webBffUrl } from '@aula/config'
import { ApiPrivate } from '@/legacy/services/api/api'
import type { ROLE } from '@aula/config'
import type { ApiClassroomAttendancesByStudent, ClassroomAttendancesByStudent } from './types'

export interface GetAttendancesByStudentParams {
  studentId: number
  role: ROLE
  classroomId: number
  subjectId?: number
  perioId?: number
}

/**
 * Obtiene el historal de asistencias de un alumno en un aula (filtrado por subjectId y periodo (opcional este último))
 * @doc https://gitlab.com/elishrid/web-bff/-/blob/master/docs/swagger.yaml#/Attendances/post_v1_users_attendances
 * @param classroomId number
 * @param subjectId number
 * @param studentId number
 * @param perioId number
 */
export const getByStudent = (
  params: GetAttendancesByStudentParams
): Promise<ClassroomAttendancesByStudent> =>
  ApiPrivate.get<ApiClassroomAttendancesByStudent>(webBffUrl + `/v2/users/attendances`, {
    params,
  }).then(({ data }) => {
    return {
      records: data.records.map((r) => {
        return { ...r, value: parseFloat(r.value) }
      }),
      percentage: data.attendancePercentage,
    }
  })

/**
 * Obtiene el historal de asistencias de un alumno en un aula (filtrado por subjectId y periodo (opcional este último))
 * @doc https://gitlab.com/elishrid/web-bff/-/blob/master/docs/swagger.yaml#/Attendances/post_v1_users_attendances
 * @param classroomId number
 * @param subjectId number
 * @param studentId number
 * @param perioId number
 */
export const getByStudentV3 = (
  params: GetAttendancesByStudentParams
): Promise<ClassroomAttendancesByStudent> =>
  ApiPrivate.get<ApiClassroomAttendancesByStudent>(webBffUrl + `/v3/users/attendances`, {
    params,
  }).then(({ data }) => {
    return {
      records: data.records.map((r) => {
        return { ...r, value: parseFloat(r.value) }
      }),
      percentage: data.attendancePercentage,
    }
  })
