import type { Reducer } from 'redux'
import { COSMOS_KEY } from '@aula/config'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin, toggleEntity } from '@/legacy/redux/utils'
import type {
  CosmosModalAction,
  CosmosModalState,
} from '@/sections/management/types/cosmosModal'
import { SET_OPEN, TOGGLE_COSMOS } from '@/sections/management/types/cosmosModal'

const initialState: CosmosModalState = {
  open: false,
  cosmos: Object.values(COSMOS_KEY).map((key) => key),
  selectedCosmos: [],
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_COSMOS_MODAL

const CosmosModalReducer: Reducer<CosmosModalState, CosmosModalAction> = (
  state = initialState,
  action
): CosmosModalState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case SET_OPEN:
      return { ...state, open: true, selectedCosmos: action.payload.selectedCosmos }
    case TOGGLE_COSMOS: {
      const { cosmos } = action.payload

      const cosmosAfterToggle = toggleEntity(state.selectedCosmos, cosmos)

      return {
        ...state,
        selectedCosmos: cosmosAfterToggle,
      }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, CosmosModalReducer, requestDestination)
