import type { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

export interface DefaultResponse<T = any> {
  description: T
}

// any status code within the 2xx range will cause this function to execute.
export function onRequest(config: AxiosRequestConfig) {
  // prevent params alteration
  if (config.params instanceof URLSearchParams) return config
  config.params = config.params ? snakecaseKeys(config.params, { deep: true }) : config.params

  // prevent data alteration
  if (config.data instanceof FormData) return config
  config.data = config.data ? snakecaseKeys(config.data, { deep: true }) : config.data
  return config
}

// any status code outside of the 2xx range will cause this function to execute.
export function onRequestError(error: AxiosError) {
  return Promise.reject(error)
}

// any status code within the 2xx range will cause this function to execute.
export function onResponse(response: AxiosResponse<DefaultResponse>) {
  // prevent response type blob alteration
  if (response.config.responseType === 'blob') return response

  const exclude = [
    'Materia / Área',
    'Edad',
    'Competencias',
    'Modalidad',
    'Tipo de Actividad',
    'Recursos',
    'Disposición grupal',
    'Colección',
    'Disponibilidad',
    'Experiencia',
    'Conceptos abordados',
    'Proyectos',
  ]

  response.data = camelcaseKeys(response.data, { deep: true, exclude })
  return response
}

// any status code outside of the 2xx range will cause this function to execute.
export function onResponseError(error: AxiosError) {
  return Promise.reject(error)
}
