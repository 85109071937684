import * as React from 'react'
import type { SVGProps } from 'react'
const SvgExams = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={43}
    height={44}
    viewBox="0 0 43 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M35.864 5.197H7.137c-1.163 0-2.11.946-2.11 2.11V41.06c0 1.164.947 2.11 2.11 2.11h28.727c1.163 0 2.11-.946 2.11-2.11V7.307c0-1.164-.947-2.11-2.11-2.11Z"
        fill="#9B00AF"
      />
      <path
        d="M8.573 38.906c0 .372.302.673.674.673h24.417a.673.673 0 0 0 .673-.673V6.716a.755.755 0 0 0-.755-.756H9.328a.755.755 0 0 0-.755.755v32.19Z"
        fill="#F6F6F7"
      />
      <path
        d="M27.963 3.043h-2.981A3.552 3.552 0 0 0 21.5.17a3.552 3.552 0 0 0-3.482 2.873h-2.982c-1.163 0-2.11.946-2.11 2.11v2.872c0 .372.302.673.674.673h15.8a.673.673 0 0 0 .673-.673V5.152c0-1.163-.947-2.11-2.11-2.11Z"
        fill="#2A205E"
      />
      <path
        d="M21.5 4.39a.673.673 0 0 1-.673-.674v-.718a.673.673 0 0 1 1.346 0v.718a.673.673 0 0 1-.673.673Z"
        fill="#fff"
      />
      <path
        d="m29.934 29.606-7.181-14.363a1.436 1.436 0 0 0-2.57 0l-7.181 14.363a1.436 1.436 0 1 0 2.569 1.285l2.835-5.67h6.124l2.835 5.67a1.436 1.436 0 1 0 2.57-1.285Zm-10.092-7.258 1.626-3.251 1.626 3.252h-3.252ZM29.368 15.212h-.763v-.763a.673.673 0 0 0-1.347 0v.763h-.763a.673.673 0 1 0 0 1.346h.763v.764a.673.673 0 1 0 1.347 0v-.764h.763a.673.673 0 1 0 0-1.346Z"
        fill="#2A205E"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 .17)" d="M0 0h43v43H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgExams
