export const LIMIT = 10

export const ACTIVITY_TABS_VALUES = {
  ACTIVITY: 'activity',
  PEDAGOGICAL_SHEET: 'pedagogical_sheet',
}

export const ACTIVITY_TABS = [
  { label: 'activityTab.activity', value: ACTIVITY_TABS_VALUES.ACTIVITY },
  { label: 'activityTab.educationalInfo', value: ACTIVITY_TABS_VALUES.PEDAGOGICAL_SHEET },
]
