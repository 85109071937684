import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import type { Reducer } from 'redux'
import type {
  CollabMiniatureWaitingRoomAction,
  CollabMiniatureWaitingRoomState,
} from '@/sections/student/collabMiniature/types/collabMiniatureWaitingRoom'
import { SET_OPEN } from '@/sections/student/collabMiniature/types/collabMiniatureWaitingRoom'

const initialState: CollabMiniatureWaitingRoomState = {
  open: false,
  activityId: null,
}

const requestDestination = reduxRequestOriginMap.COLLAB_MINIATURE_WAITING_ROOM

const CollabMiniatureWaitingRoom: Reducer<
  CollabMiniatureWaitingRoomState,
  CollabMiniatureWaitingRoomAction
> = (state = initialState, action): CollabMiniatureWaitingRoomState => {
  switch (action.type) {
    case SET_OPEN: {
      const { open, activityId } = action.payload

      return {
        ...state,
        open,
        activityId: activityId || initialState.activityId,
      }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, CollabMiniatureWaitingRoom, requestDestination)
