import type { LoginActions, LoginState } from '@/sections/auth/login/types/login'
import {
  SET_DOCUMENT_TYPE,
  SET_DNI,
  SET_PASSWORD,
  SET_STEP,
  WHICH_LOGIN_FAILURE,
  WHICH_LOGIN_REQUEST,
  WHICH_LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  GET_DOC_TYPES_REQUEST,
  GET_DOC_TYPES_FAILURE,
  GET_DOC_TYPES_SUCCESS,
  GET_PASSWORD_TYPES_REQUEST,
  GET_PASSWORD_TYPES_FAILURE,
  GET_PASSWORD_TYPES_SUCCESS,
  GOOGLE_LOGIN_FAILURE,
  MICROSOFT_LOGIN_FAILURE,
} from '@/sections/auth/login/types/login'
import {
  GET_QUESTIONS_FAILURE,
  GET_QUESTIONS_TIMEOUT,
  LOGIN_FAILURE as ANSWERS_LOGIN_FAILURE,
  LOGIN_TIMEOUT as ANSWERS_LOGIN_TIMEOUT,
} from '@/sections/auth/answersLogin/types/answersLogin'
import { FLAGS_MAP } from '@aula/config'

const DEFAULT_DOC_TYPE = { value: 1, label: 'DNI', flag: FLAGS_MAP[1], hidden: false }
const DEFAULT_PASSWORD_TYPE = { id: 1, inputType: 'number', max: 4, min: 4, name: 'number' }

const initialState: LoginState = {
  step: 'dni',
  selectedDocType: 1,
  docTypesOptions: [DEFAULT_DOC_TYPE],
  docTypes: [],
  docTypesFetched: false,
  loading: false,
  selectedPasswordType: DEFAULT_PASSWORD_TYPE,
  passwordTypes: [DEFAULT_PASSWORD_TYPE],
  passwordTypesFetched: false,
  dni: '',
  password: '',
  error: '',
}

function root(state = initialState, action: LoginActions) {
  switch (action.type) {
    case GET_DOC_TYPES_REQUEST:
      return { ...state, loading: true, error: '' }
    case GET_DOC_TYPES_SUCCESS: {
      const { docTypes, docTypesOptions, selectedDocType } = action.payload
      return {
        ...state,
        loading: false,
        docTypes,
        docTypesOptions,
        selectedDocType,
        docTypesFetched: true,
      }
    }
    case GET_DOC_TYPES_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case GET_PASSWORD_TYPES_REQUEST:
      return { ...state, loading: true, error: '' }
    case GET_PASSWORD_TYPES_SUCCESS: {
      const { passwordTypes } = action.payload
      return { ...state, loading: false, passwordTypes, passwordTypesFetched: true }
    }
    case GET_PASSWORD_TYPES_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case SET_DOCUMENT_TYPE:
      return { ...state, selectedDocType: action.payload.selectedDocType, dni: '' }
    case SET_DNI:
      return { ...state, dni: action.payload.dni }
    case SET_PASSWORD:
      return { ...state, password: action.payload.password }
    case SET_STEP:
      return { ...state, step: action.payload.step }
    case WHICH_LOGIN_REQUEST:
      return { ...state, loading: true, error: '' }
    case WHICH_LOGIN_SUCCESS: {
      const { step, selectedPasswordType } = action.payload
      return { ...state, loading: false, step, selectedPasswordType }
    }
    case WHICH_LOGIN_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case LOGIN_REQUEST:
      return { ...state, loading: true, error: '' }
    case LOGIN_SUCCESS:
      return {
        ...initialState,
        docTypesOptions: state.docTypesOptions,
        docTypesFetched: state.docTypesFetched,
        passwordTypes: state.passwordTypes,
        passwordTypesFetched: state.passwordTypesFetched,
      }
    case LOGIN_FAILURE:
    case ANSWERS_LOGIN_FAILURE:
    case GET_QUESTIONS_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case ANSWERS_LOGIN_TIMEOUT:
    case GOOGLE_LOGIN_FAILURE:
    case MICROSOFT_LOGIN_FAILURE:
      return { ...state, loading: false }
    default:
      return state
  }
}

export default root
