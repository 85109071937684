import axios from 'axios'
import * as interceptors from './interceptors'

export const Public = axios.create()
export const Private = axios.create({
  headers: { Authorization: ' ' },
})

Public.interceptors.response.use(interceptors.onResponseSuccess, interceptors.onResponseError)
Private.interceptors.response.use(interceptors.onResponseSuccess, interceptors.onResponseError)

Public.interceptors.request.use(interceptors.onRequestSuccess, interceptors.onRequestError)
Private.interceptors.request.use(interceptors.onRequestSuccess, interceptors.onRequestError)
