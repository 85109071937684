import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type { Reducer } from 'redux'

import type {
  SendReportCardsAction,
  SendReportCardState,
} from '@/sections/report-card/grading/types/sendReportCard'
import { SEND_REPORT_CARD_TYPE } from '@/sections/report-card/grading/types/sendReportCard'

const initialState: SendReportCardState = {
  loadingSend: false,
  loadingExport: false,
  error: '',
  open: false,
  exportOptions: {
    send: false,
    export: false,
  },
  subitems: {
    enableScores: true,
    enableAttitudinal: true,
    enableCompetences: true,
    enableReviews: true,
    enableAttendances: true,
  },
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_GRADING_SEND_REPORT_CARD_MODAL

const sendReportCardReducer: Reducer<SendReportCardState, SendReportCardsAction> = (
  state = initialState,
  action: SendReportCardsAction
): SendReportCardState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD: {
      return {
        ...setGenericField(state, action.payload),
      }
    }
    case Redux.Types.Generic.CLEAR_STATE: {
      return initialState
    }
    case SEND_REPORT_CARD_TYPE.SEND_REQUEST: {
      return {
        ...state,
        loadingSend: true,
      }
    }
    case SEND_REPORT_CARD_TYPE.SEND_SUCCESS: {
      return {
        ...state,
        loadingSend: false,
      }
    }
    case SEND_REPORT_CARD_TYPE.SEND_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        loadingSend: false,
        error,
      }
    }
    case SEND_REPORT_CARD_TYPE.EXPORT_REQUEST: {
      return {
        ...state,
        loadingExport: true,
      }
    }
    case SEND_REPORT_CARD_TYPE.EXPORT_SUCCESS: {
      return {
        ...state,
        loadingExport: false,
      }
    }
    case SEND_REPORT_CARD_TYPE.EXPORT_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        loadingExport: false,
        error,
      }
    }
    case SEND_REPORT_CARD_TYPE.SET_EXPORT_OPTIONS: {
      return {
        ...state,
        exportOptions: action.payload.exportOptions,
      }
    }
    case SEND_REPORT_CARD_TYPE.SET_SUBITEMS: {
      return {
        ...state,
        subitems: action.payload.subitems,
      }
    }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, sendReportCardReducer, requestDestination)
