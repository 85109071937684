import type { ReduxRequestOrigin } from '@/legacy/redux/constants'
import type { SetField } from '@/legacy/redux/types/generic'
import type { ErrorPayload } from '@/legacy/redux/utils'
import type { Pages } from '@/sections/content-creator/dashboard/types/dashboard'
import type { EducationalTag } from './educationalInfoStep'
import type * as EducationalInfoStepTypes from './educationalInfoStep'

export const GET_EDUCATIONAL_TAGS_REQUEST = 'EDUCATIONAL_INFO/GET_EDUCATIONAL_TAGS_REQUEST'
export const GET_EDUCATIONAL_TAGS_SUCCESS = 'EDUCATIONAL_INFO/GET_EDUCATIONAL_TAGS_SUCCESS'
export const GET_EDUCATIONAL_TAGS_FAILURE = 'EDUCATIONAL_INFO/GET_EDUCATIONAL_TAGS_FAILURE'

export const TOGGLE_TAG = 'EDUCATIONAL_INFO/TOGGLE_TAG'

export const SET_OPEN = 'EDUCATIONAL_INFO/SET_OPEN'

export interface EducationalTagsModalState {
  open: boolean
  allTags: EducationalTag[]
  selectedTags: EducationalTag[]
  pagination: Pages
  search: string
  category: string
  loading: boolean
  error: string
}

export interface GetEducationalTagsRequest {
  type: typeof GET_EDUCATIONAL_TAGS_REQUEST
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    page: number
    rowsPerPage: number
  }
}

export interface GetEducationalTagsSuccess {
  type: typeof GET_EDUCATIONAL_TAGS_SUCCESS
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    tags: EducationalTag[]
    count: number
  }
}

export interface GetEducationalTagsFailure {
  type: typeof GET_EDUCATIONAL_TAGS_FAILURE
  payload: ErrorPayload
}

export interface SetOpen {
  type: typeof SET_OPEN
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    open: boolean
    category?: string
    currentTags?: EducationalTag[]
  }
}

export interface ToggleTag {
  type: typeof TOGGLE_TAG
  payload: {
    requestOrigins: ReduxRequestOrigin[]
    tag: EducationalTag
  }
}

export type EducationalTagsModalAction =
  | SetOpen
  | GetEducationalTagsRequest
  | GetEducationalTagsSuccess
  | GetEducationalTagsFailure
  | ToggleTag
  | SetField<'search', string>
  | EducationalInfoStepTypes.SetTags
