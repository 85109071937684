import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { checkRequestOrigin, setGenericField } from '@/legacy/redux/utils'
import type { Reducer } from 'redux'
import type {
  ReportCardGradingReviewsTabAction,
  ReportCardGradingReviewsTabState,
} from '@/sections/report-card/grading/types/tabs/reviewsTab'
import {
  GET_STUDENT_REVIEWS_FAILURE,
  GET_STUDENT_REVIEWS_REQUEST,
  GET_STUDENT_REVIEWS_SUCCESS,
} from '@/sections/report-card/grading/types/tabs/reviewsTab'
import { FIND_CLASSROOM_REPORT_CARD_CONFIG_SUCCESS } from '@/sections/report-card/grading/types/mainView'
import { Redux } from '@/legacy/redux'

const initialState: ReportCardGradingReviewsTabState = {
  loadingReviews: false,
  error: '',
  studentReviews: [],
  bySubject: false,
  selectedEvaluableConfigurationId: 0,
}

const requestDestination = reduxRequestOriginMap.REPORT_CARD_GRADING_REVIEWS_TAB

const ReportCardGradingReviewsTab: Reducer<
  ReportCardGradingReviewsTabState,
  ReportCardGradingReviewsTabAction
> = (state = initialState, action): ReportCardGradingReviewsTabState => {
  switch (action.type) {
    case Redux.Types.Generic.SET_FIELD:
      return {
        ...setGenericField(state, action.payload),
      }
    case GET_STUDENT_REVIEWS_REQUEST:
      return { ...state, loadingReviews: true, error: initialState.error }
    case GET_STUDENT_REVIEWS_SUCCESS:
      return {
        ...state,
        loadingReviews: false,
        studentReviews: action.payload.studentReviews,
      }
    case GET_STUDENT_REVIEWS_FAILURE:
      return { ...state, loadingReviews: false, error: action.payload.error }
    case FIND_CLASSROOM_REPORT_CARD_CONFIG_SUCCESS:
      return {
        ...state,
        bySubject: Boolean(action.payload.config?.reviews?.bySubject),
        selectedEvaluableConfigurationId:
          action.payload.config?.scores.numericalScores.subjects[0]?.evaluableId ??
          action.payload.config?.scores.conceptScores.subjects[0]?.evaluableId ??
          action.payload.config?.attitudinalScores?.values[0]?.id ??
          0, // it should have at least one numerical, concept or attitudinal score
      }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, ReportCardGradingReviewsTab, requestDestination)
