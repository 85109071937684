import { reportCardUrl } from '@aula/config'
import { ApiPrivate } from '@/legacy/services/api/api'
import type { EditReportCardConfig } from './types/edit'
import type { GetReportCardConfigResponse } from './types/get'
import type { SaveReportCardConfig } from './types/save'

/**
 * Obtiene la configuracion de un report card en base a su id con el agregado de los flags "editable" que determinan si un campo puede ser editado o no
 * Si editable = true => computa que se puede editar y que no segun criterios del backend
 * Si editable = false => devuelve todo como no editable (mismo caso si no se le pasa nada)
 * @doc https://gitlab.com/elishrid/report-card/-/blob/develop/docs/swagger.yaml#/Configuration/get_v3_configuration__configurationID
 * @doc https://aula-educabot.atlassian.net/wiki/spaces/DOC/pages/14909441/Editar+configuraci+n+de+boletines
 */
export const get = (reportCardId: number, editable = false) =>
  ApiPrivate.get<GetReportCardConfigResponse>(reportCardUrl + `/v3/configuration/${reportCardId}`, {
    params: { editable },
  }).then((response) => response.data)

/**
 * Guarda la configuracion de un boletin y devuelve el boletin configurado
 * @doc https://gitlab.com/elishrid/report-card/-/blob/develop/docs/swagger.yaml#/Configuration/post_v4_configuration
 */
export const save = (config: SaveReportCardConfig) => {
  return ApiPrivate.post<GetReportCardConfigResponse[]>(
    reportCardUrl + '/v4/configuration',
    config
  ).then((response) => response.data)
}

/**
 * Edita la configuracion de un boletin
 * If there are no scores loaded, it will update the entire configuration
 * @doc https://gitlab.com/elishrid/report-card/-/blob/develop/docs/swagger.yaml#/Configuration/put_v2_configuration
 */
export const edit = (config: EditReportCardConfig) => {
  return ApiPrivate.put(reportCardUrl + '/v2/configuration', config).then(
    (response) => response.data
  )
}

/**
 * Edita la configuracion de un boletin
 * If there are no scores loaded, it will update the entire configuration
 * @doc https://gitlab.com/elishrid/report-card/-/blob/develop/docs/swagger.yaml#/Configuration/put_v2_configuration
 */
export const toggleLock = (reportCardId: number, lock = true) =>
  ApiPrivate.put(reportCardUrl + `/v1/configuration/${reportCardId}/enable_scores`, {
    enableScores: lock,
  }).then((response) => response.data)
