import type { ActivityTypes } from '@/api/lms/activities/activities'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

export enum ENTITY_TYPE {
  ACTIVITY = 'activity',
  UNIT = 'unit',
}

export interface Entity {
  id: number
  title: string
  entityType: ENTITY_TYPE
  type: ActivityTypes | ''
}

// State
export interface EntitiesState {
  data: Entity[]
  status: REQUEST_STATUS
}

export enum ENTITIES_TYPES {
  ADD_ENTITY = 'CONTENT_CREATOR/NEW_SEQUENCES/ENTITIES/ADD_ENTITY',
  REMOVE_ENTITY = 'CONTENT_CREATOR/NEW_SEQUENCES/ENTITIES/REMOVE_ENTITY',
  EDIT_UNIT = 'CONTENT_CREATOR/NEW_SEQUENCES/ENTITIES/EDIT_UNIT',
  SORT_ENTITIES = 'CONTENT_CREATOR/NEW_SEQUENCES/ENTITIES/SORT_ENTITIES',
  CLEAN_ENTITIES = 'CONTENT_CREATOR/NEW_SEQUENCES/ENTITIES/CLEAN_ENTITIES',
  ADD_ENTITIES = 'CONTENT_CREATOR/NEW_SEQUENCES/ENTITIES/ADD_ENTITIES',
  REMOVE_ENTITIES = 'CONTENT_CREATOR/NEW_SEQUENCES/ENTITIES/REMOVE_ENTITIES',
  SAVE_ENTITIES_REQUEST = 'CONTENT_CREATOR/NEW_SEQUENCES/ENTITIES/SAVE_ENTITIES_REQUEST',
  SAVE_ENTITIES_SUCCESS = 'CONTENT_CREATOR/NEW_SEQUENCES/ENTITIES/SAVE_ENTITIES_SUCCESS',
  SAVE_ENTITIES_FAILURE = 'CONTENT_CREATOR/NEW_SEQUENCES/ENTITIES/SAVE_ENTITIES_FAILURE',
}

// Actions
export type AddEntityAction = {
  type: ENTITIES_TYPES.ADD_ENTITY
  payload: { newEntity: Entity }
}

export type RemoveEntityAction = {
  type: ENTITIES_TYPES.REMOVE_ENTITY
  payload: { indexToRemove: number }
}

export type EditUnitAction = {
  type: ENTITIES_TYPES.EDIT_UNIT
  payload: {
    newTitle: string
    indexToEdit: number
  }
}

export type SortEntitiesAction = {
  type: ENTITIES_TYPES.SORT_ENTITIES
  payload: {
    newSortedEntities: Entity[]
  }
}

export type CleanEntitiesAction = {
  type: ENTITIES_TYPES.CLEAN_ENTITIES
}

export type AddEntitiesAction = {
  type: ENTITIES_TYPES.ADD_ENTITIES
  payload: {
    newEntities: Entity[]
  }
}

export type RemoveEntitiesAction = {
  type: ENTITIES_TYPES.REMOVE_ENTITIES
  payload: {
    entitiesToRemove: Entity[]
  }
}

// Save entities actions.
export type SaveEntitiesRequestAction = {
  type: ENTITIES_TYPES.SAVE_ENTITIES_REQUEST
}

export type SaveEntitiesSuccessAction = {
  type: ENTITIES_TYPES.SAVE_ENTITIES_SUCCESS
}

export type SaveEntitiesFailureAction = {
  type: ENTITIES_TYPES.SAVE_ENTITIES_FAILURE
}

export type SaveEntitiesAction =
  | SaveEntitiesRequestAction
  | SaveEntitiesSuccessAction
  | SaveEntitiesFailureAction

export type EntitiesAction =
  | AddEntityAction
  | RemoveEntityAction
  | EditUnitAction
  | SortEntitiesAction
  | CleanEntitiesAction
  | AddEntitiesAction
  | RemoveEntitiesAction
  | SaveEntitiesAction
