import { persistReducer } from 'redux-persist'
import localforage from 'localforage'
import type {
  LibraryActions,
  LibraryState,
  Filter,
} from '@/sections/teacher/library/types/library'
import {
  SET_CLOSE,
  SET_OPEN,
  SET_FILTERS_OPEN,
  SET_FILTERS_CLOSED,
  SET_FILTERS,
  RESET_FILTERS,
  LIBRARY_TAB,
  SET_ACTIVE_TAB,
} from '@/sections/teacher/library/types/library'

const initialState = {
  open: false,
  selectedPDF: '',
  filtersOpen: false,
  filters: [],
  activeTab: LIBRARY_TAB.ACTIVITIES,
}

function root(state: LibraryState = initialState, action: LibraryActions) {
  switch (action.type) {
    case SET_OPEN:
      return { ...state, open: true, selectedPDF: action.payload.pdf }
    case SET_CLOSE:
      return { ...state, open: false }
    case SET_FILTERS_OPEN:
      return { ...state, filtersOpen: true }
    case SET_FILTERS_CLOSED:
      return { ...state, filtersOpen: false }
    case SET_FILTERS: {
      const exists: Filter | undefined = state.filters.find(
        (f) => f.id === action.payload.filter.id
      )
      const filters = exists
        ? state.filters.filter((f) => f.id !== action.payload.filter.id)
        : [...state.filters, action.payload.filter]
      return { ...state, filters }
    }
    case RESET_FILTERS:
      return { ...state, filters: [] }
    case SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload,
      }
    }
    default:
      return state
  }
}

const persistConfig = {
  key: 'library-teacher',
  storage: localforage,
  whitelist: [],
}

export default persistReducer(persistConfig, root)
