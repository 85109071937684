import localforage from 'localforage'
import { persistReducer } from 'redux-persist'
import type {
  PremiumActivitiesActions,
  PremiumActivitiesState,
} from '@/sections/content-creator/premium-activities/types/premium-activities'
import {
  DELETE_PREMIUM_ACTIVITY_FAILURE,
  DELETE_PREMIUM_ACTIVITY_REQUEST,
  DELETE_PREMIUM_ACTIVITY_SUCCESS,
  PREMIUM_ACTIVITY_DELETION_MODAL,
  SET_OPEN,
  SET_SEARCH,
} from '@/sections/content-creator/premium-activities/types/premium-activities'

const initialState: PremiumActivitiesState = {
  open: false,
  modalType: PREMIUM_ACTIVITY_DELETION_MODAL,
  selectedActivity: null,
  loading: false,
  error: '',
  search: '',
}

function root(state = initialState, action: PremiumActivitiesActions): PremiumActivitiesState {
  switch (action.type) {
    case SET_OPEN: {
      const { open, modalType, selectedActivity } = action.payload
      return {
        ...state,
        open,
        modalType: modalType || state.modalType,
        selectedActivity: selectedActivity || state.selectedActivity,
      }
    }
    case DELETE_PREMIUM_ACTIVITY_REQUEST:
      return { ...state, loading: true, error: '' }
    case DELETE_PREMIUM_ACTIVITY_SUCCESS:
      return initialState
    case DELETE_PREMIUM_ACTIVITY_FAILURE:
      return { ...state, loading: false, error: action.payload.error }
    case SET_SEARCH:
      return { ...state, search: action.payload.search }
    default:
      return state
  }
}

const persistConfig = {
  key: 'content-creator-dashboard',
  storage: localforage,
}

export default persistReducer(persistConfig, root)
