import type { Reducer } from 'redux'
import { reduxRequestOriginMap } from '@/legacy/redux/constants'
import { Redux } from '@/legacy/redux'
import { checkRequestOrigin } from '@/legacy/redux/utils'
import type {
  DeleteOrganizationModalAction,
  DeleteOrganizationModalState,
} from '@/sections/management/types/users/deleteOrganizationModal'
import { INIT_MODAL } from '@/sections/management/types/users/deleteOrganizationModal'

const initialState: DeleteOrganizationModalState = {
  assignmentsList: null,
  open: false,
  loading: false,
  error: '',
}

const requestDestination = reduxRequestOriginMap.MANAGEMENT_DELETE_USER_ORGANIZATION_MODAL

const AdminDeleteUserReducer: Reducer<
  DeleteOrganizationModalState,
  DeleteOrganizationModalAction
> = (state = initialState, action): DeleteOrganizationModalState => {
  switch (action.type) {
    case Redux.Types.Generic.CLEAR_STATE:
      return initialState
    case INIT_MODAL:
      return {
        ...state,
        assignmentsList: action.payload.assignmentsList,
        open: true,
        error: initialState.error,
      }
    case Redux.Types.ManagementUsers.UNASSIGN_USER_FROM_ORGANIZATION_REQUEST:
      return { ...state, loading: true }
    case Redux.Types.ManagementUsers.UNASSIGN_USER_FROM_ORGANIZATION_SUCCESS:
      return initialState
    case Redux.Types.ManagementUsers.UNASSIGN_USER_FROM_ORGANIZATION_FAILURE:
      return { ...state, error: action.payload.error, loading: false }
    default:
      return state
  }
}

export default checkRequestOrigin(initialState, AdminDeleteUserReducer, requestDestination)
