import type {
  WrittenExamActions,
  WrittenExamsState,
} from '@/sections/teacher/writtenExams/types/writtenExam'
import {
  GET_EXES_STUDENTS_FAILURE,
  GET_EXES_STUDENTS_QUANTITY_FAILURE,
  GET_EXES_STUDENTS_QUANTITY_REQUEST,
  GET_EXES_STUDENTS_QUANTITY_SUCCESS,
  GET_EXES_STUDENTS_REQUEST,
  GET_EXES_STUDENTS_SUCCESS,
  GET_HELP_REQUESTS_FAILURE,
  GET_HELP_REQUESTS_QUANTITY_FAILURE,
  GET_HELP_REQUESTS_QUANTITY_REQUEST,
  GET_HELP_REQUESTS_QUANTITY_SUCCESS,
  GET_HELP_REQUESTS_REQUEST,
  GET_HELP_REQUESTS_SUCCESS,
  INCREASE_SOCKET_ID,
  REMOVE_HELP_REQUEST,
  UPDATE_HELP_REQUEST_FAILURE,
  UPDATE_HELP_REQUEST_REQUEST,
  UPDATE_HELP_REQUEST_SUCCESS,
} from '@/sections/teacher/writtenExams/types/writtenExam'

const initialState: WrittenExamsState = {
  socketID: 1,
  helpRequests: [],
  exorStudents: [],
  helpRequestsQuantity: 0,
  exorStudentsQuantity: 0,
  loadingHelpRequests: false,
  loadingExesStudents: false,
  loadingHelpRequestsQuantity: false,
  loadingExesStudentsQuantity: false,
  errorHelpRequests: '',
  errorExesStudents: '',
  errorHelpRequestsQuantity: '',
  errorExesStudentsQuantity: '',
}

function root(state = initialState, action: WrittenExamActions) {
  switch (action.type) {
    case GET_HELP_REQUESTS_REQUEST:
      return { ...state, loadingHelpRequests: true }
    case GET_HELP_REQUESTS_SUCCESS:
      return {
        ...state,
        loadingHelpRequests: false,
        helpRequests: action.payload.requests,
        helpRequestsQuantity: action.payload.requests.length,
      }
    case GET_HELP_REQUESTS_FAILURE:
      return { ...state, loadingHelpRequests: false, errorHelpRequests: action.payload.error }
    case UPDATE_HELP_REQUEST_REQUEST:
      return { ...state, loadingHelpRequests: true }
    case REMOVE_HELP_REQUEST:
    case UPDATE_HELP_REQUEST_SUCCESS: {
      const updatedHelpRequest = action.payload.request
      const helpRequests = state.helpRequests.filter(
        (helpRequest) => helpRequest.request.id !== updatedHelpRequest.id
      )
      return {
        ...state,
        loadingHelpRequests: false,
        helpRequests,
        helpRequestsQuantity: helpRequests.length,
      }
    }
    case UPDATE_HELP_REQUEST_FAILURE:
      return { ...state, loadingHelpRequests: false, errorHelpRequests: action.payload.error }
    case GET_EXES_STUDENTS_REQUEST:
      return { ...state, loadingExesStudents: true }
    case GET_EXES_STUDENTS_SUCCESS:
      return {
        ...state,
        loadingExesStudents: false,
        exorStudents: action.payload.exes?.sort((a, b) =>
          a.student?.lastName?.localeCompare(b?.student?.lastName)
        ),
        exorStudentsQuantity: action.payload.exes.length,
      }
    case GET_EXES_STUDENTS_FAILURE:
      return { ...state, loadingExesStudents: false, errorExesStudents: action.payload.error }
    case GET_HELP_REQUESTS_QUANTITY_REQUEST:
      return { ...state, loadingHelpRequestsQuantity: true }
    case GET_HELP_REQUESTS_QUANTITY_SUCCESS:
      return {
        ...state,
        loadingHelpRequestsQuantity: false,
        helpRequestsQuantity: action.payload.requestsQuantity,
      }
    case GET_HELP_REQUESTS_QUANTITY_FAILURE:
      return {
        ...state,
        loadingHelpRequestsQuantity: false,
        errorHelpRequestsQuantity: action.payload.error,
      }
    case GET_EXES_STUDENTS_QUANTITY_REQUEST:
      return { ...state, loadingExesStudentsQuantity: true }
    case GET_EXES_STUDENTS_QUANTITY_SUCCESS:
      return {
        ...state,
        loadingExesStudentsQuantity: false,
        exorStudentsQuantity: action.payload.exesQuantity,
      }
    case GET_EXES_STUDENTS_QUANTITY_FAILURE:
      return {
        ...state,
        loadingExesStudentsQuantity: false,
        errorExesStudentsQuantity: action.payload.error,
      }
    case INCREASE_SOCKET_ID:
      return { ...state, socketID: state.socketID + 1 }
    default:
      return state
  }
}

export default root
