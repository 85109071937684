import type { LocaleSpecification } from 'moment'

export const ja: LocaleSpecification = {
  months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  monthsShort: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
  weekdaysMin: ['日', '月', '火', '水', '木', '金', '土'],
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'YYYY/MM/DD',
    LL: 'YYYY年M月D日',
    LLL: 'YYYY年M月D日 LT',
    LLLL: 'YYYY年M月D日 dddd LT',
  },
  calendar: {
    sameDay: function () {
      return '[今日] LT'
    },
    nextDay: function () {
      return '[明日] LT'
    },
    nextWeek: function () {
      return 'dddd [at] LT'
    },
    lastDay: function () {
      return '[昨日] LT'
    },
    lastWeek: function () {
      return 'dddd [at] LT'
    },
    sameElse: 'L',
  },
  relativeTime: {
    future: '%s後',
    past: '%s前',
    s: '数秒',
    m: '1分',
    mm: '%d分',
    h: '1時間',
    hh: '%d時間',
    d: '1日',
    dd: '%d日',
    M: '1ヶ月',
    MM: '%dヶ月',
    y: '1年',
    yy: '%d年',
  },
  ordinalParse: /\d{1,2}日/,
  ordinal: function (val: number) {
    return val + '日'
  },
  week: {
    dow: 0,
    doy: 4,
  },
}
