import { STUDENT_SUMMARY_TABS } from '@/sections/student/summary/constants/summary'
import * as Types from '@/sections/student/summary/types/drawer/drawer'

const initialState: Types.StudentSummaryDrawerState = {
  isOpen: false,
  activeTab: STUDENT_SUMMARY_TABS.INFORMATION,
}

const drawerReducer = (
  state: Types.StudentSummaryDrawerState = initialState,
  action: Types.StudentSummaryDrawerAction
): Types.StudentSummaryDrawerState => {
  switch (action.type) {
    case Types.STUDENT_SUMMARY_DRAWER_TYPES.OPEN_DRAWER: {
      return {
        ...state,
        isOpen: true,
      }
    }
    case Types.STUDENT_SUMMARY_DRAWER_TYPES.CLOSE_DRAWER: {
      return initialState
    }
    case Types.STUDENT_SUMMARY_DRAWER_TYPES.SET_ACTIVE_TAB: {
      const { newActiveTab } = action.payload

      return {
        ...state,
        activeTab: newActiveTab,
      }
    }
    case Types.STUDENT_SUMMARY_DRAWER_TYPES.CLEAN_DRAWER: {
      return initialState
    }
    default:
      return state
  }
}

export default drawerReducer
