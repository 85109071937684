export const SET_ACTIVITY_DISPLAY_TYPE = 'DISPLAY/SET_ACTIVITY_DISPLAY_TYPE'

export const ACTIVITY_DISPLAY_TYPE_RIBBON = 'ACTIVITY_DISPLAY_TYPE_RIBBON'
export const ACTIVITY_DISPLAY_TYPE_ROW = 'ACTIVITY_DISPLAY_TYPE_ROW'

export type ActivityDisplayType =
  | typeof ACTIVITY_DISPLAY_TYPE_RIBBON
  | typeof ACTIVITY_DISPLAY_TYPE_ROW

export interface SetActivityDisplayType {
  type: typeof SET_ACTIVITY_DISPLAY_TYPE
  payload: {
    activityDisplayType: ActivityDisplayType
  }
}

export type DisplayState = {
  activityDisplayType: ActivityDisplayType
}

export type DisplayActions = SetActivityDisplayType
