import { REQUEST_STATUS } from '@/legacy/redux/types/status'

// State.
export interface CloneOrganizationModalState {
    isOpenModal: boolean
    selectedOrganization: {
      id: number
      name: string
    }
    status: REQUEST_STATUS
}

// Types.
export enum CLONE_ORGANIZATION_MODAL_TYPES {
    OPEN_MODAL = 'MANAGEMENT/ORGANIZATIONS/CLONE_ORGANIZATION_MODAL/OPEN_MODAL',
    CLEAN_MODAL = 'MANAGEMENT/ORGANIZATIONS/CLONE_ORGANIZATION_MODAL/CLEAN_MODAL',
    CLONE_ORGANIZATION_REQUEST = 'MANAGEMENT/ORGANIZATIONS/CLONE_ORGANIZATION_MODAL/CLONE_ORGANIZATION_REQUEST',
    CLONE_ORGANIZATION_SUCCESS = 'MANAGEMENT/ORGANIZATIONS/CLONE_ORGANIZATION_MODAL/CLONE_ORGANIZATION_SUCCESS',
    CLONE_ORGANIZATION_FAILURE = 'MANAGEMENT/ORGANIZATIONS/CLONE_ORGANIZATION_MODAL/CLONE_ORGANIZATION_FAILURE',
}

// Actions.
// Open modal action.
export type OpenModalAction = {
    type: CLONE_ORGANIZATION_MODAL_TYPES.OPEN_MODAL,
    payload: CloneOrganizationModalState['selectedOrganization'],
}

// Clean modal action.
export type CleanModalAction = {
    type: CLONE_ORGANIZATION_MODAL_TYPES.CLEAN_MODAL,
}

// Clone organization actions.
export type CloneOrganizationRequestAction = {
    type: CLONE_ORGANIZATION_MODAL_TYPES.CLONE_ORGANIZATION_REQUEST,
}

export type CloneOrganizationSuccessAction = {
    type: CLONE_ORGANIZATION_MODAL_TYPES.CLONE_ORGANIZATION_SUCCESS,
}

export type CloneOrganizationFailureAction = {
    type: CLONE_ORGANIZATION_MODAL_TYPES.CLONE_ORGANIZATION_FAILURE,
}

export type CloneOrganizationAction = 
    | CloneOrganizationRequestAction
    | CloneOrganizationSuccessAction
    | CloneOrganizationFailureAction;

// Action.
export type CloneOrganizationModalAction =
    | OpenModalAction
    | CleanModalAction
    | CloneOrganizationAction;