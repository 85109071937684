import { lazy, Suspense } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

const Auth = lazy(() => import('./auth'))
const Login = lazy(() => import('./login'))
const Admin = lazy(() => import('./admin'))
const Principal = lazy(() => import('./principal'))
const Teacher = lazy(() => import('./teacher'))
const Student = lazy(() => import('./student'))
const Evaluator = lazy(() => import('./evaluator'))
const ContentCreator = lazy(() => import('./contentCreator'))
const Guest = lazy(() => import('./guest'))
const License = lazy(() => import('./license'))
const Conectados = lazy(() => import('./conectados'))

const Router = () => (
  <BrowserRouter basename="/app">
    <Suspense>
      <Routes>
        <Route path="/login/*" element={<Login />} />
        <Route path="/auth/*" element={<Auth />} />
        <Route path="/docente/*" element={<Teacher />} />
        <Route path="/alumno/*" element={<Student />} />
        <Route path="/admin/*" element={<Admin />} />
        <Route path="/evaluator/*" element={<Evaluator />} />
        <Route path="/contenidista/*" element={<ContentCreator />} />
        <Route path="/principal/*" element={<Principal />} />
        <Route path="/invitado/*" element={<Guest />} />
        <Route path="/conectados/*" element={<Conectados />} />
        <Route path="/licencia/*" element={<License />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
)

export default Router
