import { makeStyles } from '@mui/styles'
import type { ReactElement } from 'react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import GenericStepper from '@/legacy/components/v3/genericStepper'
import type { Option } from '@/legacy/components/v3/selects/selectSimple'
import { scrollBarStyles } from '@/sections/teacher/activities/styles'
import type {
  Classroom,
  ClassroomToMigrate,
  Migration,
} from '@/sections/principal/classroom-migration/types/migrationClassrooms'
import ClassroomsSelectionStep from './steps/classroomsSelection'
import FinalStep from './steps/finalStep'
import VerifyStudentsStep from './steps/verifyStudents'
import type { Pages } from '@/legacy/components/v3/moreTablePagination'
import { REQUEST_STATUS, isIdle, isSuccess } from '@/legacy/redux/types/status'
import Button, { Variant } from '@/legacy/components/atomic/button/button'
import AlertMigrationModal from './alertMigrationModal'

const useStyles = makeStyles((theme) => ({
  bodyContainer: (props: { loadingOldClassrooms?: boolean }) => ({
    width: '100%',
    margin: '0 auto',
    overflow: props.loadingOldClassrooms ? 'hidden' : 'auto',
    height: 'calc(100% - 76px)',
    position: 'relative',
    ...scrollBarStyles,
  }),
  stepperContainer: {
    margin: '24px auto 36px',
    maxWidth: 406,
  },
  buttonWrapper: {
    position: 'fixed',
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.white,
    borderTop: '1px solid',
    borderTopColor: theme.palette.border,
  },
  buttonContainer: {
    padding: 18,
    marginBottom: 32,
    height: 88,
    boxSizing: 'border-box',
    gap: 8,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  button: {
    width: 105,
    minWidth: 'fit-content',
    height: 36,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
  },
}))

// Deprecated
export const formatClassroomLabel = (classroom: Classroom) =>
  classroom
    ? `${classroom.stage ? classroom.stage + ' - ' : ''}${classroom.grade} ${classroom.division} ${
        classroom.shift
      }`
    : ''

export type MigrationTabProps = {
  verificationTableStatus: REQUEST_STATUS
  pagination: Pages
  migrations: Migration[]
  newClassroomStatus: REQUEST_STATUS
  oldClassroomStatus: REQUEST_STATUS
  academicPeriodsOptions: Option[]
  activeStep: number
  classroomsToMigrate: ClassroomToMigrate[]
  completeMigration: () => void
  fetchClassroomOptions: VoidFunction
  getNewClassroomOptions: (search: string) => void
  lastYearId: number
  migrationCompletionStatus: REQUEST_STATUS
  newClassroomEnabled?: boolean
  newClassrooms: Classroom[]
  newYearId: number
  oldClassrooms: Classroom[]
  handleOldClassroomPagination: (newPage: number) => void
  onLastYearChange: (year: number) => void
  onNewYearChange: (year: number) => void
  openStudentsDrawer: () => void
  getClassroomStudents: () => void
  setActiveStep: (activeStep: number) => void
  setClassroomToMigrate: (oldClassroom: Classroom, newClassroom?: Classroom) => void
  setClassroomToMigrateV2: (oldClassroom: Classroom, newClassroom?: Classroom) => void
  setSelectedMigration: (migration: Migration) => void
  setNewClassroomModalOpen: () => void
  handleOpenAlertModal: () => void
  handleCloseAlertModal: () => void
  clearMigrationState: () => void
}

const MigrationTab = (props: MigrationTabProps): ReactElement => {
  const styles = useStyles({
    loadingOldClassrooms: props.oldClassroomStatus === REQUEST_STATUS.LOADING,
  })
  const { t } = useTranslation()

  const steps = [
    { id: 1, label: t('principal.classroomMigration.migrateClassrooms') },
    { id: 2, label: t('principal.classroomMigration.verifyStudents') },
  ]

  useEffect(() => {
    if (props.migrationCompletionStatus === REQUEST_STATUS.SUCCESS && props.activeStep === 1) {
      props.clearMigrationState()
      props.fetchClassroomOptions()
    }
  }, [props.migrationCompletionStatus, props.activeStep])

  const handleNextClick = () => {
    const allClassroomsVerified = props.migrations.every((migration) => migration.verified)
    switch (true) {
      case props.activeStep === 2 && !allClassroomsVerified:
        return props.handleOpenAlertModal()
      case props.activeStep === 2 && isSuccess(props.migrationCompletionStatus):
        return props.setActiveStep(1)
      case props.activeStep === 2 && allClassroomsVerified:
        return props.completeMigration()
      default:
        return props.setActiveStep(props.activeStep + 1)
    }
  }

  const handlePreviousClick = () => props.setActiveStep(props.activeStep - 1)

  const buttonDisabled =
    (props.activeStep === 1 && !props.classroomsToMigrate.length) ||
    (props.activeStep === 2 && !props.migrations.some((ctm) => ctm.verified)) ||
    props.migrationCompletionStatus === REQUEST_STATUS.LOADING

  const getColumns = (step: number) => {
    switch (step) {
      case 1:
        return (
          <ClassroomsSelectionStep
            newClassroomStatus={props.newClassroomStatus}
            oldClassroomStatus={props.oldClassroomStatus}
            pagination={props.pagination}
            academicPeriodsOptions={props.academicPeriodsOptions}
            classroomsToMigrate={props.classroomsToMigrate}
            getNewClassroomOptions={props.getNewClassroomOptions}
            handleOldClassroomPagination={props.handleOldClassroomPagination}
            lastYearId={props.lastYearId}
            newClassroomEnabled={props.newClassroomEnabled}
            newClassrooms={props.newClassrooms}
            newYearId={props.newYearId}
            oldClassrooms={props.oldClassrooms}
            onLastYearChange={props.onLastYearChange}
            onNewYearChange={props.onNewYearChange}
            setClassroomToMigrate={props.setClassroomToMigrate}
            setClassroomToMigrateV2={props.setClassroomToMigrateV2}
            setNewClassroomModalOpen={props.setNewClassroomModalOpen}
          />
        )
      case 2: {
        return (
          <VerifyStudentsStep
            migrations={props.migrations}
            classroomsToMigrate={props.classroomsToMigrate}
            verificationTableStatus={props.verificationTableStatus}
            migrationCompletionStatus={props.migrationCompletionStatus}
            openStudentsDrawer={props.openStudentsDrawer}
            setSelectedMigration={props.setSelectedMigration}
            getClassroomStudents={props.getClassroomStudents}
            newYearId={props.newYearId}
            academicPeriodsOptions={props.academicPeriodsOptions}
          />
        )
      }
      case 3:
        return <FinalStep onClick={props.fetchClassroomOptions} />
    }
  }

  return (
    <>
      <div className={styles.bodyContainer}>
        {props.activeStep !== 3 && isIdle(props.migrationCompletionStatus) && (
          <div className={styles.stepperContainer}>
            <GenericStepper steps={steps} activeStep={props.activeStep} verticalItems />
          </div>
        )}
        {getColumns(props.activeStep)}
      </div>
      {props.activeStep !== 3 && props.oldClassroomStatus !== REQUEST_STATUS.LOADING && (
        <div className={styles.buttonWrapper}>
          <div className={styles.buttonContainer}>
            {props.activeStep !== steps[0].id && !isSuccess(props.migrationCompletionStatus) && (
              <Button
                variant={Variant.OUTLINE}
                onClick={handlePreviousClick}
                className={styles.button}
              >
                {t('generic.back')}
              </Button>
            )}
            <Button onClick={handleNextClick} disabled={buttonDisabled} className={styles.button}>
              {isSuccess(props.migrationCompletionStatus)
                ? t('principal.classroomMigration.goBack')
                : props.activeStep === steps.length
                ? t('principal.classroomMigration.finishMigrate')
                : t('generic.next')}
            </Button>
          </div>
        </div>
      )}
      <AlertMigrationModal />
    </>
  )
}

export default MigrationTab
