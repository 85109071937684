import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { tedUrl, tedStorageUrl, tedUploadVideoUrl } from '@aula/config'
import { ApiPrivate, ApiPrivateNotIntercepted } from '@/legacy/services/api/api'
import type { VideoList, Video, VideoStatus, VideoUploadPolicyResponse } from './types'
import type { DefaultResponse } from '@/legacy/services/helpers/types'

// TODO move to types
export type GetVideos = (
  groupId: number,
  page: number,
  size: number,
  academicPeriod: number
) => void

export function getVideos(
  groupId: number,
  teacher: boolean,
  page: number,
  size: number,
  onlyCompleted: boolean,
  academicPeriodId: number,
  search?: string
) {
  const params = {
    ...(!teacher && { groupId }),
    onlyCompleted,
    page,
    size,
    teacher,
    academicPeriodId,
    ...(search && { search }),
  }

  return ApiPrivate.get<VideoList<Video>>(tedUrl + '/videos', { params: snakecaseKeys(params) })
    .then((res) => res.data)
    .then((data) => {
      const list = data.list.map((l) => ({
        ...l,
        speakers: l.speakers.length ? l.speakers.filter(Boolean) : [],
        tags: l.tags ? l.tags : [],
      }))
      return {
        ...data,
        list,
      }
    })
}

// TODO move to types
export type CreateVideo = (videoId: number) => void

export function createVideo(groupId: number, file: File) {
  return ApiPrivateNotIntercepted.post<DefaultResponse<VideoUploadPolicyResponse>>(
    tedUrl + `/videos/upload-policy?group_id=${groupId}`
  )
    .then((res) => camelcaseKeys(res.data.description, { deep: true }))
    .then(({ fileId, fields }) => {
      const form = new FormData()
      form.append('key', fields.key)
      form.append('policy', fields.policy)
      form.append('x-goog-date', fields.xGoogDate)
      form.append('x-goog-algorithm', fields.xGoogAlgorithm)
      form.append('x-goog-signature', fields.xGoogSignature)
      form.append('x-goog-credential', fields.xGoogCredential)
      form.append('file', file)
      // send file directly to storage
      return fetch(tedStorageUrl, {
        mode: 'cors',
        method: 'post',
        body: form,
      }).then(() => fileId)
    })
    .then((fileId) => {
      const body = snakecaseKeys({ fileId, videoName: file.name })
      // send file upload success to backend
      return ApiPrivateNotIntercepted.post<DefaultResponse<Video>>(
        `${tedUploadVideoUrl}?group_id=${groupId}`,
        body
      )
    })
    .then((resp) => resp.data.description)
    .then((data) => camelcaseKeys(data))
}

// TODO move to types
export type DeleteVideo = (videoId: number, groupId: number) => void

export function deleteVideo(groupId: number, videoId: number) {
  return ApiPrivate.delete<void>(tedUrl + `/videos/${videoId}?group_id=${groupId}`)
}

// TODO move to types
export type DownloadVideo = (videoId: number, groupId: number) => void

export function downloadVideo(url: string): Promise<Blob> {
  return fetch(url, {
    mode: 'cors',
    method: 'get',
  }).then((res) => res.blob())
}

export type UpdateVideoPayload = {
  title: string
  tags: string[]
  speakers: string[]
  description: string
  file?: File
}

// TODO move to types
export type UpdateVideo = (videoId: number, groupId: number, payload: UpdateVideoPayload) => void

export function updateVideo(
  videoId: number,
  groupId: number,
  { file, title, tags, speakers, description }: UpdateVideoPayload
) {
  const form = new FormData()
  form.append('title', title)
  form.append('tags', tags.join(','))
  form.append('speakers', speakers.join(','))
  form.append('description', description)
  file && form.append('new_auth_file', file)

  return ApiPrivateNotIntercepted.put<void>(tedUrl + `/videos/${videoId}?group_id=${groupId}`, form)
}

// TODO move to types
export type DownloadAuthFile = (videoId: number) => void

export function downloadAuthFile(videoId: number, teacher: boolean) {
  const url = tedUrl + `/videos/${videoId}/authorizations`
  const params = {
    teacher,
  }

  return ApiPrivateNotIntercepted({
    url,
    method: 'get',
    responseType: 'blob',
    params,
  }).then((res) => res.data)
}

// TODO move to types
export type ApproveVideo = (approve: boolean) => void

export function approveVideo(videoId: number, approve: boolean) {
  return ApiPrivate.post<Video>(tedUrl + `/videos/${videoId}/review`, { approve }).then(
    (res) => res.data
  )
}

// TODO move to types
export type RejectVideo = (approve: boolean) => void

export function rejectVideo(videoId: number, status: VideoStatus, feedback: string) {
  return ApiPrivate.put<Video>(tedUrl + `/videos/${videoId}/status`, {
    status,
    feedback,
  }).then((res) => res.data)
}
