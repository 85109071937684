import { combineReducers } from 'redux'
import subjects from './subjects'
import periods from './periods'
import count from './count'
import activities from './activities'

export default combineReducers({
  subjects,
  periods,
  count,
  activities,
})
