import type { BookByAudience } from '@/api/lms/library'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

// State.
export interface DrawerState {
  isOpen: boolean
  isEditMode: boolean
  currentStep: DRAWER_STEP
  status: REQUEST_STATUS
  bookToEdit: BookByAudience | null
}

// Types.
export enum DRAWER_TYPES {
  OPEN_MODAL = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/DRAWER/OPEN_MODAL',
  CLOSE_MODAL = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/DRAWER/CLOSE_MODAL',
  SET_CURRENT_STEP = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/DRAWER/SET_CURRENT_STEP',
  SET_EDIT_MODE = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/DRAWER/SET_EDIT_MODE',
  LOAD_BOOK_INFO_TO_EDIT_REQUEST = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/DRAWER/LOAD_BOOK_INFO_TO_EDIT_REQUEST',
  LOAD_BOOK_INFO_TO_EDIT_SUCCESS = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/DRAWER/LOAD_BOOK_INFO_TO_EDIT_SUCCESS',
  LOAD_BOOK_INFO_TO_EDIT_FAILURE = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/DRAWER/LOAD_BOOK_INFO_TO_EDIT_FAILURE',
  SET_BOOK_ID_TO_EDIT = 'CONTENT_CREATOR/NEW_BOOKS/ADD_EDIT_BOOK_DRAWER/DRAWER/SET_BOOK_ID_TO_EDIT',
  TOGGLE_AVAILABLE_IN_LIBRARY = 'CONTENT_CREATOR/COURSES/TOGGLE_AVAILABLE_IN_LIBRARY',
}

export enum DRAWER_STEP {
  INITIAL = 0,
  FIRST = 1,
  SECOND = 2,
}

// Open drawer action.
export type OpenDrawerAction = {
  type: DRAWER_TYPES.OPEN_MODAL
}

// Close drawer action.
export type CloseDrawerAction = {
  type: DRAWER_TYPES.CLOSE_MODAL
}

// Set current step action.
export type SetCurrentStepAction = {
  type: DRAWER_TYPES.SET_CURRENT_STEP
  payload: {
    newStep: DRAWER_STEP
  }
}

// Set edit mode action.
export type SetEditModeAction = {
  type: DRAWER_TYPES.SET_EDIT_MODE
  payload: {
    isEditMode: boolean
  }
}

// Load book info to edit actions.
export type LoadBookInfoToEditRequestAction = {
  type: DRAWER_TYPES.LOAD_BOOK_INFO_TO_EDIT_REQUEST
}

export type LoadBookInfoToEditSuccessAction = {
  type: DRAWER_TYPES.LOAD_BOOK_INFO_TO_EDIT_SUCCESS
}

export type LoadBookInfoToEditFailureAction = {
  type: DRAWER_TYPES.LOAD_BOOK_INFO_TO_EDIT_FAILURE
}

export type ToggleAvailableInLibraryAction = {
  type: DRAWER_TYPES.TOGGLE_AVAILABLE_IN_LIBRARY
}

export type LoadBookInfoToEditAction =
  | LoadBookInfoToEditRequestAction
  | LoadBookInfoToEditSuccessAction
  | LoadBookInfoToEditFailureAction

// Set bookId to edit action.
export type SetBookIdToEditAction = {
  type: DRAWER_TYPES.SET_BOOK_ID_TO_EDIT
  payload: {
    bookToEdit: BookByAudience
  }
}

// Action.
export type DrawerAction =
  | ToggleAvailableInLibraryAction
  | OpenDrawerAction
  | CloseDrawerAction
  | SetCurrentStepAction
  | SetEditModeAction
  | LoadBookInfoToEditAction
  | SetBookIdToEditAction
