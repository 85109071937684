export default {
  SUMMARY_REQUEST: 'DASHBOARD_STUDENT/SUMMARY_STUDENT_REQUEST',
  SUMMARY_SUCCESS: 'DASHBOARD_STUDENT/SUMMARY_STUDENT_SUCCESS',
  SUMMARY_FAILURE: 'DASHBOARD_STUDENT/SUMMARY_STUDENT_FAILURE',
  DASHBOARD_V4_REQUEST: 'DASHBOARD_STUDENT/DASHBOARD_V4_REQUEST',
  DASHBOARD_V4_SUCCESS: 'DASHBOARD_STUDENT/DASHBOARD_V4_SUCCESS',
  DASHBOARD_V4_FAILURE: 'DASHBOARD_STUDENT/DASHBOARD_V4_FAILURE',
  DASHBOARD_REQUEST: 'DASHBOARD_STUDENT/DASHBOARD_STUDENT_REQUEST',
  DASHBOARD_SUCCESS: 'DASHBOARD_STUDENT/DASHBOARD_STUDENT_SUCCESS',
  DASHBOARD_FAILURE: 'DASHBOARD_STUDENT/DASHBOARD_STUDENT_FAILURE',
  TOGGLE_SEARCH_BAR: 'DASHBOARD_STUDENT/TOGGLE_SEARCH_BAR',
  SET_SEARCH: 'DASHBOARD_STUDENT/SET_SEARCH',
  SEARCH_REQUEST: 'DASHBOARD_STUDENT/SEARCH_REQUEST',
  SEARCH_SUCCESS: 'DASHBOARD_STUDENT/SEARCH_SUCCESS',
  SEARCH_FAILURE: 'DASHBOARD_STUDENT/SEARCH_FAILURE',
}
