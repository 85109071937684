import type { Classroom, Method, PasswordType } from '@/sections/auth/login/types/login'
import type { User } from '@/sections/user/types'
import type { LoginSuccess as LoginWithPasswordSuccess } from '@/sections/auth/login/types/login'
import type { ClaimCodeSuccess } from '@/sections/backpack/types/codeClaimModal'

export const SET_STEP = 'LOGIN_WITH_CODE/SET_STEP'

export const SET_PASSWORD = 'LOGIN_WITH_CODE/SET_PASSWORD'

export const WHICH_LOGIN_REQUEST = 'LOGIN_WITH_CODE/WHICH_LOGIN_REQUEST'
export const WHICH_LOGIN_SUCCESS = 'LOGIN_WITH_CODE/WHICH_LOGIN_SUCCESS'
export const WHICH_LOGIN_FAILURE = 'LOGIN_WITH_CODE/WHICH_LOGIN_FAILURE'

export const LOGIN_REQUEST = 'LOGIN_WITH_CODE/LOGIN_WITH_CODE_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_WITH_CODE/LOGIN_WITH_CODE_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_WITH_CODE/LOGIN_WITH_CODE_FAILURE'

export const GET_DOC_TYPES_REQUEST = 'LOGIN_WITH_CODE/GET_DOC_TYPES_REQUEST'
export const GET_DOC_TYPES_SUCCESS = 'LOGIN_WITH_CODE/GET_DOC_TYPES_SUCCESS'
export const GET_DOC_TYPES_FAILURE = 'LOGIN_WITH_CODE/GET_DOC_TYPES_FAILURE'

export const SET_CODE = 'LOGIN_WITH_CODE/SET_CODE'
export const SET_DOCUMENT_TYPE = 'LOGIN_WITH_CODE/SET_DOCUMENT_TYPE'
export const SET_DNI = 'LOGIN_WITH_CODE/SET_DNI'
export const SET_NAME = 'LOGIN_WITH_CODE/SET_NAME'
export const SET_LAST_NAME = 'LOGIN_WITH_CODE/SET_LAST_NAME'

export const VERIFY_CODE_REQUEST = 'LOGIN_WITH_CODE/VERIFY_CODE_REQUEST'
export const VERIFY_CODE_SUCCESS = 'LOGIN_WITH_CODE/VERIFY_CODE_SUCCESS'
export const VERIFY_CODE_FAILURE = 'LOGIN_WITH_CODE/VERIFY_CODE_FAILURE'

export interface SetStep {
  type: typeof SET_STEP
  payload: {
    step: string
  }
}

export interface WhichLoginRequest {
  type: typeof WHICH_LOGIN_REQUEST
}

export interface WhichLoginSuccess {
  type: typeof WHICH_LOGIN_SUCCESS
  payload: {
    step: Method
    selectedPasswordType: PasswordType
  }
}

export interface WhichLoginFailure {
  type: typeof WHICH_LOGIN_FAILURE
  payload: {
    step?: Method
    error: string
  }
}

export interface LoginRequest {
  type: typeof LOGIN_REQUEST
}

export interface LoginSuccess {
  type: typeof LOGIN_SUCCESS
  payload: {
    user: User
    token: string
    classroomID: number
    classroom: Classroom
    role: string
    altRoles: string[]
    sandbox: number
    organizations: number[]
    organizationList: Organization[]
  }
}

export interface LoginFailure {
  type: typeof LOGIN_FAILURE
  payload: {
    error: string
  }
}

export interface VerifyCodeRequest {
  type: typeof VERIFY_CODE_REQUEST
}

export interface VerifyCodeSuccess {
  type: typeof VERIFY_CODE_SUCCESS
  payload: {
    organization: Organization
  }
}

export interface VerifyCodeFailure {
  type: typeof VERIFY_CODE_FAILURE
  payload: {
    error: string
  }
}

export interface GetDocTypesRequest {
  type: typeof GET_DOC_TYPES_REQUEST
}

export interface GetDocTypesSuccess {
  type: typeof GET_DOC_TYPES_SUCCESS
  payload: {
    docTypes: DocType[]
    docTypesOptions: DocTypeOption[]
    selectedDocType: number
  }
}

export interface GetDocTypesFailure {
  type: typeof GET_DOC_TYPES_FAILURE
  payload: {
    error: string
  }
}

export interface SetCode {
  type: typeof SET_CODE
  payload: {
    code: string
  }
}
export interface SetDni {
  type: typeof SET_DNI
  payload: {
    dni: string
  }
}

export interface SetDocumentType {
  type: typeof SET_DOCUMENT_TYPE
  payload: {
    selectedDocType: number
  }
}

export interface SetName {
  type: typeof SET_NAME
  payload: {
    name: string
  }
}
export interface SetLastName {
  type: typeof SET_LAST_NAME
  payload: {
    lastName: string
  }
}

export interface SetPassword {
  type: typeof SET_PASSWORD
  payload: {
    password: string
  }
}

export type Organization = {
  id: number
  name: string
  logo?: string
}

export type Country = {
  id: number
  name: string
  flag: string
}

export type DocType = {
  country: Country
  id: number
  inputType: string
  max: number
  min: number
  name: string
  hidden: boolean
  icon: string
}

export type DocTypeOption = {
  value: number
  label: string
  flag: string
}

export type CodeLoginState = {
  code: string
  name: string
  lastName: string
  dni: string

  selectedDocType: number
  docTypesOptions: DocTypeOption[]
  docTypes: DocType[]
  docTypesFetched: boolean

  organization: Organization

  passwordModalOpen: boolean
  password: string
  passwordRepeat: string
  selectedPasswordType: PasswordType
  step: string
  loading: boolean
  error: string
  loginWithPasswordSuccess: boolean
}

export type CodeLoginActions =
  | SetStep
  | LoginRequest
  | LoginSuccess
  | LoginFailure
  | GetDocTypesRequest
  | GetDocTypesSuccess
  | GetDocTypesFailure
  | SetCode
  | SetDocumentType
  | SetDni
  | SetName
  | SetLastName
  | VerifyCodeRequest
  | VerifyCodeSuccess
  | VerifyCodeFailure
  | WhichLoginRequest
  | WhichLoginSuccess
  | WhichLoginFailure
  | SetPassword
  | LoginWithPasswordSuccess
  | ClaimCodeSuccess
