import type { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Delete } from '@mui/icons-material'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import ThreeDotMenu from '@/legacy/components/v3/threeDotMenu'

interface StylesProps {
  width?: string | number
  height?: string | number
  padding?: string | number
  inputBorder?: string
  inputBorderError?: string
  background?: string
  backgroundSelected?: string
  disabled?: boolean
  isHighlightedValue?: boolean
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  root: {
    width: ({ width }) => width ?? 'fit-content',
    height: ({ height }) => height,
  },
  wrapper: {
    width: ({ width }) => width ?? 'fit-content',
    height: ({ height }) => height,
  },
  inputWrapperBase: {
    width: ({ width }) => width,
    height: '100%',
    display: 'flex',
    userSelect: 'none',
    borderRadius: 4,
    boxSizing: 'border-box',
    transition: 'background 200ms ease',
  },
  inputWrapper: {
    width: ({ width }) => width,
    background: ({ background, disabled, isHighlightedValue }) =>
      isHighlightedValue
        ? 'rgba(66, 179, 255, 0.5)'
        : disabled
        ? 'rgba(245, 245, 245)'
        : background,
    border: ({ inputBorder, isHighlightedValue }) =>
      isHighlightedValue ? '1px solid rgba(66, 179, 255, 0.5)' : inputBorder,
    padding: ({ padding }) => padding,
  },
  inputWrapperSelected: {
    width: ({ width }) => width,
    background: ({ backgroundSelected, disabled, isHighlightedValue }) =>
      isHighlightedValue
        ? 'rgba(66, 179, 255, 0.5)'
        : disabled
        ? 'rgba(245, 245, 245)'
        : backgroundSelected,
    padding: '0 !important',
    border: ({ inputBorder, disabled, isHighlightedValue }) =>
      isHighlightedValue
        ? '1px solid rgba(66, 179, 255, 0.5)'
        : disabled
        ? inputBorder
        : `2px solid ${theme.palette.secondary.main} !important`,
  },
  inputWrapperSelectedError: {
    width: ({ width }) => width,
    background: ({ backgroundSelected }) => backgroundSelected,
    padding: '0 !important',
    border: ({ inputBorderError }) => `${inputBorderError} !important`,
  },
  inputContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  input: {
    width: '100%',
    height: '100%',
    border: 'none',
    fontSize: '1rem',
    background: 'none',
    paddingLeft: 14,
    paddingRight: 14,
    fontFamily: 'DM Sans',
    boxSizing: 'border-box',
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      color: ({ isHighlightedValue }) =>
        isHighlightedValue ? theme.palette.common.white : 'rgba(170, 170, 170)',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&:input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  inputIconContainer: {
    width: 50,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      alignSelf: 'center',
      fill: '#1a1a1a',
      width: '1.3rem',
    },
  },
  separator: {
    width: 1,
    height: '50%',
    background: 'rgb(223 223 218)',
    alignSelf: 'center',
  },
  rightIconContainer: {
    height: '100%',
    aspectRatio: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteIconContainer: {
    height: '90%',
    aspectRatio: '1',
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '300ms all',
    '& svg': {
      fill: theme.palette.primary.main,
      transition: '300ms all ease',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    '&:hover': {
      background: theme.palette.disabled.secondary,
    },
  },
}))

const defaultStyles = {
  width: 250,
  height: 40,
  padding: 0,
  inputBorder: '1px solid rgb(223, 223, 218)',
  inputBorderError: '2px solid #ff4040',
  background: 'transparent',
  backgroundSelected: 'rgb(235 235 235 / 47%)',
}

export enum VisibilityProp {
  Text = 'text',
  Password = 'password',
  Number = 'number',
}

export enum ActionIconTypeProp {
  NONE = 'none',
  DELETE = 'delete',
  MENU = 'menu',
}

export type MenuOptionsProp = { label: string; onClick: () => void }[]

export interface InputSimpleDebouncedProps {
  icon?: React.ReactElement
  value?: string
  error?: boolean
  placeholder?: string
  onBlur?: (value: string) => void
  onFocus?: () => void
  onChange?: (value: string) => void
  onRemove?: () => void
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  styles?: StylesProps
  type?: VisibilityProp
  disabled?: boolean
  debounceTime?: number
  menuOptions?: { label: string; onClick: () => void }[]
  actionIconType?: ActionIconTypeProp
  isHighlightedValue?: boolean
}
const InputSimpleDebounced: React.FC<InputSimpleDebouncedProps> = ({
  icon = null,
  error = false,
  value: initialValue = '',
  placeholder = '',
  onBlur = () => {
    return
  },
  onFocus = () => {
    return
  },
  onChange = () => {
    return
  },
  onRemove = null,
  onKeyDown = () => {
    return
  },
  styles: {
    width = defaultStyles.width,
    height = defaultStyles.height,
    padding = defaultStyles.padding,
    inputBorder = defaultStyles.inputBorder,
    inputBorderError = defaultStyles.inputBorderError,
    background = defaultStyles.background,
    backgroundSelected = defaultStyles.backgroundSelected,
  } = defaultStyles,
  type = 'text',
  disabled = false,
  debounceTime = 250,
  menuOptions = [],
  actionIconType = ActionIconTypeProp.NONE,
  isHighlightedValue,
}) => {
  const classes = useStyles({
    width,
    height,
    padding,
    inputBorder,
    inputBorderError,
    background,
    backgroundSelected,
    disabled,
    isHighlightedValue,
  })
  const [value, setValue] = useState(initialValue)
  const [toggleMenu, setToggleMenu] = useState(false)

  const onInputSelectClick = () => {
    setToggleMenu((state) => !state)
  }

  const onInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur(event.target.value)
    setToggleMenu(false)
  }

  const debouncedOnChangeValue = useDebouncedCallback((newValue: string) => {
    onChange(newValue)
  }, debounceTime)

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedOnChangeValue(event.target.value)
  }

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <div
          className={clsx(classes.inputWrapperBase, {
            [classes.inputWrapperSelectedError]: error,
            [classes.inputWrapper]: !toggleMenu,
            [classes.inputWrapperSelected]: toggleMenu,
          })}
          onClick={onInputSelectClick}
        >
          {icon && <div className={classes.inputIconContainer}>{icon}</div>}

          <div className={classes.inputContainer}>
            {icon && <span className={classes.separator} />}
            <input
              type={type}
              spellCheck="true"
              className={classes.input}
              value={value}
              placeholder={placeholder}
              onFocus={onFocus}
              onBlur={onInputBlur}
              onChange={onChangeValue}
              disabled={disabled}
              onKeyDown={onKeyDown}
            />
            {actionIconType === ActionIconTypeProp.MENU ? (
              menuOptions.length > 0 ? (
                <div className={classes.rightIconContainer}>
                  <ThreeDotMenu
                    menuOptions={menuOptions}
                    styles={{ width: 30, height: 30, alignSelf: 'center' }}
                  />
                </div>
              ) : null
            ) : actionIconType === ActionIconTypeProp.DELETE ? (
              <div className={classes.rightIconContainer}>
                <div className={classes.deleteIconContainer}>
                  {onRemove && !disabled && <Delete onClick={onRemove} />}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InputSimpleDebounced
