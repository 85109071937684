import type { Cosmo } from '@/api/org'
import type { REQUEST_STATUS } from '@/legacy/redux/types/status'

// State
export interface AddEditAudienceModalState {
  isOpenModal: boolean
  isEditMode: boolean
  cosmos: {
    status: REQUEST_STATUS
    cosmos: Cosmo[]
    selectedCosmos: string[]
  }
  audience: {
    id: number
    name: string
    status: REQUEST_STATUS
    canCreateAudience: boolean
  }
}

export enum MODAL_TYPES {
  OPEN_MODAL = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/OPEN_MODAL',
  CLOSE_MODAL = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/CLOSE_MODAL',
  GET_COSMOS_REQUEST = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/GET_COSMOS_REQUEST',
  GET_COSMOS_SUCCESS = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/GET_COSMOS_SUCCESS',
  GET_COSMOS_FAILURE = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/GET_COSMOS_FAILURE',
  SET_SELECTED_COSMOS = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/SET_SELECTED_COSMOS',
  SET_SELECTED_ALL_COSMOS = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/SET_SELECTED_ALL_COSMOS',
  GET_AUDIENCE_VALIDATION_REQUEST = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/GET_AUDIENCE_VALIDATION_REQUEST',
  GET_AUDIENCE_VALIDATION_SUCCESS = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/GET_AUDIENCE_VALIDATION_SUCCESS',
  GET_AUDIENCE_VALIDATION_FAILURE = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/GET_AUDIENCE_VALIDATION_FAILURE',
  SET_AUDIENCE_NAME = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/SET_AUDIENCE_NAME',
  CREATE_AUDIENCE_REQUEST = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/CREATE_AUDIENCE_REQUEST',
  CREATE_AUDIENCE_SUCCESS = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/CREATE_AUDIENCE_SUCCESS',
  CREATE_AUDIENCE_FAILURE = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/CREATE_AUDIENCE_FAILURE',
  SET_AUDIENCE_TO_EDIT = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/SET_AUDIENCE_TO_EDIT',
  EDIT_AUDIENCE_REQUEST = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/EDIT_AUDIENCE_REQUEST',
  EDIT_AUDIENCE_SUCCESS = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/EDIT_AUDIENCE_SUCCESS',
  EDIT_AUDIENCE_FAILURE = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/EDIT_AUDIENCE_FAILURE',
  CLEAN_MODAL = 'EBADMIN/MANAGEMENT/ADD_EDIT_AUDIENCE_MODAL/CLEAN_MODAL',
}

// Actions.
export type OpenModalAction = {
  type: MODAL_TYPES.OPEN_MODAL
}

export type CloseModalAction = {
  type: MODAL_TYPES.CLOSE_MODAL
}

export type GetCosmosRequestAction = {
  type: MODAL_TYPES.GET_COSMOS_REQUEST
}

export type GetCosmosSuccessAction = {
  type: MODAL_TYPES.GET_COSMOS_SUCCESS
  payload: {
    cosmos: Cosmo[]
  }
}

export type GetCosmosFailureAction = {
  type: MODAL_TYPES.GET_COSMOS_FAILURE
}

export type GetCosmosAction =
  | GetCosmosRequestAction
  | GetCosmosSuccessAction
  | GetCosmosFailureAction

export type SetSelectedCosmosAction = {
  type: MODAL_TYPES.SET_SELECTED_COSMOS
  payload: {
    cosmoToChange: string
  }
}

export type SetSelectedAllCosmosAction = {
  type: MODAL_TYPES.SET_SELECTED_ALL_COSMOS
  payload: {
    canSelectAllCosmos: boolean
  }
}

export type GetAudienceValidationRequestAction = {
  type: MODAL_TYPES.GET_AUDIENCE_VALIDATION_REQUEST
}

export type GetAudienceValidationSuccessAction = {
  type: MODAL_TYPES.GET_AUDIENCE_VALIDATION_SUCCESS
  payload: {
    canCreateAudience: boolean
  }
}

export type GetAudienceValidationFailureAction = {
  type: MODAL_TYPES.GET_AUDIENCE_VALIDATION_FAILURE
}

export type GetAudienceValidationAction =
  | GetAudienceValidationRequestAction
  | GetAudienceValidationSuccessAction
  | GetAudienceValidationFailureAction

export type SetAudienceNameAction = {
  type: MODAL_TYPES.SET_AUDIENCE_NAME
  payload: {
    newName: string
  }
}

export type CreateAudienceRequestAction = {
  type: MODAL_TYPES.CREATE_AUDIENCE_REQUEST
}

export type CreateAudienceSuccessAction = {
  type: MODAL_TYPES.CREATE_AUDIENCE_SUCCESS
}

export type CreateAudienceFailureAction = {
  type: MODAL_TYPES.CREATE_AUDIENCE_FAILURE
}

export type CreateAudienceAction =
  | CreateAudienceRequestAction
  | CreateAudienceSuccessAction
  | CreateAudienceFailureAction

export type SetAudienceToEditAction = {
  type: MODAL_TYPES.SET_AUDIENCE_TO_EDIT
  payload: {
    audience: {
      id: number
      name: string
      cosmos: string[]
    }
  }
}

export type EditAudienceRequestAction = {
  type: MODAL_TYPES.EDIT_AUDIENCE_REQUEST
}

export type EditAudienceSuccessAction = {
  type: MODAL_TYPES.EDIT_AUDIENCE_SUCCESS
}

export type EditAudienceFailureAction = {
  type: MODAL_TYPES.EDIT_AUDIENCE_FAILURE
}

export type EditAudienceAction =
  | EditAudienceRequestAction
  | EditAudienceSuccessAction
  | EditAudienceFailureAction

export type CleanModalAction = {
  type: MODAL_TYPES.CLEAN_MODAL
}

export type AddEditAudienceModalAction =
  | OpenModalAction
  | CloseModalAction
  | GetCosmosAction
  | SetSelectedCosmosAction
  | SetSelectedAllCosmosAction
  | GetAudienceValidationAction
  | SetAudienceNameAction
  | CreateAudienceAction
  | SetAudienceToEditAction
  | EditAudienceAction
  | CleanModalAction
